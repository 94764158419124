import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'

import { classesTranslate } from '../../../i18n/translatedMessages'
import { getAreas } from '../../../API/carrerProjection'
import { postTraining } from '../../../API/Employee/Training'
import './createCourse.sass'
import { FormattedMessage } from 'react-intl'

const CreateCourse = ({ handleShow, show, close }) => {

    const company = JSON.parse(localStorage.getItem('user'))

    const [training, setTraining] = useState({
        name: "",
        description: "",
        link: "",
        areaId: "0",
        companyId: company.id
    })

    const [listAreas, setListAreas] = useState([])

    const handleChange = event => {
        setTraining({
            ...training,
            [event.target.name]: event.target.value
        })
    }

    useEffect(() => {
        const fetchArea = async () => {
            setListAreas(await getAreas())
        }
        fetchArea()
    }, [])


    const handleCreate = async () => {
        await postTraining(training)
        close()
        handleShow(false)
        setTraining({
            name: "",
            description: "",
            link: "",
            areaId: "0",
            companyId: company.id
        })
    }

    return (
        <Modal className="create-course" show={show} onHide={() => handleShow(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title className="title">{classesTranslate.createNewClass}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <FormattedMessage id={"className"}>
                        {msg => (
                            <div className="col">
                                <input type="text"
                                value={training.name}
                                name="name"
                                placeholder={msg}
                                onChange={handleChange} />
                            </div>
                        )}
                    </FormattedMessage>
                </div>
                <div className="row">
                    <div className="col">
                        <select value={training.areaId} onChange={handleChange} name="areaId">
                            <FormattedMessage id={'capacitationChooseArea'}>
                                {msg => (
                                    <option value="0" disabled label={msg}></option>
                                )}
                            </FormattedMessage>
                            {listAreas.length > 0 && listAreas.map(item =>
                                <option key={item.id} value={item.id}>{item.profileArea}</option>
                            )}
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <textarea name="description" cols="30" rows="10" value={training.description} onChange={handleChange}></textarea>
                    </div>
                </div>
                <div className="row">
                    <FormattedMessage id={"classLink"}>
                        {msg => (
                            <div className="col">
                                <input
                                type="text"
                                name="link" v
                                alue={training.link}
                                placeholder={msg}
                                onChange={handleChange} />
                            </div>
                        )}
                    </FormattedMessage>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <button className='create' variant="primary" onClick={handleCreate}>
                    {classesTranslate.btnSave}
                </button>
            </Modal.Footer>
        </Modal>
    )
}
export default CreateCourse