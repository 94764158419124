import "./tooltip.sass";

const TooltipStars = () => {
  return (
    <div className="tooltip-stars">
      <span className="text">Evaluación de los usuarios</span>
    </div>
  );
};
export default TooltipStars;
