import { useState } from "react";
import {
  AiOutlineEnvironment,
  AiOutlineHome,
  AiOutlineUpload,
} from "react-icons/ai";

import "./styles.css";

const Vacancies = () => {
  const [vacancyInformation, setVacancyInformation] = useState({
    nameVacancy: "",
    nameEmpresa: "",
    nameCidade: "",
    namePais: "",
    description: "",
    imgEmp: "",
  });

  const [itemSelected, setItemSelected] = useState();

  const data = [
    {
      nameVacancy: "Nome da Vaga 1.0",
      nameEmpresa: "Nome da Empresa 1.1",
      nameCidade: "Cidade 1.2",
      namePais: "Pais 1.3",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      imgEmp:
        "https://assets.mydna.company/industry2.svg",
      tipo: "Remoto",
      requisitos:
        'Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.',
    },
    {
      nameVacancy: "Nome da Vaga 2.0",
      nameEmpresa: "Nome da Empresa 2.1",
      nameCidade: "Cidade 2.2",
      namePais: "Pais 2.3",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      imgEmp:
        "https://assets.mydna.company/industry2.svg",
      tipo: "Presencial",
      requisitos:
        'Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.',
    },
  ]

  const handleClick = (e) => {
    const info = JSON.parse(e.currentTarget.dataset.info);
    setVacancyInformation(info);
    const index = JSON.parse(e.currentTarget.dataset.index);
    setItemSelected(index);
  }

  return (
    <div className="container-fluid vh-100">
      <div className="row pt-4 align-items-center">
        <div className="col-4">
          <input
            className="form-control me-2 rounded-pill"
            type="search"
            placeholder=""
            aria-label="Search"
          />
        </div>
        <div className="col-4">
          <input
            className="form-control me-2 rounded-pill"
            type="search"
            placeholder=""
            aria-label="Search"
          />
        </div>
      </div>
      <div className="row pt-4">
        <div className="col-4">
          <ul className="list-group rounded">
            {data.map((vacancies, index) => {
              return (
                <li
                  className={
                    itemSelected === index
                      ? "list-group-item list-item selected-item"
                      : "list-group-item list-item"
                  }
                  key={index}
                  data-index={JSON.stringify(index)}
                  data-info={JSON.stringify(vacancies)}
                  onClick={(e) => handleClick(e)}
                >
                  <div className="row align-items-center">
                    <div className="col-3 p-4">
                      <img
                        alt="..."
                        className="img-fluid shadow rounded-circle"
                        src={vacancies.imgEmp}
                      />
                    </div>
                    <div className="col-9">
                      <h4>{vacancies.nameVacancy}</h4>
                      <h5>{vacancies.nameEmpresa}</h5>
                      <span>{vacancies.nameCidade}</span>
                      <span> {vacancies.namePais}</span>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="col-8">
          {vacancyInformation.nameVacancy ? (
            <div className="p-3 rounded response-menu shadow">
              <div className="row align-items-center">
                <div className="col-10">
                  <h3>{vacancyInformation.nameVacancy}</h3>
                  <h4>{vacancyInformation.nameEmpresa}</h4>
                  <span>{vacancyInformation.nameCidade}</span>
                  <span> {vacancyInformation.namePais}</span>
                </div>
                <div className="col-2">
                  <img
                    alt="..."
                    className="img-fluid shadow rounded-circle"
                    src={vacancyInformation.imgEmp}
                  />
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-3 align-items-center">
                  <span>
                    {vacancyInformation.tipo === "Remoto" ? (
                      <AiOutlineHome size={20} />
                    ) : (
                      <AiOutlineEnvironment size={20} />
                    )}{" "}
                    {vacancyInformation.tipo}
                  </span>
                </div>
                <div className="col-12 pt-4">
                  <button className="btn shadow rounded-pill post-button">
                    <AiOutlineUpload size={20} /> Postularme
                  </button>
                </div>
              </div>
              <div className="row color-grey pt-4">
                <h5>Principales funciones</h5>
                <div className="col-12">{vacancyInformation.description}</div>
                <h5 className="pt-4">Requisitos</h5>
                <div className="col-12">{vacancyInformation.requisitos}</div>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Vacancies;
