import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { MdArrowBackIos } from "react-icons/md";
// import { EmployerContext } from '../../../Store/EmployerContext';
import { getEmployee } from "../../../API/Employee/Employee";
import ModalViewProfileInfo from "../../Service/Views/PeopleAnalytics/Components/ModalProfileInfo/ModalViewProfileInfo";
import ModalEditProfileInfo from "../../Service/Views/PeopleAnalytics/Components/ModalProfileInfo/ModalEditProfileInfo";
import { getCountry } from "../../../API/Lists/country";
import { civilStatesList, contractTypesList, genderList } from "../../../API/Employee/Profile";
import { futureCVTranslate } from "../../../i18n/translatedMessages";

import "./Header.sass";
import Loading from "../../../Components/Loading/Loading";

const REGEX_ID = /(([\s\S])+[^\d+$])/g;

const HeaderLayout = ({ children }) => {
  // const { store } = useContext(EmployerContext);
  const [showProfileView, setShowProfileView] = useState(false);
  const [showProfileEdit, setShowProfileEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [employee, setEmployee] = useState({
    name: "",
    email: "",
    profile: "",
  });

  const [lists, setLists] = useState({
    gender: [],
    maritalStatus: [],
    contract: [],
    country: [],
  });

  const history = useHistory();
  const pathName = history.location.pathname;
  const EmployeeID = pathName.replace(REGEX_ID, "");

  const requests = async () => {
    setLoading(true);
    const datos = await getEmployee(EmployeeID);
    setEmployee({
      name: datos?.name,
      profile: datos?.employerSubAreas?.name,
      email: datos?.employerUser?.email,
    });
    const [country, gender, maritalStatus, contract] = await Promise.all([
      getCountry(),
      genderList(),
      civilStatesList(),
      contractTypesList(),
    ]);

    setLists({
      country,
      gender,
      maritalStatus,
      contract,
    });
    setLoading(false);
  };

  useEffect(() => {
    requests();
  }, [EmployeeID]);

  const handleClose = () => {
    requests();
    setShowProfileEdit(false);
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <ModalViewProfileInfo
            employeeId={EmployeeID}
            show={showProfileView}
            onEditClick={() => {
              setShowProfileView(false);
              setShowProfileEdit(true);
            }}
            lists={lists}
            onCloseClick={() => setShowProfileView(false)}
          />
          <ModalEditProfileInfo
            show={showProfileEdit}
            lists={lists}
            employeeId={EmployeeID}
            onCloseClick={() => handleClose()}
          />
          <div className="row m-0 p-0">
            <div className="col">
              <ul className="colaborador-infos">
                <li>
                  <button className="item-circle" onClick={history.goBack}>
                    <MdArrowBackIos size={30} />
                  </button>
                  <div>
                    <span className="item-menu">{employee?.name}</span>
                    <span className="item-menu">{employee?.email}</span>
                    <span className="item-menu">{employee?.profile}</span>
                  </div>
                </li>
                <li className="more-info">
                  {!pathName.indexOf("futurecv") !== -1 && (
                    <Link
                      to={`/app/analytic/futurecv/${EmployeeID}`}
                      className="btn rounded-pill btnObtenerPDF fw-bold fs-6"
                    >
                      {futureCVTranslate.showReview}
                    </Link>
                  )}

                  <button
                    size={30}
                    onClick={() => setShowProfileView(true)}
                    className="link-info cursor-pointer btn p-0"
                  >
                    {futureCVTranslate.showMore}
                    <span className="item-circle-down" onClick={() => setShowProfileView(true)}>
                      <MdArrowBackIos size={30} />
                    </span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          {children}
        </>
      )}
    </>
  );
};

export default HeaderLayout;
