import Modal from 'react-bootstrap/Modal'
import { useCallback, useState, useEffect } from 'react'

import Loading from '../../../Components/Loading/Loading.jsx'

import { Descriptor } from '../Descriptor/descriptor'
import JobDescription from '../../../API/JobDescription/jobDescription.js'
import List from '../../../API/Lists/Lists.js'

import './modal.sass'
import { jobDescriptorTranslate } from '../../../i18n/translatedMessages.js'

export function DescriptorModal({ show, handleShow, user }) {
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(false)
	const [descriptor, setDescriptor] = useState(null)
	const [positions, setPositions] = useState({
		position: '',
		positionName: '',
	})

	const loadDescriptor = useCallback(async () => {
		try {
			if (show) {
				setLoading(true)
				const subareas = await List.subareas()
				const [subarea] = await subareas.filter(item => item.name.toLowerCase() === user.SubAreas?.name.toLowerCase())

				const email = JSON.parse(localStorage.getItem('email'))
				const company = JSON.parse(localStorage.getItem('user'))

				setPositions({
					position: user.jobName,
					positionName: `${user.level?.PositionN} de ${user.SubAreas?.name}`,
				})
				const { data, status } = await JobDescription.get(email, subarea?.id, user.level.id, company.id)

				if (status === 200) {
					setDescriptor(data)
				}
			}
		} catch (error) {
			setError(true)
		} finally {
			setLoading(false)
		}
	}, [user, show])

	useEffect(() => {
		loadDescriptor()
	}, [loadDescriptor])

	return (
		<Modal
			show={show}
			dialogClassName='modal-auto'
			onHide={() => {
				handleShow(false)
				setError(false)
				setDescriptor(null)
			}}
		>
			{loading && (
				<div className='overflow-hidden' style={{ height: 300, width: 500 }}>
					<Loading size='sm' />
				</div>
			)}
			{!loading && (
				<>
					<Modal.Header closeButton className='border-0 m-0' />
					<Modal.Body className='ps-0 m-0'>
						{descriptor && (
							<div style={{ width: 800 }}>
								<Descriptor descriptor={descriptor} user={positions} />
							</div>
						)}
						{!descriptor && (
							<div className='px-4'>
								<p style={{ width: 500 }}>
									<span className='fw-bold'>{jobDescriptorTranslate.jobDescriptorNullText}</span> {jobDescriptorTranslate.jobDescriptorNullText2}.
								</p>
							</div>
						)}
						{error && (
							<div className='w-100 flex justify-content-center'>
								<h3>Algo deu errado!</h3>
							</div>
						)}
					</Modal.Body>
					<Modal.Footer className='border-0 m-0'>
						<div className='row w-100'>
							<div className='col'>
								<button
									className='btn btn-outline-primary rounded-pill w-100'
									onClick={() => {
										handleShow(false)
										setError(false)
										setDescriptor(null)
									}}
								>
									{jobDescriptorTranslate.jobDescriptorBack}
								</button>
							</div>
						</div>
					</Modal.Footer>
				</>
			)}
		</Modal>
	)
}
