// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.treinaments-content .icon {
  background: linear-gradient(45deg, #40c2ff 0%, #32d8d8 100%);
  width: 50px;
  height: 50px;
  border-radius: 50rem;
  padding: 8px;
  margin: 0 10px;
}
.treinaments-content .icon svg {
  fill: white;
  width: 100%;
}
.treinaments-content .card-treinaments {
  width: 500px;
  height: 150px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin: 25px;
  padding: 12px;
  color: rgb(60, 70, 80);
  background: white;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.095);
}
.treinaments-content .card-treinaments .col-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.treinaments-content .card-treinaments .col-img {
  margin-left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  background: #7a6be3;
  border-radius: 8px;
}
.treinaments-content .card-treinaments .col-img img {
  width: 100%;
}
.treinaments-content .card-treinaments h6 {
  font-weight: bolder;
}
.treinaments-content .card-treinaments p {
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/Views/Service/Views/EntrenamientoYDesarrollo/Treinaments/treinaments.sass"],"names":[],"mappings":"AACI;EACI,4DAAA;EACA,WAAA;EACA,YAAA;EACA,oBAAA;EACA,YAAA;EACA,cAAA;AAAR;AAEQ;EACI,WAAA;EACA,WAAA;AAAZ;AAEI;EACI,YAAA;EACA,aAAA;EAEA,kBAAA;EACA,qCAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,0CAAA;AADR;AAGQ;EACI,aAAA;EACA,sBAAA;EACA,2BAAA;AADZ;AAGQ;EACI,iBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;AADZ;AAGY;EACI,WAAA;AADhB;AAGQ;EACI,mBAAA;AADZ;AAEQ;EACI,eAAA;AAAZ","sourcesContent":[".treinaments-content\n    & .icon\n        background: linear-gradient(45deg,#40c2ff 0%, #32d8d8 100%)\n        width: 50px\n        height: 50px\n        border-radius: 50rem\n        padding: 8px\n        margin: 0 10px\n\n        & svg\n            fill: white\n            width: 100%\n\n    .card-treinaments\n        width: 500px\n        height: 150px\n\n        border-radius: 8px\n        border: 1px solid rgba(0,0,0,.15)\n        margin: 25px\n        padding: 12px\n        color: rgb(60,70,80)\n        background: white\n        box-shadow: 0 5px 8px rgba(0,0,0, .095)\n\n        .col-text\n            display: flex\n            flex-direction: column\n            justify-content: flex-start\n\n        .col-img\n            margin-left: 12px\n            display: flex\n            justify-content: center\n            align-items: center\n            width: 100px\n            background: #7a6be3\n            border-radius: 8px\n\n            img\n                width: 100%\n\n        h6\n            font-weight: bolder\n        p\n            font-size: 12px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
