import { RadioButton } from '../RadioButton/radioButton'
import { useEffect, useState } from 'react'

import { assessment } from '../../../i18n/translatedMessages'

export function GroupRadioButton({ onChange, answer }) {
	const [selectedId, setSelectedId] = useState(null)

	useEffect(() => {
		if (selectedId) {
			onChange({
				identifier: answer,
				answer: selectedId,
			})
		}
	}, [selectedId])

	return (
		<div className='flex w-100 justify-content-center my-3'>
			<span className='fw-bold' style={{ fontSize: 14, color: '#d67806' }}>
				{assessment.veryDisagreeable}
			</span>
			<span className='mx-3'>
				<RadioButton id={1} color='#d67806' selectedId={selectedId} setSelectedId={setSelectedId} />
			</span>
			<span className='mx-3'>
				<RadioButton id={2} color='#d67806' selectedId={selectedId} setSelectedId={setSelectedId} />
			</span>
			<span className='mx-3'>
				<RadioButton id={3} color='#627489' selectedId={selectedId} setSelectedId={setSelectedId} />
			</span>
			<span className='mx-3'>
				<RadioButton id={4} color='#7a6af1' selectedId={selectedId} setSelectedId={setSelectedId} />
			</span>
			<span className='mx-3'>
				<RadioButton id={5} color='#7a6af1' selectedId={selectedId} setSelectedId={setSelectedId} />
			</span>

			<span className='fw-bold' style={{ fontSize: 14, color: '#7a6af1' }}>
				{assessment.iAgreeVeryMuch}
			</span>
		</div>
	)
}
