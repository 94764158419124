// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empty {
  height: 40vh;
  width: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}
.empty h4 {
  font-weight: bold;
  color: #a6a6a6;
}
.empty svg {
  color: #a6a6a6;
  margin: 10px 0;
}

.ombudsman-main .header-icon {
  background: linear-gradient(45deg, #40c2ff 0%, #32d8d8 100%);
  width: 50px;
  height: 50px;
  border-radius: 50rem;
  padding: 8px;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ombudsman-main .ombudsman-btn {
  background: #5F52C4;
  border: none;
  color: white;
}
.ombudsman-main .ombudsman-btn:hover {
  background: #7A6BE8;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/Views/Service/Views/Ombudsman/ombudsman.sass"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,WAAA;EAGA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;AADJ;AAGI;EACI,iBAAA;EACA,cAAA;AADR;AAGI;EACI,cAAA;EACA,cAAA;AADR;;AAGI;EACI,4DAAA;EACA,WAAA;EACA,YAAA;EACA,oBAAA;EACA,YAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAAR;AACI;EACI,mBAAA;EACA,YAAA;EACA,YAAA;AACR;AAAQ;EACI,mBAAA;EACA,YAAA;AAEZ","sourcesContent":[".empty\n    height: 40vh\n    width: 50vw\n    //background: #efefef\n    //border: 1px solid #cacaca\n    display: flex\n    flex-direction: column\n    justify-content: center\n    align-items: center\n    border-radius: 8px\n\n    h4\n        font-weight: bold\n        color: #a6a6a6\n\n    svg\n        color: #a6a6a6\n        margin: 10px 0\n.ombudsman-main\n    & .header-icon\n        background: linear-gradient(45deg, #40c2ff 0%, #32d8d8 100%)\n        width: 50px\n        height: 50px\n        border-radius: 50rem\n        padding: 8px\n        margin: 0 10px\n        display: flex\n        align-items: center\n        justify-content: center\n    & .ombudsman-btn\n        background: #5F52C4\n        border: none\n        color: white\n        &:hover\n            background: #7A6BE8\n            color: white\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
