import { useEffect, useState, useRef } from "react"
import "./inputDropdown.sass"

export const InputDropdown = ({ list, onChange, handleClick, value, placeholder = "", autoComplete="off", name }) => {

    const [dropdown, setDropdown] = useState([])
    const [click, setClick] = useState(false)
    const inputDropdownRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (inputDropdownRef.current && !inputDropdownRef.current.contains(event.target)) {
                setDropdown([]);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [inputDropdownRef]);

    useEffect(() => {
        const newList = list.filter(item => {
            return item.name.toLowerCase().includes(value.toLowerCase())
        })
        setDropdown(value === "" || click ? [] : newList)
        setClick(false)
    }, [value, list])

    const setItem = event => {
        handleClick(event)
        setDropdown([])
        setClick(true)
    }

    return (
        <div className="input-dropdown">
            <input type="text" name={name} className="auto-complete" autoComplete={autoComplete} placeholder={placeholder} onChange={onChange} value={value} />
            {dropdown.length > 0 && <ul className="dropdown" ref={inputDropdownRef} >
                {dropdown.map(item => <li onClick={setItem} data-name={item.name} data-value={item.id} key={item.id}>{item.name}</li>)}
            </ul>}
        </div>
    )
}