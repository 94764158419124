import { useState, useEffect } from "react";
import { BsTrash } from "react-icons/bs";
import { BiChat } from "react-icons/bi";
import {
    deleteMessage,
    getMessageByCompany,
} from "../../API/InternalChannel/InternalChannel";
import Loading from "../../Components/Loading/Loading";
import MailButton from "./Components/MailButton";
import { internalChannel } from "../../i18n/translatedMessages";

import "./InternalChannel.sass";

const URL_REGEX =
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
const BOLD_REGEX = /(\*\*([\s\S])+\*\*)/g;
const ITALIC_REGEX = /(\*([\s\S])+\*)/g;
const DOUBLE_ASTERISTIK_REMOVE_REGEX = /[**]/g;
const ASTERISTIK_REMOVE_REGEX = /[*]/g;

const InternalChannel = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const [bringMessages, setBringMessages] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const company = JSON.parse(localStorage.getItem("user"));
        const requests = async () => {
            setLoading(true);
            const messagesAux = await getMessageByCompany(company.id);
            const mapMsg = messagesAux?.map((msg) => msg.messages);
            const msgsIds = mapMsg.map((o) => o.id);
            const filteredMsg = mapMsg.filter(
                ({ id }, index) => !msgsIds.includes(id, index + 1)
            );
            const readMoreFilteredMsgs = filteredMsg.map((msgs) =>
                msgs.description.length > 250
                    ? { ...msgs, readMore: true }
                    : { ...msgs, readMore: "noNeed" }
            );
            setBringMessages(readMoreFilteredMsgs);
            setLoading(false);
        };
        requests();
    }, []);

    const getMessagesRequest = async () => {
        setLoading(true);
        const messagesAux = await getMessageByCompany(user.id);
        const mapMsg = messagesAux?.map((msg) => msg.messages);
        const msgsIds = mapMsg.map((o) => o.id);
        const filteredMsg = mapMsg.filter(
            ({ id }, index) => !msgsIds.includes(id, index + 1)
        );
        const readMoreFilteredMsgs = filteredMsg.map((msgs) =>
            msgs.description.length > 250
                ? { ...msgs, readMore: true }
                : { ...msgs, readMore: "noNeed" }
        );
        setBringMessages(readMoreFilteredMsgs);
        setLoading(false);
    };

    const toggleReadMore = (msgId, value) => {
        const mapForToggle = bringMessages.map((msgs) => {
            if (msgs.id === msgId) {
                return { ...msgs, readMore: !value };
            } else {
                return msgs;
            }
        });
        setBringMessages(mapForToggle);
    };

    const delMessage = (messageId) => {
        deleteMessage(messageId).then(
            setBringMessages(
                bringMessages.filter((msg) => msg.id !== messageId)
            )
        );
    };

    const urlPatternValidation = (URL) => {
        const regex = new RegExp(URL_REGEX);
        return regex.test(URL);
    };
    const boldPatternValidation = (BOLD) => {
        const regex = new RegExp(BOLD_REGEX);
        return regex.test(BOLD);
    };
    const italicPatternValidation = (ITALIC) => {
        const regex = new RegExp(ITALIC_REGEX);
        return regex.test(ITALIC);
    };

    const messageWithHtmlOrMD = (str) => {
        const htmlRegex = /<\/?[a-z][\s\S]*>/i; // Expresión regular para etiquetas HTML
        return htmlRegex.test(str); // Devuelve true si la cadena contiene etiquetas HTML
    };

    const allMessages = bringMessages?.map((msg, key) => {
        const words = msg.description.split(" ");
        return (
            <div className="row row-cols-1 mb-3" key={key}>
                <div className="col">
                    <div className="card shadow rounded message-cards">
                        <div className="card-body">
                            <h5 className="card-title">{msg.title}</h5>
                            {/* {For messages with HTML Tags (> 06/03/2023)} */}
                            {messageWithHtmlOrMD(words) ? (
                                <>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: msg.description,
                                        }}
                                    />
                                </>
                            ) : (
                                <>
                                    {/* {For messages with MarkDown ( < to 06/03/2023)} */}

                                    <p className="card-text whiteSpace">
                                        {msg.readMore
                                            ? words
                                                  .slice(0, 100)
                                                  .map((word, keyW) => {
                                                      return urlPatternValidation(
                                                          word
                                                      ) ? (
                                                          <a
                                                              key={keyW}
                                                              target={"_blank"}
                                                              rel="noreferrer"
                                                              href={word}
                                                          >
                                                              {word}{" "}
                                                          </a>
                                                      ) : boldPatternValidation(
                                                            word
                                                        ) ? (
                                                          <span
                                                              className="fw-bold"
                                                              key={keyW}
                                                          >
                                                              {word.replace(
                                                                  DOUBLE_ASTERISTIK_REMOVE_REGEX,
                                                                  ""
                                                              )}{" "}
                                                          </span>
                                                      ) : italicPatternValidation(
                                                            word
                                                        ) ? (
                                                          <span
                                                              className="fst-italic"
                                                              key={keyW}
                                                          >
                                                              {word.replace(
                                                                  ASTERISTIK_REMOVE_REGEX,
                                                                  ""
                                                              )}{" "}
                                                          </span>
                                                      ) : (
                                                          word + " "
                                                      );
                                                  })
                                            : words.map((word, keyW) => {
                                                  return urlPatternValidation(
                                                      word
                                                  ) ? (
                                                      <a
                                                          target={"_blank"}
                                                          rel="noreferrer"
                                                          href={word}
                                                          key={keyW}
                                                      >
                                                          {word}{" "}
                                                      </a>
                                                  ) : boldPatternValidation(
                                                        word
                                                    ) ? (
                                                      <span
                                                          className="fw-bold"
                                                          key={keyW}
                                                      >
                                                          {word.replace(
                                                              DOUBLE_ASTERISTIK_REMOVE_REGEX,
                                                              ""
                                                          )}{" "}
                                                      </span>
                                                  ) : italicPatternValidation(
                                                        word
                                                    ) ? (
                                                      <span
                                                          className="fst-italic"
                                                          key={keyW}
                                                      >
                                                          {word.replace(
                                                              ASTERISTIK_REMOVE_REGEX,
                                                              ""
                                                          )}{" "}
                                                      </span>
                                                  ) : (
                                                      word + " "
                                                  );
                                              })}
                                        {msg.readMore !== "noNeed" ? (
                                            <span
                                                onClick={() =>
                                                    toggleReadMore(
                                                        msg.id,
                                                        msg.readMore
                                                    )
                                                }
                                                className="read-or-hide"
                                            >
                                                {msg.readMore
                                                    ? "... Read More"
                                                    : " Show Less"}
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </p>
                                </>
                            )}

                            <button className="btn btn-cards p-0 me-2">
                                {""}
                            </button>
                            <button
                                className="btn btn-cards p-0"
                                onClick={() => delMessage(msg.id)}
                            >
                                <BsTrash />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    });
    return (
        <div className="offset-1 InternalChannelMain">
            <div className="row pt-2">
                <div className="col-auto">
                    <h3>
                        <BiChat color="#7A6BE8" size={35} />{" "}
                        {internalChannel.internalChannelSendedMessages}
                    </h3>
                </div>
                <div className="col-auto">
                    <MailButton
                        getMessagesRequest={getMessagesRequest}
                        verifyLength={bringMessages.length}
                    />
                </div>
            </div>
            <p className="text-muted">
                La comunicación interna es el latido vital de una empresa, manteniendo a todos los equipos sincronizados y en armonía. Es el medio a través del cual se comparten objetivos, se celebran logros y se abordan desafíos. Una comunicación interna eficaz es el cimiento sobre el cual se construye el éxito y la cohesión organizacional.
            </p>
            <div className="row mt-1 px-3 pb-2">
                {loading ? (
                    <Loading />
                ) : bringMessages.length ? (
                    allMessages.sort((a, b) => {
                        return b.key - a.key;
                    })
                ) : (
                    <div className="mt-5">
                        <h5>{internalChannel.internalChannelNoMessages1}</h5>
                        <h5>{internalChannel.internalChannelNoMessages2}</h5>
                    </div>
                )}
            </div>
        </div>
    );
};

export default InternalChannel;
