import { useEffect, useState } from "react";
import { BiChat } from "react-icons/bi";
import { getMessageByEmployee } from "../../API/InternalChannel/InternalChannel";
import {
    employeeExperienceTranslate,
    internalChannel,
} from "../../i18n/translatedMessages";

import "./ReceivedMessages.sass";

const URL_REGEX =
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
const BOLD_REGEX = /(\*\*([\s\S])+\*\*)/g;
const ITALIC_REGEX = /(\*([\s\S])+\*)/g;
const DOUBLE_ASTERISTIK_REMOVE_REGEX = /[**]/g;
const ASTERISTIK_REMOVE_REGEX = /[*]/g;

const ReceivedMessages = () => {
    const [messages, setMessages] = useState([]);
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        const handleRequest = async () => {
            const employeeMessagesAux = await getMessageByEmployee(user.id);
            const messagesAux = employeeMessagesAux.map(
                (msgs) => msgs.messages
            );
            const readMoreFilteredMsgs = messagesAux.map((msgs) =>
                msgs.description.length > 250
                    ? { ...msgs, readMore: true }
                    : { ...msgs, readMore: "noNeed" }
            );
            setMessages(readMoreFilteredMsgs);
        };
        handleRequest();
    }, []);

    const toggleReadMore = (msgId, value) => {
        const mapForToggle = messages.map((msgs) => {
            if (msgs.id === msgId) {
                return { ...msgs, readMore: !value };
            } else {
                return msgs;
            }
        });
        setMessages(mapForToggle);
    };

    const urlPatternValidation = (URL) => {
        const regex = new RegExp(URL_REGEX);
        return regex.test(URL);
    };
    const boldPatternValidation = (BOLD) => {
        const regex = new RegExp(BOLD_REGEX);
        return regex.test(BOLD);
    };
    const italicPatternValidation = (ITALIC) => {
        const regex = new RegExp(ITALIC_REGEX);
        return regex.test(ITALIC);
    };

    const allReceivedMessages = messages?.map((msg, key) => {
        const words = msg.description.split(" ");
        return (
            <div className="row row-cols-1 mb-3" key={key}>
                <div className="col">
                    <div className="card rounded message-cards">
                        <div className="card-body">
                            <h5 className="card-title">{msg.title}</h5>
                            <p className="card-text whiteSpace">
                                {msg.readMore
                                    ? words.slice(0, 100).map((word, keyW) => {
                                          return urlPatternValidation(word) ? (
                                              <a
                                                  key={keyW}
                                                  target={"_blank"}
                                                  rel="noreferrer"
                                                  href={word}
                                              >
                                                  {word}{" "}
                                              </a>
                                          ) : boldPatternValidation(word) ? (
                                              <span
                                                  className="fw-bold"
                                                  key={keyW}
                                              >
                                                  {word.replace(
                                                      DOUBLE_ASTERISTIK_REMOVE_REGEX,
                                                      ""
                                                  )}{" "}
                                              </span>
                                          ) : italicPatternValidation(word) ? (
                                              <span
                                                  className="fst-italic"
                                                  key={keyW}
                                              >
                                                  {word.replace(
                                                      ASTERISTIK_REMOVE_REGEX,
                                                      ""
                                                  )}{" "}
                                              </span>
                                          ) : (
                                              word + " "
                                          );
                                      })
                                    : words.map((word, keyW) => {
                                          return urlPatternValidation(word) ? (
                                              <a
                                                  target={"_blank"}
                                                  rel="noreferrer"
                                                  href={word}
                                                  key={keyW}
                                              >
                                                  {word}{" "}
                                              </a>
                                          ) : boldPatternValidation(word) ? (
                                              <span
                                                  className="fw-bold"
                                                  key={keyW}
                                              >
                                                  {word.replace(
                                                      DOUBLE_ASTERISTIK_REMOVE_REGEX,
                                                      ""
                                                  )}{" "}
                                              </span>
                                          ) : italicPatternValidation(word) ? (
                                              <span
                                                  className="fst-italic"
                                                  key={keyW}
                                              >
                                                  {word.replace(
                                                      ASTERISTIK_REMOVE_REGEX,
                                                      ""
                                                  )}{" "}
                                              </span>
                                          ) : (
                                              word + " "
                                          );
                                      })}
                                {msg.readMore !== "noNeed" ? (
                                    <span
                                        onClick={() =>
                                            toggleReadMore(msg.id, msg.readMore)
                                        }
                                        className="read-or-hide"
                                    >
                                        {msg.readMore
                                            ? "... Read More"
                                            : " Show Less"}
                                    </span>
                                ) : (
                                    ""
                                )}
                            </p>
                            <button className="btn btn-cards p-0 me-2">
                                {}
                            </button>
                            <button className="btn btn-cards p-0">{}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    return (
        <>
            <div className="container pt-2">
                <h3>
                    <BiChat color="#7A6BE8" size={35} />{" "}
                    {internalChannel.internalChannelReceivedMessages}
                </h3>
            </div>
            <div className="container mt-1 pt-2 ReceivedMessagesMain">
                {messages.length ? (
                    allReceivedMessages.sort((a, b) => {
                        return b.key - a.key;
                    })
                ) : (
                    <div className="text-center mt-5">
                        <h4>{employeeExperienceTranslate.emptyMessage}</h4>
                    </div>
                )}
            </div>
        </>
    );
};

export default ReceivedMessages;
