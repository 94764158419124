export const genderList = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API}/gender`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
      },
    });
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

export const civilStatesList = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API}/civilstate`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
      },
    });
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

export const contractTypesList = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API}/contracttypes`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
      },
    });
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

export const sendProfile = async (data) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API}/employerinformations`, {
      body: JSON.stringify(data),
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
    });
    return response;
  } catch (err) {
    console.error(err);
  }
};

export const editProfile = async (data, id) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API}/employerinformations/${id}`, {
      body: JSON.stringify(data),
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
      },
    });
    return response;
  } catch (err) {
    console.error(err);
  }
};

export const getProfile = async (id) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API}/employerinformations/${id}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.json();
  } catch (err) {
    console.error(err);
  }
};
