import { useCallback, useEffect, useState } from 'react'
import { HiMiniArrowLongLeft, HiMiniArrowLongRight, HiClipboardDocumentList, HiMiniUser, HiMiniUserGroup, HiFlag } from 'react-icons/hi2'

import Assessment from '../../../API/Assessment'

import { Card } from '../Card/card'
import { HorizontalCard } from '../HorizontalCard/horizontalCard'
import { ModalAssessment } from '../Modal/modalAssessment'
import { ProgressBar } from '../ProgressBar/progressBar'
import { Board } from '../Board/board'
import { NavigationDots } from '../NavigationDots/navigationDots'
import { Pills } from '../Pills/pills'

import { assessment } from '../../../i18n/translatedMessages'
import { assessmentTranslate } from '../../../i18n/Library/assessment'

import { slidesContent } from './slides'
import { useIntl } from 'react-intl'
import Loading from '../../Loading/Loading'

const assets = {
	Organizacional: {
		'small-icon': <HiClipboardDocumentList />,
		icon: <HiClipboardDocumentList size={28} />,
		color: '#008ACD',
	},
	Persona: {
		'small-icon': <HiMiniUser />,
		icon: <HiMiniUser size={28} />,
		color: '#D87706',
	},
	Líder: {
		'small-icon': <HiFlag />,
		icon: <HiFlag size={28} />,
		color: '#00A28B',
	},
	Social: {
		'small-icon': <HiMiniUserGroup />,
		icon: <HiMiniUserGroup size={28} />,
		color: '#6C4FDB',
	},
	'sky-300': '#008ACD',
	'orange-300': '#D87706',
	'green-300': '#00A28B',
	'purple-300': '#6C4FDB',
}

export function ResultScreen() {
	const getLanguage = useIntl()
	const language = getLanguage.locale === 'PT-BR' ? 'pt' : 'es'

	const [loading, setLoading] = useState(false)
	const [slide, setSlide] = useState(1)
	const totalSlides = 4
	const [keywords, setKeywords] = useState(null)
	const [dimensions, setDimensions] = useState(null)
	const [strengthWeakness, setStrengthWeakness] = useState(null)
	const [show, setShow] = useState(true)
	const [selectedDimension, setDimension] = useState(null)

	const handleDimension = dimension => {
		setDimension(dimension)
		setShow(true)
	}

	const loadData = useCallback(async () => {
		const user = JSON.parse(localStorage.getItem('user')).id
		try {
			setLoading(true)
			const [getKeywords, getDimensions, getStrengthWeakness] = await Promise.all([Assessment.getKeywords(user), Assessment.getDimensions(user), Assessment.getStrengthWeakness(user)])

			setKeywords(getKeywords)
			setDimensions(getDimensions)
			setStrengthWeakness(getStrengthWeakness)
		} catch {
		} finally {
			setLoading(false)
		}
	}, [])

	useEffect(() => {
		loadData()
	}, [loadData])

	const handleNext = () => {
		if (slide < totalSlides) {
			setSlide(currentSlide => currentSlide + 1)
		}
	}

	const handlePrevious = () => {
		if (slide > 1) {
			setSlide(currentSlide => currentSlide - 1)
		}
	}

	if (loading && !dimensions && !strengthWeakness && !keywords) return <Loading />

	return (
		<>
			<div className='tw-mb-10 tw-h-[650px]'>
				<div className='tw-bg-[#F1F4F9] tw-p-4 tw-mb-10 tw-rounded-lg tw-mt-4'>
					<h5 className='tw-font-bold'>{slidesContent[slide - 1].title[language]}</h5>
					<p>{slidesContent[slide - 1].description[language]}</p>
				</div>
				{slide === 1 && (
					<div className='tw-flex tw-gap-3 tw-flex-wrap'>
						{keywords?.map(item => {
							return <Pills label={item.description} key={item.description} color={assets[item.color]} />
						})}
					</div>
				)}

				{slide === 2 && (
					<div className='tw-flex tw-justify-center tw-gap-4'>
						{dimensions?.map(item => (
							<Card content={item} key={item.dimension} assets={assets} handleDimension={handleDimension} />
						))}
					</div>
				)}
				{slide === 3 && (
					<div className='tw-grid tw-grid-cols-2'>
						<div className='tw-flex tw-flex-col tw-gap-4 tw-justify-center	'>
							{dimensions?.map(item => (
								<ProgressBar key={item.dimension} title={`${assessmentTranslate[language]['Yo']} ${assessmentTranslate[language][item.dimension]}`} color={assets[item.dimension].color} percentagem={item.score_relative} />
							))}
						</div>
						<div className='tw-flex tw-flex-col tw-gap-4 tw-justify-center'>
							<HorizontalCard assets={assets} content={dimensions[0]} title={assessment.fortresses} list={strengthWeakness.strengths} />
							<HorizontalCard assets={assets} content={dimensions[3]} title={assessment.improvementOpportunities} list={strengthWeakness.weaknesses} />
						</div>
					</div>
				)}
				{slide === 4 && (
					<div className='tw-grid tw-grid-cols-2 tw-gap-4'>
						<Board assest={assets} content={dimensions[0]} title={assessment.fortresses} list={strengthWeakness.strengths} />
						<Board assest={assets} content={dimensions[3]} title={assessment.improvementOpportunities} list={strengthWeakness.weaknesses} />
					</div>
				)}
			</div>

			<div className='tw-w-full tw-flex tw-justify-between tw-items-center tw-mt-3'>
				{slide !== 1 ? (
					<button onClick={handlePrevious} className='tw-rounded-full tw-bg-white tw-text-purple tw-w-[150px] tw-flex tw-justify-center tw-items-center tw-p-3 tw-font-bold tw-gap-3' style={{ border: '1px solid #7A6BE8' }}>
						<HiMiniArrowLongLeft size={28} />
						{assessment.back}
					</button>
				) : (
					<div className='tw-w-[150px]' />
				)}
				<NavigationDots totalSlides={totalSlides} currentSlide={slide} />
				{slide < 4 ? (
					<button onClick={handleNext} className='tw-rounded-full tw-bg-purple tw-text-white tw-w-[150px] tw-flex tw-justify-center tw-items-center tw-p-3 tw-font-bold tw-gap-3' style={{ border: '1px solid #7A6BE8' }}>
						{assessment.next}
						<HiMiniArrowLongRight size={28} />
					</button>
				) : (
					<div className='tw-w-[150px]' />
				)}
			</div>
			{dimensions && <ModalAssessment show={show} setShow={setShow} assets={assets} content={dimensions} selectedDimension={selectedDimension} setNewDimension={setDimension} />}
		</>
	)
}
