import { MdArrowBackIos, MdArrowForwardIos } from 'react-icons/md'
import { Link } from 'react-router-dom'

const HeaderCarouselMandala = ({ actualTitle, prevTitle, nextTitle, routePrev, routeNext, iconPrev, iconNext, iconActual }) => {
	return (
		<>
			<div
				className='header d-flex justify-content-center align-items-center mb-4'
				style={{
					background: 'white',
					borderRadius: '50px',
					padding: '15px 30px 15px 30px',
				}}
			>
				<Link to={routePrev}>
					<button className='item-circle-white'>
						<MdArrowBackIos className='me-1' size={30} />
					</button>
				</Link>

				<div className='left component'>
					<Link to={routePrev}>
						<button className='btn btn-link d-flex justify-content-center align-items-center me-1 ms-3' style={{ textDecoration: 'none', color: 'black' }}>
							<span
								className='rounded-circle d-flex justify-content-center align-items-center me-2'
								style={{
									width: '40px',
									height: '40px',
									borderRadius: '50rem',
									padding: '8px',
									margin: '0 10px',
									background: 'linear-gradient(45deg,#40c2ff 0%, #32d8d8 100%)',
									fill: 'white',
								}}
							>
								{iconPrev}
							</span>
							<h5 className='me-5'>{prevTitle}</h5>
						</button>
					</Link>
				</div>

				<div className='actual component'>
					<div
						className='d-flex justify-content-center align-items-center me-1 ms-3'
						style={{
							textDecoration: 'none',
							color: 'black',
						}}
					>
						<span
							className='rounded-circle d-flex justify-content-center align-items-center me-2'
							style={{
								width: '50px',
								height: '50px',
								borderRadius: '50rem',
								padding: '8px',
								margin: '0 10px',
								background: 'linear-gradient(45deg,#40c2ff 0%, #32d8d8 100%)',
								fill: 'white',
							}}
						>
							{iconActual}
						</span>
						<h1 className='me-5'>{actualTitle}</h1>
					</div>
				</div>

				<div className='right component'>
					<Link to={routeNext}>
						<button className='btn btn-link d-flex justify-content-center align-items-center me-1 ms-3' style={{ textDecoration: 'none', color: 'black' }}>
							<span
								className='rounded-circle d-flex justify-content-center align-items-center me-2'
								style={{
									width: '40px',
									height: '40px',
									borderRadius: '50rem',
									padding: '8px',
									margin: '0 10px',
									background: 'linear-gradient(45deg,#40c2ff 0%, #32d8d8 100%)',
									fill: 'white',
								}}
							>
								{iconNext}
							</span>
							<h5 className='me-5'>{nextTitle}</h5>
						</button>
					</Link>
				</div>

				<Link to={routeNext}>
					<button className='item-circle-white'>
						<MdArrowForwardIos className='me-1' size={30} />
					</button>
				</Link>
			</div>
		</>
	)
}

export default HeaderCarouselMandala
