import { MdRadioButtonUnchecked, MdRadioButtonChecked } from 'react-icons/md'
import './toggle.sass'

const Toggle = ({ answer }) => {

    return (
        <div className="radio-answer">
            {answer ? <MdRadioButtonChecked size={25} style={{ fill: '#5ac2ad' }} /> : <MdRadioButtonUnchecked size={25} />}
        </div>
    )
}
export default Toggle