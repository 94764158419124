import { useEffect, useState } from "react";
import { MdSearch } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { getEmployers } from "../../../../API/Analytics/employers";
import { getAssetsCompany } from "../../../../API/Services/Company/Company";
import { useIntl } from "react-intl";
import { translate } from "../../../../i18n/Library/hierarchicalLevel";

import "./menuColaboradores.sass";

const img = "https://assets.mydna.company/profile-placeholder.png";

const REGEX_ID = /(([\s\S])+[^\d+$])/g;

const MenuColaboradores = () => {
	const getLanguage = useIntl();
	const language = getLanguage.locale === "PT-BR" ? "pt" : "es";

	const [employers, setEmployers] = useState([]);
	const [imgCompany, setImgCompany] = useState([]);
	const [list, setList] = useState(employers);
	const [search, setSearch] = useState("");

	const history = useHistory();
	const pathName = history.location.pathname;
	const EmployeeID = pathName.replace(REGEX_ID, "");

	const handleChange = (event) => {
		setSearch(event.currentTarget.value);
	};

	useEffect(() => {
		const newList = employers.filter((colaborador) => {
			return colaborador.name
				.toLowerCase()
				.includes(search.toLowerCase());
		});
		setList(newList);
	}, [search, employers]);

	useEffect(() => {
		const fetchEmployers = async () => {
			const company = JSON.parse(localStorage.getItem("user"));
			setEmployers(await getEmployers(company.id));
			const imgCAux = await getAssetsCompany(company.id);
			setImgCompany(imgCAux[imgCAux.length - 1]?.url);
		};
		fetchEmployers();
	}, []);

	const handleClick = (id) => {
		history.push(`/app/analytic/perfil/${id}`);
	};

	return (
		<div className="menu-colaboradores">
			<div className="menu-industry">
				<img
					src={
						imgCompany ||
						"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpLotRL0OYMLOnUTpIxP6nouw7U6KZ7HuAFe4729r3W4WaWo9EctFWBMaMnBiIDPfcetQ&usqp=CAU"
					}
					className="img-fluid rounded-circle border p-1"
					alt=""
					onError={(e) => {
						e.target.src =
							"https://assets.mydna.company/b2b-images/no-avatar.png";
					}}
				/>
			</div>
			<div className="search-colaborador">
				<MdSearch size={25} />
				<input type="text" value={search} onChange={handleChange} />
			</div>
			<ul>
				{list.map((colaborador) => (
					<li
						key={colaborador.id}
						className={
							Number(colaborador.id) === Number(EmployeeID)
								? "active"
								: ""
						}
						onClick={() => handleClick(colaborador.id)}
					>
						<img src={colaborador.asset || img} alt="" />
						<div className="text">
							<h6>{colaborador.name}</h6>
							<span>
								{
									translate[language][
										colaborador.level?.PositionN
									]
								}
							</span>
						</div>
					</li>
				))}
			</ul>
		</div>
	);
};

export default MenuColaboradores;
