import "./DropFilter.sass";
import { translate as translateAreas } from "./../../i18n/Library/areasCorrelation";
import { translate as translatePosition } from "./../../i18n/Library/hierarchicalLevel";
import { useIntl } from "react-intl";

const DropFilter = ({ changeAppearence, icon, filterName, setFiltValues, filtValues, list }) => {
  const getLanguage = useIntl();
  const language = getLanguage.locale === "PT-BR" ? "pt" : "es";
  const handleChange = (e) => {
    const { value, checked } = e.target;
    setFiltValues([...filtValues, value]);
    if (!checked) {
      setFiltValues(filtValues.filter((item) => item !== value));
    }
  };
  return (
    <>
      <div className="dropdown FiltersMain my-3">
        <button
          className={`btn rounded-pill w-100 px-1 dropdown-toggle`}
          type="button"
          id={"dropdownFilter_" + filterName?.toLowerCase()}
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {icon} {filterName}
        </button>
        <ul
          className="dropdown-menu"
          aria-labelledby={"dropdownFilter_" + filterName?.toLowerCase()}
        >
          {list?.map((item, key) => {
            return (
              <li className="ps-2" key={key}>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={
                      item?.name ||
                      translateAreas[language][item?.profileArea] ||
                      translatePosition[language][item?.PositionN]
                    }
                    id={item.id + "_" + filterName?.toLowerCase()}
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={item.id + "_" + filterName?.toLowerCase()}
                  >
                    {item?.name ||
                      translateAreas[language][item?.profileArea] ||
                      translatePosition[language][item?.PositionN]}
                  </label>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default DropFilter;
