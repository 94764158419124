// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wheel-volver {
  position: absolute;
  top: 100px;
  right: 10px;
  border: none;
  border-radius: 50rem;
  background: rgb(123, 107, 228);
  padding: 5px 20px;
  color: white;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}
.wheel-volver:hover {
  background: rgb(147, 134, 233);
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/Views/Packages/package.sass"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EACA,oBAAA;EACA,8BAAA;EACA,iBAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,eAAA;AACF;AACE;EACE,8BAAA;EACA,YAAA;AACJ","sourcesContent":[".wheel-volver\n  position: absolute\n  top: 100px\n  right: 10px\n  border: none\n  border-radius: 50rem\n  background: rgb(123, 107, 228)\n  padding: 5px 20px\n  color: white\n  font-size: 18px\n  font-weight: 500\n  cursor: pointer\n\n  &:hover\n    background: rgb(147, 134, 233)\n    color: white\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
