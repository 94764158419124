// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-dropdown {
  position: relative;
  width: 300px;
  margin: 0 15px;
  padding: 0;
}
.input-dropdown .auto-complete {
  border: none;
  border-bottom: 2px solid #6d68aa;
  border-radius: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
  background: transparent;
}
.input-dropdown ul {
  list-style: none;
  position: absolute;
  top: 38px;
  left: 0;
  border: 2px solid rgba(0, 0, 0, 0.05);
  background: #fdfdfd;
  width: 100%;
  border-radius: 5px;
  padding: 0;
}
.input-dropdown ul li {
  padding: 8px 12px;
  cursor: pointer;
}
.input-dropdown ul li:hover {
  background: #F0F0F0;
}`, "",{"version":3,"sources":["webpack://./src/Views/CareerProjection/inputDropdown.sass"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,YAAA;EACA,cAAA;EACA,UAAA;AACJ;AACI;EACI,YAAA;EACA,gCAAA;EACA,gBAAA;EACA,WAAA;EACA,SAAA;EACA,UAAA;EACA,kBAAA;EACA,uBAAA;AACR;AACI;EACI,gBAAA;EACA,kBAAA;EACA,SAAA;EACA,OAAA;EACA,qCAAA;EACA,mBAAA;EACA,WAAA;EACA,kBAAA;EACA,UAAA;AACR;AACQ;EACI,iBAAA;EACA,eAAA;AACZ;AACY;EACI,mBAAA;AAChB","sourcesContent":[".input-dropdown\n    position: relative\n    width: 300px\n    margin: 0 15px\n    padding: 0\n\n    .auto-complete\n        border: none\n        border-bottom: 2px solid #6d68aa\n        border-radius: 0\n        width: 100%\n        margin: 0\n        padding: 0\n        text-align: center\n        background: transparent\n\n    ul\n        list-style: none\n        position: absolute\n        top: 38px\n        left: 0\n        border: 2px solid rgba(0,0,0,.05)\n        background: #fdfdfd\n        width: 100%\n        border-radius: 5px\n        padding: 0\n\n        li\n            padding: 8px 12px\n            cursor: pointer\n\n            &:hover\n                background: #F0F0F0\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
