import ServicesContextProvider from '../../../../Store/ServicesContext'

// Components
import Posiciones from './Views/Posiciones'

const Index = ({ location }) => {
	return (
		<ServicesContextProvider>
			<>
				<div className='detail-page pt-2'>
					<div className='row'>
						<div className='col-12 col-md-12 d-flex flex-column align-items-center'>
							<Posiciones location={location} />
						</div>
					</div>
				</div>
			</>
		</ServicesContextProvider>
	)
}
export default Index
