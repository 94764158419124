// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-motivaciones .title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.content-motivaciones .title .icon {
  background: linear-gradient(45deg, #40c2ff 0%, #32d8d8 100%);
  width: 50px;
  height: 50px;
  border-radius: 50rem;
  padding: 8px;
  margin-right: 10px;
}
.content-motivaciones .title .icon svg {
  fill: white;
  width: 100%;
}
.content-motivaciones .motivationsAnswerPositive .progress-bar {
  background-color: #41C8FF;
  color: black;
}
.content-motivaciones .motivationsAnswerNegative .progress-bar {
  background-color: #9391A1;
}
.content-motivaciones canvas {
  margin-left: -70px;
}
.content-motivaciones .motivationsChartHeight {
  height: 300px;
}`, "",{"version":3,"sources":["webpack://./src/Views/Analytics/motivaciones.sass"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,mBAAA;EACA,2BAAA;AAAR;AAEQ;EACI,4DAAA;EACA,WAAA;EACA,YAAA;EACA,oBAAA;EACA,YAAA;EACA,kBAAA;AAAZ;AAEY;EACI,WAAA;EACA,WAAA;AAAhB;AAEQ;EACI,yBAAA;EACA,YAAA;AAAZ;AAEQ;EACI,yBAAA;AAAZ;AACI;EACI,kBAAA;AACR;AAAI;EACI,aAAA;AAER","sourcesContent":[".content-motivaciones\n    & .title\n        display: flex\n        align-items: center\n        justify-content: flex-start\n\n        & .icon\n            background: linear-gradient(45deg,#40c2ff 0%, #32d8d8 100%)\n            width: 50px\n            height: 50px\n            border-radius: 50rem\n            padding: 8px\n            margin-right: 10px\n\n            & svg\n                fill: white\n                width: 100%\n    .motivationsAnswerPositive\n        .progress-bar\n            background-color: #41C8FF\n            color: black\n    .motivationsAnswerNegative\n        .progress-bar\n            background-color: #9391A1\n    canvas\n        margin-left: -70px\n    .motivationsChartHeight\n        height: 300px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
