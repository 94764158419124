import { Button, Modal } from 'react-bootstrap';

import './ModalApplyVacancy.sass';

const ModalApplyVacancy = ({ show, handleClose, data, handleSend }) => {
  return (
    <>
      <Modal className="ModalApplyVacancyMain" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Postular Vacante</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">Desea solicitar el puesto vacante {data?.name}?</div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-yes me-auto" variant="" onClick={() => handleSend(data?.id)}>
            Sí
          </Button>
          <Button className="btn-no" variant="" onClick={handleClose}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalApplyVacancy;
