import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BsArrowLeftCircle } from "react-icons/bs";
import DragNDrop from "./Components/DnD";

import "./styles.css"

const defaultData = [
  {
    title: "1ª Fase - Pré-entrevista",
    items: [
      {
        candidato: "Marcos",
        profileImg:
          "https://assets.mydna.company/profile-placeholder.png",
      },
      {
        candidato: "Elle",
        profileImg:
          "https://assets.mydna.company/profile-placeholder.png",
      },
      {
        candidato: "Andy",
        profileImg:
          "https://assets.mydna.company/profile-placeholder.png",
      },
    ],
  },
  {
    title: "2ª Fase - Teste Cultura",
    items: [
      {
        candidato: "Juan",
        profileImg:
          "https://assets.mydna.company/profile-placeholder.png",
      },
      {
        candidato: "Annie",
        profileImg:
          "https://assets.mydna.company/profile-placeholder.png",
      },
    ],
  },
  {
    title: "3ª Fase - Teste Myco",
    items: [
      {
        candidato: "Pedro",
        profileImg:
          "https://assets.mydna.company/profile-placeholder.png",
      },
      {
        candidato: "Silas",
        profileImg:
          "https://assets.mydna.company/profile-placeholder.png",
      },
    ],
  },
  {
    title: "4ª Fase - Test Inglês",
    items: [
      {
        candidato: "Ellie",
        profileImg:
          "https://assets.mydna.company/profile-placeholder.png",
      },
      {
        candidato: "Jonathan",
        profileImg:
          "https://assets.mydna.company/profile-placeholder.png",
      },
    ],
  },
  {
    title: "5ª Fase - Entrevista",
    items: [
      {
        candidato: "Simon",
        profileImg:
          "https://assets.mydna.company/profile-placeholder.png",
      },
      {
        candidato: "Erik",
        profileImg:
          "https://assets.mydna.company/profile-placeholder.png",
      },
      {
        candidato: "Savio",
        profileImg:
          "https://assets.mydna.company/profile-placeholder.png",
      },
      {
        candidato: "Eddie",
        profileImg:
          "https://assets.mydna.company/profile-placeholder.png",
      },
    ],
  },
];
function RecruitmentProcess() {
  const [data, setData] = useState();

  useEffect(() => {
    setData(defaultData);
  }, []);

  return (
    <div className="container window-vacante-process shadow mt-3">
      <div className="row header-bg-grey color-grey ps-4 pt-1 pb-1 align-items-center">
        <div className="col-md-11">
          <Link className="color-grey" to="/ras/recruitmentandselection">
            <BsArrowLeftCircle size={40} />
          </Link>
          <span className="vacante-title-in-window-process ps-3 mt-1">
            Analista de Marketing
          </span>
        </div>
        <div className="col-md-1 text-end p-0">
          <img
            alt="..."
            className="rounded img-rys-process"
            src="https://assets.mydna.company/industry2.svg"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 pt-5">
          <DragNDrop data={data} />
        </div>
      </div>
    </div>
  );
}
export default RecruitmentProcess;
