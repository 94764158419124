import { FormacionIcon } from '../../../../../Icons/CollaboratorsIcons'
import { Link } from 'react-router-dom'
import { listTreinaments } from './listTreinaments'
import { trainningTranslate } from '../../../../../i18n/translatedMessages'

import './treinaments.sass'

const Cards = ({ treinament }) =>
    <Link to={treinament.link}>
        <div className="card-treinaments">
            <div className="row h-100">
                <div className="col-3 col-img">
                    <img src={treinament.icon} alt={treinament.title} />
                </div>
                <div className="col-9 col-text">
                    <h6>{treinament.title}</h6>
                    <p>{treinament.text}</p>
                </div>
            </div>
        </div>
    </Link>


const Treinaments = () => {
    return (
        <div className="row treinaments-content">
            <div className="col offset-1">
                <div className="row">
                    <div className="col d-flex mb-4">
                        <div className="icon">
                            <FormacionIcon />
                        </div>
                        <h1>{trainningTranslate.trainningTitle}</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="d-flex flex-wrap">
                            {listTreinaments.map(treinament =>
                                <Cards key={treinament.id} treinament={treinament} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Treinaments