export const questionsTranslate = {
	a01: {
		es: 'Busco comprender a los demás reconociendo sus emociones.',
		pt: 'Busco compreender os outros reconhecendo suas emoções.'
	},
	a02: {
		es: 'Tiendo a basarme en suposiciones, sin hacer consultas para confirmar datos.',
		pt: 'Tendo a basear-me em suposições, sem fazer consultas para confirmar dados.'
	},
	a03: {
		es: 'Busco conocer las habilidades y conocimientos de mis compañeros de trabajo.',
		pt: 'Busco conhecer as habilidades e conhecimentos dos meus colegas de trabalho.'
	},
	a04: {
		es: 'Suelo mostrarme ansioso frente a una situacion de cambio.',
		pt: 'Costumo mostrar-me ansioso diante de uma situação de mudança.'
	},
	a05: {
		es: 'Suelo adelantarme a los hechos e implementar planes para poder concretar mis proyectos.',
		pt: 'Costumo antecipar os fatos e implementar planos para concretizar meus projetos.'
	},
	a06: {
		es: 'No tengo problemas para asumir riesgos controlados en la toma de decisiones.',
		pt: 'Não tenho problemas para assumir riscos controlados na tomada de decisões.'
	},
	a07: {
		es: 'Me motiva resolver los obstáculos que surgen.',
		pt: 'Sou motivado a resolver os obstáculos que surgem.'
	},
	a08: {
		es: 'Entiendo la negociación como un proceso en el que ambas partes deben ganar',
		pt: 'Entendo a negociação como um processo no qual ambas as partes devem ganhar.'
	},
	a09: {
		es: ' Me motiva el logro de objetivos complejos y tiendo a sentirme satisfecho con mi desempeño',
		pt: 'Sou motivado pela conquista de objetivos complexos e costumo sentir-me satisfeito com meu desempenho.'
	},
	a10: {
		es: 'Me cuesta relacionarme y entrar en confianza con personas nuevas.',
		pt: 'Tenho dificuldade em relacionar-me e ganhar confiança com pessoas novas.'
	},
	a11: {
		es: 'Mantengo contacto constante con clientes internos y externos para estar al tanto de sus inquietudes.',
		pt: 'Mantenho contato constante com clientes internos e externos para estar ciente de suas preocupações.'
	},
	a12: {
		es: 'Tiendo a relacionarme con los demás de modo abierto, sin importar su religión, nacionalidad u orientación sexual.',
		pt: 'Tenho tendência a relacionar-me com os outros de forma aberta, independentemente de sua religião, nacionalidade ou orientação sexual.'
	},
	a13: {
		es: 'Estoy atento al equilibrio entre mi vida laboral y personal.',
		pt: 'Estou atento ao equilíbrio entre minha vida laboral e pessoal.'
	},
	a14: {
		es: 'Fomento un clima de armonía y respeto en mi grupo buscando que todos den lo mejor de sí.',
		pt: 'Promovo um clima de harmonia e respeito em meu grupo, buscando que todos deem o melhor de si.'
	},
	a15: {
		es: 'Me cuesta manejar mi agenda y suelo sentirme superado para establecer prioridades y urgencias ',
		pt: 'Tenho dificuldade em gerenciar minha agenda e costumo sentir-me sobrecarregado para estabelecer prioridades e urgências.'
	},
	a16: {
		es: 'Reflexiono sobre mí mismo: me pregunto qué deseo, quién soy.',
		pt: 'Reflexiono sobre mim mesmo: questiono o que desejo, quem sou.'
	},
	a17: {
		es: 'Encaro nuevas situaciones con soluciones creativas y originales.',
		pt: 'Enfrento novas situações com soluções criativas e originais.'
	},
	a18: {
		es: 'Busco constantemente superarme y aprender.',
		pt: 'Busco constantemente superar-me e aprender.'
	},
	a20: {
		es: 'Tengo la capacidad de gestionar con un alto nivel de influencia involucrando a otros. ',
		pt: 'Tenho a capacidade de gerir com um alto nível de influência, envolvendo outros.'
	},
	a21: {
		es: ' Los cambios del entorno me suelen alterar y estresar.',
		pt: 'As mudanças no ambiente costumam me perturbar e estressar.'
	},
	b01: {
		es: 'Promuevo que en una conversacion los demás se puedan expresar con libertad y confianza.',
		pt: 'Promovo que, em uma conversa, os outros possam se expressar com liberdade e confiança.'
	},
	b02: {
		es: ' Suelo escuchar con atención antes de dar mi opinión',
		pt: 'Costumo ouvir com atenção antes de dar minha opinião.'
	},
	b03: {
		es: 'Soy receptivo ante distintos puntos de vista y los integro para mejorar mi desempeño.',
		pt: 'Sou receptivo a diferentes pontos de vista e os integro para melhorar meu desempenho.'
	},
	b04: {
		es: 'Pienso y reacciono con rapidez al adaptarme a una nueva situación.',
		pt: 'Penso e reajo rapidamente ao me adaptar a uma nova situação.'
	},
	b05: {
		es: 'Identifico cómo los procesos del equipo aportan al logro de los objetivos de la empresa.',
		pt: 'Identifico como os processos da equipe contribuem para a conquista dos objetivos da empresa.'
	},
	b06: {
		es: 'Efectúo un análisis racional y emocional de distintas alternativas antes de tomar una decisión.',
		pt: 'Faço uma análise racional e emocional de diferentes alternativas antes de tomar uma decisão.'
	},
	b07: {
		es: 'Para mí los problemas son una "oportunidad".',
		pt: 'Para mim, os problemas são uma "oportunidade".'
	},
	b08: {
		es: 'Tiendo a imponer mis condiciones en una negociación.',
		pt: 'Tenho tendência a impor minhas condições em uma negociação.'
	},
	b09: {
		es: 'Los objetivos de la organización me resultan muy exigentes, por lo que elijo focalizarme en los propios',
		pt: 'Os objetivos da organização me parecem muito exigentes, então escolho focar nos meus próprios.'
	},
	b10: {
		es: 'Me resulta sencillo ser empático con mis pares, clientes y colaboradores.',
		pt: 'Acho fácil ser empático com meus pares, clientes e colaboradores.'
	},
	b11: {
		es: 'Indago formal o informalmente, para evaluar la calidad de mis servicios ',
		pt: 'Investigo formal ou informalmente para avaliar a qualidade dos meus serviços.'
	},
	b12: {
		es: 'Me cuesta respetar y aceptar las diferencias entre personas.',
		pt: 'Tenho dificuldade em respeitar e aceitar as diferenças entre as pessoas.'
	},
	b13: {
		es: 'Considero que mi trabajo está en sintonía con mis valores y objetivos de vida.',
		pt: 'Considero que meu trabalho está em sintonia com meus valores e objetivos de vida.'
	},
	b14: {
		es: 'Logro estimular a los demás en el logro de objetivos personales y organizacionales.',
		pt: 'Consigo estimular os outros na conquista de objetivos pessoais e organizacionais.'
	},
	b15: {
		es: 'Realizo una adecuada planficación de tiempos y recursos.',
		pt: 'Realizo uma adequada planificação de tempos e recursos.'
	},
	b16: {
		es: 'Intento conocer la mejor versión de mí mismo, integrando las distintas partes de mi personalidad.',
		pt: 'Tento conhecer a melhor versão de mim mesmo, integrando as diferentes partes da minha personalidade.'
	},
	b17: {
		es: 'Busco inspirarme a través de nuevos estímulos para lograr respuestas innovadoras.',
		pt: 'Busco me inspirar através de novos estímulos para encontrar respostas inovadoras.'
	},
	b18: {
		es: 'Identifico a consciencia mis oportunidades de mejora para poder  cambiarlo.  ',
		pt: 'Identifico conscientemente minhas oportunidades de melhoria para poder mudá-las.'
	},
	b20: {
		es: 'Transmito una visión inspiradora, mostrando el camino a los demás',
		pt: 'Transmito uma visão inspiradora, mostrando o caminho aos outros.'
	},
	b21: {
		es: 'Identifico y flexibilizo mis propias resistencias.',
		pt: 'Identifico e flexibilizo minhas próprias resistências.'
	},
	c01: {
		es: 'Suelo preguntar para asegurarme que entiendo lo que estoy escuchando.',
		pt: 'Costumo perguntar para me certificar de que entendo o que estou ouvindo.'
	},
	c02: {
		es: 'Suelo utilizar las herramientas necesarias para comunicar acorde a la situación',
		pt: 'Costumo utilizar as ferramentas necessárias para comunicar de acordo com a situação.'
	},
	c03: {
		es: 'Me adapto a las necesidades y requerimientos del equipo para el logro de metas comunes.',
		pt: 'Adapto-me às necessidades e requisitos da equipe para alcançar metas comuns.'
	},
	c04: {
		es: 'Me considero abierto a aprender cosas nuevas.',
		pt: 'Considero-me aberto a aprender coisas novas.'
	},
	c05: {
		es: 'Tiendo a dar seguimiento a los planes de acción vinculados a la organización.',
		pt: 'Tenho a tendência de acompanhar os planos de ação vinculados à organização.'
	},
	c06: {
		es: 'Siento que mis decisiones agregan valor a la organización.',
		pt: 'Sinto que minhas decisões agregam valor à organização.'
	},
	c07: {
		es: 'Logro salir fortalecido de los fracasos y obstáculos.',
		pt: 'Consigo sair fortalecido dos fracassos e obstáculos.'
	},
	c08: {
		es: 'Recopilo y sistematizo información que resulta útil para una negociación',
		pt: 'Reúno e sistematizo informações que são úteis para uma negociação.'
	},
	c09: {
		es: 'Suelo chequear los procesos y así evaluar los avances de las tareas.',
		pt: 'Costumo verificar os processos e assim avaliar o progresso das tarefas.'
	},
	c10: {
		es: 'Busco fomentar redes de trabajo productivas y positivas ',
		pt: 'Busco promover redes de trabalho produtivas e positivas.'
	},
	c11: {
		es: 'Busco sistemáticamente ofrecer apoyo eficiente a mis clientes',
		pt: 'Busco sistematicamente oferecer apoio eficiente aos meus clientes.'
	},
	c12: {
		es: 'Incorporo la diversidad a los equipos porque enriquece los puntos de vista y el trabajo.',
		pt: 'Incorporo a diversidade às equipes porque enriquece os pontos de vista e o trabalho.'
	},
	c13: {
		es: 'Propicio un ambiente de confianza, empatía y equilibrio en mis entornos sociales.',
		pt: 'Promovo um ambiente de confiança, empatia e equilíbrio em meus ambientes sociais.'
	},
	c14: {
		es: 'Inspiro a los demás con mi ejemplo',
		pt: 'Inspiro os outros com meu exemplo.'
	},
	c15: {
		es: 'Controlo los avances en proyectos y procesos a fin de realizar ajustes a tiempo.',
		pt: 'Controlo os avanços em projetos e processos para realizar ajustes a tempo.'
	},
	c16: {
		es: 'Estoy atento a mis fortalezas y oportunidades de mejora.',
		pt: 'Estou atento às minhas fortalezas e oportunidades de melhoria.'
	},
	c17: {
		es: 'Amplío mi visión con nuevas miradas y estimulos externos.',
		pt: 'Amplio minha visão com novas perspectivas e estímulos externos.'
	},
	c18: {
		es: 'Intento reconocer lo que no sé y asumo el desafío de aprender lo nuevo.',
		pt: 'Tento reconhecer o que não sei e assumo o desafio de aprender algo novo.'
	},
	c20: {
		es: 'Mi visión me permite estar un paso adelante preparando el camino para que otros puedan transitarlo.',
		pt: 'Minha visão me permite estar um passo à frente, preparando o caminho para que outros possam segui-lo.'
	},
	c21: {
		es: 'Influencio activamente a otros para sumarse a los procesos cambio.',
		pt: 'Influencio ativamente os outros para se unirem aos processos de mudança.'
	},
	d01: {
		es: 'Tiendo a aleccionar a través de monólogos.',
		pt: 'Tenho tendência a ensinar através de monólogos.'
	},
	d02: {
		es: 'Pido feedback para verificar que mi mensaje se entendió',
		pt: 'Peço feedback para verificar se minha mensagem foi entendida.'
	},
	d03: {
		es: 'Busco fortalecer los vínculos de los miembros del equipo.',
		pt: 'Busco fortalecer os vínculos entre os membros da equipe.'
	},
	d04: {
		es: 'Me resulta difícil reemplazar planes y procedimientos habituales por otros nuevos.',
		pt: 'Tenho dificuldade em substituir planos e procedimentos habituais por outros novos.'
	},
	d05: {
		es: 'Me cuesta anticipar tendencias o factores que puedan influir en mi proyecto.',
		pt: 'Tenho dificuldade em antecipar tendências ou fatores que possam influenciar meu projeto.'
	},
	d06: {
		es: 'Antepongo en mis decisiones las políticas y normas de la organización.',
		pt: 'Dou prioridade em minhas decisões às políticas e normas da organização.'
	},
	d07: {
		es: 'Puedo identificar lo positivo de un resultado que no cumpla con mis expectativas',
		pt: 'Consigo identificar o lado positivo de um resultado que não atenda às minhas expectativas.'
	},
	d08: {
		es: 'Tengo facilidad para persuadir a otras personas y llegar a un acuerdo.',
		pt: 'Tenho facilidade para persuadir outras pessoas e chegar a um acordo.'
	},
	d09: {
		es: 'Soy persistente en la ejecución de acciones para lograr una meta',
		pt: 'Sou persistente na execução de ações para alcançar uma meta.'
	},
	d10: {
		es: 'Utilizo la escucha activa ante un conflicto en mi entorno.',
		pt: 'Utilizo a escuta ativa diante de um conflito em meu ambiente.'
	},
	d11: {
		es: 'Aplico benchmarking (exploración de competidores) para mantener actualizados mis servicios',
		pt: 'Aplico benchmarking (exploração de concorrentes) para manter meus serviços atualizados.'
	},
	d12: {
		es: 'Trabajo sobre mis creencias y prejuicios,  descartando la idea de una única verdad.',
		pt: 'Trabalho sobre minhas crenças e preconceitos, descartando a ideia de uma única verdade.'
	},
	d13: {
		es: 'Cuido mi salud física y mental: duermo y me alimento bien, hago ejercicio físico, doy lugar a mis relaciones socio-afectivas, fomento tener hobbies,etc..',
		pt: 'Cuido da minha saúde física e mental: durmo e me alimento bem, faço exercícios físicos, dou espaço para minhas relações socioafetivas, incentivo a ter hobbies, etc.'
	},
	d14: {
		es: 'Me interesa conocer y apoyar a cada miembro de mi grupo.',
		pt: 'Tenho interesse em conhecer e apoiar cada membro do meu grupo.'
	},
	d15: {
		es: 'Busco ser productivo sabiendo en qué momento del día soy más eficaz.',
		pt: 'Busco ser produtivo sabendo em que momento do dia sou mais eficaz.'
	},
	d16: {
		es: 'Hago planes realistas para el desarrollo de mí mismo, siendo consciente del tiempo, recursos y los cambios del entorno',
		pt: 'Faço planos realistas para o meu desenvolvimento, estando ciente do tempo, recursos e mudanças no ambiente.'
	},
	d17: {
		es: 'Reviso con frecuencia procesos y procedimientos.',
		pt: 'Reviso com frequência processos e procedimentos.'
	},
	d18: {
		es: 'Soy capaz de desaprender para volver a aprender.',
		pt: 'Sou capaz de desaprender para reaprender.'
	},
	d20: {
		es: 'Cuento con capacidad de generar alternativas frente a novedades que modifican el entorno.',
		pt: 'Tenho capacidade de gerar alternativas diante de novidades que alteram o ambiente.'
	},
	d21: {
		es: 'Mi capacidad de adaptación tiende a ser rápida y efectiva.',
		pt: 'Minha capacidade de adaptação tende a ser rápida e eficaz.'
	},
}