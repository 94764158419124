import translate from './translate'

export const UnderConstruction = {
	titleConstruction: translate('titleConstruction'),
	textConstruction: translate('textConstruction'),
}

export const cardContentTranslate = {
	customerRating: translate('customerRating'),
}

export const componentsTranslate = {
	searchByName: translate('searchByName'),
}

export const performancePotentialTranslate = {
	performanceTitle: translate('performanceTitle'),
	profile1: translate('profile1'),
	profile2: translate('profile2'),
	profile3: translate('profile3'),
	profile4: translate('profile4'),
	profile5: translate('profile5'),
	profile6: translate('profile6'),
	profile7: translate('profile7'),
	profile8: translate('profile8'),
	profile9: translate('profile9'),
	performanceTitle: translate('performanceTitle'),
	potentialTitle: translate('potentialTitle'),
	performanceAvaliationTitle: translate('performanceAvaliationTitle'),
	potentialAvaliationTitle: translate('potentialAvaliationTitle'),
	weightTitle: translate('weightTitle'),
	reachedTitle: translate('reachedTitle'),
	scoreTitle: translate('scoreTitle'),
	emptyValuesPP: translate('emptyValuesPP'),
	observationsTitle: translate('observationsTitle'),
	phObservations: translate('phObservations'),
	pillText1: translate('pillText1'),
	pillText2: translate('pillText2'),
	pillText3: translate('pillText3'),
	deleteCriterionText: translate('deleteCriterionText'),
	nineBoxDesc1: translate('nineBoxDesc1'),
	nineBoxDesc2: translate('nineBoxDesc2'),
	nineBoxDesc3: translate('nineBoxDesc3'),
	nineBoxDesc4: translate('nineBoxDesc4'),
	nineBoxDesc5: translate('nineBoxDesc5'),
	nineBoxDesc6: translate('nineBoxDesc6'),
	nineBoxDesc7: translate('nineBoxDesc7'),
	nineBoxDesc8: translate('nineBoxDesc8'),
	nineBoxDesc9: translate('nineBoxDesc9'),
	empty9Box: translate('empty9Box'),
}

export const salaryEvaluationGraphTranslate = {
	salaryTitle9Box: translate('salaryTitle9Box'),
	performanceTitle9Box: translate('performanceTitle9Box'),
	potentialTitle9Box: translate('potentialTitle9Box'),
	salaryBandPerformance: translate('salaryBandPerformance'),
	salaryBandPortential: translate('salaryBandPortential'),
	nineBoxSalaryPotential1: translate('nineBoxSalaryPotential1'),
	nineBoxSalaryPotential2: translate('nineBoxSalaryPotential2'),
	nineBoxSalaryPotential3: translate('nineBoxSalaryPotential3'),
	nineBoxSalaryPotential4: translate('nineBoxSalaryPotential4'),
	nineBoxSalaryPotential5: translate('nineBoxSalaryPotential5'),
	nineBoxSalaryPotential6: translate('nineBoxSalaryPotential6'),
	nineBoxSalaryPotential7: translate('nineBoxSalaryPotential7'),
	nineBoxSalaryPotential8: translate('nineBoxSalaryPotential8'),
	nineBoxSalaryPotential9: translate('nineBoxSalaryPotential9'),
	nineBoxSalaryTitlePotential1: translate('nineBoxSalaryTitlePotential1'),
	nineBoxSalaryTitlePotential2: translate('nineBoxSalaryTitlePotential2'),
	nineBoxSalaryTitlePotential3: translate('nineBoxSalaryTitlePotential3'),
	nineBoxSalaryTitlePotential4: translate('nineBoxSalaryTitlePotential4'),
	nineBoxSalaryTitlePotential5: translate('nineBoxSalaryTitlePotential5'),
	nineBoxSalaryTitlePotential6: translate('nineBoxSalaryTitlePotential6'),
	nineBoxSalaryTitlePotential7: translate('nineBoxSalaryTitlePotential7'),
	nineBoxSalaryTitlePotential8: translate('nineBoxSalaryTitlePotential8'),
	nineBoxSalaryTitlePotential9: translate('nineBoxSalaryTitlePotential9'),
	nineBoxSalaryPerformance1: translate('nineBoxSalaryPerformance1'),
	nineBoxSalaryPerformance2: translate('nineBoxSalaryPerformance2'),
	nineBoxSalaryPerformance3: translate('nineBoxSalaryPerformance3'),
	nineBoxSalaryPerformance4: translate('nineBoxSalaryPerformance4'),
	nineBoxSalaryPerformance5: translate('nineBoxSalaryPerformance5'),
	nineBoxSalaryPerformance6: translate('nineBoxSalaryPerformance6'),
	nineBoxSalaryPerformance7: translate('nineBoxSalaryPerformance7'),
	nineBoxSalaryPerformance8: translate('nineBoxSalaryPerformance8'),
	nineBoxSalaryPerformance9: translate('nineBoxSalaryPerformance9'),
	nineBoxSalaryTitlePerformance1: translate('nineBoxSalaryTitlePerformance1'),
	nineBoxSalaryTitlePerformance2: translate('nineBoxSalaryTitlePerformance2'),
	nineBoxSalaryTitlePerformance3: translate('nineBoxSalaryTitlePerformance3'),
	nineBoxSalaryTitlePerformance4: translate('nineBoxSalaryTitlePerformance4'),
	nineBoxSalaryTitlePerformance5: translate('nineBoxSalaryTitlePerformance5'),
	nineBoxSalaryTitlePerformance6: translate('nineBoxSalaryTitlePerformance6'),
	nineBoxSalaryTitlePerformance7: translate('nineBoxSalaryTitlePerformance7'),
	nineBoxSalaryTitlePerformance8: translate('nineBoxSalaryTitlePerformance8'),
	nineBoxSalaryTitlePerformance9: translate('nineBoxSalaryTitlePerformance9'),
	nineBoxSalaryPotentialNullText: translate('nineBoxSalaryPotentialNullText'),
	nineBoxSalaryPotentialDescText: translate('nineBoxSalaryPotentialDescText'),
	nineBoxSalaryPerformanceNullText: translate('nineBoxSalaryPerformanceNullText'),
	nineBoxSalaryPerformanceDescText: translate('nineBoxSalaryPerformanceDescText'),
	bandTitleText: translate('bandTitleText'),
	bandNullText1: translate('bandNullText1'),
	bandNullText2: translate('bandNullText2'),
	bandNullText3: translate('bandNullText3'),
	bandText1: translate('bandText1'),
	bandText2: translate('bandText2'),
	bandText3: translate('bandText3'),
	bandText4: translate('bandText4'),
	bandText5: translate('bandText5'),
	bandText6: translate('bandText6'),
	bandExampleTitle: translate('bandExampleTitle'),
	bandExampleText: translate('bandExampleText'),
	bandBtnSave: translate('bandBtnSave'),
	bandValueNull: translate('bandValueNull'),
	bandOption1: translate('bandOption1'),
	bandOption2: translate('bandOption2'),
	bandOption3: translate('bandOption3'),
}

export const futureCVTranslate = {
	showMore: translate('showMore'),
	showReview: translate('showReview'),
	downloadFutureCV: translate('downloadFutureCV'),
	inTextFutureCV: translate('inTextFutureCV'),
	yearsTextFutureCV: translate('yearsTextFutureCV'),
	experienceTextFutureCV: translate('experienceTextFutureCV'),
	careerYearsTextFutureCV: translate('careerYearsTextFutureCV'),
	monthsTextFutureCV: translate('monthsTextFutureCV'),
	employeeYearsExpFutureCV: translate('employeeYearsExpFutureCV'),
	employeeMonthsExpFutureCV: translate('employeeMonthsExpFutureCV'),
	formationTextFutureCV: translate('formationTextFutureCV'),
	purposeTextFutureCV: translate('purposeTextFutureCV'),
	cultureTextFutureCV: translate('cultureTextFutureCV'),
	comercialTitleFutureCV: translate('comercialTitleFutureCV'),
	comercialTextFutureCV: translate('comercialTextFutureCV'),
	disruptiveTitleFutureCV: translate('disruptiveTitleFutureCV'),
	disruptiveTextFutureCV: translate('disruptiveTextFutureCV'),
	traditionalTitleFutureCV: translate('traditionalTitleFutureCV'),
	traditionalTextFutureCV: translate('traditionalTextFutureCV'),
	collaborativeTitleFutureCV: translate('collaborativeTitleFutureCV'),
	collaborativeTextFutureCV: translate('collaborativeTextFutureCV'),
	performanceTextFutureCV: translate('performanceTextFutureCV'),
	resultTextFutureCV: translate('resultTextFutureCV'),
	abilitiesTextFutureCV: translate('abilitiesTextFutureCV'),
	motivationsTextFutureCV: translate('motivationsTextFutureCV'),
	salaryTextFutureCV: translate('salaryTextFutureCV'),
	collabTextFutureCV: translate('collabTextFutureCV'),
	emptyValueTextFutureCV: translate('emptyValueTextFutureCV'),
	comparativeTextFutureCV: translate('comparativeTextFutureCV'),
	riskTitleFutureCV: translate('riskTitleFutureCV'),
	riskHighTextFutureCV: translate('riskHighTextFutureCV'),
	riskMediumTextFutureCV: translate('riskMediumTextFutureCV'),
	riskLowTextFutureCV: translate('riskLowTextFutureCV'),
	careerPlanningTextFutureCV: translate('careerPlanningTextFutureCV'),
	actualLevelTextFutureCV: translate('actualLevelTextFutureCV'),
	nextLevelTextFutureCV: translate('nextLevelTextFutureCV'),
	observationsTextFutureCV: translate('observationsTextFutureCV'),
	editObservationsTextFutureCV: translate('editObservationsTextFutureCV'),
}

export const modalAssignTranslate = {
	btnClose: translate('closeButton'),
	btnSave: translate('saveButton'),
	confirmDelete: translate('confirmDelete'),
	deleteButton: translate('deleteButton'),
	cancelButton: translate('cancelButton'),
	yesText: translate('yesText'),
	noText: translate('noText'),
	clickHere: translate('clickHere'),
	successAlert: translate('successAlert'),
	errorAlert: translate('errorAlert'),
	contactAlert: translate('contactAlert'),
}

export const addEmployeeTranslate = {
	assignCollaborator: translate('assignCollaborator'),
	collaboratorTitle: translate('collaboratorTitle'),
	nullEmployersRegister: translate('nullEmployersRegister'),
	closeBtn: translate('closeButton'),
	saveBtn: translate('saveButton'),
	collaboratorStatusTitle: translate('collaboratorStatusTitle'),
	collaboratorStatusText: translate('collaboratorStatusText'),
	collaboratorStatusSuccess: translate('collaboratorStatusSuccess'),
	collaboratorStatusError: translate('collaboratorStatusError'),
	successMessageAddEmployer: translate('successMessageAddEmployer'),
	newCollaborator: translate('newCollaborator'),
	showListCollaborators: translate('showListCollaborators'),
}

export const loginTranslate = {
	textLogin: translate('textLogin'),
	createAccount: translate('createAccount'),
	login: translate('login'),
	errorUserPass: translate('errorUserPass'),
	passPH: translate('passPH'),
}

export const mainMenuTextsTranslate = {
	mainMenuFeed: translate('mainMenuFeed'),
	mainMenuProfile: translate('mainMenuProfile'),
	mainMenuMoment: translate('mainMenuMoment'),
	mainMenuVacancies: translate('mainMenuVacancies'),
	mainMenuCompany: translate('mainMenuCompany'),
	mainMenuSearch: translate('mainMenuSearch'),
	mainMenuSeeMore: translate('mainMenuSeeMore'),
	dropdownCompany: translate('dropdownCompany'),
	dropdownLogout: translate('dropdownLogout'),
	dropdownProfile: translate('dropdownProfile'),
}

export const employeeExperienceTranslate = {
	EEMonth: translate('EEMonth'),
	EEYear: translate('EEYear'),

	//HistoricProfessional
	historicProfessionalEENameCompany: translate('historicProfessionalEENameCompany'),
	historicProfessionalEEAreaCompany: translate('historicProfessionalEEAreaCompany'),
	historicProfessionalEEOption0: translate('historicProfessionalEEOption0'),
	historicProfessionalEEOption1: translate('historicProfessionalEEOption1'),
	historicProfessionalEEOption2: translate('historicProfessionalEEOption2'),
	historicProfessionalEEOption3: translate('historicProfessionalEEOption3'),
	historicProfessionalEEOption4: translate('historicProfessionalEEOption4'),
	historicProfessionalEEOption5: translate('historicProfessionalEEOption5'),
	historicProfessionalEEOption6: translate('historicProfessionalEEOption6'),
	historicProfessionalEEOption7: translate('historicProfessionalEEOption7'),
	historicProfessionalEEOption8: translate('historicProfessionalEEOption8'),
	historicProfessionalEEOption9: translate('historicProfessionalEEOption9'),
	historicProfessionalEEOption10: translate('historicProfessionalEEOption10'),
	historicProfessionalEEOption11: translate('historicProfessionalEEOption11'),
	historicProfessionalEEOption12: translate('historicProfessionalEEOption12'),
	historicProfessionalEEOption13: translate('historicProfessionalEEOption13'),
	historicProfessionalEEOption14: translate('historicProfessionalEEOption14'),
	historicProfessionalEEOption15: translate('historicProfessionalEEOption15'),
	historicProfessionalEEOption16: translate('historicProfessionalEEOption16'),
	historicProfessionalEEOption17: translate('historicProfessionalEEOption17'),
	historicProfessionalEEOption18: translate('historicProfessionalEEOption18'),
	historicProfessionalEEOption19: translate('historicProfessionalEEOption19'),
	historicProfessionalEEOption20: translate('historicProfessionalEEOption20'),
	historicProfessionalEEOption21: translate('historicProfessionalEEOption21'),
	historicProfessionalEEOption22: translate('historicProfessionalEEOption22'),
	historicProfessionalEEOption23: translate('historicProfessionalEEOption23'),
	historicProfessionalEEOption24: translate('historicProfessionalEEOption24'),
	historicProfessionalEEOption25: translate('historicProfessionalEEOption25'),
	historicProfessionalEEOption26: translate('historicProfessionalEEOption26'),
	historicProfessionalEEOption27: translate('historicProfessionalEEOption27'),
	historicProfessionalEEOption28: translate('historicProfessionalEEOption28'),
	historicProfessionalEEOption29: translate('historicProfessionalEEOption29'),
	historicProfessionalEEOption30: translate('historicProfessionalEEOption30'),
	historicProfessionalEEOption31: translate('historicProfessionalEEOption31'),
	historicProfessionalEEOption32: translate('historicProfessionalEEOption32'),
	historicProfessionalEEOption33: translate('historicProfessionalEEOption33'),
	historicProfessionalEEJob: translate('historicProfessionalEEJob'),
	historicProfessionalEEEntryDate: translate('historicProfessionalEEEntryDate'),

	historicProfessionalEEActualJob: translate('historicProfessionalEEActualJob'),
	historicProfessionalEELeaveDate: translate('historicProfessionalEELeaveDate'),

	//Formation
	formationEENameUniversity: translate('formationEENameUniversity'),
	formationEEType: translate('formationEEType'),
	formationEEOption1: translate('formationEEOption1'),
	formationEEOption2: translate('formationEEOption2'),
	formationEEOption3: translate('formationEEOption3'),
	formationEEOption4: translate('formationEEOption4'),
	formationEEOption5: translate('formationEEOption5'),
	formationEECourse: translate('formationEECourse'),
	formationEEEntryDateCourse: translate('formationEEEntryDateCourse'),
	formationEECurrentlyStuding: translate('formationEECurrentlyStuding'),
	formationEELeaveDateCourse: translate('formationEELeaveDateCourse'),

	//ClimeQuestions
	climeQuestionsEEH1: translate('climeQuestionsEEH1'),
	climeQuestionsEEH2: translate('climeQuestionsEEH2'),
	climeQuestionsEEH3: translate('climeQuestionsEEH3'),
	climeQuestionsEEH4: translate('climeQuestionsEEH4'),
	climeQuestionsEETitle1: translate('climeQuestionsEETitle1'),
	climeQuestionEE1: translate('climeQuestionEE1'),
	climeQuestionEE2: translate('climeQuestionEE2'),
	climeQuestionEE3: translate('climeQuestionEE3'),
	climeQuestionEE4: translate('climeQuestionEE4'),
	climeQuestionsEETitle2: translate('climeQuestionsEETitle2'),
	climeQuestionEE5: translate('climeQuestionEE5'),
	climeQuestionEE6: translate('climeQuestionEE6'),
	climeQuestionEE7: translate('climeQuestionEE7'),
	climeQuestionEE8: translate('climeQuestionEE8'),
	climeQuestionEE9: translate('climeQuestionEE9'),
	climeQuestionEE10: translate('climeQuestionEE10'),
	climeQuestionsEETitle3: translate('climeQuestionsEETitle3'),
	climeQuestionEE11: translate('climeQuestionEE11'),
	climeQuestionEE12: translate('climeQuestionEE12'),
	climeQuestionEE13: translate('climeQuestionEE13'),
	climeQuestionEE14: translate('climeQuestionEE14'),
	climeQuestionEE15: translate('climeQuestionEE15'),
	climeQuestionsEETitle4: translate('climeQuestionsEETitle4'),
	climeQuestionEE16: translate('climeQuestionEE16'),
	climeQuestionEE17: translate('climeQuestionEE17'),
	climeQuestionEE18: translate('climeQuestionEE18'),
	climeQuestionEE19: translate('climeQuestionEE19'),
	climeQuestionEE20: translate('climeQuestionEE20'),
	climeQuestionEE21: translate('climeQuestionEE21'),
	climeQuestionsEETitle6: translate('climeQuestionsEETitle6'),
	climeQuestionEE22: translate('climeQuestionEE22'),
	climeQuestionEE23: translate('climeQuestionEE23'),
	climeQuestionEE24: translate('climeQuestionEE24'),
	climeQuestionEE25: translate('climeQuestionEE25'),

	cultureQuestionEE1: translate('cultureQuestionEE1'),
	cultureQuestionEE2: translate('cultureQuestionEE2'),
	cultureQuestionEE3: translate('cultureQuestionEE3'),
	cultureQuestionEE4: translate('cultureQuestionEE4'),
	cultureQuestionEE5: translate('cultureQuestionEE5'),
	cultureQuestionEE6: translate('cultureQuestionEE6'),
	cultureQuestionEE7: translate('cultureQuestionEE7'),
	cultureQuestionEE8: translate('cultureQuestionEE8'),
	cultureQuestionEE9: translate('cultureQuestionEE9'),
	cultureQuestionEE10: translate('cultureQuestionEE10'),
	cultureQuestionEE11: translate('cultureQuestionEE11'),
	cultureQuestionEE12: translate('cultureQuestionEE12'),
	cultureQuestionEE13: translate('cultureQuestionEE13'),
	cultureQuestionEE14: translate('cultureQuestionEE14'),
	cultureQuestionEE15: translate('cultureQuestionEE15'),
	cultureQuestionEE16: translate('cultureQuestionEE16'),
	cultureQuestionEE17: translate('cultureQuestionEE17'),
	cultureQuestionEE18: translate('cultureQuestionEE18'),
	cultureQuestionEE19: translate('cultureQuestionEE19'),
	cultureQuestionEE20: translate('cultureQuestionEE20'),
	cultureQuestionEE21: translate('cultureQuestionEE21'),
	cultureQuestionEE22: translate('cultureQuestionEE22'),
	cultureQuestionEE23: translate('cultureQuestionEE23'),
	cultureQuestionEE24: translate('cultureQuestionEE24'),

	cultureQuestionsEEForMe: translate('cultureQuestionsEEForMe'),

	contractTypeEE: translate('contractTypeEE'),

	//CareerPlan
	directorCP: translate('directorCP'),
	managerCP: translate('managerCP'),
	submanagerCP: translate('submanagerCP'),
	bossCP: translate('bossCP'),
	specialistCP: translate('specialistCP'),
	analystCP: translate('analystCP'),
	operatorCP: translate('operatorCP'),
	assistantCP: translate('assistantCP'),
	technicianCP: translate('technicianCP'),

	//InternalJobs
	emptyInternalJobs: translate('emptyInternalJobs'),

	//InternalMessage
	emptyMessage: translate('emptyMessage'),

	//PersonalEditProfile
	personalTitleEEProfile: translate('personalTitleEEProfile'),
	emailEEProfile: translate('emailEEProfile'),
	phoneEEProfile: translate('phoneEEProfile'),
	documentEEProfile: translate('documentEEProfile'),
	birthDateEEProfile: translate('birthDateEEProfile'),
	socialInformationEEProfile: translate('socialInformationEEProfile'),
	photoEEProfile: translate('photoEEProfile'),
	photoTextEEProfile: translate('photoTextEEProfile'),
	interestsTitleEEProfile: translate('interestsTitleEEProfile'),
	hobbyEEProfile: translate('hobbyEEProfile'),
	sportsEEProfile: translate('sportsEEProfile'),
	interestsEEProfile: translate('interestsEEProfile'),
	volunteerWorkEEProfile: translate('volunteerWorkEEProfile'),
	healthTitleEEProfile: translate('healthTitleEEProfile'),
	healthPlanEEProfile: translate('healthPlanEEProfile'),
	heightEEProfile: translate('heightEEProfile'),
	weightEEProfile: translate('weightEEProfile'),
	diseasesEEProfile: translate('diseasesEEProfile'),
	pcdEEProfile: translate('pcdEEProfile'),
	typeEEProfile: translate('typeEEProfile'),
	familyTitleEEProfile: translate('familyTitleEEProfile'),
	genderEEProfile: translate('genderEEProfile'),
	civilStatusEEProfile: translate('civilStatusEEProfile'),
	sonsEEProfile: translate('sonsEEProfile'),
	contractTitleEEProfile: translate('contractTitleEEProfile'),
	contractTypeEEProfile: translate('contractTypeEEProfile'),
	myBossEEProfile: translate('myBossEEProfile'),
	selectBossEEProfile: translate('selectBossEEProfile'),
	bankEEProfile: translate('bankEEProfile'),
	bankAccountEEProfile: translate('bankAccountEEProfile'),
	adressTitleEEProfile: translate('adressTitleEEProfile'),
	cityEEProfile: translate('cityEEProfile'),
	countryEEProfile: translate('countryEEProfile'),
	adressEEProfile: translate('adressEEProfile'),
	nationalityEEProfile: translate('nationalityEEProfile'),
	transportEEProfile: translate('transportEEProfile'),
}

export const wheelCompanyTranslate = {
	seeMore: translate('seeMore'),
	peopleAnalytics: translate('peopleAnalytics'),
	learning: translate('onBoarding'),
	trainings: translate('trainingAndDevelopment'),
	climeAndCulture: translate('climeAndCulture'),
	performanceAnalysis: translate('performanceAnalysis'),
	salariesAndBenefits: translate('salariesAndBenefits'),
	professionalDevelopment: translate('professionalDevelopment'),
	careerPlan: translate('careerPlan'),
	ombudsman: translate('ombudsman'),
	outplacement: translate('outplacement'),
}

export const jobDescriptorTranslate = {
	jobDescriptor: translate('jobDescriptor'),
	jobDescriptorNullTitle: translate('jobDescriptorNullTitle'),
	jobDescriptorNullText: translate('jobDescriptorNullText'),
	jobDescriptorNullText2: translate('jobDescriptorNullText2'),
	jobDescriptorBack: translate('jobDescriptorBack'),
	EEjobDescriptorNullTitle: translate('EEjobDescriptorNullTitle'),
	EEjobDescriptorNullText: translate('EEjobDescriptorNullText'),
	EEjobDescriptorNullText2: translate('EEjobDescriptorNullText2'),
	EEjobDescriptorNullRegister: translate('EEjobDescriptorNullRegister'),
	EEjobDescriptorBack: translate('EEjobDescriptorBack'),
}

export const cardSectionTranslate = {
	// titles
	collaborator360: translate('collaborator360'),
	dashboardCompany: translate('dashboardCompany'),
	executiveSearch: translate('executiveSearch'),
	middleTopManagement: translate('middleTopManagement'),
	backoffandStaff: translate('backoffandStaff'),
	plataformOnboardinGamified: translate('plataformOnboardinGamified'),
	englishClass: translate('englishClass'),
	commercialTraining: translate('commercialTraining'),
	gamefiedTraining: translate('gamefiedTraining'),
	cultureReport: translate('cultureReport'),
	culturalFitEmp: translate('culturalFitEmp'),
	culturalFitCol: translate('culturalFitCol'),
	culturalTransformation: translate('culturalTransformation'),
	performanceAnalysis: translate('performanceAnalysis'),
	realTimeConsult: translate('realTimeConsult'),
	customStudy: translate('customStudy'),
	variableSalary: translate('variableSalary'),
	salaryGuide: translate('salaryGuide'),
	motivacionalTest: translate('motivacionalTest'),
	knowMyco: translate('knowMyco'),
	skillTest: translate('skillTest'),
	englishLevel: translate('englishLevel'),
	careerProjection: translate('careerProjection'),
	salaryProjection: translate('salaryProjection'),
	jobDescriptor: translate('jobDescriptor'),
	topManagement: translate('topManagement'),
	middleManagement: translate('middleManagement'),
	backofficeOutput: translate('backofficeOutput'),
	searchOutput: translate('searchOutput'),

	// texts
	textCollaborator360: translate('textCollaborator360'),
	textDashboardCompany: translate('textDashboardCompany'),
	textExecutiveSearch: translate('textExecutiveSearch'),
	textMiddleTopManagement: translate('textMiddleTopManagement'),
	textBackoffandStaff: translate('textBackoffandStaff'),
	textPlataformOnboardinGamified: translate('textPlataformOnboardinGamified'),
	textEnglishClass: translate('textEnglishClass'),
	textCommercialTraining: translate('textCommercialTraining'),
	textGamefiedTraining: translate('textGamefiedTraining'),
	textCultureReport: translate('textCultureReport'),
	textCulturalFitEmp: translate('textCulturalFitEmp'),
	textCulturalFitCol: translate('textCulturalFitCol'),
	textCulturalTransformation: translate('textCulturalTransformation'),
	textPerformanceAnalysis: translate('textPerformanceAnalysis'),
	textRealTimeConsult: translate('textRealTimeConsult'),
	textCustomStudy: translate('textCustomStudy'),
	textVariableSalary: translate('textVariableSalary'),
	textSalaryGuide: translate('textSalaryGuide'),
	textMotivacionalTest: translate('textMotivacionalTest'),
	textKnowMyco: translate('textKnowMyco'),
	textSkillTest: translate('textSkillTest'),
	textEnglishLevel: translate('textEnglishLevel'),
	textCareerProjection: translate('textCareerProjection'),
	textSalaryProjection: translate('textSalaryProjection'),
	textJobDescriptor: translate('textJobDescriptor'),
	textTopManagement: translate('textTopManagement'),
	textMiddleManagement: translate('textMiddleManagement'),
	textBackofficeOutput: translate('textBackofficeOutput'),
	textSearchOutput: translate('textSearchOutput'),
}

export const lateralMenuTextTranslate = {
	myBusiness: translate('myBusiness'),

	performancePotentialTitle: translate('performancePotentialTitle'),
	performancePotentialReferences: translate('performancePotentialReferences'),

	peopleAnalytics: translate('peopleAnalytics'),
	myCollaborators: translate('myCollaborators'),
	collaboratorsRegister: translate('collaboratorsRegister'),
	massiveRegister: translate('massiveRegister'),
	campaignMenu: translate('campaignMenu'),

	onBoarding: translate('onBoarding'),
	knowBadgeHeroes: translate('knowBadgeHeroes'),
	onBoardingRegister: translate('onBoardingRegister'),

	trainingAndDevelopment: translate('trainingAndDevelopment'),
	manageTrainings: translate('manageTrainings'),
	workoutsMydna: translate('workoutsMydna'),
	ebooksAndContent: translate('ebooksAndContent'),

	climeAndCulture: translate('climeAndCulture'),
	climeSurvey: translate('climeSurvey'),
	nps: translate('nps'),
	businessCulture: translate('businessCulture'),
	matchCulture: translate('Match Cultural'),

	performanceAnalysis: translate('performanceAnalysis'),
	performanceEvaluation: translate('performanceEvaluation'),

	salariesAndBenefits: translate('salariesAndBenefits'),
	unitReport: translate('unitReport'),
	knowPaybalance: translate('knowPaybalance'),
	goPaybalance: translate('goPaybalance'),

	professionalDevelopment: translate('professionalDevelopment'),
	knowMyco: translate('knowMyco'),
	skillTest: translate('skillTest'),
	motivacionalTest: translate('motivacionalTest'),
	englishLevelTest: translate('englishLevelTest'),
	englishClass: translate('englishClass'),

	careerPlan: translate('careerPlan'),
	careerProjection: translate('careerProjection'),
	jobDescriptor: translate('jobDescriptor'),

	ombudsman: translate('ombudsman'),
	occurrences: translate('occurrences'),

	outplacement: translate('outplacement'),
	knowGenes: translate('knowGenes'),
	realocation: translate('realocation'),
	leaveSearch: translate('leaveSearch'),

	posicionesTitle: translate('posicionesTitle'),
	bands: translate('bands'),
	emotionalSalary: translate('emotionalSalary'),
	benefits: translate('benefits'),

	potentialMenuTitle: translate('potentialMenuTitle'),
	performanceMenuTitle: translate('performanceMenuTitle'),
	nineBoxMenuTitle: translate('nineBoxMenuTitle'),
}

export const headerTextTranslate = {
	myBusiness: translate('myBusiness'),
	myCollaborators: translate('myCollaborators'),
	mySolutions: translate('mySolutions'),
	myPartnes: translate('myPartnes'),
}

export const bodyTextTranslate = {
	ourProducts: translate('ourProducts'),
	customerEvaluation: translate('customerEvaluation'),
	apresentationDown: translate('apresentationDown'),

	textServices: translate('textServices'),
	textCertified: translate('textCertified'),

	myBusiness: translate('myBusiness'),
	peopleAnalytics: translate('peopleAnalytics'),
	recruitmentAndSelection: translate('recruitmentAndSelection'),
	learning: translate('learning'),
	trainings: translate('trainings'),
	performanceAnalysis: translate('performanceAnalysis'),
	salariesAndBenefits: translate('salariesAndBenefits'),
	careerPlan: translate('careerPlan'),
	cultureAnalysis: translate('cultureAnalysis'),
	professionalDevelopment: translate('professionalDevelopment'),
	outplacement: translate('outplacement'),

	activeService: translate('activeService'),
}

export const myCollaboratorsTranslate = {
	myCollaboratorsTitle: translate('myCollaboratorsTitle'),
	searchCollaborator: translate('searchCollaborator'),
	loadCollaborator: translate('loadCollaborator'),
	notDoneCultureSearch: translate('notDoneCultureSearch'),
	noCollaboratorsRegistered: translate('noCollaboratorsRegistered'),
}

export const supportTranslate = {
	supportTitle: translate('supportTitle'),
	supportText: translate('supportText'),
}

export const wheelCollaboratorTranslate = {
	professionalHistoric: translate('professionalHistoric'),
	purpose: translate('purpose'),
	formation: translate('formation'),
	culture: translate('culture'),
	salary: translate('salary'),
	habilities: translate('habilities'),
	motivacions: translate('motivacions'),
	performance: translate('performance'),
	training: translate('training'),
	careerPlan: translate('careerPlan'),
	climeAndCulture: translate('climeAndCulture'),
	aData: translate('aData'),
	functionsTitle: translate('functionsTitle'),
	achievementsTitle: translate('achievementsTitle'),
	currentJobTitle: translate('currentJobTitle'),
	functionsDescriptions: translate('functionsDescriptions'),
	achievementsDescriptions: translate('achievementsDescriptions'),
	editProfile: translate('editProfile'),
	documentsTitle: translate('documentsTitle'),
}

export const professionalHistoricTranslate = {
	professionalHistoric: translate('professionalHistoric'),
	collaboratorTrajectory: translate('collaboratorTrajectory'),
	experienceTime: translate('experienceTime'),
	yearsAnd: translate('yearsAnd'),
	months: translate('months'),
	workedIndustries: translate('workedIndustries'),
	workedPositions: translate('workedPositions'),
}

export const purposeTranslate = {
	purposeTitle: translate('purpose'),
	purposeToWork: translate('purposeToWork'),
}

export const formationTranslate = {
	formationTitle: translate('formation'),
	education: translate('education'),
	englishLevel: translate('englishLevel'),
}

export const englishLineLevelTranslate = {
	nulo: translate('englishNulo'),
	basic: translate('englishBasic'),
	intermedio: translate('englishIntermedio'),
	avanzado: translate('englishAvanzado'),
	fluido: translate('englishFlued'),
}

export const cultureTranslate = {
	cultureTitle: translate('culture'),
	commercial: translate('commercial'),
	commercialText: translate('commercialText'),
	disruptive: translate('disruptive'),
	disruptiveText: translate('disruptiveText'),
	collaborative: translate('collaborative'),
	collaborativeText: translate('collaborativeText'),
	traditional: translate('traditional'),
	traditionalText: translate('traditionalText'),
	culturePreview: translate('culturePreview'),
	cultureNoAnswer: translate('cultureNoAnswer'),
}

export const salaryTitle = {
	salaryTitle: translate('salary'),
	actualSalary: translate('actualSalary'),
	monthSalary: translate('monthSalary'),
	annualSalary: translate('annualSalary'),
	fixedSalary: translate('fixedSalary'),
	variableAnualSalary: translate('variableAnualSalary'),
	titlePosiciones: translate('titlePosiciones'),
}

export const careerPlanTranslate = {
	careerPlanTitle: translate('careerPlan'),
	hierarchicalLevel: translate('hierarchicalLevel'),
	nextLevel: translate('nextLevel'),
	actualLevel: translate('actualLevel'),
	collaboratorTextP1: translate('collaboratorTextP1'),
	collaboratorTextP2: translate('collaboratorTextP2'),
	director: translate('director'),
	manager: translate('manager'),
	submanager: translate('submanager'),
	boss: translate('boss'),
	specialist: translate('specialist'),
	analist: translate('analist'),
	technical: translate('technical'),
	careerPlanText: translate('careerPlanText'),
	careerPlanPHRole: translate('careerPlanPHRole'),
	careerPlanPHArea: translate('careerPlanPHArea'),
	careerPlanBtnSearch: translate('careerPlanBtnSearch'),
	careerPlanBtnClean: translate('careerPlanBtnClean'),
	careerPlanPHNameColaborator: translate('careerPlanPHNameColaborator'),
	careerPlanNextLevel: translate('careerPlanNextLevel'),
	careerPlanSameAreas: translate('careerPlanSameAreas'),
	possibleAreas: translate('possibleAreas'),
}

export const loadingTranslate = {
	loadingP1: translate('loadingP1'),
	loadingP2: translate('loadingP2'),
}

export const multipleCollTranslate = {
	collBodyText: translate('collBodyText'),
	collDownloadSheet: translate('collDownloadSheet'),
	collUpSheet: translate('collUpSheet'),
	collArchive: translate('collArchive'),
}

export const badgeHeroesTranslate = {
	badgeHeroesTitle: translate('badgeHeroesTitle'),
	badgeHeroesTextCard: translate('badgeHeroesTextCard'),
}

export const mycoTranslate = {
	mycoTitle: translate('mycoTitle'),
	mycoTextCard: translate('mycoTextCard'),
}

export const KnowPaybalanceTranslate = {
	KnowPaybalanceTitle: translate('KnowPaybalanceTitle'),
	KnowPaybalanceTextCard: translate('KnowPaybalanceTextCard'),
}

export const DefaultPattern = {
	date: translate('date'),
	collaboratorCountryOption: translate('collaboratorCountryOption'),
	country: translate('country'),
	city: translate('city'),
	industry: translate('industry'),
	industrySelect: translate('industrySelect'),
	areaSelect: translate('areaSelect'),
	positionLabel: translate('positionLabel'),
	positionSelect: translate('positionSelect'),
	optionSelect: translate('optionSelect'),
	positionSelect: translate('positionSelect'),
	optionSelect: translate('optionSelect'),
	englishLabel: translate('englishLabel'),
	indifferentOption: translate('indifferentOption'),
	smallOption: translate('smallOption'),
	mediumOption: translate('mediumOption'),
	largeOption: translate('largeOption'),
	basicLevelOption: translate('basicLevelOption'),
	mediumLevelOption: translate('mediumLevelOption'),
	advancedLevelOption: translate('advancedLevelOption'),
	roleOption: translate('roleOption'),
	roleJunior: translate('roleJunior'),
	rolePleno: translate('rolePleno'),
	roleSenior: translate('roleSenior'),
	educationLabel: translate('educationLabel'),
	graduationOption: translate('graduationOption'),
	salaryOption: translate('salaryOption'),
	salaryInput: translate('salaryInput'),
	bonusLabel: translate('bonusLabel'),
	bonusInput: translate('bonusInput'),
	clickHereLink: translate('clickHereLink'),
	selectAllLabel: translate('selectAllLabel'),
	inputRequired: translate('inputRequired'),
}

export const onboardingTranslate = {
	collStatus: translate('collStatus'),
	assignStatus1: translate('assignStatus1'),
	assignStatus2: translate('assignStatus2'),
	collEmpty: translate('collEmpty'),
	responsable: translate('responsable'),
	registerResponsable: translate('registerResponsable'),
	registerCollaborator: translate('registerCollaborator'),
	professionalResponsableTitle: translate('professionalResponsableTitle'),

	onboardingAlertAnswer: translate('onboardingAlertAnswer'),

	statusOnboardingQuestion1: translate('statusOnboardingQuestion1'),
	statusOnboardingQuestion2: translate('statusOnboardingQuestion2'),
	statusOnboardingQuestion3: translate('statusOnboardingQuestion3'),
	statusOnboardingQuestion4: translate('statusOnboardingQuestion4'),
	statusOnboardingQuestion5: translate('statusOnboardingQuestion5'),
	statusOnboardingQuestion6: translate('statusOnboardingQuestion6'),
	statusOnboardingQuestion7: translate('statusOnboardingQuestion7'),

	statusOnboardingAnswerYes: translate('statusOnboardingAnswerYes'),
	statusOnboardingAnswerNo: translate('statusOnboardingAnswerNo'),
}

export const capacitationsTranslate = {
	capacitationTitle: translate('capacitationTitle'),
	capacitationsTitle: translate('capacitationsTitle'),
	capacitationChooseArea: translate('capacitationChooseArea'),
	newCourse: translate('newCourse'),
	capacitationCourse: translate('capacitationCourse'),
	capacitationArea: translate('capacitationArea'),
	capacitationEmpty: translate('capacitationEmpty'),
	capacitationCol: translate('capacitationCol'),
	capacitationAreaCol: translate('capacitationAreaCol'),
	capacitationProgress: translate('capacitationProgress'),
	className: translate('className'),
	courseDescription: translate('courseDescription'),
}

export const trainningTranslate = {
	trainningTitle: translate('trainningTitle'),
}

export const trainningListTranslate = {
	//English
	trainningCardTitleEnglish: translate('trainningCardTitleEnglish'),
	trainningCardTextEnglish: translate('trainningCardTextEnglish'),

	//Comercial & Selling
	trainningCardTitleComercialSelling: translate('trainningCardTitleComercialSelling'),
	trainningCardTextComercialSelling: translate('trainningCardTextComercialSelling'),

	//Gamefied Trainning
	trainningCardTitleGamefied: translate('trainningCardTitleGamefied'),
	trainningCardTextGamefied: translate('trainningCardTextGamefied'),
}

export const emptyContentBookTranslate = {
	empyContentText: translate('empyContentText'),
}

export const formEETranslate = {
	EEpersonalInformation: translate('EEpersonalInformation'),
	EEdescriptionValidator: translate('EEdescriptionValidator'),
	EEnextStep: translate('EEnextStep'),
	EEbackBtn: translate('EEbackBtn'),
	EEregisterColaborator: translate('EEregisterColaborator'),
	EEFormName: translate('EEFormName'),
	EEFormEmail: translate('EEFormEmail'),
	EEFormSelect: translate('EEFormSelect'),
	EEFormCountry: translate('EEFormCountry'),
	EEFormRegion: translate('EEFormRegion'),
	EEFormPosition: translate('EEFormPosition'),
	EEFormNivel: translate('EEFormNivel'),
	EEFormArea: translate('EEFormArea'),
	EEFormSubarea: translate('EEFormSubarea'),
	EEFormEnglishLevel: translate('EEFormEnglishLevel'),
	EEFormFormation: translate('EEFormFormation'),
	EEFormFunction: translate('EEFormFunction'),
	EEFormSalaryFix: translate('EEFormSalaryFix'),
	EEFormSalaryVariable: translate('EEFormSalaryVariable'),
	EENamePlaceholder: translate('EENamePlaceholder'),
	EEPositionPlaceholder: translate('EEPositionPlaceholder'),
	EESalaryVariablePlaceholder: translate('EESalaryVariablePlaceholder'),
	EEColaboratorData: translate('EEColaboratorData'),
	EEDescriptionRole: translate('EEDescriptionRole'),
	EEAction: translate('EEAction'),
	EEEditData: translate('EEEditData'),
	EELevelAndArea: translate('EELevelAndArea'),
}

export const dashboardClimeTranslate = {
	dashboardClimeTitle: translate('dashboardClimeTitle'),
	dashboardClimeText: translate('dashboardClimeText'),
	leaveSearchTitle: translate('leaveSearchTitle'),
	leaveSearchNotFound: translate('leaveSearchNotFound'),
	emptyInformationClime: translate('emptyInformationClime'),
	textInformationBusinessCulture: translate('textInformationBusinessCulture'),
	textInformationBusinessCulture2: translate('textInformationBusinessCulture2'),
	climeQuestionsAlert: translate('climeQuestionsAlert'),
}

export const businessCultureQuestions = {
	//cultural
	businessCulturalQ1: translate('businessCulturalQ1'),
	businessCulturalQ2: translate('businessCulturalQ2'),
	businessCulturalQ3: translate('businessCulturalQ3'),
	businessCulturalQ4: translate('businessCulturalQ4'),
	businessCulturalQ5: translate('businessCulturalQ5'),
	businessCulturalQ6: translate('businessCulturalQ6'),

	//Career
	businessCareerQ1: translate('businessCareerQ1'),
	businessCareerQ2: translate('businessCareerQ2'),
	businessCareerQ3: translate('businessCareerQ3'),
	businessCareerQ4: translate('businessCareerQ4'),
	businessCareerQ5: translate('businessCareerQ5'),
	businessCareerQ6: translate('businessCareerQ6'),

	//leader
	businessLeaderQ1: translate('businessLeaderQ1'),
	businessLeaderQ2: translate('businessLeaderQ2'),
	businessLeaderQ3: translate('businessLeaderQ3'),
	businessLeaderQ4: translate('businessLeaderQ4'),
	businessLeaderQ5: translate('businessLeaderQ5'),
	businessLeaderQ6: translate('businessLeaderQ6'),

	//interests
	businessInterestsQ1: translate('businessInterestsQ1'),
	businessInterestsQ2: translate('businessInterestsQ2'),
	businessInterestsQ3: translate('businessInterestsQ3'),
	businessInterestsQ4: translate('businessInterestsQ4'),
	businessInterestsQ5: translate('businessInterestsQ5'),
	businessInterestsQ6: translate('businessInterestsQ6'),
}

export const promoterScoreTranslate = {
	netPromoteScoreTitle: translate('netPromoteScoreTitle'),
	detractorTitle: translate('detractorTitle'),
	detractorText: translate('detractorText'),
	neutralTitle: translate('neutralTitle'),
	neutralText: translate('neutralText'),
	promotorTitle: translate('promotorTitle'),
	promotorText: translate('promotorText'),
}

export const businessCultureTranslate = {
	businessCultureTitle: translate('businessCultureTitle'),
}

export const cultureBusinessTranslate = {
	businessCultureTitle: translate('businessCultureTitle'),
	asksTimelineText: translate('asksTimelineText'),
	cbTimelineStart: translate('cbTimelineStart'),
	cbTimeLineQuestions: translate('cbTimeLineQuestions'),
	cbTimeLineCareer: translate('cbTimeLineCareer'),
	cbTimeLineLeader: translate('cbTimeLineLeader'),
	cbTimeLineInterests: translate('cbTimeLineInterests'),
	cbTimeLineReportDelivery: translate('cbTimeLineReportDelivery'),
	cbTimeLineAswer: translate('cbTimeLineAswer'),
	cbTimeLineComercial: translate('cbTimeLineComercial'),
	cbTimeLineComercialDesc: translate('cbTimeLineComercialDesc'),
	cbTimeLineDisruptive: translate('cbTimeLineDisruptive'),
	cbTimeLineDisruptiveDec: translate('cbTimeLineDisruptiveDec'),
	cbTimeLineColaborate: translate('cbTimeLineColaborate'),
	cbTimeLineColaborateDesc: translate('cbTimeLineColaborateDesc'),
	cbTimeLineTraditional: translate('cbTimeLineTraditional'),
	cbTimeLineTraditionalDesc: translate('cbTimeLineTraditionalDesc'),
}

export const performanceEmployerTranslate = {
	saveButton: translate('saveButton'),
	employerPerformanceTitle: translate('employerPerformanceTitle'),
	employersShowCollumTitle: translate('employersShowCollumTitle'),
	employerCriterionCollumTitle: translate('employerCriterionCollumTitle'),
	employerWeigthCollumTitle: translate('employerWeigthCollumTitle'),
	employerExecutedCollumTitle: translate('employerExecutedCollumTitle'),
	employeeSugests: translate('employeeSugests'),
	employeeObservations: translate('employeeObservations'),
}

export const salariesAndBenefitsTranslate = {
	closeButton: translate('closeButton'),
	salaryConsultTitle: translate('salaryConsultTitle'),
	salaryConsultText: translate('salaryConsultText'),
	salaryConsultErrorEmptyFields: translate('salaryConsultErrorEmptyFields'),
	salaryConsultErrorIncompatibleSalary: translate('salaryConsultErrorIncompatibleSalary'),
	salaryConsultSuccessful: translate('salaryConsultSuccessful'),
	salaryConsultEducationLevel: translate('salaryConsultEducationLevel'),
	salaryConsultExperienceYears: translate('salaryConsultExperienceYears'),
	salaryConsultExperienceThreeLess: translate('salaryConsultExperienceThreeLess'),
	salaryConsultExperienceThreeOrSeven: translate('salaryConsultExperienceThreeOrSeven'),
	salaryConsultExperienceSevenOrMore: translate('salaryConsultExperienceSevenOrMore'),
	salaryConsultEnglishLevel: translate('salaryConsultEnglishLevel'),
	salaryConsultEnglishAloof: translate('salaryConsultEnglishAloof'),
	salaryConsultEnglishBasic: translate('salaryConsultEnglishBasic'),
	salaryConsultEnglishMid: translate('salaryConsultEnglishMid'),
	salaryConsultEnglishHigh: translate('salaryConsultEnglishHigh'),
	salaryConsultGrossMonthly: translate('salaryConsultGrossMonthly'),
	salaryConsultAnnualBonus: translate('salaryConsultAnnualBonus'),
	salaryConsultButtonSend: translate('salaryConsultButtonSend'),

	salaryRoleEmployee: translate('salaryRoleEmployee'),
	salaryRoleCoin: translate('salaryRoleCoin'),
	salaryRoleSalary: translate('salaryRoleSalary'),
	salaryDownloadList: translate('salaryDownloadList'),

	//SeniorityLevel
	salaryRoleSL1: translate('salaryRoleSL1'),
	salaryRoleSL2: translate('salaryRoleSL2'),
	salaryRoleSL3: translate('salaryRoleSL3'),
	salaryRoleSL4: translate('salaryRoleSL4'),

	//QualificationLevel
	salaryRoleQL1: translate('salaryRoleQL1'),
	salaryRoleQL2: translate('salaryRoleQL2'),
	salaryRoleQL3: translate('salaryRoleQL3'),
	salaryRoleQL4: translate('salaryRoleQL4'),

	salaryRoleAlert: translate('salaryRoleAlert'),

	dashboardTextClimeBenefits: translate('dashboardTextClimeBenefits'),
	alertEmptyDashboard: translate('alertEmptyDashboard'),

	//benefits
	benefitsText: translate('benefitsText'),
	benefitsSearchByCountry: translate('benefitsSearchByCountry'),

	alimentationTitle: translate('alimentationTitle'),
	alimentationText: translate('alimentationText'),
	transportTitle: translate('transportTitle'),
	transportText: translate('transportText'),
	lifeInsuranceTitle: translate('lifeInsuranceTitle'),
	lifeInsuranceText: translate('lifeInsuranceText'),
	certificationTitle: translate('certificationTitle'),
	certificationText: translate('certificationText'),
	vehicleTitle: translate('vehicleTitle'),
	vehicleText: translate('vehicleText'),
	tradingPowerTitle: translate('tradingPowerTitle'),
	tradingPowerText: translate('tradingPowerText'),
	computerTitle: translate('computerTitle'),
	computerText: translate('computerText'),
	gymTitle: translate('gymTitle'),
	gymText: translate('gymText'),
	anualBonusTitle: translate('anualBonusTitle'),
	anualBonusText: translate('anualBonusText'),
	bonusTitle: translate('bonusTitle'),
	bonusText: translate('bonusText'),
	equityTitle: translate('equityTitle'),
	equityText: translate('equityText'),
	stockOptionsTitle: translate('stockOptionsTitle'),
	stockOptionsText: translate('stockOptionsText'),

	//EmotionalSalary
	emotionalSalaryText: translate('emotionalSalaryText'),
	emotionalSalaryOptionSelect: translate('emotionalSalaryOptionSelect'),

	salaryInformation: translate('salaryInformation'),
}

export const SalaryComparativeTranslate = {
	midpointText: translate('midpointText'),
	salaryDifferenceText: translate('salaryDifferenceText'),
	salaryColaborator: translate('salaryColaborator'),
	salaryComparativeEmptyText: translate('salaryComparativeEmptyText'),
	payrollStatus: translate('payrollStatus'),
}

export const jobsDescriptionTranslate = {
	jobsDescriptionTitle: translate('jobsDescriptionTitle'),
	jobsDescriptionShowMore: translate('jobsDescriptionShowMore'),
	jobsDescriptionReturn: translate('jobsDescriptionReturn'),
	jobsDescriptionTitleJob: translate('jobsDescriptionTitleJob'),
	jobsDescriptionActivies: translate('jobsDescriptionActivies'),
	jobsDescriptionFormation: translate('jobsDescriptionFormation'),
	jobsDescriptionPersonal: translate('jobsDescriptionPersonal'),
	jobsDescriptionRelacion: translate('jobsDescriptionRelacion'),
	jobsDescriptionLanguage: translate('jobsDescriptionLanguage'),
	jobsDescriptionStudy: translate('jobsDescriptionStudy'),
}

export const cardsTranslate = {
	apresentationDown: translate('apresentationDown'),
	activeService: translate('activeService'),
	customerEvaluation: translate('customerEvaluation'),
}

export const classesTranslate = {
	createNewClass: translate('createNewClass'),
	className: translate('className'),
	classLink: translate('classLink'),
	btnSave: translate('saveButton'),
}

export const salaryRangeTranslate = {
	createNewSalaryRange: translate('createNewSalaryRange'),
	salaryRangeName: translate('salaryRangeName'),
	salaryRangeCountry: translate('salaryRangeCountry'),
	salaryRangeMidPoint: translate('salaryRangeMidPoint'),
	salaryRangeTitle: translate('salaryRangeTitle'),
	salarysRangesTitle: translate('salarysRangesTitle'),
	salaryRangeEdit: translate('salaryRangeEdit'),
	salaryRangeDelete: translate('salaryRangeDelete'),
	salaryRangeConfirmDelete: translate('salaryRangeConfirmDelete'),
}

export const complaintTranslate = {
	complaintSecurityText: translate('complaintSecurityText'),
	complaintSecuryTextBody: translate('complaintSecuryTextBody'),
	btnComplaint: translate('btnComplaint'),
	btnBack: translate('backButton'),
	complaintTypes: translate('complaintTypes'),
	complaintGenerate: translate('complaintGenerate'),
	complaintOptionA: translate('complaintOptionA'),
	complaintOptionB: translate('complaintOptionB'),
	complaintOptionC: translate('complaintOptionC'),
	complaintOptionD: translate('complaintOptionD'),
	complaintOptionE: translate('complaintOptionE'),
	complaintOptionF: translate('complaintOptionF'),
	complaintOptionG: translate('complaintOptionG'),
	complaintOptionH: translate('complaintOptionH'),
	complaintOptionI: translate('complaintOptionI'),
	complaintOptionJ: translate('complaintOptionJ'),
	complaintOptionK: translate('complaintOptionK'),
	complaintOptionL: translate('complaintOptionL'),
	complaintOptionM: translate('complaintOptionM'),
}

export const ombudsmanTranslate = {
	noOccurrences: translate('noOccurrences'),
	ombudmanTextOne: translate('ombudmanTextOne'),
	ombudmanTextTwo: translate('ombudmanTextTwo'),
	ombudsmanBtn: translate('ombudsmanBtn'),
}

export const personalInfoTranslate = {
	editProfileImg: translate('editProfileImg'),
	professionalInformation: translate('professionalInformation'),
	personalCompanyCol: translate('personalCompanyCol'),
	personalIndustryCol: translate('personalIndustryCol'),
	personalAreaCol: translate('personalAreaCol'),
	personalEntryDate: translate('personalEntryDate'),
	personalExitDate: translate('personalExitDate'),
	personalHastaFecha: translate('personalHastaFecha'),

	//Professional Experience
	personalAddInfoProf: translate('personalAddInfoProf'),
	personalConectiveProf1: translate('personalConectiveProf1'),
	personalConectiveProf2: translate('personalConectiveProf2'),

	//Education
	personalEducation: translate('personalEducation'),
	personalEnglishLevel: translate('personalEnglishLevel'),
	personalELNulo: translate('personalELNulo'),
	personalELBasic: translate('personalELBasic'),
	personalELAverage: translate('personalELAverage'),
	personalELAdvanced: translate('personalELAdvanced'),
	personalELFluent: translate('personalELFluent'),
	personalUniversity: translate('personalUniversity'),
	personalType: translate('personalType'),
	personalClass: translate('personalClass'),
	personalAddInfo: translate('personalAddInfo'),

	//Salary
	personalSalary: translate('personalSalary'),
	personalFixBruteSalary: translate('personalFixBruteSalary'),
	personalCoin: translate('personalCoin'),
	personalVariableSalary: translate('personalVariableSalary'),
}

export const btnTranslate = {
	saveButton: translate('saveButton'),
	closeButton: translate('closeButton'),
	backButton: translate('backButton'),
	nextButton: translate('nextButton'),
	createButton: translate('createButton'),
	performTest: translate('performTest'),
	showResult: translate('showResult'),
	accessButton: translate('accessButton'),
}

export const myPurposesTranslate = {
	myPurposes: translate('myPurposes'),
	myPurposeText: translate('myPurposeText'),
	yourPurpose: translate('yourPurpose'),
}

export const progressBarTranslate = {
	progressText: translate('progressText'),
	progressMyProfile: translate('progressMyProfile'),
	progressProfileText: translate('progressProfileText'),
}

export const climeCultureTranslate = {
	climeAndCultureTitle: translate('climeAndCulture'),
	climeSurvey: translate('climeSurvey'),
	cultureSurveyText: translate('cultureSurveyText'),
	cultureSurvey: translate('cultureSurvey'),
	climeSurveyText: translate('climeSurveyText'),

	//Culture
	culturePreview: translate('culturePreview'),
	myCultureProfile: translate('myCultureProfile'),
	myCultureText: translate('myCultureText'),
	commercial: translate('commercial'),
	disruptive: translate('disruptive'),
	collaborative: translate('collaborative'),
	traditional: translate('traditional'),
}

export const abilitiesCollaboratorTranslate = {
	competeciaTitle: translate('competenciaTitle'),
	collaboratorWarning: translate('abilitiesCollaboratorTitleText'),
	collaboratorWarningTextP1: translate('abilitiesCollaboratorTextP1'),
	collaboratorWarningTextP2: translate('abilitiesCollaboratorTextP2'),
	collaboratorWarningTextP3: translate('abilitiesCollaboratorTextP3'),
	abilitiesCollaboratorTextEmail: translate('abilitiesCollaboratorTextEmail'),
	abilitiesCollaboratorDoubtsText: translate('abilitiesCollaboratorDoubtsText'),
}

export const motivacionCollaboratorTranslate = {
	motivacionTitle: translate('motivationTitle'),
	motivacionCollaboratorTitle: translate('motivacionCollaboratorTitle'),
	motivacionalCollaboratorTextP1: translate('motivacionalCollaboratorTextP1'),
	motivacionalCollaboratorTextP2: translate('motivacionalCollaboratorTextP2'),
	motivacionalCollaboratorTextP3: translate('motivacionalCollaboratorTextP3'),
	motivacionalCollaboratorTextEmail: translate('abilitiesCollaboratorTextEmail'),
	motivacionalCollaboratorDoubtsText: translate('abilitiesCollaboratorDoubtsText'),
}

export const performanceTranslate = {
	performanceTitle: translate('performanceTitle'),
	perfomanceFinalResult: translate('perfomanceFinalResult'),
}

export const emptyInformationTranslate = {
	incompleteProfile: translate('incompleteProfile'),
	incompleteTest: translate('incompleteTest'),
	emptyDesempeno: translate('emptyDesempeno'),
}

export const trainingsTranslate = {
	ebooks: translate('ebooks'),
	myTrainings: translate('myTrainings'),
	createNewTraining: translate('createNewTraining'),
	nulingaText: translate('nulingaText'),
	englishTestTitle: translate('englishText'),
	enterTrainingLink: translate('enterTrainingLink'),
}

export const employeeProfileEdit = {
	profileError: translate('profileError'),
}

export const internalJobsTranslate = {
	internalJobStep1: translate('internalJobStep1'),
	internalJobName: translate('internalJobName'),
	internalJobArea: translate('internalJobArea'),
	internalJobNivel: translate('internalJobNivel'),
	internalJobExperienceYears: translate('internalJobExperienceYears'),
	internalJobOptionArea: translate('internalJobOptionArea'),
	internalJobOptionNivel: translate('internalJobOptionNivel'),

	internalJobStep2: translate('internalJobStep2'),
	internalJobDescription: translate('internalJobDescription'),
	internalJobRequeriments: translate('internalJobRequeriments'),

	internalJobRequerimentsPlaceHolder: translate('internalJobRequerimentsPlaceHolder'),

	internalJobTitle: translate('internalJobTitle'),
	internalJobTableC1: translate('internalJobTableC1'),
	internalJobTableC2: translate('internalJobTableC2'),
	internalJobTableC3: translate('internalJobTableC3'),
	internalJobSearchPlaceHolder: translate('internalJobSearchPlaceHolder'),
	internalJobShowMore: translate('internalJobShowMore'),
	internalJobEmptyJob: translate('internalJobEmptyJob'),
	internalJobReturn: translate('internalJobReturn'),
}

export const paybalanceTranslate = {
	paybalanceTitle: translate('paybalanceTitle'),
	paybalanceDescription1: translate('paybalanceDescription1'),
	paybalanceDescription2: translate('paybalanceDescription2'),
	paybalanceDescription3: translate('paybalanceDescription3'),
	paybalanceDescription4: translate('paybalanceDescription4'),
	paybalanceDescription5: translate('paybalanceDescription5'),
	paybalanceTxt: translate('paybalanceTxt'),
	paybalanceBtn: translate('paybalanceBtn'),
}

export const bandsTranslate = {
	selectBands: translate('selectBands'),
}

export const outplacementTranslate = {
	outplacementAlertAnswer: translate('outplacementAlertAnswer'),
	outplacementQuestionOne: translate('outplacementQuestionOne'),
	outplacementQuestionTwo: translate('outplacementQuestionTwo'),
	outplacementQuestionThree: translate('outplacementQuestionThree'),
	outplacementQuestionFour: translate('outplacementQuestionFour'),
	outplacementQuestionFive: translate('outplacementQuestionFive'),
	outplacementQuestionSix: translate('outplacementQuestionSix'),
	outplacementQuestionSixPos: translate('outplacementQuestionSixPos'),
	outplacementQuestionSeven: translate('outplacementQuestionSeven'),
	outplacementAnswerYes: translate('outplacementAnswerYes'),
	outplacementAnswerNo: translate('outplacementAnswerNo'),
	outplacementAnswerOne: translate('outplacementAnswerOne'),
	outplacementAnswerTwo: translate('outplacementAnswerTwo'),
	outplacementAnswerThree: translate('outplacementAnswerThree'),
	outplacementAnswerFour: translate('outplacementAnswerFour'),
	outplacementOservation: translate('outplacementOservation'),
}

export const campaign = {
	campaignTitle: translate('campaignTitle'),
	campaignTxt: translate('campaignTxt'),
	campaignMenu: translate('campaignMenu'),
	campaignTableName: translate('campaignTableName'),
	campaignTableMail: translate('campaignTableMail'),
	campaignTableArea: translate('campaignTableArea'),
	campaignTableJob: translate('campaignTableJob'),
}

export const internalChannel = {
	internalChannelTitle: translate('internalChannelTitle'),
	internalChannelNewMessage: translate('internalChannelNewMessage'),
	internalChannelNoMessages1: translate('internalChannelNoMessages1'),
	internalChannelNoMessages2: translate('internalChannelNoMessages2'),
	internalChannelSendedMessages: translate('internalChannelSendedMessages'),
	internalChannelReceivedMessages: translate('internalChannelReceivedMessages'),
}
export const internalVacancies = {
	internalVacanciesTitle: translate('internalVacanciesTitle'),
	internalVacanciesCreate: translate('internalVacanciesCreate'),
	internalVacanciesActive: translate('internalVacanciesActive'),
}

export const mycoTest = {
	mycoAbilitiesQ1: translate('mycoAbilitiesQ1'),
	mycoAbilitiesQ2: translate('mycoAbilitiesQ2'),
	mycoAbilitiesQ3: translate('mycoAbilitiesQ3'),
	mycoAbilitiesQ4: translate('mycoAbilitiesQ4'),
	mycoAbilitiesQ5: translate('mycoAbilitiesQ5'),
	mycoAbilitiesQ6: translate('mycoAbilitiesQ6'),
	mycoAbilitiesQ7: translate('mycoAbilitiesQ7'),
	mycoAbilitiesQ8: translate('mycoAbilitiesQ8'),
	mycoAbilitiesQ9: translate('mycoAbilitiesQ9'),
	mycoAbilitiesQ10: translate('mycoAbilitiesQ10'),
	mycoAbilitiesQ11: translate('mycoAbilitiesQ11'),

	mycoMotivationsQ1: translate('mycoMotivationsQ1'),
	mycoMotivationsQ2: translate('mycoMotivationsQ2'),
	mycoMotivationsQ3: translate('mycoMotivationsQ3'),
	mycoMotivationsQ4: translate('mycoMotivationsQ4'),
	mycoMotivationsQ5: translate('mycoMotivationsQ5'),
	mycoMotivationsQ6: translate('mycoMotivationsQ6'),
	mycoMotivationsQ7: translate('mycoMotivationsQ7'),
	mycoMotivationsQ8: translate('mycoMotivationsQ8'),
	mycoMotivationsQ9: translate('mycoMotivationsQ9'),
	mycoMotivationsQ10: translate('mycoMotivationsQ10'),
	mycoMotivationsQ11: translate('mycoMotivationsQ11'),
	mycoMotivationsQ12: translate('mycoMotivationsQ12'),
	mycoMotivationsQ13: translate('mycoMotivationsQ13'),
	mycoMotivationsQ14: translate('mycoMotivationsQ14'),
	mycoMotivationsQ15: translate('mycoMotivationsQ15'),
	mycoMotivationsQ16: translate('mycoMotivationsQ16'),
	mycoMotivationsQ17: translate('mycoMotivationsQ17'),
}

export const myCompanyTranslate = {
	companyDetails: translate('companyDetails'),
	companyLogo: translate('companyLogo'),
	companyName: translate('companyName'),
	companyDescription: translate('companyDescription'),
	companyAnualBilling: translate('companyAnualBilling'),
	companyLocation: translate('companyLocation'),
	companySize: translate('companySize'),
	companySize1: translate('companySize1'),
	companySize2: translate('companySize2'),
	companySize3: translate('companySize3'),
	companyEmployeeAmount: translate('companyEmployeeAmount'),
	companyContact: translate('companyContact'),
	companyPhone: translate('companyPhone'),
	companyWebPage: translate('companyWebPage'),
	companyProducts: translate('companyProducts'),
	companyAddProduct: translate('companyAddProduct'),
	companyContent: translate('companyContent'),
	companyUpVideos: translate('companyUpVideos'),
}

export const unitReportTranslate = {
	unitReportTitle: translate('unitReport'),
	unitSectionDescription: translate('unitSectionDescription'),
	unityReportMandatoryInfo: translate('unityReportMandatoryInfo'),
	unityReportIncomplete: translate('unityReportIncomplete'),
	unityReportRecInfo: translate('unityReportRecInfo'),
	unityReportAddInfo: translate('unityReportAddInfo'),
	unityReportAddInfoBtn: translate('unityReportAddInfoBtn'),
	unityReportAddRecomBtn: translate('unityReportAddRecomBtn'),
	unityReportBtn: translate('unityReportBtn'),
}

export const documentsTranslate = {
	documentsTitle: translate('docTitleView'),
	documentsDescription: translate('documentsDescription'),
	myTemplatesTitle: translate('myTemplatesTitle'),
	createTemplate: translate('createTemplate'),
	emptyTemplate: translate('emptyTemplate'),
	nameTemplate: translate('nameTemplate'),
	newDocumentAlert: translate('newDocumentAlert'),
	loadingTemplate: translate('loadingTemplate'),
	loadingCompleteTemplate: translate('loadingCompleteTemplate'),
	btnSaveTemplate: translate('btnSaveTemplate'),
	btnCancelTemplate: translate('btnCancelTemplate'),
	msgErrorDoc: translate('msgErrorDoc'),
	msgErrorWait: translate('msgErrorWait'),
	receivedDocTitle: translate('receivedDocTitle'),
	docPending: translate('docPending'),
	docSended: translate('docSended'),
	docDownload: translate('docDownload'),
	docDescriptionEmp: translate('docDescriptionEmp'),
}

export const references = {
	useGuide: translate('useGuide'),
	evaluatePotential: translate('evaluatePotential'),
	examplesOfCriteria: translate('examplesOfCriteria'),
	potential: translate('potential'),
	definition: translate('definition'),
	stepsToAnalyzeResults: translate('stepsToAnalyzeResults'),
	evaluationAndCriteria: translate('evaluationAndCriteria'),
	examplesOfPerformance: translate('examplesOfPerformance'),
	performanceTitle: translate('performanceTitle'),
	performanceEvaluationTitle: translate('performanceEvaluationTitle'),
	performanceEvaluationText: translate('performanceEvaluationText'),
	performanceCriteriaTitle: translate('performanceCriteriaTitle'),
	performanceCriteriaText: translate('performanceCriteriaText'),
	examplesOfPerformanceIndicators: translate('examplesOfPerformanceIndicators'),
	TypesOfMethods: translate('TypesOfMethods'),
	ConductualRatingScaleTitle: translate('ConductualRatingScaleTitle'),
	ConductualRatingScaleText: translate('ConductualRatingScaleText'),
	OfCriticalIncidentsTitle: translate('OfCriticalIncidentsTitle'),
	OfCriticalIncidentsText: translate('OfCriticalIncidentsText'),
	TypesOfMethodsList1: translate('TypesOfMethodsList1'),
	TypesOfMethodsList2: translate('TypesOfMethodsList2'),
	TypesOfMethodsList3: translate('TypesOfMethodsList3'),
	TypesOfMethodsList4: translate('TypesOfMethodsList4'),
	TypesOfMethodsList5: translate('TypesOfMethodsList5'),
	ObjectiveOfTheCriteria: translate('ObjectiveOfTheCriteria'),
	ObjectiveOfTheCriteriaList1: translate('ObjectiveOfTheCriteriaList1'),
	ObjectiveOfTheCriteriaList2: translate('ObjectiveOfTheCriteriaList2'),
	ObjectiveOfTheCriteriaList3: translate('ObjectiveOfTheCriteriaList3'),
	ObjectiveOfTheCriteriaList4: translate('ObjectiveOfTheCriteriaList4'),
	ObjectiveOfTheCriteriaList5: translate('ObjectiveOfTheCriteriaList5'),
	ObjectiveOfTheCriteriaList6: translate('ObjectiveOfTheCriteriaList6'),
	ObjectiveOfTheCriteriaList7: translate('ObjectiveOfTheCriteriaList7'),
	ObjectiveOfTheCriteriaList8: translate('ObjectiveOfTheCriteriaList8'),
	ObjectiveOfTheCriteriaList9: translate('ObjectiveOfTheCriteriaList9'),
	ObjectiveOfTheCriteriaList10: translate('ObjectiveOfTheCriteriaList10'),
	ObjectiveOfTheCriteriaList11: translate('ObjectiveOfTheCriteriaList11'),
	ObjectiveOfTheCriteriaList12: translate('ObjectiveOfTheCriteriaList12'),
	StepsToAnalyzePerformanceEvaluationResults: translate('StepsToAnalyzePerformanceEvaluationResults'),
	StepsToAnalyzePerformanceEvaluationResultsList1: translate('StepsToAnalyzePerformanceEvaluationResultsList1'),
	StepsToAnalyzePerformanceEvaluationResultsList2: translate('StepsToAnalyzePerformanceEvaluationResultsList2'),
	StepsToAnalyzePerformanceEvaluationResultsList3: translate('StepsToAnalyzePerformanceEvaluationResultsList3'),
	StepsToAnalyzePerformanceEvaluationResultsList4: translate('StepsToAnalyzePerformanceEvaluationResultsList4'),
	StepsToAnalyzePerformanceEvaluationResultsList5: translate('StepsToAnalyzePerformanceEvaluationResultsList5'),
	StepsToAnalyzePerformanceEvaluationResultsList6: translate('StepsToAnalyzePerformanceEvaluationResultsList6'),
	StepsToAnalyzePerformanceEvaluationResultsList7: translate('StepsToAnalyzePerformanceEvaluationResultsList7'),
	PostEvaluationTitle: translate('PostEvaluationTitle'),
	PostEvaluationText: translate('PostEvaluationText'),
	Potential: translate('Potential'),
	DefinitionTitle: translate('DefinitionTitle'),
	DefinitionText: translate('DefinitionText'),
	ExamplesOfPotentialCriteria: translate('ExamplesOfPotentialCriteria'),
	WhyIsPotentialEvaluated: translate('WhyIsPotentialEvaluated'),
	PotentialsOfAnExecutive: translate('PotentialsOfAnExecutive'),
	TypesOfModels: translate('TypesOfModels'),
	PsychologicalEvaluationsTitle: translate('PsychologicalEvaluationsTitle'),
	PsychologicalEvaluationsText: translate('PsychologicalEvaluationsText'),
	AssessmentTitle: translate('AssessmentTitle'),
	AssessmentText: translate('AssessmentText'),
	AssessmentPuzzleTitle: translate('AssessmentPuzzleTitle'),
	AssessmentPuzzleText: translate('AssessmentPuzzleText'),
	AssessmentStrongPerformanceTitle: translate('AssessmentStrongPerformanceTitle'),
	AssessmentStrongPerformanceText: translate('AssessmentStrongPerformanceText'),
	AssessmentHighImpactTitle: translate('AssessmentHighImpactTitle'),
	AssessmentHighpotentialTitle: translate('AssessmentHighpotentialTitle'),
	AssessmentHighpotentialText: translate('AssessmentHighpotentialText'),
	AssessmentQuestionableTitle: translate('AssessmentQuestionableTitle'),
	AssessmentQuestionableText: translate('AssessmentQuestionableText'),
	AssessmentMaintainerTitle: translate('AssessmentMaintainerTitle'),
	AssessmentMaintainerText1: translate('AssessmentMaintainerText1'),
	AssessmentSpecialistTitle: translate('AssessmentSpecialistTitle'),
	AssessmentMaintainerText2: translate('AssessmentMaintainerText2'),
	AssessmentInsufficientTitle: translate('AssessmentInsufficientTitle'),
	AssessmentInsufficientText: translate('AssessmentInsufficientText'),
	AssessmentEffectiveTitle: translate('AssessmentEffectiveTitle'),
	AssessmentEffectiveText: translate('AssessmentEffectiveText'),
	WhatIsThe9boxToolForTitle: translate('WhatIsThe9boxToolForTitle'),
	WhatIsThe9boxToolForText: translate('WhatIsThe9boxToolForText'),
	AdvantagesOfThe9BoxMatrix: translate('AdvantagesOfThe9BoxMatrix'),
	AdvantagesOfThe9BoxMatrixList1: translate('AdvantagesOfThe9BoxMatrixList1'),
	AdvantagesOfThe9BoxMatrixList2: translate('AdvantagesOfThe9BoxMatrixList2'),
	AdvantagesOfThe9BoxMatrixList3: translate('AdvantagesOfThe9BoxMatrixList3'),
}

export const assessment = {
	competencyTest: translate('competencyTest'),
	questionsDescription: translate('questionsDescription'),
	responseOptions: translate('responseOptions'),
	veryDisagreeable: translate('veryDisagreeable'),
	inDisagreement: translate('inDisagreement'),
	neutral: translate('neutral'),
	iAgree: translate('iAgree'),
	iAgreeVeryMuch: translate('iAgreeVeryMuch'),
	tryNotToRespondInNeutral: translate('tryNotToRespondInNeutral'),
	answerHonestly: translate('answerHonestly'),
	itsLessThan25Minutes: translate('itsLessThan25Minutes'),
	saveAndFollow: translate('saveAndFollow'),
	conclude: translate('conclude'),
	progress: translate('progress'),
	steps: translate('steps'),
	back: translate('back'),
	next: translate('next'),
	fortresses: translate('fortresses'),
	improvementOpportunities: translate('improvementOpportunities'),
	readMore: translate('readMore')
}