import React, { useEffect, useState } from 'react'

export function Pill({ label, text, color, eventColorText, onClick }) {
	const [event, setEvent] = useState(eventColorText)

	useEffect(() => {
		setEvent(eventColorText)
	}, [eventColorText])

	const handleClick = () => {
		if (onClick) {
			onClick()
		}
	}

	return (
		<div className='' onClick={handleClick}>
			<div className={`${event === true ? 'tw-text-white' : 'tw-text-gray-400'} mb-1`}>{label}</div>
			<div className='tw-flex'>
				<div className={`tw-font-medium px-3 me-2 tw-rounded-full tw-w-max ${color ?? 'tw-bg-green-200 tw-text-green-900'}`}>{text}</div>
			</div>
		</div>
	)
}
