import { Modal, Button } from 'react-bootstrap'
import { modalAssignTranslate } from '../../../../../i18n/translatedMessages'
import { FormattedMessage } from 'react-intl'
import Loading from '../../../../../Components/Loading/Loading'

const ModalAssign = ({ toShow, toClose, assignType, employeeList, busca, handleChangeBusca, handleChangeAssign, handleChangeRespAssign, checkedValidation, checkedValidationResp, handleApply, title, loadData }) => {
	return (
		<Modal show={toShow} onHide={toClose} backdrop='static' keyboard={false} size='lg' aria-labelledby='contained-modal-title-vcenter' centered scrollable={true} className='modal-assign'>
			<Modal.Header closeButton>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='container assign-modal-main'>
					<div className='row'>
						<div className='col-12'>
							<FormattedMessage id={'searchCollaborator'}>{msg => <input type='text' className='principal-search adjust-input-principal-s w-100' placeholder={msg} value={busca} onChange={handleChangeBusca} />}</FormattedMessage>
						</div>
					</div>
					{loadData ? (
						<div className='loading'>
							<Loading />
						</div>
					) : assignType === 'Responsable' ? (
						employeeList?.map((employees, key) => {
							return (
								<div key={key} className='row py-2 align-items-center'>
									<label className='row align-items-center' htmlFor={`checkUser${employees.id}`}>
										<div className='col-1'>
											<input
												className='assign-custom-checkbox'
												name='checkResp'
												type='radio'
												value={employees.id}
												id={`checkUser${employees.id}`}
												onChange={e => handleChangeRespAssign(e)}
												checked={Number(checkedValidationResp[0]?.assign) === Number(employees.id)}
											/>
											<span className='checkmark-radio'></span>
										</div>
										<div className='col-10'>
											<div className='row align-items-center'>
												<div className='col-2'>
													<img className='img-fluid rounded-circle' src={employees.asset ? employees.asset : 'https://assets.mydna.company/b2b-images/profileImg.png'} alt='' />
												</div>
												<div className='col-5'>
													<h5 className=''>{employees.name}</h5>
													<p>{employees.area?.profileArea}</p>
												</div>
											</div>
										</div>
									</label>
								</div>
							)
						})
					) : (
						employeeList?.map((employees, key) => {
							const mapAux = checkedValidation?.map(filt => Number(filt))
							return (
								<div key={key} className='row py-2 align-items-center'>
									<label className='row align-items-center' htmlFor={`checkUser${employees.id}`}>
										<div className='col-1'>
											<input
												className='assign-custom-checkbox'
												name={`checkUser${employees.id}`}
												type='checkbox'
												value={employees.id}
												id={`checkUser${employees.id}`}
												onChange={e => handleChangeAssign(e, key)}
												checked={mapAux.includes(Number(employees.id))}
											/>
											<span className='checkmark'></span>
										</div>

										<div className='col-10'>
											<div className='row align-items-center'>
												<div className='col-2'>
													<img className='img-fluid rounded-circle' src={employees.asset ? employees.asset : 'https://assets.mydna.company/b2b-images/profileImg.png'} alt='' />
												</div>
												<div className='col-5'>
													<h5 className=''>{employees.name}</h5>
													<p>{employees.area?.profileArea}</p>
												</div>
											</div>
										</div>
									</label>
								</div>
							)
						})
					)}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button variant='secondary' onClick={toClose}>
					{modalAssignTranslate.btnClose}
				</Button>
				{assignType === 'Responsable' ? (
					<>
						<Button variant='primary' onClick={toClose}>
							{modalAssignTranslate.btnSave}
						</Button>
					</>
				) : (
					<>
						<Button variant='primary' onClick={handleApply}>
							{modalAssignTranslate.btnSave}
						</Button>
					</>
				)}
			</Modal.Footer>
		</Modal>
	)
}

export default ModalAssign
