import { useEffect, useState } from "react";
import { getEmployeesByCompany } from "../../../API/Employee/Employee";
import { getAreaList } from "../../../API/Lists/area";
import { getCountry } from "../../../API/Lists/country";
import { getLevelList } from "../../../API/Lists/level";
import { useIntl } from "react-intl";
import { translate as translateAreas } from "../../../i18n/Library/areasCorrelation";
import { translate as translatePosition } from "../../../i18n/Library/hierarchicalLevel";

export function useListFilters() {
  const getLanguage = useIntl();
  const language = getLanguage.locale === "PT-BR" ? "pt" : "es";
  const [area, setArea] = useState([]);
  const [level, setLevel] = useState([]);
  const [country, setCountry] = useState([]);

  const [filterCountryCheck, setFilterCountryCheck] = useState([]);
  const [filterAreaCheck, setFilterAreaCheck] = useState([]);
  const [filterLevelCheck, setFilterLevelCheck] = useState([]);
  const [search, setSearch] = useState("");

  const [employeeList, setEmployeeList] = useState([]);
  const [filterEmployees, setFilterEmployees] = useState([]);

  useEffect(() => {
    const listRequests = async () => {
      const users = JSON.parse(localStorage.getItem("user"));
      setEmployeeList(await getEmployeesByCompany(users.id));
      setArea([...(await getAreaList())]);
      setLevel([...(await getLevelList())]);
      setCountry([...(await getCountry())]);
    };
    listRequests();
  }, []);

  useEffect(() => {
    const toLowerBusca = search.toLowerCase();
    if (filterCountryCheck.length && filterAreaCheck.length && filterLevelCheck.length) {
      const employeeFilterList = employeeList
        ?.filter((employee) => employee.name.toLowerCase().includes(toLowerBusca))
        .filter(
          (employee) =>
            filterCountryCheck.includes(employee.country) &&
            filterAreaCheck.includes(translateAreas[language][employee.area?.profileArea]) &&
            filterLevelCheck.includes(translatePosition[language][employee.level?.PositionN])
        );
      setFilterEmployees(employeeFilterList);
    } else if (filterAreaCheck.length && filterLevelCheck.length) {
      const employeeFilterList = employeeList
        ?.filter((employee) => employee.name.toLowerCase().includes(toLowerBusca))
        .filter(
          (employee) =>
            filterLevelCheck.includes(translatePosition[language][employee.level?.PositionN]) &&
            filterAreaCheck.includes(translateAreas[language][employee.area?.profileArea])
        );
      setFilterEmployees(employeeFilterList);
    } else if (filterCountryCheck.length && filterAreaCheck.length) {
      const employeeFilterList = employeeList
        ?.filter((employee) => employee.name.toLowerCase().includes(toLowerBusca))
        .filter(
          (employee) =>
            filterAreaCheck.includes(translateAreas[language][employee.area?.profileArea]) &&
            filterCountryCheck.includes(employee.country)
        );
      setFilterEmployees(employeeFilterList);
    } else if (filterCountryCheck.length && filterLevelCheck.length) {
      const employeeFilterList = employeeList
        ?.filter((employee) => employee.name.toLowerCase().includes(toLowerBusca))
        .filter(
          (employee) =>
            filterLevelCheck.includes(translatePosition[language][employee.level?.PositionN]) &&
            filterCountryCheck.includes(employee.country)
        );
      setFilterEmployees(employeeFilterList);
    } else if (filterCountryCheck.length) {
      const employeeFilterList = employeeList
        ?.filter((employee) => employee.name.toLowerCase().includes(toLowerBusca))
        .filter((employee) => filterCountryCheck.includes(employee.country));
      setFilterEmployees(employeeFilterList);
    } else if (filterAreaCheck.length) {
      const employeeFilterList = employeeList
        ?.filter((employee) => employee.name.toLowerCase().includes(toLowerBusca))
        .filter((employee) =>
          filterAreaCheck.includes(translateAreas[language][employee.area?.profileArea])
        );
      setFilterEmployees(employeeFilterList);
    } else if (filterLevelCheck.length) {
      const employeeFilterList = employeeList
        ?.filter((employee) => employee.name.toLowerCase().includes(toLowerBusca))
        .filter((employee) =>
          filterLevelCheck.includes(translatePosition[language][employee.level?.PositionN])
        );
      setFilterEmployees(employeeFilterList);
    } else {
      const employeeFilterList = employeeList?.filter((employee) =>
        employee.name.toLowerCase().includes(toLowerBusca)
      );
      setFilterEmployees(employeeFilterList);
    }
  }, [search, filterCountryCheck, employeeList, filterAreaCheck, filterLevelCheck]);

  return {
    filterEmployees,
    setSearch,
    setFilterAreaCheck,
    setFilterCountryCheck,
    setFilterLevelCheck,
    area,
    level,
    country,
    filterAreaCheck,
    filterCountryCheck,
    filterLevelCheck,
  };
}
