import { useState, useContext } from "react";
import { useHistory, Link } from "react-router-dom";

import { CompanyContext } from "../../Store/CompanyContext";
import { MdErrorOutline } from "react-icons/md";
import { signin } from "../../API/Login/login";
import { loginTranslate } from "../../i18n/translatedMessages";
import { FormattedMessage } from "react-intl";
import Loading from "../../Components/Loading/Loading";

import "./login.sass";

const Login = () => {
  const history = useHistory();
  const { actions } = useContext(CompanyContext);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const handleChange = (event) => {
    setError(false);
    setUser({
      ...user,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleLogin = async () => {
    setLoading(true);
    const auth = await signin(user);
    if (auth) {
      setError(false);
      localStorage.setItem("token", auth.token);
      localStorage.setItem("user", JSON.stringify(auth.response));
      localStorage.setItem("email", JSON.stringify(user.email));

	  const bringElementsForToken = user.email + ":" + user.password;
	  const utf8Bytes = decodeURIComponent(encodeURIComponent(bringElementsForToken));
	  const hash = window.btoa(utf8Bytes);
      localStorage.setItem("hash", hash);

      actions.setLogin(auth.response);
      history.push(auth.response.roleId === 1 || auth.response.roleId === 4  ? "/app" : "/employee");
    } else {
      setLoading(false);
      setError(true);
    }
  };

  return (
    <div className="login">
      {loading && <Loading />}
      <img
        className="companyLogo"
        src="https://aadcdn.msftauthimages.net/dbd5a2dd-q-vgx0wbu9ntyuhzdvuv5jk-4tjk439hs8ffouhilva/logintenantbranding/0/bannerlogo?ts=637565229533956374"
        alt="Logotipo de la empresa"
      ></img>
      <h2>{loginTranslate.textLogin}</h2>
      <div className="group-input">
        <input
          placeholder="Email"
          type="text"
          name="email"
          value={user.email}
          onChange={handleChange}
        />
      </div>
      <div className="group-input">
        <FormattedMessage id={"passPH"}>
          {(msg) => (
            <input
              placeholder={msg}
              type="password"
              name="password"
              value={user.password}
              onChange={handleChange}
            />
          )}
        </FormattedMessage>
        {error && (
          <span className="error">
            <MdErrorOutline size={18} /> {loginTranslate.errorUserPass}
          </span>
        )}
      </div>
      <div className="content-button">
        <Link to="/signup">{loginTranslate.createAccount}</Link>
        <button onClick={handleLogin}>{loginTranslate.login}</button>
      </div>
    </div>
  );
};
export default Login;
