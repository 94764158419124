// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalApplyVacancyMain .btn-img {
  opacity: 0.5;
}
.ModalApplyVacancyMain .modal-content {
  border-radius: 2rem;
  background-color: rgb(249, 249, 249);
}
.ModalApplyVacancyMain .btn-yes {
  border: 2px solid #27E6C4;
  background-color: #27E6C4;
  color: black;
  text-align: center;
  padding-right: 30px;
  padding-left: 30px;
  border-radius: 20px;
  font-size: 18px;
}
.ModalApplyVacancyMain .btn-yes:hover {
  border: 2px solid #0AB4C4;
  background-color: #0AB4C4;
}
.ModalApplyVacancyMain .btn-no {
  border: 2px solid #EC6376;
  background-color: #EC6376;
  color: white;
  text-align: center;
  padding-right: 30px;
  padding-left: 30px;
  border-radius: 20px;
  font-size: 18px;
}
.ModalApplyVacancyMain .btn-no .span-img {
  max-height: 36px;
}
.ModalApplyVacancyMain .btn-no img {
  max-height: 25px;
  max-width: 25px;
}`, "",{"version":3,"sources":["webpack://./src/Views/Employee/Components/ModalApplyVacancy/ModalApplyVacancy.sass"],"names":[],"mappings":"AACE;EACE,YAAA;AAAJ;AACE;EACE,mBAAA;EACA,oCAAA;AACJ;AAAE;EACE,yBAAA;EACA,yBAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;AAEJ;AADI;EACE,yBAAA;EACA,yBAAA;AAGN;AAFE;EACE,yBAAA;EACA,yBAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;AAIJ;AAHI;EACE,gBAAA;AAKN;AAJI;EACE,gBAAA;EACA,eAAA;AAMN","sourcesContent":[".ModalApplyVacancyMain\n  & .btn-img\n    opacity: .5\n  & .modal-content\n    border-radius: 2rem\n    background-color: rgb(249, 249, 249)\n  & .btn-yes\n    border: 2px solid #27E6C4\n    background-color: #27E6C4\n    color: black\n    text-align: center\n    padding-right: 30px\n    padding-left: 30px\n    border-radius: 20px\n    font-size: 18px\n    &:hover\n      border: 2px solid #0AB4C4\n      background-color: #0AB4C4\n  & .btn-no\n    border: 2px solid #EC6376\n    background-color: #EC6376\n    color: white\n    text-align: center\n    padding-right: 30px\n    padding-left: 30px\n    border-radius: 20px\n    font-size: 18px\n    & .span-img\n      max-height: 36px\n    img\n      max-height: 25px\n      max-width: 25px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
