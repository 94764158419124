export const translate = {
	es: {
		'Logística': 'Logística',
		'Marketing': 'Marketing',
		'Operaciones': 'Operaciones',
		'Reclutamiento & Selección': 'Reclutamiento & Selección',
		'Proyectos': 'Proyectos',
		'Administrativo': 'Administrativo',
		'Comunicaciones': 'Comunicaciones',
		'Ingeniería': 'Ingeniería',
		'Comercial': 'Comercial',
		'Supply Chain': 'Supply Chain',
		'Producción': 'Producción',
		'Marketing Digital': 'Marketing Digital',
		'Recursos Humanos': 'Recursos Humanos',
		'Finanzas': 'Finanzas',
		'Business Inteligence': 'Business Inteligence',
		'Bodega': 'Bodega',
		'Career Services': 'Career Services',
		'Admisión': 'Admisión',
		'Internacionalización': 'Internacionalización',
		'Comunicación Interna': 'Comunicación Interna',
		'Servicios Generales': 'Servicios Generales',
		'Tesorería': 'Tesorería',
		'Diplomados': 'Diplomados',
		'Cursos Inhouse': 'Cursos Inhouse',
		'Centro de Patrimonio': 'Centro de Patrimonio',
		'Control de Gestión': 'Control de Gestión',
		'Oficina, Recepción & Secretáriado': 'Oficina, Recepción & Secretáriado',
		'Abastecimiento': 'Abastecimiento',
		'Conducción de Maquinaría': 'Conducción de Maquinaría',
		'Conducción de Vehículos Grandes': 'Conducción de Vehículos Grandes',
		'Conducción de Vehículos Pequeños y Medianos': 'Conducción de Vehículos Pequeños y Medianos',
		'Distribución': 'Distribución',
		'Gestión de Flota': 'Gestión de Flota',
		'Logística Reversa': 'Logística Reversa',
		'Terminales Marítimos': 'Terminales Marítimos',
		'Ultima Milla': 'Ultima Milla',
		'Desarrollo de Negocios': 'Desarrollo de Negocios',
		'Estratégia de Ventas': 'Estratégia de Ventas',
		'Gestión de Cuentas': 'Gestión de Cuentas',
		'Post Venta': 'Post Venta',
		'Pré Venta': 'Pré Venta',
		'Representación & Ejecución de Ventas': 'Representación & Ejecución de Ventas',
		'CEO (Chief Executive Officer)': 'CEO (Chief Executive Officer)',
		'CCO (Chief Communications Officer)': 'CCO (Chief Communications Officer)',
		'CFO (Chief Financial Officer)': 'CFO (Chief Financial Officer)',
		'CHRO (Chief Human Resources Officer)': 'CHRO (Chief Human Resources Officer)',
		'CIO (Chief Information Officer)': 'CIO (Chief Information Officer)',
		'CKO (Chief Knowledge Officer)': 'CKO (Chief Knowledge Officer)',
		'CLO (Chief Legal Officer)': 'CLO (Chief Legal Officer)',
		'CMO (Chief Marketing Officer)': 'CMO (Chief Marketing Officer)',
		'COO (Chief Operating Officer)': 'COO (Chief Operating Officer)',
		'CPO (Chief Product Officer)': 'CPO (Chief Product Officer)',
		'CTO (Chief Technical Officer)': 'CTO (Chief Technical Officer)',
		'Gerencia General': 'Gerencia General',
		'Gerencia General Regional': 'Gerencia General Regional',
		'Presidencia': 'Presidencia',
		'Rectoría': 'Rectoría',
		'Bienestar Estudantil': 'Bienestar Estudantil',
		'Centro de Investigación': 'Centro de Investigación',
		'Profesor': 'Profesor',
		'Profesor Universitario': 'Profesor Universitario',
		'Traducción Inglés': 'Traducción Inglés',
		'Administración & Finanzas': 'Administración & Finanzas',
		'Auditoría': 'Auditoría',
		'Consultoría Financiera': 'Consultoría Financiera',
		'Contable': 'Contable',
		'Contraloría': 'Contraloría',
		'Control de Costos': 'Control de Costos',
		'Controles Internos': 'Controles Internos',
		'Credito & Cobranza': 'Credito & Cobranza',
		'Cuentas a Pagar': 'Cuentas a Pagar',
		'Cuentas a Recibir': 'Cuentas a Recibir',
		'Fusiones & Adquisiciones': 'Fusiones & Adquisiciones',
		'Impuestos': 'Impuestos',
		'Inversiones': 'Inversiones',
		'Planeamiento Estratégico': 'Planeamiento Estratégico',
		'Planificación y Análisis Financiero': 'Planificación y Análisis Financiero',
		'Proyectos Financieros': 'Proyectos Financieros',
		'Relación con Inversionistas': 'Relación con Inversionistas',
		'Seguros': 'Seguros',
		'Arquitectura': 'Arquitectura',
		'Automatización': 'Automatización',
		'Bioingeniería': 'Bioingeniería',
		'Construcción Civil': 'Construcción Civil',
		'Diseño Técnico': 'Diseño Técnico',
		'Eléctrica': 'Eléctrica',
		'Electrónica': 'Electrónica',
		'Geología': 'Geología',
		'Hidráulica': 'Hidráulica',
		'Infraestructura': 'Infraestructura',
		'Mecánica': 'Mecánica',
		'Química': 'Química',
		'Servicio Técnico': 'Servicio Técnico',
		'Sostenibilidad y Medio Ambiente': 'Sostenibilidad y Medio Ambiente',
		'Telecomunicaciones': 'Telecomunicaciones',
		'Topográfica': 'Topográfica',
		'Investigación & Desarrollo': 'Investigación & Desarrollo',
		'Innovación': 'Innovación',
		'Transformación Digital': 'Transformación Digital',
		'Legal': 'Legal',
		'Compliance': 'Compliance',
		'Experiencia del Cliente': 'Experiencia del Cliente',
		'Gestión de Contenido & Redes Sociales': 'Gestión de Contenido & Redes Sociales',
		'Growth': 'Growth',
		'Marca': 'Marca',
		'Marketing & Eventos': 'Marketing & Eventos',
		'Marketing & Ventas': 'Marketing & Ventas',
		'Marketing de Producto': 'Marketing de Producto',
		'Precificación': 'Precificación',
		'Relaciones Públicas': 'Relaciones Públicas',
		'Servicio al Cliente & Telemarketing': 'Servicio al Cliente & Telemarketing',
		'Trade Marketing': 'Trade Marketing',
		'Acreditación': 'Acreditación',
		'Asuntos Regulatórios': 'Asuntos Regulatórios',
		'Comercio Exterior': 'Comercio Exterior',
		'Compras': 'Compras',
		'Confiabilidad': 'Confiabilidad',
		'Contratos': 'Contratos',
		'Control de Calidad': 'Control de Calidad',
		'Corte y Soldadura': 'Corte y Soldadura',
		'Costos y Presupuesto': 'Costos y Presupuesto',
		'Exportaciones': 'Exportaciones',
		'Horno y Pintura': 'Horno y Pintura',
		'Instrumentación': 'Instrumentación',
		'Limpieza': 'Limpieza',
		'Mantención': 'Mantención',
		'Mejora Contínua': 'Mejora Contínua',
		'Montaje': 'Montaje',
		'Planeamiento y Control de Producción': 'Planeamiento y Control de Producción',
		'Planta': 'Planta',
		'Procesos': 'Procesos',
		'Saneamiento': 'Saneamiento',
		'Seguridad Laboral': 'Seguridad Laboral',
		'Seguridad y Vigilancia': 'Seguridad y Vigilancia',
		'Terreno': 'Terreno',
		'Turno': 'Turno',
		'Bienestar': 'Bienestar',
		'Business Partner': 'Business Partner',
		'Capacitaciones': 'Capacitaciones',
		'Desarrollo Organizacional': 'Desarrollo Organizacional',
		'Relaciones Laborales': 'Relaciones Laborales',
		'Remuneración & Benefícios': 'Remuneración & Benefícios',
		'Medicina General': 'Medicina General',
		'Anestesía': 'Anestesía',
		'Asistencia de Enfermaría': 'Asistencia de Enfermaría',
		'Cardiología': 'Cardiología',
		'Cirurgía': 'Cirurgía',
		'Cirurgía plastica': 'Cirurgía plastica',
		'Dermatología': 'Dermatología',
		'Dirección Médica': 'Dirección Médica',
		'Educación Física': 'Educación Física',
		'Enfermaría': 'Enfermaría',
		'Fonoaudiología': 'Fonoaudiología',
		'Gastroentereología': 'Gastroentereología',
		'Gestión Hospitalar': 'Gestión Hospitalar',
		'Gestión Médica': 'Gestión Médica',
		'Ginecología y Obstetricia': 'Ginecología y Obstetricia',
		'Instrumentación Quirúrgica': 'Instrumentación Quirúrgica',
		'Laboratorio': 'Laboratorio',
		'Neorología': 'Neorología',
		'Nutrición': 'Nutrición',
		'Odontología': 'Odontología',
		'Oftalmología': 'Oftalmología',
		'Oncología': 'Oncología',
		'Ortpopedía': 'Ortpopedía',
		'Otorrinolaringología': 'Otorrinolaringología',
		'Pediatría': 'Pediatría',
		'Psicologia': 'Psicologia',
		'Psicoloia laboral': 'Psicoloia laboral',
		'Psiquiatría': 'Psiquiatría',
		'Químico Farmacéutico': 'Químico Farmacéutico',
		'Quinesiología': 'Quinesiología',
		'Salud Animal': 'Salud Animal',
		'Traumatología': 'Traumatología',
		'Urología': 'Urología',
		'Tecnología de la información': 'Tecnología de la información',
		'Agilidad / Scrum': 'Agilidad / Scrum',
		'Arquitectura de Datos': 'Arquitectura de Datos',
		'Arquitectura de Software': 'Arquitectura de Software',
		'Ciencia de Datos': 'Ciencia de Datos',
		'Cloud': 'Cloud',
		'Desarrollo Android': 'Desarrollo Android',
		'Desarrollo Backend': 'Desarrollo Backend',
		'Desarrollo de Producto': 'Desarrollo de Producto',
		'Desarrollo Frontend': 'Desarrollo Frontend',
		'Desarrollo FullStack': 'Desarrollo FullStack',
		'Desarrollo IOS': 'Desarrollo IOS',
		'Devops': 'Devops',
		'Diseño Web': 'Diseño Web',
		'Gestión de Datos': 'Gestión de Datos',
		'Infraestructura & Redes': 'Infraestructura & Redes',
		'Inteligencia Artificial': 'Inteligencia Artificial',
		'Proyectos de TI': 'Proyectos de TI',
		'QA y Funcional': 'QA y Funcional',
		'Seguridad de la información': 'Seguridad de la información',
		'Soporte Técnico': 'Soporte Técnico',
		'UI/ UX Design': 'UI/ UX Design',
	},

	pt: {
		'Logística': 'Logística',
		'Marketing': 'Marketing',
		'Operaciones': 'Operações',
		'Reclutamiento & Selección': 'Recrutamento e seleção',
		'Proyectos': 'Projetos',
		'Administrativo': 'Administrativo',
		'Comunicaciones': 'Comunicações',
		'Ingeniería': 'Engenharia',
		'Comercial': 'Comercial',
		'Supply Chain': 'Cadeia de Suprimentos',
		'Producción': 'Produção',
		'Marketing Digital': 'Marketing Digital',
		'Recursos Humanos': 'Recursos Humanos',
		'Finanzas': 'Financeiro',
		'Business Inteligence': 'Inteligência Empresarial',
		'Bodega': 'Armazém',
		'Career Services': 'Serviços de carreira',
		'Admisión': 'Admissão',
		'Internacionalización': 'Internacionalização',
		'Comunicación Interna': 'Comunicação Interna',
		'Servicios Generales': 'Serviços gerais',
		'Tesorería': 'Tesouraria',
		'Diplomados': 'Diplomas',
		'Cursos Inhouse': 'Cursos internos',
		'Centro de Patrimonio': 'Centro do Patrimônio',
		'Control de Gestión': 'Controle de gestão',
		'Oficina, Recepción & Secretáriado': 'Escritório, Recepção e Secretariado',
		'Abastecimiento': 'Abastecimiento',
		'Conducción de Maquinaría': 'Condução de máquinas',
		'Conducción de Vehículos Grandes': 'Condução de veículos grandes',
		'Conducción de Vehículos Pequeños y Medianos': 'Condução de veículos pequenos e médios',
		'Distribución': 'Distribuição',
		'Gestión de Flota': 'Gestão de Frota',
		'Logística Reversa': 'Lógica reversa',
		'Terminales Marítimos': 'Terminais Marítimos',
		'Ultima Milla': 'Última milha',
		'Desarrollo de Negocios': 'Desenvolvimento de Negócios',
		'Estratégia de Ventas': 'Estratégia de Vendas',
		'Gestión de Cuentas': 'Gestão de contas',
		'Post Venta': 'Pós venda',
		'Pré Venta': 'Pré-venda',
		'Representación & Ejecución de Ventas': 'Representação e Execução de Vendas',
		'CEO (Chief Executive Officer)': 'CEO (Chief Executive Officer)',
		'CCO (Chief Communications Officer)': 'CCO (Chief Communications Officer)',
		'CFO (Chief Financial Officer)': 'CFO (Chief Financial Officer)',
		'CHRO (Chief Human Resources Officer)': 'CHRO (Chief Human Resources Officer)',
		'CIO (Chief Information Officer)': 'CIO (Chief Information Officer)',
		'CKO (Chief Knowledge Officer)': 'CKO (Chief Knowledge Officer)',
		'CLO (Chief Legal Officer)': 'CLO (Chief Legal Officer)',
		'CMO (Chief Marketing Officer)': 'CMO (Chief Marketing Officer)',
		'COO (Chief Operating Officer)': 'COO (Chief Operating Officer)',
		'CPO (Chief Product Officer)': 'CPO (Chief Product Officer)',
		'CTO (Chief Technical Officer)': 'CTO (Chief Technical Officer)',
		'Gerencia General': 'Gerencia Geral',
		'Gerencia General Regional': 'Gestão Geral Regional',
		'Presidencia': 'Presidência',
		'Rectoría': 'Reitoria',
		'Bienestar Estudantil': 'Bem-estar do aluno',
		'Centro de Investigación': 'Centro de Investigação',
		'Profesor': 'Professor',
		'Profesor Universitario': 'Professor Universitario',
		'Traducción Inglés': 'Tradução do inglês',
		'Administración & Finanzas': 'Adiministrativo & Financeiro',
		'Auditoría': 'Auditoria',
		'Consultoría Financiera': 'Consultoria Financeira',
		'Contable': 'Contábil',
		'Contraloría': 'Controladoría',
		'Control de Costos': 'Controle de custos',
		'Controles Internos': 'Controles internos',
		'Credito & Cobranza': 'Crédito e cobranças',
		'Cuentas a Pagar': 'Contas a pagar',
		'Cuentas a Recibir': 'Contas a receber',
		'Fusiones & Adquisiciones': 'Fusões e Aquisições',
		'Impuestos': 'Impostos',
		'Inversiones': 'Investimentos',
		'Planeamiento Estratégico': 'Planejamento estratégico',
		'Planificación y Análisis Financiero': 'Planejamento e Análise Financeira',
		'Proyectos Financieros': 'Projetos Financeiros',
		'Relación con Inversionistas': 'Relações com Investidores',
		'Seguros': 'Seguro',
		'Arquitectura': 'Arquitetura',
		'Automatización': 'Automação',
		'Bioingeniería': 'Bioengenharia',
		'Construcción Civil': 'Contrução civil',
		'Diseño Técnico': 'Design técnico',
		'Eléctrica': 'Elétrico',
		'Electrónica': 'Eletrônicos',
		'Geología': 'Geologia',
		'Hidráulica': 'Hidráulica',
		'Infraestructura': 'Infraestrutura',
		'Mecánica': 'Mecânica',
		'Química': 'Química',
		'Servicio Técnico': 'Serviço técnico',
		'Sostenibilidad y Medio Ambiente': 'Sustentabilidade e Meio Ambiente',
		'Telecomunicaciones': 'Telecomunicações',
		'Topográfica': 'Topográfía',
		'Investigación & Desarrollo': 'Pesquisa e desenvolvimento',
		'Innovación': 'Inovação',
		'Transformación Digital': 'Transformação Digital',
		'Legal': 'Jurídico',
		'Compliance': 'Compliance',
		'Experiencia del Cliente': 'Experiência do cliente',
		'Gestión de Contenido & Redes Sociales': 'Gestão de Conteúdo e Redes Sociais',
		'Growth': 'Growth',
		'Marca': 'Marca',
		'Marketing & Eventos': 'Marketing & Eventos',
		'Marketing & Ventas': 'Marketing & Vendas',
		'Marketing de Producto': 'Marketing de produto',
		'Precificación': 'Precificação',
		'Relaciones Públicas': 'Relações públicas',
		'Servicio al Cliente & Telemarketing': 'Atendimento ao Cliente e Telemarketing',
		'Trade Marketing': 'Trade Marketing',
		'Acreditación': 'Acreditação',
		'Asuntos Regulatórios': 'Questões regulatórias',
		'Comercio Exterior': 'Comércio exterior',
		'Compras': 'Compras',
		'Confiabilidad': 'Confiabilidade',
		'Contratos': 'Contratos',
		'Control de Calidad': 'Controle de qualidade',
		'Corte y Soldadura': 'Corte e Soldagem',
		'Costos y Presupuesto': 'Custos e Orçamento',
		'Exportaciones': 'Exportações',
		'Horno y Pintura': 'Pintura',
		'Instrumentación': 'Instrumentação',
		'Limpieza': 'Limpeza',
		'Mantención': 'Manutenção',
		'Mejora Contínua': 'Melhora contínua',
		'Montaje': 'Montagem',
		'Planeamiento y Control de Producción': 'Planejamento e controle da Produção',
		'Planta': 'Chão de Fábrica',
		'Procesos': 'Processos',
		'Saneamiento': 'Saneamento',
		'Seguridad Laboral': 'Segurança no trabalho',
		'Seguridad y Vigilancia': 'Segurança e vigilância',
		'Terreno': 'Campo',
		'Turno': 'Turno',
		'Bienestar': 'Bem-estar',
		'Business Partner': 'Business Partner',
		'Capacitaciones': 'Treinamentos',
		'Desarrollo Organizacional': 'Desenvolvimento organizacional',
		'Relaciones Laborales': 'Relações de trabalho',
		'Remuneración & Benefícios': 'Compensações',
		'Medicina General': 'Medicina geral',
		'Anestesía': 'Anestesia',
		'Asistencia de Enfermaría': 'Assistência de Enfermagem',
		'Cardiología': 'Cardiologia',
		'Cirurgía': 'Cirurgia',
		'Cirurgía plastica': 'Cirurgia plástica',
		'Dermatología': 'Dermatologia',
		'Dirección Médica': 'Diretor médico',
		'Educación Física': 'Educação Física',
		'Enfermaría': 'Enfermagem',
		'Fonoaudiología': 'Fonoaudiologia',
		'Gastroentereología': 'Gastroenterologia',
		'Gestión Hospitalar': 'Gestão Hospitalar',
		'Gestión Médica': 'Gerenciamento médico',
		'Ginecología y Obstetricia': 'Ginecologia e Obstetrícia',
		'Instrumentación Quirúrgica': 'Instrumentação cirúrgica',
		'Laboratorio': 'Laboratório',
		'Neorología': 'Neorologia',
		'Nutrición': 'Nutrição',
		'Odontología': 'Odontologia',
		'Oftalmología': 'Oftalmologia',
		'Oncología': 'Oncologia',
		'Ortpopedía': 'Ortopedia',
		'Otorrinolaringología': 'Otorrinolaringologia',
		'Pediatría': 'Pediatria',
		'Psicologia': 'Psicologia',
		'Psicoloia laboral': 'Psicologia do trabalho',
		'Psiquiatría': 'Psiquiatria',
		'Químico Farmacéutico': 'Quimico farmaceutico',
		'Quinesiología': 'Cinesiologia',
		'Salud Animal': 'Saúde Animal',
		'Traumatología': 'Traumatologia',
		'Urología': 'Urologia',
		'Tecnología de la información': 'TI',
		'Agilidad / Scrum': 'Agilidade / Scrum',
		'Arquitectura de Datos': 'Arquitetura de dados',
		'Arquitectura de Software': 'Arquitetura de software',
		'Ciencia de Datos': 'Ciência de Dados',
		'Cloud': 'Cloud',
		'Desarrollo Android': 'Desenvolvimento Android',
		'Desarrollo Backend': 'Desenvolvimento de Back-end',
		'Desarrollo de Producto': 'Desenvolvimento de produto',
		'Desarrollo Frontend': 'Desenvolvimento Front-end',
		'Desarrollo FullStack': 'Desenvolvimento FullStack',
		'Desarrollo IOS': 'Desenvolvimento iOS',
		'Devops': 'Devops',
		'Diseño Web': 'Desenho web',
		'Gestión de Datos': 'Gestão de dados',
		'Infraestructura & Redes': 'Infraestrutura e Redes',
		'Inteligencia Artificial': 'Inteligência artificial',
		'Proyectos de TI': 'Projetos de TI',
		'QA y Funcional': 'Controle de qualidade e funcional',
		'Seguridad de la información': 'Segurança da informação',
		'Soporte Técnico': 'Suporte técnico',
		'UI/ UX Design': 'Design de UI/UX',
	},
}
