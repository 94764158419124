import {
	DataPositionsByAreaId,
	DataPositionsByName,
	DataPositionsCompany,
	getAllAreaGroups,
	getAllAreaGroupsByCountry,
	getDataCompanyByEmail,
	getQuestionEmotional,
	getSalaryEmotional,
	getUserInfo,
	sendCreateReport,
} from '../API/Services/PayBalance/SmtmData.js'
const getState = ({ getStore, getActions, setStore }) => {
	return {
		store: {
			companyIndex: 1,
			RoleId: 2,
			comercialContactCompanyUser: [],

			showIframe: false,
			showBonding: false,
			showBondingChangeProf: false,
			showDosStepsForm: false,
			showTresStepsForm: false,
			showDosStepsFormChangeProf1: false,
			showDosStepsFormChangeProf2: false,
			showDosStepsFormChangeProf3: false,
			showSMTM: false,
			showAnalisisDesempeno: false,
			showRemuneracionVariable: false,
			showPayPabalanceUnitReport: false,
			companyPositions: [],
			areaGroups: [],
			areaGroupsByCountry: [],
			allAreasByCountry: [],
			questionEmotional: [],
			dataProfileCompany: [],
			dashboardDataPosition: [],
			listaDeCargos: [],
			descripcionDeCargo: [],
			allAreasCurrentPositions: [],
			allGroupsCurrentPositions: [],
			allFantasyJobsCurrentPositions: [],
			detailDataByArea: [],
			detailDataByGroup: [],
			detailDataByFantasyJob: [],
			dataCorrelationIndustries: [],
			profile_data_company: [
				{
					company_size_id: 1,
					indexCurrentCompany: 0,
					country_id: 1,
					industry_id: null,
				},
			],
			industries: [],
			areas: [],
			indexCurrentCompany: 0,
		},
		actions: {
			showIframe: () => {
				setStore({ showIframe: true })
			},
			showBonding: () => {
				setStore({ showBonding: true })
			},
			showBondingChangeProf: () => {
				setStore({ showBondingChangeProf: true })
			},
			showDosStepsForm: () => {
				setStore({ showDosStepsForm: true })
			},
			showTresStepsForm: () => {
				setStore({ showTresStepsForm: true })
			},
			showDosStepsFormChangeProf1: () => {
				setStore({ showDosStepsFormChangeProf1: true })
			},
			showDosStepsFormChangeProf2: () => {
				setStore({ showDosStepsFormChangeProf2: true })
			},
			showDosStepsFormChangeProf3: () => {
				setStore({ showDosStepsFormChangeProf3: true })
			},
			showSMTM: () => {
				setStore({ showSMTM: true })
			},
			showAnalisisDesempeno: () => {
				setStore({ showAnalisisDesempeno: true })
			},
			showRemuneracionVariable: () => {
				setStore({ showRemuneracionVariable: true })
			},
			showPayPabalanceUnitReport: () => {
				setStore({ showPayPabalanceUnitReport: true })
			},
			hideIframe: () => {
				setStore({ showIframe: false })
			},
			hideBonding: () => {
				setStore({ showBonding: false, showBondingChangeProf: false })
			},
			hideDosStepsForm: () => {
				setStore({
					showDosStepsForm: false,
					showDosStepsFormChangeProf1: false,
					showDosStepsFormChangeProf2: false,
					showDosStepsFormChangeProf3: false,
				})
			},
			hideTresStepsForm: () => {
				setStore({ showTresStepsForm: false })
			},
			hideSMTM: () => {
				setStore({ showSMTM: false })
			},
			hideAnalisisDesempeno: () => {
				setStore({ showAnalisisDesempeno: false })
			},
			hideRemuneracionVariable: () => {
				setStore({ showRemuneracionVariable: false })
			},
			hidePayPabalanceUnitReport: () => {
				setStore({ showPayPabalanceUnitReport: false })
			},

			/*
			 * ! Paybalance and SMTM Actions
			 */

			/*
			 * ! Paybalance and SMTM Actions
			 */

			getDataPositionsCompany: async (companyId, filtros) => {
				const actions = getActions()
				const queryParameters = new URLSearchParams(window.location.search)
				const levelFromDash = queryParameters.get('level')
				const areaFromDash = queryParameters.get('area')

				// Manejar el flujo si hay un área desde el dashboard
				if (areaFromDash) {
					try {
						await actions.getDataPositionsByAreaId(companyId, areaFromDash)
					} catch (error) {
						console.error('Error al obtener datos de la compañía por área:', error)
						// Manejar el error según sea necesario
					}
					return
				}

				// Construir la URL base
				let url = `https://smtm.co/v1/position_companies?company_id=${companyId}`

				// Añadir el filtro de grupo si existe desde el dashboard
				if (levelFromDash) {
					url += `&filtro_grupo=${levelFromDash}`
				}

				// Añadir filtros adicionales si se proporcionan
				if (filtros) {
					if (filtros.filtro_pais !== null && filtros.filtro_pais !== 'undefined') {
						url += `&filtro_pais=${filtros.filtro_pais}`
					}
					if (filtros.filtro_grupo !== null && filtros.filtro_grupo !== 'undefined') {
						url += `&filtro_grupo=${filtros.filtro_grupo}`
					}
				}

				try {
					const resp = await DataPositionsCompany(url)

					if (resp.status === 200) {
						const data = await resp.json()
						setStore({ companyPositions: data })
					} else {
						console.error(`Error al obtener datos de la compañía. Código de estado: ${resp.status}`)
					}
				} catch (error) {
					console.error('Error al obtener datos de la compañía:', error)
					// Manejar el error según sea necesario
				}
			},
			getDataPositionsByName: async (companyId, filtro_nombre) => {
				setStore({ companyPositions: [{}] })
				let url = `https://smtm.co/v1/positions_by_name?company_id=${companyId}&filtro_nombre=${filtro_nombre}`

				try {
					const resp = await DataPositionsByName(url)
					if (resp.status === 200) {
						const data = await resp.json()
						setStore({ companyPositions: data })
					}
				} catch (error) {
					console.error(error)
				}
			},
			getDataPositionsByAreaId: async (companyId, area_id) => {
				setStore({ companyPositions: [{}] })

				const queryParameters = new URLSearchParams(window.location.search)
				const areaFromDash = queryParameters.get('area')
				let url

				//Si viene con QueryParams desde el Dashboard
				if (areaFromDash) {
					url = `https://smtm.co/v1/position_companies?company_id=${companyId}&filtro_area=${areaFromDash}`
				} else {
					url = `https://smtm.co/v1/positions_by_area?company_id=${companyId}&filtro_area=${area_id}`
				}

				try {
					const resp = await DataPositionsByAreaId(url)
					if (resp.status === 200) {
						const data = await resp.json()
						setStore({ companyPositions: data })
					}
				} catch (error) {
					console.error(error)
				}
			},
			allAreaGroups: async () => {
				const resp = await getAllAreaGroups('https://smtm.co/v1/area_groups')
				const data = await resp.json()
				setStore({ areaGroups: data })
			},
			allAreaGroupsByCountry: async (country_id = '1') => {
				getStore()
				let country = country_id
				const resp = await getAllAreaGroupsByCountry(`https://smtm.co/v1/get_benefit_group?authorization=19c073e1768a3cbf6e9d76390e640f3b&country_id=${country}`)
				const data = await resp.json()
				setStore({ areaGroupsByCountry: data })
			},
			questionEmotional: async () => {
				const resp = await getQuestionEmotional(`https://smtm.co/v1/question_emotionals`)
				const data = await resp.json()
				setStore({ questionEmotional: data })
			},
			salaryEmotional: async (question_id, country) => {
				const resp = await getSalaryEmotional(`https://smtm.co/v1/salary_emotional?country=${country}&question_id=${question_id}`)
				const data = await resp.json()
				return data
			},
			createReport: async (report, email, history) => {
				try {
					setStore({ invalidSalary: [] })
					getStore()
					const actions = getActions()
					let country = ''
					let locale = ''
					switch (String(report.country_id)) {
						case '1':
							country = 'CL'
							locale = 'es-CL'
							break
						case '2':
							country = 'AR'
							locale = 'es-AR'
							break
						case '3':
							country = 'PE'
							locale = 'es-PE'
							break
						case '4':
							country = 'BR'
							locale = 'pt-BR'
							break
						case '5':
							country = 'CO'
							locale = 'es-CO'
							break
						case '6':
							country = 'MX'
							locale = 'es-MX'
							break
						case '7':
							country = 'EC'
							locale = 'es-EC'
							break
						default:
							country = 'CL'
							locale = 'es-CL'
					}
					const body = {
						user: {
							email: JSON.parse(localStorage.getItem('email')),
						},
					}
					let variable
					if (report.variable_salary !== 'undefined') {
						variable = report.variable_salary
					} else if (report.variable_salaries !== 'undefined') {
						variable = report.variable_salaries
					}

					let job_fantasia
					let area_fantasia
					let reportFrom
					if (report.job_name !== 'undefined' && report.job_name !== 'Sin data') {
						reportFrom = 'payb'
						job_fantasia = report.job_name
						area_fantasia = report.subArea
					} else {
						reportFrom = 'nopayb'
						job_fantasia = 'Sin data'
						area_fantasia = 'Sin data'
					}
					const resp = await sendCreateReport(
						`https://smtm.co/report?city_id=${report.city_id}&country_id=${report.country_id}&job_id=${report.job_id}&current_salary_aux=${report.salary}&variable_salaries=${parseInt(variable)}&industry_id=${
							report.industry_id
						}&english_level_id=${report.english_level_id}&company_size_id=${report.company_size_id}&seniority_id=${report.seniority_id}&qualification_id=${
							report.qualification_id
						}&country_code=${country}&locale=${locale}&payment_method=webpay&report_type=company&people_report=false&trans_areas=%C3%81rea%20de%20actuaci%C3%B3n&trans_jobs=Posici%C3%B3n&company_size_id_aux=${
							report.company_size_id
						}&current_salary=${report.current_salary}&area_id=${report.area_id}&language=native&reportfrom=${reportFrom}&job_fantasia=${job_fantasia}&area_fantasia=${area_fantasia}`,
						{
							method: 'POST',
							headers: { 'Content-Type': 'application/json' },
							body: JSON.stringify(body),
						}
					)
					const data = await resp.json()
					if (resp.status === 200) {
						if (data.hasOwnProperty('modal')) {
							setStore({ invalidSalary: data.modal.error })
							return resp
						} else {
							if (data.hasOwnProperty('report_sent')) {
								setStore({
									last_report_code: data.report_sent.report_info.params.code,
								})
							}

							actions.userInfo('64402', 1)
							return resp.status
						}
					}
				} catch (error) {
					console.error(error)
				}
			},
			userInfo: async (id, pag = 1) => {
				try {
					const resp = await getUserInfo(`https://smtm.co/v1/user_info?authorization=19c073e1768a3cbf6e9d76390e640f3b&id=${id}&page=${pag}`)
					if (resp.status === 200) {
						const data = await resp.json()
						setStore({ userReport: data })
					} else {
						pag = pag - 1
						setStore({ pagination: pag })
					}
				} catch (error) {
					console.error(error)
				}
			},
			getDataCompany: async email => {
				console.log('entrou no getDataCompany linha 362')
				const respProfileCompany = await getDataCompanyByEmail(`https://smtm.co/v1/data_comany_from_email?email=${email}`)
				const dataProfileCompany = await respProfileCompany.json()
				if (dataProfileCompany !== 'No hay empresas creadas') {
					setStore({ profile_data_company: dataProfileCompany })
				}
			},
			setDashboardDataPositions: async companyId => {
				if (companyId !== null) {
					try {
						const resp = await fetch(`https://smtm.co/v1/positions_for_dashboard_salary?company_id=${companyId}`)
						if (resp.status === 200) {
							const data = await resp.json()
							setStore({ dashboardDataPosition: data })
						}
					} catch (error) {
						console.error(error)
					}
				}
			},
			allAreasByCountry: async () => {
				const store = getStore()

				let country = ''
				let company = ''

				if (store.profile_data_company[0].hasOwnProperty('country_id') && store.profile_data_company[0].hasOwnProperty('industry_id')) {
					let currentCompanyIndustry = store.profile_data_company[0].industry_id
					let currentCompanyCountry = store.profile_data_company[0].country_id
					company = currentCompanyIndustry

					switch (currentCompanyCountry) {
						case 1:
							country = 'CL'
							break
						case 2:
							country = 'AR'
							break
						case 3:
							country = 'PE'
							break
						case 4:
							country = 'BR'
							break
						case 5:
							country = 'CO'
							break
						case 6:
							country = 'MX'
							break
						case 7:
							country = 'EC'
							break
						default:
							country = 'CL'
					}
				} else {
					country = 'CL'
					company = '1'
				}

				const resp = await fetch(`https://smtm.co/v1/get_areas?authorization=19c073e1768a3cbf6e9d76390e640f3b&country=${country}&industry_id=${company}`)
				const data = await resp.json()
				setStore({ allAreasByCountry: data })
			},
			getDataCorrelationIndustries: async job_name => {
				const store = getStore()
				let country_id = store.profile_data_company[0].country_id
				try {
					const resp = await fetch(`https://smtm.co/v1/get_factor_industry?country_id=${country_id}`)
					const data = await resp.json()
					setStore({ dataCorrelationIndustries: data })
				} catch (error) {
					console.error(error)
				}
			},
			getAllAreasCurrentPositions: async () => {
				const store = getStore()
				let areas = store.dashboardDataPosition
				let hash = {}
				let areasPosition = []
				areas = areas.filter(o => (hash[o.area_id] ? false : (hash[o.area_id] = true)))
				for (let index = 0; index < areas.length; index++) {
					let area_id = areas[index].area_id
					let country_id = areas[index].country_id
					store.allAreasByCountry.forEach(area => {
						if (area.value === area_id) {
							areasPosition.push({
								value: area.value,
								name: area.name,
								country_id: country_id,
							})
						}
					})
				}
				setStore({ allAreasCurrentPositions: areasPosition })
			},
			getAllGroupsCurrentPositions: async () => {
				const store = getStore()
				let groups = store.dashboardDataPosition
				let hash = {}
				let groupsPosition = []
				groups = groups.filter(o => (hash[o.group] ? false : (hash[o.group] = true)))
				for (let index = 0; index < groups.length; index++) {
					let group_name = groups[index].group
					store.areaGroupsByCountry.forEach(group => {
						if (group.name === group_name) {
							groupsPosition.push({
								id: group.id,
								name: group.name,
							})
						}
					})
				}
				setStore({ allGroupsCurrentPositions: groupsPosition })
			},
			// getDataCorrelationIndustries: async job_name => {
			// 	const store = getStore()
			// 	let country_id = store.profile_data_company[0].country_id
			// 	try {
			// 		const resp = await fetch(`https://smtm.co/v1/get_factor_industry?country_id=${country_id}`)
			// 		const data = await resp.json()
			// 		setStore({ dataCorrelationIndustries: data })
			// 	} catch (error) {
			// 		console.error(error)
			// 	}
			// },
			// ATENCION. Los metodos all...Current son metodos que filtrar las posiciones sin repetir dependiendo del criterio, para luego utilizarlas dentro de otros metodos
			getAllGroupsCurrentFantasyJobs: async () => {
				const store = getStore()

				var array = store.dashboardDataPosition

				var hash = {}
				array = array.filter(function (current) {
					var exists = !hash[current.job_name]
					hash[current.job_name] = true
					return exists
				})

				setStore({ allFantasyJobsCurrentPositions: array })
			},
			allIndustries: async () => {
				const resp = await fetch('https://smtm.co/v1/industries')
				const data = await resp.json()
				setStore({ industries: data })
			},

			allAreas: async () => {
				const resp = await fetch('https://smtm.co/v1/areas')
				const data = await resp.json()
				setStore({ areas: data })
			},

			allCompaniesSizes: async () => {
				const resp = await fetch('https://smtm.co/v1/company_sizes')
				const data = await resp.json()
				setStore({ companySizes: data })
			},
		},
	}
}
export default getState
