export const getAbilities = async (employeeId) => {
    try {
      const resp = await fetch(`${process.env.REACT_APP_API}/motivation/resultMycoHabilities/${employeeId}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return resp.json();
    } catch (err) {
      console.error(err);
    }
  };
  
  export const getMotivations = async (employeeId) => {
    try {
      const resp = await fetch(`${process.env.REACT_APP_API}/motivation/resultMycoMotivations/${employeeId}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return resp.json();
    } catch (err) {
      console.error(err);
    }
  };
  