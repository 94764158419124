export const potencialValue = [
	{
		id: 1,
		title: {
			es: 'Desarrollo Profesional',
			pt: 'Desenvolvimento profissional'
		},
		content: {
			es: 'Identificar y apoyar el desarrollo de habilidades necesarias para roles futuros.',
			pt: 'Identificar e apoiar o desenvolvimento de habilidades necessárias para funções futuras.'
		}
	},
	{
		id: 2,
		title: {
			es: 'Sucesión y Planificación de Carrera',
			pt: 'Sucessão e Planejamento de Carreira'
		},
		content: {
			es: 'Preparar a los empleados para asumir roles de liderazgo o críticos en la organización a medida que surjan vacantes',
			pt: 'Preparar os funcionários para assumirem liderança ou funções críticas na organização à medida que surgem vagas'
		}
	},
	{
		id: 3,
		title: {
			es: 'Retención de Talentos',
			pt: 'Retenção de talentos'
		},
		content: {
			es: 'Mantener empleados altamente capacitados y comprometidos mediante el apoyo a su crecimiento y desarrollo.',
			pt: 'Manter funcionários altamente treinados e engajados, apoiando seu crescimento e desenvolvimento.'
		}
	},
]

export const executivePotencial = [
	{
		id: 1,
		title: {
			es: 'Alto potencial de un ejecutivo',
			pt: 'Alto potencial de um executivo'
		},
		content: {
			es: 'El "alto potencial de un ejecutivo" se refiere a la capacidad demostrada por un líder dentro de una organización para sobresalir en roles de mayor responsabilidad y desafío en el futuro, basada en sus habilidades, competencias, comportamientos y potencial de desarrollo identificados y evaluados a través de procesos de selección y evaluación específicos.',
			pt: '“Executivo de alto potencial” refere-se à capacidade demonstrada de um líder dentro de uma organização para se destacar em funções de maior responsabilidade e desafio no futuro, com base nas suas habilidades, competências, comportamentos e potencial de desenvolvimento identificados e avaliados através de processos específicos de seleção e avaliação.'
		}
	},
	{
		id: 2,
		title: {
			es: 'Potencial mediano de un ejecutivo',
			pt: 'Potencial mediano de um executivo'
		},
		content: {
			es: 'El "potencial mediano de un ejecutivo" se refiere a la capacidad y habilidades de un líder dentro de una organización para desempeñar roles de responsabilidad intermedia con eficacia y eficiencia, mostrando un nivel de competencia sólido pero con un menor margen de proyección para roles de liderazgo más senior en el futuro en comparación con aquellos identificados como de alto potencial.',
			pt: 'O “potencial executivo mediano” refere-se à capacidade e habilidades de um líder dentro de uma organização para desempenhar funções de responsabilidade média de forma eficaz e eficiente, demonstrando um nível sólido de competência, mas com menos espaço para projeção para funções de liderança mais seniores no futuro em comparação com. aqueles identificados como de alto potencial.'
		}
	},
	{
		id: 3,
		title: {
			es: 'Bajo potencial de un ejecutivo',
			pt: 'Baixo potencial de um executivo'
		},
		content: {
			es: 'El "bajo potencial de un ejecutivo" se refiere a la evaluación de un líder dentro de una organización que indica limitaciones significativas en términos de habilidades, competencias y capacidades para asumir roles de mayor responsabilidad o liderazgo sénior en el futuro, lo que sugiere la necesidad de un desarrollo adicional o la asignación a roles más específicos y menos exigentes en la organización.',
			pt: '“Executivo baixo potencial” refere-se à avaliação de um líder dentro de uma organização que indica limitações significativas em termos de habilidades, competências e capacidades para assumir funções de liderança mais responsáveis ​​ou seniores no futuro, sugerindo a necessidade de desenvolvimento adicional ou atribuição de funções mais específicas. e funções menos exigentes na organização.'
		}
	},
]

export const potencialCriterias = [
	{
		id: 1, es: 'Liderazgo inspirador',
		pt: 'Liderança inspiradora'
	},
	{ id: 2, es: 'Inteligencia emocional', pt: 'Inteligência emocional' },
	{ id: 3, es: 'Capacidad de resolución de problemas', pt: 'Habilidades de resolução de problemas' },
	{ id: 4, es: 'Visión estratégica', pt: 'Visão estratégica' },
	{ id: 5, es: 'Comunicación efectiva', pt: 'Comunicação eficaz' },
	{ id: 6, es: 'Actitud proactiva', pt: 'Atitude proativa' },
	{ id: 7, es: 'Capacidad de trabajo en equipo', pt: 'Capacidade de trabalho em equipe' },
	{ id: 8, es: 'Adaptabilidad al cambio', pt: 'Adaptabilidade à mudança' },
	{ id: 9, es: 'Capacidad de toma de decisiones', pt: 'Capacidade de tomada de decisão' },
	{ id: 10, es: 'Innovació', pt: 'Inovação' },
	{ id: 11, es: 'Orientación a resultados', pt: 'Orientação para resultados' },
	{ id: 12, es: 'Perseverancia', pt: 'Perseverança' },
	{ id: 13, es: 'Habilidades de negociación', pt: 'Habilidades de negociação' },
	{ id: 14, es: 'Ética profesional', pt: 'Ética profissional' },
	{ id: 15, es: 'Capacidad de motivar a otros', pt: 'Capacidade de motivar outras pessoas' },
	{ id: 16, es: 'Networking', pt: 'Rede' },
	{ id: 17, es: 'Inteligencia empresarial', pt: 'Inteligência empresarial' },
	{ id: 18, es: 'Habilidades de delegación', pt: 'Habilidades de delegação' },
	{ id: 19, es: 'Empatía', pt: 'Empatia' },
	{ id: 20, es: 'Capacidad de liderazgo situacional', pt: 'Capacidade de liderança situacional' },
	{ id: 21, es: 'Resiliencia', pt: 'Resiliência' },
	{ id: 22, es: 'Pensamiento estratégico', pt: 'Pensamento estratégico' },
	{ id: 23, es: 'Habilidades interpersonales', pt: 'Habilidades interpessoais' },
	{ id: 24, es: 'Orientación al cliente', pt: 'Orientação para o cliente' },
	{ id: 25, es: 'Creatividad', pt: 'Criatividade' },
	{ id: 26, es: 'Gestión del tiempo', pt: 'Gerenciamento de tempo' },
	{ id: 27, es: 'Habilidades de coaching', pt: 'Habilidades de coaching' },
	{ id: 28, es: 'Toma de riesgos calculados', pt: 'Assumir riscos calculados' },
	{ id: 29, es: 'Capacidad de influencia', pt: 'Capacidade de influenciar' },
	{ id: 30, es: 'Conocimiento del mercado', pt: 'Conhecimento de mercado' },
	{ id: 31, es: 'Capacidad de planificación', pt: 'Capacidade de planejamento' },
	{ id: 32, es: 'Asertividad', pt: 'Assertividade' },
	{ id: 33, es: 'Trabajo bajo presión', pt: 'Trabalhe sob pressão' },
	{ id: 34, es: 'Capacidad de aprender de los errores', pt: 'Capacidade de aprender com os erros' },
	{ id: 35, es: 'Orientación al detalle', pt: 'Orientação detalhada' },
	{ id: 36, es: 'Pensamiento crítico', pt: 'Pensamento crítico' },
	{ id: 37, es: 'Empowerment', pt: 'Empoderamento' },
	{ id: 38, es: 'Orientación al aprendizaje continuo', pt: 'Orientação para aprendizagem contínua' },
	{ id: 39, es: 'Habilidades de presentación', pt: 'Habilidades de apresentação' },
	{ id: 40, es: 'Gestión del cambio', pt: 'Gestão de mudanças' },
	{ id: 41, es: 'Flexibilidad', pt: 'Flexibilidade' },
	{ id: 42, es: 'Pensamiento analítico', pt: 'Pensamento analítico' },
	{ id: 43, es: 'Capacidad de construir relaciones sólidas', pt: 'Capacidade de construir relacionamentos fortes' },
	{ id: 44, es: 'Tolerancia a la ambigüedad', pt: 'Tolerância à ambigüidade' },
	{ id: 45, es: 'Mentalidad de crecimiento', pt: 'Mentalidade de crescimento' },
	{ id: 46, es: 'Gestión del conflicto', pt: '​​​​Gestão de conflitos' },
	{ id: 47, es: 'Innovación disruptiva', pt: 'Inovação disruptiva' },
	{ id: 48, es: 'Visión global', pt: 'Visão geral' },
	{ id: 49, es: 'Pensamiento lateral', pt: 'Pensamento lateral' },
	{ id: 50, es: 'Orientación al empoderamiento', pt: 'Orientação para o empoderamento' },
	{ id: 51, es: 'Capacidades digitales', pt: 'Capacidades digitais' },
	{ id: 52, es: 'Resolución de conflictos', pt: 'Resolução de conflitos' },
	{ id: 53, es: 'Capacidad de automotivación', pt: 'Capacidade de automotivação' },
	{ id: 54, es: 'Toma de decisiones éticas', pt: 'Tomada de decisão ética' },
	{ id: 55, es: 'Capacidades de influencia sin autoridad', pt: 'Capacidades de influência sem autoridade' },
	{ id: 56, es: 'Análisis de riesgos', pt: 'Análise de risco' },
	{ id: 57, es: 'Mentalidad emprendedora', pt: 'Mentalidade empreendedora' },
	{ id: 58, es: 'Colaboración', pt: 'Colaboração' },
	{ id: 59, es: 'Capacidad de influenciar sin autoridad', pt: 'Capacidade de influenciar sem autoridade' },
	{ id: 60, es: 'Gestión de la diversidad', pt: 'Gestão da diversidade' },
	{ id: 61, es: 'Capacidad para trabajar en entornos multiculturales', pt: 'Capacidade de trabalhar em ambientes multiculturais' },
	{ id: 62, es: 'Pensamiento sistémico', pt: 'Pensamento sistêmico' },
	{ id: 63, es: 'Compromiso con la excelencia', pt: 'Compromisso com a excelência' },
	{ id: 64, es: 'Toma de decisiones basada en datos', pt: 'Tomada de decisão baseada em dados' },
	{ id: 65, es: 'Orientación al cliente interno', pt: 'Orientação para o cliente interno' },
	{ id: 66, es: 'Promoción del trabajo en equipo', pt: 'Promoção do trabalho em equipe' },
	{ id: 67, es: 'Habilidades de resolución de problemas complejos', pt: 'Habilidades de resolução de problemas complexos' },
	{ id: 68, es: 'Capacidad de formar equipos de alto rendimiento', pt: 'Capacidade de formar equipes de alto desempenho' },
	{ id: 69, es: 'Liderazgo con integridad', pt: 'Liderança com integridade' },
	{ id: 70, es: 'Adaptabilidad a entornos cambiantes', pt: 'Adaptabilidade a ambientes em mudança' },
	{ id: 71, es: 'Visión a largo plazo', pt: 'Visão de longo prazo' },
	{ id: 72, es: 'Habilidades de influencia persuasiva', pt: 'Habilidades de influência persuasiva' },
	{ id: 73, es: 'Capacidad de construir alianzas estratégicas', pt: 'Capacidade de construir alianças estratégicas' },
	{ id: 74, es: 'Análisis de tendencias', pt: 'Análise de tendências' },
	{ id: 75, es: 'Capacidad de motivar a través de la visión', pt: 'Capacidade de motivar através da visão' },
	{ id: 76, es: 'Gestión de la innovación', pt: 'Gestão da inovação' },
	{ id: 77, es: 'Habilidades de mentoring', pt: 'Habilidades de mentoria' },
	{ id: 78, es: 'Capacidades de storytelling', pt: 'Capacidades de contar histórias' },
	{ id: 79, es: 'Hardware de autoconocimiento', pt: 'Hardware de autoconhecimento' },
	{ id: 80, es: 'Capacidad de generar confianza', pt: 'Capacidade de gerar confiança' },
	{ id: 81, es: 'Gestión de la complejidad', pt: 'Gestão de complexidade' },
	{ id: 82, es: 'Pensamiento creativo', pt: 'Pensamento criativo' },
	{ id: 83, es: 'Innovación en procesos', pt: 'Inovação em processos' },
	{ id: 84, es: 'Promoción de la diversidad e inclusión', pt: 'Promoção da diversidade e inclusão' },
	{ id: 85, es: 'Capacidad de liderar con empatía', pt: 'Capacidade de liderar com empatia' },
	{ id: 86, es: 'Gestión del conocimiento', pt: 'Gestão do conhecimento' },
	{ id: 87, es: 'Habilidades de guerra de talentos', pt: 'Habilidades de guerra de talentos' },
	{ id: 88, es: 'Capacidad de feedback constructivo', pt: 'Capacidade de feedback construtivo' },
	{ id: 89, es: 'Orientación a la mejora continua', pt: 'Orientação para melhoria contínua' },
	{ id: 90, es: 'Capacidad de construir una cultura organizacional sólida', pt: 'Capacidade de construir uma cultura organizacional sólida' },
	{ id: 91, es: 'Adaptabilidad a entornos disruptivos', pt: 'Adaptabilidade a ambientes disruptivos' },
	{ id: 92, es: 'Gestión de crisis', pt: 'Gestão de crises' },
	{ id: 93, es: 'Habilidades de coaching ejecutivo', pt: 'Habilidades de coaching executivo' },
	{ id: 94, es: 'Capacidad de generar alineación estratégica', pt: 'Capacidade de gerar alinhamento estratégico' },
	{ id: 95, es: 'Inteligencia cultural', pt: 'Inteligência cultural' },
	{ id: 96, es: 'Habilidad para potenciar el talento', pt: 'Capacidade de aprimorar talentos' },
	{ id: 97, es: 'Capacidad para mantener la calma en situaciones de crisis', pt: 'Capacidade de manter a calma em situações de crise' },
	{ id: 98, es: 'Gestión de equipos virtuales', pt: 'Gerenciamento de equipe virtual' },
	{ id: 99, es: 'Capacidad de liderar con resiliencia', pt: 'Capacidade de liderar com resiliência' },
	{ id: 100, es: 'Desarrollo de liderazgo en otros', pt: 'Desenvolvimento de liderança em outras pessoas' }
]















