// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel-culture .box-title {
  height: 40px;
  width: 650px;
  background-color: #444258;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  margin-top: 50px;
  color: white;
}
.carousel-culture .title {
  color: white;
}
.carousel-culture .box-content {
  border-radius: 20px;
  height: 320px;
  width: 650px;
  background-color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.carousel-culture .arrow {
  border: solid black;
  order-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}
.carousel-culture .triangle-left {
  width: 17px;
  height: 17px;
  border: 4px solid white;
  border-left: 0;
  border-top: 0;
  background: transparent;
  transform: rotate(135deg);
}
.carousel-culture .triangle-right {
  width: 17px;
  height: 17px;
  border: 4px solid white;
  border-left: 0;
  border-top: 0;
  background: transparent;
  transform: rotate(315deg);
}
.carousel-culture .icon {
  width: 70px;
  height: 70px;
  margin: 50px;
}
.carousel-culture .text {
  font-size: 16px;
  margin-right: 50px;
}`, "",{"version":3,"sources":["webpack://./src/Views/Analytics/Components/Carousel/Carousel.sass"],"names":[],"mappings":"AAEE;EACE,YAAA;EACA,YAAA;EACA,yBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;EACA,YAAA;AADJ;AAGE;EACE,YAAA;AADJ;AAGE;EACE,mBAAA;EACA,aAAA;EACA,YAAA;EACA,uBAAA;EACA,aAAA;EACA,6BAAA;EACA,mBAAA;EACA,2CAAA;AADJ;AAGE;EACE,mBAAA;EACA,wBAAA;EACA,qBAAA;EACA,YAAA;AADJ;AAGE;EACE,WAAA;EACA,YAAA;EACA,uBAAA;EACA,cAAA;EACA,aAAA;EACA,uBAAA;EACA,yBAAA;AADJ;AAGE;EACE,WAAA;EACA,YAAA;EACA,uBAAA;EACA,cAAA;EACA,aAAA;EACA,uBAAA;EACA,yBAAA;AADJ;AAGE;EACE,WAAA;EACA,YAAA;EACA,YAAA;AADJ;AAIE;EACE,eAAA;EACA,kBAAA;AAFJ","sourcesContent":[".carousel-culture\n    \n  .box-title\n    height: 40px\n    width: 650px\n    background-color: #444258\n    display: flex\n    justify-content: space-between\n    align-items: center\n    border-radius: 20px\n    margin-top: 50px\n    color: white\n\n  & .title\n    color: white\n\n  & .box-content\n    border-radius: 20px\n    height: 320px\n    width: 650px\n    background-color: white\n    display: flex\n    justify-content: space-evenly\n    align-items: center\n    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px\n  \n  & .arrow \n    border: solid black\n    order-width: 0 3px 3px 0\n    display: inline-block\n    padding: 3px\n  \n  & .triangle-left\n    width: 17px\n    height: 17px\n    border: 4px solid white\n    border-left: 0\n    border-top: 0\n    background: transparent\n    transform: rotate(135deg)\n  \n  & .triangle-right\n    width: 17px\n    height: 17px\n    border: 4px solid white\n    border-left: 0\n    border-top: 0\n    background: transparent\n    transform: rotate(315deg)\n\n  & .icon\n    width: 70px\n    height: 70px\n    margin: 50px\n\n  \n  & .text\n    font-size: 16px\n    margin-right: 50px\n    \n\n  \n\n\n  \n  \n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
