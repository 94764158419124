class DocumentsTemplateService {
    
    async createDocuments (data) {
        const response = await fetch(`${process.env.REACT_APP_API}/documents/documents_template`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(data),
        })

        return response.json()
    }

    async getAllDocuments (id) {
        const response = await fetch(`${process.env.REACT_APP_API}/documents/documents_template/${id}`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
            },
        })

        return response.json()
    }

    async deleteDocuments (data, id) {
        const response = await fetch(`${process.env.REACT_APP_API}/documents/documents_template/${id}`, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(data),
        })

        return response.json()
    }
}
export default new DocumentsTemplateService();