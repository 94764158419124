import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";

import * as RB from "react-bootstrap";

import {
    personalInfoTranslate,
    wheelCollaboratorTranslate,
} from "../../../../i18n/translatedMessages";
import FormProfInfo from "../FormsInfo/FormProfInfo";

export const AccordionItem = ({
    item,
    index,
    select = 0,
    onDelete,
    onUpdate,
    onClick,
    onChange
}) => {
    const { onSelect } = useContext(RB.AccordionContext);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => onSelect(select), [select]);

    return (
        <RB.Accordion.Item
            className="shadow my-3"
            eventKey={index}>
            <RB.Accordion.Header>
                {item.id && item.currentJob ? (
                    <>
                        {item.job} en {item.company} de {item.startMonth}/
                        {item.startYear} {wheelCollaboratorTranslate.aData}
                    </>
                ) : item.id &&
                    (item.currentJob == null || item.currentJob === false) ? (
                    <>
                        {item.job} {personalInfoTranslate.personalConectiveProf1}{" "}
                        {item.company} de {item.startMonth}/{item.startYear}{" "}
                        {personalInfoTranslate.personalConectiveProf2}{" "}
                        {item.endMonth}/{item.endYear}
                    </>
                ) : (
                    <>{personalInfoTranslate.personalAddInfoProf}</>
                )}
            </RB.Accordion.Header>
            <RB.Accordion.Body>
                <FormProfInfo
                    data={item}
                    index={index}
                    deleteProfInfo={onDelete}
                    updateProfInfo={onUpdate}
                    handleChange={onChange}
                    handleSend={onClick}
                />
            </RB.Accordion.Body>
        </RB.Accordion.Item>
    );
};

AccordionItem.prototype = {
    item: PropTypes.object,
    index: PropTypes.number,
    select: PropTypes.number,
    onDelete: PropTypes.func,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    onUpdate: PropTypes.func,
};
