export const getDataCompany = async (id) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API}/companies/${id}`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
      },
    });
    return response.json();
  } catch (error) {
    console.error(error);
  }
};

export const postAssetsCompany = async (data) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/assetsuploader/company`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};
export const getAssetsCompany = async (companyId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API}/assetsuploader/company/${companyId}`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
      },
    });
    return response.json();
  } catch (error) {
    console.error(error);
  }
};
export const delAssetsCompany = async (assetsId) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/assetsuploader/company/${assetsId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};

export const putCompany = async (data, companyId) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/companies/individual/${companyId}`,
      {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};

export const postCompanyProducts = async (data) => {
  try {
      const resp = await fetch(`${process.env.REACT_APP_API}/companies/products`, {
          method: "POST",
          headers: {
              "Content-type": "application/json",
          },
          body: JSON.stringify(data),
      });
      return resp.json();
  } catch (err) {
      console.error(err);
  }
};

export const putCompanyProducts = async (data, companyId) => {
    try {
      const resp = await fetch(`${process.env.REACT_APP_API}/companies/products/${companyId}`,
        {
          method: "PUT",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      return resp.json();
    } catch (err) {
      console.error(err);
    }
  };

export const getCompanyProducts = async (companyId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API}/companies/products/${companyId}`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
      },
    });
    return response.json();
  } catch (error) {
    console.error(error);
  }
};

export const deleteCompanyProducts = async (id) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/companies/products/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};