export const questionsSalida = [
    {
        title: "RECURSOS, MEDIO AMBIENTE Y CULTURA",
        header: [
            "Muy Insatisfecho",
            "Insatisfecho",
            "Satisfecho",
            "Muy Satisfecho",
        ],
        data: [
            {
                id: 1,
                question:
                    "¿Las relaciones personales con tus colegas eran generalmente buenas?",
                inputName: "question1",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 2,
                question:
                    "¿La empresa y el equipo directivo brindaron suficientes iniciativas, canales y espacios para fomentar las buenas relaciones con tus colegas?",
                inputName: "question2",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 3,
                question:
                    "¿Contabas con los recursos, herramientas y entornos necesarios, ya sean físicos o digitales, para llevar a cabo tus tareas y alcanzar los objetivos?",
                inputName: "question3",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 4,
                question:
                    "¿Eran coherentes la cultura y los valores de la empresa con tus valores personales?",
                inputName: "question4",
                answers: ["1", "2", "3", "4"],
            },
        ],
    },
    {
        title: "ACTIVIDADES Y FUNCIONES REALIZADAS",
        header: [
            "Muy Insatisfecho",
            "Insatisfecho",
            "Satisfecho",
            "Muy Satisfecho",
        ],
        data: [
            {
                id: 5,
                question:
                    "¿El volumen de trabajo realizado era el adecuado para tu cargo y perfil?",
                inputName: "question5",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 6,
                question:
                    "¿Tu puesto era consistente con tus habilidades, conocimientos y antigüedad profesional?",
                inputName: "question6",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 7,
                question:
                    "¿Eran claros tus objetivos, tareas y entregables establecidos?",
                inputName: "question7",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 8,
                question:
                    "¿Eran tus responsabilidades acordes con el puesto que ocupabas?",
                inputName: "question8",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 9,
                question:
                    "¿Tenías claridad y percepción de valor del impacto de las tareas en los objetivos de la empresa?",
                inputName: "question9",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 10,
                question:
                    "¿El modelo de trabajo (presencial, híbrido u home office) era adecuado para el desempeño de tus funciones?",
                inputName: "question10",
                answers: ["1", "2", "3", "4"],
            },
        ],
    },
    {
        title: "LIDERAZGO",
        header: [
            "Muy Insatisfecho",
            "Insatisfecho",
            "Satisfecho",
            "Muy Satisfecho",
        ],
        data: [
            {
                id: 11,
                question:
                    "¿Tu líder escuchaba y consideraba tus opiniones al tomar decisiones?",
                inputName: "question11",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 12,
                question:
                    "¿Era adecuada la evaluación de tu desempeño y el reconocimiento de tus contribuciones?",
                inputName: "question12",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 13,
                question:
                    "Tu líder mantenía un nivel adecuado de exigencia a tu posición y carga de trabajo?",
                inputName: "question13",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 14,
                question:
                    "¿Tenía tu líder el conocimiento técnico, las habilidades generales y el apoyo necesarios para que tu tuvieras éxito en tus actividades?",
                inputName: "question14",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 15,
                question:
                    "¿Te sentías cómodo compartiendo dificultades, incomodidades o pidiendo retroalimentación cuando era necesario?",
                inputName: "question15",
                answers: ["1", "2", "3", "4"],
            },
        ],
    },
    {
        title: "OPORTUNIDAD Y CARRERA",
        header: [
            "Muy Insatisfecho",
            "Insatisfecho",
            "Satisfecho",
            "Muy Satisfecho",
        ],
        data: [
            {
                id: 16,
                question: "¿Había motivaciones y desafíos en tus funciones?",
                inputName: "question16",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 17,
                question:
                    "¿Había autonomía para aplicar tus habilidades y tomar decisiones adecuadas a tu cargo?",
                inputName: "question17",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 18,
                question:
                    "¿Había posibilidad de promoción y/o plan de carrera bien claros y definidas?",
                inputName: "question18",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 19,
                question:
                    "¿Había inversión de la empresa en desarrollo de carrera?",
                inputName: "question19",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 20,
                question:
                    "¿Había espacio para expresar tus ambiciones y aplicar a posiciones más altas dentro de la organización?",
                inputName: "question20",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 21,
                question:
                    "¿Había claridad acerca de lo que es necesario para crecer en la compañía?",
                inputName: "question21",
                answers: ["1", "2", "3", "4"],
            },
        ],
    },
    {
        title: "SALARIOS Y BENEFICIOS",
        header: [
            "Muy Insatisfecho",
            "Insatisfecho",
            "Satisfecho",
            "Muy Satisfecho",
        ],
        data: [
            {
                id: 22,
                question:
                    "¿Tu remuneración era adecuada para el cargo que ocupabas y las actividades que realizabas?",
                inputName: "question22",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 23,
                question: "¿Los beneficios satisfacían tus necesidades?",
                inputName: "question23",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 24,
                question:
                    "¿Los procesos de reembolso eran claros y fáciles de ejecutar?",
                inputName: "question24",
                answers: ["1", "2", "3", "4"],
            },
        ],
    },
    {
        title: 'Responda las siguientes preguntas del 1 al 10, donde 1 indica "totalmente en desacuerdo" y 10 indica "totalmente de acuerdo":',
        header: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
        data: [
            {
                id: 25,
                question:
                    "¿Recomendaría a un amigo o familiar para trabajar en esta empresa?",
                inputName: "question25",
                answers: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
            },
        ],
    },
];
