import Stars from '../Stars/stars'
import { cardsTranslate } from '../../../../i18n/translatedMessages'
import { MdOutlineInsertLink } from 'react-icons/md'

import './consultorCard.sass'

const ConsultorCard = ({ title, background, text, video, file, stars, file2, fileAmount, url = null, language = 'es' }) => {
	return (
		<>
			<div className='card consultor-card shadow'>
				<div className='card-body'>
					<div className='row'>
						<div className='col-md-1 col-sm-12 d-flex align-items-center flex-column'>
							<div className='rounded-circle img-service mb-2' style={{ background: `url("${background}")` }}></div>
						</div>
						<div className='col-md-11'>
							<h4>{title}</h4>
							<div className='content-service'>
								<p>{text}</p>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-md-6 text-center'>
							<iframe
								width='320'
								height='230'
								src={video}
								title='YouTube video player'
								allowFullScreen
								allowfullscreen
								allow='fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
							></iframe>
						</div>
						<div className='col-md-6 flex flex-col justify-content-between p-0'>
							<div className='row w-100'>
								{fileAmount == 1 ? (
									<>
										<div className='col-md-12 btn-descargar text-center'>
											<a href={file} target='_blank' rel='noreferrer'>
												<button className='bt_file1'>
													<img src='https://assets.mydna.company/iconoCard_descargar-reporte.svg' alt='' />
													<span>{cardsTranslate.apresentationDown}</span>
												</button>
											</a>
										</div>
									</>
								) : (
									<>
										<div className='col-md-12 btn-descargar descargar-archivos  text-center'>
											<a href={file} target='_blank' rel='noreferrer'>
												<button>
													<img src='https://assets.mydna.company/iconoCard_descargar-reporte.svg' alt='' />
													<span>{cardsTranslate.apresentationDown}</span>
												</button>
											</a>
											<a href={file2} target='_blank' rel='noreferrer'>
												<button>
													<img src='https://assets.mydna.company/iconoCard_descargar-reporte.svg' alt='' />
													<span>{cardsTranslate.apresentationDown}</span>
												</button>
											</a>
										</div>
									</>
								)}
							</div>
							{url && (
								<div className='row w-100'>
									<div className='col-md-12 '>
										<div className='flex justify-content-center align-items-center'>
											<MdOutlineInsertLink size={28} className='text-primary' />
											<a href={url} target='_blank' rel='noopener noreferrer' className='text-primary text-decoration-underline'>
												{language === 'pt' ? 'Provar Outplacement' : 'Probar Outplacement'}
											</a>
										</div>
									</div>
								</div>
							)}
							<div className='row w-100'>
								<div className='col-md-12 card-score_and_button'>
									<div className='row align-content-center m-0'>
										<div className='col-3 card-score_number'>{stars}</div>
										<div className='col-9 card-score_explain'>{cardsTranslate.customerEvaluation}</div>
									</div>
									<Stars note={stars} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default ConsultorCard
