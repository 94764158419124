import React, { useCallback, useEffect, useState } from 'react'
import { performancePotentialTranslate, salaryEvaluationGraphTranslate } from '../../i18n/translatedMessages'

export function PerformanceSalaryGraph({ chart, evaluation, salary9Box }) {
	const [coordinate, setCoordinate] = useState({
		cdPerformance: null,
		cdSalary: null,
	})
	const [dataPerformance, setDataPerformance] = useState(evaluation.evaluation.performance.boxValue)

	useEffect(() => {
		if (salary9Box != null || '') {
			let cdPerformance = null
			let cdSalary = null

			if (salary9Box === 1) {
				cdSalary = 0
			} else if (salary9Box === 2) {
				cdSalary = 1
			} else if (salary9Box === 3) {
				cdSalary = 2
			}

			if (dataPerformance >= 0.1 && dataPerformance <= 3) {
				cdPerformance = 0
			} else if (dataPerformance >= 3.1 && dataPerformance <= 4) {
				cdPerformance = 1
			} else if (dataPerformance >= 4.1) {
				cdPerformance = 2
			}

			setCoordinate({
				cdPerformance,
				cdSalary,
			})
		}
	}, [evaluation, dataPerformance, salary9Box])

	return (
		<>
			{evaluation === null || !salary9Box || !dataPerformance || dataPerformance === '0.0' ? (
				<div className='tw-flex tw-items-center tw-justify-center tw-p-8'>
					<div className='tw-p-8 tw-flex tw-items-center tw-justify-center tw-shadow-lg tw-w-4/6 tw-text-black'>{salaryEvaluationGraphTranslate.nineBoxSalaryPerformanceNullText}</div>
				</div>
			) : (
				<div className='tw-p-8 tw-overflow-auto tw-relative'>
					<div className='tw-mb-10 tw-text-black'>{salaryEvaluationGraphTranslate.nineBoxSalaryPerformanceDescText}</div>
					<div className='tw-flex tw-items-center tw-justify-center'>
						<table className='tw-table-fixed'>
							<tbody>
								<tr>
									<td rowSpan={6} className={`${chart ? '' : 'tw-hidden'}`}>
										<div className='[writing-mode:vertical-rl] flex flex-col tw-rotate-180 w-fit'>
											<div className='tw-w-auto-fill tw-text-center'>
												<p className='tw-text-lg tw-font-bold'>{salaryEvaluationGraphTranslate.salaryTitle9Box}</p>
											</div>
										</div>
									</td>
								</tr>
								<tr className='tw-flex'>
									<td className={`tw-w-[70px] tw-text-center tw-font-medium tw-text-lg ${chart ? '' : 'tw-hidden'}`}>
										{/* 1,2 MM */}
										<div className='tw-text-gray-400 '>120%</div>
									</td>
									<td className='tw-p-2'>
										<div
											className={`tw-h-[280px] tw-flex tw-items-center tw-justify-center  tw-min-w-sm lg:tw-max-w-[220px] xl:tw-max-w-[261px] 2xl:tw-min-w-[300px] 2xl:tw-max-w-[300px] tw-p-6 border tw-rounded-lg tw-shadow tw-bg-[#FEE4E5] border-s-[#891336] tw-cursor-pointer  ${
												coordinate.cdPerformance === 0 && coordinate.cdSalary === 2 ? '' : 'tw-opacity-[.2]'
											}`}
										>
											<h5 className='tw-mb-2 tw-text-base tw-font-bold tw-text-center'>
												{salaryEvaluationGraphTranslate.nineBoxSalaryTitlePerformance1} <span className='tw-font-normal'>{salaryEvaluationGraphTranslate.nineBoxSalaryPerformance1}</span>
											</h5>
										</div>
									</td>
									<td className='tw-p-2'>
										<div
											className={`tw-h-[280px] tw-flex tw-items-center tw-justify-center tw-min-w-sm lg:tw-max-w-[220px] xl:tw-max-w-[261px] 2xl:tw-min-w-[300px] 2xl:tw-max-w-[300px] tw-p-6 border tw-rounded-lg tw-shadow tw-bg-[#FEF3C6] border-s-[#793510] tw-cursor-pointer  ${
												coordinate.cdPerformance === 1 && coordinate.cdSalary === 2 ? '' : 'tw-opacity-[.2]'
											}`}
										>
											<h5 className='tw-mb-2 tw-text-base tw-font-bold tw-text-center'>
												{salaryEvaluationGraphTranslate.nineBoxSalaryTitlePerformance2} <span className='tw-font-normal'>{salaryEvaluationGraphTranslate.nineBoxSalaryPerformance2}</span>
											</h5>
										</div>
									</td>
									<td className='tw-p-2'>
										<div
											className={`tw-h-[280px] tw-flex tw-items-center tw-justify-center tw-min-w-sm lg:tw-max-w-[220px] xl:tw-max-w-[261px] 2xl:tw-min-w-[300px] 2xl:tw-max-w-[300px] tw-p-6 border tw-rounded-lg tw-shadow tw-bg-[#C7FFF1] border-s-[#0D554A] tw-cursor-pointer  ${
												coordinate.cdPerformance === 2 && coordinate.cdSalary === 2 ? '' : 'tw-opacity-[.2]'
											}`}
										>
											<h5 className='tw-mb-2 tw-text-base tw-font-bold tw-text-center'>
												{salaryEvaluationGraphTranslate.nineBoxSalaryTitlePerformance3} <span className='tw-font-normal'>{salaryEvaluationGraphTranslate.nineBoxSalaryPerformance3}</span>
											</h5>
										</div>
									</td>
								</tr>
								<tr className='tw-flex'>
									<td className={`tw-w-[70px] tw-text-center tw-flex tw-flex-col tw-justify-between tw-font-medium tw-text-lg ${chart ? '' : 'tw-hidden'}`}>
										<div>
											<div className='tw-text-gray-400'>110%</div>
										</div>
										<div>
											<div className='tw-text-gray-400'>90%</div>
										</div>
									</td>
									<td className='tw-p-2 tw-flex tw-items-center'>
										<div
											className={`tw-h-[280px] tw-flex tw-items-center tw-justify-center tw-min-w-sm lg:tw-max-w-[220px] xl:tw-max-w-[261px] 2xl:tw-min-w-[300px] 2xl:tw-max-w-[300px] tw-p-6 border tw-rounded-lg tw-shadow tw-bg-[#FEF3C6] border-s-[#793510] tw-cursor-pointer  ${
												coordinate.cdPerformance === 0 && coordinate.cdSalary === 1 ? '' : 'tw-opacity-[.2]'
											}`}
										>
											<h5 className='tw-mb-2 tw-text-base tw-font-bold tw-text-center'>
												{salaryEvaluationGraphTranslate.nineBoxSalaryTitlePerformance4} {''}
												<span className='tw-font-normal'>{salaryEvaluationGraphTranslate.nineBoxSalaryPerformance4}</span>
											</h5>
										</div>
									</td>
									<td className='tw-p-2 tw-flex tw-items-center'>
										<div
											className={`tw-h-[280px] tw-flex tw-items-center tw-justify-center tw-min-w-sm lg:tw-max-w-[220px] xl:tw-max-w-[261px] 2xl:tw-min-w-[300px] 2xl:tw-max-w-[300px] tw-p-6 border tw-rounded-lg tw-shadow tw-bg-[#C7FFF1] border-s-[#0D554A] tw-cursor-pointer  ${
												coordinate.cdPerformance === 1 && coordinate.cdSalary === 1 ? '' : 'tw-opacity-[.2]'
											}`}
										>
											<h5 className='tw-mb-2 tw-text-base tw-font-bold tw-text-center'>
												{salaryEvaluationGraphTranslate.nineBoxSalaryTitlePerformance5} {''}
												<span className='tw-font-normal'>{salaryEvaluationGraphTranslate.nineBoxSalaryPerformance5}</span>
											</h5>
										</div>
									</td>
									<td className='tw-p-2 tw-flex tw-items-center'>
										<div
											className={`tw-h-[280px] tw-flex tw-items-center tw-justify-center tw-min-w-sm lg:tw-max-w-[220px] xl:tw-max-w-[261px] 2xl:tw-min-w-[300px] 2xl:tw-max-w-[300px] tw-p-6 border tw-rounded-lg tw-shadow tw-bg-[#FEF3C6] border-s-[#793510] tw-cursor-pointer  ${
												coordinate.cdPerformance === 2 && coordinate.cdSalary === 1 ? '' : 'tw-opacity-[.2]'
											}`}
										>
											<h5 className='tw-mb-2 tw-text-base tw-font-bold tw-text-center'>
												{salaryEvaluationGraphTranslate.nineBoxSalaryTitlePerformance6} {''}
												<span className='tw-font-normal'>{salaryEvaluationGraphTranslate.nineBoxSalaryPerformance6}</span>
											</h5>
										</div>
									</td>
								</tr>
								<tr className='tw-flex'>
									<td className={`tw-w-[70px] tw-flex tw-flex-col tw-items-end tw-justify-end tw-text-center tw-font-medium tw-text-lg ${chart ? '' : 'tw-hidden'}`}>
										<div className='tw-text-gray-400 '>80%</div>
									</td>
									<td className='tw-p-2'>
										<div
											className={`tw-h-[280px] tw-flex tw-items-center tw-justify-center tw-min-w-sm lg:tw-max-w-[220px] xl:tw-max-w-[261px] 2xl:tw-min-w-[300px] 2xl:tw-max-w-[300px] tw-p-6 border tw-rounded-lg tw-shadow tw-bg-[#C7FFF1] border-s-[#0D554A] tw-cursor-pointer  ${
												coordinate.cdPerformance === 0 && coordinate.cdSalary === 0 ? '' : 'tw-opacity-[.2]'
											}`}
										>
											<h5 className='tw-mb-2 tw-text-base tw-font-bold tw-text-center'>
												{salaryEvaluationGraphTranslate.nineBoxSalaryTitlePerformance7} {''}
												<span className='tw-font-normal'>{salaryEvaluationGraphTranslate.nineBoxSalaryPerformance7}</span>
											</h5>
										</div>
									</td>
									<td className='tw-p-2'>
										<div
											className={`tw-h-[280px] tw-flex tw-items-center tw-justify-center tw-min-w-sm lg:tw-max-w-[220px] xl:tw-max-w-[261px] 2xl:tw-min-w-[300px] 2xl:tw-max-w-[300px] tw-p-6 border tw-rounded-lg tw-shadow tw-bg-[#FEF3C6] border-s-[#793510] tw-cursor-pointer  ${
												coordinate.cdPerformance === 1 && coordinate.cdSalary === 0 ? '' : 'tw-opacity-[.2]'
											}`}
										>
											<h5 className='tw-mb-2 tw-text-base tw-font-bold tw-text-center'>
												{salaryEvaluationGraphTranslate.nineBoxSalaryTitlePerformance8} {''}
												<span className='tw-font-normal'>{salaryEvaluationGraphTranslate.nineBoxSalaryPerformance8}</span>
											</h5>
										</div>
									</td>
									<td className='tw-p-2'>
										<div
											className={`tw-h-[280px] tw-flex tw-items-center tw-justify-center  tw-min-w-sm lg:tw-max-w-[220px] xl:tw-max-w-[261px] 2xl:tw-min-w-[300px] 2xl:tw-max-w-[300px]  tw-p-6 border tw-rounded-lg tw-shadow tw-bg-[#FEE4E5] border-s-[#891336] tw-cursor-pointer  ${
												coordinate.cdPerformance === 2 && coordinate.cdSalary === 0 ? '' : 'tw-opacity-[.2]'
											}`}
										>
											<h5 className='tw-mb-2 tw-text-base  tw-font-bold tw-text-center'>
												{salaryEvaluationGraphTranslate.nineBoxSalaryTitlePerformance9} {''}
												<span className='tw-font-normal'>{salaryEvaluationGraphTranslate.nineBoxSalaryPerformance9}</span>
											</h5>
										</div>
									</td>
								</tr>
								<tr className={`text-center tw-flex ${chart ? '' : 'tw-hidden'}`}>
									<td></td>
									<td></td>
									<td className='tw-m-auto'>0 - 3</td>
									<td className='tw-m-auto'>3.1 - 4</td>
									<td className='tw-m-auto'>4.1 - 5</td>
								</tr>
								<tr>
									<td colSpan={4} className={`text-center ${chart ? '' : 'tw-hidden'}`}>
										<p className='tw-text-lg tw-font-bold'>{performancePotentialTranslate.performanceTitle}</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			)}
		</>
	)
}
export default PerformanceSalaryGraph
