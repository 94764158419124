import {PropositoIcon } from "../../Icons/CollaboratorsIcons"
import './canaldenuncia.sass'
import { complaintTranslate } from "../../i18n/translatedMessages";
import { Link } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";




const B2ECanalDenuncia = () => {
    return (
        <div className="content-denuncia">
            <div className="title">
                <div className="icon">
                    <PropositoIcon />
                </div>
                <h2><b>Ombudsman</b></h2>
            </div>
            <div className="col-6 parrafo_principal">
                <h1 >{complaintTranslate.complaintSecurityText}</h1>
                <p className="texto_parrafo">
                {complaintTranslate.complaintSecuryTextBody}
                </p>
                <div>
                <Link
                    className="btn-denuncia px-2 align-items-center"
                     to="ingresodenuncia">
                    <b>{complaintTranslate.btnComplaint}</b>
                  </Link>
                </div>
            </div>
            <div className="col-6">
          <Link
            className="button-volver color-blue"
            to={"/employee"}
          >
            <MdArrowBack size={20} />
            {complaintTranslate.btnBack}
          </Link>
        </div>
        </div>
    )
}
export default B2ECanalDenuncia