// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-desempeno {
  margin-bottom: 50px;
}
.content-desempeno .title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.content-desempeno .title .icon {
  background: linear-gradient(45deg, #40c2ff 0%, #32d8d8 100%);
  width: 50px;
  height: 50px;
  border-radius: 50rem;
  padding: 8px;
  margin: 0 10px;
}
.content-desempeno .title .icon svg {
  fill: white;
  width: 100%;
}
.content-desempeno .bars {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
}
.content-desempeno .bars .desempeno-bars {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content-desempeno .bars .desempeno-bars h5 {
  text-align: center;
}
.content-desempeno .bars .desempeno-bars span {
  text-align: center;
  color: #9a9a9a;
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/Views/Employee/desempeno.sass"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;AAAI;EACI,aAAA;EACA,mBAAA;EACA,2BAAA;AAER;AACQ;EACI,4DAAA;EACA,WAAA;EACA,YAAA;EACA,oBAAA;EACA,YAAA;EACA,cAAA;AACZ;AACY;EACI,WAAA;EACA,WAAA;AAChB;AACI;EACI,gBAAA;EACA,aAAA;EACA,eAAA;AACR;AACQ;EACI,UAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AACZ;AACY;EACI,kBAAA;AAChB;AAAY;EACI,kBAAA;EACA,cAAA;EACA,eAAA;AAEhB","sourcesContent":[".content-desempeno\n    margin-bottom: 50px\n    & .title\n        display: flex\n        align-items: center\n        justify-content: flex-start\n        \n\n        & .icon\n            background: linear-gradient(45deg,#40c2ff 0%, #32d8d8 100%)\n            width: 50px\n            height: 50px\n            border-radius: 50rem\n            padding: 8px\n            margin: 0 10px\n\n            & svg\n                fill: white\n                width: 100%\n\n    & .bars\n        margin-top: 50px\n        display: flex\n        flex-wrap: wrap\n\n        & .desempeno-bars\n            width: 33%\n            display: flex\n            flex-direction: column\n            align-items: center\n\n            & h5\n                text-align: center\n            & span\n                text-align: center\n                color: #9a9a9a\n                font-size: 14px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
