import { IoIosSchool } from 'react-icons/io'

import './univesrityCard.sass'

const UniversityCard = ({ university, course, date, type }) => {
    return (
        <div className="content-university-card">
            < IoIosSchool size={50} />
            <div className="info">
                <h6>{university}</h6>
                <span className="curse">{type}</span>
                <span className="curse">{course}</span>
                <span className="date">{date.start} - {date.end}</span>
            </div>
        </div>
    )
}
export default UniversityCard