import { trainningListTranslate } from "../../../../../i18n/translatedMessages"

export const listTreinaments = [
    {
        id: 0,
        title: trainningListTranslate.trainningCardTitleEnglish,
        text: trainningListTranslate.trainningCardTextEnglish,
        icon: "https://assets.mydna.company/iconoCard_ingles.svg",
        link: "/app/services/english-classes"
    },
    {
        id: 1,
        title: trainningListTranslate.trainningCardTitleGamefied,
        text: trainningListTranslate.trainningCardTextGamefied,
        icon: "https://assets.mydna.company/iconoCard_gamificacion-de-contenidos.svg",
        link: "/app/services/gamified-tool"
    },
]