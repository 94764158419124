import Askes from "./Components/Askes/Askes";
import Timeline from "./Components/Timeline/Timeline";
import { cultureBusinessTranslate } from "../../i18n/translatedMessages";

const Culture = ({ questions, index, answer, handleClick }) => {
    return (
        <>
            <Timeline step={index} />
            <div className="row my-5">
                <div className="col-8 offset-2">
                    <p>{cultureBusinessTranslate.asksTimelineText}</p>
                </div>
            </div>
            <div className="row my-5">
                <div className="col-8 offset-2">
                    <Askes questions={questions} answer={answer} handleClick={handleClick} array={index} />
                </div>
            </div>
        </>
    );
};
export default Culture;
