import * as ReactBootstrap from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
const CarouselMyDnaGenes = () => {
  return (
    <>
      <Carousel controls={false}>
        <Carousel.Item>
          <ReactBootstrap.Container fluid>
            <ReactBootstrap.Row>
              <ReactBootstrap.Col md={3}>
                <div className="carousel-logo">
                  <img
                    alt="..."
                    className="img-fluid"
                    src="https://www.genescompany.com/assets/img/partners/Alsea.webp"
                  />
                </div>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col md={3}>
                <div className="carousel-logo">
                  <img
                    alt="..."
                    className="img-fluid"
                    src="https://www.genescompany.com/assets/img/partners/BNE.webp"
                  />
                </div>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col md={3}>
                <div className="carousel-logo">
                  <img
                    alt="..."
                    className="img-fluid"
                    src="https://www.genescompany.com/assets/img/partners/Chilexpress.webp"
                  />
                </div>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col md={3}>
                <div className="carousel-logo">
                  <img
                    alt="..."
                    className="img-fluid"
                    src="https://www.genescompany.com/assets/img/partners/Falabella.webp"
                  />
                </div>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </Carousel.Item>
        <Carousel.Item>
          <ReactBootstrap.Container fluid>
            <ReactBootstrap.Row>
              <ReactBootstrap.Col md={3}>
                <div className="carousel-logo">
                  <img
                    alt="..."
                    className="img-fluid"
                    src="https://www.genescompany.com/assets/img/partners/Glovo.webp"
                  />
                </div>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col md={3}>
                <div className="carousel-logo">
                  <img
                    alt="..."
                    className="img-fluid"
                    src="https://www.genescompany.com/assets/img/partners/Grupo EFE.webp"
                  />
                </div>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col md={3}>
                <div className="carousel-logo">
                  <img
                    alt="..."
                    className="img-fluid"
                    src="https://www.genescompany.com/assets/img/partners/Hamburg Süd.webp"
                  />
                </div>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col md={3}>
                <div className="carousel-logo">
                  <img
                    alt="..."
                    className="img-fluid"
                    src="https://www.genescompany.com/assets/img/partners/itau.webp"
                  />
                </div>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </Carousel.Item>
        <Carousel.Item>
          <ReactBootstrap.Container fluid>
            <ReactBootstrap.Row>
              <ReactBootstrap.Col md={3}>
                <div className="carousel-logo">
                <img
                    alt="..."
                    className="img-fluid"
                    src="https://www.genescompany.com/assets/img/partners/Kantar World Panel.webp"
                  />
                </div>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col md={3}>
                <div className="carousel-logo">
                  <img
                    alt="..."
                    className="img-fluid"
                    src="https://www.genescompany.com/assets/img/partners/Mercado libre Colombia.webp"
                  />
                </div>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col md={3}>
                <div className="carousel-logo">
                <img
                    alt="..."
                    className="img-fluid"
                    src="https://www.genescompany.com/assets/img/partners/Orica.webp"
                  />
                </div>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col md={3}>
                <div className="carousel-logo">
                <img
                    alt="..."
                    className="img-fluid"
                    src="https://www.genescompany.com/assets/img/partners/Schlumberger.webp"
                  />
                </div>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Container>
        </Carousel.Item>
      </Carousel>
    </>
  );
};
export default CarouselMyDnaGenes;
