import './progress.sass'

export function ProgressBar({ progress }) {
	const percentProgress = (progress * 252) / 100 - 252

	return (
		<div className='progress-bar-rounded'>
			<svg>
				<circle cx='45' cy='45' r='40' strokeDashoffset={Math.abs(percentProgress)} />
			</svg>
			<div className='progress-bar__text'>{`${progress}%`}</div>
		</div>
	)
}
