import { formatNumberWithCommas } from "../../../../../../Utils/TransformNumbers";

export const Tooltip = ({ item }) => {
	return (
		<div
			className="position-absolute rounded-2 bg-black text-white p-2 d-flex flex-column shadow-sm"
			style={{
				opacity: 0.85,
				left: `${
					item.position > 100
						? 100
						: item.position < 0
						? 0
						: item.position
				}%`,
				top: 15,
				zIndex: 99999,
				minWidth: 300,
			}}
		>
			<div className="d-flex">
				<span className="fs-4">{item.employer_name}</span>
			</div>
			<div className="d-flex">
				<b className="">Salario($):</b>
				<span className="ms-2">
					{formatNumberWithCommas(item.employee_salary)}
				</span>
			</div>
			<div className="d-flex">
				<b className="">Diferencia(%):</b>
				<span className="ms-2">{Math.ceil(item.diference * -1)}%</span>
			</div>
			<div className="d-flex">
				<b className="">Diferencia($):</b>
				<span className="ms-2">
					{formatNumberWithCommas(
						item.employee_salary - item.company_salary_mean
					)}
				</span>
			</div>
		</div>
	);
};
