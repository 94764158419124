// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlayer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
}

.modal-body-success {
  padding: 35px;
  background: white;
  border-radius: 8px;
  width: 546px;
  height: 405px;
}

.modal-auto {
  max-width: fit-content !important;
  display: block;
}

.modal-width {
  min-width: 1010px;
}
.modal-width .modal-content {
  border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Employee/Modal/modal.sass"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,aAAA;EACA,8BAAA;EACA,kCAAA;UAAA,0BAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;AACD;;AAEA;EACC,aAAA;EACA,iBAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;AACD;;AAEA;EACC,iCAAA;EACA,cAAA;AACD;;AACA;EACC,iBAAA;AAED;AAAC;EACC,mBAAA;AAEF","sourcesContent":[".overlayer\r\n\tposition: absolute\r\n\ttop: 0\r\n\tleft: 0\r\n\twidth: 100vw\r\n\theight: 100vh\r\n\tbackground: rgba(0,0,0,.5)\r\n\tbackdrop-filter: blur(3px)\r\n\tdisplay: flex\r\n\tjustify-content: center\r\n\talign-items: center\r\n\tz-index: 999999\r\n\r\n\r\n.modal-body-success\r\n\tpadding: 35px\r\n\tbackground: white\r\n\tborder-radius: 8px\r\n\twidth: 546px\r\n\theight: 405px\r\n\r\n\r\n.modal-auto\r\n\tmax-width: fit-content !important\r\n\tdisplay: block\r\n\r\n.modal-width\r\n\tmin-width: 1010px\r\n\r\n\t.modal-content\r\n\t\tborder-radius: 10px\r\n\t\t"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
