import { useContext, useState } from 'react'

import { Link } from 'react-router-dom'

import { Col, Navbar, Nav } from 'react-bootstrap'

import { MydnaLogoMobileDark, MydnaLogoDark } from '../../Icons/logo'
import { MdSearch } from 'react-icons/md'
import { FaRegUserCircle } from 'react-icons/fa'
import { HiOutlineEmojiHappy } from 'react-icons/hi'
import { AiOutlineLogout } from 'react-icons/ai'
import { RiNotification3Line } from 'react-icons/ri'
import DropdownMenu from './dropdown'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'

import { headerTextTranslate } from '../../i18n/translatedMessages.js'

import './mainMenu.sass'

import { CompanyContext } from '../../Store/CompanyContext'

const MainMenu = props => {
	const { store } = useContext(CompanyContext)

	const [value, setValue] = useState(store.language == 'ES' ? 'Español' : 'Português')
	const handleSelect = e => {
		setValue(e)
	}

	const { actions } = useContext(CompanyContext)

	return (
		<Navbar className='main-menu bg-md-light' sticky='top'>
			<Col className='main-menu-col' md={10} xs={12}>
				<Navbar.Brand href='/app'>
					<div className='desktop'>
						<MydnaLogoDark size={140} />
					</div>
					<div className='mobile'>
						<MydnaLogoMobileDark size={22} />
					</div>
				</Navbar.Brand>

				<Nav className='main-menu-mobile'>
					<Link to='/app'>
						<HiOutlineEmojiHappy />
					</Link>
					<Link to='/app'>
						<FaRegUserCircle />
					</Link>
					<Link to='/app'>
						<MdSearch />
					</Link>
					<Link to='/app'>
						<RiNotification3Line />
					</Link>
					<Link to='/app'>
						<AiOutlineLogout />
					</Link>
				</Nav>

				<Nav className='main-menu-nav d-flex align-items-center'>
					<div className='desktop m-0'>
						<a href={`https://ta.mydnadigital.com`} className='accessToTA p-0 d-flex gap-3 rounded-pill text-decoration-none'>
							<div className={`rounded-pill py-2 px-3`}>Talent Acquisition</div>
							<div className={`rounded-pill py-2 px-3 bg-blue text-black`}>Employee Experience</div>
						</a>
					</div>
					<div>
						<Link to='/app'>{headerTextTranslate.myBusiness}</Link>
						<Link to='/app/services/list'>{headerTextTranslate.myCollaborators}</Link>
					</div>
					<div className='bar-active-menu'></div>
				</Nav>
			</Col>
			<Col className='col-user-dropdown d-flex justify-content-end pe-5'>
				<DropdownButton className='dropdown-main-menu text-white' id='dropdown-basic-button' size='sm' variant='secondary' menuVariant='dark' title={<span style={{ color: 'white' }}>{value}</span>} onSelect={handleSelect}>
					<Dropdown.Item eventKey='Español' onClick={() => actions.setLanguage('ES')}>
						Español
					</Dropdown.Item>
					<Dropdown.Item eventKey='Português' onClick={() => actions.setLanguage('PT-BR')}>
						Português
					</Dropdown.Item>
				</DropdownButton>
			</Col>
			<Col md={-2} xs={0} className='col-user-dropdown d-flex justify-content-end pe-2'>
				<DropdownMenu />
			</Col>
		</Navbar>
	)
}
export default MainMenu
