import { useState, useEffect } from "react";
import {
	CulturaIcon,
	FormacionIcon,
	PropositoIcon,
} from "../../Icons/CollaboratorsIcons";
import { getKnowledge } from "../../API/Analytics/knowledge";
import { getEmployee } from "../../API/Employee/Employee";
import {
	formationTranslate,
	wheelCollaboratorTranslate,
} from "../../i18n/translatedMessages";

import Englishline from "./Components/Englishline/Englishline";
import UniversityCard from "./Components/UniversityCard/UnivesrityCard";
import EmptyInformation from "./Components/EmptyInformation/EmptyInformation";
import { FormattedMessage, useIntl } from "react-intl";

import "./formacion.sass";
import Loading from "../../Components/Loading/Loading";
import { useParams } from "react-router-dom";
import { getNulingaEnglishLevel } from "../../API/Employee/EnglishLevel";
import HeaderCarouselMandala from "./Components/HeaderCarouselMandala/HeaderCarouselMandala";
import { CulturalIcon } from "../../Icons/Icons";

const Formacion = () => {
	const language = useIntl();
	const { id } = useParams();
	const [knowledges, setKnowledges] = useState([]);
	const [employee, setEmployee] = useState([]);
	const [employeeNulinga, setEmployeeNulinga] = useState({});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const fetchKnowledge = async () => {
			setLoading(true);
			setKnowledges(await getKnowledge(id));
			setEmployee(await getEmployee(id));
			const getNulingaAux = await getNulingaEnglishLevel(id);
			if (getNulingaAux) {
				setEmployeeNulinga(getNulingaAux.data);
				setLoading(false);
			}
			setLoading(false);
		};

		fetchKnowledge();
	}, [id]);

	return (
		<div className="content-formacion">
			<div>
				<HeaderCarouselMandala
					actualTitle={wheelCollaboratorTranslate.formation}
					prevTitle={wheelCollaboratorTranslate.purpose}
					routePrev={`/app/analytic/proposito/${id}`}
					nextTitle={wheelCollaboratorTranslate.culture}
					routeNext={`/app/analytic/cultura/${id}`}
					iconActual={<FormacionIcon />}
					iconPrev={<PropositoIcon />}
					iconNext={<CulturalIcon />}
				/>
			</div>
			{loading ? (
				<Loading />
			) : knowledges.length > 0 ||
			  (employee.englishLevel !== null &&
					employee.englishLevel !== "0") ||
			  (employeeNulinga?.id && employeeNulinga.id !== null) ? (
				<div className="row body mt-4">
					<div className="col-4">
						<h5>{formationTranslate.education}</h5>
						{knowledges.map((knowledge) => (
							<UniversityCard
								key={knowledge.id}
								university={knowledge.university}
								type={knowledge.type}
								date={{
									start: knowledge.startDate,
									end: knowledge.endDate,
								}}
								course={knowledge.course}
							/>
						))}
					</div>
					<div className="col-8">
						<div className="ingles">
							<h5>
								{employee.englishLevel !== null &&
								employee.englishLevel !== "0" ? (
									<>
										{formationTranslate.englishLevel} es{" "}
										{employee.englishLevel}
									</>
								) : (
									"Nivel de Inglés"
								)}
							</h5>
							{employee.englishLevel !== null &&
							employee.englishLevel !== "0" ? (
								<>
									<Englishline />
									<div
										className="mark"
										style={
											employee.englishLevel === "Nulo"
												? { left: 0 }
												: employee.englishLevel ===
												  "Basico"
												? { left: 100 }
												: employee.englishLevel ===
												  "Intermedio"
												? { left: 200 }
												: employee.englishLevel ===
												  "Avanzado"
												? { left: 300 }
												: employee.englishLevel ===
												  "Fluido"
												? { left: 400 }
												: { left: 0 }
										}
									></div>
								</>
							) : (
								<>Não respondeu ainda.</>
							)}
						</div>

						<div className="nulinga mt-5">
							<h5>
								{employeeNulinga?.nulinga_level?.level ? (
									<>
										{formationTranslate.englishLevel}{" "}
										{language.locale == "ES"
											? " segun test es"
											: " de acordo com o teste é"}{" "}
										{employeeNulinga?.nulinga_level?.level}
									</>
								) : (
									<>
										{/* 
										{formationTranslate.englishLevel}{" "}
										{language.locale == "ES"
											? " segun test es"
											: " de acordo com o teste é"}
									 */}
									</>
								)}
							</h5>
							{employeeNulinga?.id ? (
								<>
									<Englishline />
									<div
										className="mark"
										style={
											employeeNulinga?.nulinga_level
												?.id === 1
												? { left: 0 }
												: employeeNulinga?.nulinga_level
														?.id === 2
												? { left: 100 }
												: employeeNulinga?.nulinga_level
														?.id === 3
												? { left: 200 }
												: employeeNulinga?.nulinga_level
														?.id === 4
												? { left: 300 }
												: employeeNulinga?.nulinga_level
														?.id === 5
												? { left: 400 }
												: { left: 0 }
										}
									></div>
								</>
							) : (
								<>{/* Não fez o teste ainda. */}</>
							)}
						</div>
					</div>
				</div>
			) : (
				<EmptyInformation />
			)}
			{/* {knowledges.length === 0 && <EmptyInformation />} */}
		</div>
	);
};
export default Formacion;
