import React, { useEffect, useState } from 'react'

import { performancePotentialTranslate } from '../../i18n/translatedMessages'

export function NineBox({ chart, evaluation, stars }) {
	const [coordinate, setCoordinate] = useState({
		cdPerformance: null,
		cdPotential: null,
	})

	const dataPotential = Number(stars.potential)
	const dataPerformance = Number(stars.performance)

	useEffect(() => {
		let cdPerformance
		let cdPotential

		if (dataPerformance >= 0.1 && dataPerformance <= 3) {
			cdPerformance = 0
		} else if (dataPerformance >= 3.1 && dataPerformance <= 4) {
			cdPerformance = 1
		} else if (dataPerformance >= 4.1) {
			cdPerformance = 2
		}

		if (dataPotential >= 0.1 && dataPotential <= 3) {
			cdPotential = 0
		} else if (dataPotential >= 3.1 && dataPotential <= 4) {
			cdPotential = 1
		} else if (dataPotential >= 4.1) {
			cdPotential = 2
		}

		setCoordinate({
			cdPerformance,
			cdPotential,
		})
	}, [dataPotential, dataPerformance, evaluation])

	return (
		<>
			{dataPerformance === null || dataPotential === null || dataPerformance === '0.0' || dataPotential === '0.0' ? (
				<div className='tw-flex tw-items-center tw-justify-center tw-p-8'>
					<div className='tw-p-8 tw-flex tw-items-center tw-justify-center tw-shadow-lg tw-w-4/6'>{performancePotentialTranslate.empty9Box}</div>
				</div>
			) : (
				<div className='tw-flex tw-items-center tw-justify-center'>
					<div className='tw-p-8 tw-overflow-auto tw-relative'>
						<table className='tw-table-fixed'>
							<tbody>
								<tr>
									<td rowSpan={6} className={`${chart ? '' : 'tw-hidden'}`}>
										<div className='[writing-mode:vertical-rl] flex flex-col tw-rotate-180 w-fit'>
											<div className='tw-w-auto-fill tw-text-center'>
												<p className='tw-text-lg tw-font-bold'>{performancePotentialTranslate.potentialTitle}</p>
											</div>
										</div>
									</td>
								</tr>
								<tr className='tw-flex'>
									<td className={`[writing-mode:vertical-rl] tw-rotate-180 tw-text-center tw-font-medium tw-text-lg ${chart ? '' : 'tw-hidden'}`}>4.1 - 5</td>
									<td className='tw-p-2'>
										<div className={`tw-h-full tw-max-w-sm tw-p-6 border tw-rounded-lg tw-shadow tw-bg-[#FEF3C6] border-s-[#793510]  tw-cursor-pointer  ${coordinate.cdPerformance === 0 && coordinate.cdPotential === 2 ? '' : 'tw-opacity-[.2]'}`}>
											<h5 className='tw-mb-2 tw-text-2xl tw-font-bold'>{performancePotentialTranslate.profile7}</h5>
											<p>{performancePotentialTranslate.nineBoxDesc1}</p>
										</div>
									</td>
									<td className='tw-p-2'>
										<div className={`tw-h-full tw-max-w-sm tw-p-6 border tw-rounded-lg tw-shadow tw-bg-[#C7FFF1] border-s-[#0D554A] tw-cursor-pointer  ${coordinate.cdPerformance === 1 && coordinate.cdPotential === 2 ? '' : 'tw-opacity-[.2]'}`}>
											<h5 className='tw-mb-2 tw-text-2xl tw-font-bold'>{performancePotentialTranslate.profile9}</h5>
											<p>{performancePotentialTranslate.nineBoxDesc2}</p>
										</div>
									</td>
									<td className='tw-p-2'>
										<div className={`tw-h-full tw-max-w-sm tw-p-6 border tw-rounded-lg tw-shadow tw-bg-[#60fab2] border-s-[#0D554A] tw-cursor-pointer  ${coordinate.cdPerformance === 2 && coordinate.cdPotential === 2 ? '' : 'tw-opacity-[.2]'}`}>
											<h5 className='tw-mb-2 tw-text-2xl tw-font-bold'>{performancePotentialTranslate.profile8}</h5>
											<p>{performancePotentialTranslate.nineBoxDesc3}</p>
										</div>
									</td>
								</tr>
								<tr className='tw-flex'>
									<td className={`[writing-mode:vertical-rl] tw-rotate-180 tw-text-center tw-font-medium tw-text-lg ${chart ? '' : 'tw-hidden'}`}>3.1 - 4</td>
									<td className='tw-p-2'>
										<div className={`tw-h-full tw-max-w-sm tw-p-6 border tw-rounded-lg tw-shadow tw-bg-[#FEF3C6] border-s-[#793510] tw-cursor-pointer  ${coordinate.cdPerformance === 0 && coordinate.cdPotential === 1 ? '' : 'tw-opacity-[.2]'}`}>
											<h5 className='tw-mb-2 tw-text-2xl tw-font-bold'>{performancePotentialTranslate.profile4}</h5>
											<p>{performancePotentialTranslate.nineBoxDesc4}</p>
										</div>
									</td>
									<td className='tw-p-2'>
										<div className={`tw-h-full tw-max-w-sm tw-p-6 border tw-rounded-lg tw-shadow tw-bg-[#DFF3FE] border-s-[#084E72] tw-cursor-pointer  ${coordinate.cdPerformance === 1 && coordinate.cdPotential === 1 ? '' : 'tw-opacity-[.2]'}`}>
											<h5 className='tw-mb-2 tw-text-2xl tw-font-bold'>{performancePotentialTranslate.profile5}</h5>
											<p>{performancePotentialTranslate.nineBoxDesc5}</p>
										</div>
									</td>
									<td className='tw-p-2'>
										<div className={`tw-h-full tw-max-w-sm tw-p-6 border tw-rounded-lg tw-shadow tw-bg-[#C7FFF1] border-s-[#0D554A] tw-cursor-pointer  ${coordinate.cdPerformance === 2 && coordinate.cdPotential === 1 ? '' : 'tw-opacity-[.2]'}`}>
											<h5 className='tw-mb-2 tw-text-2xl tw-font-bold'>{performancePotentialTranslate.profile6}</h5>
											<p>{performancePotentialTranslate.nineBoxDesc6}</p>
										</div>
									</td>
								</tr>
								<tr className='tw-flex'>
									<td className={`[writing-mode:vertical-rl] tw-rotate-180 tw-text-center tw-font-medium tw-text-lg ${chart ? '' : 'tw-hidden'}`}>0 - 3</td>
									<td className='tw-p-2'>
										<div className={`tw-h-full tw-max-w-sm tw-p-6 border tw-rounded-lg tw-shadow tw-bg-[#FEE4E5] border-s-[#891336] tw-cursor-pointer  ${coordinate.cdPerformance === 0 && coordinate.cdPotential === 0 ? '' : 'tw-opacity-[.2]'}`}>
											<h5 className='tw-mb-2 tw-text-2xl tw-font-bold'>{performancePotentialTranslate.profile1}</h5>
											<p>{performancePotentialTranslate.nineBoxDesc7}</p>
										</div>
									</td>
									<td className='tw-p-2'>
										<div className={`tw-h-full tw-max-w-sm tw-p-6 border tw-rounded-lg tw-shadow tw-bg-[#DFF3FE] border-s-[#084E72] tw-cursor-pointer  ${coordinate.cdPerformance === 1 && coordinate.cdPotential === 0 ? '' : 'tw-opacity-[.2]'}`}>
											<h5 className='tw-mb-2 tw-text-2xl tw-font-bold'>{performancePotentialTranslate.profile2}</h5>
											<p>{performancePotentialTranslate.nineBoxDesc8}</p>
										</div>
									</td>
									<td className='tw-p-2'>
										<div className={`tw-h-full tw-max-w-sm tw-p-6 border tw-rounded-lg tw-shadow tw-bg-[#DFF3FE] border-s-[#084E72] tw-cursor-pointer  ${coordinate.cdPerformance === 2 && coordinate.cdPotential === 0 ? '' : 'tw-opacity-[.2]'}`}>
											<h5 className='tw-mb-2 tw-text-2xl tw-font-bold'>{performancePotentialTranslate.profile3}</h5>
											<p>{performancePotentialTranslate.nineBoxDesc9}</p>
										</div>
									</td>
								</tr>
								<tr className={`text-center tw-flex ${chart ? '' : 'tw-hidden'}`}>
									<td></td>
									<td></td>
									<td className='tw-m-auto'>0 - 3</td>
									<td className='tw-m-auto'>3.1 - 4</td>
									<td className='tw-m-auto'>4.1 - 5</td>
								</tr>
								<tr>
									<td colSpan={4} className={`text-center ${chart ? '' : 'tw-hidden'}`}>
										<p className='tw-text-lg tw-font-bold'>{performancePotentialTranslate.performanceTitle}</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			)}
		</>
	)
}
export default NineBox
