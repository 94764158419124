import React, { useState } from "react";
import "./Carousel.sass";

export const CarouselItem = ({ children, width }) => (
    <div className="yoydev-carousel-item" style={{ width }}>
        {children}
    </div>
);

const Carousel = ({
    col,
    children,
    experieceList,
    setDataEmployee,
}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [translateX, setTranslateX] = useState(0);

    const updateIndex = (newIndex, current="next") => {
        if (current === 'next' && (activeIndex + 1) % 3 === 0) {
            setTranslateX((React.Children.count(children) - (col + 1)) / col);
        }
        
        if (current === 'prev' && activeIndex % 3 === 0) {
            setTranslateX(0);
            setActiveIndex(0);
            setDataEmployee(experieceList[0]);
        }

        setActiveIndex(newIndex);
        setDataEmployee(experieceList[newIndex]);
    };

    return (
        <div className="yoydev-carousel">
            <div
                className="yoydev-inner"
                style={{ transform: `translateX(-${translateX * 100}%)` }}
            >
                {React.Children.map(children, (child) => {
                    return React.cloneElement(child);
                })}
            </div>
              <div className="yoydev-btn-group">
                  {activeIndex !== 0 && (
                      <button
                          className="yoydev-btn"
                          onClick={() => updateIndex(activeIndex - 1, "prev")}
                      >
                          Anterior
                      </button>
                  )}
                  {activeIndex < React.Children.count(children) - 1 && (
                      <button
                          className="yoydev-btn"
                          onClick={() => updateIndex(activeIndex + 1)}
                      >
                          Proximo
                      </button>
                  )}
              </div>
        </div>
    );
};

export default Carousel;
