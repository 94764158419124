class DocfilesService {
    async createDocfile(data) {
        const response = await fetch(`${process.env.REACT_APP_API}/documents/docfile`, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify(data),
        });

        return response.json();
    }

    async updateDocfile(id, data) {
        console.log(id)
        const response = await fetch(`${process.env.REACT_APP_API}/documents/docfile/${id}`, {
            method: "PATCH",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify(data),
        });
        console.log(await response.json())

        return response.json();
    }

    async getURLDocfile(id, token, filename) {
        const response = await fetch(
            `${process.env.REACT_APP_API}/documents/docfile/upload/${id}?file=${filename}&companyDocument=true`,
            {
                method: "GET",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Basic " + token,
                },
                redirect: "follow",
                referrerPolicy: "no-referrer",
            }
        ); 
        return response.json()
    }

    async getCompanyDocfiles(id) {
        const response = await fetch(`${process.env.REACT_APP_API}/documents/docfile/company/${id}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json",
            },
        });

        return response.json();
    }

    async getEmployeeDocfiles(id) {
        const response = await fetch(`${process.env.REACT_APP_API}/documents/docfile/${id}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json",
            },
        });

        return response.json();
    }

    async deleteDocfile(id) {
        const response = await fetch(`${process.env.REACT_APP_API}/documents/docfile/${id}`, {
            method: "DELETE",
            headers: {
                "Content-type": "application/json",
            },
        });

        return response.json();
    }

    async uploadFile(url, file) {
        await fetch(url, {
            method: "PUT",
            mode: "cors",
            body: file,
            headers: {
                "Content-type": file.type,
            },
        });
    }
}
export default new DocfilesService();
