// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.steps-line {
  display: flex;
}
.steps-line .step {
  width: 20px;
  height: 20px;
  background: #7b6be4;
  border-radius: 50rem;
  margin: 0 5px;
  transition: all 0.2s ease;
}
.steps-line .step.active {
  transform: scale(1.5);
}`, "",{"version":3,"sources":["webpack://./src/Views/Services/Components/WheelSteps/steps.sass"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AACI;EACI,WAAA;EACA,YAAA;EACA,mBAAA;EACA,oBAAA;EACA,aAAA;EACA,yBAAA;AACR;AACQ;EACI,qBAAA;AACZ","sourcesContent":[".steps-line\n    display: flex\n\n    & .step\n        width: 20px\n        height: 20px\n        background: #7b6be4\n        border-radius: 50rem\n        margin: 0 5px\n        transition: all .2s ease\n\n        &.active\n            transform: scale(1.5)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
