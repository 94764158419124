import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
    editProfile,
    getProfile,
    sendProfile,
} from "../../../../../../API/Employee/Profile";
import Swal from "sweetalert2";

import "./ModalEditProfileInfo.sass";

const ModalEditProfileInfo = ({ show, onCloseClick, employeeId, lists }) => {
    const profileEmpty = {
        employerId: employeeId,
        mailPersonal: "",
        phone: "",
        document: "",
        birth: "",
        linkedIn: "",
        facebook: "",
        instagram: "",
        skype: "",
        twitter: "",
        gender: "",
        maritalStatus: "",
        childrens: "",
        city: "",
        country: "",
        address: "",
        nationality: "",
        transport: "",
        contract: "",
        boss: "",
        bank: "",
        currentAccount: "",
        healthPlan: "",
        height: 0,
        weight: 0,
        enfermedad: "",
        pcd: "",
        typePCD: "",
        hobies: "",
        sports: "",
        interests: "",
        voluntaryWork: "",
    };
    const [employeeProfile, setEmployeeProfile] = useState(profileEmpty);
    const [edit, setEdit] = useState(false);

    const profileRequest = async () => {
        const [profileAux] = await getProfile(employeeId);
        if (profileAux) {
            setEmployeeProfile({ ...employeeProfile, ...profileAux });
            setEdit(true);
        }
    };

    useEffect(() => {
        setEmployeeProfile(profileEmpty);
        profileRequest();
    }, [employeeId, show]);

    const handleChange = (event) => {
        setEmployeeProfile({
            ...employeeProfile,
            [event.target.name]: event.target.value,
        });
    };
    const editProf = async () => {
        const ep = await editProfile(employeeProfile, employeeId);
        if (ep.status < 200 || ep.status > 299) {
            if (ep.status > 399 && ep.status <= 500) {
                Swal.fire({
                    icon: "warning",
                    title: "Oops...",
                    text: "Algo ha ido mal, verifique os datos mas una vez!",
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Algo ha ido mal, inténtelo de nuevo más tarde!",
                });
            }
        } else {
            Swal.fire({
                icon: "success",
                title: "Listo",
                text: "Actualizado con Éxito!",
            });
        }
    };
    const sendProf = async () => {
        const sp = await sendProfile(employeeProfile);
        if (sp.status < 200 || sp.status > 299) {
            if (sp.status > 399 && sp.status < 500) {
                Swal.fire({
                    icon: "warning",
                    title: "Oops...",
                    text: "Algo ha ido mal, comprueba los datos una vez más!",
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Algo ha ido mal, inténtelo de nuevo más tarde!",
                });
            }
        } else {
            Swal.fire({
                icon: "success",
                title: "Listo",
                text: "Actualizado con Éxito!",
            });
        }
    };
    const handleSubmit = () => {
        edit ? editProf() : sendProf();

        onCloseClick();
    };

    return (
        <Modal
            show={show}
            backdrop="static"
            keyboard={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="ModalProfileContent"
        >
            <div className="modal-header row border-0">
                <h5 className="modal-title col">Alterar Informações</h5>
                <div className="col d-flex justify-content-end"></div>
            </div>
            <div className="modal-body pt-0">
                <div className="row">
                    <div className="col-12 panel mb-3">
                        <div className="bg-white p-3">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label>Mail Personal</label>
                                        <input
                                            name="mailPersonal"
                                            value={
                                                employeeProfile?.mailPersonal
                                            }
                                            onChange={(e) => handleChange(e)}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label>N Documento</label>
                                        <input
                                            name="document"
                                            value={employeeProfile?.document}
                                            onChange={(e) => handleChange(e)}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label>Teléfono</label>
                                        <input
                                            name="phone"
                                            value={employeeProfile?.phone}
                                            onChange={(e) => handleChange(e)}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label>Fecha de Nacimiento</label>
                                        <input
                                            name="birth"
                                            value={employeeProfile?.birth}
                                            onChange={(e) => handleChange(e)}
                                            className="form-control"
                                            type="date"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 panel mb-3">
                        <div className="bg-white p-3">
                            <div className="row">
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label>Género</label>
                                        <div
                                            className="radio-group"
                                            onChange={(e) => handleChange(e)}
                                        >
                                            {lists?.gender?.map((item) => (
                                                <div
                                                    key={item.id}
                                                    className="mb-2 d-flex align-items-center"
                                                >
                                                    <input
                                                        type="radio"
                                                        value={item.id}
                                                        name="gender"
                                                        className="me-1"
                                                        id={item.name}
                                                        defaultChecked={
                                                            item.id ===
                                                            Number(
                                                                employeeProfile?.gender
                                                            )
                                                        }
                                                    />{" "}
                                                    <label htmlFor={item.name}>
                                                        {item.name}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label>Estado Civil</label>
                                    <div
                                        className="radio-group"
                                        onChange={(e) => handleChange(e)}
                                    >
                                        {lists?.maritalStatus?.map((item) => (
                                            <div
                                                key={item.id}
                                                className="mb-2 d-flex align-items-center"
                                            >
                                                <input
                                                    type="radio"
                                                    value={item.id}
                                                    name="maritalStatus"
                                                    className="me-1"
                                                    id={item.name}
                                                    defaultChecked={
                                                        item.id ===
                                                        Number(
                                                            employeeProfile?.maritalStatus
                                                        )
                                                    }
                                                />
                                                <label htmlFor={item.name}>
                                                    {item.name}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label>Hijos</label>
                                        <input
                                            name="childrens"
                                            value={employeeProfile?.childrens}
                                            onChange={(e) => handleChange(e)}
                                            className="form-control"
                                            type="number"
                                            min="0"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 panel mb-3">
                        <div className="bg-white p-3">
                            <div className="row">
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label>País</label>
                                        <select
                                            name="country"
                                            onChange={(e) => handleChange(e)}
                                            className="form-control"
                                            value={employeeProfile?.country}
                                        >
                                            <option
                                                value=""
                                                style={{ color: "#AFAFAF" }}
                                            >
                                                País
                                            </option>
                                            {lists?.country?.map((country) => (
                                                <option
                                                    value={country.id}
                                                    key={country.id}
                                                >
                                                    {country.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label>Nacionalidad</label>
                                        <input
                                            name="nationality"
                                            value={employeeProfile?.nationality}
                                            onChange={(e) => handleChange(e)}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label>Ciudad</label>
                                        <input
                                            name="city"
                                            value={employeeProfile?.city}
                                            onChange={(e) => handleChange(e)}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-8">
                                    <div className="form-group">
                                        <label>Dirección</label>
                                        <input
                                            name="address"
                                            value={employeeProfile?.address}
                                            onChange={(e) => handleChange(e)}
                                            className="form-control"
                                        />
                                    </div>
                                </div>

                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label>
                                            Medio de transporte utilizado
                                        </label>
                                        <input
                                            name="transport"
                                            value={employeeProfile?.transport}
                                            onChange={(e) => handleChange(e)}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 panel mb-3">
                        <div className="bg-white p-3">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label>Cargo</label>
                                        <input
                                            name="boss"
                                            value={employeeProfile?.boss}
                                            onChange={(e) => handleChange(e)}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label>Tipo de Contrato</label>
                                        <div
                                            className="radio-group"
                                            onChange={(e) => handleChange(e)}
                                        >
                                            {lists?.contract?.map((item) => (
                                                <div
                                                    key={item.id}
                                                    className="mb-2 d-flex align-items-center"
                                                >
                                                    <input
                                                        type="radio"
                                                        value={item.id}
                                                        name="contract"
                                                        id={item.name}
                                                        defaultChecked={
                                                            item.id ===
                                                            Number(
                                                                employeeProfile?.contract
                                                            )
                                                        }
                                                        className="me-1"
                                                    />
                                                    <label htmlFor={item.type}>
                                                        {item.type}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label>Banco</label>
                                        <input
                                            name="bank"
                                            value={employeeProfile?.bank}
                                            onChange={(e) => handleChange(e)}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label>Número de Cuenta</label>
                                        <input
                                            name="currentAccount"
                                            value={
                                                employeeProfile?.currentAccount
                                            }
                                            onChange={(e) => handleChange(e)}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 panel mb-3">
                        <div className="bg-white p-3">
                            <div className="row">
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label>Plan de Salud</label>
                                        <input
                                            name="healthPlan"
                                            value={employeeProfile?.healthPlan}
                                            onChange={(e) => handleChange(e)}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label>Altura</label>
                                        <input
                                            name="height"
                                            value={employeeProfile?.height}
                                            onChange={(e) => handleChange(e)}
                                            className="form-control"
                                            type="number"
                                            step="0.1"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label>Peso</label>
                                        <input
                                            name="weight"
                                            value={employeeProfile?.weight}
                                            onChange={(e) => handleChange(e)}
                                            className="form-control"
                                            type="number"
                                            step="0.1"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label>Enfermedades Crónicas</label>
                                        <input
                                            name="enfermedad"
                                            value={employeeProfile?.enfermedad}
                                            onChange={(e) => handleChange(e)}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label>PCD</label>
                                        <div
                                            className="radio-group"
                                            onChange={(e) => handleChange(e)}
                                        >
                                            <div className="mb-2 d-flex align-items-center">
                                                <input
                                                    type="radio"
                                                    className="me-1"
                                                    onChange={() => {}}
                                                    value={true}
                                                    name="pcd"
                                                    id="Yes"
                                                    checked={
                                                        String(
                                                            employeeProfile.pcd
                                                        ) === "true"
                                                    }
                                                />
                                                <label htmlFor="Yes">Sí</label>
                                            </div>
                                            <div className="mb-2 d-flex align-items-center">
                                                <input
                                                    type="radio"
                                                    className="me-1"
                                                    onChange={() => {}}
                                                    value={false}
                                                    name="pcd"
                                                    id="No"
                                                    checked={
                                                        String(
                                                            employeeProfile.pcd
                                                        ) === "false"
                                                    }
                                                />
                                                <label htmlFor="No">No</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 panel mb-3">
                        <div className="bg-white p-3">
                            <div className="row">
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label>Facebook</label>
                                        <input
                                            name="facebook"
                                            value={employeeProfile?.facebook}
                                            onChange={(e) => handleChange(e)}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label>Linkedin</label>
                                        <input
                                            name="linkedIn"
                                            value={employeeProfile?.linkedIn}
                                            onChange={(e) => handleChange(e)}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label>Instagram</label>
                                        <input
                                            name="instagram"
                                            value={employeeProfile?.instagram}
                                            onChange={(e) => handleChange(e)}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer border-0">
                <button
                    type="button"
                    className="btn btn-primary px-4"
                    onClick={() => handleSubmit()}
                >
                    Guardar Cambios
                </button>
                <button
                    type="button"
                    className="btn btn-secondary px-4"
                    data-dismiss="modal"
                    onClick={onCloseClick}
                >
                    Cancelar
                </button>
            </div>
        </Modal>
    );
};
export default ModalEditProfileInfo;
