export const DataPositionsByAreaId = async (url) => {
    const resp = await fetch(url);
    return resp;
};

export const DataPositionsByName = async (url) => {
    const resp = await fetch(url);
    return resp;
};

export const DataPositionsCompany = async (url) => {
    const resp = await fetch(url);
    return resp;
};

export const getAllAreaGroups = async (url) => {
    const resp = await fetch(url);
    return resp;
};

export const getAllAreaGroupsByCountry = async (url) => {
    const resp = await fetch(url);
    return resp;
};

export const getQuestionEmotional = async (url) => {
    const resp = await fetch(url);
    return resp;
};

export const getSalaryEmotional = async (url, method) => {
    const resp = await fetch(url, method);
    return resp;
};

export const sendCreateReport = async (url, method) => {
    const resp = await fetch(url, method);
    return resp;
};

export const getUserInfo = async (url, method) => {
    const resp = await fetch(url, method);
    return resp;
};

export const getDataCompanyByEmail = async (url, method) => {
    const resp = await fetch(url, method);
    return resp;
};

export const sendRequestReportMail = async (data) => {
    try {
        const resp = await fetch(`${process.env.REACT_APP_API}/mail/smtm_email`, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify(data),
        });
        return resp.json();
      } catch (err) {
        console.error(err);
      }
};