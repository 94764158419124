import Toggle from "../Toggle/Toggle";
import "./askes.sass";

const Askes = ({ questions, answer, array, handleClick }) => (
    questions.map((question, index) => {
        return (
            <div className="row border-bottom py-3" key={index}>
                <div
                    data-index={array}
                    className="col-12 d-flex cursor-pointer"
                    data-answer={index}
                    onClick={handleClick}
                >
                    <Toggle answer={answer[index]} />
                    <span className="askes">{question.question}</span>
                </div>
            </div>
        );
    })
)
export default Askes;
