import { useEffect, useRef, useState } from 'react'

import {
	getAreaList,
	getAssetsEmployee,
	getCompentences,
	getCulture,
	getEmployeeInformation,
	getEmployeePerformance,
	getEmployeesById,
	getEmployeesExp,
	getKnowledge,
	getLevelList,
	getMotivations,
	getPurposeByEmployeeId,
	getSalary,
	getSalarySMTM,
} from './FutureCVRequests'
import { getCountry } from '../../../../API/Lists/country'
import { getIndustryTypeList } from '../../../../API/Lists/industryType'

import CircleProgressBar from './Components/CircleProgressBar'
import {
	CareerPlanMidArrowIcon,
	CompMotThunderIcon,
	ExpProfArea,
	ExpProfIndustry,
	ExpProfTime,
	ExpProfTimeMedia,
	FlagArgentina,
	FlagBrasil,
	FlagChile,
	FlagColombia,
	FlagEcuador,
	FlagMexico,
	FlagPeru,
	FlagUruguay,
	FormationIcon,
	PerformanceCritIcon,
	PurposeIcon,
} from './FutureCVIcons'

import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import { Link, useParams } from 'react-router-dom'
import { AiOutlineCloudDownload } from 'react-icons/ai'
import Loading from '../../../../Components/Loading/Loading'

import { futureCVTranslate } from '../../../../i18n/translatedMessages'
import { FormattedMessage } from 'react-intl'

import './Components/FutureCV.sass'

const FutureCV = () => {
	const templateRef = useRef(null)
	const { id } = useParams()
	const [user] = useState(JSON.parse(localStorage.getItem('user')))
	const [employee, setEmployee] = useState({})
	const [loading, setLoading] = useState(false)
	const [visibleOnPdf, setVisibleOnPdf] = useState(false)
	const [currentEmployeeData, setCurrentEmployeeData] = useState({
		knowledge: undefined,
		purpose: undefined,
		culture: undefined,
		performance: undefined,
		competences: undefined,
		motivations: undefined,
		salary: undefined,
		employeeProfile: undefined,
		countryList: undefined,
		industryTypeList: undefined,
		employeeExp: undefined,
	})

	const getBase64FromUrl = async url => {
		if (url) {
			const data = await fetch(url)
			const blob = await data.blob()
			const resp = new Promise(resolve => {
				const reader = new FileReader()
				reader.readAsDataURL(blob)
				reader.onloadend = () => {
					const base64data = reader.result
					resolve(base64data)
				}
			})
			return await resp
		}
	}

	const competencesRequest = async () => {
		const [competences] = await getCompentences(id)
		if (competences) {
			const competencesAux = {
				analysis_problem_solving: competences?.analysis_problem_solving,
				change_management: competences?.change_management,
				customer_orientation: competences?.customer_orientation,
				innovation: competences?.innovation,
				leadership: competences?.leadership,
				organization_plan: competences?.organization_plan,
				result_orientation: competences?.result_orientation,
				self_development: competences?.self_development,
				strategic_thinking: competences?.strategic_thinking,
				stress_management: competences?.stress_management,
				team_work: competences?.team_work,
			}
			const compArrAux = Object.entries(competencesAux)
				.sort((a, b) => b[1] - a[1])
				.slice(0, 4)
			return compArrAux
		}
	}

	const motivationsRequest = async () => {
		const [motivations] = await getMotivations(id)
		if (motivations) {
			const motivacionsAux = {
				autonomy_versatility: motivations?.autonomy_versatility,
				business_orientation: motivations?.business_orientation,
				career: motivations?.career,
				commitment: motivations?.commitment,
				conceptual_orientation: motivations?.conceptual_orientation,
				economic_aspect: motivations?.economic_aspect,
				innovation_orientation: motivations?.innovation_orientation,
				job_satisfaction: motivations?.job_satisfaction,
				management_orientation: motivations?.management_orientation,
				orientation_interpersonal_relations: motivations?.orientation_interpersonal_relations,
				orientation_type_work_performed: motivations?.orientation_type_work_performed,
				prestige: motivations?.prestige,
				professional_balance: motivations?.professional_balance,
				professional_proyect: motivations?.professional_proyect,
				safety: motivations?.safety,
				skills_development: motivations?.skills_development,
				target_orientation: motivations?.target_orientation,
			}
			const motArrAux = Object.entries(motivacionsAux)
				.sort((a, b) => b[1] - a[1])
				.slice(0, 4)
			return motArrAux
		}
	}

	const getUnique = (array, key) => {
		if (typeof key !== 'function') {
			const property = key
			key = function (item) {
				return item[property]
			}
		}
		return Array.from(
			array
				.reduce(function (map, item) {
					const k = key(item)
					if (!map.has(k)) map.set(k, item)
					return map
				}, new Map())
				.values()
		)
	}

	useEffect(() => {
		const requestsForEmployeeData = async () => {
			setLoading(true)
			const employeeExp = await getEmployeesExp(id)
			const knowledge = await getKnowledge(id)
			const purpose = await getPurposeByEmployeeId(id)
			const culture = await getCulture(id)
			const [performance] = await getEmployeePerformance(id)
			const countryList = await getCountry()
			const industryTypeList = await getIndustryTypeList()
			const motivations = await motivationsRequest()
			const competences = await competencesRequest()

			const industryTypeAux = employeeExp?.map(item => {
				return industryTypeList?.find(industry => industry.id === item.industryTypeId)
			})
			setCurrentEmployeeData({
				...currentEmployeeData,
				employeeExp: employeeExp && employeeExp.sort((a, b) => a.startYear - b.startYear),
				knowledge: knowledge && knowledge,
				purpose: purpose && purpose,
				culture: culture && culture,
				performance: performance && performance,
				countryList: countryList,
				industryTypeList: getUnique(industryTypeAux, 'id'),
				motivations: motivations,
				competences: competences,
			})
			setEmployee(await getEmployeesById(id))
			setLoading(false)
		}
		requestsForEmployeeData()
	}, [id])

	useEffect(() => {
		const nextRequests = async () => {
			setLoading(true)
			const areaList = await getAreaList()
			const levelList = await getLevelList()

			const [employeeProfile] = await getEmployeeInformation(id)
			const [areaResult] = areaList.filter(filt => filt.id === employee?.areaId)
			const [levelResult] = levelList.filter(filt => filt.id === employee?.levelId)
			const assetEmployee = await getAssetsEmployee(id)
			const [salary] = await getSalarySMTM(employee?.employerUser?.email)
			const [currency] = await getSalary(id)
			const employeeAsset = await getBase64FromUrl(assetEmployee[assetEmployee.length - 1]?.url)
			setCurrentEmployeeData({
				...currentEmployeeData,
				salary: { ...salary, currency: currency?.typeCurrency },
				employeeProfile: {
					...employeeProfile,
					level: levelResult.PositionN,
					levelPlus: levelResult && levelResult.PositionNPlus1,
					area: areaResult && areaResult.profileArea,
					asset: employeeAsset && employeeAsset.length > 0 ? employeeAsset : null,
				},
			})
			setLoading(false)
		}
		nextRequests()
	}, [employee])

	const findMaxCulture = () => {
		if (currentEmployeeData.culture) {
			const arrayAux = []
			arrayAux.push(Number(currentEmployeeData.culture?.comercial))
			arrayAux.push(Number(currentEmployeeData.culture?.disruptiva))
			arrayAux.push(Number(currentEmployeeData.culture?.colaborativa))
			arrayAux.push(Number(currentEmployeeData.culture?.tradicional))
			return Math.max(...arrayAux)
		}
	}

	const calcEmployeeAge = () => {
		if (currentEmployeeData.employeeProfile) {
			const today = new Date()
			const birthDate = new Date(currentEmployeeData.employeeProfile?.birth)
			var age = today.getFullYear() - birthDate.getFullYear()
			const month = today.getMonth() - birthDate.getMonth()
			if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
				age--
			}
			return age
		}
	}
	const calcEmployeeCreated = () => {
		if (employee) {
			const date = new Date(employee.createdAt)
			var createdDate = date.getFullYear()
			return createdDate
		}
	}

	const CalcEmployeeExp = () => {
		if (currentEmployeeData.employeeExp) {
			let years = new Date().getFullYear() - currentEmployeeData.employeeExp[0]?.startYear
			let months = new Date().getMonth() + 1 - currentEmployeeData.employeeExp[0]?.startMonth

			if (months < 0) {
				months = months * -1
				years = years - 1
			}

			return {
				years,
				months,
				yearsMedia: years / currentEmployeeData.employeeExp?.length,
				monthsMedia: months / currentEmployeeData.employeeExp?.length,
			}
		}
	}

	const showCountry = () => {
		const findCountry = currentEmployeeData.countryList?.find(obj => {
			return obj.id === Number(employee?.countryId)
		})
		return findCountry
	}

	const FLAG = [<FlagChile width='20' />, <FlagArgentina width='20' />, <FlagPeru width='20' />, <FlagBrasil width='20' />, <FlagColombia width='20' />, <FlagMexico width='20' />, <FlagEcuador width='20' />, <FlagUruguay width='20' />]

	const handleGeneratePdf = async () => {
		await setVisibleOnPdf(true)
		const imageSrc = currentEmployeeData?.profileImage ?? 'https://assets.mydna.company/profile-placeholder.png'

		html2canvas(templateRef.current, {
			logging: false,
			allowTaint: true,
			useCORS: true,
		}).then(canvas => {
			let imgWidth = 212
			let imgHeight = (canvas.height * imgWidth) / canvas.width
			const imgData = canvas.toDataURL('image/png')
			const pdf = new jsPDF('p', 'mm', 'a4')
			pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight)
			pdf.save('futurecv.pdf')
		})

		setVisibleOnPdf(false)
	}

	return (
		<>
			{loading ? (
				<Loading />
			) : (
				<>
					<div className='row'>
						<div className='col text-end'>
							<button className='btn btnDescargarPDF rounded-pill fw-bolder fs-6 me-4' onClick={handleGeneratePdf}>
								<AiOutlineCloudDownload size={22} /> {futureCVTranslate.downloadFutureCV}
							</button>
						</div>
					</div>

					<div className='row p-3 align-items-center m-3 FutureCVMain' ref={templateRef}>
						<div className='col-2 text-end'>
							<img className='img-fluid rounded-circle w-75' alt='...' src={currentEmployeeData.employeeProfile?.asset || 'https://assets.mydna.company/profile-placeholder.png'} />
						</div>
						<div className='col-auto ms-3'>
							<h3 className='mb-4 fw-bolder'>{employee?.name}</h3>
							<h4 className='mb-3 h5 fw-normal'>
								<span className='fw-bolder h4'>{currentEmployeeData.employeeProfile?.level}</span> {futureCVTranslate.inTextFutureCV} <span className='fw-bolder h4'>{user?.name}</span>{' '}
								{(calcEmployeeCreated() || !isNaN(calcEmployeeCreated())) && ` desde ${calcEmployeeCreated()}`}
							</h4>
							<h4 className='d-flex h5 align-items-center fw-normal'>
								{(calcEmployeeAge() || !isNaN(calcEmployeeAge())) && (
									<>
										{calcEmployeeAge()}
										<span className='mx-2'> {futureCVTranslate.yearsTextFutureCV} | </span>
									</>
								)}
								{FLAG[showCountry()?.id - 1] || ''} <span className='mx-2'>{currentEmployeeData.employeeProfile?.city}</span>
							</h4>
						</div>
						<div className='px-5 pt-5'>
							<div className='row border-2 rounded-3 px-3 py-4' style={{ background: '#F6F6F9' }}>
								{currentEmployeeData.employeeExp?.length > 0 && (
									<div className='col-4'>
										<h4 className='fw-bolder h3 pb-4'>{futureCVTranslate.experienceTextFutureCV}</h4>
										<div className='row align-items-center pb-4'>
											<div className='col-auto'>
												<ExpProfTime width='20' />
											</div>
											<div className='col-auto'>
												<h5 className='m-0'>
													{!isNaN(CalcEmployeeExp()?.years) ? (
														CalcEmployeeExp()?.years >= 1 ? (
															<FormattedMessage id={'careerYearsTextFutureCV'}>{msg => CalcEmployeeExp()?.years + ' ' + msg}</FormattedMessage>
														) : !isNaN(CalcEmployeeExp()?.months) ? (
															<FormattedMessage id={'careerYearsTextFutureCV'}>{msg => CalcEmployeeExp()?.months?.toFixed(2) + ' ' + msg}</FormattedMessage>
														) : (
															''
														)
													) : (
														''
													)}
												</h5>
											</div>
										</div>
										<div className='row align-items-center pb-4'>
											<div className='col-auto'>
												<ExpProfArea width='20' />
											</div>
											<div className='col-auto'>
												<h5 className='m-0'>
													{getUnique(currentEmployeeData.employeeExp, 'job')
														?.slice(0, 3)
														.map((exp, key) => {
															return (
																<span key={key}>
																	{key !== 0 && ' '} {exp.job} {currentEmployeeData.employeeExp?.length - 1 !== key ? ' |' : ''}
																</span>
															)
														})}
												</h5>
											</div>
										</div>

										<div className='row align-items-center pb-4'>
											<div className='col-auto'>
												<ExpProfIndustry width='20' />
											</div>
											<div className='col-auto'>
												<h5 className='m-0'>
													{currentEmployeeData.industryTypeList?.slice(0, 3).map((industryTp, key) => {
														return (
															<span key={key}>
																{key !== 0 && ' '} {industryTp?.name} {currentEmployeeData.industryTypeList?.length - 1 !== key && ' |'}
															</span>
														)
													})}
												</h5>
											</div>
										</div>

										<div className='row align-items-center'>
											<div className='col-auto'>
												<ExpProfTimeMedia width='20' height='21' />
											</div>
											<div className='col-auto'>
												<h5 className='m-0'>
													{!isNaN(CalcEmployeeExp()?.years) ? (
														CalcEmployeeExp()?.years >= 1 ? (
															<FormattedMessage id={'employeeYearsExpFutureCV'}>{msg => CalcEmployeeExp()?.yearsMedia?.toFixed(2) + ' ' + msg}</FormattedMessage>
														) : !isNaN(CalcEmployeeExp()?.months) ? (
															<FormattedMessage id={'employeeMonthsExpFutureCV'}>{msg => CalcEmployeeExp()?.monthsMedia?.toFixed(2) + ' ' + msg}</FormattedMessage>
														) : (
															''
														)
													) : (
														''
													)}
												</h5>
											</div>
										</div>
									</div>
								)}

								{currentEmployeeData.knowledge?.length > 0 && (
									<div className='col-4'>
										<h4 className='fw-bolder h3 pb-4'>{futureCVTranslate.formationTextFutureCV}</h4>
										{currentEmployeeData.knowledge?.slice(currentEmployeeData.knowledge?.length - 4).map((know, key) => {
											return (
												<div className='pb-3 row' key={key}>
													<div className='col-auto'>
														<FormationIcon color='#27E6C4' width='20' height='21' />
													</div>
													<div className='col-auto'>
														<h5 className='align-items-center m-0'>{know?.course}</h5>
														<p className='m-0 p-0 text-muted h6'>{know?.university}</p>
													</div>
												</div>
											)
										})}
									</div>
								)}

								{currentEmployeeData.purpose?.length > 0 && (
									<div className='col-4'>
										<h4 className='fw-bolder h3 pb-4'>{futureCVTranslate.purposeTextFutureCV}</h4>

										{currentEmployeeData.purpose?.slice(currentEmployeeData.purpose?.length - 5).map((purpose, key) => {
											return (
												<div className='row align-items-center pb-4' key={key}>
													<div className='col-auto'>
														<PurposeIcon color='#78D7FF' width='20' height='21' />
													</div>
													<div className='col-auto'>
														<h5 className='m-0'>{purpose.employerPurposeToPurpose.definition}</h5>
													</div>
												</div>
											)
										})}
									</div>
								)}
							</div>
						</div>
						<div className={`row mt-5 p-0 ${currentEmployeeData.culture && currentEmployeeData.performance ? `border-bottom border-2` : ``}`}>
							{currentEmployeeData.culture && (
								<div className='col-6 border-end px-5 py-3'>
									<div className='row align-items-center'>
										<div className='col-auto'>
											<h4 className='ps-3 h3 fw-bolder'>{futureCVTranslate.cultureTextFutureCV}</h4>
											<CircleProgressBar porcentage={Math.round(findMaxCulture() || 0)} />
										</div>
										{findMaxCulture() === currentEmployeeData.culture?.comercial ? (
											<div className='col-7'>
												<h4 className='text-primary h3'>{futureCVTranslate.comercialTitleFutureCV}</h4>
												<p>{futureCVTranslate.comercialTextFutureCV}</p>
											</div>
										) : findMaxCulture() === currentEmployeeData.culture?.disruptiva ? (
											<div className='col-7'>
												<h4 className='text-primary h3'>{futureCVTranslate.disruptiveTitleFutureCV}</h4>
												<p>{futureCVTranslate.disruptiveTextFutureCV}</p>
											</div>
										) : findMaxCulture() === currentEmployeeData.culture?.tradicional ? (
											<div className='col-7'>
												<h4 className='text-primary h3'>{futureCVTranslate.traditionalTitleFutureCV}</h4>
												<p>{futureCVTranslate.traditionalTextFutureCV}</p>
											</div>
										) : findMaxCulture() === currentEmployeeData.culture?.colaborativa ? (
											<div className='col-7'>
												<h4 className='text-primary h3'>{futureCVTranslate.collaborativeTitleFutureCV}</h4>
												<p>{futureCVTranslate.collaborativeTextFutureCV}</p>
											</div>
										) : (
											''
										)}
									</div>
								</div>
							)}
							{currentEmployeeData.performance && (
								<div className='col-6 ps-5 py-3'>
									<div className='row p-0 align-items-center'>
										<h4 className='h3 fw-bolder'>{futureCVTranslate.performanceTextFutureCV}</h4>
										<div className='col-6'>
											{currentEmployeeData.performance?.answers.slice(0, 5).map((crit, key) => {
												return (
													<div className='row pb-4' key={key}>
														<div className='col-6'>
															<PerformanceCritIcon width='21' height='20' />
															<span className='ms-2'>{crit.name}</span>
														</div>
														<div className='col-6 text-end'>{crit.value}%</div>
													</div>
												)
											})}
										</div>
										<div className='col-6 text-center'>
											<h3 className='h4 textPrimary'>{futureCVTranslate.resultTextFutureCV}</h3>
											<CircleProgressBar radius={68} circleWidth={150} porcentage={Math.round(Number(currentEmployeeData.performance?.media))} fontSizePercentage={30} />
										</div>
									</div>
								</div>
							)}
						</div>
						<div className='row p-0 border-bottom border-2'>
							{currentEmployeeData.competences && (
								<div className='col-6 px-5 py-4 border-end'>
									<h4 className='h3 fw-bolder'>{futureCVTranslate.abilitiesTextFutureCV}</h4>
									{currentEmployeeData.competences?.map((comp, key) => {
										return (
											<div className='row py-2 align-items-top' key={key}>
												<div className='col-auto'>
													<CompMotThunderIcon height='22' />
												</div>
												<div className='col-11'>
													<h5>
														{comp[0] === 'analysis_problem_solving'
															? 'Análisis y Resolución de Problemas'
															: comp[0] === 'innovation'
															? 'Innovación'
															: comp[0] === 'strategic_thinking'
															? 'Visión Estratégica'
															: comp[0] === 'customer_orientation'
															? 'Orientación al cliente'
															: comp[0] === 'team_work'
															? 'Trabajo en Equipo'
															: comp[0] === 'leadership'
															? 'Liderazgo'
															: comp[0] === 'result_orientation'
															? 'Orientación a resultados'
															: comp[0] === 'organization_plan'
															? 'Plan. Y Org.'
															: comp[0] === 'change_management'
															? 'Gestión del cambio'
															: comp[0] === 'self_development'
															? 'Auto desenvolvimento'
															: comp[0] === 'stress_management'
															? 'Gerenciamento de estresse'
															: ''}
													</h5>
													<div className='progress'>
														<div
															className='progress-bar bg-green text-black'
															role='progressbar'
															style={{
																width: `${comp[1] * 10}%`,
															}}
														>
															{comp[1]} / 10
														</div>
													</div>
												</div>
											</div>
										)
									})}
								</div>
							)}

							{currentEmployeeData.motivations && (
								<div className='col-6 ps-5 pe-4 py-4'>
									<h4 className='h3 fw-bolder'>{futureCVTranslate.motivationsTextFutureCV}</h4>
									{currentEmployeeData.motivations?.map((comp, key) => {
										return (
											<div className='row align-items-top py-2' key={key}>
												<div className='col-auto'>
													<CompMotThunderIcon height='22' />
												</div>
												<div className='col-11'>
													<h5>
														{comp[0] === 'business_orientation'
															? 'Orientación comercial'
															: comp[0] === 'target_orientation'
															? 'Orientación al objetivo'
															: comp[0] === 'orientation_type_work_performed'
															? 'Orientación a la tipología del trabajo realizado'
															: comp[0] === 'innovation_orientation'
															? 'Orientación a la innovación'
															: comp[0] === 'economic_aspect'
															? 'Aspecto Económico'
															: comp[0] === 'autonomy_versatility'
															? 'Autonomía y Versatilidad'
															: comp[0] === 'safety'
															? 'Seguridad'
															: comp[0] === 'prestige'
															? 'Prestigio'
															: comp[0] === 'professional_balance'
															? 'Balance Profesional'
															: comp[0] === 'job_satisfaction'
															? 'Satisfacción con el puesto'
															: comp[0] === 'commitment'
															? 'Compromiso'
															: comp[0] === 'career'
															? 'Carrera'
															: comp[0] === 'skills_development'
															? 'Desarrollo de Competencias'
															: comp[0] === 'professional_proyect'
															? 'Proyecto Profesional'
															: comp[0] === 'orientation_interpersonal_relations'
															? 'Orientación a las Rel. Interpersonales'
															: comp[0] === 'conceptual_orientation'
															? 'Orientación Conceptual'
															: comp[0] === 'management_orientation'
															? 'Orientación a la gestión'
															: ''}
													</h5>
													<div className='progress'>
														<div
															className='progress-bar bg-green text-black'
															role='progressbar'
															style={{
																width: `${comp[1] * 10}%`,
															}}
														>
															{comp[1]} / 10
														</div>
													</div>
												</div>
											</div>
										)
									})}
								</div>
							)}
						</div>
						<div className='row border-bottom border-2 p-0'>
							{currentEmployeeData.salary && (
								<div className='col-6 px-5 py-4 border-end'>
									<h4 className='h3 fw-bolder'>{futureCVTranslate.salaryTextFutureCV}</h4>
									<div className='row p-0'>
										<div className='col-5'>
											{currentEmployeeData.salary?.current_salary ? (
												<div className='py-3'>
													<h5 className='d-inline-block me-3'>{futureCVTranslate.collabTextFutureCV}</h5>{' '}
													<span className='d-inline-block me-3'>{currentEmployeeData.salary?.currency === undefined || null || '' ? '' : currentEmployeeData.salary?.currency + ' ' + currentEmployeeData.salary?.current_salary}</span>
												</div>
											) : (
												<div className='py-3'>
													<h5 className='d-inline-block me-3'>{futureCVTranslate.emptyValueTextFutureCV}</h5>
												</div>
											)}

											{currentEmployeeData.salary?.salary_market && (
												<div className='py-3'>
													<h5 className='d-inline-block me-3'>Mercado</h5> <span>{currentEmployeeData.salary?.currency + ' ' + currentEmployeeData.salary?.salary_market}</span>
												</div>
											)}

											{currentEmployeeData.salary?.comparative && (
												<div className='py-3'>
													<h5 className='d-inline-block me-3'>{futureCVTranslate.comparativeTextFutureCV}</h5> <span className='text-dark-green fw-bolder'>{currentEmployeeData.salary?.comparative}%</span>
												</div>
											)}
										</div>
										{currentEmployeeData.salary?.comparative && (
											<div className='col-7 p-3'>
												<div className='p-4 rounded bg-light-grey text-center borderGrey'>
													<p className='h3 fw-bolder'>{futureCVTranslate.riskTitleFutureCV}</p>
													{currentEmployeeData?.salary?.comparative < -15 ? (
														<p className='text-negative-red h2 m-0 fw-bolder'>{futureCVTranslate.riskHighTextFutureCV}</p>
													) : currentEmployeeData?.salary?.comparative >= -14.9 && currentEmployeeData?.salary?.comparative <= 14.9 ? (
														<p className='textBlue h2 m-0 fw-bolder'>{futureCVTranslate.riskMediumTextFutureCV}</p>
													) : currentEmployeeData?.salary?.comparative >= 15 ? (
														<p className='textGreen h2 m-0 fw-bolder'>{futureCVTranslate.riskLowTextFutureCV}</p>
													) : (
														''
													)}
												</div>
											</div>
										)}
									</div>
								</div>
							)}
							{currentEmployeeData.employeeProfile && (
								<div className='col-6 ps-5 pe-4 py-4'>
									<h4 className='h3 fw-bolder'>{futureCVTranslate.careerPlanningTextFutureCV}</h4>
									<div className='row row-cols-3 pt-3 align-items-center'>
										<div className={currentEmployeeData?.employeeProfile?.levelPlus ? `col-5` : `col-auto`}>
											<div className='p-4 rounded bg-light-grey borderGrey'>
												<p className='text-muted'>{futureCVTranslate.actualLevelTextFutureCV}</p>
												<p className='text-dark-purple h2 m-0 fw-bolder'>{currentEmployeeData?.employeeProfile?.level}</p>
											</div>
										</div>
										<div className='col-1 p-0 text-center'>
											<CareerPlanMidArrowIcon width='50' />
										</div>
										<div className={currentEmployeeData?.employeeProfile?.levelPlus ? `col-6` : `col-auto`}>
											<div className='p-4 rounded bgBlue textBlue borderBlue'>
												<p> {futureCVTranslate.nextLevelTextFutureCV}</p>
												<p className='m-0 h2 fw-bolder'>{currentEmployeeData?.employeeProfile?.levelPlus}</p>
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
						<div className='row pt-4 justify-content-between align-items-bottom'>
							<div className='col-auto'>
								<h4 className='h3 fw-bolder'> {futureCVTranslate.observationsTextFutureCV}:</h4>
							</div>

							{!visibleOnPdf && (
								<div className='col-auto'>
									<Link to={`/app/analytic/desempeno/${id}`}>{futureCVTranslate.editObservationsTextFutureCV}</Link>
								</div>
							)}
						</div>
						<div className={`row`}>
							<div className='col'>
								{console.log(currentEmployeeData?.performance?.observation)}
								<textarea rows={10} value={currentEmployeeData?.performance?.observation} className={`w-100 borderPurple`} style={{ maxHeight: '200px', overflowY: 'scroll' }} disabled />
							</div>
						</div>
					</div>
				</>
			)}
		</>
	)
}

export default FutureCV
