import { EmployeeRoutes } from "../../Routes/Routes";
import EmployerContextProvider from "../../Store/EmployerContext";
import MenuColaboradores from "./Components/Menu/MenuColaboradores";

import "./analytics.sass";
import HeaderLayout from "./layouts/Header";
import ServicesContextProvider from "../../Store/ServicesContext";

const Management = () => {
    return (
        <ServicesContextProvider>
            <EmployerContextProvider>
                <div className="menu-scrolling-analytics">
                    <div className="row py-3 ps-3">
                        <div className="col-md-3 col-sm-3">
                            <MenuColaboradores />
                        </div>
                        <div className="col-md-9 col-sm-9">
                            <HeaderLayout>
                                <EmployeeRoutes />
                            </HeaderLayout>
                        </div>
                    </div>
                </div>
            </EmployerContextProvider>
        </ServicesContextProvider>
    );
};
export default Management;
