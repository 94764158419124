export const getEmployeesById = async (employeeId) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/employer/${employeeId}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return resp.json();
  } catch (error) {
    console.error(error);
  }
};

export const getEmployeeInformation = async (employeeId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API}/employerinformations/${employeeId}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

export const getAssetsEmployee = async (employeeId) => {
 try {
     const response = await fetch(
         `${process.env.REACT_APP_API}/assetsuploader/employee/${employeeId}`,
         {
             method: "GET",
             headers: {
                 "Content-type": "application/json",
             },
         }
     );
     return response.json();
 } catch (error) {
     console.error(error);
 }
};

export const getAreaList = async () => {
 try {
     const resp = await fetch(`${process.env.REACT_APP_API}/search/area`, {
         method: 'GET',
         mode: 'cors',
         headers: {
             'Content-Type': 'application/json',
         },
     });
     return await resp.json();
 } catch (err) {
     console.error(err);
 }
};

export const getLevelList = async () => {
 try {
   const resp = await fetch(`${process.env.REACT_APP_API}/search/level`, {
     method: 'GET',
     mode: 'cors',
     headers: {
       'Content-Type': 'application/json',
     },
   });
   return resp.json();
 } catch (err) {
   console.error(err);
 }
};

export const getEmployeesExp = async (employeeId) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/employersexp/exp/${employeeId}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};

export const getKnowledge = async (employeeId) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/knowledge/${employeeId}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};

export const getPurposeByEmployeeId = async (employeeId) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/purpose/${employeeId}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};

export const getCulture = async (employeeId) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/employercp/${employeeId}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return resp.json();
  } catch (err) {
    console.error(err);
  }
};

export const getEmployeePerformance = async (employeeId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API}/employermedia/${employeeId}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.json();
  } catch (error) {
    console.error(error);
  }
};

export const getEmployerMotivationAssets = async (employeeId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API}/motivation/${employeeId}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.json();
  } catch (error) {
    console.error(error);
  }
};

export const getCompentences = async (employeeId) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/motivation/resultMycoHabilities/${employeeId}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};

export const getMotivations = async (employeeId) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/motivation/resultMycoMotivations/${employeeId}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};

export const getSalarySMTM = async (employeeMail) => {
  try {
    const data = await fetch(`https://smtm.co/v1/position_by_mydna_email?email=${employeeMail}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return data.json();
  } catch (error) {
    console.error(error);
  }
};

export const getSalary = async (employerId) => {
  try {
      const resp = await fetch(
          `${process.env.REACT_APP_API}/salary/${employerId}`,
          {
              method: "GET",
              mode: "cors",
              headers: {
                  "Content-Type": "application/json",
              },
          }
      );
      return resp.json();
  } catch (err) {
      console.error(err);
  }
};
