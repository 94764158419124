import "./circularBar.sass"

const CircularBar = ({ percentagem }) => {
    return (
        <div className="content-circular-bar">
            <svg >
                <circle cx="85" cy="85" r="70" />
                <circle cx="85" cy="85" r="70" style={{ strokeDashoffset: `calc(440 - (440 * ${percentagem}) / 100)` }} />
            </svg>
            <div className="number">
                <h2>{percentagem}<span>%</span></h2>
            </div>
        </div>
    )
}
export default CircularBar