import { useState, useEffect } from "react";
import { BiError } from "react-icons/bi";

import {
    civilStatesList,
    genderList,
    contractTypesList,
    sendProfile,
    getProfile,
    editProfile,
} from "../../../API/Employee/Profile";

import { Address } from "./Components/Address";
import { Contract } from "./Components/Contract";
import { Family } from "./Components/Family";
import { Health } from "./Components/Health";
import { Interests } from "./Components/Interests";
import { Personal } from "./Components/Personal";
import { SocialNetwork } from "./Components/SocialNetwork";
import { MenuForm } from "./Components/MenuForm/MenuForm";
import { validation } from "./validation";
import Loading from "../../../Components/Loading/Loading";
import { getEmployers } from "../../../API/Analytics/employers";
import { getCountry } from "../../../API/Lists/country";

import "./profile.sass";
import { postAssetsEmployee } from "../../../API/Employee/Employee";
import { modalAssignTranslate } from "../../../i18n/translatedMessages";

const ProfileEdit = () => {
    const { companyId, id } = JSON.parse(localStorage.getItem("user"));

    const profileEmpty = {
        employerId: id,
        mailPersonal: "",
        phone: "",
        document: "",
        birth: "",
        linkedIn: "",
        facebook: "",
        instagram: "",
        skype: "",
        twitter: "",
        gender: "",
        maritalStatus: "",
        childrens: "",
        city: "",
        country: "",
        address: "",
        nationality: "",
        transport: "",
        contract: "",
        boss: "",
        bank: "",
        currentAccount: "",
        healthPlan: "",
        height: 0,
        weight: 0,
        enfermedad: "",
        pcd: "",
        typePCD: "",
        hobies: "",
        sports: "",
        interests: "",
        voluntaryWork: "",
    };

    const [loading, setLoading] = useState(false);
    const [messageError, setMessageError] = useState(false);
    const [edit, setEdit] = useState(false);

    const [lists, setLists] = useState({
        gender: [],
        maritalStatus: [],
        contract: [],
        country: [],
        employee: [],
    });

    const [data, setData] = useState(profileEmpty);
    const [dataImage, setDataImage] = useState(null);

    const [error, setError] = useState({
        mailPersonal: false,
        phone: false,
        document: false,
        birth: false,
        gender: false,
        city: false,
        country: false,
        boss: false,
    });

    useEffect(() => {
        const fetchList = async () => {
            const [country, gender, maritalStatus, contract, employeeList] =
                await Promise.all([
                    getCountry(),
                    genderList(),
                    civilStatesList(),
                    contractTypesList(),
                    getEmployers(companyId),
                ]);

            const employee = employeeList?.filter((person) => person.id !== id);

            setLists({
                country,
                gender,
                maritalStatus,
                contract,
                employee,
            });
        };

        const fetchData = async () => {
            const [response] = await getProfile(id);
            if (response) {
                setData({
                    ...data,
                    ...response,
                });
                setEdit(true);
            }
        };

        fetchData();
        fetchList();
    }, []);

    const handleChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value,
        });
    };

    const handleImage = (image) => {
        setData({
            ...data,
            image: image,
        });
    };

    const handleSubmit = async (event) => {
        //const [errors, validated] = validation(data, error);
        //setError(errors);
        //setMessageError(!validated);
        const imgSendAux = {
            ...dataImage,
            file: data.image,
        };
        if (dataImage !== null) {
            await postAssetsEmployee(imgSendAux);
        }
        //if (validated) {
        setLoading(true);

        edit ? await editProfile(data, id) : await sendProfile(data);
        setLoading(false);
        //}
    };

    return (
        <>
            {messageError && (
                <span className="message-error">
                    <BiError size={20} />
                    Campos vazios não permitidos
                </span>
            )}
            {loading && (
                <div className="loading">
                    {" "}
                    <Loading />
                </div>
            )}

            <div className="content-profile-edit">
                <div className="menu">
                    <MenuForm />
                </div>
                <div className="w-100">
                    <div className="form">
                        <div className="row">
                            <div className="col">
                                <div className="box" id="personal">
                                    <Personal
                                        onChange={handleChange}
                                        data={data}
                                        error={error}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="box" id="socialNetwork">
                                    <SocialNetwork
                                        onChange={handleChange}
                                        data={data}
                                        handleImage={handleImage}
                                        setDataImage={setDataImage}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="box" id="family">
                                    <Family
                                        onChange={handleChange}
                                        data={data}
                                        error={error}
                                        lists={lists}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="box" id="address">
                                    <Address
                                        onChange={handleChange}
                                        data={data}
                                        error={error}
                                        lists={lists}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="box" id="contract">
                                    <Contract
                                        onChange={handleChange}
                                        data={data}
                                        lists={lists}
                                        error={error}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="box" id="health">
                                    <Health
                                        onChange={handleChange}
                                        data={data}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="box" id="interests">
                                    <Interests
                                        onChange={handleChange}
                                        data={data}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col d-flex justify-content-end">
                            <button onClick={handleSubmit}>
                                {modalAssignTranslate.btnSave}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default ProfileEdit;
