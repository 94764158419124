import { useContext, useEffect, useState } from 'react'
import { GrLineChart } from 'react-icons/gr'
import { InputDropdown } from './InputDropdown'
import { getEmployers } from '../../API/Analytics/employers'
import { getAreas, getLevels, searchUsersByProjection } from '../../API/carrerProjection'
import { careerPlanTranslate } from '../../i18n/translatedMessages'
import { CompanyContext } from '../../Store/CompanyContext'

import { translate } from '../../i18n/Library/areasCorrelation'

import Loading from '../../Components/Loading/Loading'

import './careerProjection.sass'
import { FormattedMessage } from 'react-intl'

const img = "https://assets.mydna.company/profile-placeholder.png"

const CareerProjection = () => {


    const { store } = useContext(CompanyContext)
    const language = store.language === 'PT-BR' ? 'pt' : 'es'
    const user = JSON.parse(localStorage.getItem('user'))
    const [searchByName, setSearchByName] = useState('')
    const [job, setJob] = useState({
        id: 0,
        name: "",
    })
    const [area, setArea] = useState({
        id: 0,
        name: "",
    })
    const [loading, setLoading] = useState(true)
    const [areaList, setAreaList] = useState(null)
    const [levelList, setLevelList] = useState(null)
    const [employerList, setEmployerList] = useState([])
    const [auxList, setAuxList] = useState(null)

    const [show, setShow] = useState(false)

    useEffect(() => {
        const setLists = async () => {
            const responseArea = await getAreas()
            const responseLevel = await getLevels()

            setAreaList(responseArea.map(item => ({ id: item.id, name: item.profileArea })))
            setLevelList(responseLevel.map(item => ({ id: item.id, name: item.PositionN })))

            setAuxList(await getEmployers(user.id))

            setLoading(false)

        }
        setLists()
    }, [])   

    const handleSearch = () => {
        setLoading(true)
        const setList = async () => {
            setAuxList(await searchUsersByProjection(job.name, area.name, user.id))
        }

        setList()
    }

    const handleSearchName = event => {
        setSearchByName(event.currentTarget.value)
    }

    const handleClear = async () => {
        setLoading(true)
        setArea({ id: 0, name: "" })
        setJob({ id: 0, name: "" })
        setAuxList(await getEmployers(user.id))
        setSearchByName("")
        setLoading(false)
    }

    useEffect(() => {
        if (auxList) {
            const newList = auxList.filter(employer =>
                employer.name.toLowerCase().includes(searchByName.toLowerCase())
            )
            setEmployerList(newList)
            setLoading(false)
        }
    }, [searchByName, auxList])

    return (
        <div className="career-projection">
            {loading && <div className="loading">
                <Loading />
            </div>}
            <div className="row">
                <div className="col offset-1 d-flex">
                    <div className="icon">
                        <GrLineChart size={30} />
                    </div>
                    <h1>{careerPlanTranslate.careerPlanTitle}</h1>
                </div>
            </div>
            <div className="row mt-5 mx-5">
                <div className="col d-flex align-items-end offset-1 mb-3">
                    <span className="fw-500">{careerPlanTranslate.careerPlanText}</span>
                </div>
            </div>
            <div className="row px-5">
                <div className="col d-flex align-items-end offset-1">
                    <FormattedMessage id={"careerPlanPHRole"}>
                        {msg => (
                            levelList && <InputDropdown
                                value={job.name}
                                placeholder={msg}
                                list={levelList}
                                handleClick={event => setJob({ id: event.target.dataset.value, name: event.target.dataset.name })}
                                onChange={event => setJob({ ...job, name: event.target.value })}
                                name="job"
                            />
                        )}
                    </FormattedMessage>
                    <span className="fw-500">de</span>
                    <FormattedMessage id={"careerPlanPHArea"}>
                        {msg => (
                            areaList && <InputDropdown
                                value={area.name}
                                placeholder={msg}
                                list={areaList}
                                handleClick={event => setArea({ id: event.target.dataset.value, name: event.target.dataset.name })}
                                onChange={event => setArea({ ...area, name: event.target.value })}
                                name="area"
                            />
                        )}
                    </FormattedMessage>
                    <button onClick={handleSearch} className="btn-search-carrer">{careerPlanTranslate.careerPlanBtnSearch}</button>
                    <button onClick={handleClear} className="btn-clear-carrer">{careerPlanTranslate.careerPlanBtnClean}</button>
                </div>
            </div>
            <div className="row px-5">
                <div className="col offset-8 my-4">
                    <FormattedMessage id={"careerPlanPHNameColaborator"}>
                        {msg => (
                            <input type="text"
                                placeholder={msg}
                                className='w-100' value={searchByName}
                                onChange={handleSearchName} />
                        )}
                    </FormattedMessage>
                </div>
            </div>
            <div className="row px-5">
                <div className="col offset-1">

                    <ul className="colaborators-list">
                        {employerList && employerList.map(employer =>
                            <li className='my-3' key={employer.id} onClick={() => setShow(!show)}>
                                <div className="row">
                                    <div className="col-1">
                                        <img src={employer.profileImg ? employer.profileImg : img} alt={employer.name} className="rounded rounded-circle" />
                                    </div>
                                    <div className="col-3">
                                        <span className="title">{employer.name}</span>
                                        <span>                                           
                                            { translate[language][employer.level?.PositionN]} de {translate[language][employer.area?.profileArea]}
                                        </span>
                                    </div>
                                    <div className="col-3">
                                        <span className="title">{careerPlanTranslate.careerPlanNextLevel}</span>
                                        <span>
                                            {  translate[language][employer?.level?.PositionN]}
                                            </span>
                                        <span>
                                            {  translate[language][employer?.level?.PositionNPlus1]}
                                            </span>
                                    </div>
                                    <div className="col-3">
                                        <span className="title">{careerPlanTranslate.careerPlanSameAreas}</span>
                                        <div className="d-flex">
                                            <span className='ms-1'>
                                                {translate[language][employer.area?.profileArea]}
                                            </span>
                                            <span className='ms-1'>
                                                {translate[language][employer.area?.affineArea1]}
                                            </span>
                                            {employer.area?.affineArea2 !== "" && <span className='ms-1'>- { translate[language][employer.area?.affineArea2] }</span>}
                                            {employer.area?.affineArea3 !== "" && <span className='ms-1'>- { translate[language][employer.area?.affineArea3] }</span>}
                                        </div>
                                    </div>
                                </div>
                            </li>)}
                    </ul>

                </div>
            </div>
        </div>
    )
}
export default CareerProjection