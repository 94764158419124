export const getMessageByCompany = async (companyId) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/message/company/${companyId}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};
export const getMessageByEmployee = async (employeeId) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/message/employee/${employeeId}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};
export const postMessage = async (data) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/message/`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};
export const putMessage = async (data, messageId) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/message/${messageId}`, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};
export const deleteMessage = async (messageId) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/message/${messageId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};
