import { useState } from 'react'
import { HiChevronDown } from 'react-icons/hi2'

import { useIntl } from 'react-intl'

const AccordionItem = ({ title, content, id, isOpen, setIsOpen }) => {
	return (
		<div className='tw-px-4 tw-py-2 border-bottom'>
			<button className='tw-cursor-pointer tw-z-0 tw-h-[50px] tw-w-full tw-bg-transparent tw-flex tw-justify-between tw-items-center border-0' onClick={() => setIsOpen(isOpen ? null : id)}>
				<h5 className='tw-font-bold tw-text-base'>{title}</h5>
				<HiChevronDown className={`tw-transition-all tw-duration-500 tw-ease-in-out ${isOpen ? 'tw-rotate-180' : 'tw-rotate-0'}`} />
			</button>
			<div className={`tw-grid tw-overflow-hidden tw-transition-all tw-duration-300 tw-ease-in-out ${isOpen ? 'tw-grid-rows-[1fr] tw-opacity-100' : 'tw-grid-rows-[0fr] tw-opacity-0'}`}>
				<div className='tw-overflow-hidden'>{content}</div>
			</div>
		</div>
	)
}

export function Accordion({ list }) {
	const getLanguage = useIntl()
	const language = getLanguage.locale === 'PT-BR' ? 'pt' : 'es'

	const [isOpen, setIsOpen] = useState(1)

	return (
		<div className='tw-rounded-md border tw-mt-5'>
			{list.map(item => (
				<AccordionItem key={item.id} id={item.id} title={item.title[language] || item.title} content={item.content[language] || item.content} isOpen={isOpen === item.id} setIsOpen={setIsOpen} />
			))}
		</div>
	)
}
