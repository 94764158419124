// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-time-line {
  position: relative;
  margin: 2rem;
}
.content-time-line .line {
  position: absolute;
  top: 55px;
  width: 100%;
}
.content-time-line .line hr {
  border: none;
  border-top: 4px solid rgb(64, 194, 255);
}

.card-item {
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  align-items: center;
  margin-top: 10px;
  flex: 0 0 33%;
  cursor: pointer;
}
.card-item svg {
  margin-bottom: 10px;
}
.card-item .ball-point {
  border-radius: 50rem;
  width: 30px;
  height: 30px;
  border: 3px solid #7a6be6;
  padding: 2px;
  margin-bottom: 5px;
  background: radial-gradient(circle, rgb(122, 107, 230) 45%, rgba(255, 255, 255, 0) 46%);
  transition: 300ms;
}
.card-item .ball-point.active, .card-item .ball-point:hover {
  border: 3px solid #32d8d8;
  background: radial-gradient(circle, #32d8d8 45%, rgba(255, 255, 255, 0) 46%);
}
.card-item .ball-point .text {
  font-weight: 500;
}
.card-item .ball-point .job {
  font-weight: 600;
}
.card-item .ball-point .date {
  color: #9a9a9a;
}

.btn_see_more {
  cursor: pointer;
  color: #7A6BE8;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/Views/Analytics/Components/Timeline/timeline.sass"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,YAAA;AACJ;AACI;EACI,kBAAA;EACA,SAAA;EACA,WAAA;AACR;AAAQ;EACI,YAAA;EACA,uCAAA;AAEZ;;AAAA;EACI,YAAA;EACA,6BAAA;EACA,aAAA;EACA,sBAAA;EACA,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,aAAA;EACA,eAAA;AAGJ;AADI;EACI,mBAAA;AAGR;AADI;EACI,oBAAA;EACA,WAAA;EACA,YAAA;EACA,yBAAA;EACA,YAAA;EACA,kBAAA;EACA,uFAAA;EACA,iBAAA;AAGR;AAFQ;EACI,yBAAA;EACA,4EAAA;AAIZ;AAFQ;EACI,gBAAA;AAIZ;AAFQ;EACI,gBAAA;AAIZ;AAFQ;EACI,cAAA;AAIZ;;AAFA;EACI,eAAA;EACA,cAAA;EACA,gBAAA;AAKJ","sourcesContent":[".content-time-line\n    position: relative\n    margin: 2rem\n\n    & .line\n        position: absolute\n        top: 55px\n        width: 100%\n        & hr\n            border: none\n            border-top: 4px solid rgb(64, 194, 255)\n\n.card-item\n    border: none\n    background-color: transparent\n    display: flex\n    flex-direction: column\n    font-size: 12px\n    align-items: center\n    margin-top: 10px\n    flex: 0 0 33%\n    cursor: pointer\n\n    & svg\n        margin-bottom: 10px\n\n    .ball-point\n        border-radius: 50rem\n        width: 30px\n        height: 30px\n        border: 3px solid #7a6be6\n        padding: 2px\n        margin-bottom: 5px\n        background: radial-gradient(circle, rgba(122,107,230,1) 45%, rgba(255,255,255,0) 46%)\n        transition: 300ms\n        &.active, &:hover\n            border: 3px solid #32d8d8\n            background: radial-gradient(circle, #32d8d8 45%, rgba(255,255,255,0) 46%)\n\n        & .text\n            font-weight: 500\n\n        & .job\n            font-weight: 600\n\n        & .date\n            color: #9a9a9a\n\n.btn_see_more\n    cursor: pointer\n    color: #7A6BE8\n    font-weight: 700"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
