import "../stylesSteps.css";
import { Link } from 'react-router-dom'

const RySStep4 = ({ imgProf, textProf, changeView }) => {
  return (
    <div className="container-fluid StepsFormClass">
      <div className="row text-center pt-5">
        <img
          className="img-fluid img-final-step m-auto"
          src={imgProf}
          alt=""
        />
        <h5 className="pt-4">
          Hola, soy {textProf}, gracias
          por la información, ahora puedo llegar preparado para nuestra reunión.
          <br />
          <br />
          Te estaré enviando mis disponibilidades de agenda para coordinar una
          reunión y empezar la búsqueda. Para agilizar el proceso, te aconsejo
          rellenar el formulario de cultura, que nos guiará en reclutar perfiles
          compatibles con la cultura de tu empresa.
        </h5>
      </div>
      <div className="row pt-5">
        <div className="col-12 text-center">
          <button className="step-button" onClick={() => changeView("8")}>
            <img
              className="img-fluid"
              src="https://assets.mydna.company/clima_laboral.svg"
              alt=""
            />
            Contestar Reporte de Cultura
          </button>
          <button className="step-button-fin">Finalizar</button>
        </div>
      </div>
    </div>
  );
};
export default RySStep4;
