import { Modal, Button } from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";

import "./ModalConfirmDelete.sass";
import { useIntl } from "react-intl";

const ModalConfirmDelete = ({ show, hide, handleDelete }) => {
  const language = useIntl();
  return (
    <Modal
      show={show}
      onHide={hide}
      backdrop="static"
      keyboard={false}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable={true}
      className="ModalComfirmDelete-main"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {language.locale == "ES" ? "Confirmar y Eliminar" : "Confirmar e Excluir"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container assign-delete-modal">
          <div className="row justify-content-center">
            <div className="col-2 text-center">
              <AiFillCloseCircle size={50} className="assign-mark-del" />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button className="confirm-delete-btn-dark-grey rounded-pill" onClick={hide}>
          Cancelar
        </Button>
        <Button className="confirm-delete-btn-red rounded-pill" onClick={handleDelete}>
          {language.locale == "ES" ? "Eliminar" : "Excluir"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalConfirmDelete;
