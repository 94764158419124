// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-response .title {
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-response .title span {
  color: #ee5151;
  font-weight: bold;
  margin-left: 15px;
}
.modal-response .title svg {
  color: #ee5151;
}
.modal-response button {
  padding: 2px 10px;
  background: #0058ad;
  color: white;
  border: none;
  border-radius: 3px;
}`, "",{"version":3,"sources":["webpack://./src/Views/SignupB2E/modal.sass"],"names":[],"mappings":"AACI;EACI,eAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAAR;AAEQ;EACI,cAAA;EACA,iBAAA;EACA,iBAAA;AAAZ;AAEQ;EACI,cAAA;AAAZ;AAEI;EACI,iBAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;AAAR","sourcesContent":[".modal-response\n    .title\n        padding: 10px 0\n        display: flex\n        justify-content: center\n        align-items: center\n\n        span\n            color: #ee5151\n            font-weight: bold\n            margin-left: 15px\n\n        svg\n            color: #ee5151\n\n    button\n        padding: 2px 10px\n        background: #0058ad\n        color: white\n        border: none\n        border-radius: 3px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
