// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FiltersMain .btn {
  background-color: #D9D9E2;
  color: #9391A1;
  font-weight: 500;
}
.FiltersMain .btn:hover {
  color: #7A6BE8;
}
.FiltersMain .btn:focus {
  box-shadow: none;
}
.FiltersMain .btn-something-marked {
  color: #7A6BE8;
}
.FiltersMain input.form-check-input {
  background-color: #7A6BE8;
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/Filters/DropFilter.sass"],"names":[],"mappings":"AACI;EACI,yBAAA;EACA,cAAA;EACA,gBAAA;AAAR;AACQ;EACI,cAAA;AACZ;AAAQ;EACI,gBAAA;AAEZ;AAAI;EACI,cAAA;AAER;AAAI;EACI,yBAAA;EACA,YAAA;AAER","sourcesContent":[".FiltersMain\n    .btn\n        background-color: #D9D9E2\n        color: #9391A1\n        font-weight: 500\n        &:hover\n            color: #7A6BE8\n        &:focus\n            box-shadow: none\n        \n    .btn-something-marked\n        color: #7A6BE8\n    \n    input.form-check-input\n        background-color: #7A6BE8\n        border: none\n    input.form-check-input:checked\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
