import { Link } from 'react-router-dom'
import { useContext, useState } from 'react'
import { Col, Navbar, Nav } from 'react-bootstrap'
import { MydnaLogoMobileDark, MydnaLogoDark } from '../../Icons/logo'
import { MdSearch } from 'react-icons/md'
import { FaRegUserCircle } from 'react-icons/fa'
import { HiOutlineEmojiHappy } from 'react-icons/hi'
import { AiOutlineLogout } from 'react-icons/ai'
import { RiNotification3Line } from 'react-icons/ri'
import DropdownMenu from './dropdown'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'

import { EmployerContext } from '../../Store/EmployerContext'

import './mainMenu.sass'

const MainMenuEmployee = ({ clearOption }) => {
	const handleRecharge = () => {
		window.location.reload(false)
	}

	const { store } = useContext(EmployerContext)

	const [value, setValue] = useState(store.language === 'ES' ? 'Español' : 'Português')
	const handleSelect = e => {
		setValue(e)
	}

	const { actions } = useContext(EmployerContext)

	return (
		<Navbar className='main-menu-employee bg-md-light' sticky='top'>
			<Col className='main-menu-col' md={10} xs={12}>
				<Navbar.Brand href='/'>
					<div className='desktop'>
						<MydnaLogoDark size={140} />
					</div>
					<div className='mobile'>
						<MydnaLogoMobileDark size={22} />
					</div>
				</Navbar.Brand>

				<Nav className='main-menu-mobile'>
					<Link to='/app'>
						<HiOutlineEmojiHappy />
					</Link>
					<Link to='/app'>
						<FaRegUserCircle />
					</Link>
					<Link to='/app'>
						<MdSearch />
					</Link>
					<Link to='/app'>
						<RiNotification3Line />
					</Link>
					<Link to='/app'>
						<AiOutlineLogout />
					</Link>
				</Nav>

				<Nav className='main-menu-nav-employee'>
					<div>
						{window.location.pathname === '/employee' ? (
							<button className='btn employeeMenuNavItem p-0 color-white' onClick={handleRecharge}>
								Inicio
							</button>
						) : (
							<Link className='employeeMenuNavItem color-white' to='/employee' onClick={clearOption}>
								Inicio
							</Link>
						)}
					</div>
					<div className='d-flex justify-content-xl-between'></div>

					<div className='bar-active-menu'></div>
				</Nav>
			</Col>

			<Col className='col-user-dropdown d-flex justify-content-end pe-5'>
				<DropdownButton className='dropdown-main-menu' id='dropdown-basic-button' size='sm' variant='secondary' menuVariant='dark' title={<span style={{ color: 'white' }}>{value}</span>} onSelect={handleSelect}>
					<Dropdown.Item eventKey='Español' onClick={() => actions.setLanguage('ES')}>
						Español
					</Dropdown.Item>
					<Dropdown.Item eventKey='Português' onClick={() => actions.setLanguage('PT-BR')}>
						Português
					</Dropdown.Item>
				</DropdownButton>
			</Col>

			<Col md={-2} xs={0} className='col-user-dropdown d-flex justify-content-end pe-2'>
				<DropdownMenu />
			</Col>
		</Navbar>
	)
}
export default MainMenuEmployee
