class Mask {

    removeComma(text) {
        const string = String(text)
        if (text !== null) {
            return string.replace(/,/g, '');
        }
        return text
    }

    setDots(text) {
        const string = String(text)
        if (text !== null) {
            const onlyNumbers = string.replace(/\D/g, '');
            return onlyNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        }
        return text
    }

    removeDots(text) {
        const string = String(text);
        if (text !== null) {
            return string.replace(/\./g, '');
        }
        return text;
    }
}
export default new Mask()