import { HiExclamationTriangle } from 'react-icons/hi2'
import './modal.sass'

export function ModalError({ show, handleShow }) {
	return (
		show && (
			<div className='overlayer'>
				<div className='modal-body-success'>
					<div className='row'>
						<div className='col flex justify-content-center align-items-center'>
							<HiExclamationTriangle size={100} className='text-negative-red' />
						</div>
					</div>
					<div className='row'>
						<div className='col p-4 text-center'>
							<h3 className='fw-bold'>¡Necesitas llenar el formulario!</h3>
						</div>
					</div>
					<div className='row mt-4'>
						<div className='col'>
							<button className='btn btn-outline-primary rounded-pill w-100' onClick={() => handleShow(false)}>
								Ok
							</button>
						</div>
					</div>
				</div>
			</div>
		)
	)
}
