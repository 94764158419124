import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";

import "./ListadoReportes.css";
import { DefaultPattern } from "../../../../../i18n/translatedMessages";

const ListadoReportes = (props) => {
  useEffect(() => {
    getDataReport();
  }, []);

  const [reportsFilters, setReportsFilters] = useState([]);

  const [userReport, setuserReport] = useState([]);

  const getDataReport = () => {
    fetch(`https://smtm.co/v1/getallreports?email=${JSON.parse(localStorage.getItem("email"))}`)
      .then((response) => response.json())
      .then((data) => setuserReport(data));
  };

  const changeDate = (datefull) => {
    let date = new Date(datefull);
    let fecha = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    return fecha;
  };

  const generatePdf = (code) => {
    let url = `https://smtm.co/recover.pdf?email=${JSON.parse(localStorage.getItem("email"))}&code=${code}`;
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <>
      <div className="custom-table-unitario ms-5">
        <Table responsive>
          <thead>
            <tr className="text-center nohighlight">
              <th>{DefaultPattern.date}</th>
              <th>{DefaultPattern.area}</th>
              <th>{DefaultPattern.industry}</th>
              <th>{DefaultPattern.positionLabel}</th>
              <th>{DefaultPattern.country}</th>
              <th>{""}</th>
            </tr>
          </thead>
          <tbody>
            {userReport.map((value, index) => (
              <tr key={index} className="custom-table-row-striped-odd-unitario highlight text-center">
                <td>{changeDate(value.created_at)}</td>
                <td>{value.report_params.split(":")[4].split(",")[0].slice(1, -1)}</td>
                <td>{value.report_params.split(":")[2].split(",")[0].slice(1, -1)}</td>
                <td>{value.report_params.split(":")[3].split(",")[0].slice(1, -1)}</td>
                <td>{value.report_params.split(":")[10].split(",")[0].slice(1, -1)}</td>
                <td>
                  <button className="btn-descargar-green" variant="outline-primary" value={value.code} onClick={() => generatePdf(value.code)}>
                    <img src="https://assets.mydna.company/b2b-images/download-cloud.svg" alt="Descargar Reporte" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default ListadoReportes;
