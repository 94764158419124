import {
    employeeExperienceTranslate,
    employeeProfileEdit,
} from "../../../../i18n/translatedMessages";

export const Address = ({ onChange, data, error, lists }) => {
    return (
        <div className="content-form">
            <div className="row">
                <div className="col">
                    <h3>{employeeExperienceTranslate.adressTitleEEProfile}</h3>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <label htmlFor="city">
                        {employeeExperienceTranslate.cityEEProfile}
                    </label>
                    <input
                        type="text"
                        id="city"
                        name="city"
                        onChange={onChange}
                        value={data.city}
                        className={error.city ? "error" : ""}
                    />
                    {error.city && (
                        <small>{employeeProfileEdit.profileError}</small>
                    )}
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="label">
                        {employeeExperienceTranslate.countryEEProfile}
                    </div>
                    <select
                        name="country"
                        onChange={onChange}
                        className={error.country ? "error" : ""}
                        value={data.country}
                    >
                        <option value="" style={{ color: "#AFAFAF" }}>
                            País
                        </option>
                        {lists?.country.map((country) => (
                            <option value={country.id} key={country.id}>
                                {country.name}
                            </option>
                        ))}
                    </select>
                    {error.boss && (
                        <small>{employeeProfileEdit.profileError}</small>
                    )}
                </div>
                {/* <div className="col">
                    <label htmlFor="country">País</label>
                    <input type="text" id="country" name="country" onChange={onChange} value={data.country} className={error.country ? 'error' : ''} />
                    {error.country && <small>{employeeProfileEdit.profileError}</small>}
                </div> */}
            </div>
            <div className="row">
                <div className="col">
                    <label htmlFor="address">
                        {employeeExperienceTranslate.adressEEProfile}
                    </label>
                    <input
                        type="text"
                        id="address"
                        name="address"
                        onChange={onChange}
                        value={data.address}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <label htmlFor="nationality">
                        {employeeExperienceTranslate.nationalityEEProfile}
                    </label>
                    <input
                        type="text"
                        id="nationality"
                        name="nationality"
                        onChange={onChange}
                        value={data.nationality}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <label htmlFor="transport">
                        {employeeExperienceTranslate.transportEEProfile}
                    </label>
                    <input
                        type="text"
                        id="transport"
                        name="transport"
                        onChange={onChange}
                        value={data.transport}
                    />
                </div>
            </div>
        </div>
    );
};
