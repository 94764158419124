import { useState } from "react";
import { Modal, Row, Col, Form, Button } from "react-bootstrap";

import "./modal.sass";

const Outplacement = ({ show, handleClose, option }) => {
  const [finish, setFinish] = useState(false);
  const [form, setForm] = useState({
    name: "",
    email: "",
    area: "",
    role: "",
  });

  const handleChange = (event) => {
    setForm({ ...Form, [event.target.name]: event.target.value });
  };

  const handleSave = () => {
    setFinish(true);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-services"
    >
      {!finish && (
        <>
          <Modal.Header>
            <Modal.Title>Outplacement</Modal.Title>
            <div className="btn-close" onClick={handleClose}></div>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col className="text-center pb-5">{option.text}</Col>
              </Row>
              <Row>
                <Col>
                  <h6 className="text-center">
                    Informe los datos del colaborador desvinculado:
                  </h6>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="my-2">
                    <Form.Label className="ms-3">Nome</Form.Label>
                    <Form.Control
                      className="rounded-pill"
                      value={form.name}
                      name="name"
                      onChange={handleChange}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="my-2">
                    <Form.Label className="ms-3">Email</Form.Label>
                    <Form.Control
                      className="rounded-pill"
                      value={form.email}
                      name="email"
                      onChange={handleChange}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="my-2">
                    <Form.Label className="ms-3">Area</Form.Label>
                    <Form.Control
                      className="rounded-pill"
                      value={form.area}
                      name="area"
                      onChange={handleChange}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="my-2">
                    <Form.Label className="ms-3">Cargo</Form.Label>
                    <Form.Control
                      className="rounded-pill"
                      name="role"
                      value={form.role}
                      onChange={handleChange}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Modal.Body>

          <Modal.Footer className="d-flex justify-content-center">
            <Button
              variant="white"
              className="rounded-pill"
              onClick={handleSave}
            >
              Agendar una Reunión
            </Button>
          </Modal.Footer>
        </>
      )}

      {finish && (
        <>
          <Modal.Body>
            <Row>
              <Col className="d-flex flex-column align-items-center mb-4">
                <h3 className="text-center color-blue">
                  Solicitud enviada con éxito ! Tu consultor te contactará en la
                  brevedad
                </h3>
                <p>Nos vemos pronto!</p>
              </Col>
            </Row>
            <Row>
              <Col md={2} className="offset-3 d-flex justify-content-end">
                <img
                  className="img-fluid rounded-circle border border-2 border-primary"
                  src={
                    process.env.REACT_APP_API_STORAGE + "Benjamin Foster.jfif"
                  }
                  width="100"
                  alt="Benjamin Foster"
                />
              </Col>
              <Col md={6} className="d-flex flex-column justify-content-center">
                <h5>Benjamin Foster</h5>
                <h6>Product Owner of Genes</h6>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button
              variant="white"
              className="rounded-pill"
              onClick={handleClose}
            >
              Contratar otro servicio
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};
export default Outplacement;
