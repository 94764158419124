import { useState, useEffect } from "react";
import { RiDashboard2Line } from "react-icons/ri";
import { getClimateScore } from "../../../../API/Employee/Climate";
import { promoterScoreTranslate } from "../../../../i18n/translatedMessages";

import "./PromoterScore.sass";
import Loading from "../../../../Components/Loading/Loading";

const PromoterScore = () => {
    const [scores, setScores] = useState([]);
    const [load, setLoad] = useState(false);
    const values = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    useEffect(() => {
        const users = JSON.parse(localStorage.getItem("user"));
        const getRequests = async () => {
        setLoad(true)
        const getAux = await getClimateScore(users.id);
            const filtGetAux = Object.values(getAux).map((x) => x);
            setScores(filtGetAux);
            setLoad(false)
        };
        getRequests();
    }, []);

    return (
        <div className="row">
            {load && (
				<div className="loading">
					<Loading />
				</div>
			)}
            <div className="col offset-1">
                <div className="promoteScore-main px-1">
                    <div className="row">
                        <div className="col d-flex mb-1">
                            <div className="icon">
                                <RiDashboard2Line size={50} />
                            </div>
                            <h1>
                                {promoterScoreTranslate.netPromoteScoreTitle}
                            </h1>
                        </div>
                    </div>
                    <div className="row px-5 justify-content-center text-center mt-5">
                        <div className="col-7">
                            <h5 className="color-red">
                                {promoterScoreTranslate.detractorTitle}{" "}
                                {scores[0] === "NaN%" || scores[0] === undefined
                                    ? "0,00%"
                                    : parseFloat(scores[0]).toFixed(2) + "%"}
                            </h5>
                        </div>
                        <div className="col">
                            <h5 className="color-purple">
                                {promoterScoreTranslate.neutralTitle}{" "}
                                {scores[1] === "NaN%" || scores[1] === undefined
                                    ? "0,00%"
                                    : parseFloat(scores[1]).toFixed(2) + "%"}
                            </h5>
                        </div>
                        <div className="col">
                            <h5 className="color-blue">
                                {promoterScoreTranslate.promotorTitle}{" "}
                                {scores[2] === "NaN%" || scores[2] === undefined
                                    ? "0,00%"
                                    : parseFloat(scores[2]).toFixed(2) + "%"}
                            </h5>
                        </div>
                    </div>
                    <div className="row px-5 mt-3 justify-content-center colored-porcentage-row px-2">
                        {values.map((inputsValues, index) => {
                            return (
                                <div
                                    key={index}
                                    className={
                                        inputsValues <= 6
                                            ? "col bg-red"
                                            : inputsValues === 7 ||
                                              inputsValues === 8
                                            ? "col bg-purple"
                                            : inputsValues === 9 ||
                                              inputsValues === 10
                                            ? "col bg-blue"
                                            : "col"
                                    }
                                >
                                    <input
                                        className={
                                            inputsValues <= 6
                                                ? "form-control bordered-red text-center"
                                                : inputsValues === 7 ||
                                                  inputsValues === 8
                                                ? "form-control bordered-purple text-center"
                                                : inputsValues === 9 ||
                                                  inputsValues === 10
                                                ? "form-control bordered-blue text-center"
                                                : "form-control text-center"
                                        }
                                        type="text"
                                        value={inputsValues}
                                        disabled
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div className="container px-5">
                        <div className="row mt-3 bordered-red py-3 rounded justify-content-center">
                            <div className="col-2 color-red">
                                <h3 className="m-0">
                                    {promoterScoreTranslate.detractorTitle}
                                </h3>
                            </div>
                            <div className="col-9">
                                <p className="m-0">
                                    {promoterScoreTranslate.detractorText}
                                </p>
                            </div>
                        </div>
                        <div className="row mt-3 bordered-purple py-3 rounded justify-content-center">
                            <div className="col-2 color-purple">
                                <h3 className="m-0">
                                    {promoterScoreTranslate.neutralTitle}
                                </h3>
                            </div>
                            <div className="col-9">
                                <p className="m-0">
                                    {promoterScoreTranslate.neutralText}
                                </p>
                            </div>
                        </div>
                        <div className="row mt-3 bordered-blue py-3 rounded justify-content-center">
                            <div className="col-2 color-blue">
                                <h3 className="m-0">
                                    {promoterScoreTranslate.promotorTitle}
                                </h3>
                            </div>
                            <div className="col-9">
                                <p className="m-0">
                                    {promoterScoreTranslate.promotorText}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default PromoterScore;
