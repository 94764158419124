export const translate = {
  es: {
    Indenfinido: "Indenfinido",
    "Por proyecto": "Por proyecto",
    Freelancer: "Freelancer",
  },

  pt: {
    Indenfinido: "CLT",
    "Por proyecto": "PJ",
    Freelancer: "Freelancer",
  },
};
