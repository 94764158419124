class Assessment {
	async getKeywords(employeeId) {
		const response = await fetch(`${process.env.REACT_APP_API}/ee/assessment/keywords/${employeeId}`, {
			method: 'GET',
			headers: {
				'Content-type': 'application/json',
			},
		})

		return response.json()
	}

	async getStrengthWeakness(employeeId) {
		const response = await fetch(`${process.env.REACT_APP_API}/ee/assessment/strength-weakness/${employeeId}`, {
			method: 'GET',
			headers: {
				'Content-type': 'application/json',
			},
		})

		return response.json()
	}

	async getDimensions(employeeId) {
		const response = await fetch(`${process.env.REACT_APP_API}/ee/assessment/dimensions/${employeeId}`, {
			method: 'GET',
			headers: {
				'Content-type': 'application/json',
			},
		})

		return response.json()
	}

	async getQuestions(page = 1) {
		const limit = 5
		const response = await fetch(`${process.env.REACT_APP_API}/ee/assessment/questions?qtd=${limit}&page=${page}`, {
			method: 'GET',
			headers: {
				'Content-type': 'application/json',
			},
		})

		return response.json()
	}

	async sendAnswers(body) {
		const response = await fetch(`${process.env.REACT_APP_API}/ee/assessment/answers/`, {
			method: 'PATCH',
			headers: {
				'Content-type': 'application/json',
			},
			body: JSON.stringify(body),
		})

		return response.json()
	}

	async getAnswersPage(EmployeeID) {
		const response = await fetch(`${process.env.REACT_APP_API}/ee/assessment/resource/${EmployeeID}`, {
			method: 'GET',
			headers: {
				'Content-type': 'application/json',
			},
		})

		return response.json()
	}

	async getEmployeeList(companyID, page, level, area, country, complete, text) {
		const response = await fetch(`${process.env.REACT_APP_API}/ee/assessment/employee-answers/${companyID}?page=${page}&level=${level}&area=${area}&country=${country}&status=${complete}&query=${text}`, {
			method: 'GET',
			headers: {
				'Content-type': 'application/json',
			},
		})

		return response.json()
	}
}

export default new Assessment()
