import { useState, useEffect } from 'react'
import { IoIosPeople } from 'react-icons/io'
import { carreraQuestions, culturalQuestions, interesQuestions, liderazgoQuestions } from './Components/Questions/questions'
import { getCompanyCulture, postCompanyCulture } from '../../API/Services/Culture/Culture'
import { businessCultureTranslate } from '../../i18n/translatedMessages'
import Loading from '../../Components/Loading/Loading'
import Culture from './Culture'
import Form from './Form'
import Fineshed from './Fineshed'

import './businessCulture.sass'

const BusinessCulture = () => {
	const { id } = JSON.parse(localStorage.getItem('user'))

	const [component, setComponent] = useState(0)
	const [answer, setAnswer] = useState([
		[false, false, false, false, false, false],
		[false, false, false, false, false, false],
		[false, false, false, false, false, false],
		[false, false, false, false, false, false],
	])
	const [loading, setLoading] = useState(false)
	const [profile, setProfile] = useState(null)

	useEffect(() => {
		const fetchCultural = async () => {
			setLoading(true)
			const response = await getCompanyCulture(id)

			if (response.status === 200) {
				setProfile(await response.json())
				setComponent(5)
			}
			setLoading(false)
		}
		fetchCultural()
	}, [])

	const handleContinue = () => {
		setComponent(prevState => prevState + 1)
	}

	const handleClick = event => {
		const index = event.currentTarget.dataset.answer
		const arrayIndex = event.currentTarget.dataset.index - 1
		const array = [...answer]

		array[arrayIndex][index] = !answer[arrayIndex][index]
		setAnswer(array)
	}

	const handleSend = async () => {
		setLoading(true)
		const data = {
			'companyId': id,
			'answers': answer,
		}
		await postCompanyCulture(data)
			.then(async () => {
				const response = await getCompanyCulture(id)
				if (response.status === 200) {
					setProfile(await response.json())
					setComponent(5)
				}
				setLoading(false)
			})
			.catch(error => {
				console.error(error)
				setLoading(false)
				setComponent(5)
			})
	}

	const components = [
		<Form />,
		<Culture questions={culturalQuestions} index={1} answer={answer[0]} setAnswer={setAnswer} handleClick={handleClick} />,
		<Culture questions={carreraQuestions} index={2} answer={answer[1]} setAnswer={setAnswer} handleClick={handleClick} />,
		<Culture questions={liderazgoQuestions} index={3} answer={answer[2]} setAnswer={setAnswer} handleClick={handleClick} />,
		<Culture questions={interesQuestions} index={4} answer={answer[3]} setAnswer={setAnswer} handleClick={handleClick} />,
		<Fineshed index={5} profile={profile} />,
	]

	return (
		<div className='row loading-content'>
			{loading && (
				<div className='loading'>
					<Loading />
				</div>
			)}
			<div className='col offset-1'>
				<div className='content-business-culture'>
					<div className='row mb-5'>
						<div className='col d-flex'>
							<div className='icon'>
								<IoIosPeople size={70} />
							</div>
							<h1>{businessCultureTranslate.businessCultureTitle}</h1>
						</div>
					</div>
					{components[component]}
					<div className='row mt-5'>
						<div className='col-8 offset-2 d-flex justify-content-center'>
							{component !== components.length - 1 && component < 4 && (
								<button className='businessBtn' onClick={handleContinue}>
									Continuar
								</button>
							)}
							{component === 4 && (
								<button className='businessBtn' onClick={handleSend}>
									Finalizar
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default BusinessCulture
