import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CulturaIcon, FormacionIcon, SalarioIcon } from '../../Icons/CollaboratorsIcons'
import { getCulture } from '../../API/Employee/Culture'
import { cultureTranslate, wheelCollaboratorTranslate } from '../../i18n/translatedMessages'

import CarouselCulture from './Components/Carousel/Carousel'
import CulturaBar from './Components/CulturaBar/CulturaBar'
import EmptyInformation from './Components/EmptyInformation/EmptyInformation'

import './cultura.sass'
import '../Analytics/Components/Carousel/Carousel.sass'
import HeaderCarouselMandala from './Components/HeaderCarouselMandala/HeaderCarouselMandala'
import { CulturalIcon } from '../../Icons/Icons'
import Loading from '../../Components/Loading/Loading'

const Cultura = () => {
	const { id } = useParams()
	const [bars, setBars] = useState([])
	const [ids, setID] = useState(0)
	const [loading, setLoading] = useState(false)

	const nextCard = () => {
		if (ids < 3) setID(ids + 1)
	}

	const prevCard = () => {
		if (ids > 0) {
			setID(ids - 1)
		}
	}

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true)
			const response = await getCulture(id)

			if (response) {
				setBars([
					{
						id: 0,
						icon: 'https://assets.mydna.company/Comercial.svg',
						percentagem: parseFloat(response['comercial']).toFixed(2),
						type: cultureTranslate.commercial,
						color: '#7a6be6',
						textArea: cultureTranslate.commercialText,
					},
					{
						id: 1,
						icon: 'https://assets.mydna.company/Disruptivo.svg',
						percentagem: parseFloat(response['disruptiva']).toFixed(2),
						type: cultureTranslate.disruptive,
						color: '#41c6ff',
						textArea: cultureTranslate.disruptiveText,
					},
					{
						id: 2,
						icon: 'https://assets.mydna.company/Colaborativo.svg',
						percentagem: parseFloat(response['colaborativa']).toFixed(2),
						type: cultureTranslate.collaborative,
						color: '#2be7c8',
						textArea: cultureTranslate.collaborativeText,
					},
					{
						id: 3,
						icon: 'https://assets.mydna.company/Tradicional.svg',
						percentagem: parseFloat(response['tradicional']).toFixed(2),
						type: cultureTranslate.traditional,
						color: '#38364b',
						textArea: cultureTranslate.traditionalText,
					},
				])
			}

			setLoading(false)
		}

		fetchData()
	}, [])

	if (loading) {
		return <Loading />
	}

	return (
		<div className='content-cultura'>
			<div>
				<HeaderCarouselMandala
					actualTitle={wheelCollaboratorTranslate.culture}
					prevTitle={wheelCollaboratorTranslate.formation}
					routePrev={`/app/analytic/formacion/${id}`}
					nextTitle={wheelCollaboratorTranslate.salary}
					routeNext={`/app/analytic/salario/${id}`}
					iconActual={<CulturalIcon />}
					iconPrev={<FormacionIcon />}
					iconNext={<SalarioIcon />}
				/>
			</div>
			{bars.length > 0 && (
				<>
					<h5 className='text-center py-4'>{cultureTranslate.culturePreview}</h5>
					<div className='flex justify-content-center my-4'>
						<ul className='d-flex'>
							{bars.map(item => (
								<li className='card-bars' key={item.id}>
									<CulturaBar percentagem={item.percentagem} color={item.color}>
										<img src={item.icon} alt='' />
									</CulturaBar>
									{item.type}
								</li>
							))}
						</ul>
					</div>
					<div className='flex justify-content-center'>
						<CarouselCulture prevCard={prevCard} proximoCard={nextCard} iconCard={bars[ids].icon} colorCard={bars[ids].color} titleCard={bars[ids].type} textCard={bars[ids].textArea} />
					</div>
				</>
			)}
			{bars.length === 0 && <EmptyInformation />}
		</div>
	)
}
export default Cultura
