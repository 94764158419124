import { useEffect, useState } from "react";
import { askes } from "./askes";
import { getCulture, postCulture } from "../../../../API/Employee/Culture";
import AskBox from "./AskBox";
import { FaSave } from "react-icons/fa";
import { Link } from "react-router-dom";
import { MdArrowBack, MdArrowForward } from "react-icons/md";
import { CulturalIcon, CarreraIcon, InteresIcon, LiderazgoIcon } from "../../../../Icons/Icons";
import { btnTranslate, modalAssignTranslate } from "../../../../i18n/translatedMessages";
import { useIntl } from "react-intl";

const Quiz = () => {
  const lang = useIntl();
  const [user, setUser] = useState();

  const [type, setType] = useState("cultural");

  const [list, setList] = useState(null);

  const [btnCondition, setBtnCondition] = useState(false);

  const answerEmpty = {
    cultural: {
      me: [null, null, null, null, null, null],
      industry: [null, null, null, null, null, null],
    },
    carrera: {
      me: [null, null, null, null, null],
      industry: [null, null, null, null, null],
    },
    liderazgo: {
      me: [null, null, null, null, null],
      industry: [null, null, null, null, null],
    },
    interese: {
      me: [null, null, null, null, null],
      industry: [null, null, null, null, null],
    },
  };
  const [answer, setAnswer] = useState(answerEmpty);

  useEffect(() => {
    const filtCarreraMe = answer.carrera.me;
    const filtCulturalMe = answer.cultural.me;
    const filtLiderazgoMe = answer.liderazgo.me;
    const filtIntereseMe = answer.interese.me;
    const answers = [...filtCarreraMe, ...filtCulturalMe, ...filtLiderazgoMe, ...filtIntereseMe];
    const filtAnswers = answers.filter((x) => {
      return x === null;
    });
    if (filtAnswers.length === 0) {
      setBtnCondition(true);
    } else {
      setBtnCondition(false);
    }
  }, [answer, btnCondition]);

  useEffect(() => {
    const array = askes.filter((item) => item.type === type);
    setList(
      array.map((item, index) => ({
        ...item,
        id: index,
      }))
    );
  }, [type]);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
    const employerids = JSON.parse(localStorage.getItem("user"));
    const fetchAnswer = async () => {
      const answerTest = await getCulture(employerids.id);
      answerTest ? setAnswer(answerTest.allUserQuestion) : setAnswer(answerEmpty);
    };
    fetchAnswer();
  }, []);

  const handleUser = (toggle, id) => {
    const array = { ...answer };
    array[type].me.splice(id, 1, toggle);

    setAnswer(array);
  };

  const handleSend = async () => {
    await postCulture(answer, user.id);
    window.location.reload();
  };
  const reloadTest = () => {
    window.location.reload();
  };

  const handleNext = () => {
    if (type === "cultural") {
      setType("carrera");
    } else if (type === "carrera") {
      setType("liderazgo");
    } else if (type === "liderazgo") {
      setType("interese");
    } else if (type === "interese") {
      setType("cultural");
    }
  };
  const handleBack = () => {
    if (type === "cultural") {
      setType("interese");
    } else if (type === "interese") {
      setType("liderazgo");
    } else if (type === "liderazgo") {
      setType("carrera");
    } else if (type === "carrera") {
      setType("cultural");
    }
  };

  return (
    <div className="quiz-b2e">
      <div className="row mt-5 buttons">
        <div className="col">
          <button
            className={`pills-b2e ${type === "cultural" ? "active" : ""}`}
            onClick={() => setType("cultural")}
          >
            <CulturalIcon size={20} color={type === "cultural" ? "#FAFAFA" : "#746be8"} />
            {lang === "PT-BR" ? "Perguntas de Cultura" : "Preguntas de Cultura"}
          </button>
        </div>
        <div className="col">
          <button
            className={`pills-b2e ${type === "carrera" ? "active" : ""}`}
            onClick={() => setType("carrera")}
          >
            <CarreraIcon size={20} color={type === "carrera" ? "#FAFAFA" : "#746be8"} />
            {lang === "PT-BR" ? "Perguntas de Carreira" : "Preguntas de Carrera"}
          </button>
        </div>
        <div className="col">
          <button
            className={`pills-b2e ${type === "liderazgo" ? "active" : ""}`}
            onClick={() => setType("liderazgo")}
          >
            <LiderazgoIcon size={20} color={type === "liderazgo" ? "#FAFAFA" : "#746be8"} />
            {lang === "PT-BR" ? "Perguntas de Liderança" : "Preguntas de Liderazgo"}
          </button>
        </div>
        <div className="col">
          <button
            className={`pills-b2e ${type === "interese" ? "active" : ""}`}
            onClick={() => setType("interese")}
          >
            <InteresIcon size={20} color={type === "interese" ? "#FAFAFA" : "#746be8"} />
            {lang === "PT-BR" ? "Perguntas de Interesse" : "Preguntas de Intereses"}
          </button>
        </div>
      </div>
      <div className="row d-flex mt-5">
        {list &&
          list?.map((item, index) => (
            <div key={index} className="col-6 my-3">
              <AskBox
                ask={item}
                me={answer[type]?.me[item?.id]}
                industry={answer[type]?.industry[item?.id]}
                handleUser={handleUser}
              />
            </div>
          ))}
      </div>
      <div className="row align-items-center">
        <div className="col">
          <button className="button-volver color-blue mt-3" onClick={handleBack}>
            <MdArrowBack size={20} />
            {lang === "PT-BR" ? "Voltar" : "Volver"}
          </button>
        </div>
        <div className="col text-center">
          {btnCondition ? (
            <button className="btn btn-save mt-3" onClick={handleSend}>
              <FaSave size="18" />
              <span className="fs-5 ms-3">{modalAssignTranslate.btnSave}</span>
            </button>
          ) : (
            ""
          )}
        </div>
        <div className="col text-end">
          <button className="button-volver px-0 mt-3" onClick={handleNext}>
            <span className="">{btnTranslate.nextButton}</span>
            <MdArrowForward />
          </button>
        </div>
      </div>
    </div>
  );
};
export default Quiz;
