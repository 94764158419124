// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-denuncia {
  margin-bottom: 50px;
}
.content-denuncia .loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}
.content-denuncia .title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.content-denuncia .title .icon {
  background: linear-gradient(45deg, #40c2ff 0%, #32d8d8 100%);
  width: 50px;
  height: 50px;
  border-radius: 50rem;
  padding: 8px;
  margin: 0 10px;
}
.content-denuncia .title .icon svg {
  fill: white;
  width: 100%;
}
.content-denuncia .parrafo_principal {
  margin-bottom: 60px;
  margin-top: 5%;
  margin-left: 5%;
}
.content-denuncia .texto_parrafo {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
  font-size: 25px;
}
.content-denuncia .btn-denuncia {
  border: 2px solid #48c4fc !important;
  font-size: 25px;
  color: white !important;
  background-color: #48c4fc;
  width: 100px !important;
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 30px !important;
  border-radius: 30px;
  margin-left: 30%;
}`, "",{"version":3,"sources":["webpack://./src/Views/Employee/canaldenuncia.sass"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;AACI;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,WAAA;EACA,aAAA;AACR;AACI;EACI,aAAA;EACA,mBAAA;EACA,2BAAA;AACR;AACQ;EACI,4DAAA;EACA,WAAA;EACA,YAAA;EACA,oBAAA;EACA,YAAA;EACA,cAAA;AACZ;AACY;EACI,WAAA;EACA,WAAA;AAChB;AACI;EACI,mBAAA;EACA,cAAA;EACA,eAAA;AACR;AACI;EACI,2BAAA;EACA,8BAAA;EACA,eAAA;AACR;AACI;EACI,oCAAA;EACA,eAAA;EACA,uBAAA;EACA,yBAAA;EACA,uBAAA;EACA,kBAAA;EACA,mBAAA;EACA,iBAAA;EACA,oBAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AACR","sourcesContent":[".content-denuncia\n    margin-bottom: 50px\n\n    & .loading\n        display: flex\n        justify-content: center\n        align-items: center\n        flex-direction: column\n        width: 100%\n        height: 100vh\n\n    & .title\n        display: flex\n        align-items: center\n        justify-content: flex-start\n\n        & .icon\n            background: linear-gradient(45deg,#40c2ff 0%, #32d8d8 100%)\n            width: 50px\n            height: 50px\n            border-radius: 50rem\n            padding: 8px\n            margin: 0 10px\n\n            & svg\n                fill: white\n                width: 100%\n\n    & .parrafo_principal\n        margin-bottom: 60px\n        margin-top: 5%\n        margin-left: 5%\n\n    & .texto_parrafo\n        margin-top: 60px !important\n        margin-bottom: 60px !important\n        font-size: 25px\n\n    & .btn-denuncia\n        border: 2px solid #48c4fc !important\n        font-size: 25px\n        color: #ffff !important\n        background-color: #48c4fc\n        width: 100px !important\n        padding-left: 80px\n        padding-right: 80px\n        padding-top: 10px\n        padding-bottom: 10px\n        height: 30px !important\n        border-radius: 30px\n        margin-left: 30%\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
