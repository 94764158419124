import { FormattedMessage } from "react-intl";
import AssignScreen from "./Components/AssignScreen";

const AbilitiesTest = () => {
    return (
        <div className="row">
            <div className="col offset-1">
                <FormattedMessage id={"skillTest"}>  
                    {msg=>(
                        <AssignScreen title={msg} />
                    )}
                </FormattedMessage>
            </div>
        </div>
    );
};

export default AbilitiesTest;
