import { useEffect } from "react";
import { useState } from "react";
import { Modal, Row, Button, InputGroup, FormControl, Accordion } from "react-bootstrap";
import { AiOutlineEdit } from "react-icons/ai";
import { detDataSmtmForEditEmployee } from "../../../../../../API/smtm";

import Loading from "../../../../../../Components/Loading/Loading";

import "./ModalEditEmployee.sass";
import { btnTranslate, modalAssignTranslate } from "../../../../../../i18n/translatedMessages";
import { useIntl } from "react-intl";

const ModalEditEmployee = ({ show, hide, data, loading, areaList, levelList, handleChange, handleSendChange }) => {
  const [ShowEditSubArea, setShowEditSubArea] = useState(false);
  const [ShowEditSalary, setShowEditSalary] = useState(false);
  const [ShowEditBonus, setShowEditBonus] = useState(false);

  useEffect(() => {
    setShowEditSubArea(false);
    setShowEditSalary(false);
    setShowEditBonus(false);
  }, [show]);

  const [trigger, setTrigger] = useState(true);

  const [dataPositionSmtm, setDataPositionSmtm] = useState({
    subArea: "",
    english_level_id: 0,
    seniority_id: 0,
    qualification_id: 0,
    current_salary: 0,
    variable_salary: 0,
  });

  useEffect(() => {
    if (data.email) {
      async function fetchData() {
        const json = await detDataSmtmForEditEmployee(data);
        setDataPositionSmtm(json[0]);
        setTrigger(false);
      }
      fetchData();
    }
  }, [data]);

  const language = useIntl();
  return (
    <Modal
      show={show}
      onHide={hide}
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="ModalEditEmployee-main"
    >
      <Modal.Body>
        {loading ? (
          <div className="loading">
            <Loading />
          </div>
        ) : (
          <Row className="justify-content-center">
            <div className="my-2">
              <span className="text-center">
                <h5>{language.locale == "ES" ? "Editar datos del colaborador" : "Editar dados do colaborador"}</h5>
              </span>
            </div>
            <div className="col-11">
              <Row>
                <div className="col-md-6">
                  <label className="form-label">{language.locale == "ES" ? "Nombre" : "Nome"}</label>
                  <input type="text" className="form-control" id="nameEditEmployee" name="name" value={data?.name} onChange={handleChange} />
                </div>
                <div className="col-md-6">
                  <label className="form-label">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    id="emailEditEmployee"
                    name="email"
                    value={data?.email}
                    onChange={handleChange}
                    disabled="true"
                  />
                </div>
                <div className="col-6">
                  <label className="form-label">País</label>
                  <InputGroup className="mb-3">
                    <select name="countryId" className="w-100 form-select" onChange={handleChange}>
                      <option>{language.locale == "ES" ? "País de la posición del colaborador" : "País da posição do colaborador"}</option>
                      <option selected={data.country === "Chile"} value="1" data-countryname="Chile">
                        Chile
                      </option>
                      <option selected={data.country === "Argentina"} value="2" data-countryname="Argentina">
                        Argentina
                      </option>
                      <option selected={data.country === "Perú"} value="3" data-countryname="Perú">
                        {language.locale == "ES" ? "Perú" : "Peru"}
                      </option>
                      <option selected={data.country === "Brasil"} value="4" data-countryname="Brasil">
                        Brasil
                      </option>
                      <option selected={data.country === "Colombia"} value="5" data-countryname="Colombia">
                        {language.locale == "ES" ? "Colombia" : "Colômbia"}
                      </option>
                      <option selected={data.country === "Mexico"} value="6" data-countryname="Mexico">
                        {language.locale == "ES" ? "Mexico" : "México"}
                      </option>
                      <option selected={data.country === "Costa Rica"} value="7" data-countryname="Costa Rica">
                        {language.locale == "ES" ? "Costa Rica" : "Costa Rica"}
                      </option>
                    </select>
                  </InputGroup>
                </div>
                <div className="col-md-6">
                  <label className="form-label">Área</label>
                  <select name="areaId" className="w-100 selectAddColaborador form-select" onChange={handleChange}>
                    <option>Areas</option>
                    {areaList?.map((area) => {
                      return (
                        <option key={area.id} value={area.id} selected={area?.id === data.areaId}>
                          {area.profileArea}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-6">
                  <label className="form-label">Nivel</label>
                  <select name="levelId" className="w-100 selectAddColaborador form-select" onChange={handleChange}>
                    <option>Level</option>
                    {levelList?.map((level) => {
                      return (
                        <option key={level?.id} value={level?.id} selected={level?.id === data.levelId}>
                          {level?.PositionN}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-6">
                  <label className="form-label">{language.locale == "ES" ? "Nombre de la posicíon" : "Cargo"}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="jobEditEmployee"
                    name="jobName"
                    onChange={handleChange}
                    value={data.jobName}
                    style={{ margin: "0px" }}
                  />
                </div>
              </Row>
              <div className="my-3">
                <span className="text-center">
                  <h5>{language.locale == "ES" ? "Informaciones Adicionales" : "Informações Adicionais"}</h5>
                </span>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-6">
                  <label className="form-label">{language.locale == "ES" ? "Nivel de Inglés" : "Nível de Inglês"}</label>
                  <InputGroup className="mb-3">
                    {language.locale == "ES" ? "" : ""}
                    <select name="englishLevel" className="w-100 form-select" onChange={handleChange}>
                      <option>{language.locale == "ES" ? "Nivel de Inglés" : "Nível de Inglês"}</option>
                      <option selected={dataPositionSmtm?.english_level_id.toString() === "0"} value={"0"}>
                        Indiferente
                      </option>
                      <option selected={dataPositionSmtm?.english_level_id.toString() === "1"} value={"1"}>
                        Básico
                      </option>
                      <option selected={dataPositionSmtm?.english_level_id.toString() === "2"} value={"2"}>
                        {language.locale == "ES" ? "Medio" : "Médio"}
                      </option>
                      <option selected={dataPositionSmtm?.english_level_id.toString() === "3"} value={"3"}>
                        {language.locale == "ES" ? "Avanzado" : "Avançado"}
                      </option>
                    </select>
                  </InputGroup>
                </div>

                <div className="col-6">
                  <label className="form-label">{language.locale == "ES" ? "Señority" : "Senioridade"}</label>
                  <InputGroup className="mb-3">
                    <select name="seniorityLevel" className="w-100 form-select" onChange={handleChange}>
                      <option>{language.locale == "ES" ? "Señority" : "Senioridade"}</option>
                      <option selected={dataPositionSmtm?.seniority_id.toString() === "0"} value={"0"}>
                        Indiferente
                      </option>
                      <option selected={dataPositionSmtm?.seniority_id.toString() === "1"} value={"1"}>
                        {language.locale == "ES" ? "Junior" : "Júnior"}
                      </option>
                      <option selected={dataPositionSmtm?.seniority_id.toString() === "2"} value={"2"}>
                        {language.locale == "ES" ? "Mediano" : "Pleno"}
                      </option>
                      <option selected={dataPositionSmtm?.seniority_id.toString() === "3"} value={"3"}>
                        {language.locale == "ES" ? "Senior" : "Sênior"}
                      </option>
                    </select>
                  </InputGroup>
                </div>

                <div className="col-12">
                  <label className="form-label">{language.locale == "ES" ? "Educación" : "Educação"}</label>
                  <InputGroup className="mb-3">
                    <select name="gradeLevel" className="w-100 form-select" onChange={handleChange}>
                      <option>{language.locale == "ES" ? "Grado" : "Grau Acadêmico"}</option>
                      <option selected={dataPositionSmtm?.qualification_id.toString() === "0"} value={"0"}>
                        Indiferente
                      </option>
                      <option selected={dataPositionSmtm?.qualification_id.toString() === "1"} value={"1"}>
                        {language.locale == "ES" ? "Graduación" : "Graduação"}
                      </option>
                      <option selected={dataPositionSmtm?.qualification_id.toString() === "2"} value={"2"}>
                        MBA
                      </option>
                      <option selected={dataPositionSmtm?.qualification_id.toString() === "3"} value={"3"}>
                        MBA Internacional
                      </option>
                    </select>
                  </InputGroup>
                </div>

                <div className="col-4 d-flex flex-column align-items-center">
                  <label className="form-label">Sub Área</label>

                  {ShowEditSubArea ? (
                    <>
                      <input
                        className="form-control"
                        style={{ margin: "0px" }}
                        type="text"
                        placeholder="Subarea"
                        name="subArea"
                        aria-label="Subarea"
                        onChange={handleChange}
                      />
                    </>
                  ) : (
                    <>
                      <div className="d-flex">
                        <div>
                          {" "}
                          <span>{dataPositionSmtm?.subArea}</span>{" "}
                        </div>
                        <div
                          className="mb-2"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setShowEditSubArea(true);
                          }}
                        >
                          <AiOutlineEdit size={20} />
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className="col-4 d-flex flex-column align-items-center">
                  <label className="form-label">{language.locale == "ES" ? "Salario del Colaborador" : "Salário do Colaborador"}</label>
                  {ShowEditSalary ? (
                    <>
                      <InputGroup className="mb-3">
                        <input
                          className="form-control"
                          style={{ margin: "0px" }}
                          type="text"
                          placeholder={"Salario"}
                          name="salary"
                          onChange={handleChange}
                        />
                      </InputGroup>
                    </>
                  ) : (
                    <>
                      <div className="d-flex">
                        <div>
                          {" "}
                          <span>{dataPositionSmtm?.current_salary}</span>{" "}
                        </div>
                        <div
                          className="mb-2"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setShowEditSalary(true);
                          }}
                        >
                          <AiOutlineEdit size={20} />
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className="col-4 d-flex flex-column align-items-center">
                  <label className="form-label">{language.locale == "ES" ? "Bonos Variables" : "Bônus Variáveis"}</label>
                  {ShowEditBonus ? (
                    <>
                      <InputGroup className="mb-3">
                        <input
                          type="text"
                          placeholder={"Salario variable (bonificaciones)"}
                          name="variableSalary"
                          onChange={handleChange}
                          className="form-control"
                          style={{ margin: "0px" }}
                        />
                      </InputGroup>
                    </>
                  ) : (
                    <>
                      <div className="d-flex">
                        <div>
                          {" "}
                          <span>{dataPositionSmtm?.variable_salary}</span>{" "}
                        </div>
                        <div
                          className="mb-2"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setShowEditBonus(true);
                          }}
                        >
                          <AiOutlineEdit size={20} />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Row>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-purple rounded-pill" onClick={hide}>
          {modalAssignTranslate.btnClose}
        </button>
        <button className="btn btn-green rounded-pill" onClick={() => handleSendChange(data.employeeId)}>
          {btnTranslate.saveButton}
        </button>
      </Modal.Footer>
    </Modal>
  );
};
export default ModalEditEmployee;
