import {
    employeeExperienceTranslate,
    employeeProfileEdit,
} from "../../../../i18n/translatedMessages";

export const Personal = ({ onChange, data, error }) => {
    return (
        <div className="content-form">
            <div className="row">
                <div className="col">
                    <h3>
                        {employeeExperienceTranslate.personalTitleEEProfile}
                    </h3>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <label htmlFor="mailPersonal">
                        {employeeExperienceTranslate.emailEEProfile}
                    </label>
                    <input
                        type="email"
                        name="mailPersonal"
                        id="mailPersonal"
                        onChange={onChange}
                        value={data.mailPersonal}
                        className={error.mailPersonal ? "error" : ""}
                    />
                    {error.mailPersonal && (
                        <small>{employeeProfileEdit.profileError}</small>
                    )}
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <label htmlFor="phone">
                        {employeeExperienceTranslate.phoneEEProfile}
                    </label>
                    <input
                        type="tel"
                        name="phone"
                        id="phone"
                        onChange={onChange}
                        value={data.phone}
                        className={error.phone ? "error" : ""}
                    />
                    {error.phone && (
                        <small>{employeeProfileEdit.profileError}</small>
                    )}
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <label htmlFor="document">
                        {employeeExperienceTranslate.documentEEProfile}
                    </label>
                    <input
                        type="number"
                        name="document"
                        id="document"
                        onChange={onChange}
                        value={data.document}
                        className={error.document ? "error" : ""}
                    />
                    {error.document && (
                        <small>{employeeProfileEdit.profileError}</small>
                    )}
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <label htmlFor="birth">
                        {employeeExperienceTranslate.birthDateEEProfile}
                    </label>
                    <input
                        type="date"
                        name="birth"
                        id="birth"
                        onChange={onChange}
                        value={data.birth}
                        className={error.birth ? "error" : ""}
                    />
                    {error.birth && (
                        <small>{employeeProfileEdit.profileError}</small>
                    )}
                </div>
            </div>
        </div>
    );
};
