import { BarElement, CategoryScale, Chart as ChartJS, Filler, Legend, LineElement, LinearScale, PointElement, RadialLinearScale, Title, Tooltip } from 'chart.js'
import { Radar } from 'react-chartjs-2'
import { RiDashboardLine } from 'react-icons/ri'

import { options1 } from './chartConfigs'

import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { getClimateGroupMedia } from '../../../../API/Employee/Climate'
import { dashboardClimeTranslate } from '../../../../i18n/translatedMessages'
import './dashboardClima.sass'
import Loading from '../../../../Components/Loading/Loading'

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title)

const DashboardClima = () => {
	const language = useIntl()
	const [climateGroupMedias, setClimateGroupMedias] = useState([])
	const [load, setLoad] = useState(false)
	useEffect(() => {
		const users = JSON.parse(localStorage.getItem('user'))
		const getRequests = async () => {
			setLoad(true)
			//getClimateGroupMedia(users.id, 1) 1 para encuesta de salida 0 para encuesta de clima
			const ClimateGroupMedia = await getClimateGroupMedia(users.id, 0)
			const filtCGM = Object.values(ClimateGroupMedia).map(x => x)
			setClimateGroupMedias(filtCGM)
			setLoad(false)
		}
		getRequests()
	}, [])

	const radarData = {
		labels: ['RECURSOS, AMBIENTE Y CULTURA', 'ATIVIDADES Y FUNCCIONES DESEMPEÑADAS', 'LIDERAZGO', 'SALÁRIO Y BENEFICIOS', 'OPORTUNIDADES DEL CARRERA'],
		datasets: [
			{
				label: 'teste',
				data: climateGroupMedias,
				borderColor: '#6a66d9',
				fill: false,
				borderWidth: 3,
			},
		],
	}

	const radarDataBR = {
		labels: ['RECURSOS, AMBIENTE E CULTURA', 'ATIVIDADES E FUNÇÕES DESEMPENHADAS', 'LIDERANÇA', 'SALÁRIO E BENEFÍCIOS', 'OPORTUNIDADES DE CARREIRA'],
		datasets: [
			{
				label: 'teste',
				data: climateGroupMedias,
				borderColor: '#6a66d9',
				fill: false,
				borderWidth: 3,
			},
		],
	}

	return (
		<div className='row'>
			{load && (
				<div className='loading'>
					<Loading />
				</div>
			)}
			<div className='col offset-1'>
				<div className='dashboard-clima'>
					<div className='row'>
						<div className='col d-flex mb-4'>
							<div className='icon'>
								<RiDashboardLine size={35} />
							</div>
							<h1>{dashboardClimeTranslate.dashboardClimeTitle}</h1>
						</div>
					</div>
					<p className='text-muted'>{dashboardClimeTranslate.dashboardClimeText}.</p>
					<div className='row justify-content-center'>
						<div className='col-6 px-5'>
							{climateGroupMedias.length > 0 ? (
								<>
									<div className='container-chart'>
										<Radar data={language.locale == 'ES' ? radarData : radarDataBR} options={options1} width={500} height={350} />
									</div>
								</>
							) : (
								<>
									<div className='alert alert-warning' role='alert'>
										{dashboardClimeTranslate.emptyInformationClime}
									</div>
								</>
							)}
						</div>
						{/* <div className="col-2">
          <Bar options={options2} data={data2} width={20} />
        </div>
        <div className="col-4 d-flex align-items-center">
          <ul className="legends">
            <li>
              <div className="box" style={{ background: "#00b0e0" }}></div>
              <span>
                ¿Tus relaciones personales con tus compañeros son buenos en
                general?
              </span>
            </li>
            <li>
              <div className="box" style={{ background: "#089ae3" }}></div>
              <span>
                ¿La empresa y el liderazgo ofrecen suficientes iniciativas,
                canales y ambiente para fomentar las buenas relaciones con sus
                colegas?
              </span>
            </li>
            <li>
              <div className="box" style={{ background: "#0878b3" }}></div>
              <span>
                ¿Cuentas con los recursos, herramientas y entornos necesarios,
                ya sean físicos o digitales, para llevar a cabo tus tareas y
                alcanzar los objetivos?
              </span>
            </li>
            <li>
              <div className="box" style={{ background: "#1452a8" }}></div>
              <span>
                ¿Son coherentes la cultura y los valores de la empresa con sus
                valores personales?
              </span>
            </li>
            <li>
              <div className="box" style={{ background: "#1a73e8" }}></div>
              <span>PROMEDIO</span>
            </li>
          </ul>
        </div> */}
					</div>
				</div>
			</div>
		</div>
	)
}
export default DashboardClima
