// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalTemplate .buttonSave {
  background: linear-gradient(45deg, #41C8FF 0%, #27E6C4 100%);
  border: none !important;
  border-radius: 50rem;
  padding: 0 10px;
  color: white;
  height: 35px;
  width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/Views/Service/Views/Documents/ModalTemplate.sass"],"names":[],"mappings":"AACI;EACI,4DAAA;EACA,uBAAA;EACA,oBAAA;EACA,eAAA;EACA,YAAA;EACA,YAAA;EACA,YAAA;AAAR","sourcesContent":[".ModalTemplate\n    .buttonSave\n        background: linear-gradient(45deg,#41C8FF 0%, #27E6C4 100%)\n        border: none !important\n        border-radius: 50rem\n        padding: 0 10px\n        color: white\n        height: 35px\n        width: 200px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
