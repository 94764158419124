import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'

import { getMotivations } from '../../API/Analytics/MYCO'

import { DesempenoIcon, HabilidadesIcon, MotivacionesIcon } from '../../Icons/CollaboratorsIcons'

import ChartsMYCO from '../../Components/ChartsMYCO/ChartsMYCO'

import './motivaciones.sass'
import { ProgressBar } from 'react-bootstrap'
import HeaderCarouselMandala from './Components/HeaderCarouselMandala/HeaderCarouselMandala'
import EmptyInformation from './Components/EmptyInformation/EmptyInformation'
import { wheelCollaboratorTranslate } from '../../i18n/translatedMessages'
import Loading from '../../Components/Loading/Loading'

const Motivaciones = () => {
	const labelsMotivation = [
		'Orientación comercial',
		'Orientación al objetivo',
		'Orientación a la tipología del trabajo realizado',
		'Orientación a la innovación',
		'Aspecto Económico',
		'Autonomía y Versatilidad',
		'Seguridad',
		'Prestigio',
		'Balance Profesional',
		'Satisfacción con el puesto',
		'Compromiso',
		'Carrera',
		'Desarrollo de Competencias',
		'Proyecto Profesional',
		'Orientación a las Rel. Interpersonales',
		'Orientación Conceptual',
		'Orientación a la gestión',
	]
	const { id } = useParams()
	const [motivations, setMotivations] = useState(null)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		const fetchEmployer = async () => {
			setLoading(true)
			const motivationAux = await getMotivations(id)

			// Verificar si motivationAux no es un arreglo vacío
			if (motivationAux.length > 0) {
				const motivationLessId = motivationAux[motivationAux.length - 1]

				// Verificar si motivationLessId y sus propiedades existen antes de eliminarlas
				if (motivationLessId && motivationLessId.employerId && motivationLessId.id) {
					delete motivationLessId.employerId
					delete motivationLessId.id
					setMotivations(motivationLessId)
				}
			}
			setLoading(false)
		}

		fetchEmployer()
	}, [id])

	if (loading) {
		return <Loading />
	}

	return (
		<div className='content-motivaciones container'>
			<div>
				<HeaderCarouselMandala
					actualTitle={wheelCollaboratorTranslate.motivacions}
					prevTitle={wheelCollaboratorTranslate.habilities}
					routePrev={`/app/analytic/habilidades/${id}`}
					nextTitle={wheelCollaboratorTranslate.performance}
					routeNext={`/app/analytic/desempeno/${id}`}
					iconActual={<MotivacionesIcon />}
					iconPrev={<HabilidadesIcon />}
					iconNext={<DesempenoIcon />}
				/>
			</div>
			<div className='row row-cols-1 py-3 mt-3'>
				{motivations ? (
					<>
						<h6>Test psicolaboral de motivaciones profesionales, para conocer lo que realmente motiva a tus colaboradores.</h6>
						<div className='col'>
							<div className='motivationsChartHeight w-100 m-auto'>
								<ChartsMYCO testName={'Motivaciones'} labels={labelsMotivation} importData={motivations} />
							</div>
							<div className='row row-cols-2 row-cols-md-3 g-3 mt-3'>
								{Object.values(motivations).map((motivation, index) => {
									return (
										<div key={index} className={`col`}>
											<h6 className={`m-0`}>{labelsMotivation[index]}</h6>
											<ProgressBar label={motivation + '/10'} className={`rounded-pill ${Number(motivation) >= 5 ? `motivationsAnswerPositive` : `motivationsAnswerNegative`}`} now={motivation} max={10} />
										</div>
									)
								})}
							</div>
						</div>
					</>
				) : (
					<div className='col mt-3'>
						<EmptyInformation />
					</div>
				)}
			</div>
		</div>
	)
}
export default Motivaciones
