// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-scrolling-analytics {
  height: calc(100vh - 62px);
  overflow-y: auto;
  overflow-x: hidden;
}`, "",{"version":3,"sources":["webpack://./src/Views/Analytics/analytics.sass"],"names":[],"mappings":"AAAA;EACI,0BAAA;EACA,gBAAA;EACA,kBAAA;AACJ","sourcesContent":[".menu-scrolling-analytics\n    height: calc(100vh - 62px)\n    overflow-y: auto\n    overflow-x: hidden\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
