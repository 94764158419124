import { useContext } from "react";
import { ServicesContext } from "../../../../../Store/ServicesContext";
import "../stylesSteps.css";

const RySStep1 = () => {
  const { store, actions } = useContext(ServicesContext);

  const handleChange = event => {
    actions.setRecruitmentAndSelection({
      [event.currentTarget.name]: event.currentTarget.value
    });
  }

  return (
    <div className="container-fluid StepsFormClass">
      <div className="row justify-content-center pt-3">
        <div className="col-12 text-center">
          <h5>
            Completa la siguiente información para poder entregarte un servicio
            especializado para tus necesidades.
          </h5>
        </div>
      </div>
      <form id="RySForm1">
        <div className="row justify-content-center pt-5">
          <div className="col">
            <input
              type="text"
              className="form-control"
              placeholder="Nombre del Responsable"
              aria-label="Nombre del Responsable"
              name="nameOfResponsible"
              value={store.recruitmentAndSelection?.nameOfResponsible}
              onChange={handleChange}
            />
          </div>
          <div className="col">
            <input
              type="email"
              className="form-control"
              placeholder="Mail del Responsable"
              aria-label="Mail del Responsable"
              name="mailOfResponsible"
              value={store.recruitmentAndSelection?.mailOfResponsible}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row justify-content-center pt-5">
          <div className="col">
            <select
              id="inputState"
              className="form-select"
              name="area"
              value={store.recruitmentAndSelection?.area}
              onChange={handleChange}
            >
              <option selected>Área de la vacante</option>
              <option>...</option>
            </select>
          </div>
          <div className="col">
            <select
              id="inputState"
              className="form-select"
              name="englishLevel"
              value={store.recruitmentAndSelection?.englishLevel}
              onChange={handleChange}
            >
              <option selected>Nivel de Inglés</option>
              <option>...</option>
            </select>
          </div>
        </div>
        <div className="row justify-content-center pt-5">
          <div className="col">
            <select
              id="inputState"
              className="form-select"
              name="jobName"
              value={store.recruitmentAndSelection?.jobName}
              onChange={handleChange}
            >
              <option selected>Nombre del Cargo</option>
              <option>...</option>
            </select>
          </div>
          <div className="col">
            <input
              type="text"
              className="form-control"
              placeholder="Salario mínimo"
              aria-label="Salario mínimo"
              name="minSalary"
              value={store.recruitmentAndSelection?.minSalary}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row justify-content-center pt-5">
          <div className="col">
            <select
              id="inputState"
              className="form-select"
              name="country"
              value={store.recruitmentAndSelection?.country}
              onChange={handleChange}
            >
              <option selected>País de vacante</option>
              <option>...</option>
            </select>
          </div>
          <div className="col">
            <input
              type="text"
              className="form-control"
              placeholder="Salario máximo"
              aria-label="Salario máximo"
              name="maxSalary"
              value={store.recruitmentAndSelection?.maxSalary}
              onChange={handleChange}
            />
          </div>
        </div>
      </form>
    </div>
  );
};
export default RySStep1;
