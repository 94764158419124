// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-bar-rounded {
  position: relative;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background: #efefef;
  rotate: 270deg;
}
.progress-bar-rounded circle {
  stroke: #7a6be8;
  fill: none;
  stroke-width: 10px;
  stroke-dasharray: 252;
}
.progress-bar-rounded .progress-bar__text {
  position: absolute;
  width: 70px;
  height: 70px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
  font-size: 18px;
  font-weight: bold;
  background: white;
  display: flex;
  color: #7a6be8;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/Views/Services/Components/AssignScreen/Components/progress/progress.sass"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,cAAA;AACD;AAEC;EACC,eAAA;EACA,UAAA;EACA,kBAAA;EACA,qBAAA;AAAF;AAEC;EACC,kBAAA;EACA,WAAA;EACA,YAAA;EACA,QAAA;EACA,SAAA;EACA,8CAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,aAAA;EACA,cAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;AAAF","sourcesContent":[".progress-bar-rounded \r\n\tposition: relative\r\n\twidth: 90px\r\n\theight: 90px\r\n\tborder-radius: 50%\r\n\tbackground: #efefef\r\n\trotate: 270deg\r\n\t\r\n\r\n\t& circle\r\n\t\tstroke: #7a6be8\r\n\t\tfill: none\r\n\t\tstroke-width: 10px\r\n\t\tstroke-dasharray: 252\r\n\t\t\r\n\t& .progress-bar__text \r\n\t\tposition: absolute\r\n\t\twidth: 70px\r\n\t\theight: 70px\r\n\t\ttop: 50%\r\n\t\tleft: 50%\r\n\t\ttransform: translate(-50%, -50%) rotate(90deg)\r\n\t\tfont-size: 18px\r\n\t\tfont-weight: bold\r\n\t\tbackground: white\r\n\t\tdisplay: flex\r\n\t\tcolor: #7a6be8\r\n\t\talign-items: center\r\n\t\tjustify-content: center\r\n\t\tborder-radius: 50%\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
