import { MdArrowBack } from 'react-icons/md'
import { Link } from 'react-router-dom'
import './transferColaboradores.sass'

const socialNetwork = [
    { id: 1, name: "Buk", img: "https://yt3.ggpht.com/ytc/AKedOLRHw3-Rw4No38QSjYhFTnAKvqLHQRekJfLeXJ0y=s900-c-k-c0x00ffffff-no-rj" },
    { id: 2, name: "Rankmi", img: "https://www.rankmi.com/hubfs/Sitio%20Web%202021/%5BAssets%202021%5D%20Rankmi%20Logo/isotipo_rankmi_color_2020_RGB.png" },
    { id: 3, name: "Adp", img: "https://www.integra-international.net/wp-content/uploads/2019/04/adp.png" },
    { id: 4, name: "Talana", img: "https://play-lh.googleusercontent.com/Xojdsy4ZdibeMxx7Uyq74eQFQ2BcFNRRI8olwOg-m6_6Tg9Jbh6QXuFtGdMUMNbQcIA" },
    { id: 5, name: "Sucess Factor", img: "https://yt3.ggpht.com/ytc/AKedOLR-r3iVbdB0qOJFOynDmKJpvexb--haxZ3wPDpJqQ=s900-c-k-c0x00ffffff-no-rj" },
]

const TransferColaboradores = () => {
    return (
        <div className="transfer-colaboradores">
            <div className="btn-back">
                <Link to="/app/gestion">
                    <div className="item-circle"><MdArrowBack size={30} /></div>
                </Link>
            </div>
            <div className="content-body">
                <p>Si ya tienes a tus colaboradores cargada en alguna de las siguientes bases de datos, puedes transferirla haciendo click en ella.</p>
                <ul>
                    {socialNetwork.map(item =>
                        <li key={item.id}>
                            <img src={item.img} alt="" />
                            <span>{item.name}</span>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    )
}
export default TransferColaboradores