class JobDescription {
	async get(companyMail, subarea, level, id) {

		const response = await fetch(`${process.env.REACT_APP_API}/company/jobdescription/get/${id}?companyMail=${companyMail}&subareaId=${subarea}&levelId=${level}`, {
			method: 'GET',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
			},
		});

		if (response.status !== 200) {
			return { data: [], status: 204 }
		}
		const data = await response.json();
		const status = response.status
		return { data, status }
	}

	/* async post(body, id) {
		const response = await fetch(`${process.env.REACT_APP_API}/company/jobdescription/get/${id}`, {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body)
		});
		const data = response.json();
		const status = response.status
		return { data, status }
	} */
}

export default new JobDescription()