// import MenuMyDnaGenes from "./Componentes/Nav/Nav";
import * as ReactBootstrap from "react-bootstrap";
import { AiOutlineStar, AiOutlineTags } from "react-icons/ai";
import { BsGear } from "react-icons/bs";
import { FaRegEdit } from "react-icons/fa";
import { GiPathDistance } from "react-icons/gi";
import { IoIosPeople } from "react-icons/io";
import { SiOpslevel } from "react-icons/si";
import { Link } from "react-router-dom";
import CarouselMyDnaGenes from "./Componentes/Nav/Carousel";
import "./styles.css";
const MyDnaGenes = () => {
	return (
		<div className="looknfeel-main">
			{/* <MenuMyDnaGenes /> */}
			<ReactBootstrap.Container fluid>
				<ReactBootstrap.Row className="bg-header">
					<ReactBootstrap.Container>
						<ReactBootstrap.Row className="gx-5 justify-content-center align-items-center py-4">
							<ReactBootstrap.Col lg={6} className="color-white">
								<h1>
									<strong>La solución</strong> digital que
									apoya a tus ex colaboradores.
								</h1>
								<p className="text-header pt-3">
									GENES es un programa laboral digital - con
									un importante toque humano - que apoya al
									profesional al momento de hacer una
									transición exitosa o desarrollar su carrera.
									Todo de manera rápida y asertiva, reduciendo
									en hasta un 50% el tiempo de desarrollo o
									transición profesional.
								</p>
							</ReactBootstrap.Col>
							<ReactBootstrap.Col lg={4} className="px-5">
								<ReactBootstrap.Form className="text-center">
									<h3 className="color-white">
										<strong>
											Agenda una reunión con nuestro
											equipo
										</strong>
									</h3>
									<ReactBootstrap.Form.Control
										type="text"
										placeholder="Nombre y apellido"
									/>
									<ReactBootstrap.Form.Control
										type="text"
										placeholder="Empresa"
									/>
									<ReactBootstrap.Row>
										<ReactBootstrap.Col>
											<ReactBootstrap.Form.Select defaultValue="0">
												<option>Pais</option>
												<option>...</option>
											</ReactBootstrap.Form.Select>
										</ReactBootstrap.Col>
										<ReactBootstrap.Col>
											<ReactBootstrap.Form.Select defaultValue="0">
												<option>Cargo</option>
												<option>...</option>
											</ReactBootstrap.Form.Select>
										</ReactBootstrap.Col>
									</ReactBootstrap.Row>
									<ReactBootstrap.Form.Control
										type="text"
										placeholder="Telefono"
									/>
									<ReactBootstrap.Form.Control
										type="text"
										placeholder="Correo"
									/>
									<ReactBootstrap.Button className="btn-fill rounded-pill w-100">
										Solicitar Reunión
									</ReactBootstrap.Button>
								</ReactBootstrap.Form>
							</ReactBootstrap.Col>
						</ReactBootstrap.Row>
					</ReactBootstrap.Container>
				</ReactBootstrap.Row>

				<ReactBootstrap.Row className="py-5">
					<ReactBootstrap.Col md={3} className="p-0">
						<div className="yellow-line"></div>
					</ReactBootstrap.Col>
				</ReactBootstrap.Row>

				<ReactBootstrap.Container>
					<ReactBootstrap.Row>
						<ReactBootstrap.Col lg={12}>
							<h1>Diferenciales</h1>
						</ReactBootstrap.Col>
					</ReactBootstrap.Row>
					<ReactBootstrap.Row className="justify-content-center">
						<ReactBootstrap.Col lg={9}>
							<ReactBootstrap.Row>
								<ReactBootstrap.Col
									md={4}
									className="position-relative"
								>
									<div className="diferenciales-boxes">
										<AiOutlineTags size={70} />
										<p>Construye tu marca empleadora</p>
										<div className="diferenciales-boxes-hover">
											<Link to={"/"}>Conoce más</Link>
										</div>
									</div>
								</ReactBootstrap.Col>
								<ReactBootstrap.Col
									md={4}
									className="position-relative"
								>
									<div className="diferenciales-boxes">
										<IoIosPeople size={70} />
										<p>Crea embajadores para tu marca</p>
										<div className="diferenciales-boxes-hover">
											<Link to={"/"}>Conoce más</Link>
										</div>
									</div>
								</ReactBootstrap.Col>
								<ReactBootstrap.Col
									md={4}
									className="position-relative"
								>
									<div className="diferenciales-boxes">
										<BsGear size={70} />
										<p>
											Plan personalizado de acuerdo a las
											necesidades de la compañía
										</p>
										<div className="diferenciales-boxes-hover">
											<Link to={"/"}>Conoce más</Link>
										</div>
									</div>
								</ReactBootstrap.Col>
							</ReactBootstrap.Row>
							<ReactBootstrap.Row>
								<ReactBootstrap.Col
									md={4}
									className="position-relative"
								>
									<div className="diferenciales-boxes">
										<SiOpslevel size={70} />
										<p>
											Transversal a todos los niveles
											jerárquicos
										</p>
										<div className="diferenciales-boxes-hover">
											<Link to={"/"}>Conoce más</Link>
										</div>
									</div>
								</ReactBootstrap.Col>
								<ReactBootstrap.Col
									md={4}
									className="position-relative"
								>
									<div className="diferenciales-boxes">
										<FaRegEdit size={70} />
										<p>
											Interfaz personalizada de acuerdo a
											de la empresa
										</p>
										<div className="diferenciales-boxes-hover">
											<Link to={"/"}>Conoce más</Link>
										</div>
									</div>
								</ReactBootstrap.Col>
								<ReactBootstrap.Col
									md={4}
									className="position-relative"
								>
									<div className="diferenciales-boxes">
										<GiPathDistance size={70} />
										<p>
											Permite conocer el estado de avance
											de los ex colaboradores
										</p>
										<div className="diferenciales-boxes-hover">
											<Link to={"/"}>Conoce más</Link>
										</div>
									</div>
								</ReactBootstrap.Col>
							</ReactBootstrap.Row>
						</ReactBootstrap.Col>
						<ReactBootstrap.Col
							lg={3}
							className="position-relative"
						>
							<div className="diferencial-big-box">
								<AiOutlineStar size={70} />
								<p>
									Los empleados que reciben programas de
									Outplacement publican su opinión en las
									redes, trabajando positivamente en la marca
									empleadora de la compañía, que tendrá 50%
									más capacidad de atracción de nuevos
									talentos.
								</p>
								<div className="diferenciales-boxes-hover">
									<Link to={"/"}>Conoce más</Link>
								</div>
							</div>
						</ReactBootstrap.Col>
					</ReactBootstrap.Row>

					<ReactBootstrap.Row className="py-5">
						<ReactBootstrap.Col lg={12}>
							<h1>Casos de éxito</h1>
						</ReactBootstrap.Col>
						<ReactBootstrap.Col lg={12}>
							<CarouselMyDnaGenes />
						</ReactBootstrap.Col>
					</ReactBootstrap.Row>

					<ReactBootstrap.Row className="justify-content-center py-5">
						<ReactBootstrap.Col lg={4} className="text-center">
							<h3 className="mb-4">
								Solicita una Interfaz personalizada para tu
								empresa
							</h3>
							<ReactBootstrap.Button className="btn-fill btn rounded-pill my-5 py-2 px-5">
								Quiero una interfaz para mi empresa
							</ReactBootstrap.Button>
						</ReactBootstrap.Col>
						<ReactBootstrap.Col lg={6}>
							<div className="box-img">
								<img
									alt=""
									className="img-fluid"
									src="https://www.genescompany.com/assets/img/img-nextstep/img/whitelabel-desktop.webp"
								/>
							</div>
						</ReactBootstrap.Col>
						<ReactBootstrap.Col lg={2}>
							<div className="box-img">
								<img
									alt=""
									className="img-fluid"
									src="https://www.genescompany.com/assets/img/img-nextstep/img/whitelabel-mobile.webp"
								/>
							</div>
						</ReactBootstrap.Col>
					</ReactBootstrap.Row>
				</ReactBootstrap.Container>

				<div className="colaboradores-faixa my-5 px-0">
					<ReactBootstrap.Container>
						<ReactBootstrap.Row className="w-100 max-width-adjust">
							<ReactBootstrap.Col lg={12}>
								<p className="color-white">
									Los colaboradores que reciben programas de
									Outplacement publican su opinión en las
									redes, trabajando positivamente{" "}
									<strong>en la marca empleadora</strong> de
									la compañía, que tendrá{" "}
									<strong>50% más</strong> capacidad de
									atracción de nuevos talentos.
								</p>
							</ReactBootstrap.Col>
						</ReactBootstrap.Row>
					</ReactBootstrap.Container>
				</div>
				<ReactBootstrap.Row className="justify-content-center text-center end-page my-5">
					<ReactBootstrap.Col lg={5}>
						<p>Entienda cómo funciona el plan empresa de GENES</p>
						<ReactBootstrap.Button className="btn-fill btn rounded-pill py-2 px-5">
							Cotiza Aqui
						</ReactBootstrap.Button>
					</ReactBootstrap.Col>
				</ReactBootstrap.Row>
			</ReactBootstrap.Container>
		</div>
	);
};
export default MyDnaGenes;
