import { useEffect, useState, useContext } from "react";
import ServicesContextProvider from "../../../../Store/ServicesContext";

// Components
import MenuPlan from "../NewMenu/MenuPlan";
import DetailPage from "../../detailPage";
import Beneficios from "./Views/Beneficios";

const BeneficiosView = ({ location }) => {
  /*
      ! Inicio
      ? Lo basico para que el componente antiguo del Menu funcione
  */

  useEffect(() => {
    if (location?.option) {
      setPage(String(location.option));
      setOption(String(location.option));
    }
  }, []);

  
  

  const [page, setPage] = useState("1");
  const [option, setOption] = useState("8");

  const handleMenu = (event) => {
    setOption(event.currentTarget.dataset.option);
  };
  useEffect(() => {
  }, [option])
  /*
      ! Fin
  */

  return (
    <ServicesContextProvider>
        <>
          <div className="detail-page ms-5 pb-5">
            <div className="row">
              <div className="col-12 col-md-12 d-flex flex-column align-items-center">
                <Beneficios location={location} />
              </div>
            </div>
          </div>{" "}
        </>
    </ServicesContextProvider>
  );
};
export default BeneficiosView;
