// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signupB2E {
  margin: 50px auto;
  width: 380px;
}
.signupB2E .companyLogo {
  width: 350px;
  margin-bottom: 50px;
}
.signupB2E h2 {
  font-family: "Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.1;
  color: rgb(51, 51, 51);
  text-size-adjust: 100%;
}
.signupB2E h4 {
  margin: 45px 0 15px 3px;
  font-size: 18px;
}
.signupB2E h4 span {
  color: rgb(111, 106, 174);
}
.signupB2E input, .signupB2E input[type=text] {
  width: 100%;
  border-radius: 2px;
  border: none;
  height: 30px;
  background: rgb(232, 240, 254);
  padding: 1px 2px;
  font-size: 14px;
  font-weight: 400;
  border-bottom: 1px solid rgb(204, 204, 204);
  font-family: "Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.signupB2E .content-button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.signupB2E .content-button button {
  background: #0058ad;
  color: #fff;
  padding: 10px 16px;
  border: none;
  width: 152px;
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Views/SignupB2E/signupB2E.sass"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,YAAA;AACJ;AACI;EACI,YAAA;EACA,mBAAA;AACR;AACI;EACI,gFAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,sBAAA;EACA,sBAAA;AACR;AACI;EACI,uBAAA;EACA,eAAA;AACR;AACQ;EACI,yBAAA;AACZ;AACI;EACI,WAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;EACA,8BAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,2CAAA;EACA,gFAAA;AACR;AAGI;EACI,WAAA;EACA,aAAA;EACA,yBAAA;AADR;AAGQ;EACI,mBAAA;EACA,WAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;EACA,gBAAA;AADZ","sourcesContent":[".signupB2E\n    margin: 50px auto\n    width: 380px\n\n    .companyLogo\n        width: 350px\n        margin-bottom: 50px\n\n    h2\n        font-family: \"Segoe UI\", SegoeUI, \"Helvetica Neue\", Helvetica, Arial, sans-serif\n        font-size: 20px\n        font-weight: 500\n        line-height: 1.1\n        color: rgb(51, 51, 51)\n        text-size-adjust: 100%\n\n    h4\n        margin: 45px 0 15px 3px\n        font-size: 18px\n\n        span\n            color: rgb(111 106 174)\n\n    input, input[type='text']\n        width: 100%\n        border-radius: 2px\n        border: none\n        height: 30px\n        background: rgb(232, 240, 254)\n        padding: 1px 2px\n        font-size: 14px\n        font-weight: 400\n        border-bottom: 1px solid rgb(204,204,204)\n        font-family: \"Segoe UI\", SegoeUI, \"Helvetica Neue\", Helvetica, Arial, sans-serif\n\n    .collaborator\n\n    .content-button\n        width: 100%\n        display: flex\n        justify-content: flex-end\n\n        button\n            background: #0058ad\n            color: #fff\n            padding: 10px 16px\n            border: none\n            width: 152px\n            margin-top: 20px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
