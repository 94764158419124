import { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { I18nProvider, LOCALES } from "./i18n";
import { LoginRoute } from "./Routes/Routes";
import CompanyContextProvider from "./Store/CompanyContext";

import EmployerContextProvider from "./Store/EmployerContext";
import "./Styles/custom.scss";
import "./Styles/general.sass";
import "./Styles/index.css"

function App() {
	const language = useState(navigator.language.toUpperCase());
	return (
		<I18nProvider
			locale={
				language[0] === "PT-BR" ? LOCALES.PORTUGUESE : LOCALES.SPANISH
			}
		>
			<BrowserRouter>
				<EmployerContextProvider>
					<CompanyContextProvider>
						<LoginRoute />
					</CompanyContextProvider>
				</EmployerContextProvider>
			</BrowserRouter>
		</I18nProvider>
	);
}

export default App;
