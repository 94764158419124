import { Link } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";
import ClimaQuestions from "./Components/QuestionsAndAnswers/ClimaQuestions";
import { questionsSalida } from "./Components/QuestionsAndAnswers/dataQSalida";
import { useEffect, useState } from "react";
const Salida = () => {
  const [allQuestions, setAllQuestions] = useState([]);
  const [receivedAnswer, setReceivedAnswer] = useState([{}]);
  const handleChange = (index, e) => {
    const auxArray = [...receivedAnswer];
    auxArray[index][e.currentTarget.name] = e.currentTarget.value;
    setReceivedAnswer(auxArray);
  };
  useEffect(() => {
    const bringQuestion = questionsSalida;
    setAllQuestions([...bringQuestion]);
  }, []);
  return (
    <div className="container-fluid">
      <div className="row">
        <ClimaQuestions questions={allQuestions} handleChange={handleChange} surveyType='1'/>
        <div className="col-12 mt-3">
          <p>
            IMPORTANTE: Tu Empresa no tendrá acceso a tus respuestas, solamente
            a la media entre los colaboradores, siempre y cuando más de 10
            colaboradores hayan respondido la encuesta, con el objetivo de
            mantener la confidencialidad.
          </p>
        </div>
        <div className="col-12 mt-5">
          <Link className="button-volver color-blue" to={"/employee"}>
            <MdArrowBack size={20} />
            Volver
          </Link>
        </div>
      </div>
    </div>
  );
};
export default Salida;
