export const getAreas = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API}/search/area`,
            {
                headers: {
                    "Content-type": "application/json",
                }
            }
        )
        return await response.json()
    }
    catch (err) {
        console.error(err)
    }
}

export const getLevels = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API}/search/level`,
            {
                headers: {
                    "Content-type": "application/json",
                }
            }
        )
        return await response.json()
    }
    catch (err) {
        console.error(err)
    }
}

export const searchUsersByProjection = async (job_name, area_name, company_id) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API}/employer/filter?area=${area_name}&level=${job_name}&companyId=${company_id}`,
            {
                headers: {
                    "Content-type": "application/json",
                }
            }
        )
        return await response.json()
    }
    catch (err) {
        console.error(err)
    }
}