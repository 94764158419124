export const filterPurpose = (list) => {
    const filter_1 = list.filter((filt) => {
        return filt.purposeCategoryId === 1
    })

    const filter_2 = list.filter((filt) => {
        return filt.purposeCategoryId === 2
    })

    const filter_3 = list.filter((filt) => {
        return filt.purposeCategoryId === 3
    })

    const filter_4 = list.filter((filt) => {
        return filt.purposeCategoryId === 4
    })

    const filter_5 = list.filter((filt) => {
        return filt.purposeCategoryId === 5
    })

    const filter_6 = list.filter((filt) => {
        return filt.purposeCategoryId === 6
    })

    return [filter_1, filter_2, filter_3, filter_4, filter_5, filter_6]
}

export const titlePurpose = [
    'Desarrollo personal',
    'Compensaciones',
    'Tipo de Trabajo',
    'Industria/Empresa',
    'Dinámica laboral',
    'Personal'
]