import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { MdClose, MdOutlineMode } from "react-icons/md";
import { getProfile } from "../../../../../../API/Employee/Profile";

import "./ModalViewProfileInfo.sass";

const ModalViewProfileInfo = ({
    show,
    onCloseClick,
    onEditClick,
    employeeId,
    lists,
}) => {
    const [employeeProfile, setEmployeeProfile] = useState({});
    useEffect(() => {
        const profileRequest = async () => {
            const [profileAux] = await getProfile(employeeId);
            setEmployeeProfile(profileAux);
        };

        profileRequest();
    }, [show, employeeId]);
    return (
        <Modal
            show={show}
            backdrop="static"
            keyboard={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="ModalProfileContent"
        >
            <div className="modal-header row border-0">
                <h5 className="modal-title col">Informaciones Generales</h5>
                <div className="col d-flex justify-content-end">
                    <button
                        type="button"
                        className="btn btn-primary btn-rounded mx-2"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={onEditClick}
                    >
                        <MdOutlineMode className="mx-1" />
                        Alterar
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={onCloseClick}
                    >
                        <MdClose className="icon" />
                    </button>
                </div>
            </div>
            <div className="container pt-0 pb-3">
                <div className="row">
                    <div className="col-md-3 panel">
                        <div className="bg-white p-3">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Mail Personal</label>
                                        <p className="info-label">
                                            {employeeProfile?.mailPersonal ||
                                                "-"}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>N Documento</label>
                                        <p className="info-label">
                                            {employeeProfile?.document || "-"}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Fecha de Nacimiento</label>
                                        <p className="info-label">
                                            {employeeProfile?.birth || "-"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 panel">
                        <div className="bg-white p-3">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Género</label>
                                        <p className="info-label">
                                            {employeeProfile?.gender
                                                ? employeeProfile?.gender === 1
                                                    ? "Masculino"
                                                    : employeeProfile?.gender ===
                                                      2
                                                    ? "Feminino"
                                                    : employeeProfile?.gender ===
                                                      3
                                                    ? "No binário"
                                                    : ""
                                                : "-"}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Estado Civil</label>
                                        <p className="info-label">
                                            {employeeProfile?.maritalStatus
                                                ? employeeProfile?.maritalStatus ===
                                                  1
                                                    ? "Soltero"
                                                    : employeeProfile?.maritalStatus ===
                                                      2
                                                    ? "Casado"
                                                    : employeeProfile?.maritalStatus ===
                                                      3
                                                    ? "Divorciado"
                                                    : employeeProfile?.maritalStatus ===
                                                      1
                                                    ? "Viúvo"
                                                    : ""
                                                : "-"}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Hijos</label>
                                        <p className="info-label">
                                            {employeeProfile?.childrens
                                                ? employeeProfile?.childrens
                                                : "Sin hijos"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 panel">
                        <div className="bg-white p-3">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>País</label>
                                        <p className="info-label">
                                            {employeeProfile?.country
                                                ? lists?.country
                                                      ?.filter(
                                                          (countries) =>
                                                              Number(
                                                                  countries.id
                                                              ) ===
                                                              Number(
                                                                  employeeProfile.country
                                                              )
                                                      )
                                                      .map(
                                                          (countriesMap) =>
                                                              countriesMap?.name
                                                      )
                                                : "-"}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Ciudad</label>
                                        <p className="info-label">
                                            {employeeProfile?.city}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Dirección</label>
                                        <p className="info-label">
                                            {employeeProfile?.address}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Nacionalidad</label>
                                        <p className="info-label">
                                            {employeeProfile?.nationality}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>
                                            Medio de transporte utilizado
                                        </label>
                                        <p className="info-label">
                                            {employeeProfile?.transport
                                                ? employeeProfile?.transport
                                                : "-"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-3 panel">
                        <div className="bg-white p-3">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Cargo</label>
                                        <p className="info-label">
                                            {employeeProfile?.boss
                                                ? employeeProfile?.boss
                                                : "-"}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Tipo de Contrato</label>
                                        <p className="info-label">
                                            {employeeProfile?.contract
                                                ? lists?.contract
                                                      ?.filter(
                                                          (contFilt) =>
                                                              Number(
                                                                  contFilt.id
                                                              ) ===
                                                              Number(
                                                                  employeeProfile.contract
                                                              )
                                                      )
                                                      .map((cont) => cont?.type)
                                                : "-"}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Banco</label>
                                        <p className="info-label">
                                            {employeeProfile?.bank
                                                ? employeeProfile?.bank
                                                : "-"}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Número de Cuenta</label>
                                        <p className="info-label">
                                            {employeeProfile?.currentAccount
                                                ? employeeProfile?.currentAccount
                                                : "-"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 panel">
                        <div className="bg-white p-3">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Plan de Salud</label>
                                        <p className="info-label">
                                            {employeeProfile?.healthPlan
                                                ? employeeProfile?.healthPlan
                                                : "-"}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Altura</label>
                                        <p className="info-label">
                                            {employeeProfile?.height
                                                ? employeeProfile?.height
                                                : "-"}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Peso</label>
                                        <p className="info-label">
                                            {employeeProfile?.weight
                                                ? employeeProfile?.weight
                                                : "-"}
                                            KG
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Enfermedades Crónicas</label>
                                        <p className="info-label">
                                            {employeeProfile?.enfermedad
                                                ? employeeProfile?.enfermedad
                                                : "-"}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Persona con Discapacidad</label>
                                        <p className="info-label">
                                            {employeeProfile?.pcd === "true"
                                                ? "Sí"
                                                : "No"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 panel">
                        <div className="bg-white p-3">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Facebook</label>
                                        <p className="info-label">
                                            {employeeProfile?.facebook
                                                ? employeeProfile?.facebook
                                                : "-"}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Linkedin</label>
                                        <p className="info-label">
                                            {employeeProfile?.linkedIn
                                                ? employeeProfile?.linkedIn
                                                : "-"}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Instagram</label>
                                        <p className="info-label">
                                            {employeeProfile?.instagram
                                                ? employeeProfile?.instagram
                                                : "-"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
export default ModalViewProfileInfo;
