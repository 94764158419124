export const postInternalEmployeeVacancy = async (data) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API}/internalvacancies/internalemployeevacancy`,
            {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );
        return response.json();
    } catch (error) {
        console.error(error);
    }
};
export const postInternalVacancyReferred = async (data) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API}/internalvacancies/internalvacancyreferred`,
            {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );
        return response.json();
    } catch (error) {
        console.error(error);
    }
};

export const getInternalVacancyByEmployee = async (employeeId) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/internalvacancies/employee/${employeeId}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};

export const getInternalEmployeeVacancy = async () => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/internalvacancies/internalemployeevacancy`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};

export const getAllVacanciesReferredByVacancy = async (internalVacancyId) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/internalvacancies/vacanciesReferredByVacancy/${internalVacancyId}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};