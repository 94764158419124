import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'

import { ProgressBar } from 'react-bootstrap'

import { getAbilities } from '../../API/Analytics/MYCO'
import Loading from '../../Components/Loading/Loading'

import { HabilidadesIcon, MotivacionesIcon, SalarioIcon } from '../../Icons/CollaboratorsIcons'
import ChartsMYCO from '../../Components/ChartsMYCO/ChartsMYCO'

import './habilidades.sass'
import HeaderCarouselMandala from './Components/HeaderCarouselMandala/HeaderCarouselMandala'
import EmptyInformation from './Components/EmptyInformation/EmptyInformation'
import { wheelCollaboratorTranslate } from '../../i18n/translatedMessages'

const Habilidades = () => {
	const labelsAbilities = [
		'Análisis y Resolución de Problemas',
		'Innovación',
		'Visión Estratégica',
		'Orientación al cliente',
		'Trabajo en Equipo',
		'Liderazgo',
		'Orientación a resultados',
		'Plan. Y Org.',
		'Gestión del cambio',
		'Auto desenvolvimento',
		'Gerenciamento de estresse',
	]
	const { id } = useParams()
	const [abilities, setAbilities] = useState(null)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		const fetchEmployer = async () => {
			setLoading(true)
			const abilitiesAux = await getAbilities(id)

			// Verificar si abilitiesAux no es un arreglo vacío
			if (abilitiesAux.length > 0) {
				const abilitiesLessId = abilitiesAux[abilitiesAux.length - 1]
				// Verificar si abilitiesLessId tiene las propiedades antes de eliminarlas
				if (abilitiesLessId && abilitiesLessId.employerId && abilitiesLessId.id) {
					delete abilitiesLessId.employerId
					delete abilitiesLessId.id
					setAbilities(abilitiesLessId)
				}
			}
			setLoading(false)
		}

		fetchEmployer()
	}, [id])

	if (loading) {
		return <Loading />
	}

	return (
		<div className='content-habilidades container'>
			<div>
				<HeaderCarouselMandala
					actualTitle={wheelCollaboratorTranslate.habilities}
					prevTitle={wheelCollaboratorTranslate.salary}
					routePrev={`/app/analytic/salario/${id}`}
					nextTitle={wheelCollaboratorTranslate.motivacions}
					routeNext={`/app/analytic/motivaciones/${id}`}
					iconActual={<HabilidadesIcon />}
					iconPrev={<SalarioIcon />}
					iconNext={<MotivacionesIcon />}
				/>
			</div>
			<div className='row row-cols-1 mt-3 py-3'>
				{abilities ? (
					<>
						<h6>Test psicolaboral de competencias profesionales, para conocer las competencias blandas que más de destacan a tus colaboradores.</h6>
						<div className='col'>
							<div className='abilitiesChartHeight m-auto'>
								<ChartsMYCO testName={'Competencias'} labels={labelsAbilities} importData={abilities} />
							</div>
							<div className='row row-cols-2 row-cols-md-4 g-3 mt-3'>
								{Object.values(abilities).map((ability, index) => {
									return (
										<div key={index} className={`col`}>
											<h6 className={`m-0`}>{labelsAbilities[index]}</h6>
											<ProgressBar label={ability + '/10'} className={`rounded-pill ${Number(ability) >= 5 ? `abilitiesAnswerPositive` : `abilitiesAnswerNegative`}`} now={ability} max={10} />
										</div>
									)
								})}
							</div>
						</div>
					</>
				) : (
					<div className='col mt-3'>
						<EmptyInformation />
					</div>
				)}
			</div>
		</div>
	)
}
export default Habilidades
