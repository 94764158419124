import { useEffect, useState, useRef } from 'react'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import './select.sass'
import { DefaultPattern } from '../../../i18n/translatedMessages'

export function Select({ list, onChange, label, name, value, placeholder = '', error = false, required = false, disabled = false }) {
	const [open, setOpen] = useState(false)
	const [item, setItem] = useState(value)

	const selectRef = useRef(null)

	useEffect(() => {
		const handleClickOutside = event => {
			if (selectRef.current && !selectRef.current.contains(event.target)) {
				setOpen(false)
			}
		}
		document.addEventListener('click', handleClickOutside)
		return () => {
			document.removeEventListener('click', handleClickOutside)
		}
	}, [])

	useEffect(() => {
		if (value !== null || value !== undefined) {
			const search = list.find(elemento => elemento.id === value)

			setItem(search)
			return
		}
		setItem(value)

		return () => {}
	}, [value, list])

	const handleClick = item => {
		setItem(item)
		onChange({ target: { value: item.id, name: name } })
	}

	if (disabled) {
		return (
			<div className='new-employee-select-disabled'>
				<div className='input'>
					<div className='ms-2 fs-6'>
						<span className='text-dark-grey'>{placeholder}</span>
					</div>
					<MdKeyboardArrowDown size={20} />
				</div>
				<label htmlFor=''>
					{label} {required && <span className='text-primary'>*</span>}
				</label>
			</div>
		)
	}

	return (
		<div className='new-employee-select' ref={selectRef} onClick={() => setOpen(!open)}>
			{error && <span className='text-negative-red small'>{DefaultPattern.inputRequired}</span>}
			<div className={`input ${error ? 'error' : required ? 'required' : 'no-required'}`}>
				<div className='ms-2 fs-6'>{item?.value || <span className='text-dark-grey'>{placeholder}</span>}</div>
				{!open && <MdKeyboardArrowDown size={20} />}
				{open && <MdKeyboardArrowUp size={20} />}
			</div>
			<label htmlFor=''>
				{label} {required && <span className='text-primary'>*</span>}
			</label>
			{open && (
				<ul className=''>
					{list.map(item => (
						<li key={item.id} onClick={() => handleClick(item)}>
							{item.value}
						</li>
					))}
				</ul>
			)}
		</div>
	)
}
