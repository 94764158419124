import { useState, useEffect } from 'react'
import { FaUserEdit } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { getAssetsCompany } from '../../../../API/Services/Company/Company'
import { wheelCompanyTranslate } from '../../../../i18n/translatedMessages'
import './wheel.sass'

const services = [
	{
		id: 2,
		link: '/app/services/list',
		name: wheelCompanyTranslate.peopleAnalytics,
		description: wheelCompanyTranslate.peopleAnalyticsDescription,
		icon: 'https://assets.mydna.company/people_analytics_white.svg',
	},
	{
		id: 3,
		link: '/app/services/badge-heores',
		name: wheelCompanyTranslate.learning,
		description: wheelCompanyTranslate.learningDescription,
		icon: 'https://assets.mydna.company/onboarding_white.svg',
	},
	{
		id: 4,
		link: '/app/services/capacitaciones',
		name: wheelCompanyTranslate.trainings,
		description: wheelCompanyTranslate.trainingsDescription,
		icon: 'https://assets.mydna.company/capacitacion_y_desarrollo_white.svg',
	},
	{
		id: 5,
		link: '/app/services/encuesta-clima',
		name: wheelCompanyTranslate.climeAndCulture,
		description: wheelCompanyTranslate.cultureAnalysisDescription,
		icon: 'https://assets.mydna.company/clima_laboral_white.svg',
	},
	{
		id: 6,
		link: '/app/services/employee-evaluation',
		name: wheelCompanyTranslate.performanceAnalysis,
		description: '',
		icon: 'https://assets.mydna.company/evaluacion_white.svg',
	},
	{
		id: 7,
		link: '/app/services/unit-report',
		name: wheelCompanyTranslate.salariesAndBenefits,
		description: wheelCompanyTranslate.salariesAndBenefitsDescription,
		icon: 'https://assets.mydna.company/salario_y_beneficios_white.svg',
	},
	{
		id: 8,
		link: '/app/services/assessment/competence',
		name: wheelCompanyTranslate.professionalDevelopment,
		description: wheelCompanyTranslate.professionalDevelopmentDescription,
		icon: 'https://assets.mydna.company/evaluacion_white.svg',
	},
	{
		id: 9,
		link: '/app/services/proyeccion-carrera',
		name: wheelCompanyTranslate.careerPlan,
		description: wheelCompanyTranslate.careerPlanDescription,
		icon: 'https://assets.mydna.company/plan_de_carrera_white.svg',
	},
	{
		id: 12,
		link: '/app/services/ombudsman',
		name: wheelCompanyTranslate.ombudsman,
		description: wheelCompanyTranslate.ombudsman,
		icon: 'https://assets.mydna.company/reclutamiento_y_selection_white.svg',
	},
	{
		id: 11,
		link: '/app/services/genes',
		name: wheelCompanyTranslate.outplacement,
		description: wheelCompanyTranslate.outplacementDescription,
		icon: 'https://assets.mydna.company/outplacement_white.svg',
	},
]

const WheelServices = () => {
	const [img, setImg] = useState(null)

	useEffect(() => {
		const company = JSON.parse(localStorage.getItem('user'))
		const requests = async () => {
			const imgAux = await getAssetsCompany(company.id)
			if (imgAux.length > 0) {
				setImg(imgAux[imgAux.length - 1].url)
				localStorage.setItem('image', imgAux[imgAux.length - 1].url)
			} else {
				setImg(null)
			}
		}
		requests()
	}, [])

	return (
		<div className={`container-wheel-services`}>
			<div className='ring-one p-md-5 p-4'>
				{services.map(service => (
					<Link to={{ pathname: service.link, option: service.id }} className={`icons-services shadow`} key={service.id}>
						<img src={service.icon} alt={service.name} className='img-fluid' />
						<div className='box-info'>
							<span className='title-service'>{service.name}</span>
							<span className='detail-service'>+ {wheelCompanyTranslate.seeMore}</span>
						</div>
					</Link>
				))}

				<div className='ring-two p-md-3 p-2'>
					<Link to={{ pathname: '/app/services/edit-industry', option: 1 }} className={img ? 'profile rounded-circle' : 'profile img-withoutLogo'}>
						<img src={img || 'https://assets.mydna.company/mydnalogo.png'} alt='' className={img ? 'img-fluid rounded-circle' : 'img-fluid'} />

						<div className={img ? 'wheelServices-hoverEditProfile' : ''}></div>
						<span className={img ? 'edit-perfil' : 'edit-perfil color-black'}>
							<FaUserEdit size={30} />
							Editar Perfil
						</span>
					</Link>
				</div>
			</div>
		</div>
	)
}
export default WheelServices
