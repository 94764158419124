import React, { useEffect, useState } from 'react'
import { StarAvaliation } from '../Employee/StarAvaliation/StarAvaliation'
import { Pill } from '../Employee/Pill/Pill'

import { performancePotentialTranslate } from '../../i18n/translatedMessages'

export function AccordionItem({ children, isOpen, setIsOpen, evaluation }) {
	const [stars, setStars] = useState({
		potential: evaluation.evaluation.potential.boxValue,
		performance: evaluation.evaluation.performance.boxValue,
	})

	const [salary9Box, setSalary9Box] = useState(evaluation.salary_band_9box)

	useEffect(() => {
		setStars({
			potential: evaluation.evaluation.potential.boxValue,
			performance: evaluation.evaluation.performance.boxValue,
		})
	}, [evaluation])

	useEffect(() => {
		setSalary9Box(evaluation.salary_band_9box)
	}, [evaluation])

	const toggleAccordion = () => {
		setIsOpen(isOpen => !isOpen)
	}

	const calcProfile = () => {
		const valuePerformance = Number(stars.performance)
		const valuePotential = Number(stars.potential)

		if (valuePotential >= 0.1 && valuePotential <= 3) {
			if (valuePerformance >= 0.1 && valuePerformance <= 3) {
				return {
					text: performancePotentialTranslate.profile1,
					color: '#FEE4E5',
				}
			} else if (valuePerformance >= 3.1 && valuePerformance <= 4) {
				return {
					text: performancePotentialTranslate.profile2,
					color: '#DFF3FE',
				}
			} else if (valuePerformance >= 4.1) {
				return {
					text: performancePotentialTranslate.profile3,
					color: '#DFF3FE',
				}
			}
		} else if (valuePotential >= 3.1 && valuePotential <= 4) {
			if (valuePerformance >= 0.1 && valuePerformance <= 3) {
				return {
					text: performancePotentialTranslate.profile4,
					color: '#FEF3C6',
				}
			} else if (valuePerformance >= 3.1 && valuePerformance <= 4) {
				return {
					text: performancePotentialTranslate.profile5,
					color: '#DFF3FE',
				}
			} else if (valuePerformance >= 4.1) {
				return {
					text: performancePotentialTranslate.profile6,
					color: '#C7FFF1',
				}
			}
		} else if (valuePotential >= 4.1) {
			if (valuePerformance >= 0.1 && valuePerformance <= 3) {
				return {
					text: performancePotentialTranslate.profile7,
					color: '#FEF3C6',
				}
			} else if (valuePerformance >= 3.1 && valuePerformance <= 4) {
				return {
					text: performancePotentialTranslate.profile9,
					color: '#C7FFF1',
				}
			} else if (valuePerformance >= 4.1) {
				return {
					text: performancePotentialTranslate.profile8,
					color: '#60fab2',
				}
			}
		}

		return {
			text: 'S/A',
			color: '#FEE4E5',
		}
	}

	const calculatedText = calcProfile()

	const clonedChild = React.cloneElement(children, { evaluation, setStars, stars, setSalary9Box, salary9Box })

	return (
		<div className='tw-divide-y'>
			<div
				className={`tw-cursor-pointer border-bottom tw-transition-all ${isOpen ? 'tw-bg-purple-500' : 'tw-bg-white'} tw-flex tw-justify-between tw-items-center tw-w-full tw-p-4 tw-text-left tw-text-gray-800 tw-font-medium tw-focus:outline-none`}
				onClick={toggleAccordion}
			>
				<div className='tw-flex tw-items-center tw-justify-between'>
					<img src={evaluation.profileImg ?? 'https://assets.mydna.company/profile-placeholder.png'} alt='' className='img-fluid rounded-circle tw-w-[64px] tw-h-[64px]'></img>
					<div className='tw-flex tw-flex-col tw-ml-5 tw-h-full'>
						<span className={`${isOpen === true ? 'tw-text-white' : 'tw-text-gray-800'} tw-text-md tw-font-bold tw-mb-1`}>{evaluation.name}</span>
						<span className={`${isOpen === true ? 'tw-text-white' : 'tw-text-gray-400'}`}>{evaluation.job.name}</span>
					</div>
				</div>
				<div className='tw-flex tw-items-center tw-space-x-8' style={{ width: '590px', overflow: 'hidden' }}>
					<StarAvaliation label={performancePotentialTranslate.performanceTitle} value={stars.performance} eventColorText={isOpen} />
					<StarAvaliation label={performancePotentialTranslate.potentialTitle} value={stars.potential} eventColorText={isOpen} />
					<div className='tw-flex-grow'>
						<Pill label={'Perfil'} text={calculatedText.text} color={`tw-bg-[${calculatedText.color}]`} eventColorText={isOpen} />
					</div>
					<div className='tw-flex tw-justify-center tw-items-center'>
						<svg className={`tw-w-5 tw-h-5 tw-transition-all ${isOpen ? 'tw-rotate-180' : 'tw-rotate-0'}`} fill='none' stroke='currentColor' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
							<path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M19 9l-7 7-7-7'></path>
						</svg>
					</div>
				</div>
			</div>
			<div className={`tw-overflow-hidden tw-transition  ${isOpen ? 'tw-max-h-full' : 'tw-max-h-0'}`}>
				<div className='tw-p-4 tw-bg-purple-200 '>{clonedChild}</div>
			</div>
		</div>
	)
}

export function AccordionEmployee({ children, evaluations }) {
	const [openIndex, setOpenIndex] = useState(null)

	const handleAccordionClick = index => {
		setOpenIndex(prevIndex => (prevIndex === index ? null : index))
	}

	return (
		<div className='tw-shadow-md tw-border-black tw-rounded-lg tw-w-full tw-mt-10 tw-overflow-hidden'>
			{evaluations.map((evaluation, index) => (
				<AccordionItem evaluation={evaluation} key={index} isOpen={index === openIndex} setIsOpen={() => handleAccordionClick(index)}>
					{children}
				</AccordionItem>
			))}
		</div>
	)
}

export default AccordionEmployee
