import { useCallback, useEffect, useState } from 'react'

import { ModalError } from '../../Components/Assessment/Modal/modalError'

import { QuestionsScreen } from '../../Components/Assessment/Screens/questionsScreen'
import { ResultScreen } from '../../Components/Assessment/Screens/resultScreens'
import AssessmentApi from '../../API/Assessment'

import Loading from '../../Components/Loading/Loading'

import { assessment } from '../../i18n/translatedMessages'

export function Assessment() {
	const [loading, setLoading] = useState(false)
	const [page, setPage] = useState(1)

	const loadAnswers = useCallback(async () => {
		try {
			setLoading(true)
			const user = JSON.parse(localStorage.getItem('user')).id
			const response = await AssessmentApi.getAnswersPage(user)

			setPage(response.page)
		} catch {
		} finally {
			setLoading(false)
		}
	}, [])

	useEffect(() => {
		loadAnswers()
	}, [loadAnswers])

	if (loading) return <Loading />

	return (
		<>
			<div className='pe-5'>
				<div className='row'>
					<div className='col'>
						<h3 className='tw-mb-4 tw-font-bold'>{assessment.competencyTest}</h3>
					</div>
				</div>
				{/* <ModalError
					show={true}
					handleShow={() => {
						return false
					}}
				/> */}
				{page <= 16 && <QuestionsScreen page={page} setPage={setPage} />}
				{page > 16 && <ResultScreen />}
			</div>
		</>
	)
}
