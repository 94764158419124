import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";

import DocumentsService from "../../../../API/Documents/Document.api";
import TemplatesService from "../../../../API/Documents/Template.api";
import DocumentsTemplate from "../../../../API/Documents/DocumentsTemplate.api";
import Loading from "../../../../Components/Loading/Loading";
import { translate } from "../../../../i18n/Library/documents.translate";
import { documentsTranslate } from "../../../../i18n/translatedMessages";

import "./ModalTemplate.sass";
import { useIntl } from "react-intl";

export const ModalTemplate = ({ showModal, setShowModal, userId }) => {
  const getLanguage = useIntl();
  const language = getLanguage.locale === "PT-BR" ? "pt" : "es";

  const [templateName, setTemplateName] = useState("");
  const [documentsList, setDocumentsList] = useState(null);
  const [checkedItems, setCheckedItems] = useState([]);
  const [newDocument, setNewDocument] = useState("");
  const [isLoadingBtn, setLoadingBtn] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const loadDocuments = async (id) => {
    if (id) {
      setLoading(true);
      const companyDocuments = await DocumentsService.getAllDocuments(id);
      const mydnaDocuments = await DocumentsService.getAllDocuments(0);
      console.log(mydnaDocuments);
      if (!companyDocuments.hasOwnProperty("message")) {
        setDocumentsList([...mydnaDocuments, ...companyDocuments]);
        setLoading(false);
      } else {
        setDocumentsList([...mydnaDocuments]);
        setLoading(false);
      }
    }
  };

  const handleCreate = async () => {
    const template = {
      owner_id: JSON.parse(localStorage.getItem("user")).id,
      name: templateName,
    };

    TemplatesService.createTemplates(template).then((template) => {
      const data = {
        documents: checkedItems,
        template_id: template.data.id,
      };

      DocumentsTemplate.createDocuments(data)
        .then(() => console.log("success"))
        .catch((error) => console.error(error));

      setTemplateName("");
      setShowModal(false);
    });
  };

  const handleCheckboxChange = (event) => {
    const { checked, id } = event.target;
    const aux = [...checkedItems];

    if (checked) {
      aux.push(Number(id));
    } else {
      const index = aux.indexOf(Number(id));
      if (index > -1) {
        aux.splice(index, 1);
      }
    }
    setCheckedItems(aux);
  };

  const createNewDocument = async (event) => {
    event.preventDefault();
    setLoadingBtn(true);
    if (newDocument === "") {
      alert(language === "PT-BR" ? "Adicionar nome do documento" : "Agregar nombre del documento");
      return;
    }

    const document = {
      name: newDocument,
      owner_id: JSON.parse(localStorage.getItem("user")).id,
    };
    DocumentsService.createDocuments(document).then(() => {
      loadDocuments(userId);
      setNewDocument("");
      setLoadingBtn(false);
    });
  };

  const handleNewDocument = (event) => {
    setNewDocument(event.target.value);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setTemplateName(value);
  };

  const handleClose = () => setShowModal(false);

  useEffect(() => {
    loadDocuments(userId);
  }, [userId]);

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      size="lg"
      fullscreen={true}
      centered={true}
      scrollable={true}
      className="ModalTemplate"
    >
      <Modal.Header closeButton>
        <Modal.Title>{templateName || documentsTranslate.nameTemplate}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <Loading />
        ) : (
          <form>
            <div className="mb-4">
              <input
                type="text"
                className="form-control"
                placeholder="Ex: Estagiário; Gerente..."
                name="title"
                value={templateName}
                onChange={handleChange}
              />
            </div>
            {documentsList && (
              <div className="row">
                {documentsList.map((document) => (
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6" key={document.id}>
                    <div className="form-check py-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={document.id}
                        name={document.id}
                        checked={checkedItems.includes(document.id) || false}
                        onChange={handleCheckboxChange}
                      />
                      <label className="form-check-label" htmlFor={document.name}>
                        {" "}
                        {translate[language][document.name.trim()] || document.name}{" "}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div className="row">
              <div className="col-2 d-flex align-items-center">
                <button
                  className="btn btn-outline-dark form-control"
                  onClick={!isLoadingBtn ? createNewDocument : null}
                  disabled={isLoadingBtn}
                >
                  {isLoadingBtn
                    ? documentsTranslate.loadingTemplate
                    : documentsTranslate.loadingCompleteTemplate}
                </button>
              </div>
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Documento Personalizado"
                  name="customDocument"
                  onChange={handleNewDocument}
                  value={newDocument}
                />
              </div>
            </div>
          </form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="row">
          <div className="col">
            <button type="button" className="btn " onClick={handleClose}>
              {documentsTranslate.btnCancelTemplate}
            </button>
          </div>
          <div className="col">
            <button
              className="btn buttonSave btn-primary rounded-pill"
              onClick={handleCreate}
              data-bs-dismiss="modal"
            >
              {documentsTranslate.btnSaveTemplate}
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
