import { useContext, useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Row, Col, Form, Modal } from "react-bootstrap";
import { sendServiceRequest, getUserCompany, handleCustomerRequest } from "../../../../API/service_request";
import { CompanyContext } from "../../../../Store/CompanyContext";

import Swal from "sweetalert2";

import "./ServiceRequestModal.css";

export const ServiceRequestModal = ({ view, hide, service, isPrivate }) => {
  const { store } = useContext(CompanyContext);
  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: false,
    icon: "success",
    title: "Servicio solicitado con éxito",
    showClass: {
      popup: "animate__animated animate__fadeInDown",
    },
    hideClass: {
      popup: "animate__animated animate__fadeOutUp",
    },
  });

  const ToastError = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: false,
    icon: "error",
    title: "Debes rellenar todos los campos",
    showClass: {
      popup: "animate__animated animate__fadeInDown",
    },
    hideClass: {
      popup: "animate__animated animate__fadeOutUp",
    },
  });

  const [user, setUser] = useState({
    name: store.currentUser?.name || "",
    lastname: "",
    jobtitle: store.currentUser?.jobName || "",
    company: "",
    mail: store.currentUser?.email || "",
    phone: store.currentUser?.mobilePhone || "",
    service: service,
    title: "Solicitud de Servicio Mydna",
    message: "",
    captcha: "",
    country: store.currentUser?.country || "",
  });

  const handleChange = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    const getCompany = async () => {
      const data = await getUserCompany(store.currentUser?.id);
      if (data) {
        setUser({
          ...user,
          company: data.currentCompany || "",
        });
      }
    };
    getCompany();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      user.captcha === "3" &&
      user.name !== "" &&
      user.jobtitle !== "" &&
      user.company !== "" &&
      user.mail !== "" &&
      user.phone !== "" &&
      user.service !== "" &&
      user.title !== "" &&
      user.message !== "" &&
      user.country !== "" &&
      user.lastname !== ""
    ) {
      try {
        if (isPrivate) {
          await handleCustomerRequest(user);
        }
        if (!isPrivate) {
          await sendServiceRequest(user);
        }
        hide();
        setTimeout(() => {
          Toast.fire();
        }, 500);
      } catch (err) {
        throw err;
      }
    } else {
      setTimeout(() => {
        ToastError.fire();
      }, 500);
    }
  };

  return (
    <>
      <Modal
        show={view}
        onHide={hide}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="background-purple">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-white"
          >
            Solicitud de Servicio Mydna
          </Modal.Title>
          <div className="btn-close" onClick={hide}></div>
        </Modal.Header>
        <Modal.Body>
          <Form method="POST">
            <Row className="mb-3">
              <Form.Group className="mb-3" as={Col}>
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  className="rounded-pill"
                  value={user.name}
                  name="name"
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" as={Col}>
                <Form.Label>Apellido</Form.Label>
                <Form.Control
                  className="rounded-pill"
                  value={user.lastname}
                  name="lastname"
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>Cargo</Form.Label>
                <Form.Control
                  className="rounded-pill"
                  value={user.jobtitle}
                  name="jobtitle"
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Empresa</Form.Label>
                <Form.Control
                  className="rounded-pill"
                  value={user.company}
                  name="company"
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>Correo</Form.Label>
                <Form.Control
                  className="rounded-pill"
                  type="email"
                  value={user.mail}
                  name="mail"
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Telefono</Form.Label>
                <Form.Control
                  className="rounded-pill"
                  value={user.phone}
                  name="phone"
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Servicio</Form.Label>
                <Form.Control
                  className="rounded-pill"
                  value={user.service}
                  name="service"
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>

            {store.currentUser ? (
              <Form.Group className="mb-3">
                <Form.Label>Titulo</Form.Label>
                <Form.Control
                  className="rounded-pill"
                  value={user.title}
                  name="title"
                  onChange={handleChange}
                />
              </Form.Group>
            ) : (
              <Form.Group className="mb-3">
                <Form.Label>Pais</Form.Label>
                <Form.Control
                  className="rounded-pill"
                  value={user.country}
                  name="country"
                  onChange={handleChange}
                />
              </Form.Group>
            )}

            <Form.Group className="mb-3">
              <Form.Label>Mensaje</Form.Label>
              <FormattedMessage id={"inputMessage"}>
                {(msg) => (
                  <Form.Control
                    rows={5}
                    as="textarea"
                    value={user.message}
                    name="message"
                    onChange={handleChange}
                    placeholder={msg}
                  />
                )}
              </FormattedMessage>
            </Form.Group>

            <Row className="d-flex justify-content-start">
              <Form.Group className="m-3 mt-4" as={Col}>
                <Form.Label>Resuelva esta suma 2 + 1 =</Form.Label>
              </Form.Group>
              <Form.Group className="m-3" as={Col}>
                <Form.Control
                  className="rounded-pill w-50"
                  value={user.captcha}
                  name="captcha"
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="m-3" lg={3} as={Col}></Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hide}>
            Cancelar
          </Button>
          <Button type="submit" onClick={handleSubmit}>
            Enviar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
