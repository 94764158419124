import { useState, useEffect } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import TableRows from "./TableRows";

import {
  getCompanyMedia,
  postCompanyMedia,
  putCompanyMedia,
} from "../../../../API/Services/RemuneracionVariable/CompanyMedia";

const RVDesempeno = ({ titleDesempeno }) => {
  const [user, setUser] = useState();

  const [medias, setMedias] = useState(null);

  const objEmpty = {
    name: "",
    weight: "",
    value: "",
  };

  const [criterios, setCriterios] = useState([objEmpty]);

  const handleAdd = () => {
    const auxArray = [...criterios];
    auxArray.push(objEmpty);
    setCriterios(auxArray);
  };

  const handleChange = (index, event) => {
    const auxArray = [...criterios];
    auxArray[index][event.currentTarget.name] = event.currentTarget.value;
    setCriterios(auxArray);
  };

  const deleteTableRows = async (index) => {
    const rows = [...criterios];

    if (index > -1) {
      rows.splice(index, 1);
      setCriterios([...rows]);
    }
    const objCrit = rows.map((x) => {
      return {
        name: x.name,
        value: parseFloat(x.value),
        weight: parseFloat(x.weight),
      };
    });
    const objSend = {
      companyId: user.id,
      answers: objCrit,
    };
    await putCompanyMedia(objSend, medias.id);
    const [response] = await getCompanyMedia(user.id);
    setMedias(response);
  };

  const handleSend = async () => {
    const objCrit = criterios.map((x) => {
      return {
        name: x.name,
        value: parseFloat(x.value),
        weight: parseFloat(x.weight),
      };
    });
    const objSendPost = {
      companyId: user.id,
      answers: objCrit,
    };
    const objSendPut = {
      answers: objCrit,
    };
    medias?.answers
      ? await putCompanyMedia(objSendPut, medias.id)
      : await postCompanyMedia(objSendPost);
    const [response] = await getCompanyMedia(user.id);
    setMedias(response);
  };

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
    const users = JSON.parse(localStorage.getItem("user"));
    const asyncRequest = async () => {
      const [response] = await getCompanyMedia(users.id);
      setMedias(response);
      if (response?.answers) setCriterios([...response.answers]);
      await getCompanyMedia(users.id);
    };
    asyncRequest();
  }, []);
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 pt-4">
          <h5>{titleDesempeno}</h5>
        </div>
        <div className="ms-auto col-2 pb-4">
          <div className="resultBox m-0">
            <h2>{medias?.media.toFixed(2)}%</h2>
          </div>
        </div>
        <table className="table table-desempeno">
          <thead>
            <tr>
              <th scope="col">Criterio</th>
              <th scope="col">Peso</th>
              <th scope="col">% Logrado</th>
              <th scope="col">
                <button className="add-remove-button" onClick={handleAdd}>
                  <AiOutlinePlusCircle size={25} />
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {criterios.map((item, index) => (
              <TableRows
                key={index}
                data={item}
                index={index}
                deleteTableRows={deleteTableRows}
                handleChange={handleChange}
              />
            ))}
          </tbody>
        </table>
        <div className="text-end">
          <button className="btn ms-auto btn-salvar" onClick={handleSend}>
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};
export default RVDesempeno;
