import { PerformanceAndPotentialEmp } from '../../Components/EmployeeEvaluation/performanceAndPotentialEmp'
import { AccordionEmployee } from '../../Components/EmployeeEvaluation/accordionEmployee'
import { DropBox } from '../../Components/EmployeeEvaluation/drobBox'
import { useCallback, useEffect, useState } from 'react'
import { getEmployeeEvaluations, getEmployeesByCompany } from '../../../src/API/Employee/Employee'
import { performancePotentialTranslate } from '../../i18n/translatedMessages'
import { Select } from '../../../src/Components/Employee/Select/Select'
import Loading from '../../../src/Components/Loading/Loading'
import NineBox from '../../../src/Components/EmployeeEvaluation/9box'
import List from '../../API/Lists/Lists.js'
import countryMapper from '../../Utils/CountriesMapper.js'
import areasMapper from '../../Utils/AreasMapper.js'
import levelsMapper from '../../Utils/LevelsMapper.js'

import { translate as translateAreas } from '../../i18n/Library/areasCorrelation.js'
import { translate as translatePosition } from '../../i18n/Library/hierarchicalLevel'
import { useIntl } from 'react-intl'
import { HiMagnifyingGlass } from 'react-icons/hi2'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { FaArrowLeft, FaArrowRight, FaRegTrashAlt, FaSearch } from 'react-icons/fa'

export function EmployeeEvaluation() {
	const getLanguage = useIntl()
	const language = getLanguage.locale === 'PT-BR' ? 'pt' : 'es'

	const location = useLocation()
	const currentPath = location.pathname

	const [loading, setLoading] = useState(false)
	const [countries, setCountries] = useState([])
	const [areas, setAreas] = useState([])
	const [levels, setLevels] = useState([])
	const [page, setPage] = useState(1)
	const [totalPages, setTotalPages] = useState(0)

	const [evaluations, setEvaluations] = useState([])
	const [filteredEvaluations, setFilteredEvaluations] = useState([])

	const [filters, setFilters] = useState({
		country: '',
		area: '',
		level: '',
		text: '',
	})
	const [textFilter, setTextFilter] = useState('')

	const fetchData = useCallback(async () => {
		try {
			setLoading(true)
			const company = JSON.parse(localStorage.getItem('user')).id
			const { area, level, country, text } = filters

			const [resArea, resCountry, resLevel] = await Promise.all([List.areas(), List.countries(), List.levels()])

			setCountries(resCountry.map(countryMapper.toDomain))
			setAreas(resArea.map(areasMapper.toDomain))
			setLevels(resLevel.map(levelsMapper.toDomain))

			const resEmployeeEvaluations = await getEmployeeEvaluations(company, page, area, level, country, text)

			setEvaluations(resEmployeeEvaluations.body)
			setFilteredEvaluations(resEmployeeEvaluations.body)
			setTotalPages(Math.ceil(resEmployeeEvaluations.count / 50))
		} catch (error) {
			console.error('Error:', error)
		} finally {
			setLoading(false)
		}
	}, [page, filters])

	useEffect(() => {
		fetchData()
	}, [fetchData])

	const handleFilterText = e => {
		const text = e.target.value

		setTextFilter(text)
	}

	const handleKeyDown = e => {
		if (e.key === 'Enter' && textFilter.length > 0) {
			setFilters(prevFilters => ({
				...prevFilters,
				text: textFilter,
			}))
		}
	}

	const handleSearchText = () => {
		setFilters(prevFilters => ({
			...prevFilters,
			text: textFilter,
		}))
	}

	const handleFilter = e => {
		const selectedOption = e.target.value

		setFilters(prev => ({
			...prev,
			[e.target.name]: selectedOption || null,
		}))
		setPage(1)
	}

	const cleanFilters = () => {
		setFilters({
			area: '',
			level: '',
			country: '',
			text: '',
		})
		setTextFilter('')
		setPage(1)
	}

	const translatedAreas = areas.map(item => ({
		...item,
		value: translateAreas[language][item?.value],
	}))

	const translatedLevels = levels.map(item => ({
		...item,
		value: translatePosition[language][item?.value],
	}))

	return (
		<>
			{loading ? (
				<Loading />
			) : (
				<>
					<h3 className='tw-mb-4 tw-font-bold'>Evaluación de Desempeño y Potencial</h3>
					<div className='tw-text-lg tw-mb-4 tw-pr-20'>
						La evaluación de desempeño y potencial implica el análisis sistemático de las competencias, resultados y comportamientos de los colaboradores en sus funciones actuales. El desempeño se mide por metas alcanzadas y habilidades demostradas,
						mientras que el potencial se evalúa en función de la capacidad para asumir funciones futuras y crecer en la organización.
					</div>
					<div className='tw-w-full tw-flex tw-space-x-3 tw-items-center tw-pr-20'>
						<div className='tw-w-7/12 border border-grey tw-rounded-lg py-1 px-3 tw-flex tw-items-center tw-justify-center tw-mt-1'>
							<HiMagnifyingGlass size={22} className='text-dark-grey' />
							<input type='text' className='border border-0 p-0 m-0 ms-4 tw-w-full' placeholder='Buscar Colaborador' onKeyDown={handleKeyDown} onChange={handleFilterText} value={textFilter} />
						</div>

						<div className='tw-w-1/5'>
							<Select placeholder='País' list={countries} onChange={handleFilter} name='country' value={filters.country} />
						</div>
						<div className='tw-w-1/5'>
							<Select placeholder='Área' list={translatedAreas} onChange={handleFilter} name='area' value={filters.area} />
						</div>

						<div className='tw-w-1/5'>
							<Select placeholder={'Nivel Hierárquico'} list={translatedLevels} onChange={handleFilter} name='level' value={filters.level} />
						</div>
						<button
							className={`tw-w-[9%] tw-text-sm tw-flex tw-px-1 tw-items-center tw-justify-center tw-mt-1 ${Object.values(filters).some(filter => filter) ? 'tw-bg-purple-500 tw-text-white' : 'tw-text-gray-400'}`}
							style={{ height: 45, border: '1px solid #CBD5E1', borderRadius: 8 }}
							onClick={cleanFilters}
							disabled={Object.values(filters).every(filter => filter === '')}
						>
							<FaRegTrashAlt className='me-1' size={12} />
							Limpar
						</button>
						<button
							className={`tw-w-[9%] tw-text-sm tw-flex tw-px-2 tw-items-center tw-justify-center tw-mt-1 ${textFilter !== '' ? 'tw-bg-purple-500 tw-text-white' : 'tw-text-gray-400'}`}
							style={{ height: 45, border: '1px solid #CBD5E1', borderRadius: 8 }}
							onClick={handleSearchText}
							disabled={!textFilter}
						>
							<FaSearch className='me-1' size={12} />
							Pesquisar
						</button>
					</div>
					<div className='tw-text-purple-600 tw-mr-20'>
						<AccordionEmployee evaluations={filteredEvaluations}>
							<DropBox path={currentPath}>
								<PerformanceAndPotentialEmp title={performancePotentialTranslate.performanceAvaliationTitle} label={'Performance'} />
								<PerformanceAndPotentialEmp title={performancePotentialTranslate.potentialAvaliationTitle} label={'Potential'} />
								<NineBox title='Perfil 9BOX' chart={true} />
							</DropBox>
						</AccordionEmployee>
					</div>
					<div className='tw-mb-4 tw-flex tw-justify-end tw-pr-20 tw-items-center tw-space-x-2 tw-mt-5'>
						<div
							onClick={() => setPage(prev => Math.max(prev - 1, 1))}
							disabled={page === 1}
							className={`tw-cursor-pointer tw-px-2 tw-py-1  tw-text-gray-700 tw-rounded disabled:tw-opacity-20 ${page === 1 ? 'tw-cursor-default tw-opacity-50' : ''} border-0`}
						>
							<FaArrowLeft size={12} />
						</div>

						{Array.from({ length: totalPages }, (_, index) => (
							<div
								key={index + 1}
								onClick={() => setPage(index + 1)}
								className={`tw-cursor-pointer tw-px-2 tw-py-1 tw-rounded ${page === index + 1 ? 'tw-bg-purple-600 tw-text-white' : ' tw-text-gray-700 hover:tw-bg-purple-500 hover:tw-text-white border-0'}`}
							>
								{index + 1}
							</div>
						))}

						<div
							onClick={() => setPage(prev => Math.min(prev + 1, totalPages))}
							disabled={page === totalPages}
							className={`tw-cursor-pointer tw-px-2 tw-py-1  tw-text-gray-700 tw-rounded disabled:tw-opacity-20 ${page === totalPages ? 'tw-cursor-default tw-opacity-50' : ''} border-0`}
						>
							<FaArrowRight size={12} />
						</div>
					</div>
				</>
			)}
		</>
	)
}
