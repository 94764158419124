import { useState, useEffect } from 'react';
import { GrLineChart } from 'react-icons/gr';
import { IoIosPeople } from 'react-icons/io';
import { MdStar } from 'react-icons/md';

import { RiDashboardLine } from 'react-icons/ri';
import { getPlans } from '../../../../API/Lists/plans';
import { getMisSoluciones } from '../../../../API/Services/MisSoluciones/MisSoluciones';
import Loading from '../../../../Components/Loading/Loading';
import { headerTextTranslate } from '../../../../i18n/translatedMessages';

import './MisSoluciones.sass';

const MisSoluciones = () => {
  const [serviceList, setServiceList] = useState([]);
  const [planList, setPlanList] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    const getRequests = async () => {
      const serviceListAux = await getMisSoluciones(user.id);
      setServiceList(serviceListAux);
      const planListAux = await getPlans();
      setPlanList(planListAux);
      setLoading(false);
    };
    getRequests();
  }, []);
  return (
    <div className="container missoluciones-main">
      <div className="row align-items-center">
        {loading && (
          <div className="loading">
            <Loading />
          </div>
        )}
        <div className="col-md-1 offset-1">
          <div className="header-icon">
            <img src="https://assets.mydna.company/b2b-images/MisSoluciones.svg" alt="" className="img-fluid" />
          </div>
        </div>
        <div className="col-md-4">
          <h1 className="mt-1">{headerTextTranslate.mySolutions}</h1>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col offset-1">
          <h4>Soluciones Contratadas</h4>
        </div>
      </div>
      {serviceList?.length > 0 ? (
        serviceList?.map((services, key) => {
          return (
            <div key={key} className="row mt-3">
              <div className="col-11 offset-1">
                <div className="card-service py-3">
                  {planList
                    .filter((plan) => {
                      return plan?.id === services?.planId;
                    })
                    .map((planFiltered, key) => {
                      return (
                        <div className="row">
                          <div className="col-1">
                            <div className="card-icon">
                              {planFiltered.id === 2 ? (
                                <img src="https://assets.mydna.company/people_analytics_white.svg" alt="" className="img-fluid" />
                              ) : planFiltered.id === 3 ? (
                                <img src="https://assets.mydna.company/capacitacion_y_desarrollo_white.svg" alt="" className="img-fluid" />
                              ) : planFiltered.id === 4 ? (
                                <img src="https://assets.mydna.company/reclutamiento_y_selection_white.svg" alt="" className="img-fluid" />
                              ) : planFiltered.id === 5 ? (
                                <img src="https://assets.mydna.company/capacitacion_y_desarrollo_white.svg" alt="" className="img-fluid" />
                              ) : planFiltered.id === 6 ? (
                                <img src="https://assets.mydna.company/iconoCard_ingles.svg" alt="" className="img-fluid" />
                              ) : planFiltered.id === 7 ? (
                                <img src="https://assets.mydna.company/iconoCard_pitch-comercial.svg" alt="" className="img-fluid" />
                              ) : planFiltered.id === 8 ? (
                                <img src="https://assets.mydna.company/iconoCard_gamificacion-de-contenidos.svg" alt="" className="img-fluid" />
                              ) : planFiltered.id === 9 ? (
                                <RiDashboardLine size={35} />
                              ) : planFiltered.id === 10 ? (
                                <IoIosPeople size={35} />
                              ) : planFiltered.id === 11 ? (
                                <MdStar size={35} />
                              ) : planFiltered.id === 12 ? (
                                <img src="https://assets.mydna.company/salario_y_beneficios_white.svg" alt="" className="img-fluid" />
                              ) : planFiltered.id === 13 ? (
                                <img src="https://assets.mydna.company/iconoCard_pitch-comercial.svg" alt="" className="img-fluid" />
                              ) : planFiltered.id === 14 ? (
                                <img src="https://assets.mydna.company/iconoCard_pitch-comercial.svg" alt="" className="img-fluid" />
                              ) : planFiltered.id === 15 ? (
                                <GrLineChart size={35} />
                              ) : planFiltered.id === 16 ? (
                                <img src="https://assets.mydna.company/reclutamiento_y_selection_white.svg" alt="" className="img-fluid" />
                              ) : planFiltered.id === 17 ? (
                                <img src="https://assets.mydna.company/outplacement_white.svg" alt="" className="img-fluid" />
                              ) : planFiltered.id === 18 ? (
                                <img src="https://assets.mydna.company/iconoCard_dashboard-empresa.svg" alt="" className="img-fluid" />
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                          <div className="col-6">
                            <h5 key={key}>{planFiltered.name}</h5>
                          </div>
                        </div>
                      );
                    })}

                  <div className="row">
                    <div className="col offset-1">{services?.description}</div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="row">
          <div className="col offset-1">
            <div className="alert alert-warning text-center" role="alert">
              Todavia no hay soluciones contratadas. escribe a <a href="mailto:contacto@mydnadigital.com">contacto@mydnadigital.com</a> para activar tus soluciones.
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MisSoluciones;
