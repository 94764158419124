// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-permission {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.error-permission span {
  margin: 0 10px;
  font-size: 25px;
}
.error-permission svg {
  color: #ce3333;
}
.error-permission button {
  background: #0058ad;
  padding: 10px;
  color: white;
  border-radius: 3px;
  border: none;
}
.error-permission button:hover {
  background: #3586c8;
}`, "",{"version":3,"sources":["webpack://./src/Components/Errors/permission.sass"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;AACI;EACI,cAAA;EACA,eAAA;AACR;AACI;EACI,cAAA;AACR;AACI;EACI,mBAAA;EACA,aAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;AACR;AACQ;EACI,mBAAA;AACZ","sourcesContent":[".error-permission\n    width: 100vw\n    height: 100vh\n    display: flex\n    justify-content: center\n    align-items: center\n\n    span\n        margin: 0 10px\n        font-size: 25px\n\n    svg\n        color: #ce3333\n\n    button\n        background: #0058ad\n        padding: 10px\n        color: white\n        border-radius: 3px\n        border: none\n\n        &:hover\n            background: #3586c8\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
