import { Button, Modal } from "react-bootstrap";
import { BiEdit } from "react-icons/bi";

import "./ModalEditLinks.sass";
import { btnTranslate } from "../../../../i18n/translatedMessages";

const ModalEditLinks = ({ show, handleShow, handleClose, onChange, data, handleSend }) => {
  return (
    <>
      <Button variant="" className="btn-img p-0" bsPrefix="btn" onClick={handleShow}>
        <BiEdit size={20} /> <span className="animationEditText">Editar datos de contacto</span>
      </Button>

      <Modal className="ModalEditLinksMain" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Datos de contacto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <div className="input-group mb-3">
                  <span className="input-group-text span-img">
                    <img
                      className="img-fluid"
                      alt=""
                      src={
                        "https://assets.mydna.company/b2b-images/employeeWheelScreen-icon_instagram.svg"
                      }
                    />
                  </span>
                  <input
                    type="text"
                    className="form-control m-0"
                    name="instagram"
                    onChange={onChange}
                    value={data?.instagram}
                    id="instagram"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="input-group mb-3">
                  <span className="input-group-text span-img">
                    <img
                      className="img-fluid"
                      alt=""
                      src={
                        "https://assets.mydna.company/b2b-images/employeeWheelScreen-icon_linkedin.svg"
                      }
                    />
                  </span>
                  <input
                    type="text"
                    className="form-control m-0"
                    name="linkedIn"
                    onChange={onChange}
                    value={data?.linkedIn}
                    id="linkedIn"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="input-group mb-3">
                  <span className="input-group-text span-img">
                    <img
                      className="img-fluid"
                      alt=""
                      src={
                        "https://assets.mydna.company/b2b-images/employeeWheelScreen-icon_whatsapp.svg"
                      }
                    />
                  </span>
                  <input
                    type="tel"
                    className="form-control m-0"
                    name="phone"
                    id="phone"
                    onChange={onChange}
                    value={data?.phone}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="input-group mb-3">
                  <span className="input-group-text span-img">
                    <img
                      className="img-fluid"
                      alt=""
                      src={
                        "https://assets.mydna.company/b2b-images/employeeWheelScreen-icon_email.svg"
                      }
                    />
                  </span>
                  <input
                    type="email"
                    className="form-control m-0"
                    name="mailPersonal"
                    id="mailPersonal"
                    onChange={onChange}
                    value={data?.mailPersonal}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-guardar" variant="" onClick={handleSend}>
            {btnTranslate.saveButton}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalEditLinks;
