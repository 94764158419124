import { useState, useEffect } from 'react'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { MdOutlinePeopleOutline, MdStars } from 'react-icons/md'
import { Link } from 'react-router-dom'

import { getEmployeeMediasByEmployerId, getEmployeesMediasByCompanyId, postEmployeeMedia, putEmployeeMedia } from '../../../../API/Services/RemuneracionVariable/EmployeeMedia'
import { performanceEmployerTranslate } from '../../../../i18n/translatedMessages'
import TableRows from './TableRows'
import Loading from '../../../../Components/Loading/Loading'
import DropFilter from '../../../../Components/Filters/DropFilter'
import { BiBriefcase, BiWorld } from 'react-icons/bi'
import { useListFilters } from '../../../../Components/Filters/ListFilters/useListFilters'
import {
	CritSuggestAdministrativoFinanciero,
	CritSuggestAltaAdministracion,
	CritSuggestComercialVentasSeguros,
	CritSuggestInvestigacionDesarrollo,
	CritSuggestLegal,
	CritSuggestMarketing,
	CritSuggestOperacionManufacuturaIngenieria,
	CritSuggestSupplyChain,
	CritSuggestTI,
	CritSuggestRRHH,
} from './ListCritSuggestion'

import 'bootstrap'

import './styles.css'
import { useIntl } from 'react-intl'

const DesempenoEmployer = () => {
	const getLanguage = useIntl()
	const language = getLanguage.locale === 'PT-BR' ? 'pt' : 'es'
	const [loading, setLoading] = useState(true)
	const [critLoading, setCritLoading] = useState(false)
	const [medias, setMedias] = useState(null)
	const [ids, setIds] = useState(undefined)

	const [mediasByCompany, setMediasByCompany] = useState(null)

	const [user, setUser] = useState()

	const { setSearch, setFilterCountryCheck, setFilterAreaCheck, setFilterLevelCheck, filterAreaCheck, filterCountryCheck, filterLevelCheck, area, level, country, filterEmployees } = useListFilters()

	const objEmpty = {
		name: '',
		weight: '',
		value: '',
	}

	const [criterios, setCriterios] = useState([objEmpty])
	const [suggestions, setSuggestions] = useState([])

	useEffect(() => {
		setUser(JSON.parse(localStorage.getItem('user')))
		const users = JSON.parse(localStorage.getItem('user'))
		const getRequests = async () => {
			const employeeMediaByCompany = await getEmployeesMediasByCompanyId(users.id)
			setMediasByCompany(employeeMediaByCompany)
			setLoading(false)
		}
		getRequests()
	}, [])

	useEffect(() => {
		if (ids !== undefined) {
			const callCriterios = async () => {
				const [response] = await getEmployeeMediasByEmployerId(ids)
				setMedias(response)
				if (response?.answers) {
					setCriterios([...response.answers])
				}
			}
			callCriterios()
			setCritLoading(false)
		}
	}, [ids])

	const handleSearch = e => {
		if (e.length >= 3) {
			setSearch(e)
		} else {
			setSearch('')
		}
	}

	const handleAdd = () => {
		const auxArray = [...criterios]
		auxArray.push(objEmpty)
		setCriterios(auxArray)
	}

	const handleChange = (index, event) => {
		const auxArray = [...criterios]
		auxArray[index][event.currentTarget.name] = event.currentTarget.value
		setCriterios(auxArray)
	}
	const handleChangeObs = e => {
		setMedias({ ...medias, observation: e })
	}

	const deleteTableRows = async index => {
		const rows = [...criterios]

		if (index > -1) {
			rows.splice(index, 1)
			setCriterios([...rows])
		}
		const objCrit = rows.map(x => {
			return {
				name: x.name,
				value: parseFloat(x.value),
				weight: parseFloat(x.weight),
			}
		})
		const objSendPut = {
			answers: objCrit,
		}
		await putEmployeeMedia(objSendPut, medias.id)
		const employeeMediaByCompany = await getEmployeesMediasByCompanyId(user.id)
		setMediasByCompany(employeeMediaByCompany)
	}

	const handleSend = async () => {
		setLoading(true)
		const objCrit = criterios.map(x => {
			return {
				name: x.name,
				value: parseFloat(x.value),
				weight: parseFloat(x.weight),
			}
		})
		const objSendPost = {
			companyId: user.id,
			employerId: ids,
			answers: objCrit,
			observation: medias?.observation || '',
		}
		const objSendPut = {
			answers: objCrit,
			observation: medias?.observation || '',
		}
		medias?.answers ? await putEmployeeMedia(objSendPut, medias.id) : await postEmployeeMedia(objSendPost)
		const employeeMediaByCompany = await getEmployeesMediasByCompanyId(user.id)
		setLoading(false)
		setMediasByCompany(employeeMediaByCompany)
	}

	const handleClick = async employee => {
		setCritLoading(true)
		setCriterios([objEmpty])
		await setMedias(null)
		await setIds(undefined)
		await setSuggestions([])
		setSuggestions(
			employee.area?.profileArea === 'TI'
				? CritSuggestTI[language]
				: employee.area.profileArea === 'Comercial y Ventas' || employee.area.profileArea === 'Seguros'
				? CritSuggestComercialVentasSeguros[language]
				: employee.area?.profileArea === 'Marketing'
				? CritSuggestMarketing[language]
				: employee.area?.profileArea === 'Ingeniería' || employee.area?.profileArea === 'Operación y Manufactura'
				? CritSuggestOperacionManufacuturaIngenieria[language]
				: employee.area?.profileArea === 'Supply Chain'
				? CritSuggestSupplyChain[language]
				: employee.area?.profileArea === 'RRHH'
				? CritSuggestRRHH[language]
				: employee.area?.profileArea === 'Investigación y Desarrollo'
				? CritSuggestInvestigacionDesarrollo[language]
				: employee.area?.profileArea === 'Financiero' || employee.area?.profileArea === 'Administrativo'
				? CritSuggestAdministrativoFinanciero[language]
				: employee.area?.profileArea === 'Legal'
				? CritSuggestLegal[language]
				: employee.area?.profileArea === 'Alta Administración'
				? CritSuggestAltaAdministracion[language]
				: []
		)
		await setIds(employee.id)
	}

	const handleSuggest = e => {
		if (criterios[criterios.length - 1].name === '') {
			const auxSug = [...criterios]
			auxSug[criterios.length - 1]['name'] = e
			setCriterios(auxSug)
		}
	}

	return (
		<div className='row'>
			<div className='col offset-1'>
				<div className='container-fluid desempeno-employee'>
					{loading && (
						<div className='loading'>
							<Loading />
						</div>
					)}
					<div className='row'>
						<div className='col d-flex'>
							<div className='icon'>
								<MdStars size={50} />
							</div>
							<h1>{performanceEmployerTranslate.employerPerformanceTitle}</h1>
						</div>
					</div>
					<div className='row'>
						<input type='text' className='form-control my-0' placeholder='Colaboradores' onChange={e => handleSearch(e.target.value)} />
						<div className='row row-cols-3'>
							<div className='col'>
								<DropFilter changeAppearence={filterCountryCheck.length} icon={<BiWorld />} filterName='País' setFiltValues={setFilterCountryCheck} filtValues={filterCountryCheck} list={country} />
							</div>
							<div className='col'>
								<DropFilter icon={<BiBriefcase />} filterName='Área' setFiltValues={setFilterAreaCheck} filtValues={filterAreaCheck} list={area} />
							</div>
							<div className='col'>
								<DropFilter icon={<MdOutlinePeopleOutline />} filterName='Nivel' setFiltValues={setFilterLevelCheck} filtValues={filterLevelCheck} list={level} />
							</div>
						</div>

						<div className='col-10 pt-5 offset-1'>
							<div className='accordion' id='accordionPerformance'>
								{filterEmployees.length ? (
									filterEmployees.map((employee, key) => {
										return (
											<div key={key} className='accordion-item'>
												<h2 className='accordion-header' id={`performanceForHeading` + key}>
													<button className='accordion-button d-flex collapsed' type='button' data-bs-toggle='collapse' data-bs-target={`#performanceFor` + key} aria-expanded='false' onClick={() => handleClick(employee)}>
														<div className='col-1'>
															<img className='img-fluid img-fixed-size rounded-circle  shadow' src={employee.asset || 'https://assets.mydna.company/profile-placeholder.png?1645585980559'} alt='...' />
														</div>
														<div className='col-8'>
															<h5>{employee.name}</h5>
															<span>{employee.area?.profileArea}</span>
														</div>
														<div className='col-2 ps-0'>
															{mediasByCompany
																?.filter(media => media.employerId === employee.id)
																.map((filtered, key) => {
																	return (
																		<div className='resultBox m-auto' key={key}>
																			<h5>{filtered.media.toFixed(2)}%</h5>
																		</div>
																	)
																})}
														</div>
													</button>
												</h2>
												<div id={`performanceFor` + key} className='accordion-collapse collapse' aria-labelledby={`#performanceForHeading` + key} data-bs-parent='#accordionPerformance'>
													<div className='accordion-body'>
														{critLoading ? (
															<Loading />
														) : (
															<>
																<table className='table table-analisis'>
																	<thead>
																		<tr>
																			<th scope='col'>{performanceEmployerTranslate.employerCriterionCollumTitle}</th>
																			<th scope='col'>{performanceEmployerTranslate.employerWeigthCollumTitle}</th>
																			<th scope='col'>{performanceEmployerTranslate.employerExecutedCollumTitle}</th>
																			<th scope='col'>
																				<button className='add-remove-button' onClick={handleAdd}>
																					<AiOutlinePlusCircle size={25} />
																				</button>
																			</th>
																		</tr>
																	</thead>
																	<tbody className='border-top-0'>
																		{criterios.map((item, index) => (
																			<TableRows key={index} data={item} index={index} deleteTableRows={deleteTableRows} handleChange={handleChange} />
																		))}
																	</tbody>
																</table>
																{criterios[criterios.length - 1].name === '' && (
																	<div className='row'>
																		<div className='col-12'>
																			<h6 className='fw-bolder'>{performanceEmployerTranslate.employeeSugests}</h6>
																			<div className='rounded-3 p-3 d-flex flex-wrap'>
																				{suggestions.map((sug, key) => {
																					return (
																						<div key={key} className='CritSuggestions px-3 rounded-pill my-1 mx-2' onClick={() => handleSuggest(sug)}>
																							{sug}
																						</div>
																					)
																				})}
																			</div>
																		</div>
																	</div>
																)}

																<div className='row pt-3'>
																	<h6 className='fw-bolder'>{performanceEmployerTranslate.employeeObservations}:</h6>
																	<div className='col'>
																		<textarea name='observation' value={medias?.observation || ''} onChange={e => handleChangeObs(e.target.value)} rows={4} className={`w-100 borderPurple`} />
																	</div>
																	<div className='col-auto'>
																		<button className='btn ms-auto btn-salvar' onClick={handleSend}>
																			{performanceEmployerTranslate.saveButton}
																		</button>
																	</div>
																</div>
															</>
														)}
													</div>
												</div>
											</div>
										)
									})
								) : (
									<div className='text-center'>
										<h5>
											Todavía no has registrado los colaboradores de tu empresa.{' '}
											<Link to='/app/services/add' target={'_blank'}>
												Haz clic aquí para registrar.
											</Link>
										</h5>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default DesempenoEmployer

