import { useState, useEffect, useRef } from "react";
import {
	HiMiniTrash,
	HiMiniPencilSquare,
	HiMiniEllipsisHorizontal,
	HiUserGroup,
	HiPresentationChartLine,
} from "react-icons/hi2";

import { DeleteBandModal } from "../DeleteBand";
import { CollaboratorBandModal } from "../CollaboratorBand";

import "./menuBands.sass";
import { salaryRangeTranslate } from "../../../../../../i18n/translatedMessages";

export const MenuBands = ({ item, refresh, country, band }) => {
	const [show, setShow] = useState(false);
	const [screenId, setScreenId] = useState(0);

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showCollaboratorModal, setShowCollaboratorModal] = useState(false);

	const handleCloseDeleteModal = () => setShowDeleteModal(false);
	const handleShowDeleteModal = () => {
		setShow(false);
		setShowDeleteModal(true);
	};

	const handleShowEditModal = () => {
		setShow(false);
		setShowCollaboratorModal(true);
		setScreenId(0);
	};

	const handleCloseCollaboratorModal = () => setShowCollaboratorModal(false);

	const handleShowCollaboratorModal = () => {
		setShow(false);
		setShowCollaboratorModal(true);
		setScreenId(1);
	};

	const handleShowChartModal = () => {
		setShow(false);
		setShowCollaboratorModal(true);
		setScreenId(2);
	};

	const componentRef = useRef();

	useEffect(() => {
		const handleClickOut = (event) => {
			if (
				componentRef.current &&
				!componentRef.current.contains(event.target)
			) {
				setShow(false);
			}
		};

		window.addEventListener("click", handleClickOut);

		return () => {
			window.removeEventListener("click", handleClickOut);
		};
	}, []);

	const handleShow = () => {
		setShow(!show);
	};

	return (
		<>
			<CollaboratorBandModal
				handleClose={handleCloseCollaboratorModal}
				show={showCollaboratorModal}
				id={item}
				refresh={refresh}
				country={country}
				band={band}
				screenId={screenId}
			/>
			<DeleteBandModal
				handleClose={handleCloseDeleteModal}
				show={showDeleteModal}
				id={item}
				refresh={refresh}
			/>
			<div className="d-flex justify-content-end" ref={componentRef}>
				<button className="btn btn-white" onClick={handleShow}>
					<HiMiniEllipsisHorizontal size={20} />
				</button>
				{show && (
					<div className="d-flex flex-column rounded-3 m-0 py-1 px-0 border-grey menu-bands">
						<button
							className="btn btn-white shadow-none text-start"
							onClick={handleShowEditModal}
						>
							<HiMiniPencilSquare className="me-3" />
							Editar Banda
						</button>
						<button
							className="btn btn-white shadow-none text-start"
							onClick={handleShowCollaboratorModal}
						>
							<HiUserGroup className="me-3" />
							Editar Colaboradores
						</button>
						<button
							className="btn btn-white shadow-none text-start"
							onClick={handleShowDeleteModal}
						>
							<HiMiniTrash className="me-3" />
							Excluir Banda
						</button>
						<button
							className="btn btn-white shadow-none text-start"
							onClick={handleShowChartModal}
						>
							<HiPresentationChartLine className="me-3" />
							Ver Gráfico
						</button>
					</div>
				)}
			</div>
		</>
	);
};
