// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box-no-information {
  width: 50vw;
  height: 150px;
  background: #fafafa;
  border: 1px solid #efefef;
  border-radius: 5px;
  margin: 25px auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #9a9a9a;
  font-weight: 500;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  text-transform: uppercase;
}
.box-no-information svg {
  opacity: 0.5;
  right: 20px;
  width: 100px;
}`, "",{"version":3,"sources":["webpack://./src/Views/Analytics/Components/EmptyInformation/emptyInformation.sass"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,kBAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,6BAAA;EACA,cAAA;EACA,gBAAA;EACA,yCAAA;EACA,gBAAA;EACA,yBAAA;AACJ;AACI;EACI,YAAA;EACA,WAAA;EACA,YAAA;AACR","sourcesContent":[".box-no-information\n    width: 50vw\n    height: 150px\n    background: #fafafa\n    border: 1px solid #efefef\n    border-radius: 5px\n    margin: 25px auto\n    display: flex\n    align-items: center\n    justify-content: space-around\n    color: #9a9a9a\n    font-weight: 500\n    box-shadow: 0 1px 3px rgba(0,0,0,.25)\n    overflow: hidden\n    text-transform: uppercase\n\n    & svg\n        opacity: .5\n        right: 20px\n        width: 100px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
