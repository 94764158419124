import { useState, useEffect } from 'react'
import { AiOutlineCheckCircle, AiOutlineCloseCircle, AiOutlineEdit } from 'react-icons/ai'
import ModalAssign from './ModalAssign'
import ModalConfirmDel from './ModalConfirmDel'
import { deleteRecruitment, getRecruitmentByCompanyAndTitle, postRecruitment, putRecruitment } from '../../../../../API/Services/Recruitment/Recruitment'
import { getEmployeesByCompany, getEmployee } from '../../../../../API/Employee/Employee'
import { getOutplacementByCompany } from '../../../../../API/Services/Outplacement/Outplacement'
import { onboardingTranslate, outplacementTranslate } from '../../../../../i18n/translatedMessages'
import { getOnboardingByCompany } from '../../../../../API/Services/Onboarding/Onboarding'
import 'bootstrap'

import './AssignScreenComponent.sass'
import { useIntl } from 'react-intl'
import ModalAssignScreen from './ModalAssignScreen'

const AssignScreen = ({ title }) => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [modalData, setModalData] = useState({})

	const language = useIntl()
	const excited = 'https://assets.mydna.company/b2b-images/animo_Excited.png'
	const neutral = 'https://assets.mydna.company/b2b-images/animo_Neutral.png'
	const sad = 'https://assets.mydna.company/b2b-images/animo_Sad.png'
	const [user, setUser] = useState()

	const [showModal, setShowModal] = useState(false)
	const [showModalResp, setShowModalResp] = useState(false)

	const [employeeList, setEmployeeList] = useState([])
	const [busca, setBusca] = useState('')

	const [respAssign, setRespAssign] = useState([])
	const [allRespAssign, setAllRespAssign] = useState([])

	const [employeesAssigned, setEmployeesAssigned] = useState([])
	const [allAssigned, setAllAssigned] = useState([])
	const [deleteVal, setDeleteVal] = useState({
		clickDel: false,
		id: '',
	})
	const [isLoadingNew, setIsLoadingNew] = useState(false)
	const [loadModalData, setLoadModalData] = useState(false)

	const [dataRequesterId, setDataRequesterId] = useState([])
	const [currentRequesterId, setCurrentRequesterId] = useState(null)

	const [outplacementList, setOutplacementList] = useState([])
	const [onboardingList, setOnboardingList] = useState([])

	const lowerBusca = busca?.toLowerCase()

	const employeesListFilt = employeeList.filter(employee => {
		return employee?.name?.toLowerCase().includes(lowerBusca)
	})

	const handleOpenModal = assigned => {
		// Aquí puedes configurar los datos del modal según necesites
		// Por ejemplo, puedes pasar todo el objeto 'assigned' o solo ciertas propiedades
		setModalData(
			assigned[0]
			//   {
			//   // Asumiendo que 'assigned' es un objeto con las propiedades que deseas mostrar
			//   ...assigned,
			//   // name: assigned.recruitmentEmployer.name,
			//   // profileArea: assigned.recruitmentEmployer.employerAreaCorrelation.profileArea,
			//   // ... otras propiedades que necesitas pasar al modal
			// }
		)
		setIsModalOpen(true)
	}
	const handleCloseModal = () => {
		setIsModalOpen(false)
	}
	const [titleForRequests] = useState(
		title[0] === 'Test de Competencias'
			? 'skills_test'
			: title[0] === 'Teste de Habilidades'
			? 'skills_test'
			: title[0] === 'Test de Motivaciones'
			? 'motivation_test'
			: title[0] === 'Teste de Motivações'
			? 'motivation_test'
			: title[0] === 'Teste Nível de Inglês'
			? 'english_test'
			: title[0] === 'Test de Nivel de Inglés'
			? 'english_test'
			: title[0] === 'Clases de Inglés'
			? 'english_classes'
			: title[0] === 'Classes de Inglês'
			? 'english_classes'
			: title[0] === 'Herramienta de Entrenamiento Gamificado'
			? 'gamified_tool'
			: title[0] === 'Ferramenta de Treinamento Gamificada'
			? 'gamified_tool'
			: title === 'Onboarding'
			? 'onboarding'
			: title === 'Outplacement'
			? 'outplacement'
			: title[0]
	)

	const userById = async respAux => {
		if (respAux) {
			const getRequesterId = await getEmployee(respAux)
			setDataRequesterId(getRequesterId)
		}
	}

	const getRequests = async () => {
		setIsLoadingNew(true)
		const users = JSON.parse(localStorage.getItem('user'))
		const employeesAux = await getEmployeesByCompany(users.id)
		setEmployeeList(employeesAux)

		const onboardingListAux = await getOnboardingByCompany(users.id)
		if (onboardingListAux) {
			if (employeesAux) {
				const onbMap = onboardingListAux.map(onb => {
					const [empFilter] = employeesAux.filter(emp => onb.responsibleId === emp.id)
					return { ...onb, ...empFilter }
				})

				setOnboardingList(onbMap)
			}
		}

		const outplacementListAux = await getOutplacementByCompany(users.id)
		setOutplacementList(outplacementListAux)
		if (title !== undefined) {
			const newEmployers = await getRecruitmentByCompanyAndTitle(users.id, titleForRequests?.toLowerCase())
			setAllAssigned(newEmployers)
			setIsLoadingNew(false)
		}
		setIsLoadingNew(false)
	}

	const handleGetRequests = async () => {
		if (title) {
			setIsLoadingNew(true)
			const newEmployers = await getRecruitmentByCompanyAndTitle(user.id, titleForRequests?.toLowerCase())
			setAllAssigned(newEmployers)
		}
	}

	const handleDeleteRequests = async id => {
		await deleteRecruitment(id)
	}

	const handleAssignEmployee = async () => {
		if (title) {
			setIsLoadingNew(true)
			const auxRecOnbByCompany = await getRecruitmentByCompanyAndTitle(user.id, titleForRequests?.toLowerCase())
			setAllAssigned(auxRecOnbByCompany)
		}
	}

	const handleValidation = () => {
		const auxAll = allAssigned.map(allId => allId.recruitmentEmployer.id)
		setEmployeesAssigned([...auxAll])
	}

	const handleShow = data => {
		setLoadModalData(true)
		handleValidation()
		setShowModal(true)
	}
	const handleClose = () => {
		setShowModal(false)
	}
	const handleShowResp = () => {
		setLoadModalData(true)
		setShowModalResp(true)
	}
	const handleCloseResp = () => {
		setShowModalResp(false)
	}
	const handleShowDeleteValidation = assignId => {
		setDeleteVal({ clickDel: true, id: assignId })
	}
	const handleCloseDeleteValidation = () => {
		setDeleteVal({ clickDel: false, id: '' })
	}

	const handleChangeBusca = e => {
		setBusca(e.target.value)
	}

	const handleAssignResp = () => {
		const empListAux = [...employeeList]
		const newEmployers = []
		respAssign.map(empAssigned => {
			const [assigneds] = empListAux.filter(emp => {
				if (parseInt(empAssigned) === parseInt(emp.id)) {
					setCurrentRequesterId(emp.id)
				}
				return parseInt(empAssigned) === parseInt(emp.id)
			})
			const newData = {
				assign: empAssigned,
				id: assigneds.id,
				name: assigneds.name,
				profileImg: assigneds.asset,
				area: assigneds.area?.profileArea,
			}
			return newEmployers.push(newData)
		})
		setAllRespAssign(newEmployers)
	}

	const handleChangeAssign = (e, index) => {
		const listAux = [...employeesAssigned]
		if (listAux.includes(e.currentTarget.value)) {
			const auxEmployeeAssign = listAux.filter(lista => lista !== e.currentTarget.value)
			setEmployeesAssigned(auxEmployeeAssign)
		} else {
			listAux.push(e.currentTarget.value)
			setEmployeesAssigned([...listAux])
		}
	}

	const handleChangeRespAssign = async e => {
		const respAux = [e.currentTarget.value]
		setRespAssign(respAux)
		if (allAssigned?.length > 0) {
			const dataRecruitment = {
				newRequesterId: e.currentTarget.value,
				requesterId: allAssigned[0].requesterId,
			}
			await putRecruitment(dataRecruitment, user.id)
		}
	}

	const handleSendRequests = async () => {
		setIsLoadingNew(true)
		const auxAll = allAssigned.map(allId => Number(allId.employerId))
		const auxEmployeeAssign = employeesAssigned.filter(filt => filt !== undefined && auxAll.indexOf(Number(filt)) < 0)

		const auxSend = {
			employerList: auxEmployeeAssign,
			category: titleForRequests?.toLowerCase(),
			status: language.locale === 'ES' ? 'En Progreso' : 'Em Progresso',
			requesterId: respAssign[0],
			companyId: user.id,
		}
		const resp = await postRecruitment(auxSend)
		if (resp.status) {
			setIsLoadingNew(false)
		}
	}

	const handleApply = async () => {
		await handleSendRequests()
		await handleGetRequests()
		handleAssignEmployee()
		setShowModal(false)
		setIsLoadingNew(false)
	}

	const handleDelete = async id => {
		await handleDeleteRequests(id)
		await handleGetRequests()
		setDeleteVal({ clickDel: false, id: '' })
		setIsLoadingNew(false)
	}

	useEffect(() => {
		handleAssignResp()
	}, [respAssign])

	useEffect(() => {
		setUser(JSON.parse(localStorage.getItem('user')))
		getRequests()
	}, [])

	useEffect(() => {
		if (allAssigned?.length > 0 && allAssigned[0].requesterId != null) {
			userById(allAssigned[0].requesterId)
		}
	}, [allAssigned])

	return (
		<div className='container-fluid p-5 assinged-screen-main'>
			<div className='row d-flex justify-content-center'>
				<div className='col-12'>
					{isLoadingNew && (
						<>
							<div className='blockui'>
								<div className='custom-container-spinner'>
									<div className='lds-dual-ring'></div>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
			<div className='row p-3 justify-content-between border-blue shadow'>
				<div className='col-md-4'>
					<h2 className=''>{title}</h2>
					{allAssigned?.length > 0 ? (
						<p>
							{onboardingTranslate.assignStatus1} <strong>{allAssigned?.length}</strong> {onboardingTranslate.assignStatus2}
						</p>
					) : (
						<p>{onboardingTranslate.collEmpty}</p>
					)}
				</div>
				<div className='col-md-4'>
					<h4 className=''>{onboardingTranslate.responsable}</h4>
					<div className='row align-items-center'>
						<ModalAssign
							toShow={showModalResp}
							toClose={handleCloseResp}
							employeeList={employeesListFilt}
							busca={busca}
							handleChangeBusca={handleChangeBusca}
							checkedValidationResp={allRespAssign}
							handleChangeRespAssign={handleChangeRespAssign}
							assignType='Responsable'
							title={onboardingTranslate.professionalResponsableTitle}
							isLoading={showModal}
						/>
						{(allRespAssign?.length > 0 && dataRequesterId.id === undefined) || (allRespAssign?.length > 0 && dataRequesterId.id) ? (
							<>
								<div className='col-2'>
									<img className='img-fluid rounded-circle' alt='...' src={allRespAssign[0].profileImg ? allRespAssign[0].profileImg : 'https://assets.mydna.company/b2b-images/profileImg.png'} />
								</div>
								<div className='col-8'>
									<h5>{allRespAssign[0].name}</h5>
									<p>{allRespAssign[0].area}</p>
								</div>
								<div className='col-2 text-center'>
									<button onClick={handleShowResp} className='btn-assign-edit btn pb-2 px-2 m-0 shadow rounded-circle'>
										<AiOutlineEdit size={20} />
									</button>
								</div>
							</>
						) : allRespAssign?.length === 0 && dataRequesterId.id ? (
							<>
								<div className='col-2'>
									<img className='img-fluid rounded-circle' alt='...' src={dataRequesterId.profileImg ? dataRequesterId.profileImg : 'https://assets.mydna.company/b2b-images/profileImg.png'} />
								</div>
								<div className='col-8'>
									<h5>{dataRequesterId.name}</h5>
									<p>{dataRequesterId.area?.profileArea}</p>
								</div>
								<div className='col-2 text-center'>
									<button onClick={handleShowResp} className='btn-assign-edit btn pb-2 px-2 m-0 shadow rounded-circle'>
										<AiOutlineEdit size={20} />
									</button>
								</div>
							</>
						) : (
							<>
								<div className='col-12'>
									<button onClick={handleShowResp} className='btn rounded shadow mt-3 btn-assign-nuevo-colaborador' disabled={allAssigned?.length === 0}>
										{onboardingTranslate.registerResponsable}
									</button>
								</div>
							</>
						)}
					</div>
				</div>
			</div>

			<div className='container'>
				<div className='row justify-content-end'>
					<div className='col-4 py-3 text-end px-0'>
						<button onClick={handleShow} className='btn rounded shadow btn-assign-nuevo-colaborador'>
							{onboardingTranslate.registerCollaborator}
						</button>
						<ModalAssign
							toShow={showModal}
							toClose={handleClose}
							employeeList={employeesListFilt}
							busca={busca}
							handleChangeBusca={handleChangeBusca}
							handleChangeAssign={handleChangeAssign}
							checkedValidation={employeesAssigned}
							assignType='Colaborador'
							title={`Registrar Colaborador para ${title}`}
							handleApply={handleApply}
							isLoading={showModal}
						/>
					</div>
				</div>
				{title === 'Outplacement' ? (
					<>
						<div className='accordion StatusOutplacementAssignScreen-main' id='accordionExample'>
							{allAssigned?.map((assigned, key) => {
								const verifyStatus = outplacementList.filter(outFilt => assigned.recruitmentEmployer?.id === outFilt.employerToExitPlan?.id)
								return (
									<div
										key={key}
										className='accordion-item my-3 shadow'
										onClick={() => {
											if (verifyStatus.length > 0) {
												handleOpenModal(verifyStatus)
											}
										}}
									>
										<button className='accordion-button collapsed' type='button'>
											<div className='container-fluid'>
												<div className='row align-items-center'>
													<ModalConfirmDel toShow={deleteVal.clickDel} toClose={handleCloseDeleteValidation} handleDelete={() => handleDelete(deleteVal.id)} />
													<div className='col-1 p-3 text-center'>
														<img
															className='img-fluid rounded-circle'
															alt='...'
															src={assigned.recruitmentEmployer.profileImg ? assigned.recruitmentEmployer.profileImg : 'https://assets.mydna.company/b2b-images/profileImg.png'}
														/>
													</div>
													<div className='col-md-7'>
														<h5>{assigned.recruitmentEmployer?.name}</h5>
														<p>{assigned.recruitmentEmployer?.employerAreaCorrelation?.profileArea}</p>
													</div>
													<div className='col-md-4'>
														<div className='row align-items-center'>
															<h5>Status {title}</h5>
															<div className='col-8 d-flex align-items-center'>
																<div className={`rounded-circle circle-blue d-inline-block ${verifyStatus?.length === 0 && `opacity-50`}`}></div>
																<div className='ps-2 d-inline-block'>{verifyStatus?.length > 0 ? 'Ver status' : language.locale == 'ES' ? 'Todavía no hay status' : 'Nenhum status ainda'}</div>
															</div>
															<div className='col-4 text-center'>
																<div
																	onClick={event => {
																		event.stopPropagation()
																		handleShowDeleteValidation(assigned.id)
																	}}
																	className='btn assign-screen-btn-delete shadow rounded-circle p-1'
																>
																	<AiOutlineCloseCircle size={30} />
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</button>

										<div id={'collapse' + key} className='accordion-collapse collapse' aria-labelledby={'heading' + key} data-bs-parent='#accordionExample'>
											{outplacementList
												.filter(outFilt => assigned.recruitmentEmployer?.id === outFilt.employerToExitPlan?.id)
												.map((outList, index) => {
													const date = new Date()
													return (
														<div key={index} className='accordion-body'>
															<div className='outplacement-answers'>
																<div className='row'>
																	<div className='col-md-4'>
																		<div className='outplacement-card'>
																			<h6>{outplacementTranslate.outplacementQuestionOne}</h6>
																			{outList.foundJob ? (
																				<>
																					<AiOutlineCheckCircle color='#BCE165' size={20} /> {outplacementTranslate.outplacementAnswerYes}
																				</>
																			) : (
																				<>
																					<AiOutlineCloseCircle color='#EC6376' size={20} /> {outplacementTranslate.outplacementAnswerNo}
																				</>
																			)}
																		</div>
																	</div>
																	<div className={outList.foundJob ? 'd-none' : 'col-md-4'}>
																		<div className='outplacement-card'>
																			<h6>{outplacementTranslate.outplacementQuestionTwo}</h6>
																			<img alt='...' className='img-fluid outplacement-mood' src={outList.mood === 'Sad' ? sad : outList.mood === 'Normal' ? neutral : outList.mood === 'Excited' ? excited : ''} />
																		</div>
																	</div>
																</div>
																<div className={outList.foundJob ? 'd-none' : 'row'}>
																	<div className='col-md-5'>
																		<div className='outplacement-card'>
																			<h6>{outplacementTranslate.outplacementQuestionThree}</h6>
																			{outList.applicationsMade}
																		</div>
																	</div>
																	<div className='col-md-4'>
																		<div className='outplacement-card'>
																			<h6>{outplacementTranslate.outplacementQuestionFour}</h6>
																			{outList.numberInterviews > 0 ? (
																				<>
																					{outplacementTranslate.outplacementAnswerYes}, {outList.numberInterviews} {outplacementTranslate.outplacementAnswerOne}
																				</>
																			) : (
																				<>{outplacementTranslate.outplacementAnswerTwo}</>
																			)}
																		</div>
																	</div>
																</div>
																<div className={outList.foundJob ? 'd-none' : 'row'}>
																	<div className='col-md-8'>
																		<div className='outplacement-card'>
																			<h6>{outplacementTranslate.outplacementQuestionFive}</h6>
																			{outplacementTranslate.outplacementAnswerThree}{' '}
																			{parseFloat(outList.newJobTimeExpectation.substr(0, 4) - parseFloat(date.getFullYear())) * 12 +
																				(parseFloat(outList.newJobTimeExpectation.substr(5, 2)) - parseFloat(date.getMonth() + 1))}{' '}
																			{outplacementTranslate.outplacementAnswerFour}
																		</div>
																	</div>
																</div>
																<div className='row'>
																	<div className='col-md-6'>
																		<div className='outplacement-card'>
																			<h6>{outList.foundJob ? outplacementTranslate.outplacementQuestionSixPos : outplacementTranslate.outplacementQuestionSix}</h6>
																			{outList.appHasHelped ? (
																				<>
																					<AiOutlineCheckCircle color='#BCE165' size={20} /> {outplacementTranslate.outplacementAnswerYes}
																				</>
																			) : (
																				<>
																					<AiOutlineCloseCircle color='#EC6376' size={20} /> {outplacementTranslate.outplacementAnswerNo}
																				</>
																			)}
																		</div>
																	</div>
																</div>
																<div className='row'>
																	<div className='col-md-4'>
																		<div className='outplacement-card'>
																			<h6>{outplacementTranslate.outplacementQuestionSeven}</h6>
																			{outList.progressInProcess} %
																		</div>
																	</div>
																</div>
																{outList.observation && (
																	<div className='row'>
																		<div className='col-md-4'>
																			<div className='outplacement-card'>
																				<h6>{outplacementTranslate.outplacementOservation}</h6>
																				{outList.observation}
																			</div>
																		</div>
																	</div>
																)}
															</div>
														</div>
													)
												})}
										</div>
									</div>
								)
							})}
						</div>
						<ModalAssignScreen isOpen={isModalOpen} onClose={handleCloseModal} data={modalData} />
					</>
				) : title === 'Onboarding' ? (
					<>
						<div className='accordion StatusOnboardingAssignScreen-main' id='accordionExample'>
							{allAssigned?.map((assigned, key) => {
								const verifyStatus = onboardingList?.filter(onbFilt => assigned.recruitmentEmployer?.id === onbFilt.responsibleId)
								return (
									<div key={key} className='accordion-item my-3 shadow'>
										<button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target={'#collapse' + key} aria-expanded='false' aria-controls={'collapse' + key}>
											<div className='container-fluid'>
												<div className='row align-items-center'>
													<ModalConfirmDel toShow={deleteVal.clickDel} toClose={handleCloseDeleteValidation} handleDelete={() => handleDelete(deleteVal.id)} />
													<div className='col-1 p-3 text-center'>
														<img
															className='img-fluid rounded-circle'
															alt='...'
															src={assigned.recruitmentEmployer.profileImg ? assigned.recruitmentEmployer.profileImg : 'https://assets.mydna.company/b2b-images/profileImg.png'}
														/>
													</div>
													<div className='col-md-7'>
														<h5>{assigned.recruitmentEmployer?.name}</h5>
														<p>{assigned.recruitmentEmployer?.employerAreaCorrelation?.profileArea}</p>
													</div>
													<div className='col-md-4'>
														<div className='row align-items-center'>
															<h5>Status {title}</h5>
															<div className='col-8 d-flex align-items-center'>
																<div className={`rounded-circle circle-blue d-inline-block ${verifyStatus?.length === 0 && `opacity-50`}`}></div>
																<div className='ps-2 d-inline-block'>{verifyStatus?.length > 0 ? 'Ver status' : language.locale == 'ES' ? 'Todavía no hay status' : 'Nenhum status ainda'}</div>
															</div>
															<div className='col-4 text-center'>
																<div
																	onClick={() => {
																		handleShowDeleteValidation(assigned.id)
																	}}
																	className='btn assign-screen-btn-delete shadow rounded-circle p-1'
																>
																	<AiOutlineCloseCircle size={30} />
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</button>

										<div id={'collapse' + key} className='accordion-collapse collapse' aria-labelledby={'heading' + key} data-bs-parent='#accordionExample'>
											{onboardingList
												?.filter(onFilt => assigned.recruitmentEmployer?.id === onFilt.responsibleId)
												.map((onbList, index) => {
													const date = new Date()
													return (
														<div key={index} className='accordion-body'>
															<div className='outplacement-answers'>
																<div className='row'>
																	<div className='col-md-4'>
																		<div className='outplacement-card'>
																			<h6>{onboardingTranslate.statusOnboardingQuestion1}</h6>
																			{onbList.question1 ? (
																				<>
																					<AiOutlineCheckCircle color='#BCE165' size={20} /> {onboardingTranslate.statusOnboardingAnswerYes}
																				</>
																			) : (
																				<>
																					<AiOutlineCloseCircle color='#EC6376' size={20} /> {onboardingTranslate.statusOnboardingAnswerNo}
																				</>
																			)}
																		</div>
																	</div>
																	<div className={onbList.foundJob ? 'd-none' : 'col-md-4'}>
																		<div className='outplacement-card'>
																			<h6>{onboardingTranslate.statusOnboardingQuestion2}</h6>
																			<img alt='...' className='img-fluid outplacement-mood' src={onbList.question2 === 0 ? sad : onbList.question2 === 1 ? neutral : onbList.question2 === 2 ? excited : ''} />
																		</div>
																	</div>
																</div>
																<div className={onbList.foundJob ? 'd-none' : 'row'}>
																	<div className='col-md-5'>
																		<div className='outplacement-card'>
																			<h6>{onboardingTranslate.statusOnboardingQuestion3}</h6>
																			{onbList.question3}
																		</div>
																	</div>
																	<div className='col-md-4'>
																		<div className='outplacement-card'>
																			<h6>{onboardingTranslate.statusOnboardingQuestion4}</h6>
																			{onbList.question4 < 1 ? <>{onboardingTranslate.statusOnboardingAnswerYes}</> : <>{onboardingTranslate.statusOnboardingAnswerNo}</>}
																		</div>
																	</div>
																</div>
																<div className={onbList.foundJob ? 'd-none' : 'row'}>
																	<div className='col-md-8'>
																		<div className='outplacement-card'>
																			<h6>{onboardingTranslate.statusOnboardingQuestion5}</h6>
																			En {parseFloat(onbList.question5?.substr(0, 4) - parseFloat(date.getFullYear())) * 12 + (parseFloat(onbList.question5?.substr(5, 2)) - parseFloat(date.getMonth() + 1))}{' '}
																			mes(es)
																		</div>
																	</div>
																</div>
																<div className='row'>
																	<div className='col-md-6'>
																		<div className='outplacement-card'>
																			<h6>{onboardingTranslate.statusOnboardingQuestion6}</h6>
																			{onbList.question6 ? (
																				<>
																					<AiOutlineCheckCircle color='#BCE165' size={20} /> {onboardingTranslate.statusOnboardingAnswerYes}
																				</>
																			) : (
																				<>
																					<AiOutlineCloseCircle color='#EC6376' size={20} /> {onboardingTranslate.statusOnboardingAnswerNo}
																				</>
																			)}
																		</div>
																	</div>
																</div>
																<div className='row'>
																	<div className='col-md-4'>
																		<div className='outplacement-card'>
																			<h6>{onboardingTranslate.statusOnboardingQuestion7}</h6>
																			{onbList.question7} %
																		</div>
																	</div>
																</div>
																{onbList.observation && (
																	<div className='row'>
																		<div className='col-md-4'>
																			<div className='outplacement-card'>
																				<h6>{outplacementTranslate.outplacementOservation}</h6>
																				{onbList.observation}
																			</div>
																		</div>
																	</div>
																)}
															</div>
														</div>
													)
												})}
										</div>
									</div>
								)
							})}
						</div>
					</>
				) : (
					<>
						{allAssigned?.map((assigned, key) => {
							return (
								<div key={key} className='row p-3 my-2 border-purple justify-content-between align-items-center shadow'>
									<ModalConfirmDel toShow={deleteVal.clickDel} toClose={handleCloseDeleteValidation} handleDelete={() => handleDelete(deleteVal.id)} />
									<div className='col-1 p-3 text-center'>
										<img className='img-fluid rounded-circle' alt='...' src={assigned.recruitmentEmployer.profileImg ? assigned.recruitmentEmployer.profileImg : 'https://assets.mydna.company/b2b-images/profileImg.png'} />
									</div>
									<div className='col-md-7'>
										<h5>{assigned.recruitmentEmployer?.name}</h5>
										<p>{assigned.recruitmentEmployer?.employerAreaCorrelation?.profileArea}</p>
									</div>
									<div className='col-md-4'>
										<div className='row align-items-center'>
											<h5>Status {title}</h5>
											<div className='col-8 d-flex align-items-center'>
												<div className='rounded-circle circle-blue d-inline-block'></div>
												<div className='ps-2 d-inline-block'>{language.locale == 'ES' ? 'En Progreso' : 'Em Progresso'}</div>
											</div>
											<div className='col-4 text-center'>
												<button onClick={() => handleShowDeleteValidation(assigned.id)} className='btn assign-screen-btn-delete shadow rounded-circle p-1'>
													<AiOutlineCloseCircle size={30} />
												</button>
											</div>
										</div>
									</div>
								</div>
							)
						})}
					</>
				)}
			</div>
		</div>
	)
}

export default AssignScreen
