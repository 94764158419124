
export const performanceIndicators = [
	{
		id: 1, area: { pt: 'Marketing', es: 'Marketing' }, subitems: [
			{ id: 1, title: { pt: 'ROI', es: 'ROI' }, content: { pt: 'É a medida de retorno financeiro gerado em relação ao custo de um investimento. É calculado como (Ganho do Investimento - Custo do Investimento) / Custo do Investimento.', es: 'Es la medida del retorno financiero generado en relación al costo de una inversión. Se calcula como (Ganancia de la inversión - Costo de la inversión) / Costo de la inversión.' } },
			{ id: 2, title: { pt: 'Taxa de Conversão', es: 'Tasa de Conversión' }, content: { pt: 'Refere-se à porcentagem de visitantes que realizam uma ação desejada, como fazer uma compra, preencher um formulário, etc.', es: 'Esto se refiere al porcentaje de visitantes que realizan una acción deseada, como realizar una compra, completar un formulario, etc.' } },
			{ id: 3, title: { pt: 'Alcance nas Redes ', es: 'Alcance en Redes' }, content: { pt: 'Indica quantas pessoas viram suas postagens ou conteúdo nas redes sociais.', es: 'Indica cuántas personas vieron tus publicaciones o contenido en las redes sociales.' } },
			{ id: 4, title: { pt: 'Engajamento nas Redes', es: 'Engagement en Redes ' }, content: { pt: 'Mede o envolvimento dos usuários com seu conteúdo nas redes sociais, incluindo curtidas, comentários, compartilhamentos, etc.', es: 'Mide la participación del usuario con su contenido en las redes sociales, incluidos los me gusta, los comentarios, las acciones compartidas, etc.' } },
			{ id: 5, title: { pt: 'Tráfego do Site', es: 'Tráfico del Sitio Web' }, content: { pt: 'Quantidade de visitantes que acessam seu site em um determinado período de tempo.', es: 'Número de visitantes que acceden a su sitio web en un período de tiempo determinado.' } },
			{ id: 6, title: { pt: 'Taxa de Retenção de Clientes', es: 'Tasa de Retención de Clientes' }, content: { pt: 'Percentual de clientes que continuam usando os serviços ou produtos da empresa ao longo do tempo.', es: 'Porcentaje de clientes que continúan utilizando los servicios o productos de la empresa a lo largo del tiempo.' } },
			{ id: 7, title: { pt: 'Custo por Lead', es: 'Costo por Lead' }, content: { pt: 'O custo médio para adquirir um lead (potencial cliente).', es: 'El costo promedio para adquirir un cliente potencial (cliente potencial).' } },
			{ id: 8, title: { pt: 'Taxa de Cliques (CTR)', es: 'Tasa de Clics (CTR)' }, content: { pt: 'Percentual de pessoas que clicam em um link, anúncio ou CTA (Call to Action) em relação ao número total de visualizações.', es: 'Porcentaje de personas que hacen clic en un enlace, anuncio o CTA (Call to Action) en relación al número total de visualizaciones.' } },
			{ id: 9, title: { pt: 'Taxa de Abandono de Carrinho', es: 'Tasa de Abandono del Carrito' }, content: { pt: 'Porcentagem de usuários que adicionam itens ao carrinho de compras online, mas não concluem a compra.', es: 'Porcentaje de usuarios que añaden artículos a su carrito de compra online pero no completan la compra.' } },
			{ id: 10, title: { pt: 'Taxa de Abertura de E-mails', es: 'Tasa de Apertura de Emails' }, content: { pt: 'Percentual de destinatários que abrem os emails enviados em uma campanha de marketing por email.', es: 'Porcentaje de destinatarios que abren correos electrónicos enviados en una campaña de email marketing.' } }
		]
	},
	{
		id: 2, area: { pt: 'Comercial e Vendas', es: 'Comercial y Ventas' }, subitems: [
			{ id: 1, title: { pt: 'Volume de Vendas', es: 'Volumen de Ventas' }, content: { pt: 'Valor total da receita gerada pela venda de produtos ou serviços em um determinado período de tempo.', es: 'Cantidad total de ingresos generados por la venta de productos o servicios en un período de tiempo determinado.' } },
			{ id: 2, title: { pt: 'Taxa de Fechamento', es: 'Tasa de Cierre' }, content: { pt: 'Porcentagem de oportunidades de vendas que se tornam vendas fechadas.', es: 'Porcentaje de oportunidades de venta que se convierten en ventas cerradas.' } },
			{ id: 3, title: { pt: 'Ciclo de Vendas', es: 'Ciclo de Ventas' }, content: { pt: 'Tempo médio que leva para concretizar uma venda, desde o primeiro contato com o potencial cliente até o fechamento da venda.', es: 'Tiempo promedio que tarda en completarse una venta, desde el primer contacto con el cliente potencial hasta el cierre de la venta.' } },
			{ id: 4, title: { pt: 'Valor Médio do Pedido (AOV)', es: 'Valor Medio del Pedido (AOV)' }, content: { pt: 'Receita média gerada por cada pedido feito pelos clientes.', es: 'Promedio de ingresos generados por cada pedido realizado por los clientes.' } },
			{ id: 5, title: { pt: 'Taxa de Churn', es: 'Tasa de Churn' }, content: { pt: 'Percentagem de clientes que deixam de utilizar os produtos ou serviços da empresa num determinado período de tempo.', es: 'Porcentaje de clientes que dejan de usar los productos o servicios de la empresa en un período de tiempo determinado.' } },
			{ id: 6, title: { pt: 'Número de Leads Gerados', es: 'Número de Leads Generados' }, content: { pt: 'Número de novos leads gerados em um período específico.', es: 'Cantidad de clientes potenciales nuevos que se generan en un período de tiempo específico.' } },
			{ id: 7, title: { pt: 'Taxa de Conversão de Leads', es: 'Tasa de Conversión de Leads' }, content: { pt: 'Porcentagem de leads (clientes potenciais) que se tornam clientes reais.', es: 'Porcentaje de leads (clientes potenciales) que se convierten en clientes reales.' } },
			{ id: 8, title: { pt: 'Receita Recorrente Mensal (MRR)', es: 'Ingresos Recurrentes Mensuales (MRR)' }, content: { pt: 'Renda previsível e recorrente gerada mensalmente pelos assinantes de um serviço ou produto.', es: 'Ingresos predecibles y recurrentes generados mensualmente por los suscriptores de un servicio o producto.' } },
			{ id: 9, title: { pt: 'Crescimento de Vendas', es: 'Crecimiento de Ventas' }, content: { pt: 'Aumento na receita de vendas em comparação com o período anterior.', es: 'Incremento en los ingresos de ventas en comparación con un período anterior.' } },
			{ id: 10, title: { pt: 'Taxa de Retenção de Clientes', es: 'Tasa de Retención de Clientes' }, content: { pt: 'Percentagem de clientes que continuam a utilizar os produtos ou serviços da empresa durante um determinado período de tempo.', es: 'Porcentaje de clientes que continúan utilizando los productos o servicios de la empresa durante un período de tiempo determinado.' } },
		]
	},
	{
		id: 3, area: { pt: 'RH', es: 'RRHH' }, subitems: [
			{ id: 1, title: { pt: 'Taxa de Retenção de Funcionários', es: 'Tasa de Retención de Empleados' }, content: { pt: 'Percentual de funcionários que permanecem na empresa por um determinado período de tempo.', es: 'Porcentaje de empleados que permanecen en la empresa durante un período de tiempo determinado.' } },
			{ id: 2, title: { pt: 'Tempo de Recrutamento', es: 'Tiempo de Contratación' }, content: { pt: 'Tempo médio de preenchimento de uma vaga desde sua publicação até a contratação do candidato.', es: 'Tiempo promedio que tarda en cubrirse una vacante desde que se publica hasta que se contrata al candidato.' } },
			{ id: 3, title: { pt: 'Índice de Satisfação dos Funcionários', es: 'Índice de Satisfacción de los Empleados' }, content: { pt: 'Uma medida de quão satisfeitos os funcionários estão com seus empregos e com o ambiente de trabalho.', es: 'Medida de cuán satisfechos están los empleados con su trabajo y el entorno laboral.' } },
			{ id: 4, title: { pt: 'Taxa de Absenteísmo', es: 'Tasa de Absentismo' }, content: { pt: 'Porcentagem de dias úteis em que os funcionários faltam ao trabalho.', es: 'Porcentaje de días laborables que los empleados están ausentes del trabajo.' } },
			{ id: 5, title: { pt: 'Taxa de Rotatividade', es: 'Tasa de Rotación' }, content: { pt: 'Percentual de funcionários que deixam a empresa em um determinado período de tempo.', es: 'Porcentaje de empleados que dejan la empresa en un período de tiempo determinado.' } },
			{ id: 6, title: { pt: 'Número de Treinamentos', es: 'Número de Capacitaciones ' }, content: { pt: 'Número total de treinamentos oferecidos aos colaboradores em um determinado período.', es: 'Número total de sesiones de capacitación ofrecidas a los empleados en un período de tiempo específico.' } },
			{ id: 7, title: { pt: 'Custo por Contratação', es: 'Costo por Contratación' }, content: { pt: 'Custo médio incorrido para contratar um novo funcionário, incluindo publicidade, entrevistas e treinamento inicial.', es: 'Costo promedio incurrido para contratar a un nuevo empleado, incluyendo publicidad, entrevistas y entrenamiento inicial.' } },
			{ id: 8, title: { pt: 'Taxa de Promoções Internas', es: 'Tasa de Promociones Internas' }, content: { pt: 'Percentual de funcionários promovidos para novos cargos na empresa.', es: 'Porcentaje de empleados promovidos a nuevas posiciones dentro de la empresa.' } },
			{ id: 9, title: { pt: 'Avaliação de Desempenho dos Funcionários', es: 'Evaluación del Desempeño de los Empleados' }, content: { pt: 'Avaliação da eficácia e eficiência do desempenho dos colaboradores em suas funções.', es: 'Evaluación de la efectividad y eficiencia del desempeño de los empleados en sus roles.' } },
			{ id: 10, title: { pt: 'Índice de Diversidade e Inclusão', es: 'Índice de Diversidad e Inclusión' }, content: { pt: 'Medição da representação e inclusão de diversas culturas, gêneros e origens na empresa.', es: 'Medida de la representación y la inclusión de diversas culturas, géneros y antecedentes en la empresa.' } },
		]
	},
	{
		id: 4, area: { pt: 'Financeiro', es: 'Financiero' }, subitems: [
			{ id: 1, title: { pt: 'Margem de Lucro', es: 'Margen de Ganancia' }, content: { pt: 'Diferença entre o preço de venda de um produto e o seu custo de produção, expresso em percentagem do preço de venda.', es: 'Diferencia entre el precio de venta de un producto y su costo de producción, expresado como un porcentaje del precio de venta.' } },
			{ id: 2, title: { pt: 'Fluxo de Caixa', es: 'Flujo de Caja' }, content: { pt: 'Uma medida do caixa que entra e sai de uma empresa durante um período específico.', es: 'Medida del efectivo que entra y sale de una empresa durante un período específico.' } },
			{ id: 3, title: { pt: 'Retorno sobre Ativos (ROA)', es: 'Retorno sobre Activos (ROA)' }, content: { pt: 'Indica a eficiência com que uma empresa gera lucros a partir de seus ativos totais.', es: 'Indica la eficiencia de una empresa para generar ganancias a partir de sus activos totales.' } },
			{ id: 4, title: { pt: 'Índice de Liquidez', es: 'Índice de Liquidez' }, content: { pt: 'A capacidade de uma empresa de cumprir suas obrigações de curto prazo utilizando seus ativos líquidos.', es: 'Capacidad de una empresa para cumplir con sus obligaciones a corto plazo utilizando sus activos líquidos.' } },
			{ id: 5, title: { pt: 'Crescimento da Receita', es: 'Crecimiento de los Ingresos' }, content: { pt: 'Aumento percentual na receita da empresa durante um determinado período.', es: 'Incremento porcentual en los ingresos de la empresa durante un período determinado.' } },
			{ id: 6, title: { pt: 'Dívida sobre Capital', es: 'Deuda sobre Capital' }, content: { pt: 'Relação entre o endividamento total de uma empresa e seu capital social, utilizada para avaliar sua alavancagem financeira.', es: 'Relación entre la deuda total de una empresa y su capital propio, utilizada para evaluar su apalancamiento financiero.' } },
			{ id: 7, title: { pt: 'Custo Operacional', es: 'Costo Operativo' }, content: { pt: 'Despesas necessárias ao funcionamento diário de uma empresa, excluindo custos de produção e vendas.', es: 'Gastos necesarios para la operación diaria de una empresa, excluyendo costos de producción y venta.' } },
			{ id: 8, title: { pt: 'Rentabilidade sobre o Patrimônio Líquido (ROE)', es: 'Rentabilidad sobre el Patrimonio (ROE)' }, content: { pt: 'Mede a rentabilidade que uma empresa gera com o dinheiro dos seus acionistas.', es: 'Mide la rentabilidad que una empresa genera con el dinero de sus accionistas.' } },
			{ id: 9, title: { pt: 'Índice de Endividamento', es: 'Índice de Endeudamiento' }, content: { pt: 'A relação entre a dívida total de uma empresa e seus ativos totais.', es: 'Proporción de la deuda total de una empresa en comparación con sus activos totales.' } },
			{ id: 10, title: { pt: 'Ciclo de Conversão de Caixa', es: 'Ciclo de Conversión de Efectivo' }, content: { pt: 'O tempo que uma empresa leva para converter seus estoques e outros investimentos em dinheiro.', es: 'Tiempo que tarda una empresa en convertir sus inventarios y otras inversiones en efectivo.' } },
		]
	},
	{
		id: 5, area: { pt: 'Operações e Manufatura', es: 'Operaciones y Manufactura' }, subitems: [
			{ id: 1, title: { pt: 'Eficiência Operacional', es: 'Eficiencia Operacional' }, content: { pt: 'A eficiência operacional se refere à capacidade de uma organização de utilizar seus recursos de forma eficaz para alcançar seus objetivos operacionais, minimizando desperdícios e maximizando a produção ou a prestação de serviços.', es: 'Es la medida del retorno financiero generado en relación al costo de una inversión. Se calcula como (Ganancia de la inversión - Costo de la inversión) / Costo de la inversión.' } },
			{ id: 2, title: { pt: 'Taxa de Defeitos', es: 'Tasa de Defectos' }, content: { pt: 'A taxa de defeitos indica a proporção de produtos ou serviços que não atendem aos padrões de qualidade estabelecidos, podendo impactar negativamente a satisfação do cliente e os custos operacionais.', es: 'Esto se refiere al porcentaje de visitantes que realizan una acción deseada, como realizar una compra, completar un formulario, etc.' } },
			{ id: 3, title: { pt: 'Custo por Unidade Produzida', es: 'Costo por Unidad Producida' }, content: { pt: 'O custo por unidade produzida representa o custo total incorrido para fabricar cada unidade de produto, incluindo materiais, mão de obra e custos indiretos.', es: 'Indica cuántas personas vieron tus publicaciones o contenido en las redes sociales.' } },
			{ id: 4, title: { pt: 'Tempo de Ciclo', es: 'Tiempo de Ciclo' }, content: { pt: 'O tempo de ciclo refere-se ao tempo necessário para completar uma atividade ou processo específico, desde o início até a conclusão, influenciando diretamente a capacidade de produção e a eficiência operacional.', es: 'Mide la participación del usuario con su contenido en las redes sociales, incluidos los me gusta, los comentarios, las acciones compartidas, etc.' } },
			{ id: 5, title: { pt: 'Utilização de Equipamentos', es: 'Utilización de Equipos' }, content: { pt: 'A utilização de equipamentos indica a proporção do tempo em que os equipamentos estão em operação e sendo utilizados de forma produtiva em relação ao tempo total disponível, afetando a capacidade de produção e custos de manutenção.', es: 'Número de visitantes que acceden a su sitio web en un período de tiempo determinado.' } },
			{ id: 6, title: { pt: 'Produtividade dos Trabalhadores', es: 'Productividad de los Trabajadores' }, content: { pt: 'A produtividade dos trabalhadores mede a eficiência com que os funcionários utilizam seus recursos e habilidades para realizar suas tarefas, impactando diretamente na produção e nos custos operacionais.', es: 'Porcentaje de clientes que continúan utilizando los servicios o productos de la empresa a lo largo del tiempo.' } },
			{ id: 7, title: { pt: 'Taxa de Paradas Não Programadas', es: 'Tasa de Paradas No Programadas' }, content: { pt: 'A taxa de paradas não programadas refere-se à frequência com que os equipamentos ou processos param inesperadamente devido a falhas ou problemas, afetando a produtividade e os custos de manutenção.', es: 'El costo promedio para adquirir un cliente potencial (cliente potencial).' } },
			{ id: 8, title: { pt: 'Qualidade do Produto', es: 'Calidad del Producto' }, content: { pt: 'A qualidade do produto representa o grau em que um produto atende aos requisitos e expectativas dos clientes, influenciando a satisfação do cliente e a reputação da empresa.', es: 'Porcentaje de personas que hacen clic en un enlace, anuncio o CTA (Call to Action) en relación al número total de visualizaciones.' } },
			{ id: 9, title: { pt: 'Custo de Manutenção', es: 'Costo de Mantenimiento' }, content: { pt: 'O custo de manutenção engloba todos os gastos relacionados à manutenção preventiva e corretiva de equipamentos e instalações, afetando diretamente os custos operacionais e a disponibilidade dos recursos.', es: 'Porcentaje de usuarios que añaden artículos a su carrito de compra online pero no completan la compra.' } },
			{ id: 10, title: { pt: 'Tempo de Configuração', es: 'Tiempo de Configuración' }, content: { pt: 'O tempo de configuração refere-se ao período necessário para ajustar e preparar equipamentos ou processos para a produção de diferentes produtos ou lotes, afetando a flexibilidade operacional e a eficiência da produção.', es: 'Porcentaje de destinatarios que abren correos electrónicos enviados en una campaña de email marketing.' } },
		]
	},
	{
		id: 6, area: { pt: 'Engenharia', es: 'Ingeniería' }, subitems: [
			{ id: 1, title: { pt: 'Tempo de Desenvolvimento', es: 'Tiempo de Desarrollo' }, content: { pt: 'O tempo de desenvolvimento refere-se ao período necessário para conceber, projetar e finalizar um produto ou projeto, impactando diretamente a rapidez com que novos produtos chegam ao mercado.', es: 'Cantidad total de ingresos generados por la venta de productos o servicios en un período de tiempo determinado.' } },
			{ id: 2, title: { pt: 'Conformidade com o Orçamento', es: 'Cumplimiento del Presupuesto' }, content: { pt: 'O cumprimento do orçamento indica a capacidade de um projeto ou atividade de se manter dentro dos limites financeiros estabelecidos, influenciando diretamente os custos totais e a viabilidade financeira do projeto.', es: 'Porcentaje de oportunidades de venta que se convierten en ventas cerradas.' } },
			{ id: 3, title: { pt: 'Qualidade do Produto', es: 'Calidad del Producto' }, content: { pt: 'A qualidade do produto representa o grau em que um produto atende aos requisitos e expectativas dos clientes, impactando diretamente a satisfação do cliente e a reputação da empresa.', es: 'Tiempo medio que se tarda en completar una venta, desde el primer contacto con el cliente potencial hasta el cierre de la venta.' } },
			{ id: 4, title: { pt: 'Inovações Introduzidas', es: 'Innovaciones Introducidas' }, content: { pt: 'As inovações introduzidas referem-se ao número e à natureza das novas ideias, tecnologias ou processos implementados, que podem impulsionar a competitividade e o crescimento da empresa no mercado.', es: 'Ingresos promedio generados por cada pedido realizado por los clientes.' } },
			{ id: 5, title: { pt: 'Satisfação do Cliente', es: 'Satisfacción del Cliente' }, content: { pt: 'A satisfação do cliente mede o grau de contentamento dos clientes com os produtos ou serviços oferecidos, influenciando diretamente a fidelidade do cliente e a reputação da empresa.', es: 'Porcentaje de clientes que dejan de utilizar los productos o servicios de la empresa en un periodo de tiempo determinado.' } },
			{ id: 6, title: { pt: 'Número de Patentes Registradas', es: 'Número de Patentes Registradas' }, content: { pt: 'O número de patentes registradas indica a quantidade de novas invenções ou tecnologias únicas protegidas legalmente pela empresa, refletindo seu potencial de inovação e diferenciação no mercado.', es: 'Número de nuevos leads generados en un período específico.' } },
			{ id: 7, title: { pt: 'Taxa de Sucesso de Projetos', es: 'Tasa de Éxito de Proyectos' }, content: { pt: 'A taxa de sucesso de projetos refere-se à proporção de projetos concluídos com sucesso em relação ao total de projetos iniciados, indicando a eficácia da gestão de projetos e a capacidade de entrega da empresa.', es: 'Porcentaje de leads (clientes potenciales) que se convierten en clientes reales.' } },
			{ id: 8, title: { pt: 'Tempo de Resposta a Problemas Técnicos', es: 'Tiempo de Respuesta a Problemas Técnicos' }, content: { pt: 'O tempo de resposta a problemas técnicos representa a rapidez com que a empresa identifica e resolve problemas técnicos relacionados a produtos ou processos, impactando diretamente a eficiência operacional e a satisfação do cliente.', es: 'Ingresos predecibles y recurrentes generados mensualmente por los suscriptores de un servicio o producto.' } },
			{ id: 9, title: { pt: 'Eficiência no Uso de Recursos', es: 'Eficiencia en el Uso de Recursos' }, content: { pt: 'A eficiência no uso de recursos refere-se à capacidade da empresa de maximizar a utilização de seus recursos (como materiais, mão de obra e capital), minimizando desperdícios e custos operacionais.', es: 'Incremento de los ingresos por ventas respecto al periodo anterior.' } },
			{ id: 10, title: { pt: 'Aderência a Prazos', es: 'Adherencia a Plazos' }, content: { pt: 'A aderência a prazos indica a capacidade da empresa de cumprir cronogramas estabelecidos para projetos ou entregas de produtos, influenciando diretamente a confiança dos clientes e parceiros comerciais.', es: 'Porcentaje de clientes que continúan utilizando los productos o servicios de la empresa durante un período de tiempo determinado.' } },
		]
	},
	{
		id: 7, area: { pt: 'Armazém, Logística e Transporte', es: 'Almacén, Logística y Transporte' }, subitems: [
			{ id: 1, title: { pt: 'Precisão do Inventário', es: 'Precisión del Inventario' }, content: { pt: 'A precisão do inventário refere-se à exatidão dos registros de estoque em comparação com a quantidade física de produtos armazenados, afetando a eficiência operacional e a capacidade de atender à demanda dos clientes.', es: 'Porcentaje de empleados que permanecen en la empresa durante un determinado periodo de tiempo.' } },
			{ id: 2, title: { pt: 'Tempo de Entrega', es: 'Tiempo de Entrega' }, content: { pt: 'O tempo de entrega representa o período necessário para enviar produtos ou serviços aos clientes após receber o pedido, influenciando diretamente a satisfação do cliente e a competitividade da empresa.', es: 'Tiempo medio necesario para cubrir una vacante desde su publicación hasta la contratación del candidato.' } },
			{ id: 3, title: { pt: 'Custo de Transporte', es: 'Costo de Transporte' }, content: { pt: 'O custo de transporte engloba todos os gastos associados ao deslocamento de produtos ou materiais de um local para outro, incluindo frete, seguros e custos administrativos, afetando diretamente os custos totais e a lucratividade.', es: 'Una medida de cuán satisfechos están los empleados con sus trabajos y el ambiente laboral.' } },
			{ id: 4, title: { pt: 'Taxa de Entrega Pontual', es: 'Tasa de Entrega Puntual' }, content: { pt: 'A taxa de entrega pontual indica a proporção de pedidos entregues dentro do prazo estabelecido em relação ao total de pedidos, refletindo a confiabilidade e eficiência do sistema de distribuição da empresa.', es: 'Porcentaje de días laborables que los empleados faltan al trabajo.' } },
			{ id: 5, title: { pt: 'Utilização do Espaço do Armazém', es: 'Utilización del Espacio del Almacén' }, content: { pt: 'A utilização do espaço do armazém refere-se à eficiência com que o espaço disponível é utilizado para armazenar produtos, minimizando custos de armazenamento e maximizando a capacidade de estoque.', es: 'Porcentaje de empleados que abandonan la empresa en un periodo de tiempo determinado.' } },
			{ id: 6, title: { pt: 'Número de Pedidos Processados', es: 'Número de Pedidos Procesados' }, content: { pt: 'O número de pedidos processados representa a quantidade de pedidos recebidos e atendidos pela empresa dentro de um período específico, impactando diretamente a capacidade de cumprir com as demandas dos clientes e parceiros comerciais.', es: 'Número total de formaciones ofrecidas a los empleados en un periodo determinado.' } },
			{ id: 7, title: { pt: 'Tempo de Ciclo do Pedido', es: 'Tiempo de Ciclo del Pedido' }, content: { pt: 'O tempo de ciclo do pedido refere-se ao período desde a colocação do pedido até a sua entrega ao cliente, incluindo processamento, preparação e transporte, influenciando diretamente a satisfação do cliente e a eficiência operacional.', es: 'Costo promedio incurrido para contratar un nuevo empleado, incluida publicidad, entrevistas y capacitación inicial.' } },
			{ id: 8, title: { pt: 'Taxa de Devoluções', es: 'Tasa de Devoluciones' }, content: { pt: 'A taxa de devoluções indica a proporção de produtos devolvidos pelos clientes em relação ao total de produtos vendidos, afetando diretamente os custos operacionais e a reputação da empresa.', es: 'Porcentaje de empleados promovidos a nuevos puestos en la empresa.' } },
			{ id: 9, title: { pt: 'Custo de Armazenagem', es: 'Costo de Almacenamiento' }, content: { pt: 'O custo de armazenamento engloba todos os gastos associados à manutenção de produtos em estoque, incluindo aluguel de espaço, segurança, seguro e manuseio, influenciando diretamente os custos totais e a lucratividade.', es: 'Evaluación de la eficacia y eficiencia del desempeño de los empleados en sus funciones.' } },
			{ id: 10, title: { pt: 'Taxa de Danos durante o Transporte', es: 'Tasa de Daños durante el Transporte' }, content: { pt: 'A taxa de danos durante o transporte refere-se à proporção de produtos danificados ou perdidos durante o transporte em relação ao total de produtos enviados, impactando diretamente os custos operacionais e a satisfação do cliente.', es: 'Medición de representación e inclusión de diversas culturas, géneros y orígenes en la empresa.' } },
		]
	},
	{
		id: 8, area: { pt: 'Administrativa', es: 'Administrativa' }, subitems: [
			{ id: 1, title: { pt: 'Eficiência de Processos', es: 'Eficiencia de Procesos' }, content: { pt: 'A eficiência de processos refere-se à capacidade de uma organização realizar suas atividades de maneira otimizada, minimizando tempo, recursos e custos, enquanto maximiza a produção ou a prestação de serviços.', es: 'Diferencia entre el precio de venta de un producto y su costo de producción, expresado como porcentaje del precio de venta.' } },
			{ id: 2, title: { pt: 'Precisão de Documentação', es: 'Precisión de la Documentación' }, content: { pt: 'A precisão da documentação refere-se à exatidão e confiabilidade dos registros e documentos mantidos pela organização, garantindo conformidade legal e facilitando a tomada de decisões informadas.', es: 'Una medida del flujo de efectivo que entra y sale de una empresa durante un período específico.' } },
			{ id: 3, title: { pt: 'Satisfação dos Funcionários', es: 'Satisfacción de los Empleados' }, content: { pt: 'A satisfação dos funcionários mede o nível de contentamento e engajamento dos colaboradores com seu trabalho e com a empresa, influenciando diretamente a produtividade e o clima organizacional.', es: 'Indica la eficiencia con la que una empresa genera beneficios a partir de sus activos totales.' } },
			{ id: 4, title: { pt: 'Gestão de Custos', es: 'Gestión de Costos' }, content: { pt: 'A gestão de custos envolve o controle e a otimização dos gastos operacionais e financeiros da empresa, buscando reduzir desperdícios e melhorar a eficiência econômica.', es: 'La capacidad de una empresa para cumplir con sus obligaciones a corto plazo utilizando sus activos líquidos.' } },
			{ id: 5, title: { pt: 'Tempo de Resposta', es: 'Tiempo de Respuesta' }, content: { pt: 'O tempo de resposta refere-se ao período necessário para a empresa atender às solicitações de clientes, parceiros ou stakeholders, impactando diretamente a satisfação do cliente e a reputação da empresa.', es: 'Aumento porcentual de los ingresos de la empresa durante un período determinado.' } },
			{ id: 6, title: { pt: 'Taxa de Conclusão de Tarefas', es: 'Tasa de Finalización de Tareas' }, content: { pt: 'A taxa de finalização de tarefas indica a proporção de tarefas ou projetos concluídos dentro do prazo estabelecido em relação ao total de tarefas iniciadas, refletindo a eficiência e a capacidade de entrega da equipe.', es: 'Relación entre la deuda total de una empresa y su capital social, utilizada para evaluar su apalancamiento financiero.' } },
			{ id: 7, title: { pt: 'Nível de Conformidade com Políticas Internas', es: 'Nivel de Cumplimiento de Políticas Internas' }, content: { pt: 'O nível de cumprimento de políticas internas refere-se à conformidade dos colaboradores com as normas, diretrizes e procedimentos estabelecidos pela empresa, garantindo segurança, eficiência e conformidade.', es: 'Gastos necesarios para el funcionamiento diario de una empresa, excluidos los costes de producción y ventas.' } },
			{ id: 8, title: { pt: 'Qualidade dos Serviços Prestados', es: 'Calidad de los Servicios Prestados' }, content: { pt: 'A qualidade dos serviços prestados refere-se ao grau em que os serviços oferecidos pela empresa atendem às expectativas e necessidades dos clientes, influenciando diretamente a satisfação e fidelidade do cliente.', es: 'Mide la rentabilidad que genera una empresa con el dinero de sus accionistas.' } },
			{ id: 9, title: { pt: 'Redução de Desperdícios', es: 'Reducción de Desperdicios' }, content: { pt: 'A redução de desperdícios envolve a minimização do uso desnecessário de recursos, materiais ou tempo durante os processos operacionais, visando à eficiência e à sustentabilidade ambiental.', es: 'La relación entre la deuda total de una empresa y sus activos totales.' } },
			{ id: 10, title: { pt: 'Grau de Automação dos Processos', es: 'Grado de Automatización de Procesos' }, content: { pt: 'O grau de automatização de processos refere-se ao nível de utilização de tecnologias e sistemas automatizados para realizar tarefas e processos, aumentando a eficiência operacional e reduzindo erros humanos.', es: 'El tiempo que le toma a una empresa convertir sus inventarios y otras inversiones en efectivo.' } },
		]
	},
	{
		id: 9, area: { pt: 'Investigação e Desenvolvimento ', es: 'Investigación y Desarrollo ' }, subitems: [
			{ id: 1, title: { pt: 'Número de Projetos Concluídos', es: 'Número de Proyectos Completados' }, content: { pt: 'O número de projetos completados refere-se à quantidade de iniciativas ou empreendimentos que foram levados a termo com sucesso pela organização dentro de um período específico, indicando a capacidade de execução e entrega.', es: 'La eficiencia operativa se refiere a la capacidad de una organización para utilizar sus recursos de manera efectiva para lograr sus objetivos operativos, minimizando el desperdicio y maximizando la producción o la prestación de servicios.' } },
			{ id: 2, title: { pt: 'Tempo de Desenvolvimento', es: 'Tiempo de Desarrollo' }, content: { pt: 'O tempo de desenvolvimento representa a duração necessária para conceber, projetar e finalizar um projeto ou produto, influenciando diretamente a agilidade e a capacidade de inovação da organização.', es: 'La tasa de defectos indica la proporción de productos o servicios que no cumplen con los estándares de calidad establecidos, lo que puede impactar negativamente la satisfacción del cliente y los costos operativos.' } },
			{ id: 3, title: { pt: 'Inovações Introduzidas', es: 'Innovaciones Introducidas' }, content: { pt: 'As inovações introduzidas referem-se à implementação de novas ideias, tecnologias ou processos que visam melhorar produtos, serviços ou processos internos, impulsionando a competitividade e o crescimento da empresa.', es: 'El costo por unidad producida representa el costo total incurrido para fabricar cada unidad de producto, incluidos los materiales, la mano de obra y los costos generales.' } },
			{ id: 4, title: { pt: 'Patentes Registradas', es: 'Patentes Registradas' }, content: { pt: 'O número de patentes registradas indica a quantidade de novas invenções ou tecnologias únicas protegidas legalmente pela empresa, refletindo seu potencial de inovação e diferenciação no mercado.', es: 'El tiempo de ciclo se refiere al tiempo necesario para completar una actividad o proceso específico, desde el principio hasta su finalización, lo que influye directamente en la capacidad de producción y la eficiencia operativa.' } },
			{ id: 5, title: { pt: 'ROI em I&D', es: 'ROI en I+D' }, content: { pt: 'O ROI (Retorno sobre Investimento) em I+D (Investigação e Desenvolvimento) representa a eficácia dos gastos e investimentos realizados em pesquisa e desenvolvimento, medindo o retorno financeiro gerado por esses esforços.', es: 'La utilización del equipo indica la proporción de tiempo que el equipo está en operación y utilizándose productivamente en relación con el tiempo total disponible, afectando la capacidad de producción y los costos de mantenimiento.' } },
			{ id: 6, title: { pt: 'Conformidade com Prazos', es: 'Cumplimiento de Plazos' }, content: { pt: 'O cumprimento de prazos refere-se à capacidade da organização de entregar projetos ou produtos dentro dos cronogramas estabelecidos, influenciando diretamente a confiança dos clientes e a reputação da empresa.', es: 'La productividad de los trabajadores mide la eficiencia con la que los empleados utilizan sus recursos y habilidades para realizar sus tareas, impactando directamente los costos operativos y de producción.' } },
			{ id: 7, title: { pt: 'Custo por Projeto', es: 'Costo por Proyecto' }, content: { pt: 'O custo por projeto engloba todos os gastos associados à execução de um projeto específico, incluindo materiais, mão de obra e despesas gerais, afetando diretamente a rentabilidade e a gestão financeira da organização.', es: 'La tasa de paradas no programadas se refiere a la frecuencia con la que equipos o procesos se detienen inesperadamente debido a fallas o problemas, afectando la productividad y los costos de mantenimiento.' } },
			{ id: 8, title: { pt: 'Qualidade das Inovações', es: 'Calidad de las Innovaciones' }, content: { pt: 'A qualidade das inovações refere-se ao grau em que as novas ideias, tecnologias ou produtos introduzidos pela organização atendem aos padrões de qualidade estabelecidos, influenciando a satisfação do cliente e a aceitação no mercado.', es: 'La calidad del producto representa el grado en que un producto cumple con los requisitos y expectativas del cliente, lo que influye en la satisfacción del cliente y la reputación de la empresa.' } },
			{ id: 9, title: { pt: 'Colaborações Externas', es: 'Colaboraciones Externas' }, content: { pt: 'As colaborações externas representam as parcerias ou alianças estabelecidas pela empresa com outras entidades externas, como universidades, fornecedores ou instituições de pesquisa, visando a compartilhar conhecimento e recursos para alcançar objetivos comuns.', es: 'El costo de mantenimiento engloba todos los gastos relacionados con el mantenimiento preventivo y correctivo de equipos e instalaciones, afectando directamente los costos operativos y la disponibilidad de recursos.' } },
			{ id: 10, title: { pt: 'Satisfação dos Stakeholders', es: 'Satisfacción de los Stakeholders' }, content: { pt: 'A satisfação dos stakeholders mede o grau de contentamento e apoio dos interessados (como clientes, acionistas, comunidade e colaboradores) em relação às atividades e decisões da organização, impactando sua reputação e sustentabilidade a longo prazo.', es: 'El tiempo de preparación se refiere al período requerido para ajustar y preparar equipos o procesos para la producción de diferentes productos o lotes, afectando la flexibilidad operativa y la eficiencia de producción.' } },

		]
	},
	{
		id: 10, area: { pt: 'Saúde', es: 'Salud' }, subitems: [
			{ id: 1, title: { pt: 'Taxa de Satisfação dos Pacientes', es: 'Tasa de Satisfacción de los Pacientes' }, content: { pt: 'A taxa de satisfação dos pacientes refere-se à proporção de pacientes que relatam estar satisfeitos com os serviços prestados pela instituição de saúde, refletindo a qualidade percebida do atendimento e dos cuidados recebidos.', es: 'El tiempo de desarrollo se refiere al período necesario para concebir, diseñar y finalizar un producto o proyecto, lo que afecta directamente la rapidez con la que los nuevos productos llegan al mercado.' } },
			{ id: 2, title: { pt: 'Tempo de Espera', es: 'Tiempo de Espera' }, content: { pt: 'O tempo de espera indica o período que os pacientes aguardam para receber atendimento médico ou realizar procedimentos, afetando diretamente a experiência do paciente e a eficiência operacional da instituição.', es: 'El cumplimiento del presupuesto indica la capacidad de un proyecto o actividad para mantenerse dentro de los límites financieros establecidos, influyendo directamente en los costos totales y la viabilidad financiera del proyecto.' } },
			{ id: 3, title: { pt: 'Taxa de Readmissão', es: 'Tasa de Readmisión' }, content: { pt: 'A taxa de readmissão representa a proporção de pacientes que retornam ao hospital dentro de um período específico após uma alta hospitalar, podendo indicar a eficácia dos cuidados prestados e a necessidade de melhorias no acompanhamento pós-alta.', es: 'La calidad del producto representa el grado en que un producto cumple con los requisitos y expectativas del cliente, lo que impacta directamente en la satisfacción del cliente y la reputación de la empresa.' } },
			{ id: 4, title: { pt: 'Índice de Segurança do Paciente', es: 'Índice de Seguridad del Paciente' }, content: { pt: 'O índice de segurança do paciente refere-se à medida de incidentes adversos ou erros evitáveis ​​durante o tratamento médico, influenciando diretamente a segurança e a qualidade dos cuidados de saúde oferecidos.', es: 'Las innovaciones introducidas se refieren al número y naturaleza de nuevas ideas, tecnologías o procesos implementados, que pueden impulsar la competitividad y el crecimiento de la empresa en el mercado.' } },
			{ id: 5, title: { pt: 'Taxa de Ocupação', es: 'Tasa de Ocupación' }, content: { pt: 'A taxa de ocupação refere-se à porcentagem de leitos hospitalares que estão ocupados por pacientes durante um período específico, impactando diretamente a eficiência operacional e a capacidade de atendimento da instituição.', es: 'La satisfacción del cliente mide el grado de satisfacción del cliente con los productos o servicios ofrecidos, influyendo directamente en la fidelidad del cliente y en la reputación de la empresa.' } },
			{ id: 6, title: { pt: 'Custo por Paciente Atendido', es: 'Costo por Paciente Atendido' }, content: { pt: 'O custo por paciente atendido engloba todos os gastos associados ao tratamento e cuidados oferecidos a cada paciente durante sua estadia hospitalar ou visitas ambulatoriais, influenciando diretamente os custos operacionais e financeiros da instituição.', es: 'El número de patentes registradas indica el número de nuevos inventos o tecnologías únicas legalmente protegidas por la empresa, reflejando su potencial de innovación y diferenciación en el mercado.' } },
			{ id: 7, title: { pt: 'Número de Procedimentos Realizados', es: 'Número de Procedimientos Realizados' }, content: { pt: 'O número de procedimentos realizados indica a quantidade de intervenções médicas, cirúrgicas ou diagnósticas realizadas pela instituição em um determinado período, refletindo sua capacidade de atender à demanda por serviços de saúde.', es: 'La tasa de éxito del proyecto se refiere a la proporción de proyectos completados con éxito en relación con el número total de proyectos iniciados, lo que indica la eficacia de la gestión del proyecto y la capacidad de entrega de la empresa.' } },
			{ id: 8, title: { pt: 'Taxa de Mortalidade', es: 'Tasa de Mortalidad' }, content: { pt: 'A taxa de mortalidade refere-se à proporção de pacientes que falecem durante ou após o tratamento hospitalar, sendo um indicador crucial da eficácia dos cuidados e da gestão de riscos da instituição.', es: 'El tiempo de respuesta a problemas técnicos representa la rapidez con la que la empresa identifica y resuelve problemas técnicos relacionados con productos o procesos, impactando directamente la eficiencia operativa y la satisfacción del cliente.' } },
			{ id: 9, title: { pt: 'Adesão a Protocolos Clínicos', es: 'Adherencia a Protocolos Clínicos' }, content: { pt: 'A aderência a protocolos clínicos refere-se ao cumprimento rigoroso de diretrizes e procedimentos estabelecidos para o tratamento de condições médicas específicas, visando garantir a qualidade e a segurança dos cuidados de saúde oferecidos.', es: 'La eficiencia en el uso de los recursos se refiere a la capacidad de la empresa para maximizar el uso de sus recursos (como materiales, mano de obra y capital), minimizando los desperdicios y los costos operativos.' } },
			{ id: 10, title: { pt: 'Índice de Infecções Hospitalares', es: 'Índice de Infecciones Hospitalarias' }, content: { pt: 'O índice de infecções hospitalares indica a frequência e a gravidade das infecções adquiridas por pacientes durante sua estadia hospitalar, sendo um indicador crítico da higiene e das práticas de controle de infecções da instituição de saúde.', es: 'El cumplimiento de los plazos indica la capacidad de la empresa para cumplir con los cronogramas establecidos para proyectos o entregas de productos, lo que influye directamente en la confianza de los clientes y socios comerciales.' } },
		]
	},
	{
		id: 11, area: { pt: 'Educação', es: 'Educación' }, subitems: [
			{ id: 1, title: { pt: 'Taxa de Graduação', es: 'Tasa de Graduación' }, content: { pt: 'A taxa de graduação refere-se à proporção de estudantes que completam com sucesso seus cursos ou programas acadêmicos dentro do período normal previsto, indicando a eficácia do currículo e do suporte oferecido pela instituição educacional.', es: 'La precisión del inventario se refiere a la precisión de los registros de inventario en comparación con la cantidad física de productos almacenados, lo que afecta la eficiencia operativa y la capacidad de satisfacer la demanda de los clientes.' } },
			{ id: 2, title: { pt: 'Taxa de Retenção de Alunos', es: 'Tasa de Retención de Estudiantes' }, content: { pt: 'A taxa de retenção de estudantes indica a porcentagem de estudantes que continuam matriculados na instituição por um período adicional de tempo, refletindo a capacidade da instituição de proporcionar um ambiente acadêmico e social positivo.', es: 'El tiempo de entrega representa el periodo necesario para enviar productos o servicios a los clientes después de recibir el pedido, influyendo directamente en la satisfacción del cliente y en la competitividad de la empresa.' } },
			{ id: 3, title: { pt: 'Satisfação dos Estudantes', es: 'Satisfacción de los Estudiantes' }, content: { pt: 'A satisfação dos estudantes mede o nível de contentamento e aprovação dos alunos em relação aos serviços educacionais e experiência geral oferecida pela instituição, influenciando a reputação e a atratividade da mesma.', es: 'El costo de transporte abarca todos los gastos asociados con el traslado de productos o materiales de un lugar a otro, incluidos fletes, seguros y costos administrativos, afectando directamente los costos totales y la rentabilidad.' } },
			{ id: 4, title: { pt: 'Desempenho Acadêmico', es: 'Desempeño Académico' }, content: { pt: 'O desempenho acadêmico refere-se ao rendimento e resultados dos estudantes em avaliações, provas e trabalhos acadêmicos, demonstrando o nível de aprendizado e competência alcançado durante seu período de estudo.', es: 'La tasa de entrega a tiempo indica la proporción de pedidos entregados dentro del plazo establecido en relación con el total de pedidos, reflejando la confiabilidad y eficiencia del sistema de distribución de la empresa.' } },
			{ id: 5, title: { pt: 'Participação em Atividades Extracurriculares', es: 'Participación en Actividades Extracurriculares' }, content: { pt: 'A participação em atividades extracurriculares indica o envolvimento dos estudantes em programas, clubes, equipes esportivas ou atividades voluntárias fora do currículo acadêmico regular, contribuindo para o desenvolvimento pessoal e social dos alunos.', es: 'La utilización del espacio del almacén se refiere a la eficiencia con la que se utiliza el espacio disponible para almacenar productos, minimizando los costos de almacenamiento y maximizando la capacidad del inventario.' } },
			{ id: 6, title: { pt: 'Número de Publicações Científicas', es: 'Número de Publicaciones Científicas' }, content: { pt: 'O número de publicações científicas representa a quantidade de artigos, pesquisas ou trabalhos acadêmicos publicados por professores e estudantes da instituição em revistas científicas ou conferências, indicando o impacto e contribuição da pesquisa realizada.', es: 'La cantidad de pedidos procesados ​​representa la cantidad de pedidos recibidos y cumplidos por la empresa dentro de un período específico, lo que impacta directamente la capacidad de satisfacer las demandas de los clientes y socios comerciales.' } },
			{ id: 7, title: { pt: 'Taxa de Empregabilidade dos Graduados', es: 'Tasa de Empleabilidad de los Graduados' }, content: { pt: 'A taxa de empregabilidade dos graduados refere-se à proporção de ex-alunos que conseguem emprego em suas áreas de estudo ou relacionadas após a conclusão do curso, refletindo a qualidade da formação acadêmica e preparação para o mercado de trabalho.', es: 'El tiempo del ciclo del pedido se refiere al período desde la realización del pedido hasta la entrega al cliente, incluido el procesamiento, la preparación y el transporte, lo que influye directamente en la satisfacción del cliente y la eficiencia operativa.' } },
			{ id: 8, title: { pt: 'Custo por Estudante', es: 'Costo por Estudiante' }, content: { pt: 'O custo por estudante engloba todos os gastos associados ao ensino, suporte estudantil e infraestrutura por aluno matriculado na instituição, influenciando diretamente a sustentabilidade financeira e a acessibilidade do ensino.', es: 'La tasa de devoluciones indica la proporción de productos devueltos por los clientes en relación con el número total de productos vendidos, afectando directamente los costos operativos y la reputación de la empresa.' } },
			{ id: 9, title: { pt: 'Taxa de Absenteísmo dos Professores', es: 'Tasa de Absentismo de los Profesores' }, content: { pt: 'A taxa de absentismo dos professores refere-se à frequência de ausências não programadas ou não justificadas de professores durante o período letivo, impactando a continuidade do ensino e a qualidade da experiência educacional dos estudantes.', es: 'Los costos de almacenamiento abarcan todos los gastos asociados con el mantenimiento de productos en stock, incluido el alquiler del espacio, la seguridad, el seguro y la manipulación, lo que influye directamente en los costos totales y la rentabilidad.' } },
			{ id: 10, title: { pt: 'Índice de Desenvolvimento dos Alunos', es: 'Índice de Desarrollo de los Estudiantes' }, content: { pt: 'O índice de desenvolvimento dos estudantes avalia o progresso e crescimento acadêmico, social e emocional dos alunos ao longo de seu tempo na instituição, medindo a eficácia dos programas educacionais e de suporte oferecidos.', es: 'La tasa de daños en el transporte se refiere a la proporción de productos dañados o perdidos durante el transporte en relación con el número total de productos enviados, lo que impacta directamente en los costos operativos y la satisfacción del cliente.' } },

		]
	},
	{
		id: 12, area: { pt: 'Tecnologia da Informação (TI)', es: 'Tecnología de la Información (TI)' }, subitems: [
			{ id: 1, title: { pt: 'Tempo de Resolução de Indidencias', es: 'Tiempo de Resolución de Incidencias' }, content: { pt: 'O tempo de resolução de incidências refere-se ao período necessário para resolver problemas ou incidentes relatados em um sistema ou serviço, influenciando diretamente a eficiência operacional e a satisfação do usuário.', es: 'La eficiencia de los procesos se refiere a la capacidad de una organización para realizar sus actividades de manera optimizada, minimizando el tiempo, los recursos y los costos, mientras se maximiza la producción o la prestación de servicios.' } },
			{ id: 2, title: { pt: 'Disponibilidade do Sistema', es: 'Disponibilidad del Sistema' }, content: { pt: 'A disponibilidade do sistema indica a proporção de tempo em que um sistema ou serviço está operacional e disponível para uso pelos usuários, impactando diretamente a produtividade e a confiabilidade do serviço.', es: 'La exactitud de la documentación se refiere a la exactitud y confiabilidad de los registros y documentos mantenidos por la organización, asegurando el cumplimiento legal y facilitando la toma de decisiones informadas.' } },
			{ id: 3, title: { pt: 'Satisfação do Usuário', es: 'Satisfacción del Usuario' }, content: { pt: 'A satisfação do usuário mede o nível de contentamento e aprovação dos usuários em relação à qualidade, usabilidade e suporte oferecido pelo sistema ou serviço, influenciando a reputação e a adoção do mesmo.', es: 'La satisfacción de los empleados mide el nivel de satisfacción y compromiso de los empleados con su trabajo y la empresa, influyendo directamente en la productividad y el clima organizacional.' } },
			{ id: 4, title: { pt: 'Custo por Incidente', es: 'Costo por Incidente' }, content: { pt: 'O custo por incidente engloba todos os gastos associados ao tratamento e resolução de cada incidente ou problema relatado pelos usuários, influenciando diretamente os custos operacionais e a gestão financeira.', es: 'La gestión de costes implica controlar y optimizar los gastos operativos y financieros de la empresa, buscando reducir los residuos y mejorar la eficiencia económica.' } },
			{ id: 5, title: { pt: 'Número de Incidentes', es: 'Número de Incidentes' }, content: { pt: 'O número de incidentes refere-se à quantidade total de problemas ou questões relatadas pelos usuários em um período específico, indicando a frequência e gravidade dos problemas enfrentados pelo sistema ou serviço.', es: 'El tiempo de respuesta se refiere al período necesario para que la empresa responda a las solicitudes de clientes, socios o partes interesadas, impactando directamente en la satisfacción del cliente y la reputación de la empresa.' } },
			{ id: 6, title: { pt: 'Tempo de Resposta do Suporte', es: 'Tiempo de Respuesta del Soporte' }, content: { pt: 'O tempo de resposta do suporte refere-se ao período necessário para que a equipe de suporte responda a uma solicitação ou incidente relatado pelos usuários, influenciando diretamente a experiência do usuário e a eficiência do suporte.', es: 'La tasa de finalización de tareas indica la proporción de tareas o proyectos completados dentro del plazo establecido en relación con el número total de tareas iniciadas, reflejando la eficiencia y la capacidad de entrega del equipo.' } },
			{ id: 7, title: { pt: 'Eficiência de Backup e Recuperação', es: 'Eficiencia de Respaldo y Recuperación' }, content: { pt: 'A eficiência de backup e recuperação refere-se à capacidade do sistema ou serviço de realizar backups de dados de forma eficiente e recuperá-los rapidamente em caso de falhas ou perdas de dados, garantindo a integridade e disponibilidade das informações.', es: 'El nivel de cumplimiento de las políticas internas se refiere al cumplimiento por parte de los empleados de las normas, lineamientos y procedimientos establecidos por la empresa, garantizando la seguridad, la eficiencia y el cumplimiento.' } },
			{ id: 8, title: { pt: 'Conformidade com Padrões de Segurança', es: 'Cumplimiento de Estándares de Seguridad' }, content: { pt: 'O cumprimento de padrões de segurança refere-se à conformidade com normas, diretrizes e práticas recomendadas para proteger informações sensíveis e garantir a segurança dos dados armazenados e transmitidos pelo sistema ou serviço.', es: 'La calidad de los servicios prestados se refiere al grado en que los servicios ofrecidos por la empresa satisfacen las expectativas y necesidades del cliente, influyendo directamente en la satisfacción y fidelidad del cliente.' } },
			{ id: 9, title: { pt: 'Número de Atualizações e Upgrades', es: 'Número de Actualizaciones y Upgrades' }, content: { pt: 'O número de atualizações e upgrades indica a quantidade de melhorias, correções de bugs ou novas funcionalidades implementadas no sistema ou serviço ao longo do tempo, mantendo-o atualizado e competitivo no mercado.', es: 'La reducción de residuos implica minimizar el uso innecesario de recursos, materiales o tiempo durante los procesos operativos, buscando la eficiencia y la sostenibilidad ambiental.' } },
			{ id: 10, title: { pt: 'Uso de Capacidade do Servidor', es: 'Uso de Capacidad del Servidor' }, content: { pt: 'O uso da capacidade do servidor refere-se à porcentagem de recursos do servidor (como CPU, memória e armazenamento) utilizados para suportar operações e demandas de usuários, influenciando diretamente o desempenho e a escalabilidade do sistema ou serviço.', es: 'El grado de automatización de procesos se refiere al nivel de uso de tecnologías y sistemas automatizados para realizar tareas y procesos, aumentando la eficiencia operativa y reduciendo los errores humanos.' } },
		]
	},
	{
		id: 13, area: { pt: 'Legal', es: 'Legal' }, subitems: [
			{ id: 1, title: { pt: 'Taxa de Sucesso em Casos', es: 'Tasa de Éxito en Casos' }, content: { pt: 'A taxa de sucesso em casos refere-se à proporção de casos ou processos que são resolvidos com êxito pela organização ou equipe legal, demonstrando a eficácia e competência na gestão de casos.', es: 'El número de proyectos completados se refiere a la cantidad de iniciativas o emprendimientos que fueron completados exitosamente por la organización dentro de un período específico, indicando la capacidad de ejecución y entrega.' } },
			{ id: 2, title: { pt: 'Tempo de Resolução de Casos', es: 'Tiempo de Resolución de Casos' }, content: { pt: 'O tempo de resolução de casos indica o período necessário para resolver um caso ou processo legal desde o seu início até a sua conclusão, influenciando diretamente a eficiência operacional e a satisfação do cliente.', es: 'El tiempo de desarrollo representa el tiempo necesario para concebir, diseñar y finalizar un proyecto o producto, influyendo directamente en la agilidad y capacidad de innovación de la organización.' } },
			{ id: 3, title: { pt: 'Conformidade Regulamentar', es: 'Cumplimiento Regulatorio' }, content: { pt: 'O cumprimento regulatório refere-se à conformidade com leis, normas e regulamentos aplicáveis ao setor ou mercado em que a organização opera, evitando sanções legais e garantindo operações dentro dos limites legais.', es: 'Las innovaciones introducidas se refieren a la implementación de nuevas ideas, tecnologías o procesos que tienen como objetivo mejorar los productos, servicios o procesos internos, impulsando la competitividad y el crecimiento de la empresa.' } },
			{ id: 4, title: { pt: 'Custos Legais', es: 'Costos Legales' }, content: { pt: 'Os custos legais englobam todas as despesas associadas a serviços jurídicos e litígios, incluindo honorários advocatícios, custas judiciais e outras despesas legais, impactando diretamente os custos operacionais e financeiros da organização.', es: 'El número de patentes registradas indica el número de nuevos inventos o tecnologías únicas legalmente protegidas por la empresa, reflejando su potencial de innovación y diferenciación en el mercado.' } },
			{ id: 5, title: { pt: 'Satisfação dos Clientes', es: 'Satisfacción de los Clientes' }, content: { pt: 'A satisfação dos clientes mede o nível de contentamento e aprovação dos clientes com os serviços jurídicos prestados pela organização, influenciando a fidelidade do cliente e a reputação da empresa.', es: 'El ROI (Retorno de la Inversión) en I+D (Investigación y Desarrollo) representa la efectividad del gasto y las inversiones realizadas en investigación y desarrollo, midiendo el retorno financiero generado por estos esfuerzos.' } },
			{ id: 6, title: { pt: 'Número de Casos Gerenciados', es: 'Número de Casos Gestionados' }, content: { pt: 'O número de casos gerenciados representa a quantidade total de casos legais ou processos tratados pela organização em um determinado período, refletindo a carga de trabalho e a capacidade de gestão de casos.', es: 'El cumplimiento de los plazos se refiere a la capacidad de la organización para entregar proyectos o productos dentro de los plazos establecidos, lo que influye directamente en la confianza del cliente y la reputación de la empresa.' } },
			{ id: 7, title: { pt: 'Taxa de Retenção de Clientes', es: 'Tasa de Retención de Clientes' }, content: { pt: 'A taxa de retenção de clientes refere-se à proporção de clientes que continuam a utilizar os serviços jurídicos da organização ao longo do tempo, indicando a qualidade do serviço e o relacionamento com o cliente.', es: 'El costo por proyecto engloba todos los gastos asociados a la ejecución de un proyecto específico, incluyendo materiales, mano de obra y gastos generales, afectando directamente la rentabilidad y gestión financiera de la organización.' } },
			{ id: 8, title: { pt: 'Tempo de Resposta a Consultas', es: 'Tiempo de Respuesta a Consultas' }, content: { pt: 'O tempo de resposta a consultas jurídicas refere-se ao período necessário para a equipe jurídica responder às consultas dos clientes ou partes interessadas, influenciando diretamente a satisfação do cliente e a eficiência do serviço.', es: 'La calidad de las innovaciones se refiere al grado en que las nuevas ideas, tecnologías o productos introducidos por la organización cumplen con los estándares de calidad establecidos, influyendo en la satisfacción del cliente y la aceptación del mercado.' } },
			{ id: 9, title: { pt: 'Redução de Riscos Legais', es: 'Reducción de Riesgos Legales' }, content: { pt: 'A redução de riscos legais envolve a implementação de medidas para minimizar a exposição da organização a litígios, penalidades ou outros riscos jurídicos, protegendo seus interesses e reputação.', es: 'Las colaboraciones externas representan asociaciones o alianzas establecidas por la empresa con otras entidades externas, como universidades, proveedores o instituciones de investigación, con el objetivo de compartir conocimientos y recursos para lograr objetivos comunes.' } },
			{ id: 10, title: { pt: 'Eficiência na Preparação de Documentos', es: 'Eficiencia en la Preparación de Documentos' }, content: { pt: 'A eficiência na preparação de documentos refere-se à rapidez e precisão com que a equipe jurídica prepara e revisa documentos legais, contratos ou outros registros necessários para suportar operações comerciais ou processos legais da organização.', es: 'La satisfacción de las partes interesadas mide el grado de satisfacción y apoyo de las partes interesadas (como clientes, accionistas, comunidad y empleados) en relación con las actividades y decisiones de la organización, impactando su reputación y sostenibilidad a largo plazo.' } },
		]
	},
	{
		id: 14, area: { pt: 'Alta Administração', es: 'Alta Administración' }, subitems: [
			{ id: 1, title: { pt: 'Crescimento da Receita', es: 'Crecimiento de los Ingresos' }, content: { pt: 'Aumento na receita ou faturamento da empresa ao longo de um período específico, refletindo a expansão dos negócios e a eficácia das estratégias de vendas e marketing.', es: 'La tasa de satisfacción del paciente se refiere a la proporción de pacientes que reportan estar satisfechos con los servicios brindados por la institución de salud, lo que refleja la calidad percibida de la atención y la atención recibida.' } },
			{ id: 2, title: { pt: 'Retorno sobre o Investimento (ROI)', es: 'Retorno sobre la Inversión (ROI)' }, content: { pt: 'Medida de eficiência financeira que calcula o retorno obtido em relação ao investimento realizado, ajudando a avaliar a rentabilidade de projetos, campanhas ou iniciativas empresariais.', es: 'El tiempo de espera indica el período que los pacientes esperan para recibir atención médica o someterse a procedimientos, afectando directamente la experiencia del paciente y la eficiencia operativa de la institución.' } },
			{ id: 3, title: { pt: 'Satisfação dos Funcionários', es: 'Satisfacción de los Empleados' }, content: { pt: 'Nível de contentamento e engajamento dos funcionários em relação ao ambiente de trabalho, benefícios, cultura corporativa e oportunidades de desenvolvimento, influenciando diretamente a produtividade e retenção de talentos.', es: 'La tasa de reingreso representa la proporción de pacientes que regresan al hospital dentro de un período específico después del alta hospitalaria y puede indicar la efectividad de la atención brindada y la necesidad de mejorar el seguimiento posterior al alta.' } },
			{ id: 4, title: { pt: 'Implementação de Estratégias', es: 'Implementación de Estrategias' }, content: { pt: 'Processo de colocar em prática planos e estratégias definidas pela empresa para atingir objetivos específicos, garantindo alinhamento entre planejamento e execução.', es: 'El índice de seguridad del paciente se refiere a la medida de incidentes adversos o errores evitables durante el tratamiento médico, influyendo directamente en la seguridad y calidad de la atención sanitaria brindada.' } },
			{ id: 5, title: { pt: 'Desenvolvimento de Liderança', es: 'Desarrollo de Liderazgo' }, content: { pt: 'Iniciativas e programas destinados a desenvolver habilidades de liderança entre os funcionários, capacitando-os para assumir papéis de liderança e contribuir para o crescimento organizacional.', es: 'La tasa de ocupación se refiere al porcentaje de camas hospitalarias que son ocupadas por pacientes durante un período específico, impactando directamente la eficiencia operativa y la capacidad de servicio de la institución.' } },
			{ id: 6, title: { pt: 'Eficiência Operacional', es: 'Eficiencia Operacional' }, content: { pt: 'Capacidade da empresa de utilizar seus recursos de maneira eficaz para maximizar a produção, minimizar desperdícios e reduzir custos operacionais, melhorando a rentabilidade e competitividade.', es: 'El costo por paciente atendido abarca todos los gastos asociados al tratamiento y atención ofrecidos a cada paciente durante su estadía hospitalaria o visitas ambulatorias, influyendo directamente en los costos operativos y financieros de la institución.' } },
			{ id: 7, title: { pt: 'Gestão de Custos', es: 'Gestión de Costos' }, content: { pt: 'Processo de monitoramento, controle e redução de despesas e custos operacionais dentro da organização, visando melhorar a eficiência financeira e a sustentabilidade.', es: 'El número de procedimientos realizados indica el número de intervenciones médicas, quirúrgicas o diagnósticas realizadas por la institución en un período determinado, reflejando su capacidad para satisfacer la demanda de servicios de salud.' } },
			{ id: 8, title: { pt: 'Inovação e Crescimento', es: 'Innovación y Crecimiento' }, content: { pt: 'Fomento de novas ideias, produtos, processos ou modelos de negócios que impulsionam o crescimento e a diferenciação da empresa no mercado, promovendo a inovação contínua.', es: 'La tasa de mortalidad se refiere a la proporción de pacientes que mueren durante o después del tratamiento hospitalario, siendo un indicador crucial de la efectividad de la atención y la gestión de riesgos de la institución.' } },
			{ id: 9, title: { pt: 'Satisfação dos Clientes', es: 'Satisfacción de los Clientes' }, content: { pt: 'Grau de contentamento dos clientes em relação aos produtos, serviços e atendimento oferecidos pela empresa, influenciando a lealdade, recomendação e sucesso do negócio.', es: 'La adherencia a los protocolos clínicos se refiere al cumplimiento estricto de las pautas y procedimientos establecidos para el tratamiento de condiciones médicas específicas, con el objetivo de garantizar la calidad y seguridad de la atención de salud ofrecida.' } },
			{ id: 10, title: { pt: 'Alcance de Metas de Sustentabilidade', es: 'Alcance de Metas de Sostenibilidad' }, content: { pt: 'Realização de objetivos ambientais, sociais e econômicos estabelecidos pela empresa para promover práticas sustentáveis, responsabilidade corporativa e contribuição para o bem-estar da comunidade e do planeta.', es: 'El índice de infección hospitalaria indica la frecuencia y gravedad de las infecciones adquiridas por los pacientes durante su estancia hospitalaria, siendo un indicador crítico de las prácticas de higiene y control de infecciones de la institución de salud.' } },

		]
	},
	{
		id: 15, area: { pt: 'Seguros', es: 'Seguros' }, subitems: [
			{ id: 1, title: { pt: 'Taxa de Retenção de Clientes', es: 'Tasa de Retención de Clientes' }, content: { pt: 'A taxa de retenção de clientes refere-se à proporção de clientes que continuam a usar os serviços ou produtos da empresa ao longo do tempo, indicando a lealdade do cliente e a qualidade do serviço.', es: 'La tasa de graduación se refiere a la proporción de estudiantes que completan exitosamente sus cursos o programas académicos dentro del período normal esperado, lo que indica la efectividad del plan de estudios y el apoyo ofrecido por la institución educativa.' } },
			{ id: 2, title: { pt: 'Índice de Sinistralidade', es: 'Índice de Siniestralidad' }, content: { pt: 'O índice de siniestralidade mede a frequência e gravidade dos sinistros ou reclamações de seguros registrados em um período específico, impactando diretamente os custos e a rentabilidade da seguradora.', es: 'La tasa de retención estudiantil indica el porcentaje de estudiantes que permanecen matriculados en la institución por un período de tiempo adicional, lo que refleja la capacidad de la institución para brindar un ambiente académico y social positivo.' } },
			{ id: 3, title: { pt: 'Tempo de Processamento de Sinistros', es: 'Tiempo de Procesamiento de Siniestros' }, content: { pt: 'O tempo de processamento de sinistros é o período necessário para resolver e processar reclamações de seguros desde o registro até a resolução final, influenciando a satisfação do cliente e a eficiência operacional da seguradora.', es: 'La satisfacción estudiantil mide el nivel de satisfacción y aprobación de los estudiantes en relación con los servicios educativos y la experiencia general ofrecida por la institución, influyendo en su reputación y atractivo.' } },
			{ id: 4, title: { pt: 'Crescimento das Apólices', es: 'Crecimiento de las Pólizas' }, content: { pt: 'O crescimento de pólizas refere-se ao aumento no número ou valor das apólices de seguro vendidas pela empresa em um período específico, refletindo a expansão do negócio e a penetração no mercado.', es: 'El rendimiento académico se refiere al desempeño y resultados de los estudiantes en evaluaciones, pruebas y trabajos académicos, demostrando el nivel de aprendizaje y competencia alcanzado durante su período de estudio.' } },
			{ id: 5, title: { pt: 'Satisfação dos Clientes', es: 'Satisfacción de los Clientes' }, content: { pt: 'A satisfação dos clientes mede o nível de contentamento e aprovação dos clientes em relação aos serviços e atendimento oferecidos pela seguradora, influenciando a fidelidade do cliente e a reputação da empresa.', es: 'La participación en actividades extracurriculares indica la participación de los estudiantes en programas, clubes, equipos deportivos o actividades voluntarias fuera del plan de estudios académico regular, contribuyendo al desarrollo personal y social de los estudiantes.' } },
			{ id: 6, title: { pt: 'Taxa de Renovação de Apólices', es: 'Tasa de Renovación de Pólizas' }, content: { pt: 'A taxa de renovação de pólizas indica a proporção de clientes que optam por renovar suas apólices de seguro com a empresa após o término do período de cobertura inicial, demonstrando a qualidade do serviço e a confiança do cliente na seguradora.', es: 'El número de publicaciones científicas representa el número de artículos, investigaciones o trabajos académicos publicados por profesores y estudiantes de la institución en revistas o congresos científicos, indicando el impacto y aporte de las investigaciones realizadas.' } },
			{ id: 7, title: { pt: 'Custo Médio de Sinistros', es: 'Costo Medio de Siniestros' }, content: { pt: 'O custo médio de sinistros é o valor médio pago pela seguradora para cobrir reclamações ou sinistros ocorridos em um determinado período, impactando diretamente os custos operacionais e a rentabilidade da empresa.', es: 'La tasa de empleabilidad de los graduados se refiere a la proporción de exalumnos que obtienen empleo en sus campos de estudio o áreas relacionadas después de completar el curso, lo que refleja la calidad de la formación académica y la preparación para el mercado laboral.' } },
			{ id: 8, title: { pt: 'Taxa de Fraude', es: 'Tasa de Fraude' }, content: { pt: 'A taxa de fraude refere-se à frequência e gravidade das tentativas fraudulentas de reclamações de seguros, representando um desafio para a seguradora em termos de perdas financeiras e gestão de riscos.', es: 'El costo por estudiante abarca todos los gastos asociados a la docencia, apoyo estudiantil e infraestructura por estudiante matriculado en la institución, influyendo directamente en la sostenibilidad financiera y accesibilidad de la educación.' } },
			{ id: 9, title: { pt: 'Tempo de Resolução de Reclamações', es: 'Tiempo de Resolución de Reclamos' }, content: { pt: 'O tempo de resolução de reclamações é o período necessário para resolver e satisfazer as reclamações ou disputas dos clientes em relação aos serviços de seguros oferecidos pela empresa, influenciando a satisfação do cliente e a reputação da seguradora.', es: 'La tasa de ausentismo docente se refiere a la frecuencia de ausencias no programadas o injustificadas de los docentes durante el período académico, impactando la continuidad de la enseñanza y la calidad de la experiencia educativa de los estudiantes.' } },
			{ id: 10, title: { pt: 'Índice de Conformidade Regulatória', es: 'Índice de Cumplimiento Regulatorio' }, content: { pt: 'O índice de cumprimento regulatório avalia o nível de conformidade da seguradora com as normas, regulamentos e diretrizes estabelecidas pelos órgãos reguladores do setor de seguros, garantindo operações dentro dos limites legais e éticos.', es: 'El índice de desarrollo estudiantil evalúa el progreso y crecimiento académico, social y emocional de los estudiantes a lo largo de su estancia en la institución, midiendo la efectividad de los programas educativos y de apoyo ofrecidos.' } },
		]
	},
]





































































































































































































































































