// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-form {
  margin: 0;
  padding: 0;
  list-style: none;
}
.menu-form li {
  border: 1px solid #d9d9e2;
  padding: 5px 10px;
  margin: 10px 0;
  border-radius: 50rem;
  text-align: center;
  cursor: pointer;
}
.menu-form li a {
  color: #444258;
}
.menu-form li:hover {
  background: #f2f2f7;
}
.menu-form li.active {
  background: #7A6BE8;
}
.menu-form li.active a {
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/Views/Employee/Profile/Components/MenuForm/menuForm.sass"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,UAAA;EACA,gBAAA;AACJ;AACI;EACI,yBAAA;EACA,iBAAA;EACA,cAAA;EACA,oBAAA;EACA,kBAAA;EACA,eAAA;AACR;AACQ;EACI,cAAA;AACZ;AACQ;EACI,mBAAA;AACZ;AACQ;EACI,mBAAA;AACZ;AAAY;EACI,YAAA;AAEhB","sourcesContent":[".menu-form\n    margin: 0\n    padding: 0\n    list-style: none\n\n    li\n        border: 1px solid #d9d9e2\n        padding: 5px 10px\n        margin: 10px 0\n        border-radius: 50rem\n        text-align: center\n        cursor: pointer\n\n        a\n            color: #444258\n\n        &:hover\n            background: #f2f2f7\n\n        &.active\n            background: #7A6BE8\n            a\n                color: white\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
