import { useEffect, useState } from 'react'
import ServicesContextProvider from '../../../../Store/ServicesContext'
import Swal from 'sweetalert2'
import NewDash from './Views/NewDash'

const DashBoard = ({ location }) => {
	useEffect(() => {
		getIdSmtmByCompanyName()
	}, [])

	const [companyIdFromSMTM, setCompanyIdFromSMTM] = useState(null)

	const getIdSmtmByCompanyName = async () => {
		await fetch(`https://smtm.co/v1/getCompanyIdFromMyDna?name=${JSON.parse(localStorage.getItem('user')).name}`)
			.then(response => response.json())
			.then(data => setCompanyIdFromSMTM(data))
			.catch(() =>
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Algo salió mal !',
				})
			)
	}

	return (
		<ServicesContextProvider>
			<>
				<div className='detail-page ms-5 pb-5'>
					<div className='row'>
						<div className='col-12 col-md-12 d-flex flex-column align-items-center'>
							<NewDash location={location} companyIdFromSMTM={companyIdFromSMTM} />
						</div>
					</div>
				</div>{' '}
			</>
		</ServicesContextProvider>
	)
}
export default DashBoard
