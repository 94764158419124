import { useIntl } from "react-intl";
import { Tooltip } from "./tooltip";

export const ToogleComparative = ({ comparative, handleClick }) => {
    const language = useIntl();

    return (
        <>
            <div className="d-inline-flex align-items-center">
                <div className="d-inline-flex w-auto rounded-pill bg-white p-1 me-3">
                    <div
                        onClick={() => handleClick(1)}
                        role="button"
                        className={`fs-6 rounded-pill py-1 px-3 ${
                            comparative === 1
                                ? "bg-primary text-white"
                                : "text-dark-grey"
                        }`}
                    >
                        {language.locale === "ES"
                            ? "Mi Empresa"
                            : "Minha Empresa"}
                    </div>
                    <div
                        onClick={() => handleClick(2)}
                        role="button"
                        className={`fs-6 rounded-pill py-1 px-3 ${
                            comparative === 2
                                ? "bg-primary text-white"
                                : "text-dark-grey"
                        }`}
                    >
                        MyDNA
                    </div>
                </div>
                <Tooltip />
            </div>
        </>
    );
};
