export const postInternalVacancy = async (data) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/internalvacancies`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};

export const getInternalVacancies = async () => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/internalvacancies`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};

export const getInternalVacancy = async (vacancieId) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/internalvacancies/${vacancieId}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};
