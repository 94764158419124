import "./circularBar.sass"

const CircularBar = ({ percentagem, color = '#03a9f4' }) => {

    const value = Math.round(percentagem)

    return (
        <div className="content-circular-bar">
            <svg >
                <circle cx="85" cy="85" r="70" />
                <circle cx="85" cy="85" r="70" style={{ strokeDashoffset: `calc(440 - (440 * ${value}) / 100)`, stroke: color }} />
            </svg>
            <div className="number">
                <h2>{value}<span>%</span></h2>
            </div>
        </div>
    )
}
export default CircularBar