import { Link } from 'react-router-dom'
import { HiMiniChevronRight } from 'react-icons/hi2'
import { assessment } from '../../../i18n/translatedMessages'
import { assessmentTranslate } from '../../../i18n/Library/assessment'

import { useIntl } from 'react-intl'

export function Card({ content, assets, handleDimension }) {
	const getLanguage = useIntl()
	const language = getLanguage.locale === 'PT-BR' ? 'pt' : 'es'

	return (
		<div className='border rounded-3 overflow-hidden' style={{ width: 350 }}>
			<div className='p-4' style={{ background: assets[content.dimension].color, height: 170 }}>
				<div className='row'>
					<div className='col flex align-items-center'>
						<h3 className='text-white fw-bold'>{content.score_relative}%</h3>
					</div>
					<div className='col flex justify-content-end align-items-center'>
						<div className='rounded-circle bg-white flex justify-content-center align-items-center' style={{ width: 45, height: 45, color: assets[content.dimension].color }}>
							{assets[content.dimension].icon}
						</div>
					</div>
				</div>
				<div className='row'>
					<div className='col text-white'>
						<h4 className='m-0 fw-bold'>{assessmentTranslate[language][content.dimension]}</h4>
						<p className='fs-5 fw-bold' style={{ opacity: 0.75 }}>
							{assessmentTranslate[language][content.brief.description]}
						</p>
					</div>
				</div>
			</div>
			{content.brief.brief && (
				<div className='p-4' style={{ height: 180 }}>
					<div className='row'>
						<div className='col'>
							<p style={{ textOverflow: 'ellipsis', overflow: 'hidden', height: 96 }}>{content.brief.brief}</p>
						</div>
					</div>
					<div className='row'>
						<div className='col'>
							<Link to='#' onClick={() => handleDimension(content.dimension)}>
								{assessment.readMore}
								<HiMiniChevronRight />
							</Link>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}
