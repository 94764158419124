import { Switch, Route, Redirect } from 'react-router-dom'
import { isAuthenticate } from '../Configs/auth'

import Services from '../Views/Service'
import PerfilIndustry from '../Views/Services/Components/PerfilIndustry/PerfilIndustry'
import DashboardClima from '../Views/Services/Components/DashboardsClima/DashboardClima'
import PromoterScore from '../Views/Services/Components/PromoterScore/PromoterScore'
import Paybalance from '../Views/Services/Components/PayBalance/Index'
import PaybalanceBeneficios from '../Views/Services/Components/PayBalance/BeneficiosView'
import PaybalanceSalarioEmocional from '../Views/Services/Components/PayBalance/SalarioEmocionalView'
import Campaign from '../Views/Campaign/Campaign'
import DesempenoEmployer from '../Views/Services/Components/RemuneracionVariable/DesempenoEmployer'
import Onboarding from '../Views/Services/Components/AssignScreen/Onboarding'
import Outplacement from '../Views/Services/Components/AssignScreen/Outplacement'
import JobSearch from '../Views/Services/Components/JobDescription/JobSearch'
import JobDescription from '../Views/Services/Components/JobDescription/JobDescription'
import AbilitiesTest from '../Views/Services/Components/AssignScreen/AbilitiesTest'
import MotivationsTest from '../Views/Services/Components/AssignScreen/MotivationsTest'
import EnglishTest from '../Views/Services/Components/AssignScreen/EnglishTest'
import EnglishClasses from '../Views/Services/Components/AssignScreen/EnglishClasses'
import UnitReport from '../Views/Services/Components/PayBalance/UnitReport'

import Treinaments from '../Views/Service/Views/EntrenamientoYDesarrollo/Treinaments/Treinaments'
import AddColaborador from '../Views/Service/Views/PeopleAnalytics/Management/AddColaborador'
import MyColaboradores from '../Views/Service/Views/PeopleAnalytics/Management/MyColaboradores'
import MultipleColaboradores from '../Views/Service/Views/PeopleAnalytics/Management/MultipleColaboradores'
import TransferColaboradores from '../Views/Service/Views/PeopleAnalytics/Management/TransferColaboradores'
import BadgeHeroes from '../Views/Service/Views/Onboarding/BadgeHeroes'
import SMTM from '../Views/Service/Views/SalariosYBeneficios/SMTM/ShowMeTheMoney'
import KnowPaybalance from '../Views/Service/Views/SalariosYBeneficios/SMTM/KnowPaybalance'
import GoPaybalance from '../Views/Service/Views/SalariosYBeneficios/SMTM/GoPaybalance'
import Genes from '../Views/Service/Views/PlanSalida/Genes'
import Ombudsman from '../Views/Service/Views/Ombudsman/Ombudsman'
import EbooksYContenidos from '../Views/Service/Views/EntrenamientoYDesarrollo/EbooksYContenidos/EbookYContenidos'

import Analytics from '../Views/Analytics'
import Colaboradores from '../Views/Analytics/Colaboradores'
import Cultura from '../Views/Analytics/Cultura'
import Desempeno from '../Views/Analytics/Desempeno'
import Entrenamiento from '../Views/Analytics/Entrenamiento'
import Formacion from '../Views/Analytics/Formacion'
import Habilidades from '../Views/Analytics/Habilidades'
import HistoricoProfesional from '../Views/Analytics/HistoricoProfesional'
import Motivaciones from '../Views/Analytics/Motivaciones'
import PlanCarrera from '../Views/Analytics/PlanCarrera'
import Proposito from '../Views/Analytics/Proposito'
import Salario from '../Views/Analytics/Salario'

import Capacitaciones from '../Views/Capacitaciones/Capacitaciones'
import CareerProjection from '../Views/CareerProjection/CareerProjection'
import BusinessCulture from '../Views/Culture/BusinessCulture'

import FilePersona from '../Views/Employee/FilePersona'
import ClimaYCultura from '../Views/Employee/ClimeAndCulture'
import Culture from '../Views/Employee/Culture'
import Clima from '../Views/Employee/Clime'
import ClimaSalida from '../Views/Employee/ClimaSalida'
import Salida from '../Views/Employee/Exit'
import MotivosSalida from '../Views/Employee/ReasonsLeaving'
import B2EIndex from '../Views/Employee'
import B2EMotivaciones from '../Views/Employee/Motivations'
import B2EProposito from '../Views/Employee/Purpose'
import B2EEntrenamiento from '../Views/Employee/Training'
import B2EHabilidades from '../Views/Employee/Abilities'
import B2EDesempeno from '../Views/Employee/Desempeno'
import B2EPlanCarrera from '../Views/Employee/PlanCarrera'
import B2ECanalDenuncia from '../Views/Employee/CanalDenuncia'
import B2EIngresoDenuncia from '../Views/Employee/IngresoDenuncia'
import B2EProfileEdit from '../Views/Employee/Profile/Profile'
import InfoProf from '../Views/Employee/ProfecionalInformation'
import InfoEducacion from '../Views/Employee/Education'
import InfoSalario from '../Views/Employee/Salary'
import InternalVacanciesEmployeeView from '../Views/Employee/InternalVacanciesEmployeeView'
import ReceivedMessages from '../Views/Employee/ReceivedMessages'

import Screen from '../Views/Screen'
import Login from '../Views/Login/Login'
import SignupB2E from '../Views/SignupB2E/SignupB2E'
import Signup from '../Views/Signup/Signup'
import Packages from '../Views/Packages'

import Permission from '../Components/Errors/Permission'

import MyCompany from '../Views/MyCompany/MyComany'
import ras from '../Views/RecruitmentAndSelection'
import RecruitmentAndSelection from '../Views/RecruitmentAndSelection/RecruitmentAndSelection'
import RecruitmentProcess from '../Views/RecruitmentAndSelection/RecruitmentProcess'
import Vacancies from '../Views/RecruitmentAndSelection/Vacancies'
import MyDnaGenes from '../Views/LooknFeel/Genes/MyDnaGenes'
import EncuestaSalida from '../Views/Services/Components/EncuestaSalida/DashboardClima'
import MatchCulture from '../Views/Culture/MatchCulture'
import MisSoluciones from '../Views/Services/Components/MisSoluciones/MisSoluciones'
import ExternalPlatforms from '../Views/Service/Views/ExternalPlatforms/ExternalPlatforms'

import InternalVacancies from '../Views/Service/Views/InternalVacancies/Vacantes/InternalVacancies'
import InternalVacanciesList from '../Views/Service/Views/InternalVacanciesList/InternalVacanciesList'
import InternalVacancyApplications from '../Views/Service/Views/InternalVacanciesList/InternalVacancyApplications'

import SupportMyDna from '../Views/Service/Views/SupportMyDna/SupportMyDna'
import InternalChannel from '../Views/InternalChannel/InternalChannel'
import DashBoard from '../Views/Services/Components/PayBalance/DashBoard'

import FutureCV from '../Views/Service/Views/FutureCV/FutureCV'
import AdmissionDocuments from '../Views/Service/Views/Documents/AdmissionDocuments'
import EmployeeDocuments from '../Views/Employee/EmployeeDocuments/EmployeeDocuments'
import ServiceEmployeeDocuments from '../Views/Service/Views/Documents/EmployeeDocuments'
import SalarialBands from '../Views/Services/Components/PayBalance/Views/SalarialBands'
import CompanyDocuments from '../Views/Service/Views/CompanyDocuments/CompanyDocuments'
import GamifiedTool from '../Views/Services/Components/AssignScreen/GamifiedTool'

import { NewEmployee } from '../APP/Employee/NewEmployee'
import { ListEmployee } from '../APP/Employee/ListEmployee'
import { Assessment } from '../APP/Assessment'

import { AssessmentResult } from '../APP/AssessmentResult'
import { EmployeeEvaluation } from '../APP/EmployeeEvaluation'

import { Referencias } from '../APP/EvaluationReferencias'
import { EmployeeEvaluationSalary } from '../APP/EmployeeEvaluationSalary'

export const PrivateRoute = ({ component: Component, ...rest }) => {
	return <Route {...rest} render={props => (isAuthenticate() ? <Component {...props} /> : <Redirect to='/' />)} />
}

export const LoginRoute = () => {
	return (
		<Switch>
			<Route exact path='/outplacement' component={Outplacement} />
			<Route exact path='/' component={Login} />
			<Route exact path='/error' component={Permission} />
			<Route exact path='/signup' component={Signup} />
			<Route exact path='/signup/:email' component={SignupB2E} />
			<PrivateRoute path='/app' component={Screen} />
			<PrivateRoute path='/employee' component={B2EIndex} />
			<Route exact path='/mycompany/genes' component={MyDnaGenes} />
		</Switch>
	)
}

export const AppRoutes = ({ user }) => {
	if (user.roleId === 2) {
		return <Permission route='/employee' />
	}

	return (
		<Switch>
			<PrivateRoute path='/app/analytic' component={Analytics} />
			<PrivateRoute path='/app/paquetes' component={Packages} />
			<PrivateRoute path='/app/services' component={Services} />
			<PrivateRoute exact path='/app' component={MyCompany} />
			<PrivateRoute exact path='/app/proyeccion-carrera' component={CareerProjection} />
			<PrivateRoute exact path='/app/reporte-cultura' component={BusinessCulture} />
			<PrivateRoute exact path='/app/descripcion-posiciones' component={JobSearch} />
			<PrivateRoute exact path='/app/descripcion-posiciones/:id' component={JobDescription} />
			<PrivateRoute exact path='/app/ras' component={ras} />
		</Switch>
	)
}

export const EmployeeRoutes = () => {
	return (
		<Switch>
			<PrivateRoute exact path='/app/analytic/perfil/:id' component={Colaboradores} />
			<PrivateRoute exact path='/app/analytic/salario/:id' component={Salario} />
			<PrivateRoute exact path='/app/analytic/desempeno/:id' component={Desempeno} />
			<PrivateRoute exact path='/app/analytic/cultura/:id' component={Cultura} />
			<PrivateRoute exact path='/app/analytic/entrenamiento/:id' component={Entrenamiento} />
			<PrivateRoute exact path='/app/analytic/formacion/:id' component={Formacion} />
			<PrivateRoute exact path='/app/analytic/habilidades/:id' component={Habilidades} />
			<PrivateRoute exact path='/app/analytic/historico-profesional/:id' component={HistoricoProfesional} />
			<PrivateRoute exact path='/app/analytic/motivaciones/:id' component={Motivaciones} />
			<PrivateRoute exact path='/app/analytic/plan-carrera/:id' component={PlanCarrera} />
			<PrivateRoute exact path='/app/analytic/proposito/:id' component={Proposito} />
			<PrivateRoute exact path='/app/analytic/futurecv/:id' component={FutureCV} />
		</Switch>
	)
}

export const ServicesRoutes = () => {
	return (
		<Switch>
			<PrivateRoute exact path='/app/services/edit-industry' component={PerfilIndustry} />
			<PrivateRoute exact path='/app/services/proyeccion-carrera' component={CareerProjection} />
			<PrivateRoute exact path='/app/services/descripcion-posiciones' component={JobSearch} />
			<PrivateRoute exact path='/app/services/descripcion-posiciones/:id' component={JobDescription} />
			<PrivateRoute exact path='/app/services/encuesta-clima' component={DashboardClima} />
			<PrivateRoute exact path='/app/services/promoter-score' component={PromoterScore} />
			<PrivateRoute exact path='/app/services/capacitaciones' component={Capacitaciones} />
			<PrivateRoute exact path='/app/services/reporte-cultura' component={BusinessCulture} />
			<PrivateRoute exact path='/app/services/match-cultura' component={MatchCulture} />
			<PrivateRoute exact path='/app/services/badge-heores' component={BadgeHeroes} />
			<PrivateRoute exact path='/app/services/genes' component={Genes} />
			<PrivateRoute exact path='/app/services/ombudsman' component={Ombudsman} />
			<PrivateRoute exact path='/app/services/colaboradores' component={MyColaboradores} />
			<PrivateRoute exact path='/app/services/add' component={AddColaborador} />
			<PrivateRoute exact path='/app/services/new' component={NewEmployee} />
			<PrivateRoute exact path='/app/services/list' component={ListEmployee} />
			<PrivateRoute exact path='/app/services/multiple-add' component={MultipleColaboradores} />
			<PrivateRoute exact path='/app/services/salarios/reporte' component={SMTM} />
			<PrivateRoute exact path='/app/services/know-paybalance' component={KnowPaybalance} />
			<PrivateRoute exact path='/app/services/go-to-paybalance' component={GoPaybalance} />
			<PrivateRoute exact path='/app/services/desempeno' component={DesempenoEmployer} />
			<PrivateRoute exact path='/app/services/entrenamientos' component={Treinaments} />
			<PrivateRoute exact path='/app/services/onboarding' component={Onboarding} />
			<PrivateRoute exact path='/app/services/ebooks-y-contenidos' component={EbooksYContenidos} />
			<PrivateRoute exact path='/app/services/encuesta-de-salida' component={EncuestaSalida} />
			<PrivateRoute exact path='/app/services/admission-documents' component={AdmissionDocuments} />
			<PrivateRoute exact path='/app/services/company-documents' component={CompanyDocuments} />
			<PrivateRoute exact path='/app/services/employee-documents' component={ServiceEmployeeDocuments} />
			<PrivateRoute exact path='/app/services/abilities-test' component={AbilitiesTest} />
			<PrivateRoute exact path='/app/services/motivations-test' component={MotivationsTest} />
			<PrivateRoute exact path='/app/services/english-test' component={EnglishTest} />
			<PrivateRoute exact path='/app/services/english-classes' component={EnglishClasses} />
			<PrivateRoute exact path='/app/services/gamified-tool' component={GamifiedTool} />
			<PrivateRoute exact path='/app/services/outplacement' component={Outplacement} />
			<PrivateRoute exact path='/app/services/mis-soluciones' component={MisSoluciones} />
			<PrivateRoute exact path='/app/services/campaign' component={Campaign} />
			<PrivateRoute exact path='/app/services/elearn-platforms' component={ExternalPlatforms} />
			<PrivateRoute exact path='/app/services/internal-vacancies' component={InternalVacancies} />
			<PrivateRoute exact path='/app/services/internal-vacancies-list' component={InternalVacanciesList} />
			<PrivateRoute exact path='/app/services/internal-vacancies-applications/:id' component={InternalVacancyApplications} />
			<PrivateRoute exact path='/app/services/salarios' component={Paybalance} />
			<PrivateRoute exact path='/app/services/bands' component={SalarialBands} />
			<PrivateRoute exact path='/app/services/benefits' component={PaybalanceBeneficios} />
			<PrivateRoute exact path='/app/services/emotional-salary/' component={PaybalanceSalarioEmocional} />
			<PrivateRoute exact path='/app/services/report' component={SMTM} />
			<PrivateRoute exact path='/app/services/unit-report' component={UnitReport} />
			<PrivateRoute exact path='/app/services/dashboard' component={DashBoard} />
			<PrivateRoute exact path='/app/services/internal-channel' component={InternalChannel} />
			<PrivateRoute exact path='/app/services/support' component={SupportMyDna} />
			<PrivateRoute exact path='/app/services/salarios' component={Paybalance} />
			<PrivateRoute exact path='/app/services/salarios/beneficios' component={PaybalanceBeneficios} />
			<PrivateRoute exact path='/app/services/salarios/emocional' component={PaybalanceSalarioEmocional} />

			<PrivateRoute exact path='/app/services/assessment/competence' component={AssessmentResult} />
			<PrivateRoute exact path='/app/services/assessment/motivations' component={AssessmentResult} />

			<PrivateRoute exact path='/app/services/employee-evaluation' component={EmployeeEvaluation} />
			<PrivateRoute exact path='/app/services/referencias' component={Referencias} />
			<PrivateRoute exact path='/app/services/employee-evaluation/potential' component={EmployeeEvaluation} />
			<PrivateRoute exact path='/app/services/employee-evaluation/performance' component={EmployeeEvaluation} />
			<PrivateRoute exact path='/app/services/employee-evaluation/nine-box' component={EmployeeEvaluation} />
			<PrivateRoute exact path='/app/services/employee-salary-evaluation' component={EmployeeEvaluationSalary} />
			<PrivateRoute exact path='/app/services/employee-salary-evaluation/potential' component={EmployeeEvaluationSalary} />
			<PrivateRoute exact path='/app/services/employee-salary-evaluation/performance' component={EmployeeEvaluationSalary} />
		</Switch>
	)
}

export const EmployeeManagement = () => {
	return (
		<Switch>
			<PrivateRoute exact path='/app/gestion/transfer' component={TransferColaboradores} />
		</Switch>
	)
}

export const B2ERoutes = ({ user }) => {
	if (user.roleId === 1) {
		return <Permission route='/app' />
	}

	return (
		<Switch>
			<PrivateRoute exact path='/employee' component={FilePersona} />
			<PrivateRoute exact path='/employee/climaycultura' component={ClimaYCultura} />
			<PrivateRoute exact path='/employee/clima' component={Clima} />
			<PrivateRoute exact path='/employee/encuesta-salida' component={ClimaSalida} />
			<PrivateRoute exact path='/employee/culture' component={Culture} />
			<PrivateRoute exact path='/employee/habilidades' component={B2EHabilidades} />
			<PrivateRoute exact path='/employee/motivaciones' component={B2EMotivaciones} />
			<PrivateRoute exact path='/employee/proposito' component={B2EProposito} />
			<PrivateRoute exact path='/employee/entrenamiento' component={B2EEntrenamiento} />
			<PrivateRoute exact path='/employee/motivossalida' component={MotivosSalida} />
			<PrivateRoute exact path='/employee/salida' component={Salida} />
			<PrivateRoute exact path='/employee/desempeno' component={B2EDesempeno} />
			<PrivateRoute exact path='/employee/canaldenuncia' component={B2ECanalDenuncia} />
			<PrivateRoute exact path='/employee/ingresodenuncia' component={B2EIngresoDenuncia} />
			<PrivateRoute exact path='/employee/plandecarrera' component={B2EPlanCarrera} />
			<PrivateRoute exact path='/employee/edit-profile' component={B2EProfileEdit} />
			<PrivateRoute exact path='/employee/professionalinfo' component={InfoProf} />
			<PrivateRoute exact path='/employee/formationinfo' component={InfoEducacion} />
			<PrivateRoute exact path='/employee/salaryinfo' component={InfoSalario} />
			<PrivateRoute exact path='/employee/internalvacancies' component={InternalVacanciesEmployeeView} />
			<PrivateRoute exact path='/employee/internal-messages' component={ReceivedMessages} />
			<PrivateRoute exact path='/employee/documents' component={EmployeeDocuments} />

			<PrivateRoute exact path='/employee/assessment' component={Assessment} />
		</Switch>
	)
}

export const RecruitmentAndSelectionRoutes = () => {
	return (
		<Switch>
			<PrivateRoute exact path='/ras/recruitmentprocess' component={RecruitmentProcess} />
			<PrivateRoute exact path='/ras/recruitmentandselection' component={RecruitmentAndSelection} />
			<PrivateRoute exact path='/ras/recruitmentandselection' component={RecruitmentAndSelection} />
			<PrivateRoute exact path='/ras/vacancies' component={Vacancies} />
		</Switch>
	)
}
