// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SupportMyDna-main .bgColor-blue {
  background-color: #2B97DB;
  color: white;
}
.SupportMyDna-main .bgColor-green {
  background-color: #0AB4C4;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/Views/Service/Views/SupportMyDna/SupportMyDna.sass"],"names":[],"mappings":"AACI;EACI,yBAAA;EACA,YAAA;AAAR;AACI;EACI,yBAAA;EACA,YAAA;AACR","sourcesContent":[".SupportMyDna-main\n    .bgColor-blue\n        background-color: #2B97DB\n        color: white\n    .bgColor-green\n        background-color: #0AB4C4\n        color: white"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
