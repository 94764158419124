import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EmployerContext } from '../../Store/EmployerContext';
import { getEmployers, getEmployeeInformation } from '../../API/Analytics/employers';
import WheelColaboradores from './Components/Wheel/WheelColaboradores';

import './colaboradores.sass';
import Loading from '../../Components/Loading/Loading';

const img = 'https://assets.mydna.company/profile-placeholder.png';

const Colaboradores = () => {
  const { id } = useParams();
  const { store, actions } = useContext(EmployerContext);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    actions.setEmployerID(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const fetchEmployers = async () => {
      setLoading(true);
      const user = JSON.parse(localStorage.getItem('user'));
      const response = await getEmployers(user.id);
      const [data] = response.filter((employer) => employer.id === Number(id));
      const information_response = await getEmployeeInformation(id);
      actions.setEmployeer({
        ...data,
        information: information_response,
      });
      setLoading(false);
    };
    fetchEmployers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="content-colaboradores">
          <div className="row">
            <div className="col">
              <WheelColaboradores currentUserId={id} img={store.employeer?.asset || img} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Colaboradores;
