import axios from 'axios';

export const getKnowledge = async (id) => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_API}/knowledge/${id}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};
