import { Modal, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { capacitationsTranslate } from "../../../../i18n/translatedMessages";
import "./ModalAddTraining.sass";

const ModalAddTraining = ({
  show,
  handleClose,
  data,
  handleChange,
  handleAdd,
}) => {
  const { courseName, courseDescription } = data;
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-training modal-training-main"
    >
      <Modal.Header closeButton>
        <Modal.Title>{capacitationsTranslate.newCourse}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <div className="mb-3">
            <FormattedMessage id={"className"}>
                {msg =>(
                    <input
                      type="text"
                      className="form-control"
                      placeholder={msg}
                      name="courseName"
                      value={courseName}
                      onChange={(e) => handleChange(e)}
                    />
                )}
            </FormattedMessage>
          </div>
          <div className="mb-3">
          <FormattedMessage id={"courseDescription"}>
                {msg =>(
                    <textarea
                      className="form-control training-textarea"
                      placeholder={msg}
                      rows="5"
                      name="courseDescription"
                      value={courseDescription}
                      onChange={(e) => handleChange(e)}
                    ></textarea>
                )}
            </FormattedMessage>
          </div>
        </Row>
      </Modal.Body>
      <Modal.Footer>
      <FormattedMessage id={"createButton"}>
                {msg =>(
                    <button
                    className="btn btn-green-add-training rounded-pill text-light px-4"
                    onClick={handleAdd}
                    >
                    {msg}
                    </button>   
                )}
            </FormattedMessage>
      </Modal.Footer>
    </Modal>
  );
};
export default ModalAddTraining;
