import { IconRecruitment } from "../../Icons/Icons";
import { AiOutlineControl, AiOutlineCloseCircle } from "react-icons/ai";
import "./styles.css";
import { MdListAlt } from "react-icons/md";
import {ImCheckmark2} from "react-icons/im"
import { FaRegCalendarAlt } from "react-icons/fa";
import { RiUserStarLine } from "react-icons/ri";
const RecruitmentAndSelection = () => {
  return (
    <div className="container-fluid vh-100">
      <div className="row pt-5 align-items-center">
        <div className="col-1">
          <span className="title-icon p-3 m-auto">
            <IconRecruitment size={20} />
          </span>
        </div>
        <div className="col-md-4">
          <h2>Recrutamiento y selección</h2>
        </div>
        <div className="ms-auto col-1">
          <div className="rounded-circle p-3">
            <img
              className="img-fluid"
              alt="..."
              src="https://assets.mydna.company/industry2.svg"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 d-flex">
          <input
            className="form-control me-2 rounded-pill"
            type="search"
            placeholder="Buscar Vacante"
            aria-label="Search"
          />
          <button className="btn rounded-pill btn-outline-info d-flex align-items-center">
            <AiOutlineControl />
            Filtro
          </button>
        </div>
        <div className="ms-md-auto col-md-2">
          <button className="mt-3 mt-md-0 ms-md-auto btn shadow btn-nv rounded-pill d-flex align-items-center">
            <AiOutlineCloseCircle />
            Nueva Vacante
          </button>
        </div>
      </div>
      <div className="row pt-4">
        <div className="col-12">
          <div className="rys-table shadow">
            <table className="table rounded-top">
              <thead>
                <tr>
                  <th scope="col">
                    <MdListAlt size={20} />
                    <span className="ps-2">Vacante</span>
                  </th>
                  <th scope="col">
                    <ImCheckmark2 size={20} />
                    <span className="ps-2">Status</span>
                  </th>
                  <th scope="col">
                    <FaRegCalendarAlt size={20} />
                    <span className="ps-2">Criado em</span>
                  </th>
                  <th scope="col">
                    <RiUserStarLine size={20} />
                    <span className="ps-2">Responsável</span>
                  </th>
                  <th scope="col">
                    <FaRegCalendarAlt size={20} />
                    <span className="ps-2">Finalizado em</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                  <td>@mdo</td>
                  <td>@mdo</td>
                </tr>
                <tr>
                  <td>Jacob</td>
                  <td>Thornton</td>
                  <td>@fat</td>
                  <td>@fat</td>
                  <td>@fat</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RecruitmentAndSelection;
