export const UploadFileRequest = async (acceptedFiles) => {
  try {
    const token = localStorage.getItem("accessToken");
    const tokenJson = JSON.parse(token);
    const formData = new FormData();
    formData.append("file", acceptedFiles);
    const resp = await fetch(
      `${process.env.REACT_APP_API}/api/traininganddevelopment/upload`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${tokenJson}`,
        },
        body: formData,
      }
    );
    return resp;
  } catch (err) {
    console.error(err);
  }
};
