import { useContext } from "react";
import { CompanyContext } from "./../../../../../Store/CompanyContext";
import { trainingsTranslate } from "./../../../../../i18n/translatedMessages";
import eybookListPT from "./eybookListPT.json";
import eybookListES from "./eybookListES.json";

import "./EbookYContenidos.sass";

const EbooksYContenidos = () => {
    const { store } = useContext(CompanyContext);

    return (
        <div className="row">
            <div className="row pb-6">
                <div className="col d-flex offset-1">
                    <div className="header-icon">
                        <img
                            src="https://assets.mydna.company/iconoCard_dashboard-empresa-nobg.svg"
                            alt=""
                            className="img-fluid"
                        />
                    </div>
                    <h1>{trainingsTranslate.ebooks}</h1>
                </div>
            </div>
            <div className="col-10 d-flex justify-content-center offset-1">
                <div className="card shadow">
                    <div className="card-body">
                        <div className="container-fluid">
                            <div className="row row-cols-1 row-cols-md-3 g-3 pt-2">
                                {store.language === "ES" ? (
                                    <>
                                        {eybookListES.map((item) => (
                                            <div
                                                className={`col text-center book-pdf ${item.class}`}
                                                key={item.id}
                                            >
                                                <a 
                                                    href={item.link}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <span>{item.name}</span>
                                                    <img
                                                        src="https://mydnadigital.com/_next/image/?url=https%3A%2F%2Fassets.mydna.company%2Flanding%2Ficonos%2FDescargar.webp&w=48&q=75"
                                                        alt=""
                                                        height="20px"
                                                    />
                                                </a>
                                            </div>
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        {eybookListPT.map((item) => (
                                            <div
                                                className={`col text-center book-pdf ${item.class}`}
                                                key={item.id}
                                                style={{
                                                    margin: 5,
                                                    borderRadius: 27,
                                                }}
                                            >
                                                <a
                                                    href={item.link}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <span>{item.name}</span>
                                                    <img
                                                        src="https://mydnadigital.com/_next/image/?url=https%3A%2F%2Fassets.mydna.company%2Flanding%2Ficonos%2FDescargar.webp&w=48&q=75"
                                                        alt=""
                                                        height="20px"
                                                    />
                                                </a>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default EbooksYContenidos;
