import { useState, useEffect, useContext } from 'react'
import { Button, InputGroup, FormControl, Accordion } from 'react-bootstrap'
import { MdUpload } from 'react-icons/md'
import Swal from 'sweetalert2'

import { postEmployee } from '../../../../../API/Employee/Employee'
import { getAreaList } from '../../../../../API/Lists/area'
import { getLevelList } from '../../../../../API/Lists/level'
import { myCollaboratorsTranslate } from '../../../../../i18n/translatedMessages'

import { CompanyContext } from '../../../../../Store/CompanyContext'
import ModalResponseStatus from '../../../../../Components/ModalResponseStatus/ModalResponseStatus'
import { FormattedMessage, useIntl } from 'react-intl'

import './addColaborador.sass'
import { translate as translateAreas } from '../../../../../i18n/Library/areasCorrelation'
import { translate as translateLevel } from '../../../../../i18n/Library/hierarchicalLevel'

const AddColaborador = () => {
	const getLanguage = useIntl()
	const language = getLanguage.locale === 'PT-BR' ? 'pt' : 'es'

	const { store, actions } = useContext(CompanyContext)
	const [companyIdFromSMTM, setCompanyIdFromSMTM] = useState(null)

	const company = JSON.parse(localStorage.getItem('user'))

	const [employerData, setEmployerData] = useState({
		name: '',
		email: '',
		profileImg: 'https://raw.githubusercontent.com/Ashwinvalento/cartoon-avatar/master/lib/images/male/45.png',
		companyId: company.id,
		levelId: null,
		areaId: null,
		jobId: '',
		subAreaId: '',
		countryId: '',
		englishLevel: '0',
		seniorityLevel: '0',
		gradeLevel: '0',
		salary: 0,
		smtmCompanyId: 0,
	})
	const [areas, setAreas] = useState([])
	const [levels, setLevels] = useState([])

	useEffect(() => {
		const getRequests = async () => {
			const areaList = await getAreaList()
			const levelList = await getLevelList()
			setAreas([...areaList])
			setLevels([...levelList])
		}
		getRequests()
		getIdSmtmByCompanyName()
	}, [])

	const getIdSmtmByCompanyName = async () => {
		const company = JSON.parse(localStorage.getItem('user'))

		await fetch(`https://smtm.co/v1/getCompanyIdFromMyDna?name=${JSON.parse(localStorage.getItem('user')).name}`)
			.then(response => response.json())
			.then(data => setEmployerData({ ...employerData, companyId: company.id, smtmCompanyId: data }))
			.catch(() => {
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Algo salió mal !',
				})
			})
	}

	const handleSubmission = async () => {
		if (employerData.name === '' || employerData.email === '' || employerData.countryId === '' || employerData.areaId === '' || employerData.levelId === '' || employerData.jobId === '' || employerData.subAreaId === '') {
			Swal.fire({
				title: getLanguage.locale == 'ES' ? 'Atención!' : 'Atenção!',
				text: getLanguage.locale == 'ES' ? 'Por favor complete al menos toda la información requerida' : 'Por favor, complete ao menos todas as informações obrigatórias!',
				icon: 'warning',
				confirmButtonText: getLanguage.locale == 'ES' ? 'Volver al Formulario' : 'Voltar ao Formulário',
			})
		} else {
			const postEmp = await postEmployee(employerData)

			if (postEmp.status < 200 || postEmp.status > 299) {
				if (postEmp.status > 399 && postEmp.status < 500) {
					actions.setResponseStatus(false)
					actions.setMessageResponseStatus('Ha ocurrido un error!')
					actions.setShowModalResponseStatus(true)
				} else {
					actions.setResponseStatus(false)
					actions.setMessageResponseStatus('Ha ocurrido un error!')
					actions.setShowModalResponseStatus(true)
				}
			} else {
				actions.setResponseStatus(true)
				actions.setMessageResponseStatus(getLanguage.locale == 'ES' ? 'Colaborador agregado con éxito!' : 'Colaborador registrado com exito!')
				actions.setShowModalResponseStatus(true)
			}
		}
	}

	const handleClose = () => {
		actions.setShowModalResponseStatus(false)
	}

	const handleRecharge = () => {
		window.location.reload()
	}

	const handleInputChange = e => {
		setEmployerData({
			...employerData,
			[e.target.name]: e.target.name === 'levelId' ? parseInt(e.target.value) : e.target.name === 'areaId' ? parseInt(e.target.value) : e.target.value,
		})
	}

	return (
		<div className='add-colaborator'>
			<div className='row'>
				<div className='w-100'>
					<h3>Registrar Colaborador</h3>
					<hr />
				</div>
			</div>
			<ModalResponseStatus
				show={store.showModalResponseStatus}
				handleClose={handleClose}
				message={store.messageResponseStatus}
				linkSendTo={'/app/services/list'}
				recharge={handleRecharge}
				btnRight={'Ver lista'}
				successMessage={store.responseStatus}
			/>

			<div className='row w-100 justify-content-center'>
				<div className='col-10'>
					<Accordion className='w-100' defaultActiveKey='0'>
						<Accordion.Item eventKey='0'>
							<Accordion.Header>{getLanguage.locale == 'ES' ? 'Información Requerida' : 'Informação Obrigatória'}</Accordion.Header>
							<Accordion.Body>
								<div className='row'>
									<div className='col-12'>
										<InputGroup className='mb-3'>
											<select name='countryId' className='w-100' onChange={handleInputChange}>
												<option>{getLanguage.locale == 'ES' ? 'País de la posición del colaborador' : 'País da posição do Colaborador'}</option>
												<option value='1' data-countryname='Chile'>
													Chile
												</option>
												<option value='2' data-countryname='Argentina'>
													Argentina
												</option>
												<option value='3' data-countryname='Perú'>
													{getLanguage.locale == 'ES' ? 'Perú' : 'Peru'}
												</option>
												<option value='4' data-countryname='Brasil'>
													Brasil
												</option>
												<option value='5' data-countryname='Colombia'>
													{getLanguage.locale == 'ES' ? 'Colombia' : 'Colômbia'}
												</option>
												<option value='6' data-countryname='Mexico'>
													{getLanguage.locale == 'ES' ? 'Mexico' : 'México'}
												</option>
												<option value='7' data-countryname='Costa Rica'>
													{getLanguage.locale == 'ES' ? 'Costa Rica' : 'Costa Rica'}
												</option>
											</select>
										</InputGroup>
									</div>
									<div className='col-6'>
										<InputGroup className='mb-3'>
											<FormattedMessage id={'collaboratorName'}>{msg => <FormControl placeholder={msg} name='name' aria-label='Nombre' aria-describedby='basic-addon1' onChange={handleInputChange} />}</FormattedMessage>
										</InputGroup>
									</div>
									<div className='col-6'>
										<InputGroup className='mb-3'>
											<FormattedMessage id={'collaboratorEmail'}>{msg => <FormControl placeholder={msg} name='email' aria-label='Mail' aria-describedby='basic-addon1' onChange={handleInputChange} />}</FormattedMessage>
										</InputGroup>
									</div>
									<div className='col-6'>
										<InputGroup className='mb-3'>
											<select name='areaId' className='w-100 selectAddColaborador' onChange={handleInputChange}>
												<option>Areas</option>
												{areas.map(area => {
													return (
														<option key={area.id} value={area.id}>
															{translateAreas[language][area.profileArea]}
														</option>
													)
												})}
											</select>
										</InputGroup>
									</div>
									<div className='col-6'>
										<InputGroup className='mb-3'>
											<select name='levelId' className='w-100 selectAddColaborador' onChange={handleInputChange}>
												<option>{getLanguage.locale == 'ES' ? 'Nível Jerárquico' : 'Nível Hierárquico'}</option>
												{levels.map(level => {
													return (
														<option key={level.id} value={level.id}>
															{translateLevel[language][level.PositionN]}
														</option>
													)
												})}
											</select>
										</InputGroup>
									</div>
									<div className='col-6'>
										<InputGroup className='mb-3'>
											<FormControl placeholder={getLanguage.locale == 'ES' ? 'Nombre del Cargo' : 'Nome do Cargo'} name='jobId' aria-label='Job' aria-describedby='basic-addon1' onChange={handleInputChange} />
										</InputGroup>
									</div>
									<div className='col-6'>
										<InputGroup className='mb-3'>
											<FormControl placeholder='Subarea' name='subAreaId' aria-label='Subarea' aria-describedby='basic-addon1' onChange={handleInputChange} />
										</InputGroup>
									</div>
								</div>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item className='w-100' eventKey='1'>
							<Accordion.Header>{getLanguage.locale == 'ES' ? 'Información Opcional' : 'Informação Opcional'}</Accordion.Header>
							<Accordion.Body>
								<div className='row'>
									<div className='col-6'>
										<InputGroup className='mb-3'>
											<select name='englishLevel' className='w-100' onChange={handleInputChange}>
												<option>{getLanguage.locale == 'ES' ? 'Nivel de Inglés' : 'Nível de Inglês'}</option>
												<option value={'0'}>Indiferente</option>
												<option value={'1'}>Básico</option>
												<option value={'2'}>{getLanguage.locale == 'ES' ? 'Medio' : 'Médio'}</option>
												<option value={'3'}>{getLanguage.locale == 'ES' ? 'Avanzado' : 'Avançado'}</option>
											</select>
										</InputGroup>
									</div>

									<div className='col-6'>
										<InputGroup className='mb-3'>
											<select name='seniorityLevel' className='w-100' onChange={handleInputChange}>
												<option>{getLanguage.locale == 'ES' ? 'Señority' : 'Senioridade'}</option>
												<option value={'0'}>Indiferente</option>
												<option value={'1'}>{getLanguage.locale == 'ES' ? 'Junior' : 'Júnior'}</option>
												<option value={'2'}>{getLanguage.locale == 'ES' ? 'Mediano' : 'Pleno'}</option>
												<option value={'3'}>{getLanguage.locale == 'ES' ? 'Senior' : 'Sênior'}</option>
											</select>
										</InputGroup>
									</div>

									<div className='col-6'>
										<InputGroup className='mb-3'>
											<select name='gradeLevel' className='w-100' onChange={handleInputChange}>
												<option>{getLanguage.locale == 'ES' ? 'Grado' : 'Grau Acadêmico'}</option>
												<option value={'0'}>Indiferente</option>
												<option value={'1'}>{getLanguage.locale == 'ES' ? 'Graduación' : 'Graduação'}</option>
												<option value={'2'}>MBA</option>
												<option value={'3'}>MBA Internacional</option>
											</select>
										</InputGroup>
									</div>

									<div className='col-6'>
										<InputGroup className='mb-3'>
											<FormControl placeholder={'Salario'} name='salary' onChange={handleInputChange} />
										</InputGroup>
									</div>

									<div className='col-6'>
										<InputGroup className='mb-3'>
											<FormControl placeholder={getLanguage.locale == 'ES' ? 'Salario Variable (bonificaciones)' : 'Salário Variavel (bonificações)'} name='variableSalary' onChange={handleInputChange} />
										</InputGroup>
									</div>
								</div>
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
				</div>
				<div className='col-5'>
					<Button className='btn-degrade' onClick={handleSubmission}>
						<MdUpload size={20} /> {myCollaboratorsTranslate.loadCollaborator}
					</Button>
				</div>
			</div>
		</div>
	)
}
export default AddColaborador
