export const assessmentTranslate = {
	es: {
		Organizacional: 'Organizacional',
		Persona: 'Persona',
		Social: 'Social',
		Líder: 'Líder',
		Yo: 'Yo',
		'Potencial Ejecutivo': 'Potencial Ejecutivo',
		'Potencial Personal': 'Potencial Personal',
		'Potencial Interpersonal': 'Potencial Interpersonal',
		'Potencial de Liderazgo': 'Potencial de Liderazgo',
	},

	pt: {
		Organizacional: 'Organizacional',
		Persona: 'Pessoal',
		Social: 'Social',
		Líder: 'Líder',
		Yo: 'Eu',
		'Potencial Ejecutivo': 'Potencial Executivo',
		'Potencial Personal': 'Potencial Pessoal',
		'Potencial Interpersonal': 'Potencial Interpessoal',
		'Potencial de Liderazgo': 'Potencial de Liderança',
	},
};


