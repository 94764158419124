import { useState } from 'react'
import { Link } from 'react-router-dom'
import { MdUploadFile, MdSimCardDownload, MdArrowBack } from 'react-icons/md'
import { multipleCollTranslate } from '../../../../../i18n/translatedMessages'
import Upload from '../../../../../Components/Upload/Upload'

import './multipleColaboradores.sass'

const MultipleColaboradores = () => {
	const user = JSON.parse(localStorage.getItem('user'))

	const [fileName, setFileName] = useState(null)
	const [progress, setProgress] = useState(0)

	return (
		<div className='multiple-colaboradores'>
			<div className='content-body'>
				<p>{multipleCollTranslate.collBodyText}</p>

				<a className='btn-download' href='https://assets.mydna.company/MyDNA_Massive_E.E_updated.xlsx'>
					<MdSimCardDownload size={30} />
					{multipleCollTranslate.collDownloadSheet}
				</a>

				{fileName === null && (
					<label className='btn-upload'>
						<Upload userID={user.id} setProgress={setProgress} setFileName={setFileName} folder='documents/xlsx' />
						<MdUploadFile size={30} />
						{multipleCollTranslate.collUpSheet}
					</label>
				)}

				{fileName !== null && (
					<>
						<div className='file-upgrade'>
							<div className='file-name'>
								<b>{multipleCollTranslate.collArchive}:</b> {fileName}
							</div>
							<div className='bar-progress'>
								<div className='progress' style={{ width: `${progress}%` }}></div>
								<span className='upgrade-progress'>{progress}%</span>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	)
}
export default MultipleColaboradores
