import { useState } from 'react'
import aws from 'aws-sdk'
import Swal from 'sweetalert2'
import { read, utils } from 'xlsx'

import './upload.sass'
import { useEffect } from 'react'

aws.config.update({
	accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
	secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
})

const myBucket = new aws.S3({
	region: 'us-east-1',
})

const Upload = ({ setProgress, setFileName, userID, folder }) => {
	useEffect(() => {
		getIdSmtmByCompanyName()
	}, [])

	const [newData, setNewData] = useState(false)

	const handleChange = async event => {
		await uploadFile(event.target.files[0])
	}

	const uploadFile = file => {
		Swal.fire({
			title: 'Procesando ⏳',
			text: 'Estamos procesando los datos, por favor espere.',
			icon: 'info',
			showCancelButton: false,
			showConfirmButton: false,
		})
		const name = file.name.split('.')
		const stringId = userID.toString()
		// console.log(`${stringId}.${name[1]}`);
		const params = {
			Body: file,
			Bucket: `${process.env.REACT_APP_AWS_BUCKET_NAME}/${folder}`,
			Key: `${stringId}.${name[1]}`,
		}

		myBucket
			.putObject(params)
			.on('httpUploadProgress', evt => {
				setProgress(Math.round((evt.loaded / evt.total) * 100))
				setFileName(file.name)
			})
			.send(err => {
				if (err) console.error(err)
				Swal.fire({
					title: 'Gracias por la espera. 👌',
					text: 'Usuarios cargados exitosamente',
					icon: 'success',
					confirmButtonText: 'Ok',
				}).then(() => {
					setNewData(!newData)
				})
			})
	}

	const readUploadFile = e => {
		e.preventDefault()
		if (e.target.files) {
			const reader = new FileReader()
			reader.onload = async e => {
				const data = e.target.result
				const workbook = read(data, { type: 'array' })
				const sheetName = workbook.SheetNames[0]
				const worksheet = workbook.Sheets[sheetName]
				const json = utils.sheet_to_json(worksheet)

				// console.log(json);

				await newPositionXlsx(json)
					.then(response => response.json())
					.then(data => {
						// console.log(data);
					})
					.catch(error => {
						console.error(error)
						// console.log('Dejo de cargar')

						setNewData(true)
					})
			}
			reader.readAsArrayBuffer(e.target.files[0])
		}
	}

	const newPositionXlsx = async position => {
		try {
			let company_id = companyIdFromSMTM
			const body = position
			const resp = await fetch(`https://smtm.co/v1/position_companies?company_id=${company_id}&fromxls=${'yes'}`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(body),
			})
			const data = await resp.json()
			// console.log("La respuesta es!")
			// console.log(data);
		} catch (error) {
			// console.log(error);
		}
	}

	const [companyIdFromSMTM, setCompanyIdFromSMTM] = useState(null)

	const getIdSmtmByCompanyName = async () => {
		await fetch(`https://smtm.co/v1/getCompanyIdFromMyDna?name=${JSON.parse(localStorage.getItem('user')).name}`)
			.then(response => response.json())
			.then(data => setCompanyIdFromSMTM(data))
			.catch(() =>
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Algo salió mal !',
				})
			)
	}

	return (
		<>
			<input className='input-upload' type='file' onChange={handleChange} />

			{/* Por favor, no borrar */}
			{/* <input
        type="file"
        name="upload"
        id="upload"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        onChange={readUploadFile}
    />

    <button onClick={() => { getIdSmtmByCompanyName() }}>Get</button> */}
		</>
	)
}
export default Upload
