// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-colaborator {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 100px;
}
.add-colaborator .selectAddColaborador {
  height: 35px;
}
.add-colaborator .btn-back {
  width: 100%;
}
.add-colaborator .btn-back .item-circle {
  width: 40px;
  height: 40px;
  border-radius: 50rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid;
  color: #6e6e6e;
}
.add-colaborator .btn-back .item-circle svg {
  fill: #6e6e6e;
}
.add-colaborator select {
  border: 1px solid #ced4da;
  color: #6e6e6e;
  border-radius: 0.25rem !important;
  padding: 0.375rem 0.75rem;
}
.add-colaborator img {
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
}
.add-colaborator .btn-degrade {
  background: linear-gradient(45deg, #40c3fe, #32d8d9);
  border: none;
  border-radius: 50rem;
  width: 100%;
  margin-top: 15px;
}`, "",{"version":3,"sources":["webpack://./src/Views/Service/Views/PeopleAnalytics/Management/addColaborador.sass"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,gBAAA;AACJ;AACI;EACI,YAAA;AACR;AACI;EACI,WAAA;AACR;AACQ;EACI,WAAA;EACA,YAAA;EACA,oBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;EACA,cAAA;AACZ;AACY;EACI,aAAA;AAChB;AACI;EACI,yBAAA;EACA,cAAA;EACA,iCAAA;EACA,yBAAA;AACR;AACI;EACI,YAAA;EACA,aAAA;EACA,mBAAA;AACR;AACI;EACI,oDAAA;EACA,YAAA;EACA,oBAAA;EACA,WAAA;EACA,gBAAA;AACR","sourcesContent":[".add-colaborator\n    display: flex\n    flex-direction: column\n    align-items: center\n    padding: 0 100px\n\n    & .selectAddColaborador\n        height: 35px\n\n    & .btn-back\n        width: 100%\n\n        & .item-circle\n            width: 40px\n            height: 40px\n            border-radius: 50rem\n            display: flex\n            align-items: center\n            justify-content: center\n            border: 3px solid\n            color: #6e6e6e\n\n            & svg\n                fill: #6e6e6e\n\n    & select\n        border: 1px solid #ced4da\n        color: #6e6e6e\n        border-radius: 0.25rem !important\n        padding: .375rem .75rem\n\n    & img\n        width: 200px\n        height: 200px\n        margin-bottom: 20px\n\n    & .btn-degrade\n        background: linear-gradient(45deg, #40c3fe, #32d8d9 )\n        border: none\n        border-radius: 50rem\n        width: 100%\n        margin-top: 15px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
