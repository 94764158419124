import React, { useState, useEffect, useCallback } from 'react'
import { HiCheck } from 'react-icons/hi2'

import { parseTypeCurrency, transformLevelStringToLevelId } from './TransformLevelString'
import SalaryComparativeChart from './SalaryComparativeChart'
import Loading from '../../../../../Components/Loading/Loading'
import { SelectSearch } from './SelectSearch'

import './SalaryComparative.css'

import { getSalaryMarket } from '../../../../../API/Services/Salary/SalaryMarket'
import { postSalaryCompany, getSalaryCompany, updateSalaryCompany } from '../../../../../API/Services/Salary/SalaryCompany'
import Mask from '../../../../../Utils/masks'

import './SalaryComparative.css'
import { FormattedMessage, useIntl } from 'react-intl'
import { SalaryComparativeTranslate } from '../../../../../i18n/translatedMessages'
import { ToogleComparative } from './ToogleComparative'

const getQuarterInfo = percentage => {
	if (percentage < -20) return { quarter: 0, color: '#f84228' }
	if (percentage >= -20 && percentage < -10) return { quarter: 1, color: '#f59e0b' }
	if (percentage >= -10 && percentage < 0) return { quarter: 2, color: '#f59e0b' }
	if (percentage >= 0 && percentage < 10) return { quarter: 3, color: '#41c9ff' }
	if (percentage >= 10 && percentage < 20) return { quarter: 4, color: '#25e6c4' }
	if (percentage >= 20) return { quarter: 5, color: '#f84228' }

	return { quarter: -1, color: '#d9d9f2' }
}

export const SalaryComparative = ({ levelSmtm, countryId, salaryPosition, currentSalary, showSalaryCompany, email, salaryMarket }) => {
	const language = useIntl()
	const [comparativeOption, setComparativeOption] = useState(2)

	const [uniqueKey, setUniqueKey] = useState(null)

	const [mydna, setMydna] = useState(null)
	const [myIndustry, setMyIndustry] = useState(null)
	const [band, setBand] = useState(null)

	const [parseLevelString] = useState(transformLevelStringToLevelId(levelSmtm))
	const [salaryMarketData, setSalaryMarketData] = useState(null)
	const [typeCurrency] = useState(parseTypeCurrency(countryId))

	const getData = useCallback(async () => {
		//const resp = await getSalaryMarket(countryId, parseLevelString);
		setSalaryMarketData({
			country_id: countryId,
			salary_mean: salaryMarket,
		})
		setMydna({
			country_id: countryId,
			salary_mean: salaryMarket,
		})
	}, [countryId, parseLevelString])

	const getEmailData = useCallback(async () => {
		const response = await getSalaryCompany(email)

		if (!response) {
			setMyIndustry({
				country_id: 1,
				salary_mean: 0,
			})
			setSalaryInput(0)
			return
		}

		const data = {
			...response,
			salary_mean: response.company_salary_mean,
		}
		setBand(response.salaryBand_id)
		setSalaryInput(response.company_salary_mean)
		setMyIndustry(data)
	}, [email])

	useEffect(() => {
		getData()
		getEmailData()
	}, [getEmailData, getData])

	const formatCurrency = (value, currency) => {
		return new Intl.NumberFormat(undefined, {
			currency,
		}).format(value)
	}

	const calculateSalaryDifference = () => {
		if (currentSalary === 0) {
			return '--'
		} else {
			return `${formatCurrency(Math.abs(parseInt(salaryMarketData.salary_mean) - parseInt(currentSalary)), typeCurrency)}`
		}
	}

	const calculateEmployeeSalary = () => {
		if (currentSalary === 0) {
			return '--'
		} else {
			return `${formatCurrency(parseInt(currentSalary), typeCurrency)}`
		}
	}

	const calculatePercentageDifference = () => {
		if (currentSalary === 0) {
			return language.locale == 'ES' ? 'No informado' : 'Não informado'
		} else {
			const percentage = ((currentSalary - salaryMarketData.salary_mean) / salaryMarketData.salary_mean) * 100
			return `${formatCurrency(parseInt(percentage), typeCurrency)}%`
		}
	}

	const calculateCurrentQuarter = () => {
		if (currentSalary === 0) {
			return language.locale == 'ES' ? 'No informado' : 'Não informado'
		} else {
			const percentage = ((currentSalary - salaryMarketData.salary_mean) / salaryMarketData.salary_mean) * 100
			const { quarter } = getQuarterInfo(percentage)
			return getQuarterDescription(quarter)
		}
	}

	const getColorCurrentQuarter = () => {
		if (currentSalary === 0) {
			return language.locale == 'ES' ? 'No informado' : 'Não informado'
		} else {
			const percentage = ((currentSalary - salaryMarketData.salary_mean) / salaryMarketData.salary_mean) * 100
			const { color } = getQuarterInfo(percentage)
			return color
		}
	}

	function getQuarterDescription(currentQuarter) {
		if (currentQuarter === 0) {
			return <FormattedMessage id={'belowRange'} />
		} else if (currentQuarter === 1) {
			return <FormattedMessage id={'firstQuartile'} />
		} else if (currentQuarter === 2) {
			return <FormattedMessage id={'secQuartile'} />
		} else if (currentQuarter === 3) {
			return <FormattedMessage id={'thirdQuartile'} />
		} else if (currentQuarter === 4) {
			return <FormattedMessage id={'fourthQuartile'} />
		} else if (currentQuarter === 5) {
			return <FormattedMessage id={'aboveRange'} />
		} else {
			return <FormattedMessage id={'inProcess'} />
		}
	}

	const [salaryInput, setSalaryInput] = useState(null)

	const handleChangeSalaryInput = event => {
		const text = Mask.removeDots(event.target.value)
		setSalaryInput(text)
	}

	const saveCompanySalaryMean = async () => {
		const userCompany = localStorage.getItem('user')
		const userCompanyData = JSON.parse(userCompany)

		const data = {
			company_id: userCompanyData.id,
			country_id: countryId,
			company_salary_mean: salaryInput,
			salaryBand_id: band,
			employer_email: email,
		}

		if (myIndustry.salary_mean !== 0) {
			await updateSalaryCompany(data)
		} else {
			await postSalaryCompany(data)
		}
		getEmailData().then(() => {
			setUniqueKey(Date.now())
		})
	}

	const handleOptionComparative = comparativeOption => {
		if (comparativeOption === 2) {
			setSalaryMarketData(mydna)
		}

		if (comparativeOption === 1) {
			setSalaryMarketData(myIndustry)
		}

		setComparativeOption(comparativeOption)
	}

	return (
		<tr className='mt-2'>
			<td colSpan='9'>
				{salaryMarketData ? (
					<div className='comparative-container d-flex flex-column'>
						<div className='mb-3'>
							<h5>
								<div className='position-relative d-inline me-2 fw-bold'>Comparativo Salarial</div>
								<ToogleComparative handleClick={handleOptionComparative} comparative={comparativeOption} />
							</h5>
						</div>
						{comparativeOption === 1 && (
							<div className='row'>
								<div className='col-8'>
									<div className='row px-2'>
										<div className='col-6'>
											<SelectSearch setSalary={setSalaryInput} setBand={setBand} bandId={band} countryId={countryId} />
										</div>
									</div>
								</div>
							</div>
						)}
						<>
							<div className='row'>
								<div className='col-8'>
									<div className='row d-flex align-items-center'>
										<div className='col-6 mb-3'>
											<div className='labelContainer mx-2'>
												<div className='mb-2'>
													<span>{SalaryComparativeTranslate.midpointText}</span>
												</div>
												{comparativeOption === 1 ? (
													<>
														{salaryInput !== null && (
															<div className='my-industry'>
																<input
																	className='my-industry-input'
																	type='text'
																	value={Mask.setDots(salaryInput)}
																	onChange={handleChangeSalaryInput}
																	onFocus={() => {
																		setSalaryInput()
																		setBand(null)
																	}}
																/>

																<button
																	className='btn my-industry-button'
																	onClick={() => {
																		saveCompanySalaryMean()
																	}}
																>
																	<HiCheck size={20} />
																</button>
															</div>
														)}
													</>
												) : (
													<>
														<div>
															<h5>{!isNaN(salaryMarketData.salary_mean) ? formatCurrency(salaryMarketData.salary_mean, typeCurrency) : '--'}</h5>
														</div>
													</>
												)}
											</div>
										</div>
										<div className='col-6'>
											<div className='labelContainer mx-2 mb-2'>
												<div className='mb-2'>
													<span>
														{SalaryComparativeTranslate.salaryDifferenceText}({typeCurrency})
													</span>
												</div>
												<div>
													<h5>{comparativeOption === 1 ? (myIndustry.salary_mean !== 0 ? calculateSalaryDifference() : '--') : !isNaN(salaryMarketData.salary_mean) ? calculateSalaryDifference() : '--'}</h5>
												</div>
											</div>
										</div>
										<div className='col-6'>
											<div className='labelContainer mx-2 mb-2'>
												<div className='mb-2'>
													<span>{SalaryComparativeTranslate.salaryColaborator}</span>
												</div>
												<div>
													<h5>{currentSalary === 0 ? <span>Favor Informar</span> : calculateEmployeeSalary()}</h5>
												</div>
											</div>
										</div>
										<div className='col-6'>
											<div className='labelContainer mx-2 mb-2'>
												<div className='mb-2'>
													<span>
														{SalaryComparativeTranslate.salaryDifferenceText}
														(%)
													</span>
												</div>
												<div>
													<h5>
														{currentSalary === 0 ? (
															<span>--</span>
														) : (
															<>{comparativeOption === 1 ? (myIndustry.salary_mean !== 0 ? calculatePercentageDifference() : '--') : !isNaN(salaryMarketData.salary_mean) ? calculatePercentageDifference() : '--'}</>
														)}
													</h5>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className='col-4'>
									<div
										style={{
											backgroundColor: getColorCurrentQuarter(),
											color: getColorCurrentQuarter() === '#d9d9f2' ? '#444258' : '#ffffff',
										}}
										className='quarterContainer w-100 h-100 d-flex flex-column'
									>
										<span>{SalaryComparativeTranslate.payrollStatus}</span>
										<span className='quartilStyle'>{calculateCurrentQuarter()}</span>
									</div>
								</div>
							</div>
							<div className='row'>
								<SalaryComparativeChart
									key={uniqueKey}
									salaryMean={salaryMarketData.salary_mean}
									currentSalary={currentSalary}
									salaryPosition={currentSalary}
									formatCurrency={formatCurrency}
									typeCurrency={typeCurrency}
									salarymeanUpdate={salaryInput}
									refresh={uniqueKey !== null && true}
								/>
							</div>
						</>
					</div>
				) : (
					<div>
						<Loading />
					</div>
				)}
			</td>
		</tr>
	)
}
