import React, { useCallback, useEffect, useState } from "react";
import { BsFillFileEarmarkTextFill } from "react-icons/bs";
import AccordionCompanyDocuments from "./AccordionCompanyDocuments";
import CompanyDocfiles from "../../../../API/Documents/CompanyDocfiles.api";
import Loading from "../../../../Components/Loading/Loading";

import "./employeeDocuments.sass";
import { documentsTranslate } from "../../../../i18n/translatedMessages";
import { useIntl } from "react-intl";

const CompanyDocuments = () => {
  const getLanguage = useIntl();
  const language = getLanguage.locale === "PT-BR" ? "pt" : "es";
  const [documentsEmployee, setDocumentsEmployee] = useState();
  const [isLoading, setLoading] = useState(false);

  const loadEmployeeDocuments = useCallback(async () => {
    setLoading(true);
    setDocumentsEmployee(
      await CompanyDocfiles.getEmployeeDocfiles("1353")
    );
    setLoading(false);
  }, []);

  useEffect(() => {
    loadEmployeeDocuments();
  }, [loadEmployeeDocuments]);

  return (
    <div className="row EmployeeDocuments">
      <div className="row header">
        <div className="col d-flex mb-4">
          <div className="icon">
            <BsFillFileEarmarkTextFill size={30} />
          </div>
          <h1>{documentsTranslate.documentsTitle}</h1>
        </div>
      </div>
      <div className="row">
        <div className="col">{documentsTranslate.docDescriptionEmp}</div>
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="mt-3">
          <AccordionCompanyDocuments documents={documentsEmployee} loadEmployee={loadEmployeeDocuments} />
        </div>
      )}
    </div>
  );
};

export default CompanyDocuments;
