const getState = ({ getStore, getActions, setStore }) => {
  return {
    store: {
      login: {},
      language: 'ES',
      responseStatus: false,
      showModalResponseStatus: false,
      messageResponseStatus: "",
    },
    actions: {
      setLogin: (data) => {
        setStore({ login: data });
      },

      setLanguage: (language) => {
        setStore({language})
      },

      setResponseStatus: (booleanResp) => {
        setStore({ responseStatus: booleanResp });
      },
      setMessageResponseStatus: (textResp) => {
        setStore({ messageResponseStatus: textResp });
      },
      setShowModalResponseStatus: (booleanResp) => {
        setStore({ showModalResponseStatus: booleanResp });
      },
    },
  };
};
export default getState;
