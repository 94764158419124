import { useState, useEffect } from "react";
import SelectMultiple from "./SelectMultiple";
import { myPurposesTranslate } from "../../../../i18n/translatedMessages";

import { titlePurpose } from "./filter";

import "./purposeArea.sass";
import { useIntl } from "react-intl";

const purposeES = [
  "Desarrollo personal",
  "Compensasiones",
  "Tipo de Trabajo",
  "Industria/Empresa",
  "Dinámica laboral",
  "Personal",
];

const purposeBR = [
  "Desenvolvimento Pessoal",
  "Benefícios",
  "Tipo de Trabalho",
  "Indústria/Empresa",
  "Dinámica Empresarial",
  "Pessoal",
];

const PurposesArea = ({ list, checkedList, name, handleChose }) => {
  const getLanguage = useIntl();
  const purposeTitle = getLanguage.locale === "PT-BR" ? purposeBR : purposeES;

  const [state, setState] = useState([]);

  useEffect(() => {
    setState(
      list.map((item) =>
        item.map((option) => {
          return {
            ...option,
            active: checkedList.some((item) => item?.purposeId === option?.purposeId),
          };
        })
      )
    );
  }, [checkedList, list]);

  return (
    <div className="card purposeAreaEmployee-main mt-5">
      <div className="card-header">
        <h2 className="texto-body pt-1">{myPurposesTranslate.yourPurpose}</h2>
      </div>
      <div className="form-control area-checked">
        {checkedList.map((item, key) => (
          <span key={key}> #{item?.purposeName}</span>
        ))}
      </div>
      <div className="row px-2 py-2">
        {state.length > 0 &&
          state.map((item, index) => (
            <div className="col d-flex p-1" key={index}>
              <SelectMultiple
                title={purposeTitle[index]}
                list={item}
                selectedList={checkedList}
                onClick={handleChose}
              />
            </div>
          ))}
      </div>
    </div>
  );
};
export default PurposesArea;
