import React, { useContext, useEffect, useState } from "react";

import { ServicesContext } from "../../../../../Store/ServicesContext";

import { Col, Button, Table, Form, Row, InputGroup } from "react-bootstrap";

import "./css/Beneficios.css";
import {
	lateralMenuTextTranslate,
	salariesAndBenefitsTranslate,
} from "../../../../../i18n/translatedMessages";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import { translate } from "../../../../../i18n/Library/hierarchicalLevel";

const Beneficios = () => {
	const getLanguage = useIntl();
	const language = getLanguage.locale === "PT-BR" ? "pt" : "es";

	const { store, actions } = useContext(ServicesContext);

	const [showBeneficiosApi, setShowBeneficiosApi] = useState(false);
	const [BeneficiosApi, setBeneficiosApi] = useState([]);

	const [countryId, setCountryId] = useState("1");

	useEffect(() => {
		actions.allAreaGroupsByCountry(countryId);
	}, []);

	useEffect(() => {
		actions.allAreaGroupsByCountry(countryId);
	}, [countryId]);

	const handlePrintBenefits = async (code) => {
		const getbeneficios = await fetch(
			`https://smtm.co/v1/get_benefit_group_benefits?authorization=19c073e1768a3cbf6e9d76390e640f3b&country_id=${countryId}&benefit_group_id=${code}`
		);

		const dataBeneficios = await getbeneficios.json();
		setShowBeneficiosApi(true);
		setBeneficiosApi(dataBeneficios);
	};

	return (
		<>
			<>
				<div className="newLanding-main-content">
					<div id="new-landing-main-content">
						<div className="content-wrapper">
							<div className="row">
								<Col xs={12} sm={12} md={12}>
									<div className="d-flex align-items-center ml-5">
										<img
											className="custom-icon-guia-salarial"
											src="https://assets.mydna.company/b2b-images/pb/img/icons/azulesnumericos/Iconos-08.svg"
											alt="Icono Beneficios MyDNA"
										/>
										<div className="d-flex flex-column justify-content-center px-4">
											<h3 className="content-wrapper-title">
												{
													lateralMenuTextTranslate.benefits
												}
											</h3>
											<h5 className="content-wrapper-subtitle">
												{
													salariesAndBenefitsTranslate.benefitsText
												}
											</h5>
										</div>
									</div>
								</Col>
							</div>
							<div className="row mt-5">
								<div className="col-6">
									<InputGroup className="mb-3">
										<div className="d-flex mx-2 align-items-center w-100 custom-input-group">
											<div className="">
												<div className="labelStep">
													País
												</div>
											</div>
											<FormattedMessage id="benefitsSearchByCountry">
												{(msg) => (
													<select
														name="filtro_pais"
														className="w-100"
														onChange={(e) => {
															setCountryId(
																e.target.value
															);
														}}
													>
														<option>{msg}</option>
														<option
															value="1"
															data-countryname="Chile"
														>
															Chile
														</option>
														<option
															value="2"
															data-countryname="Argentina"
														>
															Argentina
														</option>
														<option
															value="3"
															data-countryname="Perú"
														>
															Perú
														</option>
														<option
															value="4"
															data-countryname="Brasil"
														>
															Brasil
														</option>
														<option
															value="5"
															data-countryname="Colombia"
														>
															Colombia
														</option>
														<option
															value="6"
															data-countryname="Mexico"
														>
															Mexico
														</option>
													</select>
												)}
											</FormattedMessage>
										</div>
									</InputGroup>
								</div>
								<div className="col-6">
									<InputGroup className="mb-3">
										<div className="d-flex mx-2 align-items-center w-100 custom-input-group">
											<div className="">
												<div className="labelStep">
													Nivel
												</div>
											</div>
											<Form.Select
												onChange={(e) =>
													handlePrintBenefits(
														e.target.value
													)
												}
											>
												<option>Filtro Cargo</option>
												{store.areaGroupsByCountry.map(
													(group) => (
														<option
															key={group.id}
															value={group.id}
															// selected={filters.group_id === group.id}
														>
															{translate[
																language
															][group.name] ||
																group.name}
														</option>
													)
												)}
											</Form.Select>
										</div>
									</InputGroup>
								</div>
							</div>
							{showBeneficiosApi ? (
								<>
									<div className="wrapper-beneficios">
										<Row>
											{BeneficiosApi.map(
												(value, index) => {
													return (
														<>
															<Col
																key={index}
																xs={12}
																sm={12}
																md={3}
															>
																<div className="d-flex flex-column align-items-center justify-content-center px-4">
																	<img
																		className="custom-icon-guia-salarial"
																		src={`https://assets.mydna.company/b2b-images/pb/img/icons/azules/${value.icon}.svg`}
																		alt=""
																	/>
																	<p className="content-wrapper-title text-center">
																		{
																			value.name
																		}
																	</p>
																	<p className="content-wrapper-subtitle text-center">
																		{
																			value.description
																		}
																	</p>
																</div>
															</Col>
														</>
													);
												}
											)}
										</Row>
									</div>
								</>
							) : (
								<div className="wrapper-beneficios">
									<div className="row">
										<Col xs={12} sm={12} md={3}>
											<div className="d-flex flex-column align-items-center justify-content-center px-4">
												<img
													className="custom-icon-guia-salarial"
													src="https://assets.mydna.company/b2b-images/pb/img/icons/azulesnumericos/Iconos-22.svg"
													alt=""
												/>
												<p className="content-wrapper-title text-center">
													{
														salariesAndBenefitsTranslate.alimentationTitle
													}
												</p>
												<p className="content-wrapper-subtitle text-center">
													{
														salariesAndBenefitsTranslate.alimentationText
													}
												</p>
											</div>
										</Col>
										<Col xs={12} sm={12} md={3}>
											<div className="d-flex flex-column align-items-center justify-content-center px-4">
												<img
													className="custom-icon-guia-salarial"
													src="https://assets.mydna.company/b2b-images/pb/img/icons/azulesnumericos/Iconos-23.svg"
													alt=""
												/>
												<p className="content-wrapper-title text-center">
													{
														salariesAndBenefitsTranslate.transportTitle
													}
												</p>
												<p className="content-wrapper-subtitle text-center">
													{
														salariesAndBenefitsTranslate.transportText
													}
												</p>
											</div>
										</Col>
										<Col xs={12} sm={12} md={3}>
											<div className="d-flex flex-column align-items-center justify-content-center px-4">
												<img
													className="custom-icon-guia-salarial"
													src="https://assets.mydna.company/b2b-images/pb/img/icons/azulesnumericos/Iconos-24.svg"
													alt=""
												/>
												<p className="content-wrapper-title text-center">
													{
														salariesAndBenefitsTranslate.lifeInsuranceTitle
													}
												</p>
												<p className="content-wrapper-subtitle text-center">
													{
														salariesAndBenefitsTranslate.lifeInsuranceText
													}
												</p>
											</div>
										</Col>
										<Col xs={12} sm={12} md={3}>
											<div className="d-flex flex-column align-items-center justify-content-center px-4">
												<img
													className="custom-icon-guia-salarial"
													src="https://assets.mydna.company/b2b-images/pb/img/icons/azulesnumericos/Iconos-25.svg"
													alt=""
												/>
												<p className="content-wrapper-title text-center">
													{
														salariesAndBenefitsTranslate.certificationTitle
													}
												</p>
												<p className="content-wrapper-subtitle text-center">
													{
														salariesAndBenefitsTranslate.certificationText
													}
												</p>
											</div>
										</Col>
										<Col xs={12} sm={12} md={3}>
											<div className="d-flex flex-column align-items-center justify-content-center px-4">
												<img
													className="custom-icon-guia-salarial"
													src="https://assets.mydna.company/b2b-images/pb/img/icons/azulesnumericos/Iconos-26.svg"
													alt=""
												/>
												<p className="content-wrapper-title text-center">
													{
														salariesAndBenefitsTranslate.transportTitle
													}
												</p>
												<p className="content-wrapper-subtitle text-center">
													{
														salariesAndBenefitsTranslate.transportText
													}
												</p>
											</div>
										</Col>
										<Col xs={12} sm={12} md={3}>
											<div className="d-flex flex-column align-items-center justify-content-center px-4">
												<img
													className="custom-icon-guia-salarial"
													src="https://assets.mydna.company/b2b-images/pb/img/icons/azulesnumericos/Iconos-27.svg"
													alt=""
												/>
												<p className="content-wrapper-title text-center">
													{
														salariesAndBenefitsTranslate.tradingPowerTitle
													}
												</p>
												<p className="content-wrapper-subtitle text-center">
													{
														salariesAndBenefitsTranslate.transportText
													}
												</p>
											</div>
										</Col>
										<Col xs={12} sm={12} md={3}>
											<div className="d-flex flex-column align-items-center justify-content-center px-4">
												<img
													className="custom-icon-guia-salarial"
													src="https://assets.mydna.company/b2b-images/pb/img/icons/azulesnumericos/Iconos-28.svg"
													alt=""
												/>
												<p className="content-wrapper-title text-center">
													{
														salariesAndBenefitsTranslate.computerTitle
													}
												</p>
												<p className="content-wrapper-subtitle text-center">
													{
														salariesAndBenefitsTranslate.computerText
													}
												</p>
											</div>
										</Col>
										<Col xs={12} sm={12} md={3}>
											<div className="d-flex flex-column align-items-center justify-content-center px-4">
												<img
													className="custom-icon-guia-salarial"
													src="https://assets.mydna.company/b2b-images/pb/img/icons/azulesnumericos/Iconos-29.svg"
													alt=""
												/>
												<p className="content-wrapper-title text-center">
													{
														salariesAndBenefitsTranslate.gymTitle
													}
												</p>
												<p className="content-wrapper-subtitle text-center">
													{
														salariesAndBenefitsTranslate.gymText
													}
												</p>
											</div>
										</Col>
										<Col xs={12} sm={12} md={3}>
											<div className="d-flex flex-column align-items-center justify-content-center px-4">
												<img
													className="custom-icon-guia-salarial"
													src="https://assets.mydna.company/b2b-images/pb/img/icons/azulesnumericos/Iconos-30.svg"
													alt=""
												/>
												<p className="content-wrapper-title text-center">
													{
														salariesAndBenefitsTranslate.anualBonusTitle
													}
												</p>
												<p className="content-wrapper-subtitle text-center">
													{
														salariesAndBenefitsTranslate.anualBonusText
													}
												</p>
											</div>
										</Col>
										<Col xs={12} sm={12} md={3}>
											<div className="d-flex flex-column align-items-center justify-content-center px-4">
												<img
													className="custom-icon-guia-salarial"
													src="https://assets.mydna.company/b2b-images/pb/img/icons/azulesnumericos/Iconos-31.svg"
													alt=""
												/>
												<p className="content-wrapper-title text-center">
													{
														salariesAndBenefitsTranslate.bonusTitle
													}
												</p>
												<p className="content-wrapper-subtitle text-center">
													{
														salariesAndBenefitsTranslate.bonusText
													}
												</p>
											</div>
										</Col>
										<Col xs={12} sm={12} md={3}>
											<div className="d-flex flex-column align-items-center justify-content-center px-4">
												<img
													className="custom-icon-guia-salarial"
													src="https://assets.mydna.company/b2b-images/pb/img/icons/azulesnumericos/Iconos-32.svg"
													alt=""
												/>
												<p className="content-wrapper-title text-center">
													{
														salariesAndBenefitsTranslate.equityTitle
													}
												</p>
												<p className="content-wrapper-subtitle text-center">
													{
														salariesAndBenefitsTranslate.equityText
													}
												</p>
											</div>
										</Col>
										<Col xs={12} sm={12} md={3}>
											<div className="d-flex flex-column align-items-center justify-content-center px-4">
												<img
													className="custom-icon-guia-salarial"
													src="https://assets.mydna.company/b2b-images/pb/img/icons/azulesnumericos/Iconos-33.svg"
													alt=""
												/>
												<p className="content-wrapper-title text-center">
													{
														salariesAndBenefitsTranslate.stockOptionsTitle
													}
												</p>
												<p className="content-wrapper-subtitle text-center">
													{
														salariesAndBenefitsTranslate.stockOptionsText
													}
												</p>
											</div>
										</Col>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</>
		</>
	);
};

export default Beneficios;
