import { useState, useEffect, useContext } from "react"
import { Link } from "react-router-dom"
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md"
import { wheelCompanyTranslate } from "../../../../i18n/translatedMessages"
import { CompanyContext } from "../../../../Store/CompanyContext"

import { services } from "./services"

import Steps from "./steps"
import "./wheelSteps.sass"

const WheelServicesSteps = () => {
  const { store } = useContext(CompanyContext)

  const [group, setGroup] = useState(0)
  const [img, setImg] = useState(
    "https://assets.mydna.company/mydnalogo.png"
  )

  useEffect(() => {
    const companyIndex = localStorage.getItem("companyIndex")
    if (store.comercialContactCompanyUser) {
      if (
        store.comercialContactCompanyUser[
          companyIndex ? companyIndex : store.companyIndex
        ]?.comercialContactCompanies.logo
      ) {
        setImg(
          store.comercialContactCompanyUser[
            companyIndex ? companyIndex : store.companyIndex
          ]?.comercialContactCompanies.logo
        )
      }
    }
  }, [])

  const nextGroup = () => {
    if (group < services.length - 1) setGroup(group + 1)
  }

  const backGroup = () => {
    if (group > 0) {
      setGroup(group - 1)
    }
  }

  return (
    <div className="d-flex justify-content-between align-items-center px-5">
      <div className="change-wheel-steps back">
        
        <button
          className="arrow-paginator"
          disabled={group === 0}
          onClick={backGroup}
        >
          <MdNavigateBefore size={45} />
        </button>
      </div>

      <div className="d-flex flex-column align-items-center">
        <h3 className="mt-3 p-0"> {services[group].title}</h3>
        <div className={`container-wheel-services-steps`}>
          <div className="ring-one p-md-5 p-4">
            {services[group].plan.map((service) => (
              <Link
                to={{ pathname: "/Services/detail", option: service.id }}
                className={`icons-services shadow`}
                key={service.id}
              >
                <img
                  src={service.icon}
                  alt={service.name}
                  className="img-fluid"
                />
                <div className="box-info">
                  <span className="title-service">{service.name}</span>
                  <span className="detail-service">
                    + {wheelCompanyTranslate.seeMore}
                  </span>
                </div>
              </Link>
            ))}

            <div className="ring-two p-md-5 p-4">
              <img src={img} alt="" className="img-fluid img" />
            </div>
          </div>
        </div>
        <Steps services={services} group={group} />
      </div>

      <div className="change-wheel-steps next">
        <button
          disabled={group === services.length - 1}
          className="arrow-paginator"
          onClick={nextGroup}
        >
          <MdNavigateNext size={45} />
        </button>
      </div>
    </div>
  )
}
export default WheelServicesSteps
