export const postEmployeeMedia = async (data) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/employermedia`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};

export const putEmployeeMedia = async (data, id) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_API}/employermedia/${id}`,
      {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};

export const getEmployeeMediasByEmployerId = async (employerId) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_API}/employermedia/${employerId}`
    );
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};

export const getEmployeesMediasByCompanyId = async (companyId) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_API}/employermedia/company/${companyId}`
    );
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};
