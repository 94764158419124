// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-colaboradores {
  height: calc(100vh - 110px) !important;
  overflow-y: scroll;
  overflow-x: hidden;
}
.my-colaboradores .loading {
  position: absolute;
  top: 0;
  left: 361px;
  width: calc(100% - 370px);
  height: 100%;
  background: rgb(255, 255, 255);
  z-index: 1;
}
.my-colaboradores .icon {
  background: linear-gradient(45deg, #40c2ff 0%, #32d8d8 100%);
  width: 50px;
  height: 50px;
  border-radius: 50rem;
  padding: 8px;
  margin: 0 10px;
  display: flex;
  align-items: center;
}
.my-colaboradores .icon svg {
  fill: white;
  width: 100%;
}
.my-colaboradores a {
  color: #4e5857;
}
.my-colaboradores a:hover > div {
  background: rgba(0, 0, 0, 0.05);
}
.my-colaboradores .box-error {
  margin: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.25);
}
.my-colaboradores .employeeImg {
  width: 50px;
  height: 50px;
}
.my-colaboradores .text-blue {
  color: #40c2ff;
}`, "",{"version":3,"sources":["webpack://./src/Views/Service/Views/PeopleAnalytics/Management/myColabs.sass"],"names":[],"mappings":"AAAA;EACI,sCAAA;EACA,kBAAA;EACA,kBAAA;AACJ;AACI;EACI,kBAAA;EACA,MAAA;EACA,WAAA;EAEA,yBAAA;EACA,YAAA;EACA,8BAAA;EACA,UAAA;AAAR;AAEI;EACI,4DAAA;EACA,WAAA;EACA,YAAA;EACA,oBAAA;EACA,YAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;AAAR;AAEQ;EACI,WAAA;EACA,WAAA;AAAZ;AAEI;EACI,cAAA;AAAR;AAEQ;EACI,+BAAA;AAAZ;AAEI;EACI,cAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;EACA,0BAAA;AAAR;AAEI;EACI,WAAA;EACA,YAAA;AAAR;AAEI;EACI,cAAA;AAAR","sourcesContent":[".my-colaboradores\n    height: calc(100vh - 110px) !important\n    overflow-y: scroll\n    overflow-x: hidden\n\n    & .loading\n        position: absolute\n        top: 0\n        left: 361px\n\n        width: calc(100% - 370px)\n        height: 100%\n        background: rgba(255,255,255,1)\n        z-index: 1\n\n    & .icon\n        background: linear-gradient(45deg,#40c2ff 0%, #32d8d8 100%)\n        width: 50px\n        height: 50px\n        border-radius: 50rem\n        padding: 8px\n        margin: 0 10px\n        display: flex\n        align-items: center\n\n        & svg\n            fill: white\n            width: 100%\n\n    & a\n        color: #4e5857\n\n        &:hover > div\n            background: rgba(0,0,0,.05)\n\n    & .box-error\n        margin: 15px 0\n        display: flex\n        justify-content: center\n        align-items: center\n        font-weight: bold\n        color: rgba(0,0,0,.25)\n\n    .employeeImg\n        width: 50px\n        height: 50px\n\n    .text-blue\n        color: #40c2ff\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
