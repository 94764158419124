import { useEffect, useState } from 'react'
import { HiMiniXMark } from 'react-icons/hi2'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'

export function ModalAssessment({ show, assets, content, selectedDimension, setShow, setNewDimension }) {
	const [dimension, setDimension] = useState(null)

	useEffect(() => {
		if (content) {
			const [aux] = content.filter(item => item.dimension === selectedDimension)
			setDimension(aux)
		}
	}, [selectedDimension, content])

	if (show && dimension)
		return (
			<div className='tw-h-screen tw-w-screen tw-bg-black/40 tw-backdrop-blur-[2px] tw-flex tw-items-center tw-justify-center tw-absolute tw-top-0 tw-left-0 '>
				<div className='tw-rounded-lg overflow-hidden' style={{ width: 880 }}>
					<div className='tw-px-6 tw-py-4 tw-flex tw-items-start tw-justify-between' style={{ background: assets[selectedDimension].color, height: 120 }}>
						<div className='tw-flex tw-items-center tw-h-full tw-gap-4'>
							<div className='tw-rounded-full tw-bg-white tw-flex tw-justify-center tw-items-center' style={{ width: 45, height: 45, color: assets[selectedDimension].color }}>
								{assets[selectedDimension].icon}
							</div>
							<div>
								<div className='tw-text-white tw-flex tw-gap-2'>
									<h3 className='text-white fw-bold tw-m-0'>{dimension.score_relative}%</h3>
									<h4 className='tw-m-0 tw-font-bold'>{dimension.dimension}</h4>
								</div>
								<p className='tw-text-sm tw-text-white tw-font-bold tw-m-0' style={{ opacity: 0.75 }}>
									{dimension.brief.description}
								</p>
							</div>
						</div>
						<button onClick={() => setShow(false)} className='tw-text-white tw-bg-transparent border-0 tw-rounded-full hover:tw-bg-white/25 tw-flex tw-items-center tw-justify-center tw-p-1 tw-w-[32px] tw-h-[32px]'>
							<HiMiniXMark size={22} />
						</button>
					</div>
					{dimension.brief.brief && (
						<div className='tw-p-8 tw-bg-white'>
							<div className='tw-flex'>
								<p>{dimension.brief.brief}</p>
							</div>
						</div>
					)}
					<div className='tw-bg-white tw-flex tw-items-center tw-px-8 tw-text-gray-500 tw-py-4 tw-gap-4' style={{ borderTop: '1px solid #cdd8ea' }}>
						{content.map((button, index) => (
							<button
								key={index}
								onClick={() => setNewDimension(button.dimension)}
								className={`tw-px-4 tw-py-1 tw-rounded-md tw-bg-transparent tw-font-bold tw-flex tw-items-center tw-gap-2 hover:tw-bg-gray-100`}
								style={{
									border: `1px solid ${dimension.dimension !== button.dimension ? '#cdd8ea' : assets[button.dimension].color}`,
									color: dimension.dimension !== button.dimension ? assets[button.dimension].color : 'white',
									background: dimension.dimension !== button.dimension ? '' : assets[button.dimension].color,
								}}
							>
								{assets[button.dimension]['small-icon']}
								{button.dimension}
								<MdOutlineKeyboardArrowRight size={18} />
							</button>
						))}
					</div>
				</div>
			</div>
		)

	return <></>
}
