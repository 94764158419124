// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.job-search .icon {
  background: linear-gradient(45deg, #40c2ff 0%, #32d8d8 100%);
  width: 50px;
  height: 50px;
  border-radius: 50rem;
  padding: 8px;
  margin: 0 10px;
  display: flex;
  align-items: center;
}
.job-search .icon svg {
  fill: white;
  width: 100%;
}
.job-search input {
  width: 100%;
}
.job-search ul {
  list-style: none;
  padding: 0;
}
.job-search ul li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 10px 20px;
  font-weight: 500;
  cursor: pointer;
  color: #444258;
}
.job-search ul li div {
  margin-top: 5px;
  font-size: small;
  font-weight: normal;
  color: #45b9eb;
}
.job-search ul li:hover {
  background: rgba(0, 0, 0, 0.05);
}`, "",{"version":3,"sources":["webpack://./src/Views/Services/Components/JobDescription/jobSearch.sass"],"names":[],"mappings":"AACI;EACI,4DAAA;EACA,WAAA;EACA,YAAA;EACA,oBAAA;EACA,YAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;AAAR;AAEQ;EACI,WAAA;EACA,WAAA;AAAZ;AAEI;EACI,WAAA;AAAR;AAEI;EACI,gBAAA;EACA,UAAA;AAAR;AAEQ;EACI,4CAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,cAAA;AAAZ;AAEY;EACI,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,cAAA;AAAhB;AAEY;EACI,+BAAA;AAAhB","sourcesContent":[".job-search\n    & .icon\n        background: linear-gradient(45deg,#40c2ff 0%, #32d8d8 100%)\n        width: 50px\n        height: 50px\n        border-radius: 50rem\n        padding: 8px\n        margin: 0 10px\n        display: flex\n        align-items: center\n\n        & svg\n            fill: white\n            width: 100%\n\n    & input\n        width: 100%\n\n    & ul\n        list-style: none\n        padding: 0\n\n        & li\n            border-bottom: 1px solid rgba(0, 0, 0, .05)\n            padding: 10px 20px\n            font-weight: 500\n            cursor: pointer\n            color: #444258\n\n            & div\n                margin-top: 5px\n                font-size: small\n                font-weight: normal\n                color: #45b9eb\n\n            &:hover\n                background: rgba(0,0,0,.05)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
