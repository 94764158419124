export const translate = {
  es: {
    Agroindustria: "Agroindustria",
    Autopartes: "Autopartes",
    "Bienes de consumo": "Bienes de consumo",
    Commodities: "Commodities",
    Comunicaciones: "Comunicaciones",
    Construcción: "Construcción",
    Consultoría: "Consultoría",
    Educación: "Educación",
    "Electro / Electrónica": "Electro / Electrónica",
    Electronica: "Electronica",
    Energía: "Energía",
    Farmacéutica: "Farmacéutica",
    Financieros: "Financieros",
    Ingeniería: "Ingeniería",
    Internet: "Internet",
    Legal: "Legal",
    "Líneas Aereas": "Líneas Aereas",
    "Logistica y Transporte": "Logistica y Transporte",
    Manufactura: "Manufactura",
    "Maquinas y equipos industriales": "Maquinas y equipos industriales",
    Minería: "Minería",
    "Ocio y Entretenimiento": "Ocio y Entretenimiento",
    Publicidad: "Publicidad",
    "Química, Plastico y Petroquímica": "Química, Plastico y Petroquímica",
    Retail: "Retail",
    Salud: "Salud",
    "Sector Público": "Sector Público",
    Seguros: "Seguros",
    Telecomunicaciones: "Telecomunicaciones",
    Textil: "Textil",
    TI: "TI",
    Vehículos: "Vehículos",
    Veterinaria: "Veterinaria",
  },
  pt: {
    Agroindustria: "Agroindústria",
    Autopartes: "Autopeças",
    "Bienes de consumo": "Bens de consumo",
    Commodities: "Commodities",
    Comunicaciones: "Comunicações",
    Construcción: "Construção",
    Consultoría: "Consultoria",
    Educación: "Educação",
    "Electro / Electrónica": "Eletro/Eletrônica",
    Electronica: "Eletrônicos",
    Energía: "Energia",
    Farmacéutica: "Farmacêutica",
    Financieros: "Serviços financeiros",
    Ingeniería: "Engenharia",
    Internet: "Internet",
    Legal: "Jurídico",
    "Líneas Aereas": "Linhas Áereas",
    "Logistica y Transporte": "Logística e Transporte",
    Manufactura: "Manufatura",
    "Maquinas y equipos industriales": "Máquinas e equipamentos industriais",
    Minería: "Mineração",
    "Ocio y Entretenimiento": "Lazer e entretenimento",
    Publicidad: "Publicidade",
    "Química, Plastico y Petroquímica": "Química, Plástica e Petroquímica",
    Retail: "Retail",
    Salud: "Saúde",
    "Sector Público": "Setor Público",
    Seguros: "Seguros",
    Telecomunicaciones: "Telecomunicações",
    Textil: "Têxtil",
    TI: "TI",
    Vehículos: "Veículos",
    Veterinaria: "Veterinaria",
  },
};
