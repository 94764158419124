// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.background-purple {
    background-color: #7A6BE8;
}`, "",{"version":3,"sources":["webpack://./src/Views/Services/Modais/ServiceRequestModal/ServiceRequestModal.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B","sourcesContent":[".background-purple {\n    background-color: #7A6BE8;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
