import React, { useEffect, useState } from 'react'

export function DropBox({ children, path, evaluation, setStars, stars, setSalary9Box, salary9Box }) {
	const [accordionState, setAccordionState] = useState([false, false, false])

	useEffect(() => {
		if (typeof path === 'string') {
			if (path.includes('/employee-salary-evaluation')) {
				if (path.includes('/performance')) {
					setAccordionState([false, true, false])
				} else if (path.includes('/potential')) {
					setAccordionState([false, false, true])
				}
			} else if (path.includes('/performance')) {
				setAccordionState([true, false, false])
			} else if (path.includes('/potential')) {
				setAccordionState([false, true, false])
			} else if (path.includes('/nine-box')) {
				setAccordionState([false, false, true])
			}
		}
	}, [path])

	const toggleAccordion = index => {
		setAccordionState(prevState => {
			const newState = [...prevState]
			newState.fill(false)
			newState[index] = !prevState[index]
			return newState
		})
	}

	return (
		<>
			{children.map((item, index) => {
				const isOpened = accordionState[index]
				const clonedChild = React.cloneElement(item, { evaluation, setStars, stars, setSalary9Box, salary9Box })

				return (
					<div className='tw-my-3 tw-bg-white tw-rounded-xl' key={index}>
						<button className='tw-border-none tw-bg-white tw-flex tw-justify-between tw-items-center tw-w-full tw-p-4 tw-py-3 tw-text-left tw-text-gray-800 tw-font-medium tw-focus:outline-none' onClick={() => toggleAccordion(index)}>
							<span className={`tw-font-semibold`}>{item.props.title}</span>
							<svg className={`tw-mr-5 tw-w-5 tw-h-5 ${isOpened ? 'tw-transform rotate-180' : ''}`} fill='none' stroke='currentColor' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
								<path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M19 9l-7 7-7-7'></path>
							</svg>
						</button>
						<div className={`tw-w-full tw-overflow-hidden ${isOpened ? 'tw-max-h-full tw-animate-accordion-up' : 'tw-max-h-0 tw-animate-accordion-down'}`}>
							<div className='tw-p-4 '>{clonedChild}</div>
						</div>
					</div>
				)
			})}
		</>
	)
}
