import { EvaluationMenu } from '../../Components/EvaluationReferencias/menu'
import { Accordion } from '../../Components/EvaluationReferencias/accordion'
import { PerformanceIndicators } from '../../Components/EvaluationReferencias/examples/PerformanceIndicators'
import { ObjectiveCriteria } from '../../Components/EvaluationReferencias/examples/ObjectiveCriteria'
import { NineBox } from '../../Components/EvaluationReferencias/examples/9box'

import { references } from '../../i18n/translatedMessages'
import { useIntl } from 'react-intl'

import { potencialCriterias, executivePotencial, potencialValue } from './lists.js'

export function Referencias() {
	const getLanguage = useIntl()
	const language = getLanguage.locale === 'PT-BR' ? 'pt' : 'es'

	return (
		<div className='tw-pr-16'>
			<h1 className='tw-font-bold tw-my-10 tw-text-3xl'>{references.useGuide}</h1>
			<div className='tw-flex	'>
				<div className='tw-flex-none tw-relative tw-w-[350px] tw-overflow-hidden'>
					<EvaluationMenu />
				</div>
				<div className='tw-h-[calc(100vh_-_168px)] tw-w-full tw-overflow-auto tw-scroll-smooth tw-pr-5'>
					<h1 className='tw-text-3xl tw-font-bold tw-mb-8'>{references.performanceTitle}</h1>

					<div className='tw-grid tw-grid-cols-2 tw-gap-14' id='Evaluation&Criteria'>
						<div>
							<h5 className='tw-font-bold tw-text-xl'>{references.performanceEvaluationTitle}</h5>
							<p>{references.performanceEvaluationText}</p>
						</div>
						<div>
							<h5 className='tw-font-bold tw-text-xl'>{references.performanceCriteriaTitle}</h5>
							<p>{references.performanceCriteriaText}</p>
						</div>
					</div>

					<div className='tw-mt-20' id='ExamplesIndicators'>
						<PerformanceIndicators />
					</div>

					<div className='tw-mt-20' id='TypesOfMethods'>
						<h5 className='tw-font-bold tw-text-xl'>{references.TypesOfMethods}</h5>
						<div className='tw-grid tw-grid-cols-2 tw-gap-14 tw-mt-5'>
							<div>
								<h5 className='tw-font-bold tw-text-lg'>{references.ConductualRatingScaleTitle}</h5>
								<p>{references.ConductualRatingScaleText}</p>
							</div>
							<div>
								<h5 className='tw-font-bold tw-text-lg'>{references.OfCriticalIncidentsTitle}</h5>
								<p>{references.OfCriticalIncidentsText}</p>
							</div>
						</div>
						<ul>
							<li>{references.TypesOfMethodsList1}</li>
							<li>{references.TypesOfMethodsList2}</li>
							<li>{references.TypesOfMethodsList3}</li>
							<li>{references.TypesOfMethodsList4}</li>
							<li>{references.TypesOfMethodsList5}</li>
						</ul>
					</div>

					<div className='tw-mt-20' id='ObjectiveCriteria'>
						<h5 className='tw-font-bold tw-text-xl'>{references.ObjectiveOfTheCriteria}</h5>
						<div className='tw-grid tw-grid-cols-4 tw-gap-8 tw-mt-5 tw-mx-2'>
							<div>
								<ObjectiveCriteria title='1' content={references.ObjectiveOfTheCriteriaList1} />
							</div>
							<div>
								<ObjectiveCriteria title='2' content={references.ObjectiveOfTheCriteriaList2} />
							</div>
							<div>
								<ObjectiveCriteria title='3' content={references.ObjectiveOfTheCriteriaList3} />
							</div>
							<div>
								<ObjectiveCriteria title='4' content={references.ObjectiveOfTheCriteriaList4} />
							</div>
							<div>
								<ObjectiveCriteria title='5' content={references.ObjectiveOfTheCriteriaList5} />
							</div>
							<div>
								<ObjectiveCriteria title='6' content={references.ObjectiveOfTheCriteriaList6} />
							</div>
							<div>
								<ObjectiveCriteria title='7' content={references.ObjectiveOfTheCriteriaList7} />
							</div>
							<div>
								<ObjectiveCriteria title='8' content={references.ObjectiveOfTheCriteriaList8} />
							</div>
							<div>
								<ObjectiveCriteria title='9' content={references.ObjectiveOfTheCriteriaList9} />
							</div>
							<div>
								<ObjectiveCriteria title='10' content={references.ObjectiveOfTheCriteriaList10} />
							</div>
							<div>
								<ObjectiveCriteria title='11' content={references.ObjectiveOfTheCriteriaList11} />
							</div>
							<div>
								<ObjectiveCriteria title='12' content={references.ObjectiveOfTheCriteriaList12} />
							</div>
						</div>
					</div>

					<div className='tw-mt-20' id='AnalyzeResults'>
						<h5 className='tw-font-bold tw-text-xl'>{references.StepsToAnalyzePerformanceEvaluationResults}</h5>
						<ul className='tw-list-none tw-space-y-4 tw-m-0 tw-p-0 tw-mt-5'>
							<li className='tw-flex tw-items-center tw-gap-2'>
								<div className='tw-w-[26px] tw-h-[26px] tw-bg-[#F1F4F9] tw-rounded-full tw-font-bold tw-flex tw-justify-center tw-items-center'>1</div>
								<span>{references.StepsToAnalyzePerformanceEvaluationResultsList1}</span>
							</li>
							<li className='tw-flex tw-items-center tw-gap-2'>
								<div className='tw-w-[26px] tw-h-[26px] tw-bg-[#F1F4F9] tw-rounded-full tw-font-bold tw-flex tw-justify-center tw-items-center'>2</div>
								<span>{references.StepsToAnalyzePerformanceEvaluationResultsList2}</span>
							</li>
							<li className='tw-flex tw-items-center tw-gap-2'>
								<div className='tw-w-[26px] tw-h-[26px] tw-bg-[#F1F4F9] tw-rounded-full tw-font-bold tw-flex tw-justify-center tw-items-center'>3</div>
								<span>{references.StepsToAnalyzePerformanceEvaluationResultsList3}</span>
							</li>
							<li className='tw-flex tw-items-center tw-gap-2'>
								<div className='tw-w-[26px] tw-h-[26px] tw-bg-[#F1F4F9] tw-rounded-full tw-font-bold tw-flex tw-justify-center tw-items-center'>4</div>
								<span>{references.StepsToAnalyzePerformanceEvaluationResultsList4}</span>
							</li>
							<li className='tw-flex tw-items-center tw-gap-2'>
								<div className='tw-w-[26px] tw-h-[26px] tw-bg-[#F1F4F9] tw-rounded-full tw-font-bold tw-flex tw-justify-center tw-items-center'>5</div>
								<span>{references.StepsToAnalyzePerformanceEvaluationResultsList5}</span>
							</li>
							<li className='tw-flex tw-items-center tw-gap-2'>
								<div className='tw-w-[26px] tw-h-[26px] tw-bg-[#F1F4F9] tw-rounded-full tw-font-bold tw-flex tw-justify-center tw-items-center'>6</div>
								<span>{references.StepsToAnalyzePerformanceEvaluationResultsList6}</span>
							</li>
							<li className='tw-flex tw-items-center tw-gap-2'>
								<div className='tw-w-[26px] tw-h-[26px] tw-bg-[#F1F4F9] tw-rounded-full tw-font-bold tw-flex tw-justify-center tw-items-center'>7</div>
								<span>{references.StepsToAnalyzePerformanceEvaluationResultsList7}</span>
							</li>
						</ul>
					</div>

					<div className='tw-mt-20' id='PostEvaluation'>
						<h5 className='tw-font-bold tw-text-xl'>{references.PostEvaluationTitle}</h5>
						<p>{references.PostEvaluationText}</p>
					</div>

					<div className='tw-my-24'>
						<hr />
					</div>

					<h1 className='tw-text-3xl tw-font-bold tw-mb-8'>{references.Potential}</h1>

					<div id='Definition'>
						<h5 className='tw-font-bold tw-text-xl'>{references.DefinitionTitle}</h5>
						<p>{references.DefinitionText}</p>
					</div>

					<div className='tw-mt-10 tw-pr-2' id='ExamplesOfCriteria'>
						<h5 className='tw-font-bold tw-text-xl'>{references.ExamplesOfPotentialCriteria}</h5>
						<div className='tw-rounded-lg tw-p-2 tw-mt-5' style={{ border: '1px solid #E1E8F0' }}>
							<div className='tw-overflow-auto tw-flex tw-flex-wrap tw-gap-2 tw-h-[324px]'>
								{potencialCriterias.map(item => (
									<div className='tw-px-4 tw-py-1 tw-bg-[#F1F4F9] tw-rounded-full' key={item.id}>
										{item[language]}
									</div>
								))}
							</div>
						</div>
					</div>

					<div className='tw-mt-24 tw-pr-2' id='EvaluatePotential'>
						<h5 className='tw-font-bold tw-text-xl'>{references.WhyIsPotentialEvaluated}</h5>
						<Accordion list={potencialValue} />
					</div>

					<div className='tw-mt-24 tw-pr-2' id='PotentialsExecutive'>
						<h5 className='tw-font-bold tw-text-xl'>{references.PotentialsOfAnExecutive}</h5>
						<Accordion list={executivePotencial} />
					</div>

					<div className='tw-mt-24 tw-pr-2' id='TypeOfModels'>
						<h5 className='tw-font-bold tw-text-xl'>{references.TypesOfModels}</h5>
						<h5 className='tw-font-bold tw-text-lg tw-mt-5 tw-mb-y-3'>{references.PsychologicalEvaluationsTitle}</h5>
						<p>{references.PsychologicalEvaluationsText}</p>
					</div>

					<div className='tw-mt-24 tw-pr-2' id='Evaluation360'>
						<h5 className='tw-font-bold tw-text-xl'>{references.AssessmentTitle}</h5>
						<p>{references.AssessmentText}</p>
						<h5 className='tw-font-bold tw-text-base tw-mt-2'>9box Grid</h5>
						<NineBox />
					</div>

					<div className='tw-mt-24 tw-pr-2' id='9box'>
						<h5 className='tw-font-bold tw-text-xl'>{references.WhatIsThe9boxToolForTitle}</h5>
						<p>{references.WhatIsThe9boxToolForText}</p>
					</div>

					<div className='tw-mt-24 tw-pr-2' id='Advantages9box'>
						<h5 className='tw-font-bold tw-text-xl'>{references.AdvantagesOfThe9BoxMatrix}</h5>
						<ul className='tw-list-none tw-space-y-4 tw-m-0 tw-p-0 tw-mt-5'>
							<li className='tw-flex tw-items-center tw-gap-2'>
								<div className='tw-w-[26px] tw-h-[26px] tw-bg-[#F1F4F9] tw-rounded-full tw-font-bold tw-flex tw-justify-center tw-items-center'>1</div>
								<span>{references.AdvantagesOfThe9BoxMatrixList1}</span>
							</li>
							<li className='tw-flex tw-items-center tw-gap-2'>
								<div className='tw-w-[26px] tw-h-[26px] tw-bg-[#F1F4F9] tw-rounded-full tw-font-bold tw-flex tw-justify-center tw-items-center'>2</div>
								<span>{references.AdvantagesOfThe9BoxMatrixList2}</span>
							</li>
							<li className='tw-flex tw-items-center tw-gap-2'>
								<div className='tw-w-[26px] tw-h-[26px] tw-bg-[#F1F4F9] tw-rounded-full tw-font-bold tw-flex tw-justify-center tw-items-center'>3</div>
								<span>{references.AdvantagesOfThe9BoxMatrixList3}</span>
							</li>
						</ul>
						<img src={`https://assets.mydna.company/9box-${language}.png`} alt='' className='tw-mt-4 img-fluid' />
					</div>
				</div>
			</div>
		</div>
	)
}
