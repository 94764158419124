export const getEmployers = async (companyID) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API}/employer/company/${companyID}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

export const getEmployeeInformation = async (employee_id) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API}/employerinformations/${employee_id}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};
