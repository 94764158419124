import React, { useState, useEffect } from "react";
import { FormacionIcon } from "../../Icons/CollaboratorsIcons";
import { BiSearch, BiTrash, BiEdit } from "react-icons/bi";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { FiArrowDown } from "react-icons/fi";
import { getAreas } from "../../API/carrerProjection";
import {
  getListTraining,
  putTraining,
  deleteTraining,
} from "../../API/Employee/Training";
import { capacitationsTranslate } from "../../i18n/translatedMessages";
import { useListFilters } from "../../Components/Filters/ListFilters/useListFilters";
import Loading from "../../Components/Loading/Loading";
import ModalEditCapacitation from "../Capacitaciones/Components/ModalEditCapacitation";
import CreateCourse from "./Modal/CreateCourse";
import AddEmployee from "./Modal/AddEmployee";
import Swal from "sweetalert2";
import { FormattedMessage, useIntl } from "react-intl";
import "./capacitaciones.sass";
import { translate } from "../../i18n/Library/areasCorrelation";

const Capacitaciones = () => {
  const getLanguage = useIntl();
  const language = getLanguage.locale === "PT-BR" ? "pt" : "es";
  const company = JSON.parse(localStorage.getItem("user"));

  const [loading, setLoading] = useState(true);
  const [showNewCurse, setShowNewCurse] = useState(false);
  const [showAddEmployers, setShowAddEmployers] = useState(false);
  const [listTraining, setListTraining] = useState([]);
  const [listAreas, setListAreas] = useState([]);
  const [filteredTrainingList, setFilteredTrainingList] = useState([]);
  const [search, setSearch] = useState({
    area: "0",
    name: "",
  });

  const [course, setCourse] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const [modalData, setModalData] = useState({
    capacitationId: 0,
    name: "",
    description: "",
    link: "",
    areaId: "",
    companyId: "",
    profileArea: "",
  });

  const { area } = useListFilters();

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async () => {
    const trainingList = await getListTraining(company.id);
    const areasList = await getAreas();

    setListTraining(trainingList);
    setListAreas(areasList);
    setFilteredTrainingList(trainingList);
    setLoading(false);
  };

  const closeModal = async () => {
    setLoading(true);
    await fetchList();
    setLoading(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setSearch((prevSearch) => ({ ...prevSearch, [name]: value }));
  };
  
  useEffect(() => {
    var filteredList = [];
    if (search.area && search.name) {
      filteredList = listTraining.filter(
        (training) =>
          training.areaId === Number(search.area) &&
          training.name.toLowerCase().includes(search.name.toLowerCase())
      );
    } else if (search.area) {
      filteredList = listTraining.filter(
        (training) => training.areaId === Number(search.area)
      );
    } else {
      filteredList = listTraining.filter((training) =>
        training.name.toLowerCase().includes(search.name.toLowerCase())
      );
    }

    setFilteredTrainingList(filteredList);
  }, [search]);

  const handleModalCurse = (event) => {
    setCourse(event.currentTarget.dataset.course);
    setShowAddEmployers(true);
  };

  const handleEditChanges = (evt) => {
    const { name, value } = evt.target;

    setModalData({
      ...modalData,
      [name]: value,
    });
  };

  const handleShowModal = (data) => {
    setModalData({
      capacitationId: data.id,
      name: data.name,
      description: data.description,
      link: data.link,
      areaId: data.areaId,
      companyId: data.companyId,
    });
    setShowModal(true);
  };

  const handleSendChange = async (capacitationId) => {
    setLoading(true);
    const auxSendChange = {
      capacitationId: modalData.capacitationId,
      name: modalData.name,
      description: modalData.description,
      link: modalData.link,
      areaId: modalData.areaId,
      companyId: modalData.companyId,
    };

    const updateCapacitation = await putTraining(capacitationId, auxSendChange);

    if (updateCapacitation) {
      Swal.fire(
        getLanguage.locale === "ES"
          ? "Capacitación actualizada correctamente"
          : "Curso atualizado com sucesso"
      );
    } else {
      Swal.fire(
        getLanguage.locale === "ES"
          ? "Error al actualizar la capacitación"
          : "Falha ao atualizar o curso"
      );
    }

    await fetchList();
    setLoading(false);
    setShowModal(false);
  };

  const handleDelete = async (capacitationId) => {
    Swal.fire({
      title:
        getLanguage.locale === "ES"
          ? "Desea eliminar la capacitación seleccionada?"
          : "Deseja excluir o curso selecionado?",
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Enviar",
      confirmButtonColor: "#0AB4C4",
      cancelButtonColor: "#EC6376",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const deleteCapacitation = await deleteTraining(capacitationId);

        if (deleteCapacitation) {
          Swal.fire(
            getLanguage.locale === "ES"
              ? "Capacitación eliminada correctamente"
              : "Curso removido com sucesso"
          );
        } else {
          Swal.fire(
            getLanguage.locale === "ES"
              ? "Error al eliminar la capacitación"
              : "Falha ao excluir o curso"
          );
        }
      }

      await fetchList();
      setLoading(false);
    });
  };

  const handleHideModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <AddEmployee
        show={showAddEmployers}
        handleClose={setShowAddEmployers}
        course={course}
      />
      <CreateCourse
        show={showNewCurse}
        handleShow={setShowNewCurse}
        close={closeModal}
      />
      <ModalEditCapacitation
        show={showModal}
        hide={handleHideModal}
        data={modalData}
        areaList={area}
        handleChange={handleEditChanges}
        handleSendChange={handleSendChange}
        loading={loading}
      />

      <div className="capacitaciones">
        {loading && (
          <div className="loading">
            <Loading />
          </div>
        )}
        <div className="row">
          <div className="col offset-1 d-flex mb-5">
            <div className="icon">
              <FormacionIcon />
            </div>
            <h1>{capacitationsTranslate.capacitationsTitle}</h1>
          </div>
        </div>

        <div className="row px-5 my-3">
          <div className="col offset-1">
            <div className="search">
              <BiSearch size={20} />
              <FormattedMessage id={"capactitationSearchPlaceHolder"}>
                {(msg) => (
                  <input
                    type="text"
                    placeholder={msg}
                    onChange={handleChange}
                    name="name"
                    style={{ color: "black" }} // Añade este estilo para asegurar el color del texto
                  />
                )}
              </FormattedMessage>
            </div>
          </div>
          <div className="col-4">
            <select value={search.area} name="area" onChange={handleChange}>
              <FormattedMessage id={"capacitationChooseArea"}>
                {(msg) => <option value="" label={msg}></option>}
              </FormattedMessage>
              {listAreas.length > 0 &&
                listAreas.map((item) => (
                  <option key={item.id} value={item.id}>
                    {translate[language][item.profileArea]}
                  </option>
                ))}
            </select>
          </div>

          <div className="col-3 d-flex justify-content-end">
            <button onClick={() => setShowNewCurse(true)}>
              + {capacitationsTranslate.newCourse}
            </button>
          </div>
        </div>

        <div className="row mt-5 px-5">
          <div className="col offset-1">
            <ul>
              <li>
                <div className="row pe-2">
                  <div className="col-6 header">
                    {capacitationsTranslate.capacitationCourse}
                  </div>
                  <div className="col-4 header d-flex justify-content-center align-items-center">
                    {capacitationsTranslate.capacitationCourse}{" "}
                    <FiArrowDown className="ms-2" />
                  </div>
                  <div className="col-2 header d-flex justify-content-end">
                    # {capacitationsTranslate.capacitationArea}
                  </div>
                </div>
              </li>
              {filteredTrainingList.length === 0 && (
                <li className="empty">
                  {" "}
                  {capacitationsTranslate.capacitationEmpty}
                </li>
              )}
              {filteredTrainingList.map((training) => (
                <li className="table-body" key={training.id}>
                  <div className="row p-2">
                    <div className="col-6 table-body curse">
                      {training.name}
                    </div>
                    <div className="col-4 table-body d-flex justify-content-center">
                      {training.capacitationAreaCorrelation.profileArea}{" "}
                    </div>
                    <div className="col-2 table-body d-flex justify-content-end">
                      <button
                        className="plus"
                        data-course={training.id}
                        onClick={handleModalCurse}
                      >
                        <AiOutlineUsergroupAdd size={25} />
                      </button>
                      <button
                        className="plus"
                        data-course={training.id}
                        onClick={() => handleShowModal(training)}
                      >
                        <BiEdit size={25} />
                      </button>
                      <button
                        className="plus"
                        data-course={training.id}
                        onClick={() => handleDelete(training.id)}
                      >
                        <BiTrash size={25} />
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Capacitaciones;
