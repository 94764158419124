export const translate = {
	es: {
		'Indiferente': 'Indiferente',
		'Básico': 'Básico',
		'Medio': 'Medio',
		'Avanzado': 'Avanzado',
	},

	pt: {
		'Indiferente': 'Indiferente',
		'Básico': 'Básico',
		'Medio': 'Médio',
		'Avanzado': 'Avançado',
	},
}
