import { useIntl } from 'react-intl'
import { jobsDescriptionTranslate } from '../../../i18n/translatedMessages'

export function Descriptor({ descriptor, user }) {
	const getLanguage = useIntl()
	const language = getLanguage.locale === 'PT-BR' ? 'pt' : 'es'

	return (
		<div className='overflow-auto pt-0 p-5' style={{ height: 600 }}>
			<div className='row'>
				<div className='col'>
					<h5 className='fw-bolder mb-3'>{jobsDescriptionTranslate.jobsDescriptionTitle}</h5>
				</div>
			</div>
			<div className='row mb-4'>
				<div className='col'>
					<span className='fw-bold text-primary'>{user.position}</span>
				</div>
			</div>
			<div className='row my-2'>
				<div className='col'>
					<span className='fw-bold text-uppercase'>
						{jobsDescriptionTranslate.jobsDescriptionTitleJob} {user.positionName}?
					</span>
					<p className='mt-2'>{language === 'pt' ? descriptor.description_ptbr : descriptor.description}</p>
				</div>
			</div>
			<div className='row my-2'>
				<div className='col'>
					<span className='fw-bold text-uppercase'>{jobsDescriptionTranslate.jobsDescriptionActivies}</span>
					<p className='mt-2'>{language === 'pt' ? descriptor.main_activities_ptbr : descriptor.main_activities}</p>
				</div>
			</div>
			<div className='row mt-3'>
				<div className='col'>
					<span>Perfil</span>

					<div className='row rounded-2 border border-1 border-black overflow-hidden '>
						<div className='col'>
							<div className='row border-bottom border-black'>
								<div className='col-1 flex align-items-center justify-content-center border-end border-black bg-grey bg-opacity-50 p-2'>Técnico</div>
								<div className='col-11 p-2'>{language === 'pt' ? descriptor.professional_profile_ptbr : descriptor.professional_profile}</div>
							</div>
							<div className='row '>
								<div className='col-1 flex align-items-center justify-content-center border-end border-black bg-grey bg-opacity-50 p-2'>Pessoal</div>
								<div className='col-11 p-2'>{language === 'pt' ? descriptor.personal_profile_ptbr : descriptor.personal_profile}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='row mt-3'>
				<div className='col'>
					<span>{jobsDescriptionTranslate.jobsDescriptionRelacion}</span>

					<div className='row rounded-2 border border-1 border-black overflow-hidden '>
						<div className='col'>
							<div className='row border-bottom border-black'>
								<div className='col-1 flex align-items-center justify-content-center border-end border-black bg-grey bg-opacity-50 p-2'>Interno</div>
								<div className='col-11 p-2'>{language === 'pt' ? descriptor.internal_relations_ptbr : descriptor.internal_relations}</div>
							</div>
							<div className='row '>
								<div className='col-1 flex align-items-center justify-content-center border-end border-black bg-grey bg-opacity-50 p-2'>Externo</div>
								<div className='col-11 p-2'>{language === 'pt' ? descriptor.external_relations_ptbr : descriptor.external_relations}</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div style={{ width: '100%', height: 50, background: '' }} />
		</div>
	)
}
