import {
    employeeExperienceTranslate,
    employeeProfileEdit,
} from "../../../../i18n/translatedMessages";

export const Family = ({ onChange, data, error, lists }) => {
    return (
        <div className="content-form">
            <div className="row">
                <div className="col">
                    <h3>{employeeExperienceTranslate.familyTitleEEProfile}</h3>
                </div>
            </div>
            <div className="row">
                <div className={`col ${error.gender ? "error-radio" : ""}`}>
                    <div className="label">
                        {employeeExperienceTranslate.genderEEProfile}
                    </div>
                    <div className="radio-group" onChange={onChange}>
                        {lists.gender.map((item) => (
                            <div key={item.id}>
                                <input
                                    type="radio"
                                    value={item.id}
                                    name="gender"
                                    id={item.name}
                                    defaultChecked={
                                        item.id === Number(data.gender)
                                    }
                                />{" "}
                                <label htmlFor={item.name}>{item.name}</label>
                            </div>
                        ))}
                    </div>
                    {error.gender && (
                        <small>{employeeProfileEdit.profileError}</small>
                    )}
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="label">
                        {employeeExperienceTranslate.civilStatusEEProfile}
                    </div>
                    <div className="radio-group" onChange={onChange}>
                        {lists.maritalStatus.map((item) => (
                            <div key={item.id}>
                                <input
                                    type="radio"
                                    value={item.id}
                                    name="maritalStatus"
                                    id={item.name}
                                    defaultChecked={
                                        item.id === Number(data.maritalStatus)
                                    }
                                />
                                <label htmlFor={item.name}>{item.name}</label>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <label htmlFor="Children">
                        {employeeExperienceTranslate.sonsEEProfile}
                    </label>
                    <input
                        type="number"
                        name="childrens"
                        id="Children"
                        onChange={onChange}
                        value={Number(data.childrens)}
                    />
                </div>
            </div>
        </div>
    );
};
