// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yoydev-carousel {
  overflow: hidden;
}

.yoydev-inner {
  white-space: nowrap;
  transition: transform 0.3s;
}

.yoydev-carousel-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 35%;
}

.yoydev-btn-group {
  display: flex;
  gap: 1rem;
  position: absolute;
  right: 8rem;
  top: 10.5rem;
  z-index: 1;
}

.yoydev-btn {
  font-size: 1em;
  font-weight: 600;
  background-color: transparent;
  border: 1px solid #303030;
  border-radius: 50px;
  color: #303030;
  padding: 5px 15px;
  transition: all 300ms;
}
.yoydev-btn:hover {
  color: #fff;
  background-color: #7a6be6;
  border: 1px solid #7a6be6;
}`, "",{"version":3,"sources":["webpack://./src/Components/Carousel/Carousel.sass"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AACA;EACE,mBAAA;EACA,0BAAA;AAEF;;AAAA;EACE,oBAAA;EACA,mBAAA;EACA,uBAAA;EACA,UAAA;AAGF;;AADA;EACE,aAAA;EACA,SAAA;EAEA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;AAGF;;AADA;EACI,cAAA;EACA,gBAAA;EAEA,6BAAA;EACA,yBAAA;EACA,mBAAA;EACA,cAAA;EAEA,iBAAA;EACA,qBAAA;AAEJ;AADI;EACI,WAAA;EACA,yBAAA;EACA,yBAAA;AAGR","sourcesContent":[".yoydev-carousel \n  overflow: hidden\n\n.yoydev-inner\n  white-space: nowrap\n  transition: transform 0.3s\n\n.yoydev-carousel-item\n  display: inline-flex\n  align-items: center\n  justify-content: center\n  width: 35%\n\n.yoydev-btn-group\n  display: flex\n  gap: 1rem\n\n  position: absolute\n  right: 8rem\n  top: 10.5rem\n  z-index: 1\n\n.yoydev-btn\n    font-size: 1em\n    font-weight: 600\n\n    background-color: transparent\n    border: 1px solid #303030\n    border-radius: 50px\n    color: #303030\n\n    padding: 5px 15px\n    transition: all 300ms\n    &:hover \n        color: #fff\n        background-color: #7a6be6\n        border: 1px solid #7a6be6"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
