import { useEffect, useState } from 'react'
import Card from '../../Components/Card/card'
import { FormattedMessage } from 'react-intl'
import { useIntl } from 'react-intl'

const Genes = () => {
	const getLanguage = useIntl()
	const language = getLanguage.locale === 'PT-BR' ? 'pt' : 'es'
	const [video, setVideo] = useState('https://www.youtube.com/embed/oozm4ZLr0eE')

	useEffect(() => {
		setVideo(language === 'pt' ? 'https://www.youtube.com/embed/2vHvDxpEU9U' : 'https://www.youtube.com/embed/oozm4ZLr0eE')
	}, [language])

	return (
		<div className='row'>
			<div className='col d-flex justify-content-center offset-1'>
				<FormattedMessage id={'textGenesCard'}>
					{msg => (
						<Card
							language={language}
							title='MyDNA Outplacement'
							background='https://assets.mydna.company/iconoCard_dashboard-empresa.svg'
							url='https://outplacement.mydnadigital.com/login?lang=es'
							text={msg}
							video={video}
							file={`https://assets.mydna.company/Reporte_Outplacement_${language}.pdf`}
							stars={4.9}
							fileAmount={1}
						/>
					)}
				</FormattedMessage>
			</div>
		</div>
	)
}
export default Genes
