export const translate = {
	es: {
		'Indiferente': 'Indiferente',
		'Graduación': 'Graduación',
		'MBA': 'MBA',
		'MBA Internacional': 'MBA Internacional',
	},

	pt: {
		'Indiferente': 'Indiferente',
		'Graduación': 'Graduação',
		'MBA': 'MBA',
		'MBA Internacional': 'MBA Internacional',
	},
}
