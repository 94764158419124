// Upload file component for upload to aws s3 bucket with simple ui.  

import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { FaFileUpload } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';

import { postAssetsEmployee } from '../../../API/Employee/Employee';


const AttachmentFile = ({ setFile, file }) => {
    
    const [loading, setLoading] = useState(false);
    
    const onDrop = async (acceptedFiles) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('file', acceptedFiles[0]);
        const respForS3 = await postAssetsEmployee(formData);
        console.log("respForS3")
        // const response = await uploadFile(formData);
        // if (response.status === 200) {
        // setFile(response.data);
        // setLoading(false);
        // } else {
        // console.error("error ocu");
        // }
        console.log(formData)
    };
    
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/jpeg, image/png, application/pdf',
        multiple: false,
    });
    
    const handleDelete = () => {
        setFile(null);
    };
    
    return (
        <div className="attachment-file">
        <div className="attachment-file__dropzone" {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="attachment-file__dropzone__icon">
            <FaFileUpload />
            </div>
            <div className="attachment-file__dropzone__text">
            {"Uplaod file"}ß
            </div>
        </div>
        {file && (
            <div className="attachment-file__file">
            <div className="attachment-file__file__name">
                {file.originalname}
            </div>
            <div className="attachment-file__file__delete">
                <Button
                variant="danger"
                onClick={handleDelete}
                disabled={loading}
                >
                <MdDelete />
                </Button>
            </div>
            </div>
        )}
        </div>
    );
    }

export default AttachmentFile;





