import { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import Swal from "sweetalert2";
import { postEmailCompAndMotiv } from "../../API/Employee/MYCO";
import ModalResponseStatus from "../../Components/ModalResponseStatus/ModalResponseStatus";
import {
	modalAssignTranslate,
	motivacionCollaboratorTranslate,
	wheelCollaboratorTranslate,
} from "../../i18n/translatedMessages";

import { ProgressBar } from "react-bootstrap";
import { getMotivations } from "../../API/Analytics/MYCO";
import ChartsMYCO from "../../Components/ChartsMYCO/ChartsMYCO";
import Loading from "../../Components/Loading/Loading";
import { MotivacionesIcon } from "../../Icons/CollaboratorsIcons";
import { CompanyContext } from "../../Store/CompanyContext";

const B2EMotivaciones = () => {
	const user = JSON.parse(localStorage.getItem("user"));
	const { store, actions } = useContext(CompanyContext);

	const labelsMotivation = [
		"Orientación comercial",
		"Orientación al objetivo",
		"Orientación a la tipología del trabajo realizado",
		"Orientación a la innovación",
		"Aspecto Económico",
		"Autonomía y Versatilidad",
		"Seguridad",
		"Prestigio",
		"Balance Profesional",
		"Satisfacción con el puesto",
		"Compromiso",
		"Carrera",
		"Desarrollo de Competencias",
		"Proyecto Profesional",
		"Orientación a las Rel. Interpersonales",
		"Orientación Conceptual",
		"Orientación a la gestión",
	];
	const [motivations, setMotivations] = useState(null);

	useEffect(() => {
		const users = JSON.parse(localStorage.getItem("user"));
		const fetchEmployer = async () => {
			const motivationAux = await getMotivations(users.id);
			const motivationLessId =
				motivationAux.length > 1 ? motivationAux.at(-1) : null;
			if (!!motivationLessId) {
				delete motivationLessId.employerId;
				delete motivationLessId.id;
			}
			if (motivationAux.length > 1) setMotivations(motivationLessId);
		};
		fetchEmployer();
	}, []);

	const [showSpinner, setShowSpinner] = useState(false);

	const handleSend = async () => {
		setShowSpinner(true);
		const resp = await postEmailCompAndMotiv(user.id)
			.then(() => {
				setShowSpinner(false);
				Swal.fire({
					title: "👌",
					text: "Nuestro equipo se pondra en contacto a la brevedad!",
					icon: "success",
					confirmButtonText: "Ok",
				});
			})
			.catch(() => {
				actions.setResponseStatus(false);
				actions.setMessageResponseStatus("Ha ocurrido un error!");
				actions.setShowModalResponseStatus(true);
			});
	};

	const handleClose = () => {
		actions.setShowModalResponseStatus(false);
	};

	return (
		<>
			{showSpinner ? (
				<>
					{" "}
					<Loading />{" "}
				</>
			) : (
				<>
					<div className="container-fluid motivacion-main">
						<FormattedMessage id={"nextButton"}>
							{(msg) => (
								<ModalResponseStatus
									show={store.showModalResponseStatus}
									handleClose={handleClose}
									message={store.messageResponseStatus}
									successMessage={store.responseStatus}
									btnRight={msg}
								/>
							)}
						</FormattedMessage>
						<div className="row mb-4">
							<div className="col-1">
								<span className="title-icon p-2 m-auto">
									<MotivacionesIcon size={20} />
								</span>
							</div>
							<div className="col-8">
								<h1>
									{wheelCollaboratorTranslate.motivacions}
								</h1>
							</div>
						</div>
						<div className="row justify-content-center">
							{motivations ? (
								<div className="col-12 py-4">
									<div className="motivationsChartHeight w-100 m-auto">
										<ChartsMYCO
											testName={"Motivaciones"}
											labels={labelsMotivation}
											importData={motivations}
										/>
									</div>
									<div className="row row-cols-2 row-cols-md-3 g-3 mt-3">
										{Object.values(motivations).map(
											(motivation, index) => {
												return (
													<div
														key={index}
														className={`col`}
													>
														<h6 className={`m-0`}>
															{
																labelsMotivation[
																	index
																]
															}
														</h6>
														<ProgressBar
															label={
																motivation +
																"/10"
															}
															className={`rounded-pill ${
																Number(
																	motivation
																) >= 5
																	? `motivationsAnswerPositive`
																	: `motivationsAnswerNegative`
															}`}
															now={motivation}
															max={10}
														/>
													</div>
												);
											}
										)}
									</div>
								</div>
							) : (
								<>
									<div className="col-12 pt-4 text-center">
										<h4 className="title-b2e">
											{
												motivacionCollaboratorTranslate.motivacionTitle
											}
										</h4>
									</div>
									<div className="col-12 pt-4 color-grey text-center">
										<h5>
											{
												motivacionCollaboratorTranslate.motivacionCollaboratorTitle
											}
										</h5>
										<h5>
											{
												motivacionCollaboratorTranslate.motivacionalCollaboratorTextP1
											}{" "}
											<b>
												{
													motivacionCollaboratorTranslate.motivacionalCollaboratorTextP2
												}
											</b>
											.
											{
												motivacionCollaboratorTranslate.motivacionalCollaboratorTextP3
											}
										</h5>
										<button
											className="btn motivacion-btn rounded-pill my-3"
											onClick={handleSend}
										>
											{modalAssignTranslate.clickHere}
										</button>
										<h5 className="pt-3">
											{
												motivacionCollaboratorTranslate.motivacionalCollaboratorTextEmail
											}
										</h5>
										<h5 className="pb-4">
											{
												motivacionCollaboratorTranslate.motivacionalCollaboratorDoubtsText
											}
										</h5>
									</div>
								</>
							)}
						</div>
					</div>
				</>
			)}
		</>
	);
};
export default B2EMotivaciones;
