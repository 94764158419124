// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-entrenamiento .loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255);
  z-index: 1;
}
.content-entrenamiento .icon svg {
  background: linear-gradient(45deg, #40c2ff 0%, #32d8d8 100%);
  width: 50px;
  height: 50px;
  margin: 0 10px;
  fill: white;
  padding: 5px;
  border-radius: 50rem;
  color: white;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.25);
}
.content-entrenamiento h1 {
  color: #3a3a3a;
  margin: 0;
  padding: 0;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
}
.content-entrenamiento table tr th {
  font-weight: bold;
}
.content-entrenamiento .table-box {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: white;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  max-height: calc(100vh - 250px);
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/Views/Analytics/entrenamiento.sass"],"names":[],"mappings":"AACI;EACI,kBAAA;EACA,MAAA;EACA,OAAA;EAEA,WAAA;EACA,YAAA;EACA,8BAAA;EACA,UAAA;AADR;AAIQ;EACI,4DAAA;EACA,WAAA;EACA,YAAA;EAGA,cAAA;EACA,WAAA;EACA,YAAA;EACA,oBAAA;EACA,YAAA;EACA,yCAAA;AAFZ;AAII;EACI,cAAA;EACA,SAAA;EACA,UAAA;EACA,0CAAA;AAFR;AAII;EACI,iBAAA;AAFR;AAII;EACI,aAAA;EACA,qCAAA;EACA,iBAAA;EACA,yCAAA;EACA,kBAAA;EACA,+BAAA;EACA,gBAAA;AAFR","sourcesContent":[".content-entrenamiento\n    & .loading\n        position: absolute\n        top: 0\n        left: 0\n\n        width: 100%\n        height: 100%\n        background: rgba(255,255,255,1)\n        z-index: 1\n\n    .icon\n        & svg\n            background: linear-gradient(45deg,#40c2ff 0%, #32d8d8 100%)\n            width: 50px\n            height: 50px\n            border-radius: 50rem\n            padding: 8px\n            margin: 0 10px\n            fill: white\n            padding: 5px\n            border-radius: 50rem\n            color: white\n            box-shadow: 0 5px 8px rgba(0,0,0,.25)\n\n    h1\n        color: #3a3a3a\n        margin: 0\n        padding: 0\n        text-shadow: 0 2px 5px rgba(0,0,0,.25)\n\n    table tr th\n        font-weight: bold\n\n    .table-box\n        padding: 10px\n        border: 1px solid rgba(0,0,0,.05)\n        background: white\n        box-shadow: 0 5px 8px rgba(0,0,0,.25)\n        border-radius: 5px\n        max-height: calc(100vh - 250px)\n        overflow-y: auto\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
