import { useState, useEffect } from "react";
import { getEmployeesByCompany } from "../../API/Employee/Employee";
import Loading from "../../Components/Loading/Loading";
import {
    careerPlanTranslate,
    employeeExperienceTranslate,
} from "../../i18n/translatedMessages";
import { PlanCarreraIcon } from "../../Icons/CollaboratorsIcons";
import HierarchicalLevel from "./Components/HierarchicalLevel/HierarchicalLevel";

import "./planCarrera.sass";

const PlanCarrera = () => {
    const [employee, setEmployee] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));

        const fetchEmployees = async () => {
            setLoading(true);
            const response = await getEmployeesByCompany(user.companyId);

            const [data] = response.filter(
                (employee) => employee?.id === user.id
            );
            console.log(data);

            setEmployee({
                actualLevel: data.level.PositionN,
                nextLevel: data.level.PositionNPlus1,
                profileArea: data.area.profileArea,
                affineArea1: data.area.affineArea1,
                affineArea2: data.area.affineArea2,
            });
        };
        fetchEmployees();
        setLoading(false);
    }, []);

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <div className="content-plan-carrera plandecarrera-main">
                    <div className="title">
                        <div className="icon">
                            <PlanCarreraIcon />
                        </div>
                        <h1>{careerPlanTranslate.careerPlanTitle}</h1>
                    </div>
                    <div className="body-nivel">
                        <div className="row background-card card-body shadow">
                            <div className="col-md-6 grid-container hierarchical-level">
                                <div className="title-nivel">
                                    {careerPlanTranslate.hierarchicalLevel}
                                </div>
                                <div className="row content-nivel">
                                    <div className="col-12">
                                        <div className="container-fluid"></div>
                                        <div className="row content-item">
                                            <div className="col-4 text-right">
                                                {employee.nextLevel ===
                                                "Director"
                                                    ? careerPlanTranslate.nextLevel
                                                    : ""}{" "}
                                                {employee.actualLevel ===
                                                "Director"
                                                    ? careerPlanTranslate.actualLevel
                                                    : "-"}
                                            </div>
                                            <div className="col-8">
                                                <div
                                                    className={`box ${
                                                        employee.nextLevel ===
                                                        "Director"
                                                            ? "nextLevel"
                                                            : ""
                                                    } ${
                                                        employee.actualLevel ===
                                                        "Director"
                                                            ? "actualLevel"
                                                            : ""
                                                    }`}
                                                ></div>
                                                {
                                                    employeeExperienceTranslate.directorCP
                                                }
                                            </div>
                                        </div>
                                        <div className="row content-item">
                                            <div className="col-4 text-right">
                                                {employee.nextLevel ===
                                                "Gerentes"
                                                    ? careerPlanTranslate.nextLevel
                                                    : ""}{" "}
                                                {employee.actualLevel ===
                                                "Gerentes"
                                                    ? careerPlanTranslate.actualLevel
                                                    : "-"}
                                            </div>
                                            <div className="col-8">
                                                <div
                                                    className={`box ${
                                                        employee.nextLevel ===
                                                        "Gerentes"
                                                            ? "nextLevel"
                                                            : ""
                                                    } ${
                                                        employee.actualLevel ===
                                                        "Gerentes"
                                                            ? "actualLevel"
                                                            : ""
                                                    }`}
                                                ></div>
                                                {
                                                    employeeExperienceTranslate.managerCP
                                                }
                                            </div>
                                        </div>
                                        <div className="row content-item">
                                            <div className="col-4 text-right">
                                                {employee.nextLevel ===
                                                "Subgerentes"
                                                    ? careerPlanTranslate.nextLevel
                                                    : ""}{" "}
                                                {employee.actualLevel ===
                                                "Subgerentes"
                                                    ? careerPlanTranslate.actualLevel
                                                    : "-"}
                                            </div>
                                            <div className="col-8">
                                                <div
                                                    className={`box ${
                                                        employee.nextLevel ===
                                                        "Subgerentes"
                                                            ? "nextLevel"
                                                            : ""
                                                    } ${
                                                        employee.actualLevel ===
                                                        "Subgerentes"
                                                            ? "actualLevel"
                                                            : ""
                                                    }`}
                                                ></div>
                                                {
                                                    employeeExperienceTranslate.submanagerCP
                                                }
                                            </div>
                                        </div>
                                        <div className="row content-item">
                                            <div className="col-4 text-right">
                                                {employee.nextLevel === "Jefes"
                                                    ? careerPlanTranslate.nextLevel
                                                    : ""}{" "}
                                                {employee.actualLevel ===
                                                "Jefes"
                                                    ? careerPlanTranslate.actualLevel
                                                    : "-"}
                                            </div>
                                            <div className="col-8">
                                                <div
                                                    className={`box ${
                                                        employee.nextLevel ===
                                                        "Jefes"
                                                            ? "nextLevel"
                                                            : ""
                                                    } ${
                                                        employee.actualLevel ===
                                                        "Jefes"
                                                            ? "actualLevel"
                                                            : ""
                                                    }`}
                                                ></div>
                                                {
                                                    employeeExperienceTranslate.bossCP
                                                }
                                            </div>
                                        </div>
                                        <div className="row content-item">
                                            <div className="col-4 text-right">
                                                {employee.nextLevel ===
                                                "Especialistas / Coordinadores"
                                                    ? careerPlanTranslate.nextLevel
                                                    : ""}{" "}
                                                {employee.actualLevel ===
                                                "Especialistas / Coordinadores"
                                                    ? careerPlanTranslate.actualLevel
                                                    : "-"}
                                            </div>
                                            <div className="col-8">
                                                <div
                                                    className={`box ${
                                                        employee.nextLevel ===
                                                        "Especialistas / Coordinadores"
                                                            ? "nextLevel"
                                                            : ""
                                                    } ${
                                                        employee.actualLevel ===
                                                        "Especialistas / Coordinadores"
                                                            ? "actualLevel"
                                                            : ""
                                                    }`}
                                                ></div>
                                                {
                                                    employeeExperienceTranslate.specialistCP
                                                }
                                            </div>
                                        </div>
                                        <div className="row content-item">
                                            <div className="col-4 text-right">
                                                {employee.nextLevel ===
                                                "Analista"
                                                    ? careerPlanTranslate.nextLevel
                                                    : ""}{" "}
                                                {employee.actualLevel ===
                                                "Analista"
                                                    ? careerPlanTranslate.actualLevel
                                                    : "-"}
                                            </div>
                                            <div className="col-8">
                                                <div
                                                    className={`box ${
                                                        employee.nextLevel ===
                                                        "Analista"
                                                            ? "nextLevel"
                                                            : ""
                                                    } ${
                                                        employee.actualLevel ===
                                                        "Analista"
                                                            ? "actualLevel"
                                                            : ""
                                                    }`}
                                                ></div>
                                                {
                                                    employeeExperienceTranslate.analystCP
                                                }
                                            </div>
                                        </div>
                                        <div className="row content-item">
                                            <div className="col-4 text-right">
                                                {employee.actualLevel ===
                                                "Asistente"
                                                    ? careerPlanTranslate.actualLevel
                                                    : "-"}
                                            </div>

                                            <div className="col-8">
                                                <div
                                                    className={`box ${
                                                        employee.actualLevel ===
                                                        "Asistente"
                                                            ? "actualLevel"
                                                            : ""
                                                    }`}
                                                ></div>
                                                <span>
                                                    {
                                                        employeeExperienceTranslate.assistantCP
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row content-item">
                                            <div className="col-4 text-right">
                                                {employee.actualLevel ===
                                                "Técnico"
                                                    ? careerPlanTranslate.actualLevel
                                                    : "-"}
                                            </div>

                                            <div className="col-8">
                                                <div
                                                    className={`box ${
                                                        employee.actualLevel ===
                                                        "Técnico"
                                                            ? "actualLevel"
                                                            : ""
                                                    }`}
                                                ></div>
                                                <span>
                                                    {
                                                        employeeExperienceTranslate.technicianCP
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row content-item">
                                            <div className="col-4 text-right">
                                                {employee.actualLevel ===
                                                "Operario"
                                                    ? careerPlanTranslate.actualLevel
                                                    : "-"}
                                            </div>

                                            <div className="col-8">
                                                <div
                                                    className={`box ${
                                                        employee.actualLevel ===
                                                        "Operario"
                                                            ? "actualLevel"
                                                            : ""
                                                    }`}
                                                ></div>
                                                <span>
                                                    {
                                                        employeeExperienceTranslate.operatorCP
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <HierarchicalLevel actualLevel={employee.actualLevel} nextLevel={employee.nextLevel} /> */}
                                </div>
                            </div>
                            <div className="col-md-6 grid-container margin-card">
                                <div className="title-nivel">
                                    {careerPlanTranslate.possibleAreas}
                                </div>
                                <div className="informative-body-text">
                                    {careerPlanTranslate.collaboratorTextP1}{" "}
                                    <strong>
                                        {employee.profileArea?.toUpperCase()}
                                    </strong>
                                    , {careerPlanTranslate.collaboratorTextP2}:
                                </div>
                                <div className="possible-areas">
                                    {employee.affineArea1}
                                </div>
                                <div className="possible-areas">
                                    {employee.affineArea2}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default PlanCarrera;
