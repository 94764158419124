// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.askes {
  text-align: justify;
  font-size: 16px;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/Views/Culture/Components/Askes/askes.sass"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,eAAA;EACA,gBAAA;AACJ","sourcesContent":[".askes\n    text-align: justify\n    font-size: 16px\n    font-weight: 500\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
