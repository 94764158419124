import Modal from 'react-bootstrap/Modal'
import { useIntl } from 'react-intl'

import { AiFillCloseCircle } from 'react-icons/ai'

import './modal.sass'

export function DeleteEmployee({ show, handleShow, handleDelete }) {
	const language = useIntl()
	return (
		<Modal
			show={show}
			centered
			onHide={() => {
				handleShow(false)
			}}
		>
			<Modal.Header closeButton className='border-0 mb-0 pb-0'></Modal.Header>
			<Modal.Body className='ps-0 m-0 flex justify-content-center'>
				<AiFillCloseCircle size={50} className='text-negative-red' />
				<span className='fs-5 fw-bold'> {language.locale === 'ES' ? 'Confirmar y Eliminar' : 'Confirmar e Excluir'}</span>
			</Modal.Body>
			<Modal.Footer className='border-0 m-0'>
				<div className='row w-100'>
					<div className='col'>
						<button className='btn btn-outline-primary rounded-pill w-100' onClick={() => handleShow(false)}>
							Cancelar
						</button>
					</div>
					<div className='col'>
						<button className='btn btn-negative-red text-white rounded-pill w-100' onClick={handleDelete}>
							{language.locale === 'ES' ? 'Eliminar' : 'Excluir'}
						</button>
					</div>
				</div>
			</Modal.Footer>
		</Modal>
	)
}
