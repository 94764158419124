// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.smtm {
  padding: 10px 50px;
}
.smtm .loading {
  position: absolute;
  top: 0;
  left: 361px;
  width: calc(100% - 370px);
  height: 100%;
  background: rgb(255, 255, 255);
  z-index: 1;
}
.smtm input[type=text] {
  border-radius: 8px;
  width: 100%;
}
.smtm input[type=number] {
  border-radius: 8px;
  width: 100%;
  background: #ffffff;
  height: 36px;
  border: 2px solid #6d68aa;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif !important;
  outline: none;
  padding: 0 18px;
  margin: 15px 0;
}
.smtm input[type=range] {
  width: 100%;
}
.smtm select {
  border-radius: 8px !important;
  width: 100%;
  height: 36px;
  border: 2px solid #6d68aa !important;
  padding: 0 18px;
  margin: 15px 0;
}
.smtm button {
  width: 250px;
  height: 36px;
  border: none;
  border-radius: 50rem;
  background: linear-gradient(145deg, #3CC4FF, #2DDDBD 75%);
  color: white;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/Views/Service/Views/SalariosYBeneficios/SMTM/smtm.sass"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AACI;EACI,kBAAA;EACA,MAAA;EACA,WAAA;EAEA,yBAAA;EACA,YAAA;EACA,8BAAA;EACA,UAAA;AAAR;AAEI;EACI,kBAAA;EACA,WAAA;AAAR;AAEI;EACI,kBAAA;EACA,WAAA;EACA,mBAAA;EACA,YAAA;EACA,yBAAA;EACA,sBAAA;EACA,gDAAA;EACA,aAAA;EACA,eAAA;EACA,cAAA;AAAR;AAEI;EACI,WAAA;AAAR;AAEI;EACI,6BAAA;EACA,WAAA;EACA,YAAA;EACA,oCAAA;EACA,eAAA;EACA,cAAA;AAAR;AAEI;EACI,YAAA;EACA,YAAA;EACA,YAAA;EACA,oBAAA;EACA,yDAAA;EACA,YAAA;EACA,gBAAA;AAAR","sourcesContent":[".smtm\n    padding: 10px 50px\n\n    & .loading\n        position: absolute\n        top: 0\n        left: 361px\n\n        width: calc(100% - 370px)\n        height: 100%\n        background: rgba(255,255,255,1)\n        z-index: 1\n\n    & input[type=\"text\"]\n        border-radius: 8px\n        width: 100%\n\n    & input[type=\"number\"]\n        border-radius: 8px\n        width: 100%\n        background: #ffffff\n        height: 36px\n        border: 2px solid #6d68aa\n        box-sizing: border-box\n        font-family: \"Montserrat\", sans-serif !important\n        outline: none\n        padding: 0 18px\n        margin: 15px 0\n\n    & input[type=\"range\"]\n        width: 100%\n\n    & select\n        border-radius: 8px !important\n        width: 100%\n        height: 36px\n        border: 2px solid #6d68aa !important\n        padding: 0 18px\n        margin: 15px 0\n\n    & button\n        width: 250px\n        height: 36px\n        border: none\n        border-radius: 50rem\n        background: linear-gradient(145deg,#3CC4FF, #2DDDBD 75%)\n        color: white\n        font-weight: 500\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
