import { MdErrorOutline } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import './permission.sass'

const Permission = ({ route }) => {
    const history = useHistory()

    return (
        <div className="error-permission">
            <MdErrorOutline size={30} />
            <span>Su usuario no tiene permiso para acceder a esta área!</span>
            <button onClick={() => history.push(route)} >Volver a Mandala</button>
        </div>
    )
}

export default Permission