import AWS from "aws-sdk";
const awsAccessKey = process.env.REACT_APP_AWS_ACCESS_KEY;
const awsSecretKey = process.env.REACT_APP_AWS_SECRET_KEY;

AWS.config.update({
  region: "us-east-1",
  accessKeyId: awsAccessKey,
  secretAccessKey: awsSecretKey,
});

const sqs = new AWS.SQS({
  apiVersion: "2012-11-05",
  accessKeyId: awsAccessKey,
  secretAccessKey: awsSecretKey,
});

export const mailSender = async (section, name, lastname, email) => {
  try {
    const params = {
      DelaySeconds: 2,
      MessageAttributes: {
        Author: {
          DataType: "String",
          StringValue: "Admin",
        },
      },
      MessageBody: JSON.stringify({
        name: name,
        lastname: lastname,
        email: email,
        section: section,
      }),

      QueueUrl:
        "https://sqs.us-east-1.amazonaws.com/274328836318/service_request_queue",
      // process.env.REACT_APP_AWS_SQSURL
    };
    let queueRes = await sqs.sendMessage(params).promise();
    const response = {
      statusCode: 200,
      body: queueRes,
    };

    throw response;
  } catch (error) {
    throw error;
  }
};
