import {
    employeeExperienceTranslate,
    modalAssignTranslate,
} from "../../../../i18n/translatedMessages";
import "./quiz.sass";
import ToggleThreePosistions from "./ToggleThreePositions";

const AskBox = ({ ask, me, industry, handleIndustry, handleUser }) => {
    const color = () => {
        if (ask.type === "cultural") return "#0ab4c4";
        if (ask.type === "carrera") return "#746be8";
        if (ask.type === "liderazgo") return "#2b97db";
        return "#5fb1a4";
    };

    return (
        <div className="askBox">
            <div className="question" style={{ backgroundColor: color() }}>
                {ask.question}
            </div>
            <div className="answer">
                <span>
                    {employeeExperienceTranslate.cultureQuestionsEEForMe}
                </span>
                <div className="d-flex my-1">
                    {modalAssignTranslate.yesText}{" "}
                    <ToggleThreePosistions
                        toggle={me}
                        color={color()}
                        id={ask.id}
                        handleToggle={handleUser}
                    />{" "}
                    {modalAssignTranslate.noText}
                </div>
            </div>
        </div>
    );
};
export default AskBox;
