import { useEffect, useState } from "react";
import { MdArrowBack } from "react-icons/md";
import { FaSave } from "react-icons/fa";
import { Link } from "react-router-dom";
import Loading from "../../Components/Loading/Loading";

import PurposesArea from "./Components/PurposesArea/PurposesArea";

import {
  getPurposeList,
  postPurpose,
  getPurposeByEmployeeId,
  deletePurpose,
} from "../../API/Employee/Purpose";
import { myPurposesTranslate } from "../../i18n/translatedMessages";
import { btnTranslate } from "../../i18n/translatedMessages";

import { filterPurpose } from "./Components/PurposesArea/filter";
import { PropositoIcon } from "../../Icons/CollaboratorsIcons";
import { useIntl } from "react-intl";

const B2EProposito = () => {
  const language = useIntl();
  const user = JSON.parse(localStorage.getItem("user"));
  const [purposes, setPurposes] = useState([]);
  const [purposeEmployeeList, setPurposeEmployeeList] = useState([]);

  const [dataPurpose, setDataPurpose] = useState([]);
  const [listDelete, setListDelete] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchPurpose = async () => {
    setLoading(true);
    const purposeListAux = await getPurposeList(
      language.locale === "PT-BR" ? "pt-br" : language.locale === "EN" ? "en-us" : "es"
    );
    const response = await getPurposeByEmployeeId(
      user.id,
      language.locale === "PT-BR" ? "pt-br" : language.locale === "EN" ? "en-us" : "es"
    );
    if (response) {
      const [...purposesAux] = response.data.map((purpose) => {
        const data = purposeListAux.items.filter((item) => item.purposeId === purpose.purposeId);
        return data[0];
      });
      setPurposes(purposesAux);
      setPurposeEmployeeList(purposesAux);
    }
    setDataPurpose(filterPurpose(purposeListAux.items));
    setLoading(false);
  };
  useEffect(() => {
    fetchPurpose();
  }, []);

  const remove = (object) => {
    const list = [...purposes];
    const auxDelete = [...listDelete];
    const index = purposes.map((purpose) => purpose?.purposeId).indexOf(object.purposeId);
    auxDelete.push(object.purposeId);
    list.splice(index, 1);

    setListDelete(auxDelete);

    return list;
  };

  const add = (object) => {
    const list = [...purposes];
    list.push(object);
    return list;
  };

  const handleChose = (event) => {
    const object = JSON.parse(event.currentTarget.dataset.object);
    setPurposes(object.active ? remove(object) : add(object));
  };

  const handleSend = async () => {
    setLoading(true);

    const filterRepeated = purposes.filter(
      (item) => !purposeEmployeeList.find((x) => x.purposeId === item.purposeId)
    );
    const sendAux = filterRepeated.map((purpose) => purpose?.purposeId);
    const sendPurpose = {
      employerId: user.id,
      purposesList: sendAux,
    };
    console.log(filterRepeated);
    if (listDelete.length > 0) {
      const list = {
        purposeList: listDelete,
      };

      await deletePurpose(list, user.id);
      setListDelete([]);
    }

    if (purposes.length > 0) {
      await postPurpose(sendPurpose);
    }
    fetchPurpose();
    setLoading(false);
  };

  return (
    <div className="container-fluid purpose purpose-main">
      <div className="row">
        <div className="col-12">
          <div className="proposito-b2c">
            <div className="row mb-4">
              <div className="col-1">
                <span className="title-icon p-2 m-auto">
                  <PropositoIcon size={20} />
                </span>
              </div>
              <div className="col-8">
                <h1>{myPurposesTranslate.myPurposes}</h1>
              </div>
            </div>
            <div className="row">
              <p className="description-section">{myPurposesTranslate.myPurposeText}</p>
            </div>

            {loading ? (
              <Loading />
            ) : (
              <div className="row">
                <div className="col">
                  <PurposesArea
                    list={dataPurpose}
                    checkedList={purposes}
                    handleChose={handleChose}
                  />
                </div>
              </div>
            )}
            <div className="row mt-4">
              <div className="col">
                <Link className="button-volver color-blue" to={"/employee"}>
                  <MdArrowBack size={20} />
                  {btnTranslate.backButton}
                </Link>
              </div>
              <div className="col d-flex justify-content-end">
                <button className="btn btn-save" onClick={handleSend}>
                  <FaSave size="18" />
                  <span className="fs-5 ms-3">{btnTranslate.saveButton}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default B2EProposito;
