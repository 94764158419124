import { useCallback, useEffect, useState } from 'react'

import { Input } from '../Input/Input'
import { Select } from '../Select_Required/Select.jsx'

import countryMapper from '../../../Utils/CountriesMapper.js'
import areasMapper from '../../../Utils/AreasMapper.js'
import subareasMapper from '../../../Utils/SubareasMapper.js'
import levelsMapper from '../../../Utils/LevelsMapper.js'

import List from '../../../API/Lists/Lists.js'
import { formationList, seniorityList, englishList } from './lists.js'
import { formEETranslate } from '../../../i18n/translatedMessages.js'
import { FormattedMessage, useIntl } from 'react-intl'

import { translate as translateAreas } from '../../../i18n/Library/areasCorrelation.js'
import { translate as translatePosition } from '../../../i18n/Library/hierarchicalLevel'
import { translate as translateEnglishLevel } from '../../../i18n/Library/englishLevel.js'
import { translate as translateFormation } from '../../../i18n/Library/formationTranslate.js'
import { translate as translateSenority } from '../../../i18n/Library/senorityTranslate.js'
import { translate as translateSubAreas } from '../../../i18n/Library/subareasTranslate.js'

export function Form({ handleChange, user, setUser, errors, emailDisabled = false }) {
	const getLanguage = useIntl()
	const language = getLanguage.locale === 'PT-BR' ? 'pt' : 'es'
	const [countries, setCountries] = useState([])
	const [areas, setAreas] = useState([])
	const [relatedSubareas, setRelatedSubareas] = useState([])
	const [subareas, setSubareas] = useState([])
	const [levels, setLevels] = useState([])

	const load = useCallback(async () => {
		try {
			const [resArea, resCountry, resLevel, resSubareas] = await Promise.all([List.areas(), List.countries(), List.levels(), List.subareas()])

			setCountries(resCountry.map(countryMapper.toDomain))
			setAreas(resArea.map(areasMapper.toDomain))
			setLevels(resLevel.map(levelsMapper.toDomain))
			setSubareas(resSubareas.map(subareasMapper.toDomain))
		} catch {}
	}, [])

	useEffect(() => {
		load()
	}, [load])

	const handlePosition = event => {
		const position = event.target.value.toLowerCase().split(' ')

		const [get_level] = levels.filter(item => position.includes(item.value.toLowerCase()))
		const [get_subarea] = subareas.filter(item => position.includes(item.value.toLowerCase()))

		setUser({
			...user,
			position: event.target.value,
			level: get_level?.id,
			subarea: get_subarea?.id || user?.subarea,
			subareaName: get_subarea?.value || user?.subareaName,
			area: get_subarea?.area,
		})
	}
	const handleSubareas = event => {
		const [subarea] = subareas.filter(item => item.id === event.target.value)
		setUser({
			...user,
			subarea: subarea?.id,
			subareaName: subarea?.value,
		})
	}

	const handleAreas = event => {
		setUser({
			...user,
			[event.target.name]: event.target.value,
			subarea: null,
			subareaName: null,
		})
	}

	useEffect(() => {
		if (user?.area) {
			setRelatedSubareas(translatedSubAreas.filter(item => item.area === user.area))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user?.area, subareas])

	useEffect(() => {
		if (user?.level && user?.subarea) {
			const [levelName] = levels.filter(item => item.id === user.level)
			const [subarea] = subareas.filter(item => item.id === user.subarea)

			setUser({
				...user,
				positionName: levelName?.value || subarea?.value ? `${levelName?.value} de ${subarea?.value}` : user?.positionName,
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user?.level, user?.subarea])

	const translatedAreas = areas.map(item => ({
		...item,
		value: translateAreas[language][item?.value],
	}))

	const translatedLevels = levels.map(item => ({
		...item,
		value: translatePosition[language][item?.value],
	}))

	const translatedEnglishLevel = englishList.map(item => ({
		...item,
		value: translateEnglishLevel[language][item?.value],
	}))

	const translatedFormation = formationList.map(item => ({
		...item,
		value: translateFormation[language][item?.value],
	}))

	const translatedSenority = seniorityList.map(item => ({
		...item,
		value: translateSenority[language][item?.value],
	}))

	const translatedSubAreas = subareas.map(item => ({
		...item,
		value: translateSubAreas[language][item?.value],
	}))

	return (
		<>
			<div className='row'>
				<div className='col'>
					<h5 className='fw-bolder mb-3'>{formEETranslate.EEpersonalInformation}</h5>
				</div>
			</div>
			<div className='row my-1'>
				<div className='col'>
					{
						<FormattedMessage id={'EENamePlaceholder'}>
							{msg => <Input label={formEETranslate.EEFormName} placeholder={msg} onChange={handleChange} name='name' value={user?.name || ''} required error={errors?.includes('name')} />}
						</FormattedMessage>
					}
				</div>
				<div className='col'>{<Input label={formEETranslate.EEFormEmail} placeholder='Email' onChange={handleChange} name='email' value={user?.email || ''} required error={errors?.includes('email')} disabled={emailDisabled} />}</div>
			</div>
			<div className='row my-1'>
				<div className='col'>
					<Select label={formEETranslate.EEFormCountry} placeholder={formEETranslate.EEFormSelect} onChange={handleChange} name='country' value={user?.country} required list={countries} error={errors?.includes('country')} />
				</div>
				<div className='col'>
					<Select label={formEETranslate.EEFormRegion} placeholder={formEETranslate.EEFormSelect} onChange={handleChange} name='region' value={user?.region} required list={countries} error={errors?.includes('region')} disabled />
				</div>
			</div>
			<div className='row my-1'>
				<div className='col'>
					<FormattedMessage id={'EEPositionPlaceholder'}>
						{msg => <Input label={formEETranslate.EEFormPosition} placeholder={msg} onChange={handlePosition} name='position' value={user?.position || ''} required error={errors?.includes('position')} />}
					</FormattedMessage>
				</div>
				<div className='col'>
					<Select label={formEETranslate.EEFormNivel} placeholder={formEETranslate.EEFormSelect} onChange={handleChange} name='level' value={user?.level} required error={errors?.includes('level')} list={translatedLevels} />
				</div>
			</div>
			<div className='row my-1'>
				<div className='col'>
					<Select label={formEETranslate.EEFormArea} placeholder={formEETranslate.EEFormSelect} onChange={handleAreas} name='area' value={user?.area} required error={errors?.includes('area')} list={translatedAreas} />
				</div>
				<div className='col'>
					<Select
						label={formEETranslate.EEFormSubarea}
						placeholder={formEETranslate.EEFormSelect}
						onChange={handleSubareas}
						name='subarea'
						value={user?.subarea}
						required
						error={errors?.includes('subarea')}
						list={relatedSubareas}
						disabled={relatedSubareas.length === 0}
					/>
				</div>
			</div>
			<div className='row my-1'>
				<div className='col'>
					<Select label={formEETranslate.EEFormEnglishLevel} placeholder={formEETranslate.EEFormSelect} onChange={handleChange} name='english' value={user?.english} list={translatedEnglishLevel} />
				</div>
				<div className='col'>
					<Select label={formEETranslate.EEFormFormation} placeholder={formEETranslate.EEFormSelect} onChange={handleChange} name='training' value={user?.training} list={translatedFormation} />
				</div>
			</div>
			<div className='row my-1'>
				<div className='col'>
					<Select label={formEETranslate.EEFormFunction} placeholder={formEETranslate.EEFormSelect} onChange={handleChange} name='experience' value={user?.experience} list={translatedSenority} />
				</div>
				<div className='col'>{<Input label={formEETranslate.EEFormSalaryFix} placeholder='Valor' onChange={handleChange} name='salary' value={user?.salary || ''} />}</div>
			</div>
			<div className='row my-1'>
				<div className='col'>
					<FormattedMessage id={'EESalaryVariablePlaceholder'}>
						{msg => <Input label={formEETranslate.EEFormSalaryVariable} placeholder={msg} onChange={handleChange} name='variableSalary' value={user?.variableSalary || ''} />}
					</FormattedMessage>
				</div>
				<div className='col'></div>
			</div>
		</>
	)
}
