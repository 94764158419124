import { useEffect, useState } from 'react'
import { getEmployers } from '../../../../../API/Analytics/employers'

import { myCollaboratorsTranslate } from '../../../../../i18n/translatedMessages'
import Loading from '../../../../../Components/Loading/Loading'
import { getCulture } from '../../../../../API/Employee/Culture'

import CulturaBarMatch from '../../../../Employee/Components/CulturaBarMatch/CulturaBarMatch'
import { cultureTranslate } from '../../../../../i18n/translatedMessages'

import './myColabs.sass'
import { FormattedMessage } from 'react-intl'
import './myColabs.sass'

import './ListaColaboradores.sass'

const img = 'https://assets.mydna.company/profile-placeholder.png'

const ListaColaboradores = () => {
	const [busca, setBusca] = useState('')
	const [loading, setLoading] = useState(true)
	const [loadCulture, setLoadCulture] = useState(false)

	const [employees, setEmployees] = useState([])

	useEffect(() => {
		const user = JSON.parse(localStorage.getItem('user'))

		const fetchEmployees = async () => {
			const response = await getEmployers(user.id)
			setEmployees(response)
			setLoading(false)
		}

		fetchEmployees()
	}, [])

	const lowerBusca = busca.toLowerCase()

	const employeesFilt = employees?.filter(employee => {
		return employee.name.toLowerCase().includes(lowerBusca)
	})

	const placeholder = myCollaboratorsTranslate.searchCollaborator

	const handleChange = e => {
		setBusca(e.target.value)
	}

	const [currentEmployeId, setcurrentEmployeId] = useState(null)

	const [bars, setBars] = useState([])

	useEffect(() => {
		setBars([])
		const fetchData = async () => {
			setLoadCulture(true)
			const response = await getCulture(currentEmployeId)
			if (response) {
				setBars([
					{
						id: 0,
						icon: 'https://assets.mydna.company/Comercial.svg',
						percentagem: !!response ? parseFloat(response['comercial']).toFixed(2) : null,
						type: cultureTranslate.commercial,
						color: '#7a6be6',
						textArea: cultureTranslate.commercialText,
					},
					{
						id: 1,
						icon: 'https://assets.mydna.company/Disruptivo.svg',
						percentagem: !!response ? parseFloat(response['disruptiva']).toFixed(2) : null,
						type: cultureTranslate.disruptive,
						color: '#41c6ff',
						textArea: cultureTranslate.disruptiveText,
					},
					{
						id: 2,
						icon: 'https://assets.mydna.company/Colaborativo.svg',
						percentagem: !!response ? parseFloat(response['colaborativa']).toFixed(2) : null,
						type: cultureTranslate.collaborative,
						color: '#2be7c8',
						textArea: cultureTranslate.collaborativeText,
					},
					{
						id: 3,
						icon: 'https://assets.mydna.company/Tradicional.svg',
						percentagem: !!response ? parseFloat(response['tradicional']).toFixed(2) : null,
						type: cultureTranslate.traditional,
						color: '#38364b',
						textArea: cultureTranslate.traditionalText,
					},
				])
				setLoadCulture(false)
			}
			setLoadCulture(false)
		}
		fetchData()
	}, [currentEmployeId])

	const [currentEmployeName, setcurrentEmployeName] = useState('')

	const setcurrentEmployeData = (id, name) => {
		setcurrentEmployeId(id)
		setcurrentEmployeName(name)
	}

	return (
		<div className='row'>
			<div className='col offset-1'>
				<div className='my-colaboradores'>
					{loading && (
						<div className='loading'>
							<Loading />
						</div>
					)}
					<div className='row pb-3'>
						<div className='col-10 offset-1 border-bottom pb-3'>
							<FormattedMessage id={'searchCollaborator'}>{msg => <input type='text' className='principal-search adjust-input-principal-s w-100' placeholder={msg} value={busca} onChange={handleChange} />}</FormattedMessage>
						</div>
					</div>
					<div className='row'>
						<div className='col-3'>
							{employees.length > 0 &&
								employeesFilt.map((employee, index) => (
									<div key={index} style={{ cursor: 'pointer' }} onClick={() => setcurrentEmployeData(employee.id, employee.name)} className='row border-bottom py-3'>
										<div className='col'>
											<div className={`row ${currentEmployeId === employee.id ? 'curretnEmployedSelected' : ''}`}>
												<div className='col-3 pe-0 text-center align-items-center'>
													<img src={employee.profileImg || img} alt='' className='employeeImg rounded-circle img-fluid shadow' />
												</div>
												<div className='col-9'>
													<div className='row'>
														<div className='col-12'>
															<h6>{employee?.name}</h6>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								))}
						</div>
						<div className='col-8 text-center'>
							{!currentEmployeId && (
								<div className='row text-center'>
									<span className='fs-5'> {myCollaboratorsTranslate.notDoneCultureSearch} </span>
								</div>
							)}
							{loadCulture ? (
								<div className=''>
									<Loading size='text' />
								</div>
							) : bars.length > 0 ? (
								<>
									<h5>
										{currentEmployeName} {cultureTranslate.culturePreview}
									</h5>
									<div className='row'>
										{bars.map((item, index) => (
											<div key={index} className='col-6 d-flex flex-column align-items-center'>
												<CulturaBarMatch percentagem={item.percentagem} color={item.color}>
													<img src={item.icon} alt='' />
												</CulturaBarMatch>
												{item.type}
											</div>
										))}
									</div>
								</>
							) : currentEmployeId ? (
								<>
									<div className='h-100 text-center'>
										<span className='fs-5'>{cultureTranslate.cultureNoAnswer}</span>
									</div>
								</>
							) : (
								''
							)}
						</div>
					</div>
					{employees.length === 0 && <div className='col-9 box-error'>{myCollaboratorsTranslate.noCollaboratorsRegistered}</div>}
				</div>
			</div>
		</div>
	)
}
export default ListaColaboradores
