import { useCallback, useEffect, useState } from 'react'
import { EmployeeList } from '../../../src/Components/Assessment/EmployeeList/employeeList'
import List from '../../API/Lists/Lists.js'
import AssessmentApi from '../../API/Assessment'
import Loading from '../../../src/Components/Loading/Loading'
import { useIntl } from 'react-intl'

import countryMapper from '../../Utils/CountriesMapper.js'
import areasMapper from '../../Utils/AreasMapper.js'
import levelsMapper from '../../Utils/LevelsMapper.js'

export function AssessmentResult() {
	const getLanguage = useIntl()
	const language = getLanguage.locale === 'PT-BR' ? 'pt' : 'es'
	const [loading, setLoading] = useState(false)
	const [list, setList] = useState([])
	const [countries, setCountries] = useState([])
	const [areas, setAreas] = useState([])
	const [levels, setLevels] = useState([])
	const [page, setPage] = useState(1)
	const [totalPages, setTotalPages] = useState(0)
	const [filters, setFilters] = useState({
		country: '',
		area: '',
		level: '',
		text: '',
		complete: '',
	})
	const [textFilter, setTextFilter] = useState('')

	const loadEmployee = useCallback(async () => {
		try {
			setLoading(true)
			const companyID = JSON.parse(localStorage.getItem('user')).id
			const { area, level, country, complete, text } = filters
			const [response, resArea, resCountry, resLevel] = await Promise.all([AssessmentApi.getEmployeeList(companyID, page, level, area, country, complete, text), List.areas(), List.countries(), List.levels()])

			setCountries(resCountry.map(countryMapper.toDomain))
			setAreas(resArea.map(areasMapper.toDomain))
			setLevels(resLevel.map(levelsMapper.toDomain))

			setList(response.body)
			setTotalPages(Math.ceil(response.count / 50))
		} catch {
		} finally {
			setLoading(false)
		}
	}, [page, filters])

	useEffect(() => {
		loadEmployee()
	}, [loadEmployee])

	const path = window.location.pathname
	let pageTitle = ''

	if (path.includes('competence')) {
		pageTitle = 'Test de Competencias'
	} else if (path.includes('motivation')) {
		pageTitle = 'Test de Motivaciones'
	}

	if (loading) return <Loading />

	return (
		<>
			<div className='pe-5'>
				<div className='row'>
					<div className='col'>
						<h3 className='tw-mb-4 tw-font-bold'>{pageTitle}</h3>
					</div>
				</div>
				<EmployeeList employeeList={list} area={areas} level={levels} country={countries} filters={filters} setFilters={setFilters} page={page} setPage={setPage} totalPages={totalPages} textFilter={textFilter} setTextFilter={setTextFilter} />
			</div>
		</>
	)
}
