import { useContext } from "react";
import { I18nProvider } from "../i18n";
import { AppRoutes } from "../Routes/Routes";
import { CompanyContext } from "../Store/CompanyContext";

import MainMenu from "../Components/MainMenu/mainMenu";

const Screen = () => {
    const { store } = useContext(CompanyContext);
    
    const user = JSON.parse(localStorage.getItem("user"));

    return (
        <I18nProvider locale={store.language}>
            <MainMenu />
            <AppRoutes user={user} />
        </I18nProvider>
    );
};
export default Screen;
