import { useState } from 'react'

import { performanceIndicators } from './list'
import { Accordion } from '../accordion'

import { references } from '../../../i18n/translatedMessages'
import { useIntl } from 'react-intl'

export function PerformanceIndicators() {
	const getLanguage = useIntl()
	const language = getLanguage.locale === 'PT-BR' ? 'pt' : 'es'

	const [areaSelected, setAreaSelected] = useState(1)
	const [subitem, setSubitem] = useState(performanceIndicators[0].subitems)

	return (
		<div className='tw-overflow-hidden tw-mx-2'>
			<h5 className='tw-font-bold tw-text-lg'>{references.examplesOfPerformanceIndicators}</h5>
			<div className='tw-overflow-x-auto tw-mb-2'>
				<ul className='tw-list-none tw-w-full tw-flex tw-gap-3'>
					{performanceIndicators.map(item => (
						<li
							onClick={() => {
								setAreaSelected(item.id)
								setSubitem(item.subitems)
							}}
							key={item.id}
							className={`tw-cursor-pointer text-nowrap tw-text-sm tw-rounded-full ${areaSelected === item.id ? 'tw-bg-[#7A6BE8] tw-text-white' : 'tw-bg-[#F9FAFC] tw-text-[#94A3B8]'} tw-font-bold tw-px-4 tw-py-2`}
						>
							{item.area[language]}
						</li>
					))}
				</ul>
			</div>

			<Accordion list={subitem} />
		</div>
	)
}
