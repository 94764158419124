import { Link } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";
import ClimaQuestions from "./Components/QuestionsAndAnswers/ClimaQuestions";
import { questions } from "./Components/QuestionsAndAnswers/dataQuestions";
import { useEffect, useState } from "react";
import { FormacionIcon } from "../../Icons/CollaboratorsIcons";
import "./Components/QuestionsAndAnswers/styles.css";
import {
    btnTranslate,
    climeCultureTranslate,
    dashboardClimeTranslate,
} from "../../i18n/translatedMessages";

const Clima = () => {
    const [allQuestions, setAllQuestions] = useState([]);
    useEffect(() => {
        const bringQuestion = questions;
        setAllQuestions([...bringQuestion]);
    }, []);
    return (
        <div>
            <div className="row align-items-center">
                <div className="col-1">
                    <span className="title-icon p-2 m-auto">
                        <FormacionIcon size={20} />
                    </span>
                </div>
                <div className="col-8">
                    <h2>{climeCultureTranslate.climeSurvey}</h2>
                </div>
            </div>

            <div className="line-visual"></div>
            <div className="centralize-card">
                <ClimaQuestions questions={allQuestions} surveyType='0'/>
                <div className="col-12 mt-3">
                    <p>{dashboardClimeTranslate.climeQuestionsAlert}</p>
                </div>
                <div className="col-12 mt-5">
                    <Link
                        className="button-volver color-blue"
                        to={"/employee/climaycultura"}
                    >
                        <MdArrowBack size={20} />
                        {btnTranslate.backButton}
                    </Link>
                </div>
            </div>
        </div>
    );
};
export default Clima;
