export const getAreaByCountry = (country, areaMydna) => {
    let area_id_smtm;
    //Chile
    if (country === "1") {
        switch (areaMydna) {
            case 1:
                area_id_smtm = "2";
                break;
            case 2:
                area_id_smtm = "88";
                break;
            case 3:
                area_id_smtm = "3";
                break;
            case 4:
                area_id_smtm = "92";
                break;
            case 5:
                area_id_smtm = "87";
                break;
            case 6:
                area_id_smtm = "5";
                break;
            case 7:
                area_id_smtm = "90";
                break;
            case 8:
                area_id_smtm = "7";
                break;
            case 9:
                area_id_smtm = "1";
                break;
            case 10:
                area_id_smtm = "91";
                break;
            case 11:
                area_id_smtm = "4";
                break;
            case 12:
                area_id_smtm = "89";
                break;
            case 13:
                area_id_smtm = "93";
                break;
            case 14:
                area_id_smtm = "6";
                break;
            case 15:
                area_id_smtm = "1"; 
                break;

            default:
                area_id_smtm = "1";
        }
    }

    //Argentina
    if (country === "2") {
        switch (areaMydna) {
            case 1:
                area_id_smtm = "21";
                break;
            case 2:
                area_id_smtm = "102";
                break;
            case 3:
                area_id_smtm = "22";
                break;
            case 4:
                area_id_smtm = "106";
                break;
            case 5:
                area_id_smtm = "101";
                break;
            case 6:
                area_id_smtm = "24";
                break;
            case 7:
                area_id_smtm = "104";
                break;
            case 8:
                area_id_smtm = "136";
                break;
            case 9:
                area_id_smtm = "20";
                break;
            case 10:
                area_id_smtm = "105";
                break;
            case 11:
                area_id_smtm = "23";
                break;
            case 12: //Educación
                area_id_smtm = "103";
                break;
            case 13: //salud
                area_id_smtm = "107";
                break;
            case 14: 
                area_id_smtm = "20"; //financieros
                break;
            case 15:
                area_id_smtm = "20"; //Financieros
                break;

            default:
                area_id_smtm = "21";
        }
    }

    //Peru
    if (country === "3") {
        // console.log(areaMydna)
        switch (areaMydna) {
            case 1:
                area_id_smtm = "15";
                break;
            case 2:
                area_id_smtm = "95";
                break;
            case 3:
                area_id_smtm = "16";
                break;
            case 4:
                area_id_smtm = "99";
                break;
            case 5:
                area_id_smtm = "94";
                break;
            case 6:
                area_id_smtm = "18";
                break;
            case 7:
                area_id_smtm = "97";
                break;
            case 8:
                area_id_smtm = "19";
                break;
            case 9:
                area_id_smtm = "14";
                break;
            case 10:
                area_id_smtm = "98";
                break;
            case 11:
                area_id_smtm = "17";
                break;
            
            case 12: //Educación
                area_id_smtm = "96";
                break;
            case 13: //salud
                area_id_smtm = "100";
                break;
            case 14: //Seguros, no existe en SMTM
                area_id_smtm = "14"; //Financieros
                break;
            case 15:
                area_id_smtm = "14"; //Financieros
                break;

            default:
                area_id_smtm = "15";
        }
    }

    //Brasil
    if (country === "4") {
        switch (areaMydna) {
            case 1:
                area_id_smtm = "9";
                break;
            case 2:
                area_id_smtm = "130";
                break;
            case 3:
                area_id_smtm = "10";
                break;
            case 4:
                area_id_smtm = "134";
                break;
            case 5:
                area_id_smtm = "129";
                break;
            case 6:
                area_id_smtm = "12";
                break;
            case 7:
                area_id_smtm = "132";
                break;
            case 8:
                area_id_smtm = "13";
                break;
            case 9:
                area_id_smtm = "8";
                break;
            case 10:
                area_id_smtm = "133";
                break;
            case 11:
                area_id_smtm = "11";
                break;
            
            case 12: //Educación
                area_id_smtm = "65";
                break;
            case 13: //salud
                area_id_smtm = "62";
                break;
            case 14: //Seguros, no existe en SMTM
                area_id_smtm = "8"; //Financiero
                break;
            case 15:
                area_id_smtm = "8"; //Financiero
                break;

            default:
                area_id_smtm = "9";
        }
    }

    //Colombia
    if (country === "5") {
        switch (areaMydna) {
            case 1:
                area_id_smtm = "67";
                break;
            case 2:
                area_id_smtm = "109";
                break;
            case 3:
                area_id_smtm = "68";
                break;
            case 4:
                area_id_smtm = "113";
                break;
            case 5:
                area_id_smtm = "108";
                break;
            case 6:
                area_id_smtm = "70";
                break;
            case 7:
                area_id_smtm = "111";
                break;
            case 8:
                area_id_smtm = "71";
                break;
            case 9:
                area_id_smtm = "66";
                break;
            case 10:
                area_id_smtm = "112";
                break;
            case 11:
                area_id_smtm = "69";
                break;
            case 12: //Educación
                area_id_smtm = "110";
                break;
            case 13: //salud
                area_id_smtm = "114";
                break;
            case 14: //Seguros, no existe en SMTM
                area_id_smtm = "66"; //Financiero
                break;
            case 15:
                area_id_smtm = "66"; //Financiero
                break;

            default:
                area_id_smtm = "67";
        }
    }

    //Mexico
    if (country === "6") {
        switch (areaMydna) {
            case 1:
                area_id_smtm = "74";
                break;
            case 2:
                area_id_smtm = "123";
                break;
            case 3:
                area_id_smtm = "75";
                break;
            case 4:
                area_id_smtm = "127";
                break;
            case 5:
                area_id_smtm = "122";
                break;
            case 6:
                area_id_smtm = "77";
                break;
            case 7:
                area_id_smtm = "125";
                break;
            case 8:
                area_id_smtm = "78";
                break;
            case 9:
                area_id_smtm = "73";
                break;
            case 10:
                area_id_smtm = "126";
                break;
            case 11:
                area_id_smtm = "76";
                break;

            case 12: //Educación
                area_id_smtm = "124";
                break;
            case 13: //salud
                area_id_smtm = "128";
                break;
            case 14: //Seguros, no existe en SMTM
                area_id_smtm = "73"; //Financiero
                break;
            case 15:
                area_id_smtm = "73"; //Financiero
                break;

            default:
                area_id_smtm = "74";
        }
    }

    //Ecuador
    if (country === "7") {
        switch (areaMydna) {
            case 1:
                area_id_smtm = "81";
                break;
            case 2:
                area_id_smtm = "116";
                break;
            case 3:
                area_id_smtm = "82";
                break;
            case 4:
                area_id_smtm = "120";
                break;
            case 5:
                area_id_smtm = "115";
                break;
            case 6:
                area_id_smtm = "84";
                break;
            case 7:
                area_id_smtm = "118";
                break;
            case 8:
                area_id_smtm = "85";
                break;
            case 9:
                area_id_smtm = "80";
                break;
            case 10:
                area_id_smtm = "119";
                break;
            case 11:
                area_id_smtm = "83";
                break;
            case 12: //Educación
                area_id_smtm = "117";
                break;
            case 13: //salud
                area_id_smtm = "121";
                break;
            case 14: //Seguros, no existe en SMTM
                area_id_smtm = "80"; //Financiero
                break;
            case 15:
                area_id_smtm = "80"; //Financiero
                break;

            default:
                area_id_smtm = "81";
        }
    }

    return area_id_smtm;
};

export const getBenefitGroupBySmtm = (grupo) => {
    let befit_group;
    switch (parseInt(grupo)) {
        case 1:
            befit_group = "Operario";
            break;
        case 2:
            befit_group = "Operario";
            break;
        case 3:
            befit_group = "Analista";
            break;
        case 4:
            befit_group = "Analista";
            break;
        case 5:
            befit_group = "Especialista";
            break;
        case 6:
            befit_group = "Jefe";
            break;
        case 7:
            befit_group = "SubGerente";
            break;
        case 8:
            befit_group = "Gerente";
            break;
        case 9:
            befit_group = "Director";
            break;
        case 10:
            befit_group = "CEO";
            break;

        default:
            befit_group = "Analista";
            break;
    }

    return befit_group;
};
export const getCityIdByCountrySmtm = (country) => {
    let cityId;

    switch (country) {
        case "1":
            cityId = "1";
            break;

        case "2":
            cityId = "2";
            break;

        case "3":
            cityId = "3";
            break;

        case "4":
            cityId = "4";
            break;

        case "5":
            cityId = "16";
            break;

        case "6":
            cityId = "22";
            break;

        case "7":
            cityId = "23";
            break;

        default:
            cityId = "1";
            break;
    }

    return cityId;
};

export const matchIndustryForSmtm = (industry_mydna) => {
    let industry_id_smtm;

    switch (industry_mydna) {
        case "1":
            industry_id_smtm = "12";
            break;
        case "2":
            industry_id_smtm = "31";
            break;
        case "3":
            industry_id_smtm = "1";
            break;
        case "4":
            industry_id_smtm = "3";
            break;
        case "5":
            industry_id_smtm = "32";
            break;
        case "6":
            industry_id_smtm = "2";
            break;
        case "7":
            industry_id_smtm = "5";
            break;
        case "8":
            industry_id_smtm = "10";
            break;
        case "9":
            industry_id_smtm = "6";
            break;
        case "10":
            industry_id_smtm = "6";
            break;
        case "11":
            industry_id_smtm = "7";
            break;
        case "12":
            industry_id_smtm = "9";
            break;
        case "13":
            industry_id_smtm = "26";
            break;
        case "14":
            industry_id_smtm = "8";
            break;
        case "15":
            industry_id_smtm = "28";
            break;
        case "16":
            industry_id_smtm = "27";
            break;
        case "17":
            industry_id_smtm = "29";
            break;
        case "18":
            industry_id_smtm = "13";
            break;
        case "19":
            industry_id_smtm = "14";
            break;
        case "20":
            industry_id_smtm = "16";
            break;
        case "21":
            industry_id_smtm = "15";
            break;
        case "22":
            industry_id_smtm = "11";
            break;
        case "23":
            industry_id_smtm = "30";
            break;
        case "24":
            industry_id_smtm = "19";
            break;
        case "25":
            industry_id_smtm = "24";
            break;
        case "26":
            industry_id_smtm = "33";
            break;
        case "27":
            industry_id_smtm = "18";
            break;
        case "28":
            industry_id_smtm = "20";
            break;
        case "29":
            industry_id_smtm = "22";
            break;
        case "30":
            industry_id_smtm = "23";
            break;
        case "31":
            industry_id_smtm = "21";
            break;
        case "32":
            industry_id_smtm = "25";
            break;
        case "33":
            industry_id_smtm = "33";
            break;

        default:
            industry_id_smtm = "1";
            break;
    }

    return industry_id_smtm;
};
