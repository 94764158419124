import { useState } from 'react'
import { IoMdArrowDropdown } from 'react-icons/io'

export function Tooltip({ text, children, background = '#010101', mb = 40, ms = 0, show = true }) {
	const [mouseOn, setMouseOn] = useState(false)

	return (
		<div className='flex justify-content-center align-items-end tw-w-auto' onMouseEnter={() => setMouseOn(true)} onMouseLeave={() => setMouseOn(false)}>
			{children}
			{mouseOn && show && (
				<>
					<div className='position-absolute text-white rounded-3 py-1 px-2 flex flex-col tw-w-auto' style={{ marginBottom: mb, marginLeft: ms, background: background }}>
						{text}
						<IoMdArrowDropdown size={36} className='position-absolute align-self-center' style={{ bottom: -21, color: background }} />
					</div>
				</>
			)}
		</div>
	)
}
