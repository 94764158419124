// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InternalVacanciesApplications .icon {
  background: linear-gradient(45deg, #40c2ff 0%, #32d8d8 100%);
  width: 50px;
  height: 50px;
  border-radius: 50rem;
  padding: 8px;
  margin: 0 10px;
}
.InternalVacanciesApplications .color-purple {
  color: #7A6BE8;
}
.InternalVacanciesApplications .color-blue {
  color: #41C8FF;
}
.InternalVacanciesApplications .btn-card {
  background-color: #7A6BE8;
  color: white;
  font-weight: bold;
}
.InternalVacanciesApplications .btn-card-blue {
  background-color: #41C8FF;
  color: white;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/Views/Service/Views/InternalVacanciesList/InternalVacanciesApplications.sass"],"names":[],"mappings":"AACC;EACC,4DAAA;EACA,WAAA;EACA,YAAA;EACA,oBAAA;EACA,YAAA;EACA,cAAA;AAAF;AACC;EACC,cAAA;AACF;AAAC;EACC,cAAA;AAEF;AAAC;EACC,yBAAA;EACA,YAAA;EACA,iBAAA;AAEF;AADC;EACC,yBAAA;EACA,YAAA;EACA,iBAAA;AAGF","sourcesContent":[".InternalVacanciesApplications\n .icon\n  background: linear-gradient(45deg,#40c2ff 0%, #32d8d8 100%)\n  width: 50px\n  height: 50px\n  border-radius: 50rem\n  padding: 8px\n  margin: 0 10px\n .color-purple\n  color: #7A6BE8\n .color-blue\n  color: #41C8FF\n\n .btn-card\n  background-color: #7A6BE8\n  color: white\n  font-weight: bold\n .btn-card-blue\n  background-color: #41C8FF\n  color: white\n  font-weight: bold"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
