import { useState, useEffect, useContext } from "react";
import { getSalary, postSalary, putSalary } from "../../API/Employee/Salary";
import { CompanyContext } from "../../Store/CompanyContext";
import {
  employeeExperienceTranslate,
  modalAssignTranslate,
  wheelCollaboratorTranslate,
} from "../../i18n/translatedMessages";
import { personalInfoTranslate } from "../../i18n/translatedMessages";
import ModalResponseStatus from "../../Components/ModalResponseStatus/ModalResponseStatus";
import { FormattedMessage, useIntl } from "react-intl";
import { SalarioIcon } from "../../Icons/CollaboratorsIcons";
import {
  getProfile,
  contractTypesList,
  editProfile,
  sendProfile,
} from "../../API/Employee/Profile";

import { formatMoney } from "accounting-js";
import { translate } from "../../i18n/Library/contract_type";

const InfoSalario = () => {
  const getLanguage = useIntl();
  const language = getLanguage.locale === "PT-BR" ? "pt" : "es";
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

  const objEmpty = {
    id: null,
    salary: "",
    fixedSalary: "",
    typeCurrency: "",
    lastAdjustDate: "",
    contract: "",
  };

  const profileEmpty = {
    employerId: user.id,
    mailPersonal: "",
    phone: "",
    document: "",
    birth: "",
    linkedIn: "",
    facebook: "",
    instagram: "",
    skype: "",
    twitter: "",
    gender: "",
    maritalStatus: "",
    childrens: "",
    city: "",
    country: "",
    address: "",
    nationality: "",
    transport: "",
    contract: "",
    boss: "",
    bank: "",
    currentAccount: "",
    healthPlan: "",
    height: 0,
    weight: 0,
    enfermedad: "",
    pcd: "",
    typePCD: "",
    hobies: "",
    sports: "",
    interests: "",
    voluntaryWork: "",
  };

  const { store, actions } = useContext(CompanyContext);
  const [salaryInfo, setSalaryInfo] = useState(objEmpty);
  const [contractList, setContractList] = useState([]);
  const [employeeProfile, setEmployeeProfile] = useState(profileEmpty);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
    const users = JSON.parse(localStorage.getItem("user"));

    const asyncRequests = async () => {
      const [profileAux] = await getProfile(users.id);
      const salary = await getSalary(users.id);

      const salaryMap = salary[salary.length - 1];
      salary?.length > -1
        ? setSalaryInfo({
            ...salaryMap,
            contract: profileAux?.contract,
          })
        : setSalaryInfo([objEmpty]);
      await getSalary(users.id);
      setContractList(await contractTypesList());
      if (profileAux) setEmployeeProfile(profileAux);
    };
    asyncRequests();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setSalaryInfo({
      ...salaryInfo,
      [name]: value,
    });
  };

  const handleSend = async (e, salid) => {
    const salaryAux = parseInt(salaryInfo.salary.toString().replace(/\./g, ""));
    const objSalarySend = {
      salary: salaryAux,
      fixedSalary: salaryInfo.fixedSalary,
      typeCurrency: salaryInfo.typeCurrency,
      employerId: user.id,
      lastAdjustDate: salaryInfo.lastAdjustDate,
    };
    const objProfileSend = {
      ...employeeProfile,
      contract: parseInt(salaryInfo.contract),
    };
    const postorputSalary = salid
      ? await putSalary(objSalarySend, salid)
      : await postSalary(objSalarySend);
    const postorputProfile =
      employeeProfile !== undefined
        ? await editProfile(objProfileSend, employeeProfile.employerId)
        : await sendProfile();
    if (
      (postorputSalary.status < 200 || postorputSalary.status > 299) &&
      (postorputProfile.status < 200 || postorputProfile.status > 299)
    ) {
      if (
        (postorputSalary.status > 399 && postorputSalary.status < 500) ||
        (postorputProfile.status > 399 && postorputProfile.status < 500)
      ) {
        //resp.status [400-499] not found
        actions.setResponseStatus(false);
        actions.setMessageResponseStatus("Ha ocurrido un error!");
        actions.setShowModalResponseStatus(true);
      } else {
        // 300 - 399 [redirect]
        actions.setResponseStatus(false);
        actions.setMessageResponseStatus("Ha ocurrido un error!");
        actions.setShowModalResponseStatus(true);
      }
    } else {
      //>=200 || <300  [200-299] OK
      actions.setResponseStatus(true);
      actions.setMessageResponseStatus("Éxito");
      actions.setShowModalResponseStatus(true);
    }
  };

  const handleClose = () => {
    actions.setShowModalResponseStatus(false);
  };

  const handleFormatMoney = (value) =>
    formatMoney(value, {
      precision: 0,
      thousand: ".",
      symbol: "R$",
      format: {
        pos: "%v",
        neg: "(%v)",
        zero: " --",
      },
    });

  return (
    <div className="container-fluid">
      <FormattedMessage id="nextButton">
        {(msg) => (
          <ModalResponseStatus
            show={store.showModalResponseStatus}
            handleClose={handleClose}
            message={store.messageResponseStatus}
            successMessage={store.responseStatus}
            btnRight={msg}
          />
        )}
      </FormattedMessage>
      <div className="row mb-4">
        <div className="col-1">
          <span className="title-icon p-2 m-auto">
            <SalarioIcon size={20} />
          </span>
        </div>
        <div className="col-8">
          <h1>{wheelCollaboratorTranslate.salary}</h1>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-auto">
          <label htmlFor="employeeSalary" className="color-blue form-label">
            {personalInfoTranslate.personalFixBruteSalary}
          </label>
          <input
            className="form-control text-center m-0"
            name="salary"
            type="text"
            aria-label="salario"
            value={salaryInfo?.salary}
            onBlur={() =>
              setSalaryInfo({
                ...salaryInfo,
                salary: handleFormatMoney(parseInt(salaryInfo.salary)),
              })
            }
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="col-md-auto">
          <label htmlFor="employeeTypeCurrency" className="form-label">
            {personalInfoTranslate.personalCoin}
          </label>
          <select
            className="form-select rounded-pill m-0"
            aria-label="Default select example"
            name="typeCurrency"
            value={salaryInfo?.typeCurrency}
            onChange={(e) => handleChange(e)}
          >
            <FormattedMessage id="selectOption0">
              {(msg) => <option>{msg}</option>}
            </FormattedMessage>
            <option value="AR$">AR$</option>
            <option value="CLP">CLP</option>
            <option value="COP">COP</option>
            <option value="MEX">MEX</option>
            <option value="R$">R$</option>
            <option value="S/.">S/.</option>
            <option value="USD">USD</option>
          </select>
        </div>
        <div className="col-md-auto">
          <label htmlFor="" className="form-label">
            {personalInfoTranslate.personalVariableSalary}
          </label>
          <select
            className="form-select rounded-pill m-0"
            aria-label="Default select example"
            name="fixedSalary"
            value={salaryInfo?.fixedSalary}
            onChange={(e) => handleChange(e)}
          >
            <FormattedMessage id="selectOption0">
              {(msg) => <option>{msg}</option>}
            </FormattedMessage>
            {Array.from({ length: 20 }).map((_, index) => {
              return (
                <option key={index} value={index / 2 + 0.5}>
                  {index / 2 + 0.5}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-md-2">
          <label htmlFor="lastAdjustDate" className="form-label">
            Último ajuste salarial:
          </label>
          <input
            type="date"
            id="lastAdjustDate"
            name="lastAdjustDate"
            className="form-control rounded-pill"
            value={salaryInfo?.lastAdjustDate}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="col-md-auto">
          <label htmlFor="" className="form-label">
            {employeeExperienceTranslate.contractTypeEE}
          </label>
          <select
            className="form-select rounded-pill m-0"
            aria-label="Default select example"
            name="contract"
            value={salaryInfo?.contract}
            onChange={(e) => handleChange(e)}
          >
            <FormattedMessage id="selectOption0">
              {(msg) => <option>{msg}</option>}
            </FormattedMessage>
            {contractList?.map((contract, index) => {
              return (
                <option key={index} value={contract.id}>
                  {translate[language][contract.type]}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className="row">
        <div className=" mt-4 col-12 text-end">
          <button
            className="btn rounded-pill btn-moveStep"
            data-salid={salaryInfo?.id}
            onClick={(e) => handleSend(e, salaryInfo.id)}
          >
            {modalAssignTranslate.btnSave}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InfoSalario;
