export const translate = {
	es: {
		'Marketing': 'Marketing',
		'Comercial y Ventas': 'Comercial y Ventas',
		'Ingeniería': 'Ingeniería',
		'Operación y Manufactura': 'Operación y Manufactura',
		'Almacén, Logística & Transporte': 'Almacén, Logística & Transporte',
		'Recursos Humanos': 'Recursos Humanos',
		'Investigación y Desarrollo': 'Investigación y Desarrollo',
		'Investiación y Desarrollo': 'Investigación y Desarrolo',
		'Tecnología de la información': 'Tecnología de la información',
		'Financiero': 'Financiero',
		'Legal': 'Legal',
		'Alta Administración': 'Alta Administración',
		'Alta administración': 'Alta Administración',
		'Educación': 'Educación',
		'Salud': 'Salud',
		'Seguros': 'Seguros',
		'Administrativo': 'Administrativo',
		'Operario': 'Operario',
		'Técnico': 'Técnico',
		'Asistente': 'Asistente',
		'Analista': 'Analista',
		'Especialistas / Coordinadores': 'Especialista/Coordinadore',
		'Jefe': 'Jefe',
		'Subgerentes': 'Subgerente',
		'Gerentes': 'Gerente',
		'Director': 'Director',
	},
	pt: {
		'Marketing': 'Marketing',
		'Comercial y Ventas': 'Comercial e Vendas',
		'Ingeniería': 'Engenharia',
		'Operación y Manufactura': 'Operação e Fabricação',
		'Almacén, Logística & Transporte': 'Armazém, Logística e Transporte',
		'Recursos Humanos': 'Recursos Humanos',
		'Investigación y Desarrollo': 'Pesquisa e Desenvolvimento',
		'Investiación y Desarrollo': 'Pesquisa e Desenvolvimento',
		'Tecnología de la información': 'Tecnologia da informação',
		'Financiero': 'Financeiro',
		'Legal': 'Jurídico',
		'Alta Administración': 'Alta Administração',
		'Alta administración': 'Alta Administração',
		'Educación': 'Educação',
		'Salud': 'Saúde',
		'Seguros': 'Seguros',
		'Administrativo': 'Administrativo',
		'Operario': 'Operário',
		'Técnico': 'Técnico',
		'Asistente': 'Assistente',
		'Analista': 'Analista',
		'Especialistas / Coordinadores': 'Especialista',
		'Jefe': 'Coordenador',
		'Subgerentes': 'Subgerente',
		'Gerentes': 'Gerente',
		'Director': 'Diretor',
	},
}
