import { createContext, useState } from 'react'
import getState from './Services.jsx'

export const ServicesContext = createContext(null)

const ServicesContextProvider = ({ children }) => {
	const [state, setState] = useState(
		getState({
			getStore: () => state.store,
			getActions: () => state.actions,
			setStore: updatedStore =>
				setState({
					store: Object.assign(state.store, updatedStore),
					actions: { ...state.actions },
				}),
		})
	)
	console.log('state.store', state.store)
	return <ServicesContext.Provider value={state}>{children}</ServicesContext.Provider>
}

export default ServicesContextProvider
