import { useLocation } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import { I18nProvider } from "../../i18n";
import { B2ERoutes } from "../../Routes/Routes";
import { EmployerContext } from "../../Store/EmployerContext";
import SideMenu from "./Components/SideMenuEmployee/SideMenu";

import MainMenuEmployee from "../../Components/MainMenuEmployee/mainMenu";

import "./Employee.sass";

const B2EIndex = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const pathname = useLocation().pathname;
    const { store, actions } = useContext(EmployerContext);

    const handleMenu = (event) => {
        actions.setOptions(event.currentTarget.dataset.option);
    };

    const handleClear = () => {
        actions.setOptions(0);
    };
    return (
        <I18nProvider locale={store.language}>
            <MainMenuEmployee clearOption={handleClear} />
            <div
                className={
                    pathname === "/employee/edit-profile"
                        ? "container-fluid employee-main overflow-hidden"
                        : "container-fluid employee-main"
                }
            >
                <div className="row">
                    <div
                        className={
                            pathname === "/employee"
                                ? "d-none"
                                : pathname === "/employee/edit-profile"
                                ? "d-none"
                                : pathname ===
                                  "/employee/internalvacancies"
                                ? "d-none"
                                : ""
                        }
                    >
                        <SideMenu
                            handleMenu={handleMenu}
                            option={store.options}
                        />
                    </div>
                    <div
                        className={
                            pathname === "/employee"
                                ? "col-12"
                                : pathname === "/employee/edit-profile"
                                ? "col-12"
                                : pathname ===
                                  "/employee/internalvacancies"
                                ? "col-12"
                                : "offset-3 col-md-9 col-sm-9"
                        }
                    >
                        <B2ERoutes user={user} />
                    </div>
                </div>
            </div>
        </I18nProvider>
    );
};
export default B2EIndex;
