import { references } from '../../../i18n/translatedMessages'

export function NineBox() {
	return (
		<div className='tw-grid tw-grid-cols-3 tw-gap-4'>
			<div className='tw-rounded-xl tw-text-black/100 tw-bg-[#FEF3C6] tw-p-4' style={{ border: '1px solid #793510' }}>
				<h5 className='tw-font-bold tw-text-base'>{references.AssessmentPuzzleTitle}</h5>
				<p>{references.AssessmentPuzzleText}</p>
			</div>
			<div className='tw-rounded-xl tw-text-black/100 tw-bg-[#C7FFF1] tw-p-4' style={{ border: '1px solid #0D554A' }}>
				<h5 className='tw-font-bold tw-text-base'>{references.AssessmentStrongPerformanceTitle}</h5>
				<p>{references.AssessmentStrongPerformanceText}</p>
			</div>
			<div className='tw-rounded-xl tw-text-black/100 tw-bg-[#E2E4FD] tw-p-4' style={{ border: '1px solid #40337B' }}>
				<h5 className='tw-font-bold tw-text-base'>{references.AssessmentHighpotentialTitle}</h5>
				<p>{references.AssessmentHighpotentialText}</p>
			</div>
			<div className='tw-rounded-xl tw-text-black/100 tw-bg-[#FEF3C6] tw-p-4' style={{ border: '1px solid #793510' }}>
				<h5 className='tw-font-bold tw-text-base'>{references.AssessmentQuestionableTitle}</h5>
				<p>{references.AssessmentQuestionableText}</p>
			</div>
			<div className='tw-rounded-xl tw-text-black/100 tw-bg-[#DFF3FE] tw-p-4' style={{ border: '1px solid #084E72' }}>
				<h5 className='tw-font-bold tw-text-base'>{references.AssessmentMaintainerTitle}</h5>
				<p>{references.AssessmentMaintainerText1}</p>
			</div>
			<div className='tw-rounded-xl tw-text-black/100 tw-bg-[#C7FFF1] tw-p-4' style={{ border: '1px solid #0D554A' }}>
				<h5 className='tw-font-bold tw-text-base'>{references.AssessmentHighImpactTitle}</h5>
				<p>{references.AssessmentStrongPerformanceText}</p>
			</div>
			<div className='tw-rounded-xl tw-text-black/100 tw-bg-[#FEE4E5] tw-p-4' style={{ border: '1px solid #891336' }}>
				<h5 className='tw-font-bold tw-text-base'>{references.AssessmentInsufficientTitle}</h5>
				<p>{references.AssessmentInsufficientText}</p>
			</div>
			<div className='tw-rounded-xl tw-text-black/100 tw-bg-[#DFF3FE] tw-p-4' style={{ border: '1px solid #084E72' }}>
				<h5 className='tw-font-bold tw-text-base'>{references.AssessmentEffectiveTitle}</h5>
				<p>{references.AssessmentEffectiveText}</p>
			</div>
			<div className='tw-rounded-xl tw-text-black/100 tw-bg-[#DFF3FE] tw-p-4' style={{ border: '1px solid #084E72' }}>
				<h5 className='tw-font-bold tw-text-base'>{references.AssessmentSpecialistTitle}</h5>
				<p>{references.AssessmentMaintainerText2}</p>
			</div>
		</div>
	)
}
