import { BsArrowLeftCircle, BsArrowLeft, BsCheckCircle } from "react-icons/bs";
import { FormattedMessage } from "react-intl";
import { internalJobsTranslate } from "../../../../../../i18n/translatedMessages";

import "./step.sass";

const InternalVacanciesStep2 = ({
    jumpToStep,
    handleChange,
    data,
    handleSend,
    imgCompany,
}) => {
    const company = JSON.parse(localStorage.getItem("user"));

    return (
        <div className="InternalVacancies-step2">
            <div className="card">
                <div className="logo">
                    <img
                        src={
                            imgCompany ||
                            "https://assets.mydna.company/mydnalogo.png"
                        }
                        alt={company.name}
                    />
                </div>
                <div className="card-header">
                    <div className="row">
                        <div className="col-4 d-flex align-items-center">
                            <BsArrowLeftCircle
                                size={20}
                                onClick={() => jumpToStep(0)}
                            />
                            <span>
                                {internalJobsTranslate.internalJobStep2}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col">
                            <div className="card-white">
                                <h6>
                                    {
                                        internalJobsTranslate.internalJobDescription
                                    }
                                    *
                                </h6>
                                <textarea
                                    className="description-text"
                                    value={data.descriptionText}
                                    name="description"
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="card-white mt-4">
                                <h6>
                                    {
                                        internalJobsTranslate.internalJobRequeriments
                                    }
                                </h6>
                                <FormattedMessage id="internalJobRequerimentsPlaceHolder">
                                    {(msg) => (
                                        <textarea
                                            className="description-text"
                                            type="text"
                                            placeholder={msg}
                                            name="requirements"
                                            onChange={handleChange}
                                            value={data.otherRequirements}
                                        />
                                    )}
                                </FormattedMessage>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col d-flex justify-content-center ">
                            <button
                                onClick={() => jumpToStep(0)}
                                className="btn-back"
                            >
                                <BsArrowLeft size={25} />
                            </button>
                            <button className="btn-next" onClick={handleSend}>
                                <BsCheckCircle size={25} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default InternalVacanciesStep2;
