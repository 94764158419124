export const postCulture = async (body, employeeId) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/employercp/userQuestions/${employeeId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    return resp.json();
  } catch (err) {
    console.error(err);
  }
};

export const getCulture = async (employeeId) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/employercp/${employeeId}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return resp.json();
  } catch (err) {
    console.error(err);
  }
};

export const getCultureValue = async (id) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/employercp/userQuestions/${id}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (resp.status === 500) throw resp.text();
    return resp.json();
  } catch (err) {
    console.error(err);
    return false;
  }
};
