export function RadioButton({ id, color = '#010100', selectedId, setSelectedId }) {
	return (
		<div
			className='rounded-circle'
			role='button'
			onClick={() => setSelectedId(id)}
			style={{
				width: 25,
				height: 25,
				border: `2px solid ${color}`,
				background: selectedId === id ? color : 'none',
			}}
		></div>
	)
}
