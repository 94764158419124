export const translate = {
	es: {
		Operario: 'Operario',
		Técnico: 'Técnico',
		'Supervisor Técnico': 'Supervisor Técnico',
		Asistente: 'Asistente',
		Analista: 'Analista',
		'Especialistas / Coordinadores': 'Especialistas / Coordinadores',
		'Especialista/Coordinador': 'Especialista/Coordinador',
		'Subgerente': 'Subgerente',
		Jefe: 'Jefe',
		Subgerentes: 'Subgerentes',
		Subgerente: 'Subgerente',
		SubGerente: 'SubGerente',
		Gerentes: 'Gerentes',
		Gerente: 'Gerentes',
		Director: 'Director',
		'Alta Administración': 'Alta Administración',
	},

	pt: {
		Operario: 'Operário',
		Técnico: 'Técnico',
		'Supervisor Técnico': 'Supervisor Técnico',
		Asistente: 'Assistente',
		Analista: 'Analista',
		'Especialistas / Coordinadores': 'Especialista',
		'Especialista/Coordinador': 'Especialista/Coordenador',
		'Especialista/Coordinador': 'Especialista/Coordenador',
		Jefe: 'Coordenador',
		Subgerentes: 'Subgerente',
		Subgerente: 'Subgerente',
		SubGerente: 'Subgerente',
		'Subgerente': 'Subgerente',
		Gerentes: 'Gerente',
		Gerente: 'Gerente',
		Director: 'Diretor',
		'Alta Administración': 'Alta Administração',
	},
}
