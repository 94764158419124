import { HiOutlineEye, HiArrowDownTray, HiMagnifyingGlass } from 'react-icons/hi2'
import { CompetenceResult } from '../CompetenceResult/competenceResult'
import { MotivationResult } from '../MotivationResult/motivationResult'
import { useState } from 'react'
import { Select } from '../../Employee/Select/Select'

import { translate as translateAreas } from '../../../i18n/Library/areasCorrelation.js'
import { translate as translatePosition } from '../../../i18n/Library/hierarchicalLevel'
import { useIntl } from 'react-intl'
import countryMapper from '../../../Utils/CountriesMapper.js'
import areasMapper from '../../../Utils/AreasMapper.js'
import levelsMapper from '../../../Utils/LevelsMapper.js'
import { FaArrowLeft, FaArrowRight, FaRegTrashAlt, FaSearch } from 'react-icons/fa'

export function EmployeeList({ employeeList, area, level, country, filters, setFilters, page, setPage, totalPages, textFilter, setTextFilter }) {
	const getLanguage = useIntl()
	const language = getLanguage.locale === 'PT-BR' ? 'pt' : 'es'
	const [showResult, setShowResult] = useState(false)
	const [employee, setEmployee] = useState({
		name: '',
		id: '',
	})
	// const [textFilter, setTextFilter] = useState('')

	const answeredPill = answered => {
		return (
			<span className={`tw-rounded-full ${answered === true ? 'tw-bg-[#00A28B] tw-text-white' : 'tw-bg-[#E1E8F0]'} tw-w-[160px] tw-h-[38px]  tw-flex tw-items-center tw-justify-center tw-col-span-2`}> {answered === true ? '' : 'No '}Respondido</span>
		)
	}

	const handleClickResult = (name, id) => {
		setEmployee({ name: name, id: id })
		setShowResult(true)
	}

	const handleCloseResult = () => {
		setShowResult(false)
	}

	const handleFilterText = e => {
		const text = e.target.value

		setTextFilter(text)
	}

	const handleKeyDown = e => {
		if (e.key === 'Enter' && textFilter.length > 0) {
			setFilters(prevFilters => ({
				...prevFilters,
				text: textFilter,
			}))
		}
	}

	const handleSearchText = () => {
		setFilters(prevFilters => ({
			...prevFilters,
			text: textFilter,
		}))
	}

	const handleFilter = e => {
		const selectedOption = e.target.value

		setFilters(prev => ({
			...prev,
			[e.target.name]: selectedOption || null,
		}))
		setPage(1)
	}

	const cleanFilters = () => {
		setFilters({
			area: '',
			level: '',
			country: '',
			text: '',
		})
		setTextFilter('')
		setPage(1)
	}

	const translatedAreas = area.map(item => ({
		...item,
		value: translateAreas[language][item?.value],
	}))

	const translatedLevels = level.map(item => ({
		...item,
		value: translatePosition[language][item?.value],
	}))

	return (
		<>
			{showResult && <CompetenceResult onCloseResult={handleCloseResult} employee={employee} />}
			{!showResult && (
				<>
					<div className='tw-text-lg tw-mb-4'>
						El test de competencias es una herramienta avanzada diseñada para evaluar habilidades y potenciales en un entorno profesional. Ofrece información valiosa sobre competencias clave, ayudando a las empresas a identificar y desarrollar
						talentos de manera efectiva.
					</div>

					<div className='tw-w-full tw-mb-5 tw-flex tw-space-x-3 tw-items-center'>
						<div className='tw-w-7/12 border border-grey tw-rounded-lg py-1 px-3 tw-flex tw-items-center tw-justify-center tw-mt-1'>
							<HiMagnifyingGlass size={22} className='text-dark-grey' />
							<input type='text' className='border border-0 p-0 m-0 ms-4 tw-w-full' placeholder='Buscar Colaborador' onKeyDown={handleKeyDown} onChange={handleFilterText} value={textFilter} />
						</div>

						<div className='tw-w-1/5'>
							<Select placeholder='País' list={country} onChange={handleFilter} name='country' value={filters.country} />
						</div>
						<div className='tw-w-1/5'>
							<Select placeholder='Área' list={translatedAreas} onChange={handleFilter} name='area' value={filters.area} />
						</div>

						<div className='tw-w-1/5'>
							<Select placeholder={'Nivel Hierárquico'} list={translatedLevels} onChange={handleFilter} name='level' value={filters.level} />
						</div>
						<button
							className={`tw-w-[9%] tw-text-sm tw-flex tw-px-1 tw-items-center tw-justify-center tw-mt-1 ${Object.values(filters).some(filter => filter) ? 'tw-bg-purple-500 tw-text-white' : 'tw-text-gray-400'}`}
							style={{ height: 45, border: '1px solid #CBD5E1', borderRadius: 8 }}
							onClick={cleanFilters}
						>
							<FaRegTrashAlt className='me-1' size={12} />
							Limpar
						</button>
						<button
							className={`tw-w-[9%] tw-text-sm tw-flex tw-px-2 tw-items-center tw-justify-center tw-mt-1 ${textFilter !== '' ? 'tw-bg-purple-500 tw-text-white' : 'tw-text-gray-400'}`}
							style={{ height: 45, border: '1px solid #CBD5E1', borderRadius: 8 }}
							onClick={handleSearchText}
						>
							<FaSearch className='me-1' size={12} />
							Pesquisar
						</button>
					</div>

					<div className='border tw-rounded-lg tw-overflow-hidden tw-divide-y-1'>
						<div className='tw-bg-[#F1F4F9] tw-p-3 tw-grid tw-grid-cols-6'>
							<span className='tw-font-bold tw-col-span-3'>Colaborador</span>
							<span className='tw-font-bold tw-col-span-2'>Status</span>
							<span className='tw-font-bold'>Resultado</span>
						</div>

						{employeeList?.map(item => (
							<div key={item.id}>
								<div className='tw-p-3 tw-grid tw-grid-cols-6 tw-items-center border-bottom'>
									<div className='tw-flex tw-gap-4 tw-items-center tw-col-span-3'>
										<img src={item.profileImg ?? 'https://assets.mydna.company/profile-placeholder.png'} alt='' className='img-fluid rounded-circle tw-w-[64px] tw-h-[64px]'></img>
										{/* <div className='tw-rounded-full tw-bg-purple-200 tw-w-16 tw-h-16' /> */}
										<div>
											<div className='tw-font-bold'>{item.name}</div>
											{/* <div>{item.level}</div> */}
										</div>
									</div>
									{answeredPill(item.status)}
									<div className='tw-flex tw-gap-4'>
										<button
											className={`tw-rounded-full tw-p-1 tw-w-[42px] tw-h-[42px] tw-bg-white ${
												item.status != true ? 'tw-text-gray-300 border-gray' : 'tw-text-purple-500  hover:tw-bg-purple-500 border-primary hover:tw-text-white'
											} tw-flex tw-items-center tw-justify-center border `}
											onClick={() => handleClickResult(item.name, item.id)}
											disabled={item.status != true}
										>
											<HiOutlineEye size={22} />
										</button>
										{/* <button
										className={`tw-rounded-full tw-p-1 tw-w-[42px] tw-h-[42px] tw-bg-white ${
											item.status != true ? 'tw-text-gray-300 border-gray' : 'tw-text-purple-500  hover:tw-bg-purple-500 border-primary hover:tw-text-white'
										} tw-flex tw-items-center tw-justify-center border `}
										disabled={item.status != true}
									>
										<HiArrowDownTray size={22} />
									</button> */}
									</div>
								</div>
							</div>
						))}

						<div className='tw-mb-4 tw-flex tw-justify-end tw-pr-20 tw-items-center tw-space-x-2 tw-mt-5'>
							<div
								onClick={() => setPage(prev => Math.max(prev - 1, 1))}
								disabled={page === 1}
								className={`tw-cursor-pointer tw-px-2 tw-py-1  tw-text-gray-700 tw-rounded disabled:tw-opacity-20 ${page === 1 ? 'tw-cursor-default tw-opacity-50' : ''} border-0`}
							>
								<FaArrowLeft size={12} />
							</div>

							{Array.from({ length: totalPages }, (_, index) => (
								<div
									key={index + 1}
									onClick={() => setPage(index + 1)}
									className={`tw-cursor-pointer tw-px-2 tw-py-1 tw-rounded ${page === index + 1 ? 'tw-bg-purple-600 tw-text-white' : ' tw-text-gray-700 hover:tw-bg-purple-500 hover:tw-text-white border-0'}`}
								>
									{index + 1}
								</div>
							))}

							<div
								onClick={() => setPage(prev => Math.min(prev + 1, totalPages))}
								disabled={page === totalPages}
								className={`tw-cursor-pointer tw-px-2 tw-py-1  tw-text-gray-700 tw-rounded disabled:tw-opacity-20 ${page === totalPages ? 'tw-cursor-default tw-opacity-50' : ''} border-0`}
							>
								<FaArrowRight size={12} />
							</div>
						</div>
					</div>
				</>
			)}
		</>
	)
}
