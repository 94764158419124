import { useState, useRef, useEffect } from "react";
import "./styles.css";
import { btnTranslate } from "../../../i18n/translatedMessages";

const DragNDrop = ({ data }) => {
  const [list, setList] = useState(data);
  const [dragging, setDragging] = useState(false);
  const dragItem = useRef();
  const dragItemNode = useRef();

  useEffect(() => {
    setList(data);
  }, [setList, data]);

  const handletDragStart = (e, item) => {
    dragItemNode.current = e.target;
    dragItemNode.current.addEventListener("dragend", handleDragEnd);
    dragItem.current = item;

    setTimeout(() => {
      setDragging(true);
    }, 0);
  };

  const handleDragEnter = (e, targetItem) => {
    if (dragItemNode.current !== e.target) {
      setList((oldList) => {
        const newList = oldList;

        newList[targetItem.grpI].items.splice(
          targetItem.itemI,
          0,
          newList[dragItem.current.grpI].items.splice(dragItem.current.itemI, 1)[0]
        );

        dragItem.current = targetItem;

        return newList;
      });
    }
  };

  const handleDragEnd = (e) => {
    setDragging(false);
    dragItem.current = null;
    dragItemNode.current.removeEventListener("dragend", handleDragEnd);
    dragItemNode.current = null;
  };
  const getStyles = (item) => {
    if (dragItem.current.grpI === item.grpI && dragItem.current.itemI === item.itemI) {
      return "dnd-item current grabbed";
    } else {
      return "dnd-item grab";
    }
  };

  const showDataLog = () => {};

  if (list) {
    return (
      <div className="drag-n-drop row m-0 justify-content-center">
        {list.map((grp, grpI) => (
          <div key={grpI} className="col">
            <div
              onDragEnter={
                dragging && !grp.items.length ? (e) => handleDragEnter(e, { grpI, itemI: 0 }) : null
              }
              className="dnd-group shadow text-center"
            >
              <div className="group-title header-bg-blue">{grp.title}</div>
              {grp.items.map((item, itemI) => (
                <div
                  draggable
                  key={itemI}
                  onDragStart={(e) => handletDragStart(e, { grpI, itemI })}
                  onDragEnter={
                    dragging
                      ? (e) => {
                          handleDragEnter(e, { grpI, itemI });
                        }
                      : null
                  }
                  className={dragging ? getStyles({ grpI, itemI }) : "dnd-item grab"}
                >
                  <img
                    alt="..."
                    src={item.profileImg}
                    className="rounded-circle img-fluid shadow"
                  />

                  <span className="ps-3">{item.candidato}</span>
                </div>
              ))}
            </div>
          </div>
        ))}
        <div className="col-12 pt-4 pb-3 text-end">
          <button className="btn btn-salvar" onClick={showDataLog}>
            {btnTranslate.saveButton}
          </button>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default DragNDrop;
