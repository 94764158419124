export const getHierarchicalLevelList = async () => {
    try {
        const resp = await fetch(`${process.env.REACT_APP_API}/hierarchicallevel`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return await resp.json();
    } catch (err) {
        console.error(err);
    }
};
