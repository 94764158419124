import React, { useCallback, useEffect, useState } from "react";
import {
    FileIcon,
    CheckIcon,
    LinkIcon,
} from "../../../../Icons/DocumentsIcons";
import DocfilesService from "../../../../API/Documents/Docfiles.api";
import { BsFillFileEarmarkTextFill } from "react-icons/bs";
import { Toast, ToastContainer } from "react-bootstrap";

import "./employeeDocuments.sass";
import Loading from "../../../../Components/Loading/Loading";
import { useIntl } from "react-intl";
import { documentsTranslate } from "../../../../i18n/translatedMessages";
import { translate } from "../../../../i18n/Library/documents.translate";

const EmployeeDocuments = () => {
    const getLanguage = useIntl();
    const language = getLanguage.locale === "PT-BR" ? "pt" : "es";
    const [employees, setEmployees] = useState([]);
    const [activeAccordion, setActiveAccordion] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [toast, setToast] = useState(false);

    const handleAccordionClick = (index) => {
        setActiveAccordion(index === activeAccordion ? null : index);
    };

    const loadEmployees = useCallback(async () => {
        try {
            setLoading(true);
            const response = await DocfilesService.getCompanyDocfiles(
                JSON.parse(localStorage.getItem("user")).id
            );
			
			if(response.length > 0){
				setEmployees(response);
			}
        } catch {
            // Add modal error - not found employees
            setToast(true);
        } finally {
            // Add loading false
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        loadEmployees();
    }, [loadEmployees]);

    return (
        <div className="content container-fluid">
            <ToastContainer
                className="p-3"
                position={"bottom-start"}
                style={{ zIndex: 1 }}
            >
                <Toast
                    bg="danger"
                    onClose={() => setToast(false)}
                    show={toast}
                    delay={5000}
                    autohide
                >
                    <Toast.Header closeButton={false}>
                        <img
                            src="holder.js/20x20?text=%20"
                            className="rounded me-2"
                            alt=""
                        />
                        <strong className="me-auto">Error</strong>
                        <small>
                            {language === "pt"
                                ? "O arquivo não foi salvo"
                                : "El archivo no fue guardado"}
                        </small>
                    </Toast.Header>
                    <Toast.Body>
                        {language === "pt"
                            ? "Aguarde um momento e tente novamente ou entre em contato conosco"
                            : "Espera un momento e inténtalo de nuevo o contáctanos"}
                    </Toast.Body>
                </Toast>
            </ToastContainer>
            <div className="row">
                <div className="col offset-lg-3 offset-xl-1">
                    <div className="row header">
                        <div className="col d-flex mb-4">
                            <div className="documentation-icon">
                                <BsFillFileEarmarkTextFill size={30} />
                            </div>
                            <h1>{documentsTranslate.receivedDocTitle}</h1>
                        </div>
                    </div>
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <table className="table align-middle mb-0 bg-white table-fixed me-5 table-hover">
                            <thead
                                className="text-white"
                                style={{ backgroundColor: "#444258" }}
                            >
                                <tr>
                                    <th className="p-3">Colaborador</th>
                                    <th className="p-3">Cargo</th>
                                    <th className="p-3 text-right">Status</th>
                                </tr>
                            </thead>

                            <tbody>
                                {employees.map((employee, index) => (
                                    <React.Fragment key={employee.employee_id}>
                                        <tr
                                            className={`accordion-toggle cursor-pointer ${
                                                activeAccordion === index
                                                    ? "active"
                                                    : ""
                                            }`}
                                            onClick={() =>
                                                handleAccordionClick(index)
                                            }
                                        >
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div className="ms-3">
                                                        <p className="fw-bold mb-1">
                                                            {employee.name}
                                                        </p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{employee.job}</td>
                                            <td>
                                                <p className="fw-normal my-1">
                                                    {employee.documents.some(
                                                        (document) =>
                                                            document.document_url ===
                                                            null
                                                    ) ? (
                                                        <span className="pending py-2 px-4 rounded-pill">
                                                            {
                                                                documentsTranslate.docPending
                                                            }
                                                        </span>
                                                    ) : (
                                                        <span className="sent py-2 px-4 rounded-pill">
                                                            {
                                                                documentsTranslate.docSended
                                                            }
                                                        </span>
                                                    )}
                                                </p>
                                            </td>
                                        </tr>
                                        {employee.documents.map((document) => {
                                            return (
                                                <tr
                                                    key={`key-${employee.employee_id}-${document.document_id}`}
                                                    className={`hide-table-padding collapse ${
                                                        activeAccordion ===
                                                        index
                                                            ? "show"
                                                            : ""
                                                    }`}
                                                >
                                                    <td
                                                        colSpan="4"
                                                        className="border-cell"
                                                        style={{
                                                            backgroundColor:
                                                                "#E6E8F0",
                                                        }}
                                                    >
                                                        <div className="p-3 row">
                                                            <div className="col d-flex align-items-center">
                                                                <FileIcon />
                                                                <span className="ms-2">
                                                                    {translate[
                                                                        language
                                                                    ][
                                                                        document.document_name.trim()
                                                                    ] ||
                                                                        document.document_name}
                                                                </span>
                                                            </div>
                                                            <div className="col d-flex align-items-center justify-content-end">
                                                                {document.document_url !==
                                                                    null && (
                                                                    <>
                                                                        <a
                                                                            href={
                                                                                document.document_url
                                                                            }
                                                                            className="d-flex"
                                                                            rule="button"
                                                                        >
                                                                            <div className="py-2 px-4 rounded-pill bg-light fs-6 me-3">
                                                                                <LinkIcon />
                                                                                <span className="ms-2">
                                                                                    {
                                                                                        documentsTranslate.docDownload
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                        </a>
                                                                        <CheckIcon />
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div>
    );
};
export default EmployeeDocuments;
