export const patchEnglishLevel = async (employerId, data) => {
  try {
     await fetch(`${process.env.REACT_APP_API}/employer/persistEnglishLevel/${employerId}`, {
      method: 'PATCH',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  } catch (err) {
    console.error(err);
  }
};
export const getNulingaEnglishLevel = async (employeeId) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/employeeenglishlevel/${employeeId}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const getResult = { status: resp.status, data: await resp.json()};
    return getResult;
  } catch (err) {
    console.error(err);
  }
};