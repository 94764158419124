import { useState } from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import Checkbox from "../Components/Checkbox/Checkbox";

import "./modal.sass";
const questions = [
  "Satisfacción con la Jefaturaión con la Jefatura",
  "Satisfacción con el desarrollo de carrera",
  "Satisfacción con el cargo",
  "Satisfacción con la empresa",
  "Satisfacción con el equipo",
  "Satisfacción Carga de Trabajo",
];

const PeopleAnalytics = ({ show, handleClose, option }) => {
  const [finish, setFinish] = useState(false);
  const handleSave = () => {
    setFinish(true);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-services"
    >
      {!finish && (
        <>
          <Modal.Header>
            <Modal.Title>People Analytics</Modal.Title>
            <div className="btn-close" onClick={handleClose}></div>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col className="text-center pb-5">{option.text}</Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <h6 className="text-center">
                  Elige los indicadores que quieres conocer:
                </h6>
              </Col>
            </Row>
            {questions.map((question, index) => {
              return (
                <Row key={index}>
                  <Col className="d-flex">
                    <Checkbox text={question} />
                  </Col>
                </Row>
              );
            })}
          </Modal.Body>

          <Modal.Footer className="d-flex justify-content-center">
            <Button
              variant="white"
              className="rounded-pill"
              onClick={handleSave}
            >
              Agendar una Reunión
            </Button>
          </Modal.Footer>
        </>
      )}
      {finish && (
        <>
          <Modal.Body>
            <Row>
              <Col className="d-flex flex-column align-items-center mb-4">
                <h3 className="text-center color-blue">
                  Solicitud enviada con éxito ! Tu consultor te contactará en la
                  brevedad
                </h3>
                <p>Nos vemos pronto!</p>
              </Col>
            </Row>
            <Row>
              <Col md={2} className="offset-3 d-flex justify-content-end">
                <img
                  className="img-fluid rounded-circle border border-2 border-primary"
                  src={
                    process.env.REACT_APP_API_STORAGE + "Éderson Floriano.jfif"
                  }
                  width="100"
                  alt="Éderson Floriano"
                />
              </Col>
              <Col md={6} className="d-flex flex-column justify-content-center">
                <h5>Éderson Floriano</h5>
                <h6>Product Owner de MyDNA</h6>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button
              variant="white"
              className="rounded-pill"
              onClick={handleClose}
            >
              Contratar otro servicio
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};
export default PeopleAnalytics;
