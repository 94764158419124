// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalProfileContent .modal-content {
  border-radius: 8px;
  background-color: #F2F2F7;
}
.ModalProfileContent .btn-primary {
  background: #7A6BE8;
  border-radius: 30px;
  font-weight: 500;
  border: 1px solid #7A6BE8;
}
.ModalProfileContent .btn-primary:hover {
  background: #7A6BE8;
}
.ModalProfileContent .modal-title {
  font-weight: 600;
  font-size: 24px;
}
.ModalProfileContent .panel > div {
  border-radius: 4px;
  height: 100%;
}
.ModalProfileContent label {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  text-transform: capitalize;
  color: #9391A1;
}
.ModalProfileContent .info-label {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #444258;
}`, "",{"version":3,"sources":["webpack://./src/Views/Service/Views/PeopleAnalytics/Components/ModalProfileInfo/ModalViewProfileInfo.sass"],"names":[],"mappings":"AACI;EACI,kBAAA;EACA,yBAAA;AAAR;AAEI;EACI,mBAAA;EACA,mBAAA;EACA,gBAAA;EACA,yBAAA;AAAR;AAEI;EACI,mBAAA;AAAR;AAEI;EACI,gBAAA;EACA,eAAA;AAAR;AAEI;EACI,kBAAA;EACA,YAAA;AAAR;AAEI;EACI,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,0BAAA;EACA,cAAA;AAAR;AAEI;EACI,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAAR","sourcesContent":[".ModalProfileContent\n    & .modal-content\n        border-radius: 8px\n        background-color: #F2F2F7\n\n    & .btn-primary\n        background: #7A6BE8\n        border-radius: 30px\n        font-weight: 500\n        border: 1px solid #7A6BE8\n\n    & .btn-primary:hover\n        background: #7A6BE8\n\n    & .modal-title\n        font-weight: 600\n        font-size: 24px\n    \n    & .panel > div\n        border-radius: 4px\n        height: 100%\n\n    & label\n        font-style: normal\n        font-weight: 500\n        font-size: 11px\n        line-height: 14px\n        text-transform: capitalize\n        color: #9391A1\n\n    & .info-label\n        font-style: normal\n        font-weight: 600\n        font-size: 13px\n        line-height: 16px\n        color: #444258"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
