export const postAreaMedia = async (data) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_API}/companyareamedia`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};

export const putAreaMedia = async (data, mediaId) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_API}/companyareamedia/${mediaId}`,
      {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};

export const getAreaMediasByCompany = async (companyId) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/companyareamedia/${companyId}`);
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};

export const getAreaMedia = async (companyId, areaId) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_API}/companyareamedia/company/?areaId=${areaId}&companyId=${companyId}`
    );
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};
