// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExternalPlatforms-main {
  height: calc(100vh - 110px) !important;
  overflow-y: scroll;
  overflow-x: hidden;
}
.ExternalPlatforms-main .header-icon {
  background: linear-gradient(45deg, #40c2ff 0%, #32d8d8 100%);
  width: 50px;
  height: 50px;
  border-radius: 50rem;
  padding: 10px;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ExternalPlatforms-main .externalLinks-cards {
  min-height: 95px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 10px;
  color: rgb(60, 70, 80);
  background: white;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.095);
}
.ExternalPlatforms-main .externalLinks-cards .col-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.ExternalPlatforms-main .externalLinks-cards .col-text a {
  color: #212529;
}
.ExternalPlatforms-main .externalLinks-cards .col-img {
  margin-left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  background: #7a6be3;
  border-radius: 8px;
}
.ExternalPlatforms-main .externalLinks-cards .col-img img {
  width: 100%;
}
.ExternalPlatforms-main .externalLinks-cards h6 {
  font-weight: bolder;
}
.ExternalPlatforms-main .externalLinks-cards p {
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/Views/Service/Views/ExternalPlatforms/ExternalPlatforms.sass"],"names":[],"mappings":"AAAA;EACI,sCAAA;EACA,kBAAA;EACA,kBAAA;AACJ;AAAI;EACI,4DAAA;EACA,WAAA;EACA,YAAA;EACA,oBAAA;EACA,aAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAER;AAAI;EACI,gBAAA;EACA,kBAAA;EACA,qCAAA;EACA,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,0CAAA;AAER;AAAQ;EACI,aAAA;EACA,sBAAA;EACA,2BAAA;AAEZ;AADY;EACI,cAAA;AAGhB;AADQ;EACI,iBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,mBAAA;EACA,kBAAA;AAGZ;AADY;EACI,WAAA;AAGhB;AADQ;EACI,mBAAA;AAGZ;AAFQ;EACI,eAAA;AAIZ","sourcesContent":[".ExternalPlatforms-main\n    height: calc(100vh - 110px) !important\n    overflow-y: scroll\n    overflow-x: hidden\n    & .header-icon\n        background: linear-gradient(45deg, #40c2ff 0%, #32d8d8 100%)\n        width: 50px\n        height: 50px\n        border-radius: 50rem\n        padding: 10px\n        margin: 0 10px\n        display: flex\n        align-items: center\n        justify-content: center\n\n    & .externalLinks-cards\n        min-height: 95px\n        border-radius: 8px\n        border: 1px solid rgba(0,0,0,.15)\n        padding: 10px\n        color: rgb(60,70,80)\n        background: white\n        box-shadow: 0 5px 8px rgba(0,0,0, .095)\n\n        .col-text\n            display: flex\n            flex-direction: column\n            justify-content: flex-start\n            & a\n                color: #212529\n\n        .col-img\n            margin-left: 12px\n            display: flex\n            justify-content: center\n            align-items: center\n            width: 75px\n            background: #7a6be3\n            border-radius: 8px\n\n            img\n                width: 100%\n\n        h6\n            font-weight: bolder\n        p\n            font-size: 12px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
