import { IoIosPeople } from 'react-icons/io'
import { paybalanceTranslate } from '../../../../../i18n/translatedMessages'
import Loading from '../../../../../Components/Loading/Loading'

const GoPaybalance = () => {

    const handleLink = ()=>{
        window.open('https://www.paybalance.co/miempresa', '_blank')
    }

    return (
        <>
            <div className="row loading-content">
                <div className="col offset-1">
                    <div className="content-business-culture">
                        <div className="row mb-5">
                            <div className="col d-flex">
                            <div className="icon">
                                <IoIosPeople size={70} />
                            </div>
                                <h1>{paybalanceTranslate.paybalanceTitle}</h1>
                            </div>
                        </div>
                        
                        <div className="row my-5">
                            <div className="col-8 offset-2">
                                <p className="my-3">
                                    {paybalanceTranslate.paybalanceDescription1}
                                </p>
                                <p className="my-3">
                                    {paybalanceTranslate.paybalanceDescription2}
                                </p>
                                <p className="my-3">
                                    {paybalanceTranslate.paybalanceDescription3}
                                </p>
                                <div className="col offset-1 d-flex py-3 justify-content-center">
                                    <button onClick={handleLink}>{paybalanceTranslate.paybalanceBtn}</button>
                                </div>
                                <p className="my-3">
                                    {paybalanceTranslate.paybalanceDescription4}
                                </p>
                                <p className="my-3">
                                    {paybalanceTranslate.paybalanceDescription5}
                                </p>
                                <p className="my-3">
                                    <a href="mailto:contacto@mydnadigital.com">contacto@mydnadigital.com</a><br/>
                                    <a href="mailto:contacto@paybalance.com">contacto@paybalance.com</a>
                                </p>
                                <p className="my-3">
                                Atte.<br/>{paybalanceTranslate.paybalanceTxt} MyDNA
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default GoPaybalance
