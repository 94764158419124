export function ProgressBar({ color, title, percentagem }) {
	return (
		<div className='tw-flex tw-flex-col'>
			<span className='tw-font-bold mb-1'>{title}</span>
			<div className={`border tw-flex tw-overflow-hidden tw-rounded-full tw-h-[32px] tw-w-[500px]`} style={{ background: `${color}33` }}>
				<div className='tw-h-full tw-rounded-full tw-flex tw-items-center tw-justify-center tw-text-white tw-font-bold' style={{ width: `${percentagem}%`, background: color }}>
					{percentagem}%
				</div>
			</div>
		</div>
	)
}
