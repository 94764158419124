// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stars-notes {
  position: relative;
  displey: flex;
}
.stars-notes ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
.stars-notes li {
  padding: 0;
  border: none;
  cursor: pointer;
}
.stars-notes li svg {
  fill: #dfab00;
}`, "",{"version":3,"sources":["webpack://./src/Views/Service/Components/Stars/stars.sass"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,aAAA;AACJ;AACI;EACI,aAAA;EACA,gBAAA;EACA,SAAA;EACA,UAAA;AACR;AACI;EACI,UAAA;EAEA,YAAA;EACA,eAAA;AAAR;AAEQ;EACI,aAAA;AAAZ","sourcesContent":[".stars-notes\n    position: relative\n    displey: flex\n\n    & ul\n        display: flex\n        list-style: none\n        margin: 0\n        padding: 0\n\n    & li\n        padding: 0\n        //margin: 5px 2px\n        border: none\n        cursor: pointer\n\n        svg\n            fill: #dfab00\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
