import { FormattedMessage, useIntl } from "react-intl";
import {
  employeeExperienceTranslate,
  employeeProfileEdit,
} from "../../../../i18n/translatedMessages";

import { translate } from "../../../../i18n/Library/contract_type";

export const Contract = ({ onChange, data, lists, error }) => {
  const getLanguage = useIntl();
  const language = getLanguage.locale === "PT-BR" ? "pt" : "es";
  return (
    <div className="content-form">
      <div className="row">
        <div className="col">
          <h3>{employeeExperienceTranslate.contractTitleEEProfile}</h3>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="label">{employeeExperienceTranslate.contractTypeEEProfile}</div>
          <div className="radio-group" onChange={onChange}>
            {lists.contract.map((item) => (
              <div key={item.id}>
                <input
                  type="radio"
                  value={item.id}
                  name="contract"
                  id={item.name}
                  defaultChecked={item.id === Number(data.contract)}
                />
                <label htmlFor={item.type}>{translate[language][item.type]}</label>
              </div>
            ))}
          </div>
        </div>
        <div className="col">
          <div className="label">{employeeExperienceTranslate.myBossEEProfile}</div>
          <select
            name="boss"
            onChange={onChange}
            className={error.country ? "error" : ""}
            value={data.boss}
          >
            <FormattedMessage id="selectBossEEProfile">
              {(msg) => (
                <option value="" style={{ color: "#AFAFAF" }}>
                  {msg}
                </option>
              )}
            </FormattedMessage>
            {lists.employee?.map((employee) => (
              <option value={employee.id} key={employee.id}>
                {employee.name}
              </option>
            ))}
          </select>
          {error.boss && <small>{employeeProfileEdit.profileError}</small>}
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label htmlFor="bank">{employeeExperienceTranslate.bankEEProfile}</label>
          <input type="text" id="bank" name="bank" onChange={onChange} value={data.bank} />
        </div>
        <div className="col">
          <label htmlFor="currentAccount">{employeeExperienceTranslate.bankAccountEEProfile}</label>
          <input
            type="number"
            id="currentAccount"
            name="currentAccount"
            onChange={onChange}
            value={data.currentAccount}
          />
        </div>
      </div>
    </div>
  );
};
