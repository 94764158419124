// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.curretnEmployedSelected {
  background-color: #796ce6;
  padding: 5px 10px;
  border-radius: 10px;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/Views/Service/Views/PeopleAnalytics/Management/ListaColaboradores.sass"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,iBAAA;EACA,mBAAA;EACA,WAAA;AACJ","sourcesContent":[".curretnEmployedSelected\n    background-color: #796ce6\n    padding: 5px 10px\n    border-radius: 10px\n    color: #fff"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
