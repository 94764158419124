import React, { useRef, useState } from "react";
import { BsFileText } from "react-icons/bs";
import { Toast, ToastContainer } from "react-bootstrap";
import { translate } from "../../../../i18n/Library/documents.translate";
import { useIntl } from "react-intl";
import { documentsTranslate } from "../../../../i18n/translatedMessages";
import DocfilesApi from "../../../../API/Documents/Docfiles.api";
import Swal from "sweetalert2";

import "./AccordionDocuments.sass";
import { FaEye, FaGlasses } from "react-icons/fa";
import { AiFillCheckCircle, AiOutlineCheckCircle, AiOutlineCloudUpload } from "react-icons/ai";

const AccordionCompanyDocuments = ({ documents, loadEmployee }) => {
    const getLanguage = useIntl();
    const language = getLanguage.locale === "PT-BR" ? "pt" : "es";
    const fileInputRefs = useRef({});
    const [isLoadingBtn, setLoadingBtn] = useState(false);
    const [toast, setToast] = useState(false);

    const removeSpecialAccents = str => {
        str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        str = str.replace(/[#$%¨&]/g, "")
        return str.replace(/[\(\)\-\s]/g, "_");
    }

    const uploadDoc = async (id, file) => {
        const user = JSON.parse(localStorage.getItem("user"));
        const fileName = removeSpecialAccents(file.name)
        try {
            setLoadingBtn(true);
            const hash = localStorage.getItem("hash");
            const getUrlResponse = await DocfilesApi.getURLDocfile(
                user.id,
                hash,
                fileName
            );

            if (getUrlResponse) {
                DocfilesApi.uploadFile(getUrlResponse.url, file).then(() => {
                    DocfilesApi.updateDocfile(id, {
                        document_url: `documents/company/${user.id}/${fileName}`,
                    });
                }).then(()=>{
                    Swal.fire({
                        title: "Archivo enviado",
                        icon: "success",
                        showCancelButton: false,
                        showConfirmButton: true,
                    }).then(() => {
                        window.location.reload();
                    });
                });
               
            }
        } catch (error) {
            setLoadingBtn(false);
            console.error("Erro ao obter a URL:", error);
        }
    };

    const handleFileSelect = async (event, id) => {
        const [file] = event.target.files;

        if (file) {
            await uploadDoc(id, file);
        }
    };

    return (
        <div className="card-body p-0 AccordionDocument">
            <ToastContainer
                className="p-3"
                position={"bottom-start"}
                style={{ zIndex: 1 }}
            >
                <Toast
                    bg="danger"
                    onClose={() => setToast(false)}
                    show={toast}
                    delay={5000}
                    autohide
                >
                    <Toast.Header closeButton={false}>
                        <img
                            src="holder.js/20x20?text=%20"
                            className="rounded me-2"
                            alt=""
                        />
                        <strong className="me-auto">Error</strong>
                        <small>
                            {language === "pt"
                                ? "O arquivo não foi salvo"
                                : "El archivo no fue guardado"}
                        </small>
                    </Toast.Header>
                    <Toast.Body>
                        {language === "pt"
                            ? "Aguarde um momento e tente novamente ou entre em contato conosco"
                            : "Espera un momento e inténtalo de nuevo o contáctanos"}
                    </Toast.Body>
                </Toast>
            </ToastContainer>
            <ul className="list-group">
                {documents &&
                    documents.map((document, index) => (
                        <li
                            key={index}
                            className="list-group-item py-3 mt-2 border d-flex justify-content-between align-items-center"
                        >
                            <div className="d-flex align-items-center">
                                <BsFileText size={20} />
                                <h5 className="ms-2 mt-2">
                                    {index} -{" "}
                                    {translate[language][
                                        document.document_name.trim()
                                    ] || document.document_name}
                                </h5>
                            </div>

                            {document.document_url === null ? (
                                <button
                                    className={
                                        "customUploadDoc rounded-pill btn"
                                    }
                                    onClick={() => {
                                        fileInputRefs.current[
                                            document.id
                                        ].click();
                                    }}
                                    disabled={isLoadingBtn}
                                >
                                    {isLoadingBtn
                                        ? documentsTranslate.loadingTemplate
                                        : language === "pt"
                                            ? "Subir Arquivo"
                                            : "Subir Archivo"}
                                </button>
                            ) : (
                                <div className="d-flex">
                                    
                                    <button
                                        className={
                                            "customSuccessDoc rounded-pill btn me-1"
                                        }
                                    >
                                        <AiOutlineCheckCircle size={17} className="me-1" />
                                        {language === "pt"
                                            ? "Arquivo Enviado"
                                            : "Archivo Enviado"}
                                    </button>

                                    <a href={document.document_url}
                                    target="__blank"
                                        className={
                                            "customViewDoc rounded-pill btn me-1"
                                        }
                                    >
                                        <FaEye size={17} className="me-1" />
                                        {language === "pt"
                                            ? "Ver Arquivo"
                                            : "Ver Archivo"}
                                    </a>

                                    <button
                                    className={
                                        "customUploadDoc rounded-pill btn"
                                    }
                                    onClick={() => {
                                        fileInputRefs.current[
                                            document.id
                                        ].click();
                                    }}
                                    disabled={isLoadingBtn}
                                >
                                    <AiOutlineCloudUpload size={17} className="me-1" />
                                    {isLoadingBtn
                                        ? documentsTranslate.loadingTemplate
                                        : language === "pt"
                                            ? "Suba de novo"
                                            : "Volver a subir"}
                                </button>
                                </div>

                            )}

                            <input
                                type="file"
                                ref={(ref) =>
                                    (fileInputRefs.current[document.id] = ref)
                                }
                                accept="*"
                                style={{ display: "none" }}
                                onChange={
                                    !isLoadingBtn
                                        ? (e) =>
                                            handleFileSelect(e, document.id)
                                        : null
                                }
                            />
                        </li>
                    ))}
            </ul>
        </div>
    );
};

export default AccordionCompanyDocuments;
