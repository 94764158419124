import { useContext } from "react";
import { ServicesContext } from "../../../../../Store/ServicesContext";
import "../stylesSteps.css";

const RySStep2 = () => {
  const { store, actions } = useContext(ServicesContext);

  const handleChange = event => {
    actions.setRecruitmentAndSelection({
      [event.currentTarget.name]: event.currentTarget.value
    });
  }

  return (
    <div className="container-fluid StepsFormClass">
      <div className="row justify-content-center pt-3">
        <div className="col-12 text-center">
          <h5>
            Completa la siguiente información para poder entregarte un servicio
            especializado para tus necesidades.
          </h5>
        </div>
      </div>
      <div className="row justify-content-center pt-5">
        <div className="col-12">
          <textarea
            className="form-control"
            id="exampleFormControlTextarea1"
            placeholder="Job description"
            rows="5"
            name="jobDescription"
            value={store.recruitmentAndSelection?.jobDescription}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="row text-center pt-4">
        <div className="col">
          <h5 className="form-label">¿Nombre de la empresa es confidencial?</h5>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="options1"
              id="inlineRadio1"
              value="option1"
            />
            <label className="form-check-label ps-2" for="inlineRadio1">
              No
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="options1"
              id="inlineRadio2"
              value="option2"
            />
            <label className="form-check-label ps-2" for="inlineRadio2">
              Si
            </label>
          </div>
        </div>
        <div className="col">
          <h5 className="form-label">¿Salario confidencial?</h5>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="options2"
              id="inlineRadio3"
              value="option1"
            />
            <label className="form-check-label ps-2" for="inlineRadio3">
              No
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="options2"
              id="inlineRadio4"
              value="option2"
            />
            <label className="form-check-label ps-2" for="inlineRadio4">
              Si
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RySStep2;
