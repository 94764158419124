import { useEffect, useState } from "react";
import { getSalary } from "../API/Analytics/salary";
import { getProfile } from "../API/Employee/Profile";
import { getAnnualCountry } from "../API/Lists/country";

export const useSalaryCalculation = (userId, employeeInfoData) => {
    const [user, setUser] = useState({});
    const [salaryAnnual, setSalaryAnnual] = useState('');

    const [state, setState] = useState({
        salary: 0,
        contract: "",
        country: employeeInfoData[0]?.country_id,
        fixedSalary: "",
        typeCurrency: "$",
    });

    const handleGetAnnualCountry = async () => {
        // TODO set country from employerUser
        const getCountry = await getAnnualCountry(state?.country);
        const contractType = state?.contract;

        // eslint-disable-next-line array-callback-return
        const factor = getCountry.find((_, i) => {
            /*CHILE*/
            if (getCountry[i].countryId === 1 || getCountry[i].countryId > 5) {
                return getCountry[i].valor;
            }
            /*ARGENTINA*/
            if (contractType > 1 && getCountry[i].countryId === 2) {
                return getCountry[0].valor;
            }
            if (contractType === 1 && getCountry[i].countryId === 2) {
                return getCountry[1].valor;
            }
            /*BRASIL*/
            if (contractType > 1 && getCountry[i].countryId === 4) {
                return getCountry[0].valor;
            }
            if (contractType === 1 && getCountry[i].countryId === 4) {
                return getCountry[1].valor;
            }
            /*COLOMBIA*/
            if (state.salary > 13000000 && getCountry[i].countryId === 5) {
                return getCountry[0].valor;
            }
            if (state.salary < 13000000 && getCountry[i].countryId === 5) {
                return getCountry[1].valor;
            }
            /*PERU*/
            if (getCountry[i].countryId === 3) {
                return getCountry[i].valor;
            }
        });

        const monthSalary = Number.parseFloat(state.salary);
        const variableAnnual = Number.parseFloat(state.fixedSalary);
        const countryFactor = Number.parseFloat(factor?.valor);
        const calculatedVariable =
            monthSalary * (variableAnnual + countryFactor);

        setSalaryAnnual(
            Number(parseFloat(calculatedVariable).toLocaleString())
            ? parseFloat(calculatedVariable).toLocaleString()
            : undefined
        );
    }

	// Revisar esta API esta fallando, en realidad hay que revisar el modelo de negocios de salarios
    // useEffect(() => {
    //     (async () => {
    //         const [salary] = await getSalary(userId);
    //         setState(salary);
    //     })();
    // }, [userId]);

    useEffect(() => {
        (async () => {
            try {
                const [profileAux] = await getProfile(543);
                setUser(profileAux || {});
            } catch (error) {
                console.error(error);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    useEffect(() => {
        handleGetAnnualCountry();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    return {
        contract: user?.contract,
        salaryAnnual
    }
};
