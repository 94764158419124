import React, { useEffect } from "react";
import "./SalaryComparativeChart.css";

const SalaryComparativeChart = ({
  salaryMean,
  salarymeanUpdate,
  currentSalary,
  formatCurrency,
  typeCurrency,
  refresh, // Nueva propiedad refresh
}) => {
  // Usar la propiedad salaryMean o salarymeanUpdate según el valor de refresh
  const salaryToUse = refresh ? salarymeanUpdate : salaryMean;

  const firstQuartile = parseInt(salaryToUse * 0.8);
  const secondQuartile = parseInt(salaryToUse * 0.9);
  const thirdQuartile = parseInt(salaryToUse.toFixed(2));
  const fourthQuartile = parseInt(salaryToUse * 1.1);
  const fifthQuartile = parseInt(salaryToUse * 1.2);

  const quartileValues = [
    firstQuartile,
    secondQuartile,
    thirdQuartile,
    fourthQuartile,
    fifthQuartile,
  ];
  const totalRange = fifthQuartile - firstQuartile;

  // Constantes para mejorar la legibilidad
  const CENTER_POSITION = 50;
  const LEFT_LIMIT = 75;
  const RIGHT_LIMIT = 125;

  // Calcular la posición relativa como un porcentaje
  let relativePosition;
  let percentajePosition = currentSalary / salaryToUse;

  let distancia = Math.abs((percentajePosition / 100) * 100 * 100);

  relativePosition =
    ((distancia - LEFT_LIMIT) / (RIGHT_LIMIT - LEFT_LIMIT)) * 100;

  if (currentSalary < firstQuartile) {
    relativePosition = 0;
  }
  if (currentSalary > fifthQuartile) {
    relativePosition = 100;
  }

  // Determine el color del punto de salario actual
  let currentSalaryColor = "#f84228";

  if (currentSalary < firstQuartile) {
    currentSalaryColor = "#f84228";
  } else if (currentSalary > firstQuartile && currentSalary < thirdQuartile) {
    currentSalaryColor = "#f59e0b";
  } else if (currentSalary > fifthQuartile) {
    currentSalaryColor = "#25e6c4";
  } else {
    currentSalaryColor = "#25e6c4";
  }

  // Calcular la distancia entre los dos círculos
  const distanceBetweenCircles = Math.abs(relativePosition - CENTER_POSITION);
  const linePosition = currentSalaryColor === "#25e6c4" ? "left" : "right";

  const percentagem = [80, 90, 100, 110, 120];

  return (
    <div className="container-chart mt-4">
      <div className="d-flex box-chart justify-content-between position-relative">
        {quartileValues.map((quarter, index) => (
          <div className={`quarter-chart`} key={index}>
            <div
              className="number-box-chart position-absolute top-0"
              style={{ transform: "translate(12px, -30px)" }}
            >
              {percentagem[index]}%
            </div>
            <div className="text-end">
              <div className="alignQuarters">
                {index !== 4 && <>Q{index + 1} </>}
                <span style={{ color: "#fff" }}>.</span>{" "}
              </div>
            </div>
            <div
              className="number-box-chart"
              style={{
                transform:
                  quarter === 0 || isNaN(salaryToUse)
                    ? "translateX(20px)"
                    : "",
              }}
            >
              {!isNaN(salaryToUse)
                ? formatCurrency(quarter, typeCurrency)
                : 0}
            </div>
          </div>
        ))}
        {salaryToUse !== 0 && !isNaN(salaryToUse) && (
          <div
            className="circle blue-circle"
            style={{ left: `${CENTER_POSITION}%` }}
          ></div>
        )}
        {currentSalary !== 0 && !isNaN(salaryToUse) && (
          <>
            <div
              className="circle"
              style={{
                background: currentSalaryColor,
                left: `${relativePosition}%`,
                top: `${CENTER_POSITION}%`,
              }}
            ></div>
            {!isNaN(salaryToUse) && (
              <div
                className="line-horizontal"
                style={{
                  [linePosition]: `${CENTER_POSITION}%`,
                  top: `${CENTER_POSITION}%`,
                  width: `${distanceBetweenCircles}%`,
                  borderTop: `2px solid ${currentSalaryColor}`,
                }}
              ></div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SalaryComparativeChart;
