import { useCallback, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";

import DocumentsService from "../../../../API/Documents/Document.api";
import DocumentsTemplate from "../../../../API/Documents/DocumentsTemplate.api";

import "./ModalEditDocuments.sass";
import { useIntl } from "react-intl";
import { documentsTranslate } from "../../../../i18n/translatedMessages";
import { translate } from "../../../../i18n/Library/documents.translate";

export const ModalEditDocuments = ({ showModal, setShowModal, documentsId }) => {
  const getLanguage = useIntl();
  const language = getLanguage.locale === "PT-BR" ? "pt" : "es";
  const [templateName, setTemplateName] = useState("");
  const [documentsList, setDocumentsList] = useState(null);
  const [checkedItems, setCheckedItems] = useState([]);
  const [forDeleteItems, setForDeleteItems] = useState([]);
  const [actualItems, setActualItems] = useState([]);
  const [isLoadingBtn, setLoadingBtn] = useState(false);

  const [newDocument, setNewDocument] = useState("");

  const loadDocuments = useCallback(async (id) => {
    const companyDocuments = await DocumentsTemplate.getAllDocuments(id || 0);
    setCheckedItems(
      companyDocuments
        ? companyDocuments.map((docs) => {
            return docs.documents_id;
          })
        : []
    );
    setActualItems(
      companyDocuments
        ? companyDocuments.map((docs) => {
            return docs.documents_id;
          })
        : []
    );
    if (id) {
      const companyDocuments = await DocumentsService.getAllDocuments(
        JSON.parse(localStorage.getItem("user")).id
      );
      const mydnaDocuments = await DocumentsService.getAllDocuments(0);
      if (!companyDocuments.hasOwnProperty("message")) {
        setDocumentsList([...mydnaDocuments, ...companyDocuments]);
      } else {
        setDocumentsList([...mydnaDocuments]);
      }
    }
  }, []);

  useEffect(() => {
    loadDocuments(documentsId);
  }, [documentsId]);

  const handleUpdate = async () => {
    const auxDelete = {
      documents: forDeleteItems,
      template_id: documentsId,
    };
    await DocumentsTemplate.deleteDocuments(auxDelete, documentsId)
      .then(async () => {
        const data = {
          documents: checkedItems,
          template_id: documentsId,
        };

        await DocumentsTemplate.createDocuments(data)
          .then(() => {
            console.log("success");
            setShowModal(false);
          })
          .catch((error) => console.error(error));
      })
      .catch((error) => console.error(error));
  };

  const handleCheckboxChange = (event) => {
    const { checked, id } = event.target;
    const aux = [...checkedItems];
    const auxDelete = [...forDeleteItems];

    if (checked) {
      aux.push(Number(id));
      if (actualItems.includes(Number(id))) {
        const index = aux.indexOf(Number(id));
        if (index > -1) {
          aux.splice(index, 1);
        }
      }
    } else {
      const index = aux.indexOf(Number(id));
      if (index > -1) {
        aux.splice(index, 1);
      }
      if (actualItems.includes(Number(id))) {
        auxDelete.push(Number(id));
      }
    }
    setForDeleteItems(auxDelete);
    setCheckedItems(aux);
  };

  const editNewDocument = async (event) => {
    event.preventDefault();
    setLoadingBtn(true);
    if (newDocument === "") {
      alert(language === "PT-BR" ? "Adicionar nome do documento" : "Agregar nombre del documento");
      return;
    }

    const document = {
      name: newDocument,
      owner_id: JSON.parse(localStorage.getItem("user")).id,
    };
    await DocumentsService.createDocuments(document).then(() => {
      loadDocuments(documentsId);
      setNewDocument("");
      setLoadingBtn(false);
    });
  };

  const handleNewDocument = (event) => {
    setNewDocument(event.target.value);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setTemplateName(value);
  };

  const handleClose = () => setShowModal(false);

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      size="lg"
      fullscreen={true}
      centered={true}
      scrollable={true}
      className="ModalEditDocuments"
    >
      <Modal.Header closeButton>
        <Modal.Title>{templateName || documentsTranslate.nameTemplate}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="mb-4">
            <input
              type="text"
              className="form-control"
              placeholder="Ex: Estagiário; Gerente..."
              name="title"
              value={templateName}
              onChange={handleChange}
            />
          </div>
          {documentsList && (
            <div className="row">
              {documentsList.map((document) => (
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6" key={document.id}>
                  <div className="form-check py-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={document.id}
                      name={document.id}
                      checked={checkedItems.includes(document.id) || false}
                      onChange={handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor={document.name}>
                      {" "}
                      {translate[language][document.name.trim()] || document.name}{" "}
                    </label>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="row">
            <div className="col-2 d-flex align-items-center">
              <button
                className="btn btn-outline-dark form-control"
                onClick={!isLoadingBtn ? editNewDocument : null}
                disabled={isLoadingBtn}
              >
                {isLoadingBtn
                  ? documentsTranslate.loadingTemplate
                  : documentsTranslate.loadingCompleteTemplate}
              </button>
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Documento personalizado"
                name="customDocument"
                onChange={handleNewDocument}
                value={newDocument}
              />
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className="row">
          <div className="col">
            <button type="button" className="btn " onClick={handleClose}>
              {documentsTranslate.btnCancelTemplate}
            </button>
          </div>
          <div className="col">
            <button
              className="btn buttonSave btn-primary"
              onClick={handleUpdate}
              data-bs-dismiss="modal"
            >
              {documentsTranslate.btnSaveTemplate}
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
