import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getEmployers } from '../../../../../API/Analytics/employers'
import { myCollaboratorsTranslate } from '../../../../../i18n/translatedMessages'
import Loading from '../../../../../Components/Loading/Loading'
import { deleteEmployee, putEmployee } from '../../../../../API/Employee/Employee'
import ModalConfirmDelete from '../../../../../Components/ModalConfirmDelete/ModalConfirmDelete'
import { BsPeople } from 'react-icons/bs'
import { MdOutlinePeopleOutline } from 'react-icons/md'
import { BiBriefcase, BiWorld } from 'react-icons/bi'
import { AiOutlineCloseCircle, AiOutlineEdit } from 'react-icons/ai'

import Swal from 'sweetalert2'
import { FormattedMessage, useIntl } from 'react-intl'
import ModalEditEmployee from '../Components/ModalEditEmployee/ModalEditEmployee'
import { getAreaByCountry, getBenefitGroupBySmtm, getCityIdByCountrySmtm } from './ParseMydnaDataToSmtmData.js'
import DropFilter from '../../../../../Components/Filters/DropFilter'
import { useListFilters } from '../../../../../Components/Filters/ListFilters/useListFilters'
import { translate as translateAreas } from './../../../../../i18n/Library/areasCorrelation'
import { translate as translatePosition } from './../../../../../i18n/Library/hierarchicalLevel'

import './myColabs.sass'

const img = 'https://assets.mydna.company/profile-placeholder.png'

const MyColabs = () => {
	const getLanguage = useIntl()
	const language = getLanguage.locale === 'PT-BR' ? 'pt' : 'es'
	const [loading, setLoading] = useState(true)
	const [isLoading, setIsLoading] = useState(true)
	const [loadingModal, setLoadingModal] = useState(false)

	const [refreshState, setrefreshState] = useState(false)

	const [employeeList, setEmployeeList] = useState([])

	const [showModalDeleteValidation, setShowModalDeleteValidation] = useState({
		check: false,
		employeeId: '',
	})
	const [showModal, setShowModal] = useState(false)

	const [modalData, setModalData] = useState({
		name: '',
		email: '',
		profileImg: '',
		companyId: '',
		levelId: '',
		areaId: '',
		subAreaId: '',
		jobName: '',
		countryId: '',
	})

	const { setSearch, setFilterCountryCheck, setFilterAreaCheck, setFilterLevelCheck, filterAreaCheck, filterCountryCheck, filterLevelCheck, area, level, country, filterEmployees } = useListFilters()

	const getDataColabBySmtm = async email => {
		try {
			const response = await fetch(`https://smtm.co/v1/position_by_mydna_email?email=${email}`, {
				method: 'GET',
				mode: 'cors',
				headers: {
					'Content-Type': 'application/json',
				},
			})
			return response.json()
		} catch (err) {
			console.error(err)
		}
	}

	useEffect(() => {
		const user = JSON.parse(localStorage.getItem('user'))
		const fetchEmployees = async () => {
			setEmployeeList(await getEmployers(user.id))
			setLoading(false)
		}
		setLoading(true)

		fetchEmployees()
	}, [])

	const handleChange = e => {
		setSearch(e.target.value)
	}

	const handleAtt = async () => {
		const user = JSON.parse(localStorage.getItem('user'))
		const response = await getEmployers(user.id)
		setEmployeeList(response)
	}

	const handleShowConfirmDelete = (employeeId, employeeData) => {
		setShowModalDeleteValidation({
			check: true,
			employeeId: employeeId,
		})
	}
	const handleHideConfirmDelete = () => {
		setShowModalDeleteValidation({
			check: false,
			employeeId: '',
		})
	}

	const handleDelete = async employeeId => {
		const del = await deleteEmployee(employeeId)
		await handleAtt()
		setShowModalDeleteValidation({
			check: false,
			employeeId: '',
		})
		if (del === 1) {
			setrefreshState(!refreshState)
			//useeffect in line 51 dont refresh
			Swal.fire({
				title: 'Colaborador eliminado',
				icon: 'success',
				confirmButtonText: 'Ok',
			}).then(() => {
				window.location.reload()
			})
		} else {
			Swal.fire({
				title: 'Hola 👋',
				text: 'Ha ocurrido un error inesperado. Si el error persiste, por favor comunicate con florenciacalderon@mydnadigital.com',
				icon: 'error',
				confirmButtonText: 'Ok',
			})
		}
	}

	const handleShowModal = async data => {
		const json = await getDataColabBySmtm(data?.email)

		setModalData({
			...modalData,
			employeeId: data.id,
			name: data.name,
			email: data.email,
			profileImg: data.profileImg,
			companyId: data.companyId,
			levelId: data.level.id,
			areaId: data.area.id,
			subAreaId: data.subAreaId,
			jobName: data.jobName,
			country: data.country,
			//SMTM DATA
			countryId: json[0]?.country_id,
			englishLevel: json[0]?.english_level_id,
			seniorityLevel: json[0]?.seniority_id,
			gradeLevel: json[0]?.qualification_id,
			variableSalary: json[0]?.variable_salary,
			salary: json[0]?.current_salary,
		})

		setShowModal(true)
	}
	const handleHideModal = () => {
		setShowModal(false)
	}

	const handleEditChanges = evt => {
		const value = evt.target.value
		setModalData({
			...modalData,
			[evt.target.name]: value,
		})
	}

	const handleSendChange = async employeeId => {
		setLoadingModal(true)
		//Modificaciones valores de MyDNA a SMTM
		let befit_group = await getBenefitGroupBySmtm(modalData.levelId)
		let area_id_smtm = await getAreaByCountry(modalData.countryId.toString(), parseInt(modalData.areaId))
		let cityId = getCityIdByCountrySmtm(modalData.countryId.toString())

		const auxSendChange = {
			name: modalData.name,
			email: modalData.email,
			profileImg: modalData.profileImg,
			companyId: modalData.companyId,
			levelId: modalData.levelId,
			areaId: modalData.areaId,
			subAreaId: modalData.subAreaId,
			jobName: modalData.jobName,
			countryId: modalData.countryId,
			//data de SMTM
			job_name: modalData.jobName,
			country_id: modalData.countryId,
			city_id: cityId,
			subArea: modalData.subArea,
			area_id: parseInt(area_id_smtm),
			group: befit_group,
			english_level_id: parseInt(modalData.englishLevel),
			seniority_id: parseInt(modalData.seniorityLevel),
			qualification_id: parseInt(modalData.gradeLevel),
			current_salary: parseInt(modalData.salary),
			variable_salary: parseInt(modalData.variableSalary),
			job_id: null,
			mydna_user_name_position: modalData.name,
			mydna_email_position: modalData.email,
		}
		await putEmployee(auxSendChange, employeeId)
		const user = JSON.parse(localStorage.getItem('user'))
		const response = await getEmployers(user.id)
		setEmployeeList(response)
		setLoadingModal(false)
		setShowModal(false)
		setTimeout(() => {
			window.location.reload()
		}, 500)
	}

	return (
		<div className='row'>
			<ModalEditEmployee show={showModal} hide={handleHideModal} data={modalData} areaList={area} levelList={level} handleChange={handleEditChanges} handleSendChange={handleSendChange} loading={loadingModal} />

			<ModalConfirmDelete show={showModalDeleteValidation.check} hide={handleHideConfirmDelete} handleDelete={() => handleDelete(showModalDeleteValidation.employeeId)} />
			<div className='col offset-1'>
				<div className='my-colaboradores'>
					{loading && (
						<div className='loading'>
							<Loading />
						</div>
					)}
					<div className='row'>
						<div className='col d-flex mb-4'>
							<div className='icon'>
								<BsPeople size={30} />
							</div>
							<h1>{myCollaboratorsTranslate.myCollaboratorsTitle}</h1>
						</div>
					</div>
					<div className='row pb-3'>
						<div className='col-10 offset-1'>
							<FormattedMessage id={'searchCollaborator'}>{msg => <input type='text' className='principal-search adjust-input-principal-s w-100' placeholder={msg} onChange={handleChange} />}</FormattedMessage>
						</div>
						<div className='col-10 offset-1 border-bottom pb-3'>
							<div className='row row-cols-3'>
								<div className='col'>
									<DropFilter changeAppearence={filterCountryCheck.length} icon={<BiWorld />} filterName='País' setFiltValues={setFilterCountryCheck} filtValues={filterCountryCheck} list={country} />
								</div>
								<div className='col'>
									<DropFilter icon={<BiBriefcase />} filterName='Área' setFiltValues={setFilterAreaCheck} filtValues={filterAreaCheck} list={area} />
								</div>
								<div className='col'>
									<DropFilter icon={<MdOutlinePeopleOutline />} filterName='Nivel' setFiltValues={setFilterLevelCheck} filtValues={filterLevelCheck} list={level} />
								</div>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-10 offset-1'>
							{employeeList?.length ? (
								filterEmployees.map(employee => (
									<div className='row border-bottom py-3 align-items-center' key={employee.id}>
										<div className='col'>
											<Link to={`/app/analytic/perfil/${employee.id}`}>
												<div className='row py-1 align-items-center'>
													<div className='col-2 pe-0 text-center align-items-center'>
														<img src={employee?.asset || img} alt='' className='employeeImg rounded-circle img-fluid shadow' />
													</div>
													<div className='col-10'>
														<div className='row'>
															<div className='col-12'>
																<h5 className='h5'>{employee?.name}</h5>
																<p className='m-0'>{employee?.jobName}</p>
																<p className='m-0'>{employee?.email}</p>
															</div>
														</div>
													</div>
												</div>
											</Link>
										</div>
										<div className='col-3'>
											<div className='row'>
												<div className='col-12'>
													<h6 className='h6'>{translatePosition[language][employee?.level?.PositionN]}</h6>
												</div>
												<div className='col-12'>
													<h6 className='h6 text-muted'>{translateAreas[language][employee?.area?.profileArea]}</h6>
												</div>
											</div>
										</div>
										<div className='col-2'>
											<div className='row justify-content-center'>
												<div className='col-4'>
													<button className='btn' onClick={() => handleShowModal(employee)}>
														<AiOutlineEdit size={20} />
													</button>
												</div>
												<div className='col-4'>
													<button className='btn' onClick={() => handleShowConfirmDelete(employee?.id)}>
														<AiOutlineCloseCircle size={20} />
													</button>
												</div>
											</div>
										</div>
									</div>
								))
							) : (
								<div className='text-center'>
									<h6 className='py-4 fw-bold'>
										Aun no has registrado ningún colaborador, registra{' '}
										<Link className='text-blue' to={`/app/services/new`}>
											aquí
										</Link>{' '}
										a uno o{' '}
										<Link className='text-blue' to={`/app/services/multiple-add`}>
											más
										</Link>{' '}
										colaboradores
									</h6>
								</div>
							)}
						</div>
					</div>
					{employeeList?.length === 0 && <div className='col-9 box-error'>Todavia no hay colaboradores registrados</div>}
				</div>
			</div>
		</div>
	)
}
export default MyColabs
