import axios from "axios";

export const sendServiceRequest = async (service_request) => {
  try {
    var today = new Date();

    var date =
      today.getMonth() + 1 + "/" + today.getDate() + "/" + today.getFullYear();
    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API}/api/service_request/sendRequest`,
      headers: {
        "Content-type": "application/json",
      },
      data: {
        name: service_request.name,
        lastname: service_request.lastname,
        jobtitle: service_request.jobtitle,
        company: service_request.company,
        mail: service_request.mail,
        phone: service_request.phone,
        service: service_request.service,
        country: service_request.country,
        title: service_request.title,
        message: service_request.message,
        date: date,
      },
    });
    await response.data;
  } catch (error) {
    throw error;
  }
};

export const getUserCompany = async (id) => {
  try {
    const token = localStorage.getItem("accessToken");
    const tokenJson = JSON.parse(token);
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/service_request/userInfos/${id}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${tokenJson}`,
        },
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const handleCustomerRequest = async (req) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API}/api/service_request/handleCustomerRequest`,
      headers: {
        "Content-type": "application/json",
      },
      data: {
        name: req.name,
        lastname: req.lastname,
        jobtitle: req.jobtitle,
        company: req.company,
        mail: req.mail,
        phone: req.phone,
        service: req.service,
        country: req.country,
        title: req.title,
        message: req.message
      },
    });
    await response.data;
  } catch (error) {
    throw error;
  }
};

export const saveRyS = async (req) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API}/api/service_request/recruitmentAndSelection`,
      headers: {
        "Content-type": "application/json",
      },
      data: {
        nameOfResponsible: req.nameOfResponsible,
        mailOfResponsible: req.mailOfResponsible,
        area: req.area,
        englishLevel: req.englishLevel,
        jobName: req.jobName,
        jobDescription: req.jobDescription,
        minSalary: req.minSalary,
        maxSalary: req.maxSalary,
        country: req.country
      },
    });
    return await response.data;
  } catch (error) {
    throw error;
  }
};
