export const getAreaList = async () => {
	try {
		const resp = await fetch(`${process.env.REACT_APP_API}/search/area`, {
			method: 'GET',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
			},
		});
		return await resp.json();
	} catch (err) {
		console.error(err);
	}
};

export const getArea = async () => {
	try {
		const resp = await fetch(`${process.env.REACT_APP_API}/areas`, {
			method: 'GET',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
			},
		});
		return await resp.json();
	} catch (err) {
		console.error(err);
	}
};


