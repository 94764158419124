// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-services .modal-header {
  background-color: white;
}
.modal-services .modal-title {
  width: 100%;
  text-align: center;
  color: rgb(69, 185, 235);
}
.modal-services .btn-close {
  cursor: pointer;
}
.modal-services button {
  border: 2px solid #181818;
  color: rgb(69, 185, 235);
  width: 350px;
}
.modal-services .color-blue {
  color: rgb(69, 185, 235);
}`, "",{"version":3,"sources":["webpack://./src/Views/Services/Modais/modal.sass"],"names":[],"mappings":"AACI;EACI,uBAAA;AAAR;AACI;EACI,WAAA;EACA,kBAAA;EACA,wBAAA;AACR;AACI;EACI,eAAA;AACR;AACI;EACI,yBAAA;EACA,wBAAA;EACA,YAAA;AACR;AACI;EACI,wBAAA;AACR","sourcesContent":[".modal-services\n    & .modal-header\n        background-color: white\n    & .modal-title\n        width: 100%\n        text-align: center\n        color: rgb(69, 185, 235)\n\n    & .btn-close\n        cursor: pointer\n\n    & button\n        border: 2px solid #181818\n        color: rgb(69, 185, 235)\n        width: 350px\n\n    & .color-blue\n        color: rgb(69, 185, 235)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
