import "./loading.sass";
import { loadingTranslate } from "../../i18n/translatedMessages";

const Loading = ({ size = "lg" }) => {
    return (
        <div className={`loading-component ${size}`}>
            <svg
                width="344"
                height="392"
                viewBox="0 0 344 392"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g id="plt1" className="animation">
                    <path
                        d="M10.434 268.697C5.00701 268.773 0.652008 273.196 0.652008 278.623V300.235C0.652008 323.802 19.826 342.908 43.478 342.908C67.13 342.908 86.304 323.802 86.304 300.235V274.685C86.304 268.783 81.189 264.168 75.32 264.803C58.757 266.594 42.018 267.796 25.124 268.387C21.235 268.523 15.42 268.626 10.434 268.697Z"
                        fill="#5AC2AD"
                    />
                    <path
                        d="M86.305 42.837C86.305 19.27 67.131 0.164001 43.479 0.164001C19.826 0.164001 0.653015 19.27 0.653015 42.837V235.075C0.653015 240.664 5.26401 245.148 10.852 245.009C33.832 244.438 56.35 242.006 78.262 237.863C82.937 236.979 86.306 232.868 86.306 228.11V42.837H86.305Z"
                        fill="#5AC2AD"
                    />
                </g>
                <g id="plt2" className="animation">
                    <path
                        d="M257.61 300.234C257.61 323.801 276.784 342.907 300.436 342.907C324.088 342.907 343.262 323.801 343.262 300.234V194.402C343.262 186.791 335.118 181.988 328.435 185.628C307.509 197.03 285.922 207.371 263.748 216.583C260.024 218.129 257.609 221.78 257.609 225.813V300.234H257.61Z"
                        fill="#6E68AF"
                    />
                    <path
                        d="M300.436 0.162994C276.783 0.162994 257.609 19.269 257.609 42.836V135.229C257.609 143.738 267.629 148.33 274.046 142.742C296.873 122.868 317.516 100.549 335.569 76.195C340.6 69.408 343.262 61.155 343.262 52.707V42.837C343.262 19.269 324.088 0.162994 300.436 0.162994Z"
                        fill="#6E68AF"
                    />
                </g>
                <g id="plt3" className="animation">
                    <path
                        d="M129 300.071C129 323.638 148.174 342.744 171.826 342.744C195.479 342.744 214.652 323.638 214.652 300.071V199.237C214.652 192.552 208.19 187.773 201.798 189.731C180.609 196.222 158.985 201.714 136.979 206.152C132.338 207.088 129 211.162 129 215.897V300.071Z"
                        fill="#45B9EB"
                    />
                    <path
                        d="M171.826 0C148.173 0 129 19.106 129 42.673V161.831C129 168.723 135.847 173.532 142.326 171.183C165.839 162.659 188.364 152.078 209.69 139.662C212.758 137.877 214.653 134.601 214.653 131.053V42.674C214.652 19.105 195.479 0 171.826 0Z"
                        fill="#45B9EB"
                    />
                </g>
            </svg>
            <div className="shadow-content">
                <div className="shadow-loading"></div>
                <div className="shadow-loading"></div>
                <div className="shadow-loading"></div>
            </div>
            <div className="text">
                <span>{loadingTranslate.loadingP1}</span>
                {loadingTranslate.loadingP2}...
            </div>
        </div>
    );
};
export default Loading;
