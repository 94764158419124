// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radio-answer {
  cursor: pointer;
  margin: 0 15px;
}
.radio-answer svg {
  fill: #bcbcbc;
}`, "",{"version":3,"sources":["webpack://./src/Views/Culture/Components/Toggle/toggle.sass"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,cAAA;AACJ;AACI;EACI,aAAA;AACR","sourcesContent":[".radio-answer\n    cursor: pointer\n    margin: 0 15px\n\n    & svg\n        fill: #bcbcbc\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
