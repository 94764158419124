import { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { getAreas, getIndustry, getJob, getQualification, getSize, postData } from '../../../../../API/smtm'
import { validate } from './validate'
import { salariesAndBenefitsTranslate } from '../../../../../i18n/translatedMessages'

import './smtm.sass'
import Loading from '../../../../../Components/Loading/Loading'
import { FormattedMessage } from 'react-intl'
import ListadoReportes from '../../../../Services/Components/PayBalance/Components/ListadoReportes'
import StepsReport from '../../../../Services/Components/PayBalance/Components/StepsReport'

const ShowMeTheMoney = () => {

    const [state, setState] = useState({
        name: "",
        email: JSON.parse(localStorage.getItem("email")),
        seniority: 0,
        english_level: 0,
        salary: "",
        salary_variable: ""
    })

    const [showSalary, setShowSalary] = useState(false)
    const [showError, setShowError] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)

    const [areaList, setAreaList] = useState(null)
    const [jobList, setJobList] = useState(null)
    const [industryList, setIndustryList] = useState(null)
    const [sizeList, setSizeList] = useState(null)
    const [qualificationList, setQualificationList] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const setIndustry = async () => {
            const list = await getIndustry(state.country)
            setIndustryList(list)
        }

        const setAreas = async () => {
            const list = await getAreas(state.country, state.industry)
            setAreaList(list)
        }

        const setJobs = async () => {
            const list = await getJob(state.country, state.area)
            setJobList(list)
        }


        const setSize = async () => {
            const list = await getSize(state.country)
            setSizeList(list)
        }

        const setQualification = async () => {
            const list = await getQualification(state.country)
            setQualificationList(list)
        }

        if (state && state.country) {
            setIndustry()
            setSize()
            setQualification()
        }

        if (state && state.industry) {
            setAreas()
        }

        if (state && state.area) {
            setJobs()
        }

    }, [state])

    const handleChange = event => {
        setState({
            ...state,
            [event.currentTarget.name]: event.currentTarget.value
        })
    }

    const handleSelect = event => {
        setState({
            ...state,
            [event.currentTarget.name]: event.currentTarget.value,
            country_id: event.target.selectedIndex
        })
    }

    const sendForm = async () => {
        if (!validate(state)) {
            setShowError(true)
        }
        else {
            console.log('teste')
            setLoading(true)
            const response = await postData(state)
            if (response.hasOwnProperty("modal")) {
                setShowSalary(true)
            } else {
                setShowSuccess(true)
            }
            setLoading(false)
        }
    }

    return (
        <>
            <Modal show={showError} onHide={() => setShowError(false)}>
                <Modal.Body>
                    <p>{salariesAndBenefitsTranslate.salaryConsultErrorEmptyFields}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowError(false)}>{salariesAndBenefitsTranslate.closeButton}</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showSalary} onHide={() => setShowSalary(false)}>
                <Modal.Body>
                    <p>{salariesAndBenefitsTranslate.salaryConsultErrorIncompatibleSalary}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowSalary(false)}>{salariesAndBenefitsTranslate.closeButton}</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showSuccess} onHide={() => setShowSuccess(false)}>
                <Modal.Body>
                    <p>{salariesAndBenefitsTranslate.salaryConsultSuccessful}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowSuccess(false)}>{salariesAndBenefitsTranslate.closeButton}</Button>
                </Modal.Footer>
            </Modal>
            <div className="smtm">
                {loading &&
                    <div className="loading">
                        <Loading />
                    </div>
                }
                <div className="row">
                    <div className="col offset-1">
                        <h2>{salariesAndBenefitsTranslate.salaryConsultTitle}</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col offset-1">
                        <p>{salariesAndBenefitsTranslate.salaryConsultText}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col offset-1">
                    <FormattedMessage id={"salaryConsultResponsableName"}>
                            {msg => (
                                <input type="text" placeholder={msg} name="name" onChange={handleChange} value={state.name} />       
                            )}
                        </FormattedMessage>
                    </div>
                    <div className="col">
                    <FormattedMessage id={"salaryConultResponsableEmail"}>
                            {msg => (
                                <input type="text" placeholder={msg} name="email" onChange={handleChange} value={state.email} />    
                            )}
                        </FormattedMessage>
                    </div>
                </div>
                <div className="row">
                    <div className="col offset-1">
                        <FormattedMessage id={"country"}>
                            {msg => (
                                <select onChange={handleSelect} name="country">
                                    <option>{msg}</option>
                                    <option value="CL">Chile</option>
                                    <option value="AR">Argentina</option>
                                    <option value="PE">Peru</option>
                                    <option value="BR">Brasil</option>
                                    <option value="CO">Colombia</option>
                                    <option value="MX">Mexico</option>
                                    <option value="EC">Ecuador</option>
                                </select>
                            )}
                        </FormattedMessage>
                        
                    </div>

                    <div className="col">
                        {industryList && <select onChange={handleChange} name="industry">
                        <FormattedMessage id={"industry"}>
                            {msg => (
                                <option >{msg}</option>   
                            )}
                        </FormattedMessage>
                            {industryList.map(item => (<option value={item.value} key={item.value}>{item.name}</option>))}
                        </select>}
                    </div>
                </div>
                <div className="row">
                    <div className="col offset-1">
                        {areaList && <select onChange={handleChange} name="area">
                        <FormattedMessage id={"area"}>
                            {msg => (
                                <option>{msg}</option>   
                            )}
                        </FormattedMessage>
                            {areaList.map(item => (
                                <option value={item.value} key={item.value}>{item.name}</option>
                            ))}
                        </select>}
                    </div>
                    <div className="col">
                        {jobList && <select onChange={handleChange} name="job">
                        <FormattedMessage id={"role"}>
                            {msg => (
                                <option>{msg}</option>  
                            )}
                        </FormattedMessage>
                            {jobList.map(item => (
                                <option value={item.value} key={item.value}>{item.name}</option>
                            ))}
                        </select>}
                    </div>
                </div>
                <div className="row mt-3 mb-5">
                    <div className="col offset-1">
                        {sizeList && <select onChange={handleChange} name="size">
                        <FormattedMessage id={"companySize"}>
                            {msg => (
                                <option>{msg}</option> 
                            )}
                        </FormattedMessage>
                            {sizeList.map(item => (
                                <option value={item.value} key={item.value}>{item.name}</option>
                            ))}
                        </select>}
                    </div>
                    <div className="col">
                        {qualificationList && <>
                            <h4 className='ms-4'>{salariesAndBenefitsTranslate.salaryConsultEducationLevel}</h4>
                            <div className="d-flex">
                                {qualificationList.map(item =>
                                    <label className="d-flex  align-items-center mx-5" key={item.value}>
                                        <input type="radio" name="qualification" value={item.value} onClick={handleChange} />
                                        <span className='ms-2'>{item.name}</span>
                                    </label>)}
                            </div>
                        </>}
                    </div>
                </div>
                <div className="row">
                    <div className="col offset-1">
                        <h4>{salariesAndBenefitsTranslate.salaryConsultExperienceYears}</h4>
                        <input type="range" step="1" min="0" max="2" onChange={handleChange} name="seniority" value={state.seniority} />
                        <div className="d-flex justify-content-between">
                            <span>{salariesAndBenefitsTranslate.salaryConsultExperienceThreeLess}</span>
                            <span>{salariesAndBenefitsTranslate.salaryConsultExperienceThreeOrSeven}</span>
                            <span> {salariesAndBenefitsTranslate.salaryConsultExperienceSevenOrMore}</span>
                        </div>
                    </div>
                    <div className="col">
                        <h4>{salariesAndBenefitsTranslate.salaryConsultEnglishLevel}</h4>
                        <input type="range" step="1" min="0" max="3" onChange={handleChange} name="english_level" value={state.english_level} />
                        <div className="d-flex justify-content-between">
                            <span>{salariesAndBenefitsTranslate.salaryConsultEnglishAloof}</span>
                            <span>{salariesAndBenefitsTranslate.salaryConsultEnglishBasic}</span>
                            <span>{salariesAndBenefitsTranslate.salaryConsultEnglishMid}</span>
                            <span>{salariesAndBenefitsTranslate.salaryConsultEnglishHigh}</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col offset-1">
                    <FormattedMessage id={"salaryConsultGrossMonthly"}>
                            {msg => (
                                <input type="number" placeholder={msg} onChange={handleChange} name="salary" value={state.salary} />   
                            )}
                        </FormattedMessage>
                    </div>
                    <div className="col">
                    <FormattedMessage id={"salaryConsultAnnualBonus"}>
                            {msg => (
                                <input type="number" placeholder={msg} onChange={handleChange} name="salary_variable" value={state.salary_variable} />    
                            )}
                        </FormattedMessage>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col offset-1 d-flex justify-content-center">
                        <button onClick={sendForm}>{salariesAndBenefitsTranslate.salaryConsultButtonSend}</button>
                    </div>
                </div>
            </div>

            <div className=" mx-5 ps-5">
            <StepsReport />
            <ListadoReportes />
            </div>

            
        </>
    )
}
export default ShowMeTheMoney