import PropTypes from 'prop-types'
import React, { memo, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { salariesAndBenefitsTranslate } from '../../../../../i18n/translatedMessages'
import EmptyInformation from '../../../../Analytics/Components/EmptyInformation/EmptyInformation'
import { SalaryComparative } from './SalaryComparative'

const TablePositionItem = ({ companyPositions, readonly, onReport, photos }) => {
	const [showSalaryComparative, setShowSalaryComparative] = useState(null)
	const language = useIntl()
	return (
		<>
			{companyPositions.length > 0 && (
				<table className='table'>
					<thead>
						<tr>
							<th scope='col'>
								<strong>{salariesAndBenefitsTranslate.salaryRoleEmployee}</strong>
							</th>
							<th scope='col'>{<strong>{salariesAndBenefitsTranslate.salaryRoleCoin}</strong>}</th>

							<th scope='col'>
								<strong>{salariesAndBenefitsTranslate.salaryRoleSalary}</strong>
							</th>
							<th scope='col'>
								<strong>Mercado</strong>
							</th>
							<th scope='col'>
								<strong>Comparativo</strong>
							</th>
							<th scope='col'>
								<strong>Info</strong>
							</th>
							<th scope='col'>
								<strong>Reporte</strong>
							</th>
						</tr>
					</thead>
					<tbody>
						{companyPositions.map((value, index) => (
							<React.Fragment key={value.id}>
								<tr>
									{!readonly && (
										<>
											<td className='flex'>
												<div className='me-3'>
													<div
														className='border border-1 rounded-circle position-relative d-flex justify-content-center align-items-center p-0'
														style={{
															width: '60px',
															height: '60px',
														}}
													>
														<span className='fs-2 fw-bold opacity-75 text-uppercase' style={{ color: '#9e8bff' }}>
															{value?.mydna_user_name_position?.charAt(0)}
														</span>
														{photos.map((el, i) => (
															<React.Fragment key={i}>
																{value.mydna_email_position === photos[i].mydnaUserEmail && (
																	<img
																		style={{
																			height: '60px',
																		}}
																		className='rounded-circle position-absolute'
																		src={photos[i].urlPhoto}
																		alt='Foto Perfil'
																	/>
																)}
															</React.Fragment>
														))}
													</div>
													<div className='bandera-avatar'>
														<img
															src={getCorrectValue({
																toValidate: value.country_id,
																valuesList: FLAG,
															})}
															style={{ maxWidth: '30px' }}
															alt=''
														/>
													</div>
												</div>
												{/* </td>
											<td> */}
												<div className='d-flex flex-column'>
													<div>
														<strong>{value.mydna_user_name_position}</strong>
													</div>
													<div>{value.job_name}</div>
												</div>
											</td>
										</>
									)}
									<td>
										<div className='d-flex flex-column'>
											<div>
												<strong>
													{getCorrectValue({
														toValidate: value.country_id,
														valuesList: CURRENCY,
													})}
												</strong>
											</div>
											<div>
												{getCorrectValue({
													toValidate: value.country_id,
													valuesList: TYPESALARY,
												})}
											</div>
										</div>
									</td>
									<td>
										{value.current_salary ? (
											<div>
												{value.current_salary === 0 ? (
													<span>{language.locale === 'ES' ? 'No informado' : 'Não informado'}</span>
												) : (
													<span>
														{getCorrectValue({
															toValidate: value.country_id,
															valuesList: CURRENCY,
															currency: value.current_salary,
														})}
													</span>
												)}
											</div>
										) : (
											<p>{language.locale === 'ES' ? 'No informado' : 'Não informado'}</p>
										)}
									</td>
									<td>
										{parseInt(value.salary_market)
											? getCorrectValue({
													toValidate: value.country_id,
													valuesList: CURRENCY,
													currency: parseInt(value.salary_market),
											  })
											: language.locale === 'ES'
											? 'Procesando'
											: 'Processando'}
									</td>
									<td>
										<div>
											<>
												{value.comparative === null ? (
													<span> ⏳ </span>
												) : value.comparative <= 15 && value.comparative >= -15 ? (
													<span>
														{value.comparative}%
														<img src='https://assets.mydna.company/salario-dentro-rango.svg' alt='Rango' />
													</span>
												) : value.comparative > 15 ? (
													<span>
														{value.comparative}%
														<img src='https://assets.mydna.company/flecha-sobre.png' alt='Sobre' />
													</span>
												) : (
													<span>
														{value.comparative}%
														<img src='https://assets.mydna.company/flecha-bajo.png' alt='Bajo' />
													</span>
												)}
											</>
										</div>
									</td>
									<td>
										<div>
											<OverlayTrigger
												placement='top'
												delay={{ show: 250, hide: 1000 }}
												overlay={RenderTooltip(
													getCorrectValue({
														toValidate: value.english_level_id,
														valuesList: GETYPENIVEL.getEnglisLevel,
														level: true,
													}),
													getCorrectValue({
														toValidate: value.seniority_id,
														valuesList: GETYPENIVEL.getSeniorityLevel,
														level: true,
													}),
													getCorrectValue({
														toValidate: value.qualification_id,
														valuesList: GETYPENIVEL.getQualificationLevel,
														level: true,
													}),
													value.comparative
												)}
												id='tooltip-top'
											>
												<div style={{ cursor: 'pointer' }}>
													<img src='https://assets.mydna.company/infoicon.png' alt='Info' />
												</div>
											</OverlayTrigger>
										</div>
									</td>
									<td>
										{value.comparative === null ? (
											<button className='btn-descargar-gray'>
												<img src='https://assets.mydna.company/b2b-images/download-cloud.svg' alt='Descargar Reporte' />
											</button>
										) : (
											<button className='btn-descargar-green' onClick={() => onReport(index)}>
												<img src='https://assets.mydna.company/b2b-images/download-cloud.svg' alt='Descargar Reporte' />
											</button>
										)}
									</td>
									<td>
										<button className='btn-salaryComparative' onClick={() => setShowSalaryComparative(value.id)}>
											Ver Banda
										</button>
									</td>
								</tr>

								{showSalaryComparative === value.id && (
									<SalaryComparative
										levelSmtm={value.group}
										countryId={value.country_id}
										salaryPosition={value.salary_position}
										currentSalary={parseInt(value.current_salary)}
										salaryMarket={parseInt(value.salary_market)}
										email={value.mydna_email_position}
									/>
								)}
							</React.Fragment>
						))}
					</tbody>
				</table>
			)}
			{companyPositions.length <= 0 && <EmptyInformation />}
		</>
	)
}

TablePositionItem.prototype = {
	companyPositions: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default memo(TablePositionItem)

const CURRENCY = ['CLP', 'ARS', 'S/.', 'R$', 'COP', 'MEX', 'CRC']

const TYPESALARY = ['Liquido', 'Bruto', 'Bruto', 'Bruto', 'Bruto', 'Bruto', 'Bruto']

const FLAG = [
	'https://assets.mydna.company/country_flags_rounded/cl.svg',
	'https://assets.mydna.company/country_flags_rounded/ar.svg',
	'https://assets.mydna.company/country_flags_rounded/pe.svg',
	'https://assets.mydna.company/country_flags_rounded/br.svg',
	'https://assets.mydna.company/country_flags_rounded/co.svg',
	'https://assets.mydna.company/country_flags_rounded/mx.svg',
	'https://assets.mydna.company/country_flags_rounded/ec.svg',
]

const CURRENCYSHORT = ['es-CL', 'es-AR', 'es-PE', 'es-BR', 'pt-BR', 'es-MX', 'es-EC']

const GETYPENIVEL = {
	getEnglisLevel: [salariesAndBenefitsTranslate.salaryConsultEnglishAloof, salariesAndBenefitsTranslate.salaryConsultEnglishBasic, salariesAndBenefitsTranslate.salaryConsultEnglishMid, salariesAndBenefitsTranslate.salaryConsultEnglishHigh],
	getSeniorityLevel: [salariesAndBenefitsTranslate.salaryRoleSL1, salariesAndBenefitsTranslate.salaryRoleSL2, salariesAndBenefitsTranslate.salaryRoleSL3, salariesAndBenefitsTranslate.salaryRoleSL4],
	getQualificationLevel: [salariesAndBenefitsTranslate.salaryRoleQL1, salariesAndBenefitsTranslate.salaryRoleQL2, salariesAndBenefitsTranslate.salaryRoleQL3, salariesAndBenefitsTranslate.salaryRoleQL4],
}

const getCorrectValue = ({ toValidate, valuesList = [], currency, level }) => {
	if (currency) {
		let newCurrency
		valuesList.find((_, index) => {
			if (toValidate === index + 1) {
				newCurrency = currency.toLocaleString(CURRENCYSHORT[index + 1])
			}
		})
		return newCurrency
	}
	return valuesList.find((_, index) => {
		if (level) {
			return toValidate === index
		}
		return toValidate === index + 1
	})
}

const RenderTooltip = (ingles, nivel, educacion, comparative) => {
	const language = useIntl()
	return (
		<Tooltip className='my-tooltip' id='button-tooltip'>
			<div>
				<div className='d-flex flex-column'>
					<div>{language.locale === 'ES' ? 'Ingles' : 'Inglês'}</div>
					<div>
						<h6>{ingles}</h6>
					</div>
					<div>{language.locale === 'ES' ? 'Nivel' : 'Nível'}</div>
					<div>
						<h6>{nivel}</h6>
					</div>
					<div>{language.locale === 'ES' ? 'Educación' : 'Educação'}</div>
					<div>
						<h6>{educacion}</h6>
					</div>
					<div>
						{comparative === null && (
							<>
								<hr />
								<span>{salariesAndBenefitsTranslate.salaryRoleAlert}</span>
							</>
						)}
					</div>
				</div>
			</div>
		</Tooltip>
	)
}
