import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";
import * as RB from "react-bootstrap";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { IdeaIcon } from "../../Icons/Icons";
import { EntrenamientoIcon } from "../../Icons/CollaboratorsIcons";
import { trainingsTranslate } from "../../i18n/translatedMessages";
import { btnTranslate } from "../../i18n/translatedMessages";
import ModalAddTraining from "./Components/ModalAddTraining/ModalAddTraining";
import {
  getCapacitaciones as getCapacitacionesService,
  getIndividualEmployeeByTraining as getIndividualCapacitacionesService,
  updateCapacitaciones as updateCapacitacionesService,
  postCapacitaciones as postCapacitacionesService,
} from "../../API/Analytics/empowerment";
import { EmployerContext } from "../../Store/EmployerContext";
import { RiLoader4Fill } from "react-icons/ri";
import Loading from "../../Components/Loading/Loading";

import "./Training.sass";

const REGEXHTTP=/^(http:\/\/|https:\/\/)/g

const B2EEntrenamiento = () => {
  const users = JSON.parse(localStorage.getItem("user"));
  const [show, setShow] = useState(false);
  const emptyObj = {
    courseName: "",
    courseDescription: "",
  };
  const [courses, setCourses] = useState([emptyObj]);

  const [newCourse, setNewCourse] = useState(emptyObj);

  const [capacitaciones, setCapacitaciones] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const { store } = useContext(EmployerContext);
  const [isLoadingNew, setIsLoadingNew] = useState(false);

  useEffect(() => {
    const fetchData = () => {
      userCapacitation();
    };
    fetchData();
  }, []);

  const userCapacitation = async () => {
    const user = JSON.parse(localStorage.getItem("user"));

    let capacitacionesList = [];

    const getCapacitacionesResponse = await getCapacitacionesService(user.id);

    const getIndividualCapacitacionesResponse = await getIndividualCapacitacionesService(user.id);

    const capacitationesDTO = getCapacitacionesResponse.map((item) => {
      return {
        id: item.id,
        name: item.employerCapacitationToCapacitation.name,
        link: item.employerCapacitationToCapacitation.link || '',
        advance: item.advance,
        description: "",
        employerId: item.employerId,
      };
    });

    capacitacionesList = capacitacionesList.concat(getIndividualCapacitacionesResponse);

    capacitacionesList = capacitacionesList.concat(capacitationesDTO);

    setCapacitaciones(
      capacitacionesList.map((item) => {
        return { ...item, advance_ref: item.advance };
      })
    );
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setNewCourse(emptyObj);
    setShow(true);
  };

  const handleChangeBar = (e, index) => {
    const { value } = e.target;
    var new_capacitaciones = [...capacitaciones];
    new_capacitaciones[index].advance = parseInt(value);
    setCapacitaciones(new_capacitaciones);
  };

  const saveCapacitacionHandler = async (index) => {
    setIsUpdating(true);
    let { id: capacitacion_id, advance } = capacitaciones[index];
    advance = advance === null ? 0 : advance;
    const updateResponse = await updateCapacitacionesService(capacitacion_id, {
      advance,
    });
    setIsUpdating(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewCourse({ ...newCourse, [name]: value });
  };

  const postCapacitation = async (newCourse) => {
    const auxSend = {
      name: newCourse.courseName,
      description: newCourse.courseDescription,
      link: "",
      employerId: users.id,
      advance: 0,
    };

    const res = await postCapacitacionesService(auxSend);

    if (res) {
      await userCapacitation();
      setIsLoadingNew(false);
    }
  };

  const handleAdd = async () => {
    const auxArray = [...courses];
    auxArray.push(newCourse);
    setIsLoadingNew(true);
    postCapacitation(newCourse);
    setCourses(auxArray);
    setShow(false);
  };

  const handleAlert = (e) => {
    alert("No hay resultados para mostrar!");
  };

  return (
    <div className="container-fluid color-grey training-main">
      {isLoadingNew && (
        <div className="loading">
          <Loading />
        </div>
      )}
      <ModalAddTraining
        show={show}
        handleClose={handleClose}
        handleAdd={handleAdd}
        handleChange={handleChange}
        data={newCourse}
      />
      <div className="row align-items-center">
        <div className="col-1">
          <span className="title-icon p-2 m-auto">
            <EntrenamientoIcon size={20} />
          </span>
        </div>
        <div className="col-8">
          <h1>{trainingsTranslate.myTrainings}</h1>
        </div>
        <div className="ms-auto col-3 text-end">
          <button className="btn training-add-btn align-items-center" onClick={handleShow}>
            {trainingsTranslate.createNewTraining} <AiOutlinePlusCircle size={18} />
          </button>
        </div>
        <div className="col-12">
          <div className="container">
            <RB.Accordion defaultActiveKey="0" className="py-3">
              <RB.Accordion.Item eventKey="0">
                <RB.Accordion.Header className="bg-training-header-nulinga">
                  <IdeaIcon size={35} color="#f4f4f4" />
                  {trainingsTranslate.englishTestTitle}
                </RB.Accordion.Header>
                <RB.Accordion.Body>
                  <div className="row align-items-center">
                    <div className="col-2 p-4">
                      <img
                        alt="..."
                        className="img-fluid"
                        src="https://nulinga.com/static/marketing/landings/images/pt_BR/nulinga-logo-violeta.svg"
                      />
                    </div>
                    <div className="col-6">
                      <p className="card-text">{trainingsTranslate.nulingaText}</p>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-2">
                      <a
                        className="btn training-btn-blue rounded-pill text-light"
                        href="https://nulinga.com/postulantes/new?token=x3w"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {btnTranslate.performTest}
                      </a>
                    </div>
                    <div className="col-2">
                      <button
                        onClick={handleAlert}
                        className="btn btn-secondary rounded-pill text-light"
                      >
                        {btnTranslate.showResult}
                      </button>
                    </div>
                  </div>
                </RB.Accordion.Body>
              </RB.Accordion.Item>
              {capacitaciones?.map((capacitacion, key) => {
                return (
                  <RB.Accordion.Item key={key} eventKey={key + 1} className="mt-3">
                    <RB.Accordion.Header>
                      <IdeaIcon size={35} color="#f4f4f4" /> {capacitacion.name}
                    </RB.Accordion.Header>
                    <RB.Accordion.Body>
                      <div className="row align-items-center justify-content-center">
                        <div className={capacitacion.link ? `col-9` : `col-12`}>
                          <span>Complete:</span>
                          <span className="float-end">
                            {capacitacion.advance === null ? 0 : capacitacion.advance}%
                          </span>
                          <input
                            type="range"
                            defaultValue={capacitacion.advance === null ? 0 : capacitacion.advance}
                            onChange={(e) => handleChangeBar(e, key)}
                            min={0}
                            max={100}
                            className="entrenamiento-range"
                            name="porcentageComplete"
                          />
                        </div>
                        {
                        capacitacion.link
                          &&  <div className="col-auto text-end">
                                <a className="btn training-link-btn rounded-pill" href={REGEXHTTP.test(capacitacion.link) ? capacitacion.link : `http://`+capacitacion.link} target="_blank" rel="noopener noreferrer">
                                    {trainingsTranslate.enterTrainingLink}
                                </a>
                              </div>
                        }
                        
                        <div className="col-12 pt-3">
                          <button
                            disabled={isUpdating}
                            className="btn btn-primary btn-sm btn-pill"
                            onClick={() => saveCapacitacionHandler(key)}
                          >
                            {isUpdating && <RiLoader4Fill className="spin" />}

                            <span className="px-2">{btnTranslate.saveButton}</span>
                          </button>
                        </div>
                      </div>
                    </RB.Accordion.Body>
                  </RB.Accordion.Item>
                );
              })}
            </RB.Accordion>
          </div>
        </div>

        <div className="col-12 pt-5">
          <Link className="button-volver color-blue" to={"/employee"}>
            <MdArrowBack size={20} />
            {btnTranslate.backButton}
          </Link>
        </div>
      </div>
    </div>
  );
};
export default B2EEntrenamiento;
