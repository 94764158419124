export const getListTraining = async (companyId) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API}/capacitation/company/${companyId}`,
            {
                method: "GET",
                mode: "cors",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                redirect: "follow",
                referrerPolicy: "no-referrer",
            }
        );
        return response.json();
    } catch (err) {
        console.error(err);
    }
};

export const postTraining = async (data) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API}/capacitation/`,
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-type": "application/json",
                },
            }
        );
        return response.json();
    } catch (err) {
        console.error(err);
    }
};

export const putTraining = async (trainingId, data) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API}/capacitation/${trainingId}`,
            {
                method: "PUT",
                body: JSON.stringify(data),
                headers: {
                    "Content-type": "application/json",
                },
            }
        );
        return response.json();
    } catch (err) {
        console.error(err);
    }
};

export const deleteTraining = async (trainingId) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API}/capacitation/${trainingId}`,
            {
                method: "DELETE",
                headers: {
                    "Content-type": "application/json",
                },
            }
        );

        return response.json();
    } catch (err) {
        console.error(err);
    }
};

export const setEmployeeTraining = async (data) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API}/employercapacitation`,
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-type": "application/json",
                },
            }
        );

        return response.json();
    } catch (err) {
        console.error(err);
    }
};

export const getEmployeeByTraining = async (id) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API}/employercapacitation/${id}`,
            {
                method: "GET",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.json();
    } catch (err) {
        console.error(err);
    }
};

