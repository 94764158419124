// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paybalance-input {
    background-color: white;
    margin: 10px 0;
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 5px;
    border: 1px solid;
    position: relative;
}

.paybalance-input .input {
    border: none;
    width: 100%;
    border-radius: 0;
    padding: 0;
    margin: 0;
    height: auto;
    font-size: 15px;
}

.paybalance-input label {
    position: absolute;
    top: -10px;
    padding: 0 5px;
    background: rgba(255, 255, 255, 1);
    font-size: 13px;
    text-transform: uppercase;
}
`, "",{"version":3,"sources":["webpack://./src/Views/Services/Components/PayBalance/Components/Input/input.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,gBAAgB;IAChB,UAAU;IACV,SAAS;IACT,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,cAAc;IACd,kCAAkC;IAClC,eAAe;IACf,yBAAyB;AAC7B","sourcesContent":[".paybalance-input {\r\n    background-color: white;\r\n    margin: 10px 0;\r\n    display: flex;\r\n    align-items: center;\r\n    padding: 12px;\r\n    border-radius: 5px;\r\n    border: 1px solid;\r\n    position: relative;\r\n}\r\n\r\n.paybalance-input .input {\r\n    border: none;\r\n    width: 100%;\r\n    border-radius: 0;\r\n    padding: 0;\r\n    margin: 0;\r\n    height: auto;\r\n    font-size: 15px;\r\n}\r\n\r\n.paybalance-input label {\r\n    position: absolute;\r\n    top: -10px;\r\n    padding: 0 5px;\r\n    background: rgba(255, 255, 255, 1);\r\n    font-size: 13px;\r\n    text-transform: uppercase;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
