import { useEffect, useState } from 'react'
import { Pill } from '../Employee/Pill/Pill'
import suggestion_pills from './mock/suggestion_pills.json'

export function Suggestions({ area, suggestName, onPillClick, language }) {
	const [filtByArea, setFiltByArea] = useState({
		title: '',
		suggestions: [],
	})

	const handlePillClick = (name, index) => {
		onPillClick(name, index)
	}
	useEffect(() => {
		const [filt] = suggestion_pills.filter(fe => fe.title === area)
		if (filt) {
			setFiltByArea(filt)
		}
	}, [area])

	return (
		<div className='border border-1 tw-rounded-lg tw-p-2 mb-2 mt-2'>
			<div className='tw-text-gray-400 tw-mb-2 tw-text-md'>Sugerencias de Nombre</div>
			<div className='tw-flex tw-flex-wrap'>
				{filtByArea?.suggestions?.map((e, i) => {
					return <Pill text={language === 'pt' ? e['pt-br'] : e.es} color={'tw-bg-purple-100'} onClick={() => handlePillClick(language === 'pt' ? e['pt-br'] : e.es, i)} />
				})}
			</div>
		</div>
	)
}
