import React, { useContext, useEffect, useState } from "react";

import { ServicesContext } from "../../../../../Store/ServicesContext";

import { Col, Button, Table, Form, Row } from "react-bootstrap";

import "./css/SalarioEmocional.css";
import {
	lateralMenuTextTranslate,
	salariesAndBenefitsTranslate,
} from "../../../../../i18n/translatedMessages";
import { FormattedMessage, useIntl } from "react-intl";
import { translate } from "../../../../../i18n/Library/emotionalSalary";

const SalarioEmocional = () => {
	const getLanguage = useIntl();
	const language = getLanguage.locale === "PT-BR" ? "pt" : "es";
	const { store, actions } = useContext(ServicesContext);
	const [emotional, setEmotional] = useState();
	const [posicionFiltro, setPosicionFiltro] = useState("1");

	const handlePosicion = (e) => {
		setPosicionFiltro(e.target.value);
	};

	useEffect(() => {
		actions.questionEmotional();
	}, []);

	const handleClick = async (e) => {
		const resp = await actions.salaryEmotional(e.target.value, "1");
		setEmotional(resp);
	};

	return (
		<>
			<div className="newLanding-main-content">
				<div id="new-landing-main-content">
					<div className="content-wrapper">
						<Row>
							<Col xs={12} sm={12} md={12}>
								<div className="d-flex align-items-center ml-5">
									<img
										className="custom-icon-guia-salarial"
										src="https://assets.mydna.company/b2b-images/pb/img/icons/azulesnumericos/Iconos-09.svg"
										alt="Icono Salario Emocional MyDna"
									/>
									<div className="d-flex flex-column justify-content-center px-4">
										<h3 className="content-wrapper-title">
											{
												lateralMenuTextTranslate.emotionalSalary
											}
										</h3>
										<h5 className="content-wrapper-subtitle">
											{
												salariesAndBenefitsTranslate.emotionalSalaryText
											}
										</h5>
									</div>
								</div>
							</Col>
						</Row>
						<Row className="mt-5">
							<Col xs={12} sm={12} md={12}>
								<Form.Group className="mb-3">
									<Form.Select
										className="custom-search-input-select"
										// onChange={(e) => handlePosicion(e)}
										onClick={(e) => handleClick(e)}
									>
										<FormattedMessage id="emotionalSalaryOptionSelect">
											{(msg) => <option>{msg}</option>}
										</FormattedMessage>
										{store.questionEmotional.map(
											(value, index) => {
												return (
													<option
														key={index}
														value={value.id}
													>
														{
															translate[language][
																value.title
															]
														}
													</option>
												);
											}
										)}
									</Form.Select>
								</Form.Group>
							</Col>
							<Col xs={6} sm={6} md={3}></Col>
						</Row>
						<div className="wrapper-beneficios">
							<Row>
								{emotional &&
									emotional.map((value, index) => {
										return (
											<>
												<Col xs={12} sm={12} md={4}>
													<div className="d-flex flex-column align-items-center justify-content-center px-4">
														<div className="contenedorIconoSalarioEmocional">
															<img
																className="iconoSalarioEmocional"
																src={`https://assets.mydna.company/b2b-images/pb/img/salarioemocional/${value.icon}.png`}
															/>
														</div>
														<p className="content-wrapper-title text-center">
															{
																translate[
																	language
																][value.title]
															}
														</p>
														<p className="content-wrapper-subtitle text-center">
															{
																translate[
																	language
																][
																	value
																		.description
																]
															}
														</p>
													</div>
												</Col>
											</>
										);
									})}
							</Row>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SalarioEmocional;
