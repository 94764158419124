import React, { useCallback, useEffect, useState } from "react";
import { BsFillFileEarmarkTextFill } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import { MdDelete, MdEdit } from "react-icons/md";
import { ModalTemplate } from "./ModalTemplate";
import { ListGroup } from "react-bootstrap";
import ModalShowEmployees from "./ModalShowEmployees";
import { ModalEditDocuments } from "./ModalEditDocuments";
import TemplatesService from "../../../../API/Documents/Template.api";

import "./AdmissionDocuments.sass";
import Loading from "../../../../Components/Loading/Loading";
import { documentsTranslate } from "../../../../i18n/translatedMessages";

const AdmissionDocuments = () => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalTemplate, setModalTemplate] = useState(null);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [idForEdit, setIdForEdit] = useState("");
  const [userId, setUserId] = useState("");
  const [isLoading, setLoading] = useState("");

  const loadTemplates = useCallback(async () => {
    try {
      setLoading(true);
      const response = await TemplatesService.getAllTemplates(
        JSON.parse(localStorage.getItem("user")).id
      );

      if (!response.hasOwnProperty("message")) {
        setTemplates(response);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const deleteTemplate = async (id) => {
    try {
      setLoading(true);
      await TemplatesService.deleteTemplate(id);
    } catch (err) {
      console.error(err);
    } finally {
      loadTemplates();
      setLoading(false);
    }
  };

  const handleModalTemplate = (id, title) => {
    setOpenEditModal(true);
    setModalTemplate({
      id,
      title,
    });
  };
  const handleModalEdit = (id) => {
    setShowModalEdit(true);
    setIdForEdit(id);
  };
  const handleModalCrear = (id) => {
    setShowModal(true);
    setUserId(id);
  };

  useEffect(() => {
    loadTemplates();
  }, [loadTemplates, showModal]);

  return (
    <>
      <ModalTemplate setShowModal={setShowModal} showModal={showModal} userId={userId} />
      <ModalEditDocuments
        setShowModal={setShowModalEdit}
        showModal={showModalEdit}
        documentsId={idForEdit}
      />
      {modalTemplate && (
        <ModalShowEmployees
          isOpen={openEditModal}
          onClose={setOpenEditModal}
          template={modalTemplate}
        />
      )}
      <div className="content mt-5 mt-lg-0">
        <div className="admission-documents">
          <div className="col offset-lg-3 offset-xl-1">
            <div className="row header">
              <div className="col d-flex mb-4">
                <div className="icon">
                  <BsFillFileEarmarkTextFill size={30} />
                </div>
                <h1>{documentsTranslate.documentsTitle}</h1>
              </div>
            </div>
            <div className="row description">
              {documentsTranslate.documentsDescription}
              {/* <div className=" border-bottom border-2  py-3" /> */}
            </div>
            {isLoading ? (
              <Loading />
            ) : (
              <div className="content">
                <div className="create-template row mt-5">
                  <h5 className="col">
                    <strong>{documentsTranslate.myTemplatesTitle}</strong>
                  </h5>
                  <div className="col-4 d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn button-blue"
                      onClick={() => handleModalCrear(JSON.parse(localStorage.getItem("user")).id)}
                    >
                      + {documentsTranslate.createTemplate}
                    </button>
                  </div>
                </div>

                {templates.length > 0 && (
                  <ListGroup className="custom-list-group mt-4">
                    {templates.map((template, index) => (
                      <div key={template.id}>
                        <ListGroup.Item className="d-flex justify-content-between align-items-center my-1">
                          <span>
                            {" "}
                            {index + 1} - {template.name}{" "}
                          </span>
                          <div>
                            <button
                              title="Ver Colaboardores"
                              className="btn border mx-1"
                              onClick={() => {
                                handleModalTemplate(template.id, template.name);
                              }}
                            >
                              <FaUsers />
                            </button>
                            <button
                              title="Editar Template"
                              className="btn border mx-1"
                              onClick={() => handleModalEdit(template.id)}
                            >
                              <MdEdit />
                            </button>
                            <button
                              title="Borrar Template"
                              className="btn btnDelete mx-1 delete"
                              onClick={() => {
                                deleteTemplate(template.id);
                              }}
                            >
                              <MdDelete />
                            </button>
                          </div>
                        </ListGroup.Item>
                      </div>
                    ))}
                  </ListGroup>
                )}
                {templates.length === 0 && (
                  <div className="empty-template mt-4">
                    <div className="p-3">{documentsTranslate.emptyTemplate}</div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdmissionDocuments;
