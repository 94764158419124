// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReceivedMessagesMain .whiteSpace {
  white-space: pre-wrap;
}
.ReceivedMessagesMain .read-or-hide {
  color: #2B97DB;
  cursor: pointer;
}
.ReceivedMessagesMain .btn-cards {
  background-color: #D9D9E2;
  color: #9391A1;
  display: inline-block;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/Views/Employee/ReceivedMessages.sass"],"names":[],"mappings":"AACI;EACI,qBAAA;AAAR;AACI;EACI,cAAA;EACA,eAAA;AACR;AAAI;EACI,yBAAA;EACA,cAAA;EACA,qBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;AAER","sourcesContent":[".ReceivedMessagesMain\n    .whiteSpace\n        white-space: pre-wrap\n    .read-or-hide\n        color: #2B97DB\n        cursor: pointer\n    .btn-cards\n        background-color: #D9D9E2\n        color: #9391A1\n        display: inline-block\n        height: 30px\n        width: 30px\n        border-radius: 50%"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
