// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail-page {
  height: calc(100vh - 62px);
  overflow: hidden;
  overflow-y: scroll;
}
.detail-page p {
  margin-top: 25px;
}
.detail-page p a {
  font-weight: 600;
  margin: 8px;
  text-transform: uppercase;
}
.detail-page ul {
  display: flex;
  list-style: none;
}
.detail-page ul .page-item {
  border: 2px solid #7a6be8;
  color: #7a6be8;
  padding: 3px 8px;
  margin: 0 5px;
  border-radius: 50rem;
  width: 230px;
  text-align: center;
  cursor: pointer;
}
.detail-page ul .active {
  color: white;
}
.detail-page .btn-back {
  width: 100%;
  background-color: transparent;
  border: none;
  margin-top: -45px;
  margin-bottom: 15px;
}
.detail-page .btn-back .item-circle {
  width: 40px;
  height: 40px;
  border-radius: 50rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid;
  color: #6e6e6e;
}
.detail-page .btn-back .item-circle svg {
  fill: #6e6e6e;
}

@media screen and (max-width: 767px) {
  .detail-page {
    text-align: center;
  }
  .detail-page p, .detail-page h3 {
    text-align: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/Views/Services/detailPage.sass"],"names":[],"mappings":"AAAA;EACI,0BAAA;EACA,gBAAA;EACA,kBAAA;AACJ;AACI;EACI,gBAAA;AACR;AAAQ;EACI,gBAAA;EACA,WAAA;EACA,yBAAA;AAEZ;AADI;EACI,aAAA;EACA,gBAAA;AAGR;AADQ;EACI,yBAAA;EACA,cAAA;EACA,gBAAA;EACA,aAAA;EACA,oBAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;AAGZ;AADQ;EACI,YAAA;AAGZ;AADI;EACI,WAAA;EACA,6BAAA;EACA,YAAA;EACA,iBAAA;EACA,mBAAA;AAGR;AADQ;EACI,WAAA;EACA,YAAA;EACA,oBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;EACA,cAAA;AAGZ;AADY;EACI,aAAA;AAGhB;;AADA;EACI;IACI,kBAAA;EAIN;EAHM;IACI,kBAAA;EAKV;AACF","sourcesContent":[".detail-page\n    height: calc(100vh - 62px)\n    overflow: hidden\n    overflow-y: scroll\n\n    p\n        margin-top: 25px\n        & a\n            font-weight: 600\n            margin: 8px\n            text-transform: uppercase\n    ul\n        display: flex\n        list-style: none\n\n        & .page-item\n            border: 2px solid #7a6be8\n            color: #7a6be8\n            padding: 3px 8px\n            margin: 0 5px\n            border-radius: 50rem\n            width: 230px\n            text-align: center\n            cursor: pointer\n\n        .active\n            color: white\n\n    & .btn-back\n        width: 100%\n        background-color: transparent\n        border: none\n        margin-top: -45px\n        margin-bottom: 15px\n\n        & .item-circle\n            width: 40px\n            height: 40px\n            border-radius: 50rem\n            display: flex\n            align-items: center\n            justify-content: center\n            border: 3px solid\n            color: #6e6e6e\n\n            & svg\n                fill: #6e6e6e\n\n@media screen and (max-width: 767px)\n    .detail-page\n        text-align: center\n        p, h3\n            text-align: center\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
