// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-wrapper {
    border-radius: 30px;
    width: 100%;
    min-height: 100px;
    padding: 30px 60px;
    margin-bottom: 50px;
}

.content-wrapper-title {
    color: #7a6be8;
    font-weight: bold;
}

.content-wrapper-subtitle {
    color: #909090;
}

.custom-modal-body {
    background-color: #ffffff !important;
    color: #222 !important;
}

.custom-search-input-select {
    border-radius: 20px;
    border: solid 2px #7a6be8;
}

.custom-icon-guia-salarial{
    width: 50px;
}`, "",{"version":3,"sources":["webpack://./src/Views/Services/Components/PayBalance/Views/css/Beneficios.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,WAAW;IACX,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,oCAAoC;IACpC,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,WAAW;AACf","sourcesContent":[".content-wrapper {\r\n    border-radius: 30px;\r\n    width: 100%;\r\n    min-height: 100px;\r\n    padding: 30px 60px;\r\n    margin-bottom: 50px;\r\n}\r\n\r\n.content-wrapper-title {\r\n    color: #7a6be8;\r\n    font-weight: bold;\r\n}\r\n\r\n.content-wrapper-subtitle {\r\n    color: #909090;\r\n}\r\n\r\n.custom-modal-body {\r\n    background-color: #ffffff !important;\r\n    color: #222 !important;\r\n}\r\n\r\n.custom-search-input-select {\r\n    border-radius: 20px;\r\n    border: solid 2px #7a6be8;\r\n}\r\n\r\n.custom-icon-guia-salarial{\r\n    width: 50px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
