class TemplatesService {
    
    async createTemplates (data) {
        const response = await fetch(`${process.env.REACT_APP_API}/documents/template`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(data),
        })

        return response.json()
    }

    async getAllTemplates (id) {
        const response = await fetch(`${process.env.REACT_APP_API}/documents/template/${id}`, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
            },
        })

        return response.json()
    }

    async deleteTemplate (id) {
        const response = await fetch(`${process.env.REACT_APP_API}/documents/template/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-type': 'application/json',
            },
        })

        return response.json()
    }
}
export default new TemplatesService();