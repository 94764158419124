import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { EmployerContext } from '../../Store/EmployerContext'
import { BsMegaphone } from 'react-icons/bs'
import { BiChat } from 'react-icons/bi'
import { FaToolbox } from 'react-icons/fa'
import WheelColaboradores from './Components/Wheel/WheelColaboradores'
import { getDataCompany, getAssetsCompany } from '../../API/Services/Company/Company'
import { getEmployee, getAssetsEmployee } from '../../API/Employee/Employee'
import { getEmployeeInformation } from '../../API/Analytics/employers'
import ModalEditLinks from './Components/ModalEditLinks/ModalEditLinks'
import Loading from '../../Components/Loading/Loading'
import { editProfile } from '../../API/Employee/Profile'
import { internalChannel, internalVacancies } from '../../i18n/translatedMessages'

const FilePersona = () => {
	const user = JSON.parse(localStorage.getItem('user'))
	const { actions } = useContext(EmployerContext)
	const [loading, setLoading] = useState(false)
	const [companyImage, setCompanyImage] = useState('')
	const [employeeImage, setEmployeeImage] = useState('')
	const [employee, setEmployee] = useState()
	const [company, setCompany] = useState()

	const profileEmpty = {
		employerId: user.id,
		mailPersonal: '',
		phone: '',
		document: '',
		birth: '',
		linkedIn: '',
		facebook: '',
		instagram: '',
		skype: '',
		twitter: '',
		gender: '',
		maritalStatus: '',
		childrens: '',
		city: '',
		country: '',
		address: '',
		nationality: '',
		transport: '',
		contract: '',
		boss: '',
		bank: '',
		currentAccount: '',
		healthPlan: '',
		height: 0,
		weight: 0,
		enfermedad: '',
		pcd: '',
		typePCD: '',
		hobies: '',
		sports: '',
		interests: '',
		voluntaryWork: '',
	}

	const [employeeInfo, setEmployeeInfo] = useState(profileEmpty)

	const [showModalEditLinks, setShowModalEditLinks] = useState(false)

	useEffect(() => {
		const requests = async () => {
			setLoading(true)
			const empAux = await getEmployee(user.id)
			const compImgAux = await getAssetsCompany(user.companyId)
			const empImageAux = await getAssetsEmployee(user.id)
			const compDataAux = await getDataCompany(user.companyId)
			const [compEmpInfoAux] = await getEmployeeInformation(user.id)
			setEmployeeInfo(compEmpInfoAux)
			setCompany(compDataAux)
			setEmployeeImage(empImageAux[empImageAux.length - 1]?.url)
			localStorage.setItem('image', [empImageAux[empImageAux.length - 1]?.url])
			setCompanyImage(compImgAux[compImgAux.length - 1]?.url)
			setEmployee(empAux)
			setLoading(false)
		}
		requests()
	}, [])

	const handleMenu = event => {
		actions.setOptions(event.currentTarget.dataset.option)
	}
	const handleCloseModalEditLinks = () => {
		setShowModalEditLinks(false)
	}
	const handleShowModalEditLinks = () => {
		setShowModalEditLinks(true)
	}
	const handleChange = event => {
		setEmployeeInfo({
			...employeeInfo,
			[event.target.name]: event.target.value,
		})
	}

	const handleSend = async () => {
		if (employeeInfo.id) {
			await editProfile(employeeInfo, user.id)
			setShowModalEditLinks(false)
		} else {
			setShowModalEditLinks(false)
		}
	}

	return (
		<div className='container-fluid'>
			{loading ? (
				<Loading />
			) : (
				<>
					<div className='row'>
						<div className='col-md-5'>
							<h3>{user.name}</h3>
							<h5 className='color-grey'>{employee?.employersJob.name}</h5>
						</div>
						<div className='offset-md-5 col-md-2 text-center'>
							<img className='img-fluid wheelEmployeePage-companyLogo mb-3' src={companyImage || 'https://assets.mydna.company/mydnalogo.png'} alt='...' />
							<ul className='list-group wheelEmployeePage-list list-group-horizontal justify-content-center align-items-center'>
								<li className='list-group-item p-0 pe-2 border-0'>
									<a href={company?.instagram ? company?.instagram : null} target='_blank' rel='noreferrer'>
										<img
											className='img-fluid'
											alt=''
											src={company?.instagram ? 'https://assets.mydna.company/b2b-images/employeeWheelScreen-icon_instagram.svg' : 'https://assets.mydna.company/b2b-images/employeeWheelScreen-icon-no_instagram.svg'}
										/>
									</a>
								</li>
								<li className='list-group-item py-0 px-2 border-0'>
									<a href={company?.linkedin ? company?.linkedin : null} target='_blank' rel='noreferrer'>
										<img
											className='img-fluid'
											alt=''
											src={company?.linkedin ? 'https://assets.mydna.company/b2b-images/employeeWheelScreen-icon_linkedin.svg' : 'https://assets.mydna.company/b2b-images/employeeWheelScreen-icon-no_linkedin.svg'}
										/>
									</a>
								</li>
								<li className='list-group-item py-0 px-2 border-0'>
									<a href={company?.whatsapp ? company?.whatsapp : null} target='_blank' rel='noreferrer'>
										<img
											className='img-fluid'
											alt=''
											src={company?.whatsapp ? 'https://assets.mydna.company/b2b-images/employeeWheelScreen-icon_whatsapp.svg' : 'https://assets.mydna.company/b2b-images/employeeWheelScreen-icon-no_whatsapp.svg'}
										/>
									</a>
								</li>
								<li className='list-group-item py-0 px-2 border-0'>
									<a href={company?.email ? 'mailto:' + company?.email : null} target='_blank' rel='noreferrer'>
										<img
											className='img-fluid'
											alt=''
											src={company?.email ? 'https://assets.mydna.company/b2b-images/employeeWheelScreen-icon_email.svg' : 'https://assets.mydna.company/b2b-images/employeeWheelScreen-icon-no_email.svg'}
										/>
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div className='row justify-content-center wheelEmployeePage-wheel'>
						<div className='col-md-8'>
							<WheelColaboradores handleMenu={handleMenu} employeeImg={employeeImage} />
						</div>
					</div>
					<div className='row wheelEmployeePage-last-row-adjust'>
						<div className='col-md-2'>
							<ul className='list-group wheelEmployeePage-list'>
								<li className='list-group-item px-0 py-0 border-0'>
									<ModalEditLinks show={showModalEditLinks} handleShow={handleShowModalEditLinks} handleClose={handleCloseModalEditLinks} data={employeeInfo} onChange={handleChange} handleSend={handleSend} />
								</li>
								<li className='list-group-item px-0 pb-2 border-0'>
									<a href={employeeInfo?.instagram ? employeeInfo?.instagram : null} target='_blank' rel='noreferrer'>
										<img
											className='img-fluid'
											alt=''
											src={employeeInfo?.instagram ? 'https://assets.mydna.company/b2b-images/employeeWheelScreen-icon_instagram.svg' : 'https://assets.mydna.company/b2b-images/employeeWheelScreen-icon-no_instagram.svg'}
										/>{' '}
										{employeeInfo?.instagram}
									</a>
								</li>
								<li className='list-group-item px-0 py-2 border-0'>
									<a href={employeeInfo?.linkedIn ? employeeInfo?.linkedIn : null} target='_blank' rel='noreferrer'>
										<img
											className='img-fluid'
											alt=''
											src={employeeInfo?.linkedIn ? 'https://assets.mydna.company/b2b-images/employeeWheelScreen-icon_linkedin.svg' : 'https://assets.mydna.company/b2b-images/employeeWheelScreen-icon-no_linkedin.svg'}
										/>{' '}
										{employeeInfo?.linkedIn}
									</a>
								</li>
								<li className='list-group-item px-0 py-2 border-0'>
									<a href={employeeInfo?.phone ? employeeInfo?.phone : null} target='_blank' rel='noreferrer'>
										<img
											className='img-fluid'
											alt=''
											src={employeeInfo?.phone ? 'https://assets.mydna.company/b2b-images/employeeWheelScreen-icon_whatsapp.svg' : 'https://assets.mydna.company/b2b-images/employeeWheelScreen-icon-no_whatsapp.svg'}
										/>{' '}
										{employeeInfo?.phone}
									</a>
								</li>
								<li className='list-group-item px-0 py-2 border-0'>
									<a href={employeeInfo?.mailPersonal ? 'mailto:' + employeeInfo?.mailPersonal : null} target='_blank' rel='noreferrer'>
										<img
											className='img-fluid'
											alt=''
											src={employeeInfo?.mailPersonal ? 'https://assets.mydna.company/b2b-images/employeeWheelScreen-icon_email.svg' : 'https://assets.mydna.company/b2b-images/employeeWheelScreen-icon-no_email.svg'}
										/>{' '}
										{employeeInfo?.mailPersonal}
									</a>
								</li>
							</ul>
						</div>
						<div className='offset-md-5 col-md-5 text-end'>
							<div className='py-3'>
								<Link className='btn-internal-vancancies py-2' to='/employee/internalvacancies'>
									<FaToolbox size={25} /> {internalVacancies.internalVacanciesTitle}
								</Link>
							</div>
							<div className='py-3'>
								<Link className='btn-internal-comunication py-2' to='/employee/internal-messages'>
									<BiChat size={30} /> {internalChannel.internalChannelTitle}
								</Link>
							</div>
							<div className='py-3'>
								<Link className='btn-canal-denuncia py-2' to='/employee/canaldenuncia'>
									<BsMegaphone size={25} /> Canal de denuncia
								</Link>
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	)
}
export default FilePersona
