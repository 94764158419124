import { LOCALES } from '../locales'

export const portuguese_messages = {
	[LOCALES.PORTUGUESE]: {
		//loading
		loadingP1: 'carre',
		loadingP2: 'gando',

		//Buttons
		saveButton: 'Salvar',
		closeButton: 'Fechar',
		backButton: 'Voltar',
		nextButton: 'Seguinte',
		createButton: 'Criar',
		accessButton: 'Acessar',
		performTest: 'REALIZAR TESTE',
		showResult: 'VER RESULTADO',
		deleteButton: 'Deletar',
		cancelButton: 'Cancelar',
		yesText: 'Sim',
		noText: 'Não',
		selectOption0: 'Selecionar...',
		clickHere: 'Clique aqui',
		selectAllLabel: 'Selecionar todos',

		//Modal
		confirmDelete: 'Confirmar Exclusão',
		successAlert: 'Sucesso!',
		errorAlert: 'Ocorreu um erro!',
		contactAlert: 'Nossa equipe entrará em contato com você o mais breve possível!',

		//FormattedMessages
		country: 'País',
		date: 'Data',
		city: 'Cidade',
		industry: 'Indústria',
		area: 'Área',
		role: 'Cargo',
		professionalResponsableTitle: 'Designar um Colaborador',
		noOcurrences: 'Todavia no hay ocurrencias',
		complaintDetail: 'Detalhe sua denúncia',
		collaboratorCountryOption: 'País do cargo do colaborador',
		industrySelect: 'Selecione uma Indústria',
		areaSelect: 'Selecione uma Área',
		positionLabel: 'Posição',
		positionSelect: 'Selecione uma Posição',
		optionSelect: 'Selecione uma Opção',
		englishLabel: 'Inglês',
		indifferentOption: 'Indiferente',
		smallOption: 'Pequena',
		mediumOption: 'Média',
		largeOption: 'Grande',
		basicLevelOption: 'Básico',
		mediumLevelOption: 'Médio',
		advancedLevelOption: 'Avançado',
		roleOption: 'Nível',
		roleJunior: 'Júnior',
		rolePleno: 'Pleno',
		roleSenior: 'Sênior',
		educationLabel: 'Educação',
		graduationOption: 'Graduação',
		salaryOption: 'Salário',
		salaryInput: 'Digite o salário',
		bonusLabel: 'Bônus',
		bonusInput: 'Bônus Anual',
		clickHereLink: 'Clique aqui para saber mais',
		invalidValue: 'Valor inválido',
		inProcess: 'Processando',
		belowRange: 'Abaixo da banda',
		firstQuartile: 'No 1° Quartil',
		secQuartile: 'No 2° Quartil',
		thirdQuartile: 'No 3° Quartil',
		fourthQuartile: 'No 4° Quartil',
		aboveRange: 'Sobre a banda',
		searchTranslate: 'Buscar',

		//EmptyInfo
		incompleteTest: 'O Colaborador ainda não realizou teste',
		incompleteProfile: 'O Colaborar ainda não completou o perfil',
		emptyDesempeno: 'Ainda nao há uma avaliacao disponível.',

		//Login Screen
		textLogin: 'Faça o login com seu endereço de e-mail',
		createAccount: 'Criar nova conta',
		login: 'Iniciar Sessão',
		errorUserPass: 'Nome de Usuário ou Senha incorreta!',
		passPH: 'Senha',

		//Card Content
		customerRating: 'Avaliação dos Clientes',

		mainMenuFeed: 'Feed',
		mainMenuProfile: 'Perfil',
		mainMenuMoment: 'Momento',
		mainMenuVacancies: 'Vagas',
		mainMenuCompany: 'Para Empresas',
		mainMenuSearch: 'Buscar pessoas e empresas',
		mainMenuSeeMore: 'Ver mais',
		dropdownCompany: 'Minha Empresa',
		dropdownLogout: 'Sair',
		dropdownProfile: 'Editar Perfil',
		searchByName: 'Buscar por Nome',

		// Body Text
		textServices: 'Serviços de ',
		textCertified: ' certificados por MyDNA',
		ourProducts: 'Nossos Produtos:',
		customerEvaluation: 'Avaliação de Clientes',
		apresentationDown: 'Baixar Apresentação',

		// HeaderText
		myPartnes: 'Partnes',

		/** WheelCompany */
		seeMore: 'Ver mais',
		myBusiness: 'Minha Empresa',
		peopleAnalytics: 'People Analytics',
		myCollaborators: 'Meus Colaboradores',
		collaboratorsRegister: 'Registro de Colaborador',
		massiveRegister: 'Registro Massivo',
		mySolutions: 'Minhas Soluções',

		onBoarding: 'Onboarding',
		knowBadgeHeroes: 'Conheça Badge Heroes',
		onBoardingRegister: 'Registrar Onboarding',

		trainingAndDevelopment: 'Treinamentos',
		manageTrainings: 'Gerenciar Treinamentos',
		workoutsMydna: 'Treinamentos by MyDNA',
		ebooksAndContent: 'Ebooks & Conteudos',

		climeAndCulture: 'Clima & Cultura',

		cultureSurvey: 'Pesquisa de Cultura',
		cultureSurveyText: 'A avaliação da cultura fornece três informações-chave: qual é sua cultura, qual é a cultura da empresa e, portanto, qual é a correspondência entre as duas. Clique no botão e selecione as melhores respostas!',
		climeSurvey: 'Pesquisa de Clima',
		climeSurveyText:
			'Faça sua voz ser ouvida! Aqui você pode dizer à sua empresa qual é a sua percepção do clima em sua organização. Será divertido, gratificante e levará apenas alguns minutos, e gerará muito valor para todos - você em primeiro lugar e acima de tudo. Clique no botão e deixe sua opinião!',
		nps: 'NPS',
		businessCulture: 'Cultura Empresarial',

		performanceAnalysis: 'Desempenho e Potencial',
		performanceEvaluation: 'Avaliação de Desempenho',

		salariesAndBenefits: 'Salários & Benefícios',
		unitReport: 'Relatório Unitário',
		knowPaybalance: 'Conheça Paybalance',
		goPaybalance: 'Acesse Paybalance',

		professionalDevelopment: 'Assessment Center',
		motivacionalTest: 'Teste de Motivações',
		knowMyco: 'Conheça Myco',
		skillTest: 'Teste de Habilidades',
		englishLevelTest: 'Teste Nível de Inglês',
		englishClass: 'Classes de Inglês',

		careerPlan: 'Plano de Carreira',
		careerProjection: 'Projeção de Carreira',
		jobDescriptor: 'Descrição de Cargos',
		jobDescriptorNullTitle: 'Cargo sem Descrição!',
		jobDescriptorNullText: 'O cargo não possue uma descrição.',
		jobDescriptorNullText2: 'Certifique-se de que o nível hierárquico e a subárea estejam corretos ou registre manualmente uma descrição para esse cargo',
		jobDescriptorBack: 'Voltar para Meus Colaboradores',
		EEjobDescriptorNullTitle: 'Cargo sem Descrição!',
		EEjobDescriptorNullText: 'Não encontramos uma descrição para a combinação informada.',
		EEjobDescriptorNullText2: 'Certifique-se de que o nível de reporte e a subárea estejam corretos ou crie manualmente uma descrição para essa posição',
		EEjobDescriptorNullRegister: 'Registrar sem descrição',
		EEjobDescriptorBack: 'Voltar para Informações do Profissional',

		ombudsman: 'Ombudsman',

		outplacement: 'Plano de Saída',
		knowGenes: 'Sobre MyDNA Outplacement',
		realocation: 'Registro Outplacement',
		leaveSearch: 'Pesquisa de Saída',
		posicionesTitle: 'Salários por Colaborador',
		bands: 'Banda Salarial',
		benefits: 'Benefícios',
		emotionalSalary: 'Salário Emocional',

		activeService: 'Ativar Serviço',

		//WheelCollaborator
		professionalHistoric: 'Histórico Profissional',
		purpose: 'Propósito',
		formation: 'Formação',
		culture: 'Cultura',
		salary: 'Salário',
		habilities: 'Competências',
		motivacions: 'Motivações',
		performance: 'Desempenho',
		training: 'Treinamentos',
		aData: 'até o momento',
		functionsTitle: 'Funções',
		achievementsTitle: 'Conquistas',
		currentJobTitle: 'Trabalho atual',
		functionsDescriptions: 'Descreva aqui suas funçoes',
		achievementsDescriptions: 'Descreva aqui suas conquistas',
		editProfile: 'Editar Perfil',
		documentsTitle: 'Documentos',

		//Professional Historic
		collaboratorTrajectory: 'Trajetória do Colaborador',
		experienceTime: 'Tempo de Experiência',
		yearsAnd: 'Anos e',
		months: 'Meses',
		workedIndustries: 'Setores da Indústria em que Trabalhou',
		workedPositions: 'Cargos Ocupados',

		//Proposito
		purposeToWork: 'Os Propósitos de trabalho são',

		//Formation
		education: 'Educação',
		englishLevel: 'Nível de Inglês',

		//English Line Level
		englishNulo: 'Nulo',
		englishBasic: 'Básico',
		englishIntermedio: 'Intermedio',
		englishAvanzado: 'Avançado',
		englishFlued: 'Fluente',

		//Culture
		commercial: 'Comercial',
		commercialText:
			'Alinha-se melhor em empresas com as seguintes características: Orientação para o alcance de resultados. O ambiente, em parte, motiva os funcionários a cumprir as metas. O estilo de liderança motiva, mas você pode colocar mais agressividade comercial. Há competitividade na equipe, mas pode ser melhorada. As relações com os clientes são estabelecidas, mas nem sempre de longo prazo. Observa-se na equipe uma maior satisfação por conquistar e conquistar o mercado. Há desafios para crescer, mas você pode ser mais ambicioso.',
		disruptive: 'Disruptiva',
		disruptiveText:
			'Alinha-se melhor com empresas com as seguintes características: Estilo de organização mais inovador, ouvindo e trabalhando mais em equipe. Está planejado mais a longo prazo. A liderança é mais motivadora, mais arriscada, mais empreendedora. As equipes começam a ser mais visionárias e com fome de sair da caixa. Há maior tolerância à diversidade e às diferenças entre as equipes. A expressão liberdade de fazer começa a ser mais ouvida.',
		collaborative: 'Colaborativa',
		collaborativeText:
			'É o melhor classificado em empresas com as seguintes características: Estilo organizacional pouco estruturado. Tem uma estrutura piramidal, onde as decisões são tomadas por poucos. A comunicação das decisões ou estratégia é lenta, conhecida por poucos e geralmente cabeças. Alguns protocolos e procedimentos estão disponíveis. Procura-se estabilidade e permanência ao longo do tempo. A eficiência é um pilar para a tomada de decisões.',
		traditional: 'Tradicional',
		traditionalText:
			'Alinha-se melhor com empresas com as seguintes características: Estilo organizacional com foco nas pessoas. Preocupado em gerar um ambiente de trabalho agradável e próximo. O desenvolvimento profissional faz parte dos desafios permanentes. Liderança incentivando o trabalho em equipe. Há alta comunicação entre gestores e colaboradores. A estratégia é conhecida de todos. Equipes alinhadas e comprometidas com a empresa.',
		culturePreview: 'Você tem o seguinte Perfil Cultural',
		cultureNoAnswer: 'O colaborador selecionado ainda não respondeu a pesquisa de cultura',

		//Salario
		actualSalary: 'Salário Atual',
		monthSalary: 'Salário Mensal',
		annualSalary: 'Salário Anual',
		fixedSalary: 'Fixo Mensal',
		variableAnualSalary: 'Variável Anual',
		titlePosiciones: 'Salários por Colaborador',

		//Career Plan
		hierarchicalLevel: 'Nível Hierárquico',
		nextLevel: 'Próximo Nível (recomendado)',
		actualLevel: 'Nível Atual -',
		possibleAreas: 'Possibilidades de Trocas de Áreas',
		collaboratorTextP1: 'Colaboradores com experiência na área de',
		collaboratorTextP2: 'podem possuir conhecimentos e habilidades para trabalhar na área de',
		director: 'Diretor',
		manager: 'Gerente',
		submanager: 'Subgerente',
		boss: 'Coordenador',
		specialist: 'Especialista',
		analist: 'Analista',
		technical: 'Operário / Técnico / Assist.',

		//Onboarding
		collStatus: 'Em Progresso',
		assignStatus1: 'Tem',
		assignStatus2: 'registrado(s)',
		collEmpty: 'Não há colaboradores cadastrados, cadastre seus colaboradores para começar',
		responsable: 'Responsável',
		registerResponsable: 'REGISTRAR NOVO RESPONSÁVEL',
		registerCollaborator: 'REGISTRAR NOVO COLABORADOR',

		onboardingAlertAnswer: 'Não há respostas',

		statusOnboardingQuestion1: 'Segue em Onboarding?',
		statusOnboardingQuestion2: 'Como se sente em relacao ao processo?',
		statusOnboardingQuestion3: 'Quantidade de etapas feitas?',
		statusOnboardingQuestion4: 'Esta ativo no programa?',
		statusOnboardingQuestion5: 'Em quanto tempo termina o Onboarding?',
		statusOnboardingQuestion6: 'Acredita que o programa de Onboarding esta ajudando?',
		statusOnboardingQuestion7: 'Avanço Geral do Programa',

		statusOnboardingAnswerYes: 'Sim',
		statusOnboardingAnswerNo: 'Não',

		//Capacitations
		capacitationTitle: 'Capacitação',
		capacitationCol: 'Treinamentos',
		capacitationAreaCol: 'Área',
		capacitationProgress: 'Progresso',
		capacitationsTitle: 'Capacitações',
		capactitationSearchPlaceHolder: 'Buscar curso por nome e/ou palavra-chave',
		capacitationChooseArea: 'Escolha a área de Capacitação',
		newCourse: 'Criar Novo Curso',
		capacitationCourse: 'Cursos',
		capacitationArea: 'Área',
		capacitationEmpty: 'Nenhuma capacitação registrada',

		//Trainnings
		trainningTitle: 'Treinamentos by Mydna',

		//Trainning List
		//English
		trainningCardTitleEnglish: 'Aulas de Inglês',
		trainningCardTextEnglish: 'Aulas de Inglês para seus colaboradores',
		//Comercial & Selling
		trainningCardTitleComercialSelling: 'Treinamento Pitch Comercial & Vendas',
		trainningCardTextComercialSelling: 'Treinamento Pitch Comercial & Vendas para seus colaboradores.',
		//Gamefied Trainning
		trainningCardTitleGamefied: 'Ferramenta de Treinamento Gameficado',
		trainningCardTextGamefied: 'Crie seu próprio treinamento personalizado. Entregamos uma experiência totalmente gamificada aos seus colaboradores.',

		//Empy Content Book
		empyContentText: 'Ainda não há conteúdo para ser exibido.',

		//DashBoard Clime
		dashboardClimeTitle: 'DashBoard - Pesquisa de Clima',
		dashboardClimeText:
			'A avaliação do clima organizacional é como tomar o pulso emocional de uma empresa: revela a temperatura emocional e psicológica do ambiente de trabalho. Através da pesquisa, são coletados dados para compreender as percepções e experiências dos funcionários. Esse processo fornece informações valiosas que permitem aos líderes identificar áreas de melhoria e promover um ambiente de trabalho saudável e produtivo. É uma ferramenta fundamental para cultivar a satisfação, o engajamento e o bem-estar dos funcionários',
		emptyInformationClime: 'Nenhuma Pesquisa de Clima foi respondida ainda',
		textInformationBusinessCulture: 'Ter candidatos alinhados com a cultura da sua empresa melhora a felicidade dos funcionários, reduzindo a rotatividade.',
		textInformationBusinessCulture2: 'Responda as perguntas sobre cultura, carreira, liderança e interesses e você saberá qual é a cultura empresarial da sua empresa.',

		//BusinessCultureQuestions
		//Cultural
		businessCulturalQ1: 'Somos reconhecidos pela forma como reciclamos, cuidamos do meio ambiente e fazemos uso inteligente dos recursos',
		businessCulturalQ2: 'Costumamos nos vestir e nos expressar de maneira formal.',
		businessCulturalQ3: 'Temos 50% de homens e 50% de mulheres em posições de liderança na linha de frente',
		businessCulturalQ4: 'Temos diretores e/ou executivos seniors abertamente gays',
		businessCulturalQ5: 'Somos reconhecidos como uma organização altamente inovadora',
		businessCulturalQ6: 'Temos números de telefone e e-mails para que os funcionários possam fazer reclamações de forma confidencial',

		//Career
		businessCareerQ1: 'Para ter sucesso aqui, você tem que ser competitivo.',
		businessCareerQ2: 'Procuramos pessoas que valorizem mais o seu desenvolvimento profissional do que uma alta remuneração financeira',
		businessCareerQ3: 'Com alta performance, as pessoas assumem rapidamente posições de poder (a cada 2 anos uma promoção)',
		businessCareerQ4: 'Para ter sucesso aqui, você deve ser politicamente correto na forma como apresenta seus pontos de vista.',
		businessCareerQ5: 'Usar tatuagens, brincos ou sua aparência física pode tornar um pouco mais difícil para você alcançar posições de alto nível.',
		businessCareerQ6: 'Ter conversas sobre desenvolvimento de carreira com funcionários não é uma prioridade no momento',

		//Leader
		businessLeaderQ1: 'Nossos cargos executivos ou gerenciais contam com vagas privilegiadas ou especiais de estacionamento',
		businessLeaderQ2: 'Se estivermos sob pressão, a comunicação às vezes pode se tornar mais difícil',
		businessLeaderQ3: 'Nosso Gerente Geral e Diretor não é reconhecido por estar próximo das pessoas',
		businessLeaderQ4: 'Liderança é o tema mais importante em nossa estratégia corporativa',
		businessLeaderQ5: 'Nossas equipes são tão apaixonadas que às vezes trabalham mais horas do que o normal',
		businessLeaderQ6: 'Faz parte da nossa cultura que os gerentes peçam feedback às suas equipes sobre como melhorar sua liderança e gestão.',

		//Interests
		businessInterestsQ1: 'Os sindicatos são um ator-chave para ouvir os funcionários',
		businessInterestsQ2: 'Temos horário flexível (escolha o horário de entrada e saída)',
		businessInterestsQ3: 'Temos sextas-feiras curtas (sair mais cedo às sextas-feiras)',
		businessInterestsQ4: 'Concedemos dias de folga além do legal se nossos funcionários se casarem ou celebrarem um Acordo de União Civil',
		businessInterestsQ5: 'Somos reconhecidos por oferecer benefícios melhores do que os do mercado para mulheres no pré e pós-natal',
		businessInterestsQ6: 'Apoiamos as comunidades desportivas dos nossos colaboradores',

		//Promoter Score
		netPromoteScoreTitle: 'Net Promote Score',
		detractorTitle: 'Detratores',
		detractorText:
			'Os que não estão satisfeitos. Qualquer um que não se qualifique acima, provavelmente, tem algo para reclamar. Além disso, eles até tendem a falar mal do empresa para seus contatos. Sem falar que não são fiéis, a qualquer momento podem definitivamentedeixar a empresa.',
		neutralTitle: 'Neutros',
		neutralText:
			'Eles não podem ser considerados insatisfeitos, mas não há garantia de que serão leais. Eles provavelmente têm algum ponto a melhorar, mas isso não significa que eles queiram ou pensem em sair da empresa. Ao mesmo tempo, eles não são perfeitamente leais, o que pode transformá-los em detratores, se algo acontecer ou perceberem que podem melhorar em outro lugar.',
		promotorTitle: 'Promotores',
		promotorText: 'São os mais leais, com tendência a recomendar a empresa. Embora a nota 9 indique que algo pode ser melhorado, não compromete a lealdade.',

		//Culture Business
		businessCultureTitle: 'Cultura Empresarial',
		asksTimelineText: 'Marque o que você planeja em sua vida profissional e o que você pensa sobre sua última/atual empresa',
		//Timeline
		cbTimelineStart: 'Início',
		cbTimeLineQuestions: 'Perguntas de Cultura',
		cbTimeLineCareer: 'Perguntas de Carreira',
		cbTimeLineLeader: 'Perguntas de Liderança',
		cbTimeLineInterests: 'Perguntas de Interesses',
		cbTimeLineReportDelivery: 'Entrega de Resultado',

		//TimeLine Finished
		cbTimeLineAswer: 'Sua Cultura Empresarial é',
		cbTimeLineComercial: 'Comercial',
		cbTimeLineComercialDesc:
			'Orientação para alcançar resultados. O ambiente, em parte, motiva os funcionários a cumprir as metas. Pode melhorar. O estilo de liderança motiva, mas você pode colocar mais agressividade comercial. Há competitividade na equipe, mas pode ser melhorada. As relações com os clientes são estabelecidas, mas nem sempre de longo prazo. Observa-se na equipe uma maior satisfação por conquistar e conquistar o mercado. Há desafios para crescer, mas você pode ser mais ambicioso.',
		cbTimeLineDisruptive: 'Distuptiva',
		cbTimeLineDisruptiveDec:
			"Estilo organizacional mais inovador, ouvindo e trabalhando mais em equipe. Está planejado mais a longo prazo. A liderança é mais motivadora, mais arriscada, mais empreendedora. As equipes começam a ser mais visionárias e com fome de sair da caixa. Há maior tolerância à diversidade e às diferenças entre as equipes. A expressão 'liberdade de fazer' está começando a ser mais ouvida.",
		cbTimeLineColaborate: 'Colaborativa',
		cbTimeLineColaborateDesc:
			'Estilo organizacional com foco nas pessoas. Preocupado em gerar um ambiente de trabalho agradável e próximo. O desenvolvimento profissional faz parte dos desafios permanentes. Liderança incentivando o trabalho em equipe. Há alta comunicação entre gestores e colaboradores. A estratégia é conhecida de todos. Equipes alinhadas e comprometidas com a empresa.',
		cbTimeLineTraditional: 'Tradicional',
		cbTimeLineTraditionalDesc:
			'Estilo organizacional pouco estruturado. Tem uma estrutura piramidal, onde as decisões são tomadas por poucos. A comunicação das decisões ou estratégia é lenta, conhecida por poucos e geralmente cabeças. Alguns protocolos e procedimentos estão disponíveis. Procura-se estabilidade e permanência ao longo do tempo. A eficiência é um pilar para a tomada de decisões.',

		//EmployerPerformance
		employerPerformanceTitle: 'Avaliação de Desempenho',
		employersShowCollumTitle: 'Colaboradores',
		employerCriterionCollumTitle: 'Critério',
		employerWeigthCollumTitle: 'Peso',
		employerExecutedCollumTitle: '% Executada',
		employeeSugests: 'Sugestões',
		employeeObservations: 'Observações',

		//ShowMeTheMoney
		salaryConsultTitle: 'Consultoria Salarial Personalizada',
		salaryConsultText: 'Preencha as informações a seguir e forneceremos um relatório adaptado aos salários de seus colaboradores.',
		salaryConsultErrorEmptyFields: 'ERRO! Certifique-se de que todos os campos estão preenchidos.',
		salaryConsultErrorIncompatibleSalary: 'ERRO! Salário incompatível com o cargo.',
		salaryConsultSuccessful: 'Relatório enviado, verifique seu e-mail!',
		salaryConsultResponsableName: 'Nome do Responsável que receberá o Relatório Salarial',
		salaryConultResponsableEmail: 'Email do Responsável',
		salaryConsultEducationLevel: 'Escolaridade',
		salaryConsultExperienceYears: 'Anos de Experiência na Posição',
		salaryConsultExperienceThreeLess: 'Menos de 3 anos',
		salaryConsultExperienceThreeOrSeven: 'Entre 3 e 7 anos',
		salaryConsultExperienceSevenOrMore: 'Mais de 7 anos',
		salaryConsultEnglishLevel: 'Nível de Inglês',
		salaryConsultEnglishAloof: 'Indiferente',
		salaryConsultEnglishBasic: 'Básico',
		salaryConsultEnglishMid: 'Intermediário',
		salaryConsultEnglishHigh: 'Avançado',
		salaryConsultGrossMonthly: 'Salário Bruto Mensal',
		salaryConsultAnnualBonus: 'Bônus Anual',
		salaryConsultButtonSend: 'Enviar Relatório por Email',

		//CareerPlan Section
		careerPlanText: 'Quero um profissional para ocupar o cargo de:',
		careerPlanPHRole: 'ex: Coordenador',
		careerPlanPHArea: 'ex: Comercial',
		careerPlanBtnSearch: 'Buscar',
		careerPlanBtnClean: 'Limpar',
		careerPlanPHNameColaborator: 'Nome do Colaborador',
		careerPlanNextLevel: 'Próximo Nível',
		careerPlanSameAreas: 'Áreas Afins',

		//SearchDashBoard
		leaveSearchTitle: 'Pesquisa de Saída',
		leaveSearchNotFound: 'As pesquisas de saídas ainda não foram respondidas',

		//AddEmployer
		assignCollaborator: 'Designar um Colaborador',
		collaboratorTitle: 'Colaborador',
		nullEmployersRegister: 'Não há empregados registrados',
		successMessageAddEmployer: 'Colaborador registrado com êxito!',
		newCollaborator: 'Cadastrar novo',
		showListCollaborators: 'Ver lista',

		//NewClass
		createNewClass: 'Criar Novo Curso',
		className: 'Nome do Curso',
		classLink: 'Link do Curso',
		courseDescription: 'Descrição',

		//Denuncia
		complaintSecurityText: 'Você está em um espaço seguro',
		complaintSecuryTextBody: 'Este é um canal totalmente confidencial entre você e o MyDNA. Tentaremos ajudar sem revelar seus dados.',
		btnComplaint: 'Criar uma Reclamação',
		complaintTypes: 'Motivo da Denúncia',
		complaintGenerate: 'Enviar mensagem confidencial',
		complaintOptionA: 'Discriminação trabalhador.',
		complaintOptionB: 'Culpar o trabalhador.',
		complaintOptionC: 'Isolamento do trabalho.',
		complaintOptionD: 'Desrespeito no trabalho e insultos.',
		complaintOptionE: 'Em relação às tarefas atribuídas.',
		complaintOptionF: 'Difamação profissional e/ou pessoal.',
		complaintOptionG: 'Sobrecarga de trabalho.',
		complaintOptionH: 'Assédio sexual',
		complaintOptionI: 'Rebaixar as funções do trabalhador.',
		complaintOptionJ: 'Burnout',
		complaintOptionK: 'Abuso trabalhista.',
		complaintOptionL: 'Outro (Especifique)',

		//BE2
		//Personal Info

		// CardSection Title
		collaborator360: 'Colaborador 360°',
		dashboardCompany: 'Dashboard Empresa',
		executiveSearch: 'Executive Search',
		middleTopManagement: 'Middle & Top Management',
		backoffandStaff: 'Backoff & Staff',
		plataformOnboardinGamified: 'Plataforma de Onboarding Gamificado',
		englishClass: 'Aulas de Inglês',
		commercialTraining: 'Treinamento de Pitch Comercial e Vendas',
		gamefiedTraining: 'Ferramenta de Treinamento Gamificada',
		cultureReport: 'Relatório de Cultura',
		culturalFitEmp: 'Fit Cultural Candidados',
		culturalFitCol: 'Fit Cultural Colaboradores',
		culturalTransformation: 'Transformação Cultural',
		realTimeConsult: 'Consulta em Tempo Real',
		customStudy: 'Estudo Personalizado',
		variableSalary: 'Estudo de Remuneração Variável',
		salaryGuide: 'Guia Salarial',
		salaryProjection: 'Projeção Salarial',
		topManagement: 'Top Management',
		middleManagement: 'Middle Managment',
		backofficeOutput: 'Backoffice & Staff (Saida)',
		searchOutput: 'Pesquisa de Saida',

		//Jobs Description
		jobsDescriptionTitle: 'Descrição de Cargos',
		jobsDescriptionShowMore: 'Ver descrição',
		jobsDescriptionReturn: 'Voltar a lista de cargos',
		jobsDescriptionTitleJob: 'O que faz um ',
		jobsDescriptionActivies: 'Quais são as suas Principais Atividades',
		jobsDescriptionFormation: 'Perfil Técnico / Experiência / Formação Acadêmica',
		jobsDescriptionPersonal: 'Perfil Pessoal',
		jobsDescriptionRelacion: 'Nível de Relação',
		jobsDescriptionLanguage: 'Idioma',
		jobsDescriptionStudy: 'Formação',

		//CardSection Text
		textCollaborator360: 'O People Analytics mais completo com os principais indicadores para gestão de talentos em sua organização.',
		textDashboardCompany: 'KPIs chave para a gestão de talentos na sua organização. Saiba onde a empresa está indo bem e onde há trabalho para melhorar com KPIs claros e bem definidos.',
		textExecutiveSearch: 'Headhunting especializado em alta e média gestão com presença regional. Garante a contratação de profissionais que se adequem ao perfil da sua empresa, conseguindo um maior nível de empenho e um menor nível de turnover.',
		textMiddleTopManagement: 'Recrutamento para cargos intermediários na pirâmide com rapidez e precisão. Procuram-se profissionais que se enquadrem no perfil da sua empresa, garantindo um maior nível de empenho e um menor nível de rotatividade.',
		textBackoffandStaff: 'Recrutamento para cargos operacionais e técnicos com rapidez e precisão. Procuram-se profissionais que se enquadrem no perfil da sua empresa, garantindo um maior nível de empenho e um menor nível de rotatividade.',
		textPlataformOnboardinGamified: 'Melhore o comprometimento e acelere o desenvolvimento de seus colaboradores com um software de gamificação que gera interação, educação, cultura e trabalho em equipe.',
		textEnglishClass: 'Aprendizagem de inglês para seus colaboradores.',
		textCommercialTraining: 'Treinamento de Pith Comercial para seus colaboradores.',
		textGamefiedTraining: 'Crie seu próprio treinamento personalizado. Entregamos uma experiência totalmente gamificada aos seus colaboradores com conteúdo de treinamento selecionado por você.',
		textCultureReport: 'Conheça a cultura da sua organização e compare a visão da empresa versus a de seus colaboradores.',
		textCulturalFitEmp: 'Conheça a % de ajuste que a organização tem com seus colaboradores em relação ao seu propósito e cultura.',
		textCulturalFitCol: 'Conheça a % de ajuste que a organização tem com seus candidatos em relação ao seu propósito e cultura.',
		textCulturalTransformation: 'Onde você está hoje e para onde você quer ir? Caso a cultura atual da sua empresa não reflita o que você gostaria, nós te ajudamos.',
		textPerformanceAnalysis: 'Avalie o desempenho de seus colaboradores, conheça os gaps e pontos fortes de suas equipes, defina a melhor estratégia de gestão de talentos com dados claros e quantitativos.',
		textRealTimeConsult: 'Acesse o banco de dados salarial da Mydna com informações atualizadas de mercado para definir a melhor estratégia para sua organização. Consulta por tipo de empresa, cargo, experiência e Habilidades.',
		textCustomStudio: 'Para aquelas vagas que ainda não encontra informação na nossa plataforma na área de “consulta em tempo real”, apoiamo-lo na recolha de informação de forma ágil e aconselhada pelas nossas equipas de especialistas.',
		textVariableSalary: 'Defina a melhor estratégia de remuneração variável para sua organização juntamente com uma avaliação de desempenho.',
		textSalaryGuide: 'Baixe o último Guia Salarial desenvolvido por PayBalance/Mydna',
		textMotivacionalTest: 'Teste psicolaboral de motivações profissionais para descobrir o que realmente motiva seus colaboradores e/ou candidatos.',
		textSkillTest: 'Teste psicolaboral de Habilidades profissionais para descobrir as soft skills que mais se destacam para os seus colaboradores e/ou candidatos.',
		textEnglishLevel: 'Avalie o nível de inglês de seus colaboradores com o apoio da Nulinga. Um teste de 20 minutos para conhecer seu domínio do idioma.',
		textCareerProjection: 'Avalie o nível de inglês de seus colaboradores com o apoio da Nulinga. Um teste de 20 minutos para descobrir seu domínio do idioma.',
		textSalaryProjection: 'Saiba quanto pagar no caso de promover um profissional aplicando as melhores estratégias de desenvolvimento e retenção profissional.',
		textJobDescriptor: 'Conheça as habilidades e responsabilidades necessárias de cada cargo.',
		textTopManagement: 'Assessoria para recolocação de profissional especializado em alta e média gerência.',
		textMiddleManagement: 'Acesso à plataforma de outplacement digital especializada em posições intermediárias na pirâmide para rápida recolocação no mercado de trabalho.',
		textBackofficeOutput: 'Acesso à plataforma de outplacement digital especializada em cargos de operador e técnicos para rápida recolocação no mercado de trabalho.',
		textSearchOutput: 'Conheça a experiência de seus ex-colaboradores no momento de sua saída',
		textGenesCard: 'Assessoria para a recolocação de um profissional',

		// PeopleAnalytics Session
		//MyCollaborators
		myCollaboratorsTitle: 'Meus Colaboradores',
		searchCollaborator: 'Buscar um Colaborador',
		notDoneCultureSearch: 'Selecione o Colaborador desejado para ver seus resultados e compará-lo com a empresa',
		noCollaboratorsRegistered: 'Ainda não há colaboradores cadastrados',

		//CollaboratoRegister
		collaboratorName: 'Nome',
		collaboratorEmail: 'E-mail',
		loadCollaborator: 'Registrar Colaborador',
		collaboratorStatusTitle: 'Atenção!',
		collaboratorStatusText: 'Por favor complete ao menos todas as informações requeridas',
		collaboratorStatusSuccess: 'Colaborador adicionado com sucesso!',
		collaboratorStatusError: 'Ocorreu um erro!',
		//MassiveRegister
		collBodyText: 'Baixe o modelo de planilha excel, complete-o com as informações de seus colaboradores e faça o upload para o banco de dados.',
		collDownloadSheet: 'Baixar o Modelo de Planilha',
		collUpSheet: 'Fazer upload de Planilha',
		collArchive: 'Arquivo',

		//BadgeHeroes
		badgeHeroesTitle: 'Badge Heroes',
		badgeHeroesTextCard: 'Melhore o comprometimento e acelere o desenvolvimento de seus colaboradores com um software de gamificação que gera interação, educação, cultura e trabalho em equipe.',

		//Myco
		mycoTitle: 'Testes de Habilidades e Motivações Profissionais',
		mycoTextCard: 'Teste psicolaboral de Habilidades e motivações profissionais para conhecer as soft skills e o que realmente motiva os seus colaboradores.',

		//ProgressBar
		progressText: 'Concluído',
		progressMyProfile: 'Meu Perfil',
		progressProfileText: 'Selecione a categoria que deseja iniciar',

		//Personal Info
		editProfileImg: 'Editar foto de Perfil',
		professionalInformation: 'Informação Profissional',
		personalCompanyCol: 'Empresa',
		personalIndustryCol: 'Indústria',
		personalAreaCol: 'Área',
		personalEntryDate: 'Data de Entrada',
		personalExitDate: 'Data de Saída',
		personalHastaFecha: 'Até o momento',
		//Education
		personalEducation: 'Educação',
		personalEnglishLevel: 'Nível de Inglês:',
		personalELNulo: 'Nulo',
		personalELBasic: 'Básico',
		personalELAverage: 'Intermediário',
		personalELAdvanced: 'Avançado',
		personalELFluent: 'Fluente',
		personalUniversity: 'Universidade',
		personalType: 'Tipo',
		personalClass: 'Curso',
		personalAddInfo: 'Adicionar formação',
		//Salary
		personalSalary: 'Salário',
		personalFixBruteSalary: 'Salário Fixo Atual:',
		personalCoin: 'Moeda:',
		personalVariableSalary: 'Salário Variável Anual:',

		//Purpose
		myPurposes: 'Meus Propósitos',
		yourPurpose: 'Seu Propósito',
		myPurposeText: 'Compartilhe seus objetivos pessoais e profissionais, o que te motiva a ser uma pessoa e profissional melhor a cada dia. Através da assessoria digital, ajudaremos você a tornar seus objetivos realidade.',

		//Culture
		myCultureProfile: 'Meu Perfil Cultural',
		myCultureText: 'Conheça seu perfil cultural e as empresas em que você se encaixa e será feliz, de acordo com suas preferências profissionais.',

		//Competencias
		competenciaTitle: 'Assessment Habilidades:',
		abilitiesCollaboratorTitleText: 'Você quer conhecer suas Habilidades? Você pode fazer isso agora.',
		abilitiesCollaboratorTextP1: 'Nosso teste avalia 11 Habilidades de sua personalidade, tais como',
		abilitiesCollaboratorTextP2: 'Visão Estratégica, Liderança, Orientação ao Cliente, Trabalho em Equipe',
		abilitiesCollaboratorTextP3: ' entre outras.',
		abilitiesCollaboratorTextEmail: 'Você receberá um e-mail com o acesso ao teste online.',
		abilitiesCollaboratorDoubtsText: 'Clique aqui novamente para ver os resultados (pode demorar até 24hrs após a conclusão do teste).',

		//Motivacions
		motivationTitle: 'Assessment Motivações:',
		motivacionCollaboratorTitle: 'O que o motiva? Descubra agora!',
		motivacionalCollaboratorTextP1: 'Nosso teste avalia 17 fatores em sua área profissional que o motivam.',
		motivacionalCollaboratorTextP2: 'Saber claramente o que o motiva é essencial para orientar sua carreira e sua vida',
		motivacionalCollaboratorTextP3: ' Faça o teste agora!',

		//Performance
		performanceTitle: 'Desempenho',
		perfomanceFinalResult: 'Resultado Final:',

		//Trainings
		ebooks: 'E-books & Conteúdos',
		myTrainings: 'Meus Treinamentos',
		createNewTraining: 'Criar nova Capacitação',
		englishText: 'Test de Inglês',
		nulingaText: 'A plataforma de aprendizado de idiomas online projetada para o mundo corporativo.',
		enterTrainingLink: 'ACESSAR AO CURSO',

		//Employee Profile Edit
		profileError: 'Campo obrigatório',

		//GoToPaybalance
		paybalanceTitle: 'Acesse Paybalance',
		paybalanceDescription1: 'Olá,',
		paybalanceDescription2: 'Obrigado pela confiança no MyDNA!',
		paybalanceDescription3: 'Aqui você terá acesso ao PayBalance by MyDNA, Plataforma de Informações Salariais em Tempo Real. Para isso você deve clicar no link abaixo:',
		paybalanceDescription4: 'E entre com seu e-mail e senha, os mesmos do MyDNA, para poder usar todos os recursos do Paybalance.',
		paybalanceDescription5: 'As equipes MyDNA e Paybalance irão apoiá-lo nesta jornada, você pode contatá-los em caso de dúvida:',
		paybalanceTxt: 'Equipe',
		paybalanceBtn: 'ACESSE PAYBALANCE',

		//KnowPaybalance
		KnowPaybalanceTitle: 'Acesse Paybalance',
		KnowPaybalanceTextCard: 'Acesse PayBalance by Mydna com informações atualizadas de mercado para definir a melhor estratégia para sua organização. Consulta por tipo de empresa, cargo, experiência e Habilidades.',

		//Outplacement
		outplacementAlertAnswer: 'Não há respostas',
		outplacementQuestionOne: 'Já encontrou emprego?',
		outplacementQuestionTwo: 'Como se sente em relação ao processo?',
		outplacementQuestionThree: 'Quantidade de candidaturas:',
		outplacementQuestionFour: 'Está participando de entrevistas?',
		outplacementQuestionFive: 'Em quanto tempo acredita encontrar um novo emprego?',
		outplacementQuestionSix: 'Acredita que o programa está ajudando no processo?',
		outplacementQuestionSixPos: 'Acredita que o programa ajudou no processo?',
		outplacementQuestionSeven: 'Avanço no programa Genes:',
		outplacementAnswerYes: 'Sim',
		outplacementAnswerNo: 'Não',
		outplacementAnswerOne: 'entrevista(s) no momento',
		outplacementAnswerTwo: 'Não está realizando estrevistas no momento',
		outplacementAnswerThree: 'Em',

		outplacementAnswerFour: 'mês(es)',
		outplacementOservation: 'Observaçao',

		//Ombudsman
		occurrences: 'Ocorrências',
		noOccurrences: 'Ainda sem ocorrências',
		ombudmanTextOne: 'Essas informações são confidenciais e restritas a pessoas autorizadas dentro da empresa.',
		ombudmanTextTwo: 'Para obter as informações, entre em contato com nossa equipe; uma vez que sua identidade tenha sido verificada, eles lhe fornecerão as informações.',
		ombudsmanBtn: 'delivery@mydnadigital.com',

		//Campaign
		campaignTitle: 'Campanha People Analytics',
		campaignTxt:
			'Após cadastrar os colaboradores na plataforma, você pode enviar o e-mail, selecionar o pessoal necessário e clicar em "ENVIAR" para fazer a entrega simultânea para as pessoas desejadas. Junto com isso entregaremos seus respectivos acessos a cada colaborador. No MyDNA Employee, cada colaborador poderá carregar todas as informações relevantes para que os responsáveis ​​pela gestão de talentos tenham à disposição dados como: histórico profissional, formação, pesquisa de clima, cultura, Habilidades, entre outros.',
		campaignMenu: 'Campanha Colaborador',
		campaignTableName: 'Nome',
		campaignTableMail: 'E-mail',
		campaignTableArea: 'Área',
		campaignTableJob: 'Cargo',

		//Salarios y Beneficios
		salaryTitle: 'Salarios por Cargo',

		selectBands: 'Nível da banda',

		//Internal Channel
		internalChannelTitle: 'Canal de comunicação',
		internalChannelNewMessage: 'Nova mensagem',
		internalChannelNoMessages1: 'A caixa de saída está vazia, cria sua primeira mensagem',
		internalChannelNoMessages2: ' para começar a se comunicar com os colaboradores',
		internalChannelSendedMessages: 'Mensagens Enviadas',
		internalChannelReceivedMessages: 'Mensagens Recebidas',

		//Internal Vacancies
		internalVacanciesTitle: 'Vagas Internas',
		internalVacanciesCreate: 'Criar vagas',
		internalVacanciesActive: 'Vagas ativas',

		//MYCO Test
		mycoAbilitiesQ1: 'Análise e Resolução de Problemas',
		mycoAbilitiesQ2: 'Inovação',
		mycoAbilitiesQ3: 'Pensamento estratégico',
		mycoAbilitiesQ4: 'Orientação ao cliente',
		mycoAbilitiesQ5: 'Liderança',
		mycoAbilitiesQ6: 'Trabalho em equipe',
		mycoAbilitiesQ7: 'Orientação a resultados',
		mycoAbilitiesQ8: 'Plan. e Org.',
		mycoAbilitiesQ9: 'Gestão de cambio',
		mycoAbilitiesQ10: 'Auto desenvolvimento',
		mycoAbilitiesQ11: 'Gerenciamento de estresse',

		mycoMotivationsQ1: 'Orientação comercial',
		mycoMotivationsQ2: 'Orientação ao alvo',
		mycoMotivationsQ3: 'Orientação para a tipologia do trabalho realizado',
		mycoMotivationsQ4: 'Orientação para inovação',
		mycoMotivationsQ5: 'Aspecto econômico',
		mycoMotivationsQ6: 'Autonomia e Versatilidade',
		mycoMotivationsQ7: 'Segurança',
		mycoMotivationsQ8: 'Prestígio',
		mycoMotivationsQ9: 'Equilíbrio Profissional',
		mycoMotivationsQ10: 'Satisfação no trabalho',
		mycoMotivationsQ11: 'Comprometimento',
		mycoMotivationsQ12: 'Carreira',
		mycoMotivationsQ13: 'Desenvolvimento de habilidades',
		mycoMotivationsQ14: 'Projeto Profissional',
		mycoMotivationsQ15: 'Orientação de relações interpessoais',
		mycoMotivationsQ16: 'Orientação do conceito',
		mycoMotivationsQ17: 'Orientação de gerenciamento',

		//SalaryByRole
		salaryRoleEmployee: 'Colaborador',
		salaryRoleCoin: 'Moeda',
		salaryRoleSalary: 'Salário Mensal',
		salaryDownloadList: 'Baixar Lista',
		//SeniorityLevel
		salaryRoleSL1: 'Indiferente',
		salaryRoleSL2: 'Junior',
		salaryRoleSL3: 'Pleno',
		salaryRoleSL4: 'Sênior',
		//QualificationLevel
		salaryRoleQL1: 'Indiferente',
		salaryRoleQL2: 'Graduação',
		salaryRoleQL3: 'MBA',
		salaryRoleQL4: 'MBA Internacional',

		salaryRoleAlert: 'Esta posição está sendo revisada. Para baixar o relatório tente novamente mais tarde',

		//DashboardClime&Benefits
		dashboardTextClimeBenefits: 'A informação apresentada no dashboard reflete apenas a informação dos colaboradores a quem o salário foi informado',
		alertEmptyDashboard: 'Olá! Você ainda não carregou as informações salariais do funcionário',

		//Benefits
		benefitsText: 'Escolha a posição e descubra as vantagens que o mercado oferece para cada posição',
		benefitsSearchByCountry: 'País da Posição do Colaborador',

		alimentationTitle: 'Alimentação',
		alimentationText: 'O valor médio é CLP $ 3.700 por dia',

		transportTitle: 'Transporte',
		transportText: 'O valor pode variar, dependendo da empresa e do salário base do trabalhador',

		lifeInsuranceTitle: 'Seguro Complementar',
		lifeInsuranceText: 'Suplemento ao plano de saúde com cobertura média de 500 UF por beneficiário',

		certificationTitle: 'Certificações',
		certificationText: 'Apoio económico para certificar Habilidades',

		vehicleTitle: 'Veículo',
		vehicleText: 'Benefício oferecido quando contribui ou utiliza seu veículo para a realização de atividades laborais',

		tradingPowerTitle: '% Trading Power Anual',
		tradingPowerText: 'Como forma de reconhecer o profissional que ajudou a empresa a agregar valor, parte do lucro do período é entregue ao executivo',

		computerTitle: 'Computador/Celular',
		computerText: 'Eles são considerados ferramentas de trabalho e sua entrega está cada dia mais massiva.',

		gymTitle: 'Academia',
		gymText: 'Convênios com academias que permitem descontos ao colaborador',

		anualBonusTitle: 'Bônus Anual por Resultados',
		anualBonusText: 'O valor pode variar de acordo com a atuação do colaborador',

		bonusTitle: 'Bônus',
		bonusText: 'O valor pode variar de 33.000 a 300.000 CLP. Sendo a média em 60.000 CLP',

		equityTitle: 'Equity',
		equityText: 'A empresa oferece suas ações oficiais estratégicas da empresa, tornando-se parceira desta',

		stockOptionsTitle: 'Stock Options',
		stockOptionsText: 'A empresa oferece ao seu colaborador estratégico a opção de comprar ações da empresa a preços melhores do que os praticados no mercado',

		//EmotionalSalary
		emotionalSalaryText: 'Escolha o tema relacionado ao salário emocional que o mercado entrega e descubra quais são os mais comuns',

		emotionalSalaryOptionSelect: 'Escolha uma opção',

		//MyCompany
		companyDetails: 'Detalhes da Empresa',
		companyLogo: 'Carregar seu Logotipo',
		companyName: 'Nome',
		companyDescription: 'Descrição',
		companyAnualBilling: 'Faturamento Anual',
		companyLocation: 'Localização',
		companySize: 'Tamanho da Empresa',
		companySize1: 'Pequena',
		companySize2: 'Média',
		companySize3: 'Grande',
		companyEmployeeAmount: 'Número de Empreados',
		companyContact: 'Dados de Contato e Redes Sociais',
		companyPhone: 'Telefone',
		companyWebPage: 'Pag. Web',
		companyProducts: 'Produtos da Empresa',
		companyAddProduct: 'Adicionar Produto',
		companyContent: 'Conteúdo',
		companyUpVideos: 'Suba os vídeos da sua Empresa aqui',

		//SupportPage
		supportTitle: 'Suporte MyDNA',
		supportText:
			'Nossa equipe de Customer Success não é apenas um grupo de profissionais; somos seus parceiros na conquista de seus objetivos. Comprometemo-nos em compreender suas necessidades e desafios únicos para oferecer soluções personalizadas e orientadas ao sucesso. Para qualquer coisa que você precise, entre em contato com nossa equipe',

		//InternalJobs
		internalJobStep1: 'Area, Nível, Tempo de Experiência',
		internalJobName: 'Nome da Vaga*:',
		internalJobArea: 'Área(s) de Atuação*:',
		internalJobNivel: 'Escolha o Nível Hierárquico*:',
		internalJobExperienceYears: 'Anos de experiência requerida para a posição*:',
		internalJobOptionArea: 'Selecione uma Área',
		internalJobOptionNivel: 'Selecione uma Opção',

		internalJobStep2: 'Descrição do Cargo',
		internalJobDescription: 'Descrição',
		internalJobRequeriments: 'Requerimentos',

		internalJobRequerimentsPlaceHolder: 'Escreva aqui os demais requisitos e funções do cargo',

		internalJobTitle: 'Vagas Internas',
		internalJobTableC1: 'VAGAS',
		internalJobTableC2: 'ÁREA',
		internalJobTableC3: 'VER CANDIDATOS',
		internalJobSearchPlaceHolder: 'Pesquisar por Vagas',
		internalJobShowMore: 'Carregar mais...',
		internalJobEmptyJob: 'Não há vagas',
		internalJobReturn: 'VOLTAR À LISTA DE VAGAS',

		//HistoricProfessionalEE
		personalAddInfoProf: 'Experiência Profissional',
		personalConectiveProf1: 'em',
		personalConectiveProf2: 'à',
		historicProfessionalEENameCompany: 'Nome da Empresa',
		historicProfessionalEEAreaCompany: 'Indústria',
		historicProfessionalEEOption0: 'Selecionar...',
		historicProfessionalEEOption1: 'Agroindustria',
		historicProfessionalEEOption2: 'Autopartes',
		historicProfessionalEEOption3: 'Bens de Consumo',
		historicProfessionalEEOption4: 'Comunicação',
		historicProfessionalEEOption5: 'Construção',
		historicProfessionalEEOption6: 'Consultoria',
		historicProfessionalEEOption7: 'Educação',
		historicProfessionalEEOption8: 'Eletrônica',
		historicProfessionalEEOption9: 'Energia',
		historicProfessionalEEOption10: 'Farmaceutica',
		historicProfessionalEEOption11: 'Engenharia',
		historicProfessionalEEOption12: 'Agropecuaria',
		historicProfessionalEEOption13: 'Eletro / Eletrônica',
		historicProfessionalEEOption14: 'Logística e Transpote',
		historicProfessionalEEOption15: 'Máquinas e Equipamentos Industriais',
		historicProfessionalEEOption16: 'Manufatura',
		historicProfessionalEEOption17: 'Lazer e Entretenimento',
		historicProfessionalEEOption18: 'Publicidade',
		historicProfessionalEEOption19: 'Química, Plástico e Petroquímica',
		historicProfessionalEEOption20: 'Varejo',
		historicProfessionalEEOption21: 'Setor Público',
		historicProfessionalEEOption22: 'Seguros',
		historicProfessionalEEOption23: 'Telecomunicação',
		historicProfessionalEEOption24: 'Têxtil',
		historicProfessionalEEOption25: 'TI',
		historicProfessionalEEOption26: 'Veículos',
		historicProfessionalEEOption27: 'Financeiro',
		historicProfessionalEEOption28: 'Saúde',
		historicProfessionalEEOption29: 'Veterinária',
		historicProfessionalEEOption30: 'Internet',
		historicProfessionalEEOption31: 'Jurídico',
		historicProfessionalEEOption32: 'Linhas Aéreas',
		historicProfessionalEEOption33: 'Mineração',
		historicProfessionalEEJob: 'Cargo',
		historicProfessionalEEEntryDate: 'Data de Entrada',
		EEMonth: 'Mês',
		EEYear: 'Ano',
		historicProfessionalEEActualJob: 'Trabalho Atual',
		historicProfessionalEELeaveDate: 'Data de Saída',

		//FormationEE
		formationEENameUniversity: 'Nome da Universidade',
		formationEEType: 'Tipo',
		formationEEOption1: 'Técnico',
		formationEEOption2: 'Profissional',
		formationEEOption3: 'Graduado',
		formationEEOption4: 'MBA/Mestre',
		formationEEOption5: 'Doutorado',
		formationEECourse: 'Curso',
		formationEEEntryDateCourse: 'Data de Entrada',
		formationEECurrentlyStuding: 'Cursando Atualmente',
		formationEELeaveDateCourse: 'Data de Saída',

		//ClimeQuestionsEE
		climeQuestionsEEH1: 'Muito Insatisfeito',
		climeQuestionsEEH2: 'Insatisfeito',
		climeQuestionsEEH3: 'Satisfeito',
		climeQuestionsEEH4: 'Muito Satisfeito',
		cultureQuestionsEEForMe: 'Para mim',
		//Section1
		climeQuestionsEETitle1: 'RECURSOS, MEIO AMBIENTE E CULTURA',
		climeQuestionEE1: 'As relações pessoais com seus colegas geralmente são boas?',
		climeQuestionEE2: 'A empresa e a liderança fornecem iniciativas, canais e ambientes suficientes para promover um bom relacionamento com seus colegas?',
		climeQuestionEE3: 'Você possui os recursos, ferramentas e ambientes necessários, físicos ou digitais, para realizar suas tarefas e atingir seus objetivos?',
		climeQuestionEE4: 'A cultura e os valores da empresa são consistentes com seus valores pessoais?',
		//Section2
		climeQuestionsEETitle2: 'ATIVIDADES E FUNÇÕES EXECUTADAS',
		climeQuestionEE5: 'O volume de trabalho realizado é adequado ao seu cargo e carga horária?',
		climeQuestionEE6: 'Sua posição é consistente com suas habilidades, conhecimentos e senioridade profissional?',
		climeQuestionEE7: 'Seus objetivos, tarefas e resultados declarados são claros?',
		climeQuestionEE8: 'Suas responsabilidades são condizentes com o cargo que ocupa?',
		climeQuestionEE9: 'Você tem clareza e percepção de valor do impacto das tarefas nos objetivos da empresa?',
		climeQuestionEE10: 'O modelo de trabalho (presencial, híbrido ou home office) é adequado para o desempenho de suas funções?',
		//Section2
		climeQuestionsEETitle3: 'LIDERANÇA',
		climeQuestionEE11: 'Seu líder ouve e considera suas opiniões ao tomar decisões?',
		climeQuestionEE12: 'A avaliação do seu desempenho e o reconhecimento das suas contribuições são adequados?',
		climeQuestionEE13: 'Seu líder mantém um nível de exigência adequado ao seu cargo e carga de trabalho?',
		climeQuestionEE14: 'Sua liderança possui o conhecimento técnico, habilidades gerais e suporte necessários para que você tenha sucesso em suas atividades?',
		climeQuestionEE15: 'Você se sente à vontade para compartilhar dificuldades, desconfortos ou pedir feedback quando necessário?',
		//Section2
		climeQuestionsEETitle4: 'OPORTUNIDADE E CARREIRA',
		climeQuestionEE16: 'Existem motivações e desafios nas suas funções?',
		climeQuestionEE17: 'Existe autonomia para aplicar suas habilidades e tomar decisões apropriadas às suas próprias custas?',
		climeQuestionEE18: 'Existe possibilidade de promoção e/ou plano de carreira bem claro e definido?',
		climeQuestionEE19: 'Há investimento da empresa em desenvolvimento de carreira?',
		climeQuestionEE20: 'Existe espaço para expressar suas ambições e se candidatar a cargos dentro da organização?',
		climeQuestionEE21: 'Há clareza sobre o que é necessário para crescer na empresa?',

		//Section2
		climeQuestionsEETitle5: 'SALÁRIOS E BENEFÍCIOS',
		climeQuestionEE22: 'A sua remuneração é adequada ao cargo que ocupa e às atividades que desenvolve?',
		climeQuestionEE23: 'Os benefícios atendem às suas necessidades?',
		climeQuestionEE24: 'Os processos de reembolso são claros e fáceis de executar?',
		climeQuestionsEETitle6: 'Por favor, responda às seguintes perguntas de 1 a 10, onde 1 indica "discordo totalmente" e 10 indica "concordo totalmente":',
		climeQuestionEE25: 'Você recomendaria um amigo ou familiar para trabalhar nesta empresa?',

		climeQuestionsAlert: 'IMPORTANTE: Sua empresa não terá acesso às suas respostas, apenas à média entre os colaboradores, desde que mais de 10 colaboradores tenham respondido a pesquisa, para manter o sigilo.',

		//CultureQuestions
		cultureQuestionEE1: 'É importante para mim que haja um bom equilíbrio entre homens e mulheres em cargos de gestão.',
		cultureQuestionEE2: 'Gosto de me vestir e me comportar formalmente no trabalho.',
		cultureQuestionEE3: 'Para mim, é importante que haja reciclagem e uso inteligente dos recursos da empresa.',
		cultureQuestionEE4: 'Gosto do fato de que há tolerância absoluta em relação à orientação sexual dos meus colegas e da gerência.',
		cultureQuestionEE5: 'Sinto-me mais confortável em uma empresa inovadora do que em uma empresa extremamente tradicional.',
		cultureQuestionEE6: 'É compreensível que uma empresa tenha números de telefone e endereços de e-mail para relatórios confidenciais.',
		cultureQuestionEE7: 'Ser competitivo é fundamental para ter sucesso no trabalho.',
		cultureQuestionEE8: 'Se eu tiver que escolher entre desenvolvimento de carreira e salário, prefiro o desenvolvimento de carreira.',
		cultureQuestionEE9: 'Quero assumir posições mais poderosas rapidamente.',
		cultureQuestionEE10: 'Ter cuidado com o que diz e ser politicamente correto em suas opiniões é fundamental para ter sucesso no local de trabalho.',
		cultureQuestionEE11: 'É importante esconder as tatuagens e cuidar de sua aparência física no trabalho para ser bem-sucedido.',
		cultureQuestionEE12: 'Se meu chefe não fala comigo sobre o desenvolvimento de minha carreira, eu entendo.',
		cultureQuestionEE13: 'É compreensível que os diretores ou gerentes tenham vagas de estacionamento privilegiadas.',
		cultureQuestionEE14: 'É compreensível que a comunicação com os chefes possa se tornar mais difícil às vezes.',
		cultureQuestionEE15: 'É compreensível que o GM esteja próximo de algumas pessoas e não de outras.',
		cultureQuestionEE16: 'A questão da liderança é a mais importante.',
		cultureQuestionEE17: 'Se eu fizesse parte de uma equipe de alto desempenho, trabalharia até mais tarde.',
		cultureQuestionEE18: 'Adoro quando meu chefe me pergunta o que penso sobre sua liderança e gestão.',
		cultureQuestionEE19: 'Sinto-me mais confortável em uma empresa com sindicato(s).',
		cultureQuestionEE20: 'Escolher meus horários de início e término é um fator fundamental para mim.',
		cultureQuestionEE21: 'Sair mais cedo às sextas-feiras é um fator fundamental para mim.',
		cultureQuestionEE22: 'Ter dias de folga acima do legal em caso de casamento ou PUA é um fator fundamental para mim.',
		cultureQuestionEE23: 'Ter os melhores benefícios pré e pós-natal para as mulheres é um fator fundamental para mim.',
		cultureQuestionEE24: 'O fato de a empresa apoiar as atividades esportivas de seus funcionários é um fator fundamental para mim.',

		contractTypeEE: 'Tipo de Contrato',

		//CareerPlan
		directorCP: 'Diretor',
		managerCP: 'Gerente',
		submanagerCP: 'Subgerente',
		bossCP: 'Chefe',
		specialistCP: 'Especialista',
		analystCP: 'Analista',
		assistantCP: 'Assistente',
		technicianCP: 'Técnico',
		operatorCP: 'Operário',

		//InternalJobs
		emptyInternalJobs: 'Ainda não há vagas publicadas',

		//InternalMessage
		emptyMessage: 'Nenhuma mensagem',

		//EEProfile
		//PersonalInformations
		personalTitleEEProfile: 'Informações Pessoais',
		emailEEProfile: 'E-mail Pessoal',
		phoneEEProfile: 'Telefone',
		documentEEProfile: 'Documento',
		birthDateEEProfile: 'Data de Nascimento',
		//SocialNetworks
		socialInformationEEProfile: 'Redes Sociais e Foto',
		photoEEProfile: 'Foto de perfil',
		photoTextEEProfile: 'As fotos ajudam a personalizar seu perfil e facilitam a identificação de outras pessoas.',
		//Interests
		interestsTitleEEProfile: 'Informação de Interesses',
		hobbyEEProfile: 'Hobies',
		sportsEEProfile: 'Esportes',
		interestsEEProfile: 'Interesses',
		volunteerWorkEEProfile: 'Trabalho Voluntário',
		//Health
		healthTitleEEProfile: 'Informação de Saúde',
		healthPlanEEProfile: 'Plano de Saúde',
		heightEEProfile: 'Altura',
		weightEEProfile: 'Peso',
		diseasesEEProfile: 'Doenças',
		pcdEEProfile: 'PCD',
		typeEEProfile: 'Tipo',
		//Family
		familyTitleEEProfile: 'Informação Familiar',
		genderEEProfile: 'Gênero',
		civilStatusEEProfile: 'Estado Civil',
		sonsEEProfile: 'Filhos',
		//Contract
		contractTitleEEProfile: 'Informações de Contrato',
		contractTypeEEProfile: 'Tipo de Contrato',
		myBossEEProfile: 'Seu Chefe',
		selectBossEEProfile: 'Escolha seu Chefe',
		bankEEProfile: 'Banco',
		bankAccountEEProfile: 'Conta Corrente',
		//Adress
		adressTitleEEProfile: 'Informação de Endereço',
		cityEEProfile: 'Cidade',
		countryEEProfile: 'Pais',
		adressEEProfile: 'Rua',
		nationalityEEProfile: 'Nacionalidade',
		transportEEProfile: 'Meio de transporte utilizado para chegar ao trabalho',

		//FutureCV
		showMore: 'Mais informações',
		showReview: 'Ver Análise MyDNA',
		downloadFutureCV: 'Baixar PDF',
		inTextFutureCV: 'em',
		yearsTextFutureCV: 'anos',
		experienceTextFutureCV: 'Experiencia Profissional',
		careerYearsTextFutureCV: 'anos de carreira',
		monthsTextFutureCV: 'meses de carreira',
		employeeYearsExpFutureCV: 'anos por empresa',
		employeeMonthsExpFutureCV: 'meses por empresa',
		formationTextFutureCV: 'Formação',
		purposeTextFutureCV: 'Propósito',
		cultureTextFutureCV: 'Cultura',
		comercialTitleFutureCV: 'Comercial',
		comercialTextFutureCV: 'Orientação para alcançar resultados. O ambiente motiva parcialmente os funcionários a atingir as metas. Estilo de liderança motiva, mas você pode colocar mais agressividade comercial.',
		disruptiveTitleFutureCV: 'Disruptiva',
		disruptiveTextFutureCV: 'Estilo organizacional mais inovador, ouvindo e trabalhando mais em equipe. Planejamento de longo prazo. A liderança é mais motivadora, assume riscos, é mais empreendedora.',
		traditionalTitleFutureCV: 'Tradicional',
		traditionalTextFutureCV: 'Estilo organizacional focado nas pessoas. Preocupada em gerar um ambiente de trabalho agradável e próximo. O desenvolvimento profissional faz parte dos desafios permanentes.',
		collaborativeTitleFutureCV: 'Colaborativa',
		collaborativeTextFutureCV: 'Estilo organizacional levemente estruturado. Tem uma estrutura piramidal, onde as decisões são tomadas por poucos. A comunicação das decisões ou estratégia é lenta, conhecida por poucos e geralmente pela liderança.',
		performanceTextFutureCV: 'Desempenho',
		resultTextFutureCV: 'Resultado Final',
		abilitiesTextFutureCV: 'Competências',
		motivationsTextFutureCV: 'Motivações',
		salaryTextFutureCV: 'Salário',
		collabTextFutureCV: 'Colaborador',
		emptyValueTextFutureCV: 'Valor não Informado',
		comparativeTextFutureCV: 'Comparativo',
		riskTitleFutureCV: 'Risco de Perda',
		riskHighTextFutureCV: 'Alto',
		riskMediumTextFutureCV: 'Médio',
		riskLowTextFutureCV: 'Baixo',
		careerPlanningTextFutureCV: 'Plano de Carreira',
		actualLevelTextFutureCV: 'Nivel Atual',
		nextLevelTextFutureCV: 'Próximo Passo',
		observationsTextFutureCV: 'Observações',
		editObservationsTextFutureCV: 'Editar Observações',

		//Under Construction
		titleConstruction: 'Ola! Este recurso está em desenvolvimento.',
		textConstruction: 'Estamos trabalhando para que você tenha uma ótima experiência. Volte em breve para conferir.',

		//UnitReport
		unitSectionDescription: 'Gere relatórios em tempo real. Preencha as informações necessárias para gerar um relatório. Se precisar de algo mais específico e personalizado, preencha os campos recomendados e por último as informações adicionais.',
		unityReportIncomplete: 'Por favor, preencha todas as informações necessárias',
		unityReportMandatoryInfo: '1 | Informação Obrigatória',
		unityReportRecInfo: '2 | Informação Recomendada',
		unityReportAddInfo: '3 | Informação Adicional',
		unityReportAddInfoBtn: 'Completar Informação Adicional',
		unityReportAddRecomBtn: 'Completar Informação Recomendada',
		unityReportBtn: 'Gerar Reporte',

		//Documents
		docTitleView: 'Documentos de Admissão',
		docDescriptionEmp: 'Aqui você poderá enviar documentos para sua empresa, quando solicitado pelos gestores.',
		documentsDescription:
			'Organize os documentos de admissão por grupo de colaboradores. Defina quais documentos são necessários para iniciar o processo seletivo por perfil de cargo, receba os documentos e tenha-os sempre em mãos para qualquer gestão.',
		myTemplatesTitle: 'Meus Templates',
		createTemplate: 'Criar Template',
		emptyTemplate: 'Nenhum template criado',
		nameTemplate: 'Nome do Template',
		newDocumentAlert: 'Adicionar nome do documento',
		loadingTemplate: 'Carregando...',
		loadingCompleteTemplate: 'Adicionar Documento',
		btnSaveTemplate: 'Salvar',
		btnCancelTemplate: 'Cancelar',
		msgErrorDoc: 'O arquivo não foi salvo',
		msgErrorWait: 'Aguarde um momento e tente novamente ou entre em contato conosco',
		receivedDocTitle: 'Documentos Recebidos',
		docPending: 'Pendente',
		docSended: 'Enviado',
		docDownload: 'Baixar Arquivo',

		//SalaryComparative
		midpointText: 'Ponto médio para a posição',
		salaryDifferenceText: 'Diferença',
		salaryColaborator: 'Salário do Colaborador',
		salaryComparativeEmptyText: 'Para mais informações, informe os salários',
		payrollStatus: 'O salário do funcionário se encontra',

		//Banda Salarial
		salaryRangeTitle: 'Banda Salarial',
		salarysRangesTitle: 'Bandas Salariais',
		createNewSalaryRange: 'Criar Banda Salarial',
		salaryRangeName: 'Nome da Banda',
		salaryRangeCountry: 'País',
		salaryRangeMidPoint: 'Ponto Médio',
		salaryRangeEdit: 'Editar Banda',
		salaryRangeDelete: 'Excluir Banda',
		salaryRangeConfirmDelete: 'Tem certeza que deseja eliminar esta banda?',

		salaryInformation: 'Informação Salarial',

		EEpersonalInformation: 'Informações do Profissional',
		EEdescriptionValidator: 'Validar Descrição',
		EEnextStep: 'Avançar para a Próxima Etapa',
		EEbackBtn: 'Voltar para Informações do Profissional',
		EEregisterColaborator: 'Registrar Colaborador',

		EEFormName: 'Nome',
		EEFormEmail: 'Email',
		EEFormSelect: 'Selecionar...',
		EEFormCountry: 'País',
		EEFormRegion: 'Região',
		EEFormPosition: 'Posição',
		EEFormNivel: 'Nivel Hierárquico',
		EEFormArea: 'Área',
		EEFormSubarea: 'Subárea',
		EEFormEnglishLevel: 'Nível Inglês',
		EEFormFormation: 'Formação',
		EEFormFunction: 'Experiência na função',
		EEFormSalaryFix: 'Salário Fixo',
		EEFormSalaryVariable: 'Salário Variavél',
		EENamePlaceholder: 'Nome do Profissional',
		EEPositionPlaceholder: 'Nome da Posição',
		EESalaryVariablePlaceholder: 'Bonificações',
		EEColaboratorData: 'Dados do Colaborador',
		EEDescriptionRole: 'Descrição de Cargo',
		EEAction: 'Ações',
		EEEditData: 'Editar Dados do Colaborador',
		EELevelAndArea: 'Nível e Área',

		inputRequired: 'Campo Obrigatório',

		//Performance&Potential
		performanceAvaliationTitle: 'Avaliação de Desempenho',
		potentialAvaliationTitle: 'Avaliação de Potencial',
		potentialTitle: 'Potencial',
		performancePotentialTitle: 'Desempenho e Potencial',
		performancePotentialReferences: 'Guia de Uso',
		potentialMenuTitle: 'Avaliação de Potencial',
		performanceMenuTitle: 'Avaliação de Desempenho',
		nineBoxMenuTitle: 'Matriz 9Box',
		profile1: 'Ineficiente',
		profile2: 'Eficaz',
		profile3: 'Especialista',
		profile4: 'Questionável',
		profile5: 'Mantenedor',
		profile6: 'Alto Impacto',
		profile7: 'Enigma',
		profile8: 'Futuro líder',
		profile9: 'Crescimento',
		empty9Box: 'Para gerar os resultados do 9box, é fundamental completar as avaliações de desempenho e potencial.',
		weightTitle: 'Peso',
		reachedTitle: 'Alcançado',
		scoreTitle: 'Pontuação',
		emptyValuesPP: 'Não há dados disponíveis.',
		observationsTitle: 'Observações',
		phObservations: 'Observações sobre o Colaborador',
		pillText1: 'Melhorar',
		pillText2: 'Regular',
		pillText3: 'Fortaleza',
		deleteCriterionText: 'Você está prestes a excluir o critério selecionados. Esta ação é irreversível e o critério será permanentemente removido da lista.',
		nineBoxDesc1: 'alto potencial com baixo desempenho. Avaliar se há causas internas ou externas que o afetam, ou se é novo na função.',
		nineBoxDesc2: 'alto potencial com desempenho médio. Os profissionais em fase de crescimento tendem a estar nesta situação.',
		nineBoxDesc3: 'tem condições para uma ascensão imediata. São os profissionais que garantem o futuro da empresa.',
		nineBoxDesc4: 'avaliar se está na área correta ou em fase de adaptação. Se persistir, seria ideal revisar suas atribuições.',
		nineBoxDesc5: 'apresenta condições para crescer, mas ainda precisa desenvolver melhor suas competências.',
		nineBoxDesc6: 'excelente desempenho. É necessário desenvolver-se um pouco mais para assumir desafios mais complexos.',
		nineBoxDesc7: 'avaliar a possibilidade de movê-lo para uma função menor ou considerar sua demissão.',
		nineBoxDesc8: 'apresenta um maior foco no desempenho. Geralmente são profissionais de trabalho especializado.',
		nineBoxDesc9: 'está no lugar certo. É muito competente no que faz e não mostra indicadores de que deva mudar de posição no futuro próximo.',

		//PerformanceGraph
		salaryTitle9Box: 'Banda Salarial',
		performanceTitle9Box: 'Desempenho',
		potentialTitle9Box: 'Potencial',
		salaryBandPerformance: 'Banda Salarial x Desempenho',
		salaryBandPortential: 'Banda Salarial x Potencial',
		nineBoxSalaryTitlePotential1: 'Perspectivas de crescimento limitadas.',
		nineBoxSalaryTitlePotential2: 'Potencial superestimado:',
		nineBoxSalaryTitlePotential3: 'Alto potencial salarial.',
		nineBoxSalaryTitlePotential4: 'Requer desenvolver competências.',
		nineBoxSalaryTitlePotential5: 'Está adequado.',
		nineBoxSalaryTitlePotential6: 'Talento promissor.',
		nineBoxSalaryTitlePotential7: 'Potencial salarial coerente.',
		nineBoxSalaryTitlePotential8: 'Não impulsionado.',
		nineBoxSalaryTitlePotential9: 'Potencial subestimado:',
		nineBoxSalaryPotential1: 'Alcançou o limite salarial para sua posição e não mostra um potencial significativo para assumir maiores responsabilidades em um futuro próximo.',
		nineBoxSalaryPotential2: 'A disparidade entre o salário e o potencial sugere a necessidade de reavaliar as expectativas em relação a este profissional.',
		nineBoxSalaryPotential3: 'A combinação de sua capacidade para evoluir e uma remuneração superior sugere que ele é considerado uma investimento estratégico para o futuro da empresa.',
		nineBoxSalaryPotential4: 'Para estar alinhado com as expectativas do papel atual e o salário recebido, é recomendável que o profissional desenvolva as competências necessárias.',
		nineBoxSalaryPotential5: 'Sua capacidade para desempenhar suas funções e evoluir dentro da organização corresponde ao nível de remuneração que recebe.',
		nineBoxSalaryPotential6: 'O salário está alinhado com a média do mercado para sua posição, mas existe a possibilidade de ajustá-lo acima da média, dado seu potencial de crescimento, se for estratégico para a empresa.',
		nineBoxSalaryPotential7: 'Sua capacidade para assumir responsabilidades adicionais e gerar um valor significativo para a organização é limitada, resultando em uma remuneração ajustada ao seu nível de contribuição.',
		nineBoxSalaryPotential8: 'A empresa pode não estar investindo adequadamente no potencial deste colaborador.',
		nineBoxSalaryPotential9: 'Ajustar sua remuneração para alinhar melhor com seu potencial de crescimento pode motivá-lo e incentivar seu desenvolvimento.',

		nineBoxSalaryTitlePerformance1: 'Está sobrevalorizado.',
		nineBoxSalaryTitlePerformance2: 'Foi um investimento.',
		nineBoxSalaryTitlePerformance3: 'É merecedor.',
		nineBoxSalaryTitlePerformance4: 'Não cumpre com as expectativas.',
		nineBoxSalaryTitlePerformance5: 'Está Adequado.',
		nineBoxSalaryTitlePerformance6: 'Merece receber um ajuste.',
		nineBoxSalaryTitlePerformance7: 'Recebe Compensação Proporcional.',
		nineBoxSalaryTitlePerformance8: 'Apto para receber um ajuste.',
		nineBoxSalaryTitlePerformance9: 'Está Desvalorizado.',
		nineBoxSalaryPerformance1: 'Recebe um salário elevado que não se justifica por seu baixo desempenho, contribuição ou resultados.',
		nineBoxSalaryPerformance2: 'Seu salário está acima da média. Deve demonstrar um desempenho a médio prazo que justifique o investimento inicial.',
		nineBoxSalaryPerformance3: 'Tem um salário alto que está completamente justificado por seu desempenho excepcional e contribuições para a organização.',
		nineBoxSalaryPerformance4: 'Recebe um salário alinhado ao mercado, por isso deve melhorar seu desempenho para que sua compensação esteja alinhada.',
		nineBoxSalaryPerformance5: 'Sua compensação reflete adequadamente seu nível de contribuição e resultados.',
		nineBoxSalaryPerformance6: 'Demonstrou um desempenho excepcional que justifica uma compensação acima da média.',
		nineBoxSalaryPerformance7: 'O salário está de acordo com seu baixo desempenho.',
		nineBoxSalaryPerformance8: 'Embora seu desempenho atual não seja ótimo, considera-se que possui um rendimento esperado e pode gerar maior valor para a organização com o apoio econômico adequado.',
		nineBoxSalaryPerformance9: 'Embora sua contribuição e resultados superem as expectativas para seu papel, a compensação que recebe não reflete adequadamente seu valor e contribuição para a organização.',
		nineBoxSalaryPotentialNullText: 'O gráfico estará disponível assim que a avaliação do potencial do colaborador for concluída e sua faixa salarial for informada na primeira aba.',
		nineBoxSalaryPotentialDescText:
			'O gráfico Banda Salarial x Potencial avalia o potencial dos colaboradores em relação ao seu salário. Ele é dividido em 9 quadrantes, com o eixo horizontal representando o potencial (baixo, médio, alto) e o eixo vertical representando o salário (baixo, médio, alto). Este gráfico facilita a identificação de colaboradores com alto potencial que podem estar subvalorizados e necessitam de desenvolvimento ou ajustes salariais. É uma ferramenta essencial para decisões estratégicas de promoção e planejamento sucessório. Assim, promove uma gestão de talentos mais eficaz e justa.',
		nineBoxSalaryPerformanceNullText: 'O gráfico estará disponível assim que a avaliação do desempenho do colaborador for concluída e sua faixa salarial for informada na primeira aba.',
		nineBoxSalaryPerformanceDescText:
			'O gráfico Banda Salarial x Desempenho é uma ferramenta que cruza o desempenho dos colaboradores com seus salários. Ele é dividido em 9 quadrantes, com o eixo horizontal representando o desempenho (baixo, médio, alto) e o eixo vertical representando o salário (baixo, médio, alto). A análise facilita a identificação de talentos sub ou supervalorizados, ajudando na tomada de decisões sobre promoções, aumentos salariais e desenvolvimento profissional. Esse mapeamento visual auxilia na criação de estratégias de gestão mais eficazes e equitativas.',

		bandTitleText: 'Instruções para Indicação de Salário por Desempenho',
		bandNullText1:
			'No eixo vertical do gráfico abaixo está representada a faixa salarial do colaborador correspondente. Para comparar com seu desempenho (eixo horizontal), é necessário saber em qual faixa salarial do gráfico se encontra esse colaborador.',
		bandNullText2: 'Dado que você ainda não indicou o salário deste colaborador, é crucial para poder apresentar a comparação de salário por desempenho que você indique como o salário dele se situa em relação à média de mercado.',
		bandNullText3: 'Com base na média de mercado mostrada no gráfico, você deve determinar em que medida o salário do seu colaborador se encontra comparado com essa média.',
		bandText1: 'No eixo vertical do gráfico abaixo está representada a faixa salarial do colaborador correspondente, comparada com seu desempenho no eixo horizontal.',
		bandText2: 'No eixo vertical do gráfico abaixo está representada a faixa salarial do colaborador correspondente, comparada com seu desempenho no eixo horizontal.',
		bandText3: 'Baseando-se na média de mercado apresentada no gráfico, o salário do colaborador que você indicou previamente se encontra em uma das seguintes categorias:',
		bandText4: 'Agora, vamos definir a posição salarial do colaborador. Indique se o salário está:',
		bandText5: 'Caso você queira editar o salário do colaborador',
		bandText6: 'você pode fazer isso aqui.',
		bandValueNull: 'Faixa não Informada',
		bandExampleTitle: 'Exemplo:',
		bandExampleText: "Se você paga 1300 USD ao seu colaborador e a média do gráfico é de 1000 USD, você está pagando 130% da média. Portanto, deve selecionar a opção 'acima de 110%'.",
		bandBtnSave: 'Salvar',
		bandOption1: 'Abaixo de 90%',
		bandOption2: 'Entre 90% e 110%',
		bandOption3: 'Acima de 110%',

		//EvaluationReference
		useGuide: 'Guia de Uso',
		examplesOfCriteria: 'Exemplos de  Critérios',
		potential: 'Potencial',
		definition: 'Definição',
		stepsToAnalyzeResults: 'Etapas para análise dos resultados',
		evaluationAndCriteria: 'Avaliação e Critérios',
		examplesOfPerformance: 'Exemplos de indicadores',
		performanceEvaluationTitle: 'Avaliação de desempenho',
		performanceEvaluationText:
			'A avaliação de desempenho é um processo fundamental para medir e analisar a eficácia, as competências e a contribuição dos colaboradores para os objetivos da empresa. Seu objetivo é identificar pontos fortes, áreas de melhoria e estabelecer planos de desenvolvimento, influenciando as decisões de remuneração e promoção.',
		performanceCriteriaTitle: 'Critérios de desempenho',
		performanceCriteriaText:
			'Critérios de desempenho são padrões utilizados para avaliar como algo é executado ou os resultados obtidos. Esses critérios são específicos para cada cargo ou função e são projetados para refletir as expectativas e objetivos organizacionais.',
		examplesOfPerformanceIndicators: 'Exemplos de indicadores de desempenho',
		TypesOfMethods: 'Tipos de métodos',
		ConductualRatingScaleTitle: 'Escala de avaliação comportamental',
		ConductualRatingScaleText:
			'A avaliação é realizada através de pontuação de 0% a 200% e um peso para cada critério. As atividades com maior impacto nos objetivos organizacionais deverão ter maior peso. O resultado será a média ponderada entre todos os critérios avaliados.',
		OfCriticalIncidentsTitle: 'De incidentes críticos',
		OfCriticalIncidentsText:
			'Esta técnica estabelece a investigação, observação e registo das características mais salientes de um colaborador durante o desempenho de uma tarefa específica. A identificação de incidentes críticos pode ser individual ou entre o colaborador e o avaliador.',
		TypesOfMethodsList1: 'Mau desempenho',
		TypesOfMethodsList2: 'Baixa performance',
		TypesOfMethodsList3: 'Desempenho aceitável',
		TypesOfMethodsList4: 'Boa performance',
		TypesOfMethodsList5: 'O melhor desempenho possível',
		ObjectiveOfTheCriteria: 'Objetivo dos Critérios',
		ObjectiveOfTheCriteriaList1: 'Meça o desempenho dos trabalhadores',
		ObjectiveOfTheCriteriaList2: 'Ofereça feedback',
		ObjectiveOfTheCriteriaList3: 'Decida promoções',
		ObjectiveOfTheCriteriaList4: 'Promova o desempenho',
		ObjectiveOfTheCriteriaList5: 'Decida demissões',
		ObjectiveOfTheCriteriaList6: 'Estabeleça objetivos',
		ObjectiveOfTheCriteriaList7: 'Aumente a motivação',
		ObjectiveOfTheCriteriaList8: 'Reduza o mau desempenho',
		ObjectiveOfTheCriteriaList9: 'Determine compensações',
		ObjectiveOfTheCriteriaList10: 'Dirija compensações',
		ObjectiveOfTheCriteriaList11: 'Melhore o gerenciamento de recursos',
		ObjectiveOfTheCriteriaList12: 'Valide contratos',
		StepsToAnalyzePerformanceEvaluationResults: 'Etapas para análise dos resultados da Avaliação de Desempenho',
		StepsToAnalyzePerformanceEvaluationResultsList1: 'Reúna os resultados individuais de todos os funcionários',
		StepsToAnalyzePerformanceEvaluationResultsList2: 'Classifique os dados por departamento, cargo ou área de trabalho',
		StepsToAnalyzePerformanceEvaluationResultsList3: 'Identifique pontos em comum e tendências nos resultados',
		StepsToAnalyzePerformanceEvaluationResultsList4: 'Compare os resultados individuais com os objetivos estabelecidos',
		StepsToAnalyzePerformanceEvaluationResultsList5: 'Examine as áreas onde as expectativas foram superadas e aquelas que necessitam de melhorias',
		StepsToAnalyzePerformanceEvaluationResultsList6: 'Investigue as causas subjacentes aos resultados positivos e negativos',
		StepsToAnalyzePerformanceEvaluationResultsList7: 'Desenvolva planos de ação específicos para abordar áreas de melhoria',
		PostEvaluationTitle: 'Pós-avaliação',
		PostEvaluationText:
			'Após a avaliação, forneça feedback efetivo sobre os resultados a cada colaborador e monitore os objetivos estabelecidos. Ajuste o processo conforme necessário, a avaliação de desempenho deve ser um ciclo contínuo de melhoria.',
		Potential: 'Potencial',
		DefinitionTitle: 'Definição',
		DefinitionText:
			'Refere-se às capacidades futuras e ao desenvolvimento profissional esperado de um funcionário. Avalia não apenas o desempenho atual de um indivíduo na sua função atual, mas também a sua capacidade de crescer, assumir maiores responsabilidades e contribuir de forma mais significativa para a organização no futuro. A avaliação do potencial na avaliação de desempenho é crucial para uma gestão eficaz de talentos, pois permite que as organizações identifiquem e estimulem os seus futuros líderes e colaboradores principais.',
		ExamplesOfPotentialCriteria: 'Exemplos de critérios potenciais',
		WhyIsPotentialEvaluated: 'Por que o potencial é avaliado?',
		PotentialsOfAnExecutive: 'Potenciais de um executivo',
		TypesOfModels: 'Tipos de modelos,',
		PsychologicalEvaluationsTitle: 'Avaliações psicológicas,',
		PsychologicalEvaluationsText: 'Esses tipos de avaliações nos permitem determinar o potencial oculto de um funcionário. Isso é feito analisando o desempenho futuro de um funcionário, e não o trabalho anterior. Como se faz?',
		AssessmentTitle: 'avaliação 360',
		AssessmentText:
			'Uma matriz 3x3, que forma nove quadrantes ou caixas. Num eixo é avaliado o desempenho dos colaboradores, geralmente numa escala de baixo a alto, e no outro eixo é avaliado o seu potencial de desenvolvimento (avaliação 360), novamente numa escala de baixo a alto. Os nove quadrantes resultantes são usados ​​para categorizar funcionários',
		AssessmentPuzzleTitle: 'Enigma',
		AssessmentPuzzleText:
			'Os funcionários desta categoria têm um desempenho atual que pode ser difícil de avaliar com clareza, muitas vezes devido a circunstâncias externas ou fatores ambíguos. Uma análise mais detalhada pode ser necessária para compreender melhor a sua contribuição e potencial.',
		AssessmentStrongPerformanceTitle: 'Crescimento',
		AssessmentStrongPerformanceText: 'Embora não atinjam necessariamente o nível mais alto de desempenho atual, esses colaboradores apresentam forte desempenho e têm potencial significativo para crescer e se desenvolver ainda mais na organização.',
		AssessmentHighImpactTitle: 'Alto Impacto',
		AssessmentHighpotentialTitle: 'Futuro Líder',
		AssessmentHighpotentialText: 'Esses funcionários apresentam excelente desempenho atual e têm alto potencial para funções mais desafiadoras ou de liderança no futuro. São considerados os talentos mais promissores da organização.',
		AssessmentQuestionableTitle: 'Questionável',
		AssessmentQuestionableText:
			'Este perfil indica o desempenho atual que pode ser inconsistente ou insatisfatório, juntamente com um potencial incerto para melhorias significativas no futuro. Eles exigem avaliação adicional e possivelmente intervenção para determinar sua trajetória dentro da empresa.',
		AssessmentMaintainerTitle: 'Mantenedor',
		AssessmentMaintainerText1:
			'São funcionários que apresentam desempenho atual consistente e confiável, mas com potencial limitado para funções mais desafiadoras ou de liderança. Freqüentemente, são atribuídas a eles tarefas cruciais para manter a estabilidade operacional dentro da organização.',
		AssessmentSpecialistTitle: 'Especialista',
		AssessmentMaintainerText2:
			'Esses colaboradores não apenas atendem às expectativas em seu desempenho atual, mas também demonstram um alto nível de comprometimento e dedicação ao trabalho e aos objetivos da organização. Além de serem eficazes, mostram um potencial promissor para assumir maiores responsabilidades e contribuir significativamente para o crescimento e sucesso da empresa.',
		AssessmentInsufficientTitle: 'Insuficiente',
		AssessmentInsufficientText:
			'Esses colaboradores apresentam um desempenho atual que não atende às expectativas exigidas. Apresentam um desempenho inferior e um potencial limitado para melhorar significativamente no futuro sem intervenção e desenvolvimento intensivos.',
		AssessmentEffectiveTitle: 'Eficaz',
		AssessmentEffectiveText:
			'São funcionários que atendem consistentemente às expectativas em seu desempenho atual. Desempenham as suas tarefas de forma competente e satisfatória, mas podem não se destacar excepcionalmente ou demonstrar um potencial extraordinário para funções de maior responsabilidade no futuro imediato.',
		WhatIsThe9boxToolForTitle: 'Para que serve a ferramenta 9box?',
		WhatIsThe9boxToolForText:
			'Ajuda os líderes e profissionais de RH a tomar decisões informadas sobre o desenvolvimento da sua força de trabalho, identificando os funcionários que necessitam de apoio adicional e aqueles que estão prontos para assumir funções de maior responsabilidade. Isso contribui para o planejamento sucessório e para o crescimento sustentável da organização.',
		AdvantagesOfThe9BoxMatrix: 'Vantagens da Matriz de 9 Caixas',
		AdvantagesOfThe9BoxMatrixList1: 'É visual. É simples e objetivo.',
		AdvantagesOfThe9BoxMatrixList2: 'Excelente suporte na busca de substitutos.',
		AdvantagesOfThe9BoxMatrixList3: 'Valor histórico.',

		/** Assessment / WithMeGrow */
		competencyTest: 'Teste de Competências',
		questionsDescription: 'Aqui você encontrará as perguntas para conhecer o nível de desenvolvimento de suas competências. Pedimos que selecione, em cada afirmação, o grau de concordância ou discordância que você tem com cada uma delas.',
		responseOptions: 'Opções de resposta:',
		veryDisagreeable: 'Discordo totalmente',
		inDisagreement: 'Discordo',
		neutral: 'Neutro',
		iAgree: 'Concordo',
		iAgreeVeryMuch: 'Concordo totalmente',
		tryNotToRespondInNeutral: 'Tente não responder neutro',
		answerHonestly: 'Responda com sinceridade',
		itsLessThan25Minutes: 'Leva menos de 25 minutos',
		saveAndFollow: 'Salvar e Continuar',
		conclude: 'Concluir',
		progress: 'Progresso',
		steps: '/16 Etapas',
		back: 'Voltar',
		next: 'Avançar',
		fortresses: 'Fortalezas',
		improvementOpportunities: 'Oportunidades de Melhoria',
		readMore: 'ler mais'
	},
}
