import { Modal, Button } from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";
import { modalAssignTranslate } from "../../../../../i18n/translatedMessages";

const ModalConfirmDel = ({ toShow, toClose, handleDelete }) => {
    return (
        <Modal
            show={toShow}
            onHide={toClose}
            backdrop="static"
            keyboard={false}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable={true}
            className="modal-assign"
        >
            <Modal.Header closeButton>
                <Modal.Title>{modalAssignTranslate.confirmDelete}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container assign-delete-modal">
                    <div className="row justify-content-center">
                        <div className="col-2 text-center">
                            <AiFillCloseCircle
                                size={50}
                                className="assign-mark-del"
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={toClose}>
                    {modalAssignTranslate.cancelButton}
                </Button>
                <Button
                    className="assign-delete-modal-btn"
                    onClick={handleDelete}
                >
                    {modalAssignTranslate.deleteButton}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalConfirmDel;
