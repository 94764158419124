import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";
import { questionsSalida } from "./Components/QuestionsAndAnswers/dataQSalida";
import { FormacionIcon } from "../../Icons/CollaboratorsIcons";
import ClimaQuestions from "./Components/QuestionsAndAnswers/ClimaQuestions";

import "./Components/QuestionsAndAnswers/styles.css";

const ClimaSalida = () => {
    const [allQuestions, setAllQuestions] = useState([]);
    useEffect(() => {
        const bringQuestion = questionsSalida;
        setAllQuestions([...bringQuestion]);
    }, []);

    return (
        <div>
            <div className="row align-items-center">
                <div className="col-1">
                    <span className="title-icon margin-icon">
                        <FormacionIcon size={10} />
                    </span>
                </div>
                <div className="col-3">
                    <h2>Encuesta de Salida</h2>
                </div>
            </div>

            <div className="line-visual"></div>
            <div className="centralize-card">
                <ClimaQuestions questions={allQuestions} surveyType='1'/>
                <div className="col-12 mt-3">
                    <p>
                        IMPORTANTE: Tu Empresa no tendrá acceso a tus
                        respuestas, solamente a la media entre los
                        colaboradores, siempre y cuando más de 10 colaboradores
                        hayan respondido la encuesta, con el objetivo de
                        mantener la confidencialidad.
                    </p>
                </div>
                <div className="col-12 mt-5">
                    <Link
                        className="button-volver color-blue"
                        to={"/employee/climaycultura"}
                    >
                        <MdArrowBack size={20} />
                        Volver
                    </Link>
                </div>
            </div>
        </div>
    );
};
export default ClimaSalida;
