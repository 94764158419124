import { useState } from "react";
import { Link } from "react-router-dom";
import { MdArrowForward } from "react-icons/md";

const MotivosSalida = () => {
  const limit = 3;
  const [checkLimit, setCheckLimit] = useState({
    currentChecked: 0,
  });
  const [dataSend, setDataSend] = useState([]);
  const selectData = (e) => {
    const isSelected = e.target.checked;
    if (isSelected) {
      if (checkLimit.currentChecked < limit) {
        setCheckLimit({ currentChecked: checkLimit.currentChecked + 1 });
        setDataSend([...dataSend, { [e.target.id]: e.target.checked }]);
      } else {
        e.preventDefault();
        e.target.checked = false;
      }
    } else {
      setCheckLimit({
        currentChecked: checkLimit.currentChecked - 1,
      });
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 text-center">
          <h2>O que te motivou a sair da empresa?</h2>
          <h4>(Escolher apenas 3 principais motivos)</h4>
        </div>
      </div>
      <div className="row pt-5">
        <div className="col-md-6">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="salario"
              id="salario"
              onChange={(e) => selectData(e, "salario")}
            //disabled={isDisabled("salario")}
            />
            <label className="form-check-label" htmlFor="salario">
              <h4>SALARIOS Y BENEFICIOS</h4>
              <p>
                (não atenderam às minhas expectativas ou estiveram desalinhados
                às minhas funções)
              </p>
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="actividades"
              id="actividades"
              onChange={(e) => selectData(e, "actividades")}
            //disabled={isDisabled("actividades")}
            />
            <label className="form-check-label" htmlFor="actividades">
              <h4>ACTIVIDADES Y FUNCIONES REALIZADAS</h4>
              <p>
                (tarefas não convieram ao cargo, não houve ferramentas
                suficientes ou não fiquei satisfeito com as metodologias
                utilizadas)
              </p>
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="recursos"
              id="recursos"
              onChange={(e) => selectData(e, "recursos")}
            //disabled={isDisabled("recursos")}
            />
            <label className="form-check-label" htmlFor="recursos">
              <h4>RECURSOS, MEDIO AMBIENTE Y CULTURA</h4>
              <p>
                (não convieram com meu perfil pessoal ou recursos não foram
                adequados)
              </p>
            </label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="oportunidad"
              id="oportunidad"
              onChange={(e) => selectData(e, "oportunidad")}
            //disabled={isDisabled("oportunidad")}
            />
            <label className="form-check-label" htmlFor="oportunidad">
              <h4>OPORTUNIDAD Y CARRERA</h4>
              <p>
                (não houve oportunidades alinhadas ao meu objetivos, não houve
                abertura para aplicação ou não existiu clareza quanto ao meu
                desenvolvimento pessoal)
              </p>
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="liderazgo"
              id="liderazgo"
              onChange={(e) => selectData(e, "liderazgo")}
            //disabled={isDisabled("liderazgo")}
            />
            <label className="form-check-label" htmlFor="liderazgo">
              <h4>LIDERAZGO</h4>
              <p>
                (não houve suporte, bom relacionamento ou
                comunicação/concordância com minha liderança)
              </p>
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="outros"
              id="outros"
              onChange={(e) => selectData(e, "outros")}
            //disabled={isDisabled("outros")}
            />
            <label className="form-check-label" htmlFor="outros">
              <h4>OUTROS</h4>
              <p>
                (motivos pessoais, localização,formato de trabalho, mudança de
                cidade, empreender ou outros, que não tenham relação total ou
                direta com a empresa)
              </p>
            </label>
          </div>
        </div>
      </div>
      <div className="row pt-5">
        <div className="col-12 text-end">
          <Link className="button-volver color-blue" to={"/employee/salida"}>
            Seguir
            <MdArrowForward size={20} />
          </Link>
        </div>
      </div>
    </div>
  );
};
export default MotivosSalida;
