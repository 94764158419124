// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.askBox {
  width: 100%;
  display: flex;
  height: 200px;
  background: #f9f9f9;
  border: 1px solid #8f8e8e;
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.04), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  padding: 0;
}
.askBox .question, .askBox .answer {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.askBox .question span, .askBox .answer span {
  font-weight: 500;
}
.askBox .question {
  align-items: center;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  color: #f9f9f9;
  padding: 35px 15px;
}
.askBox .answer {
  display: flex;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/Views/Employee/Components/Quiz/quiz.sass"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,aAAA;EAEA,mBAAA;EACA,yBAAA;EACA,uIAAA;EACA,kBAAA;EACA,UAAA;AAAJ;AAEI;EACI,UAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAAR;AACQ;EACI,gBAAA;AACZ;AACI;EACI,mBAAA;EACA,uBAAA;EACA,gBAAA;EACA,eAAA;EACA,cAAA;EACA,kBAAA;AACR;AACI;EACI,aAAA;EACA,sBAAA;AACR","sourcesContent":[".askBox\n    width: 100%\n    display: flex\n    height: 200px\n\n    background: #f9f9f9\n    border: 1px solid #8f8e8e\n    box-shadow: 0px 100px 80px rgb(0 0 0 / 7%), 0px 12.5216px 10.0172px rgb(0 0 0 / 4%), 0px 6.6501px 5.32008px rgb(0 0 0 / 3%)\n    border-radius: 6px\n    padding: 0\n\n    .question, .answer\n        width: 50%\n        height: 100%\n        display: flex\n        justify-content: center\n        align-items: center\n        span\n            font-weight: 500\n\n    .question\n        align-items: center\n        font-family: Montserrat\n        font-weight: 600\n        font-size: 16px\n        color: #f9f9f9\n        padding: 35px 15px\n\n    .answer\n        display: flex\n        flex-direction: column\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
