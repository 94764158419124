// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-links-industry .modal-header {
  background-color: white;
  border-radius: 15px 15px 0px 0px;
}
.modal-links-industry .modal-title {
  width: 100%;
  color: #6E68AF;
}
.modal-links-industry .modal-content {
  border-radius: 15px;
}
.modal-links-industry .btn-close {
  cursor: pointer;
}
.modal-links-industry button {
  color: white;
  background-color: #7A6BE8;
}
.modal-links-industry button:hover {
  background-color: #5F52C4;
  color: white;
}
.modal-links-industry .color-blue {
  color: rgb(69, 185, 235);
}
.modal-links-industry .input-zoom {
  width: 350px;
  font-weight: bold;
}
.modal-links-industry .input-range-scale {
  width: 350px;
  height: 16px;
  background: #111;
  border-radius: 15px;
  overflow: hidden;
  margin: 5px 0 25px 0;
}
.modal-links-industry .input-range-scale::-webkit-slider-runnable-track {
  height: 0;
}
.modal-links-industry .input-range-scale::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
  margin-top: -8px;
  padding: 0;
  border-radius: 50%;
  background: rgb(69, 185, 235);
  cursor: pointer;
  border: 4px solid #fefefe;
  box-shadow: -407px 0 0 400px rgb(69, 185, 235);
}`, "",{"version":3,"sources":["webpack://./src/Views/Services/Components/PerfilIndustry/ModalLinks/modalLinks.sass"],"names":[],"mappings":"AACI;EACI,uBAAA;EACA,gCAAA;AAAR;AACI;EACI,WAAA;EACA,cAAA;AACR;AAAI;EACI,mBAAA;AAER;AAAI;EACI,eAAA;AAER;AAAI;EACI,YAAA;EACA,yBAAA;AAER;AADQ;EACI,yBAAA;EACA,YAAA;AAGZ;AADI;EACI,wBAAA;AAGR;AADI;EACI,YAAA;EACA,iBAAA;AAGR;AADI;EACI,YAAA;EACA,YAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;EACA,oBAAA;AAGR;AADQ;EACI,SAAA;AAGZ;AADQ;EACI,wBAAA;EACA,UAAA;EACA,WAAA;EACA,gBAAA;EACA,UAAA;EACA,kBAAA;EACA,6BAAA;EACA,eAAA;EACA,yBAAA;EACA,8CAAA;AAGZ","sourcesContent":[".modal-links-industry\n    & .modal-header\n        background-color: white\n        border-radius: 15px 15px 0px 0px\n    & .modal-title\n        width: 100%\n        color: #6E68AF\n    & .modal-content\n        border-radius: 15px\n\n    & .btn-close\n        cursor: pointer\n\n    & button\n        color: white\n        background-color: #7A6BE8\n        &:hover\n            background-color: #5F52C4\n            color: white\n\n    & .color-blue\n        color: rgb(69, 185, 235)\n\n    & .input-zoom\n        width: 350px\n        font-weight: bold\n\n    & .input-range-scale\n        width: 350px\n        height: 16px\n        background: #111\n        border-radius: 15px\n        overflow: hidden\n        margin: 5px 0 25px 0\n\n        &::-webkit-slider-runnable-track\n            height: 0\n\n        &::-webkit-slider-thumb\n            -webkit-appearance: none\n            width: 8px\n            height: 8px\n            margin-top: -8px\n            padding: 0\n            border-radius: 50%\n            background: rgb(69, 185, 235)\n            cursor: pointer\n            border: 4px solid #fefefe\n            box-shadow: -407px 0 0 400px rgb(69, 185, 235)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
