export const postRecruitment = async (data) => {
	try {
		const resp = await fetch(`${process.env.REACT_APP_API}/recruitment`, {
			method: "POST",
			headers: {
				"Content-type": "application/json",
			},
			body: JSON.stringify(data),
		});
		return resp;
	} catch (err) {
		console.error(err);
	}
};

export const getRecruitmentByCompanyAndTitle = async (companyId, title) => {
	try {
		const resp = await fetch(
			`${process.env.REACT_APP_API}/recruitment/${companyId}?category=${title}`
		);
		return resp.json();
	} catch (err) {
		console.error(err);
	}
};

export const putRecruitment = async (data, companyId) => {
	try {
		await fetch(
			`${process.env.REACT_APP_API}/recruitment/${companyId}`,
			{
				method: "PUT",
				headers: {
					"Content-type": "application/json",
				},
				body: JSON.stringify(data),
			}
		);

	} catch (err) {
		console.error(err);
	}
};

export const deleteRecruitment = async (recruitId) => {
	try {
		const resp = await fetch(
			`${process.env.REACT_APP_API}/recruitment/${recruitId}`,
			{
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
		return resp.json();
	} catch (err) {
		console.error(err);
	}
};
