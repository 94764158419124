export function validation(data, errors) {
    Object.keys(errors).forEach(key => {
        errors = {
            ...errors,
            [key]: data[key] === '' ? true : false
        }
    })
    const validated = !Object.values(errors).includes(true)

    return [errors, validated]
} 