import { matchIndustryForSmtm } from "../../Views/Service/Views/PeopleAnalytics/Management/ParseMydnaDataToSmtmData"

export const signin = async (body) => {
    try {
        const resposta = await fetch(`${process.env.REACT_APP_API}/user/signin`,
            {
                method: "POST",
                body: JSON.stringify(body),
                mode: 'cors',
                headers: {
                    "Content-type": "application/json",
                }
            })
        return await resposta.json()
    } catch (error) {
        console.error(error)
    }
}

export const signup = async (body) => {
    try {
        const resposta = await fetch(`${process.env.REACT_APP_API}/user/signup/company`,
            {
                method: "POST",
                body: JSON.stringify(body),
                mode: 'cors',
                headers: {
                    "Content-type": "application/json",
                }
            })
        return await resposta
    } catch (error) {
        console.error(error)
    }
}

export const signupSmtm = async (body) => {
    let industry_id_smtm = matchIndustryForSmtm(body.industry)
    try {
        const resposta = await fetch(`https://smtm.co/v1/companies?name=${body.company}&industry=${industry_id_smtm}&country=${body.countryId}&company_size=${body.size}&email=${body.email}&mydna=mydna&mydna_id=${body.id}`,
            {
                method: "POST",
                mode: 'cors',
                headers: {
                    "Content-type": "application/json",
                }
            })
        return await resposta
    } catch (error) {
        console.error(error)
    }
}

export const signupEmployee = async (body) => {
    try {
        const resposta = await fetch(`${process.env.REACT_APP_API}/user/signup/`,
            {
                method: "PUT",
                body: JSON.stringify(body),
                mode: 'cors',
                headers: {
                    "Content-type": "application/json",
                }
            })
        return await resposta
    } catch (error) {
        console.error(error)
    }
}

