export const links = [
  {
    id: 1,
    name: "Coursera",
    link: "https://www.coursera.org/",
  },
  {
    id: 2,
    name: "Lynda",
    link: "https://www.lynda.com.cach3.com/",
  },
  {
    id: 3,
    name: "EDX",
    link: "https://www.edx.org/",
  },
  {
    id: 5,
    name: "Platzi",
    link: "https://platzi.com/",
  },
  {
    id: 6,
    name: "Udemy",
    link: "https://www.udemy.com/",
  },
  {
    id: 7,
    name: "Crehana",
    link: "https://www.crehana.com/cl/",
  },
  {
    id: 8,
    name: "Domestika",
    link: "https://www.domestika.org/es",
  },
  {
    id: 9,
    name: "Tutellus",
    link: "https://formacion.tutellus.com/",
  },
  {
    id: 10,
    name: "eMagister Express",
    link: "https://www.emagister.co.u",
  },
  {
    id: 11,
    name: "Keep Coding",
    link: "https://keepcoding.io/",
  },
  {
    id: 12,
    name: "Future Learn",
    link: "https://www.futurelearn.com/",
  },
  {
    id: 13,
    name: "Linkedln learning",
    link: "https://www.linkedin.com/learning/?trk=aff-ir_progid%3D8005_partid%3D34020_sid%3D_adid%3D459087",
  },
  {
    id: 14,
    name: "UniMOOC",
    link: "https://unimooc.com/",
  },
  {
    id: 15,
    name: "UNED",
    link: "http://ocw.innova.uned.es/ocwuniversia",
  },
  {
    id: 16,
    name: "Activate",
    link: "https://learndigital.withgoogle.com/activate/",
  },
  {
    id: 17,
    name: "Capacitate para el empleo",
    link: "https://capacitateparaelempleo.org/",
  },
  {
    id: 18,
    name: "Grupo 5",
    link: "https://www.grupo5.cl/",
  },
  {
    id: 19,
    name: "Enovus",
    link: "https://www.enovus.cl/",
  },
  //   {
  //     id: 20,
  //     name: "Practicum",
  //     link: "https://practicum.com/es-chl/?gclid=Cj0KCQjwsrWZBhC4ARIsAGGUJurEi_mxF0S4kLzkIAz13zPJ478h5CR0BgZ9PPC6xDhf_NHZV557fQ0aAi_xEALw_wcB",
  //   },
  {
    id: 21,
    name: "Coding Dojo",
    link: "https://www.codingdojo.com/",
  },
  {
    id: 22,
    name: "Tech Universidad Tecnológica",
    link: "https://www.techtitute.com/cl/",
  },
  {
    id: 23,
    name: "Escuela de Securos de Chile",
    link: "https://escueladeseguros.cl/vitrina?business_line=cursos-online&gclid=Cj0KCQjwsrWZBhC4ARIsAGGUJurVcXf0gDaBDCd99qAlOKcbDISvGdjGHxxaH1aeS1Qo6YG6C6-8NXMaArrREALw_wcB",
  },
  {
    id: 24,
    name: "IST Educa",
    link: "https://ist-educa.cl/",
  },
  {
    id: 25,
    name: "Educa",
    link: "https://e-learning.global/",
  },
  {
    id: 26,
    name: "CoderHouse",
    link: "https://www.coderhouse.cl/",
  },
  {
    id: 27,
    name: "Open English",
    link: "https://www.openenglish.com/",
  },
  {
    id: 28,
    name: "SkillShare",
    link: "https://www.skillshare.com/",
  },
  //   {
  //     id: 29,
  //     name: "Academia Ágil",
  //     link: "https://academiaagil.com/",
  //   },
  {
    id: 30,
    name: "OKR Learning",
    link: "https://www.okrlearning.com/",
  },
  {
    id: 31,
    name: "CognosOnline",
    link: "https://cognosonline.com/cl/",
  },
  {
    id: 32,
    name: "Dexway",
    link: "https://www.dexway.com/",
  },
  {
    id: 33,
    name: "Emeritus",
    link: "https://emeritus.org/",
  },
  {
    id: 34,
    name: "Digital Career Institute",
    link: "https://digitalcareerinstitute.org/",
  },
  {
    id: 35,
    name: "The hague Academy for Local Governance",
    link: "https://thehagueacademy.com/course/",
  },
  {
    id: 36,
    name: "MIT (emeritus collaboration)",
    link: "https://executive-ed.xpro.mit.edu/professional-certificate-coding?utm_source=Google&utm_medium=c&utm_term=coding&utm_location=1003325&utm_campaign=B-365D_WW_GG_SE_PCC_RLSA_Stage2&utm_content=Coding___School_Duration&gclid=Cj0KCQjwsrWZBhC4ARIsAGGUJuqqGmsOjR0oeehVtaaRJAdSgz7SHnXc6ubnnwjE5ieuApQWLBRTabEaAvt3EALw_wcB",
  },
  {
    id: 37,
    name: "Datacamp",
    link: "https://www.datacamp.com/courses-all",
  },
  {
    id: 38,
    name: "Codecademy",
    link: "https://www.codecademy.com/",
  },
  {
    id: 39,
    name: "+Babbel",
    link: "https://es.babbel.com/",
  },
  {
    id: 40,
    name: "Lingopie",
    link: "https://try.lingopie.com/",
  },
  {
    id: 41,
    name: "Mondly",
    link: "https://www.mondly.com/",
  },
  {
    id: 42,
    name: "Pimsleur",
    link: "https://offers.pimsleur.com/free-trial-14041-intl/lp-2-591Z9-2134842.html?",
  },
  {
    id: 43,
    name: "Preply",
    link: "https://preply.com/",
  },
  {
    id: 44,
    name: "Rocketlanguages",
    link: "https://www.rocketlanguages.com/",
  },
  {
    id: 45,
    name: "Rosetta Stone",
    link: "https://www.rosettastone.com/",
  },
  {
    id: 46,
    name: "Edureka!",
    link: "https://www.edureka.co/",
  },
  {
    id: 47,
    name: "CreativeLive",
    link: "https://www.creativelive.com/?via=site-header_0",
  },
  {
    id: 48,
    name: "Udacity",
    link: "https://www.udacity.com/",
  },
  {
    id: 49,
    name: "Acadium",
    link: "https://acadium.com/",
  },
  {
    id: 50,
    name: "PluralSight",
    link: "https://www.pluralsight.com/",
  },
  {
    id: 51,
    name: "Expertrating",
    link: "https://www.expertrating.com/",
  },
  {
    id: 52,
    name: "Excel Everest",
    link: "https://exceleverest.com/",
  },
  {
    id: 53,
    name: "Class Central",
    link: "https://www.classcentral.com/",
  },
  {
    id: 54,
    name: "Amazon Web Services",
    link: "https://aws.amazon.com/es/",
  },
  {
    id: 55,
    name: "Swayam",
    link: "https://swayam.gov.in/",
  },
  {
    id: 56,
    name: "bootcamp institute",
    link: "https://bootcamp-institute.com/",
  },
  {
    id: 57,
    name: "Netec",
    link: "https://www.netec.com/",
  },
  {
    id: 58,
    name: "Miriadax",
    link: "https://miriadax.net/",
  },
  {
    id: 59,
    name: "France Université Numérique",
    link: "https://www.fun-mooc.fr/en/courses/?limit=21&offset=0",
  },
  {
    id: 60,
    name: "Canvas Network",
    link: "https://www.canvas.net/",
  },
  {
    id: 61,
    name: "Treehouse",
    link: "https://teamtreehouse.com/",
  },
  {
    id: 62,
    name: "Open SAP",
    link: "https://open.sap.com/",
  },
  {
    id: 63,
    name: "Kadenze",
    link: "https://www.kadenze.com/",
  },
  {
    id: 64,
    name: "MasterClass",
    link: "https://www.masterclass.com/",
  },
  {
    id: 65,
    name: "The Great Courses",
    link: "https://www.thegreatcourses.com/",
  },
  {
    id: 66,
    name: "iversity",
    link: "https://iversity.org/",
  },
  {
    id: 67,
    name: "MIT Opencourseware",
    link: "https://ocw.mit.edu/",
  },
  {
    id: 68,
    name: "OpenHPI",
    link: "https://open.hpi.de/",
  },
  {
    id: 69,
    name: "Polimi Open Knowledge",
    link: "https://www.pok.polimi.it/",
  },
  {
    id: 70,
    name: "NovoEd",
    link: "https://www.novoed.com/",
  },
  {
    id: 71,
    name: "Test Automation University",
    link: "https://testautomationu.applitools.com/",
  },
  {
    id: 72,
    name: "OpenLearning",
    link: "https://www.openlearning.com/",
  },
  {
    id: 73,
    name: "World Science U",
    link: "https://worldscienceu.com/courses/",
  },
  {
    id: 74,
    name: "Stepik",
    link: "https://stepik.org/catalog",
  },
  {
    id: 75,
    name: "Scrimba",
    link: "https://scrimba.com/allcourses",
  },
  {
    id: 76,
    name: "OpenClassrooms",
    link: "https://openclassrooms.com/en/paths",
  },
  {
    id: 77,
    name: "Symfony Casts",
    link: "https://symfonycasts.com/",
  },
  {
    id: 78,
    name: "MongoDB",
    link: "https://university.mongodb.com/courses/catalog",
  },
  {
    id: 79,
    name: "Efico Academy",
    link: "https://efiko.academy/",
  },
  {
    id: 80,
    name: "Landscape Academy",
    link: "https://academy.globallandscapesforum.org/",
  },
  {
    id: 81,
    name: "Acumen Academy",
    link: "https://acumenacademy.org/courseCatalog/",
  },
  {
    id: 82,
    name: "MathWorks",
    link: "https://www.mathworks.com/",
  },
  {
    id: 83,
    name: "Complexity Explorer - Santa Fe Institute",
    link: "https://www.complexityexplorer.org/",
  },
  {
    id: 84,
    name: "MRUniversity",
    link: "https://mru.org/",
  },
  {
    id: 85,
    name: "kaggle",
    link: "https://www.kaggle.com/",
  },
  {
    id: 86,
    name: "Sadie Valeri Atelier",
    link: "https://sadievaleriatelier.net/all-courses/",
  },
  {
    id: 87,
    name: "JuliaAcademy",
    link: "https://juliaacademy.com/courses",
  },
  {
    id: 88,
    name: "Cisco Networking Academy",
    link: "https://www.cisco.com/c/m/en_sg/partners/cisco-networking-academy/index.html",
  },
  {
    id: 89,
    name: "Jovian",
    link: "https://www.jovian.ai/",
  },
  {
    id: 90,
    name: "The Odin Project",
    link: "https://www.theodinproject.com/",
  },
  {
    id: 91,
    name: "Simplilearn",
    link: "https://www.simplilearn.com/",
  },
  {
    id: 92,
    name: "EU Business School",
    link: "https://online.euruni.edu/",
  },
  {
    id: 93,
    name: "International University of Applied Sciences",
    link: "https://www.iu.org/",
  },
  {
    id: 94,
    name: "University of the people",
    link: "https://www.uopeople.edu/?utm_source=google&utm_medium=cpc&utm_campaign=ggl-search_generic_latam&utm_term=%2Bonline%20%2Buniversity&utm_content=93920335431&gclid=Cj0KCQjwsrWZBhC4ARIsAGGUJurWkVAqhh8CshcHMgtqSfp9EPNerZti2xuC-bGrMMsfKowhU4Bxbs8aAkADEALw_wcB",
  },
  {
    id: 95,
    name: "London College of Professional Studies",
    link: "https://lcps.org.uk/",
  },
  {
    id: 96,
    name: "University of Bath",
    link: "https://online.bath.ac.uk/online-courses?cmgfrm=https%3A%2F%2Fwww.google.com%2F",
  },
  {
    id: 97,
    name: "highfield",
    link: "https://www.highfieldqualifications.com/",
  },
  {
    id: 98,
    name: "University of Essex Online",
    link: "https://online.essex.ac.uk/",
  },
  {
    id: 99,
    name: "360 Training",
    link: "https://www.360training.com/",
  },
];
