import { useLocation } from 'react-router-dom'
import { references } from '../../i18n/translatedMessages'

export function EvaluationMenu() {
	const hash = useLocation().hash
	return (
		<div className='tw-fixed tw-w-auto'>
			<span className='tw-font-bold'>{references.performanceTitle}</span>
			<ul className='tw-list-none tw-px-0 tw-mt-4 tw-mb-8'>
				<li className={`tw-px-2 tw-py-1`} style={{ borderLeft: `2px solid ${hash === '#Evaluation&Criteria' || hash === '' ? '#7A6BE8' : '#E1E8F0'}` }}>
					<a href='#Evaluation&Criteria' className={`${hash === '#Evaluation&Criteria' || hash === '' ? 'tw-text-[#0F172A]' : 'tw-text-[#94A3B8]'}`}>
						{references.evaluationAndCriteria}
					</a>
				</li>
				<li className={`tw-px-2 tw-py-1 `} style={{ borderLeft: `2px solid ${hash === '#ExamplesIndicators' ? '#7A6BE8' : '#E1E8F0'}` }}>
					<a href='#ExamplesIndicators' className={`${hash === '#ExamplesIndicators' ? 'tw-text-[#0F172A]' : 'tw-text-[#94A3B8]'}`}>
						{references.examplesOfPerformance}
					</a>
				</li>
				<li className={`tw-px-2 tw-py-1 `} style={{ borderLeft: `2px solid ${hash === '#TypesOfMethods' ? '#7A6BE8' : '#E1E8F0'}` }}>
					<a href='#TypesOfMethods' className={`${hash === '#TypesOfMethods' ? 'tw-text-[#0F172A]' : 'tw-text-[#94A3B8]'}`}>
						{references.TypesOfMethods}
					</a>
				</li>
				<li className={`tw-px-2 tw-py-1 `} style={{ borderLeft: `2px solid ${hash === '#ObjectiveCriteria' ? '#7A6BE8' : '#E1E8F0'}` }}>
					<a href='#ObjectiveCriteria' className={`${hash === '#ObjectiveCriteria' ? 'tw-text-[#0F172A]' : 'tw-text-[#94A3B8]'}`}>
						{references.ObjectiveOfTheCriteria}
					</a>
				</li>
				<li className={`tw-px-2 tw-py-1 `} style={{ borderLeft: `2px solid ${hash === '#AnalyzeResults' ? '#7A6BE8' : '#E1E8F0'}` }}>
					<a href='#AnalyzeResults' className={`${hash === '#AnalyzeResults' ? 'tw-text-[#0F172A]' : 'tw-text-[#94A3B8]'}`}>
						{references.stepsToAnalyzeResults}
					</a>
				</li>
				<li className={`tw-px-2 tw-py-1 `} style={{ borderLeft: `2px solid ${hash === '#PostEvaluation' ? '#7A6BE8' : '#E1E8F0'}` }}>
					<a href='#PostEvaluation' className={`${hash === '#PostEvaluation' ? 'tw-text-[#0F172A]' : 'tw-text-[#94A3B8]'}`}>
						{references.PostEvaluationTitle}
					</a>
				</li>
			</ul>
			<span className='tw-font-bold'>{references.potential}</span>
			<ul className='tw-list-none tw-px-0 tw-mt-4'>
				<li className={`tw-px-2 tw-py-1 `} style={{ borderLeft: `2px solid ${hash === '#Definition' ? '#7A6BE8' : '#E1E8F0'}` }}>
					<a href='#Definition' className={`${hash === '#Definition' ? 'tw-text-[#0F172A]' : 'tw-text-[#94A3B8]'}`}>
						{references.definition}
					</a>
				</li>
				<li className={`tw-px-2 tw-py-1 `} style={{ borderLeft: `2px solid ${hash === '#ExamplesOfCriteria' ? '#7A6BE8' : '#E1E8F0'}` }}>
					<a href='#ExamplesOfCriteria' className={`${hash === '#ExamplesOfCriteria' ? 'tw-text-[#0F172A]' : 'tw-text-[#94A3B8]'}`}>
						{references.examplesOfCriteria}
					</a>
				</li>
				<li className={`tw-px-2 tw-py-1 `} style={{ borderLeft: `2px solid ${hash === '#EvaluatePotential' ? '#7A6BE8' : '#E1E8F0'}` }}>
					<a href='#EvaluatePotential' className={`${hash === '#EvaluatePotential' ? 'tw-text-[#0F172A]' : 'tw-text-[#94A3B8]'}`}>
						{references.WhyIsPotentialEvaluated}
					</a>
				</li>
				<li className={`tw-px-2 tw-py-1 `} style={{ borderLeft: `2px solid ${hash === '#PotentialsExecutive' ? '#7A6BE8' : '#E1E8F0'}` }}>
					<a href='#PotentialsExecutive' className={`${hash === '#PotentialsExecutive' ? 'tw-text-[#0F172A]' : 'tw-text-[#94A3B8]'}`}>
						{references.PotentialsOfAnExecutive}
					</a>
				</li>
				<li className={`tw-px-2 tw-py-1 `} style={{ borderLeft: `2px solid ${hash === '#TypeOfModels' ? '#7A6BE8' : '#E1E8F0'}` }}>
					<a href='#TypeOfModels' className={`${hash === '#TypeOfModels' ? 'tw-text-[#0F172A]' : 'tw-text-[#94A3B8]'}`}>
						{references.TypesOfModels}
					</a>
				</li>
				<li className={`tw-px-2 tw-py-1 `} style={{ borderLeft: `2px solid ${hash === '#Evaluation360' ? '#7A6BE8' : '#E1E8F0'}` }}>
					<a href='#Evaluation360' className={`${hash === '#Evaluation360' ? 'tw-text-[#0F172A]' : 'tw-text-[#94A3B8]'}`}>
						{references.AssessmentTitle}
					</a>
				</li>
				<li className={`tw-px-2 tw-py-1 `} style={{ borderLeft: `2px solid ${hash === '#9box' ? '#7A6BE8' : '#E1E8F0'}` }}>
					<a href='#9box' className={`${hash === '#9box' ? 'tw-text-[#0F172A]' : 'tw-text-[#94A3B8]'}`}>
						{references.WhatIsThe9boxToolForTitle}
					</a>
				</li>
				<li className={`tw-px-2 tw-py-1 `} style={{ borderLeft: `2px solid ${hash === '#Advantages9box' ? '#7A6BE8' : '#E1E8F0'}` }}>
					<a href='#Advantages9box' className={`${hash === '#Advantages9box' ? 'tw-text-[#0F172A]' : 'tw-text-[#94A3B8]'}`}>
						{references.AdvantagesOfThe9BoxMatrix}
					</a>
				</li>
			</ul>
		</div>
	)
}
