// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-course .title {
  color: #40c2ff;
}
.create-course button.create {
  background: linear-gradient(45deg, #40c2ff 0%, #32d8d8 100%);
  border: none;
  border-radius: 50rem;
  color: white;
  padding: 5px 0;
  width: 150px;
}
.create-course input, .create-course textarea, .create-course select {
  width: 100%;
  margin: 15px 0;
  padding: 0 18px;
  border: 1px solid #c9c9c9 !important;
  border-radius: 5px !important;
}
.create-course textarea {
  padding: 18px;
}
.create-course select {
  height: 36px;
}`, "",{"version":3,"sources":["webpack://./src/Views/Capacitaciones/Modal/createCourse.sass"],"names":[],"mappings":"AACI;EACI,cAAA;AAAR;AAEI;EACI,4DAAA;EACA,YAAA;EACA,oBAAA;EACA,YAAA;EACA,cAAA;EACA,YAAA;AAAR;AAEI;EACI,WAAA;EACA,cAAA;EACA,eAAA;EACA,oCAAA;EACA,6BAAA;AAAR;AAEI;EACI,aAAA;AAAR;AAEI;EACI,YAAA;AAAR","sourcesContent":[".create-course\n    .title\n        color: #40c2ff\n\n    button.create\n        background: linear-gradient(45deg,#40c2ff 0%, #32d8d8 100%)\n        border: none\n        border-radius: 50rem\n        color: white\n        padding: 5px 0\n        width: 150px\n\n    input, textarea, select\n        width: 100%\n        margin: 15px 0\n        padding: 0 18px\n        border: 1px solid #c9c9c9 !important\n        border-radius: 5px !important\n\n    textarea\n        padding: 18px\n\n    select\n        height: 36px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
