export const getSalaryMarket = async (countryId, levelID) => {
    const url = `${process.env.REACT_APP_API}/salarymarketbylevel/?levelId=${levelID}&countryId=${countryId}`

    try {
        const resp = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });

        return resp.json();
    } catch (err) {
        console.error(err);
    }
};

