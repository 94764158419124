import { LOCALES } from '../locales'

export const english_messages = {
    [LOCALES.ENGLISH]: {
        mainMenuFeed: "My Feed",
        mainMenuProfile: "My Profile",
        mainMenuMoment: "My Moment",
        mainMenuVacancies: "My Vacancies",
        mainMenuCompany: "For Companies",
        mainMenuSearch: "Search persons and companies",
        mainMenuSeeMore: "See more",

        dropdownCompany: "My Company",
        dropdownLogout: "Log out",
        dropdownProfile: "Edit Profile",

        /** WheelCompany */
        seeMore: 'See more',
        myBusiness: "My Business",
        peopleAnalytics: "People Analytics",
        recruitmentAndSelection: "Ombudsman",
        learning: "Learning",
        trainings: "Trainings",
        performanceAnalysis: "Performance Analysis",
        salariesAndBenefits: "Salaries & Benefits",
        careerPlan: "Career Plan",
        cultureAnalysis: "Culture Analysis",
        professionalDevelopment: "Professional Development",
        outplacement: "Outplacement",

        //Employee Profile Edit
        profileError: "Required field",
    }
}