// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-status-main .modal-content {
  border-radius: 2rem;
  background-color: rgb(249, 249, 249);
}
.modal-status-main .text-exito {
  color: #bce165;
}
.modal-status-main .text-error {
  color: #ec6376;
}
.modal-status-main .btn {
  width: 200px;
}
.modal-status-main .btn-green {
  background-color: #0ab4c4;
}
.modal-status-main .btn-purple {
  background-color: #7A6BE8;
}`, "",{"version":3,"sources":["webpack://./src/Components/ModalResponseStatus/ModalResponseStatus.sass"],"names":[],"mappings":"AACI;EACI,mBAAA;EACA,oCAAA;AAAR;AAEI;EACI,cAAA;AAAR;AAEI;EACI,cAAA;AAAR;AAEI;EACI,YAAA;AAAR;AAEI;EACI,yBAAA;AAAR;AAEI;EACI,yBAAA;AAAR","sourcesContent":[".modal-status-main\n    & .modal-content\n        border-radius: 2rem\n        background-color: rgb(249, 249, 249)\n\n    & .text-exito\n        color: #bce165\n\n    & .text-error\n        color: #ec6376\n\n    & .btn\n        width: 200px\n\n    & .btn-green\n        background-color: #0ab4c4\n\n    & .btn-purple\n        background-color: #7A6BE8\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
