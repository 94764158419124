import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { postInternalVacancyReferred } from '../../../../API/Employee/InternalEmployeeVacancy';
import { getAreaList } from '../../../../API/Lists/area';
import { getLevelList } from '../../../../API/Lists/level';

import './ModalVacancyReferrer.sass';

const ModalVacancyReferrer = ({ show, handleClose, data }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const [error, setError] = useState(false);
  const [areaList, setAreaList] = useState([]);
  const [levelList, setLevelList] = useState([]);
  const [referrer, setReferrer] = useState({
    internalVacanciesId: data.id,
    employerId: user.id,
    name: '',
    email: '',
    levelCorrelationsId: '',
    areaId: '',
    positionName: '',
    linkedin: '',
  });

  useEffect(() => {
    const req = async () => {
      setAreaList(await getAreaList());
      setLevelList(await getLevelList());
    };
    req();
  }, []);

  const handleChange = (e) => {
    setReferrer({ ...referrer, internalVacanciesId: data.id, [e.target.name]: e.target.value });
  };

  const handleSend = async () => {
    if (referrer.name !== '' && referrer.email !== '' && referrer.levelCorrelationsId !== '' && referrer.areaId !== '' && referrer.positionName !== '' && referrer.linkedin !== '') {
      // console.log(referrer);
      await postInternalVacancyReferred(referrer);
      handleClose()
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    console.log(referrer);
  }, [referrer]);

  return (
    <>
      <Modal className="ModalVacancyReferrer" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Indicar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            {error && <>Verifique se todos os campos estão preenchidos</>}
            <div className="row row-cols-1">
              <div className="col">
                <input name="name" type="text" className="form-control" placeholder="Nombre" onChange={handleChange} />
              </div>
              <div className="col">
                <input name="email" type="email" className="rounded-pill form-control" placeholder="Correo" onChange={handleChange} />
              </div>
              <div className="col">
                <select name="areaId" className="rounded-pill form-select" onChange={handleChange}>
                  <option selected>Area</option>
                  {areaList?.map((area) => {
                    return (
                      <option key={area.id} value={area.id}>
                        {area.profileArea}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col">
                <select name="levelCorrelationsId" className="rounded-pill form-select" onChange={handleChange}>
                  <option selected>Level</option>
                  {levelList?.map((level) => {
                    return (
                      <option key={level.id} value={level.id}>
                        {level.PositionN}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col">
                <input name="positionName" type="text" className="form-control" placeholder="Position" onChange={handleChange} />
              </div>
              <div className="col">
                <input name="linkedin" type="text" className="form-control" placeholder="LinkedIn" onChange={handleChange} />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-yes me-auto" variant="" onClick={handleSend}>
            Enviar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalVacancyReferrer;
