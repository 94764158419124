export const translate = {
  es: {
    "Hacer más agradable la rutina de trabajo, proporcionando una mayor conciliación de la vida personal y laboral":
      "Hacer más agradable la rutina de trabajo, proporcionando una mayor conciliación de la vida personal y laboral",
    "Aumentar sentido de pertenecia , fomentar y fortalece las relaciones interpersonales":
      "Aumentar sentido de pertenecia , fomentar y fortalece las relaciones interpersonales",
    "Lugar de trabajo agradable y propcio al desarrollo profesioanal": "Lugar de trabajo agradable y propcio al desarrollo profesional",
    "Dias libres adicionales": "Dias libres adicionales",

    "Horario Flexible": "Horario Flexible",
    "Una mayor conciliación de la personal y laboral.": "Una mayor conciliación de la personal y laboral.",

    "Teletrabajo/Hibrido": "Teletrabajo/Hibrido",
    "Hay quienes prefieran el teletrabajo 100%, hay quienes prefiera el hibrido. La empresa debe encontrar el mejor equilibrio entre satisfación y productividad.":
      "Hay quienes prefieran el teletrabajo 100%, hay quienes prefiera el hibrido. La empresa debe encontrar el mejor equilibrio entre satisfación y productividad.",

    "Viernes Libre": "Viernes Libre",
    "La extensión de los fines de semana es un beneficio muy valorado entre los profesionales":
      "La extensión de los fines de semana es un beneficio muy valorado entre los profesionales",

    Vestimenta: "Vestimenta",
    "Trabajar como se sienta comodo. Cada vez menos el trabajo formal es adoptado por las empresas, con fin de promover la comodidad del colaborador en el trabajo.":
      "Trabajar como se sienta comodo. Cada vez menos el trabajo formal es adoptado por las empresas, con fin de promover la comodidad del colaborador en el trabajo.",

    "Empresa pet friendly": "Empresa pet friendly",
    "Permitir llevar la mascota al trabajo": "Permitir llevar la mascota al trabajo",

    "Politicas de Diversidad": "Politicas de Diversidad",
    "La diversidad aplicada al talento en las empresas no debe responder a cuotas ni a prejuicios, sino a competencias, habilidades, experiencias, conocimientos, valores y actitudes diferentes aportadas por cada persona.":
      "La diversidad aplicada al talento en las empresas no debe responder a cuotas ni a prejuicios, sino a competencias, habilidades, experiencias, conocimientos, valores y actitudes diferentes aportadas por cada persona.",

    "Actividades de Teambuilding": "Actividades de Teambuilding",
    "Actividades que busca formar equipos de alto desempeño y mejorar las relaciones interpersonales dentro de un grupo.":
      "Actividades que busca formar equipos de alto desempeño y mejorar las relaciones interpersonales dentro de un grupo.",

    Celebraciones: "Celebraciones",
    "Eventos que promovan la interacción entre los equipos , en ocasiones especiales.":
      "Eventos que promovan la interacción entre los equipos , en ocasiones especiales.",

    "Misión, visión y valores compartidos": "Misión, visión y valores compartidos",
    "Alinean la motivación y el enfoque de los colaboradores en una dirección unificada.":
      "Alinean la motivación y el enfoque de los colaboradores en una dirección unificada.",

    "Espacios de esparcimiento": "Espacios de esparcimiento",
    "Diversión o distracción, en especial para descansar o alejarse por un tiempo del trabajo o de las preocupaciones.":
      "Diversión o distracción, en especial para descansar o alejarse por un tiempo del trabajo o de las preocupaciones.",

    "Canales de Comunicación": "Canales de Comunicación",
    "Los trabajadores son personas que como el resto necesitan comunicarse de tú a tú, conocer a sus compañeros y superiores y poder acceder a ellos cuando los necesiten.":
      "Los trabajadores son personas que como el resto necesitan comunicarse de tú a tú, conocer a sus compañeros y superiores y poder acceder a ellos cuando los necesiten.",

    "Espacio fisico": "Espacio fisico",
    "La ubicación geográfica de la empresa, la facilidad de acceso, contar con aparcamiento o transporte gratuito, comedor propio, etc. Además de un espacio limpio y comodo para la realización de su trabajo. Son aspectos que también valoran los trabajadores.":
      "La ubicación geográfica de la empresa, la facilidad de acceso, contar con aparcamiento o transporte gratuito, comedor propio, etc. Además de un espacio limpio y comodo para la realización de su trabajo. Son aspectos que también valoran los trabajadores.",

    Cultura: "Cultura",
    "Estar alineado a la cultura de la empresa, aumenta la motiviación y sentido de pertenencia entre los colaboradores.":
      "Estar alineado a la cultura de la empresa, aumenta la motiviación y sentido de pertenencia entre los colaboradores.",

    "Planes de onboarding": "Planes de onboarding",
    "La experiencia en los primeros días de trabajo hacen toda la diferencia en la inclusión y sentido de pertecer del colaborador, además de agilizar el conocimiento acerca de sus responsabilidades , acortando la curva de prendizaje.":
      "La experiencia en los primeros días de trabajo hacen toda la diferencia en la inclusión y sentido de pertecer del colaborador, además de agilizar el conocimiento acerca de sus responsabilidades , acortando la curva de prendizaje.",

    "Desarrollo y plan de Carrera": "Desarrollo y plan de Carrera",
    "Promoción interna debe ser justa y basada en principios de equidad en función de méritos, valores y grado de formación. En este sentido debe facilitar la asistencia a cursos internos o externos que permitan el crecimiento del empleado":
      "Promoción interna debe ser justa y basada en principios de equidad en función de méritos, valores y grado de formación. En este sentido debe facilitar la asistencia a cursos internos o externos que permitan el crecimiento del empleado",

    Autonomia: "Autonomia",
    "Dotar a los colaboradores de los conocimientos, recursos y capacidades necesarias para cumplir los requisitos que demanda su tarea.":
      "Dotar a los colaboradores de los conocimientos, recursos y capacidades necesarias para cumplir los requisitos que demanda su tarea.",

    Reconocimientos: "Reconocimientos",
    "Poner en valor sus logros, ya sea con acciones tan sencillas como un reconocimiento público o con medidas más tangibles, como bonos o viajes de incentivos, por ejemplo. ":
      "Poner en valor sus logros, ya sea con acciones tan sencillas como un reconocimiento público o con medidas más tangibles, como bonos o viajes de incentivos, por ejemplo. ",

    "Cumpleaños y Matrimonio libres, por ejemplo.": "Cumpleaños y Matrimonio libres, por ejemplo.",
    "Dias libres en fechas especiales para el colaborador": "Dias libres en fechas especiales para el colaborador",

    "Extensión de pre y post natal": "Extensión de pre y post natal",
    "Adicional a lo que ya se está aplicado por ley": "Adicional a lo que ya se está aplicado por ley",

    "Tramites y obligaciones extra laborales": "Tramites y obligaciones extra laborales",
    "Flexibilidad para realizar tramites bancarios, correos, etc": "Flexibilidad para realizar tramites bancarios, correos, etc",
  },

  pt: {
    "Hacer más agradable la rutina de trabajo, proporcionando una mayor conciliación de la vida personal y laboral":
      "Ter uma rotina de trabalho mais agradável, proporcionando uma melhor conciliação entre a vida pessoal e profissional",
    "Aumentar sentido de pertenecia , fomentar y fortalece las relaciones interpersonales":
      "Aumentar o sentimento de pertencimento, promover e fortalecer as relações interpessoais",
    "Lugar de trabajo agradable y propcio al desarrollo profesioanal": "Local de trabalho agradável e propício ao desenvolvimento profissional",
    "Dias libres adicionales": "Dias de folga adicionais",

    "Horario Flexible": "Horário flexível",
    "Una mayor conciliación de la personal y laboral.": "Uma maior conciliação da vida pessoal e profissional.",

    "Teletrabajo/Hibrido": "Teletrabalho/Híbrido",
    "Hay quienes prefieran el teletrabajo 100%, hay quienes prefiera el hibrido. La empresa debe encontrar el mejor equilibrio entre satisfación y productividad.":
      "Há quem prefira o teletrabalho 100%, há quem prefira o híbrido. A empresa deve encontrar o melhor equilíbrio entre satisfação e produtividade.",

    "Viernes Libre": "Sexta-feira livre",
    "La extensión de los fines de semana es un beneficio muy valorado entre los profesionales":
      "A prorrogação dos finais de semana é um benefício muito valorizado entre os profissionais",

    Vestimenta: "Vestimenta",
    "Trabajar como se sienta comodo. Cada vez menos el trabajo formal es adoptado por las empresas, con fin de promover la comodidad del colaborador en el trabajo.":
      "Trabalhe como você se sentir confortável. O trabalho cada vez menos formal é adotado pelas empresas, a fim de promover o conforto dos funcionários no trabalho.",

    "Empresa pet friendly": "Empresa pet friendly",
    "Permitir llevar la mascota al trabajo": "Permitindo que você leve seu animal de estimação para o trabalho",

    "Politicas de Diversidad": "Políticas de Diversidade",
    "La diversidad aplicada al talento en las empresas no debe responder a cuotas ni a prejuicios, sino a competencias, habilidades, experiencias, conocimientos, valores y actitudes diferentes aportadas por cada persona.":
      "A diversidade aplicada ao talento nas empresas não deve responder a cotas ou preconceitos, mas sim às diferentes competências, habilidades, experiências, conhecimentos, valores e atitudes contribuídas por cada pessoa.",

    "Actividades de Teambuilding": "Atividades de Teambuilding",
    "Actividades que busca formar equipos de alto desempeño y mejorar las relaciones interpersonales dentro de un grupo.":
      "Atividades que buscam formar equipes de alto desempenho e melhorar o relacionamento interpessoal dentro de um grupo.",

    Celebraciones: "Celebrações",
    "Eventos que promovan la interacción entre los equipos , en ocasiones especiales.":
      "Eventos que promovem a interação entre equipes, em ocasiões especiais.",

    "Misión, visión y valores compartidos": "Missão, visão e valores compartilhados",
    "Alinean la motivación y el enfoque de los colaboradores en una dirección unificada.":
      "Eles alinham a motivação e o foco dos funcionários em uma direção unificada.",

    "Espacios de esparcimiento": "Espaços de recreação",
    "Diversión o distracción, en especial para descansar o alejarse por un tiempo del trabajo o de las preocupaciones.":
      "Diversão ou distração, principalmente para descansar ou fugir um pouco do trabalho ou das preocupações.",

    "Canales de Comunicación": "Canais de comunicação",
    "Los trabajadores son personas que como el resto necesitan comunicarse de tú a tú, conocer a sus compañeros y superiores y poder acceder a ellos cuando los necesiten.":
      "Os trabalhadores são pessoas que, como todas as outras pessoas, precisam de comunicar cara a cara, conhecer os seus colegas e superiores e poder aceder-lhes quando precisarem.",

    "Espacio fisico": "Espaço físico",
    "La ubicación geográfica de la empresa, la facilidad de acceso, contar con aparcamiento o transporte gratuito, comedor propio, etc. Además de un espacio limpio y comodo para la realización de su trabajo. Son aspectos que también valoran los trabajadores.":
      "A localização geográfica da empresa, facilidade de acesso, estacionamento ou transporte gratuito, refeitório próprio, etc. Além de um espaço limpo e confortável para realizar seu trabalho. São aspectos que os trabalhadores também valorizam.",

    "Desarrollo y plan de Carrera": "Desenvolvimento e plano de carreira",
    "Promoción interna debe ser justa y basada en principios de equidad en función de méritos, valores y grado de formación. En este sentido debe facilitar la asistencia a cursos internos o externos que permitan el crecimiento del empleado":
      "A promoção interna deve ser justa e baseada em princípios de equidade baseados em méritos, valores e grau de formação. Neste sentido, deve facilitar a frequência de cursos internos ou externos que permitam o crescimento do colaborador.",

    Cultura: "Cultura",
    "Estar alineado a la cultura de la empresa, aumenta la motiviación y sentido de pertenencia entre los colaboradores.":
      "Estar alinhado com a cultura da empresa aumenta a motivação e o sentimento de pertencimento dos colaboradores.",

    "Planes de onboarding": "Planos de onboarding",
    "La experiencia en los primeros días de trabajo hacen toda la diferencia en la inclusión y sentido de pertecer del colaborador, además de agilizar el conocimiento acerca de sus responsabilidades , acortando la curva de prendizaje.":
      "A experiência nos primeiros dias de trabalho faz toda a diferença na inclusão e no sentimento de pertencimento do colaborador, além de agilizar o conhecimento sobre suas responsabilidades, encurtando a curva de aprendizado.",

    Autonomia: "Autonomia",
    "Dotar a los colaboradores de los conocimientos, recursos y capacidades necesarias para cumplir los requisitos que demanda su tarea.":
      "Fornecer aos colaboradores o conhecimento, os recursos e as capacidades necessárias para atender aos requisitos de sua tarefa.",

    Reconocimientos: "Reconhecimentos",
    "Poner en valor sus logros, ya sea con acciones tan sencillas como un reconocimiento público o con medidas más tangibles, como bonos o viajes de incentivos, por ejemplo. ":
      "Destacar suas conquistas, seja com ações tão simples como o reconhecimento público ou com medidas mais tangíveis, como bônus ou viagens de incentivo, por exemplo. ",

    "Cumpleaños y Matrimonio libres, por ejemplo.": "Aniversários e casamentos gratuitos, por exemplo.",
    "Dias libres en fechas especiales para el colaborador": "Dias de folga em datas especiais para o colaborador",

    "Extensión de pre y post natal": "Extensão pré e pós-natal",
    "Adicional a lo que ya se está aplicado por ley": "Adicional ao que já é aplicado por lei",

    "Tramites y obligaciones extra laborales": "Procedimentos e obrigações extra-laborais",
    "Flexibilidad para realizar tramites bancarios, correos, etc": "Flexibilidade para realizar procedimentos bancários, pessoais, etc.",
  },
};
