import { useState } from 'react'
import { Link } from 'react-router-dom'
import { HistoricoProfessionalIcon, PropositoIcon, FormacionIcon, CulturaIcon, SalarioIcon, HabilidadesIcon, MotivacionesIcon, DesempenoIcon, EntrenamientoIcon, PlanCarreraIcon } from '../../../../Icons/CollaboratorsIcons'
import { wheelCollaboratorTranslate } from '../../../../i18n/translatedMessages'
import './wheel.sass'
import { FaUserEdit } from 'react-icons/fa'

const itens = [
	{
		id: 1,
		name: wheelCollaboratorTranslate.professionalHistoric,
		icon: <HistoricoProfessionalIcon />,
		link: '/employee/professionalinfo',
	},
	{
		id: 2,
		name: wheelCollaboratorTranslate.purpose,
		icon: <PropositoIcon />,
		link: '/employee/proposito',
	},
	{
		id: 3,
		name: wheelCollaboratorTranslate.formation,
		icon: <FormacionIcon />,
		link: '/employee/formationinfo',
	},
	{
		id: 4,
		name: wheelCollaboratorTranslate.climeAndCulture,
		icon: <CulturaIcon />,
		link: '/employee/climaycultura',
	},
	{
		id: 5,
		name: wheelCollaboratorTranslate.salary,
		icon: <SalarioIcon />,
		link: '/employee/salaryinfo',
	},
	{
		id: 6,
		name: wheelCollaboratorTranslate.habilities,
		icon: <HabilidadesIcon />,
		link: '/employee/assessment',
	},
	{
		id: 7,
		name: wheelCollaboratorTranslate.motivacions,
		icon: <MotivacionesIcon />,
		link: '/employee/motivaciones',
	},
	{
		id: 8,
		name: wheelCollaboratorTranslate.performance,
		icon: <DesempenoIcon />,
		link: '/employee/desempeno',
	},
	{
		id: 9,
		name: wheelCollaboratorTranslate.training,
		icon: <EntrenamientoIcon />,
		link: '/employee/entrenamiento',
	},
	{
		id: 10,
		name: wheelCollaboratorTranslate.careerPlan,
		icon: <PlanCarreraIcon />,
		link: '/employee/plandecarrera',
	},
]

const WheelColaboradores = ({ employeeImg, handleMenu }) => {
	const [img] = useState('https://assets.mydna.company/profile-placeholder.png?1654276597528')

	return (
		<div className={`container-wheel-colaboradores`}>
			<div className='ring-one p-md-5 p-4'>
				{itens.map(service => (
					<Link to={service.link} className={`icons-colaboradores shadow`} key={service.id} data-option={service.id} onClick={handleMenu}>
						{service.icon}
						<div className='box-info'>
							<span className='title-colaboradores'>{service.name}</span>
							<span className='detail-colaboradores'>{service.description}</span>
						</div>
					</Link>
				))}

				<div className='ring-two p-md-5 p-4'>
					<Link className='wheelEmployeeLink-editProfile' to={'/employee/edit-profile'}>
						<img src={employeeImg ? employeeImg : img} alt='' className='img-fluid img' />
						<span className='wheelEmployeeImgHover-editProfile'>
							<FaUserEdit size={30} />
							{wheelCollaboratorTranslate.editProfile}
						</span>
					</Link>
				</div>
			</div>
		</div>
	)
}
export default WheelColaboradores
