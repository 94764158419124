import "../stylesSteps.css";
import { useContext, useState } from "react";
import { ServicesContext } from "../../../../../Store/ServicesContext";

import {
  AiOutlinePlusCircle,
  AiOutlineMinusCircle,
  AiOutlineCloseCircle,
  AiOutlineCheckCircle,
} from "react-icons/ai";
import { MdFileUpload } from "react-icons/md";
import { UploadFileRequest } from "../../../../../API/Upload_file_request";

const TresStepsForm2 = () => {
  const { store, actions } = useContext(ServicesContext);
  const [inputList, setInputList] = useState([
    { name: "", email: "", area: "", cargo: "" },
  ]);

  const [isFilePicked, setIsFilePicked] = useState(false);
  const [selectedFile, setSelectedFile] = useState();

  const [checkboxConditionUn, setCheckboxConditionUn] = useState(false);
  const [checkboxConditionDos, setCheckboxConditionDos] = useState(false);

  const handleSelectedFile = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  const handleSubmission = () => {
    UploadFileRequest(selectedFile);
  };

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
    actions.setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    actions.setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { name: "", email: "", area: "", cargo: "" }]);
  };

  return (
    <div className="container-fluid StepsFormClass">
      <div className="row pt-5">
        <div className="col-12 text-center">
          <h5>
            ¿Estas listo para crear el onborarding más dinamico que hayas
            implementado? Completa la siguiente información para personalizar tu
            onboarding según las características de tu empresa.
          </h5>
        </div>
        <div className="col-12 justify-content-center text-center pt-4">
          <div
            className={
              checkboxConditionUn === false && checkboxConditionDos
                ? "form-check form-check-inline check-style check-style-disabled"
                : "form-check form-check-inline check-style"
            }
          >
            <input
              className="form-check-input"
              type="checkbox"
              id="checkboxUpdate"
              onChange={(e) => {
                e.target.checked
                  ? setCheckboxConditionUn(true)
                  : setCheckboxConditionUn(false);
              }}
              disabled={checkboxConditionDos}
              style={{ display: "none" }}
            />
            <label className="form-check-label" for="checkboxUpdate">
              Subir archivo
              {checkboxConditionUn && checkboxConditionDos === false ? (
                <AiOutlineCloseCircle size={25} />
              ) : (
                ""
              )}
            </label>
          </div>
          <div
            className={
              checkboxConditionUn && checkboxConditionDos === false
                ? "form-check form-check-inline check-style check-style-disabled"
                : "form-check form-check-inline check-style"
            }
          >
            <input
              className="form-check-input"
              type="checkbox"
              id="checkboxList"
              onChange={(e) => {
                e.target.checked
                  ? setCheckboxConditionDos(true)
                  : setCheckboxConditionDos(false);
              }}
              disabled={checkboxConditionUn}
              style={{ display: "none" }}
            />
            <label className="form-check-label" for="checkboxList">
              Rellenar formulario{" "}
              {checkboxConditionUn === false && checkboxConditionDos ? (
                <AiOutlineCloseCircle size={25} />
              ) : (
                ""
              )}
            </label>
          </div>
        </div>
      </div>
      {checkboxConditionUn === false && checkboxConditionDos === true ? (
        <div className="row pt-4 justify-content-center">
          <div className="col-12 text-center">
            <h6>
              Ingrese los datos de los colaboradores a los cuales quieres
              entregar este servicio
            </h6>
          </div>
          {inputList.map((x, i) => {
            return (
              <>
                <div className="col-5 pt-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Nombre"
                    aria-label="Nombre"
                    name="name"
                    value={x.name}
                    onChange={(e) => handleInputChange(e, i)}
                  />
                </div>
                <div className="col-5 pt-3">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Mail"
                    aria-label="Mail"
                    name="email"
                    value={x.email}
                    onChange={(e) => handleInputChange(e, i)}
                  />
                </div>

                <div className="col-2"></div>

                <div className="col-5 pt-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Área"
                    aria-label="Área"
                    name="area"
                    value={x.area}
                    onChange={(e) => handleInputChange(e, i)}
                  />
                </div>
                <div className="col-5 pt-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Cargo"
                    aria-label="Cargo"
                    name="cargo"
                    value={x.cargo}
                    onChange={(e) => handleInputChange(e, i)}
                  />
                </div>
                <div className="col-2 text-center add-remove-button">
                  {inputList.length !== 1 && (
                    <button onClick={() => handleRemoveClick(i)}>
                      <AiOutlineMinusCircle size={30} />
                    </button>
                  )}
                  {inputList.length - 1 === i && (
                    <button onClick={handleAddClick}>
                      <AiOutlinePlusCircle size={30} />
                    </button>
                  )}
                </div>
              </>
            );
          })}
        </div>
      ) : checkboxConditionUn === true && checkboxConditionDos === false ? (
        <div className="row pt-4 pb-5 justify-content-center">
          <div className="col-12 text-center pb-3">
            <h6>
              Sube un archivo con los datos de los colaboradores a los cuales
              quieres entregar este servicio (nombre, mail, cargo y área).
            </h6>
          </div>
          <div className="col text-center justify-content-center">
            <div className="upload-field m-auto d-inline-block p-4">
              <label for="formFile" className="label-input-file">
                {isFilePicked ? (
                  <>
                    <h6>Archivo: {selectedFile.name}</h6>
                  </>
                ) : (
                  <>
                    <h6>Clic para elegir archivo</h6>
                  </>
                )}
              </label>
              <input
                className="input-file"
                type="file"
                id="formFile"
                onChange={handleSelectedFile}
                style={{ display: "none" }}
              />
            </div>
            <button
              onClick={handleSubmission}
              type="button"
              data-bs-toggle={isFilePicked ? "modal" : ""}
              data-bs-target={isFilePicked ? "#exampleModal" : ""}
              className="btn upload-button d-inline-block p-4"
            >
              <MdFileUpload size={25} /> Upload file
            </button>
            <div
              className="modal fade"
              id="exampleModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header titleModalTresSteps">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Success! Su archivo ha subido.
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="rounded-circle circleConfirm">
                      <AiOutlineCheckCircle size={100} />
                    </div>
                  </div>
                  {/* <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Cerrar
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
export default TresStepsForm2;
