import { Link } from "react-router-dom";
import { MdArrowBack, MdArrowForward } from "react-icons/md";
import { btnTranslate, climeCultureTranslate } from "../../i18n/translatedMessages";
import { CulturaIcon } from "../../Icons/CollaboratorsIcons";

const dataCards = [
  {
    title: climeCultureTranslate.cultureSurvey,
    description: climeCultureTranslate.cultureSurveyText,
    link: "/employee/culture",
  },
  {
    title: climeCultureTranslate.climeSurvey,
    description: climeCultureTranslate.climeSurveyText,
    link: "/employee/clima",
  },
];

const ClimaYCultura = () => {
  return (
    <div className="container-fluid color-grey">
      <div className="row align-items-center">
        <div className="col-1">
          <span className="title-icon p-2 m-auto">
            <CulturaIcon size={20} />
          </span>
        </div>
        <div className="col-4">
          <h1>{climeCultureTranslate.climeAndCultureTitle}</h1>
        </div>
        <div className="col-12">
          {dataCards.map((data, index) => {
            return (
              <div key={index} className="card shadow card-cyc mt-3">
                <div className="card-title card-title-bg-cyc p-3 rounded-top">
                  <h4 className="m-0">{data.title}</h4>
                </div>
                <div className="card-body">
                  <p className="card-text">{data.description}</p>
                  <Link
                    className="btn btn-acceder rounded-pill align-items-center"
                    to={data.link}
                  >
                    {btnTranslate.accessButton} <MdArrowForward size={20} />
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
        <div className="col-12 pt-5">
          <Link
            className="button-volver color-blue"
            to={"/employee"}
          >
            <MdArrowBack size={20} />
            {btnTranslate.backButton}
          </Link>
        </div>
      </div>
    </div>
  );
};
export default ClimaYCultura;
