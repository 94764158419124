import Loading from "../../Components/Loading/Loading";
import CulturaBar from "../Analytics/Components/CircularBar/CircularBar";
import Timeline from "./Components/Timeline/Timeline";
import { cultureBusinessTranslate } from "../../i18n/translatedMessages";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { FormattedMessage } from "react-intl";

import { Button, ButtonToolbar, OverlayTrigger, Popover } from "react-bootstrap";

const bars = [
    {
        id: 0,
        icon: "https://assets.mydna.company/Comercial.svg",
        type: "Comercial",
        color: "#7a6be6",
        desc: "cbTimeLineComercialDesc",
    },
    {
        id: 1,
        icon: "https://assets.mydna.company/Disruptivo.svg",
        type: "Disruptiva",
        color: "#41c6ff",
        desc: "cbTimeLineDisruptiveDec",
    },
    {
        id: 2,
        icon: "https://assets.mydna.company/Colaborativo.svg",
        type: "Colaborativa",
        color: "#2be7c8",
        desc: "cbTimeLineColaborateDesc",
    },
    {
        id: 3,
        icon: "https://assets.mydna.company/Tradicional.svg",
        type: "Tradicional",
        color: "#38364b",
        desc: "cbTimeLineTraditionalDesc",
    },
];

const Fineshed = ({ profile }) => {

    return (
        <>
            <Timeline step="5" />
            {profile ? (
                <>
                    <div className="row my-5">
                        <div className="col-8 offset-2">
                            <h4 className="my-3">
                                {cultureBusinessTranslate.cbTimeLineAswer} <span className={`type ${profile.type.toLowerCase()}`}>
                                    {profile.type.toUpperCase()}
                                </span>.
                            </h4>
                        </div>
                    </div>
                    <div className="row my-5">
                        <div className="col-8 offset-2">
                            {profile !== null && (
                                <ul className="d-flex justify-content-center">
                                    {bars.map((item) => (
                                        <li className="card-bars mx-5" key={item.id}>
                                            <CulturaBar
                                                percentagem={profile[item.type.toLowerCase()]}
                                                color={item.color}
                                            >
                                                <img src={item.icon} alt="" />
                                            </CulturaBar>
                                            <span style={{ color: item.color }}>{item.type+" "}</span>
                                            <FormattedMessage id={item.desc}>
                                                {
                                                    (msg) => 
                                                    <ButtonToolbar className="d-inline-block" >
                                                        <OverlayTrigger
                                                            trigger={['hover', 'focus']}
                                                            placement="top"
                                                            overlay={<Popover className="popoverCulture" tabIndex="0" id="popover-trigger-hover-focus p-2"> {msg} </Popover>}
                                                        >
                                                            <Button tabIndex="0" className="finishedCultureBtn"><BsFillInfoCircleFill color={item.color}/></Button>
                                                        </OverlayTrigger>
                                                    </ButtonToolbar>
                                                }
                                            </FormattedMessage>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                </>
            ) : (
                <Loading />
            )}
        </>
    );
};
export default Fineshed;
