import { useState, useMemo, useEffect } from "react";
import { BsListCheck } from "react-icons/bs";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { getAreaList } from "../../../../API/Lists/area";
import { getHierarchicalLevelList } from "../../../../API/Lists/hierarchicalLevel";
import { getInternalVacancies } from "../../../../API/Services/CareerPlan/InternalVacancies";
import { internalJobsTranslate } from "../../../../i18n/translatedMessages";
import { InternalVacancies } from "../../../../Icons/Icons";

import "./InternalVacanciesList.sass";

const InternalVacanciesList = () => {
    const company = JSON.parse(localStorage.getItem("user"));
    const [search, setSearch] = useState("");
    let [loadMore, setLoadMore] = useState(10);
    const [areaList, setAreaList] = useState([]);
    const [hierarchList, setHierarchList] = useState([]);
    const [data, setData] = useState([]);
    const history = useHistory();

    useEffect(() => {
        const resquests = async () => {
            const dataAux = await getInternalVacancies();
            const dataFilter = dataAux.filter(
                (dtFilter) => company.id === dtFilter.companyId
            );
            setData(dataFilter);
            setAreaList(await getAreaList());
            setHierarchList(await getHierarchicalLevelList());
        };
        resquests();
    }, []);

    const lowerSearch = search?.toLowerCase();
    const searchFilt = useMemo(() => {
        return data?.filter((dt) => {
            return dt.name?.toLowerCase().includes(lowerSearch);
        });
    }, [lowerSearch, data]);

    const searchEvent = (event) => {
        setSearch(event.currentTarget.value);
    };

    const showMoreItems = () => {
        setLoadMore((prevValue) => prevValue + 5);
    };

    const handleClick = (vacancyId) => {
        history.push(
            `/app/services/internal-vacancies-applications/${vacancyId}`
        );
    };

    return (
        <div className="col-10 offset-1 InternalVacanciesList">
            <div className="row">
                <div className="col d-flex align-items-center">
                    <div className="icon">
                        <InternalVacancies color={"white"} size={30} />
                    </div>
                    <h4>{internalJobsTranslate.internalJobTitle}</h4>
                </div>
            </div>
            <div className="row">
                <div className="col my-3">
                    <div className="content-input">
                        <FormattedMessage id="internalJobSearchPlaceHolder">
                            {(msg) => (
                                <input
                                    className="dropdrown-size m-0"
                                    type="text"
                                    onChange={searchEvent}
                                    value={search}
                                    placeholder={msg}
                                />
                            )}
                        </FormattedMessage>
                    </div>
                </div>
            </div>
            {searchFilt.length > 0 ? (
                <>
                    <table className="table">
                        <thead className="border-bottom">
                            <tr className="color-purple">
                                <th
                                    scope="col"
                                    className="fw-bolder py-3 border-0"
                                >
                                    {internalJobsTranslate.internalJobTableC1}
                                </th>
                                <th
                                    scope="col"
                                    className="fw-bolder py-3 border-0"
                                >
                                    {internalJobsTranslate.internalJobTableC2}
                                </th>
                                <th
                                    scope="col"
                                    className="fw-bolder py-3 border-0"
                                >
                                    {internalJobsTranslate.internalJobTableC3}
                                </th>
                            </tr>
                        </thead>
                        <tbody className="border-top-0">
                            {searchFilt?.slice(0, loadMore).map((dt, index) => {
                                return (
                                    <tr
                                        key={index}
                                        onClick={() => handleClick(dt.id)}
                                    >
                                        <td className="fw-bolder">{dt.name}</td>
                                        <td className="fw-bolder">
                                            {areaList
                                                ?.filter(
                                                    (area) =>
                                                        dt.area1 === area.id
                                                )
                                                .map((areafilt, areaIndex) => {
                                                    return (
                                                        <span
                                                            key={areaIndex}
                                                            className="fw-bolder"
                                                        >
                                                            {
                                                                areafilt.profileArea
                                                            }{" "}
                                                            /
                                                        </span>
                                                    );
                                                })}
                                            {hierarchList
                                                ?.filter(
                                                    (hierarc) =>
                                                        dt.hierarchical_level ===
                                                        hierarc.id
                                                )
                                                .map(
                                                    (
                                                        hierarcfilt,
                                                        hierIndex
                                                    ) => {
                                                        return (
                                                            <span
                                                                key={hierIndex}
                                                                className="fw-bolder"
                                                            >
                                                                {" "}
                                                                {
                                                                    hierarcfilt.level
                                                                }
                                                            </span>
                                                        );
                                                    }
                                                )}
                                        </td>
                                        <td>
                                            <button
                                                className="btn btn-add px-2"
                                                onClick={() =>
                                                    handleClick(dt.id)
                                                }
                                            >
                                                <BsListCheck size={23} />
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    {searchFilt.length > 10 && (
                        <button
                            className="btn btn-light btnLoadMore mt-3"
                            onClick={showMoreItems}
                        >{""}</button>
                    )}
                </>
            ) : (
                <div className="alertNoVacancies text-center p-3">
                    No hay vacantes
                </div>
            )}
        </div>
    );
};
export default InternalVacanciesList;
