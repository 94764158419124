export const postCompanyCulture = async (data) => {
	try {
		const resp = await fetch(`${process.env.REACT_APP_API}/companyCulture`, {
			method: "POST",
			headers: {
				"Content-type": "application/json",
			},
			body: JSON.stringify(data),
		});
		return resp.json();
	} catch (err) {
		console.error(err);
	}
};

export const getCompanyCulture = async (id) => {
	try {
		const resp = await fetch(`${process.env.REACT_APP_API}/companyCulture/${id}`, {
			method: "GET",
			headers: {
				"Content-type": "application/json",
			},
		});

		return resp
	} catch (err) {
		console.error(err);
	}
};