export function Pills({ color = '#008ACD', label }) {
	return (
		<div
			className='tw-rounded-full tw-p-4 tw-w-[281px] 2xl:tw-w-[381px] tw-font-bold tw-text-center tw-text-white'
			style={{
				background: color,
			}}
		>
			{label}
		</div>
	)
}
