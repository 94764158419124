export function ValidateNewEmployee(user) {
	const fieldsToValidate = ['name', 'email', 'country', 'position', 'level', 'area', 'subarea']

	if (user) {
		const fields = fieldsToValidate.filter(field => !user[field])

		return { validate: fields.length === 0, fields: fields }
	}
	return { validate: false, fields: fieldsToValidate }
}


