import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getEmployersExp } from "../../API/Analytics/experience";
import { getIndustryTypeList } from "../../API/Lists/industryType";
import {
  HistoricoProfessionalIcon,
  PlanCarreraIcon,
  PropositoIcon,
} from "../../Icons/CollaboratorsIcons";
import {
  professionalHistoricTranslate,
  wheelCollaboratorTranslate,
} from "../../i18n/translatedMessages";
import EmptyInformation from "./Components/EmptyInformation/EmptyInformation";
import Loading from "./../../Components/Loading/Loading";

import Timeline from "./Components/Timeline/Timeline";
import { translate } from "../../i18n/Library/industry_type";

import "./historicoProfesional.sass";
import HeaderCarouselMandala from "./Components/HeaderCarouselMandala/HeaderCarouselMandala";
import { useIntl } from "react-intl";

const HistoricoProfesional = () => {
  const getLanguage = useIntl();
  const language = getLanguage.locale === "PT-BR" ? "pt" : "es";
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [employerExps, setEmployerExps] = useState([]);
  const [industryTypeRefs, setIndustryTypeRefs] = useState([]);

  const getIndustryName = (industryID) => {
    return industryTypeRefs.find((industry) => industry.id === industryID);
  };

  const IndustryTags = () => {
    const processed_tags = [];
    return employerExps.map((item, index) => {
      if (!processed_tags.includes(item.industryTypeId)) {
        processed_tags.push(item.industryTypeId);
        const getIndustry = getIndustryName(item.industryTypeId);
        if (getIndustry)
          return (
            <div key={`#${item.id} ${index}`} className="professional-historic-badge">
              {translate[language][getIndustry.name]}
            </div>
          );
      } else {
        return false;
      }
    });
  };

  const CalculateEmployerXP = () => {
    let years = new Date().getFullYear() - employerExps[0].startYear;
    let months = new Date().getMonth() + 1 - employerExps[0].startMonth;

    if (months < 0) {
      months = months * -1;
      years = years - 1;
    }

    return {
      years,
      months,
    };
  };

  const GenerateEmployerXPBar = () => {
    const years = CalculateEmployerXP().years;

    let n_bars = 1;

    if (years > 0) {
      n_bars = Math.ceil(years / 2);
      n_bars = n_bars > 10 ? 10 : n_bars;
    } else if (years > 20) {
      n_bars = 10;
    }
    const enabled_bars = [];
    for (let index = 0; index < n_bars; index++) {
      enabled_bars.push(
        <div
          key={index}
          className="professional-historic-slot professional-historic-slot-fill"
        ></div>
      );
    }

    const disabled_bars = [];
    for (let index = 0; index < 10 - n_bars; index++) {
      disabled_bars.push(<div key={index} className="professional-historic-slot"></div>);
    }

    return (
      <>
        {enabled_bars}
        {disabled_bars}
      </>
    );
  };

  useEffect(() => {
    const fetchProfessionalHistoric = async () => {
      const industryTypes = await getIndustryTypeList();
      const employerXp = await getEmployersExp(id);

      employerXp.sort((a, b) => a.startYear - b.startYear);
      setIndustryTypeRefs(industryTypes);
      setEmployerExps(employerXp);

      setIsLoading(false);
    };

    fetchProfessionalHistoric();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="content-historico-profesional">
        <div>
          <HeaderCarouselMandala
            actualTitle={wheelCollaboratorTranslate.professionalHistoric}
            prevTitle={wheelCollaboratorTranslate.careerPlan}
            routePrev={`/app/analytic/plan-carrera/${id}`}
            nextTitle={wheelCollaboratorTranslate.purpose}
            routeNext={`/app/analytic/proposito/${id}`}
            iconActual={<HistoricoProfessionalIcon />}
            iconPrev={<PlanCarreraIcon />}
            iconNext={<PropositoIcon />}
          />
        </div>

        {isLoading ? (
          <Loading />
        ) : (
          <div>
            <div className="timeline">
              {employerExps.length === 0 && <EmptyInformation />}
              {employerExps.length > 0 && <Timeline experiences={employerExps} />}
            </div>

            {employerExps.length > 0 && (
              <div className="info-content">
                <div className="row">
                  <p className="professional-historic-subtitle">
                    {professionalHistoricTranslate.collaboratorTrajectory}
                  </p>
                  <div className="col mb-4">
                    <p className="professional-historic-title mb-2">
                      {professionalHistoricTranslate.experienceTime}:{" "}
                      <small>
                        {CalculateEmployerXP().years} {professionalHistoricTranslate.yearsAnd}{" "}
                        {CalculateEmployerXP().months} {professionalHistoricTranslate.months}
                      </small>
                    </p>
                    <div className="d-inline-flex">{GenerateEmployerXPBar()}</div>
                  </div>

                  <div className="col mb-4">
                    <p className="professional-historic-title mb-2">
                      {professionalHistoricTranslate.workedIndustries}:
                    </p>
                    <div className="">{IndustryTags()}</div>
                  </div>
                </div>

                <div className="mb-4">
                  <p className="professional-historic-title mb-2">
                    {professionalHistoricTranslate.workedPositions}:
                  </p>
                  <div className="">
                    {employerExps.map((item, index) => (
                      <div className="professional-historic-badge" key={item.id + index}>
                        {item.job}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default HistoricoProfesional;
