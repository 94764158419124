export const transformLevelStringToLevelId = (levelText) => {
    let value;

    switch (levelText) {
        case "Operario":
            value = "1";
            break;
        case "Técnico":
            value = "2";
            break;
        case "Asistente":
            value = "3";
            break;
        case "Analista":
            value = "4";
            break;
        case "Especialistas / Coordinadores":
            value = "5";
            break;
        case "Especialista":
            value = "5";
            break;
        case "Jefe":
            value = "6";
            break;
        case "Subgerentes":
            value = "7";
            break;
        case "SubGerente":
            value = "7";
            break;
        case "Gerentes":
            value = "8";
            break;
        case "Gerente":
            value = "8";
            break;
        case "Director":
            value = "9";
            break;
        case "Alta Administración":
            value = "10";
            break;
        default:
            value = "";
            break;
    }

    return value;
};

export const parseTypeCurrency = (countryId) => {
    switch (countryId) {
        case 1:
            return "CLP";
        case 2:
            return "ARS";
        case 3:
            return "PEN";
        case 4:
            return "BRL";
        case 5:
            return "COP";
        case 6:
            return "MEX";
        case 7:
            return "USD";
        default:
            return "Moneda no reconocida";
    }
}

export const parseTypeCurrencyForParseFormat = (countryId) => {
    switch (countryId) {
        case 1:
            return "CLP";
        case 2:
            return "ARS";
        case 3:
            return "PEN";
        case 4:
            return "BRL";
        case 5:
            return "COP";
        case 6:
            return "MEX";
        case 7:
            return "USD";
        default:
            return "Moneda no reconocida";
    }
}
