import AssignScreen from "./Components/AssignScreen";

const Onboarding = () => {
    return (
        <div className="row">
            <div className="col offset-1">
                <AssignScreen title={"Onboarding"} />
            </div>
        </div>
    );
};

export default Onboarding;
