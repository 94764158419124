import { useEffect, useState } from "react";
import {
    getInternalVacancyByEmployee,
    postInternalEmployeeVacancy,
} from "../../API/Employee/InternalEmployeeVacancy";
import { getAreaList } from "../../API/Lists/area";
import {
    employeeExperienceTranslate,
    internalVacancies,
} from "../../i18n/translatedMessages";
import { getInternalVacancies } from "./../../API/Services/CareerPlan/InternalVacancies";
import ModalApplyVacancy from "./Components/ModalApplyVacancy/ModalApplyVacancy";
import ModalVacancyReferrer from "./Components/ModalVacancyReferrer/ModalVacancyReferrer";

const InternalVacanciesEmployeeView = () => {
    const [vacancies, setVacancies] = useState([]);
    const [areas, setAreas] = useState([]);
    const [show, setShow] = useState(false);
    const [showModalVacancyReferrer, setShowModalVacancyReferrer] =
        useState(false);
    const [dataModal, setDataModal] = useState({});
    const [dataModalVacancyReferrer, setDataModalVacancyReferrer] = useState(
        {}
    );
    const [postulated, setPostulated] = useState([]);

    const reqPostulatedVac = async () => {
        const user = JSON.parse(localStorage.getItem("user"));
        const vacPostAux = await getInternalVacancyByEmployee(user.id);
        setPostulated(vacPostAux);
    };

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        const requests = async () => {
            const vacAux = await getInternalVacancies();
            const vacAuxFilt = vacAux.filter(
                (vacFilt) => user.companyId === vacFilt.companyId
            );
            const areaAux = await getAreaList();
            setVacancies(vacAuxFilt);
            setAreas(areaAux);
        };
        reqPostulatedVac();
        requests();
    }, []);

    const handleShow = (data) => {
        setDataModal(data);
        setShow(true);
    };
    const handleHide = () => {
        setShow(false);
    };
    const handleShowModalVacancyReferrer = (data) => {
        setDataModalVacancyReferrer(data);
        setShowModalVacancyReferrer(true);
    };
    const handleHideModalVacancyReferrer = () => {
        setShowModalVacancyReferrer(false);
    };

    const handleSend = async (vacancieId) => {
        const user = JSON.parse(localStorage.getItem("user"));
        const sendAux = {
            internalVacanciesId: vacancieId,
            employerId: user.id,
        };
        await postInternalEmployeeVacancy(sendAux);
        reqPostulatedVac();
        setShow(false);
    };

    return (
        <>
            <div className="title d-flex align-items-center pb-4">
                <ModalApplyVacancy
                    data={dataModal}
                    show={show}
                    handleClose={handleHide}
                    handleSend={handleSend}
                />
                <ModalVacancyReferrer
                    show={showModalVacancyReferrer}
                    handleClose={handleHideModalVacancyReferrer}
                    data={dataModalVacancyReferrer}
                />
                <div className="title-icon p-3">
                    <img
                        className="img-fluid"
                        alt=""
                        src="https://assets.mydna.company/b2b-images/icon-InternalVacancies.png"
                    />
                </div>
                <h1 className="ps-3">
                    {internalVacancies.internalVacanciesTitle}
                </h1>
            </div>
            <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 g-2 g-lg-3 internalvacanciesEmployeeView-main">
                {vacancies.length ? (
                    vacancies?.map((vacancy, index) => {
                        return (
                            <div key={index} className="col">
                                <div className="card h-100 shadow">
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            {vacancy.name}
                                        </h5>
                                        {areas
                                            ?.filter(
                                                (filter) =>
                                                    vacancy.area1 === filter.id
                                            )
                                            .map((area, indexArea) => {
                                                return (
                                                    <p
                                                        key={indexArea}
                                                        className="card-text"
                                                    >
                                                        {area.profileArea}
                                                    </p>
                                                );
                                            })}

                                        <div className="card-footer d-flex justify-content-between align-items-center">
                                            {postulated?.some(
                                                (post) =>
                                                    post.internalVacanciesId ===
                                                    vacancy.id
                                            ) ? (
                                                <>
                                                    <span
                                                        style={{
                                                            color: "#BCE165",
                                                        }}
                                                    >
                                                        Postulación enviada
                                                    </span>
                                                    <button
                                                        className="btn ms-auto"
                                                        style={{
                                                            backgroundColor:
                                                                "#7A6BE8",
                                                            color: "white",
                                                            fontWeight: "600",
                                                        }}
                                                        onClick={() =>
                                                            handleShowModalVacancyReferrer(
                                                                vacancy
                                                            )
                                                        }
                                                    >
                                                        Indicar
                                                    </button>
                                                </>
                                            ) : (
                                                <>
                                                    <button
                                                        className="btn"
                                                        style={{
                                                            backgroundColor:
                                                                "#41C8FF",
                                                            color: "white",
                                                            fontWeight: "600",
                                                        }}
                                                        onClick={() =>
                                                            handleShow(vacancy)
                                                        }
                                                    >
                                                        Postular
                                                    </button>

                                                    <button
                                                        className="btn ms-auto"
                                                        style={{
                                                            backgroundColor:
                                                                "#7A6BE8",
                                                            color: "white",
                                                            fontWeight: "600",
                                                        }}
                                                        onClick={() =>
                                                            handleShowModalVacancyReferrer(
                                                                vacancy
                                                            )
                                                        }
                                                    >
                                                        Indicar
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <h5>{employeeExperienceTranslate.emptyInternalJobs}</h5>
                )}
            </div>
        </>
    );
};

export default InternalVacanciesEmployeeView;
