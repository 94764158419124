import { useState } from 'react'
import NewMenu from '../Services/Components/NewMenu/MenuPlan'
import { ServicesRoutes } from '../../Routes/Routes'

import './index.sass'

const ServicesView = props => {
	const [option, setOption] = useState(String(props.location.option))

	const handleMenu = event => {
		setOption(event.currentTarget.dataset.option)
	}

	return (
		<div className='row'>
			<div className='col-3 py-5 menu-scrolling-management'>
				<NewMenu handleMenu={handleMenu} option={option} />
			</div>
			<div className='col-9 py-5 scrollable'>
				<ServicesRoutes />
			</div>
		</div>
	)
}
export default ServicesView
