import { performancePotentialTranslate } from '../../../i18n/translatedMessages'

export function DeleteModal({ show, onClick, close, title, index }) {
	const handleClick = () => {
		onClick(index)
		close()
	}

	if (show) {
		return (
			<div className='tw-absolute tw-z-50 tw-flex tw-items-center tw-justify-center tw-top-0 tw-left-0 tw-w-screen tw-h-screen tw-bg-black/10 tw-backdrop-blur-sm'>
				<div className='tw-p-5 tw-rounded-xl tw-shadow-xl tw-bg-white tw-w-[475px] tw-text-black' style={{ border: '1px solid #F43F5E' }}>
					<div className='tw-flex tw-flex-col tw-items-start'>
						<h5>{title}</h5>
						<p className='tw-text-left'>{performancePotentialTranslate.deleteCriterionText}</p>
						<div className='tw-flex tw-gap-4 tw-mt-5 tw-w-full'>
							<button onClick={close} className='tw-rounded-full tw-py-2 tw-px-3 tw-w-full tw-bg-white hover:tw-bg-[#DFE9F8]' style={{ border: '1px solid #CBD5E1' }}>
								Cancelar
							</button>
							<button onClick={handleClick} className='tw-rounded-full tw-py-2 tw-px-3 tw-w-full tw-text-white tw-bg-[#F43F5E] hover:tw-bg-[#CF0025]' style={{ border: '1px solid #F43F5E' }}>
								Eliminar critério
							</button>
						</div>
					</div>
				</div>
			</div>
		)
	}

	return <></>
}
