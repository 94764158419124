import { useEffect, useState } from "react";
import { employeeExperienceTranslate } from "../../../../../i18n/translatedMessages";
import "./menuForm.sass";

export const MenuForm = () => {
    const [url, setUrl] = useState("");

    useEffect(() => {
        setUrl(window.location.hash);
    }, [window.location.hash]);

    return (
        <ul className="menu-form">
            <li className={url === "#personal" || url === "" ? "active" : ""}>
                <a href="#personal">
                    {employeeExperienceTranslate.personalTitleEEProfile}
                </a>
            </li>
            <li className={url === "#socialNetwork" ? "active" : ""}>
                <a href="#socialNetwork">
                    {employeeExperienceTranslate.socialInformationEEProfile}
                </a>
            </li>
            <li className={url === "#family" ? "active" : ""}>
                <a href="#family">
                    {employeeExperienceTranslate.familyTitleEEProfile}
                </a>
            </li>
            <li className={url === "#address" ? "active" : ""}>
                <a href="#address">
                    {employeeExperienceTranslate.adressTitleEEProfile}
                </a>
            </li>
            <li className={url === "#contract" ? "active" : ""}>
                <a href="#contract">
                    {employeeExperienceTranslate.contractTitleEEProfile}
                </a>
            </li>
            <li className={url === "#health" ? "active" : ""}>
                <a href="#health">
                    {employeeExperienceTranslate.healthTitleEEProfile}
                </a>
            </li>
            <li className={url === "#interests" ? "active" : ""}>
                <a href="#interests">
                    {employeeExperienceTranslate.interestsTitleEEProfile}
                </a>
            </li>
        </ul>
    );
};
