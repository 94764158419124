export const getEmployersExp = async (id) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API}/employersexp/exp/${id}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.json();
  } catch (error) {
    console.error(error);
  }
};
