import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { FormacionIcon, HistoricoProfessionalIcon, PropositoIcon } from '../../Icons/CollaboratorsIcons'
import { purposeTranslate, wheelCollaboratorTranslate } from '../../i18n/translatedMessages'

import { getPurposeByEmployeeId } from '../../API/Employee/Purpose'

import EmptyInformation from './Components/EmptyInformation/EmptyInformation'

import './proposito.sass'
import HeaderCarouselMandala from './Components/HeaderCarouselMandala/HeaderCarouselMandala'
import { useIntl } from 'react-intl'
import { translate } from './../../i18n/Library/purpose'
import Loading from '../../Components/Loading/Loading'

const Proposito = () => {
	const getLanguage = useIntl()
	const language = getLanguage.locale === 'PT-BR' ? 'pt' : 'es'
	const { id } = useParams()
	const [purposeList, setPurposeList] = useState([])
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		const getPurposeRequest = async () => {
			setLoading(true)
			const getPurposeByEmployee = await getPurposeByEmployeeId(id)
			if (getPurposeByEmployee.data) {
				const AuxList = getPurposeByEmployee?.data?.map(purposes => {
					return purposes.employerPurposeToPurpose
				})
				setPurposeList(AuxList)
			}
			setLoading(false)
		}

		getPurposeRequest()
	}, [])

	if (loading) {
		return <Loading />
	}

	return (
		<div className='content-proposito'>
			<div>
				<HeaderCarouselMandala
					actualTitle={wheelCollaboratorTranslate.purpose}
					prevTitle={wheelCollaboratorTranslate.professionalHistoric}
					routePrev={`/app/analytic/historico-profesional/${id}`}
					nextTitle={wheelCollaboratorTranslate.formation}
					routeNext={`/app/analytic/formacion/${id}`}
					iconActual={<PropositoIcon />}
					iconPrev={<HistoricoProfessionalIcon />}
					iconNext={<FormacionIcon />}
				/>
			</div>

			<div className='content-pills'>
				<h6>{purposeTranslate.purposeToWork}:</h6>
				<div className='rows-purpose'>
					{purposeList.map((y, index) => {
						return (
							<div key={index} className='purpose-show'>
								{translate[language][y.definition]}
							</div>
						)
					})}
				</div>
			</div>
			{purposeList.length === 0 && <EmptyInformation />}
		</div>
	)
}
export default Proposito
