import { englishLineLevelTranslate } from "../../../../i18n/translatedMessages"

const Englishline = () => {
    return (
        <svg width="640" height="50" >
            <line x1="10" x2="410" y1="8" y2="8" stroke="#4e5857" strokeWidth="3" />
            
            <line x1="10" x2="10" y1="1" y2="15" stroke="#4e5857" strokeWidth="2" />
            <text x="-1" y="30">{englishLineLevelTranslate.nulo}</text>

            <line x1="100" x2="100" y1="1" y2="15" stroke="#4e5857" strokeWidth="2" />
            <text x="75" y="30">{englishLineLevelTranslate.basic}</text>

            <line x1="210" x2="210" y1="1" y2="15" stroke="#4e5857" strokeWidth="2" />
            <text x="165" y="30">{englishLineLevelTranslate.intermedio}</text>

            <line x1="310" x2="310" y1="1" y2="15" stroke="#4e5857" strokeWidth="2" />
            <text x="275" y="30">{englishLineLevelTranslate.avanzado}</text>

            <line x1="410" x2="410" y1="1" y2="15" stroke="#4e5857" strokeWidth="2" />
            <text x="385" y="30">{englishLineLevelTranslate.fluido}</text>
        </svg>
    )
}
export default Englishline