import { useEffect, useState, useContext } from "react";
import { CompanyContext } from "../../../../Store/CompanyContext";

import { getClimate, postClimate } from "../../../../API/Employee/Climate";
import ModalResponseStatus from "../../../../Components/ModalResponseStatus/ModalResponseStatus";

import "./styles.css";
import Loading from "../../../../Components/Loading/Loading";
import {
    employeeExperienceTranslate,
    modalAssignTranslate,
} from "../../../../i18n/translatedMessages";

const ClimaQuestions = ({ questions, surveyType}) => {
    const { store, actions } = useContext(CompanyContext);
    const [user, setUser] = useState();
    const objEmpty = {
        question1: null,
        question2: null,
        question3: null,
        question4: null,
        question5: null,
        question6: null,
        question7: null,
        question8: null,
        question9: null,
        question10: null,
        question11: null,
        question12: null,
        question13: null,
        question14: null,
        question15: null,
        question16: null,
        question17: null,
        question18: null,
        question19: null,
        question20: null,
        question21: null,
        question22: null,
        question23: null,
        question24: null,
        question25: null,
        surveyType: null,
    };
    const [climateAnswers, setClimateAnswers] = useState([objEmpty]);
    const [btnCondition, setBtnCondition] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem("user")));
        const users = JSON.parse(localStorage.getItem("user"));

        const getRequests = async () => {
            setLoading(true);
            const climateAnswerList = await getClimate(users.id, surveyType);

            if (climateAnswerList) {
                setClimateAnswers(climateAnswerList);
            }
            setLoading(false);
        };
        getRequests();
    }, []);

    useEffect(() => {
        const filtAnswers = Object.values(climateAnswers)
            .filter((x) => x === null || x === "")
            .map((y) => {
                return y;
            });
        if (filtAnswers.length !== 0) {
            setBtnCondition(true);
        } else {
            setBtnCondition(false);
        }
    }, [climateAnswers]);

    const handleChange = (e) => {
        const auxArray = { ...climateAnswers };
        const url = window.location.href;
        const typeSurvey = url.includes("salida") ? 1 : 0;
        auxArray.surveyType = typeSurvey;
        auxArray[e.currentTarget.name] = Number(e.currentTarget.value);
        setClimateAnswers({ ...auxArray });
    };

    const handleSend = async () => {
        const postEmp = await postClimate(climateAnswers, user.id, surveyType);
        if (postEmp.status < 200 || postEmp.status > 299) {
            if (postEmp.status > 399 && postEmp.status < 500) {
                actions.setResponseStatus(false);
                actions.setMessageResponseStatus(modalAssignTranslate.errorAlert);
                actions.setShowModalResponseStatus(true);
            } else {
                actions.setResponseStatus(false);
                actions.setMessageResponseStatus(modalAssignTranslate.errorAlert);
                actions.setShowModalResponseStatus(true);
            }
        } else {
            actions.setResponseStatus(true);
            actions.setMessageResponseStatus(modalAssignTranslate.successAlert);
            actions.setShowModalResponseStatus(true);
        }
    };
    const handleClose = () => {
        actions.setShowModalResponseStatus(false);
    };

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <ModalResponseStatus
                        show={store.showModalResponseStatus}
                        handleClose={handleClose}
                        successMessage={store.responseStatus}
                        message={store.messageResponseStatus}
                        btnRight={"Listo"}
                    />
                    {questions.map((quest, indexQ) => {
                        return (
                            <div
                                key={indexQ}
                                className="shadow card-clima card-entrenamiento p-0 mt-3"
                            >
                                <div className="card-title card-title-bg-clima-questions p-3">
                                    <h4 className="card-title-bg-clima-questions m-0">
                                        {quest.title}
                                    </h4>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <div className="table">
                                            <div>
                                                {quest.data.map(
                                                    (data, index) => {
                                                        return (
                                                            <div
                                                                key={index}
                                                                className="text-center"
                                                            >
                                                                <div className="card-item-center">
                                                                    {
                                                                        data.question
                                                                    }
                                                                </div>
                                                                <div className="inline-input">
                                                                    {
                                                                        employeeExperienceTranslate.climeQuestionsEEH1
                                                                    }
                                                                </div>
                                                                {data.answers.map(
                                                                    (
                                                                        resp,
                                                                        key
                                                                    ) => {
                                                                        return (
                                                                            <div
                                                                                key={
                                                                                    key
                                                                                }
                                                                                className="inline-input"
                                                                            >
                                                                                <div className="form-check-inline">
                                                                                    <input
                                                                                        className="form-check-input float-none radioCheck"
                                                                                        type="radio"
                                                                                        name={
                                                                                            data.inputName
                                                                                        }
                                                                                        value={
                                                                                            resp
                                                                                        }
                                                                                        onChange={
                                                                                            handleChange
                                                                                        }
                                                                                        checked={
                                                                                            climateAnswers[
                                                                                                data
                                                                                                    .inputName
                                                                                            ] ===
                                                                                            Number(
                                                                                                resp
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    }
                                                                )}
                                                                <div className="inline-input">
                                                                    {
                                                                        employeeExperienceTranslate.climeQuestionsEEH4
                                                                    }
                                                                </div>
                                                                <div className="line-visual"></div>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    <div className="col-3">
                        <button
                            className="btn btn-save mt-5"
                            onClick={handleSend}
                            disabled={btnCondition}
                        >
                            {modalAssignTranslate.btnSave}
                        </button>
                    </div>
                </>
            )}
        </>
    );
};
export default ClimaQuestions;
