import { useEffect, useState } from "react";
import { IoIosPeople } from "react-icons/io";
import { getCultureValue } from "../../API/Employee/Culture";
import { cultureTranslate } from "../../i18n/translatedMessages";

import ListaColaboradores from "../Service/Views/PeopleAnalytics/Management/ListaColaboradores";
import MatchFinished from "./MatchFinished";

import {
	getCompanyCulture,
	postCompanyCulture,
} from "../../API/Services/Culture/Culture";
import Loading from "../../Components/Loading/Loading";
import {
	carreraQuestions,
	culturalQuestions,
	interesQuestions,
	liderazgoQuestions,
} from "./Components/Questions/questions";
import Culture from "./Culture";
import Form from "./Form";
import "./businessCulture.sass";

const MatchCulture = () => {
	const { id } = JSON.parse(localStorage.getItem("user"));

	const [component, setComponent] = useState(0);
	const [answer, setAnswer] = useState([
		[false, false, false, false, false, false],
		[false, false, false, false, false, false],
		[false, false, false, false, false, false],
		[false, false, false, false, false, false],
	]);
	const [loading, setLoading] = useState(false);
	const [profile, setProfile] = useState(null);

	useEffect(() => {
		const fetchCultural = async () => {
			const response = await getCompanyCulture(id);
			if (response.status===200) {
				setProfile(await response.json());
				setComponent(5);
			}
		};
		fetchCultural();
	}, []);

	const handleContinue = () => {
		setComponent((prevState) => prevState + 1);
	};

	const handleClick = (event) => {
		const index = event.currentTarget.dataset.answer;
		const arrayIndex = event.currentTarget.dataset.index - 1;
		const array = [...answer];

		array[arrayIndex][index] = !answer[arrayIndex][index];
		setAnswer(array);
	};

	const handleSend = async () => {
		setLoading(true);
		const data = {
			companyId: id,
			answers: answer,
		};
		await postCompanyCulture(data);
		const response = await getCompanyCulture(id);
		setProfile(response);
		setLoading(false);
		setComponent(5);
	};

	const components = [
		<Form />,
		<Culture
			questions={culturalQuestions}
			index={1}
			answer={answer[0]}
			setAnswer={setAnswer}
			handleClick={handleClick}
		/>,
		<Culture
			questions={carreraQuestions}
			index={2}
			answer={answer[1]}
			setAnswer={setAnswer}
			handleClick={handleClick}
		/>,
		<Culture
			questions={liderazgoQuestions}
			index={3}
			answer={answer[2]}
			setAnswer={setAnswer}
			handleClick={handleClick}
		/>,
		<Culture
			questions={interesQuestions}
			index={4}
			answer={answer[3]}
			setAnswer={setAnswer}
			handleClick={handleClick}
		/>,
		<MatchFinished index={5} profile={profile} />,
	];

	const [bars, setBars] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			const response = await getCultureValue(id);
			setBars([
				{
					id: 0,
					icon: "https://assets.mydna.company/Comercial.svg",
					percentagem: !!response.profile
						? parseFloat(response.profile["comercial"]).toFixed(2)
						: null,
					type: cultureTranslate.commercial,
					color: "#7a6be6",
					textArea: cultureTranslate.commercialText,
				},
				{
					id: 1,
					icon: "https://assets.mydna.company/Disruptivo.svg",
					percentagem: !!response.profile
						? parseFloat(response.profile["disruptiva"]).toFixed(2)
						: null,
					type: cultureTranslate.disruptive,
					color: "#41c6ff",
					textArea: cultureTranslate.disruptiveText,
				},
				{
					id: 2,
					icon: "https://assets.mydna.company/Colaborativo.svg",
					percentagem: !!response.profile
						? parseFloat(response.profile["colaborativa"]).toFixed(
								2
						  )
						: null,
					type: cultureTranslate.collaborative,
					color: "#2be7c8",
					textArea: cultureTranslate.collaborativeText,
				},
				{
					id: 3,
					icon: "https://assets.mydna.company/Tradicional.svg",
					percentagem: !!response.profile
						? parseFloat(response.profile["tradicional"]).toFixed(2)
						: null,
					type: cultureTranslate.traditional,
					color: "#38364b",
					textArea: cultureTranslate.traditionalText,
				},
			]);
		};
		fetchData();
	}, []);

	return (
		<div className="row loading-content">
			{loading && (
				<div className="loading">
					<Loading />
				</div>
			)}
			<div className="col offset-1">
				<div className="content-business-culture">
					<div className="row mb-1">
						<div className="col d-flex">
							<div className="icon mx-4">
								<IoIosPeople size={70} />
							</div>
							<h1>Match Cultural</h1>
						</div>
					</div>
					<div className="row">
						<div className="col-12">{components[component]}</div>
						<div className="col-12">
							<ListaColaboradores />
						</div>
					</div>
					<div className="row mt-5">
						<div className="col-8 offset-2 d-flex justify-content-center">
							{component !== components.length - 1 &&
								component < 4 && (
									<button onClick={handleContinue}>
										Continuar
									</button>
								)}
							{component === 4 && (
								<button onClick={handleSend}>Finalizar</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default MatchCulture;
