// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sent {
  background: #EBF7E6;
  color: #004500;
}

.pending {
  color: #513400;
  background: #F7D9A7;
}

.documentation-icon {
  background: linear-gradient(45deg, #40c2ff 0%, #32d8d8 100%);
  width: 50px;
  height: 50px;
  border-radius: 50rem;
  padding: 8px;
  margin: 0 10px;
  display: flex;
  align-items: center;
}
.documentation-icon svg {
  fill: white;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Views/Service/Views/Documents/employeeDocuments.sass"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,cAAA;AACJ;;AACA;EACI,cAAA;EACA,mBAAA;AAEJ;;AACA;EACI,4DAAA;EACA,WAAA;EACA,YAAA;EACA,oBAAA;EACA,YAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;AAEJ;AAAI;EACI,WAAA;EACA,WAAA;AAER","sourcesContent":[".sent\r\n    background: #EBF7E6\r\n    color: #004500\r\n\r\n.pending\r\n    color: #513400\r\n    background: #F7D9A7\r\n\r\n\r\n.documentation-icon\r\n    background: linear-gradient(45deg,#40c2ff 0%, #32d8d8 100%)\r\n    width: 50px\r\n    height: 50px\r\n    border-radius: 50rem\r\n    padding: 8px\r\n    margin: 0 10px\r\n    display: flex\r\n    align-items: center\r\n    \r\n    & svg\r\n        fill: white\r\n        width: 100%"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
