import { Modal, Row } from "react-bootstrap";
import Loading from "../../../Components/Loading/Loading";
import { btnTranslate } from "../../../i18n/translatedMessages";

const ModalEditCapacitation = ({
  show,
  hide,
  data,
  loading,
  areaList,
  handleChange,
  handleSendChange,
}) => {
  return (
    <Modal
      show={show}
      className="create-course"
      onHide={hide}
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="title">Editar curso </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="loading">
            <Loading />
          </div>
        ) : (
          <Row className="justify-content-center">
            <div className="col-10">
              <Row>
                <div className="row">
                  <div className="col">
                    <input
                      type="text"
                      value={data.name}
                      name="name"
                      placeholder="Nombre de curso"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <select value={data.areaId} onChange={handleChange} name="areaId">
                      <option>Areas</option>
                      {areaList?.map((area) => {
                        return (
                          <option key={area.id} value={area.id}>
                            {area.profileArea}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <textarea
                      name="description"
                      cols="30"
                      rows="10"
                      value={data.description}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <input
                      type="text"
                      value={data.link}
                      name="link"
                      placeholder="Link del curso"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </Row>
            </div>
          </Row>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          className="create"
          variant="primary"
          onClick={() => handleSendChange(data.capacitationId)}
        >
          {btnTranslate.saveButton}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalEditCapacitation;
