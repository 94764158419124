export function CalcEmployeeEvaluation(item) {
	if (item) {
		const calculationOfCriteria = item.reduce((accumulator, currentValue) => {
			const calc = currentValue.value * currentValue.weight
			return accumulator + calc
		}, 0)

		const sumOfWeights = item.reduce((accumulator, currentValue) => accumulator + currentValue.weight, 0)
		const weightedAverage = calculationOfCriteria / sumOfWeights

		return weightedAverage / 20 || 0
	}

	return 0
}
