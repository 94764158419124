export const translate = {
	es: {
		'Indiferente': 'Indiferente',
		'Junior': 'Junior',
		'Mediano': 'Mediano',
		'Senior': 'Senior',
	},

	pt: {
		'Indiferente': 'Indiferente',
		'Junior': 'Júnior',
		'Mediano': 'Mediano',
		'Senior': 'Sênior',
	},
}
