const getState = ({ getStore, getActions, setStore }) => {
  return {
    store: {
      employerID: '',
      language: 'ES',
      options: '1',
      employeer: null
    },
    actions: {
      setEmployerID: (id) => setStore({ employerID: id }),
      setEmployeer: (data) => setStore({ employeer: data }),
      setLanguage: (language) => setStore({ language }),
      setOptions: (event) => setStore({ options: event }),
    },
  };
};
export default getState;
