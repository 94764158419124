import { useEffect, useState } from "react";
import { BsArrowLeftCircle, BsArrowRight } from "react-icons/bs";
import { FormattedMessage, useIntl } from "react-intl";
import { getAreaList } from "../../../../../../API/Lists/area";
import { getHierarchicalLevelList } from "../../../../../../API/Lists/hierarchicalLevel";
import Loading from "../../../../../../Components/Loading/Loading";
import { translate as translateAreas } from "../../../../../../i18n/Library/areasCorrelation";
import { translate as translateLevel } from "../../../../../../i18n/Library/hierarchicalLevel";
import { internalJobsTranslate } from "../../../../../../i18n/translatedMessages";

import "./step.sass";

const InternalVacanciesStep1 = ({
	handleChange,
	data,
	jumpToStep,
	imgCompany,
}) => {
	const company = JSON.parse(localStorage.getItem("user"));

	const getLanguage = useIntl();
	const language = getLanguage.locale === "PT-BR" ? "pt" : "es";

	const [areaList, setAreaList] = useState([]);
	const [hierarchList, setHierarchList] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const resquests = async () => {
			setLoading(true);
			const areaListAux = await getAreaList();
			const hierarchListAux = await getHierarchicalLevelList();
			setAreaList(areaListAux);
			setHierarchList(hierarchListAux);
			setLoading(false);
		};
		resquests();
	}, []);

	return (
		<div className="InternalVacancies-step1">
			{loading ? (
				<Loading />
			) : (
				<div className="card">
					<div className="logo">
						<img
							src={
								imgCompany ||
								"https://assets.mydna.company/mydnalogo.png"
							}
							alt={company.name}
						/>
					</div>
					<div className="card-header">
						<div className="row">
							<div className="col-4 d-flex align-items-center">
								<BsArrowLeftCircle size={20} />
								<span>
									{internalJobsTranslate.internalJobStep1}
								</span>
							</div>
						</div>
					</div>
					<div className="card-body">
						<div className="row row-cols-2">
							<div className="col">
								<div className="row">
									<div className="col">
										<div className="card-white">
											<h4>
												{
													internalJobsTranslate.internalJobName
												}
											</h4>
											<input
												type="text"
												name="name"
												className="m-0"
												onChange={handleChange}
											/>
										</div>
									</div>
								</div>
								<div className="row mt-2">
									<div className="col">
										<div className="card-white">
											<h4>
												{
													internalJobsTranslate.internalJobArea
												}
											</h4>
											<select
												value={data.areaA}
												name="area1"
												onChange={handleChange}
											>
												<FormattedMessage id="internalJobOptionArea">
													{(msg) => (
														<option value={0}>
															{msg}
														</option>
													)}
												</FormattedMessage>
												{areaList?.map((item) => (
													<option
														key={item.id}
														value={item.id}
													>
														{
															translateAreas[
																language
															][item.profileArea]
														}
													</option>
												))}
											</select>
										</div>
									</div>
								</div>
							</div>
							<div className="col">
								<div className="row">
									<div className="col">
										<div className="card-white">
											<h4>
												{
													internalJobsTranslate.internalJobNivel
												}
											</h4>
											<select
												value={data.hierarchicalLevel}
												name="hierarchical_level"
												onChange={handleChange}
											>
												<FormattedMessage id="internalJobOptionNivel">
													{(msg) => (
														<option value="">
															{msg}
														</option>
													)}
												</FormattedMessage>
												{hierarchList?.map((item) => (
													<option
														key={item.id}
														value={item.id}
													>
														{
															translateLevel[
																language
															][item.level]
														}
													</option>
												))}
											</select>
											<div></div>
										</div>
									</div>
								</div>
								<div className="row mt-2">
									<div className="col">
										<div className="card-white">
											<h4>
												{
													internalJobsTranslate.internalJobExperienceYears
												}
											</h4>
											<input
												type="number"
												name="time_experience"
												onChange={handleChange}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row mt-3">
							<div className="col d-flex justify-content-center ">
								<button
									onClick={() => jumpToStep(1)}
									className="btn-next"
								>
									<BsArrowRight size={25} />
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
export default InternalVacanciesStep1;
