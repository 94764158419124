export const getMisSoluciones = async (companyId) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_API}/companiewallet/${companyId}`,
      {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
      }
    );
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};