import { useState, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import AvatarEditor from 'react-avatar-editor';

import './modalImagem.sass';

const ModalImagem = ({ show, handleClose, image, handleImage, setImage, handleSend }) => {
  const [scale, setScale] = useState(1);

  const editor = useRef(null);

  const onScaleChange = (event) => {
    setScale(parseFloat(event.target.value));
  };

  const save = () => {
    const img = editor.current.getImageScaledToCanvas().toDataURL();
    setImage(img);
    handleImage(img);
    handleClose();
    handleSend(img);
  };

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered className="modal-perfil-image-industry">
      <Modal.Header>
        <Modal.Title>Image Perfil</Modal.Title>
        <div className="btn-close" onClick={handleClose}></div>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column align-items-center">
        <AvatarEditor ref={editor} image={image} width={250} height={250} border={50} color={[255, 255, 255, 0.8]} scale={scale} rotate={0} borderRadius={150} />
        <span className="input-zoom">Zoom:</span>
        <input type="range" min="0.1" max="1" step="0.1" onChange={onScaleChange} value={scale} className="input-range-scale" />
        <Button variant="white" className="rounded-pill" onClick={save}>
          Save
        </Button>
      </Modal.Body>
    </Modal>
  );
};
export default ModalImagem;
