export const services = [
  {
    title: "Reclutamiento & Selección",
    plan: [
      {
        id: 1,
        name: "Publicación Vacantes",
        icon: "https://assets.mydna.company/mi-empresa_white.svg",
      },
      {
        id: 2,
        name: "Entrevista con Especialsitas",
        icon: "https://assets.mydna.company/people_analytics_white.svg",
      },
      {
        id: 3,
        name: "Descripción de Cargos",
        icon: "https://assets.mydna.company/reclutamiento_y_selection_white.svg",
      },
      {
        id: 4,
        name: "Evaluación  Inglés",
        icon: "https://assets.mydna.company/onboarding_white.svg",
      },
      {
        id: 5,
        name: "Salario de Mercado",
        icon: "https://assets.mydna.company/capacitacion_y_desarrollo_white.svg",
      },
      {
        id: 6,
        name: "Match Cultural ",
        icon: "https://assets.mydna.company/salario_y_beneficios_white.svg",
      },
      {
        id: 7,
        name: "Habilidades & Motivaciones",
        icon: "https://assets.mydna.company/plan_de_carrera_white.svg",
      },
      {
        id: 8,
        name: "Onboarding Guiado",
        icon: "https://assets.mydna.company/clima_laboral_white.svg",
      },
    ],
  },
  {
    title: "Desarrollo Organizacional",
    plan: [
      {
        id: 9,
        name: "Encuesta de Clima",
        icon: "https://assets.mydna.company/mi-empresa_white.svg",
      },
      {
        id: 10,
        name: "Encuesta de Cultura",
        icon: "https://assets.mydna.company/people_analytics_white.svg",
      },
      {
        id: 11,
        name: "Transición Cultural",
        icon: "https://assets.mydna.company/reclutamiento_y_selection_white.svg",
      },
      {
        id: 12,
        name: "Análisis de Desempeño",
        icon: "https://assets.mydna.company/onboarding_white.svg",
      },
      {
        id: 13,
        name: "Mapeo de Capacitaciones",
        icon: "https://assets.mydna.company/capacitacion_y_desarrollo_white.svg",
      },
      {
        id: 14,
        name: "Test de Habilidades",
        icon: "https://assets.mydna.company/salario_y_beneficios_white.svg",
      },
      {
        id: 15,
        name: "Test de Motivaciones",
        icon: "https://assets.mydna.company/plan_de_carrera_white.svg",
      },
      {
        id: 16,
        name: "Plan de Carrera",
        icon: "https://assets.mydna.company/clima_laboral_white.svg",
      },
    ],
  },
  {
    title: "Salarios & Beneficios",
    plan: [
      {
        id: 25,
        name: "Consulta por Cargo",
        icon: "https://assets.mydna.company/mi-empresa_white.svg",
      },
      {
        id: 26,
        name: "Consulta Masiva",
        icon: "https://assets.mydna.company/people_analytics_white.svg",
      },
      {
        id: 27,
        name: "Beneficios",
        icon: "https://assets.mydna.company/reclutamiento_y_selection_white.svg",
      },
      {
        id: 28,
        name: "Salario Emocional",
        icon: "https://assets.mydna.company/onboarding_white.svg",
      },
      {
        id: 29,
        name: "Remuneración Variable",
        icon: "https://assets.mydna.company/capacitacion_y_desarrollo_white.svg",
      },
      {
        id: 30,
        name: "Proyección Salarial",
        icon: "https://assets.mydna.company/salario_y_beneficios_white.svg",
      },
      {
        id: 31,
        name: "Descripción de Cargos",
        icon: "https://assets.mydna.company/plan_de_carrera_white.svg",
      },
      {
        id: 32,
        name: "Indicadores de Mercado",
        icon: "https://assets.mydna.company/clima_laboral_white.svg",
      },
    ],
  },
  {
    title: "Plan de Salida",
    plan: [
      {
        id: 33,
        name: "Guía Desvinculación",
        icon: "https://assets.mydna.company/mi-empresa_white.svg",
      },
      {
        id: 34,
        name: "Encuesta de Salida",
        icon: "https://assets.mydna.company/people_analytics_white.svg",
      },
      {
        id: 35,
        name: "Indicadores de Renuncia",
        icon: "https://assets.mydna.company/reclutamiento_y_selection_white.svg",
      },
      {
        id: 36,
        name: "Tasa de Rotación",
        icon: "https://assets.mydna.company/onboarding_white.svg",
      },
      {
        id: 37,
        name: "Outplacement Digital",
        icon: "https://assets.mydna.company/capacitacion_y_desarrollo_white.svg",
      },
      {
        id: 38,
        name: "Seguimiento de Recolocación",
        icon: "https://assets.mydna.company/salario_y_beneficios_white.svg",
      },
      {
        id: 39,
        name: " Sesiones Individuales",
        icon: "https://assets.mydna.company/plan_de_carrera_white.svg",
      },
      {
        id: 40,
        name: "Sesiones Webinars",
        icon: "https://assets.mydna.company/clima_laboral_white.svg",
      },
    ],
  },
  {
    title: "People Analytics",
    plan: [
      {
        id: 41,
        name: "Evaluación Profesional",
        icon: "https://assets.mydna.company/mi-empresa_white.svg",
      },
      {
        id: 42,
        name: "Indicadores de Formación",
        icon: "https://assets.mydna.company/people_analytics_white.svg",
      },
      {
        id: 43,
        name: "Indicadores de Desempeño",
        icon: "https://assets.mydna.company/reclutamiento_y_selection_white.svg",
      },
      {
        id: 44,
        name: "Indicadores Cultura",
        icon: "https://assets.mydna.company/onboarding_white.svg",
      },
      {
        id: 45,
        name: "Habilidades & Job Fit",
        icon: "https://assets.mydna.company/capacitacion_y_desarrollo_white.svg",
      },
      {
        id: 46,
        name: "Motivaciones & Job Fit",
        icon: "https://assets.mydna.company/salario_y_beneficios_white.svg",
      },
      {
        id: 47,
        name: "Indicadores de Compensaciones",
        icon: "https://assets.mydna.company/plan_de_carrera_white.svg",
      },
      {
        id: 48,
        name: "Plan de Carrera",
        icon: "https://assets.mydna.company/clima_laboral_white.svg",
      },
    ],
  },
];
