import { useState } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';

const SelectMultiple = ({ list, title, onClick }) => {
  const [show, setShow] = useState(false);

  const handleClick = () => {
    setShow(!show);
  };

  return (
    <div className={`select-multiple justify-content-center ${show ? 'open' : 'close'}`}>
      <div onClick={handleClick} className="d-flex align-items-center justify-content-between w-100 px-2">
        <span>{title}</span>
        <MdKeyboardArrowRight size={20} style={{ transform: show ? '' : 'rotate(90deg)' }} />
      </div>
      {show && (
        <ul>
          {list.map((item) => {
            return (
              <li className={`select-option ${item.active ? 'select-active' : ''}`} style={{ cursor: 'pointer' }} key={item.purposeId} onClick={onClick} data-object={JSON.stringify(item)}>
                #{item.purposeName}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
export default SelectMultiple;
