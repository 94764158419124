export const getPurposeList = async (language) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/purpose`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Localization: language,
      },
    });
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};

export const getPurposeByEmployeeId = async (id, language) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/purpose/${id}`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Localization: language,
      },
    });
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};

export const postPurpose = async (data) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/purpose`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};

export const deletePurpose = async (list, id) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/purpose/${id}`, {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(list),
    });
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};
