import { useEffect, useState } from "react";
import {
	HiChevronRight,
	HiMiniAdjustmentsHorizontal,
	HiPresentationChartLine,
	HiUserGroup,
} from "react-icons/hi2";

import Modal from "react-bootstrap/Modal";

import Loading from "../../../../../../Components/Loading/Loading";

import { BandCharts } from "./Charts";
import { Collaborators } from "./Collaborators";
import { EditBand } from "./EditBand";

const countrys = {
	chile: 1,
	argentina: 2,
	peru: 3,
	brasil: 4,
	colombia: 5,
	méxico: 6,
	ecuador: 7,
	uruguai: 8,
};

export const CollaboratorBandModal = ({
	handleClose,
	show,
	id,
	refresh,
	country,
	band,
	screenId,
}) => {
	const [loading, setLoading] = useState(false);
	const [screen, setScreem] = useState(null);

	useEffect(() => {
		setScreem(screenId);
	}, [show, screenId]);

	const handleSetScreen = (item) => {
		setScreem(Number(item));
	};

	return (
		<Modal
			show={show}
			onHide={handleClose}
			size="xl"
			centered
			className="rounded-3"
		>
			{loading && <Loading size="sm" />}
			{!loading && (
				<>
					<Modal.Header className="border-0 pb-1 " closeButton>
						<div className="row">
							<div className="col fs-6 text-dark-grey d-flex align-items-center">
								BANDA SALARIAL
								<HiChevronRight size={15} className="mx-2" />
								{country.toUpperCase()}
								<HiChevronRight size={15} className="mx-2" />
								{band.name.toUpperCase()}
							</div>
						</div>
					</Modal.Header>
					<Modal.Body className="pt-0">
						<div className="border-bottom border-2 py-1 mb-3 container">
							<div
								className="row border-grey mb-3"
								style={{
									border: "none",
									borderBottom: "2px solid",
									marginBottom: "-6px",
								}}
							>
								<div className="col-auto">
									<button
										className={`btn btn-white shadow-none align-items-center rounded-0 ${
											screen === 0
												? "text-primary border-primary"
												: ""
										}`}
										onClick={() => handleSetScreen(0)}
									>
										<HiMiniAdjustmentsHorizontal className="me-1" />
										Editar Banda
									</button>
								</div>

								<div className="col-auto">
									<button
										className={`btn btn-white shadow-none d-inline align-items-center rounded-0 ${
											screen === 1
												? "text-primary border-primary"
												: ""
										}`}
										onClick={() => handleSetScreen(1)}
									>
										<HiUserGroup className="me-1" />
										Editar Colaborador
									</button>
								</div>

								<div className="col-auto">
									<button
										className={`btn btn-white shadow-none d-inline align-items-center rounded-0 ${
											screen === 2
												? "text-primary border-primary"
												: ""
										}`}
										onClick={() => handleSetScreen(2)}
									>
										<HiPresentationChartLine className="me-1" />
										Ver Gráfico
									</button>
								</div>
							</div>
							{screen === 0 && (
								<EditBand
									handleClose={handleClose}
									id={band.id}
									refresh={refresh}
								/>
							)}
							{screen === 1 && (
								<Collaborators
									countryId={countrys[country.toLowerCase()]}
									handleClose={handleClose}
									band={band}
								/>
							)}
							{screen === 2 && <BandCharts id={band.id} />}
						</div>
					</Modal.Body>
				</>
			)}
		</Modal>
	);
};
