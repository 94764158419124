import { useEffect, useState } from 'react'
import { IoStarSharp } from 'react-icons/io5'

export function StarAvaliation({ label, value, eventColorText }) {
	const [event, setEvent] = useState(eventColorText)

	useEffect(() => {
		setEvent(eventColorText)
	}, [eventColorText])

	const renderStars = value => {
		const starIcons = []

		for (let starValue = 1; starValue <= 5; starValue++) {
			let starIcon

			if (starValue <= value) {
				starIcon = <IoStarSharp size={22} key={starValue} style={{ color: '#FBBF23' }} />
			} else if (starValue - value >= 0.1 && starValue - value <= 0.9) {
				starIcon = (
					<div key={starValue} style={{ position: 'relative', display: 'inline-block', width: '22px', height: '22px' }}>
						<IoStarSharp size={22} style={{ position: 'absolute', color: '#E1E8F0' }} />
						<IoStarSharp size={22} style={{ position: 'absolute', color: '#FBBF23', clipPath: 'inset(0 50% 0 0)' }} />
					</div>
				)
			} else {
				starIcon = <IoStarSharp size={22} key={starValue} style={{ color: '#E1E8F0' }} />
			}

			starIcons.push(starIcon)
		}

		return starIcons
	}

	return (
		<div className='tw-flex tw-flex-col tw-min-w-[150px]'>
			<div className={`${event === true ? 'tw-text-white' : 'tw-text-gray-400'}`}>{label}</div>
			<div className='tw-flex tw-items-center'>
				{value === null || value === '' || value === undefined || value === 0 || value === 0.0 || value === '0' || value === '0.0' ? (
					<div className={`${event === true ? 'tw-text-white' : 'tw-text-black'} tw-font-semibold tw-text-lg tw-mr-2`}>S/A</div>
				) : (
					<div className={`${event === true ? 'tw-text-white' : 'tw-text-black'} tw-font-semibold tw-text-lg tw-mr-2`}>{Math.min(value, 5).toFixed(1)}</div>
				)}
				{renderStars(value)}
			</div>
		</div>
	)
}
