export const StepsFormRequest = async (inputList) => {
  try {
    const token = localStorage.getItem("accessToken");
    const tokenJson = JSON.parse(token);
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/trainingAndDevelopment`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${tokenJson}`,
        },
        body: JSON.stringify(inputList),
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};


/**
 * Mudar o body e a rota
 */