export const getSalary = async (employerId) => {
    try {
        const resp = await fetch(
            `${process.env.REACT_APP_API}/salary/${employerId}`,
            {
                method: "GET",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        return resp.json();
    } catch (err) {
        console.error(err);
    }
};
export const postSalary = async (data, colabEmail = JSON.parse(localStorage.getItem("email")) ) => {
    try {
        const resp = await fetch(`${process.env.REACT_APP_API}/salary`, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify(data),
        });

        const data_to_smtm = {
            mydna_email_position: colabEmail,
            current_salary: data.salary,
            variable_salary: data.fixedSalary,
        };
        await respSmtm(data_to_smtm);

        return resp.json();
    } catch (err) {
        console.error(err);
    }
};
export const putSalary = async (data, salaryId, colabEmail = JSON.parse(localStorage.getItem("email"))) => {
    try {
        const resp = await fetch(
            `${process.env.REACT_APP_API}/salary/${salaryId}`,
            {
                method: "PUT",
                headers: {
                    "Content-type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );

        const data_to_smtm = {
            mydna_email_position: colabEmail,
            current_salary: data.salary,
            variable_salary: data.fixedSalary,
        };
        await respSmtm(data_to_smtm);

        return resp.json();
    } catch (err) {
        console.error(err);
    }
};

const respSmtm = async (data) => {
    try {
        const resp = await fetch(
            `https://smtm.co/v1/update_position_from_mydna`,
            {
                method: "PUT",
                headers: {
                    "Content-type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );

        return resp.json();
    } catch (err) {
        console.error(err);
    }
};
