class Bands {
	async getAllBands(companyID) {
		const response = await fetch(`${process.env.REACT_APP_API}/salaryband/company/${companyID}&order=asc`, {
			headers: {
				"Content-type": "application/json",
			}
		})

		return response.json()
	}

	async getBand(id) {
		const response = await fetch(`${process.env.REACT_APP_API}/salaryband/${id}`, {
			headers: {
				"Content-type": "application/json",
			}
		})

		return response.json()
	}

	async postBands(data) {
		const response = await fetch(`${process.env.REACT_APP_API}/salaryband`, {
			headers: {
				"Content-type": "application/json",
			},
			method: "POST",
			body: JSON.stringify(data)
		})

		return response.json()
	}

	async updateBands(id, data) {
		const response = await fetch(`${process.env.REACT_APP_API}/salaryband/${id}`, {
			headers: {
				"Content-type": "application/json",
			},
			method: "PUT",
			body: JSON.stringify(data)
		})

		return response.json()
	}

	async deleteBands(id) {
		const response = await fetch(`${process.env.REACT_APP_API}/salaryband/${id}`, {
			headers: {
				"Content-type": "application/json",
			},
			method: "DELETE"
		})

		return response.json()
	}


	async getEmployees(company_id, country, page = 1, size = 10, level = "", name = "") {
		const response = await fetch(`${process.env.REACT_APP_API}/salaryband/employees/${company_id}?country=${country}&page=${page}&size=${size}&level=${level}&name=${name}`, {
			headers: {
				"Content-type": "application/json",
			},
			method: "GET"
		})

		return response.json()
	}

	async getEmployeeByBand(id) {
		const response = await fetch(`${process.env.REACT_APP_API}/salaryband/band/${id}`, {
			headers: {
				"Content-type": "application/json",
			},
			method: "GET"
		})

		return response.json()
	}
}
export default new Bands()