// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-clima .icon {
  background: linear-gradient(45deg, #40c2ff 0%, #32d8d8 100%);
  width: 50px;
  height: 50px;
  border-radius: 50rem;
  padding: 8px;
  margin: 0 10px;
}
.dashboard-clima .icon svg {
  fill: white;
  width: 100%;
}
.dashboard-clima .title {
  color: #6a66d9;
  margin: 50px 0 0 20px;
}
.dashboard-clima .title h2 {
  color: #3a3a3a;
}
.dashboard-clima .container-chart {
  padding: 0;
}
.dashboard-clima .legends {
  list-style: none;
  font-size: small;
  padding: 0;
}
.dashboard-clima .legends li {
  margin: 5px 0;
  display: flex;
  align-items: flex-start;
}
.dashboard-clima .legends li .box {
  width: 25px;
  height: 15px;
  margin-right: 5px;
}
.dashboard-clima .legends li span {
  width: calc(100% - 50px);
}`, "",{"version":3,"sources":["webpack://./src/Views/Services/Components/DashboardsClima/dashboardClima.sass"],"names":[],"mappings":"AACI;EACI,4DAAA;EACA,WAAA;EACA,YAAA;EACA,oBAAA;EACA,YAAA;EACA,cAAA;AAAR;AAEQ;EACI,WAAA;EACA,WAAA;AAAZ;AAEI;EACI,cAAA;EACA,qBAAA;AAAR;AAEQ;EACI,cAAA;AAAZ;AAEI;EACI,UAAA;AAAR;AAEI;EACI,gBAAA;EACA,gBAAA;EACA,UAAA;AAAR;AAEQ;EACI,aAAA;EACA,aAAA;EACA,uBAAA;AAAZ;AAEY;EACI,WAAA;EACA,YAAA;EACA,iBAAA;AAAhB;AAEY;EACI,wBAAA;AAAhB","sourcesContent":[".dashboard-clima\n    & .icon\n        background: linear-gradient(45deg,#40c2ff 0%, #32d8d8 100%)\n        width: 50px\n        height: 50px\n        border-radius: 50rem\n        padding: 8px\n        margin: 0 10px\n\n        & svg\n            fill: white\n            width: 100%\n\n    .title\n        color: #6a66d9\n        margin: 50px 0 0 20px\n\n        h2\n            color: #3a3a3a\n\n    .container-chart\n        padding: 0\n\n    .legends\n        list-style: none\n        font-size: small\n        padding: 0\n\n        & li\n            margin: 5px 0\n            display: flex\n            align-items: flex-start\n\n            .box\n                width: 25px\n                height: 15px\n                margin-right: 5px\n\n            span\n                width: calc(100% - 50px)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
