// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-employee-input {
  margin: 5px 0;
  display: flex;
  flex-direction: column-reverse;
}
.new-employee-input label {
  font-size: 14px;
  font-weight: bold;
}
.new-employee-input input {
  height: 45px !important;
  width: 100%;
  border-radius: 8px !important;
  border: 1px solid #CBD5E1 !important;
  margin: 0 !important;
}
.new-employee-input input.required {
  border-left: 4px solid #7A6BE8 !important;
}
.new-employee-input input.no-required {
  border-left: 4px solid #465568 !important;
}
.new-employee-input input.error {
  border: 1px solid #ec6376 !important;
  border-left: 4px solid #ec6376 !important;
}
.new-employee-input input:disabled {
  opacity: 0.5;
}
.new-employee-input input:disabled ~ label {
  opacity: 0.5;
}`, "",{"version":3,"sources":["webpack://./src/Components/Employee/Input/input.sass"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,aAAA;EACA,8BAAA;AACD;AACC;EACC,eAAA;EACA,iBAAA;AACF;AACC;EACC,uBAAA;EACA,WAAA;EACA,6BAAA;EACA,oCAAA;EACA,oBAAA;AACF;AACE;EACC,yCAAA;AACH;AACE;EACC,yCAAA;AACH;AACE;EACC,oCAAA;EACA,yCAAA;AACH;AACE;EACC,YAAA;AACH;AACC;EACC,YAAA;AACF","sourcesContent":[".new-employee-input\r\n\tmargin: 5px 0\r\n\tdisplay: flex\r\n\tflex-direction: column-reverse\r\n\r\n\tlabel\r\n\t\tfont-size: 14px\r\n\t\tfont-weight: bold\r\n\r\n\tinput\r\n\t\theight: 45px !important\r\n\t\twidth: 100%\r\n\t\tborder-radius: 8px !important\r\n\t\tborder: 1px solid #CBD5E1 !important\r\n\t\tmargin: 0 !important\r\n\r\n\t\t&.required\r\n\t\t\tborder-left: 4px solid #7A6BE8 !important\r\n\r\n\t\t&.no-required\r\n\t\t\tborder-left: 4px solid #465568 !important\r\n\r\n\t\t&.error\r\n\t\t\tborder: 1px solid #ec6376 !important\r\n\t\t\tborder-left: 4px solid #ec6376 !important\r\n\r\n\t\t&:disabled\r\n\t\t\topacity: 0.5\r\n\r\n\t& input:disabled ~ label\r\n\t\topacity: .5\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
