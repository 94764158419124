export const CritSuggestMarketing = {
  es: [
    "Retención de Clientes",
    "Market Share ",
    "Nuevos Leads",
    "Accesos en la Pagina",
    "Seguidores en RRSS",
    "Interacciones en RRSS",
    "Conversión Ventas Online",
    "Posicionamiento Organico",
    "Generación de Contenido",
    "Net Promoter Score",
  ],
  pt: [
    "Retenção de Clientes",
    "Participação de Mercado",
    "Novos Leads",
    "Acessos à Página",
    "Seguidores em Redes Sociais",
    "Interações em Redes Sociais",
    "Conversão de Vendas Online",
    "Posicionamento Orgânico",
    "Geração de Conteúdo",
    "Net Promoter Score",
  ],
};

export const CritSuggestAdministrativoFinanciero = {
  es: ["Costo fijo", "Costo variable", "Financiamiento inversión", "Costo de capital", "ROI", "Margen EBITDA", "Rentabilidad"],
  pt: [
    "Custo Fixo",
    "Custo Variável",
    "Financiamento de Investimento",
    "Custo de Capital",
    "ROI (Retorno sobre o Investimento)",
    "Margem EBITDA",
    "Rentabilidade",
  ],
};

export const CritSuggestOperacionManufacuturaIngenieria = {
  es: [
    "Unidades producidas",
    "Ocupación de fábrica",
    "Costo de producción",
    "Lead Time Production",
    "Redución  Productos defectuosos",
    "Reducir Quejas de clientes",
    "Previsión de la demanda",
  ],
  pt: [
    "Unidades Produzidas",
    "Ocupação da Fábrica",
    "Custo de Produção",
    "Lead Time de Produção",
    "Redução de Produtos Defeituosos",
    "Redução de Reclamações de Clientes",
    "Previsão da Demanda",
  ],
};

export const CritSuggestTI = {
  es: [
    "Cumplimiento Cronograma",
    "Desempeño del sistema",
    "Lanzamiento de productos",
    "Nuevas funcionalidades",
    "Repuesta a bugs",
    "Costos con  Sistemas",
    "Custo Estimado Projeto",
  ],
  pt: [
    "Cumprimento de Conograma",
    "Desempenho do Sistema",
    "Lançamento de Produtos",
    "Novas Funcionalidades",
    "Resposta a Bugs",
    "Custos com Sistemas",
    "Custo Estimado do Projeto",
  ],
};

export const CritSuggestRRHH = {
  es: ["Retención", "Turnover", "Clima", "Employer Branding", "Cultura", "Onboarding", "Capacitaciones", "Reclutamiento"],
  pt: ["Retenção", "Turnover", "Clima Organizacional", "Employer Branding", "Cultura Organizacional", "Onboarding", "Capacitações", "Recrutamento"],
};

export const CritSuggestComercialVentasSeguros = {
  es: ["Crescimiento de ventas", "Crescimiento de Ebitda", "Marketing Share", "Nuevos Clientes", "Reuniones Agendadas", "Propuestas Enviadas"],
  pt: ["Crescimento de Vendas", "Crescimento de EBITDA", "Participação de Mercado", "Novos Clientes", "Reuniões Agendadas", "Propostas Enviadas"],
};

export const CritSuggestSupplyChain = {
  es: [
    "Tasa de Retorno",
    "Rotación de Inventario",
    "Costos de Transporte",
    "Costos de Almacenaje",
    "Eficiencia de Recepción",
    "Tiempo de Expedición",
    "Tasa de Retraso",
  ],
  pt: [
    "Tasa de Retorno",
    "Rotatividade de Inventário",
    "Custos de Transporte",
    "Custos de Armazenagem",
    "Eficiência na Recepção",
    "Tempo de Expedição",
    "Taxa de Atraso",
  ],
};

export const CritSuggestInvestigacionDesarrollo = {
  es: ["Nuevas Soluciones", "Innovación en Productos", "Innovación en Procesos"],
  pt: ["Novas Soluções", "Inovação em Produtos", "Inovação em Processos"],
};

export const CritSuggestAltaAdministracion = {
  es: ["Crecimiento de la Empresa", "Rentabilidad", "Market Share"],
  pt: ["Crescimento da Empresa", "Rentabilidade", "Participação de Mercado"],
};
export const CritSuggestLegal = {
  es: ["Volumen de procesos", "Duración de procesos", "Tasa de Éxito", "Despesas con procesos"],
  pt: ["Volume de Processos", "Duração de Processos", "Taxa de Sucesso", "Despesas com Processos"],
};
