import { Link } from 'react-router-dom'
import { HistoricoProfessionalIcon, PropositoIcon, FormacionIcon, CulturaIcon, SalarioIcon, HabilidadesIcon, MotivacionesIcon, DesempenoIcon, EntrenamientoIcon, PlanCarreraIcon } from '../../../../Icons/CollaboratorsIcons'
import { wheelCollaboratorTranslate } from '../../../../i18n/translatedMessages'

import './wheel.sass'

const itens = [
	{
		id: 1,
		name: wheelCollaboratorTranslate.professionalHistoric,
		icon: <HistoricoProfessionalIcon />,
		link: '/app/analytic/historico-profesional',
	},
	{
		id: 2,
		name: wheelCollaboratorTranslate.purpose,
		icon: <PropositoIcon />,
		link: '/app/analytic/proposito',
	},
	{
		id: 3,
		name: wheelCollaboratorTranslate.formation,
		icon: <FormacionIcon />,
		link: '/app/analytic/formacion',
	},
	{
		id: 4,
		name: wheelCollaboratorTranslate.culture,
		icon: <CulturaIcon />,
		link: '/app/analytic/cultura',
	},
	{
		id: 5,
		name: wheelCollaboratorTranslate.salary,
		icon: <SalarioIcon />,
		link: '/app/analytic/salario',
	},
	{
		id: 6,
		name: wheelCollaboratorTranslate.habilities,
		icon: <HabilidadesIcon />,
		link: '/app/analytic/habilidades',
	},
	{
		id: 7,
		name: wheelCollaboratorTranslate.motivacions,
		icon: <MotivacionesIcon />,
		link: '/app/analytic/motivaciones',
	},
	{
		id: 8,
		name: wheelCollaboratorTranslate.performance,
		icon: <DesempenoIcon />,
		link: '/app/analytic/desempeno',
	},
	{
		id: 9,
		name: wheelCollaboratorTranslate.training,
		icon: <EntrenamientoIcon />,
		link: '/app/analytic/entrenamiento',
	},
	{
		id: 10,
		name: wheelCollaboratorTranslate.careerPlan,
		icon: <PlanCarreraIcon />,
		link: '/app/analytic/plan-carrera',
	},
]

const WheelColaboradores = ({ img, currentUserId }) => {
	return (
		<div className={`container-wheel-colaboradores`}>
			<div className='ring-one p-md-5 p-4'>
				{itens.map(service => (
					<Link to={service.link + '/' + currentUserId} className={`icons-colaboradores shadow`} key={service.id}>
						{service.icon}
						<div className='box-info'>
							<span className='title-colaboradores'>{service.name}</span>
							<span className='detail-colaboradores'>{service.description}</span>
						</div>
					</Link>
				))}

				<div className='ring-two p-md-5 p-4'>
					<img src={img} alt='' className='img-fluid img-colaborador rounded-circle' />
				</div>
			</div>
		</div>
	)
}
export default WheelColaboradores
