import { useContext, useEffect, useState } from "react";
import StepZilla from "react-stepzilla";

import { I18nProvider } from "../../../../../i18n";
import { CompanyContext } from "../../../../../Store/CompanyContext";

import InternalVacanciesStep1 from "./Step1/InternalVacanciesStep1";
import InternalVacanciesStep2 from "./Step2/InternalVacanciesStep2";

import { FormattedMessage, useIntl } from "react-intl";
import {
	getInternalVacancies,
	postInternalVacancy,
} from "../../../../../API/Services/CareerPlan/InternalVacancies";
import { getAssetsCompany } from "../../../../../API/Services/Company/Company";
import ModalResponseStatus from "../../../../../Components/ModalResponseStatus/ModalResponseStatus";

const InternalVacancies = () => {
	const language = useIntl();
	const { store, actions } = useContext(CompanyContext);
	const user = JSON.parse(localStorage.getItem("user"));
	const [companyImage, setCompanyImage] = useState("");
	const objEmpty = {
		name: "",
		area1: "",
		area2: "",
		hierarchical_level: "",
		time_experience: "",
		description: "",
		requirements: "",
		companyId: user.id,
	};

	const [data, setData] = useState([]);
	const [newData, setNewData] = useState(objEmpty);
	const requests = async () => {
		const dataAux = await getInternalVacancies();
		const imgCompanyAux = await getAssetsCompany(user.id);
		setCompanyImage(
			imgCompanyAux[imgCompanyAux.length - 1]
				? imgCompanyAux[imgCompanyAux.length - 1].url
				: ""
		);
		setData(dataAux);
	};
	useEffect(() => {
		requests();
	}, []);

	const handleChange = (event) => {
		setNewData({
			...newData,
			[event.target.name]: event.target.value,
		});
	};

	const handleSend = async () => {
		const sendAux = newData;
		if (
			newData.area1 !== "" &&
			newData.hierarchical_level !== "" &&
			newData.name !== "" &&
			newData.time_experience !== "" &&
			newData.description !== ""
		) {
			const postEduInfo = await postInternalVacancy(sendAux);
			if (postEduInfo.status < 200 || postEduInfo.status > 299) {
				if (postEduInfo.status > 399 && postEduInfo.status < 500) {
					actions.setResponseStatus(false);
					actions.setMessageResponseStatus(
						language.locale == "ES"
							? "Ha ocurrido un error!"
							: "Ocorreu um erro!"
					);
					actions.setShowModalResponseStatus(true);
				} else {
					actions.setResponseStatus(false);
					actions.setMessageResponseStatus(
						language.locale == "ES"
							? "Ha ocurrido un error!"
							: "Ocorreu um erro!"
					);
					actions.setShowModalResponseStatus(true);
				}
			} else {
				actions.setResponseStatus(true);
				actions.setMessageResponseStatus(
					language.locale == "ES" ? "Vacante Creada" : "Vaga Criada"
				);
				actions.setShowModalResponseStatus(true);
				setNewData(objEmpty);
				requests();
			}
		} else {
			actions.setResponseStatus(false);
			actions.setMessageResponseStatus(
				language.locale == "ES"
					? "¡Asegúrate de que los campos con * estén llenos!"
					: "Verifique se os campos com * foram preenchidos!"
			);
			actions.setShowModalResponseStatus(true);
		}
	};
	const handleClose = () => {
		actions.setShowModalResponseStatus(false);
	};

	const steps = [
		{
			name: "Step 1",
			component: (
				<InternalVacanciesStep1
					handleChange={handleChange}
					data={data}
					imgCompany={companyImage}
				/>
			),
		},
		{
			name: "Step 2",
			component: (
				<InternalVacanciesStep2
					handleChange={handleChange}
					data={data}
					handleSend={handleSend}
					imgCompany={companyImage}
				/>
			),
		},
	];

	return (
		<I18nProvider locale={store.language}>
			<FormattedMessage id={"nextButton"}>
				{(msg) => (
					<ModalResponseStatus
						show={store.showModalResponseStatus}
						handleClose={handleClose}
						message={store.messageResponseStatus}
						successMessage={store.responseStatus}
						btnRight={msg}
						linkSendTo={`/app/services/internal-vacancies-list`}
					/>
				)}
			</FormattedMessage>
			<StepZilla
				steps={steps}
				showSteps={false}
				prevBtnOnLastStep={true}
				startAtStep={0}
				dontValidate={false}
				backButtonCls="d-none"
				nextButtonCls="d-none"
			/>
		</I18nProvider>
	);
};
export default InternalVacancies;
