import { useEffect, useState } from 'react'

import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa'
import { IoMdCreate } from 'react-icons/io'

import { FormattedMessage, useIntl } from 'react-intl'
import { performancePotentialTranslate } from '../../i18n/translatedMessages'

import { DeleteModal } from './Modals/DeleteModal'
import { Pill } from '../Employee/Pill/Pill'
import { Tooltip } from '../Employee/Tooltip'

import { Suggestions } from './suggestions'

import { postEmployeePerformance, postEmployeePotential } from '../../API/Employee/Employee'
import { CalcEmployeeEvaluation } from '../../../src/Utils/calc-employee-evaluation'

export function PerformanceAndPotentialEmp({ label, evaluation, setStars, stars }) {
	const getLanguage = useIntl()
	const language = getLanguage.locale === 'PT-BR' ? 'pt' : 'es'

	const { id } = evaluation

	const company = JSON.parse(localStorage.getItem('user'))
	const [data, setData] = useState([])
	const [addValue, setAddValue] = useState(false)
	const [newValue, setNewValue] = useState([])
	const [editData, setEditData] = useState(false)
	const [editingData, setEditingData] = useState([])
	const [observationText, setObservationText] = useState('')
	const [showDelete, setShowDelete] = useState(false)
	const [deleteIndex, setDeleteIndex] = useState(null)

	useEffect(() => {
		setData(evaluation.evaluation[label.toLowerCase()].answers ?? [])
		setObservationText(evaluation.evaluation[label.toLowerCase()].observation)
	}, [evaluation, label])

	const closeModal = () => {
		setDeleteIndex(null)
		setShowDelete(false)
	}

	const handleEdit = () => {
		if (data.length !== editingData.length) {
			const aux = [...data]
			setEditingData(aux)
		} else if (!data || data.length === 0) {
			setEditingData([])
		}
		setAddValue(true)
	}

	const handleAddValue = () => {
		setNewValue([...newValue, {}])
	}

	const handlePillClick = (name, index) => {
		setNewValue(prevData => {
			return prevData.map((item, i) => {
				if (i === index) {
					return {
						...item,
						name: name,
					}
				}
				return item
			})
		})
	}

	const handleInputChange = (event, index) => {
		if (editData) {
			setEditingData(prevData => {
				return prevData.map((item, i) => {
					if (i === index) {
						let newValue
						if (event.target.name === 'weight') {
							newValue = parseFloat(event.target.value) || 0
							newValue = Math.max(0, newValue)
						} else if (event.target.name === 'value') {
							if (label === 'Potential') {
								newValue = parseInt(event.target.value) || 0
								newValue = Math.min(100, Math.max(0, newValue))
							} else {
								newValue = parseInt(event.target.value) || 0
								newValue = Math.min(999, Math.max(0, newValue))
							}
						} else {
							newValue = event.target.value
						}
						return {
							...item,
							[event.target.name]: newValue,
						}
					}
					return item
				})
			})
		} else {
			setNewValue(prevData => {
				return prevData.map((item, i) => {
					if (i === index) {
						let newValue
						if (event.target.name === 'weight') {
							newValue = parseFloat(event.target.value) || 0
							newValue = Math.max(0, newValue)
						} else if (event.target.name === 'value') {
							newValue = parseInt(event.target.value) || 0
							newValue = Math.min(999, Math.max(0, newValue))
						} else {
							newValue = event.target.value
						}
						return {
							...item,
							[event.target.name]: newValue,
						}
					}
					return item
				})
			})
		}
	}

	const handleSaveValues = async () => {
		let answers = []
		let sendData

		if (addValue && newValue.length > 0) {
			data.push(...newValue)
			answers = [...data]
			setStars({
				...stars,
				[label.toLowerCase()]: CalcEmployeeEvaluation(data).toFixed(1),
			})
			setNewValue([])
		} else {
			setData([...editingData])
			setStars({
				...stars,
				[label.toLowerCase()]: CalcEmployeeEvaluation(editingData).toFixed(1),
			})
			answers = [...editingData]
			setEditingData([])
		}

		if (label === 'Potential') {
			sendData = {
				'companyId': company.id,
				'employerId': id,
				'observationPotential': observationText,
				'potential': answers.map(item => ({
					name: item.name,
					value: parseFloat(item.value),
					weight: parseFloat(item.weight),
				})),
			}
		} else {
			sendData = {
				'companyId': company.id,
				'employerId': id,
				'observation': observationText,
				'answers': answers.map(item => ({
					name: item.name,
					value: parseFloat(item.value),
					weight: parseFloat(item.weight),
				})),
			}
		}
		setAddValue(false)
		setEditData(false)
		label === 'Performance' ? await postEmployeePerformance(sendData) : await postEmployeePotential(sendData)
	}

	const handleDeleteNewValue = index => {
		setNewValue(prevData => {
			const updateData = [...prevData]
			updateData.splice(index, 1)
			return updateData
		})

		if (newValue.length === 1) {
			setAddValue(false)
		}
	}

	const handleDelete = async index => {
		let sendData

		const newData = [...data]
		newData.splice(index, 1)
		setData([...newData])

		if (label === 'Potential') {
			sendData = {
				'companyId': company.id,
				'employerId': id,
				'observationPotential': observationText,
				'potential': newData.map(item => ({
					name: item.name,
					value: parseFloat(item.value),
					weight: parseFloat(item.weight),
				})),
			}
		} else {
			sendData = {
				'companyId': company.id,
				'employerId': id,
				'observation': observationText,
				'answers': newData.map(item => ({
					name: item.name,
					value: parseFloat(item.value),
					weight: parseFloat(item.weight),
				})),
			}
		}

		label === 'Performance' ? await postEmployeePerformance(sendData) : await postEmployeePotential(sendData)

		setStars({
			...stars,
			[label.toLowerCase()]: CalcEmployeeEvaluation(newData).toFixed(1),
		})

		setDeleteIndex(null)
	}

	function getPillColor(value) {
		if (value < 40) {
			return 'tw-bg-error-100 tw-text-error-600'
		} else if (value < 80) {
			return 'tw-bg-alert-100 tw-text-alert-600'
		} else {
			return 'tw-bg-green-100 tw-text-green-600'
		}
	}

	function getPillText(value) {
		if (value < 40) {
			return performancePotentialTranslate.pillText1
		} else if (value < 80) {
			return performancePotentialTranslate.pillText2
		} else {
			return performancePotentialTranslate.pillText3
		}
	}

	return (
		<>
			<table className='tw-min-w-full'>
				<thead>
					<tr>
						<th className='tw-w-1/2 tw-text-left tw-font-bold tw-text-black'>{label === 'Performance' ? performancePotentialTranslate.performanceTitle : performancePotentialTranslate.potentialTitle}</th>
						<th className='tw-w-1/12 tw-text-center  tw-font-bold tw-text-black'>{performancePotentialTranslate.weightTitle}</th>
						<th className='tw-w-1/12 tw-text-left  tw-font-bold tw-text-black'>{label === 'Performance' ? performancePotentialTranslate.reachedTitle : performancePotentialTranslate.scoreTitle}</th>
						{!addValue && (
							<th className='tw-w-1/12 tw-text-right  tw-font-bold tw-text-black'>
								<div className='tw-flex tw-justify-end tw-bg-none tw-border-none'>
									{data?.length > 0 && (
										<button className={`tw-text-blue-500 tw-bg-white tw-border-none ${editData || addValue ? 'tw-opacity-50' : ''}`}>
											<IoMdCreate
												size={20}
												onClick={() => {
													if (data.length > 0 && !editData && !addValue) {
														setEditData(true)
														handleEdit()
													}
												}}
											/>
										</button>
									)}
									<button className='tw-ml-2 tw-text-purple-500 tw-bg-white tw-border-none'>
										<FaPlusCircle
											size={18}
											color='#7A6BE8 '
											onClick={async () => {
												handleEdit()
												handleAddValue()
											}}
										/>
									</button>
								</div>
							</th>
						)}
					</tr>
				</thead>
				<tbody>
					{!data || data?.length === 0 ? (
						<tr>
							<td colSpan='4' className='tw-text-center tw-py-4'>
								{performancePotentialTranslate.emptyValuesPP}
							</td>
						</tr>
					) : (
						!editData &&
						data.map((item, index) => (
							<tr className='tw-border-b tw-border-none' key={index}>
								<td className='tw-text-left tw-py-4'>{item.name}</td>
								<td className='tw-text-center tw-py-4'>{item.weight}</td>
								<td className='tw-text-left tw-py-4 tw-flex tw-place-items-start'>
									<span className='tw-text-gray-700 tw-w-12'>{item.value} %</span>
									<span className='tw-ml-2 tw-flex tw-content-end tw-justify-end tw-px-2'>
										<div className='tw-w-24'>
											<Pill text={getPillText(item.value)} color={getPillColor(item.value)} />
										</div>
									</span>
								</td>
								<td className='tw-text-right tw-items-center'>
									{deleteIndex === index && <DeleteModal show={showDelete} onClick={handleDelete} index={index} close={closeModal} title={item.name} />}
									<button
										className='tw-text-red-500 tw-py-4 tw-bg-white tw-border-none'
										onClick={() => {
											setShowDelete(true)
											setDeleteIndex(index)
										}}
									>
										<FaMinusCircle size={18} color='#F43F5E' className='' />
									</button>
								</td>
							</tr>
						))
					)}
				</tbody>
			</table>

			{addValue && !editData && (
				<div className='tw-w-full tw-flex tw-flex-col tw-space-y-4 pr-4'>
					{newValue.map((item, index) => (
						<div key={index}>
							<div className='tw-flex tw-w-full tw-space-x-4 tw-mt-5'>
								<div className='tw-flex-1'>
									<FormattedMessage id={label === 'Performance' ? 'performanceTitle' : 'potentialTitle'}>
										{msg => <input className='border border-1 tw-rounded-lg tw-border tw-p-2 tw-w-full' placeholder={msg} value={item.name || ''} name='name' onChange={e => handleInputChange(e, index)} />}
									</FormattedMessage>
								</div>
								<div className='tw-w-1/12'>
									<FormattedMessage id='weightTitle'>
										{msg => <input className='border border-1 tw-rounded-lg tw-border tw-p-2 tw-w-full' placeholder={`001 ${msg}`} value={item?.weight || ''} name='weight' onChange={e => handleInputChange(e, index)} />}
									</FormattedMessage>
								</div>
								<div className='tw-w-1/12'>
									<FormattedMessage id={label === 'Performance' ? 'reachedTitle' : 'scoreTitle'}>
										{msg => <input className='border border-1 tw-rounded-lg tw-border tw-p-2 tw-w-full' placeholder={`001% ${msg}`} value={item?.value || ''} name='value' onChange={e => handleInputChange(e, index)} />}
									</FormattedMessage>
								</div>
								<div className='tw-flex tw-items-center'>
									{/* <DeleteModal show={showDelete} onClick={handleDelete} index={index} close={closeModal} title={item.name} /> */}
									<button className='tw-text-red-500  tw-bg-white tw-border-none' onClick={() => handleDeleteNewValue(index)}>
										<FaMinusCircle size={18} color='#F43F5E' />
									</button>
								</div>
							</div>
							<Suggestions language={language} area={evaluation.area.area} onPillClick={name => handlePillClick(name, index)} index={index} />
						</div>
					))}
				</div>
			)}

			{editData && (
				<div className='tw-w-full tw-flex tw-flex-col tw-space-y-4 pr-4 mt-3'>
					{editingData.map((edit, index) => (
						<div key={index} className='tw-flex tw-w-full tw-space-x-4'>
							<div className='tw-flex-1'>
								<input className='border border-1 tw-rounded-lg tw-border tw-p-2 tw-w-full' placeholder={label} value={edit.name || ''} name='name' onChange={e => handleInputChange(e, index)} />
							</div>
							<div className='tw-w-32'>
								<FormattedMessage id='weightTitle'>
									{msg => <input className='border border-1 tw-rounded-lg tw-border tw-p-2 tw-w-full' placeholder={`001 ${msg}`} value={edit.weight || ''} name='weight' onChange={e => handleInputChange(e, index)} />}
								</FormattedMessage>
							</div>
							<div className='tw-w-32'>
								<FormattedMessage id={label === 'Performance' ? 'reachedTitle' : 'scoreTitle'}>
									{msg => <input className='border border-1 tw-rounded-lg tw-border tw-p-2 tw-w-full' placeholder={`001% ${msg}`} value={edit.value || ''} name='value' onChange={e => handleInputChange(e, index)} />}
								</FormattedMessage>
							</div>
						</div>
					))}
				</div>
			)}

			<div className='tw-min-w-full tw-space-y-4 border-top border-1 tw-mt-2'>
				<div className='tw-text-black tw-font-bold tw-mt-5'>{performancePotentialTranslate.observationsTitle} </div>
				{addValue || editData ? (
					<FormattedMessage id='phObservations'>
						{msg => <textarea className='border border-1 tw-max-h-32 tw-min-h-[50px] tw-w-full tw-border tw-rounded-md tw-p-2 tw-resize-y' placeholder={msg} value={observationText} onChange={e => setObservationText(e.target.value)} />}
					</FormattedMessage>
				) : (
					<div className='tw-text-black'>{observationText}</div>
				)}
			</div>

			{(addValue || editData) && (
				<div className='tw-flex tw-items-center tw-justify-end tw-space-x-4 tw-relative'>
					<button
						className='tw-py-2 tw-px-10 tw-rounded-full tw-border-none tw-bg-white'
						onClick={() => {
							setNewValue([])
							setEditingData([])
							setAddValue(false)
							setEditData(false)
						}}
					>
						Cancelar
					</button>
					<Tooltip text='Completa toda la información requerida' background='#e11d48' mb={50} ms={-5} show={newValue.some(value => !value.name || !value.weight || !value.value)}>
						<button
							className={`tw-py-2 tw-px-10 tw-rounded-full tw-border-none disabled:tw-bg-gray-300 disabled:tw-text-gray-500 tw-bg-purple-500 tw-text-white`}
							disabled={newValue.some(value => !value.name || !value.weight || !value.value)}
							onClick={handleSaveValues}
						>
							Guardar
						</button>
					</Tooltip>
				</div>
			)}
		</>
	)
}
