import Carousel from "react-bootstrap/Carousel";
import { CarouselItem } from "react-bootstrap";

import "./Carousel.sass";

const carouselCulture = ({
	iconCard,
	textCard,
	titleCard,
	colorCard = "#444258",
	prevCard,
	onClick,
	proximoCard,
}) => {
	return (
		<>
			<div className="carousel-culture">
				<div
					className="box-title mb-3"
					style={{ backgroundColor: colorCard }}
				>
					<button
						onClick={prevCard}
						className="triangle-left m-4"
					></button>
					<h4 className="mt-2">{titleCard}</h4>
					<button
						onClick={proximoCard}
						className="triangle-right m-4"
					></button>
				</div>

				<div className="box-content">
					<img className="icon" src={iconCard}></img>
					<div className="text">{textCard}</div>
				</div>
			</div>
		</>
	);
};
export default carouselCulture;
