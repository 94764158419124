export const MydnaLogo = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1631.53 393.67"
      width={size}
    >
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M86.46,289a88.31,88.31,0,0,1-.92,18.41c-3.62,20.28-22.75,35.79-43,35.08C20.82,341.75,3.32,326,.71,304.94-.37,296.16.06,287.37.24,278.6c.14-6.93,4.34-10.53,11.22-10.62a613.13,613.13,0,0,0,61.62-3.8c8.29-.94,13,3.1,13.36,11.44C86.59,279.6,86.46,283.59,86.46,289Z"
          fill="#5ac2ad"
        />
        <path
          className="cls-1"
          d="M.11,137.71C.11,106.46,0,75.22.13,44,.2,23.05,13.42,6.15,33.22,1.34A42.58,42.58,0,0,1,85.87,38.61c.36,3.62.57,7.28.57,10.92q.06,86.76,0,173.52c0,10.81-2.07,13.17-12.73,15.24-19.46,3.79-39.16,5.35-58.91,6.42-11,.6-14.72-3.11-14.72-14.25Z"
          fill="#5ac2ad"
        />
        <path
          className="cls-1"
          d="M128.73,148.74q0-28.41,0-56.82a42.94,42.94,0,0,1,85.87-.68c.24,29.07,0,58.15.11,87.22a11.3,11.3,0,0,1-6,10.65,409.82,409.82,0,0,1-65.4,30.54c-8.2,3-14.55-1.71-14.56-10.6Q128.68,178.9,128.73,148.74Z"
          fill="#45b9eb"
        />
        <path
          className="cls-1"
          d="M214.66,300.19c0,15.79.07,31.58,0,47.37-.13,24.39-18.18,43.58-42.53,43.65-25.62.06-42.81-19.25-43.25-40.75-.57-28.41-.16-56.83-.12-85.25,0-5.84,2.74-9.54,8.45-10.7,21.5-4.36,42.69-9.88,63.74-16,8.05-2.35,13.67,2.24,13.7,10.85C214.71,266.29,214.66,283.24,214.66,300.19Z"
          fill="#45b9eb"
        />
        <path
          className="cls-1"
          d="M343.2,251c-.42,15.16.74,33.93-.52,52.68-1.42,21.25-20.07,38.39-42.1,38.8-21.49.41-40.72-16.22-43-37.26a90.17,90.17,0,0,1-.54-9.43Q257,261.85,257,228c0-7.5,1.79-10.07,8.55-12.91q30.81-12.93,60.22-28.76c9.82-5.3,17.29-.94,17.39,10.22C343.31,213.46,343.2,230.41,343.2,251Z"
          fill="#6e68af"
        />
        <path
          className="cls-1"
          d="M257,84.28c.48-13.56-1-30.53.64-47.41C259.66,16.3,279.3-.37,300.5,0c22,.38,40.66,17.52,42.21,38.73a119.37,119.37,0,0,1,.13,16.38,40.86,40.86,0,0,1-8.49,22.44,427.63,427.63,0,0,1-59.55,64.21c-3.59,3.16-7.45,4.39-11.9,2.35-4.27-2-5.88-5.66-5.89-10.14C257,118.53,257,103.09,257,84.28Z"
          fill="#6e68af"
        />
        <path
          className="cls-1"
          d="M765,149.61c20.85,0,41,0,61.08-.05,3,0,3.38,2.05,4.17,4Q852.16,207.7,874,261.81c.53,1.32.76,2.8,2,3.86,1.61-1,1.73-2.78,2.31-4.24q21.45-53.48,42.78-107c1.41-3.56,3-5,7.08-5,19.44.28,38.88.12,59,.12-4.3,9.87-8.35,19.24-12.46,28.59q-46.43,105.5-92.79,211c-1.51,3.44-3.3,4.49-7,4.45-19.11-.22-38.22-.1-58-.1l8.84-20.41c5.6-12.91,11.07-25.89,16.9-38.7a10.62,10.62,0,0,0,0-9.75q-38.16-85.53-76.08-171.15C766.21,152.52,765.8,151.45,765,149.61Z"
          fill="#39374b"
        />
        <path
          className="cls-1"
          d="M529.32,168.4c27-33.62,85.61-30,107.5,9.37,1.49,0,1.73-1.36,2.35-2.25,15.71-22.58,37.88-31.54,64.64-29.59,37.89,2.75,57.1,27.78,63.32,60.4a135.54,135.54,0,0,1,2.16,25.77q-.12,52.13.07,104.24c0,3.84-1.05,4.6-4.68,4.56-16.32-.21-32.65-.26-49,0-4.21.07-4.74-1.43-4.73-5q.19-47.88,0-95.76A78.08,78.08,0,0,0,708.4,219c-3-10.65-8.65-18.85-20.48-20.42s-22.12,2.07-29.19,12.2c-7.22,10.34-9.21,22.34-9.3,34.56-.24,30.25-.16,60.51,0,90.77,0,3.95-1,4.89-4.9,4.84-16.15-.22-32.31-.28-48.46,0-4.37.08-5.05-1.3-5-5.27.17-30.59.13-61.18.07-91.77,0-8.13-.42-16.25-2.55-24.18-3.56-13.25-10.52-20.08-21.86-21.44a30.37,30.37,0,0,0-30.2,15.36c-4.95,8.91-6.94,18.69-7,28.71q-.36,46.64,0,93.26c0,4.62-1.36,5.37-5.54,5.3-16.32-.24-32.64-.18-49,0-3.2,0-4.23-.72-4.23-4.09q.16-91.52,0-183c0-3.28,1-4.24,4.24-4.21q25.22.21,50.45,0c3,0,4.12.81,4,3.92C529.14,158.08,529.32,162.72,529.32,168.4Z"
          fill="#39374b"
        />
        <path
          className="cls-1"
          d="M1145.83,322.42c-9.11,9.43-18.87,15.78-30.34,19.35-29.82,9.27-64.6-1.74-85.86-27.16-32.39-38.75-33.45-95.63-2.53-135.5,21.89-28.22,58.25-40.32,89.78-29.81,10.91,3.63,20.29,9.72,28.95,19.17v-6.31c0-34.24.06-68.49-.08-102.73,0-3.8.82-5.09,4.88-5,16.32.25,32.64.21,49,0,3.5,0,4.43.9,4.43,4.41q-.15,138.9,0,277.79c0,3.12-.64,4.3-4.06,4.26q-25-.25-50,0c-3.21,0-4.42-.81-4.21-4.14C1146,332.42,1145.83,328.09,1145.83,322.42Zm-38.75-124.27c-19.74-.06-35.2,11.18-41.22,30a58,58,0,0,0-1.84,27c2.91,18.81,16.88,33.72,34.61,36.61,20,3.26,37.6-5.14,46.36-22.46,4.93-9.75,6-20.18,5-31C1147.9,215.05,1130,198.22,1107.08,198.15Z"
          fill="#6e68af"
        />
        <path
          className="cls-1"
          d="M1573.52,322.23c-8.44,8.79-17.14,15.14-27.58,18.49-29.65,9.53-56.41,3.34-79.44-16.9-44.43-39.05-46.21-112.65-3.61-153.72,23.6-22.77,51.63-30.55,83.56-20,10.27,3.39,18.91,9.54,27.07,18.6,0-5.94.13-10.65,0-15.34-.1-2.77.76-3.79,3.65-3.77,16.82.11,33.63.18,50.44,0,4.7-.06,3.92,2.81,3.92,5.56v176c0,9.71,0,9.71-9.52,9.71-14.31,0-28.63-.24-42.94.13-4.83.12-6-1.52-5.57-5.86C1573.78,331.31,1573.52,327.48,1573.52,322.23Zm4.29-77.48a51.78,51.78,0,0,0-1.15-11.64c-6.33-31.51-43.13-45.89-68.43-26.48-13.71,10.52-18.15,25.38-17.39,42,1.39,30.63,26.58,49.75,55.4,42.33C1565.47,286,1577.78,268.24,1577.81,244.75Z"
          fill="#6e68af"
        />
        <path
          className="cls-1"
          d="M1290,168.87c7.37-9.05,15.56-14.82,25.2-18.33,29.35-10.7,67.22-5.09,87.21,23.9,10.43,15.12,15.06,32.2,15.24,50.27.37,37.22.07,74.45.19,111.68,0,3.37-.71,4.55-4.34,4.5q-24.7-.3-49.44,0c-3.62.05-4.34-1.13-4.33-4.5.12-31.58.06-63.15.08-94.73a78.56,78.56,0,0,0-1.88-18.28c-3-12.52-9.14-22.32-22.85-24.59-14-2.32-26.11,1.49-35,13-5.86,7.58-8.25,16.54-9.26,25.92-1.19,11.1-.76,22.24-.78,33.36,0,21.77-.09,43.54.06,65.31,0,3.43-.83,4.53-4.39,4.49-16.48-.19-33-.16-49.44,0-3.19,0-4.28-.74-4.28-4.11q.15-91.49,0-183c0-3.42,1.19-4.23,4.33-4.21,16.48.11,33,.16,49.44,0,3.69,0,4.5,1.33,4.32,4.64C1289.85,158.59,1290,163,1290,168.87Z"
          fill="#6e68af"
        />
      </g>
    </svg>
  );
};

export const MydnaLogoDark = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1631.53 393.67"
      width={size}
    >
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M86.46,289a88.31,88.31,0,0,1-.92,18.41c-3.62,20.28-22.75,35.79-43,35.08C20.82,341.75,3.32,326,.71,304.94-.37,296.16.06,287.37.24,278.6c.14-6.93,4.34-10.53,11.22-10.62a613.13,613.13,0,0,0,61.62-3.8c8.29-.94,13,3.1,13.36,11.44C86.59,279.6,86.46,283.59,86.46,289Z"
          fill="#26e5c6"
        />
        <path
          className="cls-1"
          d="M.11,137.71C.11,106.46,0,75.22.13,44,.2,23.05,13.42,6.15,33.22,1.34A42.58,42.58,0,0,1,85.87,38.61c.36,3.62.57,7.28.57,10.92q.06,86.76,0,173.52c0,10.81-2.07,13.17-12.73,15.24-19.46,3.79-39.16,5.35-58.91,6.42-11,.6-14.72-3.11-14.72-14.25Z"
          fill="#26e5c6"
        />
        <path
          className="cls-1"
          d="M128.73,148.74q0-28.41,0-56.82a42.94,42.94,0,0,1,85.87-.68c.24,29.07,0,58.15.11,87.22a11.3,11.3,0,0,1-6,10.65,409.82,409.82,0,0,1-65.4,30.54c-8.2,3-14.55-1.71-14.56-10.6Q128.68,178.9,128.73,148.74Z"
          fill="#40c8ff"
        />
        <path
          className="cls-1"
          d="M214.66,300.19c0,15.79.07,31.58,0,47.37-.13,24.39-18.18,43.58-42.53,43.65-25.62.06-42.81-19.25-43.25-40.75-.57-28.41-.16-56.83-.12-85.25,0-5.84,2.74-9.54,8.45-10.7,21.5-4.36,42.69-9.88,63.74-16,8.05-2.35,13.67,2.24,13.7,10.85C214.71,266.29,214.66,283.24,214.66,300.19Z"
          fill="#40c8ff"
        />
        <path
          className="cls-1"
          d="M343.2,251c-.42,15.16.74,33.93-.52,52.68-1.42,21.25-20.07,38.39-42.1,38.8-21.49.41-40.72-16.22-43-37.26a90.17,90.17,0,0,1-.54-9.43Q257,261.85,257,228c0-7.5,1.79-10.07,8.55-12.91q30.81-12.93,60.22-28.76c9.82-5.3,17.29-.94,17.39,10.22C343.31,213.46,343.2,230.41,343.2,251Z"
          fill="#7b6be4"
        />
        <path
          className="cls-1"
          d="M257,84.28c.48-13.56-1-30.53.64-47.41C259.66,16.3,279.3-.37,300.5,0c22,.38,40.66,17.52,42.21,38.73a119.37,119.37,0,0,1,.13,16.38,40.86,40.86,0,0,1-8.49,22.44,427.63,427.63,0,0,1-59.55,64.21c-3.59,3.16-7.45,4.39-11.9,2.35-4.27-2-5.88-5.66-5.89-10.14C257,118.53,257,103.09,257,84.28Z"
          fill="#7b6be4"
        />
        <path
          className="cls-1"
          d="M765,149.61c20.85,0,41,0,61.08-.05,3,0,3.38,2.05,4.17,4Q852.16,207.7,874,261.81c.53,1.32.76,2.8,2,3.86,1.61-1,1.73-2.78,2.31-4.24q21.45-53.48,42.78-107c1.41-3.56,3-5,7.08-5,19.44.28,38.88.12,59,.12-4.3,9.87-8.35,19.24-12.46,28.59q-46.43,105.5-92.79,211c-1.51,3.44-3.3,4.49-7,4.45-19.11-.22-38.22-.1-58-.1l8.84-20.41c5.6-12.91,11.07-25.89,16.9-38.7a10.62,10.62,0,0,0,0-9.75q-38.16-85.53-76.08-171.15C766.21,152.52,765.8,151.45,765,149.61Z"
          fill="#FFFFFF"
        />
        <path
          className="cls-1"
          d="M529.32,168.4c27-33.62,85.61-30,107.5,9.37,1.49,0,1.73-1.36,2.35-2.25,15.71-22.58,37.88-31.54,64.64-29.59,37.89,2.75,57.1,27.78,63.32,60.4a135.54,135.54,0,0,1,2.16,25.77q-.12,52.13.07,104.24c0,3.84-1.05,4.6-4.68,4.56-16.32-.21-32.65-.26-49,0-4.21.07-4.74-1.43-4.73-5q.19-47.88,0-95.76A78.08,78.08,0,0,0,708.4,219c-3-10.65-8.65-18.85-20.48-20.42s-22.12,2.07-29.19,12.2c-7.22,10.34-9.21,22.34-9.3,34.56-.24,30.25-.16,60.51,0,90.77,0,3.95-1,4.89-4.9,4.84-16.15-.22-32.31-.28-48.46,0-4.37.08-5.05-1.3-5-5.27.17-30.59.13-61.18.07-91.77,0-8.13-.42-16.25-2.55-24.18-3.56-13.25-10.52-20.08-21.86-21.44a30.37,30.37,0,0,0-30.2,15.36c-4.95,8.91-6.94,18.69-7,28.71q-.36,46.64,0,93.26c0,4.62-1.36,5.37-5.54,5.3-16.32-.24-32.64-.18-49,0-3.2,0-4.23-.72-4.23-4.09q.16-91.52,0-183c0-3.28,1-4.24,4.24-4.21q25.22.21,50.45,0c3,0,4.12.81,4,3.92C529.14,158.08,529.32,162.72,529.32,168.4Z"
          fill="#FFFFFF"
        />
        <path
          className="cls-1"
          d="M1145.83,322.42c-9.11,9.43-18.87,15.78-30.34,19.35-29.82,9.27-64.6-1.74-85.86-27.16-32.39-38.75-33.45-95.63-2.53-135.5,21.89-28.22,58.25-40.32,89.78-29.81,10.91,3.63,20.29,9.72,28.95,19.17v-6.31c0-34.24.06-68.49-.08-102.73,0-3.8.82-5.09,4.88-5,16.32.25,32.64.21,49,0,3.5,0,4.43.9,4.43,4.41q-.15,138.9,0,277.79c0,3.12-.64,4.3-4.06,4.26q-25-.25-50,0c-3.21,0-4.42-.81-4.21-4.14C1146,332.42,1145.83,328.09,1145.83,322.42Zm-38.75-124.27c-19.74-.06-35.2,11.18-41.22,30a58,58,0,0,0-1.84,27c2.91,18.81,16.88,33.72,34.61,36.61,20,3.26,37.6-5.14,46.36-22.46,4.93-9.75,6-20.18,5-31C1147.9,215.05,1130,198.22,1107.08,198.15Z"
          fill="#40c8ff"
        />
        <path
          className="cls-1"
          d="M1573.52,322.23c-8.44,8.79-17.14,15.14-27.58,18.49-29.65,9.53-56.41,3.34-79.44-16.9-44.43-39.05-46.21-112.65-3.61-153.72,23.6-22.77,51.63-30.55,83.56-20,10.27,3.39,18.91,9.54,27.07,18.6,0-5.94.13-10.65,0-15.34-.1-2.77.76-3.79,3.65-3.77,16.82.11,33.63.18,50.44,0,4.7-.06,3.92,2.81,3.92,5.56v176c0,9.71,0,9.71-9.52,9.71-14.31,0-28.63-.24-42.94.13-4.83.12-6-1.52-5.57-5.86C1573.78,331.31,1573.52,327.48,1573.52,322.23Zm4.29-77.48a51.78,51.78,0,0,0-1.15-11.64c-6.33-31.51-43.13-45.89-68.43-26.48-13.71,10.52-18.15,25.38-17.39,42,1.39,30.63,26.58,49.75,55.4,42.33C1565.47,286,1577.78,268.24,1577.81,244.75Z"
          fill="#40c8ff"
        />
        <path
          className="cls-1"
          d="M1290,168.87c7.37-9.05,15.56-14.82,25.2-18.33,29.35-10.7,67.22-5.09,87.21,23.9,10.43,15.12,15.06,32.2,15.24,50.27.37,37.22.07,74.45.19,111.68,0,3.37-.71,4.55-4.34,4.5q-24.7-.3-49.44,0c-3.62.05-4.34-1.13-4.33-4.5.12-31.58.06-63.15.08-94.73a78.56,78.56,0,0,0-1.88-18.28c-3-12.52-9.14-22.32-22.85-24.59-14-2.32-26.11,1.49-35,13-5.86,7.58-8.25,16.54-9.26,25.92-1.19,11.1-.76,22.24-.78,33.36,0,21.77-.09,43.54.06,65.31,0,3.43-.83,4.53-4.39,4.49-16.48-.19-33-.16-49.44,0-3.19,0-4.28-.74-4.28-4.11q.15-91.49,0-183c0-3.42,1.19-4.23,4.33-4.21,16.48.11,33,.16,49.44,0,3.69,0,4.5,1.33,4.32,4.64C1289.85,158.59,1290,163,1290,168.87Z"
          fill="#40c8ff"
        />
      </g>
    </svg>
  );
};

export const MydnaLogoMobile = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 344.53 393.67"
      width={size}
    >
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M86.46,289a88.31,88.31,0,0,1-.92,18.41c-3.62,20.28-22.75,35.79-43,35.08C20.82,341.75,3.32,326,.71,304.94-.37,296.16.06,287.37.24,278.6c.14-6.93,4.34-10.53,11.22-10.62a613.13,613.13,0,0,0,61.62-3.8c8.29-.94,13,3.1,13.36,11.44C86.59,279.6,86.46,283.59,86.46,289Z"
          fill="#FFFFFF "
        />
        <path
          className="cls-1"
          d="M.11,137.71C.11,106.46,0,75.22.13,44,.2,23.05,13.42,6.15,33.22,1.34A42.58,42.58,0,0,1,85.87,38.61c.36,3.62.57,7.28.57,10.92q.06,86.76,0,173.52c0,10.81-2.07,13.17-12.73,15.24-19.46,3.79-39.16,5.35-58.91,6.42-11,.6-14.72-3.11-14.72-14.25Z"
          fill="#FFFFFF "
        />
        <path
          className="cls-1"
          d="M128.73,148.74q0-28.41,0-56.82a42.94,42.94,0,0,1,85.87-.68c.24,29.07,0,58.15.11,87.22a11.3,11.3,0,0,1-6,10.65,409.82,409.82,0,0,1-65.4,30.54c-8.2,3-14.55-1.71-14.56-10.6Q128.68,178.9,128.73,148.74Z"
          fill="#FFFFFF "
        />
        <path
          className="cls-1"
          d="M214.66,300.19c0,15.79.07,31.58,0,47.37-.13,24.39-18.18,43.58-42.53,43.65-25.62.06-42.81-19.25-43.25-40.75-.57-28.41-.16-56.83-.12-85.25,0-5.84,2.74-9.54,8.45-10.7,21.5-4.36,42.69-9.88,63.74-16,8.05-2.35,13.67,2.24,13.7,10.85C214.71,266.29,214.66,283.24,214.66,300.19Z"
          fill="#FFFFFF "
        />
        <path
          className="cls-1"
          d="M343.2,251c-.42,15.16.74,33.93-.52,52.68-1.42,21.25-20.07,38.39-42.1,38.8-21.49.41-40.72-16.22-43-37.26a90.17,90.17,0,0,1-.54-9.43Q257,261.85,257,228c0-7.5,1.79-10.07,8.55-12.91q30.81-12.93,60.22-28.76c9.82-5.3,17.29-.94,17.39,10.22C343.31,213.46,343.2,230.41,343.2,251Z"
          fill="#FFFFFF "
        />
        <path
          className="cls-1"
          d="M257,84.28c.48-13.56-1-30.53.64-47.41C259.66,16.3,279.3-.37,300.5,0c22,.38,40.66,17.52,42.21,38.73a119.37,119.37,0,0,1,.13,16.38,40.86,40.86,0,0,1-8.49,22.44,427.63,427.63,0,0,1-59.55,64.21c-3.59,3.16-7.45,4.39-11.9,2.35-4.27-2-5.88-5.66-5.89-10.14C257,118.53,257,103.09,257,84.28Z"
          fill="#FFFFFF "
        />
      </g>
    </svg>
  );
};

export const MydnaLogoMobileDark = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 344.53 393.67"
      width={size}
    >
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M86.46,289a88.31,88.31,0,0,1-.92,18.41c-3.62,20.28-22.75,35.79-43,35.08C20.82,341.75,3.32,326,.71,304.94-.37,296.16.06,287.37.24,278.6c.14-6.93,4.34-10.53,11.22-10.62a613.13,613.13,0,0,0,61.62-3.8c8.29-.94,13,3.1,13.36,11.44C86.59,279.6,86.46,283.59,86.46,289Z"
          fill="#26e5c6"
        />
        <path
          className="cls-1"
          d="M.11,137.71C.11,106.46,0,75.22.13,44,.2,23.05,13.42,6.15,33.22,1.34A42.58,42.58,0,0,1,85.87,38.61c.36,3.62.57,7.28.57,10.92q.06,86.76,0,173.52c0,10.81-2.07,13.17-12.73,15.24-19.46,3.79-39.16,5.35-58.91,6.42-11,.6-14.72-3.11-14.72-14.25Z"
          fill="#26e5c6"
        />
        <path
          className="cls-1"
          d="M128.73,148.74q0-28.41,0-56.82a42.94,42.94,0,0,1,85.87-.68c.24,29.07,0,58.15.11,87.22a11.3,11.3,0,0,1-6,10.65,409.82,409.82,0,0,1-65.4,30.54c-8.2,3-14.55-1.71-14.56-10.6Q128.68,178.9,128.73,148.74Z"
          fill="#3fc4ff"
        />
        <path
          className="cls-1"
          d="M214.66,300.19c0,15.79.07,31.58,0,47.37-.13,24.39-18.18,43.58-42.53,43.65-25.62.06-42.81-19.25-43.25-40.75-.57-28.41-.16-56.83-.12-85.25,0-5.84,2.74-9.54,8.45-10.7,21.5-4.36,42.69-9.88,63.74-16,8.05-2.35,13.67,2.24,13.7,10.85C214.71,266.29,214.66,283.24,214.66,300.19Z"
          fill="#3fc4ff"
        />
        <path
          className="cls-1"
          d="M343.2,251c-.42,15.16.74,33.93-.52,52.68-1.42,21.25-20.07,38.39-42.1,38.8-21.49.41-40.72-16.22-43-37.26a90.17,90.17,0,0,1-.54-9.43Q257,261.85,257,228c0-7.5,1.79-10.07,8.55-12.91q30.81-12.93,60.22-28.76c9.82-5.3,17.29-.94,17.39,10.22C343.31,213.46,343.2,230.41,343.2,251Z"
          fill="#7b6be4"
        />
        <path
          className="cls-1"
          d="M257,84.28c.48-13.56-1-30.53.64-47.41C259.66,16.3,279.3-.37,300.5,0c22,.38,40.66,17.52,42.21,38.73a119.37,119.37,0,0,1,.13,16.38,40.86,40.86,0,0,1-8.49,22.44,427.63,427.63,0,0,1-59.55,64.21c-3.59,3.16-7.45,4.39-11.9,2.35-4.27-2-5.88-5.66-5.89-10.14C257,118.53,257,103.09,257,84.28Z"
          fill="#7b6be4"
        />
      </g>
    </svg>
  );
};

export const MydnaLogoBastonetes = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 344.53 393.67"
      width={size}
    >

      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M86.46,289a88.31,88.31,0,0,1-.92,18.41c-3.62,20.28-22.75,35.79-43,35.08C20.82,341.75,3.32,326,.71,304.94-.37,296.16.06,287.37.24,278.6c.14-6.93,4.34-10.53,11.22-10.62a613.13,613.13,0,0,0,61.62-3.8c8.29-.94,13,3.1,13.36,11.44C86.59,279.6,86.46,283.59,86.46,289Z"
          fill="#5ac2ad"
        />
        <path
          className="cls-1"
          d="M.11,137.71C.11,106.46,0,75.22.13,44,.2,23.05,13.42,6.15,33.22,1.34A42.58,42.58,0,0,1,85.87,38.61c.36,3.62.57,7.28.57,10.92q.06,86.76,0,173.52c0,10.81-2.07,13.17-12.73,15.24-19.46,3.79-39.16,5.35-58.91,6.42-11,.6-14.72-3.11-14.72-14.25Z"
          fill="#5ac2ad"
        />
        <path
          className="cls-1"
          d="M128.73,148.74q0-28.41,0-56.82a42.94,42.94,0,0,1,85.87-.68c.24,29.07,0,58.15.11,87.22a11.3,11.3,0,0,1-6,10.65,409.82,409.82,0,0,1-65.4,30.54c-8.2,3-14.55-1.71-14.56-10.6Q128.68,178.9,128.73,148.74Z"
          fill="#45b9eb"
        />
        <path
          className="cls-1"
          d="M214.66,300.19c0,15.79.07,31.58,0,47.37-.13,24.39-18.18,43.58-42.53,43.65-25.62.06-42.81-19.25-43.25-40.75-.57-28.41-.16-56.83-.12-85.25,0-5.84,2.74-9.54,8.45-10.7,21.5-4.36,42.69-9.88,63.74-16,8.05-2.35,13.67,2.24,13.7,10.85C214.71,266.29,214.66,283.24,214.66,300.19Z"
          fill="#45b9eb"
        />
        <path
          className="cls-1"
          d="M343.2,251c-.42,15.16.74,33.93-.52,52.68-1.42,21.25-20.07,38.39-42.1,38.8-21.49.41-40.72-16.22-43-37.26a90.17,90.17,0,0,1-.54-9.43Q257,261.85,257,228c0-7.5,1.79-10.07,8.55-12.91q30.81-12.93,60.22-28.76c9.82-5.3,17.29-.94,17.39,10.22C343.31,213.46,343.2,230.41,343.2,251Z"
          fill="#7a6be8"
        />
        <path
          className="cls-1"
          d="M257,84.28c.48-13.56-1-30.53.64-47.41C259.66,16.3,279.3-.37,300.5,0c22,.38,40.66,17.52,42.21,38.73a119.37,119.37,0,0,1,.13,16.38,40.86,40.86,0,0,1-8.49,22.44,427.63,427.63,0,0,1-59.55,64.21c-3.59,3.16-7.45,4.39-11.9,2.35-4.27-2-5.88-5.66-5.89-10.14C257,118.53,257,103.09,257,84.28Z"
          fill="#7a6be8"
        />
      </g>
    </svg>
  );
};

export const MydnaLogoBastonetesDark = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 344.53 393.67"
      width={size}
    >
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M86.46,289a88.31,88.31,0,0,1-.92,18.41c-3.62,20.28-22.75,35.79-43,35.08C20.82,341.75,3.32,326,.71,304.94-.37,296.16.06,287.37.24,278.6c.14-6.93,4.34-10.53,11.22-10.62a613.13,613.13,0,0,0,61.62-3.8c8.29-.94,13,3.1,13.36,11.44C86.59,279.6,86.46,283.59,86.46,289Z"
          fill="#FFFFFF"
        />
        <path
          className="cls-1"
          d="M.11,137.71C.11,106.46,0,75.22.13,44,.2,23.05,13.42,6.15,33.22,1.34A42.58,42.58,0,0,1,85.87,38.61c.36,3.62.57,7.28.57,10.92q.06,86.76,0,173.52c0,10.81-2.07,13.17-12.73,15.24-19.46,3.79-39.16,5.35-58.91,6.42-11,.6-14.72-3.11-14.72-14.25Z"
          fill="#FFFFFF"
        />
        <path
          className="cls-1"
          d="M128.73,148.74q0-28.41,0-56.82a42.94,42.94,0,0,1,85.87-.68c.24,29.07,0,58.15.11,87.22a11.3,11.3,0,0,1-6,10.65,409.82,409.82,0,0,1-65.4,30.54c-8.2,3-14.55-1.71-14.56-10.6Q128.68,178.9,128.73,148.74Z"
          fill="#FFFFFF"
        />
        <path
          className="cls-1"
          d="M214.66,300.19c0,15.79.07,31.58,0,47.37-.13,24.39-18.18,43.58-42.53,43.65-25.62.06-42.81-19.25-43.25-40.75-.57-28.41-.16-56.83-.12-85.25,0-5.84,2.74-9.54,8.45-10.7,21.5-4.36,42.69-9.88,63.74-16,8.05-2.35,13.67,2.24,13.7,10.85C214.71,266.29,214.66,283.24,214.66,300.19Z"
          fill="#FFFFFF"
        />
        <path
          className="cls-1"
          d="M343.2,251c-.42,15.16.74,33.93-.52,52.68-1.42,21.25-20.07,38.39-42.1,38.8-21.49.41-40.72-16.22-43-37.26a90.17,90.17,0,0,1-.54-9.43Q257,261.85,257,228c0-7.5,1.79-10.07,8.55-12.91q30.81-12.93,60.22-28.76c9.82-5.3,17.29-.94,17.39,10.22C343.31,213.46,343.2,230.41,343.2,251Z"
          fill="#FFFFFF"
        />
        <path
          className="cls-1"
          d="M257,84.28c.48-13.56-1-30.53.64-47.41C259.66,16.3,279.3-.37,300.5,0c22,.38,40.66,17.52,42.21,38.73a119.37,119.37,0,0,1,.13,16.38,40.86,40.86,0,0,1-8.49,22.44,427.63,427.63,0,0,1-59.55,64.21c-3.59,3.16-7.45,4.39-11.9,2.35-4.27-2-5.88-5.66-5.89-10.14C257,118.53,257,103.09,257,84.28Z"
          fill="#FFFFFF"
        />
      </g>
    </svg>
  );
};
