// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip-stars {
  width: 250px;
  position: absolute;
  top: 45px;
  background: #6c6cac;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 5px;
  border-radius: 4px;
}
.tooltip-stars::before {
  content: "";
  position: absolute;
  top: -8px;
  left: 10px;
  width: 15px;
  height: 20px;
  transform: rotate(45deg);
  background: #6c6cac;
}`, "",{"version":3,"sources":["webpack://./src/Views/Service/Components/Tooltip/tooltip.sass"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,kBAAA;EACA,SAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;AACJ;AACI;EACI,WAAA;EACA,kBAAA;EACA,SAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EACA,wBAAA;EACA,mBAAA;AACR","sourcesContent":[".tooltip-stars\n    width: 250px\n    position: absolute\n    top: 45px\n    background: #6c6cac\n    display: flex\n    align-items: center\n    justify-content: center\n    color: white\n    padding: 5px\n    border-radius: 4px\n\n    &::before\n        content: \"\"\n        position: absolute\n        top: -8px\n        left: 10px\n        width: 15px\n        height: 20px\n        transform: rotate(45deg)\n        background: #6c6cac\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
