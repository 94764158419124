import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { DesempenoIcon, EntrenamientoIcon, PlanCarreraIcon } from '../../Icons/CollaboratorsIcons'
import { getCapacitaciones, getIndividualEmployeeByTraining } from '../../API/Analytics/empowerment'
import { capacitationsTranslate, wheelCollaboratorTranslate } from '../../i18n/translatedMessages'
import EmptyInformation from './Components/EmptyInformation/EmptyInformation'
import Loading from '../../Components/Loading/Loading'

import './entrenamiento.sass'
import HeaderCarouselMandala from './Components/HeaderCarouselMandala/HeaderCarouselMandala'

const Entrenamiento = () => {
	const { id } = useParams()
	const [loading, setLoading] = useState(true)
	const [empowerments, setEmpowerments] = useState([])
	const [trainings, setTrainings] = useState([])

	const loadData = useCallback(async () => {
		const response = await getCapacitaciones(id)
		if (response) {
			setEmpowerments(response)
		}
		const byTrainingAux = await getIndividualEmployeeByTraining(id)
		if (byTrainingAux) {
			setTrainings(byTrainingAux)
		}
		setLoading(false)
	}, [id])

	useEffect(() => {
		loadData()
	}, [loadData])

	return (
		<div className='content-entrenamiento'>
			{loading && <Loading />}

			<div className='mb-5'>
				<HeaderCarouselMandala
					actualTitle={wheelCollaboratorTranslate.training}
					prevTitle={wheelCollaboratorTranslate.performance}
					routePrev={`/app/analytic/desempeno/${id}`}
					nextTitle={wheelCollaboratorTranslate.careerPlan}
					routeNext={`/app/analytic/plan-carrera/${id}`}
					iconActual={<EntrenamientoIcon />}
					iconPrev={<DesempenoIcon />}
					iconNext={<PlanCarreraIcon />}
				/>
			</div>
			{empowerments.length > 0 && (
				<div className='row'>
					<div className='col-8 offset-2'>
						<div className='table-box'>
							<table className='table table-hover'>
								<thead>
									<tr>
										<th scope='col'>#</th>
										<th scope='col'>{capacitationsTranslate.capacitationCol}</th>
										<th scope='col'>{capacitationsTranslate.capacitationAreaCol}</th>
										<th scope='col'>{capacitationsTranslate.capacitationProgress}</th>
									</tr>
								</thead>
								<tbody>
									{empowerments.map(empowerment => (
										<tr key={empowerment.id}>
											<th scope='row'>{empowerment.id}</th>
											<td>{empowerment.employerCapacitationToCapacitation?.name}</td>
											<td> -- </td>
											<td>{empowerment.advance} %</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			)}
			{trainings.length > 0 && (
				<div className='row mt-3'>
					<div className='col-8 offset-2'>
						<div className='table-box'>
							<table className='table table-hover'>
								<thead>
									<tr>
										<th scope='col'>#</th>
										<th scope='col'>{capacitationsTranslate.capacitationCol}</th>
										<th scope='col'>{capacitationsTranslate.capacitationAreaCol}</th>
										<th scope='col'>{capacitationsTranslate.capacitationProgress}</th>
									</tr>
								</thead>
								<tbody>
									{trainings.map(training => (
										<tr key={training.id}>
											<th scope='row'>{training.id}</th>
											<td>{training.name}</td>
											<td> -- </td>
											<td>{training.advance} %</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			)}
			{empowerments.length === 0 && trainings.length === 0 && <EmptyInformation />}
		</div>
	)
}
export default Entrenamiento
