import { useState } from "react";
import { careerPlanTranslate } from "../../../../i18n/translatedMessages";
import "./hierarchicalLevel.sass";

const HierarchicalLevel = ({ nextLevel, actualLevel }) => {
    return (
        <div>
            <div className="row">
                <div className="col grid-container">
                    <div className="content-nivel">
                        <div className="margin-left hierarchical-level">
                            <div className="content-item">
                                <div
                                    className={`box ${
                                        nextLevel === "Director"
                                            ? "nextLevel"
                                            : ""
                                    } ${
                                        actualLevel === "Director"
                                            ? "actualLevel"
                                            : ""
                                    }`}
                                ></div>
                                {careerPlanTranslate.director}
                            </div>

                            <div className="content-item">
                                <div
                                    className={`box ${
                                        nextLevel === "Gerentes"
                                            ? "nextLevel"
                                            : ""
                                    } ${
                                        actualLevel === "Gerentes"
                                            ? "actualLevel"
                                            : ""
                                    }`}
                                ></div>
                                {careerPlanTranslate.manager}
                            </div>

                            <div className="content-item">
                                <div
                                    className={`box ${
                                        nextLevel === "Subgerentes"
                                            ? "nextLevel"
                                            : ""
                                    } ${
                                        actualLevel === "Subgerentes"
                                            ? "actualLevel"
                                            : ""
                                    }`}
                                ></div>
                                {careerPlanTranslate.submanager}
                            </div>

                            <div className="content-item">
                                <div
                                    className={`box ${
                                        nextLevel === "Jefes" ? "nextLevel" : ""
                                    } ${
                                        actualLevel === "Jefes"
                                            ? "actualLevel"
                                            : ""
                                    }`}
                                ></div>
                                {careerPlanTranslate.boss}
                            </div>

                            <div className="content-item">
                                <div
                                    className={`box ${
                                        nextLevel ===
                                        "Especialistas / Coordinadores"
                                            ? "nextLevel"
                                            : ""
                                    } ${
                                        actualLevel ===
                                        "Especialistas / Coordinadores"
                                            ? "actualLevel"
                                            : ""
                                    }`}
                                ></div>
                                {careerPlanTranslate.specialist}
                            </div>

                            <div className="content-item">
                                <div
                                    className={`box ${
                                        nextLevel === "Analista"
                                            ? "nextLevel"
                                            : ""
                                    } ${
                                        actualLevel === "Analista"
                                            ? "actualLevel"
                                            : ""
                                    }`}
                                ></div>
                                {careerPlanTranslate.analist}
                            </div>

                            <div className="content-item">
                                <div
                                    className={`box ${
                                        actualLevel ===
                                        "Operário / Técnico / Asistente"
                                            ? "actualLevel"
                                            : ""
                                    }`}
                                ></div>
                                {careerPlanTranslate.technical}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default HierarchicalLevel;
