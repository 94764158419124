import "./timeline.sass";
import { useState, memo } from "react";
import { CarouselItem } from "../../../../Components/Carousel";
import Carousel from "../../../../Components/Carousel";

const TimelineChild = memo(
    ({ exp, dataEmployee, setDataEmployee }) => {

        return (
            <button
                className="card-item"
                onClick={() => {
                    setDataEmployee(exp);
                }}
            >
                <div
                    className={`ball-point${
                        exp.id === dataEmployee.id ? " active" : ""
                    }`}
                ></div>
                <h6>{exp.company}</h6>
                <svg width="3" height="16">
                    <line
                        y1={1}
                        y2={15}
                        x1={1}
                        x2={1}
                        style={{ stroke: "#7a6be6", strokeWidth: "3px" }}
                    />
                </svg>
                <span className="job">{exp.job}</span>
                {exp.startYear !== "" && (
                    <span className="date">
                        {exp.startMonth.padStart(2, "0")}/{exp.startYear}{" "}
                        {exp.endMonth && exp.currentJob ? (
                            `- hasta la fecha`
                        ):(
                            `- ${exp.endMonth.padStart(2, "0")}/${exp.endYear}`
                        )}
                    </span>
                )}
            </button>
        );
    }
);

const Timeline = ({ experiences }) => {
    const [seeMore, setSeeMore] = useState(false);
    const [seeMoreFunctions, setSeeMoreFunctions] = useState(false);
    const experieceList = experiences.sort((a, b) => b.startYear - a.startYear);
    const [dataEmployee, setDataEmployee] = useState(experieceList[0]);
    const functions = dataEmployee.functions ? dataEmployee.functions : '';
    const achievements = dataEmployee.achievements ? dataEmployee.achievements : '';
    
    return (
        <div className="content-time-line">
            <Carousel
                col={2}
                experieceList={experieceList}
                setDataEmployee={setDataEmployee}>
                {experieceList.map((exp) => (
                    <CarouselItem key={exp.id}>
                        <TimelineChild
                            exp={exp}
                            dataEmployee={dataEmployee}
                            setDataEmployee={setDataEmployee}
                        />
                    </CarouselItem>
                ))}
            </Carousel>
            <div className="line">
                <svg width="100%" height={50}>
                    <circle cx="99%" cy={25} r={5} fill="#7a6be6" />
                    <line
                        y1={25}
                        y2={25}
                        x1={10}
                        x2="99%"
                        style={{ stroke: "#7a6be6", strokeWidth: "3px" }}
                    />
                    <line
                        y1={18}
                        y2={25}
                        x1={20}
                        x2={9}
                        style={{ stroke: "#7a6be6", strokeWidth: "3px" }}
                        strokeLinecap="round"
                    />
                    <line
                        y1={25}
                        y2={33}
                        x1={9}
                        x2={20}
                        style={{ stroke: "#7a6be6", strokeWidth: "3px" }}
                        strokeLinecap="round"
                    />
                </svg>
            </div>

            {handleEmployee(dataEmployee) !== undefined && (
                <>
                    <div className="h-card h-card-header">
                        <h2 className="h-employee">
                            {handleEmployee(dataEmployee).employeeJob}
                        </h2>
                    </div>
                    <div className="h-card">
                        <div className="h-card-content">
                            <h3 className="h-card__title">Funciones</h3>
                            <p className="h-card__description">
                                {seeMoreFunctions ? functions : `${functions.slice(0, 180)}...`}
                                {functions.length > 180 && 
                                <a className="btn_see_more" onClick={() => setSeeMoreFunctions(!seeMoreFunctions)}>
                                    {seeMoreFunctions ? ' Ver menos' : ' Ver más'}
                                </a>
                                }
                            </p>
                        </div>
                        <div className="h-card-content">
                            <h3 className="h-card__title">
                                Logros y Conquistas
                            </h3>
                            <p className="h-card__description text-container">
                                {seeMore ? achievements : `${achievements.slice(0, 180)}...`}
                                {achievements.length > 180 && 
                                <a className="btn_see_more" onClick={() => setSeeMore(!seeMore)}>
                                    {seeMore ? ' Ver menos' : ' Ver más'}
                                </a>
                                }    
                            </p>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
export default Timeline;

const handleEmployee = (exp) => {
    let sendata = undefined;
    
    const {
        job,
        company,
        endYear,
        endMonth,
        startYear,
        functions,
        startMonth,
        achievements,
    } = exp;

    const EMPLEYEESTARYEAR = `${startMonth}/${startYear}`;
    const EMPLEYEEENDYEAR = `${endMonth}/${endYear}`;

    const employeeJob = `${job} en ${company} de ${EMPLEYEESTARYEAR} hasta ${exp.currentJob !== true ? EMPLEYEEENDYEAR : "la fecha"}`;

    if (functions !== "" && achievements !== "") {
        sendata = {
            functions,
            achievements,
            employeeJob,
        };
    }
    return sendata;
};
