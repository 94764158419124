import React, { useContext, useState } from "react";
import { ServicesContext } from "../../../../Store/ServicesContext"
import { ServiceRequestModal } from "../../Modais/ServiceRequestModal/ServiceRequestModal";

import {bodyTextTranslate} from "../../../../i18n/translatedMessages"

import Stars from "../Stars/stars";

import "./consultorCard.sass";
import ButtonClick from "./buttonClick";

const ConsultorCard = ({ option }) => {
  const { store } = useContext(ServicesContext);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const ModalService = () => {
    return React.cloneElement(option.modal, { show, handleClose, option });
  };

  return (
    <>
      {store.RoleId !== 2 && (
        <ServiceRequestModal
          view={show}
          hide={handleClose}
          service={option.title}
        />
      )}
      {store.RoleId === 2 && (
        <ModalService view={show} hide={handleClose} option={option} />
      )}
      <div className="card consultor-card shadow">
        <div className="card-body">
          <div className="row">
            <div className="col-md-1 col-sm-12 d-flex align-items-center flex-column">
              <div
                className="rounded-circle img-service mb-2"
                style={{ background: `url("${option.background}")` }}
              ></div>
            </div>
            <div className="col-md-11">
              <h4>{option.title}</h4>
              <div className="content-service">
                <p>{option.text}</p>
              </div>
            </div>
          </div>
          <div className="row align-items-end">
            <div className="col-md-4 text-center">
              <iframe
                width="220"
                height="130"
                src={option.video}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              ></iframe>
            </div>
            <div className="col-md-4 btn-descargar text-center">
              <a href={option.file} target="_blank" rel="noreferrer">
                <button>
                  <img
                    src="https://assets.mydna.company/iconoCard_descargar-reporte.svg"
                    alt=""
                  />
                  <span>{bodyTextTranslate.apresentationDown}</span>
                </button>
              </a>
            </div>
            <div className="col-md-4 card-score_and_button">
              <div className="row align-content-center m-0">
                <div className="col-3 card-score_number">{option.stars}</div>
                <div className="col-9 card-score_explain">
                  {bodyTextTranslate.customerEvaluation}
                </div>
              </div>
              <Stars note={option.stars} />

              <ButtonClick handleShow={handleShow} option={option} roleID={store.RoleId} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ConsultorCard;
