import { useState, useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { CompanyContext } from '../../Store/CompanyContext'
import { signupEmployee, signin } from '../../API/Login/login'
import ResponseModal from './responseModal'

import './signupB2E.sass'

const SignupB2E = () => {
	const history = useHistory()
	const { actions } = useContext(CompanyContext)
	const { email } = useParams()
	const [show, setShow] = useState(false)
	const [password, setPassword] = useState()

	const handleChange = event => {
		setPassword(event.currentTarget.value)
	}

	const handleLogin = async () => {
		const account = {
			email,
			password,
		}
		const response = await signupEmployee(account)
		if (response.status === 200) {
			const login = await signin(account)
			localStorage.setItem('token', login.token)
			localStorage.setItem('user', JSON.stringify(login.response))

			actions.setLogin(login.response)
			const data = await response.json()
			const route = Number(data.roleId) === 2 ? '/employee' : '/app'

			history.push(route)
		}
		setShow(true)
	}

	return (
		<>
			<ResponseModal setShow={setShow} show={show} />
			<div className='signupB2E'>
				<img className='companyLogo' src='https://aadcdn.msftauthimages.net/dbd5a2dd-q-vgx0wbu9ntyuhzdvuv5jk-4tjk439hs8ffouhilva/logintenantbranding/0/bannerlogo?ts=637565229533956374' alt='Logotipo de la empresa'></img>
				<h2>Crea tu contraseña</h2>
				{email && (
					<div className='collaborator'>
						<h4>
							<span> {email} </span>
							bienvenido
						</h4>
					</div>
				)}
				<div className='group-input'>
					<label htmlFor='password'></label>
					<input placeholder='Contraseña' type='password' name='password' value={password} onChange={handleChange} />
				</div>
				<div className='content-button'>
					<button onClick={handleLogin}>Crear</button>
				</div>
			</div>
		</>
	)
}
export default SignupB2E
