import { useEffect, useState } from "react";
import { getCultureValue } from "../../API/Employee/Culture";
import {
    btnTranslate,
    climeCultureTranslate,
} from "../../i18n/translatedMessages";
import CulturaBar from "./Components/CulturaBar/CulturaBar";

import { NoteIcon } from "../../Icons/Icons";
import Quiz from "./Components/Quiz/Quiz";

import "./culture.sass";
import { Link } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";

const Culture = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [bars, setBars] = useState([]);

    const Statistics = () => {
        return (
            <div className="content-cultura">
                <>
                    <div className="cultura-perfil">
                        <h5>{climeCultureTranslate.culturePreview}</h5>
                        <ul className="d-flex">
                            {bars.map((item) => (
                                <li className="card-bars" key={item.id}>
                                    <CulturaBar
                                        percentagem={item.percentagem}
                                        color={item.color}
                                    >
                                        <img src={item.icon} alt="" />
                                    </CulturaBar>
                                    {item.type}
                                </li>
                            ))}
                        </ul>
                    </div>
                </>
            </div>
        );
    };

    useEffect(() => {
        const fetchData = async () => {
            const current_user = JSON.parse(localStorage.getItem("user"));
            const response = await getCultureValue(current_user.id);
            if (response.profile) {
                const current_bars = [
                    {
                        id: 0,
                        icon: "https://assets.mydna.company/Comercial.svg",
                        percentagem: parseFloat(
                            response.profile["comercial"]
                        ).toFixed(2),
                        type: climeCultureTranslate.commercial,
                        color: "#7a6be6",
                    },
                    {
                        id: 1,
                        icon: "https://assets.mydna.company/Disruptivo.svg",
                        percentagem: parseFloat(
                            response.profile["disruptiva"]
                        ).toFixed(2),
                        type: climeCultureTranslate.disruptive,
                        color: "#41c6ff",
                    },
                    {
                        id: 2,
                        icon: "https://assets.mydna.company/Colaborativo.svg",
                        percentagem: parseFloat(
                            response.profile["colaborativa"]
                        ).toFixed(2),
                        type: climeCultureTranslate.collaborative,
                        color: "#2be7c8",
                    },
                    {
                        id: 3,
                        icon: "https://assets.mydna.company/Tradicional.svg",
                        percentagem: parseFloat(
                            response.profile["tradicional"]
                        ).toFixed(2),
                        type: climeCultureTranslate.traditional,
                        color: "#38364b",
                    },
                ];
                setBars(current_bars);
            }
            setIsLoading(false);
        };
        fetchData();
    }, []);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="culture-b2e">
                        <div className="row mb-4">
                            <div className="col-md-1">
                                <NoteIcon color="#818888" />
                            </div>
                            <div className="col">
                                <h1 className="titleDescription-section title-my-center">
                                    {climeCultureTranslate.myCultureProfile}
                                </h1>
                                <p className="description-section">
                                    {climeCultureTranslate.myCultureText}
                                </p>
                            </div>
                        </div>
                        <Link
                            className="button-volver color-blue"
                            to={"/employee/climaycultura"}
                        >
                            <MdArrowBack size={20} /> {btnTranslate.backButton}
                        </Link>
                        {!isLoading &&
                            (bars.length > 0 ? Statistics() : <Quiz />)}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Culture;
