import { useEffect, useState, useContext } from "react";
import { CompanyContext } from '../../../../../Store/CompanyContext'
import { KnowPaybalanceTranslate } from '../../../../../i18n/translatedMessages'
import Card from '../../../Components/Card/card'

const KnowPaybalance = () => {

    const { store } = useContext(CompanyContext);    

    const [urlVideo, setUrlVideo] = useState('')
    const [urlFile, seturlFile] = useState('')

    useEffect(() => {  
        store.language === 'ES' ? setUrlVideo('https://www.youtube.com/embed/REsgKxHbkTI') : setUrlVideo('https://www.youtube.com/embed/_AQVWe907AY')
        store.language === 'ES' ? seturlFile('https://assets.mydna.company/reportes/ejemplos/reporte_salarial_ejemplo_2.pdf') : seturlFile('https://assets.mydna.company/reportes/ejemplos/reporte_salarial_ejemplo_1.pdf')
    }, [store.language])

    return (
    <div className="row">
        <div className="col d-flex justify-content-center offset-1">
            <Card
                title={KnowPaybalanceTranslate.KnowPaybalanceTitle}
                background="https://assets.mydna.company/iconoCard_dashboard-empresa.svg"
                text={KnowPaybalanceTranslate.KnowPaybalanceTextCard}
                video={urlVideo}
                file={urlFile}
                stars={4.9}
                fileAmount={1}
            />
        </div>
    </div>)}
export default KnowPaybalance