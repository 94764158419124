import { useState } from "react";
import {
    DiscoDuroIcon,
    ImageIcon,
    CameraIcon,
    MicroPhoneIcon,
    GenerarProcesoIcon,
    PropositoIcon,
} from "../../Icons/CollaboratorsIcons";
import { Link, useHistory } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";
import { sendComplaint } from "../../API/Onbudsman/Onbudsman";
import { complaintTranslate } from "../../i18n/translatedMessages";
import { btnTranslate } from "../../i18n/translatedMessages";
import Loading from "../../Components/Loading/Loading";
import { FormattedMessage } from "react-intl";

import "./ingresod.css";
import "./canaldenuncia.sass";

const typesA = [
    { name: complaintTranslate.complaintOptionA },
    { name: complaintTranslate.complaintOptionB },

    { name: complaintTranslate.complaintOptionC },
    { name: complaintTranslate.complaintOptionD },

    { name: complaintTranslate.complaintOptionE },
    { name: complaintTranslate.complaintOptionF },

    { name: complaintTranslate.complaintOptionG },
    { name: complaintTranslate.complaintOptionH },

    { name: complaintTranslate.complaintOptionI },
    { name: complaintTranslate.complaintOptionJ },

    { name: complaintTranslate.complaintOptionK },

    { name: complaintTranslate.complaintOptionL },
];

const B2EIngresoDenuncia = () => {
    const history = useHistory();

    const { id } = JSON.parse(localStorage.getItem("user"));
    const [message, setMessage] = useState("");
    const [complaint, setComplaint] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleText = (event) => {
        setMessage(event.target.value);
    };

    const add = (item) => {
        const array = [...complaint];
        array.push(item);
        setComplaint(array);
    };

    const remove = (item) => {
        const array = [...complaint];
        const index = array.indexOf(item);
        array.splice(index, 1);
        setComplaint(array);
    };

    const handleClick = (event) => {
        event.target.checked
            ? add(event.target.dataset.value)
            : remove(event.target.dataset.value);
    };

    const send = async () => {
        setLoading(true);
        const data = {
            employerId: id,
            types: complaint,
            message,
        };

        await sendComplaint(data);
        setComplaint([]);
        setMessage("");
        setLoading(false);
        history.push("/employee/canaldenuncia");
    };

    return (
        <div className="content-denuncia">
            {loading && (
                <div className="loading">
                    <Loading />
                </div>
            )}
            <div className="title">
                <div className="icon">
                    <PropositoIcon />
                </div>
                <h2>
                    <b>Ombudsman</b>
                </h2>
            </div>

            <div className="row mt-5 mx-2">
                <div className="col-12 col-md-6 mt-5 mt-md-0">
                    <div className=" h-cont m-0 p-0">
                        <div className="h-d m-0 p-0">
                            <p className="h-d-title text-center">
                                {complaintTranslate.complaintTypes}
                            </p>
                        </div>

                        <div className="row mx-3  align-items-center justify-content-start  h-100">
                            {typesA?.map((item, index) => (
                                <div
                                    key={index + 1}
                                    className="col-11 col-md-5 m-1"
                                >
                                    <div className="form-check">
                                        <input
                                            data-value={item.name}
                                            className="form-check-input cursor-pointer"
                                            type="checkbox"
                                            onClick={handleClick}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="flexCheckChecked"
                                        >
                                            {item.name}
                                        </label>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 mt-3 mt-md-0">
                    <div className="row">
                        <div className="col-12">
                            <FormattedMessage id={"complaintDetail"}>
                                {msg => (
                                    <textarea
                                        onChange={handleText}
                                        className="form-control"
                                        rows="11"
                                        placeholder={msg}
                                    ></textarea>
                                )}
                            </FormattedMessage>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12">
                            <button className="btn-g" onClick={send}>
                                <span className="btn-g-txt">
                                    <GenerarProcesoIcon />
                                    {complaintTranslate.complaintGenerate}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-6 mt-5">
                <Link
                    className="button-volver color-blue"
                    to={"/employee"}
                >
                    <MdArrowBack size={20} />
                    {btnTranslate.backButton}
                </Link>
            </div>
        </div>
    );
};
export default B2EIngresoDenuncia;
