class bandsMapper {
    toDomain(persistenceBands) {
        return {
            name: persistenceBands.name,
            band: persistenceBands.band,
            country: persistenceBands.country_id,
        }
    }

    toDomainSelect(persistenceBands) {
        return {
            value: persistenceBands.id,
            label: persistenceBands.name,
            band: persistenceBands.band,
        }
    }
}

export default new bandsMapper()