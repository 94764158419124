// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.culture-b2e {
  padding: 50px;
}
.culture-b2e .pills-b2e {
  width: 100%;
  height: 37px;
  border: 2px solid #746be8;
  border-radius: 29px;
  color: #746be8;
  background-color: transparent;
  padding: 0 5px;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 14px;
  background: white;
}
.culture-b2e .pills-b2e svg {
  margin-right: 15px;
}
.culture-b2e .buttons .col:nth-child(1) .active {
  background: #0ab4c4;
}
.culture-b2e .buttons .col:nth-child(2) .active {
  background: #746be8;
}
.culture-b2e .buttons .col:nth-child(3) .active {
  background: #2b97db;
}
.culture-b2e .buttons .col:nth-child(4) .active {
  background: #5fb1a4;
}
.culture-b2e .active {
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/Views/Employee/culture.sass"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AAGI;EACI,WAAA;EACA,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,cAAA;EACA,6BAAA;EACA,cAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AADR;AAGQ;EACI,kBAAA;AADZ;AAGQ;EACI,mBAAA;AADZ;AAEQ;EACI,mBAAA;AAAZ;AACQ;EACI,mBAAA;AACZ;AAAQ;EACI,mBAAA;AAEZ;AAAI;EACI,YAAA;AAER","sourcesContent":[".culture-b2e\n    padding: 50px\n    // height: calc(100vh - 60px)\n    // overflow-y: auto\n\n    .pills-b2e\n        width: 100%\n        height: 37px\n        border: 2px solid #746be8\n        border-radius: 29px\n        color: #746be8\n        background-color: transparent\n        padding: 0 5px\n        margin-bottom: 10px\n        font-weight: 600\n        font-size: 14px\n        background: white\n\n        svg\n            margin-right: 15px\n    .buttons\n        .col:nth-child(1) .active\n            background: #0ab4c4\n        .col:nth-child(2) .active\n            background: #746be8\n        .col:nth-child(3) .active\n            background: #2b97db\n        .col:nth-child(4) .active\n            background: #5fb1a4\n\n    .active\n        color: white\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
