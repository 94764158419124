// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.job-description .back {
  font-size: 20px;
  border: 1px solid;
  padding: 10px;
  border-radius: 5px;
}
.job-description .back:hover {
  background: rgba(13, 110, 253, 0.15);
}
.job-description ul li {
  margin: 12px 5px;
}
.job-description .fs-8 {
  font-size: smaller;
}
.job-description .rounded-8 {
  border-radius: 25px;
}`, "",{"version":3,"sources":["webpack://./src/Views/Services/Components/JobDescription/jobDescription.sass"],"names":[],"mappings":"AACI;EACI,eAAA;EACA,iBAAA;EACA,aAAA;EACA,kBAAA;AAAR;AAEQ;EACI,oCAAA;AAAZ;AAEI;EACI,gBAAA;AAAR;AAEI;EACI,kBAAA;AAAR;AAEI;EACI,mBAAA;AAAR","sourcesContent":[".job-description\n    & .back\n        font-size: 20px\n        border: 1px solid\n        padding: 10px\n        border-radius: 5px\n\n        &:hover\n            background: rgba(13, 110, 253, .15)\n\n    & ul li\n        margin: 12px 5px\n\n    & .fs-8\n        font-size: smaller\n\n    & .rounded-8\n        border-radius: 25px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
