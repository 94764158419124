// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-container {
	display: flex;
	justify-content: space-between;
	max-width: 600px; /* Ajusta el ancho máximo según tus necesidades */
	margin: 20px;
  }
  
  .filter-group {
	flex: 1 1;
	margin-right: 15px;
  }
  
  label {
	margin-bottom: 5px;
	font-weight: bold;
  }
  
  .selectFiltros {
	width: 100%;
	padding: 8px;
	margin-bottom: 15px;
	border: 2px solid #6d68aa !important;
	border-radius: 18px !important;
	box-sizing: border-box;
  }
  
  /* Ajusta más estilos según sea necesario */
  `, "",{"version":3,"sources":["webpack://./src/Views/Services/Components/PayBalance/Components/PositionCompanyFilter/FilterComponent.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,8BAA8B;CAC9B,gBAAgB,EAAE,iDAAiD;CACnE,YAAY;EACX;;EAEA;CACD,SAAO;CACP,kBAAkB;EACjB;;EAEA;CACD,kBAAkB;CAClB,iBAAiB;EAChB;;EAEA;CACD,WAAW;CACX,YAAY;CACZ,mBAAmB;CACnB,oCAAoC;CACpC,8BAA8B;CAC9B,sBAAsB;EACrB;;EAEA,2CAA2C","sourcesContent":[".filter-container {\r\n\tdisplay: flex;\r\n\tjustify-content: space-between;\r\n\tmax-width: 600px; /* Ajusta el ancho máximo según tus necesidades */\r\n\tmargin: 20px;\r\n  }\r\n  \r\n  .filter-group {\r\n\tflex: 1;\r\n\tmargin-right: 15px;\r\n  }\r\n  \r\n  label {\r\n\tmargin-bottom: 5px;\r\n\tfont-weight: bold;\r\n  }\r\n  \r\n  .selectFiltros {\r\n\twidth: 100%;\r\n\tpadding: 8px;\r\n\tmargin-bottom: 15px;\r\n\tborder: 2px solid #6d68aa !important;\r\n\tborder-radius: 18px !important;\r\n\tbox-sizing: border-box;\r\n  }\r\n  \r\n  /* Ajusta más estilos según sea necesario */\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
