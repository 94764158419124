export const postEmployee = async formData => {
	try {
		const resp = await fetch(`${process.env.REACT_APP_API}/employer/form`, {
			method: 'POST',
			headers: {
				'Content-type': 'application/json',
			},
			body: JSON.stringify(formData),
		})
		return resp.json()
	} catch (error) {
		console.error(error)
	}
}

export const getEmployeesByCompany = async companyId => {
	try {
		const resp = await fetch(`${process.env.REACT_APP_API}/employer/company/${companyId}`, {
			method: 'GET',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
			},
		})
		return resp.json()
	} catch (error) {
		console.error(error)
	}
}

export const getEmployee = async employeeId => {
	try {
		const resp = await fetch(`${process.env.REACT_APP_API}/employer/${employeeId}`, {
			method: 'GET',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
			},
		})
		return resp.json()
	} catch (error) {
		console.error(error)
	}
}

export const putEmployee = async (data, employeeId) => {
	try {
		const resp = await fetch(`${process.env.REACT_APP_API}/employer/${employeeId}`, {
			method: 'PUT',
			headers: {
				'Content-type': 'application/json',
			},
			body: JSON.stringify(data),
		})
		const respSmtm = await fetch(`https://smtm.co/v1/update_position_from_mydna`, {
			method: 'PUT',
			headers: {
				'Content-type': 'application/json',
			},
			body: JSON.stringify(data),
		})
		return resp.json()
	} catch (error) {
		console.error(error)
	}
}

export const deleteEmployee = async employeeId => {
	try {
		const resp = await fetch(`${process.env.REACT_APP_API}/employer/${employeeId}`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
			},
		})
		return resp.json()
	} catch (error) {
		console.error(error)
	}
}

export const postAssetsEmployee = async data => {
	try {
		const resp = await fetch(`${process.env.REACT_APP_API}/assetsuploader/employee`, {
			method: 'POST',
			headers: {
				'Content-type': 'application/json',
			},
			body: JSON.stringify(data),
		})
		return resp.json()
	} catch (error) {
		console.error(error)
	}
}
export const getAssetsEmployee = async employeeId => {
	try {
		const response = await fetch(`${process.env.REACT_APP_API}/assetsuploader/employee/${employeeId}`, {
			method: 'GET',
			headers: {
				'Content-type': 'application/json',
			},
		})
		return response.json()
	} catch (error) {
		console.error(error)
	}
}
export const delAssetsEmployee = async assetsId => {
	try {
		const resp = await fetch(`${process.env.REACT_APP_API}/assetsuploader/employee/${assetsId}`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
			},
		})
		return resp.json()
	} catch (error) {
		console.error(error)
	}
}

export const getAllPhotoUrls = async employeeId => {
	// datos que deseas enviar en la solicitud POST
	const data = {}

	// opciones para la solicitud POST
	const options = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(data),
	}

	// URL del endpoint al que deseas enviar la solicitud POST
	const url = `${process.env.REACT_APP_API}/assetsuploader/employeesPhotoUrls/${JSON.parse(localStorage.getItem('user')).id}`

	// hacer la solicitud POST con fetch()
	const resp = await fetch(url, options)

	return resp.json()
}

export const getEmployeePerformance = async id => {
	try {
		const response = await fetch(`${process.env.REACT_APP_API}/ee/employee/performance/${id}`, {
			method: 'GET',
			headers: {
				'Content-type': 'application/json',
			},
		})
		return response.json()
	} catch (error) {
		console.error(error)
	}
}

export const getEmployeeEvaluations = async (companyId, page, area, level, country, text) => {
	try {
		const response = await fetch(`${process.env.REACT_APP_API}/ee/employee/evaluations/${companyId}?page=${page}&area=${area}&level=${level}&country=${country}&query=${text}`, {
			method: 'GET',
			headers: {
				'Content-type': 'application/json',
			},
		})
		return response.json()
	} catch (error) {
		console.error(error)
	}
}

export const getSalaryBand = async (companyId, page, area, level, country, text) => {
	try {
		const response = await fetch(`${process.env.REACT_APP_API}/company/all-salary-band-9box/${companyId}?page=${page}&area=${area}&level=${level}&country=${country}&query=${text}`, {
			method: 'GET',
			headers: {
				'Content-type': 'application/json',
			},
		})
		return response.json()
	} catch (error) {
		console.error(error)
	}
}

export const getEmployeePotential = async id => {
	try {
		const response = await fetch(`${process.env.REACT_APP_API}/ee/employee/potential/${id}`, {
			method: 'GET',
			headers: {
				'Content-type': 'application/json',
			},
		})
		return response.json()
	} catch (error) {
		console.error(error)
	}
}

export const postEmployeePerformance = async data => {
	try {
		const response = await fetch(`${process.env.REACT_APP_API}/ee/employee/performance`, {
			method: 'POST',
			headers: {
				'Content-type': 'application/json',
			},
			body: JSON.stringify(data),
		})
		const responseData = await response.json()
		return responseData
	} catch (error) {
		console.error(error)
	}
}

export const postEmployeePotential = async data => {
	try {
		const response = await fetch(`${process.env.REACT_APP_API}/ee/employee/potential`, {
			method: 'POST',
			headers: {
				'Content-type': 'application/json',
			},
			body: JSON.stringify(data),
		})
		const responseData = await response.json()
		return responseData
	} catch (error) {
		console.error(error)
	}
}

export const getSalary9Box = async ({ id, companyName, email }) => {
	try {
		const response = await fetch(`${process.env.REACT_APP_API}/company/salary-band-9box/${id}?companyName=${companyName}&employeeMail=${email}`, {
			method: 'GET',
			headers: {
				'Content-type': 'application/json',
			},
		})
		return response.json()
	} catch (error) {
		console.error(error)
	}
}

export const postSalary9Box = async data => {
	try {
		const response = await fetch(`${process.env.REACT_APP_API}/company/salary-band-9box`, {
			method: 'POST',
			headers: {
				'Content-type': 'application/json',
			},
			body: JSON.stringify(data),
		})
		const responseData = await response.json()
		return responseData
	} catch (error) {
		console.error(error)
	}
}
