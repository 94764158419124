import ConsultorCard from '../Card/card'
import Capacitaciones from '../../Modais/Capacitaciones'
import Evaluaccion from '../../Modais/Evaluaccion'
import Onboarding from '../../Modais/Onboarding'
import Outplacement from '../../Modais/Outplacement'
import PeopleAnalytics from '../../Modais/PeopleAnalytics'
import PlanDeCarrera from '../../Modais/PlanDeCarrera'
import ReclutamientoYSeleccion from '../../Modais/ReclutamientoYSeleccion'
import SalariosYBeneficios from '../../Modais/SalariosYBeneficios'
import BlueColor from '../../Modais/BlueColor'
import FitCulturalCandidados from '../../Modais/FitCulturalCandidados'
import FitCulturalColaboradores from '../../Modais/FitCulturalColaboradores'
import GameficationContenidos from '../../Modais/GameficationContenidos'
import ReporteCultura from '../../Modais/ReporteCultura'
import { cardSectionTranslate } from '../../../../i18n/translatedMessages'

const AccreditedCompanies = ({ option }) => {
	const sections = [
		[],
		[
			{
				title: cardSectionTranslate.collaborator360,
				background: 'https://assets.mydna.company/iconoCard_dashboard-empresa.svg',
				text: cardSectionTranslate.textCollaborator360,
				video: 'https://www.youtube.com/embed/aVfzDCoiW9o',
				file: 'https://assets.mydna.company/Reporte_People_Analytics-Colaborador_360.pdf',
				stars: 4.9,
				modal: <PeopleAnalytics />,
			},
			{
				title: cardSectionTranslate.dashboardCompany,
				background: 'https://assets.mydna.company/iconoCard_dashboard-empresa.svg',
				text: cardSectionTranslate.textDashboardCompany,
				video: 'https://www.youtube.com/embed/aVfzDCoiW9o',
				file: '',
				stars: 4.5,
				modal: <PeopleAnalytics />,
			},
		],
		[
			{
				title: cardSectionTranslate.executiveSearch,
				background: 'https://assets.mydna.company/iconoCard_executive-search.svg',
				text: cardSectionTranslate.textExecutiveSearch,
				video: 'https://www.youtube.com/embed/BlUPE4NnVk0',
				file: 'https://assets.mydna.company/Reporte_R&S-Executive_Search-Book_Hunting.pdf',
				stars: 5 + '.' + 0,
				modal: <ReclutamientoYSeleccion />,
			},
			{
				title: cardSectionTranslate.middleTopManagement,
				background: 'https://assets.mydna.company/iconoCard_middle-e-top-management.svg',
				text: cardSectionTranslate.textMiddleTopManagement,
				video: 'https://www.youtube.com/embed/BlUPE4NnVk0',
				file: 'https://assets.mydna.company/Reporte_R&S-Middle_&_Top_Management-Book_Bonding.pdf',
				stars: 4.6,
				modal: <ReclutamientoYSeleccion />,
			},
			{
				title: cardSectionTranslate.backoffandStaff,
				background: 'https://assets.mydna.company/iconoCard_blue-collar.svg',
				text: cardSectionTranslate.textBackoffandStaff,
				video: 'https://www.youtube.com/embed/BlUPE4NnVk0',
				file: 'https://assets.mydna.company/Reporte_R&S-Backoffice_&_Staff-The_Bonding.pdf',
				stars: 4.8,
				modal: <BlueColor />,
			},
		],
		[
			{
				title: cardSectionTranslate.plataformOnboardinGamified,
				background: 'https://assets.mydna.company/iconoCard_compromiso-de-los-trabajadores.svg',
				text: cardSectionTranslate.textPlataformOnboardinGamified,
				video: 'https://www.youtube.com/embed/Z-dj9P7PA9c',
				file: 'https://assets.mydna.company/Reporte_Onboarding-Plataforma_de_Onboarding-Guia_Empleador_BadgeHeroes.pdf',
				stars: 4.4,
				modal: <Onboarding />,
			},
		],
		[
			{
				title: cardSectionTranslate.englishClass,
				background: 'https://assets.mydna.company/iconoCard_ingles.svg',
				text: cardSectionTranslate.textEnglishClass,
				video: 'https://www.youtube.com/embed/_8KOnMhgrkk',
				file: 'https://assets.mydna.company/Reporte_Capacitacion_e_Desarrollo-Nulinga.pdf',
				stars: 5 + '.' + 0,
				modal: <Capacitaciones />,
			},
			{
				title: 'Capacitaciones',
				background: 'https://assets.mydna.company/iconoCard_capacitacion-de-personas.svg',
				text: 'Texto descrevendo Capacitaciones.',
				video: 'https://www.youtube.com/embed/Vc7AGe_IjqI',
				file: 'https://assets.mydna.company/Reporte_Capacitacion_e_Desarrollo-Nulinga.pdf',
				stars: 4 + '.' + 0,
				modal: <Capacitaciones />,
			},
			{
				title: 'Entrenamiento pitch comercial & ventas',
				background: 'https://assets.mydna.company/iconoCard_pitch-comercial.svg',
				text: cardSectionTranslate.textCommercialTraining,
				video: 'https://www.youtube.com/embed/aVfzDCoiW9o',
				file: '',
				stars: 5 + '.' + 0,
				modal: <Capacitaciones />,
			},
			{
				title: cardSectionTranslate.gamefiedTraining,
				background: 'https://assets.mydna.company/iconoCard_gamificacion-de-contenidos.svg',
				text: cardSectionTranslate.textGamefiedTraining,
				video: 'https://www.youtube.com/embed/OXdaFdqgmAA',
				file: '',
				stars: 4.2,
				modal: <GameficationContenidos />,
			},
		],
		[
			{
				title: cardSectionTranslate.cultureReport,
				background: 'https://assets.mydna.company/iconoCard_ingles.svg',
				text: cardSectionTranslate.textCultureReport,
				video: 'https://www.youtube.com/embed/BH0Tsd5nf0o',
				file: 'https://assets.mydna.company/Reporte_Analisis_de_Cultura-Reporte_de_cultura.pdf',
				stars: 5 + '.' + 0,
				modal: <ReporteCultura />,
			},
			{
				title: cardSectionTranslate.culturalFitEmp,
				background: 'https://assets.mydna.company/iconoCard_seleccion-de-personas.svg',
				text: cardSectionTranslate.textCulturalFitEmp,
				video: 'https://www.youtube.com/embed/BH0Tsd5nf0o',
				file: 'https://assets.mydna.company/Reporte_Analisis_de_Cultura-Fit_cultural_candidatos.pdf',
				stars: 5 + '.' + 0,
				modal: <FitCulturalCandidados />,
			},
			{
				title: cardSectionTranslate.culturalFitCol,
				background: 'https://assets.mydna.company/iconoCard_talent-search.svg',
				text: cardSectionTranslate.textCulturalFitCol,
				video: 'https://www.youtube.com/embed/BH0Tsd5nf0o',
				file: 'https://assets.mydna.company/Reporte_Analisis_de_Cultura-Fit_cultural_colaboradores.pdf',
				stars: 5 + '.' + 0,
				modal: <FitCulturalColaboradores />,
			},
			{
				title: cardSectionTranslate.culturalTransformation,
				background: 'https://assets.mydna.company/iconoCard_talent-search.svg',
				text: cardSectionTranslate.textCulturalTransformation,
				video: 'https://www.youtube.com/embed/BH0Tsd5nf0o',
				file: 'https://assets.mydna.company/Reporte_Analisis_de_Cultura-Fit_cultural_colaboradores.pdf',
				stars: 5 + '.' + 0,
				modal: <FitCulturalColaboradores />,
			},
		],
		[
			{
				title: cardSectionTranslate.performanceAnalysis,
				background: 'https://assets.mydna.company/iconoCard_coach-ejecutivo.svg',
				text: cardSectionTranslate.textPerformanceAnalysis,
				video: 'https://www.youtube.com/embed/aVfzDCoiW9o',
				file: '',
				stars: 4.3,
				modal: <Evaluaccion />,
			},
		],
		[
			{
				title: cardSectionTranslate.realTimeConsult,
				background: 'https://assets.mydna.company/iconoCard_estudio-desalarios-e-beneficios.svg',
				// background: "#5AC2AD",
				text: cardSectionTranslate.textRealTimeConsult,
				video: 'https://www.youtube.com/embed/C9KxLMFzYgc',
				file: 'https://assets.mydna.company/Reporte_Salario_&_beneficios-Estudio_de_Salario_&_beneficios.pdf',
				stars: 5 + '.' + 0,
				modal: <SalariosYBeneficios />,
			},
			{
				title: cardSectionTranslate.customStudy,
				background: 'https://assets.mydna.company/iconoCard_estudio-desalarios-e-beneficios.svg',
				// background: "#5AC2AD",
				text: cardSectionTranslate.textCustomStudy,
				video: 'https://www.youtube.com/embed/C9KxLMFzYgc',
				file: '',
				stars: 5 + '.' + 0,
				modal: <SalariosYBeneficios />,
			},
			{
				title: cardSectionTranslate.variableSalary,
				background: 'https://assets.mydna.company/iconoCard_estudio-desalarios-e-beneficios.svg',
				// background: "#5AC2AD",
				text: cardSectionTranslate.textVariableSalary,
				video: 'https://www.youtube.com/embed/C9KxLMFzYgc',
				file: '',
				stars: 5 + '.' + 0,
				modal: <SalariosYBeneficios />,
			},
			{
				title: cardSectionTranslate.salaryGuide,
				background: 'https://assets.mydna.company/iconoCard_estudio-desalarios-e-beneficios.svg',
				// background: "#5AC2AD",
				text: cardSectionTranslate.textSalaryGuide,
				video: 'https://www.youtube.com/embed/C9KxLMFzYgc',
				file: 'https://assets.mydna.company/Reporte_Salario_&_beneficios-GuíaSalarial2022_PayBalance_ES.pdf',
				stars: 5 + '.' + 0,
				modal: <SalariosYBeneficios />,
			},
		],
		[
			{
				title: cardSectionTranslate.motivacionalTest,
				background: 'https://assets.mydna.company/iconoCard_coach-ejecutivo.svg',
				text: cardSectionTranslate.textMotivacionalTest,
				video: 'https://www.youtube.com/embed/-NjQQsL6j3E',
				file: 'https://assets.mydna.company/Reporte_Evaluacion-Test_Motivacion-Myco_Impulse.pdf',
				stars: 4.9,
				modal: <Evaluaccion />,
			},
			{
				title: cardSectionTranslate.skillTest,
				background: 'https://assets.mydna.company/iconoCard_capacitacion-de-personas.svg',
				text: cardSectionTranslate.textSkillTest,
				video: 'https://www.youtube.com/embed/-NjQQsL6j3E',
				file: 'https://assets.mydna.company/Reporte_Evaluacion-Test_Competencias-Myco_Effectiveness.pdf',
				stars: 4.8,
				modal: <Evaluaccion />,
			},
			{
				title: cardSectionTranslate.englishLevel,
				background: 'https://assets.mydna.company/iconoCard_coach-ejecutivo.svg',
				text: cardSectionTranslate.textEnglishLevel,
				video: 'https://www.youtube.com/embed/_8KOnMhgrkk',
				file: '',
				stars: 4.7,
				modal: <Evaluaccion />,
			},
		],
		[
			{
				title: cardSectionTranslate.careerProjection,
				background: 'https://assets.mydna.company/iconoCard_proyeccion-de-carrera.svg',
				text: cardSectionTranslate.textCareerProjection,
				video: 'https://www.youtube.com/embed/aVfzDCoiW9o',
				file: 'https://assets.mydna.company/Reporte_Plan_de_Carrera-Proyeccion_de_Carrera.pdf',
				stars: 5 + '.' + 0,
				modal: <PlanDeCarrera />,
			},
			{
				title: cardSectionTranslate.salaryProjection,
				background: 'https://assets.mydna.company/iconoCard_proyeccion-salarial.svg',
				text: cardSectionTranslate.textSalaryProjection,
				video: 'https://www.youtube.com/embed/aVfzDCoiW9o',
				file: 'https://assets.mydna.company/Reporte_Plan_de_Carrera-Proyeccion_Salarial.pdf',
				stars: 4.6,
				modal: <PlanDeCarrera />,
			},
			{
				title: cardSectionTranslate.jobDescriptor,
				background: 'https://assets.mydna.company/iconoCard_proyeccion-salarial.svg',
				text: cardSectionTranslate.textJobDescriptor,
				video: 'https://www.youtube.com/embed/aVfzDCoiW9o',
				file: '',
				stars: 4.6,
				modal: <PlanDeCarrera />,
			},
		],
		[
			{
				title: cardSectionTranslate.topManagement,
				background: 'https://assets.mydna.company/iconoCard_plan-de-sucesion.svg',
				text: cardSectionTranslate.textTopManagement,
				video: 'https://www.youtube.com/embed/IE74rGrtIBo',
				file: 'https://assets.mydna.company/Reporte_Outplacement_es.pdf',
				stars: 5 + '.' + 0,
				modal: <Outplacement />,
			},
			{
				title: cardSectionTranslate.middleManagement,
				background: 'https://assets.mydna.company/iconoCard_induccion-de-personas.svg',
				text: cardSectionTranslate.textMiddleManagement,
				video: 'https://www.youtube.com/embed/IE74rGrtIBo',
				file: 'https://assets.mydna.company/Reporte_Outplacement_es.pdf',
				stars: 4.5,
				modal: <Outplacement />,
			},
			{
				title: cardSectionTranslate.backofficeOutput,
				background: 'https://assets.mydna.company/iconoCard_blue-collar.svg',
				text: cardSectionTranslate.textBackofficeOutput,
				video: 'https://www.youtube.com/embed/puIEMMcv_O4',
				file: 'https://assets.mydna.company/Reporte_Outplacement.pdf',
				stars: 4.1,
				modal: <Outplacement />,
			},
			{
				title: cardSectionTranslate.searchOutput,
				background: 'https://assets.mydna.company/iconoCard_blue-collar.svg',
				text: cardSectionTranslate.textSearchOutput,
				video: 'https://www.youtube.com/embed/puIEMMcv_O4',
				file: '',
				stars: 4.8,
				modal: <Outplacement />,
			},
		],
		[],
		[],
		[],
		[],
	]

	//return <div>{option}</div>

	return sections[option - 1].map((section, index) => <ConsultorCard key={index} option={section} />)
}
export default AccreditedCompanies
