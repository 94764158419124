import React, { useState, useEffect } from 'react'
import { wheelCollaboratorTranslate, personalInfoTranslate, employeeExperienceTranslate, modalAssignTranslate } from '../../../../i18n/translatedMessages'
import { translate } from '../../../../i18n/Library/industry_type'
import { AiOutlineDelete, AiOutlineCheckCircle } from 'react-icons/ai'
import { FormattedMessage, useIntl } from 'react-intl'
import { getIndustryTypeList } from '../../../../API/Lists/industryType'

const FormProfInfo = ({ data, index, handleChange, deleteProfInfo, updateProfInfo, handleSend }) => {
	const intl = useIntl()
	const language = intl.locale === 'PT-BR' ? 'pt' : 'es'
	const { id, company, job, industryTypeId, startMonth, startYear, endMonth, endYear, currentJob, functions, achievements } = data
	const [showDate, setShowDate] = useState(currentJob)
	const [industryList, setIndustryList] = useState([])

	const handleShowDate = () => {
		setShowDate(!showDate)
	}

	useEffect(() => {
		const requests = async () => {
			setIndustryList(await getIndustryTypeList())
		}
		requests()
	}, [])

	return (
		<>
			<div className='container formProfInfo-main'>
				<div className='row'>
					<div className='col-md-4'>
						<div className='mb-3'>
							<label htmlFor={`formProfInfo-input1-` + id} className='form-label'>
								{employeeExperienceTranslate.historicProfessionalEENameCompany}
							</label>
							<input type='text' className='form-control m-0' id={`formProfInfo-input1-` + id} placeholder='' name='company' value={company} onChange={e => handleChange(index, e)} />
						</div>
					</div>
					<div className='col-md-4'>
						<div className='mb-3'>
							<label htmlFor='formProfInfo-select1' className='form-label'>
								{employeeExperienceTranslate.historicProfessionalEEAreaCompany}
							</label>
							<select className='form-select rounded-pill select-iformation m-0' id='formProfInfo-select1' aria-label='Default select example' name='industryTypeId' value={industryTypeId} onChange={e => handleChange(index, e)}>
								<option value={0}>{intl.formatMessage({ id: 'historicProfessionalEEOption0' })}</option>
								{industryList.map((ind, key) => {
									return (
										<option key={key} value={ind.id}>
											{translate[language][ind.name]}
										</option>
									)
								})}
							</select>
						</div>
					</div>

					<div className='col-md-4'>
						<label htmlFor={`formProfInfo-input2-` + id} className='form-label'>
							{employeeExperienceTranslate.historicProfessionalEEJob}
						</label>
						<input type='text' className='form-control text-center input-iformation w-100 m-0' id={`formProfInfo-input2-` + id} placeholder='' aria-label='cargo' name='job' value={job} onChange={e => handleChange(index, e)} />
					</div>

					<div className='col-md-4'>
						<div className='row'>
							<label className='form-label'>{personalInfoTranslate.personalEntryDate}</label>
							<div className='col-6'>
								<select className='form-select select-iformation rounded-pill m-0' aria-label='Default select example' name='startMonth' value={startMonth} onChange={e => handleChange(index, e)}>
									<FormattedMessage id='EEMonth'>{msg => <option>{msg}</option>}</FormattedMessage>
									{Array.from({ length: 12 }).map((_, index) => {
										return (
											<option key={index} value={index + 1}>
												{index + 1}
											</option>
										)
									})}
								</select>
							</div>
							<div className='col-6'>
								<select className='form-select select-iformation rounded-pill m-0' aria-label='Default select example' name='startYear' value={startYear} onChange={e => handleChange(index, e)}>
									<FormattedMessage id='EEYear'>{msg => <option>{msg}</option>}</FormattedMessage>
									{Array.from({ length: 56 }).map((_, index) => {
										return (
											<option key={index} value={index + 1969}>
												{index + 1969}
											</option>
										)
									})}
								</select>
							</div>
						</div>
					</div>

					<div className='col-md-3 d-flex align-items-end'>
						<div className='form-check d-flex'>
							<label className='form-check-label' htmlFor={`formProfInfo-check-` + id}>
								{personalInfoTranslate.personalHastaFecha}
							</label>
							{currentJob && currentJob != null ? (
								<>
									<input className='form-check-input ms-2' type='checkbox' name='stillInProgress' id={`formProfInfo-check-` + id} onChange={e => handleChange(index, e)} onClick={handleShowDate} checked='checked' />
								</>
							) : (
								<>
									<input className='form-check-input ms-2' type='checkbox' name='stillInProgress' id={`formProfInfo-check-` + id} onChange={e => handleChange(index, e)} onClick={handleShowDate} />
								</>
							)}
						</div>
					</div>

					<div className='col-md-4'>
						<div className='row' style={{ display: showDate ? 'none' : '' }}>
							<label className='form-label'>{personalInfoTranslate.personalExitDate}</label>
							<div className='col-6'>
								<select className='form-select select-iformation rounded-pill m-0' aria-label='Default select example' name='endMonth' value={endMonth} onChange={e => handleChange(index, e)}>
									<FormattedMessage id='EEMonth'>{msg => <option>{msg}</option>}</FormattedMessage>
									{Array.from({ length: 12 }).map((_, index) => {
										return (
											<option key={index} value={index + 1}>
												{index + 1}
											</option>
										)
									})}
								</select>
							</div>
							<div className='col-6'>
								<select className='form-select select-iformation rounded-pill m-0' aria-label='Default select example' name='endYear' value={endYear} onChange={e => handleChange(index, e)}>
									<FormattedMessage id='EEYear'>{msg => <option>{msg}</option>}</FormattedMessage>
									{Array.from({ length: 56 }).map((_, index) => {
										return (
											<option key={index} value={index + 1969}>
												{index + 1969}
											</option>
										)
									})}
								</select>
							</div>
						</div>
					</div>
				</div>

				<div className='row pt-4'>
					<div className='col-md-6'>
						<div className='mb-3'>
							<label htmlFor={`functions-` + id} className='form-label'>
								{wheelCollaboratorTranslate.functionsTitle}
							</label>
							<textarea cols='10' rows='6' className='form-control m-0' id={`functions-` + id} placeholder='' name='functions' value={functions} onChange={e => handleChange(index, e)}></textarea>
						</div>
					</div>
					<div className='col-md-6'>
						<div className='mb-3'>
							<label htmlFor={`achievements-` + id} className='form-label'>
								{wheelCollaboratorTranslate.achievementsTitle}
							</label>
							<textarea cols='10' rows='6' className='form-control m-0' id={`achievements-` + id} placeholder='' name='achievements' value={achievements} onChange={e => handleChange(index, e)}></textarea>
						</div>
					</div>
				</div>

				<div className='row justify-content-end text-end pt-4'>
					<div className='col-auto'>
						<button data-expid={data.id} className='btn btn-saveinfo rounded-pill me-1 d-flex align-items-center' onClick={id ? e => updateProfInfo(index, e) : () => handleSend()}>
							{modalAssignTranslate.btnSave} <AiOutlineCheckCircle size={25} />
						</button>
					</div>
					<div className='col-auto'>
						<button data-expid={data.id} className='btn btn-delinfo rounded-pill d-flex align-items-center' onClick={e => deleteProfInfo(index, e)}>
							Eliminar <AiOutlineDelete size={25} />
						</button>
					</div>
				</div>
			</div>
		</>
	)
}

export default FormProfInfo
