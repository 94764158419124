import "./input.css";

export const Input = ({
    label,
    placeholder,
    name,
    onChange,
    value,
    className,
    icon,
}) => {
    return (
        <div className={`paybalance-input d-flex w-100 ${className}`}>
            <label>{label}</label>
            {icon}
            <input
                className="input"
                type="text"
                name={name}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
            />
        </div>
    );
};
