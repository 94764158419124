import { useCallback, useEffect, useState } from 'react'
import { HiArrowNarrowRight, HiCheckCircle } from 'react-icons/hi'

import { GroupRadioButton } from '../GroupRadioButton/groupRadioButton'
import Loading from '../../Loading/Loading'

import Assessment from '../../../API/Assessment'

import { assessment } from '../../../i18n/translatedMessages.js'
import { questionsTranslate } from './questions'
import { useIntl } from 'react-intl'

export function AsksGroup({ page, setPage }) {
	const getLanguage = useIntl()
	const language = getLanguage.locale === 'PT-BR' ? 'pt' : 'es'

	const [questions, setQuestions] = useState([])
	const [answers, setAnswers] = useState([])
	const [loading, setLoading] = useState(false)

	const loadQuestions = useCallback(async () => {
		try {
			setLoading(true)
			const response = await Assessment.getQuestions(page)
			setQuestions(response)
		} catch {
		} finally {
			setLoading(false)
		}

		return () => {}
	}, [page])

	useEffect(() => {
		loadQuestions()
	}, [loadQuestions])

	const handleNext = async () => {
		try {
			setLoading(true)
			const user = JSON.parse(localStorage.getItem('user')).id

			if (answers.length === 5) {
				await Assessment.sendAnswers({
					employeeId: user,
					answers,
				})
				setAnswers([])
				setPage(previousPage => previousPage + 1)
			}
		} catch {
		} finally {
			setLoading(false)
		}
	}

	const handleChange = event => {
		setAnswers(prevAnswers => {
			const existingAnswerIndex = prevAnswers.findIndex(answer => answer.identifier === event.identifier)

			if (existingAnswerIndex !== -1) {
				const updatedAnswers = [...prevAnswers]
				updatedAnswers[existingAnswerIndex] = event
				return updatedAnswers
			} else {
				return [...prevAnswers, event]
			}
		})
	}

	if (loading) return <Loading />

	return (
		<>
			<div className='border border-grey' style={{ borderRadius: 12 }}>
				{questions.map(item => (
					<div key={item.identifier} className='border-bottom border-grey p-3'>
						<h5 className='text-center pb-3' style={{ fontSize: 18 }}>
							{questionsTranslate[item.identifier][language]}
						</h5>
						<GroupRadioButton onChange={handleChange} answer={item.identifier} />
					</div>
				))}
			</div>
			<div className='tw-flex tw-justify-between tw-items-center'>
				<div className='tw-w-[150px]'></div>
				<div className='tw-mt-4'>
					<Progress page={page} />
				</div>
				<button className='btn btn-primary rounded-pill tw-mt-4' onClick={handleNext}>
					{page < 16 && <span>{assessment.saveAndFollow}</span>}
					{page === 16 && <span>{assessment.conclude}</span>}
					<HiArrowNarrowRight size={18} className='ms-2' />
				</button>
			</div>
		</>
	)
}

const Progress = ({ page }) => {
	return (
		<div className='tw-flex tw-gap-3'>
			<span className='font-bold'>{assessment.progress}</span>
			<div className='tw-w-[240px] tw-h-[30px] tw-rounded-full tw-p-1 ' style={{ border: '1px solid #CBD5E1' }}>
				<div className='tw-bg-[#00A28B] tw-h-full tw-rounded-full' style={{ width: `${(100 / 16) * page}%` }}></div>
			</div>
			{page < 16 && (
				<div className='tw-text-gray-400'>
					<span className='tw-text-[#00A28B] tw-font-bold'>{page}</span>
					{assessment.steps}
				</div>
			)}
			{page === 16 && (
				<div className='tw-text-[#00A28B]'>
					<HiCheckCircle size={22} />
				</div>
			)}
		</div>
	)
}
