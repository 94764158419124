import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { EntrenamientoIcon, HistoricoProfessionalIcon, PlanCarreraIcon } from '../../Icons/CollaboratorsIcons'
import { careerPlanTranslate } from '../../i18n/translatedMessages'
import { getEmployers } from '../../API/Analytics/employers'
import EmptyInformation from './Components/EmptyInformation/EmptyInformation'
import HierarchicalLevel from './Components/HierarchicalLevel/HierarchicalLevel'

import './planCarrera.sass'
import HeaderCarouselMandala from './Components/HeaderCarouselMandala/HeaderCarouselMandala'
import { wheelCollaboratorTranslate } from '../../i18n/translatedMessages'
import Loading from '../../Components/Loading/Loading'

const PlanCarrera = () => {
	const { id } = useParams()
	const [employee, setEmployee] = useState(null)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		const company = JSON.parse(localStorage.getItem('user'))
		const fetchEmployers = async () => {
			setLoading(true)
			const response = await getEmployers(company.id)
			const [data] = response.filter(employee => employee?.id === Number(id))

			setEmployee({
				actualLevel: data.level.PositionN,
				nextLevel: data.level.PositionNPlus1,
				profileArea: data.area.profileArea,
				affineArea1: data.area.affineArea1,
				affineArea2: data.area.affineArea2,
			})
			setLoading(false)
		}
		fetchEmployers()
	}, [id])

	if (loading) {
		return <Loading />
	}

	return (
		<div className='content-plan-carrera plandecarreraAnalytics-main'>
			<div>
				<HeaderCarouselMandala
					actualTitle={wheelCollaboratorTranslate.careerPlan}
					prevTitle={wheelCollaboratorTranslate.training}
					routePrev={`/app/analytic/entrenamiento/${id}`}
					nextTitle={wheelCollaboratorTranslate.professionalHistoric}
					routeNext={`/app/analytic/historico-profesional/${id}`}
					iconActual={<PlanCarreraIcon />}
					iconPrev={<EntrenamientoIcon />}
					iconNext={<HistoricoProfessionalIcon />}
				/>
			</div>
			{employee && (
				<>
					<div className='body-nivel'>
						<div className='row card-career shadow'>
							<div className='col grid-container margin-card'>
								<div className='title-nivel align-nivel-title'>{careerPlanTranslate.hierarchicalLevel}</div>
								<div className='content-nivel'>
									<div className='text-right'>
										<div>
											{employee.nextLevel === 'Director' ? careerPlanTranslate.nextLevel : ''} {employee.actualLevel === 'Director' ? careerPlanTranslate.actualLevel : '-'}
										</div>
										<div>
											{employee.nextLevel === 'Gerentes' ? careerPlanTranslate.nextLevel : ''} {employee.actualLevel === 'Gerentes' ? careerPlanTranslate.actualLevel : '-'}
										</div>
										<div>
											{employee.nextLevel === 'Subgerentes' ? careerPlanTranslate.nextLevel : ''} {employee.actualLevel === 'Subgerentes' ? careerPlanTranslate.actualLevel : '-'}
										</div>
										<div>
											{employee.nextLevel === 'Jefes' ? careerPlanTranslate.nextLevel : ''} {employee.actualLevel === 'Jefes' ? careerPlanTranslate.actualLevel : '-'}
										</div>
										<div>
											{employee.nextLevel === 'Especialistas / Coordinadores' ? careerPlanTranslate.nextLevel : ''} {employee.actualLevel === 'Especialistas / Coordinadores' ? careerPlanTranslate.actualLevel : '-'}
										</div>
										<div>
											{employee.nextLevel === 'Analista' ? careerPlanTranslate.nextLevel : ''} {employee.actualLevel === 'Analista' ? careerPlanTranslate.actualLevel : '-'}
										</div>
										<div>{employee.actualLevel === 'Operário / Técnico / Asistente' ? careerPlanTranslate.actualLevel : '-'}</div>
									</div>
									<HierarchicalLevel actualLevel={employee.actualLevel} nextLevel={employee.nextLevel} />
								</div>
							</div>
							<div className='col grid-container margin-card'>
								<div className='title-nivel'>{careerPlanTranslate.possibleAreas}</div>
								<div className='informative-body-text'>
									{careerPlanTranslate.collaboratorTextP1} <strong>{employee.profileArea.toUpperCase()}</strong>, {careerPlanTranslate.collaboratorTextP2}:
								</div>
								<div className='possible-areas'>{employee.affineArea1}</div>
								<div className='possible-areas'>{employee.affineArea2}</div>
							</div>
						</div>
					</div>
				</>
			)}
			{!employee && <EmptyInformation />}
		</div>
	)
}
export default PlanCarrera
