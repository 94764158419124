import { jobDescriptorTranslate } from '../../../i18n/translatedMessages'
import './modal.sass'

export function ModalNoDescriptor({ show, handleShow, handleSend }) {
	return (
		show && (
			<div className='overlayer'>
				<div className='modal-body-success' style={{ width: 546, height: 405 }}>
					<div className='row'>
						<div className='col p-4 text-center'>
							<h3 className='fw-bold'>{jobDescriptorTranslate.EEjobDescriptorNullTitle}</h3>
						</div>
					</div>
					<div className='row'>
						<div className='col'>
							<p>
								<span className='fw-bold'>{jobDescriptorTranslate.EEjobDescriptorNullText} </span>
								{jobDescriptorTranslate.EEjobDescriptorNullText2}.
							</p>
						</div>
					</div>
					<div className='row mt-4'>
						<div className='col'>
							<button
								className='btn btn-primary rounded-pill w-100'
								onClick={() => {
									handleSend()
									handleShow(false)
								}}
							>
								{jobDescriptorTranslate.EEjobDescriptorNullRegister}
							</button>
							<button className='btn btn-outline-primary rounded-pill w-100 mt-2' onClick={() => handleShow(false)}>
								{jobDescriptorTranslate.EEjobDescriptorBack}
							</button>
						</div>
					</div>
				</div>
			</div>
		)
	)
}
