import { BsFillChatDotsFill } from 'react-icons/bs';
import { ombudsmanTranslate } from '../../../../i18n/translatedMessages';

import './ombudsman.sass';

const Ombudsman = () => (
  <div className="row">
    <div className="col d-flex justify-content-center offset-1">
      {/* <div className="empty">
                <BsFillChatDotsFill size={145} />
                <h4>{ombudsmanTranslate.noOccurrences}</h4>
            </div> */}
      <div className="container ombudsman-main">
        <div className="row">
          <div className="col-md-1">
            <div className="header-icon">
              <img src="https://assets.mydna.company/reclutamiento_y_selection_white.svg" alt="" className="img-fluid" />
            </div>
          </div>
          <div className="col-md-4">
            <h1 className="mt-1">Ombudsman</h1>
          </div>
        </div>
        <div className="row mt-4 justify-content-center">
          <div className="col-md-12">
            <h5 className="m-0">{ombudsmanTranslate.ombudmanTextOne}</h5>
            <h5>{ombudsmanTranslate.ombudmanTextTwo}</h5>
          </div>
          <div className="col-md-4 mt-3">
            <a href='mailto:delivery@mydnadigital.com'>
                {ombudsmanTranslate.ombudsmanBtn}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);
export default Ombudsman;
