// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalComfirmDelete-main .modal-content {
  border-radius: 2rem;
  background-color: rgb(249, 249, 249);
}
.ModalComfirmDelete-main .btn {
  width: 200px;
}
.ModalComfirmDelete-main .btn-green {
  background-color: #0ab4c4;
}
.ModalComfirmDelete-main .btn-purple {
  background-color: #7A6BE8;
  color: white;
}
.ModalComfirmDelete-main .confirm-delete-btn-dark-grey {
  background-color: #9391A1 !important;
  color: white !important;
  border: none;
}
.ModalComfirmDelete-main .confirm-delete-btn-red {
  background-color: #ec6376 !important;
  color: white !important;
  border: none;
}
.ModalComfirmDelete-main .assign-mark-del {
  color: #ec6376 !important;
}`, "",{"version":3,"sources":["webpack://./src/Components/ModalConfirmDelete/ModalConfirmDelete.sass"],"names":[],"mappings":"AACI;EACI,mBAAA;EACA,oCAAA;AAAR;AAEI;EACI,YAAA;AAAR;AAEI;EACI,yBAAA;AAAR;AAEI;EACI,yBAAA;EACA,YAAA;AAAR;AAEI;EACI,oCAAA;EACA,uBAAA;EACA,YAAA;AAAR;AAEI;EACI,oCAAA;EACA,uBAAA;EACA,YAAA;AAAR;AAEI;EACI,yBAAA;AAAR","sourcesContent":[".ModalComfirmDelete-main\n    & .modal-content\n        border-radius: 2rem\n        background-color: rgb(249, 249, 249)\n\n    & .btn\n        width: 200px\n\n    & .btn-green\n        background-color: #0ab4c4\n\n    & .btn-purple\n        background-color: #7A6BE8\n        color: white\n\n    & .confirm-delete-btn-dark-grey\n        background-color: #9391A1 !important\n        color: white !important\n        border: none\n\n    & .confirm-delete-btn-red\n        background-color: #ec6376 !important\n        color: white !important\n        border: none\n\n    & .assign-mark-del\n        color: #ec6376 !important\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
