export const postEmailCompAndMotiv = async (employeeId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API}/emails/demo_request/employer/${employeeId}`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
    });

    return response.json();
  } catch (err) {
    console.error(err);
  }
};
