export const selectIndustry = async (pais) => {
    try {
        let country = "";
        switch (pais) {
            case "1":
                country = "CL";
                break;
            case "2":
                country = "AR";
                break;
            case "3":
                country = "PE";
                break;
            case "4":
                country = "BR";
                break;
            case "5":
                country = "CO";
                break;
            case "6":
                country = "MX";
                break;
            case "7":
                country = "EC";
                break;
            default:
                country = "CL";
        }
        const resp = await fetch(
            `https://smtm.co/v1/get_industries?authorization=19c073e1768a3cbf6e9d76390e640f3b&country=${country}`
        );
        const data = await resp.json();
        return data;
    } catch (error) {
        console.error(error);
    }
};

export const selectArea = async (pais, industry) => {
    try {
        let country = "";
        switch (pais) {
            case "1":
                country = "CL";
                break;
            case "2":
                country = "AR";
                break;
            case "3":
                country = "PE";
                break;
            case "4":
                country = "BR";
                break;
            case "5":
                country = "CO";
                break;
            case "6":
                country = "MX";
                break;
            case "7":
                country = "EC";
                break;
            default:
                country = "CL";
        }
        const resp = await fetch(
            `https://smtm.co/v1/get_areas?authorization=19c073e1768a3cbf6e9d76390e640f3b&country=${country}&industry_id=${industry}`
        );
        const data = await resp.json();
        return data;
    } catch (error) {
        console.error(error);
    }
};

export const selectPositions = async (pais, area, city) => {
    try {
        let country = "";
        switch (pais) {
            case "1":
                country = "CL";
                break;
            case "2":
                country = "AR";
                break;
            case "3":
                country = "PE";
                break;
            case "4":
                country = "BR";
                break;
            case "5":
                country = "CO";
                break;
            case "6":
                country = "MX";
                break;
            case "7":
                country = "EC";
                break;
            default:
                country = "CL";
        }
        //Esta es una nueva API que remplaza a la antigua. Trae solo las posiciones disponibles para esa ciudad en concreto
        const resp = await fetch(
                `https://smtm.co/v1/list_positions_by_city?authorization=19c073e1768a3cbf6e9d76390e640f3b&country=${country}&area_id=${area}&city_id=${city}`
        );
        const data = await resp.json();
        return data;
    } catch (error) {
        console.error(error);
    }
};

export const createReport = async (report, email, history) => {
    try {
        let country = "";
        let locale = "";
        switch (String(report.country_id)) {
            case "1":
                country = "CL";
                locale = "es-CL";
                break;
            case "2":
                country = "AR";
                locale = "es-AR";
                break;
            case "3":
                country = "PE";
                locale = "es-PE";
                break;
            case "4":
                country = "BR";
                locale = "pt-BR";
                break;
            case "5":
                country = "CO";
                locale = "es-CO";
                break;
            case "6":
                country = "MX";
                locale = "es-MX";
                break;
            case "7":
                country = "EC";
                locale = "es-EC";
                break;
            default:
                country = "CL";
                locale = "es-CL";
        }
        const body = {
            user: {
                email: email,
            },
        };
        let variable;
        if (report.variable_salary != "undefined") {
            variable = report.variable_salary;
        } else if (report.variable_salaries != "undefined") {
            variable = report.variable_salaries;
        }

        let job_fantasia;
        let area_fantasia;
        let reportFrom;
        if (report.job_name != "undefined" && report.job_name != "Sin data") {
            reportFrom = "payb";
            job_fantasia = report.job_name;
            area_fantasia = report.subArea;
        } else {
            reportFrom = "nopayb";
            job_fantasia = "Sin data";
            area_fantasia = "Sin data";
        }
        const resp = await fetch(
            `https://smtm.co/report?city_id=${report.city_id}&country_id=${
                report.country_id
            }&job_id=${report.job_id}&current_salary_aux=${
                report.salary
            }&variable_salaries=${parseInt(variable)}&industry_id=${
                report.industry_id
            }&english_level_id=${report.english_level_id}&company_size_id=${
                report.company_size_id
            }&seniority_id=${report.seniority_id}&qualification_id=${
                report.qualification_id
            }&country_code=${country}&locale=${locale}&payment_method=webpay&report_type=company&people_report=false&trans_areas=%C3%81rea%20de%20actuaci%C3%B3n&trans_jobs=Posici%C3%B3n&company_size_id_aux=${
                report.company_size_id
            }&current_salary=${report.current_salary}&area_id=${
                report.area_id
            }&language=native&reportfrom=${reportFrom}&job_fantasia=${job_fantasia}&area_fantasia=${area_fantasia}`,
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(body),
            }
        );
        const data = await resp.json();
        if (resp.status === 200) {
            if (data.hasOwnProperty("modal")) {
                return resp;
            } else {
                return resp.status;
            }
        }
    } catch (error) {
        console.error(error);
    }
};

export const selectCity = async (country_id) => {
    let pais = country_id ? country_id : "1"
    try {
      const resp = await fetch(
          `https://smtm.co/v1/select_city?country_id=${pais}`
      );
      const data = await resp.json();
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  };