// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-cultura .card-bars {
  list-style: none;
  margin: 0 10px;
  text-align: center;
}
.content-cultura .title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.content-cultura .title .icon {
  background: linear-gradient(45deg, #40c2ff 0%, #32d8d8 100%);
  width: 50px;
  height: 50px;
  border-radius: 50rem;
  padding: 8px;
  margin: 0 10px;
}
.content-cultura .title .icon svg {
  fill: white;
  width: 100%;
}
.content-cultura .cultura-perfil {
  margin-top: 50px;
}
.content-cultura .cultura-perfil ul {
  padding: 0;
  margin-top: 20px;
}
.content-cultura .cultura-perfil ul li {
  list-style: none;
  margin: 0 10px;
  text-align: center;
}
.content-cultura .match {
  margin: 50px 0;
}`, "",{"version":3,"sources":["webpack://./src/Views/Analytics/cultura.sass"],"names":[],"mappings":"AACI;EACI,gBAAA;EACA,cAAA;EACA,kBAAA;AAAR;AAEI;EACI,aAAA;EACA,mBAAA;EACA,2BAAA;AAAR;AAEQ;EACI,4DAAA;EACA,WAAA;EACA,YAAA;EACA,oBAAA;EACA,YAAA;EACA,cAAA;AAAZ;AAEY;EACI,WAAA;EACA,WAAA;AAAhB;AAEI;EACI,gBAAA;AAAR;AAEQ;EACI,UAAA;EACA,gBAAA;AAAZ;AACY;EACI,gBAAA;EACA,cAAA;EACA,kBAAA;AAChB;AACI;EACI,cAAA;AACR","sourcesContent":[".content-cultura\n    & .card-bars\n        list-style: none\n        margin: 0 10px\n        text-align: center\n        \n    & .title\n        display: flex\n        align-items: center\n        justify-content: flex-start\n\n        & .icon\n            background: linear-gradient(45deg,#40c2ff 0%, #32d8d8 100%)\n            width: 50px\n            height: 50px\n            border-radius: 50rem\n            padding: 8px\n            margin: 0 10px\n\n            & svg\n                fill: white\n                width: 100%\n\n    & .cultura-perfil\n        margin-top: 50px\n\n        & ul\n            padding: 0\n            margin-top: 20px\n            & li\n                list-style: none\n                margin: 0 10px\n                text-align: center\n\n    & .match\n        margin: 50px 0\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
