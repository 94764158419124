import { useState } from "react";
import { HiInformationCircle } from "react-icons/hi2";

export const Tooltip = () => {
    const [show, setShow] = useState(false);

    const handleHide = () => {
        setShow(false);
    };

    const handleShow = () => {
        setShow(true);
    };

    return (
        <div className="position-relative text-primary d-flex justify-content-center">
            <HiInformationCircle
                size={22}
                onMouseEnter={handleShow}
                onMouseLeave={handleHide}
            />
            {show && (
                <div
                    className="position-absolute d-flex flex-column align-items-center"
                    style={{
                        width: 350,
                        zIndex: 9999,
                        marginTop: 22,
                    }}
                >
                    <div
                        style={{
                            width: 15,
                            height: 15,
                            transform: "translateY(7px) rotate(45deg)",
                        }}
                        className="bg-black"
                    ></div>
                    <div className="bg-black text-white rounded-3 p-3 fs-6">
                        <p>
                            Para definir la MEDIANA de la Banda, tienes 2 opciones:
                        </p>
                        <ul
                            className="flex-column p-3 m-0"
                            style={{ listStyleType: "decimal" }}
                        >
                            <li>
                                Banda MyDNA :  usamos el sueldo de mercado calculado por MyDNA como punto medio
                            </li>
                            <li className="mt-3">
                                Banda Mi Empresa : En caso de que ya tengas definido y estés trabajando con una banda específica  de tu organización, puedes registrar los niveles de banda y asociarlos a sus colaboradores en el espacio en blanco que aparece
                            </li>
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};
