import { DefaultPattern } from '../../../i18n/translatedMessages'
import './input.sass'

export function Input({ onChange, label, name, value, placeholder = '', error = false, required = false, disabled }) {
	return (
		<div className='new-employee-input'>
			{error && <span className='text-negative-red small'>{DefaultPattern.inputRequired}</span>}
			<input type='text' placeholder={placeholder} onChange={onChange} defaultValue={value} name={name} className={error ? 'error' : required ? 'required' : 'no-required'} disabled={disabled} />
			<label htmlFor=''>
				{label} {required && <span className='text-primary'>*</span>}
			</label>
		</div>
	)
}
