import React from "react";
import ListadoReportes from "./Components/ListadoReportes";
import StepsReport from "./Components/StepsReport";
import { unitReportTranslate } from "../../../../i18n/translatedMessages";

const UnitReport = () => {
	return (
		<div className="ms-5 px-5">
			<div className="row">
				<div className="col-12">
					<div className="d-flex align-items-center ml-5">
						<img
							className="custom-icon-guia-salarial"
							src="https://assets.mydna.company/b2b-images/pb/img/icons/azulesnumericos/Iconos-08.svg"
							alt="Icono Beneficios MyDNA"
							style={{ width: 100 }}
						/>
						<div className="d-flex flex-column px-2">
							<h3 className="content-wrapper-title mt-4">
								{unitReportTranslate.unitReportTitle}
							</h3>
							<h5 className="content-wrapper-subtitle mb-3">
								{unitReportTranslate.unitSectionDescription}
							</h5>
						</div>
					</div>
				</div>
			</div>

			<div className="px-5">
				<div>
					<StepsReport />
				</div>
				<div className="mt-5">
					<ListadoReportes />
				</div>
			</div>
		</div>
	);
};

export default UnitReport;
