// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.missoluciones-main .header-icon {
  background: linear-gradient(45deg, #40c2ff 0%, #32d8d8 100%);
  width: 50px;
  height: 50px;
  border-radius: 50rem;
  padding: 8px;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.missoluciones-main .card-icon {
  background-color: #7A6BE8;
  width: 50px;
  height: 50px;
  border-radius: 50rem;
  padding: 8px;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.missoluciones-main .card-service {
  background-color: #F2F2F7;
  border-radius: 15px;
}`, "",{"version":3,"sources":["webpack://./src/Views/Services/Components/MisSoluciones/MisSoluciones.sass"],"names":[],"mappings":"AACI;EACI,4DAAA;EACA,WAAA;EACA,YAAA;EACA,oBAAA;EACA,YAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAAR;AACI;EACI,yBAAA;EACA,WAAA;EACA,YAAA;EACA,oBAAA;EACA,YAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;AACR;AAAI;EACI,yBAAA;EACA,mBAAA;AAER","sourcesContent":[".missoluciones-main\n    .header-icon\n        background: linear-gradient(45deg, #40c2ff 0%, #32d8d8 100%)\n        width: 50px\n        height: 50px\n        border-radius: 50rem\n        padding: 8px\n        margin: 0 10px\n        display: flex\n        align-items: center\n        justify-content: center\n    .card-icon\n        background-color: #7A6BE8\n        width: 50px\n        height: 50px\n        border-radius: 50rem\n        padding: 8px\n        margin: 0 10px\n        display: flex\n        align-items: center\n        justify-content: center\n        color: white\n    .card-service\n        background-color: #F2F2F7\n        border-radius: 15px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
