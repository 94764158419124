import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { MdArrowDropDown } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import { mainMenuTextsTranslate } from '../../i18n/translatedMessages'

const DropdownMenu = () => {
	const image = localStorage.getItem('image') || 'https://assets.mydna.company/profile-placeholder.png?1654276597528'
	const history = useHistory()
	const dropdownRef = useRef(null)

	const [show, setShow] = useState(false)
	const [user, setUser] = useState({})

	useEffect(() => {
		setUser(JSON.parse(localStorage.getItem('user')))
	}, [])

	useEffect(() => {
		function handleClickOutside(event) {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setShow(false)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [dropdownRef])

	const openDropdown = () => {
		setShow(!show)
	}

	const handleLogout = () => {
		localStorage.clear()
		history.push('/')
	}

	return (
		<div className='tw-shadow-md tw-relative tw-flex tw-flex-col tw-cursor-pointer tw-items-center tw-mr-10' ref={dropdownRef} onClick={openDropdown}>
			<div className='tw-flex tw-items-center'>
				<div className='tw-rounded-full  tw-w-[32px] tw-h-[32px] tw-bg-white tw-overflow-hidden'>
					<img src={image} alt='' className='tw-w-full tw-h-full' />
				</div>
				<MdArrowDropDown size={20} />
			</div>
			{show && (
				<div className='tw-absolute tw-py-4 tw-right-0 tw-top-[50px] tw-bg-[#444258]'>
					<ul className='tw-list-none tw-m-0 tw-p-0 tw-text-white'>
						{user && (
							<li className='tw-w-full tw-font-bold tw-px-4 tw-pb-2 tw-mb-3' style={{ borderBottom: '1px solid #747b83', overflow: 'hidden', whiteSpace: 'nowrap' }}>
								{user?.name}
							</li>
						)}
						<li className='hover:tw-bg-white/25  tw-cursor-pointer tw-flex '>
							<Link to='/employee/edit-profile' className='tw-px-4 tw-py-1 tw-w-full tw-h-full tw-text-white hover:tw-text-white'>
								{mainMenuTextsTranslate.dropdownProfile}
							</Link>
						</li>
						<li className='hover:tw-bg-white/25 tw-px-4 tw-py-1 tw-cursor-pointer' onClick={handleLogout}>
							{mainMenuTextsTranslate.dropdownLogout}
						</li>
					</ul>
				</div>
			)}
		</div>
	)
}
export default DropdownMenu
