import { employeeExperienceTranslate } from '../../../../i18n/translatedMessages';

export const askes = [
  {
    id: 1,
    type: 'cultural',
    question: employeeExperienceTranslate.cultureQuestionEE1,
  },
  {
    id: 2,
    type: 'cultural',
    question: employeeExperienceTranslate.cultureQuestionEE2,
  },
  {
    id: 3,
    type: 'cultural',
    question: employeeExperienceTranslate.cultureQuestionEE3,
  },
  {
    id: 4,
    type: 'cultural',
    question: employeeExperienceTranslate.cultureQuestionEE4,
  },
  {
    id: 5,
    type: 'cultural',
    question: employeeExperienceTranslate.cultureQuestionEE5,
  },
  {
    id: 6,
    type: 'cultural',
    question: employeeExperienceTranslate.cultureQuestionEE6,
  },
  {
    id: 7,
    type: 'carrera',
    question: employeeExperienceTranslate.cultureQuestionEE7,
  },
  {
    id: 8,
    type: 'carrera',
    question: employeeExperienceTranslate.cultureQuestionEE8,
  },
  {
    id: 9,
    type: 'carrera',
    question: employeeExperienceTranslate.cultureQuestionEE9,
  },
  {
    id: 10,
    type: 'carrera',
    question: employeeExperienceTranslate.cultureQuestionEE10,
  },
  {
    id: 11,
    type: 'carrera',
    question: employeeExperienceTranslate.cultureQuestionEE11,
  },
  {
    id: 12,
    type: 'carrera',
    question: employeeExperienceTranslate.cultureQuestionEE12,
  },
  {
    id: 13,
    type: 'liderazgo',
    question: employeeExperienceTranslate.cultureQuestionEE13,
  },
  {
    id: 14,
    type: 'liderazgo',
    question: employeeExperienceTranslate.cultureQuestionEE14,
  },
  {
    id: 15,
    type: 'liderazgo',
    question: employeeExperienceTranslate.cultureQuestionEE15,
  },
  {
    id: 16,
    type: 'liderazgo',
    question: employeeExperienceTranslate.cultureQuestionEE16,
  },
  {
    id: 17,
    type: 'liderazgo',
    question: employeeExperienceTranslate.cultureQuestionEE17,
  },
  {
    id: 18,
    type: 'liderazgo',
    question: employeeExperienceTranslate.cultureQuestionEE18,
  },
  {
    id: 19,
    type: 'interese',
    question: employeeExperienceTranslate.cultureQuestionEE19,
  },
  {
    id: 20,
    type: 'interese',
    question: employeeExperienceTranslate.cultureQuestionEE20,
  },
  {
    id: 21,
    type: 'interese',
    question: employeeExperienceTranslate.cultureQuestionEE21,
  },
  {
    id: 22,
    type: 'interese',
    question: employeeExperienceTranslate.cultureQuestionEE22,
  },
  {
    id: 23,
    type: 'interese',
    question: employeeExperienceTranslate.cultureQuestionEE23,
  },
  {
    id: 24,
    type: 'interese',
    question: employeeExperienceTranslate.cultureQuestionEE24,
  },
];
export const askespt = [
  {
    id: 1,
    type: 'cultural',
    question: 'Haja um bom equilíbrio de homens e mulheres em cargos gerenciais.',
  },
  { id: 2, type: 'cultural', question: 'Vestirse formalmente no trabalho.' },
  {
    id: 3,
    type: 'cultural',
    question: 'Façam reciclagem e uso inteligente dos recursos da empresa.',
  },
  {
    id: 4,
    type: 'cultural',
    question: 'Haja tolerância absoluta à orientação sexual de meus colegas e gerentes.',
  },
  {
    id: 5,
    type: 'cultural',
    question: 'Estar em um ambiente inovador e não extremamente tradicional.',
  },
  {
    id: 6,
    type: 'cultural',
    question: 'Telefones e e-mails para fazer denuncias confidenciais.',
  },
  {
    id: 7,
    type: 'carrera',
    question: 'Ser competitivo para ter sucesso no trabalho.',
  },
  {
    id: 8,
    type: 'carrera',
    question: 'Que o foco seja o desenvolvimento profissional, e não tanto o salário.',
  },
  { id: 9, type: 'carrera', question: 'Ser promovido em pouco tempo.' },
  {
    id: 10,
    type: 'carrera',
    question: 'Ter atenção no que você diz, como se expressa e ser politicamente correto em sua opinião.',
  },
  {
    id: 11,
    type: 'carrera',
    question: 'Esconder tatuagens e cuidar da aparência física no trabalho para ter sucesso.',
  },
  {
    id: 12,
    type: 'carrera',
    question: 'Que os chefes conversem com suas equipes sobre desenvolvimento de carreira.',
  },
  {
    id: 13,
    type: 'liderazgo',
    question: 'Diretores ou Gerentes tenham vagas de estacionamento privilegiadas.',
  },
  {
    id: 14,
    type: 'liderazgo',
    question: 'A comunicação com os chefes possa ser difícil às vezes.',
  },
  {
    id: 15,
    type: 'liderazgo',
    question: 'O Gerente Geral seja próximo de algumas pessoas e não de outras.',
  },
  {
    id: 16,
    type: 'liderazgo',
    question: 'A liderança seja o mais importante.',
  },
  {
    id: 17,
    type: 'liderazgo',
    question: 'Uma equipe de alto desempenho, trabalhe mais.',
  },
  {
    id: 18,
    type: 'liderazgo',
    question: 'Que os chefes peçam a opinião de suas equipes sobre sua liderança e gestão.',
  },
  {
    id: 19,
    type: 'interese',
    question: 'Trabalhar em uma empresa que tenha sindicatos.',
  },
  { id: 20, type: 'interese', question: 'Trabalhar com horário flexível.' },
  { id: 21, type: 'interese', question: 'Trabalhar no modelo home-office.' },
  {
    id: 22,
    type: 'interese',
    question: 'Ter direito a dias livres adicionais.',
  },
  {
    id: 23,
    type: 'interese',
    question: 'Tenha os melhores benefícios para as mulheres no pré e pós natal.',
  },
  {
    id: 24,
    type: 'interese',
    question: 'Que a empresa apoie as atividades esportivas dos colaboradores.',
  },
];
