export const postVariableMultiplier = async (data, employeeid) => {
  try {
    const token = localStorage.getItem("accessToken");
    const tokenJson = JSON.parse(token);
    const resp = await fetch(
      `${process.env.REACT_APP_API}/variablesalary/employer/${employeeid}`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${tokenJson}`,
        },
        body: JSON.stringify(data),
      }
    );
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};

export const putVariableMultiplier = async (data, variableId) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_API}/variablesalary/${variableId}`,
      {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};

export const getVariableMultiplierByCompany = async (companyId) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_API}/variablesalary/company/${companyId}`
    );
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};
