import { FormattedMessage } from "react-intl";
import { MydnaLogoBastonetes } from "../../../../Icons/logo";
import "./emptyInformation.sass";

const EmptyInformation = () => (
    <div className="box-no-information">
        <FormattedMessage id={"incompleteTest"} />
        <MydnaLogoBastonetes />
    </div>
);

export default EmptyInformation;
