export const postClimate = async (data, employeeId, surveyType) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/employerclimate/${employeeId}`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({...data, surveyType}),
    });
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};

export const getClimate = async (employeeId, surveyType) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/employerclimate/${employeeId}?surveyType=${surveyType}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};

export const getClimateGroupMedia = async (companyId, surveyType) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/employerclimate/group/company/${companyId}?surveyType=${surveyType}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};
export const getClimateScore = async (companyId) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/employerclimate/score/company/${companyId}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};
