import axios from 'axios'

const authorization = "19c073e1768a3cbf6e9d76390e640f3b"

export const getAreas = async (country, id) => {
    const { data } = await axios.get(`https://smtm.co/v1/get_areas?authorization=${authorization}&country=${country}&industry_id=${id}`)
    return (data)
}

export const getSize = async (country) => {
    const { data } = await axios.get(`https://smtm.co/v1/get_company_sizes?authorization=${authorization}&country=${country}`)
    return (data)
}

export const getIndustry = async (country) => {
    const { data } = await axios.get(`https://smtm.co/v1/get_industries?authorization=${authorization}&country=${country}`)
    return (data)
}

export const getJob = async (country, id) => {
    const { data } = await axios.get(`https://smtm.co/v1/get_positions?authorization=${authorization}&country=${country}&area_id=${id}`)
    return (data)
}

export const getQualification = async (country) => {
    const { data } = await axios.get(`https://smtm.co/v1/get_qualifications?authorization=${authorization}&country=${country}`)
    return data
}

export const getSeniority = async (country) => {
    const { data } = await axios.get(`https://smtm.co/v1/get_seniorities?authorization=${authorization}&country=${country}`)
    return (data)

}

export const postData = async (form) => {

    const locale = () => {
        if (form.country === 'BR') {
            return "pt-BR"
        }

        return `es-${form.country}`
    }

    const city_id = () => {
        if (form.country === 'CL')
            return 1
        if (form.country === 'AR')
            return 2
        if (form.country === 'PE')
            return 3
        if (form.country === 'BR')
            return 4

        return 5
    }

    const { data } = await axios.post(`https://smtm.co/report?city_id=${city_id()}&country_id=${form.country_id}&job_id=${form.job}&current_salary_aux=${form.salary}&variable_salaries=${form.salary_variable}&industry_id=${form.industry}&english_level_id=${form.english_level}&company_size_id=${form.size}&seniority_id=${form.seniority}&qualification_id=${form.qualification}&country_code=${form.country}&locale=${locale()}&payment_method=webpay&report_type=company&mydna=mydna&people_report=false&trans_areas=Área de actuación&trans_jobs=Posición&company_size_id_aux=${form.size}&current_salary=${form.salary}&area_id=${form.area}&language=native`, {
        user: {
            name: form.name,
            email: form.email
        }
    })
    return (data)
}

export const detDataSmtmForEditEmployee = async (data) => {
    try {
        const url = `https://smtm.co/v1/position_by_mydna_email?email=${data.email}`;
        const response = await fetch(url);
        const json = await response.json();   
        return json;
    } catch (error) {
        throw error;
    }

}

