import { useCallback, useEffect, useState } from "react";
import { HiMagnifyingGlass } from "react-icons/hi2";

import { TriploCheck, DuploCheck } from "../CheckBox";
import { Input } from "../Input";
import { Select } from "../Select";
import Button from "react-bootstrap/Button";

import HierachicalLevelsMapper from "../../../../../../Utils/HierachicalLevelsMapper";

import Bands from "../../../../../../API/Services/PayBalance/Bands";
import { getHierarchicalLevelList } from "../../../../../../API/Lists/hierarchicalLevel";
import {
	clearSalaryCompanyMassively,
	postSalaryCompanyMassively,
} from "../../../../../../API/Services/Salary/SalaryCompany";
import Loading from "../../../../../../Components/Loading/Loading";

export const Collaborators = ({ countryId, band, handleClose }) => {
	const objEmpty = { id: 0, level: "Todos" };
	const [options, setOptions] = useState(null);
	const [employees, setEmployee] = useState([]);
	const [level, setLevel] = useState(null);
	const [search, setSearch] = useState("");

	const [loading, setLoading] = useState(false);

	const [selectAll, setSelectAll] = useState("none");

	const load = useCallback(async (level, search) => {
		try {
			const user = JSON.parse(localStorage.getItem("user")).id;
			setLoading(true);
			const response = await getHierarchicalLevelList();
			const responseEmployee = await Bands.getEmployees(
				user,
				countryId,
				1,
				10,
				level,
				search
			);
			setEmployee(
				responseEmployee.map((item) => ({
					...item,
					selected: item?.groupBand?.id === band.id ? true : false,
				}))
			);
			var arrAux = [
				HierachicalLevelsMapper.toDomain(objEmpty),
				...response.map(HierachicalLevelsMapper.toDomain),
			];

			setOptions(arrAux);
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		load();
	}, [load]);

	const handleChange = (event) => {
		setLevel(event.target.value);
	};
	const handleChangeSearch = (event) => {
		setSearch(event.target.value);
	};

	const toggleSelect = (itemId) => {
		setEmployee((prevItens) =>
			prevItens.map((item) =>
				item.id === itemId
					? { ...item, selected: !item.selected }
					: item
			)
		);
	};

	const toggleSelectAll = () => {
		if (selectAll === "none") {
			setSelectAll("all");
		} else {
			setSelectAll("none");
		}
		setEmployee((prevItens) =>
			prevItens.map((item) => ({
				...item,
				selected: selectAll === "none" ? true : false,
			}))
		);
	};

	useEffect(() => {
		if (selectAll === "all") {
			const data = employees.some((item) => item.selected === false);
			if (data) {
				setSelectAll("some");
			}
		}
		if (selectAll === "some") {
			const none = employees.some((item) => item.selected === true);
			const all = employees.some((item) => item.selected === false);
			if (!none) {
				setSelectAll("none");
			}
			if (!all) {
				setSelectAll("all");
			}
		}
	}, [employees, selectAll]);

	useEffect(() => {
		if (level && level?.id !== 0 && search.length > 3) {
			load(Number(level), search);
		} else if (level && level?.id !== 0) {
			load(Number(level), "");
		} else if (search.length > 3) {
			load("", search);
		} else {
			load();
		}
	}, [level, search]);

	const sendData = async () => {
		try {
			setLoading(true);
			const user = JSON.parse(localStorage.getItem("user")).id;
			const empFilt = employees.filter(
				(itemF) => itemF.selected === true
			);
			const empFiltFalse = employees.filter(
				(itemF) => itemF.selected === false
			);
			var objPostSend = [];
			for (const item of empFilt) {
				const objSendAux = {
					company_id: user,
					country_id: countryId,
					employer_id: item.id,
					company_salary_mean: band.band,
				};
				objPostSend.push(objSendAux);
			}
			var objClearSend = [];
			for (const item of empFiltFalse) {
				const objClearSendAux = {
					employer_id: item.id,
				};
				if (item?.groupBand?.id === band.id) {
					objClearSend.push(objClearSendAux);
				}
			}
			await postSalaryCompanyMassively(band.id, objPostSend);
			if (objClearSend.length > 0) {
				await clearSalaryCompanyMassively(user, objClearSend);
			}
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<div className="row">
				<div className="col">
					<h3 className="fw-bold">Lista de Colaboradores</h3>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<Input
						label="Nombre"
						placeholder="Buscar"
						icon={<HiMagnifyingGlass className="me-2" size={20} />}
						onChange={handleChangeSearch}
						value={search}
					/>
				</div>
				<div className="col">
					<Select
						placeholder="Seleccionar"
						label="Nivel Jerárquico"
						options={options}
						onChange={handleChange}
						value={level}
					/>
				</div>
			</div>
			<div className="row">
				<div className="col py-3">
					<div
						className="p-0 m-0 border border-bottom-0 overflow-hidden"
						style={{
							borderRadius: "15px 15px 0 0",
						}}
					>
						<div className="bg-light-grey d-flex px-3 py-2">
							<div className="col-1 text-primary">
								<TriploCheck
									onClick={toggleSelectAll}
									value={selectAll}
								/>
							</div>
							<div className="col fw-bold">Colaborador</div>
							<div className="col fw-bold">Cargo</div>
							<div className="col fw-bold">Banda Actual</div>
						</div>
					</div>
					<ul className="p-0 m-0 list-group rounded-0">
						{loading && <Loading size="sm" />}
						{!loading &&
							employees.map((employee) => (
								<li
									className="list-group-item d-flex"
									key={employee.id}
								>
									<div className="col-1 text-primary d-flex align-items-center">
										<DuploCheck
											onClick={() => {
												toggleSelect(employee.id);
											}}
											value={employee.selected}
										/>
									</div>
									<div className="col d-flex align-items-center">
										<div
											className="rounded-circle border border-dark-blue text-dark-blue bg-dark-blue bg-opacity-10 d-flex align-items-center justify-content-center fs-2"
											style={{
												width: 50,
												height: 50,
											}}
										>
											G
										</div>
										<span className="ms-3">
											{employee.name}
										</span>
									</div>
									<div className="col d-flex align-items-center">
										{employee.job_name}
									</div>
									<div className="col d-flex align-items-center">
										{employee.groupBand?.name || (
											<span className="text-dark-grey">
												--
											</span>
										)}
									</div>
								</li>
							))}
					</ul>
				</div>
			</div>
			<div className="d-flex justify-content-end mt-2">
				<Button
					onClick={handleClose}
					className="rounded-pill mx-2 text-black px-3"
					variant="outline-grey"
				>
					Cancelar
				</Button>
				<Button
					className="rounded-pill px-4"
					variant="primary"
					onClick={sendData}
				>
					Guardar cambios
				</Button>
			</div>
		</>
	);
};
