export const slidesContent = [
	{
		title:
		{
			es: 'Estas son las palabras clave sobre tu perfil que podrían describirte',
			pt: 'Estas são as palavras-chave sobre o seu perfil que poderiam descrevê-lo'
		},
		description:
		{
			es: 'Basado en tus respuestas, identificamos aquellas palabras que describirían las tendencias conductuales que más se manifiestan en tu perfil.',
			pt: 'Com base nas suas respostas, identificamos as palavras que descreveriam as tendências comportamentais mais evidentes no seu perfil.'
		},
	},
	{
		title:
		{
			es: 'Agrupamos las competencias evaluadas en 4 dimensiones',
			pt: 'Agrupamos as competências avaliadas em 4 dimensões'
		},
		description:
		{
			es: 'Esta sección te permite contar con una visión global de tus aspectos más destacados. Ten en cuenta que todas las características descriptas aquí no se manifiestan en simultáneo y con igual intensidad.',
			pt: 'Esta seção permite que você tenha uma visão global dos seus aspectos mais destacados. Tenha em mente que todas as características descritas aqui não se manifestam simultaneamente e com igual intensidade.'
		},
	},
	{
		title:
		{
			es: 'Veamos tus fortalezas y tus oportunidades de mejora',
			pt: 'Vamos ver suas forças e oportunidades de melhoria'
		},
		description:
		{
			es: 'Basado en tus respuestas, identificamos 3 competencias que son tus fortalezas y 3 competencias que son oportunidades de mejora.',
			pt: 'Com base nas suas respostas, identificamos 3 competências que são suas fortalezas e 3 competências que são oportunidades de melhoria.'
		},
	},
	{
		title:
		{
			es: 'Ahora sí, ¡Comienza tu desarrollo!',
			pt: 'Agora sim, comece o seu desenvolvimento!'
		},
		description:
		{
			es: 'Comenzarás trabajando en las 3 competencias donde tienes mayor área de oportunidad.',
			pt: 'Você começará trabalhando nas 3 competências onde tem maior área de oportunidade.'
		},
	},
];