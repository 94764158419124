// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contenedorIconoSalarioEmocional{
    width: 50px;
    height: 50px;
    padding: 5px;
    border-radius: 100%;
    background-color: #444258;
}

.iconoSalarioEmocional{
    width: 40px;
}`, "",{"version":3,"sources":["webpack://./src/Views/Services/Components/PayBalance/Views/css/SalarioEmocional.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,WAAW;AACf","sourcesContent":[".contenedorIconoSalarioEmocional{\n    width: 50px;\n    height: 50px;\n    padding: 5px;\n    border-radius: 100%;\n    background-color: #444258;\n}\n\n.iconoSalarioEmocional{\n    width: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
