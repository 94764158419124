// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.promoteScore-main {
  height: calc(100vh - 60px);
  overflow-y: scroll;
  overflow-x: hidden;
}
.promoteScore-main .icon {
  background: linear-gradient(45deg, #40c2ff 0%, #32d8d8 100%);
  width: 50px;
  height: 50px;
  border-radius: 50rem;
  padding: 8px;
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.promoteScore-main .icon svg {
  fill: white;
  width: 100%;
}
.promoteScore-main .color-red {
  color: #ec6376;
}
.promoteScore-main .color-blue {
  color: #0ab4c4;
}
.promoteScore-main .color-green {
  color: #bce165;
}
.promoteScore-main .color-purple {
  color: #7a6be8;
}
.promoteScore-main .bordered-red {
  border: 2px solid #ec6376 !important;
  background-color: white !important;
}
.promoteScore-main .bordered-purple {
  border: 2px solid #7a6be8 !important;
  background-color: white !important;
}
.promoteScore-main .bordered-blue {
  border: 2px solid #0ab4c4 !important;
  background-color: white !important;
}
.promoteScore-main .bg-red {
  background-color: #eb7e8c;
}
.promoteScore-main .bg-red:nth-child(1) {
  border-radius: 15px 0px 0px 15px;
}
.promoteScore-main .bg-purple {
  background-color: #9185e9;
}
.promoteScore-main .bg-blue {
  background-color: #49b9c3;
}
.promoteScore-main .bg-blue:nth-child(10) {
  border-radius: 0px 15px 15px 0px;
}`, "",{"version":3,"sources":["webpack://./src/Views/Services/Components/PromoterScore/PromoterScore.sass"],"names":[],"mappings":"AAAA;EACI,0BAAA;EACA,kBAAA;EACA,kBAAA;AACJ;AACI;EACI,4DAAA;EACA,WAAA;EACA,YAAA;EACA,oBAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;AACR;AACQ;EACI,WAAA;EACA,WAAA;AACZ;AACI;EACI,cAAA;AACR;AACI;EACI,cAAA;AACR;AACI;EACI,cAAA;AACR;AACI;EACI,cAAA;AACR;AACI;EACI,oCAAA;EACA,kCAAA;AACR;AACI;EACI,oCAAA;EACA,kCAAA;AACR;AACI;EACI,oCAAA;EACA,kCAAA;AACR;AACI;EACI,yBAAA;AACR;AACQ;EACI,gCAAA;AACZ;AACI;EACI,yBAAA;AACR;AACI;EACI,yBAAA;AACR;AACQ;EACI,gCAAA;AACZ","sourcesContent":[".promoteScore-main\n    height: calc(100vh - 60px)\n    overflow-y: scroll\n    overflow-x: hidden\n\n    & .icon\n        background: linear-gradient(45deg,#40c2ff 0%, #32d8d8 100%)\n        width: 50px\n        height: 50px\n        border-radius: 50rem\n        padding: 8px\n        margin-right: 10px\n        display: flex\n        align-items: center\n\n        & svg\n            fill: white\n            width: 100%\n\n    & .color-red\n        color: #ec6376\n\n    & .color-blue\n        color: #0ab4c4\n\n    & .color-green\n        color: #bce165\n\n    & .color-purple\n        color: #7a6be8\n\n    & .bordered-red\n        border: 2px solid #ec6376 !important\n        background-color: white !important\n\n    & .bordered-purple\n        border: 2px solid #7a6be8 !important\n        background-color: white !important\n\n    & .bordered-blue\n        border: 2px solid #0ab4c4 !important\n        background-color: white !important\n\n    & .bg-red\n        background-color: #eb7e8c\n\n        &:nth-child(1)\n            border-radius: 15px 0px 0px 15px\n\n    & .bg-purple\n        background-color: #9185e9\n\n    & .bg-blue\n        background-color: #49b9c3\n\n        &:nth-child(10)\n            border-radius: 0px 15px 15px 0px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
