import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { selectArea } from "../../Services/StepsReport";
import "./FilterComponent.css"; // Importa tu archivo CSS personalizado

const FilterComponent = ({ handleFilter, companyIdFromSMTM }) => {
	const language = useIntl();

	const [state, setState] = useState({
		country: "",
		areas: [],
		group: [],
	});

	const [allAreas, setAllAreas] = useState([]);
	const [countrySelected, setCountrySelected] = useState(false);
	const [userNamePosition, setUserNamePosition] = useState("");

	const [searchTimeout, setSearchTimeout] = useState(null);

const handleUserNamePositionChange = (value) => {
  setUserNamePosition(value);

  // Limpiar el temporizador anterior
  if (searchTimeout) {
    clearTimeout(searchTimeout);
  }
  fetchData();

};


	const handleCountryChange = async (event) => {
		setState({ ...state, country: event.target.value });
		let areas = await selectArea(event.target.value, 1); //cambiar industria por real
		setAllAreas(areas);
		setCountrySelected(true); // Seleccionar país
	};

	const handleAreaChange = (event) => {
		const selectedAreas = Array.from(
			event.target.selectedOptions,
			(option) => option.value
		);
		setState({ ...state, areas: selectedAreas });
	};

	const handleGroupChange = (event) => {
		const selectedGroups = Array.from(
			event.target.selectedOptions,
			(option) => option.value
		);
		setState({ ...state, group: selectedGroups });
	};

	const fetchData = () => {
		const { country, areas, group } = state;
		const queryParams = [];
		const queryParameters = new URLSearchParams(
			window.location.search
		);
		const levelFromDash = queryParameters.get("level");
		const areaFromDash = queryParameters.get("area");
		const countryFromDash = queryParameters.get("country");
	  
		if (country && country !== 'all') {
		  queryParams.push(`country_id=${encodeURIComponent(country)}`);
		}
	  
		if (areas.length > 0 && areas[0] !== "all") {
		  queryParams.push(`area_id=${encodeURIComponent(areas.join(","))}`);
		}
	  
		if (group.length > 0 && group[0] !== 'all') {
		  queryParams.push(`group=${encodeURIComponent(group.join(","))}`);
		}
	  
		if (userNamePosition) {
		  queryParams.push(`mydna_user_name_position=${encodeURIComponent(userNamePosition)}`);
		}
	  
		let apiUrl = `https://smtm.co/v1/position_companies?company_id=${companyIdFromSMTM}&${queryParams.join(
		  "&"
		)}`;

		if(queryParams.length <= 0 && group[0] !== "all" && areas[0] !== "all" && country !== "all") {
			if(levelFromDash) {
				apiUrl = `https://smtm.co/v1/position_companies?company_id=${companyIdFromSMTM}${queryParams.join(
					"&"
				)}&group=${levelFromDash}`;
			}
			if(countryFromDash && areaFromDash && areas[0] !== "all" && country !== "all" && group[0] !== "all") {
				apiUrl = `https://smtm.co/v1/position_companies?company_id=${companyIdFromSMTM}&${queryParams.join(
					"&"
				)}&country_id=${countryFromDash}&area_id=${areaFromDash}`
			}
		}
	  
		fetch(apiUrl)
		  .then((response) => response.json())
		  .then((data) => handleFilter(data))
		  .catch((error) => console.error(error));
	  };
	  
	  

	useEffect(() => {
		fetchData();
	}, [state]);
	useEffect(() => {
		if(state.country === "all"){
			setState({ ...state, areas: ['all'] });
		}
	}, [state.country]);

	return (
		<>
		<div className="row mx-5">
		<input
		type="text"
		id="userNamePosition"
		value={userNamePosition}
		placeholder={"Nombre"}
		onChange={(e) => handleUserNamePositionChange(e.target.value)}
	  />
	  
	  </div>
		<div className="d-flex mx-5">


			<div className="d-flex flex-column me-3 w-100">
				<label htmlFor="country">País</label>
				<select id="country" onChange={handleCountryChange} className="selectFiltros">
					<option value="all">Todos</option>
					<option>
						{language.locale == "ES"
							? "País de la posición del colaborador"
							: "País da posição do colaborador"}{" "}
					</option>
					<option value="1" data-countryname="Chile">
						Chile
					</option>
					<option value="2" data-countryname="Argentina">
						Argentina
					</option>
					<option value="3" data-countryname="Perú">
						Perú
					</option>
					<option value="4" data-countryname="Brasil">
						Brasil
					</option>
					<option value="5" data-countryname="Colombia">
						Colombia
					</option>
					<option value="6" data-countryname="Mexico">
						Mexico
					</option>
					<option value="7" data-countryname="Costa Rica">
						Costa Rica
					</option>
					{/* Agrega más países según sea necesario */}
				</select>
			</div>

			<div className="d-flex flex-column me-3 w-100">
				<label htmlFor="area">Area</label>
				<select
					id="area"
					onChange={handleAreaChange}
					disabled={!countrySelected || state.country=== "all" || !state.country === ''}
					className="selectFiltros"
					value={!countrySelected || state.country=== "all" || !state.country === '' ? "all" : state.areas[0]}
				>
					<option value="all">Todos</option>
					{allAreas.map((el, i) => {
						return <option value={el.value}>{el.name}</option>;
					})}
				</select>
			</div>
			<div className="d-flex flex-column w-100">
				<label htmlFor="group">Nivél</label>
				<select id="group" onChange={handleGroupChange} className="selectFiltros">
					<option value="all">Todos</option>
					<option value="Operario">Operario</option>
					<option value="Técnico">Técnico</option>
					<option value="Asistente">Asistente</option>
					<option value="Analista">Analista</option>
					<option value="Especialistas / Coordinadores">
						Especialistas / Coordinadores
					</option>
					<option value="Jefe">Jefe</option>
					<option value="Subgerentes">Subgerentes</option>
					<option value="Gerentes">Gerentes</option>
					<option value="Director">Director</option>
					<option value="Alta Administración">
						Alta Administración
					</option>
				</select>
			</div>
		</div>
		</>
	);
};

export default FilterComponent;
