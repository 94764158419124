export const getEmployeesExp = async (employeeId) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/employersexp/exp/${employeeId}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};

export const postEmployeesExp = async (data, employeeId) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/employersexp/exp/${employeeId}`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};
export const putEmployeesExp = async (data, employeeId) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/employersexp/exp/${employeeId}`, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return resp.json;
  } catch (err) {
    console.error(err);
  }
};

export const deleteEmployeesExp = async (expId) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/employersexp/${expId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};
