import { useIntl } from 'react-intl'
import { assessmentTranslate } from '../../../i18n/Library/assessment'

export function Board({ title, content, list, assest }) {
	const getLanguage = useIntl()
	const language = getLanguage.locale === 'PT-BR' ? 'pt' : 'es'
	return (
		<div className='tw-w-full tw-overflow-hidden tw-rounded-lg tw-shadow-lg'>
			<div className='tw-flex tw-justify-between tw-full tw-p-8' style={{ background: assest[content.dimension].color }}>
				<h4 className='tw-text-white tw-font-bold'>{title}</h4>
				<div className='tw-flex tw-space-x-5 tw-items-center'>
					<span className='tw-text-white tw-font-bold'>{assessmentTranslate[language][content.dimension]}</span>
					<div className='  tw-bg-white tw-flex tw-justify-center tw-items-center tw-rounded-full tw-p-2 tw-text-2xl' style={{ color: assest[content.dimension].color }}>
						{assest[content.dimension].icon}
					</div>
				</div>
			</div>
			<div className='tw-mt-4'>
				<ul className='tw-list-none tw-space-y-6 tw-px-5 tw-py-2 tw-m-0'>
					{list.map((item, index) => (
						<li className='tw-flex tw-gap-6' key={item.description}>
							<div
								className='tw-w-[30px] tw-h-[30px] tw-flex-none tw-place-items-center tw-text-md tw-font-bold tw-rounded-full tw-p-1'
								style={{ background: `${assest[content.dimension].color}33`, color: assest[content.dimension].color }}
							>
								<div className='tw-size-full tw-flex tw-items-center tw-justify-center tw-font-bold'>{index + 1}</div>
							</div>
							<div>
								<span className='tw-text-md tw-font-bold'>{item.description}</span>
								<p>{item.summary}</p>
							</div>
						</li>
					))}
				</ul>
			</div>
		</div>
	)
}
