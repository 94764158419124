import { useState } from "react";
import { MdStar, MdStarBorder } from "react-icons/md";
import TooltipStars from "../Tooltip/Tooltip";
import "./stars.sass";

const Stars = ({ note }) => {
  const [show, setShow] = useState(false);

  return (
    <div
      className="stars-notes"
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      {show && <TooltipStars />}
      <ul>
        <li data-note="1">
          {note >= 1 ? <MdStar size={15} /> : <MdStarBorder size={15} />}
        </li>
        <li data-note="2">
          {note >= 2 ? <MdStar size={15} /> : <MdStarBorder size={15} />}
        </li>
        <li data-note="3">
          {note >= 3 ? <MdStar size={15} /> : <MdStarBorder size={15} />}
        </li>
        <li data-note="4">
          {note >= 4 ? <MdStar size={15} /> : <MdStarBorder size={15} />}
        </li>
        <li data-note="5">
          {note >= 5 ? <MdStar size={15} /> : <MdStarBorder size={15} />}
        </li>
      </ul>
    </div>
  );
};
export default Stars;
