export const FileIcon = () =>
    <svg width="18" height="20" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            fillRule="evenodd" 
            clipRule="evenodd" 
            d="M1.5 0C0.671573 0 0 0.671573 0 1.5V14.5C0 15.3284 0.671573 16 1.5 16H12.5C13.3284 16 14 15.3284 14 14.5V5.62132C14 5.2235 13.842 4.84197 13.5607 4.56066L9.43934 0.43934C9.15804 0.158035 8.7765 0 8.37868 0H1.5ZM3.75 8.5C3.33579 8.5 3 8.83579 3 9.25C3 9.66421 3.33579 10 3.75 10H10.25C10.6642 10 11 9.66421 11 9.25C11 8.83579 10.6642 8.5 10.25 8.5H3.75ZM3.75 11.5C3.33579 11.5 3 11.8358 3 12.25C3 12.6642 3.33579 13 3.75 13H10.25C10.6642 13 11 12.6642 11 12.25C11 11.8358 10.6642 11.5 10.25 11.5H3.75Z" 
            fill="#8F95B2"
        />
    </svg>

export const LinkIcon = () =>
    <svg width="15" height="16" viewBox="0 1 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.02867 2.30863C7.65351 1.6838 8.66657 1.6838 9.29141 2.30863C9.91625 2.93347 9.91625 3.94654 9.29141 4.57138L8.50784 5.35495C8.32039 5.5424 8.32039 5.84632 8.50784 6.03377C8.69529 6.22122 8.99921 6.22122 9.18666 6.03377L9.97023 5.2502C10.97 4.25046 10.97 2.62955 9.97023 1.62981C8.97049 0.630069 7.34959 0.630069 6.34985 1.62981L4.42985 3.54981C3.43011 4.54955 3.43011 6.17045 4.42985 7.1702C4.47652 7.21686 4.52461 7.26141 4.574 7.30382C4.77513 7.47651 5.07817 7.45345 5.25086 7.25232C5.42356 7.0512 5.4005 6.74815 5.19937 6.57546C5.1684 6.54887 5.13814 6.52084 5.10867 6.49137C4.48383 5.86654 4.48383 4.85347 5.10867 4.22863L7.02867 2.30863Z" fill="#444258"/>
        <path d="M6.62609 4.69619C6.42496 4.52349 6.12191 4.54655 5.94922 4.74768C5.77653 4.94881 5.79958 5.25185 6.00071 5.42454C6.03168 5.45114 6.06194 5.47916 6.09141 5.50863C6.71625 6.13347 6.71625 7.14653 6.09141 7.77137L4.17141 9.69137C3.54657 10.3162 2.53351 10.3162 1.90867 9.69137C1.28383 9.06653 1.28383 8.05346 1.90867 7.42863L2.69224 6.64505C2.8797 6.4576 2.8797 6.15368 2.69224 5.96623C2.50479 5.77878 2.20087 5.77878 2.01342 5.96623L1.22985 6.7498C0.230106 7.74955 0.230106 9.37045 1.22985 10.3702C2.22959 11.3699 3.85049 11.3699 4.85023 10.3702L6.77023 8.45019C7.76998 7.45045 7.76998 5.82955 6.77023 4.8298C6.72357 4.78314 6.67548 4.73859 6.62609 4.69619Z" fill="#444258"/>
    </svg>

export const CheckIcon = () =>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            fillRule="evenodd" 
            clipRule="evenodd" 
            d="M2.25002 12C2.25002 6.61522 6.61524 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61524 21.75 2.25002 17.3848 2.25002 12ZM15.6103 10.1859C15.8511 9.84887 15.773 9.38046 15.4359 9.1397C15.0989 8.89894 14.6305 8.97701 14.3897 9.31407L11.1543 13.8436L9.53034 12.2197C9.23745 11.9268 8.76258 11.9268 8.46969 12.2197C8.17679 12.5126 8.17679 12.9874 8.46969 13.2803L10.7197 15.5303C10.8756 15.6862 11.0922 15.7656 11.3119 15.7474C11.5316 15.7293 11.7322 15.6153 11.8603 15.4359L15.6103 10.1859Z" 
            fill="#3EA528"
        />
    </svg>


