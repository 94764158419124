import { employeeExperienceTranslate } from "../../../../i18n/translatedMessages";

export const questions = [
    {
        title: employeeExperienceTranslate.climeQuestionsEETitle1,
        header: [
            employeeExperienceTranslate.climeQuestionsEEH1,
            employeeExperienceTranslate.climeQuestionsEEH2,
            employeeExperienceTranslate.climeQuestionsEEH3,
            employeeExperienceTranslate.climeQuestionsEEH4,
        ],
        data: [
            {
                id: 1,
                question: employeeExperienceTranslate.climeQuestionEE1,
                inputName: "question1",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 2,
                question: employeeExperienceTranslate.climeQuestionEE2,
                inputName: "question2",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 3,
                question: employeeExperienceTranslate.climeQuestionEE3,
                inputName: "question3",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 4,
                question: employeeExperienceTranslate.climeQuestionEE4,
                inputName: "question4",
                answers: ["1", "2", "3", "4"],
            },
        ],
    },
    {
        title: employeeExperienceTranslate.climeQuestionsEETitle2,
        header: [
            "Muy Insatisfecho",
            "Insatisfecho",
            "Satisfecho",
            "Muy Satisfecho",
        ],
        data: [
            {
                id: 5,
                question: employeeExperienceTranslate.climeQuestionEE5,
                inputName: "question5",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 6,
                question: employeeExperienceTranslate.climeQuestionEE6,
                inputName: "question6",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 7,
                question: employeeExperienceTranslate.climeQuestionEE7,
                inputName: "question7",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 8,
                question: employeeExperienceTranslate.climeQuestionEE8,
                inputName: "question8",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 9,
                question: employeeExperienceTranslate.climeQuestionEE9,
                inputName: "question9",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 10,
                question: employeeExperienceTranslate.climeQuestionEE10,
                inputName: "question10",
                answers: ["1", "2", "3", "4"],
            },
        ],
    },
    {
        title: employeeExperienceTranslate.climeQuestionsEETitle3,
        header: [
            "Muy Insatisfecho",
            "Insatisfecho",
            "Satisfecho",
            "Muy Satisfecho",
        ],
        data: [
            {
                id: 11,
                question: employeeExperienceTranslate.climeQuestionEE11,
                inputName: "question11",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 12,
                question: employeeExperienceTranslate.climeQuestionEE12,
                inputName: "question12",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 13,
                question: employeeExperienceTranslate.climeQuestionEE13,
                inputName: "question13",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 14,
                question: employeeExperienceTranslate.climeQuestionEE14,
                inputName: "question14",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 15,
                question: employeeExperienceTranslate.climeQuestionEE15,
                inputName: "question15",
                answers: ["1", "2", "3", "4"],
            },
        ],
    },
    {
        title: employeeExperienceTranslate.climeQuestionsEETitle4,
        header: [
            "Muy Insatisfecho",
            "Insatisfecho",
            "Satisfecho",
            "Muy Satisfecho",
        ],
        data: [
            {
                id: 16,
                question: employeeExperienceTranslate.climeQuestionEE16,
                inputName: "question16",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 17,
                question: employeeExperienceTranslate.climeQuestionEE17,
                inputName: "question17",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 18,
                question: employeeExperienceTranslate.climeQuestionEE18,
                inputName: "question18",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 19,
                question: employeeExperienceTranslate.climeQuestionEE19,
                inputName: "question19",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 20,
                question: employeeExperienceTranslate.climeQuestionEE20,
                inputName: "question20",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 21,
                question: employeeExperienceTranslate.climeQuestionEE21,
                inputName: "question21",
                answers: ["1", "2", "3", "4"],
            },
        ],
    },
    {
        title: employeeExperienceTranslate.climeQuestionsEETitle5,
        header: [
            "Muy Insatisfecho",
            "Insatisfecho",
            "Satisfecho",
            "Muy Satisfecho",
        ],
        data: [
            {
                id: 22,
                question: employeeExperienceTranslate.climeQuestionEE22,
                inputName: "question22",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 23,
                question: employeeExperienceTranslate.climeQuestionEE23,
                inputName: "question23",
                answers: ["1", "2", "3", "4"],
            },
            {
                id: 24,
                question: employeeExperienceTranslate.climeQuestionEE24,
                inputName: "question24",
                answers: ["1", "2", "3", "4"],
            },
        ],
    },
    {
        title: employeeExperienceTranslate.climeQuestionsEETitle6,
        header: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
        data: [
            {
                id: 25,
                question: employeeExperienceTranslate.climeQuestionEE25,
                inputName: "question25",
                answers: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
            },
        ],
    },
];
