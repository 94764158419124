import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

const ChartsMYCO = ({ labels, importData }) => {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            y: {
              min: 0,
              max: 10,
            }
          }
    };
    const data = {
        labels,
        datasets: [
            {
                fill: {
                    target: "origin",
                    above: "rgba(24, 219, 253, 0.281)", // Area will be red above the origin
                    below: "rgb(0, 119, 255)", // And blue below the origin
                },
                data: importData ? Object.values(importData) : "",
                borderColor: "#2B97DB",
                backgroundColor: "rgba(65, 200, 255)",
                tension: 0.3,
            },
        ],
    };
    return <Line options={options} data={data} />;
};

export default ChartsMYCO;
