export const translate = {
    es: {
        "Documento de identificación": "Documento de identificación",
        "Registro tributario o del contribuyente": "Registro tributario o del contribuyente",
        "Comprobante residencia": "Comprobante residencia",
        "Carné o constancia de Trabajo": "Carné o constancia de Trabajo",
        "Certificado de Situación Militar, de reservista, o de enrolamiento": "Certificado de Situación Militar, de reservista, o de enrolamiento",
        "Certificados de estudios secundarios": "Certificados de estudios secundarios",
        "Currículum vitae actualizado": "Currículum vitae actualizado",
        "Foto": "Foto",
        "Licencia de conducir": "Licencia de conducir",
        "Colegio, Matrícula, Cédula o Registro Profesionales": "Colegio, Matrícula, Cédula o Registro Profesionales",
        "Certificado de nacimiento o matrimonio": "Certificado de nacimiento o matrimonio",
        "Certificados de estudios Universitarios": "Certificados de estudios Universitarios",
        "Comprobante de experiencia profesional": "Comprobante de experiencia profesional",
        "Seguro laboral emitido por institución educativa para pasantías o prácticas": "Seguro laboral emitido por institución educativa para pasantías o prácticas",
        "Certificado de antecedentes": "Certificado de antecedentes",
        "Identificación de dependientes": "Identificación de dependientes",
        "Afiliación de salud": "Afiliación de salud",
        "Autorización de descuento por planilla o por nómina": "Autorización de descuento por planilla o por nómina",
        "Comprobante de registro Sindical": "Comprobante de registro Sindical",
        "Datos para depósito bancario": "Datos para depósito bancario",
        "Certificado de capacitación en seguridad y salud ocupacional": "Certificado de capacitación en seguridad y salud ocupacional",
        "Acuerdo de confidencialidad": "Acuerdo de confidencialidad",
        "Contrato de trabajo firmado": "Contrato de trabajo firmado",
        "Ficha de registro de empleado": "Ficha de registro de empleado",
        "Declaración de Responsabilidad": "Declaración de Responsabilidad",
        "Certificados de afiliación de fondo de pensión o de seguridad social": "Certificados de afiliación de fondo de pensión o de seguridad social",
        "Autorización para exámenes médicos de ingreso": "Autorización para exámenes médicos de ingreso",
        "Comprobante apertura cuenta bancaria": "Comprobante apertura cuenta bancaria",
        "Visa de trabajo (para extranjeros)": "Visa de trabajo (para extranjeros)",
    },
  
    pt: {
        "Documento de identificación": "Carteira de identidade",
        "Registro tributario o del contribuyente": "Cadastro de Pessoa Física",
        "Comprobante residencia": "Comprovante de residência",
        "Carné o constancia de Trabajo": "Carteira de trabalho",
        "Certificado de Situación Militar, de reservista, o de enrolamiento": "Certificado de reservista",
        "Certificados de estudios secundarios": "Comprovante de escolaridade",
        "Currículum vitae actualizado": "Currículo atualizado",
        "Foto": "Foto 3x4 recente",
        "Licencia de conducir": "Carteira nacional de habilitação",
        "Colegio, Matrícula, Cédula o Registro Profesionales": "Registro profissional",
        "Certificado de nacimiento o matrimonio": "Certidão de nascimento ou casamento",
        "Certificados de estudios Universitarios": "Diplomas e certificados",
        "Comprobante de experiencia profesional": "Comprovante de experiência profissional",
        "Seguro laboral emitido por institución educativa para pasantías o prácticas": "Vinculo instituições de ensino (para estagiários)",
        "Certificado de antecedentes": "Certidão de antecedentes criminais",
        "Identificación de dependientes": "Identificação de dependentes",
        "Afiliación de salud": "Benefícios e planos de saúde",
        "Autorización de descuento por planilla o por nómina": "Autorização desc.  folha de pagamento",
        "Comprobante de registro Sindical": "Documentos de registro sindical",
        "Datos para depósito bancario": "Informações bancárias para depósito de salário",
        "Certificado de capacitación en seguridad y salud ocupacional": "Documentos de segurança e saúde ocupacional",
        "Acuerdo de confidencialidad": "Termo de confidencialidade e sigilo",
        "Contrato de trabajo firmado": "Contrato de trabalho assinado",
        "Ficha de registro de empleado": "Ficha de registro de funcionário",
        "Declaración de Responsabilidad": "Termo de responsabilidade",
        "Certificados de afiliación de fondo de pensión o de seguridad social": " Formulários de inscrição de benefícios",
        "Autorización para exámenes médicos de ingreso": "Autorização para exames admissionais",
        "Comprobante apertura cuenta bancaria": " Comprovante de conta bancária",
        "Visa de trabajo (para extranjeros)": "Vistos  de trabalho (para estrangeiros)",
    },
  };
  