class List {
	async english() {
		const resp = await fetch(`${process.env.REACT_APP_API}/englishlevel`, {
			method: 'GET',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
			},
		});
		return resp.json();
	}

	async levels() {
		const resp = await fetch(`${process.env.REACT_APP_API}/hierarchicallevel`, {
			method: 'GET',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
			},
		});
		return await resp.json();
	}

	async countries() {
		const resp = await fetch(`${process.env.REACT_APP_API}/search/country`,
			{
				method: "GET",
				mode: "cors",
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
		return resp.json();
	}

	async areas() {
		const resp = await fetch(`${process.env.REACT_APP_API}/areas`, {
			method: 'GET',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
			},
		});
		return await resp.json();
	}

	async subareas() {
		const resp = await fetch(`${process.env.REACT_APP_API}/company/subareas`, {
			method: 'GET',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
			},
		});
		return await resp.json();
	}
}
export default new List()
