import Modal from 'react-bootstrap/Modal'
import { useCallback, useEffect, useState } from 'react'

import Loading from '../../Loading/Loading'
import EmplyeeMapper from '../../../Utils/EmplyeeMapper'

import { formEETranslate, jobDescriptorTranslate } from '../../../i18n/translatedMessages'
import { Form } from '../Form/form'

import List from '../../../API/Lists/Lists.js'
import { getEmployee, putEmployee } from '../../../API/Employee/Employee.js'
import { getBenefitGroupBySmtm, getAreaByCountry, getCityIdByCountrySmtm } from '../../../Parses/ParseMydnaDataToSmtmData.js'

import './modal.sass'
import Lists from '../../../API/Lists/Lists.js'

export function EditModal({ show, handleShow, employeeData }) {
	const [employee, setEmployee] = useState(null)
	const [loading, setLoading] = useState(false)

	const load = useCallback(async () => {
		try {
			if (show && employeeData) {
				setLoading(true)
				const response = await fetch(`https://smtm.co/v1/position_by_mydna_email?email=${employeeData.email}`, {
					method: 'GET',
					mode: 'cors',
					headers: {
						'Content-Type': 'application/json',
					},
				})
				const [data] = await response.json()

				const responseEmployee = await getEmployee(employeeData.id)

				const subareas = await List.subareas()

				const [get_subarea] = subareas.filter(item => item.name.toLowerCase() === employeeData.SubAreas.name.toLowerCase())

				const formatted = EmplyeeMapper.toDomain({
					...employeeData,
					...data,
				})

				setEmployee({
					...formatted,
					subarea: get_subarea?.id,
					subareaName: get_subarea?.name,
					salary: responseEmployee.salary,
				})
			}
		} catch (error) {
			console.error('error', error)
		} finally {
			setLoading(false)
		}
	}, [employeeData, show])

	useEffect(() => {
		load()
	}, [load])

	const handleChange = event => {
		setEmployee({
			...employee,
			[event.target.name]: event.target.value,
		})
	}

	const sendEdit = async () => {
		setLoading(true)

		const company = JSON.parse(localStorage.getItem('user'))
		const befit_group = getBenefitGroupBySmtm(employee.level)
		const area_id_smtm = getAreaByCountry(employee.country.toString(), employee.area)
		const cityId = getCityIdByCountrySmtm(employee.country.toString())

		const mapperData = {
			areaId: employee.area,
			companyId: company.id,
			countryId: employee.country,
			email: employee.email,
			jobName: employee.position,
			levelId: employee.level,
			name: employee.name,
			profileImg: employeeData.profileImg,
			subAreaId: employee.subarea,
			// ** data de SMTM ** //
			area_id: parseInt(area_id_smtm),
			country_id: employee.country,
			city_id: cityId,
			current_salary: employee.salary || '0',
			english_level_id: employee.english || 0,
			group: befit_group,
			job_name: employee.position,
			job_id: null,
			mydna_user_name_position: employee.name,
			mydna_email_position: employee.email,
			qualification_id: employee.training || 0,
			subArea: employee.subareaName,
			seniority_id: employeeData.seniorityLevel || 0,
			variable_salary: employee.variableSalary || '0',
		}

		await putEmployee(mapperData, employeeData.id)

		setLoading(false)
		setTimeout(() => {
			window.location.reload()
		}, 500)
	}

	return (
		<Modal
			dialogClassName='modal-width'
			show={show}
			onHide={() => {
				handleShow(false)
			}}
		>
			{loading && <Loading />}
			{!loading && (
				<>
					<Modal.Header closeButton className='border-0'>
						<Modal.Title>
							<span className='fs-5 fw-bold'>{formEETranslate.EEColaboratorData}</span>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body className='d-block'>
						<Form user={employee} setUser={setEmployee} emailDisabled={true} handleChange={handleChange} />
					</Modal.Body>
					<Modal.Footer className='border-0'>
						<div className='row w-100'>
							<div className='col'>
								<button className='btn btn-outline-dark-grey text-black rounded-pill w-100' onClick={() => handleShow(false)}>
									{jobDescriptorTranslate.jobDescriptorBack}
								</button>
							</div>

							<div className='col'>
								<button className='btn btn-outline-primary rounded-pill w-100' onClick={sendEdit}>
									{formEETranslate.EEEditData}
								</button>
							</div>
						</div>
					</Modal.Footer>
				</>
			)}
		</Modal>
	)
}
