import React, { useState, useContext, useEffect } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'

import { Col, Row } from 'react-bootstrap'
import { PieChart } from 'react-minimal-pie-chart'

import { ServicesContext } from '../../../../../Store/ServicesContext'

import './NewDashboard.css'
import Loading from '../../../../../Components/Loading/Loading'
import { salariesAndBenefitsTranslate } from '../../../../../i18n/translatedMessages'

const NewDash = props => {
	const { store, actions } = useContext(ServicesContext)
	const history = useHistory()

	const [isLoading, setIsLoading] = useState(true)

	const [showAreasOrdenandas, setshowAreasOrdenandas] = useState(false)
	const [promedioComparativaPorGroup, setpromedioComparativaPorGroup] = useState([])

	useEffect(() => {
		//MEJORAR ESTO. DEBE HACERCE DESDE EL BACK
		setTimeout(() => {
			getDataByGroups()
			getDataByAreas()
			getDataByFantasyJobs()
		}, 3000)
		setCompanySize(store.profile_data_company[0].company_size_id)
	}, [promedioComparativaPorGroup])

	useEffect(() => {
		async function fetchData() {
			// You can await here
			await actions.allAreaGroupsByCountry()
			await actions.allAreasByCountry()
			await actions.getDataCorrelationIndustries()
			await actions.allIndustries()
			await actions.allAreas()
			await actions.getDataCompany(JSON.parse(localStorage.getItem('email')))
			setTimeout(() => {
				setIsLoading(false)
			}, 2000)
		}
		fetchData()
	}, [store.dashboardDataPosition])

	useEffect(() => {
		let companyId = props.companyIdFromSMTM
		actions.setDashboardDataPositions(companyId)
	}, [props.companyIdFromSMTM])

	useEffect(() => {
		actions.allAreasByCountry()
	}, [store.profile_data_company])

	const [dataPromedioPosiciones, setDataPromedioPosiciones] = useState(0)

	useEffect(() => {
		actions.getAllAreasCurrentPositions()
		setTimeout(() => {
			actions.getAllAreasCurrentPositions()
			actions.getAllGroupsCurrentPositions()
			actions.getAllGroupsCurrentFantasyJobs()
		}, 2000)
	}, [])

	const getDataByGroups = () => {
		let arr = []
		store.allGroupsCurrentPositions.map(group => {
			let contador = 0
			let comparativaTotal = 0
			let comparativa = 0
			let index_position = ''

			store.dashboardDataPosition.map(dataArea => {
				if (group.name == dataArea.group) {
					contador++
					comparativaTotal += dataArea.comparative
				}

				/*
                  ! Este if no es Eficiente. A Futuro debera tratarse toda la data del DashBoard desde Backend y no manipularla desde front
                */
				if (group.name == 'CEO') {
					index_position = 1
				}
				if (group.name == 'Director') {
					index_position = 2
				}
				if (group.name == 'Gerente') {
					index_position = 3
				}
				if (group.name == 'SubGerente') {
					index_position = 4
				}
				if (group.name == 'Jefe') {
					index_position = 5
				}
				if (group.name == 'Especialista') {
					index_position = 6
				}
				if (group.name == 'Analista') {
					index_position = 7
				}
				if (group.name == 'Operario') {
					index_position = 8
				}
			})
			arr.push({
				group: group.name,
				group_id: group.id,
				promedioComparativa: comparativaTotal / contador,
				index_position: index_position,
			})
		})
		setpromedioComparativaPorGroup(arr)
		setshowAreasOrdenandas(true)
		arr.sort(ordenarIndexPosition)
	}

	const ordenarIndexPosition = (a, b) => {
		if (a.index_position < b.index_position) {
			return -1
		}
		if (a.index_position > b.index_position) {
			return 1
		}
		return 0
	}

	const getColorbar = input => {
		let porcentaje = parseInt(input)
		let color = ''

		if (porcentaje > 15) {
			color = '#0ab4c4'
		}
		if (porcentaje >= -15 || porcentaje <= 15) {
			color = '#5f52c4'
		}

		if (porcentaje < -15) {
			color = '#ec6376'
		}

		return color
	}

	const [promedioComparativaPorArea, setpromedioComparativaPorArea] = useState([])
	const getDataByAreas = () => {
		let arr = []
		store.allAreasCurrentPositions.map(area => {
			let contador = 0
			let comparativaTotal = 0
			let comparativa = 0
			store.dashboardDataPosition.map(dataArea => {
				if (area.value == dataArea.area_id) {
					contador++
					comparativaTotal += dataArea.comparative
				}
			})
			arr.push({
				area: area.name,
				area_id: area.value,
				country_id: area.country_id,
				promedioComparativa: comparativaTotal / contador,
			})
		})
		setpromedioComparativaPorArea(arr)
		setshowAreasOrdenandas(true)
	}

	const [promedioComparativaPorFanatasyJob, setpromedioComparativaPorFanatasyJob] = useState([])
	const [porcentajeSobre, setPorcentajeSobre] = useState(0)
	const [porcentajeIgual, setPorcentajeIgual] = useState(0)
	const [porcentajeBajo, setPorcentajeBajo] = useState(0)
	const [totalSobre, settotalSobre] = useState(0)
	const [totalIgual, settotalIgual] = useState(0)
	const [totalDebajo, settotalDebajo] = useState(0)

	const [promedioTodasLasBandas, setPromedioTodasLasBandas] = useState(0)

	const getDataByFantasyJobs = () => {
		let arr = []
		let sobre = 0
		let igual = 0
		let bajo = 0
		let contadorBandas = 0
		store.allFantasyJobsCurrentPositions.map(fantasyJob => {
			let contador = 0
			let comparativaTotal = 0
			let comparativa = 0
			store.dashboardDataPosition.map(dataArea => {
				if (fantasyJob.job_name == dataArea.job_name) {
					if (parseInt(dataArea.comparative) > 15) {
						sobre++
					}
					if (parseInt(dataArea.comparative) < -15) {
						bajo++
					}
					if (parseInt(dataArea.comparative) >= -15 && parseInt(dataArea.comparative) <= 15) {
						igual++
					}
					contador++
					comparativaTotal += dataArea.comparative
					contadorBandas += dataArea.comparative
				}
			})
			arr.push({
				job_name: fantasyJob.job_name,
				job_id: fantasyJob.job_id,
				promedioComparativa: parseInt(comparativaTotal) / parseInt(contador),
			})
		})
		setpromedioComparativaPorFanatasyJob(arr)
		setshowAreasOrdenandas(true)
		setPorcentajeSobre(((100 * sobre) / store.dashboardDataPosition.length).toFixed(1))
		settotalSobre(sobre)
		setPorcentajeIgual(((100 * igual) / store.dashboardDataPosition.length).toFixed(1))
		settotalIgual(igual)
		setPorcentajeBajo(((100 * bajo) / store.dashboardDataPosition.length).toFixed(1))
		settotalDebajo(bajo)
		setPromedioTodasLasBandas((contadorBandas / store.dashboardDataPosition.length).toFixed(1))
	}

	const setIndustryName = industry_id => {
		let industry = ''
		store.industries.map(value => {
			if (value.id === industry_id) {
				industry = value.name
			}
		})
		return industry
	}

	const [companySize, setCompanySize] = useState(null)

	return (
		<>
			<div className='newLanding-main-content ms-5'>
				{isLoading && (
					<>
						{' '}
						<Loading />{' '}
					</>
				)}
				<Row>
					<Col xs={12} sm={12} md={12}>
						<div className='d-flex align-items-start ml-5 mb-3'>
							<img className='custom-icon-guia-salarial' src='https://assets.mydna.company/b2b-images/pb/img/icons/azulesnumericos/Iconos-10.svg' alt='Icono Dashboard MyDNA' />
							<div className='d-flex flex-column justify-content-center px-4'>
								<h3 className='content-wrapper-title'>Dashboard</h3>
								<h5 className='content-wrapper-subtitle mb-3'>{salariesAndBenefitsTranslate.dashboardTextClimeBenefits}</h5>
							</div>
						</div>
					</Col>
				</Row>

				{store.dashboardDataPosition.length <= 0 ? (
					<>
						<div>{salariesAndBenefitsTranslate.alertEmptyDashboard}</div>
					</>
				) : (
					<>
						<Row className='mb-5 d-flex justify-content-center text-start mt-5'>
							<Col xs={12} sm={12} md={6}>
								<Row>
									<Col>
										<div className='mb-4 d-flex'>
											<h4 style={{ color: 'grey' }}>
												<span style={{ fontWeight: 'bold' }}>{store.profile_data_company[0].name}</span> <span>esta pagando </span>
												<span
													style={{
														color: getColorbar(promedioTodasLasBandas),
														fontWeight: 'bold',
													}}
												>
													{promedioTodasLasBandas}%{' '}
												</span>
												<span>
													<span>
														en relacion a una empresa {companySize == '2' ? 'Mediana' : ''}
														{companySize == '3' ? 'Grande' : ''}
														{companySize == '1' ? 'Pequeña' : ''} de la industria {store.profile_data_company !== '' ? setIndustryName(store.profile_data_company[0].industry_id) : ''}{' '}
													</span>
												</span>
											</h4>
										</div>
									</Col>
								</Row>
								<Row className='mt-5'>
									<Col xs={12} sm={12} md={12}>
										<div className='mb-4'>
											<span className='newDash-subtitles'>Cada nivel jerárquico de tu empresa, está ganando, en relación a la media de mercado: </span>
										</div>

										{promedioComparativaPorGroup.map((el, i) => {
											return (
												<div
													onClick={() => {
														history.push(`/app/services/salarios?level=${el.group}`, {
															state: {
																id: el.id,
																group: el.group,
															},
														})
													}}
													className='d-flex flex-column goToSalaries'
													key={i}
												>
													<Row style={{ zIndex: '2' }} className='mb-2'>
														<Col xs={12} sm={12} md={3}>
															<span className='newDash-textPyramid'> {el.group} </span>
														</Col>
														<Col xs={12} sm={12} md={7}>
															<div className='progress'>
																<div
																	className='progress-bar'
																	style={{
																		width: (el.promedioComparativa / 100 / 2 + 0.5) * 100 + '%',
																		backgroundColor: getColorbar(el.promedioComparativa),
																	}}
																	role='progressbar'
																	aria-valuenow={el.promedioComparativa}
																	aria-valuemin='0'
																	aria-valuemax='100'
																></div>
															</div>
														</Col>
														<Col xs={12} sm={12} md={2}>
															<div>
																<span>{el.promedioComparativa.toFixed(1)} %</span>
															</div>
														</Col>
													</Row>
												</div>
											)
										})}
									</Col>
								</Row>
								<Row className='mt-5'>
									<Col xs={12} sm={12} md={12}>
										<div className='mb-4 d-flex flex-column'>
											<span className='newDash-subtitles'>ANÁLISIS ÁREA DE LA ORGANIZACIÓN</span>
											<span>Cada área de tu empresa, esta ganado, en relación a la media de mercado: </span>{' '}
										</div>
										<Row className='d-flex justify-content-between align-content-start'>
											{promedioComparativaPorArea.map((el, i) => {
												return (
													<Col className='mb-2' xs={12} sm={12} md={5} key={i}>
														<div
															onClick={() => {
																history.push(`/app/services/salarios?country=${el.country_id}&area=${el.area_id}`, {
																	state: {
																		id: el.area_id,
																		area: el.area,
																	},
																})
															}}
															className='d-flex justify-content-start align-items-center mb-3 goToSalaries'
														>
															<span
																className='newDashboard-label mx-1'
																style={{
																	backgroundColor: getColorbar(el.promedioComparativa),
																}}
															>
																{el.promedioComparativa.toFixed(1)} %
															</span>
															<span>{el.area}</span>
														</div>
													</Col>
												)
											})}
										</Row>
									</Col>
								</Row>
							</Col>

							<Col xs={12} sm={12} md={6}>
								<div>
									<div className='mb-4'>
										<h4>
											<span className='newDash-subtitles'>Entre los colaborades: </span>
										</h4>
									</div>
									<div className='d-flex flex-row align-items-center justify-content-center'>
										<PieChart
											style={{
												fontFamily: '"Nunito Sans", -apple-system, Helvetica, Arial, sans-serif',
												fontSize: '6px',
												fontWeight: 'normal',
												width: '35%',
											}}
											data={[
												{
													title: 'Sobre',
													value: parseInt(totalSobre),
													color: '#0ab4c4',
												},
												{
													title: 'Igual',
													value: parseInt(totalIgual),
													color: '#5f52c4',
												},
												{
													title: 'Bajo',
													value: parseInt(totalDebajo),
													color: '#ec6376',
												},
											]}
											radius={50}
											lineWidth={50}
											segmentsStyle={{
												transition: 'stroke .3s',
												cursor: 'pointer',
											}}
											animate
											label={({ dataEntry }) => Math.round(dataEntry.percentage) + '%'}
											labelPosition={80}
											labelStyle={{
												fill: '#fff',
												opacity: 1,
												pointerEvents: 'none',
											}}
										/>

										<div className='d-flex flex-column h-100 mx-5 justify-content-center'>
											<div className='d-flex align-content-center mb-2'>
												<div className='newDash-leyendaSobre'> </div>
												{porcentajeSobre}% <span className='mx-1'>Estan ganando sobre la media de mercado (sobre 15%)</span>
											</div>
											<div className='d-flex align-content-center mb-2'>
												<div className='newDash-leyendaIgual'> </div>
												{porcentajeIgual}% <span className='mx-1'>Estan ganando dentro de la media de mercado (entre -15% y 15%)</span>
											</div>
											<div className='d-flex align-content-center mb-2'>
												<div className='newDash-leyendaBajo'></div>
												{porcentajeBajo}% <span className='mx-1'>Estan ganando abajo de la media de mercado (bajo 15%)</span>
											</div>
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</>
				)}
			</div>
		</>
	)
}

export default NewDash
