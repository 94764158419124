import {
    employeeExperienceTranslate,
    modalAssignTranslate,
} from "../../../../i18n/translatedMessages";

export const Health = ({ onChange, data }) => {
    return (
        <div className="content-form">
            <div className="row">
                <div className="col">
                    <h3>{employeeExperienceTranslate.healthTitleEEProfile}</h3>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <label htmlFor="healthPlan">
                        {employeeExperienceTranslate.healthPlanEEProfile}
                    </label>
                    <input
                        type="text"
                        id="healthPlan"
                        name="healthPlan"
                        onChange={onChange}
                        value={data.healthPlan}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <label htmlFor="height">
                        {employeeExperienceTranslate.heightEEProfile}
                    </label>
                    <input
                        type="number"
                        id="height"
                        name="height"
                        onChange={onChange}
                        value={Number(data.height)}
                    />
                </div>
                <div className="col">
                    <label htmlFor="weight">
                        {employeeExperienceTranslate.weightEEProfile}
                    </label>
                    <input
                        type="number"
                        id="weight"
                        name="weight"
                        onChange={onChange}
                        value={Number(data.weight)}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <label htmlFor="enfermedad">
                        {employeeExperienceTranslate.diseasesEEProfile}
                    </label>
                    <input
                        type="text"
                        id="enfermedad"
                        name="enfermedad"
                        onChange={onChange}
                        value={data.enfermedad}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-2">
                    <div className="label">
                        {employeeExperienceTranslate.pcdEEProfile}
                    </div>
                    <div className="radio-group" onChange={onChange}>
                        <div>
                            <input
                                type="radio"
                                onChange={() => {}}
                                value={true}
                                name="pcd"
                                id="Yes"
                                checked={String(data.pcd) === "true"}
                            />
                            <label htmlFor="Yes">
                                {modalAssignTranslate.yesText}
                            </label>
                        </div>
                        <div>
                            <input
                                type="radio"
                                onChange={() => {}}
                                value={false}
                                name="pcd"
                                id="No"
                                checked={String(data.pcd) === "false"}
                            />
                            <label htmlFor="No">
                                {modalAssignTranslate.noText}
                            </label>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <label htmlFor="type">
                        {employeeExperienceTranslate.typeEEProfile}
                    </label>
                    <input
                        type="text"
                        id="typePCD"
                        name="typePCD"
                        onChange={onChange}
                        value={data.typePCD}
                    />
                </div>
            </div>
        </div>
    );
};
