import { FormattedMessage } from 'react-intl'
import AssignScreen from './Components/AssignScreen'

const EnglishClasses = () => {
	return (
		<div className='row'>
			<div className='col offset-1'>
				<FormattedMessage id={'englishClass'}>{msg => <AssignScreen title={msg} />}</FormattedMessage>
			</div>
		</div>
	)
}

export default EnglishClasses
