import { useContext, useEffect, useState } from "react";
import { MdWorkOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import { CompanyContext } from "../../../../Store/CompanyContext";
import { jobsDescriptionTranslate } from "../../../../i18n/translatedMessages";
import "./jobSearch.sass";
import jobList from "./job_descriptor.json";
import jobListPT from "./job_descriptor_pt-br.json";

const JobSearch = () => {
	const { store } = useContext(CompanyContext);

	const [list, setList] = useState(jobList);
	const [search, setSearch] = useState("");

	const handleChange = (event) => {
		setSearch(event.currentTarget.value);
	};

	useEffect(() => {
		const newList =
			store.language == "ES"
				? jobList.filter((job) => {
						return job.slug
							.toLowerCase()
							.includes(search.toLowerCase());
				  })
				: jobListPT.filter((job) => {
						return job.slug
							.toLowerCase()
							.includes(search.toLowerCase());
				  });
		setList(newList);
	}, [search, store.language]);

	return (
		<div className="row">
			<div className="col offset-1">
				<div className="job-search ">
					<div className="row">
						<div className="col d-flex mb-4">
							<div className="icon">
								<MdWorkOutline size={30} />
							</div>
							<h1>
								{jobsDescriptionTranslate.jobsDescriptionTitle}
							</h1>
						</div>
					</div>
					<div className="row">
						<div className="col-8 offset-2">
							<input
								type="text"
								onChange={handleChange}
								value={search}
								placeholder="Buscar por cargos"
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-8 offset-2">
							<ul>
								{list.map((item) => (
									<Link
										key={item.id}
										to={`/app/services/descripcion-posiciones/${item.id}`}
									>
										<li>
											{item.slug}
											<div>
												{
													jobsDescriptionTranslate.jobsDescriptionShowMore
												}
											</div>
										</li>
									</Link>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default JobSearch;
