import { IoIosArrowBack, IoIosArrowDropleftCircle } from 'react-icons/io'
import { MdDownload } from 'react-icons/md'
import { HorizontalCard } from '../HorizontalCard/horizontalCard'
import { HiClipboardDocumentList, HiFlag, HiMiniUser, HiMiniUserGroup } from 'react-icons/hi2'
import { Card } from '../Card/card'
import { Pills } from '../Pills/pills'
import { Board } from '../Board/board'
import { useCallback, useEffect, useState } from 'react'
import Assessment from '../../../API/Assessment/index'
import { ModalAssessment } from '../Modal/modalAssessment'
import Loading from '../../Loading/Loading'

const assets = {
	Organizacional: {
		'small-icon': <HiClipboardDocumentList />,
		icon: <HiClipboardDocumentList size={28} />,
		color: '#008ACD',
	},
	Persona: {
		'small-icon': <HiMiniUser />,
		icon: <HiMiniUser size={28} />,
		color: '#D87706',
	},
	Líder: {
		'small-icon': <HiFlag />,
		icon: <HiFlag size={28} />,
		color: '#00A28B',
	},
	Social: {
		'small-icon': <HiMiniUserGroup />,
		icon: <HiMiniUserGroup size={28} />,
		color: '#6C4FDB',
	},
}

export function CompetenceResult({ onCloseResult, employee }) {
	const [loading, setLoading] = useState(false)
	const [keywords, setKeywords] = useState(null)
	const [dimensions, setDimensions] = useState(null)
	const [selectedDimension, setDimension] = useState(null)
	const [show, setShow] = useState(true)
	const [strengthWeakness, setStrengthWeakness] = useState(null)

	const handleDimension = dimension => {
		setDimension(dimension)
		setShow(true)
	}

	const loadData = useCallback(async () => {
		try {
			setLoading(true)
			const [getKeywords, getDimensions, getStrengthWeakness] = await Promise.all([Assessment.getKeywords(employee.id), Assessment.getDimensions(employee.id), Assessment.getStrengthWeakness(employee.id)])
			console.log('getKeywords', getKeywords)
			setKeywords(getKeywords)
			setDimensions(getDimensions)
			setStrengthWeakness(getStrengthWeakness)
		} catch {
		} finally {
			setLoading(false)
		}
	}, [])

	useEffect(() => {
		loadData()
	}, [loadData])

	if (loading) return <Loading />

	return (
		<>
			<div className='tw-flex tw-items-center tw-justify-between tw-mb-5'>
				<div className='tw-flex tw-space-x-4 tw-items-center'>
					<button onClick={onCloseResult} className='tw-rounded-full tw-text-purple-500 tw-p-1 tw-w-[32px] tw-h-[32px] tw-bg-white hover:tw-bg-purple-500 hover:tw-text-white tw-flex tw-items-center tw-justify-center border border-primary'>
						<IoIosArrowBack />
					</button>
					<div className='tw-text-xl'>{employee.name}</div>
				</div>
				{/* <div className='tw-bg-purple-500 tw-p-3 tw-flex tw-space-x-2 tw-items-center tw-rounded-lg tw-text-white  hover:tw-bg-purple-400 tw-cursor-pointer'>
					<MdDownload />
					<div className='tw-flex tw-items-center '>Descargar respuesta</div>
				</div> */}
			</div>
			<div className='tw-my-9 tw-text-lg'>
				A continuación, tendrás el resultado de la prueba del colaborador, que analiza 4 dimensiones y el potencial del colaborador en cada una de ellas, destacando 3 fortalezas que impulsan su dimensión más desarrollada, así como 3 áreas de mejora
				destinadas a desarrollar su dimensión menos desarrollada.
			</div>
			<div className='tw-space-y-10'>
				<div>
					<div className='tw-p-4 tw-bg-gray-100 tw-font-bold tw-text-xl tw-mb-5'>Palabras clave sobre el perfil</div>
					<div className='tw-flex tw-items-center tw-justify-center'>
						<div className='tw-grid  tw-gap-2 tw-grid-cols-2 lg:tw-gap-3 lg:tw-grid-cols-3'>
							{keywords?.map(item => (
								<Pills label={item.description} key={item.description} color='#008ACD' />
							))}
						</div>
					</div>
				</div>

				<div>
					<div className='tw-p-4 tw-bg-gray-100 tw-font-bold tw-text-xl tw-mb-5'>Competencias evaluadas en 4 dimensiones</div>
					<div className='tw-flex tw-justify-center tw-gap-4'>
						{dimensions?.map(item => (
							<Card content={item} key={item.dimension} assets={assets} handleDimension={handleDimension} />
						))}
					</div>
				</div>

				{dimensions && (
					<div>
						<div className='tw-p-4 tw-bg-gray-100 tw-font-bold tw-text-xl tw-mb-5'>Fortalezas y oportunidades de mejora</div>
						<div className='tw-grid tw-grid-cols-2 tw-gap-4'>
							<Board assest={assets} content={dimensions[0]} title='Fortalezas' list={strengthWeakness.strengths} />
							<Board assest={assets} content={dimensions[3]} title='Oportunidades de Mejora' list={strengthWeakness.weaknesses} />
						</div>
					</div>
				)}
			</div>
			<ModalAssessment show={show} setShow={setShow} assets={assets} content={dimensions} selectedDimension={selectedDimension} setNewDimension={setDimension} />
		</>
	)
}
