import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Modal, Button } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'

import { ProgressBar } from './progress/progress'

import './ModalAssignScreen.css' // Asegúrate de que este es el camino correcto a tu archivo CSS
import { AiFillSignal } from 'react-icons/ai'

const ModalAssignScreen = ({ isOpen, onClose, data, outlist }) => {
	return (
		<Modal centered show={isOpen} onHide={onClose} keyboard={false} backdrop='static' scrollable={true} dialogClassName='modal-assign'>
			<Modal.Header closeButton>
				<div className='modal-top-info'>
					<div className='modal-employee-info'>
						<div className='modal-employe-avatar'>
							<img src={data?.employerToExitPlan?.profileImg} alt={data?.employerToExitPlan?.name} />
						</div>
						<div>
							<div className='employee-name'>{data?.employerToExitPlan?.name}</div>
							<div className='employee-role'>{data?.profileArea}</div>
						</div>
					</div>
					<div className='modal-progress-info'>
						<div className='progress-bar-container'>{<ProgressBar progress={data.progressInProcess} />}</div>
						<div className='progress-text'>de avance en el programa</div>
					</div>
				</div>
			</Modal.Header>
			<Modal.Body>
				<div className='modal-flex'>
					<Container fluid>
						<Row>
							<Col xs={3}>
								<div className='modal-info-card'>
									<span className='modal-info-title'>¿Ya se recolocó?</span>
									<div className='info-answer'>
										{data?.foundJob ? (
											<div>
												<svg xmlns='http://www.w3.org/2000/svg' width='38' height='36' viewBox='0 0 38 36' fill='none'>
													<path
														d='M9.607 16.6875C11.0181 16.6875 12.2913 15.9073 13.1622 14.7969C14.5152 13.0717 16.2205 11.6359 18.1697 10.5979C19.4334 9.92486 20.53 8.925 21.0618 7.59561C21.4338 6.66542 21.625 5.67277 21.625 4.67091V3.5625C21.625 2.83763 22.2126 2.25 22.9375 2.25C25.1121 2.25 26.875 4.01288 26.875 6.1875C26.875 8.20285 26.4208 10.1121 25.6091 11.8185C25.1443 12.7956 25.7963 14.0625 26.8783 14.0625M26.8783 14.0625H32.3485C34.1451 14.0625 35.7533 15.277 35.9435 17.0635C36.0222 17.8024 36.0625 18.5527 36.0625 19.3125C36.0625 24.2958 34.3267 28.8738 31.4268 32.4746C30.7484 33.3169 29.6998 33.75 28.6184 33.75H21.5904C20.744 33.75 19.9031 33.6135 19.1001 33.3459L13.6499 31.5291C12.8469 31.2615 12.006 31.125 11.1596 31.125H8.33236M26.8783 14.0625H22.9375M8.33236 31.125C8.47728 31.483 8.63498 31.8345 8.8049 32.1789C9.1499 32.878 8.66835 33.75 7.88868 33.75H6.30005C4.74481 33.75 3.30235 32.8435 2.84848 31.3559C2.25615 29.4146 1.9375 27.3539 1.9375 25.2188C1.9375 22.5019 2.45341 19.9056 3.3926 17.5224C3.92646 16.1678 5.29284 15.375 6.74888 15.375H8.5912C9.41706 15.375 9.89528 16.3478 9.46653 17.0537C8.0203 19.4346 7.1875 22.2294 7.1875 25.2188C7.1875 27.3074 7.59406 29.3011 8.33236 31.125Z'
														stroke='#3EA528'
														strokeWidth='2.625'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
												</svg>
												<span className='success'>Si</span>
											</div>
										) : (
											<div>
												<svg xmlns='http://www.w3.org/2000/svg' width='37' height='35' viewBox='0 0 37 35' fill='none'>
													<path
														d='M10.5384 21.375H5.15503C3.38693 21.375 1.80432 20.1798 1.61713 18.4216C1.53971 17.6945 1.5 16.956 1.5 16.2083C1.5 11.3041 3.20824 6.79884 6.06216 3.25512C6.7297 2.42623 7.76167 2 8.82594 2L15.7423 2C16.5753 2 17.4029 2.13429 18.1931 2.39771L23.5569 4.18563C24.3471 4.44904 25.1747 4.58333 26.0077 4.58333L28.2367 4.58334M10.5384 21.375C11.6032 21.375 12.2449 22.6218 11.7875 23.5834C10.9887 25.2627 10.5417 27.1416 10.5417 29.125C10.5417 31.2651 12.2766 33 14.4167 33C15.13 33 15.7083 32.4217 15.7083 31.7083V30.6175C15.7083 29.6316 15.8965 28.6547 16.2626 27.7392C16.7859 26.431 17.8651 25.447 19.1088 24.7846C21.0271 23.763 22.7053 22.3501 24.0368 20.6522C24.8938 19.5595 26.1469 18.7917 27.5356 18.7917H28.1964M10.5384 21.375H14.3306M28.2367 4.58334C28.2549 4.66945 28.284 4.7549 28.3249 4.83788C29.3441 6.90331 29.9167 9.22854 29.9167 11.6875C29.9167 14.248 29.2958 16.6635 28.1964 18.7917M28.2367 4.58334C28.104 3.95531 28.5519 3.29167 29.2266 3.29167H30.79C32.3206 3.29167 33.7401 4.18381 34.1868 5.64774C34.7697 7.55826 35.0833 9.58626 35.0833 11.6875C35.0833 14.3612 34.5756 16.9163 33.6513 19.2616C33.1259 20.5948 31.7813 21.375 30.3483 21.375H28.5352C27.7225 21.375 27.2519 20.4176 27.6738 19.723C27.8583 19.4192 28.0327 19.1086 28.1964 18.7917'
														stroke='#F84228'
														strokeWidth='2.58333'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
												</svg>
												<span className='error'>No</span>
											</div>
										)}
									</div>
								</div>
							</Col>
							<Col xs={5}>
								<div className='modal-info-card'>
									<span className='modal-info-title'>¿Cómo se siente?</span>
									<div className='info-icon positive m-0'>
										{data?.mood === 'Sad' ? (
											<svg width='92' height='89' viewBox='0 0 92 89' fill='none' xmlns='http://www.w3.org/2000/svg'>
												<g clipPath='url(#clip0_306_900)'>
													<rect width='92' height='89' rx='8' fill='none' />
													<path d='M-3.625 33.5C30 68 70 83 92 85C92 85 98.875 88.5 93.875 88.5H-3.625V33.5Z' fill='url(#paint0_linear_306_900)' />
													<path d='M94.5 86C72 82.5 28 68 -8.5 28' stroke='#FB7286' strokeWidth='2' />
													<path
														d='M25.7127 27.0376C24.6921 26.017 23.356 25.5045 22.0184 25.4999C20.6688 25.4953 19.3178 26.0079 18.2881 27.0376M32.5 22C32.5 27.799 27.799 32.5 22 32.5C16.201 32.5 11.5 27.799 11.5 22C11.5 16.201 16.201 11.5 22 11.5C27.799 11.5 32.5 16.201 32.5 22ZM19.375 19.375C19.375 19.8582 19.1791 20.25 18.9375 20.25C18.6959 20.25 18.5 19.8582 18.5 19.375C18.5 18.8918 18.6959 18.5 18.9375 18.5C19.1791 18.5 19.375 18.8918 19.375 19.375ZM18.9375 19.375H18.9463V19.3925H18.9375V19.375ZM25.5 19.375C25.5 19.8582 25.3041 20.25 25.0625 20.25C24.8209 20.25 24.625 19.8582 24.625 19.375C24.625 18.8918 24.8209 18.5 25.0625 18.5C25.3041 18.5 25.5 18.8918 25.5 19.375ZM25.0625 19.375H25.0713V19.3925H25.0625V19.375Z'
														stroke='#F43F5E'
														strokeWidth='1.75'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
												</g>
												<defs>
													<linearGradient id='paint0_linear_306_900' x1='47' y1='33.5' x2='51' y2='101.5' gradientUnits='userSpaceOnUse'>
														<stop stopColor='#FEA4AE' />
														<stop offset='1' stopColor='#FFF1F1' stopOpacity='0' />
													</linearGradient>
													<clipPath id='clip0_306_900'>
														<rect width='92' height='89' rx='8' fill='none' />
													</clipPath>
												</defs>
											</svg>
										) : data?.mood === 'Normal' ? (
											<svg xmlns='http://www.w3.org/2000/svg' width='92' height='89' viewBox='0 0 92 89' fill='none'>
												<g clipPath='url(#clip0_306_892)'>
													<rect width='92' height='89' rx='8' fill='none' />
													<path d='M91.9998 54.5C54.4998 54.5 20.7432 52.9954 -0.500323 55.5C-0.500323 55.5 -5.50047 88.5 -0.50047 88.5H96.9995L91.9998 54.5Z' fill='url(#paint0_linear_306_892)' />
													<line x1='-2' y1='55' x2='93' y2='55' stroke='#FBBF23' strokeWidth='2' />
													<path
														d='M25.7127 26.0833C24.625 26.0833 23.3376 26.0879 22 26.0833C20.6504 26.0787 19.375 26.0833 18.2881 26.0833M32.5 22C32.5 27.799 27.799 32.5 22 32.5C16.201 32.5 11.5 27.799 11.5 22C11.5 16.201 16.201 11.5 22 11.5C27.799 11.5 32.5 16.201 32.5 22ZM19.375 19.375C19.375 19.8582 19.1791 20.25 18.9375 20.25C18.6959 20.25 18.5 19.8582 18.5 19.375C18.5 18.8918 18.6959 18.5 18.9375 18.5C19.1791 18.5 19.375 18.8918 19.375 19.375ZM18.9375 19.375H18.9462V19.3925H18.9375V19.375ZM25.5 19.375C25.5 19.8582 25.3041 20.25 25.0625 20.25C24.8209 20.25 24.625 19.8582 24.625 19.375C24.625 18.8918 24.8209 18.5 25.0625 18.5C25.3041 18.5 25.5 18.8918 25.5 19.375ZM25.0625 19.375H25.0712V19.3925H25.0625V19.375Z'
														stroke='#F49E0B'
														strokeWidth='1.75'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
												</g>
												<defs>
													<linearGradient id='paint0_linear_306_892' x1='47' y1='33.5' x2='51' y2='101.5' gradientUnits='userSpaceOnUse'>
														<stop stopColor='#FBD34D' />
														<stop offset='1' stopColor='#FEF3C6' stopOpacity='0' />
													</linearGradient>
													<clipPath id='clip0_306_892'>
														<rect width='92' height='89' rx='8' fill='none' />
													</clipPath>
												</defs>
											</svg>
										) : data?.mood === 'Excited' ? (
											<svg xmlns='http://www.w3.org/2000/svg' width='92' height='89' viewBox='0 0 92 89' fill='none'>
												<g clipPath='url(#clip0_306_907)'>
													<rect width='92' height='89' rx='8' fill='none' />
													<path d='M97 10C67.9845 54.389 18.2435 70.4954 -3 73C-3 73 -5.5 88.5 -0.5 88.5H97V10Z' fill='url(#paint0_linear_306_907)' />
													<path d='M-3 73C18.2435 70.4954 67.9845 54.389 97 10' stroke='#05C8AA' strokeWidth='2' />
													<path
														d='M25.7123 25.7123C23.6621 27.7626 20.3379 27.7626 18.2877 25.7123M32.5 22C32.5 27.799 27.799 32.5 22 32.5C16.201 32.5 11.5 27.799 11.5 22C11.5 16.201 16.201 11.5 22 11.5C27.799 11.5 32.5 16.201 32.5 22ZM19.375 19.375C19.375 19.8582 19.1791 20.25 18.9375 20.25C18.6959 20.25 18.5 19.8582 18.5 19.375C18.5 18.8918 18.6959 18.5 18.9375 18.5C19.1791 18.5 19.375 18.8918 19.375 19.375ZM18.9375 19.375H18.9463V19.3925H18.9375V19.375ZM25.5 19.375C25.5 19.8582 25.3041 20.25 25.0625 20.25C24.8209 20.25 24.625 19.8582 24.625 19.375C24.625 18.8918 24.8209 18.5 25.0625 18.5C25.3041 18.5 25.5 18.8918 25.5 19.375ZM25.0625 19.375H25.0713V19.3925H25.0625V19.375Z'
														stroke='#00A28B'
														strokeWidth='1.75'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
												</g>
												<defs>
													<linearGradient id='paint0_linear_306_907' x1='47' y1='33.5' x2='51' y2='101.5' gradientUnits='userSpaceOnUse'>
														<stop stopColor='#50F8D5' />
														<stop offset='1' stopColor='#EFFEFB' stopOpacity='0' />
													</linearGradient>
													<clipPath id='clip0_306_907'>
														<rect width='92' height='89' rx='8' fill='none' />
													</clipPath>
												</defs>
											</svg>
										) : (
											<div className='d-flex h5 m-0'>No informado</div>
										)}
									</div>
								</div>
							</Col>
							<Col xs={4}>
								<div className='modal-info-card'>
									<span className='modal-info-title'>Entrevistas realizadas:</span>
									<span className='info-answer'>
										<div className='progress-bar-container'>
											<div
												data-progress={data?.numberInterviews}
												className='progress-bar progress-circle'
												style={{
													'--progress-value': 30,
												}}
											>
												<progress id='circle-2' min='0' max='100' value={data?.numberInterviews} />
											</div>
										</div>
									</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={4}>
								<div className='modal-info-card'>
									<span className='modal-info-title'>Postulaciones realizadas:</span>
									<div className='flex'>
										<AiFillSignal size={25} color='#41C8FF' />
										<span className='info-answer'>{data?.applicationsMade}</span>
									</div>
								</div>
							</Col>
							<Col xs={4}>
								<div className='modal-info-card'>
									<span className='modal-info-title'>Previsión de recolocación:</span>
									<div className='flex'>
										<svg xmlns='http://www.w3.org/2000/svg' width='37' height='38' viewBox='0 0 37 38' fill='none'>
											<path
												d='M8.58333 2V6.25M28.4167 2V6.25M1.5 31.75V10.5C1.5 8.15279 3.40279 6.25 5.75 6.25H31.25C33.5972 6.25 35.5 8.15279 35.5 10.5V31.75M1.5 31.75C1.5 34.0972 3.40279 36 5.75 36H31.25C33.5972 36 35.5 34.0972 35.5 31.75M1.5 31.75V17.5833C1.5 15.2361 3.40279 13.3333 5.75 13.3333H31.25C33.5972 13.3333 35.5 15.2361 35.5 17.5833V31.75'
												stroke='#0092C5'
												strokeWidth='2.83333'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
										</svg>
										<span className='info-answer'>{data?.newJobTimeExpectation}</span>
									</div>
								</div>
							</Col>
							<Col xs={4}>
								<div className='modal-info-card'>
									<span className='modal-info-title'>¿Es freelancer?</span>
									<div className='flex'>
										<svg xmlns='http://www.w3.org/2000/svg' width='34' height='34' viewBox='0 0 34 34' fill='none'>
											<path
												d='M11.8333 26.0417V27.7766C11.8333 29.1468 11.289 30.461 10.3201 31.4299L9.25 32.5H24.75L23.6799 31.4299C22.711 30.461 22.1667 29.1468 22.1667 27.7766V26.0417M32.5 5.375V22.1667C32.5 24.3068 30.7651 26.0417 28.625 26.0417H5.375C3.2349 26.0417 1.5 24.3068 1.5 22.1667V5.375M32.5 5.375C32.5 3.2349 30.7651 1.5 28.625 1.5H5.375C3.2349 1.5 1.5 3.2349 1.5 5.375M32.5 5.375V17C32.5 19.1401 30.7651 20.875 28.625 20.875H5.375C3.2349 20.875 1.5 19.1401 1.5 17V5.375'
												stroke='#584EC0'
												strokeWidth='1.5'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
										</svg>
										<span className='info-answer'>{data?.isFreelancer ? 'Sí' : 'No'}</span>
									</div>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={5}>
								<div className='modal-info-card'>
									<span className='modal-info-title'>¿Participa en procesos de selección?</span>
									<div className='activities'>
										<span className='activities-info'>{data?.numberInterviews}</span>
										<span className='activities-content'>Proceso/s activo/s</span>
									</div>
								</div>
							</Col>
							<Col xs={7}>
								<div className='modal-info-card'>
									<span className='modal-info-title'>¿Qué grado de compromiso tiene con el programa?</span>
									<div className='flex-col'>
										<div className='flex program'>
											<span className='lines line-one' />
											<span className='lines line-two' />
											<span className='lines line-three' />
										</div>
										<span className='flex info-tag'>
											{data?.applicationsMade === 0 && data?.numberInterviews === 0 ? (
												<>
													<span className='square low' /> Bajo
												</>
											) : data?.applicationsMade === 0 || data?.numberInterviews === 0 ? (
												<>
													<span className='square middle' /> Mediano
												</>
											) : data?.applicationsMade > 0 && data?.numberInterviews > 0 ? (
												<>
													<span className='square high' /> Alto
												</>
											) : (
												''
											)}
										</span>
									</div>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={8}>
								<div className='modal-info-card'>
									<span className='modal-info-title'>¿Realiza la preparación recomendada para cada sesión?</span>
									<div className='checkbox flex-col'>
										<div className={`${data?.applicationsMade > 0 && data?.numberInterviews > 0 ? 'flex' : 'flex disabled'}`}>
											<input type='checkbox' checked={data?.applicationsMade > 0 && data?.numberInterviews > 0} id='alway' />
											<label htmlFor='alway'>Siempre</label>
										</div>
										<div className={`${(data?.applicationsMade === 0 && data?.numberInterviews > 0) || (data?.numberInterviews === 0 && data?.applicationsMade > 0) ? 'flex' : 'flex disabled'}`}>
											<input type='checkbox' checked={(data?.applicationsMade === 0 && data?.numberInterviews > 0) || (data?.numberInterviews === 0 && data?.applicationsMade > 0)} id='sometimes' />
											<label htmlFor='sometimes'>A veces</label>
										</div>
										<div className={`${data?.applicationsMade === 0 && data?.numberInterviews === 0 ? 'flex' : 'flex disabled'}`}>
											<input type='checkbox' checked={data?.applicationsMade === 0 && data?.numberInterviews === 0} id='never' />
											<label htmlFor='never'>Nunca</label>
										</div>
									</div>
									<span className='info-tag'>{data?.sessionPreparation}</span>
								</div>
							</Col>
							<Col xs={4}>
								<div className='modal-info-card'>
									<span className='modal-info-title'>¿Cree que el programa está ayudando?</span>
									<div className='info-answer'>
										{data?.appHasHelped ? (
											<div>
												<svg xmlns='http://www.w3.org/2000/svg' width='38' height='36' viewBox='0 0 38 36' fill='none'>
													<path
														d='M9.607 16.6875C11.0181 16.6875 12.2913 15.9073 13.1622 14.7969C14.5152 13.0717 16.2205 11.6359 18.1697 10.5979C19.4334 9.92486 20.53 8.925 21.0618 7.59561C21.4338 6.66542 21.625 5.67277 21.625 4.67091V3.5625C21.625 2.83763 22.2126 2.25 22.9375 2.25C25.1121 2.25 26.875 4.01288 26.875 6.1875C26.875 8.20285 26.4208 10.1121 25.6091 11.8185C25.1443 12.7956 25.7963 14.0625 26.8783 14.0625M26.8783 14.0625H32.3485C34.1451 14.0625 35.7533 15.277 35.9435 17.0635C36.0222 17.8024 36.0625 18.5527 36.0625 19.3125C36.0625 24.2958 34.3267 28.8738 31.4268 32.4746C30.7484 33.3169 29.6998 33.75 28.6184 33.75H21.5904C20.744 33.75 19.9031 33.6135 19.1001 33.3459L13.6499 31.5291C12.8469 31.2615 12.006 31.125 11.1596 31.125H8.33236M26.8783 14.0625H22.9375M8.33236 31.125C8.47728 31.483 8.63498 31.8345 8.8049 32.1789C9.1499 32.878 8.66835 33.75 7.88868 33.75H6.30005C4.74481 33.75 3.30235 32.8435 2.84848 31.3559C2.25615 29.4146 1.9375 27.3539 1.9375 25.2188C1.9375 22.5019 2.45341 19.9056 3.3926 17.5224C3.92646 16.1678 5.29284 15.375 6.74888 15.375H8.5912C9.41706 15.375 9.89528 16.3478 9.46653 17.0537C8.0203 19.4346 7.1875 22.2294 7.1875 25.2188C7.1875 27.3074 7.59406 29.3011 8.33236 31.125Z'
														stroke='#3EA528'
														strokeWidth='2.625'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
												</svg>
												<span className='success'>Si</span>
											</div>
										) : (
											<div>
												<svg xmlns='http://www.w3.org/2000/svg' width='37' height='35' viewBox='0 0 37 35' fill='none'>
													<path
														d='M10.5384 21.375H5.15503C3.38693 21.375 1.80432 20.1798 1.61713 18.4216C1.53971 17.6945 1.5 16.956 1.5 16.2083C1.5 11.3041 3.20824 6.79884 6.06216 3.25512C6.7297 2.42623 7.76167 2 8.82594 2L15.7423 2C16.5753 2 17.4029 2.13429 18.1931 2.39771L23.5569 4.18563C24.3471 4.44904 25.1747 4.58333 26.0077 4.58333L28.2367 4.58334M10.5384 21.375C11.6032 21.375 12.2449 22.6218 11.7875 23.5834C10.9887 25.2627 10.5417 27.1416 10.5417 29.125C10.5417 31.2651 12.2766 33 14.4167 33C15.13 33 15.7083 32.4217 15.7083 31.7083V30.6175C15.7083 29.6316 15.8965 28.6547 16.2626 27.7392C16.7859 26.431 17.8651 25.447 19.1088 24.7846C21.0271 23.763 22.7053 22.3501 24.0368 20.6522C24.8938 19.5595 26.1469 18.7917 27.5356 18.7917H28.1964M10.5384 21.375H14.3306M28.2367 4.58334C28.2549 4.66945 28.284 4.7549 28.3249 4.83788C29.3441 6.90331 29.9167 9.22854 29.9167 11.6875C29.9167 14.248 29.2958 16.6635 28.1964 18.7917M28.2367 4.58334C28.104 3.95531 28.5519 3.29167 29.2266 3.29167H30.79C32.3206 3.29167 33.7401 4.18381 34.1868 5.64774C34.7697 7.55826 35.0833 9.58626 35.0833 11.6875C35.0833 14.3612 34.5756 16.9163 33.6513 19.2616C33.1259 20.5948 31.7813 21.375 30.3483 21.375H28.5352C27.7225 21.375 27.2519 20.4176 27.6738 19.723C27.8583 19.4192 28.0327 19.1086 28.1964 18.7917'
														stroke='#F84228'
														strokeWidth='2.58333'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
												</svg>
												<span className='error'>No</span>
											</div>
										)}
									</div>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12}>
								<div className='modal-additional'>
									<h3>Información adicional:</h3>
									<hr />
									<p>{data?.observation}</p>
								</div>
							</Col>
						</Row>
						<hr className='divider' />
						<Row>
							<h2>Sesiones</h2>
							<Col xs={12}>
								<div className='modal-additional'>
									<h3>EVALUACIÓN INICIAL</h3>
									<hr />
									<p>
										Entender las necesidades, historia de vida y situación actual para asegurar un buen direccionamiento del programa. Entregando un espacio de contención y apoyo a la persona, frente a este difícil momento que
										está viviendo, para luego empoderarlo con el proceso de búsqueda.
									</p>
								</div>
							</Col>
							<Col xs={12}>
								<div className='modal-additional'>
									<h3>ANÁLISIS DE COMPETENCIA</h3>
									<hr />
									<p>Entender cuáles son las competencias que han ido acompañando al usuario durante su evolución profesional y enfrentarlo con lo que el mercado también está pidiendo hoy en día.</p>
								</div>
							</Col>
							<Col xs={12}>
								<div className='modal-additional'>
									<h3>PROPUESTA DE VALOR</h3>
									<hr />
									<p>Lograr una propuesta diferenciadora en relación a su perfil, en comparación con otros profesionales como él que existen en el mercado.</p>
								</div>
							</Col>
							<Col xs={12}>
								<div className='modal-additional'>
									<h3>MATCH DE MERCADO</h3>
									<hr />
									<p>Construir un plan, con las posibilidades de cargos e industrias donde la persona debe apuntar, de acuerdo a su perfil. Es decir identificar su mercado objetivo.</p>
								</div>
							</Col>
							<Col xs={12}>
								<div className='modal-additional'>
									<h3>VALOR DE MERCADO</h3>
									<hr />
									<p>Identificar los rangos de renta que la persona puede solicitar, de acuerdo a la valoración de su perfil.</p>
								</div>
							</Col>
							<Col xs={12}>
								<div className='modal-additional'>
									<h3>ESTRATEGIA DE ENTREVISTA</h3>
									<hr />
									<p>Comprender desde el modelo por competencias, la mejor forma de llevar a cabo una entrevista, con foco en la distinción de los interlocutores.</p>
								</div>
							</Col>
							<Col xs={12}>
								<div className='modal-additional'>
									<h3>PRÁCTICAS DE ENTREVISTA</h3>
									<hr />
									<p>Hacer un role playing, entrenando el proceso de entrevista, aplicando lo aprendido en función de su perfil y el cómo motrar su experiencia.</p>
								</div>
							</Col>
							<Col xs={12}>
								<div className='modal-additional'>
									<h3>LINKEDIN E IMPACTO DIGITAL</h3>
									<hr />
									<p>Optimizar el perfil de Linkedin, para lograr una mayor visibilidad en el mercado.</p>
								</div>
							</Col>
							<Col xs={12}>
								<div className='modal-additional'>
									<h3>PROPSPECCIÓN & NETWORKING</h3>
									<hr />
									<p>Incorporar las principales técnicas y formas de gestionar su network para el éxito del proceso.</p>
								</div>
							</Col>
							<Col xs={12}>
								<div className='modal-additional'>
									<h3>COACHING</h3>
									<hr />
									<p>Brindar contención y concientización personal, Identificar barreras u obstáculos que podrían aparecer en este proceso de entrenamiento y definción de fortalezas, debilidades, oportunidades y amenazas.</p>
								</div>
							</Col>
							<Col xs={12}>
								<div className='modal-additional'>
									<h3>GESTIÓN DE REDES EXECUTIVE</h3>
									<hr />
									<p>Identificar y comprender como abordar los canales de redes, top executive.</p>
								</div>
							</Col>
							<Col xs={12}>
								<div className='modal-additional'>
									<h3>FINANZAS PERSONALES</h3>
									<hr />
									<p>Asesorar financieramente, entregándole las mejores alternativas, de acuerdo al ciclo de vida en que se encuentre.</p>
								</div>
							</Col>
							<Col xs={12}>
								<div className='modal-additional'>
									<h3>DIRECTOR DE RRHH</h3>
									<hr />
									<p>Asesorar y contribuir en el proceso del cliente en dos líneas: respecto a su performance en las reuniones con perfiles de directores de RRHH y en la gestión de contactos.</p>
								</div>
							</Col>
							<Col xs={12}>
								<div className='modal-additional'>
									<h3>CEO</h3>
									<hr />
									<p>Asesorar y contribuir en el proceso del cliente en dos líneas: respecto a su performance en las reuniones con perfiles de GG y en la gestión de contactos.</p>
								</div>
							</Col>
							<Col xs={12}>
								<div className='modal-additional'>
									<h3>HEAD HUNTER</h3>
									<hr />
									<p>Asesorar y contribuir en el proceso del cliente en dos líneas: respecto a su performance en las entrevistas con HH y entregar información de mercado que le ayude en el proceso.</p>
								</div>
							</Col>
							<Col xs={12}>
								<div className='modal-additional'>
									<h3>MATCH CULTURAL</h3>
									<hr />
									<p>Identificar con Información sólida, aquellas empresas con las que el cliente tiene sintonía cultural. Se ocupa la plataforma The Bonding.</p>
								</div>
							</Col>
							<Col xs={12}>
								<div className='modal-additional'>
									<h3>CV</h3>
									<hr />
									<p>Armar un CV que refleje de manera profesional y asertiva la experiencia del cliente.</p>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button className='modal-close-button' onClick={onClose}>
					Cerrar
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default ModalAssignScreen
