// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalShowEmployees .buttonBlue {
  background: linear-gradient(45deg, #41C8FF 0%, #27E6C4 100%);
  border: none !important;
  border-radius: 50rem;
  padding: 0 10px;
  color: white;
  height: 35px;
  width: 200px;
}
.ModalShowEmployees .button-transparent {
  background: transparent !important;
  border: none !important;
  color: #40c2ff !important;
}
.ModalShowEmployees .border-cell {
  border: 2px solid #ccc;
  padding: 8px;
}
.ModalShowEmployees .z-1000 {
  z-index: 1000;
}
.ModalShowEmployees .scrollable-employees {
  overflow: hidden;
  overflow-y: auto;
}
.ModalShowEmployees .scrollable-employees input[type=checkbox] + label {
  cursor: pointer;
}
.ModalShowEmployees .scrollable-employees input[type=checkbox] {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Views/Service/Views/Documents/ModalShowEmployees.sass"],"names":[],"mappings":"AACI;EACI,4DAAA;EACA,uBAAA;EACA,oBAAA;EACA,eAAA;EACA,YAAA;EACA,YAAA;EACA,YAAA;AAAR;AAEI;EACI,kCAAA;EACA,uBAAA;EACA,yBAAA;AAAR;AAEI;EACI,sBAAA;EACA,YAAA;AAAR;AAEI;EACI,aAAA;AAAR;AAEI;EAEI,gBAAA;EACA,gBAAA;AADR;AAGQ;EACI,eAAA;AADZ;AAEQ;EACI,eAAA;AAAZ","sourcesContent":[".ModalShowEmployees\r\n    .buttonBlue\r\n        background: linear-gradient(45deg,#41C8FF 0%, #27E6C4 100%)\r\n        border: none !important\r\n        border-radius: 50rem\r\n        padding: 0 10px\r\n        color: white\r\n        height: 35px\r\n        width: 200px\r\n\r\n    .button-transparent\r\n        background: transparent !important\r\n        border: none !important\r\n        color: #40c2ff !important\r\n\r\n    .border-cell \r\n        border: 2px solid #ccc\r\n        padding: 8px\r\n\r\n    .z-1000\r\n        z-index: 1000\r\n\r\n    .scrollable-employees\r\n        // max-height: 250px\r\n        overflow: hidden\r\n        overflow-y: auto\r\n        \r\n        input[type=checkbox] + label\r\n            cursor: pointer\r\n        input[type=checkbox]\r\n            cursor: pointer\r\n        \r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
