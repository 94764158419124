import { useState } from "react";
import { HiMiniChevronDown, HiCurrencyDollar } from "react-icons/hi2";

import { MenuBands } from "../MenuBands";

export const TableBands = ({ response, country, refresh, band }) => {
    const [show, setShow] = useState(true);

    const handleShow = () => {
        setShow(!show);
    };

    if (response.length > 0) {
        return (
            <>
                <div className="row mt-5">
                    <div className="col-6">
                        <button
                            disabled={!response}
                            onClick={handleShow}
                            className="btn btn-outline-grey text-black rounded-pill px-3 d-flex justify-content-between align-items-center text-uppercase"
                        >
                            {country}
                            <HiMiniChevronDown size={20} className="ms-2" />
                        </button>
                    </div>
                </div>
                {show && response && (
                    <div className="row mt-3">
                        <div className="col">
                            <div className="list-group">
                                {response.map((item) => (
                                    <div
                                        className="list-group-item d-flex justify-content-between"
                                        key={item.id}
                                    >
                                        <div className="col d-flex align-items-center">
                                            {item.name}
                                        </div>
                                        <div className="col d-flex align-items-center">
                                            <HiCurrencyDollar
                                                size={20}
                                                className="text-dark-grey me-2"
                                            />
                                            {item.band}
                                        </div>
                                        <div className="col">
                                            <MenuBands
                                                country={country}
                                                item={item.id}
                                                refresh={refresh}
                                                band={item}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }

    return <></>;
};
