import { DefaultPattern } from "../../../../i18n/translatedMessages";
import { links } from "./externalLinks";

import "./ExternalPlatforms.sass";

const Cards = ({ links }) => (
  <div className="col">
    <a target={"_blank"} rel="noreferrer" href={links.link}>
      <div className="externalLinks-cards h-100">
        <div className="row h-100">
          <div className="col-3 col-img">
            <img
              className="img-fluid"
              src="https://assets.mydna.company/b2b-images/ExternalPlatformsIcon.svg"
              alt={links.name}
            />
          </div>
          <div className="col-9 col-text">
            <h6>{links.name}</h6>
            <p>{DefaultPattern.clickHereLink}</p>
          </div>
        </div>
      </div>
    </a>
  </div>
);

const ExternalPlatforms = () => {
  return (
    <div className="row">
      <div className="col d-flex justify-content-center offset-1">
        <div className="container ExternalPlatforms-main">
          <div className="row">
            <div className="col-md-1">
              <div className="header-icon">
                <img
                  src="https://assets.mydna.company/b2b-images/ExternalPlatformsIcon.svg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-6">
              <h1 className="mt-1">Plataformas externas</h1>
            </div>
          </div>

          <div className="row my-3 row-cols-3 g-3 justify-content-center">
            {links.map((link) => (
              <Cards key={link.id} links={link} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExternalPlatforms;
