import { AiOutlineDelete, AiOutlineEdit, AiOutlineCheckCircle } from 'react-icons/ai'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { employeeExperienceTranslate, modalAssignTranslate } from '../../../../i18n/translatedMessages'
const FormEduInfo = ({ data, index, updateEduInfo, handleChange, deleteEduInfo, handleSend }) => {
	const { id, university, type, course, startMonth, startYear, stillInProgress, endMonth, endYear } = data
	return (
		<div className='container formProfInfo-main'>
			<div className='row'>
				<div className='col-md-4'>
					<div className='mb-3'>
						<label htmlFor='formEduInfo-input1' className='form-label'>
							{employeeExperienceTranslate.formationEENameUniversity}
						</label>
						<input type='text' id='formEduInfo-input1' className='form-control text-center input-iformation m-0' aria-label='nombreUniversidad' name='university' value={university} onChange={e => handleChange(index, e)} />
					</div>
				</div>
				<div className='col-md-4'>
					<div className='mb-3'>
						<label htmlFor='formEduInfo-select1' className='form-label'>
							{employeeExperienceTranslate.formationEEType}
						</label>
						<select className='form-select select-iformation m-0' id='formEduInfo-select1' aria-label='Default select example' name='type' value={type} onChange={e => handleChange(index, e)}>
							<FormattedMessage id='historicProfessionalEEOption0'>{msg => <option>{msg}</option>}</FormattedMessage>
							<FormattedMessage id='formationEEOption1'>{msg => <option value='Técnico'>{msg}</option>}</FormattedMessage>
							<FormattedMessage id='formationEEOption2'>{msg => <option value='Profesional'>{msg}</option>}</FormattedMessage>
							<FormattedMessage id='formationEEOption3'>{msg => <option value='Diplomado'>{msg}</option>}</FormattedMessage>
							<FormattedMessage id='formationEEOption4'>{msg => <option value='MBA/Master'>{msg}</option>}</FormattedMessage>
							<FormattedMessage id='formationEEOption5'>{msg => <option value='Doctorado'>{msg}</option>}</FormattedMessage>
						</select>
					</div>
				</div>
				<div className='col-md-4'>
					<label htmlFor='formEduInfo-input2' className='form-label'>
						{employeeExperienceTranslate.formationEECourse}
					</label>
					<input type='text' id='formEduInfo-input2' className='form-control text-center input-iformation m-0' aria-label='curso' name='course' value={course} onChange={e => handleChange(index, e)} />
				</div>

				<div className='col-md-4'>
					<div className='row'>
						<label className='form-label'>{employeeExperienceTranslate.formationEEEntryDateCourse}</label>
						<div className='col-6'>
							<select className='form-select select-iformation rounded-pill m-0' aria-label='Default select example' name='startMonth' value={startMonth ? startMonth : 1} onChange={e => handleChange(index, e)}>
								<FormattedNumber id='EEMonth'>{msg => <option>{msg}</option>}</FormattedNumber>
								{Array.from({ length: 12 }).map((_, index) => {
									return (
										<option key={index} value={index + 1}>
											{index + 1}
										</option>
									)
								})}
							</select>
						</div>
						<div className='col-6'>
							<select className='form-select select-iformation rounded-pill m-0' aria-label='Default select example' name='startYear' value={startYear ? startYear : 2000} onChange={e => handleChange(index, e)}>
								<FormattedNumber id='EEYear'>{msg => <option>{msg}</option>}</FormattedNumber>
								{Array.from({ length: 56 }).map((_, index) => {
									return (
										<option key={index} value={index + 1969}>
											{index + 1969}
										</option>
									)
								})}
							</select>
						</div>
					</div>
				</div>

				<div className='col-md-3 d-flex align-items-end'>
					<div className='form-check d-flex'>
						<label className='form-check-label' htmlFor='formProfInfo-check1'>
							{employeeExperienceTranslate.formationEECurrentlyStuding}
						</label>
						<input className='form-check-input ms-2' type='checkbox' name='stillInProgress' id='formProfInfo-check1' onChange={e => handleChange(index, e)} />
					</div>
				</div>

				<div className={stillInProgress ? 'd-none' : 'col-md-4'}>
					<div className='row'>
						<label className='form-label'>{employeeExperienceTranslate.formationEELeaveDateCourse}</label>
						<div className='col-6'>
							<select className='form-select select-iformation rounded-pill m-0' aria-label='Default select example' name='endMonth' value={endMonth ? endMonth : 1} onChange={e => handleChange(index, e)}>
								<FormattedNumber id='EEMonth'>{msg => <option>{msg}</option>}</FormattedNumber>
								{Array.from({ length: 12 }).map((_, index) => {
									return (
										<option key={index} value={index + 1}>
											{index + 1}
										</option>
									)
								})}
							</select>
						</div>
						<div className='col-6'>
							<select className='form-select select-iformation rounded-pill m-0' aria-label='Default select example' name='endYear' value={endYear ? endYear : 2000} onChange={e => handleChange(index, e)}>
								<FormattedNumber id='EEYear'>{msg => <option>{msg}</option>}</FormattedNumber>
								{Array.from({ length: 56 }).map((_, index) => {
									return (
										<option key={index} value={index + 1969}>
											{index + 1969}
										</option>
									)
								})}
							</select>
						</div>
					</div>
				</div>
			</div>
			<div className='row justify-content-end text-end pt-4'>
				<div className='col-2'>
					<button data-expid={data.id} className='btn btn-saveinfo rounded-pill me-1' onClick={id ? e => updateEduInfo(index, e) : () => handleSend()}>
						{modalAssignTranslate.btnSave} <AiOutlineCheckCircle size={25} />
					</button>
				</div>
				<div className='col-2'>
					<button data-expid={data.id} className='btn btn-delinfo rounded-pill' onClick={e => deleteEduInfo(index, e)}>
						{modalAssignTranslate.deleteButton} <AiOutlineDelete size={25} />
					</button>
				</div>
			</div>
		</div>
	)
}

export default FormEduInfo
