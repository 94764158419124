// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CircleProgressBarSVG .CircleBG {
  fill: none;
  stroke: #ddd;
}
.CircleProgressBarSVG .CircleProg {
  fill: none;
  stroke: #7A6BE8;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.CircleProgressBarSVG .CircleText {
  font-weight: bold;
}

.FutureCVMain {
  color: #444258;
}
.FutureCVMain .bg-green {
  background-color: #50F8D5;
}
.FutureCVMain .bgBlue {
  background-color: #EFF9FF;
}
.FutureCVMain .borderBlue {
  border: 1.5px solid #B8E8FF;
}
.FutureCVMain .textPrimary {
  color: #7A6BE8;
}
.FutureCVMain .text-dark-purple {
  color: #686589;
}
.FutureCVMain .textBlue {
  color: #008ACE;
}
.FutureCVMain .bg-light-grey {
  background-color: #F6F6F9;
}
.FutureCVMain .borderGrey {
  border: 1.5px solid #D9D9E2;
}
.FutureCVMain .text-negative-red {
  color: #EC6376;
}
.FutureCVMain textarea.borderPurple {
  border: 2px solid #7A6BE8;
  border-color: #7A6BE8 !important;
  border-radius: 1rem;
  padding: 5px;
}

.btn.btnDescargarPDF {
  border: 2px solid #7A6BE8;
  color: #7A6BE8;
}`, "",{"version":3,"sources":["webpack://./src/Views/Service/Views/FutureCV/Components/FutureCV.sass"],"names":[],"mappings":"AACC;EACC,UAAA;EACA,YAAA;AAAF;AAEC;EACC,UAAA;EACA,eAAA;EACA,qBAAA;EACA,sBAAA;AAAF;AAEC;EACC,iBAAA;AAAF;;AAEA;EACC,cAAA;AACD;AAAC;EACC,yBAAA;AAEF;AADC;EACC,yBAAA;AAGF;AAFC;EACC,2BAAA;AAIF;AAFC;EACC,cAAA;AAIF;AAHC;EACC,cAAA;AAKF;AAJC;EACC,cAAA;AAMF;AALC;EACC,yBAAA;AAOF;AANC;EACC,2BAAA;AAQF;AAPC;EACC,cAAA;AASF;AARC;EACC,yBAAA;EACA,gCAAA;EACA,mBAAA;EACA,YAAA;AAUF;;AARA;EACE,yBAAA;EACA,cAAA;AAWF","sourcesContent":[".CircleProgressBarSVG\n .CircleBG\n  fill: none\n  stroke: #ddd\n\n .CircleProg\n  fill: none\n  stroke: #7A6BE8\n  stroke-linecap: round\n  stroke-linejoin: round\n\n .CircleText\n  font-weight: bold\n\n.FutureCVMain\n color: #444258\n .bg-green\n  background-color: #50F8D5\n .bgBlue\n  background-color: #EFF9FF\n .borderBlue\n  border: 1.5px solid #B8E8FF\n  // border-color: #B8E8FF\n .textPrimary\n  color: #7A6BE8\n .text-dark-purple\n  color: #686589\n .textBlue\n  color: #008ACE\n .bg-light-grey\n  background-color: #F6F6F9\n .borderGrey\n  border: 1.5px solid #D9D9E2\n .text-negative-red\n  color: #EC6376\n textarea.borderPurple\n  border: 2px solid #7A6BE8\n  border-color: #7A6BE8 !important\n  border-radius: 1rem\n  padding: 5px\n\n.btn.btnDescargarPDF\n  border: 2px solid #7A6BE8\n  color: #7A6BE8\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
