import "./culturaBar.sass"

const CulturaBarMatch = ({ percentagem, children, color = '#03a9f4' }) => {
    return (
        <>
            <div className="content-cultural-bar">
                <svg >
                    <circle cx="70" cy="70" r="55" />
                    <circle cx="70" cy="70" r="55" style={{ strokeDashoffset: `calc(440 - (440 * ${percentagem}) / 100)`, stroke: color }} />
                </svg>
                <div className="children">
                    {children}
                </div>
            </div>
            <div className="cultura-bar-number">
                <h2>{percentagem}<span>%</span></h2>
            </div>
        </>
    )
}
export default CulturaBarMatch