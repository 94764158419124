import {
	MdIndeterminateCheckBox,
	MdCheckBox,
	MdCheckBoxOutlineBlank,
} from "react-icons/md";

export const DuploCheck = ({ onClick, value }) => {
	return (
		<div className="text-primary" onClick={onClick}>
			{!value && <MdCheckBoxOutlineBlank size={23} color="#7a6be8" />}
			{value && <MdCheckBox size={23} color="#7a6be8" />}
		</div>
	);
};
export const TriploCheck = ({ onClick, value }) => {
	return (
		<div className="text-primary" onClick={onClick}>
			{value === "none" && (
				<MdCheckBoxOutlineBlank
					size={23}
					color="#7a6be8"
					className="text-primary"
				/>
			)}
			{value === "all" && (
				<MdCheckBox
					size={23}
					color="#7a6be8"
					className="text-primary"
				/>
			)}
			{value === "some" && (
				<MdIndeterminateCheckBox
					size={23}
					color="#7a6be8"
					className="text-primary"
				/>
			)}
		</div>
	);
};
