import { useContext, useEffect, useState } from "react";
import * as RB from "react-bootstrap";
import { AiOutlineCheckCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { FormattedMessage } from "react-intl";
import { getEmployee } from "../../API/Employee/Employee";
import { patchEnglishLevel } from "../../API/Employee/EnglishLevel";
import {
	deleteKnowledge,
	getKnowledge,
	postKnowledge,
	putKnowledge,
} from "../../API/Employee/Knowledge";
import Loading from "../../Components/Loading/Loading";
import ModalResponseStatus from "../../Components/ModalResponseStatus/ModalResponseStatus";
import { FormacionIcon } from "../../Icons/CollaboratorsIcons";
import { CompanyContext } from "../../Store/CompanyContext";
import {
	modalAssignTranslate,
	personalInfoTranslate,
	wheelCollaboratorTranslate,
} from "../../i18n/translatedMessages";
import FormEduInfo from "./Components/FormsInfo/FormEduInfo";
const InfoEducacion = () => {
	const objEmpty = {
		id: null,
		university: "",
		type: "",
		course: "",
		startMonth: "",
		startYear: "",
		stillInProgress: false,
		endMonth: "",
		endYear: "",
	};
	const { store, actions } = useContext(CompanyContext);
	const [user, setUser] = useState();

	const [englishLevel, setEnglishLevel] = useState("");

	const [educacionInfos, setEducacionInfos] = useState([objEmpty]);
	const [loading, setLoading] = useState(false);

	const [currentSelect, setCurrentSelect] = useState(null);

	useEffect(() => {
		setUser(JSON.parse(localStorage.getItem("user")));
		const users = JSON.parse(localStorage.getItem("user"));
		const getRequest = async () => {
			setLoading(true);
			const know = await getKnowledge(users.id);
			const knowSort = know.sort((x, y) =>
				x.endYear * 12 + x.endMonth > y.endYear * 12 + y.endMonth
					? -1
					: 1
			);
			knowSort?.length > 0
				? setEducacionInfos([...knowSort])
				: setEducacionInfos([objEmpty]);
			const employeeDataAux = await getEmployee(users.id);
			setEnglishLevel(
				employeeDataAux ? employeeDataAux.englishLevel : ""
			);
			setLoading(false);
		};
		getRequest();
	}, []);

	const handleSend = async () => {
		const objSend = educacionInfos
			.filter((filt) => filt.id === null)
			.map((x) => {
				return {
					university: x.university,
					type: x.type,
					course: x.course,
					startMonth: x.startMonth,
					startYear: x.startYear,
					endMonth: x.endMonth,
					endYear: x.endYear,
					employerId: user.id,
				};
			});
		if (objSend.length > 0) {
			const postEduInfo = await postKnowledge(objSend);
			if (postEduInfo.status < 200 || postEduInfo.status > 299) {
				if (postEduInfo.status > 399 && postEduInfo.status < 500) {
					actions.setResponseStatus(false);
					actions.setMessageResponseStatus("Ha ocurrido un error!");
					actions.setShowModalResponseStatus(true);
				} else {
					actions.setResponseStatus(false);
					actions.setMessageResponseStatus("Ha ocurrido un error!");
					actions.setShowModalResponseStatus(true);
				}
			} else {
				actions.setResponseStatus(true);
				actions.setMessageResponseStatus("Éxito");
				actions.setShowModalResponseStatus(true);
				const know = await getKnowledge(user.id);
				know?.length > 0
					? setEducacionInfos([...know])
					: setEducacionInfos([objEmpty]);
			}
		}
	};

	const handleChangeEnglishLevel = async (e) => {
		setEnglishLevel(e.currentTarget.value);
	};

	const handleSendEnglishLevel = async () => {
		await patchEnglishLevel(user.id, { englishLevel });
	};

	const handleAdd = () => {
		const auxArray = [...educacionInfos];
		auxArray.push(objEmpty);
		setEducacionInfos(auxArray);
		setCurrentSelect(auxArray.length - 1);
	};
	const handleUpdate = async (index, e) => {
		const objSend = educacionInfos.map((x) => {
			return {
				university: x.university,
				type: x.type,
				course: x.course,
				startMonth: x.startMonth,
				startYear: x.startYear,
				endMonth: x.endMonth,
				endYear: x.endYear,
				employerId: user.id,
			};
		});
		const expid = JSON.parse(e.currentTarget.dataset.expid);
		await putKnowledge(objSend[index], expid);
	};

	const handleClose = () => {
		actions.setShowModalResponseStatus(false);
	};

	const handleChange = (index, event) => {
		const auxArray = [...educacionInfos];
		auxArray[index][event.currentTarget.name] = event.currentTarget.value;
		auxArray[index].stillInProgress = event.currentTarget.checked;
		setEducacionInfos(auxArray);
	};

	const deleteEdu = async (index, e) => {
		const rows = [...educacionInfos];

		if (index > -1) {
			rows.splice(index, 1);
			setEducacionInfos([...rows]);
		}
		const expid = JSON.parse(e.currentTarget.dataset.expid);
		await deleteKnowledge(expid);
	};

	return (
		<div className="container-fluid">
			<FormattedMessage id={"nextButton"}>
				{(msg) => (
					<ModalResponseStatus
						show={store.showModalResponseStatus}
						handleClose={handleClose}
						message={store.messageResponseStatus}
						successMessage={store.responseStatus}
						btnRight={msg}
					/>
				)}
			</FormattedMessage>
			<div className="row mb-4">
				<div className="col-1">
					<span className="title-icon p-2 m-auto">
						<FormacionIcon size={20} />
					</span>
				</div>
				<div className="col-8">
					<h1>{wheelCollaboratorTranslate.formation}</h1>
				</div>
			</div>
			{loading ? (
				<Loading />
			) : (
				<div className="row justify-content-center">
					<div className="col-12 pt-4">
						<p>
							<strong>
								{personalInfoTranslate.personalEnglishLevel}
							</strong>
						</p>
						<div className="form-check form-check-inline">
							<input
								className="form-check-input"
								type="radio"
								name="englishLevel"
								id="englishLevel0"
								value="Nulo"
								onChange={handleChangeEnglishLevel}
								checked={englishLevel === "Nulo"}
							/>
							<label
								className="form-check-label"
								htmlFor="englishLevel0"
							>
								{personalInfoTranslate.personalELNulo}
							</label>
						</div>
						<div className="form-check form-check-inline">
							<input
								className="form-check-input"
								type="radio"
								name="englishLevel"
								id="englishLevel1"
								value="Basico"
								onChange={handleChangeEnglishLevel}
								checked={englishLevel === "Basico"}
							/>
							<label
								className="form-check-label"
								htmlFor="englishLevel1"
							>
								{personalInfoTranslate.personalELBasic}
							</label>
						</div>
						<div className="form-check form-check-inline">
							<input
								className="form-check-input"
								type="radio"
								name="englishLevel"
								id="englishLevel2"
								value="Intermedio"
								onChange={handleChangeEnglishLevel}
								checked={englishLevel === "Intermedio"}
							/>
							<label
								className="form-check-label"
								htmlFor="englishLevel2"
							>
								{personalInfoTranslate.personalELAverage}
							</label>
						</div>
						<div className="form-check form-check-inline">
							<input
								className="form-check-input"
								type="radio"
								name="englishLevel"
								id="englishLevel3"
								value="Avanzado"
								onChange={handleChangeEnglishLevel}
								checked={englishLevel === "Avanzado"}
							/>
							<label
								className="form-check-label"
								htmlFor="englishLevel3"
							>
								{personalInfoTranslate.personalELAdvanced}
							</label>
						</div>
						<div className="form-check form-check-inline">
							<input
								className="form-check-input"
								type="radio"
								name="englishLevel"
								id="englishLevel4"
								value="Fluido"
								onChange={handleChangeEnglishLevel}
								checked={englishLevel === "Fluido"}
							/>
							<label
								className="form-check-label"
								htmlFor="englishLevel4"
							>
								{personalInfoTranslate.personalELFluent}
							</label>
						</div>
						<div className="form-check form-check-inline">
							<button
								className="btn btn-saveinfo rounded-pill bg-blue"
								onClick={handleSendEnglishLevel}
							>
								{modalAssignTranslate.btnSave}{" "}
								<AiOutlineCheckCircle size={25} />
							</button>
						</div>
					</div>
					<div className="col-12 text-end">
						<button
							className="btn rounded-pill addEmployeeInfo-btn"
							onClick={handleAdd}
						>
							Add <AiOutlinePlusCircle size={25} />
						</button>
					</div>
					<div className="col-12 pt-4">
						{educacionInfos.map((item, index) => (
							// Esta solución es temporal, se debe mejorar el código para que el acordeon este cerrado por defecto, pero abierto cuando se agrega uno nuevo. Por alguna razon, defaultActiveKey no funciona.
							<div key={index}>
								{currentSelect === index ? (
									<>
										<RB.Accordion
											key={index}
											className="pb-3"
										>
											<RB.Accordion.Item className="shadow mb-1">
												<RB.Accordion.Header>
													{item.id ? (
														<>
															{item.course}{" "}
															{
																personalInfoTranslate.personalConective1
															}{" "}
															{item.university} de{" "}
															{item.startMonth}/
															{item.startYear}{" "}
															{
																personalInfoTranslate.personalConective2
															}{" "}
															{item.endMonth}/
															{item.endYear}
														</>
													) : (
														<>
															{
																personalInfoTranslate.personalAddInfo
															}
														</>
													)}
												</RB.Accordion.Header>
												<RB.Accordion.Body>
													<FormEduInfo
														key={index}
														data={item}
														index={index}
														handleChange={
															handleChange
														}
														handleSend={handleSend}
														updateEduInfo={
															handleUpdate
														}
														deleteEduInfo={
															deleteEdu
														}
													/>
												</RB.Accordion.Body>
											</RB.Accordion.Item>
										</RB.Accordion>
									</>
								) : (
									<>
										<RB.Accordion
											key={index}
											defaultActiveKey=""
											className="pb-3"
										>
											<RB.Accordion.Item className="shadow mb-1">
												<RB.Accordion.Header>
													{item.id ? (
														<>
															{item.course}{" "}
															{
																personalInfoTranslate.personalConective1
															}{" "}
															{item.university} de{" "}
															{item.startMonth}/
															{item.startYear}{" "}
															{
																personalInfoTranslate.personalConective2
															}{" "}
															{item.endMonth}/
															{item.endYear}
														</>
													) : (
														<>
															{
																personalInfoTranslate.personalAddInfo
															}
														</>
													)}
												</RB.Accordion.Header>
												<RB.Accordion.Body>
													<FormEduInfo
														key={index}
														data={item}
														index={index}
														handleChange={
															handleChange
														}
														handleSend={handleSend}
														updateEduInfo={
															handleUpdate
														}
														deleteEduInfo={
															deleteEdu
														}
													/>
												</RB.Accordion.Body>
											</RB.Accordion.Item>
										</RB.Accordion>
									</>
								)}
							</div>
						))}
					</div>
				</div>
			)}
		</div>
	);
};

export default InfoEducacion;
