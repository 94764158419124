import { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'

import Swal from 'sweetalert2'

import { getSalary, postSalary, putSalary } from '../../API/Employee/Salary'
import { getProfile, contractTypesList } from '../../API/Employee/Profile'
import { getEmployee } from '../../API/Employee/Employee'
import { CompanyContext } from '../../Store/CompanyContext'

import { btnTranslate, salaryTitle, wheelCollaboratorTranslate } from '../../i18n/translatedMessages'
import { personalInfoTranslate } from '../../i18n/translatedMessages'

import { CulturaIcon, HabilidadesIcon, SalarioIcon } from '../../Icons/CollaboratorsIcons'

import { useGenerateReport, useSalaryCalculation } from '../../hooks'

import HeaderCarouselMandala from './Components/HeaderCarouselMandala/HeaderCarouselMandala'
import ModalResponseStatus from '../../Components/ModalResponseStatus/ModalResponseStatus'
import TablePositionItem from '../Services/Components/PayBalance/Components/TablePositionItemAnalytics'

const Salario = () => {
	const language = useIntl()
	const { id } = useParams()

	const { store, actions } = useContext(CompanyContext)

	const [showModalEdit, setShowModalEdit] = useState(false)
	const [employeeInfoData, setEmployeeInfoData] = useState([])

	const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')))
	const [userid, setUserid] = useState()
	const [colabId, setColabId] = useState()
	const [colabEmail, setColabEmail] = useState(null)

	const [salaryInfo, setSalaryInfo] = useState({ contract: null })
	const [contractList, setContractList] = useState([])

	const objEmpty = {
		id: id,
		salary: '',
		fixedSalary: '',
		typeCurrency: '',
		lastAdjustDate: '',
		contract: '',
	}

	const profileEmpty = {
		employerId: id,
		mailPersonal: '',
		phone: '',
		document: '',
		birth: '',
		linkedIn: '',
		facebook: '',
		instagram: '',
		skype: '',
		twitter: '',
		gender: '',
		maritalStatus: '',
		childrens: '',
		city: '',
		country: '',
		address: '',
		nationality: '',
		transport: '',
		contract: '',
		boss: '',
		bank: '',
		currentAccount: '',
		healthPlan: '',
		height: 0,
		weight: 0,
		enfermedad: '',
		pcd: '',
		typePCD: '',
		hobies: '',
		sports: '',
		interests: '',
		voluntaryWork: '',
	}

	const [employeeProfile, setEmployeeProfile] = useState(profileEmpty)

	const { handleReport } = useGenerateReport()
	const calculation = useSalaryCalculation(userid, employeeInfoData)

	const smtmRequest = async colabEmail => {
		if (colabEmail) {
			try {
				const data = await fetch(`https://smtm.co/v1/position_by_mydna_email?email=${colabEmail}`)
				const rest = await data.json()
				setEmployeeInfoData(rest)
			} catch (error) {
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Algo salió mal !',
				})
			}
		} else {
			console.warn('Email no válido o undefined en smtmRequest')
		}
	}

	useEffect(() => {
		setUserid(id)

		if (colabEmail !== undefined && colabEmail) {
			smtmRequest(colabEmail)
		}
	}, [id, colabEmail])

	useEffect(() => {
		async function fetchData() {
			const response = await getEmployee(id)
			setColabId(response.userId)
			if (response?.employerUser && response?.employerUser?.email && response?.employerUser?.email !== undefined) {
				setColabEmail(response?.employerUser?.email)
			}
		}
		fetchData()
	}, [userid])

	const asyncRequests = async () => {
		const [profileAux] = await getProfile(id)
		const salary = await getSalary(id)

		const salaryMap = salary[salary.length - 1]
		salary?.length > -1
			? setSalaryInfo({
					...salaryMap,
					contract: profileAux?.contract,
			  })
			: setSalaryInfo([objEmpty])
		await getSalary(id)
		setContractList(await contractTypesList())
		setEmployeeProfile(profileAux)
	}

	useEffect(() => {
		setUser(JSON.parse(localStorage.getItem('user')))
		asyncRequests()
	}, [])

	const handleChange = e => {
		const { name, value } = e.target

		setSalaryInfo({
			...salaryInfo,
			[name]: value,
		})
	}

	const handleSend = async (e, salid) => {
		if (
			salaryInfo.salary === null ||
			salaryInfo.salary === '' ||
			salaryInfo.fixedSalary === null ||
			salaryInfo.fixedSalary === '' ||
			salaryInfo.typeCurrency === null ||
			salaryInfo.typeCurrency === '' ||
			salaryInfo.lastAdjustDate === null ||
			salaryInfo.lastAdjustDate === ''
		) {
			Swal.fire({
				title: 'Hola 👋',
				text: 'Por favor completa todos los campos',
				icon: 'info',
				confirmButtonText: '👌',
			})
		} else {
			const objSalarySend = {
				salary: salaryInfo.salary,
				fixedSalary: salaryInfo.fixedSalary,
				typeCurrency: salaryInfo.typeCurrency,
				employerId: id,
				lastAdjustDate: salaryInfo.lastAdjustDate,
			}
			const postorputSalary = salid ? await putSalary(objSalarySend, salid, colabEmail) : await postSalary(objSalarySend, colabEmail)
			smtmRequest(colabEmail)
			asyncRequests()
			setShowModalEdit(false)
			Swal.fire({
				icon: 'success',
			})
		}
	}

	const handleClose = () => actions.setShowModalResponseStatus(false)

	const formatedDate = date => {
		const newDate = new Date(date)
		return `${('0' + newDate.getDate()).slice(-2)}/${('0' + (newDate.getMonth() + 1)).slice(-2)}/${newDate.getFullYear()}`
	}

	return (
		<div className='container-fluid'>
			<Modal show={showModalEdit} onHide={() => setShowModalEdit(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Editar Salario</Modal.Title>
				</Modal.Header>
				<div className='row justify-content-center pt-5 pb-3'>
					<div className='col-12'>
						<div className='container-fluid'>
							<div className='row align-items-center justify-content-center mb-4'>
								<div className='col'>
									<label htmlFor='employeeSalary' className='color-blue form-label'>
										{personalInfoTranslate.personalFixBruteSalary}
									</label>
									<input className='form-control text-center m-0' name='salary' type='text' aria-label='salario' value={salaryInfo?.salary} onChange={e => handleChange(e)} />
								</div>
								<div className='col'>
									<label htmlFor='employeeTypeCurrency' className='form-label'>
										{personalInfoTranslate.personalCoin}
									</label>
									<select className='form-select rounded-pill m-0' aria-label='Default select example' name='typeCurrency' value={salaryInfo?.typeCurrency} onChange={e => handleChange(e)}>
										<option>Seleccione...</option>
										<option value='AR$'>AR$</option>
										<option value='CLP'>CLP</option>
										<option value='COP'>COP</option>
										<option value='MEX'>MEX</option>
										<option value='R$'>R$</option>
										<option value='S/.'>S/.</option>
										<option value='USD'>USD</option>
									</select>
								</div>
							</div>
						</div>
					</div>
					<div className='col-12'>
						<div className='container-fluid'>
							<div className='row align-items-center justify-content-center mb-4'>
								<div className='col'>
									<label htmlFor='employeeFixedSalary' className='form-label'>
										{personalInfoTranslate.personalVariableSalary}
									</label>
									<select className='form-select rounded-pill m-0' aria-label='Default select example' name='fixedSalary' value={salaryInfo?.fixedSalary} onChange={e => handleChange(e)}>
										<option>Seleccione...</option>
										{Array.from({ length: 20 }).map((_, index) => {
											return (
												<option key={index} value={index / 2 + 0.5}>
													{index / 2 + 0.5}
												</option>
											)
										})}
									</select>
								</div>
								<div className='col'>
									<label htmlFor='lastAdjustDate' className='form-label'>
										Último ajuste salarial:
									</label>
									<input type='date' id='lastAdjustDate' name='lastAdjustDate' className='form-control rounded-pill' value={salaryInfo?.lastAdjustDate} onChange={e => handleChange(e)} />
								</div>
							</div>
						</div>
					</div>
					<div className='col-12 px-4'>
						<button className='btn rounded-pill btn-salvar w-100' data-salid={salaryInfo?.id} onClick={e => handleSend(e, salaryInfo.id)}>
							{btnTranslate.saveButton}
						</button>
					</div>
				</div>
			</Modal>

			<FormattedMessage id='nextButton'>
				{msg => <ModalResponseStatus show={store.showModalResponseStatus} handleClose={handleClose} message={store.messageResponseStatus} successMessage={store.responseStatus} btnRight={msg} />}
			</FormattedMessage>
			<div className='row mb-4'>
				<div>
					<HeaderCarouselMandala
						actualTitle={wheelCollaboratorTranslate.salary}
						prevTitle={wheelCollaboratorTranslate.culture}
						routePrev={`/app/analytic/cultura/${id}`}
						nextTitle={wheelCollaboratorTranslate.habilities}
						routeNext={`/app/analytic/habilidades/${id}`}
						iconActual={<SalarioIcon />}
						iconPrev={<CulturaIcon />}
						iconNext={<HabilidadesIcon />}
					/>
				</div>
				<div className='row'>
					<div className='col'>
						<TablePositionItem readonly onReport={handleReport} companyPositions={employeeInfoData} />
					</div>
				</div>
				<div className='row'>
					<div className='col mt-5'>
						<button className='btn rounded-pill btn-salvar px-5' data-salid={salaryInfo?.id} onClick={() => setShowModalEdit(true)}>
							Editar
						</button>
					</div>
				</div>

				<div className='row mt-5'>
					<div className='col'>
						<div className='row'>
							{calculation.salaryAnnual && (
								<div className='col-6'>
									<strong className='col-auto p-2'>Salario Anual</strong>
									<p className='col-auto px-2'>{calculation.salaryAnnual}</p>
								</div>
							)}
							{calculation.contrat && (
								<div className='col-6'>
									<strong className='col-4 p-2'>Tipo de Contrato</strong>
									<p className='col-4 px-2'>
										{contractList
											?.filter(contractFilt => employeeProfile?.contract === contractFilt.id)
											.map(contract => {
												return contract.type
											})}
									</p>
								</div>
							)}
							{employeeInfoData[0]?.updated_at && (
								<div className='col-6'>
									<strong className='col-auto p-2'>{language.locale == 'ES' ? 'Fecha del último ajuste de salario' : 'Data do último ajuste Salarial'}</strong>
									<p className='col-auto px-2'>{formatedDate(employeeInfoData[0]?.updated_at)}</p>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Salario
