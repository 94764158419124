import { FormattedMessage } from "react-intl";
import AssignScreen from "./Components/AssignScreen";

const MotivationsTest = () => {
    return (
        <div className="row">
            <div className="col offset-1">
                <FormattedMessage id={"motivacionalTest"}>  
                        {msg=>(
                            <AssignScreen title={msg} />
                        )}
                    </FormattedMessage>
            </div>
        </div>
    );
};

export default MotivationsTest;
