export function ObjectiveCriteria({ title, content }) {
	return (
		<div className='tw-block tw-max-w-sm tw-h-full tw-p-6 tw-rounded-lg  tw-bg-[#EFF1FE]'>
			<h5 className='mb-2 tw-h-[35px] tw-w-[35px] tw-flex tw-items-center tw-justify-center tw-text-white tw-bg-[#7A6BE8]  tw-rounded-full '>{title}</h5>
			<p className='tw-font-normal'>{content}</p>
		</div>
	)
}
/**
 *
 * hover:tw-bg-[#FEF3C6]
 * hover:tw-bg-[#F49E0B]
 */
