import { dashboardClimeTranslate } from "../../i18n/translatedMessages";
import Timeline from "./Components/Timeline/Timeline";

const Form = () => {
    return (
        <>
            <div className="row my-5">
                <div className="col-8 offset-2">
                    <p className="my-3">
                        {dashboardClimeTranslate.textInformationBusinessCulture}
                    </p>

                    <p className="my-3">
                        {
                            dashboardClimeTranslate.textInformationBusinessCulture2
                        }
                    </p>
                </div>
            </div>
            <Timeline step="0" />
        </>
    );
};
export default Form;
