import { useState } from "react";
import { HiExclamationTriangle } from "react-icons/hi2";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import Loading from "../../../../../../Components/Loading/Loading";
import Bands from "../../../../../../API/Services/PayBalance/Bands";
import {
    btnTranslate,
    salaryRangeTranslate,
} from "../../../../../../i18n/translatedMessages";

export const DeleteBandModal = ({ handleClose, show, id, refresh }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const handleDelete = async () => {
        try {
            setLoading(true);
            await Bands.deleteBands(id);
        } catch (err) {
            setError(true);
            console.error("ERRO: ", err);
        } finally {
            refresh();
            setLoading(false);
            // handleClose();
        }
    };

    return (
        <Modal show={show} onHide={handleClose} centered className="rounded-3">
            {loading && <Loading size="sm" />}
            {!loading && (
                <>
                    {!error && (
                        <>
                            <Modal.Body>
                                <div className="p-3 d-flex align-items-center">
                                    <HiExclamationTriangle
                                        size={130}
                                        className="text-negative-red me-3"
                                    />
                                    <h3>
                                        {
                                            salaryRangeTranslate.salaryRangeConfirmDelete
                                        }
                                    </h3>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    onClick={handleClose}
                                    className="rounded-pill text-black px-3"
                                    variant="outline-grey"
                                >
                                    {btnTranslate.closeButton}
                                </Button>
                                <Button
                                    onClick={handleDelete}
                                    className="rounded-pill px-4"
                                    variant="negative-red"
                                >
                                    Confirmar
                                </Button>
                            </Modal.Footer>
                        </>
                    )}
                    {error && (
                        <>
                            <Modal.Body>
                                <div className="p-3 d-flex align-items-center">
                                    <HiExclamationTriangle
                                        size={130}
                                        className="text-negative-red me-3"
                                    />
                                    <h4 className="text-center">
                                        Para que una banda sea eliminada no debe
                                        tener ningún colaborador asociado.
                                    </h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    onClick={handleClose}
                                    className="rounded-pill px-5"
                                    variant="outline-primary"
                                >
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </>
                    )}
                </>
            )}
        </Modal>
    );
};
