export const postCampaign = async (data, companyID) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API}/company/campaign/${companyID}`, {
        method: "POST",
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(data),
      });
      return response.json();
    } catch (err) {
      console.error(err);
    }
  };