export const translate = {
  es: {
    Capacitarme: "Capacitarme",
    "Aprender un nuevo idioma": "Aprender un nuevo idioma",
    "Mejorar mi formación académica": "Mejorar mi formación académica",
    "Ampliar nuevo softskill": "Ampliar nuevo softskill",
    "Ampliar networking": "Ampliar networking",
    "Mejorar Marca Personal": "Mejorar Marca Personal",
    "Ser experto en mi área": "Ser experto en mi área",
    "Mejores Benefícios": "Mejores Benefícios",
    "Remuneración por performance": "Remuneración por performance",
    "Trabajar con algo que domino": "Trabajar con algo que domino",
    "Contribuir con algo importante": "Contribuir con algo importante",
    "Como un Líder en mi área": "Como un Líder en mi área",
    "Un Ascenso en el corto Plazo": "Un Ascenso en el corto Plazo",
    "Trabajar en otra área": "Trabajar en otra área",
    "Trabajar con Creatividad": "Trabajar con Creatividad",
    "Trabajar con Numeros / logíca": "Trabajar con Numeros / logíca",
    "Trabajar en contacto con el publico": "Trabajar en contacto con el publico",
    "Trabajar con Tecnología": "Trabajar con Tecnología",
    "Trabajar en tereno": "Trabajar en tereno",
    "Trabajar en equipo": "Trabajar en equipo",
    "Trabajar Solo": "Trabajar Solo",
    "Como un Consultor externo": "Como un Consultor externo",
    "Como un Empresario": "Como un Empresario",
    "Trabajar en una ONG": "Trabajar en una ONG",
    "Trabajar en el sector publico": "Trabajar en el sector publico",
    "Trabajar en otra industria": "Trabajar en otra industria",
    "Trabajar en una Startup": "Trabajar en una Startup",
    "Trabajar en una Grande Empresa": "Trabajar en una Grande Empresa",
    "Empresa Innovadora": "Empresa Innovadora",
    "Empresa Meritocrática": "Empresa Meritocrática",
    "Empresa Ética": "Empresa Ética",
    "Ambiente Colaborativo": "Ambiente Colaborativo",
    "Ambiente Agresivo y competitivo": "Ambiente Agresivo y competitivo",
    "Trabajar con algo desafiador": "Trabajar con algo desafiador",
    "Trabajo remoto": "Trabajo remoto",
    "Trabajo híbrido": "Trabajo híbrido",
    "Trabajo presencial": "Trabajo presencial",
    "Horario flexible": "Horario flexible",
    "Escribir un livro": "Escribir un livro",
    "Hacer volutariado": "Hacer volutariado",
    "Participar de un Podcast": "Participar de un Podcast",
    "Plan de Jubilacion": "Plan de Jubilacion",
    "Ahorrar plata": "Ahorrar plata",
    "Año Sabatico": "Año Sabatico",
    "Llife Balance": "Llife Balance",
    "Viajar por el mundo": "Viajar por el mundo",
    "Dejar un legado": "Dejar un legado",
  },

  pt: {
    Capacitarme: "Me Capacitar",
    "Aprender un nuevo idioma": "Aprender um Novo Idioma",
    "Mejorar mi formación académica": "Melhorar minha Formação Acadêmica",
    "Ampliar nuevo softskill": "Expandir nova Softskill",
    "Ampliar networking": "Expandir Network",
    "Mejorar Marca Personal": "Melhorar a Marca Pessoal",
    "Ser experto en mi área": "Ser um Especialista na minha Área",
    "Mejores Benefícios": "Melhores Benefícios",
    "Remuneración por performance": "Remuneração por Performance",
    "Trabajar con algo que domino": "Trabalhar com algo que eu domino",
    "Contribuir con algo importante": "contribuir com algo importante",
    "Como un Líder en mi área": "Como Líder na minha área",
    "Un Ascenso en el corto Plazo": "Uma promoção no curto prazo",
    "Trabajar en otra área": "Trabalhar em outra área",
    "Trabajar con Creatividad": "Trabalhe com criatividade",
    "Trabajar con Numeros / logíca": "Trabalhar com números / lógica",
    "Trabajar en contacto con el publico": "Trabalhar em contato com o público",
    "Trabajar con Tecnología": "Trabalhe com tecnologia",
    "Trabajar en tereno": "trabalho em campo",
    "Trabajar en equipo": "Trabalhar em equipe",
    "Trabajar Solo": "Trabalhar sozinho",
    "Como un Consultor externo": "Como consultor externo",
    "Como un Empresario": "Como empresário",
    "Trabajar en una ONG": "Trabalhar em uma ONG",
    "Trabajar en el sector publico": "Trabalhar no setor público",
    "Trabajar en otra industria": "trabalhar em outro setor",
    "Trabajar en una Startup": "Trabalhe em uma startup",
    "Trabajar en una Grande Empresa": "Trabalhe em uma grande empresa",
    "Empresa Innovadora": "Empresa Inovadora",
    "Empresa Meritocrática": "Empresa meritocrática",
    "Empresa Ética": "Empresa Ética",
    "Ambiente Colaborativo": "Ambiente Colaborativo",
    "Ambiente Agresivo y competitivo": "Ambiente agressivo e competitivo",
    "Trabajar con algo desafiador": "Trabalhar com algo desafiador",
    "Trabajo remoto": "trabalho remoto",
    "Trabajo híbrido": "trabalho híbrido",
    "Trabajo presencial": "trabalho presencial",
    "Horario flexible": "Horário flexível",
    "Escribir un livro": "Escrever um livro",
    "Hacer volutariado": "Trabalho voluntário",
    "Participar de un Podcast": "Participar de um podcast",
    "Plan de Jubilacion": "Plano de aposentadoria",
    "Ahorrar plata": "Economizar",
    "Año Sabatico": "Ano sabatico",
    "Llife Balance": "Llife Balance",
    "Viajar por el mundo": "Equilíbrio da vida",
    "Dejar un legado": "Deixar um legado",
  },
};
