import { useContext, useEffect, useState } from "react";
import { MdPerson } from "react-icons/md";
import Swal from "sweetalert2";
import { getEmployeesByCompany } from "../../API/Employee/Employee";
import { postCampaign } from "../../API/Services/Campaign/Campaign";
import { EmployerContext } from "../../Store/EmployerContext";
import { campaign } from "../../i18n/translatedMessages";
import "./campaign.sass";

const Campaign = () => {
	const [selected, setSelected] = useState(false);
	const [employeeList, setEmployeeList] = useState([]);
	const [employeesAssigned, setEmployeesAssigned] = useState([]);
	const [allAssigned, setAllAssigned] = useState([]);
	const [busca, setBusca] = useState("");
	const { store } = useContext(EmployerContext);
	const [isLoadingNew, setIsLoadingNew] = useState(false);

	useEffect(async () => {
		const users = JSON.parse(localStorage.getItem("user"));
		const list = await getEmployeesByCompany(users.id);
		selected
			? setEmployeeList(
					employeeList.filter((item) => item.progress !== "100")
			  )
			: setEmployeeList(list);
	}, [selected]);

	const handleChangeAssign = (e) => {
		const listAux = [...employeesAssigned, e.currentTarget.value];
		if (e.currentTarget.checked) {
			setEmployeesAssigned([...listAux]);
		} else {
			const auxEmployeeAssign = listAux.filter(
				(lista) => lista !== e.currentTarget.value
			);
			setEmployeesAssigned(auxEmployeeAssign);
		}
	};

	const alertSend = () => {
		if (employeesAssigned.length === 0) {
			Swal.fire(
				store.language == "ES"
					? "Por favor seleccionar un colaborador"
					: "Por favor selecionar um colaborador",
				"",
				"warning"
			);
			return;
		}

		Swal.fire({
			title:
				store.language == "ES"
					? "Confirmar envio de emails a los colaboradores seleccionados"
					: "Confirme o envio de e-mails para colaboradores selecionados",
			showDenyButton: false,
			showCancelButton: true,
			cancelButtonText: "Cancelar",
			confirmButtonText: "Enviar",
			confirmButtonColor: "#0AB4C4",
			cancelButtonColor: "#EC6376",
			reverseButtons: true,
		}).then((result) => {
			if (result.isConfirmed) {
				handleSendRequests();
			}
		});
		return;
	};

	const handleSendRequests = async () => {
		setIsLoadingNew(true);
		const company = JSON.parse(localStorage.getItem("user"));
		const companyID = company.id;
		const auxAll = allAssigned.map((allId) => Number(allId.employerId));
		const auxEmployeeAssign = employeesAssigned.filter(
			(filt) => filt !== undefined && auxAll.indexOf(Number(filt)) < 0
		);

		const emailListSend = { employersList: auxEmployeeAssign };

		const sendData = await postCampaign(emailListSend, companyID);
		setIsLoadingNew(false);
		unCheck();
		setEmployeesAssigned([]);
		sendData.message == "require sended"
			? Swal.fire(
					store.language == "ES"
						? "Enviado con Éxito"
						: "Enviado com sucesso",
					"",
					"success"
			  )
			: Swal.fire(
					store.language == "ES"
						? "Error al enviar emails"
						: "Erro ao enviar email",
					"",
					"error"
			  );
	};

	const lowerBusca = busca.toLowerCase();

	const employeesListFilt = employeeList.filter((employee) => {
		return employee.name.toLowerCase().includes(lowerBusca);
	});

	const unCheck = () => {
		let x = document.getElementsByClassName("assign-custom-checkbox");
		for (const item of x) {
			item.checked = false;
		}
	};

	return (
		<div className="content-campaign">
			<div className="col-12">
				{isLoadingNew && (
					<>
						<div className="blockui">
							<div className="custom-container-spinner">
								<div className="lds-dual-ring"></div>
							</div>
						</div>
					</>
				)}
			</div>
			<div className="row">
				<div className="col-8 offset-2 d-flex">
					<MdPerson size={35} />
					<h2>{campaign.campaignTitle}</h2>
				</div>
			</div>
			<div className="row my-3">
				<div className="col-8 offset-2">
					<p>{campaign.campaignTxt}</p>
				</div>
			</div>
			<div className="row">
				<div className="col-8 offset-2 d-flex justify-content-end">
					<button onClick={alertSend} className="btn-send-campaign">
						Enviar
					</button>
				</div>
			</div>
			<div className="row mt-4">
				<div className="col-8 offset-2">
					<ul className="header">
						<li className="row">
							<div className="col-3 ps-5">
								{campaign.campaignTableName}
							</div>
							<div className="col-3 ">
								{campaign.campaignTableMail}
							</div>
							{/* <div className="col-2">
                                {campaign.campaignTableArea}
                            </div> */}
							<div className="col-3">
								{campaign.campaignTableJob}
							</div>
						</li>
					</ul>
					<ul className="scrolling">
						{employeeList.map((employee) => (
							<li className="row" key={employee.id}>
								<div className="col-3 ps-1 d-flex align-items-center">
									<label
										className="col-3"
										htmlFor={`checkUser${employee.id}`}
									>
										<input
											className="assign-custom-checkbox"
											name={`checkUser${employee.id}`}
											type="checkbox"
											value={employee.id}
											id={`checkUser${employee.id}`}
											onChange={handleChangeAssign}
										/>
										<span className="checkmark"></span>
									</label>
									{employee.name}
								</div>
								<div className="col-3">{employee.email}</div>
								{/* <div className="col-2">
                                    {employee.employerSubAreas
                                        ? employee.employerSubAreas.name
                                        : ""}
                                </div> */}
								<div className="col-3">{employee.jobName}</div>
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
};
export default Campaign;
