export const sendComplaint = async (data) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API}/employeedenunciate`,
            {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                },
                body: JSON.stringify(data)
            })
        return response.json()
    } catch (err) {
        console.error(err)
    }
}