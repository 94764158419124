import { useState, useRef, useEffect } from "react";
import { MdOutlinePeopleOutline, MdOutlineOpenInNew } from "react-icons/md";
import { BiBriefcase, BiWorld } from "react-icons/bi";
import { BsTrash, BsFilterCircle } from "react-icons/bs";
import { marked } from "marked";

import DropFilter from "../../../Components/Filters/DropFilter";
import { postMessage } from "../../../API/InternalChannel/InternalChannel";
import { DefaultPattern, btnTranslate, internalChannel } from "../../../i18n/translatedMessages";
import { useListFilters } from "../../../Components/Filters/ListFilters/useListFilters";
import MDEditor, { commands } from "@uiw/react-md-editor";
import AttachmentFile from "./AttachmentFile";

const MailButton = ({ getMessagesRequest, verifyLength }) => {
  const user = JSON.parse(localStorage.getItem("user"));

  const titleRef = useRef(null);
  const descRef = useRef(null);

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  const emptyObj = {
    title: "",
    description: "",
    employees: [],
    companyId: user.id,
  };

  const [sendMessage, setSendMessage] = useState(emptyObj);
  const [message, setMessage] = useState("");
  const [messageToHtml, setmessageToHtml] = useState("");

  useEffect(() => {
    const renderer = new marked.Renderer();
    renderer.paragraph = (text) => {
      return `<p style="margin-top: 17px;">${text}</p>`;
    };

    renderer.image = (href, title, text) => {
      return `<img src="${href}" title="${title}" alt="${text}" style="max-width: 50%; margin-top: 17px;">`;
    };

    renderer.code = (text) => {
      return `<code style="background-color: #f6f8fa; margin-top: 17px; padding: 6px;"> ${text} </code>`;
    };

    renderer.blockquote = (text) => {
      return `<blockquote style="
            background-color: #f6f8fa;
            padding: 5px;
            border-radius: 10px;
            margin-top: 17px;
        "> ${text} </blockquote>`;
    };

    const newHtml = marked(message, { renderer });

    setmessageToHtml(marked(newHtml));
  }, [message]);

  useEffect(() => {
    setmessageToHtml(marked(message));
  }, [message]);

  const {
    setSearch,
    setFilterCountryCheck,
    setFilterAreaCheck,
    setFilterLevelCheck,
    filterAreaCheck,
    filterCountryCheck,
    filterLevelCheck,
    area,
    level,
    country,
    filterEmployees,
  } = useListFilters();

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(filterEmployees.map((li) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {
    const { value, checked } = e.target;
    setIsCheck([...isCheck, Number(value)]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== Number(value)));
    }
  };

  const handleSearch = (e) => {
    if (e.length >= 3) {
      setSearch(e);
    } else {
      setSearch("");
    }
  };

  const handleChange = (e) => {
    setSendMessage({ ...sendMessage, [e.target.name]: e.target.value });
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleSend = async () => {
    if (isCheck.length) {
      const sendAux = {
        ...sendMessage,
        description: message.replace(/\n/g, " \n "),
        descriptionInHtmlTags: messageToHtml,
        employees: isCheck,
      };
      postMessage(sendAux)
        .then(await delay(2000), getMessagesRequest())
        .then(clearStates());
    }
  };

  const clearStates = () => {
    setSearch("");
    setIsCheckAll(false);
    setIsCheck([]);
    setSendMessage(emptyObj);
    setMessage("");
    titleRef.current.value = "";
    descRef.current.value = "";
    setFilterCountryCheck([]);
    setFilterAreaCheck([]);
    setFilterLevelCheck([]);
  };

  return (
    <div className="window-fixed-bottom d-inline-block">
      <button
        type="button"
        className="btn btn-openWindow rounded-pill ms-5 d-inline-block"
        data-bs-toggle="modal"
        data-bs-target="#mailBox"
      >
        {internalChannel.internalChannelNewMessage} <MdOutlineOpenInNew size={20} />
      </button>
      <div
        className="modal fade"
        id="mailBox"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-header rounded-top py-2 purple-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {internalChannel.internalChannelNewMessage}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="card card-fixed-bottom">
                <div className="card-body">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-6">
                        <input
                          type="text"
                          className="form-control my-0"
                          placeholder="Colaboradores"
                          onChange={(e) => handleSearch(e.target.value)}
                        />
                        <div className="row row-cols-3">
                          <div className="col">
                            <DropFilter
                              changeAppearence={filterCountryCheck.length}
                              icon={<BiWorld />}
                              filterName="Country"
                              setFiltValues={setFilterCountryCheck}
                              filtValues={filterCountryCheck}
                              list={country}
                            />
                          </div>
                          <div className="col">
                            <DropFilter
                              icon={<BiBriefcase />}
                              filterName="Area"
                              setFiltValues={setFilterAreaCheck}
                              filtValues={filterAreaCheck}
                              list={area}
                            />
                          </div>
                          <div className="col">
                            <DropFilter
                              icon={<MdOutlinePeopleOutline />}
                              filterName="Level"
                              setFiltValues={setFilterLevelCheck}
                              filtValues={filterLevelCheck}
                              list={level}
                            />
                          </div>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={"all_colab"}
                            onChange={handleSelectAll}
                            checked={isCheckAll}
                          />
                          <label className="forms-check-label" htmlFor={"all_colab"}>
                            <strong>{DefaultPattern.selectAllLabel}</strong>
                          </label>
                        </div>
                        <div className="employeeList">
                          {filterEmployees?.map((emp, key) => {
                            return (
                              <div className="form-check" key={key}>
                                <input
                                  className="form-check-input "
                                  type="checkbox"
                                  value={emp?.id}
                                  id={key + "_colab"}
                                  onChange={handleClick}
                                  checked={isCheck.includes(emp?.id)}
                                />
                                <label className="form-check-label" htmlFor={key + "_colab"}>
                                  {emp?.name}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="d-flex flex-column">
                          <div>
                            <input
                              type="text"
                              ref={titleRef}
                              name="title"
                              className="form-control my-0"
                              placeholder="Tema"
                              onChange={handleChange}
                            />
                            {/* <textarea ref={descRef} name="description" className="form-control mt-3" rows="13" placeholder="Escribe tu mensaje" onChange={handleChange} /> */}
                            <MDEditor
                              className="mt-3"
                              commands={[
                                commands.title1,
                                commands.bold,
                                commands.italic,
                                commands.strikethrough,
                                commands.divider,
                                commands.unorderedListCommand,
                                commands.orderedListCommand,
                                commands.codeBlock,
                                commands.divider,
                                commands.hr,
                                commands.link,
                                commands.quote,
                                commands.fullscreen,
                                commands.image,
                              ]}
                              value={message}
                              textareaProps={{ name: "description" }}
                              extraCommands={[
                                commands.codeEdit,
                                commands.codeLive,
                                commands.codePreview,
                              ]}
                              onChange={setMessage}
                              preview="live"
                            />
                          </div>
                          {/* Not delete pls. Current working here */}

                          {/* <div>
                                                        <AttachmentFile />
                                                    </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="me-auto">
                  <button
                    className="btn btn-blue rounded-pill"
                    data-bs-dismiss="modal"
                    onClick={handleSend}
                  >
                    {btnTranslate.saveButton}
                  </button>
                </div>
                <div className="ms-auto">
                  <button
                    className="btn"
                    data-bs-dismiss="modal"
                    type="button"
                    onClick={clearStates}
                  >
                    <BsTrash size={25} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MailButton;
