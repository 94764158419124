import { Link } from 'react-router-dom'
import WheelServicesSteps from "../Services/Components/WheelSteps/WheelServiceSteps";

import "./package.sass";

const Packages = () => {

    return (
        <div className="row">
            <div className="col-md-12">
                <WheelServicesSteps />
                <Link to="/app" className="wheel-volver">Volver</Link>
            </div>
        </div>
    );
};
export default Packages
