import { useContext } from "react";
import { ServicesContext } from "../../../../../Store/ServicesContext";
import "../stylesSteps.css";

const RySStep3 = () => {
  const { store } = useContext(ServicesContext);

  return (
    <div className="container-fluid StepsFormClass">
      <div className="row pt-5">
        <div className="col-3">
          <label for="field1" className="form-label">
            Área:
          </label>
          <input
            type="text"
            id="field1"
            className="form-control"
            placeholder="Área"
            aria-label="Área"
            value={store.recruitmentAndSelection?.area}
            disabled
          />
        </div>
        <div className="col-3">
          <label for="field2" className="form-label">
            País:
          </label>
          <input
            type="text"
            id="field2"
            className="form-control"
            placeholder="País"
            aria-label="País"
            value={store.recruitmentAndSelection?.country}
            disabled
          />
        </div>
        <div className="col-3">
          <label for="field3" className="form-label">
            Salario:
          </label>
          <input
            type="text"
            id="field3"
            className="form-control"
            placeholder="Salario"
            aria-label="Salario"
            value={`${store.recruitmentAndSelection?.minSalary} - ${store.recruitmentAndSelection?.maxSalary}`}
            disabled
          />
        </div>
        <div className="col-3">
          <label for="field4" className="form-label">
            Salario confidencial:
          </label>
          <input
            type="text"
            id="field4"
            className="form-control"
            placeholder="Salario confidencial"
            aria-label="Salario confidencial"
            value="No"
            disabled
          />
        </div>
      </div>
      <div className="row pt-4">
        <div className="col-3">
          <label for="field5" className="form-label">
            Nombre de la Vacante:
          </label>
          <input
            type="text"
            id="field5"
            className="form-control"
            placeholder="Nombre de la Vacante"
            aria-label="Nombre de la Vacante"
            value={store.recruitmentAndSelection?.jobName}
            disabled
          />
        </div>
        <div className="col-3">
          <label for="field6" className="form-label">
            Nivel de Inglés:
          </label>
          <input
            type="text"
            id="field6"
            className="form-control"
            placeholder="Nivel de Inglés"
            aria-label="Nivel de Inglés"
            value={store.recruitmentAndSelection?.englishLevel}
            disabled
          />
        </div>
        <div className="col-3">
          <label for="field7" className="form-label">
            Empresa confidencial:
          </label>
          <input
            type="text"
            id="field7"
            className="form-control"
            placeholder="Empresa confidencial"
            aria-label="Empresa confidencial"
            value={store.recruitmentAndSelection?.company}
            disabled
          />
        </div>
      </div>
      <div className="row pt-4">
        <label for="field8" className="form-label">
          Job description:
        </label>
        <p>
          {store.recruitmentAndSelection?.jobDescription}
        </p>
        {/* <textarea
          className="form-control"
          id="field8"
          placeholder="Job description"
          rows="5"
          value={
            "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi."
          }
        /> */}
      </div>
    </div>
  );
};

export default RySStep3;
