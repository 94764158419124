import { useState, useEffect } from "react";
import { DesempenoIcon, EntrenamientoIcon, MotivacionesIcon } from "../../Icons/CollaboratorsIcons";
import { getEmployeeMediasByEmployerId, putEmployeeMedia } from "../../API/Services/RemuneracionVariable/EmployeeMedia";
import { btnTranslate, performanceTranslate, wheelCollaboratorTranslate } from "../../i18n/translatedMessages";
import Loading from "../../Components/Loading/Loading";
import CircularBar from "./Components/CircularBar/CircularBar";
import EmptyInformation from "./Components/EmptyInformation/EmptyInformation";

import "./desempeno.sass";
import { useParams } from "react-router-dom";
import HeaderCarouselMandala from "./Components/HeaderCarouselMandala/HeaderCarouselMandala";
import { AiOutlineEdit } from "react-icons/ai";

const Desempeno = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [media, setMedia] = useState(0);
  const [mediaId, setMediaId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editChange, setEditChange] = useState(true);
  const [obs, setObs] = useState("");

  useEffect(() => {
    const fetch = async () => {
      const [response] = await getEmployeeMediasByEmployerId(id);

      if (response) {
        setMedia(response.media);
        setData(response.answers);
        setObs(response.observation);
        setMediaId(response.id);
      }
      setLoading(false);
    };
    fetch();
  }, []);

  const handleChange = (e) => {
    setObs(e);
  };

  const handleSend = async () => {
    setLoading(true);
    const sendAux = {
      answers: data,
      observation: obs,
    };
    await putEmployeeMedia(sendAux, mediaId);
    setLoading(false);
  };

  return (
    <>
      <div className="content-desempeno">
        {loading && <Loading />}
        <div>
          <HeaderCarouselMandala
            actualTitle={wheelCollaboratorTranslate.performance}
            prevTitle={wheelCollaboratorTranslate.motivacions}
            routePrev={`/app/analytic/motivaciones/${id}`}
            nextTitle={wheelCollaboratorTranslate.training}
            routeNext={`/app/analytic/entrenamiento/${id}`}
            iconActual={<DesempenoIcon />}
            iconPrev={<MotivacionesIcon />}
            iconNext={<EntrenamientoIcon />}
          />
        </div>
        {data.length > 0 && (
          <>
            <div className="box-media">
              <span>{performanceTranslate.perfomanceFinalResult}</span>
              <div className="media">{Math.floor(media)}%</div>
            </div>
            <div className="bars">
              {data.map((item) => (
                <div className="desempeno-bars-analytics" key={item.name}>
                  <CircularBar percentagem={item.value} />
                  <h5>{item.name}</h5>
                </div>
              ))}
            </div>
          </>
        )}
        {data.length === 0 && <EmptyInformation />}
      </div>
      {data.length > 0 && (
        <div className="container">
          <div className="row">
            <h5>Observación:</h5>
            <div className="col">
              <textarea className="form-control" value={obs} onChange={(e) => handleChange(e.target.value)} rows={4} disabled={editChange} />
            </div>
            <div className="col-auto">
              <div className="pb-2">
                <button className="btn rounded-pill ms-auto btn-salvar" onClick={() => setEditChange(!editChange)}>
                  <AiOutlineEdit size={28} />
                </button>
              </div>
              {!editChange && (
                <div className="pt-2">
                  <button className="btn rounded-pill ms-auto btn-salvar" onClick={handleSend}>
                    {btnTranslate.saveButton}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Desempeno;
