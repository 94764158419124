import { useCallback, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import Loading from "../../../../../../Components/Loading/Loading";

import { Input } from "../Input";
import { Select } from "../Select";

import { getCountry } from "../../../../../../API/Lists/country";
import CountryMapper from "../../../../../../Utils/CountryMapper";
import Bands from "../../../../../../API/Services/PayBalance/Bands";
import { btnTranslate, salaryRangeTranslate } from "../../../../../../i18n/translatedMessages";
import { FormattedMessage } from "react-intl";

export const NewBandModal = ({ show, handleClose, refresh }) => {
  const [band, setBand] = useState({
    name: "",
    band: "",
    country: 0,
  });

  const [countries, setCountries] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setBand({
      ...band,
      [event.target.name]: event.target.value,
    });
  };

  const dataLoad = useCallback(async () => {
    const response = await getCountry();
    setCountries(response.map(CountryMapper.toDomain));
  }, []);

  useEffect(() => {
    dataLoad();
  }, [dataLoad]);

  const saveData = async () => {
    try {
      const data = {
        company_id: JSON.parse(localStorage.getItem("user")).id,
        name: band.name,
        band: band.band,
        country_id: band.country,
      };
      await Bands.postBands(data);
      setBand({
        name: "",
        band: "",
        country: 0,
      });
      refresh();
      handleClose();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const close = () => {
    setBand({
      name: "",
      band: "",
      country: 0,
    });
    handleClose();
  };

  return (
    <Modal show={show} onHide={close} centered className="rounded-3" size="lg">
      {loading && (
        <div style={{ height: 450 }}>
          <Loading size="sm" />
        </div>
      )}
      {!loading && (
        <>
          <Modal.Header closeButton className="border-0">
            <Modal.Title>{salaryRangeTranslate.createNewSalaryRange}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormattedMessage id="salaryRangeName">
              {(msg) => (<Input label={msg} placeholder={msg} name="name" value={band.name} onChange={handleChange} className="my-4" />)}
            </FormattedMessage>

            <FormattedMessage id="salaryRangeCountry">
                {(msg) => (
                    <Select
                    options={countries}
                    label={msg}
                    placeholder={`Selecionar ${msg}`}
                    name="country"
                    value={band.country}
                    onChange={handleChange}
                    className="my-4"
                  />
                )}
            </FormattedMessage>
            <FormattedMessage id='salaryRangeMidPoint'>
                {(msg) => (
                    <Input label={msg} placeholder="Ex.: $ 50000" name="band" value={band.band} onChange={handleChange} className="my-4" />
                )}
            </FormattedMessage>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <Button variant="outline-grey" className="rounded-pill text-black px-3" onClick={close}>
              {btnTranslate.closeButton}
            </Button>
            <Button variant="primary" className="rounded-pill px-4" onClick={saveData}>
              {btnTranslate.saveButton}
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};
