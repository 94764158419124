export const getPlans = async () => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/plans`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};
