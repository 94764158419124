// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-formacion .title {
  display: flex;
  align-items: center;
  margin: 25px;
  justify-content: flex-start;
}
.content-formacion .title .icon {
  background: linear-gradient(45deg, #40c2ff 0%, #32d8d8 100%);
  width: 50px;
  height: 50px;
  border-radius: 50rem;
  padding: 8px;
  margin: 0 10px;
}
.content-formacion .title .icon svg {
  fill: white;
  width: 100%;
}
.content-formacion .body h5 {
  margin-bottom: 40px;
}
.content-formacion .body .ingles {
  position: relative;
}
.content-formacion .body .ingles .mark {
  width: 25px;
  height: 25px;
  border: 2px solid;
  border-radius: 50rem;
  background: #40c2ff;
  position: absolute;
  top: 56px;
}
.content-formacion .body .nulinga {
  position: relative;
}
.content-formacion .body .nulinga .mark {
  width: 25px;
  height: 25px;
  border: 2px solid;
  border-radius: 50rem;
  background: #40c2ff;
  position: absolute;
  top: 56px;
}`, "",{"version":3,"sources":["webpack://./src/Views/Analytics/formacion.sass"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,mBAAA;EACA,YAAA;EACA,2BAAA;AAAR;AAEQ;EACI,4DAAA;EACA,WAAA;EACA,YAAA;EACA,oBAAA;EACA,YAAA;EACA,cAAA;AAAZ;AAEY;EACI,WAAA;EACA,WAAA;AAAhB;AAIQ;EACI,mBAAA;AAFZ;AAIQ;EACI,kBAAA;AAFZ;AAIY;EACI,WAAA;EACA,YAAA;EACA,iBAAA;EACA,oBAAA;EACA,mBAAA;EACA,kBAAA;EACA,SAAA;AAFhB;AAGQ;EACI,kBAAA;AADZ;AAGY;EACI,WAAA;EACA,YAAA;EACA,iBAAA;EACA,oBAAA;EACA,mBAAA;EACA,kBAAA;EACA,SAAA;AADhB","sourcesContent":[".content-formacion\n    & .title\n        display: flex\n        align-items: center\n        margin: 25px\n        justify-content: flex-start\n\n        & .icon\n            background: linear-gradient(45deg,#40c2ff 0%, #32d8d8 100%)\n            width: 50px\n            height: 50px\n            border-radius: 50rem\n            padding: 8px\n            margin: 0 10px\n\n            & svg\n                fill: white\n                width: 100%\n\n    & .body\n\n        & h5\n            margin-bottom: 40px\n\n        & .ingles\n            position: relative\n\n            .mark\n                width: 25px\n                height: 25px\n                border: 2px solid\n                border-radius: 50rem\n                background: #40c2ff\n                position: absolute\n                top: 56px\n        & .nulinga\n            position: relative\n\n            .mark\n                width: 25px\n                height: 25px\n                border: 2px solid\n                border-radius: 50rem\n                background: #40c2ff\n                position: absolute\n                top: 56px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
