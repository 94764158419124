import { useState, useEffect, useContext } from "react";
import { MdArrowBack, MdArrowForward, MdCheck } from "react-icons/md";
import { Link } from "react-router-dom";
import { ServiceRequestModal } from "./Modais/ServiceRequestModal/ServiceRequestModal";
import { ServicesContext } from "../../Store/ServicesContext";

import StepZilla from "react-stepzilla";

import AccreditedCompanies from "./Components/accreditedCompanies/accreditedCompanies";
import MenuPlan from "./Components/NewMenu/MenuPlan";
import PerfilIndustry from "./Components/PerfilIndustry/PerfilIndustry";

import RySStep1 from "./Components/StepForm/RyS/RySStep1";
import RySStep2 from "./Components/StepForm/RyS/RySStep2";
import RySStep3 from "./Components/StepForm/RyS/RySStep3";
import RySStep4 from "./Components/StepForm/RyS/RySStep4";

import DosStepsForm1 from "./Components/StepForm/DosStepsForm/DosStepsForm1";
import DosStepsForm2 from "./Components/StepForm/DosStepsForm/DosStepsForm2";

import TresStepsForm1 from "./Components/StepForm/TresStepsForm/TresStepsForm1";
import TresStepsForm2 from "./Components/StepForm/TresStepsForm/TresStepsForm2";
import TresStepsForm3 from "./Components/StepForm/TresStepsForm/TresStepsForm3";

import RemuneracionVariable from "./Components/RemuneracionVariable/RemuneracionVariable";

import { StepsFormRequest } from "../../API/steps_form_request";
import { saveRyS } from "../../API/service_request";
import { mailSender } from "../../API/sqs";

import "./detailPage.sass";
import DesempenoEmployer from "./Components/RemuneracionVariable/DesempenoEmployer";

const dossteps = [
  { name: "Step 1", component: <DosStepsForm1 /> },
  {
    name: "Step 2",
    component: (
      <DosStepsForm2
        imgProf={
          "https://assets.mydna.company/stepForm-Guillermo.png"
        }
        presProf={
          "Hola, soy Guillermo Sepúlveda, Product Owner de BadgeHeroes,"
        }
        textProf={
          "gracias por la información, ahora puedo llegar preparado para nuestra reunión."
        }
      />
    ),
  },
];
const tressteps = [
  { name: "Step 1", component: <TresStepsForm1 /> },
  { name: "Step 2", component: <TresStepsForm2 /> },
  {
    name: "Step 3",
    component: (
      <TresStepsForm3
        imgProf={
          "https://assets.mydna.company/stepForm-Ederson.png"
        }
        presProf={"Hola, soy Éderson Floriano, Product Owner de MyDNA,"}
        textProf={
          "gracias por la información, ahora puedo llegar preparado para nuestra reunión."
        }
      />
    ),
  },
];
const dosstepschangeprof1 = [
  { name: "Step 1", component: <DosStepsForm1 /> },
  {
    name: "Step 2",
    component: (
      <DosStepsForm2
        imgProf={
          "https://assets.mydna.company/stepForm-Ederson.png"
        }
        presProf={"Hola, soy Éderson Floriano, Product Owner de MyDNA,"}
        textProf={
          "gracias por la información, ahora puedo llegar preparado para nuestra reunión."
        }
      />
    ),
  },
];
const dosstepschangeprof2 = [
  { name: "Step 1", component: <DosStepsForm1 /> },
  {
    name: "Step 2",
    component: (
      <DosStepsForm2
        imgProf={
          "https://assets.mydna.company/stepForm-Benjamin.png"
        }
        presProf={"Hola, soy Benjamin Foster, Product Owner de Genes,"}
        textProf={
          "gracias por la información, ahora puedo llegar preparado para nuestra reunión."
        }
      />
    ),
  },
];
const dosstepschangeprof3 = [
  { name: "Step 1", component: <DosStepsForm1 /> },
  {
    name: "Step 2",
    component: (
      <DosStepsForm2
        imgProf={
          "https://assets.mydna.company/stepForm-Joice.png"
        }
        presProf={"Hola, soy Joice Meneguetti, Product Owner de The Bonding,"}
        textProf={
          "gracias por la información, ahora puedo llegar preparado para nuestra reunión."
        }
      />
    ),
  },
];
const tresstepschangeprof2 = [
  { name: "Step 1", component: <TresStepsForm1 /> },
  { name: "Step 2", component: <TresStepsForm2 /> },
  {
    name: "Step 3",
    component: (
      <TresStepsForm3
        imgProf={
          "https://assets.mydna.company/stepForm-Benjamin.png"
        }
        presProf={"Hola, soy Benjamin Foster, Product Owner de Genes,"}
        textProf={
          "gracias por la información, ahora puedo llegar preparado para nuestra reunión."
        }
      />
    ),
  },
];
const tresstepschangeprof3 = [
  { name: "Step 1", component: <TresStepsForm1 /> },
  { name: "Step 2", component: <TresStepsForm2 /> },
  {
    name: "Step 3",
    component: (
      <TresStepsForm3
        imgProf={
          "https://assets.mydna.company/stepForm-Joice.png"
        }
        presProf={"Hola, soy Joice Meneguetti, Product Owner de The Bonding,"}
        textProf={
          "gracias por la información, ahora puedo llegar preparado para nuestra reunión."
        }
      />
    ),
  },
];

const titles = [
    "Mi empresa",
    "People Analytics",
    "Reclutamiento y Selección",
    "Onboarding",
    "Entrenamiento & Desarrollo",
    "Clima & Cultura",
    "Analisis de Desempeño",
    "Salarios & Beneficios",
    "Assessment Center",
    "Plan de Carrera",
    "Plan de Salida",
];

const DetailPage = ({ location, opt = "1" }) => {
  const [page, setPage] = useState("1");
  const [option, setOption] = useState("1");
  const { store, actions } = useContext(ServicesContext);

  const handleSubmitRyS = () => {
    saveRyS(store.recruitmentAndSelection);
  };

  const changeView = (id) => {
    setOption(id);
  };



  const ryssteps = [
    { name: "Step 1", component: <RySStep1 /> },
    { name: "Step 2", component: <RySStep2 /> },
    { name: "Step 3", component: <RySStep3 /> },
    {
      name: "Step 4",
      component: (
        <RySStep4
          changeView={changeView}
          imgProf={"https://assets.mydna.company/stepForm-Joice.png"}
          textProf={"Joice Meneguetti, Product Owner de The Bonding"}
        />
      ),
    },
  ];
  const rysstepsProf = [
    { name: "Step 1", component: <RySStep1 /> },
    { name: "Step 2", component: <RySStep2 /> },
    { name: "Step 3", component: <RySStep3 /> },
    {
      name: "Step 4",
      component: (
        <RySStep4
          changeView={changeView}
          imgProf={"https://assets.mydna.company/stepsPage-Alfonso_Ochoa.png"}
          textProf={"Alfonso Ochoa, Country Manager de DNA Human Capital"}
        />
      ),
    },
  ];

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (location?.option) {
      setPage(String(location.option));
      setOption(String(location.option));
    }

    setOption(opt)
  }, []);

  const handleSubmitStepsData = () => StepsFormRequest(store.inputList);

  const handleMenu = (event) => {
    setOption(event.currentTarget.dataset.option);
    actions.hideBonding();
    actions.hideDosStepsForm();
    actions.hideTresStepsForm();
    actions.hideAnalisisDesempeno();
    actions.hideRemuneracionVariable();
  };

  return (
    <>
      {show && (
        <ServiceRequestModal
          view={show}
          hide={handleClose}
          service={titles[option - 1]}
          isPrivate={true}
        />
      )}
      <div className="detail-page pt-5">
        <div className="row">
          <div className="col-12 col-md-3 menu-plan-container">
            <MenuPlan handleMenu={handleMenu} option={option} />
            {/* <NewMenu /> */}
          </div>
          <div className="col-12 col-md-9 d-flex flex-column align-items-center">
            {option !== "1" && (
              <h3>Servicios de {titles[option - 1]} certificados por MyDNA </h3>
            )}
            {store.RoleId !== 2 && (
              <p>
                Al contratar la licencia de MyDNA, accederás a los servicios
                oferecidos por las empresas listadas.
                <Link onClick={handleShow}>Cotizar mi plan</Link>
              </p>
            )}
            {parseInt(option) === 1 && <PerfilIndustry />}
            {
			parseInt(option) > 1 ?
              (
                store.showTresStepsForm === true ? (
                  <>
                    <button
                      className="btn-back float-right"
                      onClick={actions.hideTresStepsForm}
                    >
                      <div className="item-circle">
                        <MdArrowBack size={30} />
                      </div>
                    </button>
                    <div className="step-progress">
                      <StepZilla
                        steps={tresstepschangeprof2}
                        showSteps={false}
                        prevBtnOnLastStep={false}
                        startAtStep={0}
                        dontValidate={false}
                        backButtonCls=""
                        nextButtonCls=""
                        nextTextOnFinalActionStep={
                          <div onClick={handleSubmitStepsData}>
                            Siguiente
                            <MdCheck size={24} />
                          </div>
                        }
                        backButtonText={
                          <>
                            <MdArrowBack size={24} />
                            Volver
                          </>
                        }
                        nextButtonText={
                          <div
                            onClick={() =>
                              mailSender(
                                "Outplacement",
                                store.name,
                                store.lastName,
                                store.email
                              )
                            }
                          >
                            Siguiente
                            <MdArrowForward size={24} />
                          </div>
                        }
                      />
                    </div>
                  </>
                ) : store.showDosStepsFormChangeProf1 === true ? (
                  <>
                    <button
                      className="btn-back float-right"
                      onClick={actions.hideDosStepsForm}
                    >
                      <div className="item-circle">
                        <MdArrowBack size={30} />
                      </div>
                    </button>
                    <div className="step-progress">
                      <StepZilla
                        steps={dosstepschangeprof1}
                        showSteps={false}
                        prevBtnOnLastStep={false}
                        startAtStep={0}
                        dontValidate={false}
                        backButtonCls=""
                        nextButtonCls=""
                        backButtonText={
                          <>
                            <MdArrowBack size={24} />
                            Volver
                          </>
                        }
                        nextButtonText={
                          <div
                            onClick={() =>
                              mailSender(
                                "Evaluación",
                                store.name,
                                store.lastName,
                                store.email
                              )
                            }
                          >
                            Siguiente
                            <MdArrowForward size={24} />
                          </div>
                        }
                      />
                    </div>
                  </>
                ) : store.showAnalisisDesempeno === true ? (
                  <>
                    <button
                      className="btn-back float-right"
                      onClick={actions.hideAnalisisDesempeno}
                    >
                      <div className="item-circle">
                        <MdArrowBack size={30} />
                      </div>
                    </button>
                    <div className="container">
                      <DesempenoEmployer />
                    </div>
                  </>
                ) : store.showTresStepsForm === true ? (
                  <>
                    <button
                      className="btn-back float-right"
                      onClick={actions.hideTresStepsForm}
                    >
                      <div className="item-circle">
                        <MdArrowBack size={30} />
                      </div>
                    </button>
                    <div className="step-progress">
                      <StepZilla
                        steps={tressteps}
                        showSteps={false}
                        prevBtnOnLastStep={false}
                        startAtStep={0}
                        dontValidate={false}
                        backButtonCls=""
                        nextButtonCls=""
                        nextTextOnFinalActionStep={
                          <div onClick={handleSubmitStepsData}>
                            Siguiente
                            <MdCheck size={24} />
                          </div>
                        }
                        backButtonText={
                          <>
                            <MdArrowBack size={24} />
                            Volver
                          </>
                        }
                        nextButtonText={
                          <div
                            onClick={() =>
                              mailSender(
                                "Evaluación",
                                store.name,
                                store.lastName,
                                store.email
                              )
                            }
                          >
                            Siguiente
                            <MdArrowForward size={24} />
                          </div>
                        }
                      />
                    </div>
                  </>
                ) : store.showDosStepsFormChangeProf3 === true ? (
                  <>
                    <button
                      className="btn-back float-right"
                      onClick={actions.hideDosStepsForm}
                    >
                      <div className="item-circle">
                        <MdArrowBack size={30} />
                      </div>
                    </button>
                    <div className="step-progress">
                      <StepZilla
                        steps={dosstepschangeprof3}
                        showSteps={false}
                        prevBtnOnLastStep={false}
                        startAtStep={0}
                        dontValidate={false}
                        backButtonCls=""
                        nextButtonCls=""
                        backButtonText={
                          <>
                            <MdArrowBack size={24} />
                            Volver
                          </>
                        }
                        nextButtonText={
                          <div
                            onClick={() =>
                              mailSender(
                                "Análisis de Cultura",
                                store.name,
                                store.lastName,
                                store.email
                              )
                            }
                          >
                            Siguiente
                            <MdArrowForward size={24} />
                          </div>
                        }
                      />
                    </div>
                  </>
                )
                  : store.showTresStepsForm === true ? (
                    <>
                      <button
                        className="btn-back float-right"
                        onClick={actions.hideTresStepsForm}
                      >
                        <div className="item-circle">
                          <MdArrowBack size={30} />
                        </div>
                      </button>
                      <div className="step-progress">
                        <StepZilla
                          steps={tresstepschangeprof3}
                          showSteps={false}
                          prevBtnOnLastStep={false}
                          startAtStep={0}
                          dontValidate={false}
                          backButtonCls=""
                          nextButtonCls=""
                          nextTextOnFinalActionStep={
                            <div onClick={handleSubmitStepsData}>
                              Siguiente
                              <MdCheck size={24} />
                            </div>
                          }
                          backButtonText={
                            <>
                              <MdArrowBack size={24} />
                              Volver
                            </>
                          }
                          nextButtonText={
                            <div
                              onClick={() =>
                                mailSender(
                                  "Análisis de Cultura",
                                  store.name,
                                  store.lastName,
                                  store.email
                                )
                              }
                            >
                              Siguiente
                              <MdArrowForward size={24} />
                            </div>
                          }
                        />
                      </div>
                    </>
                  ) : store.showDosStepsFormChangeProf2 === true ? (
                    <>
                      <button
                        className="btn-back float-right"
                        onClick={actions.hideDosStepsForm}
                      >
                        <div className="item-circle">
                          <MdArrowBack size={30} />
                        </div>
                      </button>
                      <div className="step-progress">
                        <StepZilla
                          steps={dosstepschangeprof2}
                          showSteps={false}
                          prevBtnOnLastStep={false}
                          startAtStep={0}
                          dontValidate={false}
                          backButtonCls=""
                          nextButtonCls=""
                          backButtonText={
                            <>
                              <MdArrowBack size={24} />
                              Volver
                            </>
                          }
                          nextButtonText={
                            <div
                              onClick={() =>
                                mailSender(
                                  "Plan de carrera",
                                  store.name,
                                  store.lastName,
                                  store.email
                                )
                              }
                            >
                              Siguiente
                              <MdArrowForward size={24} />
                            </div>
                          }
                        />
                      </div>
                    </>
                  ) : store.showDosStepsFormChangeProf2 === true ? (
                    <>
                      <button
                        className="btn-back float-right"
                        onClick={actions.hideDosStepsForm}
                      >
                        <div className="item-circle">
                          <MdArrowBack size={30} />
                        </div>
                      </button>
                      <div className="step-progress">
                        <StepZilla
                          steps={dosstepschangeprof2}
                          showSteps={false}
                          prevBtnOnLastStep={false}
                          startAtStep={0}
                          dontValidate={false}
                          backButtonCls=""
                          nextButtonCls=""
                          backButtonText={
                            <>
                              <MdArrowBack size={24} />
                              Volver
                            </>
                          }
                          nextButtonText={
                            <div
                              onClick={() =>
                                mailSender(
                                  "Salarios & beneficios",
                                  store.name,
                                  store.lastName,
                                  store.email
                                )
                              }
                            >
                              Siguiente
                              <MdArrowForward size={24} />
                            </div>
                          }
                        />
                      </div>
                    </>

                  ) : store.showRemuneracionVariable === true ? (
                    <>
                      <button
                        className="btn-back float-right"
                        onClick={actions.hideRemuneracionVariable}
                      >
                        <div className="item-circle">
                          <MdArrowBack size={30} />
                        </div>
                      </button>
                      <RemuneracionVariable />
                    </>
                  ) : store.showDosStepsFormChangeProf1 === true ? (
                    <>
                      <button
                        className="btn-back float-right"
                        onClick={actions.hideDosStepsForm}
                      >
                        <div className="item-circle">
                          <MdArrowBack size={30} />
                        </div>
                      </button>
                      <div className="step-progress">
                        <StepZilla
                          steps={dosstepschangeprof1}
                          showSteps={false}
                          prevBtnOnLastStep={false}
                          startAtStep={0}
                          dontValidate={false}
                          backButtonCls=""
                          nextButtonCls=""
                          backButtonText={
                            <>
                              <MdArrowBack size={24} />
                              Volver
                            </>
                          }
                          nextButtonText={
                            <div
                              onClick={() =>
                                mailSender(
                                  "Capacitaciones",
                                  store.name,
                                  store.lastName,
                                  store.email
                                )
                              }
                            >
                              Siguiente
                              <MdArrowForward size={24} />
                            </div>
                          }
                        />
                      </div>
                    </>
                  ) : store.showTresStepsForm === true ? (
                    <>
                      <button
                        className="btn-back float-right"
                        onClick={actions.hideTresStepsForm}
                      >
                        <div className="item-circle">
                          <MdArrowBack size={30} />
                        </div>
                      </button>
                      <div className="step-progress">
                        <StepZilla
                          steps={tressteps}
                          showSteps={false}
                          prevBtnOnLastStep={false}
                          startAtStep={0}
                          dontValidate={false}
                          backButtonCls=""
                          nextButtonCls=""
                          nextTextOnFinalActionStep={
                            <div onClick={handleSubmitStepsData}>
                              Siguiente
                              <MdCheck size={24} />
                            </div>
                          }
                          backButtonText={
                            <>
                              <MdArrowBack size={24} />
                              Volver
                            </>
                          }
                          nextButtonText={
                            <div
                              onClick={() =>
                                mailSender(
                                  "Capacitaciones",
                                  store.name,
                                  store.lastName,
                                  store.email
                                )
                              }
                            >
                              Siguiente
                              <MdArrowForward size={24} />
                            </div>
                          }
                        />
                      </div>
                    </>
                  ) : store.showDosStepsForm === true ? (
                    <>
                      <button
                        className="btn-back float-right"
                        onClick={actions.hideDosStepsForm}
                      >
                        <div className="item-circle">
                          <MdArrowBack size={30} />
                        </div>
                      </button>
                      <div className="step-progress">
                        <StepZilla
                          steps={dossteps}
                          showSteps={false}
                          prevBtnOnLastStep={false}
                          startAtStep={0}
                          dontValidate={false}
                          backButtonCls=""
                          nextButtonCls=""
                          backButtonText={
                            <>
                              <MdArrowBack size={24} />
                              Volver
                            </>
                          }
                          nextButtonText={
                            <div
                              onClick={() =>
                                mailSender(
                                  "Onboarding",
                                  store.name,
                                  store.lastName,
                                  store.email
                                )
                              }
                            >
                              Siguiente
                              <MdArrowForward size={24} />
                            </div>
                          }
                        />
                      </div>
                    </>
                  ) : store.showBondingChangeProf === true ? (
                    <>
                      <button
                        className="btn-back float-right"
                        onClick={actions.hideBonding}
                      >
                        <div className="item-circle">
                          <MdArrowBack size={30} />
                        </div>
                      </button>
                      <div className="step-progress">
                        <StepZilla
                          steps={rysstepsProf}
                          showSteps={false}
                          prevBtnOnLastStep={false}
                          startAtStep={0}
                          dontValidate={false}
                          backButtonCls=""
                          nextButtonCls=""
                          nextTextOnFinalActionStep={
                            <div onClick={handleSubmitRyS}>
                              Publicar Vacante
                              <MdCheck size={24} />
                            </div>
                          }
                          backButtonText={
                            <>
                              <MdArrowBack size={24} />
                              Volver
                            </>
                          }
                          nextButtonText={
                            <>
                              Siguiente
                              <MdArrowForward size={24} />
                            </>
                          }
                        />
                      </div>
                    </>
                  ) : store.showBonding === true ? (
                    <>
                      <button
                        className="btn-back float-right"
                        onClick={actions.hideBonding}
                      >
                        <div className="item-circle">
                          <MdArrowBack size={30} />
                        </div>
                      </button>
                      <div className="step-progress">
                        <StepZilla
                          steps={ryssteps}
                          showSteps={false}
                          prevBtnOnLastStep={false}
                          startAtStep={0}
                          dontValidate={false}
                          backButtonCls=""
                          nextButtonCls=""
                          nextTextOnFinalActionStep={
                            <>
                              Publicar Vacante
                              <MdCheck size={24} />
                            </>
                          }
                          backButtonText={
                            <>
                              <MdArrowBack size={24} />
                              Volver
                            </>
                          }
                          nextButtonText={
                            <>
                              Siguiente
                              <MdArrowForward size={24} />
                            </>
                          }
                        />
                      </div>
                    </>
                  ) : store.showDosStepsFormChangeProf1 === true ? (
                    <>
                      <button
                        className="btn-back float-right"
                        onClick={actions.hideDosStepsForm}
                      >
                        <div className="item-circle">
                          <MdArrowBack size={30} />
                        </div>
                      </button>
                      <div className="step-progress">
                        <StepZilla
                          steps={dosstepschangeprof1}
                          showSteps={false}
                          prevBtnOnLastStep={false}
                          startAtStep={0}
                          dontValidate={false}
                          backButtonCls=""
                          nextButtonCls=""
                          backButtonText={
                            <>
                              <MdArrowBack size={24} />
                              Volver
                            </>
                          }
                          nextButtonText={
                            <>
                              Siguiente
                              <MdArrowForward size={24} />
                            </>
                          }
                        />
                      </div>
                    </>
                  )  : (
					
                    <AccreditedCompanies section={page} option={option} />
                  ) 
               ) : (
               <AccreditedCompanies section={page} option={option} />
              )
            }
          </div>
        </div>
      </div>
    </>
  );
};
export default DetailPage;
