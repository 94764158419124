// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .drag-n-drop {
    padding: 0.5rem;
    display: grid;
    gap: 0.5rem;
    width: 100%;
    height: 100%;
    grid-template-columns: repeat(auto-fill, 300px);
    align-items: start;
    font-size: calc(10px + 1vmin);
  } */

.dnd-group {
  background-color: #fff;
  border: 2px solid #41c8ff;
  border-radius: 15px;
}
.dnd-item {
  display: flex;
  align-items: center;
  min-height: 50px;
  background-color: rgb(237, 237, 237);
  border-radius: 15px;
  color: #282c34;
  font-weight: bold;
  padding: 15px;
  margin: 0px 0.5rem 0.5rem 0.5rem;
}

.dnd-item:not(:last-of-type) {
  margin-bottom: 0.5rem;
}

.dnd-item * {
  margin: 0;
  font-size: 1rem;
}

.dnd-item img {
  max-width: 50px;
}

.group-title {
  margin-bottom: 0.8rem;
  font-size: 1.1rem;
  color: white;
  border-bottom: 2px solid #41c8ff;
}
.current {
  background-color: #aeaeae8e;
}

.grap {
  cursor: grab;
}

.grabbed {
  cursor: grabbing;
}
`, "",{"version":3,"sources":["webpack://./src/Views/RecruitmentAndSelection/Components/styles.css"],"names":[],"mappings":"AAAA;;;;;;;;;KASK;;AAEL;EACE,sBAAsB;EACtB,yBAAyB;EACzB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,oCAAoC;EACpC,mBAAmB;EACnB,cAAc;EACd,iBAAiB;EACjB,aAAa;EACb,gCAAgC;AAClC;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,YAAY;EACZ,gCAAgC;AAClC;AACA;EACE,2BAA2B;AAC7B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["/* .drag-n-drop {\n    padding: 0.5rem;\n    display: grid;\n    gap: 0.5rem;\n    width: 100%;\n    height: 100%;\n    grid-template-columns: repeat(auto-fill, 300px);\n    align-items: start;\n    font-size: calc(10px + 1vmin);\n  } */\n\n.dnd-group {\n  background-color: #fff;\n  border: 2px solid #41c8ff;\n  border-radius: 15px;\n}\n.dnd-item {\n  display: flex;\n  align-items: center;\n  min-height: 50px;\n  background-color: rgb(237, 237, 237);\n  border-radius: 15px;\n  color: #282c34;\n  font-weight: bold;\n  padding: 15px;\n  margin: 0px 0.5rem 0.5rem 0.5rem;\n}\n\n.dnd-item:not(:last-of-type) {\n  margin-bottom: 0.5rem;\n}\n\n.dnd-item * {\n  margin: 0;\n  font-size: 1rem;\n}\n\n.dnd-item img {\n  max-width: 50px;\n}\n\n.group-title {\n  margin-bottom: 0.8rem;\n  font-size: 1.1rem;\n  color: white;\n  border-bottom: 2px solid #41c8ff;\n}\n.current {\n  background-color: #aeaeae8e;\n}\n\n.grap {\n  cursor: grab;\n}\n\n.grabbed {\n  cursor: grabbing;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
