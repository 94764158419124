import {
    employeeExperienceTranslate,
    modalAssignTranslate,
} from "../../../../i18n/translatedMessages";

export const Interests = ({ onChange, data }) => {
    return (
        <div className="content-form">
            <div className="row">
                <div className="col">
                    <h3>
                        {employeeExperienceTranslate.interestsTitleEEProfile}
                    </h3>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <label htmlFor="hobies">
                        {employeeExperienceTranslate.hobbyEEProfile}
                    </label>
                    <input
                        type="text"
                        id="hobies"
                        name="hobies"
                        onChange={onChange}
                        value={data.hobies}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <label htmlFor="sports">
                        {employeeExperienceTranslate.sportsEEProfile}
                    </label>
                    <input
                        type="text"
                        id="sports"
                        name="sports"
                        onChange={onChange}
                        value={data.sports}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <label htmlFor="interests">
                        {employeeExperienceTranslate.interestsEEProfile}
                    </label>
                    <input
                        type="text"
                        id="interests"
                        name="interests"
                        onChange={onChange}
                        value={data.interests}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="label">
                        {employeeExperienceTranslate.volunteerWorkEEProfile}
                    </div>
                    <div className="radio-group" onChange={onChange}>
                        <div>
                            <input
                                type="radio"
                                onChange={() => {}}
                                value={true}
                                name="voluntaryWork"
                                id="WorkYes"
                                checked={String(data.voluntaryWork) === "true"}
                            />
                            <label htmlFor="WorkYes">
                                {modalAssignTranslate.yesText}
                            </label>
                        </div>
                        <div>
                            <input
                                type="radio"
                                onChange={() => {}}
                                value={false}
                                name="voluntaryWork"
                                id="WorkNo"
                                checked={String(data.voluntaryWork) === "false"}
                            />
                            <label htmlFor="WorkNo">
                                {modalAssignTranslate.noText}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
