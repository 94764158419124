import { badgeHeroesTranslate } from '../../../../i18n/translatedMessages'
import Card from '../../Components/Card/card'

const BadgeHeroes = () =>
    <div className="row">
        <div className="col d-flex justify-content-center offset-1">
            <Card
                title={badgeHeroesTranslate.badgeHeroesTitle}
                background="https://assets.mydna.company/iconoCard_dashboard-empresa.svg"
                text={badgeHeroesTranslate.badgeHeroesTextCard}
                video="https://www.youtube.com/embed/Z-dj9P7PA9c"
                file="https://6674506.fs1.hubspotusercontent-na1.net/hubfs/6674506/BadgeHeroes/GuiaEmpleador_BadgeHeroes.pdf"
                stars={4.9}
                fileAmount={1}
            />
        </div>
    </div>
export default BadgeHeroes