import { HiMagnifyingGlass, HiMiniPencilSquare, HiClipboardDocumentList, HiMiniTrash } from 'react-icons/hi2'
import { useCallback, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import { Tooltip } from '../../../Components/Employee/Tooltip'
import { Select } from '../../../Components/Employee/Select/Select'
import Loading from '../../../Components/Loading/Loading.jsx'

import { EditModal } from '../../../Components/Employee/Modal/Edit'
import { ModalError } from '../../../Components/Employee/Modal/Error.jsx'
import { DescriptorModal } from '../../../Components/Employee/Modal/Descriptor'
import { DeleteEmployee } from '../../../Components/Employee/Modal/DeleteEmployee.jsx'

import countryMapper from '../../../Utils/CountriesMapper.js'
import areasMapper from '../../../Utils/AreasMapper.js'
import levelsMapper from '../../../Utils/LevelsMapper.js'

import { translate as translateAreas } from '../../../i18n/Library/areasCorrelation.js'
import { translate as translatePosition } from '../../../i18n/Library/hierarchicalLevel'

import List from '../../../API/Lists/Lists.js'
import { getEmployeesByCompany } from '../../../API/Employee/Employee.js'
import { deleteEmployee } from '../../../API/Employee/Employee.js'
import { formEETranslate } from '../../../i18n/translatedMessages.js'

export function ListEmployee() {
	const getLanguage = useIntl()
	const language = getLanguage.locale === 'PT-BR' ? 'pt' : 'es'

	const [loading, setLoading] = useState(false)

	const [showEdit, setShowEdit] = useState(false)
	const [showDelete, setShowDelete] = useState(false)
	const [showDescriptor, setShowDescriptor] = useState(false)
	const [showModalError, setShowModalError] = useState(false)
	const [employeeSelected, setEmployeeSelected] = useState(null)
	const [employeeData, setEmployeeData] = useState(null)

	const [employees, setEmployees] = useState([])
	const [employeesFiltered, setEmployeesFiltered] = useState([])

	const [countries, setCountries] = useState([])
	const [areas, setAreas] = useState([])
	const [levels, setLevels] = useState([])

	const [filters, setFilters] = useState({
		country: null,
		area: null,
		level: null,
		text: '',
	})

	const load = useCallback(async () => {
		try {
			setLoading(true)
			const company = JSON.parse(localStorage.getItem('user')).id

			const [resArea, resCountry, resLevel, resEmployee] = await Promise.all([List.areas(), List.countries(), List.levels(), getEmployeesByCompany(company)])

			setEmployees(resEmployee)
			setCountries(resCountry.map(countryMapper.toDomain))
			setAreas(resArea.map(areasMapper.toDomain))
			setLevels(resLevel.map(levelsMapper.toDomain))
		} catch {
			setShowModalError(true)
		} finally {
			setLoading(false)
		}
	}, [])

	useEffect(() => {
		load()
	}, [load])

	const handleDelete = async () => {
		try {
			await deleteEmployee(employeeSelected)
			load()
		} catch {
			setShowModalError(true)
		}
	}

	const handleFilterText = event => {
		setFilters({
			...filters,
			text: event.target.value,
		})
	}

	const handleFilter = event => {
		setFilters({
			...filters,
			[event.target.name]: event.target,
		})
	}

	useEffect(() => {
		let data = [...employees]

		if (filters?.country) {
			data = data.filter(item => item.country.toLowerCase() === filters.country.label.toLowerCase())
		}

		if (filters?.area) {
			data = data.filter(item => item.area?.id === filters.area.value)
		}

		if (filters?.level) {
			data = data.filter(item => item.level?.id === filters.level.value)
		}

		if (filters?.text) {
			data = data.filter(item => item.name.toLowerCase().includes(filters.text.toLowerCase()))
		}

		setEmployeesFiltered(data)
	}, [filters, employees])

	const cleanFilters = () => {
		setFilters({
			country: null,
			area: null,
			level: null,
			text: '',
		})
	}

	const translatedAreas = areas.map(item => ({
		...item,
		value: translateAreas[language][item?.value],
	}))

	const translatedLevels = levels.map(item => ({
		...item,
		value: translatePosition[language][item?.value],
	}))

	return (
		<>
			{loading && <Loading />}
			<ModalError show={showModalError} handleShow={setShowModalError} />
			<DeleteEmployee show={showDelete} handleShow={setShowDelete} handleDelete={handleDelete} />
			<DescriptorModal show={showDescriptor} handleShow={setShowDescriptor} user={employeeData} />
			<EditModal show={showEdit} handleShow={setShowEdit} employeeData={employeeData} />
			<div className='pe-5'>
				<div className='row'>
					<div className='col'>
						<div className='w-100 border border-grey rounded-3 py-2 px-3'>
							<HiMagnifyingGlass size={22} className='text-dark-grey' />
							<FormattedMessage id='searchCollaborator'>{msg => <input type='text' className='border border-0 p-0 m-0 ms-4' placeholder={msg} onChange={handleFilterText} value={filters.text} />}</FormattedMessage>
						</div>
					</div>
				</div>
				<div className='row'>
					<div className='col'>
						<Select placeholder='País' list={countries} onChange={handleFilter} name='country' value={filters.country?.value} />
					</div>
					<div className='col'>
						<Select placeholder='Área' list={translatedAreas} onChange={handleFilter} name='area' value={filters.area?.value} />
					</div>
					<div className='col'>
						<FormattedMessage id='EEFormNivel'>{msg => <Select placeholder={msg} list={translatedLevels} onChange={handleFilter} name='level' value={filters.level?.value} />}</FormattedMessage>
					</div>
					<div className='col-2 flex align-items-center'>
						<button className='btn btn-outline-grey w-100 mt-1' style={{ height: 45, border: '1px solid #CBD5E1', color: '#9391a1', borderRadius: 8 }} onClick={cleanFilters}>
							Limpar Filtros
						</button>
					</div>
				</div>
				<div className='rowm mt-3'>
					<div className='col border border-grey rounded-3 overflow-hidden'>
						<div className='row  bg-light-grey p-2'>
							<div className='col fw-bold'>Colaborador</div>
							<div className='col-2 fw-bold'>{formEETranslate.EELevelAndArea}</div>
							<div className='col-2 fw-bold'>{formEETranslate.EEAction}</div>
						</div>
						<div className='overflow-auto' style={{ height: 'calc(100vh - 360px)' }}>
							{employeesFiltered.map(item => (
								<div key={item.id} className='row p-2 border-bottom m-0 '>
									<div className='col-1 flex justify-content-center align-items-center'>
										<img src={item.asset || 'https://assets.mydna.company/profile-placeholder.png'} alt='' className='img-fluid rounded-circle' style={{ width: 60, height: 60 }} />
									</div>
									<div className='col'>
										<Link to={`/app/analytic/perfil/${item.id}`}>
											<h5 title={item.id}>{item.name}</h5>
										</Link>
										<span className='text-dark-grey'>{item.jobName}</span>
										<p className='text-dark-grey'>{item.email}</p>
									</div>
									<div className='col-2'>
										<span>{translatePosition[language][item?.level?.PositionN]}</span>
										<p>{translateAreas[language][item?.area?.profileArea]}</p>
									</div>
									<div className='col-2 flex'>
										<Tooltip text={formEETranslate.EEColaboratorData}>
											<button
												className='btn btn-outline-primary mx-1 rounded-circle p-0 pb-2'
												style={{ width: 30, height: 30 }}
												onClick={() => {
													setEmployeeData(item)
													setShowEdit(true)
												}}
											>
												<HiMiniPencilSquare />
											</button>
										</Tooltip>
										<Tooltip text={formEETranslate.EEDescriptionRole}>
											<button
												className='btn btn-outline-primary mx-1 rounded-circle p-0 pb-2'
												style={{ width: 30, height: 30 }}
												onClick={() => {
													setEmployeeData(item)
													setShowDescriptor(true)
												}}
											>
												<HiClipboardDocumentList />
											</button>
										</Tooltip>
										<Tooltip text='Excluir Colaborador'>
											<button
												className='btn btn-outline-primary mx-1 rounded-circle p-0 pb-2'
												style={{ width: 30, height: 30 }}
												onClick={() => {
													setEmployeeSelected(item.id)
													setShowDelete(true)
												}}
											>
												<HiMiniTrash />
											</button>
										</Tooltip>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
