import { RecruitmentAndSelectionRoutes } from "../../Routes/Routes";

const ras = () => {
  return (
    <div className="container-fluid recruitment-main">
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <RecruitmentAndSelectionRoutes />
        </div>
      </div>
    </div>
  );
};
export default ras;