import { portuguese_messages } from './pt-BR'
import { spanish_messages } from './es-ES'
import { english_messages } from './en-EN';

const messages = {
    ...portuguese_messages,
    ...spanish_messages,
    ...english_messages,
}

export default messages;