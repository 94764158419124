import './steps.sass'

const Steps = ({ services, group }) => {

    return (
        <div className="steps-line">
            {services.map((items, index) =>
                <div key={index} className={`step ${index === group ? 'active' : ''}`}></div>
            )}
        </div>
    )
}
export default Steps