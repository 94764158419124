// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalEditLinksMain .btn-img {
  opacity: 0.5;
}
.ModalEditLinksMain .modal-content {
  border-radius: 2rem;
  background-color: rgb(249, 249, 249);
}
.ModalEditLinksMain .btn-guardar {
  border: 2px solid #7A6BE8;
  background-color: #7A6BE8;
  color: white;
  text-align: center;
  padding-right: 30px;
  padding-left: 30px;
  border-radius: 20px;
  font-size: 18px;
}
.ModalEditLinksMain .span-img {
  max-height: 36px;
}
.ModalEditLinksMain .span-img img {
  max-height: 25px;
  max-width: 25px;
}
.ModalEditLinksMain input[type=tel], .ModalEditLinksMain input[type=email] {
  background: #ffffff;
  height: 36px;
  border: 2px solid #6d68aa;
  box-sizing: border-box;
  border-radius: 18px;
  font-family: "Montserrat", sans-serif !important;
  outline: none;
  padding: 0 18px;
  margin: 15px 0;
}`, "",{"version":3,"sources":["webpack://./src/Views/Employee/Components/ModalEditLinks/ModalEditLinks.sass"],"names":[],"mappings":"AACI;EACI,YAAA;AAAR;AACI;EACI,mBAAA;EACA,oCAAA;AACR;AAAI;EACI,yBAAA;EACA,yBAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;AAER;AADI;EACI,gBAAA;AAGR;AAFQ;EACI,gBAAA;EACA,eAAA;AAIZ;AAHI;EACI,mBAAA;EACA,YAAA;EACA,yBAAA;EACA,sBAAA;EACA,mBAAA;EACA,gDAAA;EACA,aAAA;EACA,eAAA;EACA,cAAA;AAKR","sourcesContent":[".ModalEditLinksMain\n    & .btn-img\n        opacity: .5\n    & .modal-content\n        border-radius: 2rem\n        background-color: rgb(249, 249, 249)\n    & .btn-guardar\n        border: 2px solid #7A6BE8\n        background-color: #7A6BE8\n        color: white\n        text-align: center\n        padding-right: 30px\n        padding-left: 30px\n        border-radius: 20px\n        font-size: 18px\n    & .span-img\n        max-height: 36px\n        img\n            max-height: 25px\n            max-width: 25px\n    & input[type=tel], input[type=email]\n        background: #ffffff\n        height: 36px\n        border: 2px solid #6d68aa\n        box-sizing: border-box\n        border-radius: 18px\n        font-family: \"Montserrat\", sans-serif !important\n        outline: none\n        padding: 0 18px\n        margin: 15px 0\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
