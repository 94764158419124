// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollable {
  height: calc(100vh - 60px);
  overflow-y: auto;
  background: none;
}`, "",{"version":3,"sources":["webpack://./src/Views/Service/index.sass"],"names":[],"mappings":"AAAA;EACI,0BAAA;EACA,gBAAA;EACA,gBAAA;AACJ","sourcesContent":[".scrollable\n    height: calc(100vh - 60px)\n    overflow-y: auto\n    background: none\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
