import "../stylesSteps.css";

const DosStepsForm2 = ({ imgProf, presProf, textProf }) => {
  return (
    <div className="container-fluid StepsFormClass">
      <div className="row text-center">
        <div className="col">
          <img className="img-fluid img-final-step m-auto" src={imgProf} />
          <h5 className="pt-4">
            {presProf}
            <br />
            <br />
            {textProf}
          </h5>
        </div>
      </div>
      <div className="row pt-4">
        <div className="col text-center">
          <button className="step-button-fin">Finalizar</button>
        </div>
      </div>
    </div>
  );
};

export default DosStepsForm2;
