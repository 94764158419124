import { assessmentTranslate } from '../../../i18n/Library/assessment'
import { useIntl } from 'react-intl'

export function HorizontalCard({ assets, content, title, list }) {
	const getLanguage = useIntl()
	const language = getLanguage.locale === 'PT-BR' ? 'pt' : 'es'

	return (
		<div className=' d-flex border tw-rounded-lg overflow-hidden'>
			<div className='p-4 tw-w-[291px]' style={{ background: assets[content.dimension].color }}>
				<div className='row'>
					<div className='col'>
						<p className='fs-5 fw-bold text-white' style={{ opacity: 0.75 }}>
							{title}
						</p>
					</div>
				</div>
				<div className='row gap-3'>
					<div className='col-2'>
						<div className='rounded-circle bg-white flex justify-content-center align-items-center' style={{ width: 35, height: 35, color: assets[content.dimension].color }}>
							{assets[content.dimension].icon}
						</div>
					</div>
					<div className='col text-white d-flex align-items-center m-0 p-0'>
						<h4 className='m-0 fw-bold'>{assessmentTranslate[language][content.dimension]}</h4>
					</div>
				</div>
			</div>
			<div className='p-4'>
				<div className='row'>
					<div className='col d-flex align-items-center'>
						<ol className='tw-list-decimal p-0 m-0 ms-3'>
							{list.map(item => (
								<li key={item.description} className='tw-text-md'>
									{item.description}
								</li>
							))}
						</ol>
					</div>
				</div>
			</div>
		</div>
	)
}
