import { useState, useContext, useEffect } from 'react'
import { MdArrowBack } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { CompanyContext } from '../../../../Store/CompanyContext'
import { jobsDescriptionTranslate } from '../../../../i18n/translatedMessages'
import jobList from './job_descriptor.json'
import jobListPT from './job_descriptor_pt-br.json'
import './jobDescription.sass'



const JobDescription = (props) => {
    const { store } = useContext(CompanyContext);
    const [job, setJob] = useState(jobList)

    useEffect(()=>{
            const newListTranslated = store.language == 'ES'? jobList.find(item => item.id === Number(props.match.params.id))
                : jobListPT.find(item =>item.id === Number(props.match.params.id))
        setJob(newListTranslated)
    }, [store.language])

    return (
        <div className="row">
            <div className="col offset-2">
                <div className="job-description">
                    <div className="row">
                        <div className="col mb-4">
                            <Link to='/app/services/descripcion-posiciones' className='back'>
                                <MdArrowBack />  {jobsDescriptionTranslate.jobsDescriptionReturn}
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="card mb-3 rounded-8">
                                <div className="card-body">
                                    <h4>{jobsDescriptionTranslate.jobsDescriptionTitleJob} {job.slug}?</h4>
                                    <p>{job.description}</p>
                                </div>
                            </div>
                            <div className="card mb-3 rounded-8">
                                <div className="card-body">
                                    <h4>{jobsDescriptionTranslate.jobsDescriptionActivies}</h4>
                                    <p>{job.main_activities}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="card mb-4 rounded-8">
                                <div className="card-body">
                                    <h6>{jobsDescriptionTranslate.jobsDescriptionFormation}</h6>
                                    <p className="fs-8">{job.profile}</p>
                                </div>
                            </div>
                            <div className="card mb-4 rounded-8">
                                <div className="card-body">
                                    <h6>{jobsDescriptionTranslate.jobsDescriptionPersonal}</h6>
                                    <p className="fs-8">{job.personal_profile}</p>
                                </div>
                            </div>
                            <div className="card mb-4 rounded-8">
                                <div className="card-body">
                                    <h6>{jobsDescriptionTranslate.jobsDescriptionRelacion}</h6>
                                    <p className="fs-8">{job.external_relationship_level}</p>
                                    <p className="fs-8">{job.internal_relationship_level}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="card mb-4 rounded-8">
                                        <div className="card-body">
                                            <h6>{jobsDescriptionTranslate.jobsDescriptionLanguage}</h6>
                                            <p className="fs-8">{job.language}</p>
                                        </div>
                                    </div></div>
                                <div className="col">
                                    <div className="card mb-4 rounded-8">
                                        <div className="card-body">
                                            <h6>{jobsDescriptionTranslate.jobsDescriptionStudy}</h6>
                                            <p className="fs-8">{job.study}</p>
                                        </div>
                                    </div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}
export default JobDescription