import { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import Swal from "sweetalert2";

import { getAbilities } from "../../API/Analytics/MYCO";
import { postEmailCompAndMotiv } from "../../API/Employee/MYCO";
import ChartsMYCO from "../../Components/ChartsMYCO/ChartsMYCO";
import Loading from "../../Components/Loading/Loading";
import {
	abilitiesCollaboratorTranslate,
	modalAssignTranslate,
	wheelCollaboratorTranslate,
} from "../../i18n/translatedMessages";
import { HabilidadesIcon } from "../../Icons/CollaboratorsIcons";

const B2EHabilidades = () => {
	const user = JSON.parse(localStorage.getItem("user"));

	const [showSpinner, setShowSpinner] = useState(false);

	const handleSend = async () => {
		setShowSpinner(true);
		const resp = await postEmailCompAndMotiv(user.id);
		if (resp.message === "Solicitação de acesso criada!") {
			setShowSpinner(false);
			Swal.fire({
				title: "👌",
				text: modalAssignTranslate.contactAlert,
				icon: "success",
				confirmButtonText: "Ok",
			});
		}
	};
	const labelsAbilities = [
		"Análisis y Resolución de Problemas",
		"Innovación",
		"Visión Estratégica",
		"Orientación al cliente",
		"Trabajo en Equipo",
		"Liderazgo",
		"Orientación a resultados",
		"Plan. Y Org.",
		"Gestión del cambio",
		"Auto desenvolvimento",
		"Gerenciamento de estresse",
	];
	const [abilities, setAbilities] = useState(null);

	useEffect(() => {
		const users = JSON.parse(localStorage.getItem("user"));
		const fetchEmployer = async () => {
			const abilitiesAux = await getAbilities(users.id);
			const abilitiesLessId =
				abilitiesAux.length > 1 ? abilitiesAux.at(-1) : null;
			if (!!abilitiesLessId) {
				delete abilitiesLessId.employerId;
				delete abilitiesLessId.id;
			}
			if (abilitiesAux.length > 1) setAbilities(abilitiesLessId);
		};
		fetchEmployer();
	}, []);
	return (
		<div className="container-fluid competencia-main">
			<div className="row mb-4">
				<div className="col-1">
					<span className="title-icon p-2 m-auto">
						<HabilidadesIcon size={20} />
					</span>
				</div>
				<div className="col-8">
					<h1>{wheelCollaboratorTranslate.habilities}</h1>
				</div>
			</div>
			{showSpinner ? (
				<Loading />
			) : (
				<>
					<div className="row justify-content-center">
						{abilities ? (
							<div className="col-12 py-4">
								<div className="abilitiesChartHeight m-auto">
									<ChartsMYCO
										testName={"Competencias"}
										labels={labelsAbilities}
										importData={abilities}
									/>
								</div>
								<div className="row row-cols-2 row-cols-md-4 g-3 mt-3">
									{Object.values(abilities).map(
										(ability, index) => {
											return (
												<div
													key={index}
													className={`col`}
												>
													<h6 className={`m-0`}>
														{labelsAbilities[index]}
													</h6>
													<ProgressBar
														label={ability + "/10"}
														className={`rounded-pill ${
															Number(ability) >= 5
																? `abilitiesAnswerPositive`
																: `abilitiesAnswerNegative`
														}`}
														now={ability}
														max={10}
													/>
												</div>
											);
										}
									)}
								</div>
							</div>
						) : (
							<>
								<div className="col-12 pt-4 text-center">
									<h4 className="title-b2e">
										{
											abilitiesCollaboratorTranslate.competeciaTitle
										}
									</h4>
								</div>
								<div className="col-12 pt-4 color-grey text-center">
									<h5>
										{
											abilitiesCollaboratorTranslate.collaboratorWarning
										}
									</h5>
									<h5>
										{
											abilitiesCollaboratorTranslate.collaboratorWarningTextP1
										}{" "}
										<b>
											{
												abilitiesCollaboratorTranslate.collaboratorWarningTextP2
											}
										</b>
										,
										{
											abilitiesCollaboratorTranslate.collaboratorWarningTextP3
										}
									</h5>
									<button
										className="btn competencia-btn rounded-pill my-3"
										onClick={handleSend}
									>
										{modalAssignTranslate.clickHere}
									</button>
									<h5 className="pt-3">
										{
											abilitiesCollaboratorTranslate.abilitiesCollaboratorTextEmail
										}
									</h5>
									<h5 className="pb-4">
										{
											abilitiesCollaboratorTranslate.abilitiesCollaboratorDoubtsText
										}
									</h5>
								</div>
							</>
						)}
					</div>
				</>
			)}
		</div>
	);
};
export default B2EHabilidades;
