// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-training-main .modal-content {
  border-radius: 2rem;
  background-color: rgb(249, 249, 249);
}
.modal-training-main .header-green {
  background-color: #0ab4c4;
}
.modal-training-main .btn-green-add-training {
  background-color: #27E6C4;
}
.modal-training-main .btn-green-add-training:hover {
  background-color: #0ab4c4;
}
.modal-training-main .modal-success .btn {
  width: 200px;
}
.modal-training-main .training-textarea {
  background: #ffffff;
  border: 2px solid #2b97db;
  box-sizing: border-box;
  border-radius: 18px 18px 0px 18px;
  outline: none;
  padding: 0 18px;
}`, "",{"version":3,"sources":["webpack://./src/Views/Employee/Components/ModalAddTraining/ModalAddTraining.sass"],"names":[],"mappings":"AACI;EACI,mBAAA;EACA,oCAAA;AAAR;AAEI;EACI,yBAAA;AAAR;AAEI;EACI,yBAAA;AAAR;AAEI;EACI,yBAAA;AAAR;AAEI;EACI,YAAA;AAAR;AAEI;EACI,mBAAA;EACA,yBAAA;EACA,sBAAA;EACA,iCAAA;EACA,aAAA;EACA,eAAA;AAAR","sourcesContent":[".modal-training-main\n    & .modal-content\n        border-radius: 2rem\n        background-color: rgb(249, 249, 249)\n\n    & .header-green\n        background-color: #0ab4c4\n\n    & .btn-green-add-training\n        background-color: #27E6C4\n\n    & .btn-green-add-training:hover\n        background-color: #0ab4c4\n\n    & .modal-success .btn\n        width: 200px\n\n    & .training-textarea\n        background: #ffffff\n        border: 2px solid #2b97db\n        box-sizing: border-box\n        border-radius: 18px 18px 0px 18px\n        outline: none\n        padding: 0 18px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
