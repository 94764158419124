// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmployeeDocuments .sent {
  background: #EBF7E6;
  color: #004500;
}
.EmployeeDocuments .pending {
  color: #513400;
  background: #F7D9A7;
}
.EmployeeDocuments .icon {
  background: linear-gradient(45deg, #40c2ff 0%, #32d8d8 100%);
  width: 50px;
  height: 50px;
  border-radius: 50rem;
  padding: 8px;
  margin: 0 10px;
  display: flex;
  align-items: center;
}
.EmployeeDocuments .icon svg {
  fill: white;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Views/Service/Views/CompanyDocuments/employeeDocuments.sass"],"names":[],"mappings":"AACI;EACI,mBAAA;EACA,cAAA;AAAR;AAEI;EACI,cAAA;EACA,mBAAA;AAAR;AAEI;EACI,4DAAA;EACA,WAAA;EACA,YAAA;EACA,oBAAA;EACA,YAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;AAAR;AAEQ;EACI,WAAA;EACA,WAAA;AAAZ","sourcesContent":[".EmployeeDocuments\n    .sent\n        background: #EBF7E6\n        color: #004500\n\n    .pending\n        color: #513400\n        background: #F7D9A7\n\n    .icon\n        background: linear-gradient(45deg,#40c2ff 0%, #32d8d8 100%)\n        width: 50px\n        height: 50px\n        border-radius: 50rem\n        padding: 8px\n        margin: 0 10px\n        display: flex\n        align-items: center\n\n        & svg\n            fill: white\n            width: 100%"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
