import { useState, useEffect, useContext } from 'react';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { getEmployeesExp, postEmployeesExp, deleteEmployeesExp, putEmployeesExp } from '../../API/Employee/EmployeesExp';
import { CompanyContext } from '../../Store/CompanyContext';
import { modalAssignTranslate, wheelCollaboratorTranslate } from '../../i18n/translatedMessages';
import ModalResponseStatus from '../../Components/ModalResponseStatus/ModalResponseStatus';
import { FormattedMessage } from 'react-intl';
import { HistoricoProfessionalIcon } from '../../Icons/CollaboratorsIcons';
import * as RB from 'react-bootstrap';
import Loading from '../../Components/Loading/Loading';
import { AccordionItem } from './Components/Accordion';

const objEmpty = {
  id: null,
  company: '',
  industryTypeId: '',
  job: '',
  startMonth: '',
  startYear: '',
  stillInProgress: false,
  endMonth: '',
  endYear: '',
  currentJob: false,
  functions: '',
  achievements: '',
};

const InfoProf = () => {
  const { store, actions } = useContext(CompanyContext);
  const [profInfos, setProfInfos] = useState([objEmpty]);
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);
  const [currentSelect, setCurrentSelect] = useState(null);

  const getRequest = async () => {
    const users = JSON.parse(localStorage.getItem('user'));
    setLoading(true);
    const empExp = await getEmployeesExp(users.id);
    const empExpAux = empExp.sort((x, y) => (x.endYear ? (x.endYear * 12 + x.endMonth > y.endYear * 12 + y.endMonth ? -1 : 1) : x.startYear * 12 + x.startYear > y.startYear * 12 + y.startYear ? -1 : 1));
    empExpAux?.length > 0 ? setProfInfos([...empExpAux]) : setProfInfos([objEmpty]);
    setLoading(false);
  };

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('user')));

    getRequest();
  }, []);

  const handleSend = async () => {
    setLoading(true);
    const objSend = profInfos
      .filter((filt) => filt.id === null && filt.startMonth !== '')
      .map((collaborator) => {
        return {
          company: collaborator.company,
          industryTypeId: parseInt(collaborator.industryTypeId),
          job: collaborator.job,
          startMonth: collaborator.startMonth,
          startYear: collaborator.startYear,
          endMonth: collaborator.endMonth,
          endYear: collaborator.endYear,
          employerId: user.id,
          currentJob: collaborator.stillInProgress,
          functions: collaborator.functions,
          achievements: collaborator.achievements,
        };
      });
    if (objSend.length > 0) {
      const postProfInfo = await postEmployeesExp(objSend, user.id);
      if (postProfInfo.status < 200 || postProfInfo.status > 299) {
        if (postProfInfo.status > 399 && postProfInfo.status < 500) {
          //resp.status [400-499] not found
          actions.setResponseStatus(false);
          actions.setMessageResponseStatus(modalAssignTranslate.errorAlert);
          actions.setShowModalResponseStatus(true);
        } else {
          // 300 - 399 [redirect]
          actions.setResponseStatus(false);
          actions.setMessageResponseStatus(modalAssignTranslate.errorAlert);
          actions.setShowModalResponseStatus(true);
        }
      } else {
        //>=200 || <300  [200-299] OK
        actions.setResponseStatus(true);
        actions.setMessageResponseStatus(modalAssignTranslate.successAlert);
        actions.setShowModalResponseStatus(true);
        const empExp = await getEmployeesExp(user.id);
        empExp?.length > 0 ? setProfInfos([...empExp]) : setProfInfos([objEmpty]);
        getRequest();
      }
    }
    setLoading(false);
  };

  const handleUpdate = async (index, e) => {
    setLoading(true);
    const objSend = profInfos.map((collaborator) => {
      return {
        company: collaborator.company,
        industryTypeId: parseInt(collaborator.industryTypeId),
        job: collaborator.job,
        startMonth: collaborator.startMonth,
        startYear: collaborator.startYear,
        endMonth: collaborator.endMonth,
        endYear: collaborator.endYear,
        employerId: user.id,
        currentJob: collaborator.currentJob,
        functions: collaborator.functions,
        achievements: collaborator.achievements,
      };
    });
    const expid = JSON.parse(e.currentTarget.dataset.expid);
    await putEmployeesExp(objSend[index], expid);
    getRequest();
    setLoading(false);
  };

  const handleAdd = () => {
    const auxArray = [...profInfos];
    auxArray.push(objEmpty);
    setProfInfos(auxArray);
    setCurrentSelect(auxArray.length - 1);
  };

  const handleChange = (index, event) => {
    const auxArray = [...profInfos];
    auxArray[index][event.currentTarget.name] = event.currentTarget.value;
    auxArray[index].stillInProgress = event.currentTarget.checked;
    auxArray[index].currentJob = event.currentTarget.checked;
    setProfInfos(auxArray);
  };

  const deleteExp = async (index, e) => {
    const rows = [...profInfos];
    if (index > -1) {
      rows.splice(index, 1);
      setProfInfos([...rows]);
    }
    const expid = JSON.parse(e.currentTarget.dataset.expid);
    await deleteEmployeesExp(expid);
  };

  const handleClose = () => {
    actions.setShowModalResponseStatus(false);
  };

  return (
    <div className="container-fluid profecional-info-main">
      <FormattedMessage id={'nextButton'}>{(msg) => <ModalResponseStatus show={store.showModalResponseStatus} handleClose={handleClose} message={store.messageResponseStatus} successMessage={store.responseStatus} btnRight={msg} />}</FormattedMessage>
      <div className="row mb-4">
        <div className="col-1">
          <span className="title-icon p-2 m-auto">
            <HistoricoProfessionalIcon size={20} />
          </span>
        </div>
        <div className="col-8">
          <h1>{wheelCollaboratorTranslate.professionalHistoric}</h1>
        </div>
      </div>

      {loading ? (
        <Loading />
      ) : (
        <div className="row justify-content-center">
          <div className="col-12 text-end">
            <button className="btn rounded-pill addEmployeeInfo-btn" onClick={handleAdd}>
              Add <AiOutlinePlusCircle size={25} />
            </button>
          </div>
          <div className="col-12 pt-4">
            <RB.Accordion className="pb-3">
              {profInfos?.map((item, index) => (
                <AccordionItem key={index} item={item} index={index} select={currentSelect} onClick={handleSend} onChange={handleChange} onUpdate={handleUpdate} onDelete={deleteExp} />
              ))}
            </RB.Accordion>
          </div>
        </div>
      )}
    </div>
  );
};
export default InfoProf;
