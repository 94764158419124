// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transfer-colaboradores {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}
.transfer-colaboradores .btn-back {
  width: 100%;
}
.transfer-colaboradores .btn-back .item-circle {
  width: 40px;
  height: 40px;
  border-radius: 50rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid;
  color: #6e6e6e;
}
.transfer-colaboradores .btn-back .item-circle svg {
  margin: 0;
  fill: #6e6e6e;
}
.transfer-colaboradores .content-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 150px;
  border: none;
  height: 70vh;
}
.transfer-colaboradores .content-body p {
  font-size: 16px;
  font-weight: bold;
  text-align: justify;
}
.transfer-colaboradores .content-body ul {
  display: flex;
  padding: 0;
  margin: 20px 0;
  list-style: none;
}
.transfer-colaboradores .content-body ul li {
  display: flex;
  flex-direction: column;
  width: 150px;
  align-items: center;
}
.transfer-colaboradores .content-body ul li img {
  margin: 5px 0;
  width: 50px;
  height: 50px;
}`, "",{"version":3,"sources":["webpack://./src/Views/Service/Views/PeopleAnalytics/Management/transferColaboradores.sass"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EAEA,mBAAA;EACA,eAAA;AAAJ;AAEI;EACI,WAAA;AAAR;AAEQ;EACI,WAAA;EACA,YAAA;EACA,oBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;EACA,cAAA;AAAZ;AAEY;EACI,SAAA;EACA,aAAA;AAAhB;AAEI;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;EACA,YAAA;EACA,YAAA;AAAR;AAEQ;EACI,eAAA;EACA,iBAAA;EACA,mBAAA;AAAZ;AAEQ;EACI,aAAA;EACA,UAAA;EACA,cAAA;EACA,gBAAA;AAAZ;AAEY;EACI,aAAA;EACA,sBAAA;EACA,YAAA;EACA,mBAAA;AAAhB;AAEgB;EACI,aAAA;EACA,WAAA;EACA,YAAA;AAApB","sourcesContent":[".transfer-colaboradores\n    display: flex\n    flex-direction: column\n\n    align-items: center\n    padding: 0 20px\n\n    & .btn-back\n        width: 100%\n\n        & .item-circle\n            width: 40px\n            height: 40px\n            border-radius: 50rem\n            display: flex\n            align-items: center\n            justify-content: center\n            border: 3px solid\n            color: #6e6e6e\n\n            & svg\n                margin: 0\n                fill: #6e6e6e\n\n    & .content-body\n        display: flex\n        flex-direction: column\n        align-items: center\n        justify-content: center\n        padding: 0 150px\n        border: none\n        height: 70vh\n\n        & p\n            font-size: 16px\n            font-weight: bold\n            text-align: justify\n\n        & ul\n            display: flex\n            padding: 0\n            margin: 20px 0\n            list-style: none\n\n            & li\n                display: flex\n                flex-direction: column\n                width: 150px\n                align-items: center\n\n                & img\n                    margin: 5px 0\n                    width: 50px\n                    height: 50px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
