import { businessCultureQuestions } from "../../../../i18n/translatedMessages";

export const culturalQuestions = [
    { question: businessCultureQuestions.businessCulturalQ1 },
    { question: businessCultureQuestions.businessCulturalQ2 },
    { question: businessCultureQuestions.businessCulturalQ3 },
    { question: businessCultureQuestions.businessCulturalQ4 },
    { question: businessCultureQuestions.businessCulturalQ5 },
    { question: businessCultureQuestions.businessCulturalQ6 },
];

export const carreraQuestions = [
    { question: businessCultureQuestions.businessCareerQ1 },
    { question: businessCultureQuestions.businessCareerQ2 },
    { question: businessCultureQuestions.businessCareerQ3 },
    { question: businessCultureQuestions.businessCareerQ4 },
    { question: businessCultureQuestions.businessCareerQ5 },
    { question: businessCultureQuestions.businessCareerQ6 },
];

export const liderazgoQuestions = [
    { question: businessCultureQuestions.businessLeaderQ1 },
    { question: businessCultureQuestions.businessLeaderQ2 },
    { question: businessCultureQuestions.businessLeaderQ3 },
    { question: businessCultureQuestions.businessLeaderQ4 },
    { question: businessCultureQuestions.businessLeaderQ5 },
    { question: businessCultureQuestions.businessLeaderQ6 },
];

export const interesQuestions = [
    { question: businessCultureQuestions.businessInterestsQ1 },
    { question: businessCultureQuestions.businessInterestsQ2 },
    { question: businessCultureQuestions.businessInterestsQ3 },
    { question: businessCultureQuestions.businessInterestsQ4 },
    { question: businessCultureQuestions.businessInterestsQ5 },
    { question: businessCultureQuestions.businessInterestsQ6 },
];
