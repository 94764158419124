import { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";

import Loading from "../../../../../../Components/Loading/Loading";

import { Input } from "../Input";
import { Select } from "../Select";

import CountryMapper from "../../../../../../Utils/CountryMapper";
import BandsMapper from "../../../../../../Utils/BandsMapper";

import { getCountry } from "../../../../../../API/Lists/country";
import Bands from "../../../../../../API/Services/PayBalance/Bands";

export const EditBand = ({ show, handleClose, id, refresh }) => {
  const [band, setBand] = useState(null);
  const [loading, setLoading] = useState(false);

  const [countries, setCountries] = useState(null);

  const handleChange = (event) => {
    setBand({
      ...band,
      [event.target.name]: event.target.value,
    });
  };

  const dataLoad = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getCountry();
      const bandEdit = await Bands.getBand(id);

      setCountries(response.map(CountryMapper.toDomain));
      setBand(BandsMapper.toDomain(bandEdit));
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    dataLoad();
  }, [dataLoad]);

  const saveData = async () => {
    try {
      setLoading(true);
      const data = {
        company_id: JSON.parse(localStorage.getItem("user")).id,
        name: band.name,
        band: band.band,
        country_id: band.country,
      };

      await Bands.updateBands(id, data);
      handleClose();
      refresh();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h3 className="fw-bold">Editar banda</h3>
        </div>
      </div>
      {loading && (
        <div style={{ height: 450 }}>
          <Loading size="sm" />
        </div>
      )}
      {band && !loading && (
        <>
          <Input
            label="nível"
            placeholder="Nome do Nível"
            name="name"
            value={band.name}
            onChange={handleChange}
            className="my-4"
          />
          <Select
            options={countries}
            label="país"
            placeholder="Selecionar pais (opcional)"
            name="country"
            value={band.country}
            onChange={handleChange}
            className="my-4"
          />
          <Input
            label="Ponto Medio do Nivel"
            placeholder="Ex.: R$50000"
            name="band"
            value={band.band}
            onChange={handleChange}
            className="my-4"
          />
          <div className="d-flex justify-content-end mt-2">
            <Button
              variant="outline-grey"
              className="rounded-pill mx-2 text-black px-3"
              onClick={handleClose}
            >
              Cerrar
            </Button>
            <Button
              variant="primary"
              className="rounded-pill px-4"
              onClick={saveData}
            >
              Guardar cambios
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
