import { MydnaLogoBastonetes } from '../../../../Icons/logo';
import { emptyInformationTranslate } from '../../../../i18n/translatedMessages';
import './emptyInformation.sass';

const EmptyInformation = ({ message }) => (
  <div className="box-no-information">
    {message ? message : emptyInformationTranslate.incompleteProfile}
    <MydnaLogoBastonetes />
  </div>
);

export default EmptyInformation;
