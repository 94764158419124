import { IoIosArrowBack, IoIosArrowDropleftCircle } from 'react-icons/io'
import { MdDownload } from 'react-icons/md'
import { HorizontalCard } from '../HorizontalCard/horizontalCard'
import { HiClipboardDocumentList, HiFlag, HiMiniUser, HiMiniUserGroup } from 'react-icons/hi2'
import { Card } from '../Card/card'
import { Pills } from '../Pills/pills'

export function MotivationResult({ onCloseResult }) {
	const cardContent = [
		{
			id: 1,
			icon: <HiClipboardDocumentList size={28} />,
			percentagem: 66,
			title: 'Organizacional',
			subtitle: 'Potencial Ejecutivo',
			color: '#008ACD',
		},
		{
			id: 2,
			icon: <HiMiniUserGroup size={28} />,
			percentagem: 58,
			title: 'Social',
			subtitle: 'Potencial Interpersonal',
			color: '#6C4FDB',
		},
		{
			id: 3,
			icon: <HiFlag size={28} />,
			percentagem: 53,
			title: 'Líder',
			subtitle: 'Potencial de Liderazgo',
			color: '#00A28B',
		},
		{
			id: 4,
			icon: <HiMiniUser size={28} />,
			percentagem: 50,
			title: 'Persona',
			subtitle: 'Potencial Personal',
			color: '#D87706',
		},
	]

	const pillsContent = [
		{
			id: 1,
			label: 'Emocional',
			color: '#008ACD',
			icon: <IoIosArrowDropleftCircle size={16} />,
		},
		{
			id: 2,
			label: 'Tenaz',
			color: '#D87706',
			icon: <IoIosArrowDropleftCircle size={16} />,
		},
		{
			id: 3,
			label: 'Organizacional',
			color: '#00A28B',
			icon: <IoIosArrowDropleftCircle size={16} />,
		},
		{
			id: 4,
			label: 'Imaginativo',
			color: '#6C4FDB',
			icon: <IoIosArrowDropleftCircle size={16} />,
		},
		{
			id: 1,
			label: 'Emocional',
			color: '#008ACD',
			icon: <IoIosArrowDropleftCircle size={16} />,
		},
		{
			id: 2,
			label: 'Tenaz',
			color: '#D87706',
			icon: <IoIosArrowDropleftCircle size={16} />,
		},
		{
			id: 3,
			label: 'Organizacional',
			color: '#00A28B',
			icon: <IoIosArrowDropleftCircle size={16} />,
		},
		{
			id: 4,
			label: 'Imaginativo',
			color: '#6C4FDB',
			icon: <IoIosArrowDropleftCircle size={16} />,
		},
	]

	return (
		<>
			<div>
				<div className='tw-flex tw-items-center tw-justify-between tw-mb-5'>
					<div className='tw-flex tw-space-x-4 tw-items-center'>
						<button onClick={onCloseResult} className='tw-rounded-full tw-text-purple-500 tw-p-1 tw-w-[32px] tw-h-[32px] tw-bg-white hover:tw-bg-purple-500 hover:tw-text-white tw-flex tw-items-center tw-justify-center border border-primary'>
							<IoIosArrowBack />
						</button>
						<div className='tw-text-xl'>Luiz Henrique</div>
					</div>
					<div className='tw-bg-purple-500 tw-p-3 tw-flex tw-space-x-2 tw-items-center tw-rounded-lg tw-text-white  hover:tw-bg-purple-400 tw-cursor-pointer'>
						<MdDownload />
						<div className='tw-flex tw-items-center '>Descargar respuesta</div>
					</div>
				</div>

				<div className='tw-space-y-10'>
					<div>
						<div className='tw-p-4 tw-bg-gray-100 tw-font-bold tw-text-xl tw-mb-5'>Palabras clave sobre el perfil</div>
						<div className='tw-flex tw-items-center tw-justify-center'>
							<div className='tw-grid  tw-gap-2 tw-grid-cols-2 lg:tw-gap-3 lg:tw-grid-cols-3'>
								{pillsContent.map(item => (
									<div className='' key={item.id}>
										<Pills label={item.label} color={item.color} />
									</div>
								))}
							</div>
						</div>
					</div>

					<div>
						<div className='tw-p-4 tw-bg-gray-100 tw-font-bold tw-text-xl tw-mb-5'>Competencias evaluadas en 4 dimensiones</div>
						<div className='tw-flex tw-justify-center tw-gap-4'>
							{cardContent.map(item => (
								<Card content={item} key={item.id} />
							))}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
