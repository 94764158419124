import aws from 'aws-sdk'
import { useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { FaYoutube } from 'react-icons/fa'

import { btnTranslate } from '../../../../../i18n/translatedMessages'
import './modalLinks.sass'

aws.config.update({
	accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
	secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
})

const myBucket = new aws.S3({
	region: 'us-east-1',
})

const ModalLinks = ({ show, handleClose, data, handleChange, handleSendYoutubeLink }) => {
	const [progress, setProgress] = useState(0)
	const [selectedFile, setSelectedFile] = useState(null)

	const handleFileInput = e => {
		setSelectedFile(e.target.files[0])
	}

	const uploadFile = file => {
		const user = JSON.parse(localStorage.getItem('user'))
		const fileName = file.name.replace(/ /g, '_')
		const params = {
			ACL: 'public-read',
			Body: file,
			Bucket: `${process.env.REACT_APP_AWS_BUCKET_NAME}/documents/profilecompanypdf`,
			Key: user.id + fileName,
		}

		// myBucket
		//   .putObject(params)
		//   .on('httpUploadProgress', (evt) => {
		//     setProgress(Math.round((evt.loaded / evt.total) * 100));
		//   })
		//   .send((err) => {
		//     if (err) console.log(err);
		//   });
		const pdfLink = `${process.env.REACT_APP_AWS_BUCKET_NAME}/documents/profilecompanypdf/` + params.Key
		handleSendYoutubeLink(pdfLink)
	}
	return (
		<Modal show={show} onHide={handleClose} backdrop='static' keyboard={false} aria-labelledby='contained-modal-title-vcenter' centered className='modal-links-industry'>
			<Modal.Header>
				<Modal.Title>Youtube contenido</Modal.Title>
				<div className='btn-close' onClick={handleClose}></div>
			</Modal.Header>
			<Modal.Body className='d-flex flex-column align-items-center'>
				<Row className='w-100'>
					<Form.Label>Youtube video</Form.Label>
					<Col className='d-flex align-items-center'>
						<Form.Label className='mx-3 mb-0 color-blue'>
							<FaYoutube size={23} />
						</Form.Label>
						<Form.Control name='youtubelink' onChange={handleChange} value={data?.youtubelink}></Form.Control>
					</Col>
				</Row>
				{/* <Row className='w-100'>
					<Col className='d-flex my-4 align-items-center'>
						<Form.Group className='w-100'>
							<Form.Label>Arquivo PDF {progress && progress + '%'}</Form.Label>
							<Form.Control type='file' onChange={handleFileInput} />
						</Form.Group>
					</Col>
				</Row> */}
				<Row className='ms-auto'>
					<Col>
						<Button variant='white' className='rounded-pill' onClick={selectedFile ? () => uploadFile(selectedFile) : handleClose}>
							{btnTranslate.saveButton}
						</Button>
					</Col>
				</Row>
			</Modal.Body>
		</Modal>
	)
}
export default ModalLinks
