// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalEditEmployee-main .modal-content {
  border-radius: 2rem;
  background-color: rgb(249, 249, 249);
}
.ModalEditEmployee-main .btn {
  width: 200px;
}
.ModalEditEmployee-main .btn-green {
  background-color: #0ab4c4;
  color: white;
}
.ModalEditEmployee-main .btn-purple {
  background-color: #7A6BE8;
  color: white;
}
.ModalEditEmployee-main .loading-component {
  height: auto;
}
.ModalEditEmployee-main .form-control {
  margin: 0px 0px 10px 0px;
}
.ModalEditEmployee-main select {
  border: 2px solid #6d68aa !important;
  outline: none;
  border-radius: 18px !important;
}`, "",{"version":3,"sources":["webpack://./src/Views/Service/Views/PeopleAnalytics/Components/ModalEditEmployee/ModalEditEmployee.sass"],"names":[],"mappings":"AACI;EACI,mBAAA;EACA,oCAAA;AAAR;AAEI;EACI,YAAA;AAAR;AAEI;EACI,yBAAA;EACA,YAAA;AAAR;AAEI;EACI,yBAAA;EACA,YAAA;AAAR;AAEI;EACI,YAAA;AAAR;AAEI;EACI,wBAAA;AAAR;AAEI;EACI,oCAAA;EACA,aAAA;EACA,8BAAA;AAAR","sourcesContent":[".ModalEditEmployee-main\r\n    & .modal-content\r\n        border-radius: 2rem\r\n        background-color: rgb(249, 249, 249)\r\n\r\n    & .btn\r\n        width: 200px\r\n\r\n    & .btn-green\r\n        background-color: #0ab4c4\r\n        color: white\r\n\r\n    & .btn-purple\r\n        background-color: #7A6BE8\r\n        color: white\r\n    \r\n    & .loading-component\r\n        height: auto\r\n\r\n    .form-control\r\n        margin: 0px 0px 10px 0px\r\n    \r\n    select\r\n        border: 2px solid #6d68aa !important\r\n        outline: none\r\n        border-radius: 18px !important"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
