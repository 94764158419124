import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { ServicesContext } from "../../../../Store/ServicesContext";
import {bodyTextTranslate} from "../../../../i18n/translatedMessages"

const ButtonClick = ({ option, handleShow, roleID }) => {
    const history = useHistory()
    const { actions } = useContext(ServicesContext)

    const handleClick = () => {
        switch (option.title) {
            case "Consulta en tiempo real":
                actions.showSMTM();
                break;
            case "Executive search":
                actions.showBondingChangeProf();
                break;
            case "Análisis de desempeño":
                actions.showAnalisisDesempeno();
                break;
            case "Middle & top management":
                actions.showBonding();
                break;
            case "Backoffice & staff":
                actions.showBonding();
                break;
            case "Plataforma de onboarding gamificado":
                actions.showDosStepsForm();
                break;
            case "Dashboard empresa":
                actions.showDosStepsFormChangeProf1();
                break;
            case "Herramienta de entrenamiento gamificado":
                actions.showDosStepsFormChangeProf1();
                break;
            case "Test nivel de inglés":
                actions.showDosStepsFormChangeProf1();
                break;
            case "Descriptores de cargo":
                history.push("/app/descripcion-posiciones");
                break;
            case "Proyección de carrera":
                history.push("/app/proyeccion-carrera");
                break;
            case "Proyección salarial":
                actions.showDosStepsFormChangeProf2();
                break;
            case "Estudio personalizado":
                actions.showDosStepsFormChangeProf2();
                break;
            case "Reporte de cultura":
                history.push("/app/reporte-cultura");
                break;
            case "Fit cultural candidados":
                actions.showDosStepsFormChangeProf3();
                break;
            case "Transformación cultural":
                actions.showDosStepsFormChangeProf3();
                break;
            case "Clases de inglés":
                actions.showTresStepsForm();
                break;
            case "Capacitaciones":
                history.push('/app/capacitaciones')
                break;
            case "Entrenamiento pitch comercial & ventas":
                actions.showTresStepsForm();
                break;
            case "Fit cultural colaboradores":
                actions.showTresStepsForm();
                break;
            case "Test de motivaciones":
                actions.showTresStepsForm();
                break;
            case "Test de habilidades":
                actions.showTresStepsForm();
                break;
            case "Top management":
                actions.showTresStepsForm();
                break;
            case "Midle managment":
                actions.showTresStepsForm();
                break;
            case "Backoffice & staff (Salida)":
                actions.showTresStepsForm();
                break;
            case "Estudio remuneración variable":
                actions.showRemuneracionVariable();
                break;
            case "Guía salarial":
                window.open(option.file, '_blank');
                break;
            case "Colaborador 360°":
                history.push("/app/gestion/colaboradores");
                break
            default:
                handleShow();
                break
        }

    }


    return (
        roleID === 2 ?
            <button onClick={handleClick}>{bodyTextTranslate.activeService}</button> :
            <button onClick={handleShow}>{bodyTextTranslate.activeService}</button>
    )
}
export default ButtonClick