// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `tr.highlight td {
    padding-top: 15px; 
    padding-bottom:15px;
    border-bottom: 1px solid rgb(211, 211, 211);
  }


tr, th {
    border: 0;
}

.btn-descargar-green{
    border-radius: 20px;
    padding: 5px 10px;
    border: none;
    background-color: #27E6C4;
    color: #444258;
    font-weight: bold;
}

`, "",{"version":3,"sources":["webpack://./src/Views/Services/Components/PayBalance/Components/ListadoReportes.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,2CAA2C;EAC7C;;;AAGF;IACI,SAAS;AACb;;AAEA;IACI,mBAAmB;IACnB,iBAAiB;IACjB,YAAY;IACZ,yBAAyB;IACzB,cAAc;IACd,iBAAiB;AACrB","sourcesContent":["tr.highlight td {\r\n    padding-top: 15px; \r\n    padding-bottom:15px;\r\n    border-bottom: 1px solid rgb(211, 211, 211);\r\n  }\r\n\r\n\r\ntr, th {\r\n    border: 0;\r\n}\r\n\r\n.btn-descargar-green{\r\n    border-radius: 20px;\r\n    padding: 5px 10px;\r\n    border: none;\r\n    background-color: #27E6C4;\r\n    color: #444258;\r\n    font-weight: bold;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
