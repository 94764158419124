import { Link } from "react-router-dom";
import { Modal, Row, Col } from "react-bootstrap";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";

import "./ModalResponseStatus.sass"

const ModalResponseStatus = ({
  show,
  message,
  linkSendTo,
  handleClose,
  successMessage,
  recharge,
  btnRight,
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-status-main"
    >
      <Modal.Body>
        <Row>
          <Col xs={12} sm={12} className="text-center">
            <h5>{message}</h5>
          </Col>
          {successMessage ? (
            <Col xs={12} sm={12} className="text-center">
              <AiFillCheckCircle className="text-exito" size={50} />
            </Col>
          ) : (
            <Col xs={12} sm={12} className="text-center">
              <AiFillCloseCircle className="text-error" size={50} />
            </Col>
          )}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Col className="text-start">
          {recharge ? (
            successMessage ? (
              <button
                className="btn btn-purple rounded-pill text-light"
                onClick={recharge}
              >
                Agregar nuevo
              </button>
            ) : (
              <button
                className="btn btn-purple rounded-pill text-light"
                onClick={handleClose}
              >
                Intentar de nuevo
              </button>
            )
          ) : (
            ""
          )}
        </Col>
        <Col className="text-end">
          {linkSendTo ? (
            <Link
              className="btn btn-green rounded-pill text-light"
              to={linkSendTo}
              onClick={handleClose}
            >
              {btnRight}
            </Link>
          ) : (
            <button
              className="btn btn-green rounded-pill text-light"
              onClick={handleClose}
            >
              {btnRight}
            </button>
          )}
        </Col>
      </Modal.Footer>
    </Modal>
  );
};
export default ModalResponseStatus;
