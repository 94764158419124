export const getKnowledge = async (employerId) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/knowledge/${employerId}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};
export const postKnowledge = async (data) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/knowledge`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};
export const deleteKnowledge = async (knowledgeId) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/knowledge/${knowledgeId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};
export const putKnowledge = async (data, knowledgeId) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/knowledge/${knowledgeId}`, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};
