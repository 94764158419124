class subareasMapper {
	toDomain(persistence) {
		return {
			value: persistence.name,
			id: persistence.id,
			area: persistence.belongsToArea
		}
	}
}

export default new subareasMapper()