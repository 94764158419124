import Loading from "../../Components/Loading/Loading";
import { cultureBusinessTranslate } from "../../i18n/translatedMessages";
import CulturaBar from "../Analytics/Components/CircularBar/CircularBar";

const bars = [
	{
		id: 0,
		icon: "https://assets.mydna.company/Comercial.svg",
		type: "Comercial",
		color: "#7a6be6",
		desc: "Orientación a lograr resultados. Ambiente motiva en parte a colaboradores a cumplir metas. Se puede mejorar. Estilo de liderazgo motiva, pero se puede poner mas agresividad comercial. Existe competitividad en el equipo, pero se puede mejorar. Se establece relaciones con clientes pero no siempre a largo plazo. Se observa en el equipo una mayor satisfacción por ganar y captar mercado. Existen desafíos por crecer pero se puede ser más ambicioso.",
	},
	{
		id: 1,
		icon: "https://assets.mydna.company/Disruptivo.svg",
		type: "Disruptiva",
		color: "#41c6ff",
		desc: "Estilo de organización más innovadora, se escucha y se trabaja más en equipo. Se planifica más a largo plazo. El liderazgo es más motivador, de tomar riesgos, más emprendedor. Los equipos comienzan a ser más visionarios y a tener más hambre de salir de la caja. Se tiene mayor tolerancia a la diversidad y diferencias entre equipos. La expresión 'libertad de hacer' comienza a escucharse más.",
	},
	{
		id: 2,
		icon: "https://assets.mydna.company/Colaborativo.svg",
		type: "Colaborativa",
		color: "#2be7c8",
		desc: "Estilo de organización con foco en las personas. Preocupados de generar un ambiente de trabajo grato y cercano. El desarrollo profesional es parte de los desafíos permanentes. Liderazgo incentivando el trabajo en equipo. Existe alta comunicación entre jefaturas y colaboradores. La estrategia es conocida por todos. Equipos alineados y comprometidos con la empresa.",
	},
	{
		id: 3,
		icon: "https://assets.mydna.company/Tradicional.svg",
		type: "Tradicional",
		color: "#38364b",
		desc: "Estilo de organización levemente estructurado. Se cuenta con una estructura piramidal, donde las decisiones son tomadas por pocos. La comunicación de las decisiones o estrategia es lenta, conocida por pocos y generalmente jefaturas. Se dispone de algunos protocolos y procedimientos. Se busca estabilidad y permanencia en el tiempo. La eficiencia es un pilar para la toma de decisiones",
	},
];

const MatchFinished = ({ profile }) => {
	return (
		<>
			{profile ? (
				<>
					<div className="row">
						<div className="col-12 mb-2">
							<h5 className="text-center">
								{cultureBusinessTranslate.cbTimeLineAswer}{" "}
								<span
									className={`type ${profile.type.toLowerCase()}`}
								>
									{profile.type.toUpperCase()}
								</span>
								.
							</h5>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							{profile !== null && (
								<>
									<div className="row">
										{bars.map((item, index) => (
											<div
												key={index}
												className="col-3 d-flex flex-column align-items-center"
											>
												<CulturaBar
													percentagem={
														profile[
															item.type.toLowerCase()
														]
													}
													color={item.color}
												>
													<img
														src={item.icon}
														alt=""
													/>
												</CulturaBar>
												<span
													style={{
														color: item.color,
													}}
												>
													{item.type}
												</span>
											</div>
										))}
									</div>
								</>
							)}
						</div>
					</div>
				</>
			) : (
				<Loading />
			)}
		</>
	);
};
export default MatchFinished;
