import React, { useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2'

import { ServicesContext } from '../../../../../Store/ServicesContext'
import TablaPosiciones from '../Components/TablaPosiciones'

const Posiciones = () => {
	const { actions } = useContext(ServicesContext)
	const [companyIdFromSMTM, setCompanyIdFromSMTM] = useState(null)

	useEffect(() => {
		async function fetchData() {
			await getIdSmtmByCompanyName()
		}
		fetchData()
	}, [companyIdFromSMTM])

	const getIdSmtmByCompanyName = async () => {
		await fetch(`https://smtm.co/v1/getCompanyIdFromMyDna?name=${JSON.parse(localStorage.getItem('user')).name}`)
			.then(response => response.json())
			.then(data => setCompanyIdFromSMTM(data))
			.catch(() =>
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Algo salió mal !',
				})
			)
		actions.getDataPositionsCompany(companyIdFromSMTM)
	}

	return (
		<>
			<div className='w-100 px-5 mx-5'>
				<TablaPosiciones companyIdFromSMTM={companyIdFromSMTM} />
			</div>
		</>
	)
}

export default Posiciones
