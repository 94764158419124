import React, { memo, useContext, useEffect, useState } from 'react'
import { FiDownloadCloud } from 'react-icons/fi'
import Swal from 'sweetalert2'
import { salariesAndBenefitsTranslate, salaryTitle } from '../../../../../i18n/translatedMessages'

import { getAllPhotoUrls } from '../../../../../API/Employee/Employee'
import { ServicesContext } from '../../../../../Store/ServicesContext'

import Loading from '../../../../../Components/Loading/Loading'
import TablePositionItem from './TablePositionItem'

import { FaFilter } from 'react-icons/fa'
import { useIntl } from 'react-intl'
import FilterComponent from './PositionCompanyFilter/FilterComponent'

import './TablaPosiciones.css'

const TablaPosiciones = props => {
	const language = useIntl()
	const { store, actions } = useContext(ServicesContext)
	const [isLoading, setIsLoading] = useState(true)

	const [showFilterComponent, setShowFilterComponent] = useState(true)

	useEffect(() => {
		async function fetchData() {
			await actions.getDataPositionsCompany(props.companyIdFromSMTM)
			await actions.getDataCompany(JSON.parse(localStorage.getItem('email')))

			setTimeout(() => {
				setIsLoading(false)
				setShowFilterComponent(false)
			}, 2000)
		}
		fetchData()
	}, [])

	const handleDownloadExcel = async props => {
		try {
			Swal.fire({
				title: language.locale === 'ES' ? 'Esto tomara un tiempo...🚀 ' : 'Isto levará um tempo...🚀',
				didOpen: () => {
					Swal.showLoading()
				},
			})

			const response = await fetch(`${process.env.REACT_APP_API}/salarycompanybylevel/generateExcel?company_id=${props.companyIdFromSMTM}`)

			Swal.close()

			if (response.ok) {
				const blob = await response.blob()

				const url = URL.createObjectURL(blob)

				const a = document.createElement('a')
				a.href = url
				a.download = 'mydna_salary_report.xlsx'
				document.body.appendChild(a)
				a.click()

				document.body.removeChild(a)
				URL.revokeObjectURL(url)
			} else {
				console.error('Error al descargar el archivo:', response.status)
				Swal.fire({
					icon: 'error',
					title: 'Error al descargar el archivo',
				})
			}
		} catch (error) {
			console.error('Error en la solicitud:', error)
			Swal.fire({
				icon: 'error',
				title: 'Error en la solicitud',
			})
		}
	}

	const [report, setReport] = useState({
		country_id: '',
		city_id: '',
		industry_id: '',
		area_id: '',
		job_id: '',
		company_size_id: '',
		qualification_id: '',
		english_level_id: '',
		seniority_id: '',
		current_salary: '',
		variable_salary: '',
	})

	const setHandleReport = index => {
		setReport({
			...report,
			country_id: store.companyPositions[index].country_id,
			city_id: store.companyPositions[index].city_id,
			industry_id: store.profile_data_company[0].industry_id,
			area_id: store.companyPositions[index].area_id,
			job_id: store.companyPositions[index].job_id,
			company_size_id: store.profile_data_company[0].company_size_id,
			qualification_id: store.companyPositions[index].qualification_id,
			english_level_id: store.companyPositions[index].english_level_id,
			seniority_id: store.companyPositions[index].seniority_id,
			current_salary: store.companyPositions[index].current_salary,
			variable_salary: store.companyPositions[index].variable_salary,
			job_name: store.companyPositions[index].job_name,
			subArea: store.companyPositions[index].subArea,
		})
		// await generarReporte()
	}

	useEffect(() => {
		const generarReporte = async () => {
			if (report.job_id == null) {
				Swal.fire({
					title: 'Hola 👋',
					text: 'La posición se esta validando. Intente nuevamente mas tarde.',
					icon: 'info',
					confirmButtonText: 'Ok',
				})
			} else {
				Swal.fire({
					title: 'Procesando ⏳',
					text: 'Estamos procesando los datos, por favor espere.',
					icon: 'info',
					showCancelButton: false,
					showConfirmButton: false,
				})
				const response = await actions.createReport(report, store.userEmail)
				if (response === 200) {
					if (store.last_report_code) {
						var url = `https://smtm.co/v1/reports/api_recover?authorization=19c073e1768a3cbf6e9d76390e640f3b&email=${JSON.parse(localStorage.getItem('email'))}&code=${store.last_report_code}`
						const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
						if (newWindow) newWindow.opener = null
						Swal.fire({
							title: 'Reporte generado con exito! 🧑‍💻',
							text: 'En caso de que el reporte no se despliegue automaticamente, por favor, habilite las ventanas emergentes.',
							icon: 'success',
							showCancelButton: false,
							showConfirmButton: true,
						})
					} else {
						return
					}
				}
			}
		}

		if (report.city_id !== '') {
			generarReporte()
		}
	}, [report])

	const [dataUrlPhoto, setDataUrlPhoto] = useState([])

	useEffect(() => {
		const getDataPhotoUrl = async () => {
			const resp = await getAllPhotoUrls()

			let arrayUrlPhoto = []
			resp.forEach((el, i) => {
				let cadena = resp[i].alias
				let numero = cadena.split('_')[0]

				arrayUrlPhoto.push({
					id: i,
					employeeId: numero,
					urlPhoto: el.url,
					mydnaUserEmail: el.mydnaUserEmail,
				})
			})
			setDataUrlPhoto(arrayUrlPhoto)
		}
		getDataPhotoUrl()
	}, [])

	const [filterData, setFilterData] = useState([])

	const handleFilter = data => {
		setFilterData(data)
	}

	return (
		<>
			<div>
				{isLoading && <> {<Loading />} </>}

				<div className='row'>
					<div className='col-12 px-5'>
						<div className='d-flex justify-content-between me-3'>
							<div className='d-flex align-items-end px-5 ms-4'>
								<img className='custom-icon-guia-salarial me-3' src='https://assets.mydna.company/b2b-images/pb/img/icons/azulesnumericos/Iconos-07.svg' alt='Icono Beneficios MyDNA' />

								<h3 className='content-wrapper-title p-0 m-0 mb-1 '>{salaryTitle.titlePosiciones}</h3>
							</div>

							{
								<div>
									<button
										onClick={() => {
											handleDownloadExcel(props)
										}}
										className='btn-descargar-green rounded-1 me-1'
									>
										<FiDownloadCloud />
										<span className='px-2'>{salariesAndBenefitsTranslate.salaryDownloadList}</span>
									</button>
									<button
										onClick={() => {
											setShowFilterComponent(!showFilterComponent)
										}}
										className='btn-descargar-green rounded-1'
									>
										<FaFilter />
										<span className='px-2'>Filtros</span>
									</button>
								</div>
							}
						</div>
					</div>
				</div>

				{props.companyIdFromSMTM && (
					<>
						{showFilterComponent && (
							<>
								<div className='row'>
									<div className='div'>
										<FilterComponent handleFilter={handleFilter} companyIdFromSMTM={props.companyIdFromSMTM} />
									</div>
								</div>
							</>
						)}
					</>
				)}

				<div className='ms-5 mt-2 row'>
					<div className='d-flex flex-row'></div>
				</div>

				<div className='row mt-4'>
					<div className='col-12'>
						<TablePositionItem onReport={setHandleReport} companyPositions={filterData ? filterData : store.companyPositions} photos={dataUrlPhoto} />
					</div>
				</div>

				<div className='ms-5 w-100'>
					<div className='custom-table'></div>
				</div>
			</div>
		</>
	)
}

export default memo(TablaPosiciones)
