// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-university-card {
  display: flex;
  margin: 30px 20px;
}
.content-university-card img {
  width: 80px;
}
.content-university-card .info {
  margin-left: 15px;
  display: flex;
  flex-direction: column;
}
.content-university-card .info h6 {
  margin: 0;
  padding: 0;
  font-weight: bolder;
}
.content-university-card .info .curse {
  font-weight: 500;
}
.content-university-card .info .date {
  color: #a6a6a6;
}`, "",{"version":3,"sources":["webpack://./src/Views/Analytics/Components/UniversityCard/univesrityCard.sass"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,iBAAA;AACJ;AACI;EACI,WAAA;AACR;AACI;EACI,iBAAA;EACA,aAAA;EACA,sBAAA;AACR;AACQ;EACI,SAAA;EACA,UAAA;EACA,mBAAA;AACZ;AACQ;EACI,gBAAA;AACZ;AAAQ;EACI,cAAA;AAEZ","sourcesContent":[".content-university-card\n    display: flex\n    margin: 30px 20px\n\n    & img\n        width: 80px\n\n    & .info\n        margin-left: 15px\n        display: flex\n        flex-direction: column\n\n        & h6\n            margin: 0\n            padding: 0\n            font-weight: bolder\n\n        & .curse\n            font-weight: 500\n        & .date\n            color: #a6a6a6\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
