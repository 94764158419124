import { LOCALES } from '../locales'

export const spanish_messages = {
	[LOCALES.SPANISH]: {
		//loading
		loadingP1: 'car',
		loadingP2: 'gando',

		//Buttons
		saveButton: 'Guardar',
		closeButton: 'Cerrar',
		backButton: 'Volver',
		nextButton: 'Siguiente',
		createButton: 'Crear',
		accessButton: 'Acceder',
		performTest: 'REALIZAR TEST',
		showResult: 'VER RESULTADO',
		deleteButton: 'Eliminar',
		cancelButton: 'Cancelar',
		yesText: 'Si',
		noText: 'No',
		selectOption0: 'Seleccionar...',
		clickHere: 'Haz click aquí',

		//Modal
		confirmDelete: 'Confirmar la eliminación',
		successAlert: 'Éxito!',
		errorAlert: 'Ha ocurrido un error!',
		contactAlert: 'Nuestro equipo se pondra en contacto a la brevedad!',

		//FormattedMessages
		date: 'Fecha',
		country: 'Pais',
		city: 'Ciudad',
		industry: 'Industria',
		area: 'Area',
		role: 'Cargo',
		professionalResponsableTitle: 'Asignar Profesional Responsable',
		complaintDetail: 'Detalla tu denuncia',
		collaboratorCountryOption: 'País de la posición del colaborador',
		industrySelect: 'Seleccione una Industria',
		areaSelect: 'Seleccione una Área',
		positionLabel: 'Posición',
		positionSelect: 'Seleccione una Posición',
		optionSelect: 'Seleccione una Opción',
		englishLabel: 'Inglés',
		indifferentOption: 'Indiferente',
		smallOption: 'Pequeña',
		mediumOption: 'Mediana',
		largeOption: 'Grande',
		basicLevelOption: 'Básico',
		mediumLevelOption: 'Medio',
		advancedLevelOption: 'Avanzado',
		roleOption: 'Señority',
		roleJunior: 'Junior',
		rolePleno: 'Pleno',
		roleSenior: 'Senior',
		educationLabel: 'Educación',
		graduationOption: 'Graduación',
		salaryOption: 'Salario',
		salaryInput: 'Ingrese salario',
		bonusLabel: 'Bono',
		bonusInput: 'Bono Anual',
		clickHereLink: 'Haz clic para saber más',
		selectAllLabel: 'Seleccionar todo',
		invalidValue: 'Valor no válido',
		inProcess: 'Procesando',
		belowRange: 'Bajo la banda',
		firstQuartile: 'en el 1° Cuartil',
		secQuartile: 'en el 2° Cuartil',
		thirdQuartile: 'en el 3° Cuartil',
		fourthQuartile: 'en el 4° Cuartil',
		aboveRange: 'Sobre la banda',
		searchTranslate: 'Busquedá',

		//EmptyInfo
		incompleteTest: 'El colaborador todavía no ha rellenado su información',
		incompleteProfile: 'El colaborador todavía no ha completado su perfil',
		emptyDesempeno: 'Todavia no hay una evaluación disponíble.',

		//Login Screen
		textLogin: 'Iniciar sesión con su dirección de correo electrónico',
		createAccount: 'Crear nueva cuenta',
		login: 'Iniciar Sesión',
		errorUserPass: 'Nombre de Usuario o Contraseña incorrecta!',
		passPH: 'Contraseña',

		//Card Content
		customerRating: 'Evaluación de los Clientes',

		//Header
		mainMenuFeed: 'Mi Feed',
		mainMenuProfile: 'Mi Perfil',
		mainMenuMoment: 'Mi Momento',
		mainMenuVacancies: 'Mis Vacantes',
		mainMenuCompany: 'Para Empresas',
		mainMenuSearch: 'Buscar personas y empresas',
		mainMenuSeeMore: 'Ver más',
		dropdownCompany: 'Mi Compañia',
		dropdownLogout: 'Salir',
		dropdownProfile: 'Editar Perfil',
		searchByName: 'Buscar por Nombre',

		// Body Card Text
		textServices: 'Servicios de ',
		textCertified: ' certificados por MyDNA',
		ourProducts: 'Nuestros Productos:',
		customerEvaluation: 'Evaluación de los Clientes',
		myPartnes: 'Partnes',

		/** WheelCompany & Menus */
		seeMore: 'Ver más',
		myBusiness: 'Mi Empresa',
		peopleAnalytics: 'People Analytics',
		myCollaborators: 'Mis Colaboradores',
		collaboratorsRegister: 'Registro de Colaborador',
		massiveRegister: 'Registro Masivo',
		mySolutions: 'Mis Soluciones',

		onBoarding: 'Onboarding',
		knowBadgeHeroes: 'Conoce Badge Heroes',
		onBoardingRegister: 'Registrar Onboarding',

		trainingAndDevelopment: 'Entrenamiento & Desarrollo',
		manageTrainings: 'Gestionar Capacitaciones',
		workoutsMydna: 'Entrenamientos by MyDNA',
		ebooksAndContent: 'Ebooks & Contenidos',

		climeAndCulture: 'Clima & Cultura',
		cultureSurvey: 'Encuesta de Cultura',
		cultureSurveyText: 'La evaluación de cultura entrega tres informaciones fundamentales: cuál es tu cultura, cuál es la de la empresa y por ende, cuál es el match entre ambos. Haz click en el botón y selecciona las mejores respuestas!',
		climeSurvey: 'Encuesta de Clima',
		climeSurveyText:
			'Haz escuchar tu voz! Aquí podrás transmitir a tu empresa cuál es tu percepción acerca del clima que se respira en la organización. Será entretenido, gratificante y te llevará pocos minutos, además de generar mucho valor para todos - tu en primer lugar. Haz click en el botón y deja tu opinión!',
		nps: 'NPS',
		businessCulture: 'Cultura Empresa',

		performanceAnalysis: 'Desempeño y Potencial',
		performanceEvaluation: 'Evaluación de Desempeño',

		salariesAndBenefits: 'Salario & Beneficios',
		unitReport: 'Reporte Unitario',
		goPaybalance: 'ir a Paybalance',
		knowPaybalance: 'Conoce Paybalance',

		professionalDevelopment: 'Assessment Center',
		skillTest: 'Test de Competencias',
		knowMyco: 'Conoce Myco',
		motivacionalTest: 'Test de Motivaciones',
		englishLevelTest: 'Test de Nivel de Inglés',
		englishClass: 'Clases de Inglés',

		careerPlan: 'Plan de Carrera',
		careerProjection: 'Proyección de Carrera',
		jobDescriptor: 'Descriptores de Cargo',
		jobDescriptorNullTitle: '¡Cargo sin descriptor!',
		jobDescriptorNullText: 'El puesto no tiene un descriptor de trabajo.',
		jobDescriptorNullText2: 'Asegúrese de que el nivel jerárquico y la subárea sean correctos o registre manualmente un descriptor para ese puesto',
		jobDescriptorBack: 'Volver para Mis Colaboradores',
		EEjobDescriptorNullTitle: '¡Cargo sin descriptor!',
		EEjobDescriptorNullText: 'No encontramos un descriptor para la combinación informada.',
		EEjobDescriptorNullText2: 'Asegúrese de que el nivel jerárquico y la subárea sean correctos o cree manualmente un descriptor para ese cargo',
		EEjobDescriptorNullRegister: 'Registrar sin descriptor',
		EEjobDescriptorBack: 'Volver para Informaciones del Profesional',
		EEjobDescriptorBack: 'Volver para Informaciones del Profesional',

		ombudsman: 'Ombudsman',

		outplacement: 'Plan de Salida',
		knowGenes: 'Conoce MyDNA Outplacement',
		realocation: 'Solicitud Outplacement',
		leaveSearch: 'Encuesta Salida',
		posicionesTitle: 'Salarios por Colaborador',
		bands: 'Banda Salarial',
		benefits: 'Beneficios',
		emotionalSalary: 'Salario Emocional',

		salaryProjection: 'Proyección Salarial',

		apresentationDown: 'Descargar Presentación',
		activeService: 'Activar Servicio',

		documentsTitle: 'Documentos',

		//WheelCollaborator
		professionalHistoric: 'Histórico Profesional',
		purpose: 'Propósito',
		formation: 'Formación',
		culture: 'Cultura',
		salary: 'Salario',
		habilities: 'Competencias',
		motivacions: 'Motivaciones',
		performance: 'Desempeño',
		training: 'Entrenamiento',
		aData: 'a la fecha',
		functionsTitle: 'Funciones',
		achievementsTitle: 'Logros y Conquistas',
		currentJobTitle: 'Trabajo actual',
		functionsDescriptions: 'Describe aquí tus funciones',
		achievementsDescriptions: 'Describe aquí tus logros',
		editProfile: 'Editar Perfil',

		//Professional Historic
		collaboratorTrajectory: 'Trayectória del Colaborador',
		experienceTime: 'Tiempo de Experiencia',
		yearsAnd: 'Años y',
		months: 'Meses',
		workedIndustries: 'Industrias en que ha Trabajado',
		workedPositions: 'Cargos Ocupados',

		//Proposito
		purposeToWork: 'Los Propósitos laborales son',

		//Formation
		education: 'Educación',
		englishLevel: 'Nivel de Inglés',

		//English Line Level
		//English Line Level
		englishNulo: 'Nulo',
		englishBasic: 'Básico',
		englishIntermedio: 'Intermedio',
		englishAvanzado: 'Avanzado',
		englishFlued: 'Fluido',

		//Culture
		commercial: 'Comercial',
		commercialText:
			'Se alinea mejor en empresas con las siguientes características : Orientación a lograr resultados. Ambiente motiva en parte a colaboradores a cumplir metas. Estilo de liderazgo motiva, pero se puede poner mas agresividad comercial. Existe competitividad en el equipo, pero se puede mejorar. Se establece relaciones con clientes pero no siempre a largo plazo. Se observa en el equipo una mayor satisfacción por ganar y captar mercado. Existen desafíos por crecer pero se puede ser más ambicioso.',
		disruptive: 'Disruptiva',
		disruptiveText:
			'Se alinea mejor en empresas con las siguientes características : Estilo de organización más innovadora, se escucha y se trabaja más en equipo. Se planifica más a largo plazo. El liderazgo es más motivador, de tomar riesgos, más emprendedor. Los equipos comienzan a ser más visionarios y a tener más hambre de salir de la caja. Se tiene mayor tolerancia a la diversidad y diferencias entre equipos. La expresión libertad de hacer comienza a escucharse más.',
		collaborative: 'Colaborativa',
		collaborativeText:
			'Se alinea mejor en empresas con las siguientes características : Estilo de organización levemente estructurado. Se cuenta con una estructura piramidal, donde las decisiones son tomadas por pocos. La comunicación de las decisiones o estrategia es lenta, conocida por pocos y generalmente jefaturas. Se dispone de algunos protocolos y procedimientos. Se busca estabilidad y permanencia en el tiempo. La eficiencia es un pilar para la toma de decisiones',
		traditional: 'Tradicional',
		traditionalText:
			'Se alinea mejor en empresas con las siguientes características : Estilo de organización con foco en las personas. Preocupados de generar un ambiente de trabajo grato y cercano. El desarrollo profesional es parte de los desafíos permanentes. Liderazgo incentivando el trabajo en equipo. Existe alta comunicación entre jefaturas y colaboradores. La estrategia es conocida por todos. Equipos alineados y comprometidos con la empresa.',
		culturePreview: 'Tiene el siguiente perfil cultural',
		cultureNoAnswer: 'El colaborador seleccionado todavía no ha contestado la encuesta de cultura',

		//Salario
		actualSalary: 'Salario Actual',
		fixedSalary: 'Fijo Mensual',
		variableAnualSalary: 'Variable Anual',
		titlePosiciones: 'Salarios por Colaborador',
		monthSalary: 'Salario Mensual',
		annualSalary: 'Salario Anual',

		//Career Plan
		hierarchicalLevel: 'Nivel Jerárquico',
		nextLevel: 'Siguiente Nivel (recomendado)',
		actualLevel: 'Nivel Actual -',
		possibleAreas: 'Posibilidades de Cambios de Areas',
		collaboratorTextP1: 'Colaboradores con experiencia en el area de',
		collaboratorTextP2: 'pueden poseer conocimiento e habilidades para trabajar en el area de',
		director: 'Director',
		manager: 'Gerente',
		submanager: 'Subgerente',
		boss: 'Jefe',
		specialist: 'Especialista',
		analist: 'Analista',
		technical: 'Operário / Técnico / Asist.',

		//Onboarding
		collStatus: 'En Progreso',
		assignStatus1: 'Se ha/n asigando',
		assignStatus2: 'test',
		collEmpty: 'No hay ningun colaborador registrado, registra tus colaboradores para empezar',
		responsable: 'Responsable',
		registerResponsable: 'REGISTRAR NUEVO RESPONSABLE',
		registerCollaborator: 'REGISTRAR NUEVO COLABORADOR',

		onboardingAlertAnswer: 'No hay respuestas',

		statusOnboardingQuestion1: 'Segue en Onboarding?',
		statusOnboardingQuestion2: 'Qué le parece el proceso?',
		statusOnboardingQuestion3: 'Cuántos pasos se han dado?',
		statusOnboardingQuestion4: 'Está activo en el programa?',
		statusOnboardingQuestion5: 'Cuánto tiempo dura el Onboarding?',
		statusOnboardingQuestion6: 'Cree que el programa Onboarding está ayudando?',
		statusOnboardingQuestion7: 'Progreso general del programa',

		statusOnboardingAnswerYes: 'Sí',
		statusOnboardingAnswerNo: 'No',

		//Capacitations
		capacitationTitle: 'Entrenamientos',
		capacitationCol: 'Capacitaciones',
		capacitationAreaCol: 'Área',
		capacitationProgress: 'Progresso',
		capacitationsTitle: 'Capacitaciones',
		capactitationSearchPlaceHolder: 'Buscar curso por nombre e palabra clave',
		capacitationChooseArea: 'Elija el área de la capacitación',
		newCourse: 'Crear Nuevo Curso',
		capacitationCourse: 'Cursos',
		capacitationArea: 'Area',
		capacitationEmpty: 'No hay capacitación registrada',

		//Trainnings
		trainningTitle: 'Entrenamientos by Mydna',
		//Trainning List
		//English
		trainningCardTitleEnglish: 'Clases Inglés',
		trainningCardTextEnglish: 'Aprendizaje de Inglés para tus colaboradores.',
		//Comercial & Selling
		trainningCardTitleComercialSelling: 'Entrenamiento Pitch Comercial & Ventas',
		trainningCardTextComercialSelling: 'Entrenamiento de Pith Comercial para tus colaboradores.',
		//Gamefied Trainning
		trainningCardTitleGamefied: 'Herramienta de Entrenamiento Gamificado',
		trainningCardTextGamefied: 'Construye tu propia capacitación personalizada. Entregamos una experiencia totalmente gamificada a sus colaboradores.',

		//Empy Content Book
		empyContentText: 'Todavia no hay contenidos para mostrar',

		//DashBoard Clime
		dashboardClimeTitle: 'DashBoard - Encuesta de Clima',
		dashboardClimeText:
			'La evaluación del clima organizacional es como tomar el pulso emocional de una empresa: revela la temperatura emocional y psicológica del entorno laboral. A través de la encuesta se recopilan datos para comprender las percepciones y experiencias de los empleados. Este proceso proporciona información valiosa que permite a los líderes identificar áreas de mejora y promover un ambiente laboral saludable y productivo. Es una herramienta fundamental para cultivar la satisfacción, el compromiso y el bienestar de los empleados',
		emptyInformationClime: 'Aun no se han contestado encuestas de clima',
		textInformationBusinessCulture: 'Tener candidatos alineados a la cultura de tu empresa mejora la felicidad del colaborador, disminuyendo la rotación.',
		textInformationBusinessCulture2: ' Responde las preguntas de cultura, carrera, liderazgo e intereses y conocerás cuál es la cultura empresarial de tu empresa.',

		//BusinessCultureQuestions
		//Cultural
		businessCulturalQ1: 'Somos reconocidos por la forma en que reciclamos, cuidamos el medioambiente y hacemos uso inteligente de los recursos',
		businessCulturalQ2: 'Nos vestimos y expresamos generalmente de manera formal',
		businessCulturalQ3: 'Tenemos 50% hombres y 50% mujeres en posiciones de primera línea de liderazgo',
		businessCulturalQ4: 'Tenemos Directores y/o altos ejecutivos abiertamente homosexuales',
		businessCulturalQ5: 'Somos reconocidos como una organización altamente innovadora',
		businessCulturalQ6: 'Tenemos telefonos y mails para que los empleados puedan hacer denuncias de manera confidencial',

		//Career
		businessCareerQ1: 'Para ser exitoso aquí, tienes que ser competitivo',
		businessCareerQ2: 'Buscamos personas que valoren más su desarrollo profesional que tener una recompensa económica alta',
		businessCareerQ3: 'Con un alto desempeño, las personas van asumiendo posiciones de poder rápidamente (cada 2 años una promoción)',
		businessCareerQ4: 'Para ser exitoso aquí, debes ser políticamente correcto en la forma de plantear tus puntos de vista',
		businessCareerQ5: 'El uso de tatuajes, aros o tu apariencia física puede hacerte un poco más difícil llegar a cargos de alto nivel',
		businessCareerQ6: 'Tener conversaciones de desarrollo de carrera con los empleados no es prioridad en este momento',

		//Leader
		businessLeaderQ1: 'Nuestros cargos directivos o gerenciales tienen estacionamientos privilegiados o especiales',
		businessLeaderQ2: 'Si estamos bajo presión, la comunicación en ocasiones puede tornarse más dura',
		businessLeaderQ3: 'Nuestro Gerente General y Directorio no es reconocido por ser cercano a las personas',
		businessLeaderQ4: 'El liderazgo es el tema más importante en nuestra estrategia corporativa',
		businessLeaderQ5: 'Nuestros equipos son tan apasionados que a veces trabajan más horas de lo habitual',
		businessLeaderQ6: 'Es parte de nuestra cultura que los jefes pidan feedback a sus equipos sobre cómo mejorar su liderazgo y gestión',

		//Interests
		businessInterestsQ1: 'Los sindicatos son un actor clave para escuchar a los colaboradores',
		businessInterestsQ2: 'Damos flex time (elegir el horario de entrada y salida)',
		businessInterestsQ3: 'Damos viernes corto (salir más temprano los viernes)',
		businessInterestsQ4: 'Damos días libres por sobre lo legal si nuestros empleados se casan o contraen un Acuerdo de Union Civil',
		businessInterestsQ5: 'Somos reconocidos por dar mejores beneficios que los del mercado para mujeres en pre y post natal',
		businessInterestsQ6: 'Apoyamos a las comunidades deportivas de los colaboradores',

		//Promoter Score
		netPromoteScoreTitle: 'Net Promote Score',
		detractorTitle: 'Detratores',
		detractorText:
			'Los que no están satisfechos. Cualquiera que probablemente tenga algo de qué quejarse. Además, incluso suelen hablar mal de la empresa a sus contactos. Sin mencionar que no son fieles, en cualquier momento definitivamente pueden abandonar la empresa.',
		neutralTitle: 'Neutros',
		neutralText:
			'No pueden considerarse insatisfechos, pero no hay garantía de que sean leales. Probablemente tengan algún punto que mejorar, pero eso no quiere decir que quieran o piensen en abandonar la empresa. Al mismo tiempo, no son perfectamente leales, lo que puede convertirlos en detractores, si algo sucede o se dan cuenta de que pueden obtener algo mejor en otro lugar.',
		promotorTitle: 'Promotores',
		promotorText: ' Son aquellos más fieles, con tendencia a recomendar la empresa. Aunque el grado 9 indica que algo puede mejorar, no compromete la lealtad.',

		//Culture Business
		businessCultureTitle: 'Cultura Empresa',
		asksTimelineText: 'Marca lo que quieres en tu vida laboral y lo que piensas sobre tu empresa última/actua',
		//Timeline
		cbTimelineStart: 'Inicio',
		cbTimeLineQuestions: 'Preguntas de Cultura',
		cbTimeLineCareer: 'Preguntas de Carrera',
		cbTimeLineLeader: 'Preguntas de Liderazgo',
		cbTimeLineInterests: 'Preguntas de Intereses',
		cbTimeLineReportDelivery: 'Entrega del Resultado',

		//TimeLine Finished
		cbTimeLineAswer: 'Tu Cultura Empresarial es',
		cbTimeLineComercial: 'Comercial',
		cbTimeLineComercialDesc:
			'Orientación a lograr resultados. Ambiente motiva en parte a colaboradores a cumplir metas. Se puede mejorar. Estilo de liderazgo motiva, pero se puede poner mas agresividad comercial. Existe competitividad en el equipo, pero se puede mejorar. Se establece relaciones con clientes pero no siempre a largo plazo. Se observa en el equipo una mayor satisfacción por ganar y captar mercado. Existen desafíos por crecer pero se puede ser más ambicioso.',
		cbTimeLineDisruptive: 'Distuptiva',
		cbTimeLineDisruptiveDec:
			"Estilo de organización más innovadora, se escucha y se trabaja más en equipo. Se planifica más a largo plazo. El liderazgo es más motivador, de tomar riesgos, más emprendedor. Los equipos comienzan a ser más visionarios y a tener más hambre de salir de la caja. Se tiene mayor tolerancia a la diversidad y diferencias entre equipos. La expresión 'libertad de hacer' comienza a escucharse más.",
		cbTimeLineColaborate: 'Colaborativa',
		cbTimeLineColaborateDesc:
			'Estilo de organización con foco en las personas. Preocupados de generar un ambiente de trabajo grato y cercano. El desarrollo profesional es parte de los desafíos permanentes. Liderazgo incentivando el trabajo en equipo. Existe alta comunicación entre jefaturas y colaboradores. La estrategia es conocida por todos. Equipos alineados y comprometidos con la empresa.',
		cbTimeLineTraditional: 'Tradicional',
		cbTimeLineTraditionalDesc:
			'Estilo de organización levemente estructurado. Se cuenta con una estructura piramidal, donde las decisiones son tomadas por pocos. La comunicación de las decisiones o estrategia es lenta, conocida por pocos y generalmente jefaturas. Se dispone de algunos protocolos y procedimientos. Se busca estabilidad y permanencia en el tiempo. La eficiencia es un pilar para la toma de decisiones.',

		//EmployerPerformance
		employerPerformanceTitle: 'Evaluación de Desempeño',
		employersShowCollumTitle: 'Colaboradores',
		employerCriterionCollumTitle: 'Criterio',
		employerWeigthCollumTitle: 'Peso',
		employerExecutedCollumTitle: '% Logrado',
		employeeSugests: 'Sugerencias',
		employeeObservations: 'Observaciones',

		//ShowMeTheMoney
		salaryConsultTitle: 'Consultoria Salarial Personalizada',
		salaryConsultText: 'Completa la siguiente información y te entregaremos un reporte hecho a la medida de los salarios de tu colaboradores.',
		salaryConsultErrorEmptyFields: 'ERROR! Asegúrese de que todos los campos estén llenos.',
		salaryConsultErrorIncompatibleSalary: 'ERROR! Salario incompatible con el puesto.',
		salaryConsultSuccessful: 'Reporte enviado, revisa tu email!',
		salaryConsultResponsableName: 'Nombre del Responsable que Recibirá el Reporte Salarial',
		salaryConultResponsableEmail: 'Mail del Responsable',
		salaryConsultEducationLevel: 'Nivel del Estudio',
		salaryConsultExperienceYears: 'Años de Experiencia en la Posición',
		salaryConsultExperienceThreeLess: 'Menos de 3 años',
		salaryConsultExperienceThreeOrSeven: 'Entre 3 y 7 años',
		salaryConsultExperienceSevenOrMore: 'Más de 7 años',
		salaryConsultEnglishLevel: 'Nivel del Inglés',
		salaryConsultEnglishAloof: 'Indiferente',
		salaryConsultEnglishBasic: 'Básico',
		salaryConsultEnglishMid: 'Intermédio',
		salaryConsultEnglishHigh: 'Avanzado',
		salaryConsultGrossMonthly: 'Salario Bruto Mensual',
		salaryConsultAnnualBonus: 'Bonus Anuales',
		salaryConsultButtonSend: 'Enviar informe al correo',

		//Jobs Description
		jobsDescriptionTitle: 'Descripción de Cargo',
		jobsDescriptionShowMore: 'Ver descripción',
		jobsDescriptionReturn: 'Volver a la  lista de cargos',
		jobsDescriptionTitleJob: 'Qué hace un ',
		jobsDescriptionActivies: 'Cuáles son sus principales actividades',
		jobsDescriptionFormation: 'Perfil Técnico / Experiencia / Formación Académica',
		jobsDescriptionPersonal: 'Perfil Personal',
		jobsDescriptionRelacion: 'Nivel de Relación',
		jobsDescriptionLanguage: 'Idioma',
		jobsDescriptionStudy: 'Estudio',

		//CareerPlan Section
		careerPlanText: 'Quiero un profesional apto a ocupar el cargo de:',
		careerPlanPHRole: 'ej: Jefe',
		careerPlanPHArea: 'ej: Comercial',
		careerPlanBtnSearch: 'Buscar',
		careerPlanBtnClean: 'Limpiar',
		careerPlanPHNameColaborator: 'Nombre del Colaborador',
		careerPlanNextLevel: 'Proximo Nivel',
		careerPlanSameAreas: 'Áreas Afines',

		//SearchDashBoard
		leaveSearchTitle: 'Encuesta de Salida',
		leaveSearchNotFound: 'La encuesta de Salida todavía no ha sido respondida por ningun colaborador',

		//AddEmployer
		assignCollaborator: 'Agregar Colaboradores',
		collaboratorTitle: 'Colaborador',
		nullEmployersRegister: 'No hay empleados registrados',
		successMessageAddEmployer: 'Colaborador agregado com éxito!',
		newCollaborator: 'Agregar nuevo',
		showListCollaborators: 'Ver lista',

		//NewClass
		createNewClass: 'Crear Nuevo Curso',
		className: 'Nombre del Curso',
		classLink: 'Link del Curso',
		courseDescription: 'Descripción',

		//Denuncia
		complaintSecurityText: 'Usted está en un espacio seguro',
		complaintSecuryTextBody: 'Este es un canal totalmente confidencial entre usted y MyDNA. Intentaremos ayudarle sin revelar sus datos.',
		btnComplaint: 'Crear Denuncia',
		complaintTypes: 'Tipos de Asedio',
		complaintGenerate: 'Generar proceso para enviar denuncia',
		complaintOptionA: 'Discriminación laboral del trabajador.',
		complaintOptionB: 'Culpabilizar al trabajador.',
		complaintOptionC: 'Aislamiento laboral.',
		complaintOptionD: 'Faltas de respeto en el trabajo e insultos.',
		complaintOptionE: 'Respecto a las tareas asignadas.',
		complaintOptionF: 'Difamación profesional y/o personal.',
		complaintOptionG: 'Sobrecarga de trabajo.',
		complaintOptionH: 'Acoso sexual',
		complaintOptionI: 'Rebajar las funciones del trabajador.',
		complaintOptionJ: 'Burnout',
		complaintOptionK: 'Maltrato laboral.',
		complaintOptionL: 'Otro (Especifique)',

		// CardSection Title
		collaborator360: 'Colaborador 360°',
		dashboardCompany: 'Dashboard Empresa',
		executiveSearch: 'Executive Search',
		middleTopManagement: 'Middle & Top Management',
		backoffandStaff: 'Backoff & Staff',
		plataformOnboardinGamified: 'Plataforma de Onboarding Gamificado',
		englishClass: 'Clases de Inglés',
		commercialTraining: 'Entrenamiento Pitch Comercial & Ventas',
		gamefiedTraining: 'Herramienta de Entrenamiento Gamificado',
		cultureReport: 'Reporte de Cultura',
		culturalFitEmp: 'Fit Cultural Candidados',
		culturalFitCol: 'Fit Cultural Colaboradores',
		culturalTransformation: 'Transformación Cultural',
		realTimeConsult: 'Consulta en Tiempo Real',
		customStudy: 'Estudio Personalizado',
		variableSalary: 'Estudio Remuneración Variable',
		salaryGuide: 'Guía Salarial',
		topManagement: 'Top Management',
		middleManagement: 'Middle Managment',
		backofficeOutput: 'Backoffice & Staff (Salida)',
		searchOutput: 'Encuesta de Salida',

		//

		//CardSection Text
		textCollaborator360: 'El People Analiytcs más completo con indicadores clave para la gestión de los talentos de tu organización.',
		textDashboardCompany: 'KPIS claves para la gestión de talentos de tu organización. Conoce dónde la empresa vá bien y dónde hay trabajo para mejorar con KPIS claros y bien definidos.',
		textExecutiveSearch:
			'Headhunting especializado en alta y mediana gerencia con presencia regional. Asegura la contratacción de profesionales que se ajustan al perfil de su empresa, logrando un mayor nivel de compromiso y un menor nivel de rotación.',
		textMiddleTopManagement: 'Reclutamiento para cargos intermedios en la pirámide de forma rápida y precisa. Se buscan profesionales que se ajustan al perfil de su empresa, asegurando un mayor nivel de compromiso y un menor nivel de rotación.',
		textBackoffandStaff: 'Reclutamiento para cargos operativos y técnicos de forma rápida y precisa. Se buscan profesionales que se ajustan al perfil de su empresa asegurando un mayor nivel de compromiso y un menor nivel de rotación.',
		textPlataformOnboardinGamified: 'Mejora el compromiso y acelera el desarrollo de tus trabajadores con un software de gamificación que genera interacción, educación, cultura y trabajo en equipo.',
		textEnglishClass: 'Aprendizaje de Inglés para tus colaboradores.',
		textCommercialTraining: 'Entrenamiento de Pith Comercial para tus colaboradores.',
		textGamefiedTraining: 'Construye tu propia capacitación personalizada. Entregamos una experiencia totalmente gamificada a sus colaboradores con contenidos de capacitación seleccionados por ti.',
		textCultureReport: 'Conoce la cultura de tu organización y compara la visión de la empresa versus la de sus colaboradores.',
		textCulturalFitEmp: 'Conoce el % de fit que tiene la organización con sus colaboradores en relación a su propósito y cultura.',
		textCulturalFitCol: 'Conoce el % de fit que tiene la organización con sus candidatos en relación a su propósito y cultura.',
		textCulturalTransformation: '¿Dónde estás hoy y dónde quieres llegar? En caso de que la cultura actual de tu empresa no refleja lo que te gustaria, te ayudamos.',
		textPerformanceAnalysis: 'Evalua el desempeño de sus colaboradores, conoce los gaps y fortalezas de sus equipos .Define la mejor estrategia de gestión de talentos con datos claros y cuantitativos.',
		textRealTimeConsult: 'Accede a la base de datos salariales de Mydna con información actualizada de mercado para definir la mejor estrategia para tu organización. Consulta por tipo de empresa, cargo, experiencia y  competencias.',
		textCustomStudio: 'Para aquellos cargos que todavia no encuentras información en nuestra plataforma en el área de "consulta en tiempo real", te apoyamos en levantar información de forma ágil y asesorada por nuestro equipos de especlialistas.',
		textVariableSalary: 'Define la mejor estrategia de remuneración variable para tu organización junto a una evaluación de desempeño.',
		textSalaryGuide: 'Descarga la última Guia Salarial desarrollada por PayBalance/Mydna',
		textMotivacionalTest: 'Test psicolaboral de motivaciones profesionales para conocer lo que realmente motiva a tus colaboradores y/o candidatos.',
		textSkillTest: 'Test psicolaboral de habilidades profesionales para conocer las habilidades blandas que más de destacan a tus colaboradores y/o candidatos.',
		textEnglishLevel: 'Evalua el nivel de inglés de tus colaboradores con el apoyo de Nulinga. Un test de 20 minutos para conocer su dominio del idioma.',
		textCareerProjection: 'Define cuál es el próximo cargo de los profesionales de acuerdo a sus habilidades, competencias, motivaciones y aspiraciones.',
		textSalaryProjection: 'Conoce cuánto pagar en el caso de ascender a un profesional aplicando las mejores estrategias de desarrollo profesional y retención.',
		textJobDescriptor: 'Conoce las comptencias necesarias y responsabilidades de cada cargo.',
		textTopManagement: 'Asesoramiento para la recolocación de un profesional especializado en alta y mediana gerencia.',
		textMiddleManagement: 'Acceso a la plataforma de outplacement digital especializada en los cargos intermedios de la pirámide para una recolocación rápida en el mercado laboral.',
		textBackofficeOutput: 'Acceso a la plataforma de outplacement digital especializada en cargos operarios y técnicos para una recolocación rápida en el mercado de trabajo.',
		textSearchOutput: 'Conoce la experiencia de sus ex colaboradores en el momento de su salida',
		textGenesCard: 'Asesoramiento para la recolocación de un profesional',

		// PeopleAnalytics Session
		//MyCollaborators
		myCollaboratorsTitle: 'Mis Colaboradores',
		searchCollaborator: 'Buscar un Colaborador',
		notDoneCultureSearch: 'Seleccione el colaborador deseado para ver sus resultados y compararlos con la empresa',
		noCollaboratorsRegistered: 'Todavia no hay colaboradores registrados',

		//CollaboratoRegister
		collaboratorName: 'Nombre',
		collaboratorEmail: 'E-mail',
		loadCollaborator: 'Cargar Colaborador',
		collaboratorStatusTitle: 'Atención!',
		collaboratorStatusText: 'Por favor complete al menos toda la información requerida',
		collaboratorStatusSuccess: 'Colaborador agregado con éxito!',
		collaboratorStatusError: 'Ha ocurrido un error!',
		//MassiveRegister
		collBodyText: 'Descarga el modelo de planilla excel, complétala con la información de tus colaboradores y luego cargala en la base.',
		collDownloadSheet: 'Descargar Planilla Modelo',
		collUpSheet: 'Click para elegir Archivo',
		collArchive: 'Archivo',

		//BadgeHeroes
		badgeHeroesTitle: 'Badge Heroes',
		badgeHeroesTextCard: 'Mejora el compromiso y acelera el desarrollo de tus trabajadores con un software de gamificación que genera interacción, educación, cultura y trabajo en equipo.',

		//Myco
		mycoTitle: 'Testes de Competencias y Motivaciones profesionales',
		mycoTextCard: 'Test psicolaboral de Compentencias y motivaciones profesionales para conocer las habilidades blandas y lo que realmente motiva a tus colaboradores.',

		//ProgressBar
		progressText: 'Concluido',
		progressMyProfile: 'Mi Perfil',
		progressProfileText: 'Seleccione la categoria que le gustaria comenzar',

		//Personal Info
		editProfileImg: 'Editar foto de Perfil',
		professionalInformation: 'Información Profesional',
		personalCompanyCol: 'Empresa',
		personalIndustryCol: 'Industria',
		personalAreaCol: 'Area',
		personalEntryDate: 'Fecha de Ingreso',
		personalExitDate: 'Fecha de Término',
		personalHastaFecha: 'Hasta la fecha',
		//Education
		personalEducation: 'Educación',
		personalEnglishLevel: 'Nivel de Inglés:',
		personalELNulo: 'Nulo',
		personalELBasic: 'Básico',
		personalELAverage: 'Intermedio',
		personalELAdvanced: 'Avanzado',
		personalELFluent: 'Fluído',
		personalUniversity: 'Universidad',
		personalType: 'Tipo',
		personalClass: 'Curso',
		personalAddInfo: 'Agregar formación',
		//Salary
		personalSalary: 'Salario',
		personalFixBruteSalary: 'Salario Fijo Actual:',
		personalCoin: 'Moneda:',
		personalVariableSalary: 'Salario Variable Anual:',

		//Purpose
		myPurposes: 'Mis Propositos',
		yourPurpose: 'Su Proposito',
		myPurposeText: 'Comparte tus objetivos personales y profesionales, lo que te motiva a ser una persona y profesional cada día mejor. Por medio de asesoría digital, te ayudaremos a hacer de tus objetivos, realidad.',

		//Culture
		myCultureProfile: 'Mi Perfil Cultural',
		myCultureText: 'Conoce tu perfil cultural y las empresas en las que encajas y estarás contento, según tus preferencias profesionales.',

		//Competencias
		competenciaTitle: 'Assessment Competencias:',
		abilitiesCollaboratorTitleText: 'Quieres conocer tus competencias? Puedes hacerlo ya.',
		abilitiesCollaboratorTextP1: 'Nuestro test evalúa 11 competencias de tu personalidad, como',
		abilitiesCollaboratorTextP2: 'Visión estratégica, Liderazgo, Orientación al cliente, Trabajo en equipo,',
		abilitiesCollaboratorTextP3: ' entre otros.',
		abilitiesCollaboratorTextEmail: 'Te llegará un correo con el acceso al test online',
		abilitiesCollaboratorDoubtsText: 'Accede nuevamente aquí para ver los resultados (pueden demorar hasta 24hrs, luego de haber completado el test)',

		//Motivacions
		motivationTitle: 'Assessment Motivaciones:',
		motivacionCollaboratorTitle: 'Qué es lo que te motiva? Descúbrelo ya!',
		motivacionalCollaboratorTextP1: 'Nuestro test evalúa 17 factores de tu ámbito profesional que te motivan.',
		motivacionalCollaboratorTextP2: 'Conocer claramente qué te mueve es fundamental para guiar tu carrera y tu vida.',
		motivacionalCollaboratorTextP3: ' Haz el test ya!',

		//Performance
		performanceTitle: 'Desempeño',
		perfomanceFinalResult: 'Resultado Final:',

		//Trainings
		ebooks: 'E-books & Contenidos',
		myTrainings: 'Mis Entrenamientos',
		createNewTraining: 'Crear nueva Capacitacion',
		englishText: 'Test de Inglés',
		nulingaText: 'La plataforma online de aprendizaje de idiomas pensada para el mundo corporativo.',
		enterTrainingLink: 'ACCEDER AL CURSO',

		//Employee Profile Edit
		profileError: 'Campo requerido',

		//GoToPaybalance
		paybalanceTitle: 'Ir a Paybalance',
		paybalanceDescription1: 'Hola,',
		paybalanceDescription2: 'Gracias por la confianza en MyDNA!',
		paybalanceDescription3: 'Acá tendrás acceso a PayBalance by MyDNA, Plataforma de Información Salarial en Tiempo Real. Para eso debes hacer click en el link Abajo:',
		paybalanceDescription4: 'Y entrar con tu email y clave, el mismo de MyDNA, para que puedar usar todos los Recursos de Paybalance.',
		paybalanceDescription5: 'Ambos equipos de MyDNA y Paybalance iran apoyarte en esta jornada, puedes entrar en contacto con ellos en caso de dudas:',
		paybalanceTxt: 'Equipo',
		paybalanceBtn: 'IR A PAYBALANCE',

		//KnowPaybalance
		KnowPaybalanceTitle: 'Conoce Paybalance',
		KnowPaybalanceTextCard: 'Accede a PayBalance by Mydna con información actualizada de mercado para definir la mejor estrategia para tu organización. Consulta por tipo de empresa, cargo, experiencia y competencias.',

		//Outplacement
		outplacementAlertAnswer: 'No hay respuestas',
		outplacementQuestionOne: 'Ya encontró trabajo?',
		outplacementQuestionTwo: 'Cómo se siente en relación al proceso?',
		outplacementQuestionThree: 'Cantidad de postulaciones:',
		outplacementQuestionFour: 'Está participando de entrevistas?',
		outplacementQuestionFive: 'En cuanto tiempo cree encontrar nuevo trabajo?',
		outplacementQuestionSix: 'Cree que el programa está ayudando en el proceso?',
		outplacementQuestionSixPos: 'Cree que el programa ha ayudado al proceso?',
		outplacementQuestionSeven: 'Avance en el programa Genes:',
		outplacementAnswerYes: 'Sí',
		outplacementAnswerNo: 'No',
		outplacementAnswerOne: 'entrevista(s) en el momento',
		outplacementAnswerTwo: 'No está realizando estrevistas en el momento',
		outplacementAnswerThree: 'En',

		outplacementAnswerFour: 'mes(es)',
		outplacementOservation: 'Observación',

		//Ombudsman
		occurrences: 'Ocurrencias',
		noOccurrences: 'Todavia no hay ocurrencias',
		ombudmanTextOne: 'Esta información es confidencial y está restringida a las personas autorizadas dentro de la compañía.',
		ombudmanTextTwo: 'Para obtener la información, entra en contacto con nuestro equipo; una vez verificada tu identidad, te entregará la información.',
		ombudsmanBtn: 'delivery@mydnadigital.com',

		//Campaign
		campaignTitle: 'Campaña People Analytics',
		campaignTxt:
			'Luego de registrar a los colaboradores en la plataforma podrá enviar el correo, seleccionar al personal requerido y hacer clic en “ENVIAR” para realizar la entrega simultánea a las personas deseadas. Junto con esto entregaremos sus respectivos accesos a cada colaborador. En MyDNA Employee cada colaborador podrá cargar toda la información relevante para que las personas responsables por la gestión de talentos tengan a su disposición en todo momento datos como: historial profesional, educación, encuesta de clima, cultura, habilidades, entre otros.',
		campaignMenu: 'Campaña Colaborador',
		campaignTableName: 'Nombre',
		campaignTableMail: 'Mail',
		campaignTableArea: 'Área',
		campaignTableJob: 'Cargo',

		//Salarios y Beneficios
		salariosTitle: 'Salarios por Cargo',

		selectBands: 'Nível de banda',

		//Internal Channel
		internalChannelTitle: 'Comunicación interna',
		internalChannelNewMessage: 'Nuevo mensaje',
		internalChannelNoMessages1: 'El buzón se encuentra vacío, crea tu primer mensaje',
		internalChannelNoMessages2: ' para comenzar a comunicarte con los colaboradores',
		internalChannelSendedMessages: 'Mensajes enviados',
		internalChannelReceivedMessages: 'Mensajes recebidas',

		//Internal Vacancies
		internalVacanciesTitle: 'Vacantes internas',
		internalVacanciesCreate: 'Crear vacantes',
		internalVacanciesActive: 'Vacantes activas',

		//MYCO Test
		mycoAbilitiesQ1: 'Análisis y Resolución de Problemas',
		mycoAbilitiesQ2: 'Innovación',
		mycoAbilitiesQ3: 'Visión Estratégica',
		mycoAbilitiesQ4: 'Orientación al cliente',
		mycoAbilitiesQ5: 'Liderazgo',
		mycoAbilitiesQ6: 'Trabajo en Equipo',
		mycoAbilitiesQ7: 'Orientación a resultados',
		mycoAbilitiesQ8: 'Plan. Y Org.',
		mycoAbilitiesQ9: 'Gestión del cambio',
		mycoAbilitiesQ10: 'Auto desenvolvimento',
		mycoAbilitiesQ11: 'Gerenciamento de estresse',

		mycoMotivationsQ1: 'Orientación comercial',
		mycoMotivationsQ2: 'Orientación al objetivo',
		mycoMotivationsQ3: 'Orientación a la tipología del trabajo realizado',
		mycoMotivationsQ4: 'Orientación a la innovación',
		mycoMotivationsQ5: 'Aspecto Económico',
		mycoMotivationsQ6: 'Autonomía y Versatilidad',
		mycoMotivationsQ7: 'Seguridad',
		mycoMotivationsQ8: 'Prestigio',
		mycoMotivationsQ9: 'Balance Profesional',
		mycoMotivationsQ10: 'Satisfacción con el puesto',
		mycoMotivationsQ11: 'Compromiso',
		mycoMotivationsQ12: 'Carrera',
		mycoMotivationsQ13: 'Desarrollo de Competencias',
		mycoMotivationsQ14: 'Proyecto Profesional',
		mycoMotivationsQ15: 'Orientación a las Rel. Interpersonales',
		mycoMotivationsQ16: 'Orientación Conceptual',
		mycoMotivationsQ17: 'Orientación a la gestión',

		//SalaryByRole
		salaryRoleEmployee: 'Colaborador',
		salaryRoleCoin: 'Moneda',
		salaryRoleSalary: 'Salario Mensual',
		salaryDownloadList: 'Descargar listado',
		//SeniorityLevel
		salaryRoleSL1: 'Indiferente',
		salaryRoleSL2: 'Junior',
		salaryRoleSL3: 'Mediano',
		salaryRoleSL4: 'Senior',
		//QualificationLevel
		salaryRoleQL1: 'Indiferente',
		salaryRoleQL2: 'Graduación',
		salaryRoleQL3: 'MBA',
		salaryRoleQL4: 'MBA Internacional',

		salaryRoleAlert: 'Esta posición esta siendo revisada. Para descargar el reporte intente nuevamente más tarde',

		//DashboardClime&Benefits
		dashboardTextClimeBenefits: 'La información presentada en el dashboard solo refleja la información de los colaboradores a los cuales  se ha informado el salario',

		alertEmptyDashboard: 'Hola! Aún no has cargado información salarial de un colaborador',

		//Benefits
		benefitsText: 'Escoge el cargo y descubre los beneficios que el mercado ofrece para cada cargo',
		benefitsSearchByCountry: 'País de la Posición del Colaborador',

		alimentationTitle: 'Colación',
		alimentationText: 'Valor promedio es de CLP $3.700 diarios',

		transportTitle: 'Movilización',
		transportText: 'El valor puede variar, dependiendo de la empresa y el salario base del trabajador',

		lifeInsuranceTitle: 'Seguro Complementario',
		lifeInsuranceText: ' Complemento del plan de salud con un promedio de cobertura de 500 UF por beneficiario',

		certificationTitle: 'Certificaciones',
		certificationText: 'Apoyo económico para certificar habilidades',

		vehicleTitle: 'Vehículo',
		vehicleText: 'Beneficio ofrecido cuando este aporta o utiliza su vehículo para desarrollar actividades relacionadas al trabajo',

		tradingPowerTitle: '% Trading Power Anual',
		tradingPowerText: 'Como forma de reconocer al profesional que ayudó a que la empresa ganara valor, parte del lucro de dicho periodo es entregado al ejecutivo',

		computerTitle: 'Computadora/Celular',
		computerText: 'Son considerados herramientas de trabajo y su entrega es cada día más masiva',

		gymTitle: 'Gimnasio',
		gymText: 'Acuerdos con gimnasios que permiten descuentos al colaborador',

		anualBonusTitle: 'Bono Anual por Resultados',
		anualBonusText: 'El valor puede variar dependiendo del desempeño del colaborador',

		bonusTitle: 'Aguinaldo',
		bonusText: 'El valor puede variar de 33.000 a 300.000 CLP. Siendo la media a 60.000 CLP',

		equityTitle: 'Equity',
		equityText: 'La compañía ofrece a su funcionario estratégico acciones de la empresa, tornándose socio de esta',

		stockOptionsTitle: 'Stock Options',
		stockOptionsText: 'La compañía ofrece a su trabajador estratégico la opción de compra de acciones de la compañía a precios mejores que los ofrecidos al mercado',

		//EmotionalSalary
		emotionalSalaryText: 'Escoge la temática referente al salario emocional que entrega el mercado y descubre cuales son los más comunes',
		emotionalSalaryOptionSelect: 'Elije una opcion',

		//MyCompany
		companyDetails: 'Detalles de la Marca',
		companyLogo: 'Cargar su logotipo',
		companyName: 'Nombre',
		companyDescription: 'Descripción',
		companyAnualBilling: 'Facturación Anual',
		companyLocation: 'Localización',
		companySize: 'Tamaño de la Empresa',
		companySize1: 'Pequeña',
		companySize2: 'Mediana',
		companySize3: 'Grande',
		companyEmployeeAmount: 'Numero Empleados',
		companyContact: 'Dados de Contacto y Redes Sociales',
		companyPhone: 'Teléfono',
		companyWebPage: 'Página Web',
		companyProducts: 'Productos de la Marca',
		companyAddProduct: 'Agregar Producto',
		companyContent: 'Contenido',
		companyUpVideos: 'Sube los videos de su Empresa aquí',

		//InternalJobs
		internalJobStep1: 'Area, Nivel, Tiempo de Experiencia',
		internalJobName: 'Nombre del Puesto*:',
		internalJobArea: 'Área(s) de Desempeño*:',
		internalJobNivel: 'Elije el nivel Jerárquico*:',
		internalJobExperienceYears: 'Años de experiencia requeridos para la posición:*',
		internalJobOptionArea: 'Seleccione un área',
		internalJobOptionNivel: 'Seleccione una opción',

		internalJobStep2: 'Descripción de Cargo',
		internalJobDescription: 'Descripción',
		internalJobRequeriments: 'Requerimientos',

		internalJobRequerimentsPlaceHolder: 'Escribe aqui los otros requerimiento y funciones del cargo',

		internalJobTitle: 'Vacantes Internas',
		internalJobTableC1: 'VACANTE',
		internalJobTableC2: 'AREA',
		internalJobTableC3: 'VER POSTULACIONES',
		internalJobSearchPlaceHolder: 'Búsqueda Vacante',
		internalJobShowMore: 'Carga Más...',
		internalJobEmptyJob: 'No hay vacantes',
		internalJobReturn: 'VOLVER A LA LISTA DE VACANTES',

		//HistoricProfessionalEE
		personalAddInfoProf: 'Experiencia Profesional',
		personalConectiveProf1: 'en',
		personalConectiveProf2: 'a',
		historicProfessionalEENameCompany: 'Nombre Empresa',
		historicProfessionalEEAreaCompany: 'Industria',
		historicProfessionalEEOption0: 'Seleccionar...',
		historicProfessionalEEOption1: 'Agroindustria',
		historicProfessionalEEOption2: 'Autopartes',
		historicProfessionalEEOption3: 'Bienes de Consumo',
		historicProfessionalEEOption4: 'Comunicaciones',
		historicProfessionalEEOption5: 'Construcción',
		historicProfessionalEEOption6: 'Consultoría',
		historicProfessionalEEOption7: 'Educación',
		historicProfessionalEEOption8: 'Electronica',
		historicProfessionalEEOption9: 'Energía',
		historicProfessionalEEOption10: 'Farmacéutica',
		historicProfessionalEEOption11: 'Ingeniería',
		historicProfessionalEEOption12: 'Commodities',
		historicProfessionalEEOption13: 'Electro / Electrónica',
		historicProfessionalEEOption14: 'Logistica y Transporte',
		historicProfessionalEEOption15: 'Maquinas y Equipos Industriales',
		historicProfessionalEEOption16: 'Manufactura',
		historicProfessionalEEOption17: 'Ocio y Entretenimiento',
		historicProfessionalEEOption18: 'Publicidad',
		historicProfessionalEEOption19: 'Química, Plastico y Petroquímica',
		historicProfessionalEEOption20: 'Retail',
		historicProfessionalEEOption21: 'Sector Público',
		historicProfessionalEEOption22: 'Seguros',
		historicProfessionalEEOption23: 'Telecomunicaciones',
		historicProfessionalEEOption24: 'Textil',
		historicProfessionalEEOption25: 'TI',
		historicProfessionalEEOption26: 'Vehículos',
		historicProfessionalEEOption27: 'Financieros',
		historicProfessionalEEOption28: 'Salud',
		historicProfessionalEEOption29: 'Veterinaria',
		historicProfessionalEEOption30: 'Internet',
		historicProfessionalEEOption31: 'Legal',
		historicProfessionalEEOption32: 'Líneas Aereas',
		historicProfessionalEEOption33: 'Minería',
		historicProfessionalEEJob: 'Cargo',
		historicProfessionalEEEntryDate: 'Fecha de Entrada',
		EEMonth: 'Mes',
		EEYear: 'Año',
		historicProfessionalEEActualJob: 'Trabajo Actual',
		historicProfessionalEELeaveDate: 'Fecha de Saida',

		//FormationEE
		formationEENameUniversity: 'Nombre Universidad',
		formationEEType: 'Tipo',
		formationEEOption1: 'Técnico',
		formationEEOption2: 'Profesional',
		formationEEOption3: 'Diplomado',
		formationEEOption4: 'MBA/Master',
		formationEEOption5: 'Doctorado',
		formationEECourse: 'Carrera',
		formationEEEntryDateCourse: 'Fecha de Ingreso',
		formationEECurrentlyStuding: 'Cursando Actualmente',
		formationEELeaveDateCourse: 'Fecha de Egreso',

		//ClimeQuestionsEE
		climeQuestionsEEH1: 'Muy Insatisfecho',
		climeQuestionsEEH2: 'Insatisfecho',
		climeQuestionsEEH3: 'Satisfecho',
		climeQuestionsEEH4: 'Muy Satisfecho',
		cultureQuestionsEEForMe: 'Para mi',

		//Section1
		climeQuestionsEETitle1: 'RECURSOS, MEDIO AMBIENTE Y CULTURA',
		climeQuestionEE1: '¿Las relaciones personales con tus colegas son generalmente buenas?',
		climeQuestionEE2: '¿La empresa y el liderazgo brindan suficientes iniciativas, canales y entornos para fomentar las buenas relaciones con tus colegas?',
		climeQuestionEE3: '¿Cuentas con los recursos, herramientas y entornos necesarios, ya sean físicos o digitales, para llevar a cabo tus tareas y alcanzar los objetivos?',
		climeQuestionEE4: '¿Son coherentes la cultura y los valores de la empresa con tus valores personales?',
		//Section2
		climeQuestionsEETitle2: 'ACTIVIDADES Y FUNCIONES REALIZADAS',
		climeQuestionEE5: '¿El volumen de trabajo realizado es adecuado para tu puesto y carga de trabajo?',
		climeQuestionEE6: '¿Tu puesto es consistente con tus habilidades, conocimientos y antigüedad profesional?',
		climeQuestionEE7: '¿Están claros sus objetivos, tareas y entregables establecidos?',
		climeQuestionEE8: '¿Son tus responsabilidades acordes con el puesto que ocupas?',
		climeQuestionEE9: '¿Tiene claridad y percepción de valor del impacto de las tareas en los objetivos de la empresa?',
		climeQuestionEE10: '¿El modelo de trabajo (presencial, híbrido o home office) es adecuado para el desempeño de tus funciones?',
		//Section2
		climeQuestionsEETitle3: 'LIDERAZGO',
		climeQuestionEE11: '¿Tu líder escucha y considera tus opiniones al tomar decisiones?',
		climeQuestionEE12: '¿Es adecuada la evaluación de tu desempeño y el reconocimiento de tus contribuciones?',
		climeQuestionEE13: 'Tu líder mantiene un nivel adecuado de exigencia a tu posición y carga de trabajo?',
		climeQuestionEE14: '¿Tiene tu liderazgo el conocimiento técnico, las habilidades generales y el apoyo necesarios para que usted tenga éxito en tus actividades?',
		climeQuestionEE15: '¿Te sientes cómodo compartiendo dificultades, incomodidades o pidiendo retroalimentación cuando es necesario?',
		//Section2
		climeQuestionsEETitle4: 'OPORTUNIDAD Y CARRERA',
		climeQuestionEE16: '¿Hay motivaciones y desafíos en tus funciones?',
		climeQuestionEE17: '¿Hay autonomía para aplicar tus habilidades y tomar decisiones adecuadas a tu cargo?',
		climeQuestionEE18: '¿Hay posibilidad de promoción y/o plan de carrera bien claros y definidos?',
		climeQuestionEE19: '¿Hay inversión de la empresa en desarrollo de carrera?',
		climeQuestionEE20: '¿Hay espacio para expresar tus ambiciones y aplicar a posiciones dentro de la organización?',
		climeQuestionEE21: '¿Hay claridad acerca de lo que es necesario para crecer en la compañía?',

		//Section2
		climeQuestionsEETitle5: 'SALARIOS Y BENEFICIOS',
		climeQuestionEE22: '¿Tu remuneración es adecuada para el cargo que ocupa y las actividades que realiza?',
		climeQuestionEE23: '¿Los beneficios satisfacen sus necesidades?',
		climeQuestionEE24: '¿Los procesos de reembolso son claros y fáciles de ejecutar?',

		//Section6
		climeQuestionsEETitle6: 'Responda las siguientes preguntas del 1 al 10, donde 1 indica "totalmente en desacuerdo" y 10 indica "totalmente de acuerdo":',
		climeQuestionEE25: '¿Recomendaría a un amigo o familiar para trabajar en esta empresa?',

		climeQuestionsAlert:
			'IMPORTANTE: Tu Empresa no tendrá acceso a tus respuestas, solamente a la media entre los colaboradores, siempre y cuando más de 10 colaboradores hayan respondido la encuesta, con el objetivo de mantener la confidencialidad.',

		//CultureQuestions
		cultureQuestionEE1: 'Para mi es importante que exista un buen balance de hombres y mujeres en cargos gerenciales.',
		cultureQuestionEE2: 'Me encanta vestirme y comportarme de manera formal en el trabajo.',
		cultureQuestionEE3: 'Para mi es importante que exista reciclaje y uso inteligente de los recursos de la empresa.',
		cultureQuestionEE4: 'Me gusta que exista absoluta tolerancia a la orientación sexual de mis compañeros y jefaturas.',
		cultureQuestionEE5: 'Me siento más cómodo/a en una empresa innovadora que en una extremadamente tradicional.',
		cultureQuestionEE6: 'Es entendible que una empresa tenga teléfonos y correos para hacer denuncias confidenciales.',
		cultureQuestionEE7: 'Ser competitivo es clave para ser exitoso en el trabajo.',
		cultureQuestionEE8: 'Si tengo que elegir entre desarrollo profesional versus sueldo, prefiero desarrollo profesional.',
		cultureQuestionEE9: 'Quiero asumir cargos de más poder rápidamente.',
		cultureQuestionEE10: 'Cuidar lo que dices y ser políticamente correcto al opinar, es clave para ser exitoso en el trabajo.',
		cultureQuestionEE11: 'Es importante esconder los tatuajes y cuidar la apariencia física en el trabajo para ser exitoso.',
		cultureQuestionEE12: 'Si mi jefe no conversa conmigo sobre mi desarrollo de carrera, lo entiendo.',
		cultureQuestionEE13: 'Es entendible que los Directores o Gerentes tengan estacionamientos privilegiados.',
		cultureQuestionEE14: 'Es entendible que la comunicación de los jefes pueda tornarse más dura en ocasiones.',
		cultureQuestionEE15: 'Es entendible que el Gerente General sea cercano con algunas personas y con otras no.',
		cultureQuestionEE16: 'El tema del liderazgo es lo más importante.',
		cultureQuestionEE17: 'Si fuera parte de un equipo de alto desempeño, trabajaría hasta más tarde.',
		cultureQuestionEE18: 'Me encanta que mi jefe me pregunte qué opino sobre su liderazgo y gestión.',
		cultureQuestionEE19: 'Me siento más cómodo en una empresa con sindicato(s).',
		cultureQuestionEE20: 'Elegir mi horario de entrada y salida, es un factor clave para mi.',
		cultureQuestionEE21: 'Salir más temprano los viernes, es un factor clave para mi.',
		cultureQuestionEE22: 'Tener días libres por sobre lo legal en caso de matrimonio o AUC, es un factor clave para mí.',
		cultureQuestionEE23: 'Tener los mejores beneficios para las mujeres en pre y post natal, es un factor clave para mi.',
		cultureQuestionEE24: 'Que la empresa apoye las actividades deportivas de los colaboradores, es un factor clave para mi.',

		contractTypeEE: 'Tipo de Contracto',

		//CareerPlan
		directorCP: 'Director',
		managerCP: 'Gerente',
		submanagerCP: 'Subgerente',
		bossCP: 'Jefe',
		specialistCP: 'Especialista',
		analystCP: 'Analista',
		assistantCP: 'Asistente',
		technicianCP: 'Técnico',
		operatorCP: 'Operario',

		//InternalJobs
		emptyInternalJobs: 'Aún no hay vacantes publicadas',

		//InternalMessage
		emptyMessage: 'No hay mensaje',

		//EEProfile
		//PersonalInformations
		personalTitleEEProfile: 'Informaciones Personales',
		emailEEProfile: 'E-mail Personal',
		phoneEEProfile: 'Telefono',
		documentEEProfile: 'Documento',
		birthDateEEProfile: 'Fecha de Nacimiento',
		//SocialNetworks
		socialInformationEEProfile: 'Redes Sociales y Foto',
		photoEEProfile: 'Foto de perfil',
		photoTextEEProfile: 'Las fotos ayudan a personalizar tu perfil y facilitan que otros te identifiquen.',
		//Interests
		interestsTitleEEProfile: 'Informaciones de Intereses',
		hobbyEEProfile: 'Hobies',
		sportsEEProfile: 'Deportes',
		interestsEEProfile: 'Intereses',
		volunteerWorkEEProfile: 'Trabajo Voluntario',
		//Health
		healthTitleEEProfile: 'Informaciones de Salud',
		healthPlanEEProfile: 'Plan de Salud',
		heightEEProfile: 'Altura',
		weightEEProfile: 'Peso',
		diseasesEEProfile: 'Enfermedades',
		pcdEEProfile: 'PCD',
		typeEEProfile: 'Tipo',
		//Family
		familyTitleEEProfile: 'Informaciones Familiar',
		genderEEProfile: 'Género',
		civilStatusEEProfile: 'Estado Civil',
		sonsEEProfile: 'Filhos',
		//Contract
		contractTitleEEProfile: 'Informaciones de Contrato',
		contractTypeEEProfile: 'Tipo de Contracto',
		myBossEEProfile: 'Tu Jefe',
		selectBossEEProfile: 'Elige a tu Jefe',
		bankEEProfile: 'Banco',
		bankAccountEEProfile: 'Cuenta Corriente',
		//Adress
		adressTitleEEProfile: 'Informaciones de Domicílio',
		cityEEProfile: 'Ciudad',
		countryEEProfile: 'País',
		adressEEProfile: 'Dirección',
		nationalityEEProfile: 'Nacionalidad',
		transportEEProfile: 'Medio de transporte utilizado para moverse al trabajo',

		//FutureCV
		showMore: 'Más información',
		showReview: 'Ver Análisis MyDNA',
		downloadFutureCV: 'Descargar PDF',
		inTextFutureCV: 'en',
		yearsTextFutureCV: 'años',
		experienceTextFutureCV: 'Experiencia Profesional',
		careerYearsTextFutureCV: 'años de carrera',
		monthsTextFutureCV: 'meses de carrera',
		employeeYearsExpFutureCV: 'años por empresa',
		employeeMonthsExpFutureCV: 'meses por empresa',
		formationTextFutureCV: 'Formación',
		purposeTextFutureCV: 'Propósito',
		cultureTextFutureCV: 'Cultura',
		comercialTitleFutureCV: 'Comercial',
		comercialTextFutureCV: 'Orientación a lograr resultados. Ambiente motiva en parte a colaboradores a cumplir metas. Estilo de liderazgo motiva, pero se puede poner mas agresividad comercial.',
		disruptiveTitleFutureCV: 'Disruptiva',
		disruptiveTextFutureCV: 'Estilo de organización más innovadora, se escucha y se trabaja más en equipo. Se planifica más a largo plazo. El liderazgo es más motivador, de tomar riesgos, más emprendedor.',
		traditionalTitleFutureCV: 'Tradicional',
		traditionalTextFutureCV: 'Estilo de organización con foco en las personas. Preocupados de generar un ambiente de trabajo grato y cercano. El desarrollo profesional es parte de los desafíos permanentes.',
		collaborativeTitleFutureCV: 'Colaborativa',
		collaborativeTextFutureCV:
			'Estilo de organización levemente estructurado. Se cuenta con una estructura piramidal, donde las decisiones son tomadas por pocos. La comunicación de las decisiones o estrategia es lenta, conocida por pocos y generalmente jefaturas.',
		performanceTextFutureCV: 'Desempeño',
		resultTextFutureCV: 'Resultado Final',
		abilitiesTextFutureCV: 'Competencias',
		motivationsTextFutureCV: 'Motivaciones',
		salaryTextFutureCV: 'Salario',
		collabTextFutureCV: 'Colaborador',
		emptyValueTextFutureCV: 'Valor no Informado',
		comparativeTextFutureCV: 'Comparativo',
		riskTitleFutureCV: 'Riesgo de Perdida',
		riskHighTextFutureCV: 'Alto',
		riskMediumTextFutureCV: 'Medio',
		riskLowTextFutureCV: 'Bajo',
		careerPlanningTextFutureCV: 'Plan de Carrera',
		actualLevelTextFutureCV: 'Nivel Actual',
		nextLevelTextFutureCV: 'Proximo Paso',
		observationsTextFutureCV: 'Observaciones',
		editObservationsTextFutureCV: 'Editar Observaciones',

		//Under Construction
		titleConstruction: 'Hola! Este recurso está en desarrollo.',
		textConstruction: 'Estamos trabajando para que tengas una gran experiencia. Vuelve pronto para comprobarlo.',

		//UnitReport
		unitSectionDescription: 'Genera reportes en tiempo real. Completa la información obligatoria para generar un reporte. Si necesitas algo más específico y customizado, completa los campos recomendados y por ultimo la información adicional.',
		unityReportIncomplete: 'Por favor completa al menos toda la información requerida',
		unityReportMandatoryInfo: '1 | Información requerida',
		unityReportRecInfo: '2 | Información Recomendada',
		unityReportAddInfo: '3 | Información Adicional',
		unityReportAddInfoBtn: 'Completar Información Adicional',
		unityReportAddRecomBtn: 'Completar Información Recomendada',
		unityReportBtn: 'Generar Reporte',

		//SupportPage
		supportTitle: 'Soporte MyDNA',
		supportText:
			'Nuestro equipo de Customer Success no es solo un grupo de profesionales; somos tus socios en la consecución de tus metas. Nos comprometemos a entender tus necesidades y desafíos únicos para ofrecerte soluciones personalizadas y orientadas al éxito. Para lo que necesites contacta a nuestro equipo',

		//Documents
		docTitleView: 'Documentos de Admisión',
		docDescriptionEmp: 'Aquí podrás enviar documentos a tu empresa, cuando solicitado por los gestores.',
		documentsDescription:
			'Organice los documentos de admisión por grupo de colaboradores. Define qué documentos son necesarios para iniciar el proceso de admisión por perfil de puesto, recibe los documentos y tenlos siempre a mano para cualquier gestión.',
		myTemplatesTitle: 'Mis Plantillas',
		createTemplate: 'Crear Plantilla',
		emptyTemplate: 'Ninguna plantilla creada',
		nameTemplate: 'Nombre de la plantilla',
		newDocumentAlert: 'Agregar nombre del documento',
		loadingTemplate: 'Cargando...',
		loadingCompleteTemplate: 'Agregar Documento',
		btnSaveTemplate: 'Guardar',
		btnCancelTemplate: 'Cancelar',
		msgErrorDoc: 'El archivo no fue guardado',
		msgErrorWait: 'Espera un momento e inténtalo de nuevo o contáctanos',
		receivedDocTitle: 'Documentos Recibidos',
		docPending: 'Pendiente',
		docSended: 'Enviado',
		docDownload: 'Descargar archivo',

		//SalaryComparative
		midpointText: 'Punto medio para la posición',
		salaryDifferenceText: 'Diferencia',
		salaryColaborator: 'Salário do Colaborador',
		salaryComparativeEmptyText: 'Para obtener más información, informe los salarios',
		payrollStatus: 'El salario del colaborador se encuentra',

		//Banda Salarial
		salaryRangeTitle: 'Banda Salarial',
		salarysRangesTitle: 'Bandas Salariales',
		createNewSalaryRange: 'Crear Banda Salarial',
		salaryRangeName: 'Nombre da Banda',
		salaryRangeCountry: 'País',
		salaryRangeMidPoint: 'Punto Médio',
		salaryRangeEdit: 'Editar Banda',
		salaryRangeDelete: 'Borrar Banda',
		salaryRangeConfirmDelete: 'Seguro quieres eliminar esa banda?',

		salaryInformation: 'Información Salarial',

		EEpersonalInformation: 'Informaciones del Profesional',
		EEdescriptionValidator: 'Validar Descriptor',
		EEnextStep: 'Avanzar para Próxima Etapa',
		EEbackBtn: 'Volver para Informaciones del Profesional',
		EEregisterColaborator: 'Registrar Colaborador',

		EEFormName: 'Nombre',
		EEFormEmail: 'Correo',
		EEFormSelect: 'Seleccionar...',
		EEFormCountry: 'País',
		EEFormRegion: 'Región',
		EEFormPosition: 'Posición',
		EEFormNivel: 'Nível Jerárquico',
		EEFormArea: 'Area',
		EEFormSubarea: 'Subarea',
		EEFormEnglishLevel: 'Nível Inglés',
		EEFormFormation: 'Formación',
		EEFormFunction: 'Experiencia en la función',
		EEFormSalaryFix: 'Salario Fijo',
		EEFormSalaryVariable: 'Salario Variable',
		EENamePlaceholder: 'Nombre del Profesional',
		EEPositionPlaceholder: 'Nombre de la Posición',
		EESalaryVariablePlaceholder: 'Bonificaciones',
		EEColaboratorData: 'Dados del Colaborador',
		EEDescriptionRole: 'Descriptor de Cargo',
		EEAction: 'Acciones',
		EEEditData: 'Editar Dados del Colaborador',
		EELevelAndArea: 'Nivel y Área',
		inputRequired: 'Campo Obligatorio',

		//Performance&Potential
		performanceAvaliationTitle: 'Evaluación de Desempeño',
		potentialAvaliationTitle: 'Evaluación de Potencial',

		potentialTitle: 'Potencial',
		performancePotentialTitle: 'Desempeño y Potencial',
		performancePotentialReferences: 'Guía de Uso',
		profile1: 'Ineficiente',
		profile2: 'Eficaz',
		profile3: 'Especialista',
		profile4: 'Cuestionable',
		profile5: 'Mantenedor',
		profile6: 'Alto Impacto',
		profile7: 'Enigma',
		profile8: 'Futuro líder',
		profile9: 'Crescimiento',
		empty9Box: 'Para generar los resultados del 9box, es fundamental completar las evaluaciones de desempeño y potencial.',
		weightTitle: 'Peso',
		reachedTitle: 'Logrado',
		scoreTitle: 'Puntuación',
		emptyValuesPP: 'Ningun critério añadido.',
		observationsTitle: 'Observaciones',
		phObservations: 'Observaciones acerca del colaborador',
		pillText1: 'Mejorar',
		pillText2: 'Regular',
		pillText3: 'Fortaleza',
		deleteCriterionText: 'Estás a punto de eliminar el criterio seleccionado. Esta acción es irreversible y el criterio se eliminará permanentemente de la lista.',
		nineBoxDesc1: 'alto potencial con bajo rendimiento. Evaluar si hay causas internas o externas que lo afecten, o si es nuevo en la función.',
		nineBoxDesc2: 'alto potencial con rendimiento medio. Los profesionales en fase de crecimiento tienden a estar en esta situación',
		nineBoxDesc3: 'tiene condiciones para una ascensión inmediata. Son los profesionales que garantizan el futuro de la empresa.',
		nineBoxDesc4: 'evaluar si está en el área correcta o en fase de adaptación. Si persiste, sería ideal revisar sus asignaciones.',
		nineBoxDesc5: 'presenta condiciones para crecer, pero aún necesita desarrollar mejor sus competencias.',
		nineBoxDesc6: 'excelente rendimiento. Es necesario desarrollarse un poco más para asumir desafíos más complejos.',
		nineBoxDesc7: 'evaluar la posibilidad de moverlo a una función menor o considerar su despido.',
		nineBoxDesc8: 'presenta un mayor enfoque en el rendimiento. Generalmente son profesionales de trabajo especializado.',
		nineBoxDesc9: 'está en el lugar correcto. Es muy competente en lo que hace y no muestra indicadores de que deba cambiar de posición en el futuro cercano.',

		//PerformanceGraph
		salaryTitle9Box: 'Banda Salarial',
		salaryBandPerformance: 'Banda Salarial x Desempeño',
		salaryBandPortential: 'Banda Salarial x Potencial',
		performanceTitle9Box: 'Desempeño',
		potentialTitle9Box: 'Potencial',
		potentialMenuTitle: 'Evaluación de Potencial',
		performanceMenuTitle: 'Evaluación de Desempeño',
		nineBoxMenuTitle: 'Matriz 9Box',
		nineBoxSalaryTitlePotential1: 'Perspectivas de crecimiento limitadas.',
		nineBoxSalaryTitlePotential2: 'Potencial sobreestimado:',
		nineBoxSalaryTitlePotential3: 'Alto potencial Salarial.',
		nineBoxSalaryTitlePotential4: 'Requiere Desarrollar Competencias.',
		nineBoxSalaryTitlePotential5: 'Está Adecuado.',
		nineBoxSalaryTitlePotential6: 'Talento Prometedor.',
		nineBoxSalaryTitlePotential7: 'Potencial salarial coherente.',
		nineBoxSalaryTitlePotential8: 'No impulsado.',
		nineBoxSalaryTitlePotential9: 'Potencial subestimado:',
		nineBoxSalaryPotential1: 'Ha alcanzado el límite salarial para su posición y no muestra un potencial significativo para asumir mayores responsabilidades en un futuro próximo.',
		nineBoxSalaryPotential2: 'La disparidad entre el salario y el potencial sugiere la necesidad de reevaluar las expectativas respecto a este profesional.',
		nineBoxSalaryPotential3: 'La combinación de su capacidad para evolucionar y una remuneración superior sugiere que se le considera una inversión estratégica para el futuro de la empresa.',
		nineBoxSalaryPotential4: 'Para estar en línea con las expectativas del rol actual y el salario recibido, es recomendable que el profesional desarrolle las competencias necesarias.',
		nineBoxSalaryPotential5: 'Su capacidad para desempeñar sus funciones y evolucionar dentro de la organización corresponde al nivel de remuneración que recibe.',
		nineBoxSalaryPotential6: 'El salario está alineado con el promedio del mercado para su cargo, pero existe la posibilidad de ajustarlo por encima de la media, dado su potencial de crecimiento, si resulta estratégico para la empresa.',
		nineBoxSalaryPotential7: 'Su capacidad para asumir responsabilidades adicionales y generar un valor significativo para la organización es limitada, lo que resulta en una remuneración ajustada a su nivel de contribución.',
		nineBoxSalaryPotential8: 'La empresa podría no estar invirtiendo adecuadamente en el potencial de este colaborador.',
		nineBoxSalaryPotential9: 'Ajustar su remuneración para alinearla mejor con su potencial de crecimiento podría motivarlo e incentivar su desarrollo.',

		nineBoxSalaryTitlePerformance1: 'Está sobrevalorado.',
		nineBoxSalaryTitlePerformance2: 'Fue una inversión.',
		nineBoxSalaryTitlePerformance3: 'Es merecedor.',
		nineBoxSalaryTitlePerformance4: 'No cumple con las expectativas.',
		nineBoxSalaryTitlePerformance5: 'Está Adecuado.',
		nineBoxSalaryTitlePerformance6: 'Merece recibir un ajuste.',
		nineBoxSalaryTitlePerformance7: 'Recibe Compensación Proporcional.',
		nineBoxSalaryTitlePerformance8: 'Apto para recibir un ajuste.',
		nineBoxSalaryTitlePerformance9: 'Está Devaluado.',

		nineBoxSalaryPerformance1: 'Recibe un salario elevado que no se justifica por su bajo rendimiento, contribución o resultados.',
		nineBoxSalaryPerformance2: 'Su salario está sobre la media. Debe demostrar un rendimiento en el mediano plazo que justificará la inversión inicial.',
		nineBoxSalaryPerformance3: 'Tiene un salario alto que está completamente justificado por su excepcional desempeño y contribuciones a la organización.',
		nineBoxSalaryPerformance4: 'Recibe un salario alineado al mercado, por lo que debe mejorar su rendimiento para que su compensación esté alineada.',
		nineBoxSalaryPerformance5: 'Su compensación refleja adecuadamente su nivel de contribución y resultados.',
		nineBoxSalaryPerformance6: 'Ha demostrado un desempeño excepcional que justifica una compensación sobre la media.',
		nineBoxSalaryPerformance7: 'El Salario está de acuerdo a su bajo desempeño',
		nineBoxSalaryPerformance8: 'Aunque su desempeño actual no es óptimo, se considera que posee un rendimiento esperado y puede generar mayor valor para la organización con el apoyo económico adecuado.',
		nineBoxSalaryPerformance9: 'Aunque su contribución y resultados superan las expectativas para su rol, la compensación que recibe no refleja adecuadamente su valor y aportación a la organización.',
		nineBoxSalaryPotentialNullText: 'El gráfico estará disponible una vez que se complete la evaluación del potencial del colaborador y se informe su banda salarial en la primera pestaña.',
		nineBoxSalaryPotentialDescText:
			'El gráfico Banda Salarial x Potencial evalúa el potencial de los colaboradores en relación con su salario. Está dividido en 9 cuadrantes, donde el eje horizontal representa el potencial (bajo, medio, alto) y el eje vertical representa el salario (bajo, medio, alto). Este gráfico facilita la identificación de colaboradores con alto potencial que pueden estar subvalorados y necesitan desarrollo o ajustes salariales. Es una herramienta esencial para decisiones estratégicas de promoción y planificación sucesoria. Así, promueve una gestión de talentos más eficaz y justa.',
		nineBoxSalaryPerformanceNullText: 'El gráfico estará disponible una vez que se complete la evaluación de desempeño del colaborador y se infome su banda salarial en la primera pestaña.',
		nineBoxSalaryPerformanceDescText:
			'El gráfico de Banda Salarial x Desempeño es una herramienta que cruza el desempeño de los colaboradores con sus salarios. Está dividido en 9 cuadrantes, donde el eje horizontal representa el desempeño (bajo, medio, alto) y el eje vertical representa el salario (bajo, medio, alto). Este análisis facilita la identificación de talentos subvalorados o sobrevalorados, ayudando en la toma de decisiones sobre promociones, aumentos salariales y desarrollo profesional. Este mapeo visual ayuda a crear estrategias de gestión más efectivas y equitativas.',

		bandTitleText: 'Instrucciones para Indicación de Salario por Desempeño',
		bandNullText1:
			'En el eje vertical del gráfico a continuación se representa la banda salarial del colaborador correspondiente. Para comparar con su desempeño (eje horizontal), es necesario saber en qué franja salarial del gráfico se encuentra dicho colaborador.',
		bandNullText2: 'Dado que aún no has indicado el salario de este colaborador, es crucial para poder presentar la comparativa de salario por desempeño que indiques cómo se sitúa su salario con respecto a la media de mercado.',
		bandNullText3: 'Con base en la media del mercado mostrada en el gráfico, debes determinar en qué medida el salario de tu colaborador se encuentra comparado con dicha media.',
		bandText1: 'En el eje vertical del gráfico a continuación se representa la banda salarial del colaborador correspondiente, comparada con su desempeño en el eje horizontal.',
		bandText2: 'En el eje vertical del gráfico a continuación se representa la banda salarial del colaborador correspondiente, comparada con su desempeño en el eje horizontal.',
		bandText3: 'Basándonos en la media del mercado presentada en el gráfico, el salario del colaborador que indicaste previamente se encuentra en una de las siguientes categorías:',
		bandText4: 'Ahora, definamos la posición salarial del colaborador. Indica si el salario está:',
		bandText5: 'En caso de que desees editar el salario del colaborador',
		bandText6: 'puedes hacerlo aquí.',
		bandValueNull: 'Rango no Informado',
		bandExampleTitle: 'Ejemplo:',
		bandExampleText: 'Si pagas 1300 USD a tu colaborador y la media del gráfico es de 1000 USD, estás pagando un 130% de la media. Por lo tanto, debes seleccionar la opción `por encima del 110%`.',
		bandBtnSave: 'Guardar',
		bandOption1: 'Por debajo del 90%',
		bandOption2: 'Entre el 90% y el 110%',
		bandOption3: 'Por encima del 110%',

		//EvaluationReference
		useGuide: 'Guía de Uso',
		examplesOfCriteria: 'Ejemplos de  Criterios',
		potential: 'Potencial',
		definition: 'Definición',
		stepsToAnalyzeResults: 'Pasos para analizar los resultados',
		evaluationAndCriteria: 'Evaluación y Criterios',
		examplesOfPerformance: 'Ejemplos de Indicadores',
		performanceEvaluationTitle: 'Evaluación de desempeño',
		performanceEvaluationText:
			'La evaluación de desempeño es un proceso clave para medir y analizar la eficacia, competencias y contribución de los empleados a los objetivos de la empresa. Su propósito es identificar fortalezas, áreas de mejora, y establecer planes de desarrollo, influyendo en decisiones de compensación y promoción.',
		performanceCriteriaTitle: 'Criterios de desempeño',
		performanceCriteriaText:
			'Los criterios de desempeño son estándares utilizados para evaluar cómo se realiza algo o los resultados obtenidos. Estos criterios son específicos de cada puesto de trabajo o función y están diseñados para reflejar las expectativas y objetivos organizacionales.',
		examplesOfPerformanceIndicators: 'Ejemplos de Indicadores de Desempeño',
		TypesOfMethods: 'Tipos de Métodos',
		ConductualRatingScaleTitle: 'Escala de calificación conductual',
		ConductualRatingScaleText:
			'La evaluación se realiza por puntuación de 0% a 200% y  un peso para cada criterio. Actividades con mayor impacto para los objetivos organizacionales deben tener mayor peso. El resultado será la media ponderada entre todos los criterios evaluados.',
		OfCriticalIncidentsTitle: 'De incidentes críticos',
		OfCriticalIncidentsText:
			'Esta técnica establece la investigación, observación y registro de los rasgos más destacados de un empleado durante el desempeño de una tarea concreta. La identificación de incidentes críticos puede ser individual o entre el empleado y el evaluador.',
		TypesOfMethodsList1: 'Mal desempeño',
		TypesOfMethodsList2: 'Bajo desempeño',
		TypesOfMethodsList3: 'Desempeño aceptable',
		TypesOfMethodsList4: 'Buen desempeño',
		TypesOfMethodsList5: 'El mejor desempeño posible',
		ObjectiveOfTheCriteria: 'Objetivo de los Criterios',
		ObjectiveOfTheCriteriaList1: 'Medir el rendimiento de los trabajadores',
		ObjectiveOfTheCriteriaList2: 'Ofrecer feedback',
		ObjectiveOfTheCriteriaList3: 'Decidir ascensos',
		ObjectiveOfTheCriteriaList4: 'Fomentar el rendimiento',
		ObjectiveOfTheCriteriaList5: 'Decidir despidos',
		ObjectiveOfTheCriteriaList6: 'Establecer objetivos: metas, logros alcanzados',
		ObjectiveOfTheCriteriaList7: 'Aumentar  la motivación',
		ObjectiveOfTheCriteriaList8: 'Reducir el bajo rendimiento',
		ObjectiveOfTheCriteriaList9: 'Determinar compensaciones',
		ObjectiveOfTheCriteriaList10: 'Impulsar compensaciones',
		ObjectiveOfTheCriteriaList11: 'Mejorar la gestión de recursos',
		ObjectiveOfTheCriteriaList12: 'Validar contrataciones',
		StepsToAnalyzePerformanceEvaluationResults: 'Pasos para analizar los resultados de la Evaluación de Desempeño',
		StepsToAnalyzePerformanceEvaluationResultsList1: 'Reúne los resultados individuales de todos los empleados',
		StepsToAnalyzePerformanceEvaluationResultsList2: 'Clasifica los datos por departamento, cargo o área de trabajo',
		StepsToAnalyzePerformanceEvaluationResultsList3: 'Identifica los puntos en común y las tendencias en los resultados',
		StepsToAnalyzePerformanceEvaluationResultsList4: 'Compara los resultados individuales con los objetivos establecidos',
		StepsToAnalyzePerformanceEvaluationResultsList5: 'Examina las áreas donde se superaron las expectativas y las que requieren mejora',
		StepsToAnalyzePerformanceEvaluationResultsList6: 'Investiga las causas que subyacen a los resultados positivos y negativos',
		StepsToAnalyzePerformanceEvaluationResultsList7: 'Desarrolla planes de acción específicos para abordar las áreas de mejora',
		PostEvaluationTitle: 'Post Evaluación',
		PostEvaluationText:
			'Después de la evaluación, entrega un feedback efectivo de los resutados a cadaempleado y realiza un seguimiento de los objetivos establecidos. Ajusta el proceso según sea necesario, la evaluación de desempeño debe ser un ciclo continuo de mejora.',
		Potential: 'Potencial',
		DefinitionTitle: 'Definición',
		DefinitionText:
			'Se refiere a las capacidades futuras y el desarrollo profesional que se espera de un empleado. Se evalúa no solo el rendimiento actual de un individuo en su rol actual, sino también su capacidad para crecer, asumir mayores responsabilidades y contribuir de manera más significativa a la organización en el futuro. La evaluación del potencial en la evaluación de desempeño es crucial para una gestión efectiva del talento, ya que permite a las organizaciones identificar y nutrir a sus futuros líderes y contribuyentes clave.',
		ExamplesOfPotentialCriteria: 'Ejemplos de  Criterios de Potencial',
		WhyIsPotentialEvaluated: '¿Por qué se evalua el potencial?',
		PotentialsOfAnExecutive: 'Potenciales de un ejecutivo',
		TypesOfModels: 'Tipos de modelos',
		PsychologicalEvaluationsTitle: 'Evaluaciones psicológicas',
		PsychologicalEvaluationsText: 'Este tipo de evaluaciones permiten determinar el potencial oculto de un empleado. Para ello se analiza el rendimiento futuro de un empleado en lugar de su trabajo previo. ¿Cómo se hace?',
		AssessmentTitle: 'Evaluación 360',
		AssessmentText:
			'Una matriz de 3x3, que forma nueve cuadrantes o casillas. En un eje, se evalúa el desempeño de los empleados, generalmente utilizando una escala de bajo a alto, y en el otro eje se evalúa su potencial de desarrollo (evaluación 360) , nuevamente utilizando una escala de bajo a alto. Los nueve cuadrantes resultantes se utilizan para categorizar a los empleados',
		AssessmentPuzzleTitle: 'Enigma',
		AssessmentPuzzleText:
			'Los colaboradores en esta categoría tienen un desempeño actual que puede ser difícil de evaluar claramente, a menudo debido a circunstancias externas o factores ambiguos. Puede ser necesario un análisis más detallado para comprender mejor su contribución y potencial.',
		AssessmentStrongPerformanceTitle: 'Crecimiento',
		AssessmentStrongPerformanceText: 'Aunque no necesariamente alcanzan el nivel más alto en desempeño actual, estos colaboradores muestran un desempeño sólido y tienen un potencial significativo para crecer y desarrollarse más en la organización.',
		AssessmentHighImpactTitle: 'Alto Impacto',
		AssessmentHighpotentialTitle: 'Futuro Líder',
		AssessmentHighpotentialText: 'Estos empleados muestran un excelente desempeño actual y tienen un alto potencial para roles más exigentes o de liderazgo en el futuro. Son considerados como los talentos más prometedores de la organización.',
		AssessmentQuestionableTitle: 'Cuestionable',
		AssessmentQuestionableText:
			'Este perfil indica un desempeño actual que puede ser inconsistente o insatisfactorio, junto con un potencial incierto para mejorar significativamente en el futuro. Requieren una evaluación más profunda y posiblemente intervención para determinar su trayectoria dentro de la empresa.',
		AssessmentMaintainerTitle: 'Mantenedor',
		AssessmentMaintainerText1:
			'Son empleados que tienen un desempeño actual constante y fiable, pero con un potencial limitado para roles más desafiantes o de liderazgo. A menudo se les asignan tareas cruciales para mantener la estabilidad operativa dentro de la organización.',
		AssessmentSpecialistTitle: 'Especialista',
		AssessmentMaintainerText2:
			'Estos colaboradores no solo cumplen con las expectativas en su desempeño actual, sino que también demuestran un alto nivel de compromiso y dedicación hacia su trabajo y hacia los objetivos de la organización. Además de ser eficaces, muestran un potencial prometedor para asumir mayores responsabilidades y contribuir significativamente al crecimiento y éxito de la empresa.',
		AssessmentInsufficientTitle: 'Insuficiente',
		AssessmentInsufficientText:
			'Estos colaboradores muestran un desempeño actual que no cumple con las expectativas requeridas. Tienen un rendimiento por debajo de lo esperado y muestran un potencial limitado para mejorar significativamente en el futuro sin intervención y desarrollo intensivo.',
		AssessmentEffectiveTitle: 'Eficaz',
		AssessmentEffectiveText:
			'Son empleados que cumplen consistentemente con las expectativas en su desempeño actual. Realizan sus tareas de manera competente y satisfactoria, pero quizás no destacan de manera excepcional ni muestran un potencial destacado para roles de mayor responsabilidad en el futuro inmediato.',
		WhatIsThe9boxToolForTitle: '¿Para qué sirve la herramienta 9box?',
		WhatIsThe9boxToolForText:
			' Ayuda a los líderes y profesionales de recursos humanos a tomar decisiones informadas sobre el desarrollo de su fuerza laboral, identificando a los empleados que necesitan apoyo adicional y aquellos que están listos para asumir roles de mayor responsabilidad. Esto contribuye a la planificación de sucesión y al crecimiento sostenible de la organización.',
		AdvantagesOfThe9BoxMatrix: 'Ventajas de la Matriz 9-Box',
		AdvantagesOfThe9BoxMatrixList1: 'Es visual. Es simple y objetivo.',
		AdvantagesOfThe9BoxMatrixList2: 'Excelente apoyo para encontrar reemplazos.',
		AdvantagesOfThe9BoxMatrixList3: 'Valor histórico.',

		/** Assessment / WithMeGrow */
		competencyTest: 'Test de Competencias',
		questionsDescription: 'Aquí encontrarás las preguntas para conocer el nivel de desarrollo de tus competencias. Te pedimos que selecciones en cada afirmación el grado de acuerdo o desacuerdo que tienes con cada una de ellas.',
		responseOptions: 'Opciones de respuesta:',
		veryDisagreeable: 'Muy en desacuerdo',
		inDisagreement: 'En desacuerdo',
		neutral: 'Neutro',
		iAgree: 'De acuerdo',
		iAgreeVeryMuch: 'Muy de acuerdo',
		tryNotToRespondInNeutral: 'Intenta no responder en neutro',
		answerHonestly: 'Responde con sinceridad',
		itsLessThan25Minutes: 'Toma menos de 25 minutos',
		saveAndFollow: 'Guardar y Seguir',
		conclude: 'Concluir',
		progress: 'Progreso',
		steps: '/16 Etapas',
		back: 'Atrás',
		next: 'Siguiente',
		fortresses: 'Fortalezas',
		improvementOpportunities: 'Oportunidades de Mejora',
		readMore: 'leer más',
	},
}
