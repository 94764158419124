import "./styles.css";
import { useEffect, useState } from "react";
import { AiOutlineCloseCircle, AiOutlineCheckCircle } from "react-icons/ai";

import RVDesempeno from "./RVDesempeno";
import RVDesempenoArea from "./RVDesempenoArea";
import DesempenoEmployer from "./DesempenoEmployer";

import { getEmployeesMediasByCompanyId } from "../../../../API/Services/RemuneracionVariable/EmployeeMedia";
import {
  getVariableMultiplierByCompany,
  postVariableMultiplier,
  putVariableMultiplier,
} from "../../../../API/Services/RemuneracionVariable/MultiplierEmployee";

const RemuneracionVariable = () => {
  const [all, setAll] = useState(null);
  const [radioValue, setRadioValue] = useState("");

  const resetRadioState = async () => {
    setRadioValue("");
  };

  const handleChange = (event) => {
    const auxArray = [...all];
    auxArray[event.currentTarget.dataset.index][event.currentTarget.name] =
      event.currentTarget.value;
    setAll(auxArray);
  };

  const handleSend = async (e) => {
    const empId = e.currentTarget.dataset.empid;
    const multId = e.currentTarget.dataset.multid;
    const objVar = all[e.currentTarget.dataset.index].multiplier;
    const objSend = { multiplier: parseInt(objVar) };
    all[e.currentTarget.dataset.index].multiplierId > 0
      ? await putVariableMultiplier(objSend, multId)
      : await postVariableMultiplier(objSend, empId);
  };
  const handleDelete = async (e) => {
    const multId = e.currentTarget.dataset.multid;
    const objSend = { multiplier: 0 };
    await putVariableMultiplier(objSend, multId);
  };

  useEffect(() => {
    const users = JSON.parse(localStorage.getItem("user"));
    const fetchMultipliers = async () => {
      const employer = await getEmployeesMediasByCompanyId(users.id);
      const multipliers = await getVariableMultiplierByCompany(users.id);
      const newEmployers = [];
      employer
        .filter((filt) => filt.media > 0 && filt)
        .map((employer) => {
          const [multiplier] = multipliers.filter(
            (mult) => mult.variableSalaryEmployer?.id === employer.employerId
          );
          const newData = {
            employerId: employer.employerId,
            name: employer.employerMediaEmployer.name,
            email: employer.employerMediaEmployer.email,
            profileImg: employer.employerMediaEmployer.profileImg,
            multiplier: multiplier ? multiplier.multiplier : 0,
            multiplierId: multiplier ? multiplier.id : 0,
          };
          return newEmployers.push(newData);
        });
      setAll(newEmployers);
    };
    fetchMultipliers();
  }, []);

  return (
    <div className="container-fluid StepsFormClass">
      <div className="row pt-5">
        <div className="col-12 text-center">
          <h5>
            Indicar desempeño de la empresa y del área para el correcto calculo
            de remuneración variable
          </h5>
        </div>
        <div className="col-12 justify-content-center text-center pt-4">
          <div
            className={
              radioValue === "radioColaborador"
                ? "form-check form-check-inline radio-style radio-style-active"
                : "form-check form-check-inline radio-style"
            }
          >
            <input
              className="form-check-input"
              type="radio"
              name="radioMenu"
              id="radioColaborador"
              value={"radioColaborador"}
              checked={radioValue === "radioColaborador"}
              onChange={(e) => {
                setRadioValue(e.target.value);
              }}
              style={{ display: "none" }}
            />
            <label className="form-check-label" htmlFor="radioColaborador">
              Desempeño Colaborador
            </label>
          </div>
          <div
            className={
              radioValue === "radioEmpresa"
                ? "form-check form-check-inline radio-style radio-style-active"
                : "form-check form-check-inline radio-style"
            }
          >
            <input
              className="form-check-input"
              type="radio"
              name="radioMenu"
              id="radioEmpresa"
              value={"radioEmpresa"}
              checked={radioValue === "radioEmpresa"}
              onChange={(e) => {
                setRadioValue(e.target.value);
              }}
              style={{ display: "none" }}
            />
            <label className="form-check-label" htmlFor="radioEmpresa">
              Desempeño Empresa
            </label>
          </div>
          <div
            className={
              radioValue === "radioArea"
                ? "form-check form-check-inline radio-style radio-style-active"
                : "form-check form-check-inline radio-style"
            }
          >
            <input
              className="form-check-input"
              type="radio"
              name="radioMenu"
              id="radioArea"
              value={"radioArea"}
              checked={radioValue === "radioArea"}
              onChange={(e) => {
                setRadioValue(e.target.value);
              }}
              style={{ display: "none" }}
            />
            <label className="form-check-label" htmlFor="radioArea">
              Desempeño Área
            </label>
          </div>
          <div className="form-check-inline">
            {radioValue !== "" ? (
              <button type="reset" className="btn" onClick={resetRadioState}>
                <AiOutlineCloseCircle size={25} />
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {radioValue === "radioColaborador" ? (
        <>
          <div className="text-center pt-4 pb-4">
            Indique, en que caso de que haya, los principales criterios que
            reflejan los objetivos de desempeño de la compañia en el período
          </div>
          <DesempenoEmployer />
        </>
      ) : radioValue === "radioEmpresa" ? (
        <>
          <div className="text-center pt-4 pb-4">
            Indique, en que caso de que haya, los principales criterios que
            reflejan los objetivos de desempeño de la compañia en el período
          </div>
          <RVDesempeno />
        </>
      ) : radioValue === "radioArea" ? (
        <>
          <div className="text-center pt-4 pb-4">
            Indique, en que caso de que haya, los principales criterios que
            reflejanlos objetivos de desempeño de cada área
          </div>
          <RVDesempenoArea />
        </>
      ) : radioValue === "radioEstrategia" ? (
        "<RVEstrategia />"
      ) : (
        <>
          <div className="row pt-4">
            <div className="col-7">
              <h3>Remuneracion Variable</h3>
            </div>
          </div>
          {all?.map((Employer, index) => {
            return (
              <div key={index} className="container-fluid">
                <div className="row rowLabel my-2 p-3 align-items-center">
                  <div className="col-1">
                    <img
                      className="img-fluid"
                      alt="..."
                      src={
                        Employer.profileImg
                          ? Employer.profileImg
                          : "https://assets.mydna.company/profile-placeholder.png?1645585980559"
                      }
                    />
                  </div>
                  <div className="col-3">
                    <h5>{Employer.name}</h5>
                    <span>{Employer.email}</span>
                  </div>
                  <div className="col-3 text-center">
                    <h6 className="remuneracionTitleTextLittle">
                      Remuneración variable anual
                    </h6>
                    <input
                      className="countPlusMinusInput"
                      type="text"
                      name="multiplier"
                      data-index={index}
                      data-id={Employer.employerId}
                      value={Employer.multiplier}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-3">
                    <span className="remuneracionDescripcionTextLittle">
                      "Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry."
                    </span>
                  </div>
                  <div className="col-2 text-center">
                    <button
                      className="add-remove-button"
                      data-index={index}
                      data-empid={Employer.employerId}
                      data-multid={Employer.multiplierId}
                      onClick={handleSend}
                    >
                      <AiOutlineCheckCircle size={25} />
                    </button>
                    <button
                      className="add-remove-button"
                      data-multid={Employer.multiplierId}
                      onClick={handleDelete}
                    >
                      <AiOutlineCloseCircle size={25} />
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};
export default RemuneracionVariable;
