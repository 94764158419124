import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { BsList } from "react-icons/bs";
import { getEmployers } from "../../../API/Analytics/employers";
import { getEmployeeByTraining, setEmployeeTraining } from "../../../API/Employee/Training";
import Loading from "../../../Components/Loading/Loading";
import { addEmployeeTranslate } from "../../../i18n/translatedMessages";

import "./addEmployee.sass";
import { useIntl } from "react-intl";

const AddEmployee = ({ handleClose, show, course }) => {
  const language = useIntl();
  const company = JSON.parse(localStorage.getItem("user"));
  const [employees, setEmployees] = useState([]);
  const [add, setAdd] = useState(false);
  const [listTraining, setListTraining] = useState([]);
  const [listID, setListID] = useState([]);
  const [loadingModal, setLoadingModal] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    const fetchList = async () => {
      setLoadingModal(true);
      const listEmployee = await getEmployers(company.id);
      const employeeByTraining = await getEmployeeByTraining(course);
      setLoadingModal(false);
      setListTraining(employeeByTraining);
      setEmployees(
        listEmployee.map((employee) => ({
          id: employee?.id,
          name: employee?.name,
          area: employee?.area?.profileArea,
          level: employee?.level?.PositionN,
          curse: employeeByTraining?.some((user) => user.employerId === employee.id),
        }))
      );
    };

    fetchList();
  }, [show, add]);

  const checkUser = (id, option) => {
    const aux = employees.map((employee) => {
      if (employee.id === id) {
        return { ...employee, curse: option };
      }
      return employee;
    });
    return aux;
  };

  const addEmployee = (id) => {
    const find = listTraining.some((employee) => employee.employerId === id);
    if (!find) {
      const auxListID = [...listID];
      auxListID.push(id);
      const aux = checkUser(id, true);
      setEmployees(aux);
      setListID(auxListID);
    }
  };

  const saveEmployee = async () => {
    const data = {
      employerList: listID,
      capacitationId: Number(course),
    };
    await setEmployeeTraining(data);
    setAdd(false);
    setListID([]);
    setSelectedItems([]);
    setLoadingModal(true);
  };

  const close = () => {
    handleClose(false);
    setEmployees([]);
    setListID([]);
    setSelectedItems([]);
    setAdd(false);
  };

  const handleSelect = (item) => {
    addEmployee(item);
    setSelectedItems([...selectedItems, item]);
  };

  const handleDeselect = (item) => {
    const aux = checkUser(item, false);
    setEmployees(aux);
    setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== item));
    setListID(selectedItems.filter((selectedItem) => selectedItem !== item));
  };

  return (
    <Modal className="add-employer" size="lg" show={show} onHide={close} centered>
      <Modal.Header className="row row-cols-1 row-cols-md-2 pb-3 ms-0 me-0">
        <div className="col d-flex align-items-end">
          <button
            className={!add ? "btn active" : "btn unactive"}
            onClick={() => {
              setAdd(false);
            }}
          >
            {language.locale == "ES" ? "Colaboradores agregados" : "Colaboradores adicionados"}
            <BsList size={25} />
          </button>
        </div>
        <div className="col d-flex align-items-end justify-content-end">
          <button
            className={add ? "btn active" : "btn unactive"}
            onClick={() => {
              setAdd(true);
            }}
          >
            {language.locale == "ES" ? "Agregar colaboradores" : "Adicionar colaboradores"}{" "}
            <AiOutlineUsergroupAdd size={25} />
          </button>
        </div>
      </Modal.Header>
      <Modal.Body className="pb-0" scrollable="true">
        {add === false ? (
          loadingModal ? (
            <div className="loading">
              <Loading />
            </div>
          ) : (
            <ul>
              {listTraining.map((employee) => (
                <li className="body py-1" key={employee.id}>
                  <div className="row">
                    <div className="col">
                      {employee?.capacitationToEmployer?.name}{" "}
                      {employees
                        ?.filter((empFilt) => empFilt.id === employee.employerId)
                        .map((empMap) => (
                          <span className="text-position">
                            {empMap.area && empMap.level ? empMap.level + " - " + empMap.area : ""}
                          </span>
                        ))}
                    </div>
                  </div>
                </li>
              ))}
              {listTraining.length === 0 && (
                <li className="col">
                  <div className="empty">{addEmployeeTranslate.nullEmployersRegister}</div>
                </li>
              )}
            </ul>
          )
        ) : (
          <ul className="chose">
            {employees?.map((employee) => (
              <li className={`body ${employee.curse ? "active" : ""}`} key={employee.id}>
                <div className="row">
                  <div className="col py-1">
                    <button
                      className={`btn-employee ${
                        selectedItems.includes(employee.id) ? "selected" : ""
                      }`}
                      onClick={() =>
                        selectedItems.includes(employee.id)
                          ? handleDeselect(employee.id)
                          : handleSelect(employee.id)
                      }
                    >
                      {employee?.name}{" "}
                      <span className="text-position">
                        {employee.area && employee.level
                          ? employee.level + " - " + employee.area
                          : ""}
                      </span>
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
        <Modal.Footer>
          {add === false ? (
            <button className="finish" variant="primary" onClick={close}>
              {addEmployeeTranslate.closeBtn}
            </button>
          ) : (
            <button className="finish" variant="primary" onClick={saveEmployee}>
              {addEmployeeTranslate.saveBtn}
            </button>
          )}
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
};
export default AddEmployee;
