class areasMapper {
	toDomain(persistence) {
		return {
			value: persistence.area,
			id: persistence.id,
		}
	}
}

export default new areasMapper()
