import { HiExclamationTriangle } from 'react-icons/hi2'

export function ModalError({ show, handleShow }) {
	return (
		show && (
			<div className='tw-h-screen tw-w-screen tw-absolute tw-top-0 tw-left-0 tw-bg-black/50 tw-backdrop-blur-sm tw-flex tw-justify-center tw-items-center'>
				<div className='tw-rounded-md tw-bg-white tw-p-10 tw-w-[30%]'>
					<div className='tw-flex tw-justify-center tw-items-center'>
						<HiExclamationTriangle size={100} className='tw-text-error-600' />
					</div>

					<div className='tw-p-4 tw-text-center'>
						<h3 className='tw-font-bold'>Email Inválido</h3>
					</div>

					<button className='tw-bg-purple-600 hover:tw-bg-purple-500 tw-rounded-full tw-w-full tw-py-2 tw-border-none tw-text-white' onClick={() => handleShow(false)}>
						Ok
					</button>
				</div>
			</div>
		)
	)
}
