import { useState, useEffect, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { CgLogOut } from 'react-icons/cg'
import { CompanyContext } from '../../Store/CompanyContext'
import { getCountry } from '../../API/Lists/country'
import { getIndustryTypeList } from '../../API/Lists/industryType'
import { signup, signupSmtm, signin } from '../../API/Login/login'

import ResponseModal from './responseModal'

import './signup.sass'

const sizes = [{ id: 1, name: "pequeña" }, { id: 2, name: "mediana" }, { id: 3, name: "grande" }]

const Signup = () => {
    const history = useHistory()
    const { actions } = useContext(CompanyContext)
    const [show, setShow] = useState(false)
    const [countryList, setCountryList] = useState([])
    const [industryList, setIndustryList] = useState([])
    const [account, setAccount] = useState({
        email: "",
        password: "",
        name: "",
        job: "",
        company: "",
        size: "",
        industry: "",
        countryId: "",
    })

    useEffect(() => {
        const fetchCountry = async () => {
            setCountryList(await getCountry())
            setIndustryList(await getIndustryTypeList())
        }

        fetchCountry()
        return () => {
            setIndustryList([])
            setCountryList([])
        }
    }, [])

    const handleChange = event => {
        setAccount({
            ...account,
            [event.currentTarget.name]: event.currentTarget.value
        })
    }

    const handleLogin = async () => {
        const response = await signup(account)
        if (response.status === 200) {
            const responsesmtm = await signupSmtm(account)
            
            const login = await signin({
                email: account.email,
                password: account.password
            })
            localStorage.setItem('token', login.token)
            localStorage.setItem('user', JSON.stringify(login.response))
            localStorage.setItem('email', JSON.stringify(account.email))

            actions.setLogin(login.response)
            history.push('/app')
        }
        setShow(true)
    }

    return (
        <>
            <ResponseModal setShow={setShow} show={show} />
            <div className="signup">
                <img className="companyLogo" src="https://aadcdn.msftauthimages.net/dbd5a2dd-q-vgx0wbu9ntyuhzdvuv5jk-4tjk439hs8ffouhilva/logintenantbranding/0/bannerlogo?ts=637565229533956374" alt="Logotipo de la empresa"></img>
                <h2>Crear Conta MyDNA Company</h2>

                <div className="group-input">
                    <label htmlFor="company"></label>
                    <input placeholder='Empresa' type="text" name="company" value={account.company} onChange={handleChange} />
                </div>
                <div className="group-input">
                    <select name="size" defaultValue="" style={{ color: account.size === "" ? '#75767c' : '#1a1a1a' }} onChange={handleChange}>
                        <option disabled value="">Tamaño de la Empresa</option>
                        {sizes.map(size =>
                            <option value={size.id} key={size.id}>{size.name}</option>
                        )}
                    </select>
                </div>
                <div className="group-input">
                    <select name="industry" defaultValue="" style={{ color: account.industry === "" ? '#75767c' : '#1a1a1a' }} onChange={handleChange}>
                        <option disabled value="">Industria</option>
                        {industryList.map(industry =>
                            <option value={industry.id} key={industry.id}>{industry.name}</option>
                        )}
                    </select>
                </div>
                <div className="group-input">
                    <select name="countryId" defaultValue="" style={{ color: account.countryId === "" ? '#75767c' : '#1a1a1a' }} onChange={handleChange}>
                        <option disabled value="">Pais</option>
                        {countryList.map(country =>
                            <option value={country.id} key={country.id}>{country.name}</option>
                        )}
                    </select>
                </div>
                <div className="group-input">
                    <input placeholder='Su posición' type="text" name="job" value={account.job} onChange={handleChange} />
                </div>
                <div className="group-input">
                    <input placeholder='Su nombre' type="text" name="name" value={account.name} onChange={handleChange} />
                </div>
                <div className="group-input">
                    <input placeholder='Su correo' type="text" name="email" value={account.email} onChange={handleChange} />
                </div>
                <div className="group-input">
                    <input placeholder='Contraseña' type="password" name="password" value={account.password} onChange={handleChange} />
                </div>
                <div className="content-button">
                    <Link to='/'><CgLogOut size={20} />Volver</Link>
                    <button onClick={handleLogin}>Crear Cuenta</button>
                </div>
            </div>
        </>
    )
}
export default Signup