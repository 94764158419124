import "./styles.css";
import { useState, useEffect } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";

import TableRows from "./TableRows";

import { getAreaList } from "../../../../API/Lists/area";
import {
  getAreaMediasByCompany,
  putAreaMedia,
  postAreaMedia,
  getAreaMedia,
} from "../../../../API/Services/RemuneracionVariable/AreaMedia";

const RVDesempenoArea = () => {
  const [areaStore, setAreaStore] = useState([]);
  const [areas, setAreas] = useState("");
  const [medias, setMedias] = useState(null);
  const [ids, setIds] = useState(null);

  const [mediasByCompany, setMediasByCompany] = useState([]);

  const [openCard, setOpenCard] = useState(0);
  const [user, setUser] = useState();

  const objEmpty = {
    name: "",
    weight: "",
    value: "",
  };

  const [criterios, setCriterios] = useState([objEmpty]);

  const handleAdd = () => {
    const auxArray = [...criterios];
    auxArray.push(objEmpty);
    setCriterios(auxArray);
  };

  const handleChange = (index, event) => {
    const auxArray = [...criterios];
    auxArray[index][event.currentTarget.name] = event.currentTarget.value;
    setCriterios(auxArray);
  };

  const deleteTableRows = async (index) => {
    const rows = [...criterios];

    if (index > -1) {
      rows.splice(index, 1);
      setCriterios([...rows]);
    }
    const objCrit = rows.map((x) => {
      return {
        name: x.name,
        value: parseFloat(x.value),
        weight: parseFloat(x.weight),
      };
    });
    const objSend = {
      companyId: user.id,
      areaId: ids,
      answers: objCrit,
    };
    await putAreaMedia(objSend, medias.id);
    const areaMediaByCompanyList = await getAreaMediasByCompany(user.id);
    setMediasByCompany([...areaMediaByCompanyList]);
  };

  const handleSend = async () => {
    const objCrit = criterios.map((x) => {
      return {
        name: x.name,
        value: parseFloat(x.value),
        weight: parseFloat(x.weight),
      };
    });
    const objSendPost = {
      companyId: user.id,
      areaId: ids,
      answers: objCrit,
    };
    const objSendPut = {
      answers: objCrit,
    };
    medias?.answers
      ? await putAreaMedia(objSendPut, medias.id)
      : await postAreaMedia(objSendPost);
    const areaMediaByCompanyList = await getAreaMediasByCompany(user.id);
    setMediasByCompany([...areaMediaByCompanyList]);
  };

  const handleClick = async (e) => {
    const area = JSON.parse(e.target.dataset.area);
    setCriterios([objEmpty]);
    setAreas(area);
    setIds(area.id);
    setOpenCard(area.id);
  };

  useEffect(() => {
    const users = JSON.parse(localStorage.getItem("user"));
    if (ids !== undefined) {
      const callCriterios = async () => {
        const [response] = await getAreaMedia(users.id, ids);
        setMedias(response);
        if (response?.answers) setCriterios([...response.answers]);
      };
      callCriterios();
    }
  }, [ids]);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
    const users = JSON.parse(localStorage.getItem("user"));
    const getRequests = async () => {
      const areaList = await getAreaList();
      const areaMediaByCompanyList = await getAreaMediasByCompany(users.id);
      setAreaStore([...areaList]);
      setMediasByCompany([...areaMediaByCompanyList]);
    };
    getRequests();
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 pt-5">
            <ul className="d-inline-block p-0 w-100">
              <span className="list-title">Areas:</span>
              {areaStore.map((Area, key) => {
                return (
                  <li
                    key={key}
                    className={
                      ids === Area.id
                        ? openCard
                          ? `employer-list expand-card`
                          : `employer-list`
                        : `employer-list`
                    }
                  >
                    <div className="container">
                      <div
                        onClick={handleClick}
                        data-area={JSON.stringify(Area)}
                        className="row align-items-center clickable-row"
                      >
                        <div data-area={JSON.stringify(Area)} className="col-2">
                          <img
                            data-area={JSON.stringify(Area)}
                            className="img-fluid img-fixed-size p-4"
                            src={
                              Area.profileImg
                                ? Area.profileImg
                                : "https://assets.mydna.company/profile-placeholder.png?1645585980559"
                            }
                            alt="..."
                          />
                        </div>
                        <div data-area={JSON.stringify(Area)} className="col-8">
                          <h2 data-area={JSON.stringify(Area)}>
                            {Area.profileArea}
                          </h2>
                        </div>
                        <div
                          className="col-2 ps-0 p-4"
                          data-area={JSON.stringify(Area)}
                        >
                          {mediasByCompany
                            ?.filter(
                              (media) =>
                                media?.companyAreaMediaAreaCorrelation?.id ===
                                Area?.id
                            )
                            .map((filtered, key) => {
                              return (
                                <div className="resultBox m-0" key={key}>
                                  <h2>{filtered.media.toFixed(2)}%</h2>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          {areas.id === Area.id ? (
                            <>
                              <div className="container container-media m-0">
                                <table className="table table-analisis">
                                  <thead>
                                    <tr>
                                      <th scope="col">Criterio</th>
                                      <th scope="col">Peso</th>
                                      <th scope="col">% Logrado</th>
                                      <th scope="col">
                                        <button
                                          className="add-remove-button"
                                          onClick={handleAdd}
                                        >
                                          <AiOutlinePlusCircle size={25} />
                                        </button>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {criterios.map((item, index) => (
                                      <TableRows
                                        key={index}
                                        data={item}
                                        index={index}
                                        deleteTableRows={deleteTableRows}
                                        handleChange={handleChange}
                                      />
                                    ))}
                                  </tbody>
                                </table>
                                <div className="text-end">
                                  <button
                                    className="btn ms-auto btn-salvar"
                                    onClick={handleSend}
                                  >
                                    Guardar
                                  </button>
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default RVDesempenoArea;
