import { useCallback, useEffect, useState } from 'react'
import { FaCircleCheck } from 'react-icons/fa6'
import { BiMessageSquareEdit } from 'react-icons/bi'
import { getSalary9Box, postSalary9Box } from '../../API/Employee/Employee'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import Loading from '../Loading/Loading'
import { salaryEvaluationGraphTranslate } from '../../../src/i18n/translatedMessages'

export function SalaryBand({ evaluation, setSalary9Box }) {
	const [selected, setSelected] = useState(evaluation.salary_band_9box || null)
	const [edit, setEdit] = useState(false)
	const handleSave = async () => {
		const data = {
			'employeeId': evaluation.id,
			'salary_band_9box': selected,
		}
		console.log('selected Francisco ', selected)
		setSalary9Box(selected)
		await postSalary9Box(data)

		setEdit(false)
	}

	const options = [
		{
			name: salaryEvaluationGraphTranslate.bandOption1,
			value: 1,
		},
		{
			name: salaryEvaluationGraphTranslate.bandOption2,
			value: 2,
		},
		{
			name: salaryEvaluationGraphTranslate.bandOption3,
			value: 3,
		},
	]

	return (
		<>
			<div className='tw-flex tw-items-center tw-justify-between tw-h-full'>
				<div className='tw-space-y-5 lg:tw-space-y-10 tw-text-black tw-pr-10 tw-h-full'>
					<div className='tw-font-bold'>{salaryEvaluationGraphTranslate.bandTitleText}</div>
					{evaluation?.source != 'salary' ? (
						<div className='texts tw-space-y-5'>
							<div>{salaryEvaluationGraphTranslate.bandNullText1}</div>
							<div>{salaryEvaluationGraphTranslate.bandNullText2}</div>
							<div>{salaryEvaluationGraphTranslate.bandNullText3}</div>
						</div>
					) : (
						<>
							<div>{salaryEvaluationGraphTranslate.bandText1}</div>
							<div>{salaryEvaluationGraphTranslate.bandText3}</div>
						</>
					)}

					<div className='img tw-flex tw-flex-col tw-items-center 2xl:tw-hidden'>
						<img src={`https://assets.mydna.company/b2b-images/9BoxSalaryCompleted.png`} alt=''></img>
						<div className='tw-text-sm tw-text-alert-500 tw-mb-2 tw-mt-2'>{salaryEvaluationGraphTranslate.bandExampleTitle}</div>
						<div className='tw-text-sm tw-text-gray-500'>{salaryEvaluationGraphTranslate.bandExampleText}</div>
					</div>

					{evaluation?.source != 'salary' ? (
						<>
							<div className='tw-font-bold'>{salaryEvaluationGraphTranslate.bandText4}</div>
							<div className='tw-space-y-5 tw-3/6'>
								<div className='tw-flex tw-space-x-2 lg:tw-space-x-5'>
									{options.map((item, index) => (
										<button
											key={index}
											className={`tw-flex border-0 tw-items-center tw-justify-center tw-space-x-2 tw-px-4 tw-py-1 ${
												selected === item.value ? 'tw-bg-black tw-text-white' : 'tw-bg-gray-50 tw-text-gray-400'
											} tw-rounded-full tw-w-full tw-text-center tw-text-sm 2xl:tw-text-base`}
											onClick={() => {
												setSelected(item.value)
												setEdit(true)
											}}
										>
											{item.name}
										</button>
									))}
								</div>
								{edit && (
									<button className={`tw-py-2 tw-px-10 tw-rounded-full tw-w-full tw-border-none ${selected === null ? 'tw-bg-gray-300 tw-text-gray-500' : 'tw-bg-purple-500 tw-text-white'}`} onClick={handleSave} disabled={selected === null}>
										Guardar
									</button>
								)}
							</div>
						</>
					) : (
						<>
							<div className='tw-space-y-5 tw-3/6'>
								<div className='tw-flex tw-space-x-2 lg:tw-space-x-5'>
									{options.map((item, index) => (
										<div
											key={index}
											className={`tw-flex tw-items-center tw-justify-center tw-space-x-2  tw-px-4 tw-py-1 tw-rounded-full tw-w-full tw-text-center  tw-text-sm tw-border-none 2xl:tw-text-base ${
												evaluation.salary_band_9box === item.value ? ' tw-bg-black tw-text-white' : 'tw-bg-gray-50 tw-text-gray-400'
											}  `}
										>
											{item.name}
										</div>
									))}
								</div>
							</div>
							<div className='tw-font-bold'>
								{salaryEvaluationGraphTranslate.bandText5},{' '}
								<Link to={'/app/services/list'}>
									<span className='tw-text-purple-600 tw-font-bold'>{salaryEvaluationGraphTranslate.bandText6}</span>
								</Link>
							</div>
						</>
					)}
				</div>
				<div className='img tw-hidden 2xl:tw-block'>
					<img src={`https://assets.mydna.company/b2b-images/9boxSalary.png`} alt=''></img>
					<div className='tw-text-sm tw-text-alert-500 tw-mb-2 tw-mt-2'>{salaryEvaluationGraphTranslate.bandExampleTitle}</div>
					<div className='tw-text-sm tw-text-gray-500'>{salaryEvaluationGraphTranslate.bandExampleText}</div>
				</div>
			</div>
		</>
	)
}
