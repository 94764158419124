import { createContext, useState } from "react";
import getState from "./Company.jsx";

export const CompanyContext = createContext(null);

const CompanyContextProvider = ({ children }) => {

    const [state, setState] = useState(
        getState({
            getStore: () => state.store,
            getActions: () => state.actions,
            setStore: (updatedStore) =>
                setState({
                    store: Object.assign(state.store, updatedStore),
                    actions: { ...state.actions },
                }),
        })
    );

    return (
        <CompanyContext.Provider value={state}>
            {children}
        </CompanyContext.Provider>
    );
};

export default CompanyContextProvider