import AssignScreen from "./Components/AssignScreen";

const Outplacement = () => {
    return (
        <div className="row">
            <div className="col offset-1">
                <AssignScreen title={"Outplacement"} />
            </div>
        </div>
    );
};

export default Outplacement;
