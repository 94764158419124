export const options1 = {
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: true,
            text: 'MÉDIA DO CLIMA'
        }
    },
    scales: {
        r: {
            angleLines: {
                display: true
            },
            suggestedMin: 0,
            suggestedMax: 4,
            ticks: {
                stepSize: 1
            }
        }
    },
    maintainAspectRatio: false
}

export const data1 = {
    labels: ['RECURSOS, AMBIENTE Y CULTURA', 'ATIVIDADES Y FUNCCIONES DESEMPEÑADAS', 'LIDERAZGO', 'SALÁRIO Y BENEFICIOS', 'OPORTUNIDADES DEL CARRERA'],
    datasets: [
        {
            label: "teste",
            data: [3, 2.8, 3, 2.7, 2.8],
            borderColor: '#6a66d9',
            fill: false,
            borderWidth: 3,
        },
    ],
};


export const options2 = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
        legend: {
            display: false
        },
        title: {
            display: true,
            text: 'RECURSOS, AMBIENTE E CULTURA',
        },
    },
    scales: {
        y: {
            ticks: {
                display: false
            },
            grid: {
                drawBorder: false,
            }
        },
        x: {
            grid: {
                drawBorder: false,
                display: false
            }
        }
    }
};

export const data2 = {
    labels: [''],
    datasets: [
        {
            label: '¿Tus relaciones personales con tus compañeros son buenos en general?',
            data: [3.2],
            backgroundColor: '#00b0e0',
        },
        {
            label: '¿La empresa y el liderazgo ofrecen suficientes iniciativas, canales y ambiente para fomentar las buenas relaciones con sus colegas?',
            data: [2.8],
            backgroundColor: '#089ae3',
        },
        {
            label: '¿Cuentas con los recursos, herramientas y entornos necesarios, ya sean físicos o digitales, para llevar a cabo tus tareas y alcanzar los objetivos?',
            data: [3.6],
            backgroundColor: '#0878b3',
        },
        {
            label: '¿Son coherentes la cultura y los valores de la empresa con sus valores personales?',
            data: [2.5],
            backgroundColor: '#1452a8',
        },
        {
            label: 'PROMEDIO',
            data: [3.0],
            backgroundColor: '#1a73e8',
        },
    ],
};