import React, { useEffect, useState } from 'react'
import { InputGroup } from 'react-bootstrap'
import Swal from 'sweetalert2'

import { FormattedMessage, useIntl } from 'react-intl'
import { sendRequestReportMail } from '../../../../../API/Services/PayBalance/SmtmData.js'
import { DefaultPattern, unitReportTranslate } from '../../../../../i18n/translatedMessages.js'
import { getCityIdByCountrySmtm } from '../../../../Service/Views/PeopleAnalytics/Management/ParseMydnaDataToSmtmData.js'
import { createReport, selectArea, selectCity, selectIndustry, selectPositions } from '../Services/StepsReport.js'
import './StepsReport.css'

const StepsReport = () => {
	const language = useIntl()

	const [allIndustries, setallIndustries] = useState([])
	const [allAreas, setAllAreas] = useState([])
	const [allPositions, setallPositions] = useState([])
	const [allCities, setAllCities] = useState([])
	const [show, setShow] = useState(false)

	const [showStep2, setshowStep2] = useState(false)
	const [showStep3, setshowStep3] = useState(false)

	const [report, setReport] = useState({
		country_id: '',
		country_name: '',
		city_id: '',
		industry_id: '',
		industry_name: '',
		area_id: '',
		area_name: '',
		job_id: '',
		job_name_resume: '',
		company_size_id: '0',
		company_size_name: '',
		qualification_id: '0',
		qualification_name: '',
		english_level_id: '0',
		english_level_name: '',
		seniority_id: '0',
		seniority_name: '',
		current_salary: 0,
		variable_salaries: 0,
		job_name: 'Sin data',
		subArea: 'Sin data',
	})
	const [mailReport, setMailReport] = useState({
		country_name: '',
		industry_name: '',
		area_name: '',
		company_size_name: '',
		qualification_name: '',
		english_level_name: '',
		seniority_name: '',
		current_salary: 0,
		variable_salaries: 0,
		job_name: '',
	})

	const handleInputChange = e => {
		setReport({
			...report,
			[e.target.name]: e.target.value,
		})

		switch (e.target.name) {
			case 'country_id':
				setMailReport({
					...mailReport,
					country_name: e.target.options[e.target.selectedIndex].dataset.countryname,
				})
				break
			case 'industry_id':
				setMailReport({
					...mailReport,
					industry_name: e.target.options[e.target.selectedIndex].dataset.industryname,
				})
				break
			case 'area_id':
				setMailReport({
					...mailReport,
					area_name: e.target.options[e.target.selectedIndex].dataset.areaname,
				})
				break
			case 'job_id':
				setMailReport({
					...mailReport,
					job_name: e.target.options[e.target.selectedIndex].dataset.jobname,
				})
				break
			case 'company_size_id':
				setMailReport({
					...mailReport,
					company_size_name: e.target.options[e.target.selectedIndex].dataset.sizename,
				})
				break
			case 'qualification_id':
				setMailReport({
					...mailReport,
					qualification_name: e.target.options[e.target.selectedIndex].dataset.qualificationname,
				})
				break
			case 'english_level_id':
				setMailReport({
					...mailReport,
					english_level_name: e.target.options[e.target.selectedIndex].dataset.englishname,
				})
				break
			case 'seniority_id':
				setMailReport({
					...mailReport,
					seniority_name: e.target.options[e.target.selectedIndex].dataset.seniorityname,
				})
				break
			default: {
			}
		}
	}

	useEffect(() => {
		async function fetchData() {
			let industries = await selectIndustry(report.country_id)
			setallIndustries(industries)
		}
		fetchData()
	}, [report.industry_id, report.country_id, report.area_id])

	useEffect(() => {
		async function fetchData() {
			let areas = await selectArea(report.country_id, report.industry_id)
			setAllAreas(areas)
		}
		fetchData()
	}, [report.industry_id, report.country_id, report.area_id])

	useEffect(() => {
		async function fetchData() {
			let positions = await selectPositions(report.country_id, report.area_id, report.city_id)
			setallPositions(positions)
		}
		fetchData()
	}, [report.industry_id, report.country_id, report.area_id])

	useEffect(() => {
		async function fetchCities() {
			const cities = await selectCity(report.country_id)
			setAllCities(cities)
			if (cities[0].id !== 4 || cities[0].id !== 5) {
				setReport({
					...report,
					city_id: cities[0].id,
				})
			}
		}
		if (report.country_id <= 7) {
			fetchCities()
		}
	}, [report.country_id])

	const setCityByCountry = pais => {
		let city = getCityIdByCountrySmtm(pais)
		setReport({
			...report,
			city_id: city,
		})
	}

	const handleSubmit = async e => {
		e.preventDefault()
		if (report.country_id === '' || report.area_id === '' || report.industry_id === '' || report.job_id === '') {
			Swal.fire({
				title: language.locale == 'ES' ? 'Hola!' : 'Olá!',
				text: language.locale == 'ES' ? 'Por favor completa al menos toda la información requerida' : 'Por favor, preencha pelo menos todas as informações necessárias',
				icon: 'info',
				confirmButtonText: 'Ok',
			})
		} else {
			if (parseInt(report.country_id) >= 8) {
				setShow(true)
				const objtMail = {
					...mailReport,
					current_salary: report.current_salary,
					variable_salaries: report.variable_salaries,
					recipient: 'sebastianceardi@mydnadigital.com',
					user_email: JSON.parse(localStorage.getItem('email')),
				}
				Swal.fire({
					timer: 15000,
					title: language.locale === 'ES' ? 'Hola! ⏳' : 'Olá! ⏳',
					text:
						language.locale === 'ES'
							? 'Estamos preparando tu reporte, la información solicitada estará disponible en las próximas 24 horas.'
							: 'Estamos preparando seu relatório, a informação solicitada estará disponível nas próximas 24 horas',
					icon: 'info',
					showCancelButton: false,
					showConfirmButton: true,
					allowOutsideClick: false,
				})
				const response = await sendRequestReportMail(objtMail)
				setShow(false)
			} else {
				setShow(true)
				Swal.fire({
					title: language.locale == 'ES' ? 'Hola! ⏳' : 'Olá! ⏳',
					text: language.locale == 'ES' ? 'Estamos generando tu reporte. Por favor espera unos segundos.' : 'Estamos gerando seu relatório. Aguarde alguns segundos.',
					icon: 'info',
					showCancelButton: false,
					showConfirmButton: false,
					allowOutsideClick: false,
				})
				const response = await createReport(report, JSON.parse(localStorage.getItem('email')))

				setShow(false)
				if (response === 200) {
					setShow(false)
					Swal.fire({
						title: language.locale == 'ES' ? 'Gracias por tu espera 🚀' : 'Obrigado por esperar 🚀',
						text: language.locale == 'ES' ? 'Encontraras tu reporte en el listado de abajo' : 'Você encontrará seu relatório na lista abaixo',
						icon: 'success',
						confirmButtonText: 'Ok',
					}).then(result => {
						if (result['isConfirmed']) {
							window.location.reload()
						}
					})
				} else {
					window.location.reload()
				}
			}
		}
	}

	return (
		<div>
			<div className='row'>
				<div className='col-4'>
					<div className='step'>
						<div className='text-center step-title'>
							<h5>{unitReportTranslate.unityReportMandatoryInfo}</h5>
							<div>{unitReportTranslate.unityReportIncomplete}</div>
						</div>
						<InputGroup className='mb-3'>
							<div className='d-flex mx-2 align-items-center w-100 custom-input-group'>
								<div className='me-2'>
									<div className='labelStep'>{DefaultPattern.country}</div>
								</div>
								<select name='country_id' className='w-100' onChange={handleInputChange}>
									<FormattedMessage id='collaboratorCountryOption'>{msg => <option>{msg}</option>}</FormattedMessage>
									<option value='2' data-countryname='Argentina'>
										Argentina
									</option>
									{/* <option value='9' data-countryname='Bolivia'>
										Bolivia
									</option> */}
									<option value='4' data-countryname='Brasil'>
										Brasil
									</option>
									<option value='1' data-countryname='Chile'>
										Chile
									</option>
									<option value='5' data-countryname='Colombia'>
										Colombia
									</option>
									<option value='7' data-countryname='Costa Rica'>
										Costa Rica
									</option>
									<option value='15' data-countryname='El Salvador'>
										Ecuador
									</option>
									<option value='13' data-countryname='El Salvador'>
										El Salvador
									</option>
									<option value='12' data-countryname='Guatemala'>
										Guatemala
									</option>
									<option value='6' data-countryname='Mexico'>
										Mexico
									</option>
									<option value='14' data-countryname='Panamá'>
										Panamá
									</option>
									<option value='10' data-countryname='Paraguay'>
										Paraguay
									</option>
									<option value='3' data-countryname='Perú'>
										Perú
									</option>
									<option value='8' data-countryname='Uruguay'>
										Uruguay
									</option>
									{/* <option value='11' data-countryname='Venezuela'>
										Venezuela
									</option> */}
								</select>
							</div>
						</InputGroup>

						{report.country_id === '4' || report.country_id === '5' ? (
							<>
								<InputGroup className='mb-3'>
									<div className='d-flex mx-2 align-items-center w-100 custom-input-group'>
										<div className='me-2'>
											<div className='labelStep'>{DefaultPattern.city}</div>
										</div>
										<select className='w-100' name='city_id' value={report.city_id} onChange={handleInputChange}>
											<FormattedMessage id='city'>{msg => <option>{msg}</option>}</FormattedMessage>
											{allCities.map(city => {
												return (
													<option key={city.id} value={city.id} data-cityname={city.name}>
														{city.name}
													</option>
												)
											})}
										</select>
									</div>
								</InputGroup>
							</>
						) : (
							<></>
						)}

						<InputGroup className='mb-3'>
							<div className='d-flex mx-2 align-items-center w-100 custom-input-group'>
								<div className='me-2'>
									<div className='labelStep'>{DefaultPattern.industry}</div>
								</div>
								<select name='industry_id' className='w-100' onChange={handleInputChange}>
									<FormattedMessage id='industrySelect'>{msg => <option>{msg}</option>}</FormattedMessage>
									{allIndustries.length > 0 &&
										allIndustries.map(el => {
											return (
												<option key={el.value} value={el.value} data-industryname={el.name}>
													{el.name}
												</option>
											)
										})}
								</select>
							</div>
						</InputGroup>

						<InputGroup className='mb-3'>
							<div className='d-flex mx-2 align-items-center w-100 custom-input-group'>
								<div className='me-2'>
									<div className='labelStep'>Área</div>
								</div>
								<select name='area_id' className='w-100' onChange={handleInputChange}>
									<FormattedMessage id='areaSelect'>{msg => <option>{msg}</option>}</FormattedMessage>
									{allAreas.length > 0 &&
										allAreas.map(el => {
											return (
												<option value={el.value} key={el.value} data-areaname={el.name}>
													{el.name}
												</option>
											)
										})}
								</select>
							</div>
						</InputGroup>

						<InputGroup className='mb-3'>
							<div className='d-flex mx-2 align-items-center w-100 custom-input-group'>
								<div className='me-2'>
									<div className='labelStep'>{DefaultPattern.positionLabel}</div>
								</div>
								<select name='job_id' className='w-100' onChange={handleInputChange}>
									<FormattedMessage id='positionSelect'>{msg => <option>{msg}</option>}</FormattedMessage>
									{allPositions.length > 0 &&
										allPositions.map(el => {
											return (
												<option value={el.value} key={el.value} data-jobname={el.name}>
													{el.name}
												</option>
											)
										})}
								</select>
							</div>
						</InputGroup>
					</div>
				</div>
				<div className='col-4'>
					<div className='step'>
						<div className='text-center step-title'>
							<h5>{unitReportTranslate.unityReportRecInfo}</h5>
						</div>

						{showStep2 ? (
							<>
								<InputGroup className='mb-3'>
									<div className='d-flex mx-2 align-items-center w-100 custom-input-group'>
										<div className='me-2'>
											<div className='labelStep'>Empresa</div>
										</div>
										<select name='company_size_id' className='w-100' onChange={handleInputChange}>
											<FormattedMessage id='optionSelect'>{msg => <option>{msg}</option>}</FormattedMessage>
											<FormattedMessage id='indifferentOption'>
												{msg => (
													<option value='0' data-sizename={msg}>
														{msg}
													</option>
												)}
											</FormattedMessage>
											<FormattedMessage id='smallOption'>
												{msg => (
													<option value='1' data-sizename={msg + '(< 20MM USD)'}>
														{msg} {'(< 20MM USD)'}
													</option>
												)}
											</FormattedMessage>
											<FormattedMessage id='mediumOption'>
												{msg => (
													<option value='2' data-sizename={msg + '(Entre 20MM a 100MM USD)'}>
														{msg} {'(Entre 20MM a 100MM USD)'}
													</option>
												)}
											</FormattedMessage>
											<FormattedMessage id='largeOption'>
												{msg => (
													<option value='3' data-sizename={msg + '(Más de 100MM USD)'}>
														{msg} {language.locale == 'ES' ? '(Más de 100MM USD)' : '(Mais de 100MM USD)'}
													</option>
												)}
											</FormattedMessage>
										</select>
									</div>
								</InputGroup>

								<InputGroup className='mb-3'>
									<div className='d-flex mx-2 align-items-center w-100 custom-input-group'>
										<div className='me-2'>
											<div className='labelStep'>{DefaultPattern.englishLabel}</div>
										</div>
										<select name='english_level_id' className='w-100' onChange={handleInputChange}>
											<FormattedMessage id='optionSelect'>{msg => <option>{msg}</option>}</FormattedMessage>
											<FormattedMessage id='indifferentOption'>
												{msg => (
													<option value='0' data-englishname={msg}>
														{msg}
													</option>
												)}
											</FormattedMessage>
											<FormattedMessage id='basicLevelOption'>
												{msg => (
													<option value='1' data-englishname={msg}>
														{msg}
													</option>
												)}
											</FormattedMessage>
											<FormattedMessage id='mediumLevelOption'>
												{msg => (
													<option value='2' data-englishname={msg}>
														{msg}
													</option>
												)}
											</FormattedMessage>
											<FormattedMessage id='advancedLevelOption'>
												{msg => (
													<option value='3' data-englishname={msg}>
														{msg}
													</option>
												)}
											</FormattedMessage>
										</select>
									</div>
								</InputGroup>

								<InputGroup className='mb-3'>
									<div className='d-flex mx-2 align-items-center w-100 custom-input-group'>
										<div className='me-2'>
											<div className='labelStep'>{DefaultPattern.roleOption}</div>
										</div>
										<select name='seniority_id' className='w-100' onChange={handleInputChange}>
											<FormattedMessage id='optionSelect'>{msg => <option>{msg}</option>}</FormattedMessage>

											<FormattedMessage id='indifferentOption'>
												{msg => (
													<option value='0' data-seniorityname={msg}>
														{msg}
													</option>
												)}
											</FormattedMessage>
											<FormattedMessage id='roleJunior'>
												{msg => (
													<option value='1' data-seniorityname={msg + '(< 3 años)'}>
														{msg} {'(< 3 años)'}
													</option>
												)}
											</FormattedMessage>
											<FormattedMessage id='rolePleno'>
												{msg => (
													<option value='2' data-seniorityname={msg + '(Entre 3 y 7 años)'}>
														{msg} {'(Entre 3 y 7 años)'}{' '}
													</option>
												)}
											</FormattedMessage>
											<FormattedMessage id='roleSenior'>
												{msg => (
													<option value='3' data-seniorityname={msg + '(Más de 7 años)'}>
														{msg} {'(Más de 7 años)'}
													</option>
												)}
											</FormattedMessage>
										</select>
									</div>
								</InputGroup>

								<InputGroup className='mb-3'>
									<div className='d-flex mx-2 align-items-center w-100 custom-input-group'>
										<div className='me-2'>
											<div className='labelStep'>{DefaultPattern.educationLabel}</div>
										</div>
										<select name='qualification_id' className='w-100' onChange={handleInputChange}>
											<FormattedMessage id='optionSelect'>{msg => <option>{msg}</option>}</FormattedMessage>
											<FormattedMessage id='indifferentOption'>
												{msg => (
													<option value='0' data-qualificationname={msg}>
														{msg}
													</option>
												)}
											</FormattedMessage>
											<FormattedMessage id='graduationOption'>
												{msg => (
													<option value='1' data-qualificationname={msg}>
														{msg}
													</option>
												)}
											</FormattedMessage>
											<option value='2' data-qualificationname={'MBA'}>
												MBA
											</option>
											<option value='3' data-qualificationname={'MBA Internacional'}>
												MBA Internacional
											</option>
										</select>
									</div>
								</InputGroup>
							</>
						) : (
							<>
								<div className='text-center'>
									<button onClick={() => setshowStep2(true)}>{unitReportTranslate.unityReportAddRecomBtn}</button>
								</div>
							</>
						)}
					</div>
				</div>
				<div className='col-4'>
					<div className='step'>
						<div className='text-center step-title'>
							<h5>{unitReportTranslate.unityReportAddInfo}</h5>
						</div>
						{showStep3 ? (
							<>
								<InputGroup className='mb-3'>
									<div className='d-flex mx-2 align-items-center w-100 custom-input-group'>
										<div className='me-2'>
											<div className='labelStep'>{DefaultPattern.salaryOption}</div>
										</div>
										<FormattedMessage id='salaryInput'>{msg => <input placeholder={msg} type='text' name='current_salary' onChange={handleInputChange} data-currentsalary='current_salary' />}</FormattedMessage>
									</div>
								</InputGroup>

								<InputGroup className='mb-3'>
									<div className='d-flex mx-2 align-items-center w-100 custom-input-group'>
										<div className='me-2'>
											<div className='labelStep'>{DefaultPattern.bonusLabel}</div>
										</div>
										<FormattedMessage id='bonusInput'>{msg => <input placeholder={msg} type='text' name='variable_salaries' onChange={handleInputChange} data-variablesalary='variable_salaries' />}</FormattedMessage>
									</div>
								</InputGroup>
							</>
						) : (
							<>
								<div className='text-center'>
									<button onClick={() => setshowStep3(true)}>{unitReportTranslate.unityReportAddInfoBtn}</button>
								</div>
							</>
						)}
					</div>
				</div>
			</div>

			<div className='row mt-3'>
				<button className='custom-btn-generar mt-3' onClick={handleSubmit}>
					{unitReportTranslate.unityReportBtn}
				</button>
			</div>
		</div>
	)
}

export default StepsReport
