import { NavLink } from 'react-router-dom'
import { internalChannel, internalVacancies, lateralMenuTextTranslate } from '../../../../i18n/translatedMessages'
import { InternalVacancies } from '../../../../Icons/Icons'
import { BsFillFileEarmarkTextFill } from 'react-icons/bs'
import { useIntl } from 'react-intl'

import './menuPlan.sass'

const MenuPlan = ({ handleMenu, option }) => {
	const getLanguage = useIntl()
	const language = getLanguage.locale === 'PT-BR' ? 'pt' : 'es'
	return (
		<div className='menu-plan pe-4'>
			<ul>
				<NavLink to='/app/services/edit-industry'>
					<li onClick={handleMenu} data-option='1' className={option === '1' ? 'active-menu-plan' : ''}>
						<img src='https://assets.mydna.company/mi-empresa_white.svg' alt='' />
						<span>{lateralMenuTextTranslate.myBusiness}</span>
					</li>
				</NavLink>

				<li onClick={handleMenu} data-option='2' className={option === '2' ? 'active-menu-plan' : ''}>
					<NavLink to='/app/services/list'>
						<img src='https://assets.mydna.company/people_analytics_white.svg' alt='' />
						<span>{lateralMenuTextTranslate.peopleAnalytics}</span>
					</NavLink>
				</li>
				{option === '2' && (
					<>
						<div className='d-flex flex-column align-items-end'>
							<div className='newmenu-container mb-1'>
								<NavLink onClick={handleMenu} data-option='2' to='/app/services/list'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>{lateralMenuTextTranslate.myCollaborators}</div>
									</div>
								</NavLink>
								<NavLink onClick={handleMenu} data-option='2' to='/app/services/new'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>{lateralMenuTextTranslate.collaboratorsRegister}</div>
									</div>
								</NavLink>
								<NavLink onClick={handleMenu} data-option='2' to='/app/services/multiple-add'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>{lateralMenuTextTranslate.massiveRegister}</div>
									</div>
								</NavLink>
								<NavLink onClick={handleMenu} data-option='2' to='/app/services/campaign'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>{lateralMenuTextTranslate.campaignMenu}</div>
									</div>
								</NavLink>
							</div>
						</div>
					</>
				)}

				<li onClick={handleMenu} data-option='15' className={option === '15' ? 'active-menu-plan' : ''}>
					<NavLink to='/app/services/admission-documents'>
						<BsFillFileEarmarkTextFill size={20} className='mx-3' />
						<span>Documentos</span>
					</NavLink>
				</li>
				{option === '15' && (
					<>
						<div className='d-flex flex-column align-items-end'>
							<div className='newmenu-container mb-1'>
								<NavLink onClick={handleMenu} data-option='15' to='/app/services/admission-documents'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>{language === 'pt' ? 'Solicitar Documentos' : 'Solicitud documentos'}</div>
									</div>
								</NavLink>
								<NavLink onClick={handleMenu} data-option='15' to='/app/services/employee-documents'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>{language === 'pt' ? 'Documentos Recebidos' : 'Documentos Recibidos'}</div>
									</div>
								</NavLink>
							</div>
						</div>
					</>
				)}

				<li onClick={handleMenu} data-option='3' className={option === '3' ? 'active-menu-plan' : ''}>
					<NavLink to='/app/services/badge-heores'>
						<img src='https://assets.mydna.company/onboarding_white.svg' alt='' />
						<span>{lateralMenuTextTranslate.onBoarding}</span>
					</NavLink>
				</li>
				{option === '3' && (
					<>
						<div className='d-flex flex-column align-items-end'>
							<div className='newmenu-container mb-1'>
								<NavLink onClick={handleMenu} data-option='3' to='/app/services/badge-heores'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>{lateralMenuTextTranslate.knowBadgeHeroes}</div>
									</div>
								</NavLink>
								<NavLink onClick={handleMenu} data-option='3' to='/app/services/onboarding'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>Onboarding</div>
									</div>
								</NavLink>
							</div>
						</div>
					</>
				)}

				<li onClick={handleMenu} data-option='4' className={option === '4' ? 'active-menu-plan' : ''}>
					<NavLink to='/app/services/capacitaciones'>
						<img src='https://assets.mydna.company/capacitacion_y_desarrollo_white.svg' alt='' />
						<span>{lateralMenuTextTranslate.trainingAndDevelopment}</span>
					</NavLink>
				</li>
				{option === '4' && (
					<>
						<div className='d-flex flex-column align-items-end'>
							<div className='newmenu-container mb-1'>
								<NavLink onClick={handleMenu} data-option='4' to='/app/services/capacitaciones'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>{lateralMenuTextTranslate.manageTrainings}</div>
									</div>
								</NavLink>
								<NavLink onClick={handleMenu} data-option='4' to='/app/services/entrenamientos'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>{lateralMenuTextTranslate.workoutsMydna}</div>
									</div>
								</NavLink>
								<NavLink onClick={handleMenu} data-option='4' to='/app/services/ebooks-y-contenidos'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>{lateralMenuTextTranslate.ebooksAndContent}</div>
									</div>
								</NavLink>
								<NavLink onClick={handleMenu} data-option='4' to='/app/services/elearn-platforms'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>Plataformas E-learning</div>
									</div>
								</NavLink>
							</div>
						</div>
					</>
				)}

				<li onClick={handleMenu} data-option='5' className={option === '5' ? 'active-menu-plan' : ''}>
					<img src='https://assets.mydna.company/clima_laboral_white.svg' alt='' />
					<span>{lateralMenuTextTranslate.climeAndCulture}</span>
				</li>
				{option === '5' && (
					<>
						<div className='d-flex flex-column align-items-end'>
							<div className='newmenu-container mb-1'>
								<NavLink onClick={handleMenu} data-option='5' to='/app/services/encuesta-clima'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>{lateralMenuTextTranslate.climeSurvey}</div>
									</div>
								</NavLink>
								<NavLink onClick={handleMenu} data-option='5' to='/app/services/promoter-score'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>{lateralMenuTextTranslate.nps}</div>
									</div>
								</NavLink>
								<NavLink onClick={handleMenu} data-option='5' to='/app/services/reporte-cultura'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>{lateralMenuTextTranslate.businessCulture}</div>
									</div>
								</NavLink>
								<NavLink onClick={handleMenu} data-option='5' to='/app/services/match-cultura'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>{lateralMenuTextTranslate.matchCulture}</div>
									</div>
								</NavLink>
							</div>
						</div>
					</>
				)}

				<NavLink onClick={handleMenu} data-option='6' to='/app/services/referencias'>
					<li onClick={handleMenu} data-option='6' className={option === '6' ? 'active-menu-plan' : ''}>
						<img src='https://assets.mydna.company/evaluacion_white.svg' alt='' />
						<span>{lateralMenuTextTranslate.performanceAnalysis}</span>
					</li>
				</NavLink>

				{option === '6' && (
					<>
						<div className='d-flex flex-column align-items-end'>
							<div className='newmenu-container mb-1'>
								<NavLink onClick={handleMenu} data-option='6' to='/app/services/referencias'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>{lateralMenuTextTranslate.performancePotentialReferences}</div>
									</div>
								</NavLink>
								<NavLink onClick={handleMenu} data-option='6' to='/app/services/employee-evaluation/performance'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>{lateralMenuTextTranslate.performanceMenuTitle}</div>
									</div>
								</NavLink>
								<NavLink onClick={handleMenu} data-option='6' to='/app/services/employee-evaluation/potential'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>{lateralMenuTextTranslate.potentialMenuTitle}</div>
									</div>
								</NavLink>
								<NavLink onClick={handleMenu} data-option='6' to='/app/services/employee-evaluation/nine-box'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>{lateralMenuTextTranslate.nineBoxMenuTitle}</div>
									</div>
								</NavLink>
								<NavLink onClick={handleMenu} data-option='6' to='/app/services/employee-salary-evaluation/performance'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>Desempeño x Salario</div>
									</div>
								</NavLink>
								<NavLink onClick={handleMenu} data-option='6' to='/app/services/employee-salary-evaluation/potential'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>Potencial x Salario</div>
									</div>
								</NavLink>
								{/* <NavLink  onClick={handleMenu} data-option='6' to='/app/services/employee-evaluation'>
								<div className='newmenu-item d-flex flex-row  p-1'>
									<div className='newmenu-link'>{lateralMenuTextTranslate.performance}</div>
								</div>
								</NavLink>
								<NavLink onClick={handleMenu} data-option='6' to='/app/services/employee-evaluation'>
								<div className='newmenu-item d-flex flex-row  p-1'>
									<div className='newmenu-link'>{lateralMenuTextTranslate.potentialTitle}</div>
								</div>
								</NavLink> */}
							</div>
						</div>
					</>
				)}

				<li onClick={handleMenu} data-option='7' className={option === '7' ? 'active-menu-plan' : ''}>
					<img src='https://assets.mydna.company/salario_y_beneficios_white.svg' alt='' />
					<span>{lateralMenuTextTranslate.salariesAndBenefits}</span>
				</li>

				{option === '7' && (
					<>
						<div className='d-flex flex-column align-items-end'>
							<div className='newmenu-container mb-1'>
								<NavLink onClick={handleMenu} data-option='7' to='/app/services/unit-report'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>{lateralMenuTextTranslate.unitReport}</div>
									</div>
								</NavLink>
								<NavLink onClick={handleMenu} data-option='7' to='/app/services/salarios'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>{lateralMenuTextTranslate.posicionesTitle}</div>
									</div>
								</NavLink>
								<NavLink onClick={handleMenu} data-option='7' to='/app/services/bands'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>{lateralMenuTextTranslate.bands}</div>
									</div>
								</NavLink>
								<NavLink onClick={handleMenu} data-option='7' to='/app/services/dashboard'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>Dashboard</div>
									</div>
								</NavLink>
								<NavLink onClick={handleMenu} data-option='7' to='/app/services/benefits'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>{lateralMenuTextTranslate.benefits}</div>
									</div>
								</NavLink>
								<NavLink onClick={handleMenu} data-option='7' to='/app/services/emotional-salary'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>{lateralMenuTextTranslate.emotionalSalary}</div>
									</div>
								</NavLink>
							</div>
						</div>
					</>
				)}
				<li onClick={handleMenu} data-option='8' className={option === '8' ? 'active-menu-plan' : ''}>
					<img src='https://assets.mydna.company/evaluacion_white.svg' alt='' />
					<span>{lateralMenuTextTranslate.professionalDevelopment}</span>
				</li>

				{option === '8' && (
					<>
						<div className='d-flex flex-column align-items-end'>
							<div className='newmenu-container mb-1'>
								<NavLink onClick={handleMenu} data-option='8' to='/app/services/assessment/competence'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>{lateralMenuTextTranslate.skillTest}</div>
									</div>
								</NavLink>
								<NavLink onClick={handleMenu} data-option='8' to='/app/services/motivations-test'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>{lateralMenuTextTranslate.motivacionalTest}</div>
									</div>
								</NavLink>
								<NavLink onClick={handleMenu} data-option='8' to='/app/services/english-test'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>{lateralMenuTextTranslate.englishLevelTest}</div>
									</div>
								</NavLink>
							</div>
						</div>
					</>
				)}

				<li onClick={handleMenu} data-option='9' className={option === '9' ? 'active-menu-plan' : ''}>
					<NavLink to='/app/services/proyeccion-carrera'>
						<img src='https://assets.mydna.company/plan_de_carrera_white.svg' alt='' />
						<span>{lateralMenuTextTranslate.careerPlan}</span>
					</NavLink>
				</li>
				{option === '9' && (
					<>
						<div className='d-flex flex-column align-items-end'>
							<div className='newmenu-container mb-1'>
								<NavLink onClick={handleMenu} data-option='9' to='/app/services/proyeccion-carrera'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>{lateralMenuTextTranslate.careerPlan}</div>
									</div>
								</NavLink>
								<NavLink onClick={handleMenu} data-option='9' to='/app/services/descripcion-posiciones'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>{lateralMenuTextTranslate.jobDescriptor}</div>
									</div>
								</NavLink>
							</div>
						</div>
					</>
				)}
				<li onClick={handleMenu} data-option='14' className={option === '14' ? 'active-menu-plan' : ''}>
					<NavLink onClick={handleMenu} data-option='14' to='/app/services/internal-vacancies'>
						<div className='d-inline-block mx-3'>
							<InternalVacancies color={`white`} size={23} />
						</div>
						<span>{internalVacancies.internalVacanciesTitle}</span>
					</NavLink>
				</li>
				{option === '14' && (
					<>
						<div className='d-flex flex-column align-items-end'>
							<div className='newmenu-container mb-1'>
								<NavLink onClick={handleMenu} data-option='14' to='/app/services/internal-vacancies'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>{internalVacancies.internalVacanciesCreate}</div>
									</div>
								</NavLink>
								<NavLink onClick={handleMenu} data-option='14' to='/app/services/internal-vacancies-list'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>{internalVacancies.internalVacanciesActive}</div>
									</div>
								</NavLink>
							</div>
						</div>
					</>
				)}
				<li onClick={handleMenu} data-option='10' className={option === '10' ? 'active-menu-plan' : ''}>
					<NavLink className={'text-center'} to='/app/services/internal-channel'>
						<img src='https://assets.mydna.company/b2b-images/ServiceMenuIcon_Internal-Channel.svg' alt='' />
						<span>{internalChannel.internalChannelTitle}</span>
					</NavLink>
				</li>
				<li onClick={handleMenu} data-option='12' className={option === '12' ? 'active-menu-plan' : ''}>
					<NavLink to='/app/services/ombudsman'>
						<img src='https://assets.mydna.company/reclutamiento_y_selection_white.svg' alt='' />
						<span>{lateralMenuTextTranslate.ombudsman}</span>
					</NavLink>
				</li>
				{option === '12' && (
					<>
						<div className='d-flex flex-column align-items-end'>
							<div className='newmenu-container mb-1'>
								<NavLink onClick={handleMenu} data-option='12' to='/app/services/ombudsman'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>{lateralMenuTextTranslate.occurrences}</div>
									</div>
								</NavLink>
							</div>
						</div>
					</>
				)}
				<li onClick={handleMenu} data-option='11' className={option === '11' ? 'active-menu-plan' : ''}>
					<NavLink to='/app/services/genes'>
						<img src='https://assets.mydna.company/outplacement_white.svg' alt='' />
						<span>{lateralMenuTextTranslate.outplacement}</span>
					</NavLink>
				</li>
				{option === '11' && (
					<>
						<div className='d-flex flex-column align-items-end'>
							<div className='newmenu-container mb-1'>
								<NavLink onClick={handleMenu} data-option='11' to='/app/services/genes'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>{lateralMenuTextTranslate.knowGenes}</div>
									</div>
								</NavLink>
								<NavLink onClick={handleMenu} data-option='11' to='/app/services/outplacement'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>{lateralMenuTextTranslate.realocation}</div>
									</div>
								</NavLink>
								<NavLink onClick={handleMenu} data-option='11' to='/app/services/encuesta-de-salida'>
									<div className='newmenu-item d-flex flex-row  p-1'>
										<div className='newmenu-link'>{lateralMenuTextTranslate.leaveSearch}</div>
									</div>
								</NavLink>
							</div>
						</div>
					</>
				)}
				<li onClick={handleMenu} data-option='13' className={option === '13' ? 'active-menu-plan' : ''}>
					<NavLink to='/app/services/support'>
						<img src='https://assets.mydna.company/b2b-images/support_mydna.svg' alt='' />
						<span>Suporte MyDNA</span>
					</NavLink>
				</li>
			</ul>
		</div>
	)
}
export default MenuPlan
