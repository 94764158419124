import CircularBar from "./Components/CircularBar/CircularBar";
import { DesempenoIcon } from "../../Icons/CollaboratorsIcons";
import "./desempeno.sass";
import EmptyInformation from "./Components/EmptyInformation/EmptyInformation";
import { emptyInformationTranslate, performanceTranslate } from "../../i18n/translatedMessages";
import { useState, useEffect } from "react";
import { getEmployeeMediasByEmployerId } from "../../API/Services/RemuneracionVariable/EmployeeMedia";
import Loading from "../../Components/Loading/Loading";

const B2EDesempeno = () => {
  const [data, setData] = useState([]);
  const [media, setMedia] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const fetch = async () => {
      const [response] = await getEmployeeMediasByEmployerId(user.id);

      if (response) {
        setMedia(response.media);
        setData(response.answers);
      }
      setLoading(false);
    };
    fetch();
  }, []);

  return (
    <div className="content-desempeno">
      {loading && <Loading />}
      <div className="title">
        <div className="icon">
          <DesempenoIcon size={20} />
        </div>
        <h1>{performanceTranslate.performanceTitle}</h1>
      </div>
      {data.length > 0 && (
        <>
          <div className="box-media">
            <span>{performanceTranslate.perfomanceFinalResult}</span>
            <div className="media">{Math.floor(media)}%</div>
          </div>
          <div className="bars">
            {data.map((item) => (
              <div className="desempeno-bars-analytics" key={item.name}>
                <CircularBar percentagem={item.value} />
                <h5>{item.name}</h5>
              </div>
            ))}
          </div>
        </>
      )}
      {data.length === 0 && <EmptyInformation message={emptyInformationTranslate.emptyDesempeno}/>}
    </div>
  );
};
export default B2EDesempeno;
