import './FutureCV.sass';

const CircleProgressBar = ({ radius = 80, porcentage = 50, circleWidth = 200, fontSizePercentage = 48 }) => {
    const dashArray = radius * Math.PI * 2;
    const dashOffset = dashArray - (dashArray * porcentage) / 100;
    return (
        <svg className='position-relative CircleProgressBarSVG' width={circleWidth} height={circleWidth} viewBox={`0 0 ${circleWidth} ${circleWidth}`}>
            <circle cx={circleWidth / 2} cy={circleWidth / 2} strokeWidth='15px' r={radius} className={`CircleBG`} />
            <circle
                cx={circleWidth / 2}
                cy={circleWidth / 2}
                strokeWidth='15px'
                r={radius}
                className={`CircleProg`}
                style={{ strokeDasharray: dashArray, strokeDashoffset: dashOffset }}
                transform={`rotate(-90 ${circleWidth / 2} ${circleWidth / 2})`}
            />
            <text x='50%' y='50%' dy='0.3em' textAnchor='middle' className={`CircleText`} style={{ fontSize: `${fontSizePercentage}` }}>
                {porcentage}%
            </text>
        </svg>
    );
};

export default CircleProgressBar;
