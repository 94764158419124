export const getCountry = async () => {
    try {
        const resp = await fetch(
            `${process.env.REACT_APP_API}/search/country`,
            {
                method: "GET",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        return resp.json();
    } catch (err) {
        console.error(err);
    }
};

export const getAnnualCountry = async (id) => {
    try {
        const resp = await fetch(
            `${process.env.REACT_APP_API}/country/countries/${id}`,
            {
                method: "GET",
                mode: "cors",
                cache: "no-cache",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        return resp.json();
    } catch (err) {
        throw new Error(err);
    }
};
