import { useCallback, useEffect, useState } from "react";
import { HiMiniCurrencyDollar } from "react-icons/hi2";

import { NewBandModal } from "../Components/NewBand";
import { TableBands } from "../Components/TableBands";
import Bands from "../../../../../API/Services/PayBalance/Bands";
import Loading from "../../../../../Components/Loading/Loading";
import { salaryRangeTranslate } from "../../../../../i18n/translatedMessages";

const SalarialBands = () => {
  const [data, setData] = useState(null);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const loadData = useCallback(async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user")).id;
      const api = await Bands.getAllBands(user);

      const cl = api.filter((item) => item.country_id === 1);
      const ar = api.filter((item) => item.country_id === 2);
      const pr = api.filter((item) => item.country_id === 3);
      const br = api.filter((item) => item.country_id === 4);
      const co = api.filter((item) => item.country_id === 5);
      const mx = api.filter((item) => item.country_id === 6);
      const ec = api.filter((item) => item.country_id === 7);
      const ur = api.filter((item) => item.country_id === 8);

      setData({ cl, ar, pr, br, co, mx, ec, ur });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <div className="row">
          <div className="offset-1 col">
            <NewBandModal handleClose={handleClose} show={show} refresh={loadData} />
            <div className="container-fluid">
              <div className="row">
                <div className="col mx-4 d-flex">
                  <HiMiniCurrencyDollar size={45} className="me-3" color="#05ACF2" />
                  <h1 className="fw-bold">{salaryRangeTranslate.salaryRangeTitle}</h1>
                </div>
                <div className="col d-flex justify-content-end">
                  <button className="btn btn-primary rounded-pill px-5" onClick={handleShow}>
                    {salaryRangeTranslate.createNewSalaryRange}
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <hr />
                </div>
              </div>
              <div className="row mt-5">
                <div className="col">
                  <h5 className="fw-bold">{salaryRangeTranslate.salarysRangesTitle}</h5>
                </div>
              </div>
              {!data && (
                <div className="row">
                  <div className="col">
                    <div className="my-2 rounded-3 bg-light-grey p-3 d-flex align-items-center">
                      Nenhuma banda criada.
                      <button type="button" className="btn btn-link p-0 ms-1 shadow-none" onClick={handleShow}>
                        Crie uma agora.
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {data && <TableBands response={data.cl} country="chile" refresh={loadData} />}
              {data && <TableBands response={data.ar} country="Argentina" refresh={loadData} />}
              {data && <TableBands response={data.pr} country="Peru" refresh={loadData} />}
              {data && <TableBands response={data.br} country="Brasil" refresh={loadData} />}
              {data && <TableBands response={data.co} country="Colombia" refresh={loadData} />}
              {data && <TableBands response={data.mx} country="México" refresh={loadData} />}
              {data && <TableBands response={data.ec} country="Ecuador" refresh={loadData} />}
              {data && <TableBands response={data.ur} country="Uruguai" refresh={loadData} />}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default SalarialBands;
