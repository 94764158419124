import { useContext } from "react";
import { ServicesContext } from "../../../../../Store/ServicesContext";
import "../stylesSteps.css";

const DosStepsForm1 = () => {
  const { actions } = useContext(ServicesContext);
  return (
    <div className="container-fluid StepsFormClass">
      <form id="OnboardingForm">
        <div className="row pt-5">
          <div className="col-12 text-center">
            <h5>
              ¿Estas listo para crear el onborarding más dinamico que hayas
              implementado? Completa la siguiente información para personalizar
              tu onboarding según las características de tu empresa.
            </h5>
          </div>
        </div>
        <div className="row pt-4">
          <div className="col">
            <input
              type="text"
              name="name"
              onChange={(e) => actions.handleChange(e)}
              className="form-control"
              placeholder="Nombre del Responsable"
              aria-label="Nombre del Responsable"
            />
          </div>
          <div className="col">
            <input
              type="text"
              name="lastName"
              onChange={(e) => actions.handleChange(e)}
              className="form-control"
              placeholder="Apellido del Responsable"
              aria-label="Apellido del Responsable"
            />
          </div>
        </div>
        <div className="row pt-4">
          <div className="col-12">
            <input
              type="email"
              name="email"
              onChange={(e) => actions.handleChange(e)}
              className="form-control"
              placeholder="Mail del Responsable"
              aria-label="Mail del Responsable"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default DosStepsForm1;
