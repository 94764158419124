import { useEffect, useState, useRef } from "react";
import {
    MdKeyboardArrowDown,
    MdOutlineRadioButtonChecked,
    MdOutlineRadioButtonUnchecked,
} from "react-icons/md";

import "./select.css";

export const Select = ({
    options,
    name,
    label,
    placeholder,
    value,
    onChange,
    className,
}) => {
    const [show, setShow] = useState(false);
    const [selected, setSelected] = useState(null);

    const componentRef = useRef(null);

    useEffect(() => {
        const handleClickOut = (event) => {
            if (
                componentRef.current &&
                !componentRef.current.contains(event.target)
            ) {
                setShow(false);
            }
        };

        window.addEventListener("click", handleClickOut);

        return () => {
            window.removeEventListener("click", handleClickOut);
        };
    }, []);

    useEffect(() => {
        if (value || value === 0) {
            const [item] = options.filter((option) => option.value === value);
            setSelected(item);
        }
    }, [value, options]);

    const handleShow = () => {
        setShow(!show);
    };

    const handleClick = (item) => {
        setShow((prevShow) => !prevShow);

        const customEvent = {
            target: {
                value: item.value,
                name: name,
            },
        };
        onChange(customEvent);
    };

    return (
        <div className={`paybalance-select ${className}`} ref={componentRef}>
            <div className="d-flex w-100" role="button" onClick={handleShow}>
                <label className="text-uppercase">{label}</label>
                <div className="select">
                    {selected && selected.label}
                    {!selected && (
                        <span className="placeholder">{placeholder}</span>
                    )}
                </div>
                <MdKeyboardArrowDown size={22} />
            </div>
            {show && (
                <div className="menu-select">
                    <ul>
                        {options.map((item) => (
                            <li
                                key={item.value}
                                onClick={() => handleClick(item)}
                            >
                                {selected?.value === item.value && (
                                    <MdOutlineRadioButtonChecked />
                                )}
                                {selected?.value !== item.value && (
                                    <MdOutlineRadioButtonUnchecked />
                                )}
                                {item.label}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};
