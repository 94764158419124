import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai'
import { MdOutlineSupportAgent } from 'react-icons/md'

import './SupportMyDna.sass'
import { supportTranslate } from '../../../../i18n/translatedMessages'

const SupportMyDna = () => {
	return (
		<div className='row'>
			<div className='col offset-1 d-flex'>
				<div className='container-fluid SupportMyDna-main'>
					<div className='row'>
						<div className='col d-flex'>
							<div className='icon'>
								<MdOutlineSupportAgent size={50} />
							</div>
							<h1>{supportTranslate.supportTitle}</h1>
						</div>
					</div>
					<div className='row pt-4'>
						<p>{supportTranslate.supportText}:</p>
					</div>
					<div className='row pt-4'>
						<div className='col-1'>
							<img className='img-fluid rounded-pill' src='https://assets.mydna.company/b2b-images/support-sebastian.jpeg' alt='' />
						</div>
						<div className='col-8'>
							<h5>Sebastián Ceardi</h5>
							<button className='btn bgColor-blue rounded shadow me-1'>
								<AiOutlineMail size={20} /> bsebastianceardi@mydnadigital.com
							</button>
							<button className='btn bgColor-green rounded shadow ms-1'>
								<AiOutlinePhone size={20} /> +56 9 5693 1175
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SupportMyDna
