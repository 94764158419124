import { useEffect } from "react";
import { useState } from "react";
import { getAssetsEmployee } from "../../../../API/Employee/Employee";
import { employeeExperienceTranslate } from "../../../../i18n/translatedMessages";
import ModalImagem from "./ModalImagem/ModalImagem";

export const SocialNetwork = ({
    onChange,
    data,
    handleImage,
    setDataImage,
}) => {
    const { id } = JSON.parse(localStorage.getItem("user"));
    const [showImage, setShowImage] = useState(false);
    const [image, setImage] = useState(null);

    useEffect(() => {
        const imgRequest = async () => {
            const imgGet = await getAssetsEmployee(id);
            if (imgGet.length) {
                const filtImage = imgGet.filter(
                    (img) => img.feature === "Profile"
                );
                setImage(filtImage[filtImage.length - 1].url);
            }
        };
        imgRequest();
    }, []);

    const handleCloseImage = () => {
        setShowImage(false);
    };

    const loadImage = (event) => {
        const file = event.target.files[0];
        const user = JSON.parse(localStorage.getItem("user"));
        setImage(file);
        setShowImage(true);
        setDataImage({
            originalName: file.name,
            alias: user.id + "_photoprofile",
            companyId: user.companyId,
            employeeId: user.id,
            feature: "Profile",
        });
    };

    return (
        <>
            <ModalImagem
                show={showImage}
                handleClose={handleCloseImage}
                setImage={setImage}
                image={image}
                handleImage={handleImage}
            />
            <div className="content-form">
                <div className="row">
                    <div className="col">
                        <h3>Redes Sociales y Foto</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3 d-flex align-items-center">
                        <label>
                            {employeeExperienceTranslate.photoEEProfile}
                        </label>
                    </div>
                    <div className="col d-flex align-items-center">
                        <p>{employeeExperienceTranslate.photoTextEEProfile}</p>
                    </div>
                    <div className="col-2">
                        <input
                            type="file"
                            name=""
                            id="imageLoad"
                            onChange={loadImage}
                        />
                        <div className="img">
                            <label className="label-image" htmlFor="imageLoad">
                                <img
                                    src={
                                        image ||
                                        "https://assets.mydna.company/b2b-images/image_default.png"
                                    }
                                    alt=""
                                />
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label htmlFor="linkedIn">LinkedIn</label>
                        <input
                            type="text"
                            name="linkedIn"
                            onChange={onChange}
                            value={data.linkedIn}
                            id="linkedIn"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label htmlFor="facebook">Facebook</label>
                        <input
                            type="text"
                            name="facebook"
                            onChange={onChange}
                            value={data.facebook}
                            id="facebook"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label htmlFor="instagram">Instagram</label>
                        <input
                            type="text"
                            name="instagram"
                            onChange={onChange}
                            value={data.instagram}
                            id="instagram"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label htmlFor="skype">Skype</label>
                        <input
                            type="text"
                            name="skype"
                            onChange={onChange}
                            value={data.skype}
                            id="skype"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label htmlFor="twitter">Twitter</label>
                        <input
                            type="text"
                            name="twitter"
                            onChange={onChange}
                            value={data.twitter}
                            id="twitter"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
