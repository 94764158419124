export const getCapacitaciones = async (id) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API}/employercapacitation/capacitation/byEmployer/${id}`,
            {
                method: "GET",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        return response.json();
    } catch (error) {
        console.error(error);
    }
};

export const getIndividualEmployeeByTraining = async (id) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API}/employercapacitation/individual/byEmployer/${id}`,
            {
                method: "GET",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.json();
    } catch (err) {
        console.error(err);
    }
};

export const postCapacitaciones = async (data) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API}/employercapacitation/individual`,
            {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );
        return response.json();
    } catch (error) {
        console.error(error);
    }
};

export const updateCapacitaciones = async (capacitacion_id, data) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API}/employercapacitation/capacitation/progress/${capacitacion_id}`,
            {
                method: "PATCH",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );
        return response;
    } catch (error) {
        console.error(error);
    }
};

