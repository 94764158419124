// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalVacancyReferrer .btn-img {
  opacity: 0.5;
}
.ModalVacancyReferrer .modal-content {
  border-radius: 2rem;
  background-color: rgb(249, 249, 249);
}
.ModalVacancyReferrer .btn-yes {
  border: 2px solid #27E6C4;
  background-color: #27E6C4;
  color: white;
  text-align: center;
  padding-right: 30px;
  padding-left: 30px;
  border-radius: 20px;
  font-size: 18px;
  font-weight: bold;
}
.ModalVacancyReferrer .btn-yes:hover {
  border: 2px solid #0AB4C4;
  background-color: #0AB4C4;
}
.ModalVacancyReferrer select, .ModalVacancyReferrer input {
  height: 36px;
  border: 2px solid #6d68aa !important;
  box-sizing: border-box;
  border-radius: 18px;
  font-family: "Montserrat", sans-serif !important;
  outline: none;
  padding: 0 18px;
  margin: 15px 0;
}`, "",{"version":3,"sources":["webpack://./src/Views/Employee/Components/ModalVacancyReferrer/ModalVacancyReferrer.sass"],"names":[],"mappings":"AACE;EACE,YAAA;AAAJ;AACE;EACE,mBAAA;EACA,oCAAA;AACJ;AAAE;EACE,yBAAA;EACA,yBAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;AAEJ;AADI;EACE,yBAAA;EACA,yBAAA;AAGN;AAFE;EACC,YAAA;EACA,oCAAA;EACA,sBAAA;EACA,mBAAA;EACA,gDAAA;EACA,aAAA;EACA,eAAA;EACA,cAAA;AAIH","sourcesContent":[".ModalVacancyReferrer\n  & .btn-img\n    opacity: .5\n  & .modal-content\n    border-radius: 2rem\n    background-color: rgb(249, 249, 249)\n  & .btn-yes\n    border: 2px solid #27E6C4\n    background-color: #27E6C4\n    color: white\n    text-align: center\n    padding-right: 30px\n    padding-left: 30px\n    border-radius: 20px\n    font-size: 18px\n    font-weight: bold\n    &:hover\n      border: 2px solid #0AB4C4\n      background-color: #0AB4C4\n  & select, input\n   height: 36px\n   border: 2px solid #6d68aa !important\n   box-sizing: border-box\n   border-radius: 18px\n   font-family: \"Montserrat\", sans-serif !important\n   outline: none\n   padding: 0 18px\n   margin: 15px 0"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
