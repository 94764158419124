import { useEffect, useState } from 'react'
import { deleteCompanyProducts, getAssetsCompany, getCompanyProducts, getDataCompany, postAssetsCompany, postCompanyProducts, putCompany, putCompanyProducts } from '../../../../API/Services/Company/Company'

import ModalImagem from './ModalImagem/ModalImagem'

import { AiFillEdit, AiFillFacebook, AiOutlineCloseCircle, AiOutlineDownload, AiOutlineMail, AiOutlinePlusCircle } from 'react-icons/ai'
import { BiBarChart, BiMapPin } from 'react-icons/bi'
import { BsArrowsFullscreen, BsCardImage, BsGlobe, BsInstagram, BsLinkedin, BsPeople, BsTelephone, BsTwitter, BsYoutube } from 'react-icons/bs'
import { HiOutlineIdentification } from 'react-icons/hi'

import { FormattedMessage } from 'react-intl'
import { modalAssignTranslate, myCompanyTranslate } from '../../../../i18n/translatedMessages'
import ModalLinks from './ModalLinks/ModalLinks'
import './perfilIndustry.sass'

const PerfilIndustry = () => {
	const imgEmpty = {
		originalName: '',
		alias: '',
	}
	const dataEmpresaEmpty = {
		name: '',
		description: '',
		salary: '',
		address: '',
		sizeId: null,
		dotacion: '',
		email: '',
		phone: '',
		website: '',
		linkedin: '',
		instagram: '',
		facebook: '',
		youtube: '',
		twitter: '',
		youtubelink: 'teste',
		pdfdocument: '',
	}
	const [img, setImg] = useState('')
	const [dataImg, setDataImg] = useState(imgEmpty)
	const [showImage, setShowImage] = useState(false)
	const [showModalLinks, setShowModalLinks] = useState(false)

	const [dataEmpresa, setDataEmpresa] = useState(dataEmpresaEmpty)

	const [products, setProducts] = useState([])

	useEffect(() => {
		const user = JSON.parse(localStorage.getItem('user'))
		const requests = async () => {
			const imgAux = await getAssetsCompany(user.id)
			if (imgAux.length) {
				const filtImage = imgAux.filter(img => img.feature === 'Profile')
				setImg(filtImage[filtImage.length - 1]?.url)
			}
		}
		requests()
		handleDataCompany()
		handleDataProducts()
	}, [])

	const handleDataCompany = async () => {
		const dataLocal = localStorage.getItem('user')
		const dataCompany = JSON.parse(dataLocal)
		const res = await getDataCompany(dataCompany.id)
		if (res) {
			setDataEmpresa({
				...dataEmpresa,
				name: res.name || '',
				description: res.description || '',
				salary: res.salary || '',
				address: res.address || '',
				sizeId: res.sizeId || null,
				dotacion: res.companiesEmployer.length.toString() || '',
				email: res.email || '',
				phone: res.phone || '',
				website: res.website || '',
				linkedin: res.linkedin || '',
				instagram: res.instagram || '',
				facebook: res.facebook || '',
				youtube: res.youtube || '',
				twitter: res.twitter || '',
				youtubelink: res.youtubelink || '',
				pdfdocument: res.pdfdocument || '',
			})
		}
	}

	const handleDataProducts = async () => {
		const dataLocal = localStorage.getItem('user')
		const dataCompany = JSON.parse(dataLocal)
		const productsAux = await getCompanyProducts(dataCompany.id)
		if (productsAux) {
			setProducts(productsAux)
		}
	}

	const handleCloseImage = () => {
		setShowImage(false)
	}

	const handleImage = image => {
		setImg(image)
	}

	const loadImage = event => {
		const user = JSON.parse(localStorage.getItem('user'))
		const file = event.target.files[0]
		setImg(file)
		setDataImg({
			...dataImg,
			originalName: file.name,
			alias: user.id + '_' + file.name,
		})
		setShowImage(true)
	}

	const handleSendImage = async imgURL => {
		const user = JSON.parse(localStorage.getItem('user'))
		const sendAux = {
			originalName: dataImg.originalName,
			alias: dataImg.alias,
			companyId: user.id,
			feature: 'Profile',
			file: imgURL,
		}
		await postAssetsCompany(sendAux)
	}

	const addProducts = async () => {
		const user = JSON.parse(localStorage.getItem('user'))
		const aux = [...products]
		if (products.length < 4) {
			aux.push(document.querySelector("input[name='newProduct']").value)
			await postCompanyProducts({
				product: [document.querySelector("input[name='newProduct']").value],
				companyId: user.id,
			})
			document.querySelector("input[name='newProduct']").value = ''
			setProducts(aux)
		}
	}

	const handleChangeProduct = (e, index) => {
		const user = JSON.parse(localStorage.getItem('user'))
		const newObject = [...products]
		newObject[index] = {
			product: e.currentTarget.value,
			companyId: user.id,
		}
		setProducts(newObject)
	}

	const handlePutProducts = async index => {
		const user = JSON.parse(localStorage.getItem('user'))
		const productAux = {
			product: [products[index].product],
			companyId: user.id,
		}
		await putCompanyProducts(productAux, user.id)
	}

	const delProducts = async (index, productId) => {
		const aux = [...products]
		if (index > -1) {
			aux[index] = { product: '' }
			aux.splice(index, 1)
			setProducts([...aux])
		}
		await deleteCompanyProducts(productId)
	}

	const handleChange = e => {
		setDataEmpresa({
			...dataEmpresa,
			[e.currentTarget.name]: e.currentTarget.value,
		})
	}

	const handleSend = async e => {
		const user = JSON.parse(localStorage.getItem('user'))
		await putCompany(dataEmpresa, user.id)
		const dataLocal = localStorage.getItem('user')
		const dataCompany = JSON.parse(dataLocal)
		const res = await getDataCompany(dataCompany.id)
		if (res) {
			setDataEmpresa({
				...dataEmpresa,
				name: res.name || '',
				description: res.description || '',
				salary: res.salary || '',
				address: res.address || '',
				sizeId: res.sizeId || null,
				dotacion: res.companiesEmployer.length.toString() || '',
				email: res.email || '',
				phone: res.phone || '',
				website: res.website || '',
				linkedin: res.linkedin || '',
				instagram: res.instagram || '',
				facebook: res.facebook || '',
				youtube: res.youtube || '',
				twitter: res.twitter || '',
				youtubelink: res.youtubelink || '',
				pdfdocument: res.pdfdocument || '',
			})
		}
	}

	const handleShowModalLink = () => {
		setShowModalLinks(true)
	}
	const handleCloseModalLink = () => {
		setShowModalLinks(false)
	}
	const handleSendYoutubeLink = async pdfLink => {
		const user = JSON.parse(localStorage.getItem('user'))
		setDataEmpresa({ ...dataEmpresa, pdfdocument: pdfLink })
		await putCompany(dataEmpresa, user.id)
		setShowModalLinks(false)
	}

	return (
		<>
			<ModalImagem show={showImage} handleClose={handleCloseImage} setImage={setImg} handleImage={handleImage} image={img} handleSend={handleSendImage} />
			<ModalLinks show={showModalLinks} handleClose={handleCloseModalLink} data={dataEmpresa} handleChange={handleChange} handleSendYoutubeLink={handleSendYoutubeLink} />
			<div className='container-fluid perfil-industry'>
				<div className='row justify-content-center g-5'>
					<div className='col-md-7'>
						<h4>{myCompanyTranslate.companyDetails}</h4>
						<div className='row row-cols-2 my-4'>
							<div className='col-md-5 ps-0'>
								<div className='content-img p-3 shadow'>
									<input type='file' className='d-none' name='' id='imageLoad' onChange={loadImage} />
									<div className='img'>
										<label className='label-image text-center cursor-pointer' htmlFor='imageLoad'>
											{img ? (
												<img src={img} alt='industry' className='img-fluid' />
											) : (
												<>
													<BsCardImage size={35} /> <h5 className='pt-3'>{myCompanyTranslate.companyLogo}</h5>
												</>
											)}
										</label>
									</div>
								</div>
							</div>
							<div className='offset-md-1 col-md-6'>
								<div className='row row-cols-1 gy-3 gy-lg-4'>
									<div className='col p-0 shadow'>
										<div className='input-group'>
											<span className='input-group-text' id='nameIconForInput'>
												<HiOutlineIdentification className='color-purple' size={20} />
											</span>
											<FormattedMessage id='companyName'>
												{msg => (
													<input
														name='name'
														onChange={handleChange}
														type='text'
														className='form-control px-2 perfilIndustry-input'
														placeholder={msg}
														aria-label='Name'
														aria-describedby='nameIconForInput'
														value={dataEmpresa?.name}
													/>
												)}
											</FormattedMessage>
										</div>
									</div>
									<div className='col p-0 shadow'>
										<div className='input-group'>
											<FormattedMessage id='companyDescription'>
												{msg => (
													<textarea
														name='description'
														onChange={handleChange}
														rows='6'
														className='form-control px-2 perfilIndustry-input'
														placeholder={msg}
														aria-label='Description'
														aria-describedby='descriptionIconForInput'
														value={dataEmpresa?.description}
													/>
												)}
											</FormattedMessage>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='row row-cols-2 g-3 gx-lg-4 mb-4'>
							<div className='col p-0 shadow'>
								<div className='input-group'>
									<span className='input-group-text color-purple' id='annualRevenuesIconForInput'>
										<BiBarChart size={20} />
									</span>
									<FormattedMessage id='companyAnualBilling'>
										{msg => (
											<input
												name='salary'
												onChange={handleChange}
												type='text'
												className='form-control px-2 perfilIndustry-input'
												placeholder={msg}
												aria-label='Faturamento Anual'
												aria-describedby='annualRevenuesIconForInput'
												value={dataEmpresa?.salary}
											/>
										)}
									</FormattedMessage>
								</div>
							</div>

							<div className='col p-0 shadow'>
								<div className='input-group'>
									<span className='input-group-text color-purple' id='locationIconForInput'>
										<BiMapPin size={20} />
									</span>
									<FormattedMessage id='companyLocation'>
										{msg => (
											<input
												name='address'
												onChange={handleChange}
												type='text'
												className='form-control px-2 perfilIndustry-input'
												placeholder={msg}
												aria-label='Localização'
												aria-describedby='locationIconForInput'
												value={dataEmpresa?.address}
											/>
										)}
									</FormattedMessage>
								</div>
							</div>
							<div className='col p-0 shadow'>
								<div className='input-group'>
									<span className='input-group-text color-purple' id='companySizeIconForInput'>
										<BsArrowsFullscreen size={18} />
									</span>
									<select name='sizeId' className='form-select perfilIndustry-input' aria-label='' onChange={handleChange} value={dataEmpresa?.sizeId ?? ''}>
										<FormattedMessage id='companySize'>{msg => <option>{msg}</option>}</FormattedMessage>
										<FormattedMessage id='companySize1'>{msg => <option value={1}>{msg}</option>}</FormattedMessage>
										<FormattedMessage id='companySize2'>{msg => <option value={2}>{msg}</option>}</FormattedMessage>
										<FormattedMessage id='companySize3'>{msg => <option value={3}>{msg}</option>}</FormattedMessage>
									</select>
								</div>
							</div>
							<div className='col p-0 shadow'>
								<div className='input-group'>
									<span className='input-group-text color-purple' id='empleadosIconForInput'>
										<BsPeople size={20} />
									</span>
									<FormattedMessage id='companyEmployeeAmount'>
										{msg => (
											<input
												name='dotacion'
												onChange={handleChange}
												type='text'
												className='form-control px-2 perfilIndustry-input'
												placeholder={msg}
												aria-label='Numero empleados MyDNA'
												aria-describedby='empleadosIconForInput'
												value={dataEmpresa?.dotacion > 0 ? dataEmpresa?.dotacion : ''}
											/>
										)}
									</FormattedMessage>
								</div>
							</div>
						</div>
						<h4>{myCompanyTranslate.companyContact}</h4>
						<div className='row row-cols-2 row-cols-lg-3 g-2 g-lg-3 gy-3 gy-lg-4 mt-2 perfilIndustry-networkInputs'>
							<div className='col p-0 shadow'>
								<div className='input-group'>
									<span className='input-group-text color-purple' id='emailIconForInput'>
										<AiOutlineMail size={20} />
									</span>
									<input name='email' onChange={handleChange} type='text' className='form-control px-2 perfilIndustry-input' placeholder='E-mail' aria-label='Email' aria-describedby='emailIconForInput' value={dataEmpresa?.email} />
								</div>
							</div>
							<div className='col p-0 shadow'>
								<div className='input-group'>
									<span className='input-group-text color-purple' id='phoneIconForInput'>
										<BsTelephone size={20} />
									</span>
									<FormattedMessage id='companyPhone'>
										{msg => (
											<input
												name='phone'
												onChange={handleChange}
												type='text'
												className='form-control px-2 perfilIndustry-input'
												placeholder={msg}
												aria-label='Phone'
												aria-describedby='phoneIconForInput'
												value={dataEmpresa?.phone}
											/>
										)}
									</FormattedMessage>
								</div>
							</div>
							<div className='col p-0 shadow'>
								<div className='input-group'>
									<span className='input-group-text color-purple' id='webPageIconForInput'>
										<BsGlobe size={20} />
									</span>
									<FormattedMessage id='companyWebPage'>
										{msg => (
											<input
												name='website'
												onChange={handleChange}
												type='text'
												className='form-control px-2 perfilIndustry-input'
												placeholder={msg}
												aria-label='Página Web'
												aria-describedby='webPageIconForInput'
												value={dataEmpresa?.website}
											/>
										)}
									</FormattedMessage>
								</div>
							</div>
							<div className='col p-0 shadow'>
								<div className='input-group'>
									<span className='input-group-text color-blue' id='linkedinIconForInput'>
										<BsLinkedin size={20} />
									</span>
									<input
										name='linkedin'
										onChange={handleChange}
										type='text'
										className='form-control px-2 perfilIndustry-input'
										placeholder='LinkedIn'
										aria-label='LinkedIn'
										aria-describedby='linkedinIconForInput'
										value={dataEmpresa?.linkedin}
									/>
								</div>
							</div>
							<div className='col p-0 shadow'>
								<div className='input-group'>
									<span className='input-group-text color-blue' id='instagramIconForInput'>
										<BsInstagram size={20} />
									</span>
									<input
										name='instagram'
										onChange={handleChange}
										type='text'
										className='form-control px-2 perfilIndustry-input'
										placeholder='Instagram'
										aria-label='Instagram'
										aria-describedby='instagramIconForInput'
										value={dataEmpresa?.instagram}
									/>
								</div>
							</div>
							<div className='col p-0 shadow'>
								<div className='input-group'>
									<span className='input-group-text color-blue' id='facebookIconForInput'>
										<AiFillFacebook size={20} />
									</span>
									<input
										name='facebook'
										onChange={handleChange}
										type='text'
										className='form-control px-2 perfilIndustry-input'
										placeholder='Facebook'
										aria-label='Facebook'
										aria-describedby='facebookIconForInput'
										value={dataEmpresa?.facebook}
									/>
								</div>
							</div>
							<div className='col p-0 shadow'>
								<div className='input-group'>
									<span className='input-group-text color-blue' id='youtubeIconForInput'>
										<BsYoutube size={20} />
									</span>
									<input
										name='youtube'
										onChange={handleChange}
										type='text'
										className='form-control px-2 perfilIndustry-input'
										placeholder='Youtube'
										aria-label='Youtube'
										aria-describedby='youtubeIconForInput'
										value={dataEmpresa?.youtube}
									/>
								</div>
							</div>
							<div className='col p-0 shadow'>
								<div className='input-group'>
									<span className='input-group-text color-blue' id='twitterIconForInput'>
										<BsTwitter size={20} />
									</span>
									<input
										name='twitter'
										onChange={handleChange}
										type='text'
										className='form-control px-2 perfilIndustry-input'
										placeholder='Twitter'
										aria-label='Twitter'
										aria-describedby='twitterIconForInput'
										value={dataEmpresa?.twitter}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className='col-md-4'>
						<h4 className='mb-4'>{myCompanyTranslate.companyProducts}</h4>
						<div className='row row-cols-1 shadow'>
							{products?.map((item, index) => (
								<div className='col' key={index}>
									<div className='input-group py-2'>
										<input type='text' name='product' onChange={e => handleChangeProduct(e, index)} className='form-control m-0 perfilIndustry-input' value={item.product || item} aria-describedby='button-del' />
										<button className='btn color-purple pe-0' onClick={() => handlePutProducts(index)}>
											<AiFillEdit className='color-purple' size={20} />
										</button>
										<button className='btn color-purple pe-0' type='button' id='button-del' onClick={() => delProducts(index, item.id)}>
											<AiOutlineCloseCircle size={25} />
										</button>
									</div>
								</div>
							))}
							<div className='input-group py-2'>
								<FormattedMessage id='companyAddProduct'>
									{msg => <input type='text' name='newProduct' className='form-control m-0 perfilIndustry-input border' placeholder={msg} aria-label='Add Product' aria-describedby='button-add' />}
								</FormattedMessage>
								<button className='btn color-purple pe-0' type='button' id='button-add' onClick={addProducts}>
									<AiOutlinePlusCircle size={25} />
								</button>
							</div>
						</div>

						<h4 className='p-0 my-4'>{myCompanyTranslate.companyContent}</h4>
						<div className='row row-cols-1 shadow cursor-pointer' onClick={handleShowModalLink}>
							<div className='col text-center p-4'>
								<AiOutlineDownload className='d-block m-auto color-purple' size={50} />
								{myCompanyTranslate.companyUpVideos}
							</div>
							<div className='col text-center'>
								{dataEmpresa?.youtubelink && (
									<a className='fs-6' href={dataEmpresa?.youtubelink}>
										YouTube Link
									</a>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className='row pb-3 justify-content-center'>
					<div className='col-11 text-end'>
						<button className='btn btnPerfilIndustry rounded-pill' onClick={handleSend}>
							{modalAssignTranslate.btnSave}
						</button>
					</div>
				</div>
			</div>
		</>
	)
}
export default PerfilIndustry
