import { Modal } from 'react-bootstrap'
import { MdError } from 'react-icons/md'

import './modal.sass'

const ResponseModal = ({ setShow, show }) => {
    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            size="lg"
            backdrop="static"
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="modal-response"
        >
            <Modal.Title className="title">
                <MdError size={30} />
                <span>Ops!!! Algo aconteceu...</span>
            </Modal.Title>
            <Modal.Body></Modal.Body>
            <Modal.Footer>
                <button onClick={() => setShow(false)}> Cerrar </button>
            </Modal.Footer >
        </Modal>

    )
}
export default ResponseModal