import { useEffect, useState } from 'react';
import { BiChat } from 'react-icons/bi';
import { BsMegaphone } from 'react-icons/bs';
import { FaToolbox } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { getDataCompany, putCompany } from '../../API/Services/Company/Company';
import { internalChannel, internalVacancies } from '../../i18n/translatedMessages';
import { useIntl } from "react-intl";
import { translate } from "../../i18n/Library/industry_type";

import WheelServices from '../Services/Components/Whell/WheelServices';
import ModalEditLinks from './Components/ModalEditLinks';

import './myCompany.sass';

const dataCompanyEmpty = {
  name: '',
  description: '',
  salary: '',
  address: '',
  sizeId: null,
  dotacion: '',
  email: '',
  phone: '',
  website: '',
  linkedin: '',
  instagram: '',
  facebook: '',
  youtube: '',
  twitter: '',
  youtubelink: '',
  pdfdocument: '',
};

const MyCompany = (props) => {
  const getLanguage = useIntl();
  const language = getLanguage.locale === "PT-BR" ? "pt" : "es";
  const [showModalEditLinks, setShowModalEditLinks] = useState(false);
  const [companyInfo, setCompanyInfo] = useState([dataCompanyEmpty]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    const requests = async () => {
      setCompanyInfo(await getDataCompany(user.id));
    };
    requests();
  }, []);

  const handleCloseModalEditLinks = () => {
    setShowModalEditLinks(false);
  };
  const handleShowModalEditLinks = () => {
    setShowModalEditLinks(true);
  };

  const handleChange = (event) => {
    setCompanyInfo({
      ...companyInfo,
      [event.target.name]: event.target.value,
    });
  };

  const handleSend = async (e) => {
    const user = JSON.parse(localStorage.getItem('user'));
    await putCompany(companyInfo, user.id);
    const res = await getDataCompany(user.id);
    if (res) {
      setCompanyInfo({ ...companyInfo, name: res?.name || '', description: res.description || '', salary: res.salary || '', address: res.address || '', sizeId: res.sizeId || null, dotacion: res.companiesEmployer.length.toString() || '', email: res.email || '', phone: res.phone || '', website: res.website || '', linkedin: res.linkedin || '', instagram: res.instagram || '', facebook: res.facebook || '', youtube: res.youtube || '', twitter: res.twitter || '', youtubelink: res.youtubelink || '', pdfdocument: res.pdfdocument || '' });
    }
    setShowModalEditLinks(false);
  };

  return (
    <div className="container-fluid myCompanyMain">
      <div className="row">
        <div className="col-md-5">
          <h3>{companyInfo?.name}</h3>
          <h5 className="color-grey">{translate[language][companyInfo?.companyIndustryType?.name]}</h5>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <WheelServices />
        </div>
      </div>
      <div className="row wheelCompanyPage-last-row-adjust">
        <div className="col-md-2">
          <ul className="list-group wheelCompanyPage-list ms-2">
            <li className="list-group-item px-0 py-0 border-0">
              <ModalEditLinks show={showModalEditLinks} handleShow={handleShowModalEditLinks} handleClose={handleCloseModalEditLinks} data={companyInfo} onChange={handleChange} handleSend={handleSend} />
            </li>
            <li className="list-group-item px-0 pb-2 border-0">
              <a href={companyInfo?.instagram ? companyInfo?.instagram : null} target="_blank" rel="noreferrer">
                <img className="img-fluid" alt="" src={companyInfo?.instagram ? 'https://assets.mydna.company/b2b-images/employeeWheelScreen-icon_instagram.svg' : 'https://assets.mydna.company/b2b-images/employeeWheelScreen-icon-no_instagram.svg'} /> {companyInfo?.instagram}
              </a>
            </li>
            <li className="list-group-item px-0 py-2 border-0">
              <a href={companyInfo?.linkedin ? companyInfo?.linkedin : null} target="_blank" rel="noreferrer">
                <img className="img-fluid" alt="" src={companyInfo?.linkedin ? 'https://assets.mydna.company/b2b-images/employeeWheelScreen-icon_linkedin.svg' : 'https://assets.mydna.company/b2b-images/employeeWheelScreen-icon-no_linkedin.svg'} /> {companyInfo?.linkedin}
              </a>
            </li>
            <li className="list-group-item px-0 py-2 border-0">
              <a href={companyInfo?.phone ? companyInfo?.phone : null} target="_blank" rel="noreferrer">
                <img className="img-fluid" alt="" src={companyInfo?.phone ? 'https://assets.mydna.company/b2b-images/employeeWheelScreen-icon_whatsapp.svg' : 'https://assets.mydna.company/b2b-images/employeeWheelScreen-icon-no_whatsapp.svg'} /> {companyInfo?.phone}
              </a>
            </li>
            <li className="list-group-item px-0 py-2 border-0">
              <a href={companyInfo?.mailPersonal ? 'mailto:' + companyInfo?.mailPersonal : null} target="_blank" rel="noreferrer">
                <img className="img-fluid" alt="" src={companyInfo?.email ? 'https://assets.mydna.company/b2b-images/employeeWheelScreen-icon_email.svg' : 'https://assets.mydna.company/b2b-images/employeeWheelScreen-icon-no_email.svg'} /> {companyInfo?.email}
              </a>
            </li>
          </ul>
        </div>

        <div className="offset-md-5 col-md-5 text-end">
          <div className="py-3">
            <Link className="btn-internal-vancancies py-2 me-2" to={{ pathname: '/app/services/internal-vacancies-list', option: 14 }}>
              <FaToolbox size={25} /> {internalVacancies.internalVacanciesTitle}
            </Link>
          </div>
          <div className="py-3">
            <Link className="btn-internal-comunication py-2 me-2" to={{ pathname: '/app/services/internal-channel', option: 10 }}>
              <BiChat size={30} /> {internalChannel.internalChannelTitle}
            </Link>
          </div>
          <div className="py-3">
            <Link className="btn-canal-denuncia py-2 me-2" to={{ pathname: '/app/services/support', option: 13 }}>
              <BsMegaphone size={25} /> Suporte
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MyCompany;
