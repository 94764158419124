// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox-services {
  cursor: pointer;
  margin: 3px 0;
}
.checkbox-services svg {
  fill: #0b95cf;
  margin: 0 10px;
}
.checkbox-services:hover {
  color: #5f56c9;
}`, "",{"version":3,"sources":["webpack://./src/Views/Services/Components/Checkbox/checkbox.sass"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,aAAA;AACJ;AAAI;EACI,aAAA;EACA,cAAA;AAER;AAAI;EACI,cAAA;AAER","sourcesContent":[".checkbox-services\n    cursor: pointer\n    margin: 3px 0\n    & svg\n        fill: #0b95cf\n        margin: 0 10px\n\n    &:hover\n        color: #5f56c9\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
