import axios from 'axios';

export const getSalary = async (id) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API}/salary/${id}`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            // Si la respuesta no es exitosa, manejar el error
            throw new Error(`Error al obtener el salario. Código: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error(error);
    }
};
