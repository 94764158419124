export const postCompanyMedia = async (data) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API}/companymedia`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};

export const putCompanyMedia = async (data, mediaId) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_API}/companymedia/${mediaId}`,
      {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};

export const getCompanyMedia = async (companyId) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_API}/companymedia/${companyId}`
    );
    return resp.json();
  } catch (err) {
    console.error(err);
  }
};
