// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-cultural-bar {
  position: relative;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}
.content-cultural-bar svg {
  position: relative;
  width: 150px;
  height: 150px;
}
.content-cultural-bar svg circle {
  width: 125px;
  height: 125px;
  fill: none;
  stroke-width: 8;
  stroke: #000;
  transform: translate(5px, 5px);
  stroke-dasharray: 440;
  stroke-dashoffset: 440;
  stroke-linecap: round;
}
.content-cultural-bar svg circle:nth-child(1) {
  stroke-dashoffset: 0;
  stroke: #d3d3d3;
}
.content-cultural-bar svg circle:nth-child(2) {
  stroke-width: 18;
  transform: rotate(-90deg) translate(-145px, 5px);
}
.content-cultural-bar .children {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-cultural-bar .children img {
  width: 65px;
  height: 65px;
}

.cultura-bar-number h2 {
  font-size: 32px;
  font-weight: bold;
}
.cultura-bar-number span {
  font-size: 24px;
}`, "",{"version":3,"sources":["webpack://./src/Views/Analytics/Components/CulturaBar/culturaBar.sass"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,qBAAA;EACA,sBAAA;AACJ;AACI;EACI,kBAAA;EACA,YAAA;EACA,aAAA;AACR;AACQ;EACI,YAAA;EACA,aAAA;EACA,UAAA;EACA,eAAA;EACA,YAAA;EACA,8BAAA;EACA,qBAAA;EACA,sBAAA;EACA,qBAAA;AACZ;AACY;EACI,oBAAA;EACA,eAAA;AAChB;AACY;EACI,gBAAA;EACA,gDAAA;AAChB;AACI;EACI,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACR;AACQ;EACI,WAAA;EACA,YAAA;AACZ;;AAEI;EACI,eAAA;EACA,iBAAA;AACR;AACI;EACI,eAAA;AACR","sourcesContent":[".content-cultural-bar\n    position: relative\n    width: 150px\n    height: 150px\n    display: flex\n    justify-content: center\n    align-content: center\n    flex-direction: column\n\n    & svg\n        position: relative\n        width: 150px\n        height: 150px\n\n        & circle\n            width: 125px\n            height: 125px\n            fill: none\n            stroke-width: 8\n            stroke: #000\n            transform: translate(5px, 5px)\n            stroke-dasharray: 440\n            stroke-dashoffset: 440\n            stroke-linecap: round\n\n            &:nth-child(1)\n                stroke-dashoffset: 0\n                stroke: #d3d3d3\n\n            &:nth-child(2)\n                stroke-width: 18\n                transform: rotate(-90deg) translate(-145px, 5px)\n\n    & .children\n        position: absolute\n        top: 0\n        left: 0\n        width: 100%\n        height: 100%\n        display: flex\n        justify-content: center\n        align-items: center\n\n        & img\n            width: 65px\n            height: 65px\n\n.cultura-bar-number\n    & h2\n        font-size: 32px\n        font-weight: bold\n\n    & span\n        font-size: 24px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
