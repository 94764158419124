import { useCallback, useState, useEffect } from "react";
import Bands from "../../../../../../API/Services/PayBalance/Bands";
import { Tooltip } from "./tolltip";
import { TransformNumber } from "../../../../../../Utils/TransformNumbers";
import Loading from "../../../../../../Components/Loading/Loading";

export const BandCharts = ({ id }) => {
	const [employee, setEmployee] = useState([]);
	const [salaryMean, setSalaryMean] = useState(0);
	const [loading, setLoading] = useState(false);
	const [showTooltip, setShowTooltip] = useState(null);

	const load = useCallback(async () => {
		try {
			setLoading(true);
			const response = await Bands.getEmployeeByBand(id);
			const [item] = response;
			setSalaryMean(item.company_salary_mean);

			setEmployee(
				response.map((item) => {
					const salaryMeanPercentagem =
						item.company_salary_mean * 1.2 -
						item.company_salary_mean * 0.8;

					const salaryEmployeePercentagem =
						item.employee_salary - item.company_salary_mean * 0.8;

					const position = Math.ceil(
						(salaryEmployeePercentagem / salaryMeanPercentagem) *
							100
					);

					const diference =
						((item.company_salary_mean - item.employee_salary) *
							100) /
						item.company_salary_mean;

					return {
						...item,
						position,
						diference,
					};
				})
			);
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		load();
	}, [load]);

	return (
		<>
			{loading && <Loading />}
			{!loading && (
				<div className="px-5 mb-3">
					<div className="row">
						<div className="col">
							<h3 className="fw-bold">Gráfico</h3>
						</div>
					</div>
					<div className="row mt-3">
						<div className="col fs-6 d-flex position-relative">
							<span
								className="position-absolute fw-bold mb-2"
								style={{
									left: "60%",
									transform: "translateX(-50%)",
								}}
							>
								Punto Medio Banda
							</span>
						</div>
					</div>
					<div className="row">
						<div className="col fs-6 py-2 text-end">
							{employee.length} Colaboradores
						</div>
					</div>
					<div className="row">
						<div className="col border border-1 rounded-3 position-relative">
							<div className="row">
								<div className="col-2 py-3 bg-light-grey text-center border-end border-1">
									<span className="fw-bold fs-6">
										Colaboradores
									</span>
								</div>
								<div className="col-1 py-3 text-center border-end border-1"></div>
								<div className="col-2 py-3 text-center border-end border-1">
									Q1
								</div>
								<div className="col-2 py-3 text-center border-end border-1">
									Q2
								</div>
								<div className="col-2 py-3 text-center border-end border-1">
									Q3
								</div>
								<div className="col-2 py-3 text-center border-end border-1">
									Q4
								</div>
								<div className="col-1 py-3 text-center"></div>
							</div>

							{employee.map((item) => (
								<div
									className="row position-relative"
									key={item.id}
								>
									<div className="col py-2 bg-light-grey text-center border-end border-1">
										<span>{item.employer_name}</span>
									</div>
									<div className="col-1 py-2 border-end border-1"></div>
									<div className="col py-2 border-end border-1"></div>
									<div className="col py-2 border-end border-1"></div>
									<div className="col py-2 border-end border-1"></div>
									<div className="col py-2 border-end border-1"></div>
									<div className="col-1 py-2 "></div>
									<div
										className="position-absolute m-0 p-0"
										style={{
											width: "100%",
											height: "100%",
										}}
									>
										<div
											style={{
												marginLeft: "25%",
												width: "66.666666666%",
												height: "100%",
											}}
											className="row"
										>
											<div className="col d-flex align-items-center position-relative">
												<hr
													rule="button"
													onMouseEnter={() =>
														setShowTooltip(item.id)
													}
													onMouseLeave={() =>
														setShowTooltip(null)
													}
													className={`border-4 position-absolute`}
													style={{
														cursor: "pointer",
														borderColor:
															item.position > 50
																? "#25e6c4"
																: item.position >
																  25
																? "#f59e0b"
																: "#f84228",
														opacity: 1,
														margin: 0,
														padding: 0,
														width: `${Math.abs(
															Math.abs(
																item.position
															) > 100
																? 102 - 50
																: item.position <
																  0
																? 52
																: item.position -
																  50
														)}%`,
														left: `${
															item.position < 0
																? -2
																: item.position <
																  50
																? item.position
																: 50
														}%`,
													}}
												/>
												<div
													onMouseEnter={() =>
														setShowTooltip(item.id)
													}
													onMouseLeave={() =>
														setShowTooltip(null)
													}
													style={{
														cursor: "pointer",
														width: 12,
														height: 12,
														background:
															item.position > 50
																? "#25e6c4"
																: item.position >
																  25
																? "#f59e0b"
																: "#f84228",
														left: `calc(${
															item.position < 0
																? -2
																: item.position >
																  100
																? 102
																: item.position
														}% - 6px)`,
													}}
													className={`d-flex rounded-circle position-absolute`}
												>
													{showTooltip ===
														item.id && (
														<Tooltip item={item} />
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							))}

							<div className="row border-top">
								<div
									className="col-12 position-relative"
									style={{ height: 30 }}
								>
									<div
										className="position-absolute fs-small"
										style={{
											left: "25%",
											bottom: 5,
											transform: "translateX(-50%)",
										}}
									>
										{TransformNumber(
											Math.ceil(salaryMean * 0.8)
										)}
									</div>
									<div
										className="position-absolute fs-small"
										style={{
											left: "42%",
											bottom: 5,
											transform: "translateX(-50%)",
										}}
									>
										{TransformNumber(
											Math.ceil(salaryMean * 0.9)
										)}
									</div>
									<div
										className="position-absolute fs-small"
										style={{
											left: "58.333333334%",
											bottom: 5,
											transform: "translateX(-50%)",
										}}
									>
										{TransformNumber(salaryMean)}
									</div>
									<div
										className="position-absolute fs-small"
										style={{
											left: "75%",
											bottom: 5,
											transform: "translateX(-50%)",
										}}
									>
										{TransformNumber(
											Math.ceil(salaryMean * 1.1)
										)}
									</div>
									<div
										className="position-absolute fs-small"
										style={{
											left: "91.666666667%",
											bottom: 5,
											transform: "translateX(-50%)",
										}}
									>
										{TransformNumber(
											Math.ceil(salaryMean * 1.2)
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
