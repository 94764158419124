import { useState } from "react";
import { MdCheckBoxOutlineBlank, MdCheckBox } from "react-icons/md";
import "./checkbox.sass";

const Checkbox = ({ text }) => {
  const [state, setState] = useState(false);

  const handleCheck = () => {
    setState(!state);
  };

  return (
    <div
      className="checkbox-services d-flex align-items-center"
      onClick={handleCheck}
    >
      {state ? <MdCheckBox size={20} /> : <MdCheckBoxOutlineBlank size={20} />}
      {text}
    </div>
  );
};
export default Checkbox;
