import './toggle.sass'

const ToggleThreePosistions = ({ color, handleToggle, toggle, id }) => {
    return (
        <div className="toggle-three-positions">
            <div className="true" onClick={() => handleToggle(true, id)}></div>
            <div className="null"></div>
            <div className="false" onClick={() => handleToggle(false, id)}></div>
            <div className="btn-toggle" style={{
                background: color,
                left: toggle === true ? 0 : toggle === false ? 30 : 15
            }}></div>
        </div>
    )
}
export default ToggleThreePosistions