class EmployeeMapper {
	toDomain(persistence) {
		return {
			name: persistence.name,
			email: persistence.email,
			country: persistence.country_id,
			region: null,
			position: persistence.jobName,
			level: persistence.level?.id,
			area: persistence.area?.id,
			subarea: persistence.SubAreas?.name,
			english: persistence.english_level_id,
			training: persistence.qualification_id,
			experience: persistence.seniority_id,
			salary: persistence.salary,
			variableSalary: persistence.variable_salary,
		}
	}

	toPersistence(domain) {
		return {
			name: domain.name,
			email: domain.email,
			countryId: `${domain.country}`,
			profileImg: 'https://raw.githubusercontent.com/Ashwinvalento/cartoon-avatar/master/lib/images/male/45.png',
			companyId: domain.companyId,
			levelId: domain.level,
			areaId: domain.area,
			jobId: domain.position,
			subAreaId: domain.subareaName || '',
			englishLevel: `${domain.english || 0}`,
			seniorityLevel: `${domain.experience || 0}`,
			gradeLevel: `${domain.training || 0}`,
			salary: domain.salary || 0,
			smtmCompanyId: domain.smtmCompanyId
		}
	}
}
export default new EmployeeMapper()

/** VINDO DO BACK SMTM
	area_id:102
	city_id:2
	company_id:149242
	comparative:null
	country_id:2
	created_at:"2023-02-03T11:27:14.000-03:00"
	current_salary:4000
	english_level_id:3
	group:"Jefe"
	id:2836
	job_id:null
	job_name:"Gerente de TI"
	mydna_email_position:"juantest@mydnadigital.com"
	mydna_photo_position:"https://assets.mydna.company/profile-placeholder.png"
	mydna_user_name_position:"Juan Manuel"
	qualification_id:3
	salary_market:null
	salary_position:null
	seniority_id:null
	subArea:"Ingeniería"
	updated_at:"2023-07-05T10:32:16.000-04:00"
	variable_salary:2
*/

/** VINDO DO BACK
	x SubAreas:{ name: 'TI' }
	* area: {id: 8, profileArea: 'Tecnología de la información', affineArea1: 'Ingeniería', affineArea2: '', affineArea3: '', …}
	- asset: "https://mydna-dev-data-pri-us-ea-1.s3.amazonaws.com/documents/Profile/image/64450/1340/istockphoto-1300512215-612x612.jpg_1340_photoprofile?AWSAccessKeyId=ASIAUQIGQPAVJO7XR3PG&Expires=1717699329&Signature=dQE64D3HNcYavzIPFjun4ZJ%2ByHk%3D&X-Amzn-Trace-Id=Root%3D1-666201d4-1a7c19fe3017117377b731b6%3BParent%3D1a909d697c5a8fed%3BSampled%3D0%3BLineage%3D1a48ea37%3A0&x-amz-security-token=IQoJb3JpZ2luX2VjEFMaCXVzLWVhc3QtMSJHMEUCIQCNBB4%2BIHWRR1%2BFlxhik50RdJf2LImUSwC27jw5N9VVjgIgPFw5nxaa16HlajFY%2B6%2BcT5%2BLSCIa9G0cPzRxGD82tO4qjQMI3P%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgwzMDk3ODgxNzg0NzQiDJ3ogm7PFPychJ72zirhAt6tDbJ%2BQUbOh4OqkGJbPZOpGGR5LAVTjHlcMe4Bilymuxb2ZQjfc%2FsKjw3diofY35PLksNJLT7KZ%2F%2B7y7jQBqP6%2F0zWnYDSYOH28KYCv5ih2tqWTRDSMhAdudG%2FCtn8P6atvDQ%2BcrWTnI0mZuTN2Tl1hG3avvU%2FSdwO4w5RfqtUD6zn%2BttzlvD7Hj2NV7veqp%2BVU%2BwkoYavWqAmls86K5T1z3j%2B8mekXY8ItwzYL5pZHDXpyfNV%2BVhwUbt3RCN1hEAhvJE%2B2YgHu1NLc0Idq13fzq%2BAvhe%2FA9hyBEU7umau7sjiGEUoQwZPk3I2C6UO3rBReBr76BQn%2BquZOMvATk0%2FC4UbXRSwsPw7T1RaFH0WANJFbzbrDOcSONtnBIUDbRPoROGRCg9wPoXa01ewX9zn7PDV3FmC63VkrzUB8do1GyEF7lYDoGlf7a94FdzNYWZ2UgoYvEfvb4ZYIsX3HMJlMJn%2Fh7MGOp4BoV4tew%2Fl4Uv7gDtlCEPfLWJ0UFqB49aVs%2B6FAiMC%2FRg92KQEojhEljoHgP8hA7i6v5Jeq2gc%2BrFpZACqsfC1J9PrPS2WkCLCyhPZ8S4BvgOs4Zy4v9um01ZLX%2FAKbivkITSFskst6ufnFURjyjpt76aPvfz%2FqhhUZI3L4JrJnszCOMT%2F3w9tbeV39JR5IXJEvAnqPBsCHqQ9dG%2BFwQE%3D"
	* country: "Chile"
	* email: "juantest@mydnadigital.com"
	* englishLevel: "Fluido"
	- id: 1340
	* jobName: "Gerente de TI"
	* level: {id: 7, PositionN: 'Subgerentes', PositionNLees1: 'Jefe', PositionNPlus1: 'Gerentes', PositionNPlus2: 'Director', …}
	* name: "Juan Manuel"
*/

/** VINDO DO BACK 
	areaId: 8,
	areaIdMyDNA: 8,
	bonus: null,
	companyEmployers: { name: 'Demo MyDNA' },
	companyId: 64450,
	countryId: 1,
	countryIdMyDNA: 1,
	createdAt: "2023-04-04T19:16:37.000Z",
	education: "0",
	employerSubAreas: { name: 'TI' },
	employerUser: { email: 'juantest@mydnadigital.com' },
	employersJob: { name: 'Gerente de TI' },
	englishLevel: "Fluido",
	id: 1340,
	jobId: 363,
	levelId: 7,
	moneyType: null,
	name: "Juan Manuel",
	nivelIdMyDNA: 8,
	profileImg: "https://raw.githubusercontent.com/Ashwinvalento/cartoon-avatar/master/lib/images/male/45.png",
	salary: "0",
	seniority: "0",
	subAreaId: 41,
	updatedAt: "2023-04-04T19:32:05.000Z",
	userId: 1699,
*/

/** MANDANDO PRO BACK
	*name: "",
	*email: "",
	*countryId: "",
	profileImg: "https://raw.githubusercontent.com/Ashwinvalento/cartoon-avatar/master/lib/images/male/45.png",
	companyId: company.id,
	*levelId: null,
	*areaId: null,
	*jobId: "",
	*subAreaId: "",
	*englishLevel: "0",
	*seniorityLevel: "0",
	*gradeLevel: "0",
	*salary: 0,
	smtmCompanyId: 0,
*/