import { useCallback, useState, useEffect } from "react";

import { Modal, Button, Toast, ToastContainer } from "react-bootstrap";
import { MdSearch } from "react-icons/md";
import { getEmployers } from "../../../../API/Analytics/employers";

import DocumentsTemplateService from "../../../../API/Documents/DocumentsTemplate.api";
import DocfilesService from "../../../../API/Documents/Docfiles.api";

import "./ModalShowEmployees.sass";
import Loading from "../../../../Components/Loading/Loading";
import { useIntl } from "react-intl";
import { documentsTranslate } from "../../../../i18n/translatedMessages";

const ModalShowEmployees = ({ isOpen, onClose, template }) => {
  const getLanguage = useIntl();
  const language = getLanguage.locale === "PT-BR" ? "pt" : "es";
  const [documents, setDocuments] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [employeesSelected, setEmployeesSelected] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [toast, setToast] = useState(false);

  const handleEmployeeClick = async (event) => {
    const aux = [...employeesSelected];
    setLoading(true);
    if (event.target.checked) {
      aux.push(Number(event.target.id));
    } else {
      const index = aux.indexOf(Number(event.target.id));
      aux.splice(index, 1);
    }
    setEmployeesSelected(aux);
    setLoading(false);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      await DocfilesService.createDocfile({
        documents,
        employees: employeesSelected,
      });
    } catch (error) {
      setToast(true);
    } finally {
      // add Loading false
      onClose();
    }
  };

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const employeesList = await getEmployers(JSON.parse(localStorage.getItem("user")).id);
      setEmployees(employeesList);

      const documentsList = await DocumentsTemplateService.getAllDocuments(template.id);
      setDocuments(documentsList.map((item) => item.documents_id));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [template.id]);

  useEffect(() => {
    if (isOpen) {
      loadData();
    }
  }, [loadData, isOpen]);

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      size="lg"
      centered={true}
      scrollable={true}
      fullscreen={true}
      className="ModalShowEmployees"
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <ToastContainer className="p-3" position={"bottom-start"} style={{ zIndex: 1 }}>
            <Toast bg="danger" onClose={() => setToast(false)} show={toast} delay={5000} autohide>
              <Toast.Header closeButton={false}>
                <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                <strong className="me-auto">Error</strong>
                <small>{documentsTranslate.msgErrorDoc}</small>
              </Toast.Header>
              <Toast.Body>{documentsTranslate.msgErrorWait}</Toast.Body>
            </Toast>
          </ToastContainer>
          <Modal.Header className="justify-content-between p-4">
            <div className="title">
              <span className="ms-1">TEMPLATE</span>
              <h4 className="mt-2">
                <strong>{template.title}</strong>
              </h4>
            </div>
          </Modal.Header>

          <Modal.Body className="p-4">
            <div className="row">
              <div className="border-1 border rounded p-3 bg-light mt-3">
                <div className="d-flex align-items-center form-control">
                  <MdSearch size={20} />
                  <input
                    type="text"
                    className="ms-2 p-0 m-0 border-0 w-100"
                    placeholder={
                      language === "pt"
                        ? "Filtrar por nome do colaborador"
                        : "Filtrar por nombre de employee"
                    }
                  />
                </div>
                <ul className="list-group list-group-flush scrollable-employees mt-2">
                  {employees.length > 0 &&
                    employees.map((employee) => (
                      <li className="list-group-item mx-2 bg-light d-flex" key={employee.id}>
                        <input
                          className="form-check-input me-2"
                          type="checkbox"
                          id={employee.id}
                          name={employee.name}
                          checked={employeesSelected.includes(employee.id)}
                          onChange={handleEmployeeClick}
                        />
                        <label className="h-100" htmlFor={employee.id}>
                          {employee.name}
                        </label>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer className="justify-content-end p-4">
            <Button className="rounded-pill button-transparent" onClick={() => onClose(false)}>
              {documentsTranslate.btnCancelTemplate}
            </Button>
            <Button className="buttonBlue rounded-pill" onClick={handleSave}>
              {documentsTranslate.btnSaveTemplate}
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};
export default ModalShowEmployees;
