// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-bands {
  position: absolute;
  z-index: 9999;
  background: white;
  border: 1px solid;
  top: 90%;
  right: -5px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
}
.menu-bands .btn-white:hover {
  background: #F1F4F9;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Views/Services/Components/PayBalance/Components/MenuBands/menuBands.sass"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,aAAA;EACA,iBAAA;EACA,iBAAA;EACA,QAAA;EACA,WAAA;EACA,0CAAA;AACJ;AACI;EACI,mBAAA;EACA,eAAA;AACR","sourcesContent":[".menu-bands \r\n    position: absolute\r\n    z-index: 9999\r\n    background: white\r\n    border: 1px solid \r\n    top: 90%\r\n    right: -5px\r\n    box-shadow: 0px 3px 8px rgba(0,0,0, .2)\r\n\r\n    & .btn-white:hover\r\n        background: #F1F4F9\r\n        cursor: pointer\r\n    \r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
