import { useCallback, useEffect, useState } from 'react'
import { HiOutlineArrowNarrowRight, HiCheck, HiMinus } from 'react-icons/hi'
import { HiMiniUserPlus } from 'react-icons/hi2'

import { Form } from '../../../Components/Employee/Form/form.jsx'
import { Descriptor } from '../../../Components/Employee/Descriptor/descriptor.jsx'
import { ModalNoDescriptor } from '../../../Components/Employee/Modal/NoDescriptor.jsx'
import { ModalSuccess } from '../../../Components/Employee/Modal/Success.jsx'
import { ModalError } from '../../../Components/Employee/Modal/Error.jsx'
import Loading from '../../../Components/Loading/Loading.jsx'

import { ValidateNewEmployee } from './validate.js'

import EmplyeeMapper from '../../../Utils/EmplyeeMapper.js'

import { postEmployee } from '../../../API/Employee/Employee.js'
import JobDescription from '../../../API/JobDescription/jobDescription.js'
import { formEETranslate } from '../../../i18n/translatedMessages.js'

export function NewEmployee() {
	const [user, setUser] = useState(null)
	const [errors, setErrors] = useState([])
	const [step, setStep] = useState(0)
	const [descriptor, setDescriptor] = useState(null)

	const [showModalError, setShowModalError] = useState(false)
	const [showSuccess, setShowSuccess] = useState(false)
	const [showNoDescription, setShowNoDescription] = useState(false)
	const [loading, setLoading] = useState(false)
	const [loadingDescriptor, setLoadingDescriptor] = useState(false)

	const getIdSmtmByCompanyName = async () => {
		try {
			const company = JSON.parse(localStorage.getItem('user'))

			const response = await fetch(`https://smtm.co/v1/getCompanyIdFromMyDna?name=${company.name}`)
			const data = await response.json()

			return { companyId: company.id, smtmCompanyId: data }
		} catch {
			setShowModalError(true)
		}
	}

	const loadDescriptor = useCallback(async () => {
		try {
			setLoadingDescriptor(true)
			if (user?.subarea && user?.level) {
				const email = JSON.parse(localStorage.getItem('email'))
				const company = JSON.parse(localStorage.getItem('user'))

				const { data, status } = await JobDescription.get(email, user.subarea, user.level, company.id)

				if (status === 200) {
					setDescriptor(data)
					return
				}
				setDescriptor(null)
			}
		} catch {
			setShowModalError(true)
		} finally {
			setLoadingDescriptor(false)
		}
	}, [user])

	useEffect(() => {
		loadDescriptor()
	}, [loadDescriptor])

	const handleChange = event => {
		setUser({
			...user,
			[event.target.name]: event.target.value,
		})
	}

	const handleNext = () => {
		const { validate, fields } = ValidateNewEmployee(user)

		setErrors(fields)

		if (validate) {
			if (descriptor) {
				setStep(1)
				return
			}

			setShowNoDescription(true)
		}
	}

	const handleSend = async () => {
		try {
			setLoading(true)
			const company = await getIdSmtmByCompanyName()

			const data = {
				...user,
				...company,
			}

			const formattedObject = EmplyeeMapper.toPersistence(data)

			await postEmployee(formattedObject)

			setShowSuccess(true)
		} catch {
			ModalError(true)
		} finally {
			setLoading(false)
		}
	}

	return (
		<>
			<ModalSuccess show={showSuccess} handleShow={setShowSuccess} />
			<ModalNoDescriptor show={showNoDescription} handleShow={setShowNoDescription} handleSend={handleSend} />
			<ModalError show={showModalError} handleShow={setShowModalError} />
			{loading && <Loading />}
			<div className='me-5'>
				<h3 className='fw-bold'>Registrar Colaborador</h3>
				<div className='row my-4'>
					<div className='col flex'>
						<div
							className='flex justify-content-center align-center rounded-circle fw-bold border border-2 border-primary'
							style={{ width: 30, height: 30, fontSize: 14, background: step === 0 ? 'white' : '#7A6BE8', color: step === 0 ? '#7A6BE8' : 'white' }}
						>
							{step === 0 ? '01' : <HiCheck size={22} />}
						</div>
						<span className='text-primary fw-bold'>{formEETranslate.EEpersonalInformation}</span>
						<div style={{ color: step === 1 ? '#7A6BE8' : '#64748B' }}>
							<HiMinus size={32} />
						</div>
						<div
							className='flex justify-content-center align-center rounded-circle'
							style={{ width: 30, height: 30, fontSize: 14, color: step === 1 ? '#7a6be8' : '#64748B', border: step === 1 ? '2px solid #7a6be8' : '1px solid #64748B', fontWeight: step === 1 ? 'bold' : 'normal' }}
						>
							02
						</div>
						<span style={{ fontWeight: step === 1 ? 'bold' : 'normal', color: step === 1 ? '#7A6BE8' : '#64748B' }}>{formEETranslate.EEdescriptionValidator}</span>
					</div>
				</div>

				{step === 0 && (
					<>
						<Form handleChange={handleChange} user={user} setUser={setUser} errors={errors} />
						<div className='button-new-employee'>
							<button className='mt-4 btn btn-primary rounded-pill px-5 flex align-center w-100' onClick={handleNext}>
								{formEETranslate.EEnextStep} <HiOutlineArrowNarrowRight className='ms-1' size={20} />
							</button>
						</div>
					</>
				)}
				{step === 1 && (
					<>
						{loadingDescriptor && <Loading />}
						{!loadingDescriptor && (
							<>
								<Descriptor descriptor={descriptor} user={user} />
								<div className='flex'>
									<button className='mt-4 btn btn-outline-black rounded-pill px-5 flex align-center w-100' onClick={() => setStep(0)}>
										{formEETranslate.EEbackBtn}
									</button>
									<button className='mt-4 btn btn-primary rounded-pill px-5 flex align-center w-100' onClick={handleSend}>
										<HiMiniUserPlus className='me-3' size={22} />
										{formEETranslate.EEregisterColaborator}
									</button>
								</div>
							</>
						)}
					</>
				)}
			</div>
		</>
	)
}
