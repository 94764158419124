export function TransformNumber(number) {
	if (number > 1000 && number < 1000000) {
		const value = (number / 1000).toFixed(1)
		return `${value.endsWith('.0') ? Number(value).toFixed(0) : value}K`
	}
	if (number > 1000000) {
		const value = (number / 1000000).toFixed(1)
		return `${value.endsWith('.0') ? Number(value).toFixed(0) : value}M`
	}
	return number
}

export function formatNumberWithCommas(number) {
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}