// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InternalChannelMain {
  overflow-y: auto;
  height: calc(100vh - 100px);
}
.InternalChannelMain .whiteSpace {
  white-space: pre-wrap;
}
.InternalChannelMain .btn-cards {
  background-color: #D9D9E2;
  color: #9391A1;
  display: inline-block;
  height: 35px;
  width: 35px;
  border-radius: 50%;
}
.InternalChannelMain .read-or-hide {
  color: #2B97DB;
  cursor: pointer;
}
.InternalChannelMain .color-purple {
  color: #7A6BE8;
}
.InternalChannelMain .purple-header {
  background-color: #7A6BE8;
  color: white;
}
.InternalChannelMain .btn-blue {
  background-color: #41C8FF;
  color: white;
  font-weight: 600;
}
.InternalChannelMain .employeeList {
  max-height: 250px;
  height: auto;
  overflow-y: auto;
}
.InternalChannelMain input.form-check-input {
  background-color: #7A6BE8;
  border: none;
}
.InternalChannelMain .card-fixed-bottom {
  height: 100%;
}
.InternalChannelMain .window-fixed-bottom {
  display: inline-block;
}
.InternalChannelMain .window-fixed-bottom .btn-openWindow {
  background-color: #7A6BE8;
  padding: 0.35rem 2rem;
  color: white;
}
.InternalChannelMain .offcanvas-bottom {
  left: initial;
  min-width: 60%;
  height: auto;
}

.internalChannel-popover {
  transform: translate3d(100px, 290px, 0px) !important;
}`, "",{"version":3,"sources":["webpack://./src/Views/InternalChannel/InternalChannel.sass"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,2BAAA;AACJ;AAAI;EACI,qBAAA;AAER;AADI;EACI,yBAAA;EACA,cAAA;EACA,qBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;AAGR;AAFI;EACI,cAAA;EACA,eAAA;AAIR;AAHI;EACI,cAAA;AAKR;AAJI;EACI,yBAAA;EACA,YAAA;AAMR;AALI;EACI,yBAAA;EACA,YAAA;EACA,gBAAA;AAOR;AANI;EACI,iBAAA;EACA,YAAA;EACA,gBAAA;AAQR;AAPI;EACI,yBAAA;EACA,YAAA;AASR;AARI;EACI,YAAA;AAUR;AATI;EACI,qBAAA;AAWR;AAVQ;EACI,yBAAA;EACA,qBAAA;EACA,YAAA;AAYZ;AAVI;EACI,aAAA;EACA,cAAA;EACA,YAAA;AAYR;;AATA;EACI,oDAAA;AAYJ","sourcesContent":[".InternalChannelMain\n    overflow-y: auto\n    height: calc(100vh - 100px)\n    .whiteSpace\n        white-space: pre-wrap\n    .btn-cards\n        background-color: #D9D9E2\n        color: #9391A1\n        display: inline-block\n        height: 35px\n        width: 35px\n        border-radius: 50%\n    .read-or-hide\n        color: #2B97DB\n        cursor: pointer\n    .color-purple\n        color: #7A6BE8\n    .purple-header\n        background-color: #7A6BE8\n        color: white\n    .btn-blue\n        background-color: #41C8FF\n        color: white\n        font-weight: 600\n    .employeeList\n        max-height: 250px\n        height: auto\n        overflow-y: auto\n    input.form-check-input\n        background-color: #7A6BE8\n        border: none\n    .card-fixed-bottom\n        height: 100%\n    .window-fixed-bottom\n        display: inline-block\n        .btn-openWindow\n            background-color: #7A6BE8\n            padding: .35rem 2rem\n            color: white\n\n    .offcanvas-bottom\n        left: initial\n        min-width: 60%\n        height: auto\n\n@media (max-width: 767.98px)\n.internalChannel-popover\n    transform: translate3d(100px, 290px, 0px) !important\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
