// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-three-positions {
  background: rgba(0, 0, 0, 0.1);
  width: 50px;
  height: 20px;
  margin: 5px;
  border-radius: 50rem;
  border: 1px solid rgba(0, 0, 0, 0.12);
  position: relative;
  display: flex;
}
.toggle-three-positions .true {
  width: 20px;
  height: 20px;
  border-radius: 50rem 0 0 50rem;
  cursor: pointer;
}
.toggle-three-positions .false {
  width: 20px;
  height: 20px;
  border-radius: 0 50rem 50rem 0;
  cursor: pointer;
}
.toggle-three-positions .null {
  width: 10px;
  height: 20px;
}
.toggle-three-positions .btn-toggle {
  position: absolute;
  border-radius: 50rem;
  width: 20px;
  height: 20px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  top: -1px;
  transition: left 0.3s ease-in-out;
}
.toggle-three-positions .btn-toggle:after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(238, 238, 238, 0.65) 0%, rgba(0, 0, 0, 0) 71%);
}`, "",{"version":3,"sources":["webpack://./src/Views/Employee/Components/Quiz/toggle.sass"],"names":[],"mappings":"AAAA;EACI,8BAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,oBAAA;EACA,qCAAA;EACA,kBAAA;EACA,aAAA;AACJ;AACI;EACI,WAAA;EACA,YAAA;EACA,8BAAA;EACA,eAAA;AACR;AAAI;EACI,WAAA;EACA,YAAA;EACA,8BAAA;EACA,eAAA;AAER;AADI;EACI,WAAA;EACA,YAAA;AAGR;AADI;EACI,kBAAA;EACA,oBAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,wCAAA;EACA,SAAA;EACA,iCAAA;AAGR;AADQ;EACI,kBAAA;EACA,OAAA;EACA,MAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,sFAAA;AAGZ","sourcesContent":[".toggle-three-positions\n    background: rgba(0,0,0,.1)\n    width: 50px\n    height: 20px\n    margin: 5px\n    border-radius: 50rem\n    border: 1px solid rgba(0,0,0,.12)\n    position: relative\n    display: flex\n\n    .true\n        width: 20px\n        height: 20px\n        border-radius: 50rem 0 0 50rem\n        cursor: pointer\n    .false\n        width: 20px\n        height: 20px\n        border-radius: 0 50rem 50rem 0\n        cursor: pointer\n    .null\n        width: 10px\n        height: 20px\n\n    .btn-toggle\n        position: absolute\n        border-radius: 50rem\n        width: 20px\n        height: 20px\n        overflow: hidden\n        box-shadow: 0 2px 5px rgba(0, 0, 0, .3)\n        top: -1px\n        transition: left .3s ease-in-out\n\n        &:after\n            position: absolute\n            left: 0\n            top: 0\n            content: \"\"\n            width: 100%\n            height: 100%\n            background: linear-gradient(45deg, rgba(238,238,238,.65) 0%, rgba(0,0,0,0) 71%)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
