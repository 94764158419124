import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { getEmployeesByCompany } from "../../../../API/Employee/Employee";
import {
    getAllVacanciesReferredByVacancy,
    getInternalEmployeeVacancy,
} from "../../../../API/Employee/InternalEmployeeVacancy";
import { getAreaList } from "../../../../API/Lists/area";
import { getInternalVacancies } from "../../../../API/Services/CareerPlan/InternalVacancies";
import { InternalVacancies } from "../../../../Icons/Icons";
import { AiOutlineLinkedin, AiOutlineMail } from "react-icons/ai";

import "./InternalVacanciesApplications.sass";
import { internalJobsTranslate } from "../../../../i18n/translatedMessages";

const InternalVacancyApplications = () => {
    const { id } = useParams();
    const [applicants, setApplicants] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [applications, setApplications] = useState([]);
    const [data, setData] = useState([]);
    const [dataReferred, setDataReferred] = useState([]);
    const [areaList, setAreaList] = useState([]);

    useEffect(() => {
        const company = JSON.parse(localStorage.getItem("user"));
        const req = async () => {
            const dataAux = await getInternalVacancies();
            const dataFilter = dataAux.filter(
                (dtFilter) => company.id === dtFilter.companyId
            );
            setData(dataFilter);
            setDataReferred(await getAllVacanciesReferredByVacancy(id));
            setApplications(await getInternalEmployeeVacancy());
            setEmployeeList(await getEmployeesByCompany(company.id));
            setAreaList(await getAreaList());
        };
        req();
    }, [id]);

    useEffect(() => {
        const applicationFilter = data.map((element) => {
            const vacAppAux = applications.map((appFilt) => [
                ...employeeList.filter(
                    (emp) =>
                        emp.id === appFilt.employerId &&
                        appFilt.internalVacanciesId === element.id
                ),
            ]);
            const vacAppClear = vacAppAux.filter((x) => x.length !== 0);
            const returnResp = { element, vacanciesApplication: vacAppClear };
            return returnResp;
        });
        setApplicants(applicationFilter);
    }, [employeeList, applications, data]);

    const [vacanteFilter] = data.filter(
        (dtFilter) => dtFilter.id === Number(id)
    );
    const [appFilter] = applicants?.filter(
        (appFilter) => Number(appFilter.element?.id) === Number(id)
    );

    return (
        <div className="col-10 offset-1 InternalVacanciesApplications">
            <div className="row">
                <div className="col d-flex align-items-center pb-4">
                    <div className="icon">
                        <InternalVacancies color={"white"} size={30} />
                    </div>
                    <h4>{internalJobsTranslate.internalJobTitle}</h4>
                </div>
            </div>
            <Link
                to={`/app/services/internal-vacancies-list`}
                className="color-purple fw-bold"
            >
                {internalJobsTranslate.internalJobReturn}
            </Link>
            <div className="row">
                <div className="col py-4">
                    <h5 className="fw-bold">{vacanteFilter?.name}</h5>
                    <h6>
                        {areaList
                            ?.filter(
                                (areaFilt) =>
                                    areaFilt.id === vacanteFilter.area1
                            )
                            .map((area) => area.profileArea)}
                    </h6>
                </div>
            </div>
            <div className="row row-cols-1 g-3 row-cols-md-3">
                {appFilter?.vacanciesApplication?.length ||
                dataReferred?.length ? (
                    <>
                        {applicants
                            ?.filter(
                                (appFilter) =>
                                    Number(appFilter.element?.id) === Number(id)
                            )
                            .map((applicant) =>
                                applicant.vacanciesApplication?.map(
                                    (vacancyApply) =>
                                        vacancyApply.map((emp, empIndex) => {
                                            return (
                                                <div
                                                    key={empIndex}
                                                    className="col h-100"
                                                >
                                                    <div className="card shadow rounded border-0">
                                                        <div className="card-body d-flex">
                                                            <div className="ps-3 pe-2 pt-3">
                                                                <img
                                                                    className="img-fluid rounded-circle"
                                                                    width={60}
                                                                    height={60}
                                                                    src={
                                                                        emp.asset
                                                                    }
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="d-flex flex-column pt-3">
                                                                <div>
                                                                    <span className="fs-5">
                                                                        <strong>
                                                                            {
                                                                                emp.name
                                                                            }
                                                                        </strong>
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    {
                                                                        emp.area
                                                                            ?.profileArea
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card-footer">
                                                            <div className="p-3 d-flex align-items-center justify-content-between">
                                                                <span className="color-purple">
                                                                    Colaborador
                                                                </span>
                                                                <Link
                                                                    to={`/app/analytic/perfil/${emp.id}`}
                                                                    className="btn ms-auto btn-card"
                                                                >
                                                                    Ver mandala
                                                                    completa
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                )
                            )}
                        {dataReferred?.map((refer, referIndex) => {
                            return (
                                <div key={referIndex} className="col h-100">
                                    <div className="card shadow rounded border-0">
                                        <div className="card-body d-flex">
                                            <div className="d-flex flex-column pt-3">
                                                <div>
                                                    <span className="fs-5">
                                                        <strong>
                                                            {refer.name}
                                                        </strong>
                                                    </span>
                                                    <p>
                                                        {areaList
                                                            ?.filter(
                                                                (areaFilt) =>
                                                                    areaFilt.id ===
                                                                    refer.areaId
                                                            )
                                                            .map(
                                                                (area) =>
                                                                    area.profileArea
                                                            )}
                                                    </p>
                                                </div>
                                                <a
                                                    className="d-flex align-items-center"
                                                    target={`_blank`}
                                                    href={
                                                        `mailto:` + refer.email
                                                    }
                                                >
                                                    <AiOutlineMail
                                                        size={23}
                                                        className="me-2"
                                                    />{" "}
                                                    {refer.email}
                                                </a>
                                                <a
                                                    className="d-flex align-items-center"
                                                    target={`_blank`}
                                                    href={refer.linkedin}
                                                >
                                                    <AiOutlineLinkedin
                                                        size={25}
                                                        className="me-2"
                                                    />{" "}
                                                    {refer.linkedin}
                                                </a>
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <div className="p-3 d-flex align-items-center justify-content-between">
                                                <span className="color-blue">
                                                    Indicación
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </>
                ) : (
                    <p className="fw-bold">No hay candidatos</p>
                )}
            </div>
        </div>
    );
};

export default InternalVacancyApplications;
