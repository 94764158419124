export const formationList = [
	{
		id: 0,
		value: "Indiferente",
	},
	{
		id: 1,
		value: "Graduación",
	},
	{
		id: 2,
		value: "MBA",
	},
	{
		id: 3,
		value: "MBA Internacional"
	}
]
export const seniorityList = [
	{
		id: 0,
		value: "Indiferente",
	},
	{
		id: 1,
		value: "Junior",
	},
	{
		id: 2,
		value: "Mediano",
	},
	{
		id: 3,
		value: "Senior"
	}
]

export const englishList = [
	{
		id: 0,
		value: "Indiferente",
	},
	{
		id: 1,
		value: "Básico",
	},
	{
		id: 2,
		value: "Medio",
	},
	{
		id: 3,
		value: "Avanzado"
	}
]
