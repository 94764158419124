import { HiOutlineClock, HiOutlineHeart } from 'react-icons/hi'
import { HiMiniScale } from 'react-icons/hi2'

import { AsksGroup } from '../AsksGroup/asksGroup'
import { RadioButton } from '../RadioButton/radioButton'

import { assessment } from '../../../i18n/translatedMessages'

export function QuestionsScreen({ page, setPage }) {
	return (
		<>
			<div className='row mb-5'>
				<div className='col-4'>
					<p>{assessment.questionsDescription}</p>
				</div>
				<div className='col'>
					<span className='tw-font-bold'>{assessment.responseOptions}</span>
					<div className='tw-mt-3 d-flex tw-relative'>
						<div className='tw-h-full tw-w-full tw-absolute tw-bg-white/00'></div>
						<div className='mx-2 tw-flex tw-items-center tw-gap-2 '>
							<RadioButton color='#d67806' id={1} selectedId={5} />
							<span style={{ color: '#d67806', fontSize: 14 }} className='fw-bold'>
								{assessment.veryDisagreeable}
							</span>
						</div>
						<div className='mx-2 tw-flex tw-items-center tw-gap-2 '>
							<RadioButton color='#d67806' id={2} selectedId={5} />
							<span style={{ color: '#d67806', fontSize: 14 }} className='fw-bold'>
								{assessment.inDisagreement}
							</span>
						</div>
						<div className='mx-2 tw-flex tw-items-center tw-gap-2 '>
							<RadioButton color='#627489' id={3} selectedId={5} />
							<span style={{ color: '#627489', fontSize: 14 }} className='fw-bold'>
								{assessment.neutral}
							</span>
						</div>
						<div className='mx-2 tw-flex tw-items-center tw-gap-2 '>
							<RadioButton color='#7a6af1' id={4} selectedId={5} />
							<span style={{ color: '#7a6af1', fontSize: 14 }} className='fw-bold'>
								{assessment.iAgree}
							</span>
						</div>
						<div className='mx-2 tw-flex tw-items-center tw-gap-2 '>
							<RadioButton color='#7a6af1' id={5} selectedId={5} />
							<span style={{ color: '#7a6af1', fontSize: 14 }} className='fw-bold'>
								{assessment.iAgreeVeryMuch}
							</span>
						</div>
					</div>
				</div>
			</div>
			<div className='row my-2'>
				<div className='col'>
					<div className='d-flex justify-content-around w-100 p-3' style={{ background: '#030617', borderRadius: 12 }}>
						<div className='d-flex align-items-center'>
							<HiOutlineClock color='#3e93b2' size={18} />
							<span className='text-white ms-1'>{assessment.itsLessThan25Minutes}</span>
						</div>
						<div className='d-flex align-items-center'>
							<HiMiniScale color='#d67806' size={18} />
							<span className='text-white ms-1'>{assessment.tryNotToRespondInNeutral}</span>
						</div>
						<div className='d-flex align-items-center'>
							<HiOutlineHeart color='#b90f59' size={22} />
							<span className='text-white ms-1'>{assessment.answerHonestly}</span>
						</div>
					</div>
				</div>
			</div>
			<div className='row my-2'>
				<div className='col'>
					<AsksGroup page={page} setPage={setPage} />
				</div>
			</div>
		</>
	)
}
