import { NavLink, useLocation } from 'react-router-dom'

import { HistoricoProfessionalIcon, PropositoIcon, FormacionIcon, CulturaIcon, SalarioIcon, HabilidadesIcon, MotivacionesIcon, DesempenoIcon, EntrenamientoIcon, PlanCarreraIcon } from '../../../../Icons/CollaboratorsIcons'
import { wheelCollaboratorTranslate } from '../../../../i18n/translatedMessages'

import './SideMenu.sass'
import { BsFileText } from 'react-icons/bs'

const links = [
	{
		id: 1,
		name: wheelCollaboratorTranslate.professionalHistoric,
		icon: <HistoricoProfessionalIcon />,
		link: '/employee/professionalinfo',
	},
	{
		id: 11,
		name: wheelCollaboratorTranslate.documentsTitle,
		icon: <BsFileText size={20} />,
		link: '/employee/documents',
	},
	{
		id: 2,
		name: wheelCollaboratorTranslate.purpose,
		icon: <PropositoIcon />,
		link: '/employee/proposito',
	},
	{
		id: 3,
		name: wheelCollaboratorTranslate.formation,
		icon: <FormacionIcon />,
		link: '/employee/formationinfo',
	},
	{
		id: 4,
		name: wheelCollaboratorTranslate.climeAndCulture,
		icon: <CulturaIcon />,
		link: '/employee/climaycultura',
	},
	{
		id: 5,
		name: wheelCollaboratorTranslate.salary,
		icon: <SalarioIcon />,
		link: '/employee/salaryinfo',
	},
	{
		id: 6,
		name: wheelCollaboratorTranslate.habilities,
		icon: <HabilidadesIcon />,
		link: '/employee/assessment',
	},
	{
		id: 7,
		name: wheelCollaboratorTranslate.motivacions,
		icon: <MotivacionesIcon />,
		link: '/employee/motivaciones',
	},
	{
		id: 8,
		name: wheelCollaboratorTranslate.performance,
		icon: <DesempenoIcon />,
		link: '/employee/desempeno',
	},
	{
		id: 9,
		name: wheelCollaboratorTranslate.training,
		icon: <EntrenamientoIcon />,
		link: '/employee/entrenamiento',
	},
	{
		id: 10,
		name: wheelCollaboratorTranslate.careerPlan,
		icon: <PlanCarreraIcon />,
		link: '/employee/plandecarrera',
	},
]

const SideMenu = ({ handleMenu, option }) => {
	const pathname = useLocation().pathname

	return (
		<div className={pathname === '/employee' ? 'd-none' : 'sideMenu-main pe-4'}>
			<ul>
				{links.map((item, key) => {
					return (
						<NavLink key={key} to={item.link}>
							<li onClick={handleMenu} data-option={item.id} className={`${pathname === item.link ? 'active-menu-plan' : ''} tw-bg-black`}>
								{item.icon}
								<span>{item.name}</span>
							</li>
						</NavLink>
					)
				})}
			</ul>
		</div>
	)
}
export default SideMenu
