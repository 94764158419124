import { useCallback, useEffect, useState, useRef } from "react";
import {
    MdKeyboardArrowDown,
    MdOutlineSearch,
    MdOutlineRadioButtonChecked,
    MdOutlineRadioButtonUnchecked,
} from "react-icons/md";

import Bands from "../../../../../../API/Services/PayBalance/Bands";

import "./select.css";
import BandsMapper from "../../../../../../Utils/BandsMapper";
import { bandsTranslate } from "../../../../../../i18n/translatedMessages";
import { NewBandModal } from "../NewBand";

export const SelectSearch = ({ setSalary, setBand, bandId, countryId }) => {
    const [show, setShow] = useState(false);
    const [selected, setSelected] = useState(null);
    const [search, setSearch] = useState(null);
    const [options, setOptions] = useState([]);
    const [filterOptions, setFilterOptions] = useState([]);
    const [showNewBandModal, setShowNewBandModal] = useState(false);

    const componentRef = useRef(null);
    const modalRef = useRef(null);

    const loadOptions = useCallback(async () => {
        const response = await Bands.getAllBands(
            JSON.parse(localStorage.getItem("user")).id
        );

        const data = response.filter((item) => item.country_id === countryId);

        setOptions(data.map(BandsMapper.toDomainSelect));
    }, []);

    useEffect(() => {
        loadOptions();
    }, [loadOptions]);

    useEffect(() => {
        if (search) {
            setFilterOptions(
                options.filter((item) =>
                    item.label.toLowerCase().includes(search.toLowerCase())
                )
            );
        } else {
            setFilterOptions(options);
        }
    }, [search, options]);

    useEffect(() => {
        const [band] = options.filter((item) => item.value === bandId);
        setSelected(band);
    }, [bandId, options]);

    useEffect(() => {
        const handleClickOut = (event) => {
            if (
                componentRef.current &&
                !componentRef.current.contains(event.target)
            ) {
                setShow(false);
                setSearch(null);
            }
        };

        window.addEventListener("click", handleClickOut);

        return () => {
            window.removeEventListener("click", handleClickOut);
        };
    }, []);

    const handleShow = () => {
        setShow(!show);
    };

    const handleCloseNewBandModal = () => {
        setShowNewBandModal(false);
        setTimeout(() => {
            setShow(true);
        }, 10);
    };

    const handleClick = (item) => {
        setShow((prevShow) => !prevShow);
        setSearch(null);
        setSelected(item);
        setBand(item.value);
        setSalary(item.band);
    };

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    return (
        <>
            <div ref={modalRef}>
                <NewBandModal
                    handleClose={handleCloseNewBandModal}
                    refresh={loadOptions}
                    show={showNewBandModal}
                />
            </div>
            <div className="paybalance-select" ref={componentRef}>
                <div
                    className="d-flex w-100"
                    role="button"
                    onClick={handleShow}
                >
                    <label>{bandsTranslate.selectBands}</label>
                    <div className="select">
                        {selected && selected.label}
                        {!selected && (
                            <span className="placeholder">
                                Selecionar Nivel
                            </span>
                        )}
                    </div>
                    <MdKeyboardArrowDown size={22} />
                </div>
                {show && (
                    <div className="menu-select">
                        <div className="search-select">
                            <MdOutlineSearch size={22} />
                            <input
                                type="text"
                                placeholder="Busquedá"
                                onChange={handleSearch}
                            />
                        </div>

                        {filterOptions.length > 0 ? (
                            <ul>
                                {filterOptions.map((item) => (
                                    <li
                                        key={item.value}
                                        onClick={() => handleClick(item)}
                                    >
                                        {selected?.value === item.value && (
                                            <MdOutlineRadioButtonChecked />
                                        )}
                                        {selected?.value !== item.value && (
                                            <MdOutlineRadioButtonUnchecked />
                                        )}
                                        {item.label}
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div className="py-3 text-center">
                                <span>No ha Bandas creadas</span>
                            </div>
                        )}

                        <div className="w-100 p-1 border-top border-grey">
                            <button
                                className="btn btn-primary w-100"
                                onClick={() => {
                                    setShowNewBandModal(true);
                                    setShow(false);
                                }}
                            >
                                Crear Banda
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
