import { CulturalIcon, CarreraIcon, LiderazgoIcon, InteresIcon } from "../../../../Icons/Icons"
import { VscDebugStart } from 'react-icons/vsc'
import { AiOutlineCloudDownload } from 'react-icons/ai'
import { cultureBusinessTranslate } from "../../../../i18n/translatedMessages"

const Timeline = ({ step }) => {
    return (
        <div className="row my-5">
            <div className="col-8 offset-2">
                <div className="content-icons-business-culture">
                    <hr />
                    <div className={`icon-b ${step === 0 ? "active" : step > 0 ? "finished" : ""}`}>
                        <div className="icon-border">
                            <VscDebugStart size="25" />
                        </div>
                        <span>{cultureBusinessTranslate.cbTimelineStart}</span>
                    </div>
                    <div className={`icon-b ${step === 1 ? "active" : step > 1 ? "finished" : ""}`}>
                        <div className="icon-border">
                            <CulturalIcon size={25} />
                        </div>
                        <span>{cultureBusinessTranslate.cbTimeLineQuestions}</span>
                    </div>
                    <div className={`icon-b ${step === 2 ? "active" : step > 2 ? "finished" : ""}`}>
                        <div className="icon-border">
                            <CarreraIcon size={25} />
                        </div>
                        <span>{cultureBusinessTranslate.cbTimeLineCareer}</span>
                    </div>
                    <div className={`icon-b ${step === 3 ? "active" : step > 3 ? "finished" : ""}`}>
                        <div className="icon-border">
                            <LiderazgoIcon size={25} />
                        </div>
                        <span>{cultureBusinessTranslate.cbTimeLineLeader}</span>
                    </div>
                    <div className={`icon-b ${step === 4 ? "active" : step > 4 ? "finished" : ""}`}>
                        <div className="icon-border">
                            <InteresIcon size={25} />
                        </div>
                        <span>{cultureBusinessTranslate.cbTimeLineInterests}</span>
                    </div>
                    <div className={`icon-b ${step === 5 ? "active" : step > 5 ? "finished" : ""}`}>
                        <div className="icon-border">
                            <AiOutlineCloudDownload size={25} />
                        </div>
                        <span>{cultureBusinessTranslate.cbTimeLineReportDelivery}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Timeline