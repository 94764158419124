import { useContext } from "react";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { ServicesContext } from "../Store/ServicesContext";

const FORMDATA = {
    country_id: "",
    city_id: "",
    industry_id: "1",
    area_id: "",
    job_id: "",
    company_size_id: "1",
    qualification_id: "",
    english_level_id: "",
    seniority_id: "",
    current_salary: "",
    variable_salary: "",
};

export const useGenerateReport = () => {
    const { store, actions } = useContext(ServicesContext);
    const [report, setReport] = useState(FORMDATA);

    const handleReport = (index) => {
        setReport({
            ...report,
            country_id: store.companyPositions[index].country_id,
            city_id: store.companyPositions[index].city_id,
            industry_id: store.profile_data_company[0].industry_id,
            area_id: store.companyPositions[index].area_id,
            job_id: store.companyPositions[index].job_id,
            company_size_id: store.profile_data_company[0].company_size_id,
            qualification_id: store.companyPositions[index].qualification_id,
            english_level_id: store.companyPositions[index].english_level_id,
            seniority_id: store.companyPositions[index].seniority_id,
            current_salary: store.companyPositions[index].current_salary,
            variable_salary: store.companyPositions[index].variable_salary,
            job_name: store.companyPositions[index].job_name,
            subArea: store.companyPositions[index].subArea,
        }); 

    };

    const getIdSmtmByCompanyName = async () => {
        await fetch(
            `https://smtm.co/v1/getCompanyIdFromMyDna?name=${
                JSON.parse(localStorage.getItem("user")).name
            }`
        )
            .then((response) => response.json())
            .then((data) => {
                actions.getDataPositionsCompany(data);

                const valorConComillas = localStorage.getItem("email").toString(); // Para eliminar las comillas dobles
                const valorSinComillas = valorConComillas.replace(/^"(.*)"$/, '$1');
                actions.getDataCompany(valorSinComillas);
            });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => getIdSmtmByCompanyName(), []);

    useEffect(() => {
        if (report.city_id !== "") {
            generarReporte();
        }
        return () => setReport(FORMDATA);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [report]);

    const generarReporte = async () => {
        if (report.job_id === null) {
            //Enviar correo para validar posicion
            Swal.fire({
                title: "Hola 👋",
                text: "La posición se esta validando. Intente nuevamente mas tarde.",
                icon: "info",
                confirmButtonText: "Ok",
            });
        } else {
            Swal.fire({
                title: "Procesando ⏳",
                text: "Estamos procesando los datos, por favor espere.",
                icon: "info",
                showCancelButton: false,
                showConfirmButton: false,
            });
            const response = await actions.createReport(report);
            if (response === 200) {
                if (store.last_report_code) {
                    var url = `https://smtm.co/v1/reports/api_recover?authorization=19c073e1768a3cbf6e9d76390e640f3b&email=${JSON.parse(
                        localStorage.getItem("email")
                    )}&code=${store.last_report_code}`;
                    const newWindow = window.open(
                        url,
                        "_blank",
                        "noopener,noreferrer"
                    );
                    if (newWindow) newWindow.opener = null;
                    Swal.fire({
                        title: "Reporte generado con exito! 🧑‍💻",
                        text: "En caso de que el reporte no se despliegue automaticamente, por favor, habilite las ventanas emergentes.",
                        icon: "success",
                        showCancelButton: false,
                        showConfirmButton: true,
                    });
                } else {
                }
            }
        }
    };

    return {
        handleReport,
    };
};
