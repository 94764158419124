import { HiMiniCheckCircle } from 'react-icons/hi2'
import { Link } from 'react-router-dom'

import './modal.sass'

export function ModalSuccess({ show, handleShow }) {
	return (
		show && (
			<div className='overlayer'>
				<div className='modal-body-success' style={{ width: 546, height: 405 }}>
					<div className='row'>
						<div className='col flex justify-content-center align-items-center'>
							<HiMiniCheckCircle size={100} className='text-primary' />
						</div>
					</div>
					<div className='row'>
						<div className='col p-4 text-center'>
							<h3 className='fw-bold'>¡Colaborador registrado con éxito!</h3>
						</div>
					</div>
					<div className='row mt-4'>
						<div className='col'>
							<Link
								to='/app/services/new'
								className='btn btn-outline-black rounded-pill w-100'
								onClick={() => {
									window.location.reload()
								}}
							>
								Registrar un nuevo colaborador
							</Link>
							<Link to='/app/services/list' className='btn btn-primary rounded-pill w-100 mt-2'>
								Ver lista de colaboradores
							</Link>
						</div>
					</div>
				</div>
			</div>
		)
	)
}
