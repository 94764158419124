import { useIntl } from "react-intl";
import "./styles.css";
import { AiOutlineMinusCircle } from "react-icons/ai";
function TableRows({ data, index, deleteTableRows, handleChange }) {
  const language = useIntl();
  const { name, weight, value } = data;
  return (
    <tr key={index} className="analisisInputRow align-items-center">
      <td>
        <input
          type="text"
          value={name}
          onChange={(e) => handleChange(index, e)}
          name="name"
          placeholder={language.locale == "ES" ? "Criterio" : "Critério"}
          className="form-control"
        />
      </td>
      <td width="20%">
        <input
          type="text"
          value={weight}
          onChange={(e) => handleChange(index, e)}
          name="weight"
          placeholder="Peso"
          className="form-control"
        />
      </td>
      <td width="20%">
        <input
          type="text"
          value={value}
          onChange={(e) => handleChange(index, e)}
          name="value"
          placeholder={language.locale == "ES" ? "Logrado" : "Executado"}
          className="form-control"
        />
      </td>
      <td className="pt-4">
        <button className="add-remove-button mt-1" onClick={() => deleteTableRows(index)}>
          <AiOutlineMinusCircle size={25} />
        </button>
      </td>
    </tr>
  );
}
export default TableRows;
